import { BottomSheet } from "react-spring-bottom-sheet";
import styled, { css } from "styled-components";
import { CloseIcon, theme } from "ui";

interface BottomSheetContentProps {
  noFooter?: boolean;
  omitHeader?: boolean;
  padding?: string;
  height?: string;
  marginBottom?: string;
  display?: string;
  alignItems?: string;
  isSafari?: boolean;
  hasOverflow?: boolean;
}

interface BottomSheetContainerProps {
  headerpadding?: string;
  hideheaderline?: string;
  omitHeader?: boolean;
  isSafari?: boolean;
  containerHeight?: string;
}

export const BottomSheetContainer = styled(
  BottomSheet
)<BottomSheetContainerProps>`
  [data-rsbs-header] {
    padding: ${({ headerpadding }) => headerpadding || "20px 16px 8px"};
    &::before {
      content: "";
      ${({ omitHeader }) => omitHeader && "margin-top: 4px;"}

      display: ${({ hideheaderline }) =>
        hideheaderline === "true" ? "none" : "block"}
    }
  }

  [data-rsbs-overlay] {
    height: ${({ containerHeight }) => containerHeight};
  }

  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: 6;
  }
`;

export const BottomSheetContent = styled.section<BottomSheetContentProps>`
  padding: ${({ noFooter }) =>
    noFooter
      ? `${theme.space.x0} ${theme.space.x4} calc( 100% + 50px)`
      : `${theme.space.x2} ${theme.space.x8} calc( 100% + 50px)`};

  height: ${({ noFooter }) => !noFooter && "auto"};
  display: ${({ display }) => display || "block"};
  flex-direction: ${({ display }) => display && "column"};
  align-items: ${({ alignItems }) => alignItems};
  width: 100vw;
  ${({ noFooter, omitHeader }) =>
    noFooter && !omitHeader && "max-height: 50vh;"};
  overflow-y: ${({ noFooter, hasOverflow }) =>
    noFooter || hasOverflow ? "auto" : "visible"};

  margin-bottom: ${({ noFooter, isSafari }) =>
    noFooter ? `${theme.space.x0}` : isSafari ? "10rem" : "20%"};

  /* @media (max-height: 820px) {
    margin-bottom: ${({ noFooter }) =>
    noFooter ? `${theme.space.x0}` : `${theme.space.x60}`};
  }

  @media (max-height: 738px) {
    margin-bottom: 18rem;
  }

  @media (max-height: 677px) {
    margin-bottom: 24rem;
  }

  @media (max-height: 518px) {
    margin-bottom: ${({ noFooter }) =>
    noFooter ? `${theme.space.x0}` : `${theme.space.x60}`};
  } */

  @media ${theme.device.desktopFirst.mobileS} {
    padding: ${({ noFooter }) => noFooter && `${theme.space.x0}`};
  }

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  ${({ height }) =>
    height &&
    css`
      height: ${height};
      max-height: ${height};
    `};

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `};
`;

interface HeaderBottomSheetProps {
  boxShadow?: string;
  padding?: string;
  justifyContent?: string;
}

interface FooterButtonProps {
  height?: string;
}

export const HeaderBottomSheet = styled.header<HeaderBottomSheetProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  padding: ${({ padding }) => padding || `1.25rem`};
`;

interface TitleBottomSheetProps {
  titleColor?: string;
  titleFontSize?: string;
  titleFontFamily?: string;
  titleFontWeight?: number;
}

export const TitleBottomSheet = styled.span<TitleBottomSheetProps>`
  font-size: ${({ titleFontSize }) => titleFontSize || `1.35rem`};
  color: ${({ titleColor }) => titleColor || theme.colors.neutral["550"]};
  font-weight: ${({ titleFontWeight }) =>
    titleFontWeight || theme.typography.fontWeight["600"]};
  font-family: ${({ titleFontFamily }) =>
    titleFontFamily || theme.typography.fontFamily.barlow};
`;

export const TopBackdropBottomSheet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: ${theme.colors.neutral["825"]};
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 4;
`;

export const Close = styled(CloseIcon).attrs({
  color: theme.colors.neutral["315"],
  width: 24,
  height: 24,
})``;

interface BaseButtonProps {
  borderRadius?: string;
  fontSize?: string;
  fontWeight?: number;
}

const BaseButton = styled.button<BaseButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 3rem;
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  padding: 0.65rem 0;
  cursor: pointer;
  border: 0;
  font-weight: 700;
`;

export const ConfirmButton = styled(BaseButton)<FooterButtonProps>`
  background: ${theme.colors.secondary["250"]};
  color: ${theme.colors.neutral.white};
  height: ${({ height }) => height || "3rem"};
  display: flex;
  transition: all 0.3s ease-in-out;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: ${theme.colors.secondary["350"]};
  }

  &:focus {
    border: thin solid ${theme.colors.secondary["350"]};
  }
`;

interface FooterBottomSheetProps {
  padding?: string;
}

export const FooterBottomSheet = styled.footer<FooterBottomSheetProps>`
  padding: ${({ padding }) => padding ?? "1rem 2rem"};
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0px;
  transition: all 0.4s ease-in-out;
  background: ${theme.colors.neutral["white-opacity-25"]};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  -webkit-box-shadow: 0px 0px 12px 1px ${theme.colors.neutral["999-opacity-15"]};
  box-shadow: 0px 0px 12px 1px ${theme.colors.neutral["999-opacity-15"]};
`;

interface HeaderActionTextProps {
  headerActionColor?: string;
  headerActionFontFamily?: string;
  headerActionFontSize?: string;
  headerActionFontWeight?: number;
}

export const HeaderActionText = styled.span<HeaderActionTextProps>`
  color: ${({ headerActionColor }) =>
    headerActionColor || theme.colors.primary["350"]};
  font-family: ${({ headerActionFontFamily }) =>
    headerActionFontFamily || theme.typography.fontFamily.chivo};
  font-size: ${({ headerActionFontSize }) =>
    headerActionFontSize || `${theme.typography.fontSize.sm.x5}`};
  font-weight: ${({ headerActionFontWeight }) =>
    headerActionFontWeight || theme.typography.fontWeight["400"]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
