import styled from "styled-components";
import { Button, ButtonProps, DefaultProductImage, theme, Title } from "ui";
import { Image } from "../Image";
import { ProductCardCurrentPrice } from "../ProductCard/styles";
import { Link } from "../Link";

interface RecommendationProductCardContainerProps {
  width?: string;
  isPlaylist: boolean;
  isMinimalist?: boolean;
}

interface ProductCardTagBorderLineProps {
  tagColor?: string;
}

export const TagBorderLine = styled.span<ProductCardTagBorderLineProps>`
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 3px);
  border: 2px solid ${({ tagColor }) => tagColor || theme.colors.primary["200"]};
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  opacity: 0;
  visibility: hidden;
  top: -1px;
`;

export const RecommendationDefaultProductImageContainer = styled(
  DefaultProductImage
).attrs({
  width: 100,
  height: 100,
})`
  position: relative;
  bottom: 0.6875rem;
  right: 0.1875rem;
  transform: scale(0.9);

  @media ${theme.device.desktopFirst.mobileL} {
    top: 0.5rem;
  }
`;

type RecommendationProductCardImageContainerProps = {
  isMinimalist?: boolean;
};

export const RecommendationProductCardImageContainer = styled(
  Link
)<RecommendationProductCardImageContainerProps>`
  width: auto;
  transition: all 0.2s linear;
  padding: ${({ isMinimalist }) =>
    !isMinimalist ? "0.667rem 0 0.667rem 0.667rem" : "1rem"};
  max-height: ${({ isMinimalist }) => (isMinimalist ? "90px" : "160px")};
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${theme.device.desktopFirst.tabletL} {
    padding: 0px;
  }
`;

type RecommendationProductCardInfoAreaProps = {
  isMinimalist?: boolean;
};

export const RecommendationProductCardInfoArea = styled.section<RecommendationProductCardInfoAreaProps>`
  width: 100%;
  max-width: 162px;
  height: 100%;
  padding: ${({ isMinimalist }) => (!isMinimalist ? "0.667rem" : "1rem")};

  @media ${theme.device.desktopFirst.tabletL} {
    padding: 0px;
  }
`;

type RecommendationProductCardImageContentProps = {
  isMinimalist?: boolean;
};
export const RecommendationProductCardImageContent = styled.div<RecommendationProductCardImageContentProps>`
  max-width: 100px;
  height: 100vh;
  max-height: ${({ isMinimalist }) => (isMinimalist ? "80px" : "100px")};
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    max-width: 100%;
  }

  @media ${theme.device.desktopFirst.tabletL} {
    max-width: ${({ isMinimalist }) => (isMinimalist ? "80px" : "160px")};
    max-height: ${({ isMinimalist }) => (isMinimalist ? "80px" : "160px")};
  }
`;

export const ImageProduct = styled(Image)`
  margin: 0px auto 0px auto !important;
  transition: all 0.2s linear;
  transform: scale(0.9);
`;

export const RecommendationProductCardContainer = styled.section<RecommendationProductCardContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 104px;
  max-width: 100%;
  cursor: pointer !important;

  background: ${theme.colors.neutral.white};

  user-select: none;
  -webkit-user-drag: none;
  position: relative;
  -webkit-tap-highlight-color: transparent !important;
  transition: all 0.2s ease-in-out;
  // artur.castro transferir o border-right para a condição da linha onde 'e utilizado o css

  min-height: ${({ isMinimalist }) => (isMinimalist ? "7rem" : "8.75rem")};
  height: auto;
  max-height: ${({ isMinimalist }) => (isMinimalist ? "10rem" : "8.75rem")};

  &:hover {
    ${RecommendationProductCardImageContainer} {
      transform: scale(1.1);
    }

    ${TagBorderLine} {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  @media ${theme.device.desktopFirst.tabletL} {
    min-width: ${({ isMinimalist }) => (isMinimalist ? "80px" : "190px")};
    width: 100%;
    max-width: ${({ isMinimalist }) => (isMinimalist ? "120px" : "190px")};
    min-height: ${({ isMinimalist }) => (isMinimalist ? "9rem" : "12rem")};
    height: auto;
    max-height: 31rem;
    border: ${({ isMinimalist }) =>
      isMinimalist ? "none" : `1px solid ${theme.colors.neutral["105"]}`};
    border-radius: 8px;
    padding: 1rem;
  }

  @media ${theme.device.desktopFirst.tablet} {
    margin-left: 0px;
  }
`;

export const CouponTagWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: 16px;
  z-index: 2;
`;

export const RecommendationProductCardContent = styled.section<{
  isMinimalist?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ isMinimalist }) => (isMinimalist ? "center" : "auto")};
  flex-direction: ${({ isMinimalist }) => (isMinimalist ? "column" : "row")};

  @media ${theme.device.desktopFirst.tabletL} {
    flex-direction: column;
    gap: ${theme.space.x4};
  }
`;

export const RecommendationProductCardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: max-content;
  margin-top: 0px;
`;

export const RecommendationProductCardTitle = styled(Title)`
  color: ${theme.colors.neutral["520"]};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight["400"]};
  line-height: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  word-break: break-word;

  max-width: 170px;
  min-width: 100%;

  @media ${theme.device.desktopFirst.tabletL} {
    -webkit-line-clamp: 3;
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const ProductCardDiscountPriceContainerSpace = styled.section`
  min-height: 18px;
`;

export const ProductCardDiscountPriceContainer = styled.section`
  display: flex;
  gap: 8px;
`;

export const RecommendationProductCardCurrentPrice = styled(
  ProductCardCurrentPrice
)`
  font-size: ${theme.typography.fontSize.md.x1};
`;

export const ProductCardSpotPriceDescription = styled.span`
  color: ${theme.colors.primary["200"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 0.875rem;
  font-weight: ${theme.typography.fontWeight["400"]};
`;

export const AddToCartButton = styled(Button)<ButtonProps>`
  display: flex;
  padding: ${theme.space.x0};
  border-radius: ${theme.space.x1};
  margin-top: ${theme.space.x1};
  width: 130px;
  align-items: center;
  height: 24px;
  gap: ${theme.space.x1};
  justify-content: center;
  font-size: ${theme.typography.fontSize.sm.x1};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-family: ${theme.typography.fontFamily.chivo};
  text-transform: uppercase;
  transition: background-color 0.3s ease-in-out;

  background-color: ${theme.colors.secondary["380"]};
  border: 1px solid ${theme.colors.secondary["380"]};
  color: ${theme.colors.neutral.white};

  &:hover {
    background-color: ${theme.colors.secondary["250"]};
    border-color: ${theme.colors.secondary["250"]};
  }

  &:disabled {
    background: ${theme.colors.neutral["760-opacity-23"]};
  }

  @media ${theme.device.desktopFirst.tabletL} {
    width: 100%;
  }
`;
