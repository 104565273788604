import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ArrowLeft = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral.white,
  alt = "Voltar",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M12.0607 14L17.5303 19.4697C17.8232 19.7626 17.8232 20.2374 17.5303 20.5303C17.2374 20.8232 16.7626 20.8232 16.4697 20.5303L10.4697 14.5303C10.1768 14.2374 10.1768 13.7626 10.4697 13.4697L16.4697 7.46967C16.7626 7.17678 17.2374 7.17678 17.5303 7.46967C17.8232 7.76256 17.8232 8.23744 17.5303 8.53033L12.0607 14Z"
        fill={color}
      />
      <mask
        id="mask0_0_3595"
        maskUnits="userSpaceOnUse"
        x="10"
        y="7"
        width="8"
        height="14"
      >
        <path
          d="M12.0607 14L17.5303 19.4697C17.8232 19.7626 17.8232 20.2374 17.5303 20.5303C17.2374 20.8232 16.7626 20.8232 16.4697 20.5303L10.4697 14.5303C10.1768 14.2374 10.1768 13.7626 10.4697 13.4697L16.4697 7.46967C16.7626 7.17678 17.2374 7.17678 17.5303 7.46967C17.8232 7.76256 17.8232 8.23744 17.5303 8.53033L12.0607 14Z"
          fill={secondColor}
        />
      </mask>
      <g mask="url(#mask0_0_3595)">
        <rect width={width} height={height} fill="none" />
      </g>
    </svg>
  );
};
