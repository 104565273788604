import styled from "styled-components";
import { theme } from "ui";

export const TextWithCountdownContainer = styled.section`
  width: 100%;
`;

export const SubTitleTextWithCountdown = styled.span`
  color: ${theme.colors.neutral["525"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeight["400"]};
  line-height: 140%;
  letter-spacing: -0.02rem;
`;

export const TitleStyledTextWithCountdown = styled(SubTitleTextWithCountdown)`
  font-weight: ${theme.typography.fontWeight["700"]};
  text-decoration: underline;
  cursor: pointer;
`;
