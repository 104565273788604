import { buildTestIds } from "application";
import { ReactNode } from "react";
import { CheckDone } from "ui";
import {
  LabelContainer,
  OptionalText,
  RequiredFieldSignal,
  StyledLabel,
} from "./style";

type JustifyContent =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-around"
  | "space-between"
  | "space-evenly";

type Display =
  | "flex"
  | "inline-flex"
  | "block"
  | "inline-block"
  | "none"
  | "grid";

type StyledLabelProps = {
  text?: string;
  hasSuccessfully?: boolean;
  htmlFor?: string;
  color?: string;
  fontSize?: string;
  display?: Display;
  justifyContent?: JustifyContent;
  paddingLeft?: string;
  maxWidth?: string;
  typeFontFamily?: string;
  typeFontWeight?: number;
  showOptionalText?: boolean;
  isRequired?: boolean;
  children?: ReactNode;
};

const Label = ({
  text,
  hasSuccessfully = false,
  color,
  fontSize,
  display,
  justifyContent,
  paddingLeft,
  maxWidth,
  typeFontFamily,
  typeFontWeight,
  showOptionalText,
  children,
  isRequired,
  ...props
}: StyledLabelProps) => {
  return (
    <LabelContainer
      display="flex"
      justifyContent={justifyContent}
      maxWidth={maxWidth}
      {...props}
      {...buildTestIds(`label-container-${text}`)}
    >
      <StyledLabel
        paddingLeft={paddingLeft}
        typeFontFamily={typeFontFamily}
        color={color}
        fontSize={fontSize}
        display={display}
        justifyContent={justifyContent}
        typeFontWeight={typeFontWeight}
        {...props}
        {...buildTestIds(`form-label-${text ?? "label"}`)}
      >
        {children || text}
      </StyledLabel>

      {isRequired && !hasSuccessfully && (
        <RequiredFieldSignal>*</RequiredFieldSignal>
      )}
      {hasSuccessfully && <CheckDone {...buildTestIds("check-done-icon")} />}

      {showOptionalText && !hasSuccessfully && !isRequired && (
        <OptionalText {...buildTestIds("optional-text")}>Opcional</OptionalText>
      )}
    </LabelContainer>
  );
};

export default Label;
