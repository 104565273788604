import { IconProps } from "typing";
import { theme } from "../themes";

export const Instagram = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["280"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M5.44737 0C2.44374 0 0 2.44374 0 5.44737V12.5526C0 15.5563 2.44374 18 5.44737 18H12.5526C15.5563 18 18 15.5563 18 12.5526V5.44737C18 2.44374 15.5563 0 12.5526 0H5.44737ZM13.7368 3.31579C14.2603 3.31579 14.6842 3.73974 14.6842 4.26316C14.6842 4.78611 14.2603 5.21053 13.7368 5.21053C13.2134 5.21053 12.7895 4.78611 12.7895 4.26316C12.7895 3.73974 13.2134 3.31579 13.7368 3.31579ZM9 4.26316C11.6119 4.26316 13.7368 6.38811 13.7368 9C13.7368 11.6119 11.6119 13.7368 9 13.7368C6.38811 13.7368 4.26316 11.6119 4.26316 9C4.26316 6.38811 6.38811 4.26316 9 4.26316ZM9 5.68421C8.56456 5.68421 8.13339 5.76998 7.7311 5.93661C7.32881 6.10324 6.96328 6.34748 6.65538 6.65538C6.34748 6.96328 6.10324 7.32881 5.93661 7.7311C5.76998 8.13339 5.68421 8.56456 5.68421 9C5.68421 9.43544 5.76998 9.86661 5.93661 10.2689C6.10324 10.6712 6.34748 11.0367 6.65538 11.3446C6.96328 11.6525 7.32881 11.8968 7.7311 12.0634C8.13339 12.23 8.56456 12.3158 9 12.3158C9.43544 12.3158 9.86661 12.23 10.2689 12.0634C10.6712 11.8968 11.0367 11.6525 11.3446 11.3446C11.6525 11.0367 11.8968 10.6712 12.0634 10.2689C12.23 9.86661 12.3158 9.43544 12.3158 9C12.3158 8.56456 12.23 8.13339 12.0634 7.7311C11.8968 7.32881 11.6525 6.96328 11.3446 6.65538C11.0367 6.34748 10.6712 6.10324 10.2689 5.93661C9.86661 5.76998 9.43544 5.68421 9 5.68421Z"
        fill={color}
      />
    </svg>
  );
};
