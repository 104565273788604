import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const SatisfiedFaceIcon = ({
  alt = "Ícone de Avaliação - Satisfeito",
  width = 40,
  height = 40,
  color = theme.colors.secondary[37],
  secondColor = theme.colors.secondary[357],
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle
        cx="20"
        cy="20"
        r="19"
        fill={color}
        stroke={secondColor}
        strokeWidth="2"
      />
      <path
        d="M13.6833 28.7226C13.6833 28.7226 16.3713 31.1772 20.3958 30.9459C23.4141 30.7724 26.4272 27.8039 26.4272 27.8039"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9.11804 15.8969L12.285 14.0685C13.2416 13.5162 14.4647 13.8439 15.017 14.8005L16.8455 17.9675"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.1544 17.9667L24.9828 14.7998C25.5351 13.8432 26.7583 13.5155 27.7149 14.0678L30.8818 15.8962"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
