import { Step, ValidationStep } from "typing";
import { theme } from "ui";
import { createStore } from "../adapter";

export interface RegisterPhoneNumberState {
  isRegisterPhoneNumberModalOpen: boolean;
  currentStep: ValidationStep;
  steps: Step[];
  phoneNumber: string;
  openCheckoutModal: boolean;
}

export interface RegisterPhoneNumberDispatch {
  setIsRegisterPhoneNumberModalOpen: (value: boolean) => void;
  changeCurrentStep: (value: ValidationStep) => void;
  setSteps: (value: Step[]) => void;
  setPhoneNumber: (value: string) => void;
  setOpenCheckoutModal: (value: boolean) => void;
}

type RegisterPhoneNumber = RegisterPhoneNumberState &
  RegisterPhoneNumberDispatch;

const useRegisterPhoneNumber = createStore<RegisterPhoneNumber>((set) => ({
  isRegisterPhoneNumberModalOpen: false,
  currentStep: "modalCall",
  steps: [],
  setIsRegisterPhoneNumberModalOpen: (value: boolean) =>
    set({ isRegisterPhoneNumberModalOpen: value }),
  changeCurrentStep: (value) => {
    const newSteps: Step[] = [
      {
        name: "modalCall",
        statusColor:
          value === "modalCall"
            ? `${theme.colors.secondary["380"]}`
            : `${theme.colors.secondary["37"]}`,
      },
      {
        name: "validation",
        statusColor:
          value === "validation"
            ? `${theme.colors.secondary["380"]}`
            : undefined,
      },
    ];

    set({
      currentStep: value,
      steps: newSteps,
    });
  },
  setSteps: (value: Step[]) => set({ steps: value }),
  phoneNumber: "",
  setPhoneNumber: (value: string) => set({ phoneNumber: value }),
  openCheckoutModal: false,
  setOpenCheckoutModal: (value: boolean) => set({ openCheckoutModal: value }),
}));

export { useRegisterPhoneNumber };
