import { IconProps } from "typing";
import { theme } from "../themes";

export const EyeOpen = ({
  width = 32,
  height = 32,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill={color}
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65892 14.1554C7.5766 17.6666 10.3044 20.4759 13.8688 20.5512C17.3931 20.6257 20.1892 17.9091 20.2617 14.3408C20.3331 10.8139 17.6264 8.02426 14.0538 7.94222C10.5438 7.86186 7.74236 10.5837 7.65892 14.1554ZM20.3947 7.14588C20.4666 7.18107 24.0527 8.93592 27.5204 13.309C27.9626 13.8665 28.1073 14.4343 27.6302 15.0304C24.8627 18.4887 21.6239 21.3139 17.2822 22.619C14.2339 23.5351 11.2807 23.1703 8.44208 21.8131C5.20724 20.267 2.59876 17.9455 0.380603 15.1573C-0.0682375 14.5931 -0.156998 14.0213 0.314523 13.4333C5.10532 7.46006 10.7526 5.85398 10.7526 5.85398C11.8276 5.55242 12.7096 5.31134 13.9455 5.28278C16.4666 5.22454 18.2872 6.10066 20.3936 7.14534C20.3936 7.14534 20.394 7.14552 20.3947 7.14588ZM10.784 14.2426C10.7868 12.4948 12.2196 11.0668 13.9676 11.0693C15.7123 11.0718 17.1448 12.5091 17.142 14.2543C17.1392 15.9976 15.6988 17.4334 13.9556 17.4304C12.212 17.4276 10.7815 15.9895 10.784 14.2426Z"
        fill={color}
      />
      <mask
        id="mask0_0_2792"
        maskUnits="userSpaceOnUse"
        x="0"
        y="5"
        width={width}
        height={height}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.65892 14.1554C7.5766 17.6666 10.3044 20.4759 13.8688 20.5512C17.3931 20.6257 20.1892 17.9091 20.2617 14.3408C20.3331 10.8139 17.6264 8.02426 14.0538 7.94222C10.5438 7.86186 7.74236 10.5837 7.65892 14.1554ZM20.3947 7.14588C20.4666 7.18107 24.0527 8.93592 27.5204 13.309C27.9626 13.8665 28.1073 14.4343 27.6302 15.0304C24.8627 18.4887 21.6239 21.3139 17.2822 22.619C14.2339 23.5351 11.2807 23.1703 8.44208 21.8131C5.20724 20.267 2.59876 17.9455 0.380603 15.1573C-0.0682375 14.5931 -0.156998 14.0213 0.314523 13.4333C5.10532 7.46006 10.7526 5.85398 10.7526 5.85398C11.8276 5.55242 12.7096 5.31134 13.9455 5.28278C16.4666 5.22454 18.2872 6.10066 20.3936 7.14534C20.3936 7.14534 20.394 7.14552 20.3947 7.14588ZM10.784 14.2426C10.7868 12.4948 12.2196 11.0668 13.9676 11.0693C15.7123 11.0718 17.1448 12.5091 17.142 14.2543C17.1392 15.9976 15.6988 17.4334 13.9556 17.4304C12.212 17.4276 10.7815 15.9895 10.784 14.2426Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_0_2792)">
        <rect fill={color} />
      </g>
    </svg>
  );
};
