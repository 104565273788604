import { IconProps } from "typing";

export const SearchIcon = ({
  height = "18px",
  width = "18px",
  color = "currentColor",
  alt = "Ícone de pesquisa",
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      fill={color}
      viewBox="0 0 256 256"
    >
      <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z" />
    </svg>
  );
};
