import { CardName, PaymentTypes } from "typing";
import {
  Banknote,
  BanknoteInput,
  Barcode,
  CheckPayment,
  FCCredit,
  PixIconWithBG,
} from "ui";
import { getSmallMappedCard } from "../getSmallMappedCard";

export const getPaymentIcon = (
  paymentMethod: PaymentTypes,
  banner?: CardName
) => {
  const paymentMethodValue = paymentMethod.toLowerCase();
  switch (paymentMethodValue) {
    case "dinheiro":
      return <Banknote />;
    case "deposito":
      return <BanknoteInput />;
    case "boleto":
      return <Barcode />;
    case "cheque":
      return <CheckPayment />;
    case "giftcard":
      return <FCCredit />;
    case "pix":
      return <PixIconWithBG />;
    default:
      return getSmallMappedCard(banner || "");
  }
};
