import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const IconRightTop = ({
  width = "211",
  height = "322",
  color = theme.colors.secondary["355"],
  secondColor = theme.colors.secondary["45"],
  thirdColor = theme.colors.neutral["white-075"],
  fourthColor = theme.colors.neutral["150"],
  fifthColor = theme.colors.primary["710"],
  sixthColor = theme.colors.primary["705"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 211 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M108 108H2V214H108V108Z" fill={color} />
      <path
        d="M34.8111 142.428C34.2714 142.428 33.7178 142.221 33.3027 141.805C28.9434 137.446 28.9434 130.333 33.3027 125.974C37.6758 121.614 44.7752 121.614 49.1345 125.974L58.4759 135.315C59.3062 136.145 59.3062 137.501 58.4759 138.332C57.6455 139.162 56.2893 139.162 55.459 138.332L46.1176 128.99C43.419 126.292 39.032 126.292 36.3334 128.99C33.6348 131.689 33.6348 136.076 36.3334 138.775C37.1638 139.605 37.1638 140.961 36.3334 141.792C35.9182 142.207 35.3647 142.414 34.825 142.414"
        fill={secondColor}
      />
      <path
        d="M53.023 192.902C52.4695 192.902 51.9297 192.694 51.5146 192.279L20.0446 160.809C19.2143 159.979 19.2143 158.622 20.0446 157.792L56.8703 120.967C57.7006 120.136 59.0568 120.136 59.8872 120.967L91.3571 152.436C92.1875 153.267 92.1875 154.623 91.3571 155.453L54.5315 192.279C54.1163 192.694 53.5627 192.902 53.023 192.902ZM24.57 159.287L53.023 187.74L86.8318 153.931L58.3787 125.478L24.57 159.287Z"
        fill={secondColor}
      />
      <path
        d="M56.9541 142.43C53.8265 142.43 51.2939 139.883 51.2939 136.77C51.2939 133.656 53.8403 131.109 56.9541 131.109C60.0679 131.109 62.6143 133.656 62.6143 136.77C62.6143 139.883 60.0679 142.43 56.9541 142.43ZM56.9541 135.372C56.193 135.372 55.5564 135.995 55.5564 136.77C55.5564 137.545 56.1791 138.167 56.9541 138.167C57.7291 138.167 58.3518 137.545 58.3518 136.77C58.3518 135.995 57.7291 135.372 56.9541 135.372Z"
        fill={secondColor}
      />
      <path
        d="M88.8246 191.325C85.6001 191.325 83.8425 189.706 82.9292 188.35C80.7011 185.015 80.7288 179.147 83.026 170.415C84.6314 164.325 86.7487 159.15 86.8456 158.928C87.1778 158.125 87.9527 157.6 88.8246 157.6C89.6965 157.6 90.4714 158.125 90.8036 158.928C90.8866 159.15 93.0178 164.325 94.6231 170.415C96.9204 179.147 96.9481 185.015 94.72 188.35C93.8066 189.706 92.0491 191.325 88.8246 191.325ZM88.8246 165.986C88.271 167.647 87.6759 169.557 87.1501 171.563C86.2782 174.898 84.4653 182.967 86.4858 185.983C86.8179 186.482 87.3853 187.063 88.8246 187.063C90.2639 187.063 90.8312 186.495 91.1634 185.983C91.8415 184.973 93.1285 181.555 90.4991 171.563C89.9732 169.57 89.3782 167.647 88.8246 165.986Z"
        fill={secondColor}
      />
      <path
        d="M37.6486 145.279C37.095 145.279 36.5553 145.072 36.1401 144.656L33.2893 141.819C32.4589 140.989 32.4589 139.633 33.2893 138.802C34.1196 137.972 35.4758 137.972 36.3062 138.802L39.157 141.653C39.9873 142.484 39.9873 143.84 39.157 144.67C38.7418 145.085 38.1883 145.293 37.6486 145.293"
        fill={secondColor}
      />
      <path
        d="M54.1858 194.075C53.6323 194.075 53.0925 193.868 52.6774 193.453L18.5227 159.298C17.6923 158.468 17.6923 157.111 18.5227 156.281C19.353 155.451 20.7092 155.451 21.5396 156.281L55.6943 190.436C56.5246 191.266 56.5246 192.622 55.6943 193.453C55.2791 193.868 54.7255 194.075 54.1858 194.075Z"
        fill={secondColor}
      />
      <path
        d="M90.7903 157.017C90.2368 157.017 89.697 156.809 89.2819 156.394L55.1272 122.239C54.2968 121.409 54.2968 120.053 55.1272 119.222C55.9575 118.392 57.3137 118.392 58.1441 119.222L92.2988 153.377C93.1291 154.207 93.1291 155.564 92.2988 156.394C91.8836 156.809 91.33 157.017 90.7903 157.017Z"
        fill={secondColor}
      />
      <path
        d="M67.9419 176.611C66.4888 176.611 65.1326 176.044 64.1085 175.034L63.209 174.134C61.1055 172.03 61.1055 168.598 63.209 166.481L74.3356 155.354C75.1659 154.524 76.5221 154.524 77.3525 155.354C78.1828 156.185 78.1828 157.541 77.3525 158.371L66.2259 169.498C65.7831 169.941 65.7831 170.674 66.2259 171.103L67.1254 172.003C67.4161 172.293 67.7482 172.335 67.9281 172.335C68.108 172.335 68.454 172.293 68.7308 172.003L88.3268 152.421C89.1572 151.59 90.5134 151.59 91.3576 152.421C92.1879 153.251 92.1879 154.607 91.3576 155.438L71.7615 175.034C70.7374 176.058 69.3812 176.611 67.9281 176.611"
        fill={secondColor}
      />
      <path
        d="M75.8576 158.996C75.304 158.996 74.7643 158.788 74.3491 158.373C73.5188 157.543 73.5188 156.187 74.3491 155.356C74.6397 155.066 74.6813 154.734 74.6813 154.554C74.6813 154.374 74.6397 154.042 74.3491 153.751L73.4496 152.851C73.159 152.561 72.8268 152.519 72.6469 152.519C72.467 152.519 72.121 152.561 71.8443 152.851C71.0139 153.682 69.6577 153.682 68.8273 152.851C67.997 152.021 67.997 150.665 68.8273 149.834C70.9309 147.731 74.3768 147.731 76.4803 149.834L77.3799 150.734C78.4039 151.758 78.9575 153.114 78.9575 154.567C78.9575 156.021 78.3901 157.377 77.3799 158.401C76.9647 158.816 76.4111 159.024 75.8714 159.024"
        fill={secondColor}
      />
      <path
        d="M61.9356 160.503C60.5517 160.503 59.1678 159.977 58.1022 158.925L57.2026 158.026C55.0991 155.922 55.0991 152.476 57.2026 150.373L71.6091 135.967C72.4394 135.136 73.7956 135.136 74.626 135.967C75.4563 136.797 75.4563 138.153 74.626 138.983L60.2196 153.39C59.7767 153.833 59.7767 154.566 60.2196 154.995L61.1191 155.895C61.5481 156.338 62.2954 156.338 62.7244 155.895L68.7998 149.819C69.6301 148.989 70.9863 148.989 71.8167 149.819C72.647 150.65 72.647 152.006 71.8167 152.836L65.7413 158.912C64.6896 159.963 63.3057 160.489 61.9079 160.489"
        fill={secondColor}
      />
      <path d="M214 2H108V108H214V2Z" fill={thirdColor} />
      <path
        d="M151.924 48.9402H120.731C119.555 48.9402 118.6 47.9853 118.6 46.809V30.6312C118.6 29.4549 119.555 28.5 120.731 28.5H151.924C153.101 28.5 154.056 29.4549 154.056 30.6312V46.809C154.056 47.9853 153.101 48.9402 151.924 48.9402ZM122.863 44.664H149.779V32.7624H122.863V44.664Z"
        fill={fourthColor}
      />
      <path
        d="M151.924 81.2937H120.731C119.555 81.2937 118.6 80.3389 118.6 79.1625V62.9847C118.6 61.8084 119.555 60.8535 120.731 60.8535H151.924C153.101 60.8535 154.056 61.8084 154.056 62.9847V79.1625C154.056 80.3389 153.101 81.2937 151.924 81.2937ZM122.863 77.0175H149.779V65.1021H122.863V77.0175Z"
        fill={fourthColor}
      />
      <path
        d="M183.049 81.2937H151.855C150.679 81.2937 149.71 80.3389 149.71 79.1625V62.9847C149.71 61.8084 150.665 60.8535 151.855 60.8535H183.049C184.225 60.8535 185.18 61.8084 185.18 62.9847V79.1625C185.18 80.3389 184.225 81.2937 183.049 81.2937ZM153.987 77.0175H180.904V65.1021H153.987V77.0175Z"
        fill={fourthColor}
      />
      <path
        d="M167.479 65.1182H136.286C135.109 65.1182 134.154 64.1633 134.154 62.9869V46.8091C134.154 45.6328 135.109 44.6641 136.286 44.6641H167.479C168.655 44.6641 169.624 45.619 169.624 46.8091V62.9869C169.624 64.1633 168.669 65.1182 167.479 65.1182ZM138.417 60.8419H165.334V48.9403H138.417V60.8419Z"
        fill={fourthColor}
      />
      <path
        d="M198.604 65.1182H167.41C166.234 65.1182 165.279 64.1633 165.279 62.9869V46.8091C165.279 45.6328 166.234 44.6641 167.41 44.6641H198.604C199.78 44.6641 200.735 45.619 200.735 46.8091V62.9869C200.735 64.1633 199.78 65.1182 198.604 65.1182ZM169.542 60.8419H196.459V48.9403H169.542V60.8419Z"
        fill={fourthColor}
      />
      <path d="M214 108H108V214H214V108Z" fill={fifthColor} />
      <path
        d="M161.401 173.374C160.847 173.374 160.308 173.167 159.892 172.752C155.492 168.351 152.738 162.525 152.129 156.339C151.589 150.831 152.765 145.254 155.45 140.465L146.026 131.041C143.189 128.204 143.189 123.582 146.026 120.731C147.41 119.347 149.236 118.6 151.188 118.6C153.139 118.6 154.966 119.361 156.35 120.731L165.774 130.155C170.562 127.47 176.139 126.294 181.647 126.834C187.833 127.443 193.66 130.197 198.06 134.598C198.891 135.428 198.891 136.784 198.06 137.614L162.937 172.738C162.522 173.153 161.968 173.361 161.429 173.361M151.201 122.862C150.399 122.862 149.638 123.18 149.07 123.748C147.894 124.924 147.894 126.834 149.07 128.01L159.671 138.611C160.391 139.33 160.501 140.438 159.948 141.296C157.152 145.586 155.893 150.775 156.391 155.91C156.834 160.393 158.619 164.67 161.484 168.129L193.438 136.175C189.978 133.31 185.702 131.525 181.218 131.082C176.084 130.57 170.894 131.844 166.604 134.639C165.76 135.193 164.639 135.082 163.92 134.362L153.319 123.762C152.751 123.194 151.99 122.876 151.188 122.876L151.201 122.862Z"
        fill={sixthColor}
      />
      <path
        d="M178.962 165.665C175.89 165.665 172.818 164.502 170.479 162.163C169.648 161.333 169.648 159.977 170.479 159.147C171.309 158.316 172.665 158.316 173.509 159.147C176.513 162.15 181.412 162.15 184.428 159.147C187.432 156.13 187.432 151.231 184.428 148.228C183.598 147.397 183.598 146.041 184.428 145.211C185.259 144.38 186.615 144.38 187.445 145.211C192.123 149.888 192.123 157.5 187.445 162.177C185.107 164.516 182.034 165.679 178.962 165.679"
        fill={sixthColor}
      />
      <path
        d="M150.219 207.155C149.444 207.155 148.696 206.726 148.309 205.993L129.432 168.904C129.059 168.17 129.142 167.285 129.64 166.634L152.973 135.995C153.692 135.054 155.035 134.874 155.962 135.593C156.903 136.313 157.083 137.655 156.363 138.596L133.833 168.17L152.101 204.069C152.64 205.121 152.211 206.408 151.16 206.947C150.855 207.113 150.523 207.183 150.191 207.183"
        fill={sixthColor}
      />
      <path d="M214 214H108V320H214V214Z" fill={thirdColor} />
      <path
        d="M181.498 271.133C181.046 271.133 180.605 270.964 180.266 270.625C175.126 265.485 168.28 262.649 161.005 262.649C153.73 262.649 146.884 265.485 141.744 270.625C141.066 271.303 139.959 271.303 139.281 270.625C138.603 269.947 138.603 268.84 139.281 268.162C145.087 262.355 152.803 259.158 161.005 259.158C169.207 259.158 176.934 262.355 182.74 268.162C183.418 268.84 183.418 269.947 182.74 270.625C182.402 270.964 181.95 271.133 181.509 271.133"
        fill={fourthColor}
      />
      <path
        d="M150.567 278.843C150.115 278.843 149.675 278.673 149.336 278.334C148.658 277.656 148.658 276.549 149.336 275.871C155.775 269.432 166.248 269.432 172.687 275.871C173.365 276.549 173.365 277.656 172.687 278.334C172.009 279.012 170.902 279.012 170.224 278.334C165.152 273.262 156.894 273.262 151.81 278.334C151.471 278.673 151.019 278.843 150.579 278.843"
        fill={fourthColor}
      />
      <path
        d="M191.789 262.07C191.338 262.07 190.897 261.9 190.558 261.561C182.661 253.665 172.166 249.327 161.016 249.327C149.866 249.327 139.36 253.676 131.463 261.561C130.785 262.239 129.678 262.239 129.001 261.561C128.323 260.884 128.323 259.776 129.001 259.099C137.552 250.547 148.917 245.836 161.016 245.836C173.115 245.836 184.48 250.547 193.032 259.099C193.71 259.776 193.71 260.884 193.032 261.561C192.693 261.9 192.241 262.07 191.801 262.07"
        fill={fourthColor}
      />
      <path
        d="M164.079 285.093C164.079 286.788 162.701 288.166 161.006 288.166C159.312 288.166 157.934 286.788 157.934 285.093C157.934 283.399 159.312 282.021 161.006 282.021C162.701 282.021 164.079 283.399 164.079 285.093Z"
        fill={fourthColor}
      />
    </svg>
  );
};
