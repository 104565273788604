import { IconProps } from "typing";
import { theme } from "../themes";

export const EyeClose = ({
  width = 32,
  height = 32,
  color = theme.colors.neutral["999"],
  alt = "Olho fechado",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 28 28"
      fill={color}
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.043 12.7576C14.2445 12.7412 14.4974 12.7172 14.6293 12.5937C15.2733 11.99 15.9022 11.3704 16.553 10.729C16.7689 10.5163 16.9872 10.3012 17.2093 10.0835C14.9679 8.5002 11.9395 8.82927 10.0601 10.78C8.24297 12.6664 7.9927 15.7043 9.53241 17.6828C9.74051 17.4757 9.9489 17.2694 10.1571 17.0634C10.7864 16.4407 11.4136 15.8199 12.0244 15.1835C12.1506 15.0522 12.176 14.7973 12.1927 14.595C12.2813 13.5087 12.9452 12.8491 14.043 12.7576ZM23.661 3.62183C24.1082 3.17786 24.5555 2.73381 25.002 2.28882L26.4874 3.88973C19.2845 11.0528 11.9736 18.3233 4.66445 25.5923L3.17641 24.0668C4.31344 22.9603 5.50214 21.8037 6.75118 20.5887C6.66145 20.5229 6.58493 20.4678 6.51733 20.4191C6.39269 20.3293 6.2984 20.2613 6.20763 20.1889C5.78707 19.8545 5.36593 19.5207 4.94478 19.187C3.61304 18.1316 2.28123 17.0762 0.967588 15.999C0.707098 15.7856 0.499519 15.5084 0.291947 15.2312C0.196352 15.1035 0.100759 14.9759 0 14.8545V14.4218C0.351763 14.0661 0.696128 13.7018 1.04049 13.3376C1.80132 12.5327 2.56215 11.7279 3.40272 11.0164C5.9539 8.85727 8.81365 7.2477 12.1869 6.71224C14.4691 6.35026 16.6643 6.67962 18.7652 7.59641C19.2755 7.81896 19.5665 7.73467 19.9421 7.35076C21.1689 6.0959 22.4146 4.85917 23.661 3.62183ZM22.296 10.8611C22.5737 10.5847 22.854 10.3058 23.1373 10.0231C24.9353 11.2744 26.5715 12.6554 28 14.2774V14.9987C24.9558 18.3711 21.474 21.092 16.9646 22.255C14.9959 22.7631 13.0209 22.781 10.8646 22.1892C11.053 22.0072 11.2352 21.8302 11.4131 21.6574C11.9521 21.1338 12.4514 20.6487 12.964 20.1784C13.0619 20.0889 13.279 20.0959 13.4369 20.1083C17.0535 20.3932 19.8154 17.6425 19.5567 14.0251C19.542 13.8207 19.6283 13.5479 19.7686 13.4036C20.5932 12.5554 21.4321 11.7207 22.296 10.8611Z"
        fill={color}
      />
      <mask
        id="mask0_0_2977"
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width={width}
        height={height}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.043 12.7576C14.2445 12.7412 14.4974 12.7172 14.6293 12.5937C15.2733 11.99 15.9022 11.3704 16.553 10.729C16.7689 10.5163 16.9872 10.3012 17.2093 10.0835C14.9679 8.5002 11.9395 8.82927 10.0601 10.78C8.24297 12.6664 7.9927 15.7043 9.53241 17.6828C9.74051 17.4757 9.9489 17.2694 10.1571 17.0634C10.7864 16.4407 11.4136 15.8199 12.0244 15.1835C12.1506 15.0522 12.176 14.7973 12.1927 14.595C12.2813 13.5087 12.9452 12.8491 14.043 12.7576ZM23.661 3.62183C24.1082 3.17786 24.5555 2.73381 25.002 2.28882L26.4874 3.88973C19.2845 11.0528 11.9736 18.3233 4.66445 25.5923L3.17641 24.0668C4.31344 22.9603 5.50214 21.8037 6.75118 20.5887C6.66145 20.5229 6.58493 20.4678 6.51733 20.4191C6.39269 20.3293 6.2984 20.2613 6.20763 20.1889C5.78707 19.8545 5.36593 19.5207 4.94478 19.187C3.61304 18.1316 2.28123 17.0762 0.967588 15.999C0.707098 15.7856 0.499519 15.5084 0.291947 15.2312C0.196352 15.1035 0.100759 14.9759 0 14.8545V14.4218C0.351763 14.0661 0.696128 13.7018 1.04049 13.3376C1.80132 12.5327 2.56215 11.7279 3.40272 11.0164C5.9539 8.85727 8.81365 7.2477 12.1869 6.71224C14.4691 6.35026 16.6643 6.67962 18.7652 7.59641C19.2755 7.81896 19.5665 7.73467 19.9421 7.35076C21.1689 6.0959 22.4146 4.85917 23.661 3.62183ZM22.296 10.8611C22.5737 10.5847 22.854 10.3058 23.1373 10.0231C24.9353 11.2744 26.5715 12.6554 28 14.2774V14.9987C24.9558 18.3711 21.474 21.092 16.9646 22.255C14.9959 22.7631 13.0209 22.781 10.8646 22.1892C11.053 22.0072 11.2352 21.8302 11.4131 21.6574C11.9521 21.1338 12.4514 20.6487 12.964 20.1784C13.0619 20.0889 13.279 20.0959 13.4369 20.1083C17.0535 20.3932 19.8154 17.6425 19.5567 14.0251C19.542 13.8207 19.6283 13.5479 19.7686 13.4036C20.5932 12.5554 21.4321 11.7207 22.296 10.8611Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_0_2977)">
        <rect fill={color} />
      </g>
    </svg>
  );
};
