import styled from "styled-components";
import { theme } from "ui/themes";

export const BoxQuantity = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const TitleBoxQuantity = styled.span`
  color: ${theme.colors.neutral["450"]};
  margin-bottom: 5px;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 500;
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectQuantity = styled.div`
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  gap: 1.2rem;
  align-items: center;
`;

export const TextUni = styled.span<{ capitalize?: boolean }>`
  font-size: 1rem;
  font-weight: bold;
  color: ${theme.colors.neutral["520"]};
  text-transform: ${(capitalize) => (capitalize ? "capitalize" : "lowercase")};
`;
