import styled, { css } from "styled-components";
import { FlexContainer, theme, WhatsappIcon } from "ui";
import { ButtonSubmit } from "../ButtonSubmit";

const WhatsAppOptionContainer = styled(FlexContainer).attrs({
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.space.x2,
})<{ hasBackground: boolean }>`
  padding: ${theme.space.x4} ${theme.space.x0};
  ${({ hasBackground }) =>
    !hasBackground &&
    css`
      padding: ${theme.space.x4};
      background-color: ${theme.colors.neutral["white-075"]};
    `}
`;

const WhatsAppImage = styled(WhatsappIcon)``;

const SaveDataButton = styled(ButtonSubmit).attrs({
  fontSize: theme.typography.fontSize.sm.x5,
  height: "4rem",
  type: "button",
})`
  padding: ${theme.space.x0} ${theme.space.x4};
  transition: background-color 0.2s ease-in;
  align-self: flex-start;
`;

const WhatsAppForm = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.space.x2};

  @media ${theme.device.desktopFirst.mobileXL} {
    gap: ${theme.space.x2};
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-self: end;
`;

export {
  WhatsAppOptionContainer,
  WhatsAppImage,
  SaveDataButton,
  WhatsAppForm,
  CheckBoxContainer,
};
