import { IconProps } from "typing";
import { theme } from "../themes";

export const FailureIcon = ({
  alt = "Ícone de Alerta",
  width = 24,
  height = 25,
  color = theme.colors.primary["150"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.029 4.63919L21.688 18.0432C22.578 19.6012 21.453 21.5402 19.659 21.5402H4.34101C2.54601 21.5402 1.42101 19.6012 2.31201 18.0432L9.97101 4.63919C10.868 3.06819 13.132 3.06819 14.029 4.63919Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M12 13.6199V9.87988"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_3"
            d="M11.999 16.625C11.861 16.625 11.749 16.737 11.75 16.875C11.75 17.013 11.862 17.125 12 17.125C12.138 17.125 12.25 17.013 12.25 16.875C12.25 16.737 12.138 16.625 11.999 16.625"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
