import { IconProps } from "typing";
import { theme } from "../themes";

export const UnavailableIcon = ({
  width = 28,
  height = 28,
  color = theme.colors.primary["200"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5027 16.6343L21.0038 19.1353"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0038 16.6343L18.5027 19.1353"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5002 12.0673V8.99902"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5002 15.6777C12.4574 15.6777 12.4164 15.6948 12.3863 15.7253C12.3562 15.7557 12.3396 15.797 12.3401 15.8398C12.3407 15.9283 12.4117 16.0002 12.5002 16.0018C12.5897 16.0018 12.6623 15.9293 12.6623 15.8398C12.6623 15.7503 12.5897 15.6777 12.5002 15.6777Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5496 13.5187L15.8206 5.39727C15.1307 4.21768 13.8667 3.49268 12.5002 3.49268C11.1337 3.49268 9.86974 4.21768 9.17983 5.39727L3.47745 15.1793C2.83852 16.2785 2.8374 17.6357 3.47454 18.7359C4.11167 19.8361 5.28939 20.5107 6.56073 20.5036H15.5375"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
