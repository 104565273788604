import { IconProps } from "typing";
import { theme } from "../themes";

export const ShoppingBagIcon = ({
  height = 20,
  width = 20,
  color = theme.colors.neutral["760"],
  alt = "ícone de sacola",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0021 17.5016H4.99793C4.55568 17.5017 4.13151 17.3261 3.81879 17.0134C3.50607 16.7007 3.33057 16.2765 3.33057 15.8342V7.0806C3.33082 6.39006 3.89055 5.83033 4.58109 5.83008H15.4189C16.1095 5.83033 16.6692 6.39006 16.6695 7.0806V15.8342C16.6695 16.2765 16.4939 16.7007 16.1812 17.0134C15.8685 17.3261 15.4443 17.5017 15.0021 17.5016Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2964 11.0195L9.67492 12.641L8.70264 11.6677"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66553 5.83082V5.6224V5.6224C6.66553 3.8958 8.06524 2.49614 9.79183 2.49609H10.2087C11.9353 2.49614 13.335 3.8958 13.335 5.6224V5.6224V5.83082"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
