import { buildTestIds } from "application";
import { useTwoFactor } from "application/state-manager";
import { useShallow } from "application/state-manager/adapter";
import { useCallback, useMemo } from "react";
import { OnClickIsInvalidClientInfo, SendCodeType } from "typing";
import { maskEmail, maskPhoneNumber } from "../../useTwoFactorContent";
import {
  ClientInfoContent,
  DescriptionContainer,
  InfoValue,
  InvalidClientInfo,
} from "./styles";

type InfoValueElementProps = {
  value: string | null;
};

const InfoValueElement = ({ value }: InfoValueElementProps) => {
  if (!value) {
    return null;
  }

  return <InfoValue {...buildTestIds("info-value")}>{value}.</InfoValue>;
};

type TwoFactorDescriptionTextProps = {
  sendCodeType: SendCodeType;
  onClickIsInvalidClientInfo?: OnClickIsInvalidClientInfo;
  marginDescription?: string;
  shouldMask?: boolean;
};

const TwoFactorDescriptionText = ({
  sendCodeType,
  onClickIsInvalidClientInfo,
  marginDescription,
  shouldMask,
}: TwoFactorDescriptionTextProps) => {
  const { email, phoneNumber, clientAction, resetInfo } = useTwoFactor(
    useShallow((state) => ({
      email: state.email,
      phoneNumber: state.phoneNumber,
      clientAction: state.clientAction,
      resetInfo: state.resetInfo,
    }))
  );

  const handleClickIsInvalidClientInfo = useCallback(() => {
    if (onClickIsInvalidClientInfo) {
      onClickIsInvalidClientInfo(clientAction, sendCodeType);
      resetInfo();
    }
  }, [clientAction, onClickIsInvalidClientInfo, resetInfo, sendCodeType]);

  const getSendCodeTypeInfos = useCallback(
    (
      passedSendCodeType: SendCodeType,
      passedEmail: string,
      passedPhoneNumber: string
    ) => {
      if (passedSendCodeType === "email") {
        return {
          text: "e-mail",
          auxText: "enviamos um código de 6 dígitos para o e-mail",
          value: shouldMask ? maskEmail(passedEmail) : passedEmail,
        };
      }

      if (passedSendCodeType === "sms") {
        return {
          text: "número",
          auxText: "enviamos um código de 6 dígitos para o número",
          value: shouldMask
            ? maskPhoneNumber(passedPhoneNumber)
            : passedPhoneNumber,
        };
      }

      return {
        text: "app autenticador",
        auxText: "Preencha o código informado no seu aplicativo autenticador",
        value: null,
      };
    },
    [shouldMask]
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const clientActionInfos = useMemo(() => {
    if (!clientAction) {
      return null;
    }

    const { text, auxText, value } = getSendCodeTypeInfos(
      sendCodeType,
      email,
      phoneNumber
    );

    const invalidValueText =
      sendCodeType !== "app" ? `Não é seu ${text}?` : null;

    if (clientAction === "createUser") {
      const formattedText =
        sendCodeType !== "app"
          ? `Para finalizar seu cadastro, ${auxText}`
          : `${auxText}`;

      return {
        content: (
          <>
            {formattedText}
            <InfoValueElement value={value as string} />
          </>
        ),
        invalidValueText,
      };
    }

    if (clientAction === "forgotPassword") {
      const formattedText =
        sendCodeType !== "app"
          ? `Digite abaixo o código recebido no ${text}`
          : `${auxText}`;

      return {
        content: (
          <>
            {formattedText}
            <InfoValueElement value={value as string} />
          </>
        ),
        invalidValueText: null,
      };
    }

    if (clientAction === "changePassword") {
      const formattedText =
        sendCodeType !== "app"
          ? `Para garantir a sua segurança, enviamos um código de 6 dígitos para o seu ${text}`
          : `${auxText}`;
      return {
        content: (
          <>
            {formattedText}
            <InfoValueElement value={value as string} />
          </>
        ),
        invalidValueText: null,
      };
    }

    if (
      [
        "validateIdentity",
        "changePhoneNumber",
        "changeEmail",
        "loginValidation",
      ].includes(clientAction)
    ) {
      const formattedText =
        sendCodeType !== "app"
          ? `Para garantir a sua segurança, ${auxText}`
          : `${auxText}`;

      return {
        content: (
          <>
            {formattedText}
            <InfoValueElement value={value as string} />
          </>
        ),
        invalidValueText:
          clientAction === "changePhoneNumber" ||
          clientAction === "changeEmail" ||
          clientAction === "loginValidation"
            ? null
            : invalidValueText,
      };
    }

    if (["verifyIdentity", "registerPhoneNumber"]?.includes(clientAction)) {
      const formattedText =
        sendCodeType !== "app"
          ? `Digite o código enviado para o ${text}`
          : `${auxText}`;

      return {
        content: (
          <>
            {formattedText}
            <InfoValueElement value={value as string} />
          </>
        ),
        invalidValueText: null,
      };
    }

    if (clientAction === "createAddress") {
      const formattedText =
        sendCodeType !== "app"
          ? `Para adicionar o novo endereço, ${auxText}`
          : `${auxText}`;

      return {
        content: (
          <>
            {formattedText}
            <InfoValueElement value={value as string} />
          </>
        ),
        invalidValueText,
      };
    }

    if (
      ["validateIdentity", "requestExclusionAccount"].includes(clientAction)
    ) {
      const formattedText =
        sendCodeType !== "app"
          ? `Para garantir a sua segurança, ${auxText}`
          : `${auxText}`;

      return {
        content: (
          <>
            {formattedText}
            <InfoValueElement value={value} />
          </>
        ),
        invalidValueText: null,
      };
    }

    return {
      content: (
        <>
          {auxText ? auxText.charAt(0).toUpperCase() + auxText.slice(1) : ""}
          <InfoValueElement value={value} />
        </>
      ),
    };
  }, [clientAction, email, getSendCodeTypeInfos, phoneNumber, sendCodeType]);

  if (!clientActionInfos) {
    return null;
  }

  return (
    <DescriptionContainer
      marginDescription={marginDescription}
      {...buildTestIds("two-factor-content-description-container")}
    >
      <ClientInfoContent>{clientActionInfos.content}</ClientInfoContent>
      {clientActionInfos.invalidValueText && (
        <InvalidClientInfo
          {...buildTestIds("invalid-client-info")}
          onClick={handleClickIsInvalidClientInfo}
        >
          {clientActionInfos.invalidValueText}
        </InvalidClientInfo>
      )}
    </DescriptionContainer>
  );
};

export { TwoFactorDescriptionText };
