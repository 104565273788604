export const getBranchAddress = (branchIdAdress: string) => {
  switch (branchIdAdress) {
    case "1":
      return "Av. Santo Antônio, 515 · Centro, Garanhuns - PE, CEP 55293-909";
    case "2":
      return "Av. Mal. Mascarenhas De Morais, nº 2929/2967 · Imbiribeira, Recife, PE, CEP 51150-905";
    case "3":
      return "Av. Luís Viana Filho, 6180 · Paralela, Salvador - BA, CEP 41730-101";
    case "4":
      return "R. Cônego Barata, 275 · Tamarineira, Recife - PE, CEP 52051-020";
    case "5":
      return "Av. Pres. Tancredo Neves, 2695 · Inácio Barbosa, Aracaju - SE, CEP 49040-490";
    case "6":
      return "R. Edgar Sáles Miranda Henriques, 345 · Lot. Morada Nova, Cabedelo - PB, CEP 58310-000";
    case "7":
      return "Av. Engenheiro Roberto Freire, 142 · Capim Macio, Natal - RN, CEP 59078-600";
    case "8":
      return "Av. dos Estados, 129 · Nova Caruaru, Caruaru - PE, CEP 55014-560";
    case "9":
      return "Av. Vale dos Barris, 125 · Barris, Salvador - BA, CEP 40070-055";
    case "92":
      return "BR-101, 30 · Distrito Industrial Santo Estevão, Cabo de Santo Agostinho - PE";
    case "93":
      return "R. Nossa Sra. da Assunção, 169 · Alhandra - PB, CEP 58320-000";

    default:
      return "Av. Mal. Mascarenhas De Morais, nº 2929/2967 · Imbiribeira, Recife, PE, CEP 51150-905";
  }
};
