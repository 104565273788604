import { IAppMonitoringClient } from "app-domain";

import { handleRefreshUserAccessToken } from "./refresh-token";

const defaultHeaders: RequestInit["headers"] = {
  saleChannel: "2",
  version: "1.28.0",
};

type EventErrorDetails = {
  defaultErrorMessage: string;
  event: string;
  errorSource: string;
  authorization?: string;
};

const handleResponseError = (
  error: unknown,
  appMonitoringClient: IAppMonitoringClient,
  { event, errorSource }: Omit<EventErrorDetails, "defaultErrorMessage">
) => {
  appMonitoringClient.captureException(error, {
    level: "error",
    tags: {
      fcx_labs_event: event,
      fcx_labs_error_source: errorSource,
    },
  });
};

const handleResponse = async (
  fetchResponse: Response,
  appMonitoringClient: IAppMonitoringClient,
  {
    defaultErrorMessage,
    event,
    errorSource,
    authorization = "",
  }: EventErrorDetails
) => {
  try {
    let responseAsJson;

    if (fetchResponse.status !== 204) {
      responseAsJson = await fetchResponse.json();
    }

    if (!fetchResponse?.ok) {
      handleResponseError(responseAsJson, appMonitoringClient, {
        event,
        errorSource,
        authorization,
      });
    }

    return { status: fetchResponse.status, response: responseAsJson };
  } catch {
    handleResponseError(defaultErrorMessage, appMonitoringClient, {
      event,
      errorSource,
      authorization,
    });

    return {
      status: fetchResponse.status,
      response: {
        errors: {
          Messages: [defaultErrorMessage],
        },
      },
    };
  }
};

export type DoRequestAgainProps = {
  retryCount: number;
  [key: string]: string | number | DoRequestAgainProps;
};

type OnErrorRetryProps = {
  url: string;
  retryCount: number;
  retryCountThreshold?: number;
  status?: number;
  doRequestAgain: (props: DoRequestAgainProps) => void;
  doRequestAgainTimeout?: number;
};

const handleOnErrorRetry = ({
  retryCount,
  doRequestAgain,
  status,
  retryCountThreshold = 4,
  doRequestAgainTimeout = 5000,
}: OnErrorRetryProps) => {
  if (retryCount >= retryCountThreshold) return;

  if (status === 500) return;

  if (/5[0-9][0-9]/.test(String(status)) && retryCount >= 2) {
    return;
  }

  if (status === 401 && retryCount >= 2) {
    return;
  }

  setTimeout(() => {
    doRequestAgain({ retryCount });
  }, doRequestAgainTimeout);
};

const getRequestPath = (input: RequestInfo) => {
  return String(input)?.split("?")?.[0] || "";
};

export {
  defaultHeaders,
  getRequestPath,
  handleOnErrorRetry,
  handleRefreshUserAccessToken,
  handleResponse,
  handleResponseError,
};
