import {
  buildTestIds,
  formatCurrency,
  formatStringUrl,
} from "application/utils";
import { useRouter } from "next/router";
import { SuggestedProductCardDto } from "typing";
import { Text } from "ui";
import { Link } from "../../../../../../../Link";
import {
  SuggestedCardContainer,
  SuggestedCardDescription,
  SuggestedCardImage,
  SuggestedCardImageBox,
  SuggestedCardInfo,
  SuggestedCardPrice,
} from "./styles";

interface CardSuggestedProductProps {
  suggestedProduct: SuggestedProductCardDto;
  afterClick: (searchTerm: string) => void;
}

const SuggestedProductCard = ({
  suggestedProduct,
  afterClick,
}: CardSuggestedProductProps) => {
  const router = useRouter();
  const isProductScreen = router?.pathname?.includes("produto");

  return (
    <SuggestedCardContainer
      onClick={() => {
        afterClick(suggestedProduct.description.replace(/[\\/\\-]+/g, " "));
      }}
      as={isProductScreen ? "a" : Link}
      href={`/produto/${suggestedProduct.id}/${formatStringUrl(
        suggestedProduct.description
      )}`}
      target="_self"
      {...buildTestIds("suggested-card-container")}
    >
      <SuggestedCardImageBox {...buildTestIds("suggested-card-image-box")}>
        {suggestedProduct?.imageUrl && (
          <SuggestedCardImage
            width="72px"
            height="72px"
            layout="fixed"
            src={suggestedProduct.imageUrl}
            alt={suggestedProduct?.description}
          />
        )}
      </SuggestedCardImageBox>
      <SuggestedCardInfo {...buildTestIds("suggested-card-info")}>
        <SuggestedCardDescription
          {...buildTestIds("suggested-card-description")}
        >
          <Text
            {...buildTestIds("suggested-card-description-text")}
            fontSize="1.1rem"
          >
            {suggestedProduct?.description} - {suggestedProduct.id}
          </Text>
        </SuggestedCardDescription>
        <SuggestedCardPrice {...buildTestIds("suggested-card-price")}>
          <Text
            {...buildTestIds("suggested-card-price-text")}
            fontSize="1.1rem"
            fontWeight="bold"
            margin="15px 0 0"
          >
            {formatCurrency(Number(suggestedProduct.price))}
          </Text>
        </SuggestedCardPrice>
      </SuggestedCardInfo>
    </SuggestedCardContainer>
  );
};

export { SuggestedProductCard };
