const redirectBasedOnProtectedRoutes = (protectedRoutes: string[]) => {
  const shouldRedirectToLogin = protectedRoutes.some((route) =>
    window?.location?.pathname?.includes(route)
  );

  if (shouldRedirectToLogin) {
    window?.location?.replace(
      `/login?redirectTo=${window?.location?.pathname}`
    );
  } else {
    window?.location?.reload();
  }
};

export { redirectBasedOnProtectedRoutes };
