import styled from "styled-components";
import { theme } from "ui";

interface RecommendationTypeBadgeContainerProps {
  recommendationType?: string;
}
export const RecommendationTypeBadgeContainer = styled.div<RecommendationTypeBadgeContainerProps>`
  width: 104px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.typography.fontSize.sm.x1};
  font-weight: ${theme.typography.fontWeight["400"]};
  border-radius: 0 0 8px 8px;
  overflow: hidden;
`;
