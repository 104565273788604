import styled, { css } from "styled-components";
import { theme } from "../themes";

type SpanValue =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

const getWidthString = (span?: SpanValue) => {
  if (!span) {
    return null;
  }

  const width = (Number(span) / 12) * 100;

  return `width: ${width}%;`;
};

export interface ColumnProps {
  display?: string;
  flexDirection?: string;
  flexWrap?: string;
  justifyContent?: string;
  alignItems?: string;
  padding?: string;
  xs?: SpanValue;
  sm?: SpanValue;
  md?: SpanValue;
  lg?: SpanValue;
  isPayment?: boolean;
}

export const Column = styled.div<ColumnProps>`
  ${({ display }) => display && `display: ${display};`}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  padding: ${({ padding }) => padding || theme.space.x0};
  float: left;
  ${({ xs }) => (xs ? getWidthString(xs) : "width: 100%")};

  @media only screen and (min-width: 768px) {
    ${({ sm }) => sm && getWidthString(sm)};
  }

  @media only screen and (min-width: 992px) {
    ${({ md }) => md && getWidthString(md)};
  }

  @media only screen and (min-width: 1200px) {
    ${({ lg }) => lg && getWidthString(lg)};
  }

  ${({ isPayment }) =>
    isPayment &&
    css`
      @media ${theme.device.desktopFirst.mobileXL} {
        justify-content: space-between;
      }
    `}
`;
