import { ReactNode } from "react";
import { BadgeContainer, BadgeText } from "./styles";

interface BadgeProps {
  icon: ReactNode;
  text: string;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  padding?: string;
  margin?: string;
  borderRadius?: string;
  border?: string;
  width?: string;
  height?: string;
  gap?: string;
  ariaLabel?: string;
  ariaLabelledby?: string;
  onClick?: () => void;
}

const Badge = ({
  icon,
  text,
  color,
  backgroundColor,
  border,
  borderRadius,
  fontSize,
  fontWeight,
  height,
  margin,
  padding,
  width,
  gap,
  ariaLabel = "Badge",
  ariaLabelledby = "Badge",
  onClick,
}: BadgeProps) => {
  return (
    <BadgeContainer
      padding={padding}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      margin={margin}
      width={width}
      height={height}
      border={border}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      gap={gap}
      onClick={onClick}
    >
      {icon}
      <BadgeText
        aria-label={text}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
      >
        {text}
      </BadgeText>
    </BadgeContainer>
  );
};

export { Badge };
