import { IconProps } from "typing";
import { theme } from "../themes";

export const SearchBagIcon = ({
  height = 24,
  width = 24,
  color = theme.colors.primary["155"],
  alt = "Sacola de compras com uma lupa",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.0017"
        cy="5.99727"
        r="4.00167"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.004 10.9996L18.8311 8.82666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.002 21.0035H18.0028C19.6603 21.0035 21.004 19.6598 21.004 18.0022V15.001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99609 9.99908V6.99783C2.99609 5.34029 4.3398 3.99658 5.99734 3.99658H7.99818"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99707 15.668C5.99707 16.773 6.89287 17.6688 7.9979 17.6688C9.10293 17.6688 9.99874 16.773 9.99874 15.668"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.99609"
        y="13"
        width="10.0042"
        height="8.00333"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
