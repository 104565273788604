import { IconProps } from "typing";
import { theme } from "../themes";

export const Check = ({
  width = "44",
  height = "44",
  color = theme.colors.secondary["265"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M32 13L18 32L12 25.5"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
