// import { IStateManager } from "../../entities";
import { createStore } from "../adapter";

type CrmState = {
  userCrmId: string | null;
};

type CrmDispatch = {
  setUserCrmId: (userCrmId?: string | null) => void;
};

type CrmProps = CrmState & CrmDispatch;

const useCrm = createStore<CrmProps>((set, get) => ({
  userCrmId: null,
  setUserCrmId: (userCrmId) => {
    if (get().userCrmId !== userCrmId) {
      set({ userCrmId });
    }
  },
}));

// type StateCallback<T> = (state: CrmProps) => T;

// let crmStore: <T>(state: StateCallback<T>) => T;

// let crmStore: CrmProps | undefined;

// const useCrm = (stateManager: IStateManager) => {
//   const createCrmStore = (usedStateManager: IStateManager) => {
//     return usedStateManager.createStore<CrmProps, CrmState>((set) => ({
//       userId: "",
//       userEmail: "",
//       setUserId: (userId: string) => {
//         set({
//           userId,
//         });
//       },
//       setUserEmail: (userEmail: string) => set({ userEmail }),
//     }));
//   };

//   if (crmStore) {
//     return crmStore;
//   }

//   crmStore = createCrmStore(stateManager) as <T>(state: StateCallback<T>) => T;

//   Object.freeze(crmStore);

//   return crmStore;
// };

export { useCrm };
