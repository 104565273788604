import { IconProps } from "typing";
import { theme } from "../themes";

export const CancellationIcon = ({
  width = 50,
  height = 50,
  color = theme.colors.neutral["520"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 6.66675L13.3337 13.3334"
        stroke={color}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3337 6.66675L6.66699 13.3334"
        stroke={color}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
