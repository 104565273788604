import styled from "styled-components";
import { ArrowLeft, FlexContainer, theme } from "ui";

export const TwoFactorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 380px;
  width: 100%;

  @media ${theme.device.desktopFirst.mobileXL} {
    max-width: 100%;
    padding: 0 24px 24px;
  }
`;

export const ContentInformation = styled(FlexContainer)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.space["x2.5"]};
  align-items: center;
  justify-content: center;
  height: 32px;
  margin-bottom: 24px;

  @media ${theme.device.desktopFirst.mobileXL} {
    align-items: flex-start;
    margin-bottom: 8px;
  }
`;

export const ArrowLeftIcon = styled(ArrowLeft).attrs({
  height: "24px",
  width: "24px",
  color: theme.colors.neutral["600"],
})``;

export const WrapperIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-12"]};
`;

export const VerifyCodeText = styled.span`
  color: ${theme.colors.neutral["550"]} !important;
  font-size: ${theme.typography.fontSize.sm.x9};
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight.bold};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x7};
    color: ${theme.colors.neutral["750"]};
  }
`;
