import styled from "styled-components";
import { theme } from "ui";

export const CategoryContainer = styled.article`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  align-items: center;
`;

export const TagNew = styled.div`
  display: flex;
  justify-self: flex-end;
  border-radius: ${theme.space.x3};
  padding: ${theme.space.x1} ${theme.space.x2};
  color: ${theme.colors.neutral.white};
  font-weight: ${theme.typography.fontWeight[500]};
  font-size: ${theme.typography.fontSize.sm.x3};
  letter-spacing: -0.36px;
  background-color: ${theme.colors.secondary["380"]};
  margin-right: 1rem;
`;
