import styled, { css } from "styled-components";
import { theme } from "../themes";

export interface IconWrapperProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  color?: string;
  mouseOverColor?: string;
  clickable?: boolean;
  marginRight?: string;
  lineHeight?: string;
  isBlackTheme?: boolean;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ width }) => width || theme.space.x16};
  max-width: ${({ width }) => width || theme.space.x16};
  min-height: ${({ height }) => height || theme.space.x16};
  max-height: ${({ height }) => height || theme.space.x16};
  margin-right: ${({ marginRight }) => marginRight || "20px"};
  border-radius: ${({ borderRadius }) => borderRadius || theme.space["x1/1"]};
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  background-color: ${({ color }) => color || theme.colors.secondary["300"]};

  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  @media ${theme.device.desktopFirst.laptop} {
    min-width: ${({ width }) =>
      width ? `${Number(width.replace(/\D/g, "")) / 1.25}px` : theme.space.x10};
    max-width: ${({ width }) =>
      width ? `${Number(width.replace(/\D/g, "")) / 1.25}px` : theme.space.x10};
    min-height: ${({ height }) =>
      height
        ? `${Number(height.replace(/\D/g, "")) / 1.25}px`
        : theme.space.x10};
    max-height: ${({ height }) =>
      height
        ? `${Number(height.replace(/\D/g, "")) / 1.25}px`
        : theme.space.x10};
    margin-right: 10px;
  }

  svg > path:hover {
    fill: ${({ mouseOverColor, color }) =>
      mouseOverColor || color || theme.colors.secondary["300"]};
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      svg *:hover {
        fill: ${theme.colors.neutral.white} !important;
      }

      @media ${theme.device.desktopFirst.laptop} {
        margin-right: 4px;
      }
    `}
`;
