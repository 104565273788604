import { IconProps } from "typing";
import { theme } from "../themes";

export const Coinpig = ({
  alt = "Cofrinho",
  width = 32,
  height = 32,
  color = theme.colors.neutral["999"],
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M13.9749 4.02513C15.3417 5.39197 15.3417 7.60804 13.9749 8.97488C12.6081 10.3417 10.392 10.3417 9.02515 8.97488C7.65831 7.60804 7.65831 5.39197 9.02515 4.02513C10.392 2.65829 12.6081 2.65829 13.9749 4.02513"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0325 11.9675C17.0505 11.9854 17.0505 12.0146 17.0325 12.0325C17.0145 12.0505 16.9854 12.0505 16.9674 12.0325C16.9495 12.0146 16.9495 11.9854 16.9674 11.9675C16.9854 11.9495 17.0145 11.9495 17.0325 11.9675"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.98 6.859C15.306 6.775 15.619 6.652 15.902 6.47C16.363 6.173 16.91 6 17.5 6H18.5V8.554C19.218 9.202 19.788 10.01 20.126 10.935H21C21.552 10.935 22 11.383 22 11.935V15C22 15.552 21.552 16 21 16H19.689C19.164 16.908 18.406 17.664 17.5 18.191V20C17.5 20.552 17.052 21 16.5 21H14.5C13.948 21 13.5 20.552 13.5 20V19H10V19.868C10 20.42 9.552 20.868 9 20.868H7C6.448 20.868 6 20.42 6 19.868V17.463C4.775 16.365 4 14.775 4 13C4 10.352 5.718 8.109 8.098 7.313"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.288 10C1.819 10.273 1.5 10.776 1.5 11.358C1.5 12.229 2.206 12.935 3.077 12.935H4"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 10H14.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
