import { buildTestIds } from "application";
import { useShallow, useTwoFactor } from "application/state-manager";
import { theme } from "ui";
import { TwoFactorContentBodyButtonSubmit } from "../styles";

type TwoFactorContentBodyButtonProps = {
  height?: string;
  borderRadius?: string;
  preventDefault?: boolean;
  handleFinishTwoFactorAction: () => void;
};

const TwoFactorContentBodyButton = ({
  height,
  borderRadius,
  handleFinishTwoFactorAction,
  preventDefault,
}: TwoFactorContentBodyButtonProps) => {
  const { isCodeComplete, isUnderAction } = useTwoFactor(
    useShallow((state) => ({
      isCodeComplete: state.isCodeComplete,
      isUnderAction: state.isUnderAction,
    }))
  );

  return (
    <TwoFactorContentBodyButtonSubmit
      {...buildTestIds("two-factor-content-body-button")}
      text="Continuar"
      height={height}
      borderRadius={borderRadius}
      buttonType="submit"
      preventDefault={preventDefault}
      textUnderAction="Aguarde..."
      underAction={isUnderAction}
      onClickAction={handleFinishTwoFactorAction}
      isDisabled={!isCodeComplete || isUnderAction}
      fontWeight={theme.typography.fontWeight["400"]}
      color={`${theme.colors.secondary["380"]}`}
      hoveredColor={`${theme.colors.secondary["350"]}`}
    />
  );
};

export { TwoFactorContentBodyButton };
