import { DeviceCheck } from "ui";
import { buildTestIds } from "application";
import { Fragment } from "react";
import { ModalGeneral } from "../ModalGeneral";
import { ButtonCloseModalConfirmedEmail } from "./styles";

interface ModalConfirmedEmailProps {
  isOpen: boolean;
  ariaHideApp?: boolean;
  isPasswordWeak?: boolean;
  onCloseModal(): void;
  onClickAction?: () => void;
}

const ModalConfirmedEmail = ({
  isOpen,
  onCloseModal,
  ariaHideApp,
  isPasswordWeak,
  onClickAction,
}: ModalConfirmedEmailProps) => {
  return (
    <ModalGeneral
      isOpen={isOpen}
      ariaHideApp={ariaHideApp}
      onCloseModal={onCloseModal}
      ariaLabelForModal="E-mail confirmado"
      title="E-mail confirmado"
      bodyDescription={
        isPasswordWeak
          ? "Você está quase lá. Seu e-mail já foi confirmado, agora está na hora de atualizar sua senha para garantir a sua segurança."
          : "Seu e-mail foi confirmado com sucesso! Agora você pode aproveitar as ofertas em nosso site com segurança."
      }
      illustration={<DeviceCheck />}
      bodyContent={
        <Fragment key="body-content">
          {isPasswordWeak ? (
            <ButtonCloseModalConfirmedEmail
              {...buildTestIds("button-close-confirmed-email")}
              onClick={onClickAction}
            >
              Garantir minha segurança
            </ButtonCloseModalConfirmedEmail>
          ) : (
            <ButtonCloseModalConfirmedEmail
              {...buildTestIds("button-close-confirmed-email")}
              onClick={onCloseModal}
            >
              Fechar
            </ButtonCloseModalConfirmedEmail>
          )}
        </Fragment>
      }
    />
  );
};

export type { ModalConfirmedEmailProps };

export { ModalConfirmedEmail };
