import { StepName } from "typing";
import { createStore } from "../adapter";

type AuthenticationState = {
  isAuthenticationDropdownActive: boolean;
  isAuthenticationModalActive: boolean;
  currentStep: StepName;
  userIdentification: string;
  identificationType: string;
  isChangePasswordDueToPurchase: boolean;
};

type AuthenticationDispatch = {
  setIsAuthenticationDropdownActive: (value: boolean) => void;
  setIsAuthenticationModalActive: (value: boolean) => void;
  changeCurrentStep: (value: StepName) => void;
  closeAuthenticationModal: () => void;
  setUserIdentification: (value: string) => void;
  setIdentificationType: (value: string) => void;
  setChangePasswordDueToPurchase: (value: boolean) => void;
};

type AuthenticationProps = AuthenticationState & AuthenticationDispatch;

const useAuthentication = createStore<AuthenticationProps>((set) => ({
  isAuthenticationDropdownActive: false,
  isAuthenticationModalActive: false,
  setIsAuthenticationDropdownActive: (value) => {
    set({
      isAuthenticationDropdownActive: value,
      ...(!value && { currentStep: "login" }),
    });
  },
  currentStep: "login",
  changeCurrentStep: (value) => {
    set({
      currentStep: value,
    });
  },
  closeAuthenticationModal: () =>
    set({
      currentStep: "login",
      isAuthenticationDropdownActive: false,
      isAuthenticationModalActive: false,
    }),
  setIsAuthenticationModalActive: (value) => {
    set({
      isAuthenticationModalActive: value,
      ...(!value && { currentStep: "login" }),
    });
  },
  userIdentification: "",
  setUserIdentification: (value) => set({ userIdentification: value }),
  identificationType: "email",
  setIdentificationType: (value) => set({ identificationType: value }),
  isChangePasswordDueToPurchase: false,
  setChangePasswordDueToPurchase: (value) =>
    set({ isChangePasswordDueToPurchase: value }),
}));

export { useAuthentication };
