import { IconProps } from "typing";
import { theme } from "../themes";

export const EmailIcon = ({
  width = 32,
  height = 32,
  color = theme.colors.secondary["380"],
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00049 9.06797V9.06797C4.00049 9.86797 4.40049 10.5346 5.06715 11.068L13.0672 16.5346C14.9338 17.7346 17.2005 17.7346 19.0672 16.5346L27.0672 11.2013C27.6005 10.5346 28.0005 9.86797 28.0005 9.06797V9.06797C28.0005 7.73464 26.9338 6.66797 25.6005 6.66797H6.40049C5.06715 6.66797 4.00049 7.73464 4.00049 9.06797Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00049 9.33496V22.6683C4.00049 24.135 5.20049 25.335 6.66716 25.335H25.3338C26.8005 25.335 28.0005 24.135 28.0005 22.6683V9.33496"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78467 24.5511L12.91 16.4258"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.147 16.4814L27.2163 24.5508"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
