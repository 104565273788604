import { buildTestIds } from "application";
import {
  NoticeConfirmationEmailContainer,
  CheckmarkCircleNoticeConfirmationEmailWrapper,
  CheckmarkCircleNoticeConfirmationEmail,
  TextNoticeConfirmationEmail,
} from "./styles";

interface NoticeConfirmationEmailProps {
  type?: "success";
  message: string;
}

const NoticeConfirmationEmail = ({
  message,
  type = "success",
}: NoticeConfirmationEmailProps) => {
  return (
    <NoticeConfirmationEmailContainer
      {...buildTestIds("notice-confirmation-email-container")}
    >
      <CheckmarkCircleNoticeConfirmationEmailWrapper
        {...buildTestIds("checkmark-circle-notice-confirmation-email-wrapper")}
      >
        <CheckmarkCircleNoticeConfirmationEmail />
      </CheckmarkCircleNoticeConfirmationEmailWrapper>
      <TextNoticeConfirmationEmail
        {...buildTestIds("text-notice-confirmation-email")}
        type={type}
      >
        {message}
      </TextNoticeConfirmationEmail>
    </NoticeConfirmationEmailContainer>
  );
};

export type { NoticeConfirmationEmailProps };

export { NoticeConfirmationEmail };
