import { IResponsiveModalProps } from "typing";
import { ResponsiveModal } from "../../../ResponsiveModal";
import { InputProps } from "../../../Form/Input";

const UniqueClientFeedbackUI = ({
  actions,
  content,
  ...props
}: IResponsiveModalProps & InputProps) => {
  return <ResponsiveModal {...actions} {...content} {...props} />;
};

export { UniqueClientFeedbackUI };
