import { memo, useEffect, useState } from "react";
import { GridCarouselProps, SliderProps } from "typing";
import { buildTestIds } from "application";
import { CarouselSubComponent } from "../SubComponent";
import { CustomGridCarousel } from "./style";
import { Slider } from "../../../Slider";

const GridCarousel = memo(
  ({
    subComponents,
    isClientMobile,
    isClientTablet,
    automaticSlide,
    carouselTheme,
    preloadComponent,
  }: GridCarouselProps) => {
    const slidesQuantity = isClientTablet ? 1 : 3;

    const [slidesToShow, setSlidesToShow] = useState<1 | 3>(slidesQuantity);

    const [slidesToScroll, setSlidesToScroll] = useState<1 | 3>(slidesQuantity);

    const [autoPlay, setAutoPlay] = useState<boolean>(false);

    useEffect(() => {
      if (automaticSlide === "true") {
        setAutoPlay(true);
      } else {
        setAutoPlay(false);
      }
    }, [automaticSlide]);

    useEffect(() => {
      setSlidesToShow(slidesQuantity);
      setSlidesToScroll(slidesQuantity);
    }, [isClientTablet, slidesQuantity]);

    const settings: SliderProps = {
      speed: 500,
      slidesToShow,
      slidesToScroll,
      arrowsIsActive:
        !isClientMobile && !isClientTablet && subComponents?.length > 3,
      arrowNextIsInCenter: true,
      arrowPrevIsInCenter: true,
      touchMove: true,
      swipe: true,
      dots: true,
      autoplay: autoPlay,
      infinite: true,
    };

    return (
      <CustomGridCarousel
        {...buildTestIds("custom-grid-carousel")}
        customTheme={carouselTheme}
      >
        <Slider customTheme={carouselTheme?.sliderButton} {...settings}>
          {subComponents?.map((subComponent, index) => {
            const subComponentKey = `${subComponent?.type}#${index}`;

            return (
              <CarouselSubComponent
                key={subComponentKey}
                {...subComponent}
                isinsidecarouselingridformat
                isClientMobile={isClientMobile}
                isClientTablet={isClientTablet}
                position={index + 1}
                preloadComponent={preloadComponent}
              />
            );
          })}
        </Slider>
      </CustomGridCarousel>
    );
  }
);

GridCarousel.displayName = "GridCarousel";

export { GridCarousel };
