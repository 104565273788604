import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const DeliveringTrackingStatusIcon = ({
  width = 32,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Saiu para entrega",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M22.0715 17.7261H17.627C17.1668 17.7261 16.7937 17.353 16.7937 16.8928V14.3333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="21.4167"
        cy="22.25"
        rx="1.25"
        ry="1.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3333 14.3333H17.1805C18.3608 14.3333 19.4723 14.889 20.1805 15.8333L21.0813 17.0344C21.2366 17.2414 21.4379 17.4094 21.6693 17.5251L23.4119 18.3964C23.9766 18.6787 24.3333 19.2558 24.3333 19.8871V21.2083C24.3333 21.7835 23.8669 22.2499 23.2916 22.2499H22.6666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1667 22.2499H17.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3318 23.5001H12.2484C12.0274 23.5001 11.8155 23.4122 11.6594 23.2559C11.5032 23.0995 11.4157 22.8875 11.416 22.6665V20.5831C11.416 20.3621 11.5039 20.1502 11.6602 19.9941C11.8166 19.8379 12.0287 19.7504 12.2496 19.7507H14.333C14.554 19.7507 14.7659 19.8386 14.922 19.995C15.0782 20.1513 15.1657 20.3634 15.1654 20.5843V22.6678C15.1654 22.8887 15.0775 23.1006 14.9212 23.2568C14.7648 23.4129 14.5528 23.5004 14.3318 23.5001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5825 23.5001H8.50036C8.27919 23.5004 8.067 23.4126 7.91062 23.2562C7.75423 23.0999 7.66649 22.8877 7.66675 22.6665V20.5831C7.66675 20.3621 7.75461 20.1502 7.91098 19.9941C8.06735 19.8379 8.27938 19.7504 8.50036 19.7507H10.5838C10.8047 19.7507 11.0166 19.8386 11.1728 19.995C11.3289 20.1513 11.4165 20.3634 11.4161 20.5843V22.6678C11.4161 22.8887 11.3283 23.1006 11.1719 23.2568C11.0155 23.4129 10.8035 23.5004 10.5825 23.5001V23.5001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.457 19.7506H10.3736C10.1525 19.7507 9.94048 19.6629 9.78414 19.5065C9.6278 19.3502 9.53999 19.1381 9.54004 18.917V16.8336C9.53998 16.6125 9.62779 16.4004 9.78414 16.2441C9.94048 16.0878 10.1525 15.9999 10.3736 16H12.457C12.6782 15.9999 12.8902 16.0878 13.0466 16.2441C13.2029 16.4004 13.2907 16.6125 13.2907 16.8336V18.917C13.2909 19.1382 13.2032 19.3504 13.0468 19.5068C12.8904 19.6632 12.6782 19.7509 12.457 19.7506V19.7506Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 14.3334V12.6993C23.5 11.9427 23.0898 11.2456 22.4285 10.8781L17.2146 7.98139C16.4595 7.56187 15.5413 7.56187 14.7863 7.98139L9.57154 10.8786C8.91018 11.246 8.5 11.9432 8.5 12.6997V13.5001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
