import { isVisibleOnDesktop, isVisibleOnMobile } from "application";
import { ComponentDetails, ComponentDto } from "typing";
import { CustomComponent } from "./CustomComponent";

const PageTemplateComponents = (
  [...components]: ComponentDto[],
  details: ComponentDetails
): JSX.Element => {
  return (
    <>
      {components
        .filter(({ type }) => type !== "header")
        .map((component, index) => {
          const componentKey = `${component?.displayName || "Componente"}#${
            component?.type
          }#${index}`;

          if (
            details?.isClientMobile &&
            isVisibleOnMobile(component) !== undefined &&
            isVisibleOnMobile(component) === false
          ) {
            return null;
          }

          if (
            !details?.isClientMobile &&
            isVisibleOnDesktop(component) !== undefined &&
            isVisibleOnDesktop(component) === false
          ) {
            return null;
          }

          return (
            <CustomComponent
              key={componentKey}
              {...details}
              {...component}
              position={index + 1}
            />
          );
        })}
    </>
  );
};

export { PageTemplateComponents };
