import { zodResolver } from "@hookform/resolvers/zod";
import { buildTestIds, validateCpf } from "application";
import {
  useAuthentication as defaultUseAuthentication,
  useShallow,
} from "application/state-manager";
import { useRouter as defaultUseRouter } from "next/router";
import { useEffect } from "react";
import { FieldErrors, UseFormRegister, useForm } from "react-hook-form";
import { IFormValues } from "typing";
import { theme } from "ui";
import { ButtonSubmit } from "../../../../../../ButtonSubmit";
import {
  RecoverPasswordFormProps,
  ValidateCpfProps,
  recoverPasswordFormValidateSchema,
  validateCpfSchema,
} from "./schema";
import {
  ArrowLeftIcon,
  ContentInformation,
  CustomSelect,
  ForgotPasswordContainer,
  ForgotPasswordContent,
  ForgotPasswordWrapper,
  FormContainer,
  LabelSelect,
  RecoverAccessText,
  RecoverInfoText,
  SelectWrapper,
  StyledInput,
  WrapperIcon,
} from "./styles";
import { useNewForgotPassword } from "./data";

interface ForgotPasswordLoginProps {
  useAuthentication?: typeof defaultUseAuthentication;
  useRouter?: typeof defaultUseRouter;
}

const NewForgotPasswordLogin = ({
  useAuthentication = defaultUseAuthentication,
  useRouter = defaultUseRouter,
}: ForgotPasswordLoginProps) => {
  const router = useRouter();
  const {
    changeCurrentStep,
    setUserIdentification,
    setIdentificationType,
    identificationType,
  } = useAuthentication(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
      setUserIdentification: state.setUserIdentification,
      setIdentificationType: state.setIdentificationType,
      identificationType: state.identificationType,
    }))
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValidating, isLoading },
  } = useForm<RecoverPasswordFormProps | ValidateCpfProps>({
    resolver: zodResolver(
      identificationType === "email"
        ? recoverPasswordFormValidateSchema
        : validateCpfSchema
    ),
  });

  const { onVerifyIdentity } = useNewForgotPassword();

  const options = [
    { value: "email", label: "E-mail" },
    { value: "cpf", label: "CPF" },
  ];

  const email = watch("recipientEmailForgotPassword");
  const cpf = watch("recipientCPFForgotPassword");
  const isSubmitDisabled = !(email || cpf);

  const emailErrorMessage = (
    errors as unknown as FieldErrors<{
      recipientEmailForgotPassword: string;
    }>
  )?.recipientEmailForgotPassword?.message;

  const checkEmailErrorMessage =
    !isSubmitDisabled && emailErrorMessage && email;

  useEffect(() => {
    if (identificationType === "email") {
      setUserIdentification(email);
      return;
    }

    setUserIdentification(cpf);
  }, [identificationType, email, cpf, setUserIdentification]);

  return (
    <ForgotPasswordContainer {...buildTestIds("forgot-password-container")}>
      <ForgotPasswordWrapper {...buildTestIds("forgot-password-wrapper")}>
        <ForgotPasswordContent {...buildTestIds("forgot-password-content")}>
          <ContentInformation {...buildTestIds("content-information")}>
            {router?.pathname !== "/login" && (
              <WrapperIcon
                onClick={() => changeCurrentStep("login")}
                {...buildTestIds("forgot-password-back-button")}
              >
                <ArrowLeftIcon />
              </WrapperIcon>
            )}
            <RecoverAccessText {...buildTestIds("recover-access-text")}>
              Esqueceu sua senha?
            </RecoverAccessText>
          </ContentInformation>
          <RecoverInfoText {...buildTestIds("recover-info-text")}>
            Vamos enviar um código de recuperação para o e-mail ou CPF inserido
            abaixo.
          </RecoverInfoText>

          <FormContainer {...buildTestIds("forgot-password-form-container")}>
            <SelectWrapper {...buildTestIds("forgot-password-select-wrapper")}>
              <LabelSelect {...buildTestIds("label-recipient-forgot-password")}>
                Modo de identificação
              </LabelSelect>
              <CustomSelect
                autoFocus={false}
                blurInputOnSelect={false}
                isSearchable={false}
                instanceId={1}
                options={options}
                defaultValue={options?.find(
                  (option) => option?.value === identificationType
                )}
                classNamePrefix="react-select"
                onChange={(choice) => {
                  const selectedOption = choice as {
                    value: string;
                  };
                  if (selectedOption?.value) {
                    setIdentificationType(selectedOption.value);
                  }
                }}
              />
            </SelectWrapper>
            {identificationType === "email" ? (
              <SelectWrapper>
                <LabelSelect {...buildTestIds("email-label")}>
                  Digite seu E-mail
                </LabelSelect>
                <StyledInput
                  {...buildTestIds("input-recipient-email-forgot-password")}
                  id="recipientEmailForgotPassword"
                  name="recipientEmailForgotPassword"
                  register={register as unknown as UseFormRegister<IFormValues>}
                  type="text"
                  inputMode="email"
                  placeholder="Digite seu e-mail"
                  hasError={checkEmailErrorMessage}
                  onChange={({ target }) =>
                    setValue("recipientEmailForgotPassword", target.value)
                  }
                  height="40px"
                  textError={emailErrorMessage}
                  width="100%"
                  padding={`${theme.space.x3}`}
                  paddingErrorMessage={`${theme.space.x0}`}
                  margin="0px"
                  fontSize={`${theme.typography.fontSize.sm.x5}`}
                  borderRadius={`${theme.space.x1}`}
                />
              </SelectWrapper>
            ) : (
              <SelectWrapper>
                <LabelSelect {...buildTestIds("cpf-label")}>
                  Digite seu CPF
                </LabelSelect>
                <StyledInput
                  {...buildTestIds("input-recipient-cpf-forgot-password")}
                  id="recipientCPFForgotPassword"
                  name="recipientCPFForgotPassword"
                  register={register as unknown as UseFormRegister<IFormValues>}
                  type="tel"
                  mask="999.999.999-99"
                  onChange={({ target }) =>
                    setValue(
                      "recipientCPFForgotPassword",
                      target.value.replace(/[^0-9]/g, "")
                    )
                  }
                  placeholder="Digite seu CPF"
                  hasError={!!validateCpf(cpf) && cpf?.length === 11}
                  height="40px"
                  textError="CPF inválido"
                  width="100%"
                  padding={`${theme.space.x3}`}
                  paddingErrorMessage={`${theme.space["x0.5"]} ${theme.space.x0} ${theme.space.x0}`}
                  margin="0px"
                  fontSize={`${theme.typography.fontSize.sm.x5}`}
                  borderRadius={`${theme.space.x1}`}
                />
              </SelectWrapper>
            )}

            <ButtonSubmit
              text="Continuar"
              onClickAction={handleSubmit(onVerifyIdentity)}
              isDisabled={isSubmitDisabled}
              minWidth="100%"
              height="37px"
              fontWeight={500}
              borderRadius="8px"
              margin="24px 0px 0px"
              underAction={isSubmitting || isValidating || isLoading}
              color={`${theme.colors.secondary["380"]}`}
              hoveredColor={`${theme.colors.secondary["350"]}`}
              {...buildTestIds("button-submit-forgot-password")}
            />
          </FormContainer>
        </ForgotPasswordContent>
      </ForgotPasswordWrapper>
    </ForgotPasswordContainer>
  );
};

export { NewForgotPasswordLogin };
