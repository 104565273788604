import styled, { css } from "styled-components";
import { BlackFridayThemeProps } from "typing/dto/BlackFriday";
import { FlexContainer, theme } from "ui";
import { HighlightCategories } from "../../backoffice/CustomHighlightCategories/style";
import {
  CategoryNameContainer,
  ListCategoriesAside,
  WelcomeText,
} from "./components/ListCategories/styles";

import { Image } from "../../Image";
import {
  CompanyProductLink,
  ContentLocation,
} from "./components/NavBarDesktop/styles";
import { Wrapper } from "./components/mobile/NavBarMobile/styles";
import { SidebarMenuMobileContainer } from "./components/mobile/SidebarMenuMobile/styles";

interface HeaderOuterContainerProps {
  themeBlackFriday?: BlackFridayThemeProps;
  isPageWeddingList: boolean;
  blackThemeText?: string;
  isBlackTheme?: boolean;
}

export const HeaderContainerMainContent = styled.section`
  min-height: 100%;
  box-shadow: 0 2px 2px 0 ${theme.colors.neutral["999-opacity-10"]};
  background-color: ${theme.colors.neutral.white};

  @media ${theme.device.desktopFirst.mobileL} {
    min-height: 19vh;
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 99;
  section {
    width: 100%;
    height: 100%;
  }

  main {
    background-color: transparent;
  }
`;

export const HamburgerIconWrapper = styled.div`
  @media ${theme.device.desktopFirst.mobileL} {
    transform: scale(0.95);
    margin-left: 0.25rem;
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 12.5rem;
  height: 4.667rem;
  cursor: pointer;
  margin-right: 0.75rem;

  transition: all 0.2s ease-in;

  &:hover {
    opacity: 0.75;
  }

  @media ${theme.device.desktopFirst.laptop} {
    width: 10rem;
    height: 4rem;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    width: 8rem;
    height: 3rem;
    margin-right: 0;

    &:hover {
      opacity: 1;
    }
  }
`;

export const MobileLogoFC = styled(Image)``;

export const DesktopLogoFC = styled(Image)``;

export const ContentHeaderMobile = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0rem;
  padding: 1rem 0.5rem;
`;

export interface ComponentPresentInWeddingListProps {
  isPageWeddingList: boolean;
  isBlackFriday?: BlackFridayThemeProps;
}

export const FlexContainerNavCenterHeaderCustom = styled.div<ComponentPresentInWeddingListProps>`
  background: ${({ isPageWeddingList }) =>
    isPageWeddingList
      ? theme.colors.primary["200"]
      : theme.colors.neutral.white};
  box-shadow: ${({ isPageWeddingList, isBlackFriday }) =>
    !isBlackFriday || isPageWeddingList
      ? `0 1px 1px 0 ${theme?.colors?.neutral?.["999-opacity-10"]}`
      : `0 1px 1px 0 ${theme?.colors?.blackFriday?.white}`};
  z-index: 99;
  /* position: relative; */
`;

export const FlexContainerCenterHeaderCustom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type ClientLocationContainerProps = {
  backgroundColor?: string;
  color?: string;
};

export const ClientLocationContainer = styled.div<ClientLocationContainerProps>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || theme.colors.neutral.white};
  color: ${({ color }) => color || theme.colors.primary["200"]};
`;

export const HeaderOuterContainer = styled.div<HeaderOuterContainerProps>`
  ${({ isPageWeddingList, isBlackTheme }) => {
    const themeConditional = isBlackTheme && !isPageWeddingList;
    const grayThemeBlackFriday = theme?.colors?.blackFriday?.["20"];
    const blackThemeBlackFriday = theme?.colors?.blackFriday?.["175"];
    const whiteThemeBlackFriday = theme?.colors?.blackFriday?.white;

    return css`
      ${SidebarMenuMobileContainer} {
        will-change: color, background-color;
      }

      ${themeConditional &&
      css`
        ${HamburgerIconWrapper} svg path {
          stroke: ${grayThemeBlackFriday} !important;
        }

        ${Wrapper} svg path {
          stroke: ${grayThemeBlackFriday} !important;
          fill: ${grayThemeBlackFriday} !important;
        }

        ${HeaderContainer}, ${FlexContainerNavCenterHeaderCustom} {
          background-color: ${blackThemeBlackFriday};
          color: ${grayThemeBlackFriday} !important;
        }

        ${HeaderContainerMainContent} {
          background-color: ${blackThemeBlackFriday};
        }

        ${CompanyProductLink} {
          color: ${whiteThemeBlackFriday};
        }

        ${ListCategoriesAside} ${FlexContainer} * {
          &:hover {
            color: ${whiteThemeBlackFriday};
          }
        }

        ${ListCategoriesAside} ${FlexContainer} svg path,
        ${ListCategoriesAside} ${FlexContainer} svg circle {
          &:hover {
            stroke: ${whiteThemeBlackFriday} !important;
          }
        }

        ${ContentLocation} * {
          color: ${grayThemeBlackFriday} !important;
        }

        ${ContentLocation}:after {
          color: ${whiteThemeBlackFriday} !important;
        }

        ${ContentLocation} svg path {
          stroke: ${whiteThemeBlackFriday} !important;
          fill: ${whiteThemeBlackFriday} !important;
        }

        ${CategoryNameContainer} * {
          color: ${grayThemeBlackFriday} !important;
        }

        ${CategoryNameContainer} svg path {
          stroke: ${whiteThemeBlackFriday} !important;
          fill: ${whiteThemeBlackFriday} !important;
        }

        ${WelcomeText} {
          color: ${grayThemeBlackFriday} !important;
        }

        ${HighlightCategories} * {
          color: ${grayThemeBlackFriday} !important;
        }

        ${HighlightCategories} a:hover {
          background-color: ${theme?.colors?.blackFriday?.["100"]};
        }
      `}
    `;
  }}
`;
