import styled from "styled-components";
import { theme } from "ui";

interface ValidationInputProps {
  width?: string;
  height?: string;
  display?: string;
  border?: string;
}

export const ValidationInput = styled.input<ValidationInputProps>`
  width: ${({ width }) => width || "39px"};
  height: ${({ height }) => height || "50px"};
  border: ${({ border }) => border};
  border-radius: ${theme.space.x2};
  text-align: center;
  font-size: ${theme.typography.fontSize.sm.x7};
  color: ${theme.colors.neutral["999"]} !important;
  :focus {
    border: 2px solid ${theme.colors.neutral["999"]};
  }
`;

export const InputCodeContainer = styled.div`
  color: ${theme.colors.neutral["999"]} !important;

  width: 100%;
  margin: ${theme.space.x1} ${theme.space.x0};
`;

export const Inputs = styled.div<ValidationInputProps>`
  color: ${theme.colors.neutral["999"]} !important;
  display: ${({ display }) => display};
  justify-content: space-between;
  width: 100%;
  margin: ${theme.space.x2} ${theme.space.x0};
`;

export const MessageErrorWrapper = styled.div`
  gap: 24px;
`;

export const SupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  margin: 24px 0;
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.space.x2};
`;

export const SupportText = styled.span`
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.neutral["550"]};
`;
export const MessageError = styled.div`
  display: flex;
  width: 100%;
  gap: ${theme.space.x2};
  align-items: center;
  padding-bottom: 0.8rem;
`;

export const MoreInformation = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${theme.space.x2};
  border-color: 1px solid ${theme.colors.neutral["150"]};
  width: 100%;
  gap: ${theme.space.x2};
  padding: 0.6rem 1rem;
`;

export const InformationText = styled.span`
  color: ${theme.colors.neutral["350"]};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight[400]};
`;

export const MessageSuccess = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.space.x2};
`;
