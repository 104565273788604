import { buildTestIds, useEnvs } from "application";
import { Head as NextHead } from "infra/components";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { HeadProps, WafInfos } from "typing";
import { WafScript } from "./Waf";

const Head = ({
  title = "Ferreira Costa",
  subtitle = "Tudo para Casa, Construção e Decoração",
  description = "Encontre as melhores ofertas de eletrodomésticos, móveis, pisos, utilidades domésticas e muito mais para sua casa! Aproveite!",
  keywords = [
    "Ferreira Costa, Home Center, Construção, Casa, Ofertas, Decoração",
  ],
  image = "https://fcx-s3-fercos-backoffice.ferreiracosta.com/favicon/apple-touch-icon.png",
  shouldIndexPage = true,
  pageUrl,
  category,
  highlight,
  product,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
HeadProps) => {
  const pageTitle = `${
    product?.productName ?? category?.title ?? highlight?.title ?? title
  } | ${subtitle}`;
  const router = useRouter();
  const [wafInfos, setWafInfos] = useState<WafInfos>({
    isWafAvailable: "false",
    awsWafChallengeUrl: "",
    awsWafJsApiUrl: "",
  });

  const [pageImage, setPageImage] = useState<string | null>(null);

  const [formattedPageUrl, setFormattedPageUrl] = useState<string | null>(
    pageUrl || null
  );

  const allConfigs = useEnvs((state) => state.allConfigs);

  useEffect(() => {
    const handleIsWafAvailable = async (passedWafInfos: WafInfos) => {
      setWafInfos(passedWafInfos);
    };

    if (allConfigs) {
      const { isWafAvailable, awsWafChallengeUrl, awsWafJsApiUrl, siteUrl } =
        allConfigs;

      handleIsWafAvailable({
        isWafAvailable,
        awsWafChallengeUrl,
        awsWafJsApiUrl,
      });

      setPageImage(
        image ? (image.startsWith("/") ? `${siteUrl}${image}` : image) : null
      );

      if (!formattedPageUrl) {
        setFormattedPageUrl(siteUrl);
      }
    }
  }, [allConfigs, formattedPageUrl, image]);

  const siteName = formattedPageUrl?.match(/https?:\/\/([^/]+)/)?.at(1);

  return (
    <NextHead {...buildTestIds("head-component")}>
      <title>{pageTitle}</title>

      {keywords && (
        <meta name="keywords" content={keywords.slice().join(", ")} />
      )}

      {!shouldIndexPage && <meta name="robots" content="noindex,nofollow" />}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://fcx-s3-fercos-backoffice.ferreiracosta.com/favicon/apple-touch-icon.png"
      />

      <link
        rel="apple-touch-icon"
        sizes="100x100"
        href="https://fercos-s3-ecommerce.s3.us-east-1.amazonaws.com/favicon/apple-touch-icon-precomposed.png"
      />

      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="https://fercos-s3-ecommerce.s3.us-east-1.amazonaws.com/favicon/apple-touch-icon-120x120-precomposed.png"
      />

      <link rel="shortcut icon" href="/favicon.ico" type="image/png" />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://fcx-s3-fercos-backoffice.ferreiracosta.com/favicon/favicon-32x32.png"
      />
      <link rel="manifest" href="/manifest.json" />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=1.0"
      />
      <meta httpEquiv="x-ua-compatible" content="IE=edge,chrome=1" />
      <meta name="MobileOptimized" content="320" />
      <meta name="HandheldFriendly" content="True" />
      <meta name="theme-color" content="#DA0812" />
      <meta name="msapplication-TileColor" content="#DA0812" />
      <meta httpEquiv="Content-Language" content="pt-br" />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta name="google" content="notranslate" />
      <meta property="og:locale" content="pt_BR" />
      <meta name="application-name" content={title} />

      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={
          product?.productTitle || category?.title || highlight?.title || title
        }
      />
      <meta
        name="twitter:description"
        content={
          product?.description ||
          category?.description ||
          highlight?.description ||
          description
        }
      />
      <meta name="twitter:image" content={product?.image || image} />

      <WafScript {...wafInfos} />

      {product || category || highlight ? (
        <>
          <link
            rel="canonical"
            href={product?.url || category?.url || highlight?.url}
          />

          {/*
           * Foi realizado um alinhamento com Ecommerce que os dados fixos das meta tags msvalidate.01 e fb:app_id deveriam ficar as mesmas do site atual.
           *  Mesmo eles não possuindo conhecimento se o content de cada uma deve ser os valores abaixo
           */}
          <meta
            name="msvalidate.01"
            content="E7DCE11C2F6EF34169891FD104F4B11B"
          />
          <meta property="fb:app_id" content="271309290176012" />

          <meta
            property="og:type"
            content={product?.id ? "product" : "website"}
          />
          <meta
            property="og:title"
            content={
              product?.productTitle || category?.title || highlight?.title
            }
          />
          <meta
            property="og:url"
            content={product?.url || category?.url || highlight?.url}
          />
          <meta property="og:image" content={product?.image || image} />
          {/* product?.id ? (
            <>
              <meta property="product:brand" content={product?.brand} />
              <meta property="product:price:amount" content={product?.value} />
              <meta property="product:retailer_item_id" content={product?.id} />
              <meta
                property="product:availability"
                content={product?.availability}
              />
              {/* Em alinhamento com Pedro(Peixoto) a condition deve sempre ser "new" */}
          {/* <meta property="product:condition" content="new" />
              <meta property="product:price:currency" content="BRL" />
            </>
          ) : null */}

          <meta
            property="og:image:secure_url"
            content={product?.image || image}
          />
          <meta
            property="og:image:alt"
            content={product?.productName || "Thumbnail"}
          />
          <meta property="og:image:width" content="420" />
          <meta property="og:image:height" content="420" />

          <meta
            name="description"
            content={
              product?.description ||
              category?.description ||
              highlight?.description
            }
          />
          {/* <meta
            property="og:description"
            content={
              product?.description ||
              category?.description ||
              highlight?.description
            }
          /> */}
        </>
      ) : (
        <>
          <meta
            name="msvalidate.01"
            content="E7DCE11C2F6EF34169891FD104F4B11B"
          />
          <meta property="fb:app_id" content="271309290176012" />
          {router &&
            !router?.asPath?.includes("/prod") &&
            !router?.asPath?.includes("/Destaque") &&
            !router?.asPath?.includes("/Categ") && (
              <link rel="canonical" href={String(formattedPageUrl)} />
            )}

          <meta property="og:title" content={pageTitle} />
          <meta property="og:type" content="website" />

          <meta name="description" content={description} />
          <meta property="og:description" content={description} />

          <meta property="og:url" content={pageUrl} />
          <meta property="og:site_name" content={siteName || pageUrl} />

          <meta name="image" content={pageImage as string} />
          <meta property="og:image" content={pageImage as string} />
          <meta property="og:image:secure_url" content={pageImage as string} />

          <meta property="og:image:alt" content="Thumbnail" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </>
      )}

      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    </NextHead>
  );
};

export { Head };
