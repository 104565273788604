import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const GoogleColored = ({
  width = 22,
  height = 22,
  color = theme.colors.neutral.white,
  secondColor = theme.colors.primary["190"],
  thirdColor = theme.colors.yellow["70"],
  fourthColor = theme.colors.tertiary["260"],
  fifthColor = theme.colors.secondary["440"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79575 1.12206C5.25108 1.99275 3.08278 3.89704 1.87504 6.30689C1.45374 7.13826 1.1504 8.02018 0.965032 8.93581C0.504408 11.2052 0.824598 13.6263 1.86943 15.6935C2.54913 17.0417 3.52655 18.2438 4.7062 19.1819C5.82405 20.0694 7.12166 20.7323 8.50353 21.0974C10.2449 21.5636 12.0986 21.5524 13.8513 21.1536C15.4354 20.7885 16.9352 20.0301 18.1317 18.9291C19.3956 17.7663 20.3 16.2384 20.7775 14.5925C21.2999 12.8006 21.3673 10.885 21.0415 9.04254C17.7666 9.04254 14.486 9.04254 11.2111 9.04254C11.2111 10.4019 11.2111 11.7613 11.2111 13.1207C13.1098 13.1207 15.0084 13.1207 16.9071 13.1207C16.688 14.424 15.9128 15.6149 14.8175 16.3507C14.1265 16.817 13.3345 17.1147 12.5143 17.2607C11.6942 17.4012 10.8404 17.418 10.0202 17.2551C9.18324 17.0866 8.39119 16.7383 7.69463 16.2496C6.5824 15.4688 5.73417 14.3285 5.30164 13.0421C4.85787 11.7333 4.85225 10.2784 5.30164 8.97513C5.61621 8.05389 6.13301 7.20566 6.81833 6.50911C7.66093 5.64404 8.75632 5.02613 9.94158 4.77334C10.9527 4.55989 12.02 4.59921 13.0087 4.89693C13.8513 5.14971 14.6265 5.61595 15.2612 6.22263C15.9016 5.58225 16.542 4.94187 17.1824 4.30149C17.5194 3.95883 17.8733 3.6274 18.1991 3.27351C17.2273 2.37473 16.0814 1.65009 14.8343 1.18947C12.5874 0.358097 10.0595 0.341245 7.79575 1.12206Z"
        fill={color}
      />
      <path
        d="M7.7957 1.12173C10.0539 0.340919 12.5873 0.357771 14.8343 1.18352C16.0813 1.64415 17.2216 2.36317 18.1991 3.26757C17.8733 3.62146 17.5194 3.95288 17.1823 4.29554C16.5419 4.93592 15.9016 5.5763 15.2612 6.21668C14.6264 5.61001 13.8512 5.14938 13.0086 4.89099C12.02 4.59327 10.9527 4.54833 9.94153 4.7674C8.76189 5.02018 7.6665 5.63809 6.81828 6.50317C6.13296 7.19411 5.61616 8.04795 5.30159 8.96919C4.16127 8.08165 3.02094 7.19972 1.875 6.31218C3.08273 3.89671 5.25104 1.99242 7.7957 1.12173Z"
        fill={secondColor}
      />
      <path
        d="M0.970594 8.92995C1.15597 8.01994 1.45931 7.1324 1.88061 6.30103C3.02093 7.18857 4.16126 8.0705 5.3072 8.95804C4.85781 10.2669 4.85781 11.7218 5.3072 13.025C4.16688 13.9126 3.02655 14.8001 1.88623 15.682C0.83016 13.6205 0.50997 11.1994 0.970594 8.92995Z"
        fill={thirdColor}
      />
      <path
        d="M11.2107 9.03662C14.4856 9.03662 17.7662 9.03662 21.0411 9.03662C21.3669 10.8735 21.2939 12.789 20.7771 14.5866C20.2996 16.2325 19.3952 17.7604 18.1313 18.9232C17.0247 18.0637 15.918 17.2043 14.8114 16.3448C15.9068 15.6089 16.682 14.4181 16.9011 13.1148C15.0024 13.1148 13.1037 13.1148 11.2051 13.1148C11.2107 11.7554 11.2107 10.396 11.2107 9.03662Z"
        fill={fourthColor}
      />
      <path
        d="M1.875 15.6875C3.01533 14.8056 4.15565 13.9181 5.29598 13.0305C5.73413 14.3169 6.57674 15.4572 7.68897 16.238C8.38553 16.7267 9.18319 17.075 10.0146 17.2435C10.8347 17.4121 11.6829 17.3896 12.5087 17.2492C13.3288 17.1031 14.1209 16.8054 14.8118 16.3391C15.9184 17.1986 17.025 18.0581 18.1317 18.9175C16.9352 20.0241 15.4353 20.7769 13.8512 21.142C12.0986 21.5408 10.2449 21.5521 8.50349 21.0858C7.12162 20.7207 5.82401 20.0635 4.70615 19.1703C3.53212 18.2378 2.5547 17.0357 1.875 15.6875Z"
        fill={fifthColor}
      />
    </svg>
  );
};
