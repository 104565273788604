import styled from "styled-components";
import { theme } from "ui";

type StyledInputProps = {
  hasError?: boolean;
  width?: string;
};

export const StyledInput = styled.div<StyledInputProps>`
  background-color: ${theme.colors.neutral["80"]};
  border-radius: 4px;
  padding: 1px;
  /* overflow: hidden; */
  display: flex;
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-box-shadow: none;
    max-width: 4rem;
    font-size: 1rem;
    color: ${theme.colors.neutral["520"]};
    font-weight: ${theme.typography.fontWeight.bold};
    font-family: ${theme.typography.fontFamily.chivo};
    background: none;
    border: none;
    text-align: center;
    line-height: 35px;
  }
  input[type="number"]:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
  button {
    background: none;
    border: none;
    width: 2rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  button span {
    font-size: 3rem;
    color: ${theme.colors.neutral["999"]};
  }
  button:first-child > span {
    transform: rotate(90deg);
  }
  button:last-child > span {
    transform: rotate(-90deg);
  }
  button:active,
  button:hover {
    background-color: ${theme.colors.neutral["200"]};
  }
`;
