interface Property<T> {
  key: string;
  value?: T;
}

const getComponentTheme = <T>(
  themeValue?: Property<string>,
  componentBlackFridayTheme?: T
) => {
  let blackFridayTheme: T | undefined = componentBlackFridayTheme;
  if (themeValue && typeof themeValue.value === "string") {
    try {
      blackFridayTheme = JSON.parse(themeValue.value) as T;
    } catch (error) {
      blackFridayTheme = undefined;
    }
  }
  return blackFridayTheme;
};

export { getComponentTheme };
