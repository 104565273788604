import { IconProps } from "typing";
import { theme } from "../themes";

export const CheckDone = ({
  alt = "concluido",
  width = 18,
  height = 18,
  color = theme.colors.secondary["265"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M20 6.5L9 17.5L4 12.5"
        stroke={color}
        strokeWidth="3.0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
