import styled, { css } from "styled-components";
import { Text, theme, H2 } from "ui";

interface SupportContactProps {
  isBlackTheme: boolean;
}

export const SupportContactTitle = styled(H2)<SupportContactProps>`
  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      color: ${theme.colors.neutral.white};
    `};
`;

export const SupportContactText = styled(Text)<SupportContactProps>`
  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      color: ${theme.colors.neutral.white};
    `};
`;
