import { IconProps } from "typing";
import { theme } from "../themes";

export const CheckValidateIcon = ({
  width = 20,
  height = 20,
  color = theme.colors.secondary["350"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M9 3.95454L4.875 8.04545L3 6.18595"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />
    </svg>
  );
};
