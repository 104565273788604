import { IconProps } from "typing";

export const EmailFailureIcon = ({
  width = 121,
  height = 120,
  alt = "ícone de e-mail para falha",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60.5" cy="59.875" r="59.875" fill="#F6F7F7" />
      <path
        d="M90.0003 75.8159L94.1726 68.5819"
        stroke="#B2B3B5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.449 74.3448L89.9996 75.8159"
        stroke="#B2B3B5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.1404 71.413L91.691 72.884"
        stroke="#B2B3B5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.7818 68.9239C94.9472 69.4164 93.0607 68.3283 92.5683 66.4937C92.0758 64.6592 93.1639 62.7727 94.9984 62.2802C96.833 61.7878 98.7195 62.8758 99.2119 64.7104C99.7044 66.545 98.6164 68.4314 96.7818 68.9239"
        stroke="#B2B3B5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.78 77.6606L110.623 73.7234"
        stroke="#C3C4C5"
        strokeWidth="1.04397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.391 78.7739L113.779 77.6606"
        stroke="#C3C4C5"
        strokeWidth="1.04397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.112 77.1782L112.5 76.0649"
        stroke="#C3C4C5"
        strokeWidth="1.04397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.388 72.3289C111.262 73.47 110.236 74.2934 109.094 74.168C107.953 74.0426 107.13 73.0159 107.255 71.8748C107.381 70.7337 108.407 69.9104 109.549 70.0357C110.69 70.1611 111.513 71.1878 111.388 72.3289"
        stroke="#C3C4C5"
        strokeWidth="1.04397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.9067 39.4858L81.3091 33.2885"
        stroke="#C3C4C5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.7215 41.4595L86.9066 39.4858"
        stroke="#C3C4C5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.452 38.948L84.6371 36.9743"
        stroke="#C3C4C5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.438 30.9106C82.3416 32.8077 80.7255 34.2674 78.8284 34.171C76.9313 34.0745 75.4716 32.4584 75.568 30.5613C75.6645 28.6642 77.2806 27.2045 79.1777 27.301C81.0748 27.3974 82.5345 29.0135 82.438 30.9106"
        stroke="#C3C4C5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6689 72.6455L29.1242 66.6545"
        stroke="#B2B3B5"
        strokeWidth="1.4306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5564 71.427L25.6687 72.6453"
        stroke="#B2B3B5"
        strokeWidth="1.4306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9568 68.9988L27.0691 70.2171"
        stroke="#B2B3B5"
        strokeWidth="1.4306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2855 66.9379C29.7662 67.3457 28.2039 66.4446 27.7961 64.9253C27.3882 63.406 28.2893 61.8437 29.8086 61.4359C31.3279 61.0281 32.8902 61.9291 33.298 63.4484C33.7058 64.9678 32.8048 66.53 31.2855 66.9379"
        stroke="#B2B3B5"
        strokeWidth="1.4306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.381 35.3737L102.483 31.7292"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.0968 34.6327L100.382 35.3738"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.9503 33.1554L101.235 33.8965"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.799 31.9018C102.875 32.1499 101.925 31.6018 101.677 30.6775C101.428 29.7533 101.977 28.8029 102.901 28.5548C103.825 28.3067 104.776 28.8548 105.024 29.7791C105.272 30.7034 104.724 31.6537 103.799 31.9018"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.1372 18.6643L73.2392 15.0197"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.8527 17.9232L71.1377 18.6644"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.7062 16.4459L71.9912 17.1871"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5552 15.1924C73.6309 15.4405 72.6805 14.8923 72.4324 13.9681C72.1843 13.0438 72.7324 12.0934 73.6567 11.8453C74.581 11.5972 75.5314 12.1454 75.7795 13.0696C76.0276 13.9939 75.4794 14.9443 74.5552 15.1924"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8491 25.6403L39.225 18.0532"
        stroke="#B2B3B5"
        strokeWidth="1.81175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.1755 24.0976L34.8506 25.6405"
        stroke="#B2B3B5"
        strokeWidth="1.81175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.949 21.0226L36.624 22.5655"
        stroke="#B2B3B5"
        strokeWidth="1.81175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.9633 18.4124C40.0392 18.9289 38.0607 17.7878 37.5442 15.8637C37.0277 13.9396 38.1688 11.9611 40.0929 11.4446C42.017 10.9281 43.9955 12.0692 44.512 13.9933C45.0285 15.9174 43.8874 17.8959 41.9633 18.4124"
        stroke="#B2B3B5"
        strokeWidth="1.81175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7855 44.7413L19.6329 46.437C18.9531 46.6244 18.2506 46.2255 18.0633 45.5457L16.8763 41.2388C16.6889 40.559 17.0878 39.8565 17.7676 39.6692L23.9203 37.9734C24.6001 37.7861 25.3026 38.185 25.4899 38.8648L26.6769 43.1717C26.8643 43.8515 26.4654 44.554 25.7855 44.7413Z"
        stroke="#C3C4C5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3823 39.4997L17.8736 37.6539V37.6539C17.499 36.2948 18.2972 34.8892 19.6564 34.5146V34.5146C21.0155 34.14 22.4211 34.9382 22.7957 36.2974V36.2974L23.3044 38.1431"
        stroke="#C3C4C5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.8324 95.2013L91.6904 91.421C91.1223 91.0033 91.0004 90.2048 91.4181 89.6366L94.0643 86.0372C94.482 85.469 95.2805 85.3471 95.8487 85.7648L100.991 89.5451C101.559 89.9628 101.681 90.7613 101.263 91.3295L98.6169 94.9289C98.1992 95.4971 97.4006 95.619 96.8324 95.2013Z"
        stroke="#C3C4C5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.3636 86.1428L97.4977 84.6003V84.6003C98.3327 83.4644 99.9307 83.2205 101.067 84.0556V84.0556C102.202 84.8906 102.446 86.4886 101.611 87.6244V87.6244L100.477 89.167"
        stroke="#C3C4C5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.33 54.4884L103.178 56.1841C102.498 56.3715 101.796 55.9726 101.608 55.2927L100.421 50.9859C100.234 50.306 100.633 49.6036 101.313 49.4162L107.465 47.7205C108.145 47.5331 108.847 47.932 109.035 48.6119L110.222 52.9187C110.409 53.5986 110.01 54.301 109.33 54.4884Z"
        stroke="#B2B3B5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.927 49.2465L101.419 47.4007V47.4007C101.044 46.0416 101.842 44.636 103.201 44.2614V44.2614C104.56 43.8868 105.966 44.6851 106.341 46.0442V46.0442L106.849 47.89"
        stroke="#B2B3B5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.293 54.9266L90.3366 57.869C89.8994 58.1942 89.282 58.1034 88.9568 57.6663L86.8971 54.8968C86.5719 54.4596 86.6627 53.8421 87.0999 53.517L91.0563 50.5745C91.4935 50.2494 92.1109 50.3401 92.4361 50.7773L94.4958 53.5468C94.821 53.984 94.7302 54.6014 94.293 54.9266Z"
        stroke="#C3C4C5"
        strokeWidth="1.00668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.4972 53.2226L86.6145 52.0357V52.0357C85.9645 51.1617 86.1461 49.9261 87.0201 49.2761V49.2761C87.894 48.6261 89.1296 48.8077 89.7796 49.6817V49.6817L90.6624 50.8687"
        stroke="#C3C4C5"
        strokeWidth="1.00668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7097 63.6294L6.64156 62.3508C6.19203 62.2095 5.94236 61.731 6.08365 61.2814L6.97866 58.4337C7.11994 57.9842 7.59847 57.7346 8.048 57.8758L12.1162 59.1544C12.5657 59.2957 12.8153 59.7742 12.6741 60.2238L11.7791 63.0715C11.6378 63.521 11.1592 63.7707 10.7097 63.6294Z"
        stroke="#B2B3B5"
        strokeWidth="0.870636"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4546 58.0037L8.83818 56.7833V56.7833C9.12061 55.8846 10.0782 55.385 10.9769 55.6674V55.6674C11.8755 55.9499 12.3751 56.9075 12.0927 57.8061V57.8061L11.7091 59.0266"
        stroke="#B2B3B5"
        strokeWidth="0.870636"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4705 53.3276L33.398 54.6489C33.0585 54.7949 32.6653 54.6382 32.5193 54.2987L31.5943 52.1479C31.4483 51.8084 31.6051 51.4152 31.9446 51.2692L35.017 49.9479C35.3565 49.8019 35.7498 49.9586 35.8958 50.2981L36.8207 52.4488C36.9667 52.7883 36.81 53.1816 36.4705 53.3276Z"
        stroke="#C3C4C5"
        strokeWidth="0.682843"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.255 51.1371L31.8586 50.2153V50.2153C31.5667 49.5366 31.8804 48.7497 32.5591 48.4578V48.4578C33.2378 48.1659 34.0247 48.4796 34.3166 49.1583V49.1583L34.713 50.08"
        stroke="#C3C4C5"
        strokeWidth="0.682843"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.4023 22.9663L82.2602 20.6149C81.692 20.355 81.4422 19.6844 81.702 19.1162L83.3481 15.5167C83.6079 14.9485 84.2786 14.6987 84.8468 14.9586L89.9889 17.31C90.5571 17.5699 90.8068 18.2406 90.547 18.8088L88.901 22.4082C88.6411 22.9764 87.9705 23.2262 87.4023 22.9663Z"
        stroke="#B2B3B5"
        strokeWidth="1.15441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.3618 15.1937L86.0672 13.6511V13.6511C86.5867 12.5152 87.9288 12.0154 89.0646 12.5348V12.5348C90.2005 13.0543 90.7003 14.3964 90.1809 15.5323V15.5323L89.4755 17.0749"
        stroke="#B2B3B5"
        strokeWidth="1.15441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1762 93.4767L21.0235 95.1724C20.3437 95.3598 19.6413 94.9609 19.4539 94.281L18.2669 89.9742C18.0795 89.2943 18.4784 88.5919 19.1583 88.4045L25.3109 86.7088C25.9908 86.5214 26.6932 86.9203 26.8806 87.6002L28.0676 91.907C28.2549 92.5869 27.856 93.2893 27.1762 93.4767Z"
        stroke="#B2B3B5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7729 88.2348L19.2642 86.389V86.389C18.8896 85.0299 19.6879 83.6243 21.047 83.2497V83.2497C22.4061 82.8751 23.8117 83.6733 24.1863 85.0325V85.0325L24.695 86.8782"
        stroke="#B2B3B5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="59.8034"
        cy="104.572"
        rx="22.9753"
        ry="1.53169"
        fill="#C3C4C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4447 86.9102H77.9122C83.7328 86.9102 88.4514 82.1916 88.4514 76.3709V52.2185C88.4514 48.5847 86.5794 45.2072 83.4979 43.2813L65.9326 32.3029C62.515 30.1669 58.1786 30.1669 54.761 32.3029L37.1956 43.2813C34.1141 45.2072 32.2422 48.5847 32.2422 52.2185L32.2422 65.068"
        fill="white"
      />
      <path
        d="M57.4447 86.9102H77.9122C83.7328 86.9102 88.4514 82.1916 88.4514 76.3709V52.2185C88.4514 48.5847 86.5794 45.2072 83.4979 43.2813L65.9326 32.3029C62.515 30.1669 58.1786 30.1669 54.761 32.3029L37.1956 43.2813C34.1141 45.2072 32.2422 48.5847 32.2422 52.2185L32.2422 65.068"
        stroke="#47494D"
        strokeWidth="3.32639"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.3245 31.6198C65.9052 30.9745 86.5873 46.7834 86.2738 48.0739C85.9604 49.3644 62.1449 59.0433 60.2647 59.0433C58.3844 59.0433 51.0254 55.6873 45.8499 53.5586C40.8651 51.5084 33.1588 49.6871 33.9421 48.0739C34.7254 46.4608 40.5228 42.2666 47.1035 38.395C51.9681 35.1296 56.4267 31.904 59.3245 31.6198Z"
        fill="#E5E6E6"
      />
      <path
        d="M85.4466 48.2324L64.5131 58.1956C62.4811 59.3257 60.0955 59.3257 58.0635 58.1956L35.5508 48.2324"
        stroke="#47494D"
        strokeWidth="3.32639"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="46.7538"
        cy="73.622"
        r="15.5203"
        fill="#BE0F2C"
        stroke="white"
        strokeWidth="3.32639"
      />
      <path
        d="M40.543 67.3594L53.2941 80.1105"
        stroke="#F6F7F7"
        strokeWidth="2.8429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.2941 67.3594L40.543 80.1105"
        stroke="#F6F7F7"
        strokeWidth="2.8429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
