import {
  buildTestIds,
  useHeaderContext,
  useShallow,
  useUniqueClient,
} from "application";
import { useAppContext } from "application/context/state";
import { useEffect, useRef, useState } from "react";
import { SidebarMenuRefProps } from "typing/types/SidebarMenuRefProps";
import { Container, FCLogoBlackFriday, HamburgerIcon, theme } from "ui";

import { useRouter } from "next/router";
import { Skeleton } from "../../Skeleton";
import { useMappedBackofficeComponent } from "../../backoffice/hooks";
import { withBlackThemeValidation } from "../../hocs";
import { Divider } from "../Divider/styles";
import { LinkRedirectHome } from "../LinkRedirectHome";
import { LoginAuthenticationModal } from "./components/AuthContent/Login/LoginAuthenticationModal";
import { CartButton } from "./components/CartButton";
import { ListCategories } from "./components/ListCategories";
import { NavBarDesktop } from "./components/NavBarDesktop";
import { SearchInput } from "./components/SearchInput";
import { NavBarMobile } from "./components/mobile/NavBarMobile";
import { SidebarMenuMobile } from "./components/mobile/SidebarMenuMobile";
import {
  ContentHeaderMobile,
  FlexContainerCenterHeaderCustom,
  FlexContainerNavCenterHeaderCustom,
  HamburgerIconWrapper,
  HeaderContainer,
  HeaderContainerMainContent,
  HeaderOuterContainer,
  LogoWrapper,
  MobileLogoFC,
} from "./styles";

interface ListItemsEventProps extends Event {
  path?: Array<HTMLDivElement>;
}

type HeaderProps = {
  isBlackTheme?: boolean;
};
// eslint-disable-next-line sonarjs/cognitive-complexity
const Header = ({ isBlackTheme }: HeaderProps) => {
  // const [location] = useState<BranchProps>(2);
  const { isUniqueClientModalOpen } = useUniqueClient(
    useShallow((state) => ({
      isUniqueClientModalOpen: state.isUniqueClientModalOpen,
    }))
  );
  const [isOpenSidebarMenu, setIsOpenSidebarMenu] = useState<boolean>(false);

  const sidebarMenuRef = useRef<SidebarMenuRefProps>(null);

  const { isClientMobile, isClientTablet, isPageWeddingList } = useAppContext();

  const handleOpenSidebarMenu = () => {
    setIsOpenSidebarMenu(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseOutsideSideBarMenu = (event?: ListItemsEventProps) => {
    const categoryItemsList = event?.path || [];
    const closeSidebarBoxItem = categoryItemsList.find(
      ({ id }) => id === "CloseSidebarBoxId"
    );

    const { id } = event?.target as HTMLDivElement;

    if (id === "SidebarContainerOverlayId" || closeSidebarBoxItem) {
      setIsOpenSidebarMenu(false);
      document.body.style.overflow = "visible";
    }
  };

  useEffect(() => {
    document.body.addEventListener(
      "click",
      // eslint-disable-next-line no-undef
      handleCloseOutsideSideBarMenu as unknown as EventListenerOrEventListenerObject,
      true
    );
  }, [isOpenSidebarMenu, sidebarMenuRef]);

  const router = useRouter();

  const { customHeader, isLoadingCustomHeader } = useHeaderContext();

  const customHeaderComponent = useMappedBackofficeComponent(customHeader, {
    isPageWeddingList,
    isClientMobile,
    isClientTablet,
    isLoading: isLoadingCustomHeader,
  });

  const refHeaderContainer = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isUniqueClientModalOpen) {
      setIsOpenSidebarMenu(false);
    }
  }, [isUniqueClientModalOpen]);

  return (
    <HeaderOuterContainer
      {...buildTestIds("header-outer-container")}
      isPageWeddingList={isPageWeddingList}
      isBlackTheme={isBlackTheme}
    >
      {isClientMobile && isOpenSidebarMenu ? (
        <SidebarMenuMobile
          sidebarMenuRef={sidebarMenuRef as unknown as SidebarMenuRefProps}
          isOpenSidebarMenu={isOpenSidebarMenu}
          onCloseSidebarMenu={() => {
            setIsOpenSidebarMenu(false);
            document.body.style.overflow = "visible";
          }}
        />
      ) : null}
      <HeaderContainer
        ref={refHeaderContainer}
        {...buildTestIds("header-container")}
      >
        {isLoadingCustomHeader ? (
          <Skeleton count={1} hasMargin={false} />
        ) : (
          customHeaderComponent
        )}
        <HeaderContainerMainContent
          {...buildTestIds("main-content-container-header")}
        >
          {isClientMobile ? (
            <FlexContainerNavCenterHeaderCustom
              {...buildTestIds("select-location-container-header")}
              isPageWeddingList={isPageWeddingList}
            >
              <Container>
                <NavBarMobile {...buildTestIds("nav-bar-mobile-header")} />
              </Container>
            </FlexContainerNavCenterHeaderCustom>
          ) : (
            <FlexContainerNavCenterHeaderCustom
              {...buildTestIds("select-location-container-header")}
              isPageWeddingList={isPageWeddingList}
            >
              <Container>
                <NavBarDesktop
                  {...buildTestIds("nav-bar-desktop-header")}
                  companyProductsColor={
                    isPageWeddingList
                      ? theme.colors.neutral.white
                      : (isBlackTheme && theme?.colors?.blackFriday?.["25"]) ||
                        undefined
                  }
                />
              </Container>
            </FlexContainerNavCenterHeaderCustom>
          )}
          {/* <Divider isWithoutMargin /> */}
          {isClientMobile ? (
            <Container hasNoMobilePadding>
              <ContentHeaderMobile>
                <HamburgerIconWrapper
                  {...buildTestIds("wrapper-hamburger-icon-mobile")}
                  onClick={() => handleOpenSidebarMenu()}
                >
                  <HamburgerIcon color={theme.colors.primary["200"]} />
                </HamburgerIconWrapper>

                <LinkRedirectHome hrefQueryParams="IsOpen=False">
                  {isBlackTheme ? (
                    <FCLogoBlackFriday
                      alt="Logo escrito Ferreira Costa, em branco com bordas vermelhas, black friday"
                      width="96"
                      height="36"
                    />
                  ) : (
                    <LogoWrapper {...buildTestIds("logo-wrapper")}>
                      <MobileLogoFC
                        src="https://d2urakutajlof6.cloudfront.net/logo/logo_fc.svg"
                        alt="Logo escrito Ferreira Costa, em branco com bordas vermelhas"
                        layout="fill"
                        priority
                      />
                    </LogoWrapper>
                  )}
                </LinkRedirectHome>
                <CartButton />
              </ContentHeaderMobile>
              {router?.pathname === "/" ? <LoginAuthenticationModal /> : null}
            </Container>
          ) : (
            <Container {...buildTestIds("list-categories-container")}>
              <FlexContainerCenterHeaderCustom>
                <ListCategories
                  isPageWeddingList={isPageWeddingList}
                  refHeaderContainer={refHeaderContainer}
                  {...buildTestIds("list-categories-desktop")}
                />
              </FlexContainerCenterHeaderCustom>
            </Container>
          )}
          {isClientMobile && !isPageWeddingList ? <SearchInput /> : null}
          {isClientMobile && isPageWeddingList ? null : (
            <Divider {...buildTestIds("bottom-divider-header")} isWithShadow />
          )}
        </HeaderContainerMainContent>
      </HeaderContainer>
    </HeaderOuterContainer>
  );
};

Header.displayName = "Header";

const HeaderWithBlackThemeValidation =
  withBlackThemeValidation<HeaderProps>(Header);

export { HeaderWithBlackThemeValidation as Header };
