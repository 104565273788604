import { Desk } from "ui";
import { Image, ImageProps } from "../Image";

const LogoImage = ({
  src = "https://d2urakutajlof6.cloudfront.net/logo/logo_fc.svg",
  alt = "Ferreira Costa",
  width = "180px",
  height = "77px",
  layout = "fixed",
}: Partial<ImageProps>) => {
  return (
    <Desk justifyContent="center">
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        layout={layout}
      />
    </Desk>
  );
};

export { LogoImage };
