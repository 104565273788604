import { IconProps } from "typing";
import { theme } from "../themes";

export const PrintedInvoiceIcon = ({
  width = 20,
  height = 20,
  strokeWidth = "1.5",
  color = theme.colors.neutral["350"],
  alt = "Nota fiscal impressa",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83179 11.4704H4.16443C3.24357 11.4704 2.49707 10.7238 2.49707 9.80299V4.80091C2.49707 3.88005 3.24357 3.13354 4.16443 3.13354H15.836C16.7568 3.13354 17.5033 3.88005 17.5033 4.80091V9.80299C17.5033 10.7238 16.7568 11.4704 15.836 11.4704H14.1686"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1688 7.302H5.83203V16.0783C5.83189 16.404 5.96121 16.7164 6.19152 16.9467C6.42182 17.177 6.67472 17.5003 7.00043 17.5002L8.00043 16.5002L9.00043 17.5002L10.5004 16.5002L11.5004 17.5002L12.5004 16.5002C12.8261 16.5003 13.579 17.177 13.8094 16.9467C14.0397 16.7164 14.169 16.404 14.1688 16.0783V7.302Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.5H10"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 11.4168H11.251"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.5H12"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
