import { IconProps } from "typing";
import { theme } from "../themes";

export const StatusIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.primary["155"],
  alt = "ícone de status",
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49835 19.7913C6.13361 19.0007 4.99957 17.8667 4.20898 16.502"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5019 4.20909C15.1346 3.41459 13.5813 2.99625 12 2.99658"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.0038C15.2135 20.9977 18.1811 19.2829 19.7912 16.502"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49797 4.20898C4.71698 5.81909 3.00222 8.78678 2.99609 12.0002"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12.0001"
        cy="12.0001"
        r="5.00208"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
