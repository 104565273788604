import styled from "styled-components";
import { theme } from "ui";

interface SendCodeAgainContainerProps {
  fontSize?: string;
  color?: string;
}
export const SendCodeAgainContainer = styled.span<SendCodeAgainContainerProps>`
  font-size: ${({ fontSize }) =>
    fontSize || `${theme.typography.fontSize.sm.x3}`};
  color: ${({ color }) => color || `${theme.colors.primary[150]}`};
  cursor: pointer;
`;
