import { CardCTASubComponentDto } from "typing";
import { theme } from "ui";
import { appMonitoringClient } from "implementations";
import { buildTestIds, useCurrentPathName } from "application";
import { useCallback } from "react";
import {
  CardCTASubComponentImage,
  CardCTASubComponentContent,
  CustomCta,
  CardCTASubComponentText,
  CardCTAImage,
  CardCTASubComponentDescription,
  CtaText,
  ArrowRightIcon,
  CustomCardCTASubComponentContainer,
  CardCTAText,
} from "./styles";
import { Link } from "../../../Link";
import { customCardCTASubComponentData } from "./data";
import { useBackofficeComponentUrl, useGetBranchLinkConfig } from "../../utils";
import SubComponentHeader from "./SubComponentHeader";

interface CustomCardCTASubComponentProps extends CardCTASubComponentDto {
  positionComponent: number | undefined;
  isBlackTheme?: boolean;
}

const CustomCardCTASubComponent = ({
  config,
  properties,
  isClientTablet,
  positionComponent,
  isBlackTheme,
}: CustomCardCTASubComponentProps) => {
  const linkConfig = useGetBranchLinkConfig(
    config?.branches,
    "subComponentCardCTA"
  );

  const currentPathName = useCurrentPathName();

  if (!config?.branches?.length) {
    appMonitoringClient.captureMessage(
      `Um subComponente de Card CTA, localizado em ${currentPathName} na posição ${positionComponent}, não possui nenhuma configuração de branches.`,
      "warning"
    );
  }

  const {
    image,
    showCta,
    showTitle,
    ctaText,
    title,
    color,
    showSubTitle,
    text,
    subTitle,
  } = customCardCTASubComponentData(properties);

  const withoutTitleAndSubTitle =
    (!showTitle || showTitle === "false") &&
    (!showSubTitle || showSubTitle === "false");
  const hasCta = showCta && showCta === "true";

  const renderCardCTAText = useCallback(() => {
    return (
      text && (
        <CardCTAText
          dangerouslySetInnerHTML={{ __html: text }}
          {...buildTestIds("card-cta-description-text")}
        />
      )
    );
  }, [text]);

  const url = useBackofficeComponentUrl(config?.branches, linkConfig?.url);

  return (
    <CustomCardCTASubComponentContainer
      isBlackTheme={isBlackTheme}
      {...buildTestIds("custom-card-cta-subcomponent-container")}
    >
      <CardCTASubComponentImage
        {...buildTestIds("card-cta-subcomponent-image")}
      >
        {image && (
          <CardCTAImage
            {...buildTestIds("card-cta-image")}
            layout="fill"
            src={image}
            alt="Imagem do Produto"
            loading="lazy"
          />
        )}
      </CardCTASubComponentImage>
      <CardCTASubComponentContent
        isBlackTheme={isBlackTheme}
        {...buildTestIds("card-cta-subcomponent-content")}
      >
        <CardCTASubComponentText
          {...buildTestIds("custom-card-cta-text-wrapper")}
        >
          {isClientTablet ? (
            <CardCTASubComponentDescription
              {...buildTestIds("card-cta-description-container")}
              textAlignCenter={false}
            >
              <SubComponentHeader
                showTitle={showTitle}
                showSubTitle={showSubTitle}
                title={title ?? ""}
                subTitle={subTitle ?? ""}
                isBlackTheme={isBlackTheme}
              />
              {renderCardCTAText()}
            </CardCTASubComponentDescription>
          ) : (
            <CardCTASubComponentDescription
              textAlignCenter={withoutTitleAndSubTitle}
              {...buildTestIds("card-cta-description-container")}
            >
              <SubComponentHeader
                showTitle={showTitle}
                showSubTitle={showSubTitle}
                title={title ?? ""}
                subTitle={subTitle ?? ""}
                isBlackTheme={isBlackTheme}
              />
              {renderCardCTAText()}
            </CardCTASubComponentDescription>
          )}
        </CardCTASubComponentText>
        {hasCta && (
          <CustomCta
            isBlackTheme={isBlackTheme}
            as={linkConfig?.url ? Link : "div"}
            backgroundColor={color}
            href={url}
            target={linkConfig?.target}
            {...buildTestIds("cta-of-card-cta-component")}
          >
            <CtaText {...buildTestIds("cta-text-of-card-cta-component")}>
              {ctaText}
            </CtaText>
            <ArrowRightIcon color={theme.colors.neutral["55"]} />
          </CustomCta>
        )}
      </CardCTASubComponentContent>
    </CustomCardCTASubComponentContainer>
  );
};

export default CustomCardCTASubComponent;
