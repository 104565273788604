import { TelephoneAuthenticationIcon, TelephoneErrorIcon, theme } from "ui";
import { TwoFactorContentProps } from "typing";
import { TwoFactorContentContainer } from "./styles";
import { TwoFactorContent } from "../../2fa";
import { ResponsiveModalImage } from "../../ResponsiveModal/components/ResponsiveModalContent/components";

const PhoneValidationComponentUI = ({ ...props }: TwoFactorContentProps) => {
  return (
    <TwoFactorContentContainer>
      <ResponsiveModalImage
        margin={`${theme.space.x4} 0`}
        image={
          props?.hasError ? (
            <TelephoneErrorIcon />
          ) : (
            <TelephoneAuthenticationIcon />
          )
        }
      />
      <TwoFactorContent {...props} />
    </TwoFactorContentContainer>
  );
};

export { PhoneValidationComponentUI };
