interface PasswordValidationProps {
  userPassword: string;
  userConfirmPassword?: string;
}

export const useValidatePassword = ({
  userPassword,
  userConfirmPassword,
}: PasswordValidationProps) => {
  const passwordHasNumbers = !!userPassword?.match(/\d+/g);
  const passwordHasLowerCaseLetters = !!userPassword?.match(/[a-z]+/g);
  const passwordHasUpperCaseLetters = !!userPassword?.match(/[A-Z]+/g);
  const passwordHasMoreThanSevenCharacters = userPassword?.length > 7;
  const passwordsMatch = !(
    userPassword !== userConfirmPassword ||
    userPassword?.length === 0 ||
    userConfirmPassword?.length === 0
  );

  const strongPassword = userPassword?.match(
    /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z])/
  );

  const validatePassword = {
    passwordHasNumbers,
    passwordHasLowerCaseLetters,
    passwordHasUpperCaseLetters,
    passwordHasMoreThanSevenCharacters,
    passwordsMatch,
  };

  const validItems = Object.values(validatePassword)?.filter(
    (item) => item === true
  )?.length;

  return {
    passwordHasLowerCaseLetters,
    passwordHasMoreThanSevenCharacters,
    passwordHasNumbers,
    passwordHasUpperCaseLetters,
    passwordsMatch,
    strongPassword,
    validItems,
  };
};
