import { IResponsiveModalContent } from "typing";
import { ResponsiveModalContentUI } from "./ui";
import { InputProps } from "../../../Form/Input";

const ResponsiveModalContent = ({
  ...props
}: IResponsiveModalContent & InputProps) => {
  return <ResponsiveModalContentUI {...props} />;
};

export { ResponsiveModalContent };
