import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const DeliveredPackageTrackingStatusIcon = ({
  width = 32,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Pacote Entregue",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9979 23.5032H9.33051C8.87008 23.5032 8.49683 23.1299 8.49683 22.6695V17.6674C8.49683 17.207 8.87008 16.8337 9.33051 16.8337H10.9979C11.4583 16.8337 11.8315 17.207 11.8315 17.6674V22.6695C11.8315 23.1299 11.4583 23.5032 10.9979 23.5032Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2506 9.33057V13.1655"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6694 17.6674V14.366C22.6671 13.7027 22.1289 13.166 21.4656 13.1655H13.0354C12.372 13.166 11.8338 13.7027 11.8315 14.366V18.5845"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8315 17.6674V12.6411C11.8317 12.3159 11.8975 11.9941 12.025 11.6949L12.6844 10.1526C12.8975 9.65386 13.3876 9.33039 13.9299 9.33057H20.5702C21.1142 9.32883 21.6066 9.65248 21.8207 10.1526L22.4801 11.6966C22.6064 11.996 22.6707 12.3179 22.6694 12.6428V18.0717"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8336 20.1683H18.7786C19.1394 20.1683 19.4904 20.0513 19.779 19.8348L21.7932 18.3242C22.296 17.9473 22.9995 17.9974 23.4439 18.4418V18.4418C23.6808 18.6785 23.8139 18.9997 23.8139 19.3346C23.8139 19.6696 23.6808 19.9908 23.4439 20.2275L21.7157 21.9557C21.2501 22.4213 20.6572 22.7386 20.0116 22.8678L17.5689 23.3563C17.0847 23.4531 16.5849 23.4412 16.1058 23.3213L13.8966 22.7694C13.6325 22.7028 13.3611 22.6692 13.0887 22.6693H11.8315"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8336 20.1685H18.0716C18.7692 20.1685 19.3347 19.603 19.3347 18.9054V18.6553C19.3347 18.076 18.9405 17.5711 18.3784 17.4307L16.4676 16.953C16.1572 16.8746 15.8383 16.8346 15.5181 16.8337V16.8337C14.7451 16.8335 13.9893 17.0624 13.3463 17.4915L11.8315 18.5011"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
