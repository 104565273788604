import { CardCTASubComponentDto, CustomCardCTADto } from "typing";
import { memo } from "react";
import { buildTestIds } from "application";
import { customCardCTAData } from "./data";
import { CustomCardCTAContainer, CustomCardCTAContent } from "./styles";
import CardCTASlider from "./CardCTASlider";
import CustomCardCTASubComponent from "./SubComponents";

const CustomCardCTA = memo(
  ({
    properties,
    subComponents,
    isClientTablet,
    position,
    isBlackTheme,
  }: CustomCardCTADto) => {
    const { layout } = customCardCTAData(properties);

    let arraySubComponents = [...subComponents];

    arraySubComponents = subComponents.slice(
      0,
      layout === "1 card" ? 1 : layout === "2 cards" ? 2 : 3
    );

    return (
      <CustomCardCTAContainer
        isOneCard={layout === "1 card"}
        isTwoCards={layout === "2 cards"}
        isThreeCards={layout === "3 cards"}
        {...buildTestIds("custom-card-cta-container")}
        customTheme={isBlackTheme}
      >
        {isClientTablet && layout === "3 cards" ? (
          <CardCTASlider
            positionComponent={position}
            subComponents={subComponents}
          />
        ) : (
          arraySubComponents?.map((subComponent, index) => {
            const componentKey = `${subComponent.type}#${index}`;
            return (
              <CustomCardCTAContent
                key={componentKey}
                {...buildTestIds("custom-card-cta-content")}
              >
                <CustomCardCTASubComponent
                  {...(subComponent as CardCTASubComponentDto)}
                  isBlackTheme={isBlackTheme}
                  positionComponent={position}
                />
              </CustomCardCTAContent>
            );
          })
        )}
      </CustomCardCTAContainer>
    );
  }
);

CustomCardCTA.displayName = "customCardCTA";

export { CustomCardCTA };
