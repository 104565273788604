import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const SearchBoxIcon = ({
  width = 175,
  height = 175,
  color = theme.colors.neutral.white,
  secondColor = theme.colors.neutral["550"],
  thirdColor = theme.colors.neutral["white-075"],
  fourthColor = theme.colors.neutral["150"],
  fifthColor = theme.colors.neutral["88"],
  sixthColor = theme.colors.primary["350"],
  alt = "ícone de uma lupa e uma caixa",
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 175 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_983_36665)">
        <rect width={width} height={height} rx="87.5" fill={thirdColor} />
        <path
          d="M46.7791 75.0557L75.1409 74.7754V151.441L67.6844 152.154V97.3224L57.1334 96.1204C57.5862 93.4206 57.1013 90.6472 55.759 88.2614C53.5182 84.3596 46.7791 83.6435 46.7791 83.6435V75.0557Z"
          fill={fourthColor}
        />
        <path
          d="M12.0676 115.273H30.9365L33.0321 151.292H12.0676V115.273Z"
          fill={fourthColor}
        />
        <path
          d="M118.773 118.703H137.642L139.738 154.722H118.773V118.703Z"
          fill={fifthColor}
        />
        <path
          d="M42.9222 115.273H60.7227V151.292H40.3625L42.9222 115.273Z"
          fill={fourthColor}
        />
        <path
          d="M149.628 118.703H167.429V154.722H147.068L149.628 118.703Z"
          fill={fifthColor}
        />
        <path
          d="M8.97437 100.536H63.6192V111.437H8.97437V100.536Z"
          fill={fourthColor}
        />
        <path
          d="M118.066 98.7773L172.038 107.326L170.333 118.092L116.361 109.544L118.066 98.7773Z"
          fill={fifthColor}
        />
        <path
          d="M47.9528 21.5381H76.2285L74.9789 69.0131H46.7791L47.9528 21.5381Z"
          fill={fourthColor}
        />
        <path
          d="M28.8303 41.8262L35.2875 43.9105L36.1437 49.2221L32.2239 62.7335L19.9499 58.6309L24.0744 45.6182L28.8303 41.8262Z"
          fill={fourthColor}
        />
        <path
          d="M83.676 21.5381H107.511V69.0902H82.2168L83.676 21.5381Z"
          fill={fourthColor}
        />
        <path
          d="M82.2168 74.7734H107.511V150.916H82.2168V74.7734Z"
          fill={fourthColor}
        />
        <path
          d="M115.618 32.9668H140.913C140.913 32.9668 133.199 38.6813 133.199 50.9242C133.199 63.1671 141.41 68.9227 143.339 83.4746C145.267 98.0264 143.339 98.7733 143.339 98.7733H115.618C115.618 98.7733 113.304 98.0174 115.618 83.4746C117.932 68.9317 124.893 63.2069 124.893 50.9203C124.924 47.3991 124.096 43.9233 122.48 40.7947C120.863 37.666 118.508 34.979 115.618 32.9668Z"
          fill={fourthColor}
        />
        <path
          d="M41.7923 91.8521C44.7724 88.872 50.492 87.8667 52.6236 91.8521C54.7551 95.8374 49.6551 100.54 49.6551 100.54H37.7336C37.7336 100.54 38.8123 94.8321 41.7923 91.8521Z"
          fill={fourthColor}
        />
        <path
          d="M32.4821 91.8521C29.5021 88.872 23.7824 87.8667 21.6508 91.8521C19.5193 95.8374 24.6206 100.54 24.6206 100.54H36.5408C36.5408 100.54 35.4621 94.8321 32.4821 91.8521Z"
          fill={fourthColor}
        />
        <path
          d="M30.7092 48.4437C31.7742 48.4437 32.6376 47.5804 32.6376 46.5153C32.6376 45.4503 31.7742 44.5869 30.7092 44.5869C29.6441 44.5869 28.7808 45.4503 28.7808 46.5153C28.7808 47.5804 29.6441 48.4437 30.7092 48.4437Z"
          fill={color}
        />
        <path
          d="M48.3217 21.6504C43.8407 22.7483 39.7821 25.1414 36.6522 28.531C32.123 33.883 31.3774 42.3911 31.3774 42.3911C31.3774 42.3911 30.489 32.6462 35.0195 27.2942C39.55 21.9422 48.3217 21.6504 48.3217 21.6504Z"
          fill={fourthColor}
        />
        <path
          d="M146.011 76.2773L140.314 79.9618L140.861 85.3141L148.145 97.3505L158.939 90.2109L151.587 78.7092L146.011 76.2773Z"
          fill={fourthColor}
        />
        <path
          d="M145.911 83.1546C146.939 82.8788 147.55 81.8212 147.274 80.7925C146.998 79.7638 145.941 79.1535 144.912 79.4293C143.883 79.7051 143.273 80.7626 143.549 81.7913C143.825 82.82 144.882 83.4304 145.911 83.1546Z"
          fill={color}
        />
        <path
          d="M121.963 61.834C126.576 61.7344 131.116 62.995 135.017 65.4586C140.78 69.4552 143.699 77.4835 143.699 77.4835C143.699 77.4835 142.033 67.8386 136.27 63.842C130.507 59.8454 121.963 61.834 121.963 61.834Z"
          fill={fourthColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.878 70.3894V118.125C126.878 122.957 122.96 126.875 118.128 126.875H56.8779C52.0455 126.875 48.1279 122.957 48.1279 118.125V70.3894C48.1263 69.2029 48.3659 68.0285 48.8323 66.9375L54.6073 53.4231C55.9881 50.2068 59.1528 48.1229 62.6529 48.125H112.353C115.856 48.125 119.022 50.2148 120.399 53.4363L126.174 66.9375C126.639 68.0287 126.879 69.203 126.878 70.3894Z"
          fill={color}
          stroke={secondColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.1279 70H126.834"
          stroke={secondColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M95.477 48.8965L99.8519 66.9089V78.5269C99.7436 80.1512 98.0626 81.3986 96.0895 81.3189H77.3647C75.3916 81.3986 73.7106 80.1512 73.6023 78.5269V66.9089L77.9772 48.8965"
          stroke={secondColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.2495 113.75H69.9995"
          stroke={secondColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.453 108.68C129.453 120.907 119.526 130.823 107.275 130.823C95.0246 130.823 85.0972 120.907 85.0972 108.68C85.0972 96.453 95.0246 86.5371 107.275 86.5371C119.526 86.5371 129.453 96.453 129.453 108.68Z"
          fill="#FEF4F5"
          stroke={secondColor}
          strokeWidth="3"
        />
        <path
          d="M124.466 123.606L123.405 122.546L121.282 124.666L122.343 125.726L124.466 123.606ZM138.364 141.723C138.95 142.308 139.9 142.308 140.486 141.723C141.073 141.137 141.073 140.188 140.486 139.603L138.364 141.723ZM122.343 125.726L138.364 141.723L140.486 139.603L124.466 123.606L122.343 125.726Z"
          fill={secondColor}
        />
        <path
          d="M106.19 112.091V110.674C106.19 110.136 106.32 109.681 106.578 109.306C106.836 108.932 107.238 108.467 107.785 107.914C108.331 107.376 108.741 106.896 109.014 106.473C109.303 106.049 109.447 105.545 109.447 104.958C109.447 104.307 109.273 103.786 108.923 103.395C108.589 102.988 108.081 102.784 107.398 102.784C106.593 102.784 106.031 103.029 105.712 103.517C105.409 104.006 105.257 104.559 105.257 105.178L105.28 105.96H102.068C102.023 105.52 102 105.219 102 105.056C102 104.209 102.22 103.395 102.66 102.614C103.116 101.832 103.754 101.205 104.573 100.733C105.393 100.244 106.327 100 107.375 100H107.876C108.939 100 109.857 100.228 110.631 100.684C111.406 101.14 111.99 101.75 112.385 102.516C112.795 103.281 113 104.112 113 105.007C113 105.756 112.879 106.408 112.636 106.961C112.408 107.515 112.135 107.971 111.816 108.329C111.497 108.671 111.087 109.054 110.586 109.477C110.13 109.884 109.796 110.21 109.584 110.454C109.386 110.698 109.288 110.983 109.288 111.309V112.091H106.19ZM106.054 113.409H109.402V117H106.054V113.409Z"
          fill={sixthColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_983_36665">
          <rect width={width} height={height} rx="87.5" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
