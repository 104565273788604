import { buildTestIds } from "application";
import { ReactSpringBottomSheet, ReactSpringBottomSheetProps } from "infra";

const BottomSheet = (props: ReactSpringBottomSheetProps) => {
  return (
    <ReactSpringBottomSheet
      {...buildTestIds("react-spring-bottom-sheet")}
      {...props}
    />
  );
};

export { BottomSheet };
