import { IconProps } from "typing";
import { theme } from "../themes";

export const PackageIcon = ({
  color = theme.colors.neutral["750"],
  width = 18,
  height = 18,
  alt = "Pacote",
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7527 6.06443V14.2511C15.7527 15.0799 15.0808 15.7517 14.2521 15.7517H3.7477C2.91893 15.7517 2.24707 15.0799 2.24707 14.2511V6.06443C2.24679 5.86095 2.28789 5.65954 2.36787 5.47244L3.35829 3.15472C3.5951 2.60313 4.13783 2.24573 4.73811 2.24609H13.2617C13.8625 2.24609 14.4054 2.6045 14.6415 3.15697L15.6319 5.47244C15.7118 5.65958 15.7529 5.86097 15.7527 6.06443Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.24707 5.9982H15.7452"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5006 2.24609L11.2509 5.99766V8.41741C11.2323 8.75571 10.944 9.01551 10.6056 8.99891H7.39429C7.05589 9.01551 6.7676 8.75571 6.74902 8.41741V5.99766L7.49934 2.24609"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.49805 13.5021H5.99867"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
