import { AuthenticationProps } from "typing";

// const requestsWaitingForRefreshedAccessToken: string[] = [];

// const isRequestAlreadyBeingDone = (requestPath: string) => {
//   return requestsWaitingForRefreshedAccessToken?.includes(requestPath);
// };

// const addRequestToWaitRefreshAccessToken = (requestPath: string) => {
//   requestsWaitingForRefreshedAccessToken.push(requestPath);
// };

// const removeRequestFromWaitRefreshAccessToken = (requestPath: string) => {
//   const requestPathIndex = requestsWaitingForRefreshedAccessToken.findIndex(
//     (currentRequest) => currentRequest === requestPath
//   );

//   if (requestPathIndex > -1) {
//     requestsWaitingForRefreshedAccessToken.splice(requestPathIndex, 1);
//   }
// };

const isValidatingAccessToken = () => {
  return !!sessionStorage?.getItem("isValidatingAccessToken");
};

const setIsValidatingAccessToken = (value: boolean) => {
  if (value) {
    sessionStorage?.setItem("isValidatingAccessToken", String(value));
    return;
  }

  sessionStorage?.removeItem("isValidatingAccessToken");
};

const refreshAccessToken = async (
  refreshToken: string
): Promise<AuthenticationProps | number | null> => {
  const response = await fetch(`/api/identity/token/refresh`, {
    method: "POST",
    body: JSON.stringify({
      refreshToken,
    }),
  });

  return response.json();
};

export {
  isValidatingAccessToken,
  refreshAccessToken,
  setIsValidatingAccessToken,
};
