import { IconProps } from "typing";
import { theme } from "../themes";

export const CheckPayment = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["550"],
  alt = "cheque",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.062 3.48228C16.734 2.81028 17.824 2.80928 18.496 3.48228V3.48228C19.168 4.15428 19.168 5.24428 18.496 5.91628L10.722 13.6853C10.534 13.8723 10.28 13.9783 10.015 13.9783H8V11.9633C8 11.6983 8.105 11.4443 8.293 11.2563L16.062 3.48228V3.48228Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13.9783H17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 17.9783H17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10.9783V10.9783C20.105 10.9783 21 11.8733 21 12.9783V19.9783C21 21.0833 20.105 21.9783 19 21.9783H5C3.895 21.9783 3 21.0833 3 19.9783V12.9783C3 11.8733 3.895 10.9783 5 10.9783V10.9783"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0703 5.47827L16.5003 7.90827"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0664 6.04429L11.7074 4.40329C12.1814 3.92929 12.9504 3.92929 13.4254 4.40329V4.40329C13.8994 4.87729 13.8994 5.64629 13.4254 6.12129L12.2844 7.26229"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
