import styled from "styled-components";
import { Button, CartIcon, FlexContainer, theme, Title } from "ui";

export const CustomProductInnerContainer = styled(FlexContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${theme.space.x20};
  padding: ${`${theme.space.x0} ${theme.space.x39} ${theme.space.x0}
    ${theme.space.x63}`};
  width: 100%;
  max-height: fit-content;
  background-color: ${theme.colors.neutral.white};
  margin: auto;

  @media ${theme.device.desktopFirst.tablet} {
    gap: ${theme.space.x14};
    padding: ${`${theme.space.x0} ${theme.space.x8} ${theme.space.x0}
    ${theme.space.x17}`};
  }

  @media ${theme.device.desktopFirst.tabletS} {
    gap: ${theme.space.x12};
    padding: ${`17px ${theme.space.x3} 17px ${theme.space.x3}`};
    max-height: 220px;
    min-height: 220px;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    gap: ${theme.space["x5.5"]};
  }
`;

interface ProductImageContainerProps {
  isinsidecarousel?: boolean;
}

export const ProductImageContainer = styled(
  FlexContainer
)<ProductImageContainerProps>`
  flex-direction: column;
  justify-content: center;

  min-width: 335px;
  max-width: 335px;
  min-height: 335px;
  max-height: 335px;

  @media ${theme.device.desktopFirst.laptop} {
    min-width: 250px;
    max-width: 250px;
  }

  @media ${theme.device.desktopFirst.tablet} {
    max-height: 220px;
    min-height: 220px;
  }

  @media ${theme.device.desktopFirst.tabletS} {
    max-height: 200px;
    min-height: 200px;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    min-width: 150px;
    max-width: 150px;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    min-width: 120px;
    max-width: 120px;
  }
`;

export const ProductDetailsContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 356px;
  gap: ${theme.space.x2};
  margin: 8px 0;

  @media ${theme.device.desktopFirst.tabletM} {
    width: 195px;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    gap: ${theme.space.x1};
    width: 180px;
  }
`;

export const ProductTitle = styled(Title)`
  font-size: ${theme.typography.fontSize.md.x1};
  font-weight: ${theme.typography.fontWeight["600"]};
  color: ${theme.colors.neutral["715"]};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  overflow: hidden;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const BoxTags = styled.div`
  display: flex;
  position: relative;
  min-height: 2rem;

  @media ${theme.device.desktopFirst.tabletM} {
    margin-bottom: 0;
  }
`;

export const TagProductWrapper = styled.div`
  display: flex;
  gap: 8px;

  @media ${theme.device.desktopFirst.tabletM} {
    flex-direction: column;
  }
`;

export const TagProductPriceOnlySiteContainer = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.secondary["250"]};
  background: ${theme.colors.secondary["250"]};
  max-width: fit-content;

  @media ${theme.device.desktopFirst.mobileXL} {
    background: ${theme.colors.secondary["28"]};
    border-radius: 4px;
    height: 20px;
    border: none;
  }
`;

export const TagProductPriceOnlySiteText = styled.span`
  color: ${theme.colors.neutral.white};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["500"]};
  line-height: 0.92rem;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    color: ${theme.colors.secondary["380"]};
  }
`;

export const TagIcon = styled.span`
  margin-right: 0.5rem;
  display: flex;
`;

interface TagCampaignBoxProductProps {
  backgroundColor: string;
  borderColor: string;
}

export const TagCampaignBoxProduct = styled.section<TagCampaignBoxProductProps>`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: max-content;
  border: 1px solid
    ${({ borderColor }) => borderColor || theme.colors.neutral["750"]};
  background: ${({ backgroundColor }) =>
    backgroundColor || theme.colors.neutral["490"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    background: ${theme.colors.neutral["999"]};
    border-radius: 4px;
    height: 20px;
  }
`;

export const TagTextCampaignBoxProduct = styled.span`
  color: ${({ color }) => color || theme.colors.neutral.white};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["500"]};
  user-select: none;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }
`;

export const ProductBuyButton = styled(Button)`
  display: flex;
  flex-direction: row;
  gap: ${theme.space.x2};
  transition: background-color 0.2s;
  margin-top: ${theme.space.x2};

  @media ${theme.device.desktopFirst.tablet} {
    display: none;
  }
`;

export const CustomCartIcon = styled(CartIcon).attrs({
  color: theme.colors.neutral.white,
  width: "30px",
  height: "30px",
})``;
