import { CustomBannerDto } from "typing";
import { memo } from "react";
import { buildTestIds, useCurrentPathName } from "application";
import { Container } from "ui";
import { useRouter } from "next/router";
import { gtag } from "implementations";
import {
  CustomCommonBannerContainer,
  CustomBannerImage,
  CustomBannerImageWrapper,
} from "./style";
import { useBackofficeComponentUrl, useGetBranchLinkConfig } from "../utils";
import {
  customBannerData,
  getBannerTypeValidation,
  getImageFormat,
  handleComponentWarnings,
} from "./data";
import { Link } from "../../Link";

const CustomBanner = memo(
  ({
    properties,
    config,
    position,
    isClientMobile = false,
    isinsidecarousel = false,
    isinsidecarouselingridformat = false,
    isBannerComponent = true,
    preloadComponent = false,
  }: CustomBannerDto) => {
    const { title, desktopImage, mobileImage, desktopFormat, mobileFormat } =
      customBannerData(properties);

    const linkConfig = useGetBranchLinkConfig(
      config?.branches,
      "subComponentBanner"
    );
    const currentPathName = useCurrentPathName();

    handleComponentWarnings(config, currentPathName, position || -1);

    const format = getImageFormat(
      !!isinsidecarouselingridformat,
      !!isinsidecarousel,
      !!isClientMobile,
      mobileFormat,
      desktopFormat
    );

    const router = useRouter();

    const bannerTypeValidation = getBannerTypeValidation(
      !!isinsidecarousel,
      !!isinsidecarouselingridformat
    );

    const gtagPromotionId = `${title}_${linkConfig?.url}_${linkConfig?.id}`;

    const gtagSelectPromotion = () => {
      gtag.selectPromotion({
        locationId: router.asPath,
        promotionId: gtagPromotionId,
        promotionName: title as string,
        creativeName: bannerTypeValidation,
        creativeSlot: bannerTypeValidation,
      });
    };

    const url = useBackofficeComponentUrl(config?.branches, linkConfig?.url);

    // const image = isClientMobile ? mobileImage || "" : desktopImage || "";

    const hasImage = mobileImage || desktopImage;

    const image = isClientMobile ? mobileImage || "" : desktopImage || "";

    return (
      <CustomCommonBannerContainer
        {...buildTestIds("custom-banner-container")}
        isToRedirect={!!linkConfig}
        as={format === "extended" ? "section" : Container}
        format={format}
        onClick={() => {
          gtagSelectPromotion();
        }}
        isBannerComponent={isBannerComponent}
      >
        <CustomBannerImageWrapper
          {...buildTestIds("custom-banner-image-wrapper")}
          as={linkConfig?.url ? Link : "section"}
          href={url}
          target={linkConfig?.target}
          format={format}
        >
          {hasImage && (
            <CustomBannerImage
              {...buildTestIds("custom-banner-image")}
              src={image}
              alt={title as string}
              layout="fill"
              objectFit={isClientMobile ? "contain" : "cover"}
              objectPosition="center"
              isinsidecarousel={String(isinsidecarousel)}
              isinsidecarouselingridformat={String(
                isinsidecarouselingridformat
              )}
              format={format}
              loading="eager"
              priority={preloadComponent}
            />
          )}
        </CustomBannerImageWrapper>
      </CustomCommonBannerContainer>
    );
  }
);

CustomBanner.displayName = "CustomBanner";

export { CustomBanner };
