import {
  buildTestIds,
  useAppContext,
  useAuth,
  useBranchLocation,
  useEnvs,
} from "application";
import {
  isPageReloadByZipCodeSearch,
  setIsPageReloadByZipCodeSearch,
} from "application/hooks/validateClientBranchChange/pageReload";
import { profileApi, shippingApi } from "implementations";
import { useRouter } from "next/router";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Search, theme } from "ui";
import { Loading } from "../Loading";
import { Skeleton } from "../Skeleton";
import { InputCepComponent } from "./InputZipCode";
import { BoxActionZipCode, ButtonSearch, ZipCodeForm } from "./styles";
import { useGetAddressByCity } from "../patterns/Header/components/ModalBranchLocation/hooks";

interface ButtonSearchZipCodeProps {
  returnInfoClickButton: (StringZipCode: string) => void;
  isLoading: boolean;
  returnWithHyphen?: boolean;
  setIsNumericKeyboardActive?: (value: boolean) => void;
}

const keyZipCodeStr = "@FC:Ecom:Dropdown:Cep";

const ButtonSearchZipCode = ({
  returnInfoClickButton,
  isLoading = false,
  returnWithHyphen = false,
  setIsNumericKeyboardActive,
}: ButtonSearchZipCodeProps) => {
  const allConfigs = useEnvs((state) => state.allConfigs);
  const router = useRouter();
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token as string;
  const { isChangingClientLocation, regionClientLocation, clientLocation } =
    useBranchLocation();
  const { addressByCityList } = useGetAddressByCity(
    regionClientLocation ?? "",
    true,
    regionClientLocation ?? "",
    shippingApi
  );
  const { data: addresses, isLoading: isLoadingAddress } =
    profileApi.getAllAddresses(
      accessToken,
      !!accessToken && allConfigs?.isAutoLoadShippingInfo === "true"
    );
  const productPage = router?.pathname?.includes("/produto/");
  const defaultAddress = addresses?.find(
    (address) => address?.isDefault === true
  )?.zipCode;

  const routerId = router?.query?.region_id;

  const clientZipCode = localStorage?.getItem(keyZipCodeStr);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedZipCode, setSelectedZipCode] = useState<string>(
    clientZipCode || ""
  );

  const [selectedZipCodeSearched, setSelectedZipCodeSearched] = useState<
    string | null
  >(null);
  const [isMouseOverInput, setIsMouseOverInput] = useState<boolean>(false);
  const { isClientMobile } = useAppContext();

  useEffect(() => {
    if (isChangingClientLocation) {
      returnInfoClickButton("");
      setSelectedZipCode("");
      setSelectedZipCodeSearched("");
    }
  }, [isChangingClientLocation, returnInfoClickButton]);

  useEffect(() => {
    if (isFirstRender) {
      if (isPageReloadByZipCodeSearch()) {
        returnInfoClickButton(selectedZipCode);
        setIsPageReloadByZipCodeSearch(false);
      }

      if (routerId) {
        setSelectedZipCode("");
        localStorage?.setItem(keyZipCodeStr, "");
      }

      setIsFirstRender(false);
    }
  }, [isFirstRender, returnInfoClickButton, selectedZipCode, routerId]);

  const onClickButtonToSearch = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      const formattedSelectedZipCode = selectedZipCode?.replace("-", "");

      if (
        formattedSelectedZipCode?.length === 8 &&
        (!clientZipCode ||
          (clientZipCode && clientZipCode !== formattedSelectedZipCode))
      ) {
        localStorage?.setItem(keyZipCodeStr, formattedSelectedZipCode);
        setSelectedZipCode(formattedSelectedZipCode);
      }

      if (
        formattedSelectedZipCode?.length === 8 &&
        selectedZipCodeSearched !== formattedSelectedZipCode
      ) {
        localStorage?.setItem(keyZipCodeStr, formattedSelectedZipCode);
        setSelectedZipCodeSearched(formattedSelectedZipCode);
      }

      if (returnWithHyphen) {
        return returnInfoClickButton(selectedZipCode);
      }

      return returnInfoClickButton(selectedZipCode.replace("-", ""));
    },
    [
      clientZipCode,
      returnInfoClickButton,
      returnWithHyphen,
      selectedZipCode,
      selectedZipCodeSearched,
    ]
  );

  useEffect(() => {
    if (
      accessToken &&
      !clientZipCode &&
      defaultAddress &&
      productPage &&
      allConfigs?.isAutoLoadShippingInfo === "true" &&
      !routerId
    ) {
      setSelectedZipCode(defaultAddress);
      setSelectedZipCodeSearched(defaultAddress);
      returnInfoClickButton(defaultAddress.replace("-", ""));
      return;
    }

    if (
      clientZipCode &&
      !isLoadingAddress &&
      productPage &&
      allConfigs?.isAutoLoadShippingInfo === "true"
    ) {
      setSelectedZipCode(clientZipCode);
      returnInfoClickButton(clientZipCode.replace("-", ""));
      setIsPageReloadByZipCodeSearch(false);
    }
  }, [
    accessToken,
    allConfigs?.isAutoLoadShippingInfo,
    clientZipCode,
    defaultAddress,
    isLoadingAddress,
    productPage,
    returnInfoClickButton,
    routerId,
  ]);

  const isKnowLocation: boolean = regionClientLocation !== "Demais regiões";

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (routerId) {
      setSelectedZipCode("");
      return;
    }

    if (accessToken && !isLoadingAddress) {
      if (defaultAddress && !clientZipCode) {
        localStorage?.setItem(keyZipCodeStr, defaultAddress);
        return;
      }

      if (clientZipCode) {
        localStorage?.setItem(keyZipCodeStr, clientZipCode);
        return;
      }

      if (isKnowLocation && addressByCityList && addressByCityList?.[0]?.cep) {
        localStorage?.setItem(keyZipCodeStr, addressByCityList[0].cep);
        return;
      }

      localStorage?.setItem(keyZipCodeStr, "");
      return;
    }

    if (!accessToken && !routerId) {
      if (clientZipCode) {
        localStorage?.setItem(keyZipCodeStr, clientZipCode);
        return;
      }

      if (isKnowLocation && addressByCityList?.[0]?.cep) {
        localStorage?.setItem(keyZipCodeStr, addressByCityList?.[0]?.cep);
        return;
      }

      localStorage?.setItem(keyZipCodeStr, "");
    }
  }, [
    accessToken,
    clientLocation,
    addresses,
    regionClientLocation,
    isLoadingAddress,
    addressByCityList,
    clientZipCode,
    isKnowLocation,
    defaultAddress,
    routerId,
  ]);

  if (isLoadingAddress) {
    return <Skeleton count={1} width={210} height={48} />;
  }

  return (
    <BoxActionZipCode>
      {isClientMobile ? (
        <ZipCodeForm onSubmit={onClickButtonToSearch} aria-disabled={isLoading}>
          <InputCepComponent
            id="mobile-zipcode-input"
            name="mobile-zipcode-input"
            className="cep-input"
            inputMode="numeric"
            placeholder={isMouseOverInput ? "_____-___" : "00000-000"}
            mask="99999-999"
            maxLength={9}
            value={selectedZipCode}
            onChange={(e) => setSelectedZipCode(e?.target?.value)}
            onFocus={() =>
              setIsNumericKeyboardActive && setIsNumericKeyboardActive(true)
            }
            onBlur={() =>
              setIsNumericKeyboardActive && setIsNumericKeyboardActive(false)
            }
            onMouseOver={() => setIsMouseOverInput(true)}
            onMouseOut={() => setIsMouseOverInput(false)}
            disableField={isLoading}
          />
          <ButtonSearch
            isLoading={isLoading}
            title="Consultar opções de frete"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loading color={theme.colors.secondary["400"]} />
            ) : (
              <Search color={theme.colors.neutral.white} height={28} />
            )}
          </ButtonSearch>
        </ZipCodeForm>
      ) : (
        <ZipCodeForm onSubmit={onClickButtonToSearch}>
          <InputCepComponent
            id="desktop-zipcode-input"
            name="desktop-zipcode-input"
            className="cep-input"
            inputMode="numeric"
            placeholder={isMouseOverInput ? "_____-___" : "00000-000"}
            mask="99999-999"
            maxLength={9}
            minLength={9}
            value={selectedZipCode}
            onChange={(e) => setSelectedZipCode(e?.target?.value)}
            onMouseOver={() => setIsMouseOverInput(true)}
            onMouseOut={() => setIsMouseOverInput(false)}
            disableField={isLoading}
          />
          <ButtonSearch
            {...buildTestIds("zip-code-search-button")}
            isLoading={isLoading}
            title="Consultar opções de frete"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loading
                {...buildTestIds("zip-code-search-button-loading")}
                color={theme.colors.secondary["400"]}
                type="dotted"
              />
            ) : (
              <Search
                {...buildTestIds("zip-code-search-button-icon")}
                color={theme.colors.neutral.white}
                height={28}
              />
            )}
          </ButtonSearch>
        </ZipCodeForm>
      )}
    </BoxActionZipCode>
  );
};

export { ButtonSearchZipCode };
