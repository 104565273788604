import { ReactSlick } from "infra";
import { memo, useCallback, useMemo, useState } from "react";
import { SliderProps } from "typing";
import { ArrowCarouselLeft, ArrowCarouselRight } from "ui";
import { buildTestIds } from "application";
import { ButtonArrowNext } from "./DefaultNextButton/style";
import { ButtonArrowPrev } from "./DefaultPrevButton/style";
import { ControlDot } from "./style";
import { withBlackThemeValidation } from "../hocs";

const Slider = memo(
  ({
    children,
    PrevButton,
    NextButton,
    swipe,
    verticalSwiping,
    touchMove,
    arrowsIsActive,
    arrowNextIsInCenter,
    arrowPrevIsInCenter,
    vertical,
    slidesToScroll,
    slidesToShow,
    speed,
    autoplay,
    autoplaySpeed = 3500,
    infinite,
    dots = false,
    responsive,
    swipeToSlide,
    customTheme,
    isBlackTheme,
    arrowButtonsSize,
  }: SliderProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const isBlackThemeValue = !!customTheme || isBlackTheme;

    const controlDot = useCallback(
      (index: number) => {
        return <ControlDot isSelected={index === currentIndex} />;
      },
      [currentIndex]
    );

    const DefaultNextButton = useMemo(
      () => (
        <ButtonArrowNext
          {...buildTestIds("button-arrow-next")}
          isCentralized={!!arrowNextIsInCenter}
          customTheme={isBlackThemeValue}
          arrowButtonsSize={arrowButtonsSize}
        >
          <ArrowCarouselRight
            alt="botão de próximo do carrosel"
            themeColors={customTheme?.arrowColor}
          />
        </ButtonArrowNext>
      ),
      [
        arrowButtonsSize,
        arrowNextIsInCenter,
        customTheme?.arrowColor,
        isBlackThemeValue,
      ]
    );

    const DefaultPrevButton = useMemo(
      () => (
        <ButtonArrowPrev
          {...buildTestIds("button-arrow-prev")}
          isCentralized={!!arrowPrevIsInCenter}
          buttonArrowTheme={customTheme}
          customTheme={isBlackThemeValue}
          arrowButtonsSize={arrowButtonsSize}
        >
          <ArrowCarouselLeft
            alt="botão de voltar do carrosel"
            themeColors={customTheme?.arrowColor}
          />
        </ButtonArrowPrev>
      ),
      [arrowButtonsSize, arrowPrevIsInCenter, customTheme, isBlackThemeValue]
    );

    return (
      <ReactSlick
        {...{
          speed,
          slidesToShow,
          slidesToScroll,
          vertical,
          arrows: arrowsIsActive,
          touchMove,
          verticalSwiping,
          swipe,
          prevArrow: PrevButton || DefaultPrevButton,
          nextArrow: NextButton || DefaultNextButton,
          autoplay,
          autoplaySpeed,
          infinite,
          dots,
          responsive,
          swipeToSlide,
          beforeChange: (_, nextIndex) => {
            const normalizedIndex = nextIndex / slidesToShow;
            setCurrentIndex(normalizedIndex);
          },
          customPaging: (index) => controlDot(index),
        }}
      >
        {children}
      </ReactSlick>
    );
  }
);

Slider.displayName = "Slider";
const SliderWithBlackThemeValidation =
  withBlackThemeValidation<SliderProps>(Slider);

export { SliderWithBlackThemeValidation as Slider };
