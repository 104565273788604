import { IconProps } from "typing";
import { theme } from "../themes";

export const CreditCard = ({
  width = 32,
  height = 32,
  color = theme.colors.neutral["999"],
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0021 18.0025V12C17.0022 11.4693 16.7915 10.9603 16.4162 10.585C16.0409 10.2098 15.5319 9.999 15.0012 9.99915H4.99708C4.46638 9.999 3.95738 10.2098 3.58212 10.585C3.20686 10.9603 2.9961 11.4693 2.99625 12V18.0025C2.9961 18.5332 3.20686 19.0422 3.58212 19.4174C3.95738 19.7927 4.46638 20.0035 4.99708 20.0033H15.0012C16.1063 20.0033 17.0021 19.1075 17.0021 18.0025Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99625 14.0799H17.0021"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.19757 9.99913L7.40807 5.47724C7.54542 4.96477 7.88105 4.52801 8.34089 4.26337C8.80074 3.99872 9.34699 3.92794 9.8591 4.06665L19.5231 6.65773C20.0356 6.79508 20.4724 7.13071 20.737 7.59055C21.0016 8.05039 21.0724 8.59665 20.9337 9.10875L19.3831 14.9012C19.1071 15.9436 18.0521 16.5776 17.0021 16.3318"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CartIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.12329 2.26021C2.71472 2.19211 2.3283 2.46813 2.2602 2.8767C2.1921 3.28528 2.46812 3.6717 2.8767 3.7398L5.2061 4.12803C5.30728 4.1449 5.38789 4.22186 5.40943 4.32215L6.14636 7.74701L7.51827 15.1159C7.60981 15.5856 8.02127 15.9246 8.4998 15.9246H18.5569C19.0211 15.9246 19.4243 15.6052 19.5304 15.1534L21.1844 8.11437C21.3319 7.48673 20.8556 6.88562 20.2109 6.88562H7.49403L6.876 4.00725C6.72526 3.30521 6.16097 2.76649 5.4527 2.64844L3.12329 2.26021ZM9.11278 14.4246L7.81611 8.38562H19.5798L18.1608 14.4246H9.11278Z"
        fill={color}
      />
      <circle
        cx="9.38818"
        cy="19.514"
        r="1.73614"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="16.7643"
        cy="19.514"
        r="1.73614"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
