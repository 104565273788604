import styled, { css } from "styled-components";
import { Apple, EyeClose, EyeOpen, GoogleColored, theme } from "ui";
import { Label } from "../../../../../Form";

interface ButtonOAuthProps {
  typeAuthentication: "Apple" | "Google";
  isUserAuthenticating: boolean;
}

interface InputAuthProps {
  hasIcon?: boolean;
}

interface AppleOAuthIconProps {
  isHovered: boolean;
}

interface TitleForgotPasswordProps {
  isMyCartPage?: boolean;
}

interface AuthContentContainerProps {
  isPageLogin?: boolean;
}

export const AuthContentContainer = styled.section<AuthContentContainerProps>`
  padding: ${({ isPageLogin }) => (isPageLogin ? "3rem 0 2rem" : "0")};
  width: ${({ isPageLogin }) => (isPageLogin ? "360px" : "auto")};
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: 16px;
    margin-top: 2rem;
    width: 100%;
    display: block;
  }
`;

export const WrapperDivider = styled.aside`
  width: 100%;
  padding: ${theme.space.x4} ${theme.space.x0};
  display: flex;
  align-items: center;
`;

export const OptionText = styled.p`
  padding: ${theme.space.x0} ${theme.space.x4};
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.neutral["350"]} !important;
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight[400]};
`;

export const AuthenticationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const WrapperOAuthButtons = styled.section`
  display: flex;
  align-items: center;
  gap: ${theme.space.x4};
  margin: 0 !important;
`;

export const ButtonAuthenticationOAuth = styled.button<ButtonOAuthProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 100px;
  border: 0.5px solid ${theme.colors.neutral["330"]};
  gap: 0.75rem;
  cursor: pointer;

  background: ${({ typeAuthentication }) =>
    typeAuthentication !== "Google"
      ? theme.colors.neutral["999"]
      : theme.colors.neutral.white};

  transition: 0.2s ease-in-out;

  &:hover {
    background: ${({ typeAuthentication }) =>
      typeAuthentication === "Google" &&
      theme.colors.neutral["999-opacity-15"]};
  }

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
`;

export const AppleOAuthIcon = styled(Apple).attrs<AppleOAuthIconProps>(
  ({ isHovered }) => ({
    color: isHovered ? theme.colors.neutral["580"] : theme.colors.neutral.white,
    height: 20,
    width: 20,
  })
)<AppleOAuthIconProps>``;

export const GoogleOAuthIcon = styled(GoogleColored).attrs({
  color: theme.colors.neutral.white,
  height: 22,
  width: 22,
})``;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${theme.typography.fontSize.sm.x3};
  gap: ${theme.space.x6};
  color: ${theme.colors.neutral["400"]};
  margin-bottom: ${theme.space.x6};
  width: 100%;
`;

export const WrapperRow = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${theme.space.x2};
  width: 100%;
`;

export const PasswordContainer = styled.div`
  display: flex;
`;

export const FormWrapper = styled.form`
  width: 100%;
`;

export const LabelCustom = styled(Label)`
  font-size: ${theme.typography.fontSize.sm.x7};
  color: ${theme.colors.neutral["750"]} !important;
  font-weight: ${theme.typography.fontWeight[400]};
  letter-spacing: -0.24px;
`;

export const InputAuth = styled.input<InputAuthProps>`
  width: 100%;
  height: 45px;
  font-family: ${theme.typography.fontFamily.chivo};
  padding-left: ${theme.space.x3};
  border: 1px solid ${theme.colors.neutral["580"]};
  border-radius: 4px 0px 0px 4px;
  background: transparent;
  border-radius: ${theme.space.x1};
  color: ${theme.colors.neutral["999"]} !important;
  border-right-width: ${({ hasIcon }) => hasIcon && "0"};

  &::placeholder {
    color: ${theme.colors.neutral["350"]} !important;
    font-size: ${theme.typography.fontSize.sm.x5};
    font-weight: ${theme.typography.fontWeight[400]};
  }

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      border-right-width: 0;
      border-radius: ${theme.space.x1} ${theme.space.x0} ${theme.space.x0}
        ${theme.space.x1};
    `}
`;

export const TitleForgotPassword = styled.span<TitleForgotPasswordProps>`
  cursor: pointer;
  font-size: ${theme.typography.fontSize.sm.x5};
  font-family: ${theme.typography.fontFamily.chivo};
  text-decoration: underline;
  color: ${theme.colors.neutral["550"]} !important;

  ${({ isMyCartPage }) =>
    isMyCartPage &&
    css`
      font-weight: ${theme.typography.fontWeight.bold};
    `}
`;

export const WrapperNewUser = styled.aside`
  margin: 1.8rem 0 0.9rem 0;
`;

export const TitleNewUser = styled.span`
  font-size: ${theme.typography.fontSize.sm.x6};
  color: ${theme.colors.neutral["520"]};
  font-family: ${theme.typography.fontFamily.chivo};
`;

export const LinkCustom = styled.span`
  margin: 0 !important;
  justify-content: flex-start !important;
  cursor: pointer;
  color: ${theme.colors.neutral["100"]} !important;
`;

export const ContainerIconEye = styled.span`
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.35rem;
  border-left: none;
  cursor: pointer;
  border: 1px solid ${theme.colors.neutral["580"]};
  border-radius: 0px 4px 4px 0px;
`;

export const EyeOpenIcon = styled(EyeOpen).attrs({
  width: 24,
  height: 24,
  color: theme.colors.neutral["200"],
})``;

export const EyeCloseIcon = styled(EyeClose).attrs({
  width: 24,
  height: 24,
  color: theme.colors.neutral["200"],
})``;

export const WrapperRecaptcha = styled.div``;

export const PasswordInfo = styled.span`
  font-size: 0.83rem;
  font-weight: ${theme.typography.fontWeight[400]};
  font-family: ${theme.typography.fontFamily.chivo};
  color: ${theme.colors.neutral["550"]};
  letter-spacing: -0.2px;
`;

export const AccessAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x6};
  width: 100%;
`;

export const PrivacyPolicy = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
  justify-content: center;
  margin: 2rem 0;
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${theme.colors.neutral["550"]};
  font-weight: ${theme.typography.fontWeight[400]};
  letter-spacing: -0.4px;
  text-align: center;

  @media ${theme.device.desktopFirst.tablet} {
    flex-wrap: wrap;
  }
`;

export const PrivacyPolicyLink = styled.span`
  letter-spacing: -0.4px;
  text-decoration: underline;
`;

export const LinkText = styled.span`
  text-decoration: underline;
  font-size: ${theme.typography.fontSize.sm.x5};
  font-family: ${theme.typography.fontFamily.chivo};
  color: ${theme.colors.neutral["550"]} !important;
`;

export const CartContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
  margin: ${theme.space["x0.5"]} ${theme.space.x0} ${theme.space.x4};
`;

export const CompleteOrderText = styled.span`
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${theme.colors.neutral["525"]};
  font-weight: ${theme.typography.fontWeight[400]};
`;

export const LoginText = styled.span`
  font-size: ${theme.typography.fontSize.md.x1};
  color: ${theme.colors.neutral["525"]};
  font-weight: ${theme.typography.fontWeight.bold};
`;

export const LoginDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.neutral["580"]} !important;
`;
