import styled from "styled-components";
import { theme } from "ui";

interface DescriptionContainerProps {
  marginDescription?: string;
}

export const DescriptionContainer = styled.section<DescriptionContainerProps>`
  color: ${theme.colors.neutral["525"]} !important;
  font-size: 1.16667rem;
  font-weight: ${theme.typography.fontWeight["400"]};
  display: inline;
  margin: ${({ marginDescription }) => marginDescription || "0"};
`;

export const ClientInfoContent = styled.span`
  color: ${theme.colors.neutral["525"]} !important;
  white-space: wrap;
`;

export const InvalidClientInfo = styled.span`
  color: ${theme.colors.primary["350"]} !important;
  cursor: pointer;
  margin-left: ${theme.space.x1};
`;

export const InfoValue = styled.span`
  color: ${theme.colors.neutral["525"]} !important;
  font-weight: ${theme.typography.fontWeight["700"]};
  margin-left: ${theme.space.x2};
`;
