import { AddressDto } from "typing";
import { createStore } from "../adapter";

type MyCardsState = {
  createdAddress: AddressDto | null;
};

type MyCardsDispatch = {
  setCreatedAddress: (createdAddress: AddressDto | null) => void;
};

type MyCardsProps = MyCardsState & MyCardsDispatch;

const useMyCards = createStore<MyCardsProps>((set) => ({
  createdAddress: null,
  setCreatedAddress: (createdAddress: AddressDto | null) => {
    set({ createdAddress });
  },
}));

export { useMyCards };
