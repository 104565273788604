import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ICookie, IIdentityApi } from "app-domain/abstractions";
import { useRouter } from "next/router";
import { useCountdown } from "../hooks/Countdown";

export interface ModalGroupContextData {
  modalToConfirmEmail: boolean;
  modalChangePassword: boolean;
  handleOpenModalToConfirmEmail(currentPath?: string): void;
  handleCloseModalToConfirmEmail(): void;
  handleCloseModalChangePassword(): void;
  getCookieEmailWasConfirmed(): boolean | null;
  sendOrResendEmailConfirmation: (
    email: string,
    condition?: boolean
  ) => Promise<{
    status?: number | undefined;
  } | null>;
  handleTriggerModalEmailConfirmation: (
    emailVerified: boolean,
    email: string
  ) => Promise<(() => void) | null>;
  handleTriggerModalChangePassword: (
    isWeakPassword: boolean
  ) => Promise<(() => void) | null>;
}

export interface ModalGroupProviderProps {
  children: ReactNode;
  cookie: ICookie;
  identityApi: IIdentityApi;
}

const cookieModalToConfirmEmail =
  "@FC:Ecom:ModalToConfirmEmail:HadVisualization";

const optionsMaxAgeEqualOneYear = {
  options: {
    maxAge: 60 * 60 * 24 * 365,
  },
};

const ModalGroupContext = createContext({} as ModalGroupContextData);

export const ModalGroupProvider = ({
  children,
  cookie,
  identityApi,
}: ModalGroupProviderProps) => {
  const router = useRouter();
  const [modalToConfirmEmail, setModalToConfirmEmail] =
    useState<boolean>(false);
  const [modalChangePassword, setModalChangePassword] =
    useState<boolean>(false);
  const { changeConditionToStartCountdown } = useCountdown();

  const getCookieEmailWasConfirmed = useCallback(() => {
    const response = cookie.getCookie({
      name: "@FC:EmailWasConfirmed",
    });

    if (response === undefined) return null;

    return Boolean(response ?? false);
  }, [cookie]);

  const handleOpenModalChangePassword = useCallback(() => {
    setModalChangePassword(true);
  }, []);

  const handleCloseModalChangePassword = useCallback(() => {
    setModalChangePassword(false);
  }, []);

  const handleOpenModalToConfirmEmail = useCallback(
    async (currentPath = "") => {
      cookie.setCookie({
        name: "@FC:Ecom:Destination:ConfirmEmail",
        value: router.asPath,
      });

      changeConditionToStartCountdown(true);

      if (!currentPath) {
        const hadVisualizationModalToConfirmEmailInActualSession =
          cookie.getCookie({
            name: cookieModalToConfirmEmail,
          });

        if (!hadVisualizationModalToConfirmEmailInActualSession) {
          setModalToConfirmEmail(true);
          cookie.setCookie({
            name: cookieModalToConfirmEmail,
            value: String(true),
            options: optionsMaxAgeEqualOneYear.options,
          });
        }
      } else {
        setModalToConfirmEmail(true);
      }
    },
    [changeConditionToStartCountdown, cookie, router]
  );

  const handleCloseModalToConfirmEmail = useCallback(() => {
    setModalToConfirmEmail(false);
  }, []);

  const sendOrResendEmailConfirmation = useCallback(
    async (email: string, condition = true) => {
      if (condition) {
        const { data } = await identityApi.sendEmailConfirmation(email);

        if (data === null) return null;

        return data;
      }
      return null;
    },
    [identityApi]
  );

  const handleTriggerModalEmailConfirmation = useCallback(
    async (emailVerified: boolean, email: string) => {
      const routesMatch = ["/cadastro", "/login"].some(
        (route) => route === router.route
      );
      const emailIsNotVerifiedAndEmail = !emailVerified;

      if (emailIsNotVerifiedAndEmail && routesMatch) {
        router.events.on("routeChangeStart", (url) => {
          if (url !== "/cadastro" || url !== "/login") {
            handleOpenModalToConfirmEmail();
            sendOrResendEmailConfirmation(email);
          }
        });

        return () => {
          router.events.off("routeChangeStart", () => {
            handleOpenModalToConfirmEmail();
            sendOrResendEmailConfirmation(email);
          });
        };
      }

      if (emailIsNotVerifiedAndEmail && !routesMatch) {
        handleOpenModalToConfirmEmail();
        sendOrResendEmailConfirmation(email);
      }

      return null;
    },
    [
      handleOpenModalToConfirmEmail,
      router.events,
      router.route,
      sendOrResendEmailConfirmation,
    ]
  );

  const handleTriggerModalChangePassword = useCallback(
    async (isWeakPassword: boolean) => {
      const routesMatch = ["/login"].some((route) => route === router.route);
      const routesShouldNotOpenModalChangePasswordMatch = [
        "/checkout/meu-carrinho",
        "/checkout/pagamento",
        "/checkout/done",
      ].some((route) => route === router.route);

      if (isWeakPassword && routesMatch) {
        router.events.on("routeChangeStart", (url) => {
          if (url !== "/login") {
            handleOpenModalChangePassword();
          }
        });

        return () => {
          router.events.off("routeChangeStart", () => {
            handleOpenModalChangePassword();
          });
        };
      }

      if (
        isWeakPassword &&
        !routesMatch &&
        !routesShouldNotOpenModalChangePasswordMatch
      ) {
        handleOpenModalChangePassword();
      }

      return null;
    },
    [handleOpenModalChangePassword, router.events, router.route]
  );

  const modalGroupContextProviderValue = useMemo(() => {
    return {
      modalToConfirmEmail,
      modalChangePassword,
      handleCloseModalToConfirmEmail,
      handleOpenModalToConfirmEmail,
      handleTriggerModalChangePassword,
      sendOrResendEmailConfirmation,
      handleTriggerModalEmailConfirmation,
      getCookieEmailWasConfirmed,
      handleCloseModalChangePassword,
    };
  }, [
    modalToConfirmEmail,
    modalChangePassword,
    handleCloseModalToConfirmEmail,
    handleOpenModalToConfirmEmail,
    handleTriggerModalChangePassword,
    sendOrResendEmailConfirmation,
    handleTriggerModalEmailConfirmation,
    getCookieEmailWasConfirmed,
    handleCloseModalChangePassword,
  ]);

  return (
    <ModalGroupContext.Provider value={modalGroupContextProviderValue}>
      {children}
    </ModalGroupContext.Provider>
  );
};

export const useModalGroup = () => {
  const modalGroupContext = useContext(ModalGroupContext);
  if (!modalGroupContext) {
    throw new Error("useModalGroup must be used within an ModalGroupProvider");
  }
  return modalGroupContext;
};
