import {
  BroadcastChannelProvider,
  CardsProviderProps,
  CartProvider,
  CountdownProviderProps,
  EventListenerProviderProps,
  HeaderProvider,
  ModalGroupProviderProps,
  NotificationProviderProps,
  PurchaseProviderProps,
  RecaptchaContextProviderProps,
  WebSocketContextProviderProps,
  obfuscate,
  sendItemToCartByServer,
  useInitializeAllConfigs,
} from "application";
import { BranchLocationProvider } from "application/context/BranchLocationContext";
import dynamic from "next/dynamic";

import { Layout } from "components";

import { CookiesProvider } from "application/context/CookiesContext";
import { AuthProvider, UserProvider } from "application/context/Identity/";
import { AppWrapper } from "application/context/state";

import { ModalAlertProps, ToastProviderProps } from "typing";

import {
  allowMeFingerprint,
  appMonitoringClient,
  backofficeApi,
  browserBroadcast,
  catalogApi,
  clearSaleFingerprint,
  clientIp,
  cookie,
  crm,
  encrypt,
  envsApi,
  eventEmitter,
  gtag,
  heatmapClient,
  identityApi,
  jwtDecoder,
  notificationApi,
  paymentApi,
  profileApi,
  purchaseApi,
  shippingApi,
  shoppingApi,
  stateManager,
  webSocketClient,
} from "implementations";
import { GenesysChatBot } from "infra";
import { NextPage } from "next";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { Fragment, ReactElement, ReactNode, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "ui";
import { GlobalStyles } from "../src/styles";
import "../src/styles/fonts.css";
import "../whyDidYouRender";

declare const window: Window &
  // eslint-disable-next-line no-undef
  typeof globalThis & {
    addToCart: (productId: string, quantity: number) => void;
  };

const ToastProvider = dynamic<ToastProviderProps>(() =>
  import("application/hooks/Toast").then((module) => module.ToastProvider)
);

const PopUpAlertConfirm = dynamic<ModalAlertProps>(() =>
  import("components/PopUpAlertConfirm").then(
    (module) => module.PopUpAlertConfirm
  )
);

const PurchaseProvider = dynamic<PurchaseProviderProps>(() =>
  import("application/context/Purchase/PurchaseContext").then(
    (module) => module.PurchaseProvider
  )
);

const CookiesModal = dynamic<Record<string, never>>(() =>
  import("components/CookiesModal").then((module) => module.CookiesModal)
);

const ModalGroup = dynamic<Record<string, never>>(() =>
  import("components/ModalGroup").then((module) => module.ModalGroup)
);

const CardsProvider = dynamic<CardsProviderProps>(() =>
  import("application/context/CardsContext").then(
    (module) => module.CardsProvider
  )
);

const CountdownProvider = dynamic<CountdownProviderProps>(() =>
  import("application/hooks/Countdown").then(
    (module) => module.CountdownProvider
  )
);

const EventListenerProvider = dynamic<EventListenerProviderProps>(() =>
  import("application/context/EventListener").then(
    (module) => module.EventListenerProvider
  )
);

const ModalGroupProvider = dynamic<ModalGroupProviderProps>(() =>
  import("application/context/ModalGroupContext").then(
    (module) => module.ModalGroupProvider
  )
);

const NotificationProvider = dynamic<NotificationProviderProps>(() =>
  import("application/context/Notification/NotificationContext").then(
    (module) => module.NotificationProvider
  )
);

const RecaptchaContextProvider = dynamic<RecaptchaContextProviderProps>(() =>
  import("application/context/Recaptcha").then(
    (module) => module.RecaptchaContextProvider
  )
);

const WebSocketContextProvider = dynamic<WebSocketContextProviderProps>(() =>
  import("application/context/WebSocket").then(
    (module) => module.WebSocketContextProvider
  )
);

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("web vital metrics: ", metric);
  }
}

const MyApp = ({
  Component,
  pageProps: { ...pageProps },
}: AppPropsWithLayout) => {
  const [showChild, setShowChild] = useState(false);
  const router = useRouter();

  const hostEnv = process.env.NEXT_PUBLIC_HOST_ENV;
  const encryptionKey = process.env.NEXT_PUBLIC_ENCRYPTION_KEY ?? "";
  const ivKey = process.env.NEXT_PUBLIC_IV_KEY ?? "";

  useEffect(() => {
    function addToCartFn(productId: string, quantity: number) {
      sendItemToCartByServer({
        productId,
        quantity,
        shoppingApi,
      });
    }
    window.addToCart = addToCartFn;
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    setShowChild(true);
  }, []);

  useInitializeAllConfigs(envsApi);

  encrypt.initialize(encryptionKey, ivKey);

  if (!showChild) {
    return null;
  }

  if (typeof window === "undefined") {
    return <Fragment key="fragment-base" />;
  }

  return (
    <>
      {/*  Não dá para cachearmos o script da insider, a url atual se mantém atualizada automaticamente por eles */}
      <Script
        src={`https://ferreiracosta.api.useinsider.com/ins.js?id=${obfuscate(
          crm.insiderId || ""
        )}`}
        strategy="afterInteractive"
      />
      <Script
        strategy="lazyOnload"
        src={`https://fcx-s3-fercos-backoffice.ferreiracosta.com/scripts/google-tag-manager/gtag.js?id=${obfuscate(
          gtag.GA_TRACKING_ID || ""
        )}`}
      />
      <Script id="google-tag-manager" strategy="lazyOnload">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${obfuscate(
          gtag.GTM_ID || ""
        )}');
      `}
      </Script>
      <Script
        id="google-tag-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${obfuscate(
              gtag.GA_TRACKING_ID || ""
            )}', { 'debug_mode': ${
            hostEnv !== "production" && hostEnv !== "qas"
          } });

            gtag('config', '${obfuscate(gtag.GTM_ID || "")}', { 'debug_mode': ${
            hostEnv !== "production" && hostEnv !== "qas"
          } });

          ${
            hostEnv !== "production" &&
            hostEnv !== "qas" &&
            `
            gtag('config', '${obfuscate(
              gtag.GA_TRACKING_ID_DEV || ""
            )}', { 'debug_mode': true });`
          }
          `,
        }}
      />

      <Script
        src="https://fcx-s3-fercos-backoffice.ferreiracosta.com/scripts/standout/standout-ferreiracosta.js"
        strategy="lazyOnload"
      />
      <GenesysChatBot.Script
        jwtDecoder={jwtDecoder}
        purchaseApi={purchaseApi}
      />
      {heatmapClient.Script ? <heatmapClient.Script /> : null}
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <CountdownProvider>
          <ToastProvider>
            <RecaptchaContextProvider>
              <SessionProvider
                session={(pageProps as { session: Session }).session}
              >
                <ModalGroupProvider cookie={cookie} identityApi={identityApi}>
                  <WebSocketContextProvider
                    webSocketClient={webSocketClient}
                    appMonitoringClient={appMonitoringClient}
                    eventEmitter={eventEmitter}
                  >
                    <BroadcastChannelProvider
                      broadcastChannelClient={browserBroadcast}
                    >
                      <AuthProvider
                        identityApi={identityApi}
                        appMonitoringClient={appMonitoringClient}
                        cookie={cookie}
                        jwtDecoder={jwtDecoder}
                        heatmapClient={heatmapClient}
                        purchaseApi={purchaseApi}
                        profileApi={profileApi}
                      >
                        <UserProvider
                          profileApi={profileApi}
                          crm={crm}
                          cookie={cookie}
                          appMonitoringClient={appMonitoringClient}
                        >
                          <CardsProvider
                            paymentApi={paymentApi}
                            appMonitoringClient={appMonitoringClient}
                          >
                            <AppWrapper
                              catalogApi={catalogApi}
                              stateManager={stateManager}
                            >
                              <BranchLocationProvider
                                cookie={cookie}
                                appMonitoringClient={appMonitoringClient}
                                shippingApi={shippingApi}
                              >
                                <CookiesProvider
                                  cookie={cookie}
                                  heatmapClient={heatmapClient}
                                >
                                  <CartProvider
                                    cookie={cookie}
                                    shoppingApi={shoppingApi}
                                    catalogApi={catalogApi}
                                    PopUpAlertConfirm={PopUpAlertConfirm}
                                    appMonitoringClient={appMonitoringClient}
                                  >
                                    <PurchaseProvider
                                      purchaseApi={purchaseApi}
                                      paymentApi={paymentApi}
                                      shippingApi={shippingApi}
                                      clearSaleFingerprint={
                                        clearSaleFingerprint
                                      }
                                      allowMeFingerprint={allowMeFingerprint}
                                      appMonitoringClient={appMonitoringClient}
                                      clientIp={clientIp}
                                    >
                                      <NotificationProvider
                                        notificationApi={notificationApi}
                                      >
                                        <HeaderProvider
                                          backofficeApi={backofficeApi}
                                          cookie={cookie}
                                        >
                                          <EventListenerProvider
                                            cookie={cookie}
                                          >
                                            {Component.getLayout ? (
                                              <>
                                                {Component.getLayout(
                                                  <Component {...pageProps} />
                                                )}
                                                <CookiesModal />
                                                <ModalGroup />
                                              </>
                                            ) : (
                                              <>
                                                <Layout>
                                                  <Component {...pageProps} />
                                                </Layout>
                                                <ModalGroup />
                                                <CookiesModal />
                                              </>
                                            )}
                                          </EventListenerProvider>
                                        </HeaderProvider>
                                      </NotificationProvider>
                                    </PurchaseProvider>
                                  </CartProvider>
                                </CookiesProvider>
                              </BranchLocationProvider>
                            </AppWrapper>
                          </CardsProvider>
                        </UserProvider>
                      </AuthProvider>
                    </BroadcastChannelProvider>
                  </WebSocketContextProvider>
                </ModalGroupProvider>
              </SessionProvider>
            </RecaptchaContextProvider>
          </ToastProvider>
        </CountdownProvider>
      </ThemeProvider>
    </>
  );
};

export default MyApp;
