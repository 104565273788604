import { useEffect, useState } from "react";

const useShowRegisterPhoneNumberModal = (
  isLoadingUserPhoneNumber: boolean | undefined,
  userHasValidPhoneNumberRegistered: boolean | undefined
) => {
  const [isRegisterPhoneNumberModalOpen, setIsRegisterPhoneNumberModalOpen] =
    useState(false);

  useEffect(() => {
    if (
      isLoadingUserPhoneNumber !== undefined &&
      isLoadingUserPhoneNumber === false &&
      !userHasValidPhoneNumberRegistered &&
      !sessionStorage?.getItem("loggedUserHasRegisteredPhoneNumber")
    ) {
      setIsRegisterPhoneNumberModalOpen(true);
    }
  }, [isLoadingUserPhoneNumber, userHasValidPhoneNumberRegistered]);

  return isRegisterPhoneNumberModalOpen;
};

export { useShowRegisterPhoneNumberModal };
