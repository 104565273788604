import { IconProps } from "typing";
import { theme } from "../themes";

export const InfoOutlineIcon = ({
  width = 20,
  height = 20,
  alt = "Informativo",
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3V3C14.1425 3 17.5 6.3575 17.5 10.5V10.5C17.5 14.6425 14.1425 18 10 18V18C5.8575 18 2.5 14.6425 2.5 10.5V10.5C2.5 6.3575 5.8575 3 10 3Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0002 10.9167V6.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99933 13.8333C9.88433 13.8333 9.791 13.9267 9.79183 14.0417C9.79183 14.1567 9.88517 14.25 10.0002 14.25C10.1152 14.25 10.2085 14.1567 10.2085 14.0417C10.2085 13.9267 10.1152 13.8333 9.99933 13.8333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
