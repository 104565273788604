import { BlackFridayProductListDto } from "typing";
import { theme } from "../../../attributes/themes";

export const blackFridayProductListTheme: BlackFridayProductListDto = {
  horizontal: {
    titleColor: theme.colors.blackFriday.white,
    descriptionColor: theme.colors.blackFriday["115"],
    oldPriceColor: theme.colors.blackFriday["45"],
    priceColor: theme.colors.blackFriday["175"],
    staleText: theme.colors.blackFriday["230"],
    sliderButton: {
      backgroundColor: {
        default: theme.colors.blackFriday["50"],
        disabled: theme.colors.blackFriday["110"],
        hover: theme.colors.blackFriday["420"],
      },
      arrowColor: {
        default: theme.colors.blackFriday["20"],
        disabled: theme.colors.blackFriday["40"],
        hover: theme.colors.blackFriday.white,
      },
    },
    ctaButton: {
      backgroundColor: {
        default: theme.colors.blackFriday["320"],
        disabled: "",
        hover: "",
      },
      textColor: {
        default: theme.colors.blackFriday.white,
        disabled: "",
        hover: "",
      },
    },
    tagCta: {
      backgroundColor: { default: theme.colors.blackFriday["17"] },
      textColor: { default: theme.colors.blackFriday["320"] },
    },
  },
  vertical: {
    titleColor: theme.colors.blackFriday.white,
    descriptionColor: "#4A4A4A",
    oldPriceColor: "#8F9194",
    priceColor: theme.colors.blackFriday["480"],
    staleText: "#0A0A0A",
    ctaButton: {
      backgroundColor: {
        default: theme.colors.blackFriday["320"],
        disabled: "",
        hover: "",
      },
      textColor: {
        default: theme.colors.blackFriday.white,
        disabled: "",
        hover: "",
      },
    },
    tagCta: {
      backgroundColor: { default: theme.colors.blackFriday["17"] },
      textColor: { default: theme.colors.blackFriday["320"] },
    },
    seeMoreButton: {
      backgroundColor: {
        default: theme.colors.blackFriday["480"],
        disabled: "",
        hover: "",
      },
      textColor: {
        default: theme.colors.blackFriday["20"],
        disabled: "",
      },
    },
  },
};
