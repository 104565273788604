import styled from "styled-components";
import { theme } from "ui";

export const PasteCodeContainer = styled.div`
  color: ${theme.colors.neutral["110"]} !important;
  display: flex;
  align-items: center;
  gap: ${theme.space.x1};
  cursor: pointer;
`;

export const PasteCodeIcon = styled.div`
  color: ${theme.colors.neutral["110"]} !important;
  width: 16px;
  height: 16px;

  svg {
    path {
      stroke: ${theme.colors.neutral["110"]} !important;
    }
  }
`;

export const ClipboardText = styled.span`
  color: ${theme.colors.neutral["110"]} !important;
  font-size: ${theme.typography.fontSize.sm.x5};
  font-family: ${theme.typography.fontFamily.chivo};
`;
