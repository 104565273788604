import styled from "styled-components";
import { TypographyProps } from "ui/Typography/typography";
import { theme, H2, H3 } from "ui";

export interface WrapperProps {
  width?: string;
  height?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width || theme.space["x1/1"]};
  height: ${({ height }) => height || theme.space["x1/1"]};
`;

export const CategoryBlockTitle = styled(H2)<TypographyProps>`
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x5};
  font-weight: ${({ fontWeight }) =>
    fontWeight || theme.typography.fontWeight.normal};
  margin: ${({ margin }) =>
    margin || `${theme.space["x2.5"]} ${theme.space.x0} ${theme.space.x2}`};
  ${({ color }) => color && `color: ${color};`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  text-align: ${({ textAlign }) => textAlign || "start"};
`;

export const CategoryBlockSubTitle = styled(H3)<TypographyProps>`
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x5};
  font-weight: ${({ fontWeight }) =>
    fontWeight || theme.typography.fontWeight.normal};
  margin: ${({ margin }) =>
    margin || `${theme.space["x2.5"]} ${theme.space.x0} ${theme.space.x2}`};
  ${({ color }) => color && `color: ${color};`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  text-align: ${({ textAlign }) => textAlign || "start"};
`;
