import styled from "styled-components";
import { theme } from "ui";

interface ReactLoadingSkeletonProps {
  gap?: string;
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
  marginBottom?: string;
}

interface SkeletonProps {
  hasMargin?: boolean;
}

export const SkeletonWrapper = styled.section<SkeletonProps>`
  margin: ${({ hasMargin }) =>
    hasMargin ? `${theme.space.x4} ${theme.space.x0}` : "0"};
`;

export const SkeletonFlexContainer = styled.section<ReactLoadingSkeletonProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems ?? "flex-start"};
  flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
  flex-wrap: wrap;
  gap: ${({ gap }) => gap ?? `${theme.space.x12} ${theme.space.x6}`};
  justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "0"};
`;

interface SkeletonCardWrapperProps {
  width?: string;
}
export const SkeletonCardWrapper = styled.div<SkeletonCardWrapperProps>`
  position: relative;
  width: ${({ width }) => width ?? "auto"};
`;

export const SkeletonCircle = styled.div`
  position: absolute;
  left: 36%;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  background-color: ${theme.colors.neutral["125"]};

  top: -35px;
  z-index: 2;
`;

export const SkeletonChip = styled.div`
  background-color: ${theme.colors.neutral["100"]};
  width: 120px;
  height: 27px;
  font-size: 1.17rem;
  font-weight: 400;
  padding: 0.4rem 0.65rem;
  border-radius: 4px;
`;

export const BoxContainer = styled.div`
  margin-bottom: 0.8rem;
`;

export const ItemWrapper = styled.section``;

interface SkeletonContainerProps {
  width?: string | number;
  borderColor?: string;
}

export const SkeletonContainer = styled.div<SkeletonContainerProps>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width ?? "auto"};
  border-bottom: 1px solid
    ${({ borderColor }) => borderColor ?? `${theme.colors.neutral["555"]}`};
  padding: ${theme.space.x4} 0;
`;
