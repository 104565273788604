import styled from "styled-components";
import { theme } from "ui";

export const FormAddNewAddressWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.neutral.white["80"]};
`;

export const NewAddressModal = styled.section`
  width: 50%;
  height: 100%;
  position: relative;
  color: ${theme.colors.neutral["520"]};
  background-color: transparent;
  flex: 1;
  align-items: center;
  z-index: 100;
`;

export const SectionModal = styled.section`
  width: auto;

  @media ${theme.device.desktopFirst.mobileS} {
    margin-top: auto;
    position: relative;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    height: calc(70vh - 100px);

    margin-top: auto;
    position: relative;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    height: 25.9rem;
  }
`;
