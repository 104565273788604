import { IBroadcastChannelClient } from "application";
import { BrowserChannelsNames } from "typing";

class BroadcastChannelRaw implements IBroadcastChannelClient {
  private broadcastChannel: BroadcastChannel | null = null;

  private isChannelOpen = false;

  private isCreatingBroadcastInstance = false;

  async createChannel(channelName: BrowserChannelsNames) {
    if (
      !this.broadcastChannel?.name &&
      !this.isChannelOpen &&
      !this.isCreatingBroadcastInstance
    ) {
      this.isCreatingBroadcastInstance = true;

      try {
        this.broadcastChannel = new BroadcastChannel(channelName);
        this.isCreatingBroadcastInstance = false;
        this.isChannelOpen = true;
      } catch {
        this.isCreatingBroadcastInstance = false;
      }
    }
  }

  closeChannel() {
    if (this.broadcastChannel) {
      this.broadcastChannel.close();
      this.broadcastChannel = null;
      this.isChannelOpen = false;
    }
  }

  getChannelName(): string {
    return this.broadcastChannel?.name || "";
  }

  postMessage<T>(message: string | T): void {
    const messageString =
      typeof message === "string" ? message : JSON.stringify(message);
    if (this.broadcastChannel) {
      this.broadcastChannel.postMessage(messageString);
    }
  }

  onMessage(callback: (message: string) => void) {
    if (this.broadcastChannel) {
      this.broadcastChannel.onmessage = (event) => {
        callback(event.data);
      };
    }
  }

  getIsChannelOpen() {
    return this.isChannelOpen;
  }
}

export { BroadcastChannelRaw };
