import { FormNewAddressProps } from "typing";
import { useAppContext, useToast } from "application";
import { theme } from "ui";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { AccountBaseModal } from "../AccountBaseModal";
import { FormNewAddress } from "../FormNewAddress";
import { FormAddNewAddressWrapper } from "./styles";
import { NewFormAddress } from "../NewFormAddress";

export interface ModalNewAddressProps extends FormNewAddressProps {
  isOpen: boolean;
  handleStatusCloseModal: (status: boolean) => void;
}

const ModalNewAddress = ({
  isOpen,
  handleStatusCloseModal,
  isMyAddress,
  ...formProps
}: ModalNewAddressProps) => {
  const { isClientTabletXL, isClientMobile, isClientTabletM } = useAppContext();
  const { addToast } = useToast();
  const router = useRouter();

  const isMyAddressPage = router?.pathname?.includes("meus-enderecos");

  const shouldNotRenderNewFormAddress = useMemo(() => {
    return Boolean(isClientMobile && isMyAddress && isMyAddressPage);
  }, [isClientMobile, isMyAddress, isMyAddressPage]);

  const modalWidth = useMemo(() => {
    if (isClientTabletM) {
      return "95vw";
    }

    if (isClientTabletXL) {
      return "75vw";
    }

    return "fit-content";
  }, [isClientTabletM, isClientTabletXL]);

  return (
    <AccountBaseModal
      backgroundColor={theme.colors.neutral.white}
      buttonWidth="20.8125rem"
      displayHeader="none"
      overlay={theme.colors.neutral["999-opacity-60"]}
      boxShadow={`7px 7px 7px ${theme.colors.neutral["999-opacity-60"]}`}
      isOpen={shouldNotRenderNewFormAddress ? false : isOpen}
      borderRadius={isClientTabletXL ? theme.space.x7 : theme.space.x4}
      contentLabel="Adicione novo endereço"
      onCloseModal={(event) => {
        handleStatusCloseModal(false);
        event?.stopPropagation();
      }}
      height="4rem"
      width={modalWidth}
      title="Adicionar novo endereço"
      buttonText="Adicionar novo endereço"
      AlternativeButtonText="Voltar"
      buttonTextUnderAction="Cadastrando endereço..."
      buttonFormId="form-new-address"
      buttonType="button"
      padding={isClientTabletXL ? "2rem" : "1.35rem 2rem"}
      hasFooterShadow
      hasFooter={false}
      hasBaseFooter={false}
    >
      <FormAddNewAddressWrapper>
        {isMyAddress ? (
          <NewFormAddress
            {...formProps}
            onCloseModal={() => {
              handleStatusCloseModal(false);
            }}
            onActionAfterCreateNewAddress={() => {
              handleStatusCloseModal(false);
              addToast({
                isNewToast: true,
                newToastTheme: "light",
                type: "success",
                title: "Endereço adicionado.",
                description: "Seu endereço foi registrado com sucesso.",
                timerInMilliseconds: 3000,
              });
            }}
            onActionAfterEditAddress={() => {
              handleStatusCloseModal(false);
              addToast({
                isNewToast: true,
                newToastTheme: "light",
                type: "success",
                title: "Endereço alterado.",
                description:
                  "Parabéns, você concluiu sua alteração com sucesso.",
                timerInMilliseconds: 3000,
              });
            }}
          />
        ) : (
          <FormNewAddress
            {...formProps}
            hasSpaceBottom
            hasSpaceTop
            onActionAfterCreateNewAddress={() => {
              handleStatusCloseModal(false);
              addToast({
                type: "success",
                title: "Seu endereço foi registrado com sucesso!",
                timerInMilliseconds: 3000,
              });
            }}
          />
        )}
      </FormAddNewAddressWrapper>
    </AccountBaseModal>
  );
};

export { ModalNewAddress };
