import { IconProps } from "typing";
import { theme } from "../themes";

export const Search = ({
  width = 32,
  height = 32,
  color = theme.colors.neutral["999"],
  strokeWidth = "1.5",
  alt = "ícone de pesquisa",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <circle
        cx="10.8024"
        cy="10.7981"
        r="6.05241"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M15.9662 14.8671L15.4359 14.3368L14.3752 15.3974L14.9055 15.9278L15.9662 14.8671ZM19.508 20.5302C19.8009 20.8231 20.2758 20.8231 20.5687 20.5302C20.8615 20.2373 20.8615 19.7624 20.5687 19.4695L19.508 20.5302ZM14.9055 15.9278L19.508 20.5302L20.5687 19.4695L15.9662 14.8671L14.9055 15.9278Z"
        fill={color}
      />
    </svg>
  );
};
