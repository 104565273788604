import styled from "styled-components";

interface AuthContainerProps {
  isPageLogin?: boolean;
}

export const AuthContainer = styled.div<AuthContainerProps>`
  margin: 0px !important;
  height: ${({ isPageLogin }) => (isPageLogin ? "100vh" : "auto")};
  width: 100%;
`;
