import { CustomHeaderDto } from "typing";
import { memo, useEffect, useRef, useState } from "react";
import { buildTestIds, useAppContext, useCurrentPathName } from "application";
import { useRouter } from "next/router";
import { OverlayDynamic } from "ui";
import {
  Banner,
  BannerCtaText,
  BannerMessage,
  BannerTitle,
  TextsContainer,
} from "./style";
import { customHeaderData, handleComponentWarnings } from "./data";
import {
  handleOnClickRedirect,
  useGetBranchLinkConfig,
} from "../utils/branch-config";
import { PreloadImage } from "../../PreloadImage";

const CustomHeader = memo(
  ({
    properties,
    isClientTablet,
    config,
    isClientMobile,
    preloadComponent,
  }: //   subComponents,
  CustomHeaderDto) => {
    const {
      showTopbar,
      backgroundColor,
      contentType,
      title,
      message,
      ctaText,
      desktopImage,
      mobileImage,
    } = customHeaderData(properties);

    const linkConfig = useGetBranchLinkConfig(
      config?.branches,
      "subComponentHeader"
    );

    handleComponentWarnings(
      config?.branches,
      useCurrentPathName(),
      contentType,
      desktopImage,
      mobileImage,
      message,
      showTopbar
    );

    const { isSidebarMenuOpened } = useAppContext();

    const router = useRouter();

    const customHeaderRef = useRef<HTMLDivElement>(null);
    const [headerOverlayHeight, setHeaderOverlayHeight] = useState("1.667rem");

    // Esse eslint está desabilitado para que, toda vez que o componente for recriado, o overlay possa obter a largura exata do StyledFooter, mesmo que a tela seja redimensionada.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (customHeaderRef?.current?.clientHeight) {
        setHeaderOverlayHeight(`${customHeaderRef.current.clientHeight}px`);
      }
    });

    if (showTopbar === "false") {
      return null;
    }

    if (contentType === "image" && (mobileImage || desktopImage)) {
      const image = isClientTablet ? mobileImage : desktopImage;

      return (
        <>
          {preloadComponent ? (
            <PreloadImage
              imgSrc={image}
              {...buildTestIds("preload-banner-image-custom-header")}
            />
          ) : null}
          <OverlayDynamic
            {...buildTestIds("header-overlay-dynamic")}
            isVisible={!isClientMobile && isSidebarMenuOpened}
            height={headerOverlayHeight}
            top="0px"
          />
          <Banner
            {...buildTestIds("custom-header-banner")}
            ref={customHeaderRef}
            backgroundColor="transparent"
            backgroundImage={image}
            onClick={() => {
              handleOnClickRedirect(linkConfig, router);
            }}
            isToRedirect={!!linkConfig}
          />
        </>
      );
    }

    // Se o tipo não for imagem, hoje, o único outro tipo possível é text. Logo, segue a lógica de texto abaixo
    return (
      <>
        <OverlayDynamic
          {...buildTestIds("header-overlay-dynamic")}
          isVisible={!isClientMobile && isSidebarMenuOpened}
          height={headerOverlayHeight}
          top="0px"
        />
        <Banner
          {...buildTestIds("custom-header-banner")}
          ref={customHeaderRef}
          backgroundColor={backgroundColor}
          onClick={() => {
            handleOnClickRedirect(linkConfig, router);
          }}
          isToRedirect={!!linkConfig}
        >
          <TextsContainer
            isClientMobile={isClientMobile}
            isClientTablet={isClientTablet}
          >
            {title && !isClientTablet && <BannerTitle>{title}</BannerTitle>}
            {message && <BannerMessage>{message}</BannerMessage>}
            {ctaText && <BannerCtaText>{ctaText}</BannerCtaText>}
          </TextsContainer>
        </Banner>
      </>
    );
  }
);

CustomHeader.displayName = "CustomHeader";

export { CustomHeader };
