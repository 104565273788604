import { ErrorPageContainer, ErrorText } from "./styles";

const ErrorPage = () => {
  return (
    <ErrorPageContainer>
      <ErrorText>
        Ops, ocorreu um erro inesperado. Por favor, tente novamente em
        instantes.
      </ErrorText>
    </ErrorPageContainer>
  );
};

export { ErrorPage };
