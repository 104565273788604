import { memo, useEffect, useState } from "react";
import { RecommendedProductCardsDto } from "typing";
import { getSpotPrice, useAppContext } from "application";
import { calculateSlidesToShow, showArrows } from "./data";
import { RecommendedProductListUI } from "./ui";

const propsAreEqual = (
  {
    isClientMobile: oldIsClientMobile,
    isClientTablet: oldIsClientTablet,
    isClientDesktop: oldIsClientDesktop,
  }: RecommendedProductCardsDto,
  {
    isClientMobile,
    isClientTablet,
    isClientDesktop,
  }: RecommendedProductCardsDto
) => {
  return (
    oldIsClientMobile === isClientMobile &&
    oldIsClientTablet === isClientTablet &&
    oldIsClientDesktop === isClientDesktop
  );
};

const RecommendedProductList = memo(
  ({
    campaignVariationId,
    recommendedProductsId,
    productCards,
    isBlackTheme,
  }: RecommendedProductCardsDto) => {
    const { isClientDesktop, isClientMobile, isClientTablet } = useAppContext();
    const [arrowsIsActive, setArrowsIsActive] = useState<boolean>(false);
    const [slidesToShow, setSlidesToShow] = useState<number>(
      calculateSlidesToShow(isClientDesktop, isClientMobile, arrowsIsActive)
    );

    const quantity = 10;

    useEffect(() => {
      setArrowsIsActive(showArrows(quantity));
    }, [isClientDesktop, quantity]);

    useEffect(() => {
      setSlidesToShow(
        calculateSlidesToShow(isClientDesktop, isClientMobile, arrowsIsActive)
      );
    }, [isClientDesktop, isClientMobile, arrowsIsActive]);

    if (!productCards?.length) {
      return null;
    }

    const heightsFromProducts = productCards?.map((product) => {
      const minHeight = 292;
      const heightBlackFriday = product?.isBlackFriday ? 24 : 0;
      const heightCoupon = product?.coupons?.length ? 18 : 0;
      const heightSpotPrice = getSpotPrice(product) ? 13 : 0;
      const heightInstallments =
        product?.prices?.[0]?.maxNumberInstallments > 1 ? 17 : 0;
      const heightTagProduct =
        product?.tag?.title || product?.tagProduct?.title ? 24 : 0;

      return (
        heightBlackFriday +
        heightCoupon +
        heightSpotPrice +
        heightInstallments +
        heightTagProduct +
        minHeight
      );
    });

    const maxHeightForProductCards = heightsFromProducts
      ? Math.max(...heightsFromProducts)
      : 292;

    return (
      <RecommendedProductListUI
        campaignVariationId={campaignVariationId}
        arrowIsActive={arrowsIsActive}
        maxHeightForProductCards={maxHeightForProductCards}
        productCards={productCards}
        recommendedProductsId={recommendedProductsId}
        slidesToShow={slidesToShow}
        isClientTablet={isClientTablet}
        isBlackTheme={isBlackTheme}
      />
    );
  },
  propsAreEqual
);

RecommendedProductList.displayName = "RecommendedProductList";

export { RecommendedProductList };
