import styled, { css } from "styled-components";
import { theme } from "ui";
import { BlackFridayProductListDto } from "typing";
import { Link } from "../../Link";

interface DefaultProps {
  isVertical: boolean;
  isClientDesktop?: boolean;
  isClientMobile?: boolean;
  increaseCardSize?: boolean;
  customTheme?: BlackFridayProductListDto;
  isCrmProductListVertical?: boolean;
  isBlackFriday?: boolean;
}

type ProductListHeaderProps = {
  increaseCardSize: boolean;
};

export const ProductListTitle = styled(Link)<{ isCrmComponent?: boolean }>`
  font-weight: ${theme.typography.fontWeight[400]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.md.x3};
  color: ${({ color }) => color || theme.colors.neutral["520"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x9};
  }

  ${({ isCrmComponent }) =>
    isCrmComponent &&
    css`
      pointer-events: none;
    `}
`;

export const CustomStoriesTitle = styled.h2`
  font-weight: ${theme.typography.fontWeight[400]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.md.x3};
`;

export const ContainerCarousel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${theme.colors.neutral["50"]};
`;

export interface ContainerProductProps {
  isCrmProductListVertical?: boolean;
}
export const ContainerProduct = styled.section<ContainerProductProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25rem;
  width: 90px;

  ${({ isCrmProductListVertical }) =>
    isCrmProductListVertical &&
    css`

    @media ${theme.device.mobileFirst.tabletL}
      overflow-y: visible;
    }
      
    `}
`;

export const ProductListHeader = styled.header<ProductListHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${theme.space.x5};

  ${({ increaseCardSize }) =>
    increaseCardSize
      ? css`
          padding: ${`${theme.space.x0} ${theme.space.x6} ${theme.space.x0} ${theme.space.x3}
            `};
        `
      : css`
          padding: ${theme.space.x0} ${theme.space.x5} ${theme.space.x0}
            ${theme.space.x4};
        `}

  @media ${theme.device.desktopFirst.tablet} {
    padding: ${`${theme.space.x0} ${theme.space["x2.5"]} ${theme.space.x0}
      ${theme.space.x3}`};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: ${theme.space.x3};
    margin-bottom: ${theme.space.x0};
  }
`;

export const CtaButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${theme.space.x3};
  border: none;
  cursor: pointer;
  color: ${({ color }) => color || theme.colors.neutral["520"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-size: ${theme.typography.fontSize.sm.x7};

  &:path {
    stroke: ${({ color }) => color || theme.colors.neutral["520"]};
  }

  &:hover path {
    stroke: ${theme.colors.primary["200"]};
  }

  &:hover {
    transition: color 0.5s ease-in-out;
  }

  svg {
    margin-top: 5px;
  }
`;

export const ButtonArrowPrev = styled.div`
  display: flex;
  justify-content: center;

  height: 60px;
  width: 60px;

  border-radius: 35px;
  padding: 0px 0px 0px ${theme.space.x1};
  background: ${theme.colors.neutral.white};
  z-index: 5;
  margin-left: -5px;

  &:hover {
    background: ${theme.colors.primary["200"]};
    transition: 0.8s;
  }

  &:hover path {
    stroke: ${theme.colors.neutral.white};
  }

  svg {
    margin-top: 15px;
    margin-left: 15px;
  }

  box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-60"]};
`;

export const ButtonArrowNext = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 35px;

  padding: 0px 0px 0px ${theme.space.x1};
  margin-right: 5px;

  background: ${theme.colors.neutral.white};
  z-index: 5;

  box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-60"]};

  &:hover path {
    stroke: ${theme.colors.neutral.white};
  }

  &:hover {
    background: ${theme.colors.primary["200"]};
    transition: 0.8s;
  }

  svg {
    margin-top: 15px;
    margin-left: 21px;
  }
`;

export const ButtonViewMore = styled.button`
  background: ${theme.colors.primary["200"]};
  height: 37px;
  width: 100vw;
  border-radius: ${theme.space["x0.5"]};
  color: ${theme.colors.neutral.white};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: ${theme.space.x1};
`;

export const ProductCardDiv = styled.div`
  height: 400px;
  width: 1px;
`;

export const ContainerView = styled.div`
  display: flex;
  justify-content: center;
  padding: ${theme.space.x0} ${theme.space["x2.5"]};
`;

type ProductCardContainerProps = {
  centralizeCards: boolean;
  isCrmProductListVertical?: boolean;
};

export const ProductCardContainer = styled.div<ProductCardContainerProps>`
  display: flex;
  padding: ${theme.space.x0} ${theme.space["x2.5"]};
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: ${theme.space.x3};
  height: 100%;

  &::-webkit-scrollbar {
    width: 2px;
    height: 3px;
    border-radius: ${theme.space.x1};
  }

  &::-webkit-scrollbar-thumb {
    height: 2px;
    width: 1px;
    border-radius: ${theme.space.x1};
    background: ${theme.colors.neutral["150"]};
  }

  @media ${theme.device.desktopFirst.tablet} {
    gap: ${theme.space.x2};
    ${({ centralizeCards }) =>
      centralizeCards &&
      css`
        justify-content: center;
      `}

    & > a {
      min-width: 190px;
    }
  }

  ${({ isCrmProductListVertical }) =>
    isCrmProductListVertical &&
    css`
      @media ${theme.device.mobileFirst.tabletL} {
        overflow-y: visible;
        margin-left: 0;
      }
    `}
`;

export const CustomProductListContainer = styled.section<DefaultProps>`
  ${({ isCrmProductListVertical }) =>
    isCrmProductListVertical
      ? css`
          @media ${theme.device.mobileFirst.tabletL} {
            padding: 0;

            ${ProductListHeader} {
              padding: ${theme.space.x2} ${theme.space.x2} 0 ${theme.space.x2};
              margin-bottom: 0;
            }

            ${ProductListTitle} {
              padding: 0;
              font-size: ${theme.typography.fontSize.sm.x7};
              font-weight: ${theme.typography.fontWeight["600"]};
              color: ${theme.colors.neutral["550"]};
            }

            .slick-slide > div {
              padding: 0;
            }
          }
        `
      : css`
          @media (min-width: 1190px) {
            padding: 0 1.34rem 0 22px;
          }
        `}

  ${({ isVertical }) =>
    isVertical === false
      ? css`
          max-width: 1280px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        `
      : css``}

  ${({ isVertical }) =>
    isVertical === false
      ? css`
          .slick-track {
            padding-bottom: 0.5rem;
            display: flex;
            flex-direction: row;
            transition: all 0.5ms ease-in-out;

            @media ${theme.device.desktopFirst.tablet} {
              margin-left: 10px;
            }
          }
        `
      : css`
          .slick-track {
            max-height: 100vh;
            padding: ${`${theme.space.x2} 0`};
          }
        `}

  ${({ isVertical }) =>
    !isVertical &&
    css`
      .slick-slide {
        width: 226px !important;
        margin: 0px 9px;
      }

      .slick-slide:first-child {
        margin: 0px 9px 0px 0px;
      }

      @media ${theme.device.desktopFirst.laptop} {
        .slick-slide {
          width: 190px !important;
          margin: 0px 5px;
        }

        .slick-slide:first-child {
          margin: 0px 5px 0px 12px;
        }
      }
    `}
    

  .slick-slide > div {
    ${({ isVertical, increaseCardSize, isCrmProductListVertical }) =>
      !isVertical && increaseCardSize
        ? css`
            width: 226px;
            height: 100%;
          `
        : !increaseCardSize && !isVertical
        ? css`
            width: 192px;
            @media (max-width: 1024px) {
              width: 205px;
            }
          `
        : isCrmProductListVertical
        ? css`

          width: 100%;

          @media ${theme.device.mobileFirst.tabletL}
            padding: 0 8px;
          }     
          `
        : css`
            max-width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
          `}
  }

  @media (max-width: 1014px) and (min-width: 769px) {
    ${ProductCardContainer} {
      overflow-x: auto;
    }
  }

  @media (min-width: 769px) and (max-width: 1260px) {
    .slick-slide > div {
      ${({ isCrmProductListVertical, increaseCardSize }) =>
        isCrmProductListVertical
          ? css`
              width: 100%;
            `
          : increaseCardSize
          ? css`
              width: 191px;
            `
          : css`
              width: 205px;
            `};
    }

    .slick-list {
      ${({ increaseCardSize }) =>
        !increaseCardSize &&
        css`
          overflow-x: scroll;
          overflow-y: hidden;
        `}
    }
  }

  .slick-list {
    padding: 0.6rem 0;
    width: 100%;
  }

  ${({ isVertical }) =>
    !isVertical &&
    css`
      .slick-track {
        width: fit-content !important;
        overflow: hidden;
      }
    `}

  ${({ isCrmProductListVertical }) =>
    isCrmProductListVertical &&
    css`
      .slick-slide:first-child {
        margin-left: 0px !important;
      }

      @media ${theme.device.mobileFirst.tabletL} {
        .slick-track {
          overflow: visible;
        }
      }
    `}

  .slick-track {
    @media ${theme.device.desktopFirst.tablet} {
      padding-top: ${theme.space.x3};
    }
  }

  .slick-prev:before {
    display: none;
  }

  .slick-next:before {
    display: none;
  }

  .slick-disabled {
    background: ${({ customTheme }) =>
      customTheme?.horizontal.sliderButton.backgroundColor?.disabled ||
      theme.colors.neutral["50"]} !important;
    opacity: 1 !important;
    visibility: visible !important;

    &:hover {
      background: ${({ customTheme }) =>
        customTheme?.horizontal.sliderButton.backgroundColor?.disabled ||
        theme.colors.neutral["50"]};
    }
    cursor: default !important;

    path {
      stroke: ${({ isBlackFriday }) =>
        isBlackFriday
          ? `${theme?.colors?.blackFriday?.["40"]} !important`
          : `${theme.colors.neutral["99"]} !important`};
    }

    &:hover path {
      stroke: ${({ isBlackFriday }) =>
        isBlackFriday
          ? `${theme?.colors?.blackFriday?.["40"]} !important`
          : `${theme.colors.neutral["99"]} !important`};
    }
  }

  ${({ increaseCardSize, isVertical }) =>
    !increaseCardSize &&
    !isVertical &&
    css`
      .slick-slide {
        width: 191px !important;
        margin: 0px 5px;

        @media ${theme.device.desktopFirst.laptop} {
          width: 203px !important;
        }

        @media ${theme.device.desktopFirst.desktop} {
          margin: 0px 7px;
        }

        @media ${theme.device.desktopFirst.desktop} {
          .slick-slide:first-child {
            margin-left: 0px;
          }

          .slick-slide:last-child {
            margin-right: 0px;
          }

          .slick-slide {
            margin: 0px 9px;
          }
        }
      }

      .slick-slide:first-child {
        margin: 0px 5px 0px 12px;
      }

      .slick-slide:last-child {
        margin: 0px 12px 0px 5px;
      }
    `}

  ${ButtonViewMore} {
    ${({ customTheme }) =>
      customTheme?.vertical.seeMoreButton &&
      css`
        background: ${customTheme.vertical.seeMoreButton.backgroundColor
          .default};
        color: ${customTheme.vertical.seeMoreButton.textColor.default};
      `}
  }

  .slick-track {
    padding-top: 0.5rem;
  }

  ${({ isVertical, increaseCardSize }) =>
    !isVertical &&
    increaseCardSize &&
    css`
      .slick-slider {
        @media ${theme.device.desktopFirst.desktop} {
          padding: 0px 15px;
        }

        & > .slick-next,
        .slick-prev {
          display: flex !important;
          justify-content: center;
          align-items: center;
          padding: 0px !important;

          & > svg {
            margin: 0px !important;
          }
        }

        & > .slick-next {
          margin-right: 15px !important;
        }

        & > .slick-prev {
          margin-left: 9px !important;
        }

        @media ${theme.device.desktopFirst.laptop} {
          padding: 0px;
        }
      }
    `}

  ${({ isVertical }) =>
    isVertical &&
    css`
      .slick-slide {
        margin: 4px 0px;
      }
    `}

  @media (min-width: 1025px) and (max-width: 1260px) {
    .slick-slide > div {
      width: 100%;
    }

    .slick-slider {
      padding: 0px;
    }

    .slick-slide:first-child {
      margin-left: 12px;
    }

    .slick-slide:last-child {
      margin-right: 12px;
    }

    ${(props) =>
      props.increaseCardSize &&
      css`
        .slick-track {
          width: 161.7% !important;
        }

        .slick-slide:last-child {
          margin-right: 28px;
        }
      `}
  }
`;

export const SkeletonContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.space.x4};
  justify-content: center;
`;
