import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const HeadPhone = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral.white,
}: // strokeWidth = "1.5",
MultipleColorsIconProps) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
    >
      <g id="Icons / Contact">
        <g id="Cores/Preto">
          <path
            id="Mask"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 26.25H15C15.9663 26.25 16.8099 25.6358 17.1224 24.7488C19.873 24.6956 22.245 22.8516 23.0017 20.25C24.7958 20.2491 26.25 18.7944 26.25 17V15C26.25 13.2892 24.9281 11.8871 23.25 11.7595V10C23.25 5.44365 19.5563 1.75 15 1.75H13C8.44365 1.75 4.75 5.44365 4.75 10V11.7595C3.07189 11.8871 1.75 13.2892 1.75 15V17C1.75 18.7949 3.20507 20.25 5 20.25H7C7.69036 20.25 8.25 19.6904 8.25 19V13C8.25 12.3096 7.69036 11.75 7 11.75H6.25V10C6.25 6.27208 9.27208 3.25 13 3.25H15C18.7279 3.25 21.75 6.27208 21.75 10V11.75H21C20.3096 11.75 19.75 12.3096 19.75 13V19C19.75 19.6904 20.3096 20.25 21 20.25H21.4174C20.7246 22 19.0436 23.1998 17.1214 23.2485C16.8082 22.3629 15.9653 21.75 15 21.75H13C11.7574 21.75 10.75 22.7574 10.75 24C10.75 25.2426 11.7574 26.25 13 26.25ZM24.75 17C24.75 17.9665 23.9665 18.75 23 18.75H21.25V13.25H22H23C23.9665 13.25 24.75 14.0335 24.75 15V17ZM5 13.25C4.0335 13.25 3.25 14.0335 3.25 15V17C3.25 17.9665 4.0335 18.75 5 18.75H6.75V13.25H5ZM12.25 24C12.25 23.5858 12.5858 23.25 13 23.25H15C15.4142 23.25 15.75 23.5858 15.75 24C15.75 24.4142 15.4142 24.75 15 24.75H13C12.5858 24.75 12.25 24.4142 12.25 24Z"
            fill={color}
          />
          <mask
            id="mask0_0_3326"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="26"
            height="26"
          >
            <path
              id="Mask_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13 26.25H15C15.9663 26.25 16.8099 25.6358 17.1224 24.7488C19.873 24.6956 22.245 22.8516 23.0017 20.25C24.7958 20.2491 26.25 18.7944 26.25 17V15C26.25 13.2892 24.9281 11.8871 23.25 11.7595V10C23.25 5.44365 19.5563 1.75 15 1.75H13C8.44365 1.75 4.75 5.44365 4.75 10V11.7595C3.07189 11.8871 1.75 13.2892 1.75 15V17C1.75 18.7949 3.20507 20.25 5 20.25H7C7.69036 20.25 8.25 19.6904 8.25 19V13C8.25 12.3096 7.69036 11.75 7 11.75H6.25V10C6.25 6.27208 9.27208 3.25 13 3.25H15C18.7279 3.25 21.75 6.27208 21.75 10V11.75H21C20.3096 11.75 19.75 12.3096 19.75 13V19C19.75 19.6904 20.3096 20.25 21 20.25H21.4174C20.7246 22 19.0436 23.1998 17.1214 23.2485C16.8082 22.3629 15.9653 21.75 15 21.75H13C11.7574 21.75 10.75 22.7574 10.75 24C10.75 25.2426 11.7574 26.25 13 26.25ZM24.75 17C24.75 17.9665 23.9665 18.75 23 18.75H21.25V13.25H22H23C23.9665 13.25 24.75 14.0335 24.75 15V17ZM5 13.25C4.0335 13.25 3.25 14.0335 3.25 15V17C3.25 17.9665 4.0335 18.75 5 18.75H6.75V13.25H5ZM12.25 24C12.25 23.5858 12.5858 23.25 13 23.25H15C15.4142 23.25 15.75 23.5858 15.75 24C15.75 24.4142 15.4142 24.75 15 24.75H13C12.5858 24.75 12.25 24.4142 12.25 24Z"
              fill={secondColor}
            />
          </mask>
          <g mask="url(#mask0_0_3326)">
            <g id="Cores/Preto_2">
              <rect id="Rectangle" width={width} height={height} fill={color} />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
