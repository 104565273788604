import { IconProps } from "typing";
import { theme } from "../../themes";

export const AppAuthenticationIcon = ({
  width = theme.space.x15,
  height = theme.space.x15,
  strokeWidth = "1.5",
  alt = "Autenticação por Aplicativo",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="60" height="60" rx="30" fill="#F6F7F7" />
      <circle
        cx="30.7227"
        cy="31.1866"
        r="2.96296"
        fill="#6D6F73"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2042 33.5816C40.2042 38.0553 34.6992 41.3786 32.0739 42.713C31.2269 43.146 30.2237 43.1468 29.3759 42.7153C26.7525 41.3822 21.2412 38.0508 21.2412 33.5816V27.1205C21.2499 26.5151 21.7084 26.011 22.3104 25.9452C24.8252 25.7541 27.2363 24.8624 29.2704 23.3712C30.1323 22.7298 31.313 22.7298 32.175 23.3712C34.209 24.8624 36.6201 25.7541 39.135 25.9452C39.7369 26.011 40.1955 26.515 40.2042 27.1205V33.5816Z"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9819 24.6679V21.1123V21.1123C25.9819 18.4942 28.1046 16.3716 30.7227 16.3716V16.3716C33.3407 16.3716 35.4634 18.4942 35.4634 21.1123V21.1123V24.6679"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5312 39.84L35.9863 38.7502C35.4844 37.7464 34.4584 37.1123 33.3361 37.1123H28.1096C26.9873 37.1123 25.9614 37.7464 25.4595 38.7502L31.3157 43.0382"
        fill="#6D6F73"
      />
      <path
        d="M36.5312 39.84L35.9863 38.7502C35.4844 37.7464 34.4584 37.1123 33.3361 37.1123H28.1096C26.9873 37.1123 25.9614 37.7464 25.4595 38.7502L31.3157 43.0382"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
