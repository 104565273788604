import {
  buildTestIds,
  formatEmailToShowOnlyFourCharactersAndDomain,
} from "application";
import {
  BoxContentEmailContainer,
  BoxContentEmailContainerContent,
  EmailMessageBoxContentEmailIcon,
  EmailMessageBoxContentEmailIconWrapper,
  TitleBoxContentEmail,
} from "./styles";

interface BoxContentEmailProps {
  email: string;
}

const BoxContentEmail = ({ email }: BoxContentEmailProps) => {
  return (
    <BoxContentEmailContainer {...buildTestIds("box-content-email-container")}>
      <BoxContentEmailContainerContent
        {...buildTestIds("box-content-email-content")}
      >
        <EmailMessageBoxContentEmailIconWrapper
          {...buildTestIds("email-message-box-content-email-icon-wrapper")}
        >
          <EmailMessageBoxContentEmailIcon />
        </EmailMessageBoxContentEmailIconWrapper>
        <TitleBoxContentEmail {...buildTestIds("title-box-content-email")}>
          {formatEmailToShowOnlyFourCharactersAndDomain(email)}
        </TitleBoxContentEmail>
      </BoxContentEmailContainerContent>
    </BoxContentEmailContainer>
  );
};

export type { BoxContentEmailProps };

export { BoxContentEmail };
