import { IconProps } from "typing";
import { theme } from "../themes";

export const House = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
  alt = "Home",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2188 12C24.0188 12 23.8189 11.9244 23.6664 11.7731L23 11.1126V21.2304C23 22.4818 21.9969 23.5 20.7639 23.5H15.7H9.3H4.23611C3.00314 23.5 2 22.4819 2 21.2304V11.1127L1.33368 11.7732C1.0286 12.0756 0.533924 12.0756 0.228845 11.7732C-0.0762818 11.4708 -0.0762818 10.9805 0.228845 10.6781L9.94507 1.04729C11.3539 -0.349098 13.6462 -0.349098 15.0549 1.04729L24.7712 10.678C25.0763 10.9804 25.0763 11.4707 24.7712 11.7731C24.6187 11.9244 24.4187 12 24.2188 12ZM14.9 21.9212H10.1V15.9038C10.1 15.4267 10.5037 15.0385 11 15.0385H14C14.4963 15.0385 14.9 15.4267 14.9 15.9038V21.9212ZM16.5 21.9212V15.9038C16.5 14.5784 15.3785 13.5 14 13.5H11C9.6215 13.5 8.5 14.5784 8.5 15.9038V21.9212H4.23611C3.86083 21.9212 3.55556 21.6113 3.55556 21.2304V9.57085L11.0499 2.14245C11.8495 1.34988 13.1505 1.34988 13.9501 2.14245L21.4444 9.57077V21.2304C21.4444 21.6113 21.1392 21.9212 20.7639 21.9212H16.5Z"
        fill={color}
      />
    </svg>
  );
};
