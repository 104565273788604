import { IconProps } from "typing";
import { theme } from "../themes";

export const DollarCircle = ({
  width = 18,
  height = 18,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path d="M12 6V7.5" stroke={color} strokeWidth="1.5" />
      <path
        d="M18.364 5.63604C21.8787 9.15076 21.8787 14.8492 18.364 18.3639C14.8493 21.8787 9.1508 21.8787 5.6361 18.3639C2.12138 14.8492 2.12138 9.15074 5.6361 5.63604C9.15082 2.12132 14.8493 2.12132 18.364 5.63604"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M12 18V16.5" stroke={color} strokeWidth="1.5" />
      <path
        d="M9 14.255V14.255C9 15.498 10.007 16.505 11.25 16.505H12.893C14.056 16.505 15 15.562 15 14.398V14.398C15 13.432 14.343 12.59 13.406 12.355L10.594 11.65C9.657 11.415 9 10.573 9 9.607V9.607C9 8.443 9.943 7.5 11.107 7.5H12.75C13.993 7.5 15 8.507 15 9.75V9.75"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
