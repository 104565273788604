import { buildTestIds } from "application";
import { Link, animateScroll as scroll } from "react-scroll";

interface Params {
  activeClass?: string;
  offset?: number;
  spy?: boolean;
  to: string;
  children: string;
}

export const ScrollToLink = ({
  to,
  spy,
  offset,
  activeClass,
  children,
}: Params) => {
  return (
    <Link
      {...buildTestIds("scroll-to-link-item")}
      activeClass={activeClass}
      offset={offset}
      spy={spy}
      to={to}
    >
      {children}
    </Link>
  );
};

export const EventScroll = scroll;
