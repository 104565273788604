type ValidateCpfResponse = "" | "O campo cpf é obrigatório" | "CPF inválido";

export const validateCpf = (
  cpf: string,
  isRequired = true
): ValidateCpfResponse => {
  const cpfRequiredMessage = "O campo cpf é obrigatório";
  const cpfInvalidMessage = "CPF inválido";

  if (!isRequired && !cpf) return "";

  if (!cpf) return cpfRequiredMessage;

  if (
    cpf?.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return cpfInvalidMessage;

  let sum = 0;
  let rest = 0;

  for (let i = 0; i < 9; i += 1) sum += parseInt(cpf?.charAt(i), 10) * (10 - i);
  rest = 11 - (sum % 11);
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf?.charAt(9), 10)) return cpfInvalidMessage;

  sum = 0;

  for (let i = 0; i < 10; i += 1)
    sum += parseInt(cpf?.charAt(i), 10) * (11 - i);
  rest = 11 - (sum % 11);
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf?.charAt(10), 10)) return cpfInvalidMessage;
  return "";
};
