import { SupportContactDto } from "typing/dto/SupportContact";
import { IHttpResponse } from "typing/types";
import { IHttpClient } from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";

class SupportContactApi implements IApi {
  baseUrl = "";

  constructor(private httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getOptions = async (): Promise<
    IHttpResponse<SupportContactDto[], unknown>
  > => {
    const response = await this.httpClient.useGet("/support-contact/options");

    return {
      data: response.data as unknown as SupportContactDto[],
      isLoading: false,
      error: false,
    };
  };
}

export { SupportContactApi };
