import { IconProps } from "typing";
import { theme } from "../themes";

export const RotateIcon = ({
  color = theme.colors.secondary["230"],
  width = 16,
  height = 16,
  strokeWidth = "1.5",
  alt = "Ícone de setas de rotação",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99756 6.66671L4.5673 4.09697C5.48424 3.17999 6.72795 2.6649 8.02474 2.66504V2.66504C10.0238 2.66518 11.8214 3.88227 12.564 5.73832L12.6687 5.99976"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66534 6.6668H1.99756V3.99902"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0025 9.33398L11.4328 11.9037C10.5158 12.8207 9.27214 13.3358 7.97535 13.3357V13.3357C5.97629 13.3355 4.17866 12.1184 3.43613 10.2624L3.33142 10.0009"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3347 9.33398H14.0025V12.0018"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
