import { RecommendedProductListDto } from "typing";

export const dataInsiderAPI: RecommendedProductListDto = {
  campaignId: 121,
  products: [
    { item_id: "214" },
    { item_id: "231597" },
    { item_id: "461331" },
    { item_id: "342859" },
    { item_id: "451578" },
    { item_id: "460760" },
    { item_id: "252611" },
    { item_id: "443128" },
    { item_id: "450326" },
    { item_id: "342884" },
  ],
  variationId: 248,
};
