import { memo, useState } from "react";
import { ArrowDown, ArrowUp, theme } from "ui";
import { FAQSubComponentDto } from "typing";
import { buildTestIds } from "application";
import { customFAQSubComponentData } from "./data";
import {
  AccordionButton,
  AccordionContent,
  AccordionContentText,
  ArrowContainer,
  CustomFAQSubComponentContainer,
  Question,
} from "./styles";

const CustomFAQSubComponent = memo(
  ({ properties, faqTheme }: FAQSubComponentDto) => {
    const [openAccordion, setOpenAccordion] = useState(false);
    const { question, text } = customFAQSubComponentData(properties);

    const arrowColor = faqTheme
      ? theme.colors.blackFriday.white
      : `${theme.colors.primary["150"]}`;

    return (
      <CustomFAQSubComponentContainer
        {...buildTestIds("custom-faq-subcomponent-container")}
        faqBlackTheme={faqTheme}
      >
        <AccordionButton
          {...buildTestIds("accordion-button")}
          faqBlackTheme={faqTheme}
          onClick={() => setOpenAccordion(!openAccordion)}
        >
          <>
            <Question
              {...buildTestIds("faq-question")}
              faqBlackTheme={faqTheme}
            >
              {question}
            </Question>
            <ArrowContainer>
              {openAccordion ? (
                <ArrowUp color={arrowColor} />
              ) : (
                <ArrowDown color={arrowColor} />
              )}
            </ArrowContainer>
          </>
        </AccordionButton>
        <AccordionContent
          {...buildTestIds("accordion-content")}
          faqBlackTheme={faqTheme}
          maxHeight={theme.space.x160}
          show={openAccordion}
        >
          {!text ? null : (
            <AccordionContentText
              {...buildTestIds("accordion-content-text")}
              faqBlackTheme={faqTheme}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </AccordionContent>
      </CustomFAQSubComponentContainer>
    );
  }
);

CustomFAQSubComponent.displayName = "CustomFAQSubComponent";
export { CustomFAQSubComponent };
