import styled, { css } from "styled-components";
import { Button, theme } from "ui";

interface StepperModalContainerProps {
  width?: string;
  padding?: string;
  minHeight?: string;
}

export const StepperModalContainer = styled.div<StepperModalContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding ?? `${theme.space.x6}`};
  border-radius: ${theme.space.x2};
  min-height: ${({ minHeight }) => minHeight ?? "480px"};
  width: ${({ width }) => width ?? "694px"};
  background-color: ${theme.colors.neutral.white};
  box-shadow: 0px 2px 8px 0px ${theme.colors.neutral["900-opacity-32"]};
`;

interface StepperModalOverlayProps {
  isVisible: boolean;
  overlayBackgroundColor?: string;
}

export const StepperModalOverlay = styled.div<StepperModalOverlayProps>`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: ${({ overlayBackgroundColor }) =>
    overlayBackgroundColor ?? `${theme.colors.neutral["999-opacity-50"]}`};
`;

interface StepperModalHeaderProps {
  headerFlexDirection?: string;
}

export const StepperModalHeader = styled.header<StepperModalHeaderProps>`
  display: flex;
  flex-direction: ${({ headerFlexDirection }) =>
    headerFlexDirection ?? "column"};
  gap: 16px;
  width: 100%;
`;

interface StepperModalTitleContainerProps {
  hasBorderBottom?: boolean;
}

export const StepperModalTitleContainer = styled.div<StepperModalTitleContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ hasBorderBottom }) =>
    hasBorderBottom &&
    css`
      padding-bottom: ${theme.space.x2};
      border-bottom: 1px solid ${theme.colors.neutral["150"]};
    `}
`;

interface ModalTitleProps {
  color?: string;
  fontSize?: string;
}

export const ModalTitle = styled.h2<ModalTitleProps>`
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${({ fontSize }) =>
    fontSize ?? `${theme.typography.fontSize.md.x1}`};
  color: ${({ color }) => color ?? `${theme.colors.neutral["900"]}`};
`;

export const CloseModalContainer = styled.div`
  cursor: pointer;
`;

export const StepperContainer = styled.div``;

export const StatusProgressBar = styled.div``;

export const StepperModalDescriptionContainer = styled.div``;

export const ModalDescription = styled.span`
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-size: ${theme.typography.fontSize.sm.x7};
  color: ${theme.colors.neutral["585"]};
`;

interface StepperModalFooterProps {
  hasNextButton?: boolean;
  hasBackButton?: boolean;
}

export const StepperModalFooter = styled.footer<StepperModalFooterProps>`
  display: flex;
  margin-top: auto;

  ${({ hasNextButton, hasBackButton }) => {
    if (hasNextButton && hasBackButton) {
      return `justify-content: space-between;`;
    }

    return `justify-content: ${hasNextButton ? "end" : "start"};`;
  }}
`;

export const StepperNextButton = styled(Button)`
  padding: ${theme.space.x2} ${theme.space.x6};
  background-color: ${theme.colors.secondary["380"]};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-family: ${theme.typography.fontFamily.chivo};
  border-radius: ${theme.space.x1};
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
  width: auto;

  :hover {
    background-color: ${theme.colors.secondary["250"]};
  }
`;

export const StepperBackButton = styled.div`
  display: flex;
  padding: ${theme.space.x2} ${theme.space.x6};
  gap: ${theme.space.x1};
  align-items: center;
  cursor: pointer;

  svg {
    stroke-width: 2;
  }
`;

export const StepperBackButtonText = styled.span`
  color: ${theme.colors.secondary["380"]};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight.bold};
  font-family: ${theme.typography.fontFamily.chivo};
`;

export const StepperModalContent = styled.div``;
