import { Product, ProductCardDto } from "typing";

const getProductImageUrl = (product: ProductCardDto | Product) => {
  try {
    if (!product?.id)
      throw new Error(
        "productImageUrl: product must be an instance of ProductCardDto or an instance of Product"
      );

    const result = product?.mediaLinks?.find(
      (mediaLink) =>
        mediaLink?.linkType === "THUMB" && mediaLink?.isDefault === true
    );

    return result?.imageUrl || "";
  } catch {
    return "";
  }
};

export { getProductImageUrl };
