import { IconProps } from "typing";
import { theme } from "../themes";

export const Store = ({
  width = 32,
  height = 32,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M11 13H8V16H10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 20.0001L11 20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.22 20V9.98706"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7243 6.47765C22.0194 7.03345 22.078 7.68474 21.887 8.28433C21.5565 9.32453 20.5804 10.0228 19.4892 9.99943C18.398 9.9761 17.4526 9.23678 17.1669 8.18341C17.1488 8.12223 17.0926 8.08025 17.0288 8.08025C16.965 8.08025 16.9088 8.12223 16.8907 8.18341C16.5989 9.25555 15.6253 9.99951 14.5142 9.99939C13.4031 9.99927 12.4296 9.2551 12.1381 8.1829C12.12 8.12169 12.0638 8.07968 12 8.07968C11.9362 8.07968 11.88 8.12169 11.8619 8.1829C11.5702 9.2551 10.5967 9.99918 9.48551 9.99914C8.37435 9.9991 7.40085 9.25495 7.10926 8.18273C7.09113 8.12156 7.03493 8.0796 6.97113 8.0796C6.90734 8.0796 6.85113 8.12156 6.833 8.18273C6.54727 9.23599 5.60193 9.97521 4.51085 9.99858C3.41977 10.0219 2.44365 9.32387 2.11308 8.28381C1.92195 7.68412 1.98061 7.0327 2.2758 6.47681L3.98252 3.09822C4.32276 2.4247 5.0131 2 5.76768 2H18.232C18.9866 2 19.6769 2.4247 20.0172 3.09822L21.7243 6.47765Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 21.5V21.094C14.0033 19.9389 14.9389 19.0033 16.094 19H18.906C20.0611 19.0033 20.9967 19.9389 21 21.094V21.5C21 21.7761 20.7761 22 20.5 22H14.5C14.3674 22 14.2402 21.9473 14.1464 21.8536C14.0527 21.7598 14 21.6326 14 21.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="17.5"
        cy="14.5"
        r="2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
