import { IconProps } from "typing";
import { theme } from "../themes";

export const DollarIcon = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["760"],
  alt = "ícone de dolar",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 6.8185V6.1815"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 13.182V13.818"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="10" r="8.25" stroke="#A0A2A4" strokeWidth="1.5" />
      <path
        d="M8.26499 12.2838C8.5237 12.7079 8.9657 13 9.50092 13H10.0005H10.5965C11.3726 13 12 12.3717 12 11.5969C12 10.9529 11.5611 10.3927 10.9369 10.2346L9.06206 9.76335C8.43886 9.60733 8 9.04712 8 8.40314C8 7.62723 8.62844 7 9.40351 7H10.0005H10.5001C11.0333 7 11.4753 7.29215 11.7329 7.71518"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
