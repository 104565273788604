import { Price } from "typing";

const valueProduct = (price: Price) => {
  if (!price?.spotPrice) {
    return price?.priceList !== price?.salePrice && price?.salePrice
      ? (price?.salePrice as number)
      : (price?.priceList as number) || 0;
  }

  return price?.spotPrice > price?.salePrice && price?.salePrice
    ? (price?.salePrice as number)
    : (price?.spotPrice as number);
};

export { valueProduct };
