import { useState, useEffect } from "react";

const useCurrentPathName = () => {
  const [currentPathname, setCurrentPathname] = useState("");

  useEffect(() => {
    setCurrentPathname(window.location.pathname);
    // O eslint disable abaixo é justamente para que haja uma modificação no state currentPathName sempre que o window.location.pathname seja alterado.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return currentPathname;
};

export { useCurrentPathName };
