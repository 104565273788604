import { StepName } from "typing";
import { EmailIcon, MessagesIcon } from "ui";

const methods = (
  setChannelSelected: (channel: number) => void,
  changeCurrentStep: (step: StepName) => void,
  availableMethods?: {
    email?: string;
    sms?: string;
  },
  isValid?: {
    email?: boolean;
    sms?: boolean;
  },
  onVerifyIdentity?: (channel: number) => void
) => {
  return [
    {
      title: "SMS",
      description: String(availableMethods?.sms),
      handleOptionAction: () => {
        setChannelSelected(1);
        onVerifyIdentity?.(1);
      },
      icon: <MessagesIcon />,
      isValid: isValid?.sms,
    },
    {
      title: "E-mail",
      description: String(availableMethods?.email),
      handleOptionAction: () => {
        setChannelSelected(2);
        onVerifyIdentity?.(2);
      },
      icon: <EmailIcon />,
      isValid: isValid?.email,
    },
  ];
};

export { methods };
