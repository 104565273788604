import styled from "styled-components";
import { theme } from "../themes";

export * from "./Flex";
export * from "./OverlayDynamic";

type ContainerProps = {
  hasNoMobilePadding?: boolean;
  backgroundColor?: string;
  widthLaptopL?: string;
};

export const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  max-width: 1310px;
  padding: 0 1.25rem;

  @media ${theme.device.desktopFirst.laptopL} {
    max-width: 1280px;
    width: ${({ widthLaptopL }) => (widthLaptopL ? "100%" : "")};
    padding: 0 1.34rem;
  }

  @media ${theme.device.desktopFirst.laptop} {
    max-width: 100%;
    width: 100%;
  }

  @media ${theme.device.desktopFirst.tablet} {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ?? "transparent"};
  }

  @media ${theme.device.desktopFirst.tabletS} {
    padding: 0;
  }

  @media ${theme.device.desktopFirst.mobileM} {
    padding: ${({ hasNoMobilePadding }) =>
      hasNoMobilePadding ? "0" : "0 1.34rem"};
  }
`;
