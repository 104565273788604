import { useCurrentPathName } from "application";
import { CustomHighlightCategoryDto } from "typing";
import { theme } from "ui";
import { Link } from "../../../Link";

const HighlightCategory = ({
  id,
  name,
  descriptionUrl,
}: CustomHighlightCategoryDto) => {
  const currentPathName = useCurrentPathName();

  // const formattedDescriptionUrl = descriptionUrl
  //   ? descriptionUrl?.startsWith("Destaque/")
  //     ? descriptionUrl
  //     : `Destaque/${descriptionUrl}`
  //   : null;

  return (
    <Link
      key={`${id}#${descriptionUrl}#${name}`}
      href={`/${descriptionUrl || ""}`}
      target="_self"
      color={
        descriptionUrl && currentPathName?.includes(descriptionUrl)
          ? theme.colors.neutral.white
          : theme.colors.neutral["520"]
      }
      backgroundColor={
        descriptionUrl && currentPathName?.includes(descriptionUrl)
          ? theme.colors.secondary["200"]
          : undefined
      }
    >
      {name}
    </Link>
  );
};

export { HighlightCategory };
