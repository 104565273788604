export const BlackFridayBag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5029 21.845H6.4979C5.9672 21.8452 5.4582 21.6344 5.08294 21.2591C4.70768 20.8839 4.49707 20.3749 4.49707 19.8442V9.3398C4.49737 8.51115 5.16905 7.83948 5.9977 7.83917H19.0031C19.8318 7.83948 20.5034 8.51115 20.5037 9.3398V19.8442C20.5037 20.3749 20.2931 20.8839 19.9179 21.2591C19.5426 21.6344 19.0336 21.8452 18.5029 21.845Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0559 14.0655L12.1101 16.0113L10.9434 14.8433"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49805 7.83913V7.58903V7.58903C8.49805 5.51712 10.1777 3.83751 12.2496 3.83746H12.7498C14.8217 3.83751 16.5014 5.51712 16.5014 7.58903V7.58903V7.83913"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
