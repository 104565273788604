import {
  buildTestIds,
  getClientBranchId,
  useAppContext,
  useProductsRecommendation,
} from "application";
import { appMonitoringClient, catalogApi, cookie, crm } from "implementations";
import { useCallback } from "react";
import { RecommendationComponentProps } from "typing";
import { SkeletonContainer } from "./styles";
import { productsRecommendationDetails } from "./data";
import { useMappedBackofficeComponent } from "../backoffice/hooks";
import { Skeleton } from "../Skeleton";

const RecommendationCarousel = ({
  crmUrl,
  crmLinkId,
}: RecommendationComponentProps) => {
  const branchId = getClientBranchId(cookie);
  const { isClientTablet, isClientMobile } = useAppContext();
  const { productCards, componentCrmProps, isLoading } =
    useProductsRecommendation(
      crm,
      productsRecommendationDetails(branchId, crmUrl, crmLinkId),
      cookie,
      catalogApi,
      appMonitoringClient
    );

  const skeletonQuantity = useCallback(() => {
    if (isClientMobile) {
      return 1;
    }

    if (isClientTablet) {
      return 3;
    }

    return 5;
  }, [isClientMobile, isClientTablet]);

  const CustomProductList = useMappedBackofficeComponent(
    productsRecommendationDetails(
      branchId,
      crmUrl,
      crmLinkId,
      componentCrmProps,
      productCards
    ),
    {
      isCrmComponent: true,
    }
  );

  if (!productCards?.length && !isLoading) {
    return null;
  }

  return isLoading ? (
    <SkeletonContainer {...buildTestIds("skeleton-container")}>
      <Skeleton
        type="card"
        count={skeletonQuantity()}
        width={190}
        height={360}
      />
    </SkeletonContainer>
  ) : (
    CustomProductList
  );
};

export { RecommendationCarousel };
