import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ThreeDotsHorizontal = ({
  width = 37,
  height = 6,
  color = theme.colors.neutral["550"],
  secondColor = theme.colors.neutral["720"],
  alt = "Ícone de três pontos na horizontal",
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 37 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5696 3.00006C35.5696 3.72523 34.9817 4.3131 34.2566 4.3131C33.5314 4.3131 32.9435 3.72523 32.9435 3.00006C32.9435 2.27488 33.5314 1.68701 34.2566 1.68701C34.9817 1.68701 35.5696 2.27488 35.5696 3.00006"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.813 3.00006C19.813 3.72523 19.2252 4.3131 18.5 4.3131C17.7748 4.3131 17.187 3.72523 17.187 3.00006C17.187 2.27488 17.7748 1.68701 18.5 1.68701C19.2252 1.68701 19.813 2.27488 19.813 3.00006"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.05648 3.00006C4.05648 3.72523 3.46861 4.3131 2.74344 4.3131C2.01826 4.3131 1.43039 3.72523 1.43039 3.00006C1.43039 2.27488 2.01826 1.68701 2.74344 1.68701C3.46861 1.68701 4.05648 2.27488 4.05648 3.00006"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
