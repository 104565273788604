import { ArrowLeft, CloseIcon, theme } from "ui";
import { ReactNode, useRef } from "react";
import { Step } from "typing";
import { buildTestIds, useClickOutside } from "application";
import {
  CloseModalContainer,
  StepperModalContainer,
  StepperModalHeader,
  StepperModalOverlay,
  ModalTitle,
  StepperModalTitleContainer,
  StepperModalDescriptionContainer,
  ModalDescription,
  StepperModalFooter,
  StepperBackButton,
  StepperNextButton,
  StepperModalContent,
  StepperBackButtonText,
} from "./styles";
import { StatusProgress } from "../StatusProgress";

export interface StepperModalProps {
  isOpen: boolean;
  onClose: () => void;
  steps: Step[];
  title: string;
  hasButtons?: boolean;
  hasBackButton?: boolean;
  hasNextButton?: boolean;
  description?: string;
  children?: ReactNode;
  isNextButtonDisabled?: boolean;
  nextButtonText?: string;
  hasBorderBottom?: boolean;
  overlayBackgroundColor?: string;
  width?: string;
  statusHeight?: string;
  headerFlexDirection?: string;
  padding?: string;
  minHeight?: string;
  titleColor?: string;
  titleFontSize?: string;
  goBack?: () => void;
  goNext?: () => void;
}

const StepperModal = ({
  isOpen,
  title,
  onClose,
  steps,
  children,
  description,
  hasBackButton,
  hasButtons,
  hasNextButton,
  goBack,
  isNextButtonDisabled,
  goNext,
  nextButtonText,
  width,
  headerFlexDirection,
  hasBorderBottom,
  overlayBackgroundColor,
  padding,
  minHeight,
  statusHeight,
  titleColor,
  titleFontSize,
}: StepperModalProps) => {
  const stepperModalRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    ref: stepperModalRef,
    callback: () => {
      if (isOpen) onClose();
    },
  });

  return (
    <StepperModalOverlay
      overlayBackgroundColor={overlayBackgroundColor}
      {...buildTestIds("stepper-modal-overlay")}
      isVisible={isOpen}
    >
      <StepperModalContainer
        width={width}
        ref={stepperModalRef}
        padding={padding}
        minHeight={minHeight}
        {...buildTestIds("stepper-modal-container")}
      >
        <StepperModalHeader
          headerFlexDirection={headerFlexDirection}
          {...buildTestIds("stepper-modal-header")}
        >
          {steps?.length ? (
            <StatusProgress
              height={statusHeight}
              statusStep={steps}
              hasIcon={false}
            />
          ) : null}
          <StepperModalTitleContainer
            {...buildTestIds("stepper-modal-title-container")}
            hasBorderBottom={hasBorderBottom}
          >
            <ModalTitle
              {...buildTestIds("modal-title")}
              color={titleColor}
              fontSize={titleFontSize}
            >
              {title}
            </ModalTitle>
            <CloseModalContainer
              {...buildTestIds("close-modal-container")}
              onClick={onClose}
            >
              <CloseIcon width={24} height={24} />
            </CloseModalContainer>
          </StepperModalTitleContainer>
          {description && (
            <StepperModalDescriptionContainer
              {...buildTestIds("stepper-modal-description-container")}
            >
              <ModalDescription {...buildTestIds("modal-description")}>
                {description}
              </ModalDescription>
            </StepperModalDescriptionContainer>
          )}
        </StepperModalHeader>
        <StepperModalContent {...buildTestIds("stepper-modal-content")}>
          {children}
        </StepperModalContent>
        {hasButtons ? (
          <StepperModalFooter
            hasBackButton={hasBackButton}
            hasNextButton={hasNextButton}
          >
            {hasBackButton && goBack ? (
              <StepperBackButton onClick={() => goBack()}>
                <ArrowLeft
                  width={16}
                  height={16}
                  color={theme.colors.secondary["380"]}
                />
                <StepperBackButtonText>Voltar</StepperBackButtonText>
              </StepperBackButton>
            ) : null}
            {hasNextButton && goNext ? (
              <StepperNextButton
                {...buildTestIds("stepper-next-cancel")}
                isDisabled={isNextButtonDisabled}
                onClick={() => goNext()}
              >
                {nextButtonText ?? "Próximo"}
              </StepperNextButton>
            ) : null}
          </StepperModalFooter>
        ) : null}
      </StepperModalContainer>
    </StepperModalOverlay>
  );
};

export { StepperModal };
