import { IconProps } from "typing";
import { theme } from "../themes";

export const CartOutlineIcon = ({
  width = 24,
  height = 24,
  alt = "Carrinho",
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43935 9.72227L4.41602 4.8916H12.8333C13.2673 4.8916 13.5853 5.29893 13.48 5.72027L12.5813 9.31494C12.4447 9.86094 11.9807 10.2623 11.4207 10.3183L6.87668 10.7729C6.19935 10.8403 5.58002 10.3876 5.43935 9.72227Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.41616 4.8916L3.98283 2.8916H2.8335"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9062 13.0698C11.7716 13.0698 11.6622 13.1791 11.6636 13.3138C11.6636 13.4485 11.7729 13.5578 11.9076 13.5578C12.0422 13.5578 12.1516 13.4485 12.1516 13.3138C12.1509 13.1791 12.0416 13.0698 11.9062 13.0698"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.29816 13.0695C6.16349 13.0695 6.05416 13.1788 6.05549 13.3135C6.05416 13.4488 6.16416 13.5581 6.29883 13.5581C6.43349 13.5581 6.54283 13.4488 6.54283 13.3141C6.54283 13.1788 6.43349 13.0695 6.29816 13.0695"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
