import { ReactCountdownCircleTimer } from "infra";

export interface CountdownCircleTimerComponentProps {
  isPlaying?: boolean;
  duration: number;
  size: number;
  onComplete?: () => void;
  onUpdate?: (time: number) => void;
  initialRemainingTime: number;
  children: (props: {
    remainingTime: number;
    elapsedTime: number;
    color: string;
  }) => React.ReactNode;
}

export const CountdownCircleTimer = ({
  isPlaying = true,
  duration,
  size,
  onComplete,
  onUpdate,
  children,
  initialRemainingTime,
}: CountdownCircleTimerComponentProps) => {
  return (
    <ReactCountdownCircleTimer
      colors={["#74b740", "#d9a900", "#aa0e27", "#d9d9d9"]}
      colorsTime={[duration, duration * 0.66, duration * 0.33, 0]}
      isPlaying={isPlaying}
      strokeWidth={10}
      isSmoothColorTransition={false}
      strokeLinecap="butt"
      rotation="counterclockwise"
      duration={duration}
      size={size}
      onComplete={onComplete}
      onUpdate={onUpdate}
      initialRemainingTime={initialRemainingTime}
    >
      {children}
    </ReactCountdownCircleTimer>
  );
};
