import { IFingerprint } from "app-domain";
import { buildTestIds, obfuscate, useEnvs } from "application";
import Script from "next/script";
import { useEffect, useState } from "react";

declare const Alwm: {
  collect(action: string): Promise<string>;
  event(action: string): void;
};

const AllowMeFingerprint: IFingerprint = {
  async collect<T = string, U = Promise<string> | null>(action: T): Promise<U> {
    if (typeof Alwm !== "undefined") {
      // Alwm.event(action as unknown as string);
      try {
        const fingerprint = await Alwm.collect(action as unknown as string);
        return fingerprint as unknown as U;
      } catch {
        return null as unknown as U;
      }
    }

    return null as unknown as U;
  },
  Script() {
    const [apiKey, setApiKey] = useState("");
    const [url, setUrl] = useState("");

    const allConfigs = useEnvs((state) => state.allConfigs);

    useEffect(() => {
      const setAllowMeConfigs = async (
        allowMeApiKey: string,
        allowMeUrl: string
      ) => {
        setApiKey(obfuscate(allowMeApiKey));
        setUrl(allowMeUrl);
      };

      if (
        allConfigs?.isAllowMeAvailable === "true" &&
        allConfigs?.allowMeApiKey &&
        allConfigs?.allowMeUrl
      ) {
        setAllowMeConfigs(allConfigs.allowMeApiKey, allConfigs.allowMeUrl);
      }
    }, [
      allConfigs?.allowMeApiKey,
      allConfigs?.allowMeUrl,
      allConfigs?.isAllowMeAvailable,
    ]);

    if (allConfigs?.isAllowMeAvailable !== "true" || !apiKey || !url) {
      return null;
    }

    return (
      <>
        <Script
          id="allow-me-fingerprint-script-init"
          strategy="afterInteractive"
        >
          {` window.alwmAsyncInit=()=>{Alwm.init({apiKey:'${apiKey}',});}; `}
        </Script>
        <Script
          async
          defer
          src={url}
          type="text/javascript"
          id="allow-me-fingerprint-script"
          strategy="afterInteractive"
          {...buildTestIds("allow-me-fingerprint-script")}
        />
      </>
    );
  },
};

export { AllowMeFingerprint };
