import {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { IAppMonitoringClient, ICookie, ICrm } from "app-domain/abstractions";

import { ClientDto, CrmUserObjectProps } from "typing";
import { ProfileApi } from "../../services";
import {
  formatDateToShortCrm,
  getUserFirstName,
  getUserSurname,
} from "../../utils";
import { useCookies } from "../CookiesContext";
import { useAuth } from "./AuthContext";
import { useCrm } from "../../state-manager";

export interface UserContextProps {
  loggedClient: ClientDto | null | undefined;
  triggerLoggedClient?: () => void;
  isLoadingLoggedClient: boolean;
  setCrmUserObject: (loggedClient: ClientDto) => void;
  acceptedWhatsapp: boolean;
}

interface UserProviderProps {
  children: ReactNode;
  profileApi: ProfileApi;
  appMonitoringClient: IAppMonitoringClient;
  cookie: ICookie;
  crm: ICrm;
}

interface CrmCampaignsUserId {
  data: string;
  _expires: string;
}

const cookieForCookies = "@FC:Ecom:Cookie:HadVisualization";
const userBranchLocation = "@FC:Ecom:Dropdown:BranchLocation";
const cookieRegionStr = "@FC:Ecom:Dropdown:Region";

export const UserContext = createContext({} as UserContextProps);

export const useUser = (): UserContextProps => {
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("useUser must be used within an userProvider");
  }

  return userContext;
};

export const UserProvider = ({
  children,
  profileApi,
  appMonitoringClient,
  cookie,
  crm,
}: UserProviderProps) => {
  const { statementCookiesModal } = useCookies();

  const userHasViewedCookieModal = cookie.getCookie({
    name: cookieForCookies,
  }) as unknown as boolean;

  const userBranch = cookie.getCookie({
    name: userBranchLocation,
  });

  const clientState = cookie
    .getCookie({ name: cookieRegionStr })
    ?.trim()
    ?.split(" - ")?.[1];

  const { isAuthenticated, getTokens } = useAuth();
  const accessToken = getTokens()?.token as string;

  const {
    data: loggedClient,
    error,
    trigger: triggerLoggedClient,
    isLoading: isLoadingLoggedClient,
  } = profileApi.getClient(accessToken, !!accessToken && isAuthenticated);

  if (error) {
    appMonitoringClient.captureException(error);
  }

  const setUserCrmId = useCrm((state) => state.setUserCrmId);

  const handleSetUserCrmId = useCallback(() => {
    const spUID: CrmCampaignsUserId | null = JSON.parse(
      localStorage.getItem("spUID") || "null"
    );

    setUserCrmId(spUID?.data || null);
  }, [setUserCrmId]);

  const setCrmUserObject = useCallback(
    (loggedUser: ClientDto | null | undefined) => {
      if (loggedUser) {
        const name = getUserFirstName(loggedUser?.name);
        const surname = getUserSurname(loggedUser?.name);

        const userAddressDefault = loggedUser?.addresses?.find(
          (address) => address.isDefault === true
        );

        const birthdateToFormat = loggedUser?.birthdate
          ? new Date(loggedUser.birthdate)
          : "";

        const birthdate = formatDateToShortCrm(birthdateToFormat);

        const loggedClientParams: CrmUserObjectProps = {
          uuid: loggedUser?.cpf,
          phone_number: `+55${loggedUser?.phoneNumber}`,
          email: loggedUser?.email,
          login: true,
          name: name || "",
          surname: surname || "",
          ...(userHasViewedCookieModal && { gdpr_optin: true }),
          sms_optin: loggedUser?.acceptedTermsUse,
          email_optin: loggedUser?.acceptedTermsUse,
          whatsapp_optin: loggedUser?.acceptedTermsUse,
          gender: loggedUser?.gender,
          birthday: birthdate,
          custom: {
            branch: userBranch || "",
            city: userAddressDefault?.city || "",
            state: userAddressDefault?.state || clientState,
          },
        };

        crm.userObject(loggedClientParams);

        if (loggedUser?.cpf) {
          handleSetUserCrmId();
        }
      } else {
        const anonymousClientParams = {
          login: false,
          ...(!statementCookiesModal && { gdpr_optin: true }),
          custom: {
            branch: userBranch || "",
          },
        };
        setUserCrmId(null);

        crm.userObject(anonymousClientParams);
      }

      // crm.reinitializeTag();
    },
    [
      clientState,
      crm,
      handleSetUserCrmId,
      setUserCrmId,
      statementCookiesModal,
      userBranch,
      userHasViewedCookieModal,
    ]
  );

  useEffect(() => {
    setCrmUserObject(loggedClient);
  }, [loggedClient, setCrmUserObject]);

  const { data: userData, error: userDataError } = profileApi.getClient(
    accessToken,
    !!accessToken
  );

  const acceptedWhatsapp = useMemo(() => {
    return !!userData?.acceptedWhatsapp;
  }, [userData]);

  if (userDataError) {
    appMonitoringClient.captureException(userDataError);
  }

  const userContextProviderValue = useMemo(() => {
    return {
      loggedClient,
      setCrmUserObject,
      triggerLoggedClient,
      isLoadingLoggedClient,
      acceptedWhatsapp,
    };
  }, [
    loggedClient,
    setCrmUserObject,
    isLoadingLoggedClient,
    triggerLoggedClient,
    acceptedWhatsapp,
  ]);

  return (
    <UserContext.Provider value={userContextProviderValue}>
      {children}
    </UserContext.Provider>
  );
};
