import styled, { css } from "styled-components";
import { CloseIcon, theme } from "ui";
import { ButtonSubmit } from "../ButtonSubmit";
import { Modal } from "../Modal";

interface BaseModalHeaderProps {
  justifyContent?: string;
  displayHeader?: string;
}

interface BaseModalBodyProps {
  backgroundColor?: string;
  padding?: string;
}

type BaseModalFooterProps = {
  gap: string;
  hasFooterShadow?: boolean;
  padding?: string;
};

type BaseModalHeaderTitleProps = {
  titleFontSize?: string;
};

const BaseModalHeaderTitle = styled.h1<BaseModalHeaderTitleProps>`
  flex: 1;
  display: inline-flex;
  align-items: center;
  padding: 1rem 1rem 1rem 2.5rem;
  font-size: ${({ titleFontSize }) => titleFontSize || "2rem"};
  font-weight: bold;
`;

const BaseModalHeaderClose = styled.section`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  cursor: pointer;
`;

interface CloseIconProps {
  color?: string;
}

const Close = styled(CloseIcon).attrs<CloseIconProps>(({ color }) => ({
  width: "3.5rem",
  height: "3.5rem",
  strokeWidth: "0.75",
  color,
}))<CloseIconProps>``;

const BaseModalBody = styled.section<BaseModalBodyProps>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? theme.colors.neutral["80"]};
  padding: ${({ padding }) => padding || "2.5rem 2rem"};
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
`;

const BaseModalFooter = styled.section<BaseModalFooterProps>`
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  gap: ${({ gap }) => gap};
  align-items: center;
  z-index: 1;
  flex: 1;
  max-height: 70px;
  min-height: 70px;
  padding: ${({ padding }) => padding || "initial"};
  padding: 1rem;

  ${({ hasFooterShadow }) =>
    hasFooterShadow &&
    css`
      -webkit-box-shadow: 0px -38px 26px -15px rgba(0, 0, 0);
      -moz-box-shadow: 0px -38px 26px -15px rgba(0, 0, 0);
      box-shadow: 0px -38px 26px -15px rgba(0, 0, 0);
    `}
`;

const BaseModalFooterButton = styled(ButtonSubmit).attrs({
  alignSelf: "center",
  fontSize: theme.typography.fontSize.sm.x5,
  height: "4rem",
})`
  padding: 0 16px;
  transition: background-color 0.2s ease-in;
  align-self: center;
`;

const BaseModalHeader = styled.section<BaseModalHeaderProps>`
  display: ${({ displayHeader }) => displayHeader || "flex"};
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: ${({ justifyContent }) => justifyContent ?? "space-between"};
  box-shadow: 0 1px 3px ${theme.colors.neutral["999-opacity-10"]};
  position: relative;
  z-index: 1;
  flex: 1;
  max-height: 70px;
  min-height: 70px;
`;

const BaseModal = styled(Modal)<{ isNewModal?: boolean }>`
  ${({ isNewModal }) =>
    isNewModal &&
    css`
      ${BaseModalHeader} {
        width: 100%;
        height: fit-content;
      }

      ${BaseModalHeaderTitle} {
        padding: 0;
        color: ${theme.colors.neutral["695"]};
        font-size: ${theme.typography.fontSize.sm.x7};
      }
    `}
`;

export {
  BaseModal,
  BaseModalHeader,
  BaseModalHeaderTitle,
  BaseModalHeaderClose,
  Close,
  BaseModalBody,
  BaseModalFooter,
  BaseModalFooterButton,
};
