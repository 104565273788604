import {
  CtaText,
  CustomTextBlockProperty,
  HideText,
  PlainText,
  ShowSubTitle,
  ShowTitle,
  SubTitle,
  Text,
  Title,
} from "typing";

const customTextBlockData = (properties: CustomTextBlockProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const showTitle = (
    properties.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const ctaText = (properties.find(({ key }) => key === "ctaText") as CtaText)
    ?.value;

  const subTitle = (
    properties.find(({ key }) => key === "subTitle") as SubTitle
  )?.value;

  const showSubTitle = (
    properties.find(({ key }) => key === "showSubTitle") as ShowSubTitle
  )?.value;

  const plainText = (
    properties.find(({ key }) => key === "plainText") as PlainText
  )?.value;

  const hideText = (
    properties.find(({ key }) => key === "hideText") as HideText
  )?.value;

  const text = (properties.find(({ key }) => key === "text") as Text)?.value;

  return {
    title,
    showTitle,
    ctaText,
    subTitle,
    showSubTitle,
    text,
    plainText,
    hideText,
  };
};

const removeHtmlTags = (html: string): string => {
  return html.replace(/<[^>]*>/g, "");
};

const calculateTextLimit = (
  htmlText: string | undefined,
  limit: number
): number => {
  if (!htmlText) return limit;

  const plainText = removeHtmlTags(htmlText);
  if (plainText.length > limit) {
    const lastSpaceIndex = plainText.lastIndexOf(" ", limit);
    return lastSpaceIndex !== -1 ? lastSpaceIndex : limit;
  }
  return plainText.length;
};

const truncateHtml = (htmlText: string, limit: number): string => {
  const plainTextLimit = calculateTextLimit(htmlText, limit);
  let plainTextCount = 0;
  let htmlTextCount = 0;
  let isInsideTag = false;
  const truncatedChars = [];

  while (plainTextCount < plainTextLimit && htmlTextCount < htmlText.length) {
    const currentChar = htmlText[htmlTextCount];

    if (currentChar === "<") {
      isInsideTag = true;
    } else if (currentChar === ">") {
      isInsideTag = false;
    } else if (!isInsideTag) {
      plainTextCount++;
    }

    truncatedChars.push(currentChar);
    htmlTextCount++;
  }

  const truncatedHtml = truncatedChars.join("");

  const openTags = [];
  const tagRegex = /<([a-zA-Z]+)(?:"[^"]*"|'[^']*'|[^'">])*>|<\/([a-zA-Z]+)>/g;
  let tagMatch;
  while ((tagMatch = tagRegex.exec(truncatedHtml)) !== null) {
    if (tagMatch[1]) {
      openTags.push(tagMatch[1]);
    } else if (tagMatch[2]) {
      const matchPosition = openTags.lastIndexOf(tagMatch[2]);
      if (matchPosition !== -1) {
        openTags.splice(matchPosition, 1);
      }
    }
  }

  openTags.reverse().forEach((tag) => {
    truncatedChars.push(`</${tag}>`);
  });

  return truncatedHtml;
};

export {
  calculateTextLimit,
  customTextBlockData,
  removeHtmlTags,
  truncateHtml,
};
