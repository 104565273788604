import { buildTestIds } from "application";
import { ReactNode } from "react";
import { CloseIcon, House, theme } from "ui";
import {
  CloseRequestModalFeedback,
  GoToInitialPageContainer,
  GoToInitialPageText,
  RequestContent,
  RequestFeedbackModalContainer,
  RequestFeedbackModalHeader,
  RequestFeedbackOverlay,
  RequestSentDescription,
  RequestSentIcon,
  RequestSentTitle,
} from "./styles";

export interface RequestFeedbackModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  title: string;
  description: string;
  icon: ReactNode;
  onNavigateToInitialPage?: () => void;
}

const RequestFeedbackModal = ({
  isOpen,
  onCloseModal,
  title,
  description,
  icon,
  onNavigateToInitialPage,
}: RequestFeedbackModalProps) => {
  return (
    <RequestFeedbackOverlay
      {...buildTestIds("request-feedback-modal-overlay")}
      isOpen={isOpen}
    >
      <RequestFeedbackModalContainer
        {...buildTestIds("request-feedback-modal-container")}
      >
        <RequestFeedbackModalHeader
          {...buildTestIds("request-feedback-modal-header")}
        >
          <CloseRequestModalFeedback
            {...buildTestIds("close-request-modal-feedback")}
            onClick={onCloseModal}
          >
            <CloseIcon color={theme.colors.neutral["999"]} />
          </CloseRequestModalFeedback>
        </RequestFeedbackModalHeader>
        <RequestSentIcon {...buildTestIds("request-feedback-modal-icon")}>
          {icon}
        </RequestSentIcon>
        <RequestContent {...buildTestIds("request-feedback-modal-content")}>
          <RequestSentTitle {...buildTestIds("request-feedback-modal-title")}>
            {title}
          </RequestSentTitle>
          <RequestSentDescription
            {...buildTestIds("request-feedback-modal-description")}
          >
            {description}
          </RequestSentDescription>
        </RequestContent>
        <GoToInitialPageContainer
          {...buildTestIds("request-feedback-modal-go-to-initial-page")}
          onClick={onNavigateToInitialPage}
        >
          <House
            width={24}
            height={24}
            color={`${theme.colors.secondary["380"]}`}
          />
          <GoToInitialPageText
            {...buildTestIds("request-feedback-modal-go-to-initial-page-text")}
          >
            Ir para página inicial
          </GoToInitialPageText>
        </GoToInitialPageContainer>
      </RequestFeedbackModalContainer>
    </RequestFeedbackOverlay>
  );
};

export { RequestFeedbackModal };
