import styled from "styled-components";
import { theme } from "ui";

interface PasswordValidationProps {
  color?: string;
  marginTop?: string;
  display?: boolean;
}

export const PasswordValidationContainer = styled.div<PasswordValidationProps>`
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${theme.space.x2};
`;

export const StrengthIndicator = styled.div`
  display: flex;
  gap: 0.4rem;
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.neutral["550"]} !important;
  margin-bottom: ${theme.space.x2};
`;

export const PasswordStrength = styled.span`
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["600"]};
  color: initial !important;
`;

export const StrengthIndicatorItems = styled.div`
  display: flex;
  gap: ${theme.space.x2};
  flex-direction: column;
`;

export const StrengthIndicatorItem = styled.div`
  display: flex;
  gap: ${theme.space.x2};
  align-items: center;
`;

export const StrengthIndicatorText = styled.span<PasswordValidationProps>`
  color: ${({ color }) => color} !important;
  font-size: ${theme.typography.fontSize.sm.x5};
`;
