export const MockedPaymentApi = {
  baseUrl: "",
  SALE_CHANNEL: 4,

  getCards(jwt: string, condition: boolean) {
    const isLoading = jwt && condition;
    const error = null;
    const data = [
      {
        id: 3147,
        ownerName: "artur polo norte",
        lastFourNumbers: "4242",
        banner: "Visa",
        cardExpiringDate: "12/2032",
        token: "0vgqzkuUCQU=",
        clientId: 4505,
        updatedon: "2023-03-14T09:34:47.910366",
        bin: "424242",
        cpfHolder: "42471108024",
        tokenBraspag: null,
        tokenMundipagg: " ",
        cardType: "C",
      },
    ];
    return { data, error, isLoading };
  },
};
