import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const OrderApproved = ({
  width = 44,
  height = 44,
  alt = "pedido aprovado",
  color = theme.colors.secondary["275"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="22" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 31.5H16C15.4695 31.5001 14.9607 31.2895 14.5856 30.9144C14.2105 30.5393 13.9999 30.0305 14 29.5L15 19C15.0003 18.1717 15.6717 17.5003 16.5 17.5H27.5C28.3283 17.5003 28.9997 18.1717 29 19L30 29.5C30.0001 30.0305 29.7895 30.5393 29.4144 30.9144C29.0393 31.2895 28.5305 31.5001 28 31.5Z"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 19.5V17C18 14.929 19.5 12.5 21.75 12.5H22.25C24.5 12.5 26 14.929 26 17V19.5"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 25.5L21 27.5L25 23"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
