import { useAppContext } from "application";
import { useShallow, useTwoFactor } from "application/state-manager";

const useFormFooter = () => {
  const { isClientMobile } = useAppContext();

  const { isValidationActive } = useTwoFactor(
    useShallow((state) => ({ isValidationActive: state.isValidationActive }))
  );

  const hideFooter = isValidationActive && isClientMobile;

  return {
    isClientMobile,
    hideFooter,
  };
};

export { useFormFooter };
