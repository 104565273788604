import styled from "styled-components";
import { FlexContainer } from "ui";

interface CustomBannerContainerProps {
  isDynamicBanner?: boolean;
  minHeight?: string;
  maxHeight?: string;
}

export const CustomBannerContainer = styled(
  FlexContainer
)<CustomBannerContainerProps>`
  align-items: center;
  justify-content: center;
  width: ${({ isDynamicBanner }) => (isDynamicBanner ? "98.5vw" : "100%")};
  min-height: ${({ minHeight }) => minHeight || "max-content"};
  max-height: ${({ maxHeight }) => maxHeight || "max-content"};
  margin-bottom: 14px;
`;
