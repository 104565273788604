import {
  ApiDefaultErrorDto,
  BlackFridayThemeDto,
  ComponentDto,
  IHttpResponse,
  NewTemplateDto,
  PlaylistUrlType,
  TemplateDto,
} from "typing";

import { IHttpClient } from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import { formatBranchId, getApisUrlFallback } from "../../../utils";
import { defaultHeaders } from "../../../utils/http";
import { EnvsApi } from "../Envs";

export class BackofficeApi implements IApi {
  baseUrl = "";

  constructor(private httpClient: IHttpClient, private envsApi: EnvsApi) {
    if (!this.baseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}backoffice-api/`;
    }
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getPageTemplateEndpoint = (
    playlistUrlType: PlaylistUrlType,
    branchId: string,
    currentPage: number,
    playlistUrl?: string,
    pageSize?: string
  ) => {
    const formattedBranchId = formatBranchId(branchId);

    return `${
      this.baseUrl
    }v1/template/url/${playlistUrlType}?branchId=${formattedBranchId}&currentPage=${currentPage}${
      playlistUrl
        ? `&playlistUrl=${playlistUrl}&PageSize=${pageSize}`
        : `&PageSize=${pageSize}`
    }`;
  };

  getPageTemplate = async (
    playlistUrlType: PlaylistUrlType,
    branchId: string,
    playlistUrl?: string,
    currentPage = 1,
    condition = true,
    pageSize = "46"
  ) => {
    if (typeof window !== "undefined") {
      const { data, error, isLoading } = this.httpClient.useGet<
        TemplateDto | NewTemplateDto
      >(
        this.getPageTemplateEndpoint(
          playlistUrlType,
          branchId,
          currentPage,
          playlistUrl,
          pageSize
        ),
        {
          headers: {
            "Accept-Encoding": "gzip, deflate",
          },
        },
        !!condition
      ) as IHttpResponse<TemplateDto | NewTemplateDto, unknown>;

      return {
        data,
        isLoading,
        error,
      };
    }

    try {
      const response = await fetch(
        this.getPageTemplateEndpoint(
          playlistUrlType,
          branchId,
          currentPage,
          playlistUrl
        ),
        {
          headers: { ...defaultHeaders },
          // remove o signal temporariamente para prosseguirmos com testes de carga
          // signal: (AbortSignal as unknown as AbortSignalWithTimeout).timeout(
          //   15000
          // ),
        }
      );

      const formattedError = {
        data: null,
        isLoading: false,
        error: `A http request para buscar o template cujo tipo é ${playlistUrlType}, cuja url é ${playlistUrl} e cuja branchId é ${branchId} não retornou nenhuma informação`,
      };

      try {
        const data = await response.json();

        if (!data || !response.ok) {
          return formattedError;
        }

        return {
          data,
          isLoading: false,
          error: null,
        } as IHttpResponse<TemplateDto | NewTemplateDto, unknown>;
      } catch {
        return formattedError;
      }
    } catch (err) {
      return {
        data: null,
        isLoading: false,
        error: err,
      };
    }
  };

  private getCustomHeaderEndpoint = (branchId: string) => {
    const formattedBranchId = formatBranchId(branchId);

    return `${this.baseUrl}v1/template/global-header/${formattedBranchId}`;
  };

  getCustomHeader = (branchId: string, condition = true) => {
    const { data, error, isLoading } = this.httpClient.useGet<ComponentDto[]>(
      this.getCustomHeaderEndpoint(branchId),
      {
        headers: {
          "Accept-Encoding": "gzip, deflate",
        },
      },
      !!condition
    ) as IHttpResponse<ComponentDto[] | ApiDefaultErrorDto, unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  private getBlackFridayThemeEndpoint = () => {
    return `${this.baseUrl}v1/draft/black-theme`;
  };

  getBlackFridayTheme = async (): Promise<
    IHttpResponse<BlackFridayThemeDto, unknown>
  > => {
    try {
      const response = await fetch(this.getBlackFridayThemeEndpoint(), {
        headers: { ...defaultHeaders },
      });

      if (!response.ok) {
        return {
          data: null,
          isLoading: false,
          error: await response.text(),
        };
      }

      const data = await response.json();

      return {
        data,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };
}
