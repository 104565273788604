import { IconProps } from "typing";
import { theme } from "../themes";

export const Share = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral.white,
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill={color}
    >
      <g id="Icons / Share">
        <path
          id="Mask"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4772 4.26136C17.4772 4.72199 17.5507 5.16577 17.6866 5.58161L8.47053 10.8358C7.68866 9.94104 6.53966 9.375 5.26138 9.375C2.91242 9.375 1 11.2864 1 13.6364C1 15.9864 2.91242 17.8977 5.26138 17.8977C6.53985 17.8977 7.689 17.3315 8.47088 16.4366L17.6868 21.6906C17.5508 22.1066 17.4772 22.5505 17.4772 23.0114C17.4772 25.3614 19.3899 27.2727 21.7386 27.2727C24.0876 27.2727 26 25.3614 26 23.0114C26 20.6614 24.0876 18.75 21.7386 18.75C20.4608 18.75 19.3121 19.3157 18.5302 20.21L9.31375 14.9556C9.44939 14.5401 9.52275 14.0966 9.52275 13.6364C9.52275 13.1759 9.44932 12.7322 9.31356 12.3165L18.5299 7.06235C19.3118 7.95686 20.4606 8.52273 21.7386 8.52273C24.0876 8.52273 26 6.61136 26 4.26136C26 1.91137 24.0876 0 21.7386 0C19.3899 0 17.4772 1.91137 17.4772 4.26136ZM19.1818 4.26136C19.1818 2.85104 20.3295 1.70455 21.7386 1.70455C23.1477 1.70455 24.2955 2.85104 24.2955 4.26136C24.2955 5.67169 23.1477 6.81818 21.7386 6.81818C20.3295 6.81818 19.1818 5.67169 19.1818 4.26136ZM19.1818 23.0114C19.1818 21.601 20.3295 20.4545 21.7386 20.4545C23.1477 20.4545 24.2955 21.601 24.2955 23.0114C24.2955 24.4217 23.1477 25.5682 21.7386 25.5682C20.3295 25.5682 19.1818 24.4217 19.1818 23.0114ZM5.26138 11.0795C3.85229 11.0795 2.70455 12.226 2.70455 13.6364C2.70455 15.0467 3.85229 16.1932 5.26138 16.1932C6.67046 16.1932 7.8182 15.0467 7.8182 13.6364C7.8182 12.226 6.67046 11.0795 5.26138 11.0795Z"
          strokeWidth={strokeWidth}
        />
        <mask
          id="mask0_0_3206"
          maskUnits="userSpaceOnUse"
          x="1"
          y="0"
          width={Number(width) - 3}
          height={height}
        >
          <path
            id="Mask_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4772 4.26136C17.4772 4.72199 17.5507 5.16577 17.6866 5.58161L8.47053 10.8358C7.68866 9.94104 6.53966 9.375 5.26138 9.375C2.91242 9.375 1 11.2864 1 13.6364C1 15.9864 2.91242 17.8977 5.26138 17.8977C6.53985 17.8977 7.689 17.3315 8.47088 16.4366L17.6868 21.6906C17.5508 22.1066 17.4772 22.5505 17.4772 23.0114C17.4772 25.3614 19.3899 27.2727 21.7386 27.2727C24.0876 27.2727 26 25.3614 26 23.0114C26 20.6614 24.0876 18.75 21.7386 18.75C20.4608 18.75 19.3121 19.3157 18.5302 20.21L9.31375 14.9556C9.44939 14.5401 9.52275 14.0966 9.52275 13.6364C9.52275 13.1759 9.44932 12.7322 9.31356 12.3165L18.5299 7.06235C19.3118 7.95686 20.4606 8.52273 21.7386 8.52273C24.0876 8.52273 26 6.61136 26 4.26136C26 1.91137 24.0876 0 21.7386 0C19.3899 0 17.4772 1.91137 17.4772 4.26136ZM19.1818 4.26136C19.1818 2.85104 20.3295 1.70455 21.7386 1.70455C23.1477 1.70455 24.2955 2.85104 24.2955 4.26136C24.2955 5.67169 23.1477 6.81818 21.7386 6.81818C20.3295 6.81818 19.1818 5.67169 19.1818 4.26136ZM19.1818 23.0114C19.1818 21.601 20.3295 20.4545 21.7386 20.4545C23.1477 20.4545 24.2955 21.601 24.2955 23.0114C24.2955 24.4217 23.1477 25.5682 21.7386 25.5682C20.3295 25.5682 19.1818 24.4217 19.1818 23.0114ZM5.26138 11.0795C3.85229 11.0795 2.70455 12.226 2.70455 13.6364C2.70455 15.0467 3.85229 16.1932 5.26138 16.1932C6.67046 16.1932 7.8182 15.0467 7.8182 13.6364C7.8182 12.226 6.67046 11.0795 5.26138 11.0795Z"
          />
        </mask>
        <g mask="url(#mask0_0_3206)">
          <g id="Cores/Preto">
            <rect
              id="Rectangle"
              width={width}
              height={height}
              fill="transparent"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
