import { buildTestIds } from "application";
import InputMask from "react-input-mask";
import { MaskInputProps } from "../../type";

export const MaskedInput = ({ ...props }: MaskInputProps) => {
  const { type, required, onBlur, onChange, value, id, ref, field } = props;

  return (
    <InputMask
      {...buildTestIds(`form-input-mask-${id}`)}
      {...field}
      {...props}
      type={type ?? "text"}
      ref={ref || field.ref}
      onChange={(e) => {
        if (onChange) onChange(e);

        if (field) field.onChange(e);
      }}
      onBlur={(e) => {
        if (onBlur) onBlur(e);

        if (field) field.onBlur();
      }}
      required={Boolean(required)}
      value={value || field.value}
    />
  );
};
