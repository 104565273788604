import { buildTestIds, useAuth, useToast, useUser } from "application";
import {
  SendCodeProps,
  useCounter,
  useShallow,
  useTwoFactor,
} from "application/state-manager";
import { notificationApi } from "implementations";
import { useCallback, useEffect, useState } from "react";
import { SendCodeType } from "typing";
import { ResendCode } from "./styles";

interface CounterProps {
  faType?: number;
  channel?: number;
  sendCodeType: Exclude<SendCodeType, "app">;
}

const Counter = ({ faType, sendCodeType, channel }: CounterProps) => {
  const { loggedClient } = useUser();
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;
  const { addToast } = useToast();

  const { email, phoneNumber, sendCode } = useTwoFactor(
    useShallow((state) => ({
      email: state.email,
      phoneNumber: state.phoneNumber,
      sendCode: state.sendCode,
    }))
  );

  const { initTimer, setInitTimer } = useCounter(
    useShallow((state) => ({
      initTimer: state.initTimer,
      setInitTimer: state.setInitTimer,
    }))
  );

  const [countdownTime, setCountdownTime] = useState<number>(() => {
    const storedCountdownTime = localStorage.getItem(
      `countdownTime_${faType}_${channel}`
    );
    const storedTimestamp = localStorage.getItem(
      `countdownTimeTimestamp_${faType}_${channel}`
    );

    if (storedCountdownTime && storedTimestamp) {
      const elapsedTime = Math.floor(
        (Date.now() - parseInt(storedTimestamp, 10)) / 1000
      );
      const remainingTime = parseInt(storedCountdownTime, 10) - elapsedTime;

      if (remainingTime > 0) {
        return remainingTime;
      }
    }

    return 0;
  });

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const getInfoValue = useCallback(
    (
      passedSendCodeType: Exclude<SendCodeType, "app">,
      passedEmail: string,
      passedPhoneNumber: string
    ) => {
      if (passedSendCodeType === "email") {
        return passedEmail?.replace(/@/g, "%40");
      }

      return passedPhoneNumber;
    },
    []
  );

  const handleResendCode = useCallback(
    ({
      sendCodeType: passedSendCodeType,
      notificationApi: passedNotificationApi,
      addToast: passedAddToast,
      infoValue,
      errorTitle,
    }: SendCodeProps) => {
      if (countdownTime === 0) {
        setInitTimer(true);
        sendCode({
          faType,
          sendCodeType: passedSendCodeType,
          notificationApi: passedNotificationApi,
          addToast: passedAddToast,
          infoValue,
          errorTitle,
          email,
          cpf: loggedClient?.cpf,
          phoneNumber: phoneNumber?.replace(/\D/g, ""),
          token: accessToken,
        });
      }
    },
    [
      countdownTime,
      setInitTimer,
      sendCode,
      faType,
      email,
      loggedClient?.cpf,
      phoneNumber,
      accessToken,
    ]
  );

  useEffect(() => {
    if (!initTimer && countdownTime === 0 && faType && channel) {
      setInitTimer(true);
      localStorage.setItem(
        `timeSent_${faType}_${channel}`,
        Date.now().toString()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (faType && channel) {
      localStorage.setItem(
        `countdownTime_${faType}_${channel}`,
        countdownTime.toString()
      );
      localStorage.setItem(
        `countdownTimeTimestamp_${faType}_${channel}`,
        Date.now().toString()
      );
    }
  }, [countdownTime, faType, channel]);

  useEffect(() => {
    if (countdownTime > 0) {
      const intervalId = setInterval(() => {
        setCountdownTime((current) => current - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }

    if (initTimer && countdownTime === 0 && faType && channel) {
      setCountdownTime(120);
      setInitTimer(false);
      localStorage.setItem(
        `timeSent_${faType}_${channel}`,
        (Date.now() / 1000 + 120).toString()
      );
    }

    return undefined;
  }, [channel, countdownTime, faType, initTimer, setInitTimer]);

  return (
    <ResendCode
      {...buildTestIds("resend-code")}
      activeTimer={countdownTime > 0}
      onClick={() =>
        handleResendCode({
          sendCodeType,
          notificationApi,
          infoValue: getInfoValue(
            sendCodeType,
            email,
            phoneNumber?.replace(/\D/g, "")
          ),
          addToast,
          cpf: loggedClient?.cpf,
          email,
          phoneNumber,
          faType,
          errorTitle: "Erro ao realizar a ação",
        })
      }
    >
      {countdownTime === 0
        ? "Reenviar código"
        : `Reenviar código em ${formatTime(countdownTime)}`}
    </ResendCode>
  );
};

export default Counter;
