import { IconProps } from "typing";
import { theme } from "../themes";

export const PhoneCallIcon = ({
  width = 20,
  height = 21,
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8041 14.342L14.8266 13.3645C14.3383 12.8761 13.5466 12.8761 13.0591 13.3645L12.2908 14.1328C12.1191 14.3045 11.8591 14.362 11.6375 14.2645C10.5241 13.777 9.42415 13.0378 8.44331 12.057C7.46665 11.0803 6.72998 9.9853 6.24165 8.87613C6.13998 8.64697 6.19915 8.3778 6.37665 8.2003L7.06498 7.51197C7.62415 6.9528 7.62415 6.16197 7.13581 5.67363L6.15831 4.69613C5.50748 4.0453 4.45248 4.0453 3.80165 4.69613L3.25831 5.23863C2.64081 5.85613 2.38331 6.74697 2.54998 7.6303C2.96165 9.8078 4.22665 12.192 6.26748 14.2328C8.30831 16.2736 10.6925 17.5386 12.87 17.9503C13.7533 18.117 14.6441 17.8595 15.2616 17.242L15.8041 16.6995C16.455 16.0486 16.455 14.9936 15.8041 14.342V14.342Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8333 6.32599C11.6924 6.31433 12.5558 6.63266 13.2116 7.28849"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5683 4.93188C14.2608 3.62438 12.5466 2.97021 10.8333 2.97021"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1742 9.66691C14.1858 8.80774 13.8675 7.94441 13.2117 7.28857"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5684 4.93164C16.8759 6.23914 17.53 7.95331 17.53 9.66664"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
