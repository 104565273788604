import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const WarningRevalidationIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.yellow[1050],
  secondColor = theme.colors.neutral["white-010"],
  alt,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.029 4.13919L21.688 17.5432C22.578 19.1012 21.453 21.0402 19.659 21.0402H4.34101C2.54601 21.0402 1.42101 19.1012 2.31201 17.5432L9.97101 4.13919C10.868 2.56819 13.132 2.56819 14.029 4.13919Z"
        fill={secondColor}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.12V9.38"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.999 16.125C11.861 16.125 11.749 16.237 11.75 16.375C11.75 16.513 11.862 16.625 12 16.625C12.138 16.625 12.25 16.513 12.25 16.375C12.25 16.237 12.138 16.125 11.999 16.125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
