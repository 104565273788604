/* eslint-disable react/no-array-index-key */
import {
  getUserInitials,
  useAuthentication,
  useEnvs,
  useShallow,
} from "application";
import { useAuth } from "application/context/Identity/AuthContext";
import { useAppContext } from "application/context/state";
import { cookie } from "implementations";
import { Fragment, MouseEventHandler, useState } from "react";
import { AllConfigs, CategoryDto } from "typing";
import { SidebarMenuRefProps } from "typing/types/SidebarMenuRefProps";
import { FlexContainer } from "ui";
import { Link } from "../../../../../Link";
import { ListContentSidebarMenuSubCategories } from "./Level2";
import { OffersList } from "./OffersList";
import { companyProducts, userAuthenticatedActions } from "./data";
import {
  AllCategoriesButton,
  ArrowLeftIcon,
  AuthenticatedOptionDefault,
  AuthenticatedOptionWithLink,
  CategoryExploreIcon,
  CategorySidebarMenu,
  CloseSidebarBox,
  CloseSidebarMenuIcon,
  EnterpriseProductSidebarMenu,
  GiftBoxIcon,
  LinkWrapper,
  ListCategoriesSidebarMenu,
  ListContentSidebarMenuWrapper,
  ListContentSidebarMenuWrapperTwo,
  SidebarContainerOverlay,
  SidebarMenuHeader,
  SidebarMenuHeaderContent,
  SidebarMenuHeaderSection,
  SidebarMenuHeaderWrapper,
  SidebarMenuMobileContainer,
  SubtitleSidebarMenuHyperlink,
  TitleAction,
  TitleSidebarMenuHyperlink,
  UserAuthenticatedActionsSidebarMenu,
  UserAuthenticatedImageCircle,
  UserAuthenticatedWrapper,
  UserSidebarMenuIcon,
  UsernameTitle,
  WeddingGiftListTitle,
  WrapperIcon,
} from "./styles";

export interface SidebarMenuProps {
  sidebarMenuRef: SidebarMenuRefProps;
  isOpenSidebarMenu: boolean;
  onCloseSidebarMenu: () => void;
}

const formatTestId = (testId: string) => {
  return testId.replace(/\s/g, "-").toLowerCase();
};

const SidebarMenuMobile = ({
  sidebarMenuRef,
  isOpenSidebarMenu,
  onCloseSidebarMenu,
}: SidebarMenuProps) => {
  const allConfigs = useEnvs((state) => state.allConfigs);
  const { categories } = useAppContext();
  const { isAuthenticated, userData, signOutAccount } = useAuth();
  const [isShowAllCategories, setIsShowAllCategories] =
    useState<string>("TodasCategorias");
  const [selectedCategory, setSelectedCategory] = useState<CategoryDto | null>(
    null
  );

  const { changeCurrentStep } = useAuthentication(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
    }))
  );

  const handleRedirectToLogin = () => {
    changeCurrentStep("login");
  };

  const handleCloseSideBarMenu = () => {
    document.body.style.overflow = "visible";
  };

  return (
    <>
      <SidebarMenuMobileContainer
        ref={sidebarMenuRef}
        isActiveOverlay={isOpenSidebarMenu}
        data-testid="sidebar-mobile-container"
        data-cy="sidebar-mobile-container"
      >
        <SidebarMenuHeader
          data-testid="styled-sidebar-menu-mobile"
          data-cy="styled-sidebar-menu-mobile"
        >
          <SidebarMenuHeaderSection
            data-testid="styled-menu-header-mobile"
            data-cy="styled-menu-header-mobile"
          >
            {isAuthenticated ? (
              <UserAuthenticatedWrapper
                data-cy="wrapper-user-authenticated-sidebar-mobile"
                data-testid="wrapper-user-authenticated-sidebar-mobile"
              >
                <UserAuthenticatedImageCircle
                  data-cy="styled-user-authenticated-image-circle"
                  data-testid="styled-user-authenticated-image-circle"
                >
                  <UsernameTitle
                    data-cy="username-title-icon"
                    data-testid="username-title-icon"
                    isTitleOnImage
                  >
                    {userData?.name ? getUserInitials(userData?.name) : null}
                  </UsernameTitle>
                </UserAuthenticatedImageCircle>
                <UsernameTitle
                  data-cy="username-authenticated-sidebar-mobile"
                  data-testid="username-authenticated-sidebar-mobile"
                >
                  Olá, {userData?.name}
                </UsernameTitle>
              </UserAuthenticatedWrapper>
            ) : (
              <>
                <UserSidebarMenuIcon
                  data-cy="user-sidebar-menu-icon"
                  data-testid="user-sidebar-menu-icon"
                />
                <SidebarMenuHeaderWrapper
                  data-cy="wrapper-unauthenticated-sidebar-mobile"
                  data-testid="wrapper-unauthenticated-sidebar-mobile"
                >
                  <TitleSidebarMenuHyperlink
                    href="/login"
                    target="_self"
                    onClick={handleRedirectToLogin}
                    data-cy="title-login-redirect-sidebar-mobile"
                    data-testid="title-login-redirect-sidebar-mobile"
                  >
                    Entrar ou
                  </TitleSidebarMenuHyperlink>
                  <TitleSidebarMenuHyperlink
                    href="/cadastro"
                    target="_self"
                    data-cy="title-signup-redirect-sidebar-mobile"
                    data-testid="title-signup-redirect-sidebar-mobile"
                  >
                    {" "}
                    criar conta
                  </TitleSidebarMenuHyperlink>
                </SidebarMenuHeaderWrapper>
              </>
            )}
            <CloseSidebarBox
              id="CloseSidebarBoxId"
              onClick={() => {
                onCloseSidebarMenu();
                handleCloseSideBarMenu();
              }}
              isOpenSidebarMenu={isOpenSidebarMenu}
              data-cy="close-sidebar-mobile"
              data-testid="close-sidebar-mobile"
            >
              <CloseSidebarMenuIcon
                data-cy="close-sidebar-menu-icon"
                data-testid="close-sidebar-menu-icon"
                onClick={() => {
                  onCloseSidebarMenu();
                  handleCloseSideBarMenu();
                }}
              />
            </CloseSidebarBox>
          </SidebarMenuHeaderSection>
          <SidebarMenuHeaderContent
            data-testid="sidebar-menu-header-content"
            data-cy="sidebar-menu-header-content"
            onClick={onCloseSidebarMenu}
          >
            <SubtitleSidebarMenuHyperlink
              href="/conta/meus-dados"
              target="_self"
              data-cy="side-bar-menu-option-user-authenticated-meus-dados"
              data-testid="side-bar-menu-option-user-authenticated-meus-dados"
            >
              Meus Dados
            </SubtitleSidebarMenuHyperlink>
            <SubtitleSidebarMenuHyperlink
              target="_self"
              data-cy="my-orders-sidebar-menu-mobile"
              data-testid="my-orders-sidebar-menu-mobile"
              href="/conta/meus-pedidos"
            >
              Meus Pedidos
            </SubtitleSidebarMenuHyperlink>
            <SubtitleSidebarMenuHyperlink
              target="_self"
              data-cy="side-bar-menu-option-user-authenticated-pedidos-eletronicos"
              data-testid="side-bar-menu-option-user-authenticated-pedidos-eletronicos"
              href="/conta/pedidos-eletronicos"
            >
              Pedidos telefone / WhatsApp
            </SubtitleSidebarMenuHyperlink>
          </SidebarMenuHeaderContent>
        </SidebarMenuHeader>

        <ListCategoriesSidebarMenu
          data-cy="list-categories-sidebar-menu"
          data-testid="list-categories-sidebar-menu"
        >
          {isShowAllCategories === "TodasCategorias" ? (
            <>
              {isAuthenticated ? (
                userAuthenticatedActions(allConfigs as AllConfigs)?.map(
                  (action) => (
                    <AuthenticatedOptionDefault
                      data-cy={`action-item-link-${formatTestId(
                        action?.title
                      )}`}
                      data-testid={`action-item-link-${formatTestId(
                        action?.title
                      )}`}
                      key={action?.id}
                      as={
                        action.isLogout
                          ? AuthenticatedOptionDefault
                          : AuthenticatedOptionWithLink
                      }
                      href={action.path}
                      target="_self"
                      onClick={
                        (action.isLogout
                          ? () => {
                              signOutAccount(cookie);
                              onCloseSidebarMenu();
                            }
                          : () =>
                              onCloseSidebarMenu()) as MouseEventHandler<HTMLAnchorElement>
                      }
                    >
                      <LinkWrapper
                        data-cy={`action-link-wrapper-${formatTestId(
                          action.title
                        )}`}
                        data-testid={`action-link-wrapper-${formatTestId(
                          action.title
                        )}`}
                      >
                        <UserAuthenticatedActionsSidebarMenu
                          data-cy={`action-item-wrapper-${formatTestId(
                            action.title
                          )}`}
                          data-testid={`action-item-wrapper-${formatTestId(
                            action.title
                          )}`}
                        >
                          <WrapperIcon
                            data-cy={`action-item-wrapper-icon-${formatTestId(
                              action.title
                            )}`}
                            data-testid={`action-item-wrapper-icon-${formatTestId(
                              action.title
                            )}`}
                          >
                            {action.icon || <GiftBoxIcon />}
                          </WrapperIcon>
                          <TitleAction
                            data-cy={`action-item-title-${formatTestId(
                              action.title
                            )}`}
                            data-testid={`action-item-title-${formatTestId(
                              action.title
                            )}`}
                            hasBorderBottom={action.hasBorderBottom}
                          >
                            {action.title}
                          </TitleAction>
                        </UserAuthenticatedActionsSidebarMenu>
                      </LinkWrapper>
                    </AuthenticatedOptionDefault>
                  )
                )
              ) : (
                <Link
                  data-cy="action-item-link-lista-casamento"
                  data-testid="action-item-link-lista-casamento"
                  href="/lista-de-casamento"
                  target="_self"
                >
                  <LinkWrapper
                    data-cy="action-item-wrapper-lista-casamento"
                    data-testid="action-item-wrapper-lista-casamento"
                  >
                    <CategorySidebarMenu>
                      <GiftBoxIcon
                        data-cy="gift-box-icon-sidebar-menu"
                        data-testid="gift-box-icon-sidebar-menu"
                      />
                      <FlexContainer flexDirection="column">
                        <WeddingGiftListTitle
                          data-cy="action-item-wedding-gift-list-title"
                          data-testid="action-item-wedding-gift-list-title"
                        >
                          Lista de Casamento
                        </WeddingGiftListTitle>
                        {/* <Divider /> */}
                      </FlexContainer>
                    </CategorySidebarMenu>
                  </LinkWrapper>
                </Link>
              )}

              <OffersList
                data-cy="offer-list"
                data-testid="offer-list"
                isAuthenticated={!!isAuthenticated}
                onCloseSidebarMenu={onCloseSidebarMenu}
                setIsShowAllCategories={setIsShowAllCategories}
              />

              {companyProducts?.length &&
                companyProducts.map((product, index) => (
                  <ListContentSidebarMenuWrapper
                    data-cy={`list-content-sidebar-menu-wrapper-${formatTestId(
                      product.title
                    )}`}
                    data-testid={`list-content-sidebar-menu-wrapper-${formatTestId(
                      product.title
                    )}`}
                    key={`${product.title}#${product.path}`}
                    isEnterpriseProduct
                    isBorderActive={!(companyProducts.length - 1 === index)}
                  >
                    <Link
                      data-cy={`list-content-sidebar-link-${formatTestId(
                        product.title
                      )}`}
                      data-testid={`list-content-sidebar-link-${formatTestId(
                        product.title
                      )}`}
                      href={product.path}
                      target="_self"
                      width="100%"
                    >
                      <EnterpriseProductSidebarMenu
                        data-cy={`enterprise-product-sidebar-menu-${formatTestId(
                          product.title
                        )}`}
                        data-testid={`enterprise-product-sidebar-menu-${formatTestId(
                          product.title
                        )}`}
                      >
                        {product.title}
                      </EnterpriseProductSidebarMenu>
                    </Link>
                  </ListContentSidebarMenuWrapper>
                ))}
            </>
          ) : !selectedCategory ? (
            <AllCategoriesButton
              data-cy="back-categories-button"
              data-testid="back-categories-button"
              onClick={() => setIsShowAllCategories("TodasCategorias")}
            >
              <ArrowLeftIcon
                data-cy="arrow-left-icon"
                data-testid="arrow-left-icon"
              />
              Todas as categorias
            </AllCategoriesButton>
          ) : (
            <AllCategoriesButton
              data-cy="back-all-categories-button"
              data-testid="all-categories-button"
              onClick={() => setSelectedCategory(null)}
            >
              <ArrowLeftIcon
                data-cy="arrow-left-icon"
                data-testid="arrow-left-icon"
              />
              Voltar para categorias
            </AllCategoriesButton>
          )}
          {isShowAllCategories !== "TodasCategorias" &&
            categories?.data?.map((category) => (
              <Fragment
                data-cy={`categories-fragment-${formatTestId(category?.title)}`}
                data-testid={`categories-fragment-${formatTestId(
                  category?.title
                )}`}
                key={category?.id}
              >
                {!selectedCategory && (
                  <ListContentSidebarMenuWrapperTwo>
                    <Link
                      data-cy={`categories-link-${formatTestId(
                        category?.title
                      )}`}
                      data-testid={`categories-link-${formatTestId(
                        category?.title
                      )}`}
                      key={category?.title}
                      href={`/${category?.descriptionUrl}`}
                      target="_self"
                      onClick={() => {
                        setSelectedCategory(category);
                      }}
                    >
                      <CategorySidebarMenu
                        data-cy={`category-title-${formatTestId(
                          category?.title
                        )}`}
                        data-testid={`category-title-${formatTestId(
                          category?.title
                        )}`}
                      >
                        {category?.title}
                      </CategorySidebarMenu>
                      <CategoryExploreIcon />
                    </Link>
                  </ListContentSidebarMenuWrapperTwo>
                )}
              </Fragment>
            ))}

          {selectedCategory ? (
            <ListContentSidebarMenuSubCategories
              data-cy="list-content-sidebar-menu-sub-categories"
              data-testid="list-content-sidebar-menu-sub-categories"
              selectedCategory={selectedCategory}
            />
          ) : null}
        </ListCategoriesSidebarMenu>
      </SidebarMenuMobileContainer>
      <SidebarContainerOverlay
        data-cy="sidebar-mobile-overlay"
        data-testid="sidebar-mobile-overlay"
        id="SidebarContainerOverlayId"
        onClick={onCloseSidebarMenu}
        isActiveOverlay={!isOpenSidebarMenu}
      />
    </>
  );
};

export { SidebarMenuMobile };
