import { IconProps } from "typing";
import { theme } from "../themes";

const Copy = ({
  width = 17,
  height = 17,
  color = theme.colors.primary["350"],
  alt = "ícone de copiar",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
    >
      <rect
        x="4.99219"
        y="4.91504"
        width="9.82354"
        height="9.33722"
        rx="1"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99227 11.5848H3.58891C2.81385 11.5848 2.18555 10.9876 2.18555 10.2509V3.58145C2.18555 2.84476 2.81385 2.24756 3.58891 2.24756H10.6057C11.3808 2.24756 12.0091 2.84476 12.0091 3.58145V4.91534"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Copy };
