import { useAppContext } from "application";
import { useRegisterPhoneNumber, useShallow } from "application/state-manager";
import { StepperModalProps } from "../../StepperModal";
import { RegisterNewPhoneNumber } from "../RegisterNewPhoneNumber";
import { PhoneValidationComponent } from "../PhoneValidationComponent";
import { useRegisterPhoneNumberData } from "../RegisterNewPhoneNumber/data";

const useRegisterPhoneNumberModal = () => {
  const { isClientMobile } = useAppContext();
  const {
    isRegisterPhoneNumberModalOpen,
    setIsRegisterPhoneNumberModalOpen,
    steps,
    currentStep,
    phoneNumber,
  } = useRegisterPhoneNumber(
    useShallow((state) => ({
      isRegisterPhoneNumberModalOpen: state.isRegisterPhoneNumberModalOpen,
      setIsRegisterPhoneNumberModalOpen:
        state.setIsRegisterPhoneNumberModalOpen,
      steps: state.steps,
      currentStep: state.currentStep,
      phoneNumber: state.phoneNumber,
    }))
  );

  const handleCloseModal = () => {
    setIsRegisterPhoneNumberModalOpen(false);
  };

  const { onPrimaryButtonClick } = useRegisterPhoneNumberData();

  const getRegisterPhoneNumberModal = (): StepperModalProps & {
    isMobile: boolean;
    currentStep?: string;
  } => {
    return {
      isMobile: isClientMobile,
      currentStep,
      isOpen: isRegisterPhoneNumberModalOpen,
      onClose: handleCloseModal,
      goNext: onPrimaryButtonClick,
      steps,
      isNextButtonDisabled:
        String(phoneNumber?.replace(/\D/g, ""))?.length < 11,
      width: "489px",
      statusHeight: "5px",
      title:
        currentStep === "modalCall"
          ? "Cadastrar telefone"
          : "Validar número de telefone",
      headerFlexDirection: "column-reverse",
      hasBorderBottom: true,
      children:
        currentStep === "modalCall" ? (
          <RegisterNewPhoneNumber />
        ) : (
          <PhoneValidationComponent />
        ),
    };
  };

  return {
    getRegisterPhoneNumberModal,
  };
};

export { useRegisterPhoneNumberModal };
