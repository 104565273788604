import { IconProps } from "typing";

export const EmailValidationIcon = ({
  width = 118,
  height = 117,
  alt = "ícone de email com um check",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 118 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="58.999" cy="58.752" r="58.124" fill="#F6F7F7" />
      <path
        d="M87.6369 74.2265L91.6872 67.204"
        stroke="#B2B3B5"
        strokeWidth="1.71887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.1606 72.7978L87.6366 74.2259"
        stroke="#B2B3B5"
        strokeWidth="1.71887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.8012 69.9521L89.2772 71.3802"
        stroke="#B2B3B5"
        strokeWidth="1.71887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.2173 67.5357C92.4364 68.0138 90.6051 66.9576 90.127 65.1766C89.649 63.3957 90.7052 61.5644 92.4861 61.0864C94.2671 60.6083 96.0983 61.6645 96.5764 63.4455C97.0545 65.2264 95.9983 67.0577 94.2173 67.5357"
        stroke="#B2B3B5"
        strokeWidth="1.71887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.727 76.0173L107.662 72.1953"
        stroke="#C3C4C5"
        strokeWidth="1.03877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.378 77.0986L110.726 76.0178"
        stroke="#C3C4C5"
        strokeWidth="1.03877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.138 75.5497L109.485 74.469"
        stroke="#C3C4C5"
        strokeWidth="1.03877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.405 70.8421C108.283 71.9498 107.287 72.7491 106.179 72.6274C105.071 72.5056 104.272 71.509 104.394 70.4012C104.515 69.2935 105.512 68.4942 106.62 68.6159C107.727 68.7377 108.527 69.7343 108.405 70.8421"
        stroke="#C3C4C5"
        strokeWidth="1.03877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.6272 38.9583L79.1933 32.9423"
        stroke="#C3C4C5"
        strokeWidth="1.71887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.5076 40.874L84.6288 38.9581"
        stroke="#C3C4C5"
        strokeWidth="1.71887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.3064 38.4365L82.4276 36.5206"
        stroke="#C3C4C5"
        strokeWidth="1.71887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.292 30.6343C80.1984 32.4759 78.6296 33.8929 76.788 33.7993C74.9464 33.7057 73.5293 32.1368 73.623 30.2952C73.7166 28.4536 75.2854 27.0366 77.127 27.1302C78.9687 27.2239 80.3857 28.7927 80.292 30.6343"
        stroke="#C3C4C5"
        strokeWidth="1.71887"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1832 71.1487L28.5374 65.333"
        stroke="#B2B3B5"
        strokeWidth="1.42348"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.135 69.9655L25.1855 71.1481"
        stroke="#B2B3B5"
        strokeWidth="1.42348"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4944 67.609L26.5449 68.7917"
        stroke="#B2B3B5"
        strokeWidth="1.42348"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6374 65.6081C29.1625 66.004 27.646 65.1293 27.25 63.6544C26.8541 62.1795 27.7288 60.663 29.2037 60.2671C30.6786 59.8712 32.1952 60.7459 32.5911 62.2208C32.987 63.6956 32.1123 65.2122 30.6374 65.6081"
        stroke="#B2B3B5"
        strokeWidth="1.42348"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.7197 34.9678L99.7602 31.4298"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.4715 34.2479L97.7189 34.9673"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.2976 32.8143L98.5451 33.5337"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.035 31.5968C100.138 31.8376 99.2152 31.3055 98.9744 30.4083C98.7335 29.511 99.2657 28.5884 100.163 28.3476C101.06 28.1068 101.983 28.6389 102.224 29.5361C102.464 30.4333 101.932 31.3559 101.035 31.5968"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.3232 18.7471L71.3637 15.2091"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.075 18.0272L69.3224 18.7466"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.9012 16.5936L70.1486 17.313"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.6386 15.3761C71.7413 15.6169 70.8187 15.0848 70.5779 14.1876C70.3371 13.2903 70.8692 12.3677 71.7664 12.1269C72.6636 11.8861 73.5862 12.4182 73.8271 13.3154C74.0679 14.2126 73.5358 15.1352 72.6386 15.3761"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.1455 30.209L64.186 26.671"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8973 29.4891L62.1447 30.2085"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.7234 28.0555L62.9708 28.7749"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.4608 26.838C64.5636 27.0788 63.641 26.5467 63.4002 25.6495C63.1593 24.7522 63.6914 23.8297 64.5887 23.5888C65.4859 23.348 66.4085 23.8801 66.6493 24.7773C66.8902 25.6745 66.3581 26.5971 65.4608 26.838"
        stroke="#C3C4C5"
        strokeWidth="0.865963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0997 25.5191L38.3476 18.1539"
        stroke="#B2B3B5"
        strokeWidth="1.80274"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5044 24.0209L34.1012 25.5186"
        stroke="#B2B3B5"
        strokeWidth="1.80274"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.227 21.0355L35.8239 22.5333"
        stroke="#B2B3B5"
        strokeWidth="1.80274"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.0077 18.5018C39.1399 19.0032 37.2193 17.8955 36.7179 16.0276C36.2165 14.1598 37.3242 12.2392 39.1921 11.7378C41.0599 11.2364 42.9806 12.3441 43.4819 14.212C43.9833 16.0798 42.8756 18.0004 41.0077 18.5018"
        stroke="#B2B3B5"
        strokeWidth="1.80274"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3022 44.0604L19.3295 45.7066C18.6695 45.8885 17.9876 45.5012 17.8057 44.8412L16.6534 40.6604C16.4715 40.0004 16.8588 39.3185 17.5188 39.1366L23.4915 37.4905C24.1514 37.3086 24.8333 37.6958 25.0152 38.3558L26.1675 42.5367C26.3494 43.1967 25.9622 43.8785 25.3022 44.0604Z"
        stroke="#C3C4C5"
        strokeWidth="1.29651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1171 38.9719L17.6233 37.1801V37.1801C17.2597 35.8607 18.0346 34.4962 19.3539 34.1326V34.1326C20.6733 33.7689 22.0378 34.5438 22.4015 35.8632V35.8632L22.8953 37.655"
        stroke="#C3C4C5"
        strokeWidth="1.29651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.2658 93.0458L89.2742 89.3761C88.7226 88.9706 88.6043 88.1954 89.0098 87.6438L91.5786 84.1497C91.9841 83.5981 92.7592 83.4798 93.3108 83.8853L98.3024 87.555C98.854 87.9605 98.9723 88.7357 98.5668 89.2873L95.998 92.7814C95.5925 93.333 94.8173 93.4513 94.2658 93.0458Z"
        stroke="#C3C4C5"
        strokeWidth="1.29651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.814 84.2519L94.9149 82.7545V82.7545C95.7256 81.6518 97.2768 81.4151 98.3795 82.2257V82.2257C99.4821 83.0363 99.7189 84.5876 98.9082 85.6902V85.6902L97.8073 87.1877"
        stroke="#C3C4C5"
        strokeWidth="1.29651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.408 53.5224L100.435 55.1685C99.775 55.3504 99.0931 54.9631 98.9112 54.3032L97.7589 50.1223C97.577 49.4623 97.9642 48.7804 98.6242 48.5985L104.597 46.9524C105.257 46.7705 105.939 47.1577 106.121 47.8177L107.273 51.9986C107.455 52.6586 107.068 53.3405 106.408 53.5224Z"
        stroke="#B2B3B5"
        strokeWidth="1.29651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.2226 48.4338L98.7288 46.642V46.642C98.3651 45.3226 99.14 43.9581 100.459 43.5945V43.5945C101.779 43.2309 103.143 44.0057 103.507 45.3251V45.3251L104.001 47.1169"
        stroke="#B2B3B5"
        strokeWidth="1.29651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.8017 53.9485L87.961 56.8049C87.5366 57.1205 86.9372 57.0324 86.6216 56.608L84.6221 53.9195C84.3064 53.4951 84.3945 52.8957 84.8189 52.5801L88.6597 49.7237C89.0841 49.408 89.6835 49.4961 89.9991 49.9205L91.9986 52.609C92.3142 53.0334 92.2261 53.6328 91.8017 53.9485Z"
        stroke="#C3C4C5"
        strokeWidth="1.00167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.2026 52.294L84.3457 51.1418V51.1418C83.7147 50.2933 83.891 49.0939 84.7394 48.4629V48.4629C85.5878 47.8319 86.7873 48.0082 87.4183 48.8566V48.8566L88.2752 50.0088"
        stroke="#C3C4C5"
        strokeWidth="1.00167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6628 62.396L6.71358 61.1548C6.2772 61.0177 6.03484 60.5531 6.17199 60.1168L7.04082 57.3523C7.17798 56.916 7.64251 56.6736 8.0789 56.8107L12.0281 58.0519C12.4645 58.1891 12.7068 58.6536 12.5697 59.09L11.7008 61.8544C11.5637 62.2908 11.0991 62.5332 10.6628 62.396Z"
        stroke="#B2B3B5"
        strokeWidth="0.866305"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.47296 56.9353L8.84532 55.7506V55.7506C9.1195 54.8782 10.0491 54.3932 10.9215 54.6674V54.6674C11.7938 54.9416 12.2788 55.8712 12.0047 56.7435V56.7435L11.6323 57.9283"
        stroke="#B2B3B5"
        strokeWidth="0.866305"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6756 52.396L32.693 53.6786C32.3634 53.8204 31.9817 53.6682 31.84 53.3387L30.9421 51.2508C30.8003 50.9212 30.9525 50.5395 31.2821 50.3978L34.2647 49.1151C34.5943 48.9734 34.976 49.1255 35.1177 49.4551L36.0156 51.5429C36.1574 51.8725 36.0052 52.2542 35.6756 52.396Z"
        stroke="#C3C4C5"
        strokeWidth="0.679446"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5795 50.2695L31.1947 49.3747V49.3747C30.9114 48.7158 31.2158 47.9519 31.8747 47.6686V47.6686C32.5335 47.3852 33.2975 47.6897 33.5808 48.3486V48.3486L33.9656 49.2433"
        stroke="#C3C4C5"
        strokeWidth="0.679446"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.1178 22.9225L80.126 20.6398C79.5745 20.3876 79.332 19.7365 79.5842 19.1849L81.1821 15.6907C81.4344 15.1391 82.0854 14.8967 82.637 15.1489L87.6287 17.4316C88.1803 17.6839 88.4228 18.3349 88.1705 18.8865L86.5726 22.3807C86.3204 22.9323 85.6693 23.1748 85.1178 22.9225Z"
        stroke="#B2B3B5"
        strokeWidth="1.14867"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.1345 15.377L83.8193 13.8795V13.8795C84.3236 12.7768 85.6264 12.2916 86.7291 12.7959V12.7959C87.8318 13.3001 88.317 14.603 87.8127 15.7057V15.7057L87.1279 17.2032"
        stroke="#B2B3B5"
        strokeWidth="1.14867"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.1105 34.1532L44.6221 34.2323C44.0157 34.241 43.5174 33.7569 43.5087 33.1504L43.4533 29.3086C43.4446 28.7021 43.9287 28.2039 44.5352 28.1951L50.0235 28.116C50.63 28.1073 51.1282 28.5914 51.137 29.1979L51.1923 33.0397C51.2011 33.6462 50.7169 34.1445 50.1105 34.1532Z"
        stroke="#B2B3B5"
        strokeWidth="1.14867"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0846 28.1875L45.0609 26.541V26.541C45.0435 25.3287 46.0123 24.3315 47.2246 24.3141V24.3141C48.437 24.2966 49.4341 25.2654 49.4516 26.4778V26.4778L49.4753 28.1243"
        stroke="#B2B3B5"
        strokeWidth="1.14867"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8293 14.8935L55.4313 13.3301C54.9453 13.1573 54.6916 12.6238 54.8644 12.1378L55.9588 9.05928C56.1316 8.5733 56.6651 8.31961 57.1511 8.49237L61.549 10.0558C62.035 10.2285 62.2887 10.7621 62.1159 11.2481L61.0215 14.3266C60.8488 14.8126 60.3152 15.0663 59.8293 14.8935Z"
        stroke="#B2B3B5"
        strokeWidth="1.14867"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5912 8.64887L58.0602 7.32949V7.32949C58.4055 6.35798 59.4732 5.85032 60.4447 6.19568V6.19568C61.4162 6.54104 61.9239 7.60872 61.5785 8.58022V8.58022L61.1095 9.89961"
        stroke="#B2B3B5"
        strokeWidth="1.14867"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6499 91.371L20.6772 93.0171C20.0172 93.199 19.3353 92.8118 19.1534 92.1518L18.0011 87.9709C17.8192 87.3109 18.2064 86.629 18.8664 86.4471L24.8391 84.801C25.4991 84.6191 26.181 85.0064 26.3629 85.6663L27.5152 89.8472C27.6971 90.5072 27.3098 91.1891 26.6499 91.371Z"
        stroke="#B2B3B5"
        strokeWidth="1.29651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4648 86.2825L18.9709 84.4906V84.4906C18.6073 83.1713 19.3822 81.8067 20.7016 81.4431V81.4431C22.0209 81.0795 23.3855 81.8544 23.7491 83.1737V83.1737L24.2429 84.9656"
        stroke="#B2B3B5"
        strokeWidth="1.29651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="58.3229"
        cy="102.142"
        rx="22.3034"
        ry="1.48689"
        fill="#C3C4C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.028 91.5215H75.8969C81.5473 91.5215 86.1279 86.9409 86.1279 81.2905V57.8444C86.1279 54.3168 84.3107 51.0381 81.3193 49.1685L64.2677 38.5112C60.95 36.4377 56.7404 36.4377 53.4228 38.5112L36.3711 49.1685C33.3797 51.0381 31.5625 54.3168 31.5625 57.8444L31.5625 70.3181"
        fill="white"
      />
      <path
        d="M56.028 91.5215H75.8969C81.5473 91.5215 86.1279 86.9409 86.1279 81.2905V57.8444C86.1279 54.3168 84.3107 51.0381 81.3193 49.1685L64.2676 38.5112C60.95 36.4377 56.7404 36.4377 53.4228 38.5112L36.3711 49.1685C33.3797 51.0381 31.5625 54.3168 31.5625 57.8444L31.5625 70.3181"
        stroke="#47494D"
        strokeWidth="3.30984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.9382 38.0582C65.4861 37.4161 86.0653 53.1463 85.7534 54.4304C85.4415 55.7145 61.7446 65.3453 59.8737 65.3452C58.0027 65.3452 50.6804 62.0059 45.5306 59.8878C40.5707 57.8478 32.9026 56.0356 33.682 54.4304C34.4614 52.8253 40.23 48.652 46.7779 44.7997C51.6184 41.5506 56.0548 38.3409 58.9382 38.0582Z"
        fill="#E5E6E6"
      />
      <path
        d="M84.0194 53.9092L63.3593 63.581C61.3539 64.6781 58.9994 64.6781 56.994 63.581L34.7754 53.9092"
        stroke="#47494D"
        strokeWidth="3.30984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="44.1685"
        cy="80.1031"
        rx="15.5689"
        ry="15.5689"
        fill="#7AC143"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.1646 95.6721V95.6721C35.5654 95.6721 28.5957 88.7024 28.5957 80.1031V80.1031C28.5957 71.5039 35.5654 64.5342 44.1646 64.5342V64.5342C52.7639 64.5342 59.7336 71.5039 59.7336 80.1031V80.1031C59.7336 88.7024 52.7639 95.6721 44.1646 95.6721Z"
        stroke="#F6F7F7"
        strokeWidth="3.30984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.0852 76.6436L42.4357 85.293L37.2461 80.1033"
        stroke="#F6F7F7"
        strokeWidth="3.30984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
