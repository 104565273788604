type ValidateBirthdateResponse =
  | ""
  | "O campo data de nascimento é obrigatório!"
  | "Data no formato inválido!"
  | "A data de nascimento não pode ser anterior ao ano de 1901."
  | "A data de nascimento não pode ser maior que a data atual.";

export const isFebruaryInvalid = (
  day: string | number,
  month: string | number,
  year: string | number
) => {
  return (
    Number(month) === 2 &&
    ((Number(day) > 28 && Number(year) % 4 !== 0) ||
      (Number(day) > 29 && Number(year) % 4 === 0))
  );
};

export const quantityOfDaysIsBiggerThanThirty = (
  day: string | number,
  month: string | number
) => {
  return (
    (Number(month) === 4 ||
      Number(month) === 11 ||
      Number(month) === 6 ||
      Number(month) === 9) &&
    Number(day) > 30
  );
};

export const validateBirthdate = (
  birthdate: string
): ValidateBirthdateResponse => {
  const birthdateRequired = "O campo data de nascimento é obrigatório!";
  const birthdateInvalid = "Data no formato inválido!";
  const birthdateMinDate =
    "A data de nascimento não pode ser anterior ao ano de 1901.";
  const birthdateMaxDate =
    "A data de nascimento não pode ser maior que a data atual.";

  if (typeof birthdate !== "string") return birthdateInvalid;

  if (!birthdate) return birthdateRequired;

  if (!(birthdate as unknown as string).match(/\d{2}\/?\d{2}\/?\d{4}/))
    return birthdateInvalid;

  const [day, month, year] = birthdate.split("/");

  if (Number(day) > 31 || Number(day) === 0) return birthdateInvalid;

  if (Number(month) > 12 || Number(month) === 0) return birthdateInvalid;

  if (
    quantityOfDaysIsBiggerThanThirty(day, month) ||
    isFebruaryInvalid(day, month, year)
  )
    return birthdateInvalid;

  const birthdateDate = new Date(`${year}-${month}-${day} 00:00:00`);
  const oldDate = new Date("1900-12-31");
  const todayDate = new Date();

  if (birthdateDate <= oldDate) return birthdateMinDate;
  if (birthdateDate >= todayDate) return birthdateMaxDate;

  return "";
};
