import {
  Title,
  TextAlign,
  CustomGridPlaylistsProperty,
  ShowTitle,
  ShowSubTitle,
  Subtitle,
} from "typing";

const customGridPlaylistsData = (properties: CustomGridPlaylistsProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const textAlign = (
    properties.find(({ key }) => key === "textAlign") as TextAlign
  )?.value;

  const showTitle = (
    properties.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const subTitle = (
    properties.find(({ key }) => key === "subTitle") as Subtitle
  )?.value;

  const showSubTitle = (
    properties.find(({ key }) => key === "showSubTitle") as ShowSubTitle
  )?.value;

  return {
    title,
    showTitle,
    subTitle,
    showSubTitle,
    textAlign,
  };
};

export { customGridPlaylistsData };
