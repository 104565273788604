import { IAppMonitoringClient } from "app-domain";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  LocationProps,
  WebSocketEventAction,
  WebSocketEventMetadata,
} from "typing";
import { IWebSocketClient } from "../../entities/WebSocketClient";
import { CallbackType, IEventEmitter } from "../../entities";

interface WebSocketContextProps {
  emitEvent: (
    event: WebSocketEventAction,
    metadata: WebSocketEventMetadata
  ) => void;
  subscribeToEvents: (
    eventsList: WebSocketEventAction[],
    callback: CallbackType
  ) => void;
  unsubscribeFromEvents: (
    eventsList: WebSocketEventAction[],
    callback: CallbackType
  ) => void;
  createWebSocketConnection: (userEmail: string, accessToken: string) => void;
  destroyWebSocketConnection: () => void;
  emitEventClientLogin: (clientLocation: LocationProps) => void;
}

export const WebSocketContext = createContext({} as WebSocketContextProps);

export interface WebSocketContextProviderProps {
  children: ReactNode;
  webSocketClient: IWebSocketClient;
  appMonitoringClient: IAppMonitoringClient;
  eventEmitter: IEventEmitter;
}

export const WebSocketContextProvider = ({
  children,
  webSocketClient,
  appMonitoringClient,
  eventEmitter,
}: WebSocketContextProviderProps) => {
  const handleMessageCallback = useCallback(
    (message: string | Event | undefined) => {
      const messageData = JSON.parse((message as MessageEvent)?.data) as {
        event: WebSocketEventAction;
        metadata: WebSocketEventMetadata;
      };

      if (
        (
          ["login", "cartModified", "branchChanged"] as WebSocketEventAction[]
        ).includes(messageData?.event)
      ) {
        eventEmitter.emit(messageData?.event, messageData?.metadata);
      }
    },
    [eventEmitter]
  );

  const emitEvent = useCallback(
    (eventAction: WebSocketEventAction, metadata: WebSocketEventMetadata) => {
      try {
        webSocketClient.emitEvent(eventAction, metadata);
      } catch (err) {
        appMonitoringClient.captureException(err, {
          level: "error",
          tags: {
            fcx_labs_event: "emit_event",
            fcx_labs_error_source: "web_socket",
          },
        });
      }
    },
    [appMonitoringClient, webSocketClient]
  );

  const emitEventClientLogin = useCallback(
    (clientLocation: LocationProps, count = 0) => {
      const timeout = setTimeout(() => {
        if (count === 10) {
          clearTimeout(timeout);
          return;
        }

        if (!webSocketClient?.getUserEmail()) {
          emitEventClientLogin(clientLocation, count + 1);
        }

        emitEvent("login", clientLocation);
        clearTimeout(timeout);
      }, 1000);
    },
    [emitEvent, webSocketClient]
  );

  const createWebSocketConnection = useCallback(
    (userEmail: string, accessToken: string) => {
      webSocketClient.createSocketInstance(userEmail, accessToken).then(() => {
        webSocketClient.onReceiveMessage("message", handleMessageCallback);
      });
    },
    [handleMessageCallback, webSocketClient]
  );

  const destroyWebSocketConnection = useCallback(() => {
    webSocketClient.destroySocketInstance();
  }, [webSocketClient]);

  const subscribeToEvents = useCallback(
    (eventsList: WebSocketEventAction[], callback: CallbackType) => {
      eventsList.forEach((event) => {
        eventEmitter.subscribe(event, callback);
      });
    },
    [eventEmitter]
  );

  const unsubscribeFromEvents = useCallback(
    (eventsList: WebSocketEventAction[], callback: CallbackType) => {
      eventsList.forEach((event) => {
        eventEmitter.unsubscribe(event, callback);
      });
    },
    [eventEmitter]
  );

  const webSocketContextProviderValue = useMemo(() => {
    return {
      createWebSocketConnection,
      destroyWebSocketConnection,
      emitEvent,
      subscribeToEvents,
      unsubscribeFromEvents,
      emitEventClientLogin,
    };
  }, [
    createWebSocketConnection,
    destroyWebSocketConnection,
    emitEvent,
    subscribeToEvents,
    unsubscribeFromEvents,
    emitEventClientLogin,
  ]);

  return (
    <WebSocketContext.Provider value={webSocketContextProviderValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);

  if (!context) {
    throw new Error("useWebSocket must be used within an WebSocketContext");
  }

  return context;
};
