import { useAppContext, buildTestIds } from "application";
import { ReactNode, useEffect, useRef, useState } from "react";
import { OverlayDynamic } from "ui";
import { MainContainer } from "./style";

export interface MainProps {
  children: ReactNode;
}

const Main = ({ children }: MainProps) => {
  const { isSidebarMenuOpened, isClientMobile } = useAppContext();
  const mainContainerRef = useRef<HTMLElement>(null);

  const [mainContainerOverlayHeight, setMainContainerOverlayHeight] =
    useState("100%");

  // Esse eslint está desabilitado para que, toda vez que o componente for recriado, o overlay possa obter a largura exata do MainContainer, mesmo que a tela seja redimensionada.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (mainContainerRef?.current?.clientHeight) {
      setMainContainerOverlayHeight(
        `${mainContainerRef.current.clientHeight}px`
      );
    }
  });

  return (
    <>
      <OverlayDynamic
        {...buildTestIds("main-overlay-dynamic")}
        isVisible={!isClientMobile && isSidebarMenuOpened}
        height={mainContainerOverlayHeight}
      />
      <MainContainer ref={mainContainerRef}>{children}</MainContainer>
    </>
  );
};
// transformar o main em styled components. Aplicar overlay no main, no footer(componente) e no banner.
export { Main };
