import { theme } from "../themes";

export interface IconProps {
  width?: number | string;
  height?: number | string;
  // eslint-disable-next-line react/no-unused-prop-types
  codeMyCard?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  color?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  strokeWidth?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  click?: () => void;
}

export const TruckFC = ({
  width = 28,
  height = 28,
  color = theme.colors.primary["200"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M6.97617 18.5027H15.5015"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="17.0021"
        cy="18.5027"
        r="1.50063"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="5.47555"
        cy="18.5027"
        r="1.50063"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 18V13.8617L15.0013 13.3615V6.99792C15.0013 5.89289 14.1054 4.99709 13.0004 4.99709H3.99667C2.89164 4.99709 1.99583 5.89289 1.99583 6.99792V17.5023C1.99583 18.0548 2.44374 18.5027 2.99625 18.5027H3.97492"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0013 6.99792H18.2492C19.302 6.99792 20.2422 7.65729 20.6007 8.64722L21.8846 12.1918C21.9637 12.4102 22.0042 12.6408 22.0042 12.8731V17.0021C22.0042 17.8309 21.3323 18.5027 20.5035 18.5027H18.5027"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 12H19H18.5H17.5881H15.0013"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 8.90532H5.27661C5.42747 8.90532 5.47778 8.95563 5.47778 9.10649V12.7531H6.55922V10.8921H8.11844V10.0119H6.55922V8.90532H8.44501V8H5V8.90532Z"
        fill={color}
      />
      <path
        d="M9.0559 12.7175C9.0559 11.8122 9.65949 11.3092 10.4393 11.3092C10.9674 11.3092 11.3949 11.4601 11.8476 11.7619V10.8062C11.3827 10.5071 10.8409 10.3498 10.2881 10.3535C8.87985 10.3535 7.94934 11.3343 7.94934 12.7426C7.94934 14.1509 8.93016 15.1066 10.3636 15.1066C10.9205 15.1141 11.4657 14.947 11.9228 14.6288V13.648C11.4198 13.9749 10.942 14.1258 10.439 14.1258C9.65942 14.1007 9.0559 13.5977 9.0559 12.7175Z"
        fill={color}
      />
    </svg>
  );
};
