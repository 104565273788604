import {
  CustomStoriesSubProperty,
  Title,
  ShowTitle,
  Image,
  HasBorder,
  Color,
  HasBadge,
  BadgeText,
} from "typing";

const customStoriesSubComponentData = (
  properties: CustomStoriesSubProperty[]
) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const showTitle = (
    properties.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const image = (properties.find(({ key }) => key === "image") as Image)?.value;

  const hasBorder = (
    properties.find(({ key }) => key === "hasBorder") as HasBorder
  )?.value;

  const color = (properties.find(({ key }) => key === "color") as Color)?.value;

  const hasBadge = (
    properties.find(({ key }) => key === "hasBadge") as HasBadge
  )?.value;

  const badgeText = (
    properties.find(({ key }) => key === "badgeText") as BadgeText
  )?.value;

  return {
    title,
    showTitle,
    image,
    hasBorder,
    color,
    hasBadge,
    badgeText,
  };
};

export { customStoriesSubComponentData };
