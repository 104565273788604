import { TwoFactorContentProps } from "typing";
import { TwoFactorContent } from "../../../../../../../../../../2fa";
import { TwoFactorContentContainer } from "./styles";

const PhoneValidationComponentUI = ({ ...props }: TwoFactorContentProps) => {
  return (
    <TwoFactorContentContainer>
      <TwoFactorContent {...props} />
    </TwoFactorContentContainer>
  );
};

export { PhoneValidationComponentUI };
