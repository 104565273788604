import styled, { css } from "styled-components";
import { ArrowRight, FlexContainer, H1, theme } from "ui";
import { Link } from "../../../../Link";
import { Divider } from "../../../Divider/styles";
import { InnerItemTitleLink } from "./Inner/styles";

type ListCategoriesContainerHeaderProps = {
  isBlackTheme: boolean;
};
interface TextContainerProps {
  mouseOver: boolean;
}

export const CategoryListedInDropdown = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.17rem;
  height: 3.5rem;
  color: ${theme.colors.neutral["520"]};
  transition: color 0.2s ease-out;
  width: 90%;
  white-space: normal;
`;

export const ListCategoriesTitle = styled(H1)``;

export const ArrowRightIcon = styled(ArrowRight).attrs({
  height: "28px",
  width: "28px",
  color: theme.colors.neutral["300"],
})`
  & > path {
    stroke-width: 1px;
  }
`;

export const WrapperList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.75rem 0.75rem 1.2rem;
  width: 100%;
  height: 3.417rem;
  transition: all 0.2s ease-in;
  cursor: pointer;
  background-color: ${theme.colors.neutral.white};

  &:hover {
    background: ${theme.colors.primary["100"]};

    a {
      color: ${theme.colors.neutral.white} !important;
    }

    & > svg > path {
      fill: ${theme.colors.neutral.white};
    }
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const DropdownContainerListItem = styled.li`
  cursor: pointer !important;

  &:hover {
    ${CategoryListedInDropdown} {
      color: ${theme.colors.neutral.white};
    }

    ${WrapperList} {
      background-color: ${theme.colors.primary["100"]};
    }
  }
`;

export const ContentHeaderWrapper = styled.section`
  display: flex;
  gap: ${theme.space.x2} !important;
  align-items: start;
`;

export const ContentHeaderDesktop = styled.section`
  position: relative;
  display: flex;
  align-items: start;
  padding: 1.685rem 0 0.65rem;
  transition: all 0.2s ease-in;
  gap: ${theme.space.x4};

  @media ${theme.device.desktopFirst.laptop} {
    padding: 1.667rem 0 1rem;

    aside section {
      justify-content: space-evenly !important;
    }

    aside section a strong {
      display: none;
    }
  }

  ${ContentHeaderWrapper} {
    display: flex;
    gap: 3px;
    align-items: start;
  }

  aside {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  aside section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.space.x4};

    @media ${theme.device.desktopFirst.tablet} {
      margin: 0;
    }
  }

  aside section a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-right: 1rem;

    transition: all 0.2s ease-in-out;

    @media ${theme.device.desktopFirst.tablet} {
      margin-right: 0;
    }
  }

  aside section a strong {
    font-weight: bold;
    font-size: 1.25rem;
    color: ${theme.colors.neutral["999"]};
    line-height: 1;
  }
`;

export const LinkWrapperUser = styled(Link)`
  &::-webkit-tap-highlight-color {
    color: none;
    background: none;
  }
`;

type SubMenuContainerProps = {
  height?: string;
};

export const SubMenuContainer = styled.ul<SubMenuContainerProps>`
  position: absolute;
  overflow-x: hidden;
  /* overflow: hidden; */
  top: -1px;
  left: 20.3rem;
  display: none;
  width: 84.5rem;
  /* height: calc(100% + 1.5px); */
  min-height: 328px;
  max-height: 533px;
  height: fit-content;
  margin: 0 !important;
  padding: 1rem 2.167rem;
  z-index: 99;
  border: thin solid ${theme.colors.neutral["315"]};
  border-radius: 0 0 4px 0;
  background-color: ${theme.colors.neutral.white};

  // @media (max-width: 1240px) {
  //   width: calc(100vw - 20.5rem);
  // }

  @media ${theme.device.desktopFirst.laptop} {
    width: calc(100vw - 20.5rem);
  }
`;

export const SubMenuContentHeader = styled.header`
  display: flex;
  flex-direction: column !important;
  width: 100%;
  margin-bottom: 1rem;
`;

export const SubMenuContent = styled.li`
  display: flex;
  flex-direction: column;
  grid-template-columns: 20.3rem 1fr;
  align-items: center;
  grid-template-columns: none;
  /* height: 100%; */
  padding-bottom: 1rem;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;

  @media ${theme.device.desktopFirst.laptopL} {
    max-height: 485px;
  }
`;

export const CategoryTitle = styled(Link)`
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  color: ${theme.colors.neutral["520"]};

  @media ${theme.device.mobileFirst.tabletS} {
    font-size: 1.65rem;
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
  z-index: 99;
  margin-top: 6rem;
  transition: all 0.2s ease-in-out;
  background-color: transparent;

  @media ${theme.device.mobileFirst.tabletS} {
    width: 20.4rem;
    height: 2rem;
    left: -15px;
  }

  @media ${theme.device.desktopFirst.tabletS} {
    margin-top: 5rem;
  }
`;

export const DropdownContainerList = styled.ul`
  z-index: 2;
  list-style-type: none;
  border-radius: 0 0 0 4px;
  border: thin solid ${theme.colors.neutral["200"]};
  position: relative;
  min-height: 328px;
  max-height: 533px;
  height: 100%;
  background-color: transparent;

  @media ${theme.device.desktopFirst.tabletS} {
    min-width: 20.35rem;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 9.5px solid transparent;
    border-right: 9.5px solid transparent;
    border-bottom: 11px solid ${theme.colors.neutral.white};
    position: absolute;
    z-index: -1;
    left: calc(100% - 19rem);
    top: -11.2px;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 9.5px solid transparent;
    border-right: 9.5px solid transparent;
    border-bottom: 11px solid transparent;
    position: absolute;
    z-index: -2;
    left: calc(100% - 19rem);
    top: -11.2px;
  }

  ${DropdownContainerListItem} {
    display: grid;
    grid-template-columns: 20.3rem 1fr;
    height: fit-content;
    cursor: default !important;
    border-bottom: 1px solid ${theme.colors.neutral["200"]};

    &:last-of-type {
      border-bottom: none;
      border-radius: 8px;
    }

    &:hover {
      ${SubMenuContainer} {
        display: block;
        overflow: hidden;
      }
    }
  }
`;

export const DropdownWrapper = styled.div`
  height: 100%;
  cursor: default !important;
`;

type CategoryButtonDropdownProps = {
  isDropdownOpen?: boolean;
};

export const CategoryNameContainer = styled(FlexContainer)``;

// export const CategoryNameContainer = styled(
//   FlexContainer
// )<CategoryButtonDropdownProps>`
//   ${({ isDropdownOpen }) =>
//     isDropdownOpen &&
//     `
//     ~ ${DropdownContainer} {
//       opacity: 1 !important;
//       visibility: visible !important;
//     }
//   `}
// `;

export const CategoryButtonDropDown = styled.div<CategoryButtonDropdownProps>`
  width: 12.5rem;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  position: relative;
  height: 100%;

  /* transition: all 0.2s ease-in; */

  strong {
    font-size: 1.33rem;
    color: ${theme.colors.primary["200"]};
    margin-left: 0.5rem;
  }

  ${({ isDropdownOpen }) =>
    isDropdownOpen &&
    `
      ${DropdownContainer} {
        opacity: 1 !important;
        display: block !important;
      }
   `}

  @media ${theme.device.desktopFirst.laptop} {
    width: 10.5rem;
  }

  @media ${theme.device.desktopFirst.tablet} {
    width: 10rem;

    strong {
      font-size: 1.33rem !important;
    }
  }
`;

export const CategoryTitleSection = styled.strong`
  margin-left: 10px !important;
`;

export const WelcomeText = styled.strong`
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ContentHeaderCategoriesDesktop = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;

  section a {
    text-decoration: none;
    font-size: 1.167rem;
    font-weight: 400;
    padding: 0.4rem 0.65rem;
    border-radius: 4px;
    cursor: pointer;
    letter-spacing: -0.03rem;

    transition: all 0.2s ease-in;

    &:hover {
      background: ${theme.colors.neutral["100"]};
    }
  }

  @media (max-width: 980px) {
    section {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: hidden;

      a {
        padding: 0.25rem 0.38rem;
      }
    }
  }

  @media ${theme.device.desktopFirst.laptop} {
    section {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    section a {
      /* font-size: 0.75rem !important; */
      padding: 0.4rem 0.65rem;
    }
  }

  @media ${theme.device.mobileFirst.laptopL} {
    section a {
      font-size: 1.17rem !important;
    }
  }

  @media ${theme.device.desktopFirst.tablet} {
    padding: 0 0rem 0.5rem 0rem !important;

    section {
      margin-left: 1.5rem;

      a {
        padding: 0.25rem 0.38rem;
      }
    }
  }
`;

export const EnterOrCreateAccountText = styled.strong<TextContainerProps>`
  line-height: 1;
  color: ${({ mouseOver }) =>
    mouseOver
      ? theme.colors.primary["200"]
      : theme.colors.neutral["999"]} !important;
  transition: color 0.2s ease-in;
`;

export const WeddingGiftListText = styled.strong<TextContainerProps>`
  line-height: 1;
  color: ${({ mouseOver }) =>
    mouseOver
      ? theme.colors.primary["200"]
      : theme.colors.neutral["999"]} !important;
  transition: color 0.2s ease-in;
`;

export const DropdownContainerOverlay = styled.div`
  width: 100%;
`;

export const FcLogoBlackWrapper = styled.div`
  padding-right: ${theme?.space?.["x2.5"]};
  @media ${theme.device.desktopFirst.tablet} {
    padding-right: ${theme?.space?.x0};
  }
`;

export const ListCategoriesAside = styled.aside`
  gap: ${theme.space.x6};
`;

export const ListCategoriesContainerHeader = styled.section<ListCategoriesContainerHeaderProps>`
  width: 100%;
  max-width: 1480px;

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      ${WeddingGiftListText} {
        color: ${theme.colors.neutral["555"]} !important;
      }

      ${EnterOrCreateAccountText} {
        color: ${theme.colors.neutral["555"]} !important;
      }

      ${DropdownContainerList} {
        background-color: ${theme.colors.blackFriday["150"]};
        border: 1px solid ${theme.colors.blackFriday["100"]} !important;
        border-radius: 2px;

        ::before {
          border-bottom: 11px solid ${theme.colors.blackFriday["150"]} !important;
        }

        ${DropdownContainerListItem} {
          border-bottom: ${theme.space.xpx} solid transparent !important;

          &:hover {
            ${WrapperList} {
              background-color: ${theme.colors.blackFriday["100"]} !important;
            }
          }
        }

        ${CategoryListedInDropdown} {
          color: ${theme.colors.blackFriday.white} !important;
        }

        ${SubMenuContainer} {
          background: ${theme.colors.blackFriday["100"]} !important;
          border: thin solid transparent !important;

          ${Divider} {
            background-color: ${theme.colors.blackFriday["50"]};
          }

          ${InnerItemTitleLink} {
            will-change: border;
            will-change: font-weight;
            border: ${theme.space.xpx} solid transparent !important;

            &:hover {
              font-weight: bold !important;
            }

            @media (max-width: 1240px) {
              background-color: transparent !important;

              &:hover {
                border: ${theme.space.xpx} solid
                  ${theme.colors.blackFriday.white} !important;
                font-weight: initial !important;
              }
            }
          }

          * {
            color: ${theme.colors.blackFriday.white} !important;
          }
        }
      }
    `}
`;

export const HeaderUserActionsSection = styled.section`
  width: fit-content;
`;
