import { IconProps } from "typing";
import { theme } from "../themes";

export const EmployeeIcon = ({
  width = 24,
  height = 25,
  color = theme.colors.neutral["550"],
  alt = "Ícone de um colaborador",
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      aria-label={alt}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 21.5V21.0285C3 19.5985 4.00938 18.3672 5.41165 18.0868L8.25765 17.5176C8.49288 17.4705 8.73716 17.5188 8.93676 17.6519V17.6519C10.7917 18.8886 13.2083 18.8886 15.0633 17.6519V17.6519C15.2629 17.5188 15.5072 17.4705 15.7424 17.5176L18.5884 18.0868C19.9906 18.3672 21 19.5985 21 21.0285V21.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 21.4993V17.5684"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21.4993V17.5684"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.83496 9.5H16.1653C16.7176 9.5 17.1653 9.05228 17.1653 8.5V7.66515C17.1653 6.29527 16.6211 4.98149 15.6524 4.01283C14.6838 3.04418 13.37 2.5 12.0001 2.5V2.5C10.6302 2.5 9.31646 3.04418 8.3478 4.01283C7.37915 4.98149 6.83496 6.29527 6.83496 7.66515V8.5C6.83496 9.05228 7.28268 9.5 7.83496 9.5Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.5H17.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9.5V11.4995C16 13.7086 14.2091 15.4995 12 15.4995V15.4995C9.79086 15.4995 8 13.7086 8 11.4995V9.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1009 14.9043L14.8249 17.8008"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.90108 14.9043L9.17704 17.8007"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 2.7207V4.09989C13.5 4.92832 12.8284 5.59989 12 5.59989V5.59989C11.1716 5.59989 10.5 4.92832 10.5 4.09989V2.7207"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 21.5H17"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
