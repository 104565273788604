import { useCallback, useEffect, useState } from "react";
import { ArrowDown, ArrowUp } from "ui";
import { buildTestIds, useDebounce } from "application";
import { StyledInput } from "./style";

type InputNumberProps = {
  min?: number;
  max?: number;
  productQuantity?: number;
  valueCount?: number;
  debounceTime?: number;
  productListId?: number;
  setCountState: (prevState: number) => void;
  callbackMaxQuantity?: () => void;
  isInputClicked?: () => void;
};

const InputNumber = ({
  productListId,
  min = 1,
  max = 99999,
  setCountState,
  valueCount = 1,
  callbackMaxQuantity,
  debounceTime = 500,
  isInputClicked,
  productQuantity,
}: InputNumberProps) => {
  const [count, setCount] = useState<number>(valueCount ?? min);
  const [isClicked, setIsClicked] = useState(false);

  const increase = () => {
    const quantity = productQuantity || count;
    setIsClicked(true);
    if (quantity === max && callbackMaxQuantity) {
      callbackMaxQuantity();
    }

    setCount((state) => {
      if (quantity > max) {
        if (callbackMaxQuantity) {
          callbackMaxQuantity();
        }
        return max || 1;
      }
      if (quantity < max) {
        if (!Number.isInteger(state)) {
          return +(state + min).toFixed(2);
        }
        return state + min;
      }
      return state;
    });
  };

  const decrease = () => {
    setIsClicked(true);
    setCount((state) => {
      if (state > min) {
        if (!Number.isInteger(state)) {
          return +(state - min).toFixed(2);
        }
        return state - min;
      }
      return state;
    });
  };

  const validateCountInput = useCallback(() => {
    setCount((state) => {
      const quantity = productQuantity || state;
      if (!state) {
        return min;
      }

      if (quantity > max && state !== min) {
        if (callbackMaxQuantity) {
          callbackMaxQuantity();
        }
        return max || 1;
      }

      if (state < min) {
        return min;
      }

      if (!Number.isInteger(state / min)) {
        return +(Math.round(state / min) * min).toFixed(2);
      }

      return state;
    });
  }, [productQuantity, max, min, callbackMaxQuantity]);

  const handlerInput = (e: { target: { value: string } }) => {
    const newValue = parseInt(e.target.value, 10);
    setCount(newValue);
  };

  useEffect(() => {
    if (isClicked && isInputClicked) {
      isInputClicked();
    }
  }, [isInputClicked, isClicked]);

  useEffect(() => {
    validateCountInput();
  }, [max, validateCountInput]);

  const debouncedCount = useDebounce(count, debounceTime);

  useEffect(() => {
    setCountState(debouncedCount);
  }, [debouncedCount, setCountState]);

  return (
    <StyledInput {...buildTestIds("styled-input-container")}>
      {!productListId && (
        <button
          type="button"
          onClick={decrease}
          {...buildTestIds("decrease-counter-button")}
        >
          <ArrowDown />
        </button>
      )}

      <input
        {...buildTestIds("value-input-number")}
        id="value-input-number"
        aria-label="Contador"
        onBlur={validateCountInput}
        type="number"
        inputMode="numeric"
        onChange={handlerInput}
        value={Number.isNaN(count) ? "" : count}
      />
      {!productListId && (
        <button
          type="button"
          onClick={increase}
          {...buildTestIds("add-counter-button")}
        >
          <ArrowUp />
        </button>
      )}
    </StyledInput>
  );
};

export { InputNumber };
