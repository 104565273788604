import { buildTestIds } from "application";
import { ReactNode } from "react";
import {
  ActionButtonContainer,
  ItemContent,
  ItemLinkContainer,
  WrapperSuggestions,
} from "./styles";

interface TermSuggestionOptionProps {
  term: string;
  onClick: () => void;
  actionButton?: {
    icon: ReactNode;
    onClick: (searchTerm: string) => void;
  };
}

export const TermSuggestionOption = ({
  term,
  onClick,
  actionButton,
}: TermSuggestionOptionProps) => {
  return (
    <WrapperSuggestions>
      <ItemLinkContainer>
        <ItemContent
          onClick={onClick}
          {...buildTestIds("item-suggestion-result")}
        >
          {term}
        </ItemContent>
        {actionButton && (
          <ActionButtonContainer
            onClick={(event) => {
              event.stopPropagation();
              actionButton.onClick(term);
            }}
          >
            {actionButton.icon}
          </ActionButtonContainer>
        )}
      </ItemLinkContainer>
    </WrapperSuggestions>
  );
};
