export const getAvailableStock = (
  maximumStockPerBranch: number | null,
  totalStockFC: number
) => {
  return maximumStockPerBranch ?? totalStockFC;
};

export const getMaxProductPossible = (factPackaged?: number) => {
  return factPackaged === 0 ? 150 : 1000;
};

export const getMaxQuantityProduct = (
  availableStock: number,
  productsQuantity: number,
  packingQuantity?: number,
  factPackaged?: number
) => {
  return availableStock < productsQuantity * (packingQuantity ?? 1)
    ? Math.floor(availableStock / (packingQuantity ?? 1))
    : getMaxProductPossible(factPackaged);
};

export const getAlertMessage = (
  maxQuantity: number,
  packageDescription: string
) => {
  return `Ops! Este produto possui o limite de ${maxQuantity} ${packageDescription} por pessoa.`;
};

export const getIsMaxQuantity = (
  productsQuantity: number,
  maxQuantityProduct: number,
  packingQuantity?: number
) => {
  return productsQuantity * (packingQuantity ?? 1) >= maxQuantityProduct;
};

export const getMaxQuantityLimitReason = (
  availableStock: number,
  productsQuantity: number,
  packingQuantity?: number,
  factPackaged?: number
) => {
  if (availableStock <= productsQuantity * (packingQuantity ?? 1)) {
    return "stock";
  }

  if (
    ((factPackaged === 0 && productsQuantity >= 150) ||
      (factPackaged !== 0 && productsQuantity >= 1000)) &&
    availableStock > productsQuantity * (packingQuantity ?? 1)
  ) {
    return "cpf";
  }

  return null;
};
