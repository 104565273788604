import { useUser } from "application";
import { useCallback, useEffect, useMemo } from "react";

interface UseSearchHistoryProps {
  limit?: number;
}

const DEFAULT_USER_SEARCH_HISTORY = "@FC:Ecom:User:Search:History";

const useSearchHistory = ({ limit = 10 }: UseSearchHistoryProps) => {
  const searchTermHistory = useMemo(() => new Set<string>(), []);

  const { loggedClient } = useUser();

  const LOGGED_USER_SEARCH_HISTORY = useMemo(
    () => `${DEFAULT_USER_SEARCH_HISTORY}:${loggedClient?.email}`,
    [loggedClient?.email]
  );

  const searchTermHistoryPointer = useMemo(
    () =>
      loggedClient?.email
        ? LOGGED_USER_SEARCH_HISTORY
        : DEFAULT_USER_SEARCH_HISTORY,
    [LOGGED_USER_SEARCH_HISTORY, loggedClient?.email]
  );

  const removeSearchTerm = useCallback(
    (searchTerm: string) => {
      const formattedSearchTerm = searchTerm.trimStart().trimEnd();
      if (
        !searchTermHistory?.has(formattedSearchTerm) ||
        !formattedSearchTerm
      ) {
        return;
      }

      searchTermHistory.delete(formattedSearchTerm);
      localStorage.setItem(
        searchTermHistoryPointer,
        JSON.stringify([...Array.from(searchTermHistory)])
      );
    },
    [searchTermHistory, searchTermHistoryPointer]
  );

  const resetSearchHistory = useCallback(
    (complete = false) => {
      searchTermHistory.clear();
      if (complete)
        localStorage.setItem(DEFAULT_USER_SEARCH_HISTORY, JSON.stringify([]));
    },
    [searchTermHistory]
  );

  const saveSearchTerm = useCallback(
    (searchTerm: string) => {
      const formattedSearchTerm = searchTerm?.trimStart().trimEnd();

      if (!formattedSearchTerm) {
        return;
      }

      if (searchTermHistory?.has(formattedSearchTerm)) {
        removeSearchTerm(formattedSearchTerm);
        saveSearchTerm(formattedSearchTerm);
        return;
      }

      if (searchTermHistory?.size > limit - 1) {
        searchTermHistory.delete(
          searchTermHistory?.values().next().value ?? ""
        );
      }

      searchTermHistory.add(formattedSearchTerm);
      localStorage.setItem(
        searchTermHistoryPointer,
        JSON.stringify([...Array.from(searchTermHistory)])
      );
    },
    [limit, removeSearchTerm, searchTermHistory, searchTermHistoryPointer]
  );

  const mergeSearchHistory = useCallback(() => {
    const loggedUserSearchHistory = JSON.parse(
      localStorage.getItem(LOGGED_USER_SEARCH_HISTORY) ?? "[]"
    ) as string[];

    const defaultUserSearchHistory = JSON.parse(
      localStorage.getItem(DEFAULT_USER_SEARCH_HISTORY) ?? "[]"
    ) as string[];

    if (defaultUserSearchHistory.length > 0) {
      localStorage.setItem(
        LOGGED_USER_SEARCH_HISTORY,
        JSON.stringify([
          ...loggedUserSearchHistory,
          ...defaultUserSearchHistory,
        ])
      );
    }
  }, [LOGGED_USER_SEARCH_HISTORY]);

  useEffect(() => {
    if (loggedClient?.email) {
      mergeSearchHistory();
      resetSearchHistory(true);
    }

    resetSearchHistory();
  }, [
    LOGGED_USER_SEARCH_HISTORY,
    loggedClient,
    mergeSearchHistory,
    resetSearchHistory,
  ]);

  useEffect(() => {
    const storedSearchTermHistory = localStorage.getItem(
      searchTermHistoryPointer
    );

    if (storedSearchTermHistory) {
      const terms = JSON.parse(storedSearchTermHistory) as string[];

      terms.forEach((term) => {
        searchTermHistory.add(term);
      });
    } else {
      localStorage.setItem(
        searchTermHistoryPointer,
        JSON.stringify([...Array.from(searchTermHistory)])
      );
    }
  }, [searchTermHistory, searchTermHistoryPointer]);

  const termsList = [...Array.from(searchTermHistory)]
    .reverse()
    .slice(0, limit);

  return {
    termsList,
    saveSearchTerm,
    removeSearchTerm,
    resetSearchHistory,
  };
};
export { useSearchHistory };
