import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const InvoicedTrackingStatusIcon = ({
  width = 32,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Faturado",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <path
        d="M11.8315 17.4704H10.1642C9.24333 17.4704 8.49683 16.7238 8.49683 15.803V10.8009C8.49683 9.88005 9.24333 9.13354 10.1642 9.13354H21.8357C22.7566 9.13354 23.5031 9.88005 23.5031 10.8009V15.803C23.5031 16.7238 22.7566 17.4704 21.8357 17.4704H20.1684"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1683 13.302H11.8315V22.0783C11.8314 22.404 11.9607 22.7164 12.191 22.9467C12.4213 23.177 12.6742 23.5003 12.9999 23.5002L13.9999 22.5002L14.9999 23.5002L16.4999 22.5002L17.4999 23.5002L18.4999 22.5002C18.8256 22.5003 19.5786 23.177 19.8089 22.9467C20.0392 22.7164 20.1685 22.404 20.1683 22.0783V13.302Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 19.5H16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7495 17.4168H17.2506"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 15.5H18"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
