import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface RecaptchaContextProps {
  checkIfTheCustomerIsNotARobotSignUp: boolean;
  checkIfTheCustomerIsNotARobotSignIn: boolean;
  handleCheckIfTheCustomerIsNotARobotSignUp(value: boolean): void;
  handleCheckIfTheCustomerIsNotARobotSignIn(value: boolean): void;
}

export interface RecaptchaContextProviderProps {
  children: ReactNode;
}

export const RecaptchaContext = createContext({} as RecaptchaContextProps);

export const RecaptchaContextProvider = ({
  children,
}: RecaptchaContextProviderProps) => {
  const [
    checkIfTheCustomerIsNotARobotSignUp,
    setCheckIfTheCustomerIsNotARobotSignUp,
  ] = useState<boolean>(true);
  const [
    checkIfTheCustomerIsNotARobotSignIn,
    setCheckIfTheCustomerIsNotARobotSignIn,
  ] = useState<boolean>(true);

  const handleCheckIfTheCustomerIsNotARobotSignUp = useCallback(
    (value: boolean) => {
      setCheckIfTheCustomerIsNotARobotSignUp(value);
    },
    []
  );

  const handleCheckIfTheCustomerIsNotARobotSignIn = useCallback(
    (value: boolean) => {
      setCheckIfTheCustomerIsNotARobotSignIn(value);
    },
    []
  );

  const recaptchaContextProviderValue = useMemo(() => {
    return {
      checkIfTheCustomerIsNotARobotSignUp,
      checkIfTheCustomerIsNotARobotSignIn,
      handleCheckIfTheCustomerIsNotARobotSignUp,
      handleCheckIfTheCustomerIsNotARobotSignIn,
    };
  }, [
    checkIfTheCustomerIsNotARobotSignIn,
    checkIfTheCustomerIsNotARobotSignUp,
    handleCheckIfTheCustomerIsNotARobotSignIn,
    handleCheckIfTheCustomerIsNotARobotSignUp,
  ]);

  return (
    <RecaptchaContext.Provider value={recaptchaContextProviderValue}>
      {children}
    </RecaptchaContext.Provider>
  );
};

export const useRecaptcha = () => {
  const context = useContext(RecaptchaContext);

  if (!context) {
    throw new Error("useRecaptcha must be used within an RecaptchaContext");
  }

  return context;
};
