import { buildTestIds } from "application";
import {
  ResponsiveModalContentButtons,
  ResponsiveModalPrimaryButton,
  ResponsiveModalSecondaryButton,
} from "./styles";

export interface IResponsiveModalButtons {
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  primaryButtonIsDisabled?: boolean;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
}

const ResponsiveModalButtons = ({
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonLabel,
  primaryButtonIsDisabled,
  secondaryButtonLabel,
}: IResponsiveModalButtons) => {
  return (
    <ResponsiveModalContentButtons
      {...buildTestIds("responsive-modal-content-buttons")}
    >
      {onPrimaryButtonClick && primaryButtonLabel ? (
        <ResponsiveModalPrimaryButton
          {...buildTestIds("responsive-modal-primary-button")}
          onClick={(e) => {
            e.preventDefault();
            onPrimaryButtonClick();
          }}
          disabled={primaryButtonIsDisabled}
        >
          {primaryButtonLabel}
        </ResponsiveModalPrimaryButton>
      ) : null}
      {onSecondaryButtonClick && secondaryButtonLabel ? (
        <ResponsiveModalSecondaryButton
          {...buildTestIds("responsive-modal-secondary-button")}
          onClick={onSecondaryButtonClick}
        >
          {secondaryButtonLabel}
        </ResponsiveModalSecondaryButton>
      ) : null}
    </ResponsiveModalContentButtons>
  );
};

export { ResponsiveModalButtons };
