import { buildTestIds } from "application";
import { ReactNode } from "react";
import { ButtonType } from "typing";
import { Loading } from "../Loading";
import { ButtonSubmitText, StyledButtonSubmit } from "./style";

type ButtonSubmitProps = {
  text?: string | ReactNode;
  textUnderAction?: string;
  underAction?: boolean;
  buttonType?: ButtonType;
  onClickAction?: () => void;
  color?: string;
  contentColor?: string;
  hoveredColor?: string;
  minWidth?: string;
  maxWidth?: string;
  fontSize?: string;
  height?: string;
  fontWeight?: number;
  border?: string;
  borderColor?: string;
  isDisabled?: boolean;
  form?: string;
  padding?: string;
  textDecoration?: string;
  alternativeButton?: boolean;
  borderRadius?: string;
  margin?: string;
  preventDefault?: boolean;
};

const ButtonSubmit = ({
  text,
  textUnderAction,
  underAction,
  onClickAction,
  buttonType = "submit",
  color,
  contentColor,
  hoveredColor,
  isDisabled = false,
  form,
  padding,
  fontWeight,
  textDecoration,
  height,
  borderRadius,
  alternativeButton = false,
  preventDefault,
  ...props
}: ButtonSubmitProps) => {
  return (
    <StyledButtonSubmit
      {...buildTestIds("component-button-submit")}
      type={buttonType}
      onClick={(e) => {
        if (!isDisabled && onClickAction) {
          onClickAction();
        }

        if (preventDefault) {
          e.preventDefault();
        }
      }}
      underAction={underAction}
      color={color ?? undefined}
      contentColor={contentColor ?? undefined}
      hoveredColor={hoveredColor ?? undefined}
      padding={padding}
      form={form as string}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onKeyDown={!isDisabled && onClickAction ? onClickAction : undefined}
      fontWeight={fontWeight}
      textDecoration={textDecoration}
      height={height}
      borderRadius={borderRadius}
      aria-disabled={isDisabled}
      {...props}
    >
      {underAction && !alternativeButton ? (
        <Loading
          {...buildTestIds("component-button-submit-loading")}
          size="medium"
        />
      ) : null}
      <ButtonSubmitText {...buildTestIds("button-submit-text")}>
        {underAction ? textUnderAction : text}
      </ButtonSubmitText>
    </StyledButtonSubmit>
  );
};

export { ButtonSubmit };
export type { ButtonSubmitProps };
