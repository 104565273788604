import { NextRouter, useRouter as defaultUseRouter } from "next/router";
import {
  useAuthentication as defaultUseAuthentication,
  useShallow,
} from "application/state-manager";
import { ChangePasswordFeedback } from "../components/ChangePasswordFeedback";

interface EmailFeedbackProps {
  useRouter?: () => NextRouter;
  useAuthentication?: typeof defaultUseAuthentication;
}

const EmailFeedback = ({
  useRouter = defaultUseRouter,
  useAuthentication = defaultUseAuthentication,
}: EmailFeedbackProps) => {
  const router = useRouter();
  const { closeAuthenticationModal } = useAuthentication(
    useShallow((state) => ({
      closeAuthenticationModal: state.closeAuthenticationModal,
    }))
  );

  return (
    <ChangePasswordFeedback
      onCloseFeedback={closeAuthenticationModal}
      onCallAction={() => router?.push("/cadastro")}
      title="E-mail não encontrado"
      buttonText="Criar cadastro"
      description="Não foi possível encontrar o e-mail digitado em nosso sistema. Por favor, verifique se o endereço de e-mail está correto ou tente usar um endereço diferente."
    />
  );
};

export { EmailFeedback };
