import styled from "styled-components";
import { theme } from "ui";

export const SwiperContainer = styled.div`
  position: relative;

  .swiper-initialized {
    height: auto;
    .swiper-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
      }
    }
  }
`;

export const NavButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 25%;
`;

type NavButtonProps = {
  isBlackTheme?: boolean;
};

export const NavButtonPrev = styled.button<NavButtonProps>`
  height: ${theme.space.x15};
  width: ${theme.space.x15};
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 35px;
  background: ${({ isBlackTheme }) =>
    isBlackTheme
      ? `${theme?.colors?.blackFriday?.["50"]} !important`
      : `${theme.colors.neutral.white} !important`};

  box-shadow: ${theme.space.x0} ${theme.space["x0.5"]} ${theme.space["x0.5"]}
    ${theme.colors.neutral["999-opacity-60"]};
  transition: 0.8s;
  cursor: pointer;

  path {
    stroke: ${({ isBlackTheme }) =>
      isBlackTheme
        ? `${theme.colors.neutral.white} !important`
        : `${theme.colors.neutral["450"]} !important`};
  }

  &:hover {
    background: ${theme.colors.primary["200"]} !important;
  }

  &:hover path {
    stroke: ${theme.colors.neutral.white} !important;
  }

  &:disabled {
    background: ${({ isBlackTheme }) =>
      isBlackTheme
        ? `${theme?.colors?.blackFriday?.["110"]} !important`
        : `${theme.colors.neutral["80"]} !important`};
    path {
      stroke: ${({ isBlackTheme }) =>
        isBlackTheme
          ? `${theme?.colors?.blackFriday?.["40"]} !important`
          : `${theme.colors.neutral["99"]} !important`};
    }

    &:hover {
      path {
        transition: 0.8s;
      }
    }
    opacity: 1;
    visibility: visible;
    cursor: default;
  }

  @media ${theme.device.desktopFirst.tablet} {
    display: none;
  }
`;
export const NavButtonNext = styled(NavButtonPrev)``;
