import { IconProps } from "typing";

const IllustrationSendEmail = ({
  alt = "Autenticacão por Email",
  width = 138,
  height = 144,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 138 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="77.5105" cy="53.9547" r="40.0181" fill="#F6F7F7" />
      <path
        opacity="0.17"
        d="M83.356 36.6851L97.9544 49.211L52.5611 136.349L10.8836 86.0632L83.356 36.6851Z"
        fill="url(#paint0_linear_42_91407)"
      />
      <path
        opacity="0.17"
        d="M84.8733 38.4243L96.8407 47.576L51.4961 120.798L27.798 85.0337L84.8733 38.4243Z"
        fill="url(#paint1_linear_42_91407)"
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.8112 65.7951C96.6503 65.7951 95.7086 66.674 95.7086 67.758C95.7086 68.8421 96.6503 69.7205 97.8112 69.7205H103.594C104.755 69.7205 105.697 68.8415 105.697 67.758C105.697 66.6746 104.755 65.7956 103.595 65.7956C103.595 64.3051 102.3 63.0967 100.703 63.0967C99.1058 63.0967 97.8112 64.3045 97.8112 65.7951Z"
          stroke="#A0A2A4"
          strokeWidth="0.443934"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.1569 74.6739C85.996 74.6739 85.0543 75.5528 85.0543 76.6368C85.0543 77.7208 85.996 78.5992 87.1569 78.5992H92.9397C94.1011 78.5992 95.0428 77.7203 95.0428 76.6368C95.0428 75.5533 94.1011 74.6744 92.9403 74.6744C92.9403 73.1838 91.6456 71.9755 90.0486 71.9755C88.4515 71.9755 87.1569 73.1833 87.1569 74.6739Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7271 86.216C73.5662 86.216 72.6245 87.0949 72.6245 88.1789C72.6245 89.2629 73.5662 90.1413 74.7271 90.1413H80.5099C81.6713 90.1413 82.613 89.2624 82.613 88.1789C82.613 87.0954 81.6713 86.2165 80.5104 86.2165C80.5104 84.7259 79.2158 83.5176 77.6188 83.5176C76.0217 83.5176 74.7271 84.7254 74.7271 86.216Z"
          stroke="#B2B3B5"
          strokeWidth="0.443934"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.2577 62.8987C44.0968 62.8987 43.1552 63.7776 43.1552 64.8616C43.1552 65.9456 44.0968 66.824 45.2577 66.824H51.0405C52.202 66.824 53.1437 65.9451 53.1437 64.8616C53.1437 63.7781 52.202 62.8992 51.0411 62.8992C51.0411 61.4086 49.7465 60.2003 48.1494 60.2003C46.5524 60.2003 45.2577 61.4081 45.2577 62.8987Z"
          stroke="#B2B3B5"
          strokeWidth="0.443934"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.9418 32.944C79.7809 32.944 78.8392 33.823 78.8392 34.907C78.8392 35.991 79.7809 36.8694 80.9418 36.8694H86.7246C87.8861 36.8694 88.8277 35.9905 88.8277 34.907C88.8277 33.8235 87.8861 32.9446 86.7252 32.9446C86.7252 31.454 85.4305 30.2457 83.8335 30.2457C82.2364 30.2457 80.9418 31.4535 80.9418 32.944Z"
          stroke="#B2B3B5"
          strokeWidth="0.443934"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.2487 35.3748C52.0878 35.3748 51.1461 36.2537 51.1461 37.3378C51.1461 38.4218 52.0878 39.3002 53.2487 39.3002H59.0315C60.1929 39.3002 61.1346 38.4213 61.1346 37.3378C61.1346 36.2543 60.1929 35.3753 59.0321 35.3753C59.0321 33.8848 57.7374 32.6765 56.1404 32.6765C54.5433 32.6765 53.2487 33.8842 53.2487 35.3748Z"
          stroke="#8F9194"
          strokeWidth="0.443934"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.2969 58.6921C61.136 58.6921 60.1943 59.571 60.1943 60.655C60.1943 61.739 61.136 62.6174 62.2969 62.6174H68.0797C69.2412 62.6174 70.1828 61.7385 70.1828 60.655C70.1828 59.5715 69.2412 58.6926 68.0803 58.6926C68.0803 57.202 66.7856 55.9937 65.1886 55.9937C63.5915 55.9937 62.2969 57.2015 62.2969 58.6921Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.686 49.262C104.834 49.262 104.144 49.9065 104.144 50.7014C104.144 51.4964 104.834 52.1405 105.686 52.1405H109.926C110.778 52.1405 111.469 51.496 111.469 50.7014C111.469 49.9069 110.778 49.2623 109.927 49.2623C109.927 48.1692 108.977 47.2831 107.806 47.2831C106.635 47.2831 105.686 48.1689 105.686 49.262Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.5622 25.9448C64.7108 25.9448 64.0203 26.5894 64.0203 27.3843C64.0203 28.1792 64.7108 28.8234 65.5622 28.8234H69.8029C70.6546 28.8234 71.3452 28.1789 71.3452 27.3843C71.3452 26.5897 70.6546 25.9452 69.8033 25.9452C69.8033 24.8521 68.8539 23.966 67.6827 23.966C66.5115 23.966 65.5622 24.8517 65.5622 25.9448Z"
          fill="#A0A2A4"
        />
      </g>
      <path
        d="M59.8392 68.015L43.4137 84.9412"
        stroke="#040405"
        strokeWidth="0.978292"
        strokeLinecap="round"
      />
      <path
        d="M70.3823 77.0572L55.6908 94.3967"
        stroke="#040405"
        strokeWidth="0.978292"
        strokeLinecap="round"
      />
      <path
        d="M60.6363 77.1303L44.5926 95.095"
        stroke="#040405"
        strokeWidth="0.978292"
        strokeLinecap="round"
      />
      <path
        d="M69.064 65.4399L69.1638 46.2124L106.046 27.1378L103.873 36.4679L69.064 65.4399Z"
        fill="url(#paint2_linear_42_91407)"
        stroke="#040405"
        strokeWidth="1.84614"
      />
      <path
        d="M108.267 22.2624C109.344 21.8727 110.026 23.4043 109.016 23.9444L69.1135 45.2847C68.9887 45.3515 68.8502 45.3886 68.7088 45.3933L47.7033 46.0896C46.6526 46.1244 46.3702 44.6567 47.3587 44.299L108.267 22.2624Z"
        fill="#68A439"
        stroke="#040405"
        strokeWidth="1.84614"
      />
      <path
        d="M109.438 24.6007C109.834 23.7091 108.756 22.8931 108.005 23.5157L76.0784 49.9854C75.7678 50.2429 75.6604 50.6723 75.8133 51.0457L85.496 74.7016C85.804 75.4541 86.8641 75.4696 87.194 74.7263L109.438 24.6007Z"
        fill="#F6F7F7"
        stroke="#040405"
        strokeWidth="1.84614"
      />
      <path
        d="M69.5943 66.3113L75.6262 50.8787C75.6337 50.8593 75.6407 50.8499 75.644 50.846C75.6474 50.8419 75.6507 50.8391 75.6546 50.8367C75.6633 50.8313 75.6815 50.8242 75.7073 50.8249C75.7331 50.8257 75.7509 50.8337 75.7593 50.8396C75.763 50.8422 75.7662 50.8452 75.7694 50.8495C75.7724 50.8536 75.7789 50.8634 75.7853 50.8831L75.7964 50.9168L75.81 50.9496L79.304 59.3699L80.3241 62.4838L69.5943 66.3113Z"
        fill="#68A439"
        stroke="#040405"
        strokeWidth="1.84614"
      />
      <defs>
        <linearGradient
          id="paint0_linear_42_91407"
          x1="89.4007"
          y1="37.3454"
          x2="38.5584"
          y2="121.155"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.419792" stopColor="#68A439" />
          <stop offset="1" stopColor="#68A439" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_42_91407"
          x1="89.4009"
          y1="37.3455"
          x2="38.5586"
          y2="121.155"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.51875" stopColor="#68A439" />
          <stop offset="1" stopColor="#68A439" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_42_91407"
          x1="83.2183"
          y1="35.2541"
          x2="92.3107"
          y2="57.5625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E6E6" />
          <stop offset="1" stopColor="#8F9194" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { IllustrationSendEmail };
