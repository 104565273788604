import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const OrderWithdrawAvailable = ({
  width = 44,
  height = 44,
  alt = "pedido pronto para retirada",
  color = theme.colors.yellow["50"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="22" fill={color} />
      <path
        d="M10.4498 18.0833L13.8759 12H30.0449L33.5378 18.0833H10.4498Z"
        stroke={secondColor}
        strokeWidth="1.5"
      />
      <path
        d="M10.2503 18.1457C9.5211 21.5415 15.5002 23.1456 16.1044 18.3123C15.8127 21.8332 21.4794 22.8123 21.9586 18.479C22.0184 21.9396 27.4201 22.563 27.8961 18.6248C28.6669 23.0623 34.4772 21.4145 33.7502 18.229"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.2083 21.5208V31.9791C12.2083 32.2552 12.4322 32.4791 12.7083 32.4791H31.2599C31.536 32.4791 31.7599 32.2552 31.7599 31.9791V21.5208"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.4374 32.75V25.5C20.4374 24.9477 19.9897 24.5 19.4374 24.5H16.6041C16.0518 24.5 15.6041 24.9477 15.6041 25.5V32.75"
        stroke={secondColor}
        strokeWidth="1.5"
      />
      <path
        d="M24 24.75H28C28.1381 24.75 28.25 24.8619 28.25 25V29C28.25 29.1381 28.1381 29.25 28 29.25H24C23.8619 29.25 23.75 29.1381 23.75 29V25C23.75 24.8619 23.8619 24.75 24 24.75Z"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
