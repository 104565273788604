import {
  CustomStoriesDto,
  CustomStoriesSubComponentDto,
  SwiperProps,
} from "typing";
import { memo } from "react";
import { appMonitoringClient } from "implementations";
import { buildTestIds, useAppContext } from "application";
import { customStoriesData } from "./data";
import {
  ComponentBody,
  ComponentHeader,
  CustomStoriesContainer,
  CustomStoriesTitle,
  StoriesContent,
  StoriesSubtitle,
} from "./styles";
import { CustomStoriesSubComponent } from "./SubComponents";
import { Swiper } from "../../Swiper";

const CustomStories = memo(
  ({
    properties,
    subComponents,
    position,
    isBlackTheme,
    preloadComponent = false,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  CustomStoriesDto) => {
    const {
      title,
      showTitle,
      textAlign,
      subTitle,
      showSubTitle,
      format,
      size,
      hasShadow,
    } = customStoriesData(properties);

    const {
      isClientDesktop,
      isClientLaptopM,
      isClientLaptop,
      isClientTabletXL,
      isClientTablet,
      isClientTabletM,
      isClientTabletSmall,
      isClientMobile,
      isClientMobileL,
    } = useAppContext();

    const storiesListVisibleOnMobile = subComponents?.filter(
      (subComponent) => subComponent?.config?.branches?.[0]?.visibleOnMobile
    );

    const storiesListVisibleOnDesktop = subComponents?.filter(
      (subComponent) => subComponent?.config?.branches?.[0]?.visibleOnDesktop
    );

    const finalStoriesList = isClientMobile
      ? storiesListVisibleOnMobile
      : storiesListVisibleOnDesktop;

    const storiesLength = finalStoriesList?.length || 0;

    const swiperConfigs: SwiperProps = {
      effect: "slide",
      freeMode: true,
      sticky: "true",
      breakpoints: {
        1280: {
          speed: 500,
          freeMode: false,
          slidesPerGroup: 6,
          showNavigation: storiesLength > 6,
          slidesPerView: storiesLength < 6 ? storiesLength : 6,
        },
        1024: {
          speed: 500,
          freeMode: false,
          slidesPerGroup: 5,
          showNavigation: storiesLength > 5,
          slidesPerView: storiesLength < 5 ? storiesLength : 5,
        },
        850: {
          speed: 500,
          freeMode: false,
          slidesPerGroup: 4,
          showNavigation: storiesLength > 4,
          slidesPerView: storiesLength < 4 ? storiesLength : 4,
        },
        768: {
          speed: 500,
          freeMode: false,
          slidesPerGroup: 3,
          showNavigation: storiesLength > 3,
          slidesPerView: storiesLength < 3 ? storiesLength : 3,
        },
        600: {
          slidesPerView:
            storiesLength < 4
              ? storiesLength
              : format === "rectangle" || format === "square"
              ? 2.7
              : 4.5,
        },
        576: {
          slidesPerView:
            storiesLength < 4
              ? storiesLength
              : format === "square" || format === "rectangle"
              ? 2.7
              : 4.5,
        },
        480: {
          slidesPerView:
            storiesLength < 3
              ? storiesLength
              : format === "rectangle" || format === "square"
              ? 2.3
              : 3.4,
        },
        425: {
          slidesPerView:
            storiesLength < 3 ? storiesLength : size === "small" ? 4.8 : 2.7,
        },
        360: {
          slidesPerView:
            storiesLength < 3
              ? storiesLength
              : size === "small"
              ? 4.4
              : format === "square" || format === "rectangle"
              ? 2.2
              : 2.4,
        },
        320: {
          slidesPerView:
            storiesLength < 3
              ? storiesLength
              : size === "small"
              ? 3.8
              : format === "square" || format === "rectangle"
              ? 1.8
              : 2.4,
        },
      },
    };

    const details = { isClientMobile, isClientTablet, isClientLaptop };

    const getBreakpointValue = () => {
      if (isClientMobileL) return 320;
      if (isClientMobile) return 425;
      if (isClientTabletSmall) return 480;
      if (isClientTabletM) return 576;
      if (isClientTablet) return 600;
      if (isClientTabletXL) return 768;
      if (isClientLaptop) return 850;
      if (isClientLaptopM) return 1024;
      if (isClientDesktop) return 1280;

      return 0;
    };

    const subComponentsStories = finalStoriesList?.map(
      (subComponent, index) => {
        const currentIndex = index;

        return (
          <CustomStoriesSubComponent
            key={`${position}#story#${currentIndex}`}
            {...(subComponent as CustomStoriesSubComponentDto)}
            {...details}
            format={format}
            size={size}
            hasShadow={hasShadow}
            positionComponent={position}
            index={index + 1}
            preloadComponent={preloadComponent}
          />
        );
      }
    );

    if (!subComponents?.length) {
      appMonitoringClient.captureMessage(
        `O componente de Stories, localizado na posição ${position}, não apresenta nenhum SubComponente, ou seja, não está retornando nenhum slide.`,
        "error"
      );
      return null;
    }

    return (
      <CustomStoriesContainer
        {...buildTestIds("custom-stories-container")}
        customTheme={isBlackTheme}
        format={format}
      >
        <StoriesContent {...buildTestIds("custom-stories-content")}>
          <ComponentHeader
            {...buildTestIds("custom-stories-header")}
            textAlign={textAlign}
          >
            <CustomStoriesTitle
              {...buildTestIds("custom-stories-title")}
              isBlackTheme={isBlackTheme}
              showTitle={showTitle}
            >
              {title}
            </CustomStoriesTitle>
            {/* </StoriesTitle> */}
            <StoriesSubtitle
              {...buildTestIds("custom-stories-subtitle")}
              showSubTitle={showSubTitle}
              size={size}
            >
              {subTitle}
            </StoriesSubtitle>
          </ComponentHeader>
          <ComponentBody
            {...buildTestIds("custom-stories-body")}
            format={format}
          >
            <Swiper
              {...buildTestIds("custom-stories-swiper")}
              configs={swiperConfigs}
              subComponents={subComponentsStories}
              position={position}
              getBreakpoints={getBreakpointValue}
            />
          </ComponentBody>
        </StoriesContent>
      </CustomStoriesContainer>
    );
  }
);

CustomStories.displayName = "CustomStories";

export { CustomStories };
