import styled, { css } from "styled-components";
import { theme } from "ui";

export const SectionDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
export const ExtraInfo = styled.div`
  margin: 0 !important;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

type ContainerCardProps = {
  isDeleting: boolean;
};

export const ContainerCard = styled.div<ContainerCardProps>`
  background-color: ${theme.colors.neutral.white};
  margin: 0 !important;
  padding: 1.4rem;
  display: flex;
  border-bottom: thin solid ${theme.colors.neutral[95]};

  ${({ isDeleting }) =>
    isDeleting &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const BoxImage = styled.div`
  max-width: 5.8rem;
  max-height: 5.8rem;
  min-width: 5.8rem;
  min-height: 5.8rem;
`;
export const DescriptionProduct = styled.h4`
  font-size: ${theme.typography.fontSize.sm.x3};
  font-weight: 200;
  margin-bottom: 1rem;
`;
export const DescriptionValue = styled.span`
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${theme.colors.neutral[520]};
`;
export const TextPrice = styled.span`
  font-size: ${theme.typography.fontSize.sm.x3};
  font-weight: 600;
  text-align: end;
  color: ${theme.colors.primary["200"]};
`;
export const PriceValue = styled.span``;
export const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${theme.typography.fontSize.sm.x3};
  font-weight: 200;
`;
export const ButtonIcon = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  justify-content: flex-end;
`;

// export const  = styled.section`
//   background-color: ${theme.colors.neutral.white};
//   border-radius: 4px;
//   padding: 1.5rem;

//   display: flex;
//   gap: 1rem;

//   @media ${theme.device.desktopFirst.tablet} {
//     flex-direction: column;
//   }
// `;

export const IconBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  padding-top: 0.1rem;

  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
