import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Filter = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={color}
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.75 18V12.2184L21.6327 1.40266C21.9505 0.903363 21.5918 0.25 21 0.25H0.999996C0.408178 0.25 0.0495176 0.903363 0.36725 1.40266L7.25 12.2184V21C7.25 21.5575 7.83673 21.9202 8.33541 21.6708L14.3354 18.6708C14.5895 18.5438 14.75 18.2841 14.75 18ZM8.41171 11.25H13.5883L19.6337 1.75H2.36625L8.41171 11.25ZM13.25 12.75H8.75V19.7865L13.25 17.5365V12.75Z"
        fill={color}
      />
      <mask
        id="mask0_0_2702"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 18V12.2184L21.6327 1.40266C21.9505 0.903363 21.5918 0.25 21 0.25H0.999996C0.408178 0.25 0.0495176 0.903363 0.36725 1.40266L7.25 12.2184V21C7.25 21.5575 7.83673 21.9202 8.33541 21.6708L14.3354 18.6708C14.5895 18.5438 14.75 18.2841 14.75 18ZM8.41171 11.25H13.5883L19.6337 1.75H2.36625L8.41171 11.25ZM13.25 12.75H8.75V19.7865L13.25 17.5365V12.75Z"
          fill={secondColor}
        />
      </mask>
      <g mask="url(#mask0_0_2702)">
        <rect x="-3" y="-3" width={width} height={height} fill={color} />
      </g>
    </svg>
  );
};
