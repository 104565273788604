import styled from "styled-components";
import { theme } from "ui";

export const PointerUp = styled.div`
  position: absolute;
  top: -23px;
  left: calc(43%);
  display: block;
  width: 0;
  height: 0;
  z-index: 1;
  filter: drop-shadow(5px -6px 3px ${theme.colors.neutral["999-opacity-65"]});
  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  border-bottom: 2rem solid ${theme.colors.neutral.white};

  @media ${theme.device.desktopFirst.laptop} {
    left: calc(53%);
  }

  @media ${theme.device.desktopFirst.tabletXL} {
    left: calc(56%);
  }

  @media ${theme.device.desktopFirst.tablet} {
    left: calc(70%);
  }

  @media ${theme.device.desktopFirst.tabletS} {
    left: calc(75%);
  }
`;

interface ModalAuthenticationContainerProps {
  isOpenedLogin: boolean;
}

export const ModalAuthenticationContainer = styled.div<ModalAuthenticationContainerProps>`
  visibility: ${({ isOpenedLogin }) => (isOpenedLogin ? "visible" : "hidden")};
  opacity: ${({ isOpenedLogin }) => (isOpenedLogin ? 1 : 0)};
  z-index: 99;
  position: absolute;
  top: calc(100% + 2.5rem);
  right: calc(100% - 16.575rem);
  background: ${theme.colors.neutral.white};
  border-radius: 16px;
  box-shadow: 6px 4px 15px 0px ${theme.colors.neutral["999-opacity-60"]};
  padding: ${theme.space.x6};
  width: 30rem;
  cursor: auto;
  height: auto;
  transition: all 0.2s ease-in-out;
  cursor: initial;

  @media ${theme.device.desktopFirst.laptop} {
    right: calc(100% - 13.675rem);
  }

  @media ${theme.device.desktopFirst.tabletXL} {
    right: calc(100% - 12.675rem);
  }

  @media ${theme.device.desktopFirst.tablet} {
    right: calc(100% - 8.65rem);
  }

  @media ${theme.device.desktopFirst.tabletS} {
    right: calc(100% - 7rem);
  }
`;
