import { buildTestIds } from "application";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { Buckets, Filters } from "typing";
import FacetsFilterList from "../FacetsFilterList";
import { SidebarFilter } from "./styles";

type SelectedFilters = {
  title: string;
  option: string;
};

type FilterObject = {
  [key: string]: Filters | null;
};

interface ProductFilterSidebarProps {
  facets: Filters[] | undefined;
  openMobile: boolean;
  isLoading: boolean;
  isBlackTheme?: boolean;
  onApplyFilters: (
    selectedCategories: string[],
    selectedBrands: string[],
    selectedModels: string[],
    selectedAttrAgg: string[]
  ) => void;
  selectedBreadcrumbFilters: SelectedFilters[];
  handlerClick: () => void;
  selectedFilters: (activeFilters: SelectedFilters[]) => void;
}

const ProductFilterSidebar = ({
  facets,
  openMobile,
  isLoading,
  onApplyFilters,
  selectedBreadcrumbFilters,
  handlerClick,
  selectedFilters,
  isBlackTheme,
}: // italo.albuquerque revisar complexidade
// eslint-disable-next-line sonarjs/cognitive-complexity
ProductFilterSidebarProps) => {
  const { events } = useRouter();
  const [filters, setFilters] = useState<FilterObject[]>([]);

  const [openMobileFilter, setOpenMobileFilter] = useState(openMobile);

  const [categoryFilterParams, setCategoryFilterParams] = useState<string[]>(
    []
  );
  const [brandFilterParams, setBrandFilterParams] = useState<string[]>([]);
  const [modelFilterParams, setModelFilterParams] = useState<string[]>([]);
  const [, setAttrAggFilterParams] = useState<string[]>([]);
  const [attrAggFilterOptionsParams, setAttrAggFilterOptionsParams] = useState<
    string[]
  >([]);

  const [brandFilterTitle, setBrandFilterTitle] = useState<string>("");
  const [brandFilterOptions, setBrandFilterOptions] = useState<Buckets[]>([]);
  const [lastClickedFilter, setLastClickedFilter] = useState<string>("");

  useEffect(() => {
    if (facets) {
      const mappedFilters = Object.entries(facets).map(
        (filter) => ({
          [filter[0]]: filter[1],
        }),
        [facets]
      );

      setFilters(mappedFilters);
    }
  }, [facets]);

  const [categoryFilterTitle, setCategoryFilterTitle] = useState<string>("");
  const [categoryFilterOptions, setCategoryFilterOptions] = useState<Buckets[]>(
    []
  );
  const [modelFilterTitle, setModelFilterTitle] = useState<string>("");
  const [modelFilterOptions, setModelFilterOptions] = useState<Buckets[]>([]);
  const [attrAggFilterOptions, setAttrAggFilterOptions] = useState<Buckets[]>(
    []
  );

  const [activeFilters, setActiveFilters] = useState<SelectedFilters[]>(
    selectedBreadcrumbFilters
  );

  useEffect(() => {
    setActiveFilters(selectedBreadcrumbFilters);
  }, [selectedBreadcrumbFilters, selectedFilters]);

  useEffect(() => {
    events.on("routeChangeStart", () => {
      setActiveFilters([]);
      selectedFilters([]);
    });
  }, [events, selectedFilters]);

  useEffect(() => {
    const auxSelectedCategoryFilters = selectedBreadcrumbFilters.map(
      (activeFilter) =>
        activeFilter.title === "Categoria" ? activeFilter.option : ""
    );

    const auxSelectedBrandFilters = selectedBreadcrumbFilters.map(
      (activeFilter) =>
        activeFilter.title === "Marcas" ? activeFilter.option : ""
    );

    const auxSelectedModelFilters = selectedBreadcrumbFilters.map(
      (activeFilter) =>
        activeFilter.title === "Modelos" ? activeFilter.option : ""
    );

    const auxSelectedAttrAggFilters = selectedBreadcrumbFilters.map(
      (activeFilter) =>
        activeFilter.title !== "Categoria" &&
        activeFilter.title !== "Marcas" &&
        activeFilter.title !== "Modelos"
          ? `${activeFilter.title}-${activeFilter.option}`
          : ""
    );

    const auxSelectedAttrAggFiltersOptions = selectedBreadcrumbFilters.map(
      (activeFilter) =>
        activeFilter.title !== "Categoria" &&
        activeFilter.title !== "Marcas" &&
        activeFilter.title !== "Modelos"
          ? activeFilter.option
          : ""
    );

    setCategoryFilterParams(
      auxSelectedCategoryFilters.filter((element) => element !== "")
    );

    setBrandFilterParams(
      auxSelectedBrandFilters.filter((element) => element !== "")
    );

    setModelFilterParams(
      auxSelectedModelFilters.filter((element) => element !== "")
    );

    setAttrAggFilterParams(
      auxSelectedAttrAggFilters.filter((element) => element !== "")
    );

    setAttrAggFilterOptionsParams(
      auxSelectedAttrAggFiltersOptions.filter((element) => element !== "")
    );

    onApplyFilters(
      auxSelectedCategoryFilters.filter((element) => element !== ""),
      auxSelectedBrandFilters.filter((element) => element !== ""),
      auxSelectedModelFilters.filter((element) => element !== ""),
      auxSelectedAttrAggFilters.filter((element) => element !== "")
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBreadcrumbFilters]);

  const getBrandFacet = useCallback(() => {
    const brandFiltersList = filters.find((filter) => filter.brand);

    if (brandFiltersList) {
      Object.entries(brandFiltersList).map(([title, options]) => {
        setBrandFilterTitle(title);
        if (options) {
          Object.entries(options).map(([key, value]) => {
            if (
              (key === "buckets" && lastClickedFilter !== "Marcas") ||
              !activeFilters.length
            ) {
              setBrandFilterOptions(value as Buckets[]);
            }
            return null;
          });
        }
        return null;
      });
    }
  }, [activeFilters, filters, lastClickedFilter]);

  const getCategoryFacet = useCallback(() => {
    const categoryFiltersList = filters.find((filter) => filter.category);

    if (categoryFiltersList) {
      Object.entries(categoryFiltersList).map(([title, options]) => {
        setCategoryFilterTitle(title);
        if (options) {
          Object.entries(options).map(([key, value]) => {
            if (
              (key === "buckets" && lastClickedFilter !== "Categoria") ||
              !activeFilters.length
            ) {
              setCategoryFilterOptions(value as Buckets[]);
            }
            return null;
          });
        }
        return null;
      });
    }
  }, [activeFilters, filters, lastClickedFilter]);

  const getModelFacet = useCallback(() => {
    const modelFiltersList = filters.find((filter) => filter.model);

    if (modelFiltersList) {
      Object.entries(modelFiltersList).map(([title, options]) => {
        setModelFilterTitle(title);
        if (options) {
          Object.entries(options).map(([key, value]) => {
            if (
              (key === "buckets" && lastClickedFilter !== "Modelos") ||
              !activeFilters.length
            ) {
              setModelFilterOptions(value as Buckets[]);
            }
            return null;
          });
        }
        return null;
      });
    }
  }, [activeFilters, filters, lastClickedFilter]);

  // italo.albuquerque revisar complexidade
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getAttrAggFacet = useCallback(() => {
    let attrAgg: object = {};

    const attrAggFiltersList = filters.find((filter) => filter.attrAgg);

    if (attrAggFiltersList) {
      Object.entries(attrAggFiltersList).map(([, options]) => {
        if (options) {
          Object.entries(options).map(([key, value]) => {
            if (key === "key") {
              attrAgg = value as Buckets[];
            }
            return null;
          });
        }
        return null;
      });

      if (attrAgg) {
        Object.entries(attrAgg).map(([key, value]) => {
          if (
            (key === "buckets" && lastClickedFilter !== value.key) ||
            !activeFilters.length
          ) {
            setAttrAggFilterOptions(value as Buckets[]);
          }
          return null;
        });
      }
    }
  }, [activeFilters, filters, lastClickedFilter]);

  // const getBrandFacet = useCallback(() => {
  //   if (filters[1]) {
  //     Object.entries(filters[1]).map(([title, options]) => {
  //       setBrandFilterTitle(title);
  //       Object.entries(options).map(([key, value]) => {
  //         if (
  //           (key === "buckets" && lastClickedFilter !== "Marcas") ||
  //           !activeFilters.length
  //         ) {
  //           setBrandFilterOptions(value as Buckets[]);
  //         }
  //         return null;
  //       });
  //       return null;
  //     });
  //   }
  // }, [activeFilters, filters, lastClickedFilter]);

  // const getCategoryFacet = useCallback(() => {
  //   if (filters[2]) {
  //     Object.entries(filters[2]).map(([title, options]) => {
  //       setCategoryFilterTitle(title);
  //       Object.entries(options).map(([key, value]) => {
  //         if (
  //           (key === "buckets" && lastClickedFilter !== "Categoria") ||
  //           !activeFilters.length
  //         ) {
  //           setCategoryFilterOptions(value as Buckets[]);
  //         }
  //         return null;
  //       });
  //       return null;
  //     });
  //   }
  // }, [activeFilters, filters, lastClickedFilter]);

  // const getModelFacet = useCallback(() => {
  //   if (filters[3]) {
  //     Object.entries(filters[3]).map(([title, options]) => {
  //       setModelFilterTitle(title);
  //       Object.entries(options).map(([key, value]) => {
  //         if (
  //           (key === "buckets" && lastClickedFilter !== "Modelos") ||
  //           !activeFilters.length
  //         ) {
  //           setModelFilterOptions(value as Buckets[]);
  //         }
  //         return null;
  //       });
  //       return null;
  //     });
  //   }
  // }, [activeFilters, filters, lastClickedFilter]);

  // // italo.albuquerque revisar complexidade
  // // eslint-disable-next-line sonarjs/cognitive-complexity
  // const getAttrAggFacet = useCallback(() => {
  //   let attrAgg: object = {};
  //   if (filters[0]) {
  //     Object.entries(filters[0]).map(([, options]) => {
  //       Object.entries(options).map(([key, value]) => {
  //         if (key === "key") {
  //           attrAgg = value as Buckets[];
  //         }
  //         return null;
  //       });
  //       return null;
  //     });

  //     if (attrAgg !== null) {
  //       Object.entries(attrAgg).map(([key, value]) => {
  //         if (
  //           (key === "buckets" && lastClickedFilter !== value.key) ||
  //           !activeFilters.length
  //         ) {
  //           // console.log(value.buckets);
  //           setAttrAggFilterOptions(value as Buckets[]);
  //         }
  //         return null;
  //       });
  //     }
  //   }
  // }, [activeFilters, filters, lastClickedFilter]);

  useEffect(() => {
    getCategoryFacet();
    getBrandFacet();
    getModelFacet();
    getAttrAggFacet();
  }, [getCategoryFacet, getBrandFacet, getModelFacet, getAttrAggFacet]);

  useEffect(() => {
    setOpenMobileFilter(openMobile);
  }, [openMobile]);

  // useEffect(() => {
  //   handleOnApplyFilters();
  // }, [handleOnApplyFilters]);

  return (
    <SidebarFilter
      {...buildTestIds("sidebar-filter")}
      openMobile={openMobileFilter}
      onClick={() => {
        handlerClick();
      }}
      isBlackTheme={isBlackTheme}
    >
      {/* {openMobile && (
         <BackButton
           width="28px"
           height="28px"
           onClick={() => setOpenMobileFilter(!openMobile)}
         >
           <ArrowLeft color={theme.colors.neutral.white} />
         </BackButton>
	       )} */}
      <FacetsFilterList
        {...buildTestIds("first-facets-filter-list")}
        isLoadingFacets={isLoading}
        hasFacets={!!facets}
        isBlackTheme={isBlackTheme}
        filterTitle={categoryFilterTitle}
        filterOptions={categoryFilterOptions}
        activeOptions={categoryFilterParams}
        handleTitleFilter={(clickedOptionTitle: string) => {
          if (clickedOptionTitle) {
            setLastClickedFilter(clickedOptionTitle);
          }
        }}
        handleOptionFilter={(clickedOption: string) => {
          // const auxClickedOption = [...categoryFilterParams, clickedOption];

          // if (!categoryFilterParams.length) {
          //   setCategoryFilterParams(auxClickedOption);
          // }

          // const newAuxClickedOption = categoryFilterParams.find(
          //   (activeItem) => activeItem === clickedOption
          // );

          // setCategoryFilterParams(
          //   auxClickedOption.filter(
          //     (option) => option !== newAuxClickedOption
          //   )
          // );
          const auxActiveFilters = [
            ...activeFilters,
            { title: "Categoria", option: clickedOption },
          ];

          if (!activeFilters?.length) {
            setActiveFilters(auxActiveFilters);
            selectedFilters(auxActiveFilters);
          }

          const newAuxActiveFilters = activeFilters.find(
            (activeItem) => activeItem.option === clickedOption
          );

          const tempActiveFilters = auxActiveFilters.filter(
            (selectedFilter) =>
              selectedFilter.option !== newAuxActiveFilters?.option
          );

          setActiveFilters(tempActiveFilters);
          selectedFilters(tempActiveFilters);
        }}
        hasPreSelectFilter
      />

      <FacetsFilterList
        {...buildTestIds("second-facets-filter-list")}
        isLoadingFacets={isLoading}
        hasFacets={!!facets}
        isBlackTheme={isBlackTheme}
        filterTitle={brandFilterTitle}
        filterOptions={brandFilterOptions}
        activeOptions={brandFilterParams}
        handleTitleFilter={(clickedTitle) => {
          if (clickedTitle) {
            setLastClickedFilter(clickedTitle);
          }
        }}
        handleOptionFilter={(clickedOption) => {
          // const auxClickedOption = [...brandFilterParams, clickedOption];

          // if (!brandFilterParams.length) {
          //   setBrandFilterParams(auxClickedOption);
          // }

          // const newAuxClickedOption = brandFilterParams.find(
          //   (activeItem) => activeItem === clickedOption
          // );

          // setBrandFilterParams(
          //   auxClickedOption.filter(
          //     (option) => option !== newAuxClickedOption
          //   )
          // );
          const auxActiveFilters = [
            ...activeFilters,
            { title: "Marcas", option: clickedOption },
          ];

          if (!activeFilters?.length) {
            setActiveFilters(auxActiveFilters);
            selectedFilters(auxActiveFilters);
          }

          const newAuxActiveFilters = activeFilters.find(
            (activeItem) => activeItem.option === clickedOption
          );

          const tempActiveFilters = auxActiveFilters.filter(
            (selectedFilter) =>
              selectedFilter.option !== newAuxActiveFilters?.option
          );

          setActiveFilters(tempActiveFilters);
          selectedFilters(tempActiveFilters);
        }}
      />

      <FacetsFilterList
        {...buildTestIds("thrid-facets-filter-list")}
        isLoadingFacets={isLoading}
        hasFacets={!!facets}
        isBlackTheme={isBlackTheme}
        filterTitle={modelFilterTitle}
        filterOptions={modelFilterOptions}
        activeOptions={modelFilterParams}
        handleTitleFilter={(clickedOptionTitle) => {
          if (clickedOptionTitle) {
            setLastClickedFilter(clickedOptionTitle);
          }
        }}
        handleOptionFilter={(clickedOption) => {
          // const auxClickedOption = [...modelFilterParams, clickedOption];

          // if (!modelFilterParams.length) {
          //   setModelFilterParams(auxClickedOption);
          // }

          // const newAuxClickedOption = modelFilterParams.find(
          //   (activeItem) => activeItem === clickedOption
          // );

          // setModelFilterParams(
          //   auxClickedOption.filter(
          //     (option) => option !== newAuxClickedOption
          //   )
          // );
          const auxActiveFilters = [
            ...activeFilters,
            { title: "Modelos", option: clickedOption },
          ];

          if (!activeFilters?.length) {
            setActiveFilters(auxActiveFilters);
            selectedFilters(auxActiveFilters);
          }

          const newAuxActiveFilters = activeFilters.find(
            (activeItem) => activeItem.option === clickedOption
          );

          const tempActiveFilters = auxActiveFilters.filter(
            (selectedFilter) =>
              selectedFilter.option !== newAuxActiveFilters?.option
          );

          setActiveFilters(tempActiveFilters);
          selectedFilters(tempActiveFilters);
        }}
      />

      {categoryFilterParams?.length
        ? attrAggFilterOptions.map(
            // italo.alboquerque diminuir a complexidade
            // eslint-disable-next-line sonarjs/cognitive-complexity
            ({ key, value }) =>
              value &&
              Object.entries(value).map(([buckets, val]) =>
                buckets === "buckets" ? (
                  <FacetsFilterList
                    {...buildTestIds(`facets-filter-${buckets}`)}
                    isLoadingFacets={isLoading}
                    hasFacets={!!facets}
                    isBlackTheme={isBlackTheme}
                    key={buckets}
                    filterTitle={key}
                    filterOptions={val}
                    activeOptions={attrAggFilterOptionsParams}
                    handleTitleFilter={(clickedOptionTitle) => {
                      if (clickedOptionTitle) {
                        setLastClickedFilter(clickedOptionTitle);
                      }
                    }}
                    handleOptionFilter={(clickedOption) => {
                      // const auxClickedOption = [
                      //   ...attrAggFilterParams,
                      //   `${key}-${clickedOption}`,
                      // ];

                      // if (!attrAggFilterParams.length) {
                      //   setAttrAggFilterParams(auxClickedOption);
                      // }

                      // const newAuxClickedOption = attrAggFilterParams.find(
                      //   (activeItem) => activeItem === clickedOption
                      // );

                      // setAttrAggFilterParams(
                      //   auxClickedOption.filter(
                      //     (option) => option !== newAuxClickedOption
                      //   )
                      // );
                      const auxActiveFilters = [
                        ...activeFilters,
                        { title: key, option: clickedOption },
                      ];

                      if (!activeFilters?.length) {
                        setActiveFilters(auxActiveFilters);
                        selectedFilters(auxActiveFilters);
                      }

                      const newAuxActiveFilters = activeFilters.find(
                        (activeItem) => activeItem.option === clickedOption
                      );

                      const tempActiveFilters = auxActiveFilters.filter(
                        (selectedFilter) =>
                          selectedFilter.option !== newAuxActiveFilters?.option
                      );

                      setActiveFilters(tempActiveFilters);
                      selectedFilters(tempActiveFilters);
                    }}
                  />
                ) : null
              )
          )
        : null}
    </SidebarFilter>
  );
};

export default ProductFilterSidebar;
