import styled from "styled-components";
import { NewEyeCloseIcon, NewEyeOpenIcon, theme } from "ui";
import { ButtonSubmit } from "../ButtonSubmit";

export const ChangePasswordContainer = styled.div``;

export const ButtonConfirmModalPasswordChange = styled(ButtonSubmit)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  width: 100% !important;
  height: 3.75rem;
  padding: 0.65rem 4.9125rem;
  text-align: center;
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x7};
  font-weight: ${theme.typography.fontWeight["400"]};
  margin-top: ${theme.space.x6};

  border-radius: ${theme.space.x2};
  color: ${theme.colors.neutral.white};
  background: ${theme.colors.secondary["250"]};
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  border: 0;

  &:hover {
    background: ${theme.colors.secondary["380"]} !important;
  }
`;

export const ChangePasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x6};
  width: 100%;
  margin-bottom: ${theme.space.x4};

  @media ${theme.device.desktopFirst.mobileXL} {
    gap: ${theme.space.x4};
  }
`;

export const InputWrapper = styled.div``;

export const PasswordValidationWrapper = styled.div`
  width: 100%;
`;

export const EyeOpenIcon = styled(NewEyeOpenIcon).attrs({
  width: 20,
  height: 18,
})``;

export const EyeCloseIcon = styled(NewEyeCloseIcon)``;
