import { useRouter } from "next/router";
import { OmniSearch } from "typing";
import { TermSuggestionOption } from "../../subcomponents";

interface OmniSearchResultProps {
  result?: OmniSearch[];
  onClick: (searchTerm: string) => void;
}
export const OmniSearchResult = ({
  result,
  onClick,
}: OmniSearchResultProps) => {
  const router = useRouter();

  const getPath = (omniSearch: OmniSearch): string => {
    const needAdjust = omniSearch.url.split("/")?.length === 1;

    return `${
      omniSearch.url?.startsWith("/") || omniSearch?.externUrl ? "" : "/"
    }${needAdjust ? "pesquisa/" : ""}${omniSearch.url}`;
  };
  if (!result || result.length === 0) return null;

  return (
    <>
      {result.map((omniSearch) => (
        <TermSuggestionOption
          key={omniSearch.term}
          term={omniSearch.term}
          onClick={() => {
            router.push(getPath(omniSearch));
            onClick(omniSearch.term);
          }}
        />
      ))}
    </>
  );
};
