import { useAppContext } from "application";
import {
  useShallow,
  useTwoFactor,
  useUniqueClient,
} from "application/state-manager";
import { cookie } from "implementations";
import { useRouter } from "next/router";
import { useState } from "react";
import { IResponsiveModalProps } from "typing";
import { EmailFailureIcon, SuccessSyncIcon, TelephoneErrorIcon } from "ui";

const useUniqueClientFeedbackData = () => {
  const router = useRouter();
  const [showModal, setShowModal] = useState(true);
  const { changeUniqueClientStep, currentStep, setIsUniqueClientModalOpen } =
    useUniqueClient(
      useShallow((state) => ({
        changeUniqueClientStep: state.changeUniqueClientStep,
        currentStep: state.currentStep,
        setIsUniqueClientModalOpen: state.setIsUniqueClientModalOpen,
      }))
    );

  const { channelSelected } = useTwoFactor(
    useShallow((state) => ({
      channelSelected: state.channelSelected,
    }))
  );

  const { isClientMobile } = useAppContext();

  const isSuccess = currentStep === "feedbackSuccess";
  const isFailed = currentStep === "feedbackError";

  const getFeedbackImage = () => {
    if (isSuccess) {
      return <SuccessSyncIcon />;
    }

    if (isFailed && channelSelected === 1) {
      return <TelephoneErrorIcon />;
    }

    if (isFailed && channelSelected === 2) {
      return <EmailFailureIcon />;
    }

    return null;
  };

  const getModalTitle = () => {
    if (isSuccess) {
      return "Agora você pode acessar todas as suas compras!";
    }

    if (isFailed) {
      return "Erro ao realizar verificação";
    }

    return undefined;
  };

  const getDescription = () => {
    if (isSuccess) {
      return "Suas compras em nossas lojas físicas e online já podem ser acessadas pelo nosso site e aplicativo.";
    }

    if (isFailed) {
      return "Parece que você atingiu o limite de envios de código por dia. Para sua segurança, pausamos temporariamente as tentativas de envio do código de verificação. Por favor, aguarde 24 horas antes de tentar novamente ou entre em contato com o SAC.";
    }

    return undefined;
  };

  const getPrimaryButtonLabel = () => {
    if (isSuccess) {
      return "Ir para a página inicial";
    }

    if (isFailed) {
      return "Entrar em contato com o SAC";
    }

    return undefined;
  };

  const getSecondaryButtonLabel = () => {
    if (isSuccess) {
      return "Ir para meus pedidos";
    }

    if (isFailed) {
      return "Ir para página inicial";
    }

    return undefined;
  };

  const onClickOnPrimaryButton = () => {
    cookie.deleteCookie({
      name: "@FC:Ecom:HadValidationVisualization",
    });
    setIsUniqueClientModalOpen(false);
    setShowModal(false);
    if (isSuccess) {
      if (router?.pathname === "/") {
        changeUniqueClientStep("modalCall");
        return;
      }
      router?.push("/");
      return;
    }

    router?.push("https://ferreiracosta.movidesk.com/kb/");
  };

  const onClickOnSecondaryButton = () => {
    cookie.deleteCookie({
      name: "@FC:Ecom:HadValidationVisualization",
    });
    setIsUniqueClientModalOpen(false);
    setShowModal(false);
    if (isSuccess) {
      router.push("/conta/meus-pedidos");
      return;
    }

    if (router?.pathname === "/") {
      changeUniqueClientStep("modalCall");
      return;
    }
    router?.push("/");
  };

  const getUniqueClientFeedback = (): IResponsiveModalProps => {
    return {
      actions: {
        closeModal: () => {
          setShowModal(false);
          changeUniqueClientStep("modalCall");
          setIsUniqueClientModalOpen(false);
        },
        isModalOpen: showModal && (isSuccess || isFailed),
        modalTitle: isSuccess ? "Já comprou em uma das nossas lojas?" : "",
        hasHeader: !isFailed,
        hideheaderline: isFailed,
        headerPadding: isFailed ? "0px" : undefined,
      },
      content: {
        title: getModalTitle(),
        description: getDescription(),
        primaryButtonLabel: getPrimaryButtonLabel(),
        secondaryButtonLabel: getSecondaryButtonLabel(),
        image: getFeedbackImage(),
        onPrimaryButtonClick: onClickOnPrimaryButton,
        onSecondaryButtonClick: onClickOnSecondaryButton,
        contentType: isClientMobile ? "component" : "modal",
      },
    };
  };

  return { getUniqueClientFeedback };
};

export { useUniqueClientFeedbackData };
