import { ProductCardDto, Product } from "typing";

const getSpotPrice = (product: ProductCardDto | Product) => {
  if (Number.isNaN(product)) return "R$ 0";

  const spotPrice =
    product?.prices?.find((price) => price?.packingQuantity === 1)?.spotPrice ||
    product?.prices?.[0]?.spotPrice ||
    null;

  return (
    spotPrice?.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    }) || null
  );
};

export { getSpotPrice };
