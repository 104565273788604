import { useAppContext } from "application";
import { ShieldUserProtection, SingleUserIcon, theme } from "ui";
import { useEnvs, useShallow, useTwoFactor } from "application/state-manager";
import { Step } from "typing";
import { Header } from "../Header";
import Form from "./Form";
import { RegisterContainer, RegisterSpaceWrapper } from "./styles";

const Register = () => {
  const { allConfigs } = useEnvs();
  const { isClientMobile } = useAppContext();

  const { isValidationActive } = useTwoFactor(
    useShallow((state) => ({
      isValidationActive: state.isValidationActive,
    }))
  );

  const getHeaderSteps = (isOnValidation: boolean): Step[] => {
    if (!isOnValidation) {
      return [
        {
          name: "Cadastro",
          statusColor: theme.colors.secondary["250"],
        },
        {
          name: "Verificação",
          statusColor: theme.colors.neutral["150"],
        },
      ];
    }
    return [
      {
        name: "Cadastro",
        statusColor: theme.colors.secondary["37"],
      },
      {
        name: "Verificação",
        statusColor: theme.colors.secondary["250"],
      },
    ];
  };

  return (
    <RegisterContainer>
      <Header
        hasStepper={
          allConfigs?.isTwoFactorAuthenticationAvailable === "true" &&
          isClientMobile
        }
        marginBottom={isClientMobile ? theme.space.x0 : theme.space.x6}
        icon={
          isValidationActive ? <ShieldUserProtection /> : <SingleUserIcon />
        }
        steps={getHeaderSteps(isValidationActive)}
        stepTitle={
          isValidationActive
            ? "Verificação em duas etapas"
            : "Informações de cadastro"
        }
      />
      <RegisterSpaceWrapper>
        <Form />
      </RegisterSpaceWrapper>
    </RegisterContainer>
  );
};

export { Register };
