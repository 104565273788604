import { NewAddressFormComponents } from "typing";
import { theme } from "ui";
import { CustomInputNewAddress, WrapperInput } from "../../../styles";

interface ZipCodeComponentProps extends NewAddressFormComponents {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ZipCodeComponent = ({
  control,
  requiredFieldText,
  hasError,
  hasSuccessfully,
  textError,
  onChange,
}: ZipCodeComponentProps) => {
  return (
    <WrapperInput>
      <CustomInputNewAddress
        id="zipCode"
        name="zipCode"
        required={requiredFieldText}
        control={control}
        placeholder="_ _ _ _ _-_ _ _"
        type="tel"
        minLength={9}
        max={9}
        onChange={(e) => {
          onChange(e);
        }}
        maxLength={9}
        width="100%"
        mask="99999-999"
        margin={`${theme.space.x2} ${theme.space.x0}`}
        maskChar=""
        hasError={hasError}
        hasSuccessfully={hasSuccessfully}
        textError={textError}
        label="CEP"
      />
    </WrapperInput>
  );
};
