import { useAppContext } from "application";
import { useEffect, useState } from "react";

interface ScreenSkeletonProps {
  [key: string]: string | number;
}

const useScreenResizeSkeleton = () => {
  const { isClientLaptop, isClientTablet, isClientMobile } = useAppContext();

  const [screenResizeSkeleton, setScreenResizeSkeleton] =
    useState<ScreenSkeletonProps>({
      width: "170px",
      marginBottom: "10px",
    });

  useEffect(() => {
    if (isClientLaptop && !isClientTablet) {
      setScreenResizeSkeleton({ width: "130px", marginBottom: "10px" });
      return;
    }

    if (isClientTablet && !isClientMobile) {
      setScreenResizeSkeleton({ width: "320px", marginBottom: "10px" });
      return;
    }

    if (isClientMobile) {
      setScreenResizeSkeleton({ width: "220px", marginBottom: "10px" });
      return;
    }

    setScreenResizeSkeleton({ width: "170px", marginBottom: "10px" });
  }, [isClientLaptop, isClientMobile, isClientTablet]);

  return screenResizeSkeleton;
};

export { useScreenResizeSkeleton };
