import { theme, Aggregator, Text, Title } from "ui";

import { CategoryBlockProps } from "typing";
import { Link } from "../Link";
import { Skeleton } from "../Skeleton";
import { CategoryBlockTitle, CategoryBlockSubTitle, Wrapper } from "./style";

const CategoryBlock = ({
  category: { title, categories: data },
  level,
  isLoading,
  isBlackTheme,
}: CategoryBlockProps) => {
  const backgroundColor = isBlackTheme
    ? theme.colors.blackFriday["175"]
    : theme.colors.neutral["white-050"];

  let jsxElement: JSX.Element | null = null;

  switch (level) {
    case 1: {
      jsxElement = (
        <Title key={title} fontSize={theme.typography.fontSize.sm.x5}>
          {title}
        </Title>
      );
      break;
    }
    case 2:
    case 3: {
      jsxElement = (
        <Wrapper width="18%">
          <Aggregator key={title} background={backgroundColor}>
            <CategoryBlockTitle
              fontSize={theme.typography.fontSize.sm.x5}
              fontWeight={theme.typography.fontWeight["600"]}
              color={
                isBlackTheme
                  ? theme.colors.neutral.white
                  : theme.colors.neutral["520"]
              }
              margin="0px 0px 20px"
            >
              {title}
            </CategoryBlockTitle>
            <Aggregator background={backgroundColor}>
              {data?.length ? (
                data.map(
                  ({ id, title: innerCategoryTitle, descriptionUrl }) => (
                    <Text
                      key={id}
                      fontSize={theme.typography.fontSize.sm.x3}
                      fontWeight={theme.typography.fontWeight["500"]}
                      margin="10px 0"
                    >
                      <Link
                        href={`/${descriptionUrl}`}
                        target="_self"
                        fontSize={theme.typography.fontSize.sm.x3}
                        color={
                          isBlackTheme
                            ? theme.colors.neutral.white
                            : theme.colors.neutral["999"]
                        }
                        mouseOver={{
                          color: theme.colors.primary["200"],
                        }}
                      >
                        <CategoryBlockSubTitle>
                          {innerCategoryTitle}
                        </CategoryBlockSubTitle>
                      </Link>
                    </Text>
                  )
                )
              ) : isLoading ? (
                <Skeleton count={10} />
              ) : null}
            </Aggregator>
          </Aggregator>
        </Wrapper>
      );
      break;
    }
    default: {
      break;
    }
  }

  return jsxElement;
};

export default CategoryBlock;
