import { IconProps } from "typing";
import { theme } from "../themes";

export const UserIcon = ({
  width = 36,
  height = 36,
  color = theme.colors.neutral["999"],
  strokeWidth = "1.25",
}: IconProps) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
    >
      <circle
        fill="none"
        stroke={color}
        strokeMiterlimit={10}
        cx="12.02"
        cy="7.24"
        r="5.12"
      />
      <path
        fill="none"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        d="M3.56,21.95c.02-7.23,3.17-8.72,5.86-8.97,.98-.09,4.24-.09,5.23,0,2.7,.26,5.79,1.75,5.82,8.99"
      />
    </svg>
  );
};
