import { useState, useEffect } from "react";

export const useDebounce = <T = boolean>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(
      () => {
        setDebouncedValue(value);
      },
      value ? delay : 0
    );
    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};
