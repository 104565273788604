import styled, { keyframes, css } from "styled-components";
import { theme, CloseIcon as Close } from "ui";
import { ButtonSubmit } from "../../../../../ButtonSubmit";

interface ModalCartProps {
  visible: boolean;
}

interface HeaderModalCartProps {
  alignAtTheEnd: boolean;
}

const showModalCartAnimation = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
    top: 4rem;
  }

  100% {
    opacity: 1;
    visibility: visible;
    top: 6rem;
  }
`;

export const ModalCart = styled.article<ModalCartProps>`
  position: absolute;
  right: 1rem;
  top: 6rem;
  border-radius: 4px;
  z-index: 1;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  flex-direction: column;
  width: 40rem;
  box-shadow: 0 6px 36px 0 rgb(0 0 0 / 20%);
  z-index: 7;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  background-color: ${theme.colors.neutral.white};
  ${({ visible }) =>
    visible
      ? css`
          animation: ${showModalCartAnimation} 0.2s ease-in-out;
        `
      : null}
`;

export const ButtonCart = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.neutral.white};
  font-size: 1.25rem;
  font-weight: 400;
  cursor: pointer;
`;

export const ArrowItem = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  transform: rotate(45deg);
  position: absolute;
  top: -0.4rem;
  right: 1rem;
`;

export const HeaderModalCart = styled.header<HeaderModalCartProps>`
  position: relative;
  display: flex;
  /* justify-content: ${({ alignAtTheEnd }) =>
    alignAtTheEnd ? "flex-end" : "flex-start"}; */
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;

  padding: 1rem 1.2rem;
  background: ${theme.colors.primary["200"]};

  ${ArrowItem} {
    background: ${theme.colors.primary["200"]};
  }
`;

export const DescriptionEmptyCartProduct = styled.h4`
  position: relative;
  font-size: 1.5rem;
  color: ${theme.colors.neutral.white};
  text-align: left;
  font-weight: 400;
`;

export const Description = styled.div`
  position: relative;
  color: ${theme.colors.neutral.white};
  text-align: left;
`;

export const BoxEmpty = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-color: ${theme.colors.neutral.white};
`;

export const DescriptionEmptyCart = styled.span`
  font-size: 1.2rem;
  color: ${theme.colors.neutral["400"]};
`;

export const ContentModalCart = styled.div`
  max-height: 30rem;
  overflow: auto;
  border-bottom: 1px solid ${theme.colors.neutral["300"]};
`;

export const DescriptionTitleSub = styled.span`
  font-weight: bold;
  font-size: 1rem;
  color: ${theme.colors.neutral["900"]};
`;

export const DescriptionPrice = styled.span`
  font-size: 1.25rem;
  color: ${theme.colors.neutral["450"]};
  font-weight: 400;
`;

export const BoxExtraInfo = styled.div`
  justify-content: flex-end !important;
  align-items: flex-end !important;
`;

export const ColumnInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline !important;
`;

export const Button = styled(ButtonSubmit)`
  display: flex;
  border: none;
  border-radius: 4px;
  padding: 1rem 1.2rem;
  cursor: pointer;
  color: ${theme.colors.neutral.white};
  font-size: 1.5rem;
  height: 48px;
  width: 100% !important;
  min-width: 100%;
  background-color: ${theme.colors.secondary["300"]} !important;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${theme.colors.secondary["315"]} !important;
  }
`;

export const FooterModalCart = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 4px 4px;

  background-color: ${theme.colors.neutral["50"]} !important;
  padding: 1rem;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const CloseIcon = styled(Close).attrs(() => ({
  color: theme.colors.neutral.white,
  width: 20,
  height: 20,
}))``;
