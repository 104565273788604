import { buildTestIds, useAppContext } from "application";
import { useRouter } from "next/router";
import { ProductCardDto } from "typing";
import { Text } from "ui";
import ProductCard from "../ProductCard";
import { CardContainer, PlaylistStyled } from "./styles";

export interface PlaylistProps {
  productCards: ProductCardDto[] | undefined;
  searchTerm?: string;
  playlistName?: string;
  playlistId?: number;
  preloadComponent?: boolean;
}

const Playlist = ({
  productCards,
  searchTerm,
  playlistName,
  playlistId,
  preloadComponent = false,
}: PlaylistProps) => {
  const router = useRouter();
  const { isClientMobile } = useAppContext();
  const isProductPage = router?.route === "/produto/[idProduct]/[nameProduct]";

  return (
    <PlaylistStyled
      {...buildTestIds("playlist-styled-component")}
      key={`playlist-styled-${playlistId}`}
      isProductPage={isProductPage}
    >
      {productCards ? (
        productCards?.map((item: ProductCardDto, index) => (
          <CardContainer
            {...buildTestIds(`card-container-${index}`)}
            key={item?.id}
          >
            <ProductCard
              {...buildTestIds(`product-card-${index}`)}
              playlistId={playlistId}
              playlistName={playlistName as string}
              searchTerm={searchTerm}
              item={item}
              shouldShowInGridOnMobile={isClientMobile}
              index={index}
              isPlaylist
              preloadComponent={preloadComponent && index < 5}
            />
          </CardContainer>
        ))
      ) : (
        <Text {...buildTestIds("result-not-found-text")}>
          Resultado não encontrado.
        </Text>
      )}
    </PlaylistStyled>
  );
};

export default Playlist;
