import { Fragment } from "react";
import { buildTestIds, useAppContext } from "application";
import {
  AlertError,
  CheckSuccess,
  TextError,
  WrapperAlertError,
  WrapperCheckSuccess,
} from "./styles";

interface StateInputProps {
  conditionError: boolean;
  messageError: string;
  conditionSuccess: boolean;
}

const StateInput = ({
  conditionError,
  conditionSuccess,
  messageError,
}: StateInputProps) => {
  const { isFirefox, isSafari } = useAppContext();

  return (
    <Fragment key={messageError}>
      {conditionError ? (
        <>
          <WrapperAlertError
            {...buildTestIds("wrapper-error-icon")}
            positionShouldBeRelative={isFirefox || isSafari}
          >
            <AlertError />
          </WrapperAlertError>
          <TextError {...buildTestIds("text-error")}>{messageError}</TextError>
        </>
      ) : conditionSuccess ? (
        <WrapperCheckSuccess {...buildTestIds("wrapper-success-icon")}>
          <CheckSuccess />
        </WrapperCheckSuccess>
      ) : null}
    </Fragment>
  );
};

export { StateInput };
