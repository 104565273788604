import styled from "styled-components";
import {
  FlexContainer,
  NewEyeCloseIcon,
  NewEyeOpenIcon,
  Row,
  SpaceWrapper,
  Text,
  theme,
  WhatsApp,
} from "ui";
import { ButtonSubmit } from "../../../ButtonSubmit";
import Label from "../../../Form/Label";
import { Link } from "../../../Link";

export const BodyContainer = styled.section``;

export const CreateAccountButtonContainer = styled.section`
  display: flex;
  justify-content: center;
`;

export const createAccountTitle = styled(Text)`
  color: ${theme.colors.neutral["520"]};
`;

export const TwoFactorContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const RowCustom = styled(Row).attrs({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.space.x0,
})``;

export const WhatsAppIcon = styled(WhatsApp).attrs({
  width: 38,
  height: 38,
})``;

export const UserData = styled.section`
  display: flex;
  gap: ${theme.space.x4};

  @media ${theme.device.desktopFirst.tabletM} {
    flex-wrap: wrap;
    gap: ${theme.space.x0};
  }
`;

export const EyeOpenIcon = styled(NewEyeOpenIcon).attrs({
  width: 16,
  height: 16,
  color: theme.colors.neutral["550"],
})``;

export const EyeCloseIcon = styled(NewEyeCloseIcon).attrs({
  width: 16,
  height: 16,
  color: theme.colors.neutral["550"],
})``;

export const LabelCustom = styled(Label)`
  color: ${theme.colors.neutral["750"]};
  font-weight: 400;
  font-size: ${theme.typography.fontSize.sm.x7};
`;

export const WrapperRadioButton = styled(FlexContainer)`
  gap: ${theme.space.x3};
  flex-direction: column;

  @media ${theme.device.desktopFirst.tabletM} {
    gap: ${theme.space.x3};
    flex-direction: row;
  }
`;

export const WrapperInput = styled.div`
  min-width: 406px;
  margin-bottom: ${theme.space.x6};

  @media ${theme.device.desktopFirst.tabletXL} {
    max-width: 324px;
    min-width: 324px;
  }

  @media ${theme.device.desktopFirst.tabletM} {
    min-width: 100%;
    max-width: 100%;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-bottom: ${theme.space.x4};
  }
`;

export const SignUpFormButton = styled(ButtonSubmit).attrs({
  minWidth: "406px",
  fontSize: theme.typography.fontSize.sm.x7,
  height: "3.75rem",
  fontWeight: theme.typography.fontWeight["400"],
  fontFamily: theme.typography.fontFamily.chivo,
})`
  @media ${theme.device.desktopFirst.tabletM} {
    min-width: 324px;
  }
`;

type StyledCheckboxProps = {
  hasError?: boolean;
  color?: string;
};

export const CheckboxCustom = styled.input.attrs({
  type: "checkbox",
})<StyledCheckboxProps>`
  -webkit-appearance: none;
  appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0.8175rem;
  border-radius: ${theme.space.x1};
  margin-right: 0.5em;
  border: ${`${theme.space.xpx} solid ${theme.colors.neutral["300"]}`};
  outline: none;
  cursor: pointer;
  pointer-events: initial;
  transition: all 0.2s ease-in;

  &:hover {
    border: ${({ color }) =>
      `${theme.space.xpx} solid ${color ?? theme.colors.primary["200"]}`};
  }

  &:checked {
    border: ${({ color }) =>
      `${theme.space.xpx} solid ${color ?? theme.colors.primary["200"]}`};

    position: relative;
    background-color: ${({ color }) => color ?? theme.colors.primary["200"]};
  }

  &:checked::before {
    content: "";
    font-size: 1.5em;
    color: ${theme.colors.neutral.white};
    position: absolute;
    left: 2px;
    top: -4px;
    width: 6px;
    height: 11px;
    border-width: 0 3px 3px 0;
    border-style: solid;
    transform-origin: bottom left;
    transform: rotate(45deg);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

interface ContainerProps {
  isHidden?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: ${({ isHidden }) => ` ${isHidden ? "none" : "block"}`};
`;

export const FormHeaderContainer = styled.div<ContainerProps>`
  display: ${({ isHidden }) => ` ${isHidden ? "none" : "block"}`};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  width: 406px;
  align-items: center;
  gap: ${theme.space.x0};

  @media ${theme.device.desktopFirst.tabletM} {
    margin-bottom: ${theme.space.x4};
    width: 100%;
  }
`;

export const Separator = styled.hr`
  margin-bottom: 0.6rem;
  background-color: ${theme.colors.neutral["200"]};
  height: 1px;
  border: 0;
`;

export const FormFooterWrapper = styled(SpaceWrapper).attrs({
  margin: `${theme.space.x4} ${theme.space.x0} ${theme.space.x0} ${theme.space.x0}`,
})`
  display: flex;
  flex-direction: column;

  @media ${theme.device.desktopFirst.tabletM} {
    margin: ${theme.space.x0};
  }
`;

export const RecaptchaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const PrivacyPolicyLink = styled.span`
  letter-spacing: -0.4px;
  font-weight: ${theme.typography.fontWeight["400"]};
  margin-left: 0.45rem;
`;

export const LinkText = styled(Link)`
  text-decoration: underline !important;
`;

export const OptionText = styled.p`
  padding: ${theme.space.x0} ${theme.space.x4};
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.neutral["350"]};
  font-family: ${theme.typography.fontFamily.chivo};
`;

export const Divider = styled.div`
  position: initial;
  background-color: ${theme.colors.neutral["580"]};
  height: 1px;
  width: 100%;
`;

export const WrapperDivider = styled.aside`
  width: 100%;
  padding-bottom: ${theme.space.x4};
  display: flex;
  align-items: center;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding-bottom: ${theme.space.x4};
  }
`;
