import { IResponsiveModalActions, IResponsiveModalContent } from "typing";
import { ResponsiveModal } from "../../../../../../../../../../ResponsiveModal";
import { InputProps } from "../../../../../../../../../../Form/Input";

const PhoneValidationContentUI = ({
  ...props
}: IResponsiveModalActions & IResponsiveModalContent & InputProps) => {
  return <ResponsiveModal {...props} />;
};

export { PhoneValidationContentUI };
