import { IconProps } from "typing";
import { theme } from "../themes";

export const ReportSecurityProblemIcon = ({
  width,
  height,
  color = theme.colors.neutral["550"],
  alt,
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <rect
        x="7.5"
        y="10.083"
        width="5"
        height="3.33333"
        rx="1.25"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4577 10.083V9.04134C11.4577 8.23593 10.8048 7.58301 9.99935 7.58301V7.58301C9.19393 7.58301 8.54102 8.23593 8.54102 9.04134V10.083"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6673 11.3504C16.6673 14.496 12.7966 16.8327 10.9508 17.7709C10.3552 18.0754 9.64978 18.076 9.05369 17.7726C7.20908 16.8353 3.33398 14.4928 3.33398 11.3504V6.80749C3.34011 6.38178 3.66251 6.02737 4.08573 5.98109C5.854 5.84672 7.54928 5.21977 8.97948 4.17129C9.58554 3.72025 10.4157 3.72025 11.0218 4.17129C12.452 5.21977 14.1473 5.84672 15.9155 5.98109C16.3388 6.02736 16.6612 6.38177 16.6673 6.80749V11.3504Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
