import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const DefaultProductImage = ({
  width = 160,
  height = 160,
  alt = "Imagem padrão de produto",
  color = theme.colors.neutral["white-075"],
  secondColor = theme.colors.neutral["88"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="20" y="20" width="120" height="120" rx="12" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.8933 43.9172L93.9172 43.8933C99.1285 38.6952 107.566 38.7033 112.767 43.9112C117.969 49.1192 117.966 57.5568 112.761 62.7614C107.557 67.9661 99.1192 67.9688 93.9112 62.7674C88.7033 57.5661 88.6952 49.1285 93.8933 43.9172Z"
        fill={secondColor}
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2001 126.8V108.065V93.698C23.2001 87.6367 36.1461 75.5545 42.1333 72.1469C45.2887 70.351 51.6 68.5551 57.9112 70.351C62.5032 71.6577 73.334 78.2307 78.4222 81.1265C84.7333 84.7184 90.5911 89.2906 97.3555 91.902C104.591 94.6954 119.444 93.698 125.755 86.5143C132.067 79.3306 136.666 55.7046 136.666 55.7046L136.571 126.813C136.563 132.331 132.088 136.8 126.571 136.8H33.2001C27.6772 136.8 23.2001 132.323 23.2001 126.8Z"
        fill={secondColor}
      />
      <path
        d="M136.8 48.8L136.666 55.7046M136.666 55.7046L136.571 126.813C136.563 132.331 132.088 136.8 126.571 136.8H33.2001C27.6772 136.8 23.2001 132.323 23.2001 126.8V108.065C23.2001 105.671 23.2 102.678 23.2001 93.698C23.2001 87.6367 36.1461 75.5545 42.1333 72.1469C45.2888 70.351 51.6 68.5551 57.9112 70.351C62.5032 71.6577 73.334 78.2307 78.4222 81.1265C84.7333 84.7184 90.5911 89.2906 97.3555 91.902C104.591 94.6954 119.444 93.698 125.755 86.5143C132.067 79.3306 136.666 55.7046 136.666 55.7046Z"
        stroke={color}
        strokeWidth="4"
      />
    </svg>
  );
};
