const formatBranchId = (branchId: string) => {
  const parsedBranchId = Number(branchId);

  if (!Number.isNaN(parsedBranchId)) {
    return branchId;
  }

  return "2";
};

export { formatBranchId };
