import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const TrackingStatusOrderOutForDelivery = ({
  width = 36,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Pedido saiu pra entrega",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M23.5865 21.2467C24.1024 21.7626 24.1024 22.5984 23.5865 23.1134C23.0707 23.6292 22.2349 23.6292 21.7199 23.1134C21.204 22.5976 21.204 21.7617 21.7199 21.2467C22.2357 20.7309 23.0715 20.7309 23.5865 21.2467"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4197 21.2467C14.9356 21.7625 14.9356 22.5983 14.4197 23.1133C13.9039 23.6292 13.0681 23.6292 12.5531 23.1133C12.0381 22.5975 12.0372 21.7617 12.5531 21.2467C13.0689 20.7317 13.9039 20.7308 14.4197 21.2467"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3332 9.3335H19.6665C20.1265 9.3335 20.4998 9.70683 20.4998 10.1668V18.5002H9.6665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1665 22.1802H10.4998C10.0398 22.1802 9.6665 21.8068 9.6665 21.3468V16.8335"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 11.8335H24.1025C24.4433 11.8335 24.75 12.041 24.8758 12.3577L26.2142 15.7027C26.2925 15.8993 26.3333 16.1093 26.3333 16.321V21.2777C26.3333 21.7377 25.96 22.111 25.5 22.111H23.9742"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3331 22.1835H14.8081"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3333 17.6668H23V14.3335H25.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6665 9.33341H13.8332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6665 11.8334H12.1665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4998 14.3334H9.6665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
