import styled, { css } from "styled-components";
import { theme } from "ui";
import { Link } from "../../Link";
import { Image } from "../../Image";

interface ImageSectionProps {
  opacity?: string;
}

interface MarqueeTitleProps {
  showTitle: "true" | "false";
}

export const CustomMarqueeContainer = styled.section`
  font-weight: ${theme.typography.fontWeight["400"]};
  font-family: ${theme.typography.fontFamily.chivo};
  height: 31.5rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  @media ${theme.device.desktopFirst.tablet} {
    height: 20rem;
  }

  @media ${theme.device.desktopFirst.mobileM} {
    height: 20.5rem;
  }
`;

const MarqueeAncor = styled(Link)``;

const MarqueeOrdenadedList = styled.ol``;

export const MainContentWrapper = styled.section`
  position: relative;
  display: flex;
  width: 100%;

  ${MarqueeAncor} {
    font-weight: bold;
    text-decoration: underline;
  }

  ${MarqueeOrdenadedList} {
    margin-left: ${theme.space.x4};
  }

  @media ${theme.device.desktopFirst.laptopM} {
    padding: 0 ${theme.space.x4};
  }

  @media ${theme.device.desktopFirst.tablet} {
    padding: ${theme.space.x0} ${theme.space.x6};
  }
`;

export const MainContent = styled.section`
  max-width: 1280px;
  width: 100%;
  margin: ${theme.space.x0} auto;
  word-break: break-word;
  color: ${theme.colors.neutral.white};
  align-self: center;
  line-height: 1.5;

  @media ${theme.device.desktopFirst.laptopM} {
    margin: 0;
  }
`;

export const ImageSection = styled(Image)<ImageSectionProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${({ opacity, src }) =>
    opacity
      ? css`
          background-image: linear-gradient(to right, ${opacity}, ${opacity}),
            url(${src});
        `
      : css`
          background-image: url(${src});
        `}
`;

export const MarqueeTitle = styled.h1<MarqueeTitleProps>`
  max-width: 61rem;
  display: ${({ showTitle }) => (showTitle === "false" ? "none" : "")};
  font-size: 4rem;
  color: ${theme.colors.neutral.white};
  line-height: normal;
  margin-bottom: ${theme.space.x4};

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.lg.x2};
    margin-bottom: ${theme.space.x1};
    padding-bottom: ${theme.space.x1};
  }

  @media ${theme.device.desktopFirst.tabletS} {
    font-size: ${theme.typography.fontSize.md.x4};
    margin-bottom: ${theme.space["x2.5"]};
    padding-bottom: ${theme.space["x2.5"]};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-bottom: ${theme.space.x1};
    padding-bottom: ${theme.space.x0};
  }

  @media ${theme.device.desktopFirst.mobileL} {
    font-size: ${theme.typography.fontSize.md.x3};
    margin-bottom: ${theme.space.x1};
    padding-bottom: ${theme.space.x0};
  }

  @media ${theme.device.desktopFirst.mobileS} {
    font-size: ${theme.typography.fontSize.sm.x9};
    margin-bottom: ${theme.space.x1};
    padding-bottom: ${theme.space.x0};
  }
`;

export const HorizontalLine = styled.hr`
  width: 349px;
  height: 1px;
  margin-bottom: ${theme.space.x4};
  padding: ${theme.space.x0};
  border-color: ${theme.colors.neutral.white};

  @media ${theme.device.desktopFirst.tablet} {
    width: 349px;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    width: 262px;
    margin-bottom: ${theme.space.x1};
  }

  @media ${theme.device.desktopFirst.mobileS} {
    width: 222px;
    margin-bottom: ${theme.space.x1};
  }
`;

export const MarqueeTextSection = styled.section`
  max-width: 61rem;
  word-break: break-word;
  font-size: ${theme.typography.fontSize.sm.x7};

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }

  @media ${theme.device.desktopFirst.tabletS} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }
`;
