import { IAppMonitoringClient, ICookie, IJwtDecoder } from "app-domain";
import { AuthenticationProps, UserData } from "typing";
import { EnvsApi } from "../../../services/apis/Envs";
import { validateAccessTokenIsExpired } from "./expiration-date";
import { isAccessTokenIssuerValid } from "./issuer";
import { accessTokenCookieKey, refreshTokenCookieKey } from "./keys";
import {
  deleteLoggedUserCookies,
  updateLoggedUserTokens,
} from "./logged-user-cookies";
import { redirectBasedOnProtectedRoutes } from "./redirects";
import {
  isValidatingAccessToken,
  refreshAccessToken,
  setIsValidatingAccessToken,
} from "./refresh-access-token";

const shouldRetry = (retryCount: number) => {
  return isValidatingAccessToken() && retryCount < 3;
};

type RefreshAccessTokenReturn = {
  refreshingAccessToken: boolean;
  refreshedAccessToken?: string;
  cancelRequest?: boolean;
};

let envsApi: EnvsApi | null = null;

const handleRefreshUserAccessToken = async (
  cookie: ICookie,
  appMonitoringClient: IAppMonitoringClient,
  jwtDecoder: IJwtDecoder,
  authorization = "",
  timeToRefreshAccessTokenBeforeItExpiresInSeconds = 120,
  retryCount = 0
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Promise<RefreshAccessTokenReturn> => {
  if (!authorization) {
    return {
      refreshingAccessToken: false,
    };
  }

  try {
    if (isValidatingAccessToken()) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (shouldRetry(retryCount)) {
            return resolve(
              handleRefreshUserAccessToken(
                cookie,
                appMonitoringClient,
                jwtDecoder,
                authorization,
                timeToRefreshAccessTokenBeforeItExpiresInSeconds,
                retryCount + 1
              )
            );
          }

          setIsValidatingAccessToken(false);

          return resolve({
            refreshingAccessToken: false,
            refreshedAccessToken: cookie.getCookie({
              name: accessTokenCookieKey,
            }),
          });
        }, 1500);
      });
    }

    setIsValidatingAccessToken(true);

    const accessToken = cookie.getCookie({ name: accessTokenCookieKey });

    const accessTokenDecoded = jwtDecoder.decode<UserData>(
      accessToken as unknown as string
    );

    if (!envsApi) {
      envsApi = new EnvsApi(cookie, appMonitoringClient);
    }

    const isIssuerValid = await isAccessTokenIssuerValid(
      accessTokenDecoded?.iss || "",
      envsApi,
      cookie,
      appMonitoringClient
    );

    if (!isIssuerValid) {
      setIsValidatingAccessToken(false);
      return { refreshingAccessToken: false, cancelRequest: true };
    }

    const isAccessTokenExpired = validateAccessTokenIsExpired(
      accessTokenDecoded.exp,
      timeToRefreshAccessTokenBeforeItExpiresInSeconds
    );

    if (isAccessTokenExpired) {
      const refreshToken = cookie.getCookie({ name: refreshTokenCookieKey });

      if (!refreshToken) {
        deleteLoggedUserCookies(
          cookie,
          accessTokenCookieKey,
          refreshTokenCookieKey
        );

        appMonitoringClient.captureException(
          `O access token do usuário expirou e não existe um cookie (${refreshTokenCookieKey}) com o refresh token do usuário para que seja gerado um novo access token`,
          {
            level: "error",
            tags: {
              fcx_labs_error_source: "refresh_access_token",
            },
          }
        );

        setIsValidatingAccessToken(false);

        window?.location?.replace(
          `/login?redirectTo=${window?.location?.pathname}`
        );

        return { refreshingAccessToken: false, cancelRequest: true };
      }

      const data = await refreshAccessToken(refreshToken);

      const refreshTokenIsNotValid =
        (data as unknown as { status: number })?.status === 400;

      if (refreshTokenIsNotValid) {
        deleteLoggedUserCookies(
          cookie,
          accessTokenCookieKey,
          refreshTokenCookieKey
        );

        appMonitoringClient.captureException(
          "Chamada a API do Identity com refresh token retornou 400",
          {
            level: "error",
            tags: {
              fcx_labs_error_source: "refresh_access_token",
            },
          }
        );

        setIsValidatingAccessToken(false);

        redirectBasedOnProtectedRoutes(["/checkout", "/conta", "/meu-painel"]);

        return {
          refreshingAccessToken: false,
          cancelRequest: true,
        };
      }

      const authenticationProps = data as AuthenticationProps;

      updateLoggedUserTokens(cookie, authenticationProps);

      setIsValidatingAccessToken(false);

      appMonitoringClient.configureScope(
        { email: accessTokenDecoded.email },
        authenticationProps?.access_token
      );

      return {
        refreshingAccessToken: false,
        refreshedAccessToken: authenticationProps?.access_token,
      };
    }
  } catch (err) {
    appMonitoringClient.captureException(err, {
      level: "error",
      tags: {
        fcx_labs_error_source: "refresh_access_token",
      },
    });

    setIsValidatingAccessToken(false);

    return {
      refreshingAccessToken: false,
      refreshedAccessToken: cookie.getCookie({
        name: accessTokenCookieKey,
      }),
    };
  }

  setIsValidatingAccessToken(false);

  return { refreshingAccessToken: false };
};

export { handleRefreshUserAccessToken };
