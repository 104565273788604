import { useEffect, useState } from "react";
import { CloseIcon } from "ui";
import {
  BreadcrumbFilterItem,
  BreadcrumbsFilterList,
  BreadcrumbsFilterStyled,
  CloseFilter,
  FilterText,
  FilterTitle,
} from "./styles";

type SelectedBreadcrumbFilter = {
  title: string;
  option: string;
};

interface BreadcrumbFilterProps {
  selectedFilters: SelectedBreadcrumbFilter[];
  isBlackTheme?: boolean;
  handlerClick: () => void;
  selectedBreadcrumbFilters: (
    selectFilters: SelectedBreadcrumbFilter[]
  ) => void;
}

const BreadcrumbFilter = ({
  selectedFilters,
  isBlackTheme,
  handlerClick,
  selectedBreadcrumbFilters,
}: BreadcrumbFilterProps) => {
  const [activeFilters, setActiveFilters] = useState<
    SelectedBreadcrumbFilter[]
  >([]);

  useEffect(() => {
    setActiveFilters(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    selectedBreadcrumbFilters(activeFilters);
  }, [activeFilters, selectedBreadcrumbFilters]);

  const removeFilter = (filter: SelectedBreadcrumbFilter) => {
    setActiveFilters(
      activeFilters.filter((element) => element.option !== filter.option)
    );
  };

  return (
    <BreadcrumbsFilterStyled isBlackTheme={isBlackTheme}>
      {activeFilters.length > 0 && (
        <BreadcrumbsFilterList>
          {activeFilters.map((filter) => (
            <BreadcrumbFilterItem
              key={filter.option}
              onClick={() => {
                removeFilter(filter);
                handlerClick();
              }}
            >
              <FilterTitle>{filter.title}</FilterTitle>
              <FilterText>{decodeURIComponent(filter.option)}</FilterText>
              <CloseFilter>
                <CloseIcon width="15px" height="15px" />
              </CloseFilter>
            </BreadcrumbFilterItem>
          ))}
        </BreadcrumbsFilterList>
      )}
    </BreadcrumbsFilterStyled>
  );
};

export default BreadcrumbFilter;
