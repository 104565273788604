import { ReactNode } from "react";
import { TermSuggestionOption } from "../../subcomponents";

interface SearchHistoricResultProps {
  showHistoricSearch: boolean;
  result?: string[];
  onClick: (searchTerm: string) => void;
  actionButton: {
    icon: ReactNode;
    onClick: (searchTerm: string) => void;
  };
}
export const SearchHistoricResult = ({
  result,
  showHistoricSearch,
  onClick,
  actionButton,
}: SearchHistoricResultProps) => {
  if (showHistoricSearch) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {result?.map((searchedTerm) => (
          <TermSuggestionOption
            key={searchedTerm}
            term={searchedTerm}
            actionButton={actionButton}
            onClick={() => {
              onClick(`/pesquisa/${searchedTerm}`);
            }}
          />
        ))}
      </>
    );
  }

  return null;
};
