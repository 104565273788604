import styled, { css } from "styled-components";
import { Button, FlexContainer, theme } from "ui";

interface PaginatorContainerProps {
  isBlackTheme?: boolean;
}

export const PageChangeButton = styled(Button)`
  width: 2.3rem;
  height: 2.3rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  background-color: ${theme.colors.neutral.white};
  border-radius: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      fill: ${theme.colors.neutral.white};
    }
  }
`;

export const PageCounter = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 4px -1px rgba(0, 0, 0, 0.16);
  margin: 0 1rem;
  border-radius: 4px;
  overflow: hidden;

  &:last-of-child {
    cursor: none;
  }
`;

export type PageProps = {
  isSelected: boolean;
  isLoading?: boolean;
  ellipsis?: boolean;
  isBlackTheme?: boolean;
};

export const Page = styled.a<PageProps>`
  width: 4rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  color: ${theme.colors.neutral["450"]};
  transition: ease-in-out 0.2s;

  ${({ ellipsis }) =>
    ellipsis
      ? `cursor: default !important;
        color: ${theme.colors.neutral["450"]} !important;
        background-color: ${theme.colors.neutral.white}`
      : ``};

  ${({ isSelected }) =>
    isSelected
      ? `
      background-color: ${theme.colors.primary["200"]};
      color: ${theme.colors.neutral.white};
      cursor: default;`
      : `&:hover {
        background-color: ${theme.colors.primary["200"]};
        color: ${theme.colors.neutral.white};
        cursor: pointer;
        }`};

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const ArrowWrapper = styled.div``;

export const Counter = styled.div`
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.neutral["999"]};
`;

export const PaginatorContainer = styled(
  FlexContainer
)<PaginatorContainerProps>`
  flex-wrap: wrap;
  width: calc(100% - 7px);
  height: 60px;
  margin-left: 7px;
  align-items: center;
  justify-content: center;
  margin: 15px 0;

  @media ${theme.device.desktopFirst.mobileL} {
    width: 100%;
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      ${PageChangeButton} {
        background-color: ${theme.colors.neutral["670"]};

        &:hover {
          svg > path {
            fill: ${theme.colors.neutral.white};
          }
        }
      }

      ${Page} {
        color: ${theme.colors.neutral["88"]};
        background-color: ${theme.colors.neutral["670"]};

        &.page-number-selected {
          color: ${theme.colors.neutral["white-075"]};
          background-color: ${theme.colors.primary["200"]};
        }
      }
    `}
`;
