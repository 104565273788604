/* eslint-disable class-methods-use-this */
import { IEncryptionClient, IWebStorage } from "app-domain";

class SecuredSessionStorage implements IWebStorage {
  encryptAgent: IEncryptionClient;

  constructor(encryptAgent: IEncryptionClient) {
    this.encryptAgent = encryptAgent;
  }

  getItem(key: string): string | null {
    const encryptedKey = this.encryptAgent.encrypt(key);
    if (!encryptedKey) return null;

    const encryptedValue = sessionStorage.getItem(encryptedKey);
    if (!encryptedValue) return null;

    return this.encryptAgent.decrypt(encryptedValue);
  }

  setItem(key: string, value: string): void {
    const encryptedKey = this.encryptAgent.encrypt(key);
    const encryptedValue = this.encryptAgent.encrypt(value);

    return sessionStorage.setItem(encryptedKey, encryptedValue);
  }

  removeItem(key: string): void {
    const encryptedKey = this.encryptAgent.encrypt(key);
    return sessionStorage.removeItem(encryptedKey);
  }
}

export { SecuredSessionStorage };
