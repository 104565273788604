import { createStore } from "../adapter";

type ChatbotState = {
  showChatbot: boolean;
  showSaleChatbot: boolean;
};

type ChatbotDispatch = {
  setShowChatbot: (showChatbot: boolean) => void;
  setShowSaleChatbot: (showSaleChatbot: boolean) => void;
};

type ChatbotProps = ChatbotState & ChatbotDispatch;

const useChatbot = createStore<ChatbotProps>((set) => ({
  showChatbot: false,
  setShowChatbot: (value) => {
    set({ showChatbot: value });
  },
  showSaleChatbot: false,
  setShowSaleChatbot: (value) => {
    set({ showSaleChatbot: value });
  },
}));

export { useChatbot };
