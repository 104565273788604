import { appMonitoringClient } from "implementations";
import { memo } from "react";
import {
  CarouselSubComponentDto,
  CustomBannerDto,
  CustomProductDto,
} from "typing";
import { CustomBanner } from "../../CustomBanner";
import { CustomProduct } from "../../CustomProduct";
import { customCarouselSubComponentData } from "./data";

const CarouselSubComponent = memo(
  ({
    properties,
    isinsidecarouselingridformat = false,
    ...restProps
  }: CarouselSubComponentDto) => {
    let { bannerType } = customCarouselSubComponentData(properties);

    if (!bannerType) {
      appMonitoringClient.captureMessage(
        'Um item de um carrosel não tem um tipo definido. Desse modo, o tipo "banner" será assumido',
        "warning"
      );
      bannerType = "banner";
    }

    const componentProps = {
      properties,
      ...restProps,
    };

    if (bannerType === "banner") {
      return (
        <CustomBanner
          {...(componentProps as CustomBannerDto)}
          isBannerComponent={false}
          isinsidecarousel
          isinsidecarouselingridformat={isinsidecarouselingridformat}
        />
      );
    }

    if (bannerType === "dynamicBanner") {
      return (
        <CustomProduct
          {...(componentProps as CustomProductDto)}
          isinsidecarousel
        />
      );
    }

    return null;
  }
);

CarouselSubComponent.displayName = "CarouselSubComponent";

export { CarouselSubComponent };
