import styled from "styled-components";
import { theme } from "ui";

type MouseOverProps = {
  color?: string;
  fontSize?: string;
};

export interface StyledLinkProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  textDecoration?: string;
  margin?: string;
  mouseOver?: MouseOverProps;
  backgroundColor?: string;
  width?: string;
  letterSpacing?: string;
}

export const StyledLink = styled.a<StyledLinkProps>`
  margin-right: 0 !important;
  width: ${({ width }) => width || "initial"};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  color: ${({ color }) => color || theme.colors.neutral["520"]};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x5};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ mouseOver }) =>
    mouseOver &&
    `&:hover {
    color: ${mouseOver?.color || theme.colors.neutral["999"]} !important;

  }`};
`;
