import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ResumeOrder = ({
  width = 18,
  height = 18,
  color = theme.colors.primary["855"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill={secondColor} />
      <path
        d="M5 20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V9H14.5C13.9477 9 13.5 8.55228 13.5 8V3H6C5.44772 3 5 3.44772 5 4V20Z"
        fill={color}
      />
      <path
        d="M14.5 3V7C14.5 7.55228 14.9477 8 15.5 8H19L14.5 3Z"
        fill={color}
      />
    </svg>
  );
};
