import { IconProps } from "typing";
import { theme } from "../themes";

export const ClientIcon = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["760"],
  alt = "ícone de cliente",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 16.6667V16.0417C16.6668 14.0857 15.0812 12.5 13.1252 12.5H6.87516C4.91915 12.5 3.3335 14.0857 3.3335 16.0417V16.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="10.0003"
        cy="5.83333"
        rx="3.33333"
        ry="3.33333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
