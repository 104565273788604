import styled from "styled-components";
import { theme, Title } from "ui";
import { Link } from "../../../../../Link";

export const ListCategoriesInnerWrapper = styled.section`
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  height: fit-content;

  @media (max-width: 1240px) {
    gap: 10px 80px;
    align-content: flex-start;
    flex-wrap: wrap !important;
    overflow: auto !important;
    margin-left: 0 !important;
  }
`;

export const ListCategoryInnerContainer = styled.div`
  width: 33.333%;
  display: flex;
  flex-direction: column;
  white-space: initial;
  margin: 0 0 2rem 0 !important;

  // &:nth-of-type(3n + 1) {
  //   order: 1;
  // }
  // &:nth-of-type(3n + 2) {
  //   order: 2;
  // }
  // &:nth-of-type(3n) {
  //   order: 3;
  // }

  @media (max-width: 1240px) {
    /* max-width: 40%; */
    width: 100%;
    margin: 0 0 1rem 0 !important;

    a {
      white-space: pre-wrap;
    }
  }
`;

export const SubCategory = styled.div``;

export const SubCategoryTitleLink = styled(Link)`
  display: flex;
  cursor: pointer;
  font-weight: bold;
  background: transparent !important;
  padding: 0 !important;
  margin-bottom: 1rem;

  &:hover {
    color: none;
    background: transparent !important;
  }
`;

export const SubCategoryTitle = styled(Title).attrs({
  fontSize: theme.typography.fontSize.sm.x7,
})``;

export const InnerItemsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0;
  height: auto !important;

  @media (max-width: 1240px) {
    flex-direction: column !important;
    flex-flow: wrap !important;
    margin: 0 !important;
  }
`;

export const InnerItemTitleLink = styled(Link)`
  cursor: pointer;
  color: ${theme.colors.neutral["520"]};
  background: transparent !important;
  transition: all 0.2s ease-in;
  padding: 0.4rem 0.65rem 0.4rem 0;
  line-height: 27px;
  padding: 0 !important;

  &:hover {
    color: ${theme.colors.primary["100"]} !important;
    background: transparent !important;
  }

  @media (max-width: 1240px) {
    background-color: ${theme.colors.neutral["30"]} !important;
    border-radius: 100px !important;
    padding: 0px 10px !important;
    margin: 0 10px 10px 0;
    font-weight: 400;

    &:hover {
      color: ${theme.colors.neutral["520"]};
      background-color: ${theme.colors.neutral["30"]} !important;
    }
  }
`;
