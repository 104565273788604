import { IconProps } from "typing";
import { theme } from "../themes";

export const FacebookIconFooter = ({
  alt = "Ícone do  Facebook",
  width = 28,
  height = 28,
  color = theme.colors.neutral["280"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M17 3H7C4.791 3 3 4.791 3 7V17C3 19.209 4.791 21 7 21H12.621V14.039H10.278V11.314H12.621V9.309C12.621 6.985 14.042 5.718 16.116 5.718C16.815 5.716 17.513 5.752 18.208 5.823V8.253H16.78C15.65 8.253 15.43 8.787 15.43 9.575V11.31H18.13L17.779 14.035H15.414V21H17C19.209 21 21 19.209 21 17V7C21 4.791 19.209 3 17 3Z"
        fill={color}
      />
    </svg>
  );
};
