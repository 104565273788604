import { IconProps } from "typing";
import { theme } from "../themes";

export const CenteredUserIcon = ({
  width = 36,
  height = 36,
  color = theme.colors.neutral["999"],
  strokeWidth = "1.25",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.0001"
        cy="16.0001"
        r="12.005"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8164 22.0025H11.183C10.7738 22.0018 10.4421 21.6701 10.4414 21.2609V20.6593C10.4465 18.9473 11.8334 17.561 13.5454 17.5566H18.4541C20.166 17.561 21.5529 18.9473 21.558 20.6593V21.2609C21.5573 21.6701 21.2257 22.0018 20.8164 22.0025Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0003 14.6656C14.3443 14.6656 13.0012 13.3243 12.999 11.6683C12.9968 10.0124 14.3363 8.66752 15.9923 8.6631C17.6483 8.6587 18.9949 9.99638 19.0015 11.6523C19.004 12.4502 18.689 13.2162 18.1259 13.7815C17.5629 14.3468 16.7981 14.6649 16.0003 14.6656V14.6656Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
