import { buildTestIds } from "application";
import {
  CustomCountdownHeader,
  CustomCountdownTitle,
  CustomCountdownSubTitle,
} from "../styles";

interface RenderCountdownHeaderProps {
  showTitle: "true" | "false";
  showSubTitle: "true" | "false";
  format?: "small" | "big";
  title?: string;
  subTitle?: string;
  subTitleColor?: string;
  titleColor?: string;
}

export const RenderCountdownHeader = ({
  showTitle,
  showSubTitle,
  format,
  title,
  subTitle,
  subTitleColor,
  titleColor,
}: RenderCountdownHeaderProps) => {
  const formatSmall = format === "small";
  const incompleteHeader = showTitle === "false" && showSubTitle === "false";
  const showSubTitleFalse = showSubTitle === "false";
  const showTitleFalse = showTitle === "false";

  if (incompleteHeader || formatSmall) return null;

  if (showSubTitleFalse) {
    return (
      <CustomCountdownHeader
        {...buildTestIds("countdown-header-container-only-title")}
      >
        <CustomCountdownTitle
          {...buildTestIds("countdown-header-only-title")}
          color={titleColor}
        >
          {title}
        </CustomCountdownTitle>
      </CustomCountdownHeader>
    );
  }

  if (showTitleFalse) {
    return (
      <CustomCountdownHeader
        {...buildTestIds("countdown-header-container-only-subTitle")}
      >
        <CustomCountdownSubTitle
          {...buildTestIds("countdown-header-only-subtitle")}
          color={subTitleColor}
        >
          {subTitle}
        </CustomCountdownSubTitle>
      </CustomCountdownHeader>
    );
  }

  return (
    <CustomCountdownHeader
      {...buildTestIds("countdown-header-container-complete")}
    >
      <CustomCountdownTitle
        isSmall={formatSmall}
        {...buildTestIds("countdown-header-title")}
        color={titleColor}
      >
        {title}
      </CustomCountdownTitle>
      <CustomCountdownSubTitle
        {...buildTestIds("countdown-header-subtitle")}
        color={subTitleColor}
      >
        {subTitle}
      </CustomCountdownSubTitle>
    </CustomCountdownHeader>
  );
};
