import { buildTestIds } from "application";
import { appMonitoringClient } from "implementations";
import { memo } from "react";
import {
  ComponentDetails,
  CustomDynamicBannerDto,
  CustomProductDto,
  SliderProps,
} from "typing";
import { theme } from "ui";
import { Slider } from "../../Slider";
import { CustomProduct } from "../CustomProduct";
import { customDynamicBannerData } from "./data";
import {
  CustomDynamicBannerContainer,
  CustomDynamicBannerHeader,
  CustomDynamicBannerSlider,
  CustomDynamicBannerSubTitle,
  CustomDynamicBannerTitle,
} from "./style";

const getTextAlignment = (
  textAlign?: "left" | "center" | "right"
): "flex-start" | "center" | "flex-end" => {
  switch (textAlign) {
    case "left":
      return "flex-start";
    case "center":
      return "center";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};

const CustomDynamicBanner = memo(
  ({
    properties,
    subComponents,
    isClientMobile,
    isClientTablet,
    isClientLaptop,
    isBlackTheme,
  }: CustomDynamicBannerDto) => {
    const { title, textAlign, showTitle, showSubTitle, subTitle } =
      customDynamicBannerData(properties);

    const titleTextAlign = getTextAlignment(textAlign);

    if (!subComponents?.length) {
      appMonitoringClient.captureMessage(
        "Um componente de banner dinâmico não possui subcomponentes, ou seja, não existem produtos a serem mostrados",
        "warning"
      );
      return null;
    }

    const details = {
      isClientMobile,
      isClientTablet,
    };

    const settings: SliderProps = {
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
      swipe: true,
      dots: true,
      autoplay: true,
      infinite: true,
      arrowsIsActive: !isClientLaptop,
    };

    const conditionForTitle = showTitle && showTitle === "true";
    const conditionForSubTitle = showSubTitle && showSubTitle === "true";

    return (
      <CustomDynamicBannerContainer
        {...buildTestIds("custom-dynamic-banner-container")}
      >
        <CustomDynamicBannerHeader
          {...buildTestIds("custom-dynamic-banner-header")}
        >
          {conditionForTitle && (
            <CustomDynamicBannerTitle
              {...buildTestIds("custom-dynamic-banner-title")}
              alignSelf={titleTextAlign}
              isBlackTheme={isBlackTheme}
              color={theme.colors.neutral["520"]}
              fontWeight={theme.typography.fontWeight[400]}
            >
              {title}
            </CustomDynamicBannerTitle>
          )}
          {conditionForSubTitle && (
            <CustomDynamicBannerSubTitle
              {...buildTestIds("custom-dynamic-banner-subtitle")}
              isBlackTheme={isBlackTheme}
              alignSelf={titleTextAlign}
            >
              {subTitle}
            </CustomDynamicBannerSubTitle>
          )}
        </CustomDynamicBannerHeader>

        {subComponents?.length === 1 ? (
          <CustomProduct
            {...(subComponents[0] as CustomProductDto)}
            {...(details as ComponentDetails)}
            priority
          />
        ) : (
          <CustomDynamicBannerSlider
            {...buildTestIds("custom-dynamic-banner-slider")}
          >
            <Slider {...settings}>
              {subComponents?.map((product, index) => {
                const subComponentKey = `${product?.displayName}#${product?.type}#${index}`;

                return (
                  <CustomProduct
                    key={subComponentKey}
                    {...(product as CustomProductDto)}
                    {...(details as ComponentDetails)}
                    priority
                  />
                );
              })}
            </Slider>
          </CustomDynamicBannerSlider>
        )}
      </CustomDynamicBannerContainer>
    );
  }
);

CustomDynamicBanner.displayName = "CustomDynamicBanner";

export { CustomDynamicBanner };
