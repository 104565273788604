import { IconProps } from "typing";
import { theme } from "../themes";

export const Camera = ({
  width = "61",
  height = "61",
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 61 61"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0722 11.6554L39.9222 14.5054C40.3989 14.9821 41.0454 15.2498 41.7195 15.2498H48.2917C51.0991 15.2498 53.375 17.5257 53.375 20.3332V43.2082C53.375 46.0156 51.0991 48.2915 48.2917 48.2915H12.7083C9.90089 48.2915 7.625 46.0156 7.625 43.2082V20.3332C7.625 17.5257 9.90089 15.2498 12.7083 15.2498H19.2806C19.9546 15.2498 20.6011 14.9821 21.0778 14.5054L23.9278 11.6554C24.8811 10.7021 26.1741 10.1665 27.5223 10.1665H33.4777C34.8259 10.1665 36.1189 10.7021 37.0722 11.6554Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="11.5" y="10.5" width="9" height="5" rx="1.5" stroke={color} />
      <rect
        x="12.5"
        y="17.5"
        width="6"
        height="2"
        rx="1"
        fill={color}
        stroke={color}
      />
      <rect
        x="43.5"
        y="17.5"
        width="6"
        height="2"
        rx="1"
        fill={color}
        stroke={color}
      />
      <circle
        cx="30.4999"
        cy="30.5002"
        r="10.1667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6875 30.5C26.6875 28.3944 28.3944 26.6875 30.5 26.6875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
