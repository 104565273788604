import { IUniqueClientComponent } from "typing";
import { UniqueClientModal } from "../UniqueClientModal";
import { UniqueClientValidation } from "../UniqueClientValidation";

const UniqueClientComponentUI = ({
  currentStep,
  isMobile,
}: IUniqueClientComponent) => {
  if (
    [
      "verifyIdentity",
      "chooseAnotherMethod",
      "feedbackError",
      "feedbackSuccess",
    ].includes(currentStep) &&
    !isMobile
  ) {
    return <UniqueClientValidation />;
  }

  return <UniqueClientModal />;
};

export { UniqueClientComponentUI };
