import {
  CardCTASubComponentProperty,
  Color,
  CtaText,
  Image,
  ShowCta,
  ShowSubTitle,
  ShowTitle,
  Subtitle,
  Text,
  Title,
} from "typing";

const customCardCTASubComponentData = (
  properties: CardCTASubComponentProperty[]
) => {
  const showTitle = (
    properties.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const showSubTitle = (
    properties.find(({ key }) => key === "showSubTitle") as ShowSubTitle
  )?.value;

  const subTitle = (
    properties.find(({ key }) => key === "subTitle") as Subtitle
  )?.value;

  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const text = (properties.find(({ key }) => key === "text") as Text)?.value;

  const showCta = (properties.find(({ key }) => key === "showCta") as ShowCta)
    ?.value;

  const ctaText = (properties.find(({ key }) => key === "ctaText") as CtaText)
    ?.value;

  const color = (properties.find(({ key }) => key === "color") as Color)?.value;

  const image = (properties.find(({ key }) => key === "image") as Image)?.value;

  return {
    showCta,
    showTitle,
    title,
    text,
    ctaText,
    color,
    image,
    showSubTitle,
    subTitle,
  };
};

export { customCardCTASubComponentData };
