import { IconProps } from "typing";
import { theme } from "../themes";

export const ChangeAddressIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
  alt = "mudar endereço",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 6H12C16.142 6 19.5 9.358 19.5 13.5C19.5 17.642 16.142 21 12 21C7.858 21 4.5 17.642 4.5 13.5C4.5 12.26 4.801 11.09 5.334 10.06"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 3L8.5 6L11.5 9"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
