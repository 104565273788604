import { IconProps } from "typing";
import { theme } from "../themes";

export const FCCredit = ({
  width = 24,
  height = 25,
  color = theme.colors.neutral["550"],
  alt = "crédito fc",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.4783" r="9.25" stroke={color} strokeWidth="1.5" />
      <path
        d="M7.33301 8.92754H7.70481C7.9076 8.92754 7.97523 8.99516 7.97523 9.19795V14.0996H9.42887V11.5981H11.5247V10.4149H9.42887V8.92754H11.9637V7.71063H7.33301V8.92754Z"
        fill={color}
      />
      <path
        d="M12.7853 14.0518C12.7853 12.8349 13.5966 12.1588 14.6448 12.1588C15.3546 12.1588 15.9293 12.3616 16.5377 12.7672V11.4826C15.9129 11.0805 15.1846 10.8691 14.4416 10.8742C12.5486 10.8742 11.2979 12.1926 11.2979 14.0855C11.2979 15.9785 12.6162 17.2631 14.543 17.2631C15.2915 17.2731 16.0244 17.0485 16.6388 16.6208V15.3024C15.9628 15.7419 15.3205 15.9448 14.6444 15.9448C13.5965 15.911 12.7853 15.2349 12.7853 14.0518Z"
        fill={color}
      />
    </svg>
  );
};
