import { buildTestIds } from "application";
import { InputCode } from "./InputCode";
import { InputCodeValidationContainer } from "./styles";

interface InputBoxValidationProps {
  height?: string;
  width?: string;
  bottomSubtitle?: boolean;
  noTopSubtitle?: boolean;
  flexDirection?: string;
  numberType: 1 | 2 | 3;
  typeItem?: "ResendCodeComponent" | "SendEmailCode";
}

const InputBoxValidation = ({
  height,
  width,
  numberType,
  typeItem,
  flexDirection,
  bottomSubtitle,
  noTopSubtitle,
}: InputBoxValidationProps) => {
  return (
    <InputCodeValidationContainer
      {...buildTestIds("input-code-validation-container")}
      flexDirection={flexDirection}
    >
      <InputCode
        height={height}
        width={width}
        length={6}
        numberType={numberType}
        typeItem={typeItem}
        noTopSubtitle={noTopSubtitle}
        bottomSubtitle={bottomSubtitle}
      />
    </InputCodeValidationContainer>
  );
};

export { InputBoxValidation };
