import { IconProps } from "typing";
import { theme } from "../themes";

export const SecurityLockIcon = ({
  width = 22,
  height = 22,
  color = theme.colors.neutral["760"],
  alt = "ícone de cadeado",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.16406"
        y="9.33203"
        width="11.6715"
        height="9.17049"
        rx="3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9998 16.0033V14.3359"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5007 16.0025V14.3351C13.5009 13.3235 12.8915 12.4115 11.9569 12.0244C11.0224 11.6373 9.9466 11.8514 9.23145 12.5669"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49887 15.168V16.0016"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66504 9.33381V6.83277V6.83277C7.66504 4.99105 9.15804 3.49805 10.9998 3.49805V3.49805C12.8415 3.49805 14.3345 4.99105 14.3345 6.83277V6.83277V9.33381"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
