import { ICookie } from "app-domain";
import { IClientIp } from "application";
import requestIp, { Request } from "request-ip";

class RequestIp implements IClientIp {
  public constructor(private cookie: ICookie) {
    this.cookie = cookie;
  }

  getClientIp = () => {
    return this.cookie.getCookie({
      name: "@FC:Ecom:Client:Ip",
    });
  };

  storageClientIp = <T = Request>(req: T, res: T) => {
    const ip = requestIp.getClientIp(req as Request);
    if (ip) {
      this.cookie.setCookie({
        name: "@FC:Ecom:Client:Ip",
        value: ip,
        options: { req, res },
      });
    }
  };
}

export { RequestIp };
