import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const TrackingStatusPickingAvailable = ({
  width = 36,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Pode vir buscar",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M17.9999 18.4793V17.6459C18.0122 16.9792 17.7527 16.3362 17.2812 15.8647C16.8097 15.3932 16.1666 15.1337 15.4999 15.1459H12.9999C12.3332 15.1337 11.6902 15.3932 11.2187 15.8647C10.7472 16.3362 10.4877 16.9792 10.4999 17.6459V18.4793"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8335 10.1667H23.8335C24.754 10.1667 25.5002 10.9129 25.5002 11.8334V16.0001C25.5002 16.9206 24.754 17.6667 23.8335 17.6667H20.5002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="14.2152"
        cy="10.5833"
        r="2.08333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1667 12.6667H20.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1599 20.1667H13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0002 20.1667V22.2968"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3174 20.1667C13.822 21.1085 14.5877 21.8847 15.5225 22.402C16.808 23.1093 18.3236 23.271 19.7294 22.8507C21.1351 22.4305 22.3134 21.4635 22.9998 20.1667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
