import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const SuccessIcon = ({
  alt = "ícone de sucesso",
  color = theme.colors.secondary["230"],
  secondColor = theme.colors.neutral["680"],
  height = 25,
  width = 25,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.238281" y="0.5" width="24" height="24" rx="8" fill={color} />
      <path
        d="M17.5719 8.83398L10.2386 16.1673L6.90527 12.834"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
