import styled, { css } from "styled-components";
import { theme } from "ui";
import { Container } from "./SubComponents/styles";

interface TextAlignProps {
  textAlign?: "center" | "left" | "right";
}

interface CustomImagesGridContainerProps {
  customTheme?: boolean;
}

interface GridProps {
  gridType?: string;
  orientation?: string;
}

const double = css`
  grid-template-areas: "a b";
`;

const doubleVertical = css`
  grid-template-areas:
    "a"
    "b";
`;

const triple = css`
  grid-template-areas: "a b c";
`;

const tripleVertical = css`
  grid-template-areas:
    "a"
    "b"
    "c";
`;

const tripleDown = css`
  grid-template-areas:
    "a a"
    "b c";
`;

const tripleUp = css`
  grid-template-areas:
    "a b"
    "c c";
`;

const quadruple = css`
  grid-template-areas:
    "a b"
    "c d";
`;

const quintupleDown = css`
  grid-template-areas:
    "a a a b b b"
    "c c d d e e";
`;

const quintupleUp = css`
  grid-template-areas:
    "a a b b c c"
    "d d d e e e";
`;

const sextuple = css`
  grid-template-areas:
    "a b c"
    "d e f";
`;

const gridArea = (gridType?: string, orientation?: string) => {
  if (orientation && orientation === "vertical") {
    return gridType === "triple" ? tripleVertical : doubleVertical;
  }
  switch (gridType) {
    case "triple":
      return triple;
    case "tripleDown":
      return tripleDown;
    case "tripleUp":
      return tripleUp;
    case "quadruple":
      return quadruple;
    case "quintupleDown":
      return quintupleDown;
    case "quintupleUp":
      return quintupleUp;
    case "sextuple":
      return sextuple;
    default:
      return double;
  }
};

export const CustomImagesGridHeader = styled.div<TextAlignProps>`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x1};
  margin-bottom: ${theme.space.x7};
  text-align: ${({ textAlign }) => textAlign || "left"};

  @media ${theme.device.desktopFirst.tablet} {
    margin-bottom: ${theme.space.x4};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-bottom: ${theme.space.x4};
  }
`;

export const CustomImagesGridTitle = styled.h2<TextAlignProps>`
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${theme.typography.fontSize.lg.x1};
  color: ${theme.colors.neutral["520"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.md.x2};
  }
`;

export const CustomImagesGridSubtitle = styled.h4<TextAlignProps>`
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${theme.typography.fontSize.sm.x9};
  color: ${theme.colors.neutral["330"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x7};
  }
`;

export const CustomImagesGridGrid = styled.section<GridProps>`
  display: grid;
  gap: ${theme.space["x4.5"]};

  ${({ gridType, orientation }) => gridArea(gridType, orientation)};

  & > ${Container}:nth-child(1) {
    grid-area: a;
    display: ${({ gridType }) => (gridType ? "block" : "none")};
  }
  & > ${Container}:nth-child(2) {
    grid-area: b;
    display: ${({ gridType }) => (gridType ? "block" : "none")};
  }
  & > ${Container}:nth-child(3) {
    grid-area: c;
    display: ${({ gridType }) =>
      gridType && gridType !== "double" && gridType !== undefined
        ? "block"
        : "none"};
  }
  & > ${Container}:nth-child(4) {
    grid-area: d;
    display: ${({ gridType }) =>
      gridType &&
      gridType !== "double" &&
      gridType !== "triple" &&
      gridType !== "tripleDown" &&
      gridType !== "tripleUp"
        ? "block"
        : "none"};
  }
  & > ${Container}:nth-child(5) {
    grid-area: e;
    display: ${({ gridType }) =>
      (gridType && gridType === "quintupleUp") ||
      gridType === "quintupleDown" ||
      gridType === "sextuple"
        ? "block"
        : "none"};
  }
  & > ${Container}:nth-child(6) {
    grid-area: f;
    display: ${({ gridType }) =>
      gridType && gridType === "sextuple" ? "block" : "none"};
  }

  @media ${theme.device.desktopFirst.tablet} {
    gap: ${theme.space.x2};
  }
`;

export const CustomImagesGridContainer = styled.section<CustomImagesGridContainerProps>`
  font-weight: ${theme.typography.fontWeight[400]};
  font-family: ${theme.typography.fontFamily.chivo};
  max-width: 1280px;
  margin: ${theme.space.x0} auto;
  width: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    width: 94vw;
    padding: ${theme.space.x0};
  }

  ${({ customTheme }) =>
    customTheme &&
    css`
      ${CustomImagesGridTitle}, ${CustomImagesGridSubtitle} {
        color: ${theme.colors.neutral.white};
      }
    `}
`;
