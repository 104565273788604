import { IAppMonitoringClient } from "app-domain";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  CardsDto,
  CreateCreditCardDto,
  ErrorDto,
  HttpResponseErrorDto,
} from "typing";
import { PaymentApi } from "../services";
import { useAuth } from "./Identity/AuthContext";
import { useToast } from "../hooks/Toast";

type CardsResponseProps = {
  cards: CardsDto[] | null | undefined;
  error: unknown;
  isLoading: boolean;
};

interface CardsContextData {
  cardsResponse: CardsResponseProps;
  createCreditCard: (
    item: CreateCreditCardDto,
    isShouldTrigger?: boolean
  ) => Promise<number>;
  deleteCreditCard: (id: number) => Promise<void>;
  isCreatingCreditCard: boolean;
}

export interface CardsProviderProps {
  children: ReactNode;
  paymentApi: PaymentApi;
  appMonitoringClient: IAppMonitoringClient;
}

const CardsContext = createContext({} as CardsContextData);

export const CardsProvider = ({
  children,
  paymentApi,
  appMonitoringClient,
}: CardsProviderProps) => {
  const { getTokens } = useAuth();
  const { addToast } = useToast();
  const accessToken = getTokens()?.token;
  const [, setHadFetchCreateCreditCard] = useState<boolean>(false);
  const [isCreatingCreditCard, setIsCreatingCreditCard] = useState(false);
  const [setIsCreateCardOpen] = useState(false);

  const router = useRouter();

  const allowedRoutesToGetCards = useMemo(() => {
    return [
      "/conta/meus-cartoes",
      "/checkout/pagamento",
      "/checkout/pagamento/[groupId]",
    ];
  }, []);

  const {
    data: cards,
    error,
    isLoading,
    trigger: triggerGetCards,
  } = paymentApi.getCards(
    accessToken as unknown as string,
    appMonitoringClient,
    !!accessToken && allowedRoutesToGetCards?.includes(router.pathname)
  );

  const cardsResponse = useMemo(() => {
    return { cards, error, isLoading };
  }, [cards, error, isLoading]);

  const onManagementStateToRenderSetCards = () => {
    setHadFetchCreateCreditCard((state) => !state);
  };

  const createCreditCard = useCallback(
    async (item: CreateCreditCardDto, isShouldTrigger = true) => {
      setIsCreatingCreditCard(true);

      if (item.expYear.length === 2) {
        // eslint-disable-next-line no-param-reassign
        item.expYear = `20${item.expYear}`;
      }

      const card = await paymentApi.createCard(
        {
          ...item,
          ...(item.expYear.length === 2 && { expYear: `20${item.expYear}` }),
        },
        getTokens()?.token as unknown as string
      );

      setIsCreatingCreditCard(false);

      const { cardIdFC } = card as unknown as { cardIdFC: number };

      const createCardResponseErrorMessage = (card as unknown as ErrorDto)
        ?.errors?.Messages?.[0];

      const createCardResponseAnotherErrorMessage = (
        card as unknown as HttpResponseErrorDto
      )?.errors?.[0]?.errorMessage;

      const errorMessage =
        createCardResponseErrorMessage || createCardResponseAnotherErrorMessage;

      if (!cardIdFC || errorMessage) {
        addToast({
          title: errorMessage || "Algo deu errado ao criar o cartão",
          showProgressBar: true,
          timerInMilliseconds: 5000,
          type: "error",
        });
        return 0;
      }

      if (isShouldTrigger && triggerGetCards) {
        triggerGetCards();
      }

      onManagementStateToRenderSetCards();

      if (cardIdFC) {
        addToast({
          title: "Cartão adicionado com sucesso!",
          description: "Seu cartão foi registrado com sucesso.",
          type: "success",
          isNewToast: true,
          newToastTheme: "light",
          timerInMilliseconds: 3000,
        });
      }

      return cardIdFC as number;
    },
    [paymentApi, getTokens, triggerGetCards, addToast]
  );

  const deleteCreditCard = useCallback(
    async (id: number) => {
      const message = "Você tem certeza que deseja excluir este cartão?";

      if (
        (window as { confirm: (passedMessage: string) => boolean })?.confirm(
          message
        )
      ) {
        const responseDelete = await paymentApi.deleteCard(
          id,
          accessToken as string
        );
        if (triggerGetCards) {
          triggerGetCards();
        }
        if (responseDelete) {
          addToast({
            title: "Cartão deletado com sucesso!",
            type: "success",
          });
        }
      }
    },
    [paymentApi, accessToken, triggerGetCards, addToast]
  );

  const cardsProviderValues = useMemo(() => {
    return {
      cardsResponse,
      createCreditCard,
      deleteCreditCard,
      isCreatingCreditCard,
      setIsCreateCardOpen,
    };
  }, [
    cardsResponse,
    createCreditCard,
    deleteCreditCard,
    isCreatingCreditCard,
    setIsCreateCardOpen,
  ]);

  return (
    <CardsContext.Provider value={cardsProviderValues}>
      {children}
    </CardsContext.Provider>
  );
};

export const useCards = (): CardsContextData => {
  const context = useContext(CardsContext);

  if (!context) {
    throw new Error("useCards must be used within CardsProvider");
  }

  return context;
};
