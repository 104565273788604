import styled from "styled-components";
import { Row, theme } from "ui";
import { IFormValues } from "typing";
import {
  Path,
  UseFormRegister,
  UseFormSetValue,
  ValidationRule,
} from "react-hook-form";
import { ChangeEvent } from "react";
import { Input, Label, Checkbox } from "../Form";
import { ButtonSubmit } from "../ButtonSubmit";
import { Loading } from "../Loading";
import { Link } from "../Link";

interface BottomSheetProps {
  isdisplayedonbottomsheet?: boolean;
}

interface WrapperRowProps extends BottomSheetProps {
  hasTwoColumns?: boolean;
  isFlex?: boolean;
}

interface ButtonDefaultFormProps {
  disabled: boolean;
  purchaseScreen: boolean;
}

interface CheckoutPageProps {
  isCheckoutPage?: boolean;
}

interface StateSelectProps {
  hasError?: boolean;
  hasSuccessfully?: boolean;
  disabled?: boolean;
}
interface CustomInputNewAddressProps extends BottomSheetProps {
  size?: "large" | "medium" | "small";
  id: string;
  name: string;
  mask?: string;
  maskChar?: string;
  label?: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  setValue?: UseFormSetValue<IFormValues>;
  required?: string;
  pattern?: ValidationRule<RegExp>;
  minLength?: ValidationRule<number>;
  maxLength?: ValidationRule<number>;
  type?: string;
  placeholder?: string;
  value?: string | number | string[];
  hasError?: boolean | string | number | undefined;
  textError?: string;
  textInfo?: string;
  hasSuccessfully?: boolean;
  disabled?: boolean;
  width?: string;
  padding?: string;
  max?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onKeyUp?: (event: React.FormEvent<HTMLInputElement>) => void;
}

const sizeInput = {
  large: "24.125rem",
  medium: "15.8125rem",
  small: "11.325rem",
};

export const IconLoading = styled(Loading)`
  margin-right: 1rem;
`;

export const ExtraInfoCEP = styled.section<BottomSheetProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: ${({ isdisplayedonbottomsheet }) =>
    isdisplayedonbottomsheet ? "0" : "5rem"};
  gap: 0.5rem;
  flex-direction: ${({ isdisplayedonbottomsheet }) =>
    isdisplayedonbottomsheet ? "row-reverse" : "row"};
  @media ${theme.device.desktopFirst.mobileXL} {
    width: 100%;
    grid-template-rows: 1fr;
    place-items: center;
  }
`;

export const DescriptionText = styled.span<BottomSheetProps>`
  font-size: 15px;
  color: ${theme.colors.secondary["250"]};
  display: flex;
  align-items: center;
  font-family: ${theme.typography.fontFamily.barlow};

  @media ${theme.device.desktopFirst.laptopL} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }
`;
export const BoxButtonDefault = styled.section`
  display: flex;
  margin-bottom: 4rem;
`;

export const ButtonDefaultForm = styled.button<ButtonDefaultFormProps>`
  font-size: 1.3rem;
  font-weight: 500;
  background-color: ${({ purchaseScreen }) =>
    purchaseScreen
      ? theme.colors.secondary["360"]
      : theme.colors.secondary["300"]};
  border: none;
  padding: 1rem 2.5rem;
  color: ${theme.colors.neutral.white};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ disabled, purchaseScreen }) =>
    !disabled &&
    `
      &:hover {
        background-color: ${
          purchaseScreen
            ? theme.colors.secondary["420"]
            : theme.colors.secondary["500"]
        };
      }
    `}
`;

export const FormNewAddressContainer = styled.form<CheckoutPageProps>`
  width: 100%;
  @media ${theme.device.desktopFirst.tablet} {
    height: ${({ isCheckoutPage }) => (isCheckoutPage ? "auto" : "39rem")};
    grid-template-rows: 1fr;
  }

  @media (max-width: 1440px) {
    grid-template-rows: 1fr;
  }
`;

export const SpaceMobileTop = styled.section`
  padding: 0.5rem;
`;

export const SpaceMobileBottom = styled.section`
  padding: 2rem;
`;
export const FormNewAddressContent = styled.section<CheckoutPageProps>`
  display: flex;
  align-items: center;
  max-width: 600px;
  justify-content: ${({ isCheckoutPage }) =>
    isCheckoutPage ? "initial" : "center"};
`;

export const WrapperContentHouse = styled.div<BottomSheetProps>`
  display: flex;
  gap: 2rem;

  @media ${theme.device.desktopFirst.mobileXL} {
    flex-direction: ${({ isdisplayedonbottomsheet }) =>
      isdisplayedonbottomsheet ? "row" : "column"};
    gap: 1.5rem;
  }
`;

export const AlignWrapperInput = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 1rem;

  @media ${theme.device.desktopFirst.mobileS} {
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 2.5rem;
  }

  @media ${theme.device.desktopFirst.tablet} {
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
`;

export const WrapperInput = styled(Row)<{ isFlex?: boolean }>`
  margin-bottom: 5px;
`;

export const WrapperSelect = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  margin: 0 0 1.5rem -1rem;
`;

export const WrapperRow = styled.section<WrapperRowProps>`
  display: flex;
  gap: ${({ isFlex }) => (isFlex ? "20px" : "2.5rem")};
  margin-bottom: 1rem;
  flex-direction: ${({ isFlex }) => (isFlex ? "column-reverse" : "row")};

  @media ${theme.device.desktopFirst.tablet} {
    flex-direction: ${({ isdisplayedonbottomsheet }) =>
      isdisplayedonbottomsheet ? "row" : "column"};
    gap: 0.8rem;
  }
`;

export const CheckDoneSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: absolute;
  right: 20%;
  margin-top: -1rem;
  @media ${theme.device.desktopFirst.mobileL} {
    width: 4rem;
    left: 7rem;
  }
`;

export const CustomLabelNewAddress = styled(Label)`
  font-weight: 400;
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
`;

export const CustomInputNewAddress = styled(Input)<CustomInputNewAddressProps>`
  width: ${({ size }) => sizeInput[size ?? "medium"]} !important;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${({ disabled }) =>
    disabled ? theme.colors.neutral["100"] : theme.colors.neutral.white};
  max-width: ${({ isdisplayedonbottomsheet }) =>
    isdisplayedonbottomsheet ? "184px" : "none"};

  @media ${theme.device.desktopFirst.mobileS} {
    max-width: ${({ isdisplayedonbottomsheet }) =>
      isdisplayedonbottomsheet ? "184px" : "100%"};
  }
`;

export const CustomCheckboxNewAddress = styled(Checkbox)`
  &:hover {
    border: ${`${theme.space.xpx} solid `};
    ${({ color }) => color ?? theme.colors.secondary["300"]};
  }

  &:checked {
    background-color: ${theme.colors.secondary["300"]} !important;
    border-color: ${theme.colors.secondary["300"]} !important;
  }
`;

export const StateSelect = styled.select<StateSelectProps>`
  font-size: 1.2rem;
  border: thin solid ${theme.colors.neutral["200"]};
  color: thin solid ${theme.colors.neutral["450"]};
  border-radius: 8px;
  padding: 1.2rem 0.9rem;
  width: 200px;
  height: 4.4rem;
  margin-top: 0.5rem;

  border-color: ${({ hasError }) =>
    hasError ? theme.colors.primary["300"] : ""};
  border-color: ${({ hasSuccessfully }) =>
    hasSuccessfully ? theme.colors.secondary["300"] : ""};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  background-color: ${({ disabled }) =>
    disabled ? theme.colors.neutral["100"] : theme.colors.neutral.white};
`;

export const ButtonRegisterAction = styled(ButtonSubmit).attrs({
  width: "53rem",
  alignSelf: "center",
  fontSize: theme.typography.fontSize.sm.x5,
  height: "4rem",
})`
  padding: 0 16px;
  transition: background-color 0.2s ease-in;
  align-self: center;
`;

export const BoxButton = styled.section`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  padding: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.neutral.white};
`;

export const OptionStates = styled.option``;

export const LinkCustom = styled(Link)`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;

  @media ${theme.device.desktopFirst.tablet} {
    padding-top: 0;
  }
`;

export const ContainerRequiredWarning = styled.div`
  display: flex;
  width: fit-content;
  margin-bottom: 3rem;
`;
