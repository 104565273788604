import { IResponsiveModalActions, IResponsiveModalContent } from "typing";
import { ResponsiveComponentUI } from "./ui";
import { InputProps } from "../../../Form/Input";

const ResponsiveComponent = ({
  ...props
}: IResponsiveModalActions & IResponsiveModalContent & InputProps) => {
  return <ResponsiveComponentUI {...props} />;
};

export { ResponsiveComponent };
