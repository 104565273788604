import styled, { css } from "styled-components";
import { theme } from "ui";

export const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 5rem;
  margin-left: 4.5rem;
  height: 3.835rem;
  border: none;
  border-radius: 4px;
  background-color: ${theme.colors.primary["350"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    width: 3.675rem;
    height: 3.085rem;
    margin-left: 1.5rem;
  }
`;

export const ImageContainer = styled.div`
  max-width: 50%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 4.5rem;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding-right: 1.5rem;
  }
`;

type HeaderContainerProps = {
  isBlackTheme: boolean;
  marginBottom?: string;
};

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  align-items: center;
  height: 7rem;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? "5.95rem"};
  background-color: ${theme.colors.neutral.white};

  @media ${theme.device.desktopFirst.tabletXM} {
    margin-bottom: 2rem;
    height: 5.175rem;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-bottom: ${({ marginBottom }) => marginBottom ?? "2rem"};
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      background-color: ${theme.colors.blackFriday["175"]};
    `}
`;

export const HeaderWrapper = styled.section`
  box-shadow: 0px 2px 4px 0px ${theme.colors.neutral["999-opacity-10"]};
`;
