import { useShallow, useTwoFactor } from "application/state-manager";
import { appMonitoringClient } from "implementations";

const usePasteText = () => {
  const { clipboardText, setClipboardText } = useTwoFactor(
    useShallow((state) => ({
      clipboardText: state.clipboardText,
      setClipboardText: state.setClipboardText,
    }))
  );

  const handlePasteText = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setClipboardText(text);
    } catch (err) {
      appMonitoringClient.captureException(err);
    }
  };

  return { clipboardText, handlePasteText };
};

export { usePasteText };
