import { IconProps } from "typing";
import { theme } from "../themes";

export const SettingsIcon = ({
  width = 16,
  height = 17,
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41421 3.75229C6.19526 4.53334 6.19526 5.79967 5.41421 6.58072C4.63316 7.36176 3.36683 7.36176 2.58579 6.58072C1.80474 5.79967 1.80474 4.53334 2.58579 3.75229C3.36683 2.97124 4.63316 2.97124 5.41421 3.75229"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 5.16634H6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4142 10.4188C14.1953 11.1998 14.1953 12.4662 13.4142 13.2472C12.6332 14.0283 11.3668 14.0283 10.5858 13.2472C9.80474 12.4662 9.80474 11.1998 10.5858 10.4188C11.3668 9.63775 12.6332 9.63775 13.4142 10.4188"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66699 11.8333H10.0003"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
