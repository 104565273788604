import { buildTestIds } from "application";
import { LegacyRef } from "react";
import { PrincipalInputProps } from "../../type";

export const PrincipalInput = (props: PrincipalInputProps) => {
  const { type, required, onBlur, onChange, value, id, ref, field } = props;

  return (
    <input
      {...field}
      {...props}
      type={type ?? "text"}
      onChange={(e) => {
        if (onChange) onChange(e);

        if (field) field.onChange(e);
      }}
      onBlur={(e) => {
        if (onBlur) onBlur(e);

        if (field) field.onBlur();
      }}
      required={Boolean(required)}
      ref={(ref as LegacyRef<HTMLInputElement>) || field.ref}
      value={value || field.value}
      {...buildTestIds(`form-input-${id}`)}
    />
  );
};
