import styled, { css } from "styled-components";
import { theme } from "ui";

interface ArrowPrevProps {
  hasPrev?: boolean;
}

export const ArrowPrev = styled.button<ArrowPrevProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: calc(50% - 25px);
  bottom: 0px;
  left: 64px;
  border-radius: 50%;
  border: none;
  outline: none;
  height: 60px;
  width: 60px;
  background-color: ${theme.colors.neutral.white} !important;
  opacity: 1 !important;
  box-shadow: 0 2px 2px 0 ${theme.colors.neutral["999-opacity-10"]};
  z-index: 5;
  -webkit-tap-highlight-color: transparent !important;

  &:hover {
    background-color: ${theme.colors.primary["200"]} !important;
    :before {
      content: "";
      width: 13px;
      height: 13px;
      border: 3px solid ${theme.colors.neutral.white};
      border-radius: 2px;
      display: block;
      position: absolute;
      top: 21px;
      left: 25px;
      border-top: 0;
      border-right: 0;
      transform: rotate(45deg);
      transition: all 0.3s ease-in-out;
    }
  }

  transition: background-color 0.2s;

  ${({ hasPrev }) =>
    !hasPrev &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};

  @media ${theme.device.desktopFirst.laptopL} {
    left: 16px;
  }

  :before {
    content: "";
    width: 13px;
    height: 13px;
    border: 3px solid ${theme.colors.neutral["450"]};
    border-radius: 2px;
    display: block;
    position: absolute;
    top: 21px;
    left: 25px;
    border-top: 0;
    border-right: 0;
    transform: rotate(45deg);
    transition: all 0.3s ease-in-out;
  }
`;
