import { buildTestIds } from "application";
import { ReactVideoPlayer } from "infra";
import { MediaPlayerProps } from "typing";

const MediaPlayer = ({
  playing = true,
  width = "100%",
  height = "100%",
  className = "react-media-player",
  ...mediaPlayProps
}: MediaPlayerProps) => {
  return (
    <ReactVideoPlayer
      {...buildTestIds("video-media-player")}
      width={width}
      height={height}
      playing={playing}
      className={className}
      {...mediaPlayProps}
    />
  );
};

export { MediaPlayer };
