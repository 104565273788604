type ValidateEmailResponse =
  | ""
  | "O campo e-mail é obrigatório!"
  | "E-mail não é válido";

export const validEmailRegex =
  // eslint-disable-next-line security/detect-unsafe-regex, no-useless-escape
  /^(([^<>()[\]~^?°`´ªº'/!%\\@#$¨&*£¢¬¹²³"=§áéíóúàèìòùâêîôûãõäëïöüç¡¿ãõ|,;:\s@"]+(\.[^<>()[\]\.,;:\s@\._~^?°`´ªº'/!@#$¨&*£¢¬¹²³%"+=§áéíóúàèìòùâêîôûãõäëïöüç¡¿ãõ\\|"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+(?<![-])\.)*(?!-)[^<>()[\]\.,;:\s@\"]{1}([^-][^<>()[\]\.,;:\s@\"]+)*(?<!-)\.[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const validCpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const validateEmail = (
  email: string | undefined,
  isRequired = true
): ValidateEmailResponse => {
  const emailValidated = !email ? "" : email;

  const emailIsRequired = "O campo e-mail é obrigatório!";
  const emailIsInvalid = "E-mail não é válido";
  const blank = "";

  if (isRequired && !email) {
    return emailIsRequired;
  }

  if (isRequired && !email?.match(validEmailRegex)) {
    return emailIsInvalid;
  }

  if (
    !isRequired &&
    emailValidated?.length > 0 &&
    !email?.match(validEmailRegex)
  ) {
    return emailIsInvalid;
  }

  return blank;
};

export const handleEmailValidation = (email: string | undefined) => {
  const textError = email ? validateEmail(email, false) : "";

  const hasError = email && email.length === 0 ? false : !!textError;

  const hasSuccessfully = email && email.length > 0 && !validateEmail(email);

  return { textError, hasError, hasSuccessfully };
};

export const validateEmailFormWeddingList = (email: string | undefined) => {
  const isValidEmail = validateEmail(email, false);

  return isValidEmail === "";
};
