import { useState } from "react";
import { useBranchLocation } from "application/context/BranchLocationContext";
import { buildTestIds, useEnvs } from "application";
import { cookie } from "implementations";
import {
  ChangeLocationButton,
  ContinueButton,
  DropdownBranchLocationContainer,
  DropdownBranchLocationContent,
  Parent,
  TitleRegionClient,
  WrapperButtons,
} from "./styles";

const cookieModalBranchLocationStr = "@FC:Ecom:Modal:BranchLocation";

const DropdownBranchLocation = () => {
  const {
    regionClientLocation,
    onOpenModalBranchLocation,
    statementDropdownBranchLocation,
    setDefaultBranchLocation,
  } = useBranchLocation();
  const allConfigs = useEnvs((state) => state.allConfigs);
  // Mudança temporária, a pedido de Volpato, Peixoto e Armando em 17/06/2023
  // const [isContinueWithCurrentLocation, setIsContinueWithCurrentLocation] =
  //   useState(true);
  const [isContinueWithCurrentLocation, setIsContinueWithCurrentLocation] =
    useState(false);

  const handleContinueLocation = () => {
    setIsContinueWithCurrentLocation(false);
    setDefaultBranchLocation();
  };

  const handleChangeLocation = () => {
    onOpenModalBranchLocation();
    setIsContinueWithCurrentLocation(false);
    setDefaultBranchLocation();
  };

  const hadVisualizationModalBranchLocationInFirstRender = cookie.getCookie({
    name: cookieModalBranchLocationStr,
  });

  if (
    statementDropdownBranchLocation &&
    !hadVisualizationModalBranchLocationInFirstRender &&
    allConfigs?.isModalBranchLocationAutoOpenOnClientFirstAccess === "true"
  ) {
    onOpenModalBranchLocation();
  }

  return (
    <Parent {...buildTestIds("dropdown-branch-location")}>
      {statementDropdownBranchLocation && (
        <DropdownBranchLocationContainer
          {...buildTestIds("dropdown-branch-location-container")}
          isHadOpen={isContinueWithCurrentLocation}
        >
          <DropdownBranchLocationContent
            {...buildTestIds("dropdown-branch-location-content")}
          >
            Para acessar produtos e ofertas da sua região, identificamos que
            você está em{" "}
            <TitleRegionClient
              {...buildTestIds("header-dropdown-branch-location-title")}
            >
              {regionClientLocation ?? "Demais Regiões"}.
            </TitleRegionClient>
          </DropdownBranchLocationContent>
          <WrapperButtons
            {...buildTestIds("header-dropdown-branch-location-wrapper-buttons")}
          >
            <ChangeLocationButton
              type="button"
              onClick={() => handleChangeLocation()}
              {...buildTestIds("header-change-location-button")}
            >
              Alterar
            </ChangeLocationButton>
            <ContinueButton
              type="button"
              onClick={() => handleContinueLocation()}
              {...buildTestIds("header-continue-button")}
            >
              Continuar
            </ContinueButton>
          </WrapperButtons>
        </DropdownBranchLocationContainer>
      )}
    </Parent>
  );
};

export { DropdownBranchLocation };
