import { useEffect, useState } from "react";
import {
  mappedIcons,
  IconWrapper,
  Desk,
  DeskInfo,
  Aggregator,
  theme,
} from "ui";
import { SupportContactDto, IHttpResponse } from "typing";
import { supportContactApi } from "implementations";
import { buildTestIds } from "application";
import { Link } from "../../Link";
import { Skeleton } from "../../Skeleton";
import { SupportContactText, SupportContactTitle } from "./styles";
import { getAriaLabel } from "./utils";

interface SupportContactProps {
  isBlackTheme: boolean;
}

const SupportContact = ({ isBlackTheme }: SupportContactProps) => {
  const [options, setOptions] = useState({
    data: null,
    isLoading: true,
    error: false,
  } as IHttpResponse<SupportContactDto[], unknown>);

  useEffect(() => {
    const getOptions = async () => {
      const response = await supportContactApi.getOptions();
      setOptions(response);
    };

    getOptions();
  }, []);

  const { data, isLoading } = options;

  return (
    <Aggregator
      id="support-contact-container"
      data-testid="support-contact-container"
      display="flex"
      flexDirection="row"
      alignItems="start"
      justifyContent="start"
      border={
        isBlackTheme
          ? `1px solid ${theme.colors.neutral["750"]}`
          : `1px solid ${theme.colors.neutral["100"]}`
      }
      borderRadius={isBlackTheme ? theme.space.x4 : theme.space.x1}
      padding={theme.space.x6}
      tablet={{
        padding: "0",
        alignItems: "start",
      }}
      mobile={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      {data ? (
        data.map(
          ({ mainIcon, title, description, link, secondaryIcons }, index) => (
            <Desk
              key={title}
              alignItems="start"
              hasRightBorder={index !== data.length - 1}
            >
              <DeskInfo>
                <IconWrapper
                  color={
                    isBlackTheme
                      ? theme.colors.secondary["230"]
                      : theme.colors.secondary["300"]
                  }
                  width="60px"
                  height="60px"
                >
                  {mappedIcons(
                    mainIcon?.title,
                    {
                      color: theme.colors.neutral.white,
                    },
                    { secondColor: theme.colors.neutral.white }
                  )}
                </IconWrapper>
              </DeskInfo>
              <DeskInfo>
                <SupportContactTitle isBlackTheme={!!isBlackTheme}>
                  {title}
                </SupportContactTitle>

                {description && (
                  <SupportContactText isBlackTheme={!!isBlackTheme}>
                    {description}
                  </SupportContactText>
                )}
                {link && (
                  <Link
                    fontWeight={`${theme.typography.fontWeight["400"]}`}
                    color={
                      isBlackTheme
                        ? theme.colors.secondary["230"]
                        : theme.colors.primary["200"]
                    }
                    href={link?.href}
                    {...buildTestIds(`support-contact-link-${title}`)}
                  >
                    {link?.title}
                  </Link>
                )}
                {secondaryIcons?.length && (
                  <Aggregator
                    display="flex"
                    flexDirection="row"
                    alignItems="start"
                    margin={`${theme.space.x3} 0 0`}
                    gap="8px"
                  >
                    {secondaryIcons?.map(
                      ({ title: iconTitle, link: iconLink }) => (
                        <IconWrapper
                          marginRight="0px"
                          key={iconLink}
                          width={theme.space.x7}
                          height={theme.space.x7}
                          color="transparent"
                          borderRadius={theme.space.x1}
                          clickable
                          isBlackTheme={!!isBlackTheme}
                          mouseOverColor={theme.colors.primary["200"]}
                          lineHeight="5px"
                        >
                          <Link
                            href={iconLink || ""}
                            aria-label={getAriaLabel(iconTitle)}
                          >
                            {mappedIcons(
                              iconTitle,
                              {
                                color: theme.colors.neutral["280"],
                              },
                              {
                                secondColor: theme.colors.neutral["280"],
                              }
                            )}
                          </Link>
                        </IconWrapper>
                      )
                    )}
                  </Aggregator>
                )}
              </DeskInfo>
            </Desk>
          )
        )
      ) : isLoading ? (
        <Skeleton
          type="card"
          count={3}
          width={theme.space.x96}
          height={theme.space.x24}
        />
      ) : null}
    </Aggregator>
  );
};

export { SupportContact };
