import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AddressDto,
  BookingCouponProps,
  BrideAndGroomProps,
  DeliveryOptionsBffDto,
  GetCartOptionsErrorDto,
  GiftCardApplied,
  GiftMessages,
  IHttpResponse,
  NewCardsTwoCardsPayment,
  NewShippingDetails,
  OrderByPhoneProps,
  OrderItemsBody,
  PostOrdersBffDto,
  PostOrdersResponse,
  PostOrdersResponseBffDto,
  PostPaymentOrderByPhonePaymentApi,
  PostPaymentOrderByPhonePaymentApiResponse,
  ProductCardDto,
  QuantityProductsType,
  ResponsiblePickUpProps,
  ShippingDetails,
  ShippingOptions,
  ShippingOptionsCartProduct,
  ShippingOptionsCartRes,
  ShippingPrices,
} from "typing";

import { IAppMonitoringClient, IFingerprint } from "app-domain";
import { useRouter } from "next/router";
import { IClientIp } from "../../entities";
import { useToast } from "../../hooks";
import { PaymentApi, PurchaseApi, ShippingApi } from "../../services";
import { useGiftCard, usePurchaseStore, useShallow } from "../../state-manager";
import { useBranchLocation } from "../BranchLocationContext";
import { useAuth } from "../Identity/AuthContext";
import { useCartContext } from "../ShoppingHookContext/CartContext";

export type ChargeTotalType = {
  one: number;
  two: number;
};

interface PurchaseContextProps {
  step: number;
  totalPackage: number;
  orderByPhone: OrderByPhoneProps;
  optionPayment: string;
  addressSelected: AddressDto;
  chargeTotal: ChargeTotalType;
  priceSendPackages: ShippingPrices;
  giftCardApplied: GiftCardApplied;
  couponApplied: BookingCouponProps;
  giftMessages: GiftMessages[];
  purchaseProduct: OrderItemsBody[];
  shippingOptions: ShippingOptions[];
  responsiblePickUp: ResponsiblePickUpProps[];
  newCards: NewCardsTwoCardsPayment[];
  brideAndGroom: BrideAndGroomProps[];
  quantityProducts: QuantityProductsType;
  clearNewCards(): void;
  clearOrderByPhone(): void;
  clearCouponApplied(): void;
  clearPackageShipping(): void;
  clearPurchaseProducts(): void;
  changeAllParamsInShippingOptionsToNull(): void;
  changeStep(value: number): void;
  changeTotalPackage(value: number): void;
  changeOptionPayment(value: string): void;
  changeGiftMessages(value: GiftMessages): void;
  changeOrderByPhone(value: OrderByPhoneProps): void;
  changeBrideAndGroom(value: BrideAndGroomProps[]): void;
  changeDiscountCoupon(value: BookingCouponProps): void;
  updateResponsiblePickUp(
    newResponsible: ResponsiblePickUpProps,
    packageIndex: number
  ): void;
  onChangeChargeTotalContext(
    totalPrice: number,
    chargeTotalContext: number,
    formId: number
  ): void;
  changeAddressSelected(value: AddressDto): void;
  changeNewCards(value: NewCardsTwoCardsPayment): void;
  changeGiftCardApplied(value: GiftCardApplied): void;
  changePackageShipping(
    value: ShippingOptions,
    products: ShippingOptionsCartProduct[],
    packages: number
  ): void;
  changeQuantityProducts(value: QuantityProductsType): void;

  createOrder: (
    order: PostOrdersBffDto,
    cartItemsList: ProductCardDto[]
  ) => Promise<
    IHttpResponse<PostOrdersResponseBffDto | PostOrdersResponse, unknown>
  >;
  doPaymentByPhone: ({
    orderId,
    payment,
  }: PostPaymentOrderByPhonePaymentApi) => Promise<
    IHttpResponse<PostPaymentOrderByPhonePaymentApiResponse, unknown>
  >;
  cartOptions: ShippingOptionsCartRes | null | undefined;
  triggerGetCartOptions?: () => unknown;
}

export interface PurchaseProviderProps {
  children: ReactNode;
  purchaseApi: PurchaseApi;
  paymentApi: PaymentApi;
  allowMeFingerprint: IFingerprint;
  clearSaleFingerprint: IFingerprint;
  shippingApi: ShippingApi;
  appMonitoringClient: IAppMonitoringClient;
  clientIp: IClientIp;
}

export const PurchaseContext = createContext({} as PurchaseContextProps);

export const PurchaseProvider = ({
  children,
  purchaseApi,
  paymentApi,
  allowMeFingerprint,
  clearSaleFingerprint,
  shippingApi,
  appMonitoringClient,
  clientIp,
}: PurchaseProviderProps) => {
  const {
    cartItems,
    id: cartId,
    handleEmitEventCartModified,
    quantityOfItemsInCart,
  } = useCartContext();
  const { getBranchLocationClient } = useBranchLocation();
  const { getTokens, userData } = useAuth();
  const [step, setStep] = useState<number>(1);
  const [orderByPhone, setOrderByPhone] = useState<OrderByPhoneProps>(
    {} as OrderByPhoneProps
  );
  const [totalPackage, setTotalPackage] = useState<number>(1);
  const [couponApplied, setCouponApplied] = useState<BookingCouponProps>({
    balance: 0,
    couponMovementId: "",
  } as BookingCouponProps);
  const [optionPayment, setOptionPayment] = useState<string>("");
  const [addressSelected, setAddressSelected] = useState<AddressDto>(
    {} as AddressDto
  );
  const [newCards, setNewCards] = useState<NewCardsTwoCardsPayment[]>([]);
  const [brideAndGroom, setBrideAndGroom] = useState<BrideAndGroomProps[]>([]);
  const [giftMessages, setGiftMessages] = useState<GiftMessages[]>([]);
  const [shippingOptions, setShippingOptions] = useState<ShippingOptions[]>([]);
  const [giftCardApplied, setGiftCardApplied] = useState<GiftCardApplied>({
    balance: 0,
    cardNumber: "",
  } as GiftCardApplied);

  const responsiblePickUp = useMemo(() => {
    return [] as ResponsiblePickUpProps[];
  }, []);

  const setResponsiblePickUp = useCallback(
    (data: ResponsiblePickUpProps, index?: number) => {
      if (index && index < 0) return;
      if (index) {
        responsiblePickUp[`${index - 1}`] = data;
        return;
      }

      responsiblePickUp.push(data);
    },
    [responsiblePickUp]
  );

  const [chargeTotal, setChargeTotal] = useState<ChargeTotalType>({
    one: 0,
    two: 0,
  });
  const [quantityProducts, setQuantityProducts] =
    useState<QuantityProductsType>({
      quantityGiftItems: 0,
      quantityProductItems: 0,
    });

  const onChangeChargeTotalContext = useCallback(
    (totalPrice: number, chargeTotalContext: number, formId: number) => {
      setChargeTotal((state) => ({
        ...state,
        one:
          formId === 1 ? chargeTotalContext : totalPrice - chargeTotalContext,
        two:
          formId === 2 ? chargeTotalContext : totalPrice - chargeTotalContext,
      }));
    },
    []
  );

  const {
    purchaseProduct,
    setBranchLocation,
    setProductsFromCart,
    clearPurchaseProducts,
  } = usePurchaseStore(
    useShallow((state) => ({
      purchaseProduct: state.purchaseProduct,
      setProductsFromCart: state.setProductsFromCart,
      setBranchLocation: state.setBranchLocation,
      clearPurchaseProducts: state.clearPurchaseProducts,
    }))
  );

  setProductsFromCart(cartItems);
  setBranchLocation(Number(getBranchLocationClient() || 2));

  const changeAddressSelected = useCallback((value: AddressDto) => {
    setAddressSelected(value);
  }, []);

  const changeStep = useCallback((value: number) => {
    setStep(value);
  }, []);

  const changeQuantityProducts = useCallback((value: QuantityProductsType) => {
    setQuantityProducts({ ...value });
  }, []);

  const validateResponsiblePickUp = useCallback(
    (
      isPickUpInStore: boolean,
      type: "name" | "email" | "cpf",
      packageNumber = 1
    ) => {
      const index = packageNumber - 1;
      switch (type) {
        case "name":
          return isPickUpInStore
            ? responsiblePickUp[`${index}`]?.name || userData.name
            : "";

        case "cpf":
          return isPickUpInStore
            ? responsiblePickUp[`${index}`]?.cpf || userData.cpf
            : "";

        case "email":
          return isPickUpInStore
            ? responsiblePickUp[`${index}`]?.email || userData.email
            : "";

        default:
          return "";
      }
    },
    [responsiblePickUp, userData]
  );

  const changePackageShipping = useCallback(
    (
      selectedShippingOption: ShippingOptions,
      products: ShippingOptionsCartProduct[],
      packages: number
    ) => {
      const shippingIsNotEqual = shippingOptions.filter(
        (item) =>
          item.shippingDetails?.deliveryOptions?.[0].package !== packages
      );

      const conditionToCheckAtPickUpOption =
        selectedShippingOption?.pickUpInStore &&
        selectedShippingOption?.branchId === 0;

      const shippingDetails = [
        ...((Array.isArray(selectedShippingOption?.shippingDetails)
          ? selectedShippingOption?.shippingDetails
          : []) as unknown as ShippingDetails[]),
      ];

      const deliveryOptions = products.map(({ productId, sellerId }) => {
        const currentShippingDetailIndex = shippingDetails.findIndex(
          (shippingDetail) => shippingDetail?.code === String(productId)
        );

        const currentShippingDetail = {
          ...shippingDetails.splice(currentShippingDetailIndex, 1)?.[0],
        };

        return {
          productId: String(productId),
          deliveryTypeId: selectedShippingOption.deliveryTypeId,
          package: packages,
          packaging: conditionToCheckAtPickUpOption
            ? "0"
            : Number(currentShippingDetail?.packaging || "0"),
          sellerId,
          addressId: conditionToCheckAtPickUpOption
            ? String(0)
            : String(currentShippingDetail?.addressId),
          branchProduct: currentShippingDetail?.branchId || 0,
          isPickupStore: selectedShippingOption?.pickUpInStore,
          pickupBranch: selectedShippingOption?.pickUpInStore
            ? selectedShippingOption?.pickUpInStoreLocation?.branchIdForPickup
            : 0,
          namePickupInStore: validateResponsiblePickUp(
            selectedShippingOption?.pickUpInStore as boolean,
            "name",
            packages
          ),
          emailPickupInStore: validateResponsiblePickUp(
            selectedShippingOption?.pickUpInStore as boolean,
            "email",
            packages
          ),
          cpfPickupInStore: validateResponsiblePickUp(
            selectedShippingOption?.pickUpInStore as boolean,
            "cpf",
            packages
          ),
        } as unknown as DeliveryOptionsBffDto;
      });

      const newShippingDetails: NewShippingDetails = {
        addressId: addressSelected.id,
        deliveryOptions,
      };

      const newShippingOption: ShippingOptions = {
        description: selectedShippingOption.description,
        branchId: selectedShippingOption.branchId,
        value: selectedShippingOption.value,
        arrives: selectedShippingOption.arrives,
        arrivesText: selectedShippingOption.arrivesText,
        arrivesTextShort: selectedShippingOption.arrivesTextShort,
        pickUpInStore: selectedShippingOption.pickUpInStore,
        pickUpInStoreLocation: selectedShippingOption.pickUpInStoreLocation,
        shippingDetails: newShippingDetails,
        deliveryTypeId: selectedShippingOption.deliveryTypeId,
        package: selectedShippingOption.package,
      };

      const updatedShippingOptions = [...shippingIsNotEqual, newShippingOption];

      if (updatedShippingOptions?.length !== shippingOptions?.length) {
        setShippingOptions(updatedShippingOptions);
        return;
      }

      const hasShippingOptionsChanges = shippingOptions.find(
        (
          {
            arrives,
            arrivesText,
            branchId,
            deliveryTypeId,
            description,
            package: shippingOptionsPackage,
            pickUpInStore,
          },
          index
        ) =>
          arrives !== updatedShippingOptions?.[`${index}`]?.arrives ||
          arrivesText !== updatedShippingOptions?.[`${index}`]?.arrivesText ||
          branchId !== updatedShippingOptions?.[`${index}`]?.branchId ||
          deliveryTypeId !==
            updatedShippingOptions?.[`${index}`]?.deliveryTypeId ||
          description !== updatedShippingOptions?.[`${index}`]?.description ||
          shippingOptionsPackage !==
            updatedShippingOptions?.[`${index}`]?.package ||
          pickUpInStore !== updatedShippingOptions?.[`${index}`]?.pickUpInStore
      );

      if (hasShippingOptionsChanges) {
        setShippingOptions(updatedShippingOptions);
      }
    },
    [addressSelected, shippingOptions, validateResponsiblePickUp]
  );

  const changeAllParamsInShippingOptionsToNull = useCallback(() => {
    const shippingDetailsAllParamsNull = {
      addressId: 0,
      deliveryOptions: [],
    };

    const allParamsEmpty = [
      {
        description: null,
        branchId: null,
        value: null,
        arrives: null,
        arrivesText: null,
        arrivesTextShort: null,
        pickUpInStore: null,
        pickUpInStoreLocation: null,
        shippingDetails: shippingDetailsAllParamsNull,
        deliveryTypeId: null,
        package: null,
      },
    ] as unknown as ShippingOptions[];

    setShippingOptions(allParamsEmpty);
  }, []);

  const clearPackageShipping = useCallback(() => {
    setShippingOptions([]);
  }, []);

  const clearCouponApplied = useCallback(() => {
    setCouponApplied({
      balance: 0,
      couponMovementId: "",
    } as BookingCouponProps);
  }, []);

  const updateResponsiblePickUp = useCallback(
    (newResponsible: ResponsiblePickUpProps, packageIndex: number) => {
      setResponsiblePickUp(newResponsible, packageIndex);
      setShippingOptions((previousShippingOptions) =>
        previousShippingOptions?.map((shippingOption, index) => {
          if (index + 1 === packageIndex) {
            return {
              ...shippingOption,
              shippingDetails: {
                ...shippingOption?.shippingDetails,
                deliveryOptions:
                  shippingOption?.shippingDetails?.deliveryOptions?.map(
                    (deliveryOption) => ({
                      ...deliveryOption,
                      namePickupInStore: newResponsible?.name,
                      emailPickupInStore: newResponsible?.email,
                      cpfPickupInStore: newResponsible?.cpf.replace(
                        /[^0-9]/g,
                        ""
                      ),
                    })
                  ),
              },
            };
          }
          return shippingOption;
        })
      );
    },
    [setResponsiblePickUp]
  );

  const changeTotalPackage = useCallback((value: number) => {
    setTotalPackage(value);
  }, []);

  const changeOptionPayment = useCallback((value: string) => {
    setOptionPayment(value);
  }, []);

  const changeDiscountCoupon = useCallback((value: BookingCouponProps) => {
    setCouponApplied(value);
  }, []);

  const changeGiftCardApplied = useCallback((value: GiftCardApplied) => {
    setGiftCardApplied(value);
  }, []);

  const changeNewCards = useCallback(
    (value: NewCardsTwoCardsPayment) => {
      const cardAlreadyExists = newCards.some(
        (card) => card.cardNumber === value.cardNumber
      );

      if (cardAlreadyExists) return;

      setNewCards((card) => [...card, value]);
    },
    [newCards]
  );

  const clearNewCards = useCallback(() => {
    setNewCards([]);
  }, []);

  const changeGiftMessages = useCallback(
    (value: GiftMessages) => {
      const foundGiftMessage = giftMessages?.find(
        ({ listId }) => listId === value?.listId
      );

      const updatedGiftMessages = giftMessages?.map((item) => {
        if (item?.listId === value?.listId) {
          return {
            listId: value?.listId,
            message: value?.message,
          } as GiftMessages;
        }
        return item;
      });

      if (foundGiftMessage) {
        setGiftMessages(updatedGiftMessages);
        return;
      }

      setGiftMessages([...giftMessages, value]);
    },
    [giftMessages]
  );

  const changeBrideAndGroom = useCallback((value: BrideAndGroomProps[]) => {
    setBrideAndGroom(value);
  }, []);

  const priceSendPackages = useMemo(() => {
    return shippingOptions.reduce(
      (accumulator, shipping) => {
        accumulator.shippingPrice += shipping.value;

        return accumulator;
      },
      {
        shippingPrice: 0,
      }
    );
  }, [shippingOptions]);

  const accessToken = getTokens()?.token as unknown as string;

  const sentSMSToken = useGiftCard((state) => state.sentSMSToken);

  const createOrder = useCallback(
    async (
      order: PostOrdersBffDto,
      cartItemsList: ProductCardDto[],
      usedClearSaleFingerprint: IFingerprint = clearSaleFingerprint,
      usedAllowMeFingerprint: IFingerprint = allowMeFingerprint,
      usedClientIp: IClientIp = clientIp
    ) => {
      const sessionId = String(
        (await usedClearSaleFingerprint.collect()) || ""
      );

      const fingerprint = String(
        (await usedAllowMeFingerprint.collect("purchase")) || ""
      );

      const ip = usedClientIp.getClientIp();

      const updatedGtagItems = cartItemsList.map((item) => ({
        ...item,
        itemVariant: cartItems[0]?.productFamilies?.[0]?.families?.find(
          (variant) => variant?.selectedValue
        )?.color?.name,
      }));

      const totalShipping = shippingOptions.reduce(
        (acc, option) => acc + (option?.value || 0),
        0
      );

      const { data, error, isLoading } = await purchaseApi.createOrder({
        cartId: order.cartId,
        giftMessages: order.giftMessages,
        couponMovementId: order.couponMovementId,
        jwt: accessToken,
        payment: order.payment,
        shippingOptions: order.shippingOptions,
        quantity: quantityOfItemsInCart,
        shipping: totalShipping,
        gtagItems: updatedGtagItems,
        sessionId,
        fingerprint,
        clientIp: ip,
        giftCardSMSToken: sentSMSToken,
      });

      handleEmitEventCartModified();

      return {
        data,
        error,
        isLoading,
      };
    },
    [
      clearSaleFingerprint,
      allowMeFingerprint,
      clientIp,
      shippingOptions,
      purchaseApi,
      accessToken,
      quantityOfItemsInCart,
      handleEmitEventCartModified,
      sentSMSToken,
      cartItems,
    ]
  );

  const doPaymentByPhone = useCallback(
    async ({ orderId, payment }: PostPaymentOrderByPhonePaymentApi) => {
      const data = (await paymentApi.postPaymentOrder(
        accessToken,
        orderId || orderByPhone.orderId,
        payment,
        "payment_by_phone"
      )) as IHttpResponse<PostPaymentOrderByPhonePaymentApiResponse, unknown>;

      handleEmitEventCartModified();

      return data;
    },
    [accessToken, handleEmitEventCartModified, orderByPhone.orderId, paymentApi]
  );

  const changeOrderByPhone = useCallback((value: OrderByPhoneProps) => {
    setOrderByPhone(value);
  }, []);

  const clearOrderByPhone = useCallback(() => {
    setOrderByPhone({} as OrderByPhoneProps);
  }, []);

  const useHandleGetCartOptionsError = (
    getCartOptionsError: GetCartOptionsErrorDto
  ) => {
    const { addToast } = useToast();
    const router = useRouter();

    useEffect(() => {
      if (
        getCartOptionsError?.status &&
        getCartOptionsError?.status === 400 &&
        router.pathname !== "/checkout/pagamento/[groupId]"
      ) {
        const errorMessage =
          getCartOptionsError?.errors?.CartId?.[0] ||
          "Ocorreu um erro ao obter os itens do seu carrinho";

        addToast({
          title: errorMessage,
          type: "error",
          timerInMilliseconds: 3000,
        });

        appMonitoringClient.captureMessage(errorMessage, {
          level: "error",
          tags: {
            fcx_labs_event: "get_cart_options",
            fcx_labs_error_source: "shopping_api",
          },
        });

        router.push("/");

        setTimeout(() => {
          router.reload();
        }, 3000);
      }
    }, [addToast, getCartOptionsError, router]);
  };

  const { data: cartOptions, trigger: triggerGetCartOptions } =
    shippingApi.getCartOptions(
      accessToken,
      cartId,
      addressSelected?.zipCode,
      !!(accessToken && cartId && addressSelected?.zipCode)
    );

  useHandleGetCartOptionsError(
    cartOptions as unknown as GetCartOptionsErrorDto
  );

  const purchaseContextProviderValue = useMemo(() => {
    return {
      addressSelected,
      changeAddressSelected,
      changeStep,
      step,
      shippingOptions,
      changePackageShipping,
      clearPackageShipping,
      totalPackage,
      changeTotalPackage,
      purchaseProduct,
      priceSendPackages,
      changeOptionPayment,
      optionPayment,
      changeDiscountCoupon,
      couponApplied,
      createOrder,
      responsiblePickUp,
      clearPurchaseProducts,
      giftCardApplied,
      changeGiftCardApplied,
      changeNewCards,
      newCards,
      clearNewCards,
      chargeTotal,
      onChangeChargeTotalContext,
      updateResponsiblePickUp,
      changeOrderByPhone,
      orderByPhone,
      doPaymentByPhone,
      changeBrideAndGroom,
      brideAndGroom,
      giftMessages,
      changeGiftMessages,
      quantityProducts,
      changeQuantityProducts,
      clearCouponApplied,
      changeAllParamsInShippingOptionsToNull,
      clearOrderByPhone,
      cartOptions,
      triggerGetCartOptions,
    };
  }, [
    addressSelected,
    changeAddressSelected,
    changeStep,
    step,
    shippingOptions,
    changePackageShipping,
    clearPackageShipping,
    totalPackage,
    changeTotalPackage,
    purchaseProduct,
    priceSendPackages,
    changeOptionPayment,
    optionPayment,
    changeDiscountCoupon,
    couponApplied,
    createOrder,
    responsiblePickUp,
    clearPurchaseProducts,
    giftCardApplied,
    changeGiftCardApplied,
    changeNewCards,
    newCards,
    clearNewCards,
    chargeTotal,
    onChangeChargeTotalContext,
    updateResponsiblePickUp,
    changeOrderByPhone,
    orderByPhone,
    doPaymentByPhone,
    brideAndGroom,
    giftMessages,
    changeGiftMessages,
    changeBrideAndGroom,
    quantityProducts,
    changeQuantityProducts,
    clearCouponApplied,
    changeAllParamsInShippingOptionsToNull,
    clearOrderByPhone,
    cartOptions,
    triggerGetCartOptions,
  ]);

  return (
    <PurchaseContext.Provider value={purchaseContextProviderValue}>
      {children}
    </PurchaseContext.Provider>
  );
};

export const usePurchase = (): PurchaseContextProps => {
  const purchaseContext = useContext(PurchaseContext);

  if (!purchaseContext) {
    throw new Error("usePurchase must be used within an PurchaseProvider");
  }

  return purchaseContext;
};
