import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ArrowRight = ({
  alt = "Seta para direita",
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  secondColor = `${theme.colors.neutral.white}`,
  strokeWidth = "0.5",
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M15.9393 14L10.4697 19.4697C10.1768 19.7626 10.1768 20.2374 10.4697 20.5303C10.7626 20.8232 11.2374 20.8232 11.5303 20.5303L17.5303 14.5303C17.8232 14.2374 17.8232 13.7626 17.5303 13.4697L11.5303 7.46967C11.2374 7.17678 10.7626 7.17678 10.4697 7.46967C10.1768 7.76256 10.1768 8.23744 10.4697 8.53033L15.9393 14Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
      <mask
        id="mask0_0_3607"
        maskUnits="userSpaceOnUse"
        x="10"
        y="7"
        width="8"
        height="14"
      >
        <path
          d="M15.9393 14L10.4697 19.4697C10.1768 19.7626 10.1768 20.2374 10.4697 20.5303C10.7626 20.8232 11.2374 20.8232 11.5303 20.5303L17.5303 14.5303C17.8232 14.2374 17.8232 13.7626 17.5303 13.4697L11.5303 7.46967C11.2374 7.17678 10.7626 7.17678 10.4697 7.46967C10.1768 7.76256 10.1768 8.23744 10.4697 8.53033L15.9393 14Z"
          fill={secondColor}
        />
      </mask>
      <g mask="url(#mask0_0_3607)">
        <rect
          width={width}
          height={height}
          transform="matrix(-1 0 0 1 28 0)"
          fill="none"
        />
      </g>
    </svg>
  );
};
