import { InfoOutlineIcon, theme } from "ui";
import { RequiredWarningContainer, RequiredWarningText } from "./styles";

const RequiredWarning = () => {
  return (
    <RequiredWarningContainer>
      <InfoOutlineIcon color={theme.colors.neutral["550"]} />
      <RequiredWarningText marginLeft={theme.space.x2}>
        {" "}
        Os itens marcados com
        <RequiredWarningText highlight> * </RequiredWarningText>
        são obrigatórios.
      </RequiredWarningText>
    </RequiredWarningContainer>
  );
};

export default RequiredWarning;
