import { buildTestIds } from "application";
import { Image } from "../../Image";
import { BoxMiniatureImage, Container } from "./styles";

interface ThumbnailSelectProps {
  defaultImage: string;
  imageUrl: string;
  linkType: string;
  sequenceNumber: number;
  actionReturnInfo: (sequenceNumber: number) => void;
  active?: boolean;
}

const ThumbnailSelect = ({
  actionReturnInfo,
  defaultImage,
  imageUrl,
  linkType,
  sequenceNumber,
  active,
}: ThumbnailSelectProps) => {
  return (
    <Container {...buildTestIds("thumbnail-container")}>
      <BoxMiniatureImage
        {...buildTestIds("thumbnail-box-miniature")}
        data-thumb-number="thumb-list-item-box-miniature"
        className={active ? "active" : "disabled"}
        onClick={() => actionReturnInfo(sequenceNumber)}
      >
        <Image
          {...buildTestIds("thumbnail-image")}
          src={defaultImage || imageUrl}
          alt={linkType}
          width="100px"
          height="100px"
          layout="responsive"
        />
      </BoxMiniatureImage>
    </Container>
  );
};

export default ThumbnailSelect;
