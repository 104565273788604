const getUserInitials = (name: string | null): string | null => {
  if (!name) return null;

  const userNamesList = name.trimEnd().trimStart().split(" ");

  if (userNamesList.length === 1) {
    const firstChar = userNamesList?.at(0)?.at(0);
    return firstChar ? firstChar.toUpperCase() : null;
  }

  const firstInitial = userNamesList?.at(0)?.at(0)?.toUpperCase();
  const lastInitial = userNamesList?.at(-1)?.at(0)?.toUpperCase();

  return `${firstInitial}${lastInitial}`;
};

export { getUserInitials };
