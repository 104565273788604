import { IAppMonitoringClient } from "app-domain";
import {
  Scope,
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  configureScope as sentryConfigureScope,
  setUser as sentrySetUser,
} from "@sentry/nextjs";
import { CaptureContext, SeverityLevel } from "@sentry/types";
import { UserData } from "typing";

export const SentryClient: IAppMonitoringClient = {
  captureException: (
    error: unknown,
    context: CaptureContext | SeverityLevel | undefined = undefined
  ) => {
    return sentryCaptureException(error, context as CaptureContext | undefined);
  },
  captureMessage: (
    message: string,
    context: CaptureContext | SeverityLevel | undefined = undefined
  ) => {
    return sentryCaptureMessage(
      message,
      context as CaptureContext | SeverityLevel | undefined
    );
  },
  configureScope: (user: Pick<UserData, "email"> | null, token?: string) => {
    return sentryConfigureScope((sentryScope: Scope) => {
      if (user) {
        sentryScope.setUser(user);
        sentryScope.setExtra("fcx_labs_user_access_token", token);
      } else {
        sentrySetUser(null);
        sentryScope.setExtra("fcx_labs_user_access_token", undefined);
      }
    });
  },
};
