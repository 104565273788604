import {
  DistrictsProps,
  IHttpResponse,
  MetropolisProps,
  ShippingAddressByCityDto,
  ShippingAddressByZipCodeDto,
  ShippingBranchDto,
  ShippingOptions,
  ShippingOptionsCartRes,
  ShippingOptionsProps,
} from "typing";

import { IHttpClient } from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import { formatBranchId, getApisUrlFallback } from "../../../utils";
import { EnvsApi } from "../Envs";

export class ShippingApi implements IApi {
  baseUrl = "";

  constructor(private httpClient: IHttpClient, private envsApi: EnvsApi) {
    if (!this.baseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}shipping/`;
    }
  }

  getZipCodeAvailabilityEndpoint = (
    productId: string,
    productQuantity: string,
    packingLevel: string,
    zipCode: string,
    branchId: string,
    sellerId: number
  ) => {
    const formattedBranchId = formatBranchId(branchId);
    const sellerVerification = sellerId ? `&SellerId=${sellerId}` : "";
    const branchIdVerification = branchId
      ? `&BranchId=${formattedBranchId}`
      : "";

    return `${this.baseUrl}v1/options?ProductId=${productId}&Quantity=${productQuantity}&Package=${packingLevel}&ZipCode=${zipCode}${sellerVerification}${branchIdVerification}`;
  };

  getZipCodeAvailability = (
    productId: string,
    productQuantity: string,
    packingLevel: string,
    zipCode: string,
    branchId: string,
    sellerId: number,
    condition: boolean
  ) => {
    const { data, error, isLoading } = this.httpClient.useGet<
      ShippingOptions[]
    >(
      this.getZipCodeAvailabilityEndpoint(
        productId,
        productQuantity,
        packingLevel,
        zipCode,
        branchId,
        sellerId
      ),
      undefined,
      condition
    ) as IHttpResponse<ShippingOptions[], unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  private getAddressByCityEndpoint = (city: string, size = 5) => {
    return `${this.baseUrl}v1/address/city?city=${city}&size=${size}`;
  };

  getAddressByCity = (city: string, size?: number, condition?: boolean) => {
    const { data, error, isLoading } = this.httpClient.useGet(
      this.getAddressByCityEndpoint(city, size),
      {},
      condition
    ) as IHttpResponse<ShippingAddressByCityDto[], unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  private getAddressByZipCodeEndpoint = (zipCode: string) => {
    return `${this.baseUrl}v1/address?zipCode=${zipCode}`;
  };

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getAddressByZipCode = (zipCode: string, condition?: boolean) => {
    const { data, error, isLoading } = this.httpClient.useGet(
      this.getAddressByZipCodeEndpoint(zipCode?.replace("-", "")),
      {},
      condition
    ) as IHttpResponse<ShippingAddressByZipCodeDto, unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  getCartOptionsEndpoint = (cartId: number, zipCode: string) => {
    return `${this.baseUrl}v1/options/cart-options?CartId=${cartId}&ZipCode=${zipCode}`;
  };

  getCartOptions = (
    jwt: string | undefined,
    cartId: number,
    zipCode: string,
    condition = true,
    cartOptions: ShippingOptionsCartRes | null | undefined = undefined
  ) => {
    const { data, error, isLoading, trigger } = this.httpClient.useGet(
      this.getCartOptionsEndpoint(cartId, zipCode),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition,
      cartOptions
    ) as IHttpResponse<ShippingOptionsCartRes, unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  getOptionsEndpoint = ({
    productId,
    quantity,
    pricePackage,
    zipCode,
    branchId,
    sellerId,
  }: ShippingOptionsProps) => {
    const branchIdQueryParam = branchId
      ? `&BranchId=${formatBranchId(branchId.toString())}`
      : "";

    return `${this.baseUrl}v1/options?ProductId=${productId}&Quantity=${quantity}&Package=${pricePackage}&ZipCode=${zipCode}&SellerId=${sellerId}${branchIdQueryParam}`;
  };

  getOptions = (
    {
      productId,
      quantity,
      pricePackage,
      zipCode,
      branchId,
      sellerId,
    }: ShippingOptionsProps,
    condition = true
  ) => {
    const { data, error, isLoading } = this.httpClient.useGet(
      this.getOptionsEndpoint({
        productId,
        quantity,
        pricePackage,
        zipCode,
        branchId,
        sellerId,
      }),
      {},
      condition
    ) as IHttpResponse<ShippingOptions[], unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  private getBranchesEndpoint = () => {
    return `${this.baseUrl}v1/branch/branches`;
  };

  getBranches = () => {
    const { data, error, isLoading } = this.httpClient.useGet(
      this.getBranchesEndpoint(),
      undefined
    ) as IHttpResponse<ShippingBranchDto[], unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  getBranchIdByZipCodeEndpoint = (zipCode: string) => {
    return `${this.baseUrl}v1/branch?cep=${zipCode}`;
  };

  getBranchIdByZipCode = (zipCode: string, condition = false) => {
    const { data, error, isLoading } = this.httpClient.useGet(
      this.getBranchIdByZipCodeEndpoint(zipCode),
      undefined,
      condition
    ) as IHttpResponse<{ branchId: number }, unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  getDistrictsEndPoint = () => {
    return `${this.baseUrl}v1/address/states`;
  };

  getDistricts = () => {
    const { data, error, isLoading } = this.httpClient.useGet(
      this.getDistrictsEndPoint()
    ) as IHttpResponse<DistrictsProps[], unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  getMetropolisEndpoint = (acronym: string) => {
    return `${this.baseUrl}v1/address/districts/${acronym}`;
  };

  getMetropolis = (acronym: string) => {
    const { data, error, isLoading } = this.httpClient.useGet(
      this.getMetropolisEndpoint(acronym)
    ) as IHttpResponse<MetropolisProps[], unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };
}
