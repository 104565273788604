import styled from "styled-components";
import { Apple, GoogleColored, theme } from "ui";
import { oAuth } from "typing/types";

interface ButtonOAuthProps {
  provider: oAuth;
}

interface AppleOAuthIconProps {
  isHovered: boolean;
}

export const WrapperOAuthButtons = styled.section`
  display: flex;
  align-items: center;
  gap: ${theme.space.x6};
  justify-content: center;
  width: 100%;
  padding: ${theme.space.x4} ${theme.space.x0} ${theme.space.x6};

  @media ${theme.device.desktopFirst.mobileXL} {
    padding-bottom: ${theme.space.x4};
  }
`;

export const ButtonAuthenticationOAuth = styled.button<ButtonOAuthProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 100px;
  border: 0.5px solid ${theme.colors.neutral["330"]};
  gap: 0.75rem;
  cursor: pointer;

  background: ${({ provider }) =>
    provider !== "google"
      ? theme.colors.neutral["999"]
      : theme.colors.neutral.white};

  transition: 0.2s ease-in-out;

  &:hover {
    background: ${({ provider }) =>
      provider === "google" && theme.colors.neutral["999-opacity-15"]};
  }

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
`;

export const AppleOAuthIcon = styled(Apple).attrs<AppleOAuthIconProps>(
  ({ isHovered }) => ({
    color: isHovered ? theme.colors.neutral["580"] : theme.colors.neutral.white,
    height: 20,
    width: 20,
  })
)<AppleOAuthIconProps>``;

export const GoogleOAuthIcon = styled(GoogleColored).attrs({
  color: theme.colors.neutral.white,
  height: 22,
  width: 22,
})``;
