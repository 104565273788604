import { ArrowLeft, EraseIcon, House, Search, theme } from "ui";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { buildTestIds } from "application";
import {
  ActionOptionsText,
  ActionOptionsWrapper,
  DescriptionContainer,
  NoOrderContainer,
  NoOrderDescription,
  NoOrderIcon,
  NoOrderTitle,
  Option,
  OptionIcon,
  OptionText,
  OptionsContainer,
} from "./styles";

export interface NoOrdersProps {
  icon: JSX.Element;
  title: string;
  description: string;
  termSearched?: string;
  padding?: string;
  clearSearch?: (term: string) => void;
}

const NoOrders = ({
  icon,
  title,
  description,
  termSearched,
  clearSearch,
  padding,
}: NoOrdersProps) => {
  const router = useRouter();

  const clearSearchTerm = useCallback(() => {
    if (clearSearch) {
      clearSearch("");
    }
  }, [clearSearch]);

  const renderOptions = () => {
    if (termSearched?.length) {
      return (
        <>
          <Option
            {...buildTestIds("option-clear-search")}
            onClick={clearSearchTerm}
          >
            <OptionIcon {...buildTestIds("option-icon-clear-search")}>
              <EraseIcon />
            </OptionIcon>
            <OptionText {...buildTestIds("option-text-clear-search")}>
              Limpar pesquisa
            </OptionText>
          </Option>
          <Option
            {...buildTestIds("option-search-for-term-on-site")}
            onClick={() => router.push(`/pesquisa/${termSearched}`)}
          >
            <OptionIcon
              {...buildTestIds("option-icon-search-for-term-on-site")}
            >
              <Search
                width={16}
                height={16}
                strokeWidth="2"
                color={theme.colors.secondary["380"]}
              />
            </OptionIcon>
            <OptionText
              {...buildTestIds("option-text-search-for-term-on-site")}
            >
              Pesquisar &quot;{termSearched}&quot; no site
            </OptionText>
          </Option>
          <Option
            {...buildTestIds("option-turn-back-to-home-search")}
            onClick={() => router.push("/")}
          >
            <OptionIcon
              {...buildTestIds("option-icon-turn-back-to-home-search")}
            >
              <House
                width={14}
                height={14}
                color={theme.colors.secondary["380"]}
              />
            </OptionIcon>
            <OptionText
              {...buildTestIds("option-text-turn-back-to-home-search")}
            >
              Ir para página inicial
            </OptionText>
          </Option>
        </>
      );
    }

    return (
      <>
        <Option
          {...buildTestIds("option-turn-back-to-previous-page")}
          onClick={() => router.back()}
        >
          <OptionIcon
            {...buildTestIds("option-icon-turn-back-to-previous-page")}
          >
            <ArrowLeft
              width={16}
              height={16}
              color={theme.colors.secondary["380"]}
            />
          </OptionIcon>
          <OptionText
            {...buildTestIds("option-text-turn-back-to-previous-page")}
          >
            Voltar para a página anterior
          </OptionText>
        </Option>
        <Option
          {...buildTestIds("option-turn-back-to-home")}
          onClick={() => router.push("/")}
        >
          <OptionIcon {...buildTestIds("option-icon-turn-back-to-home")}>
            <House
              width={14}
              height={14}
              color={theme.colors.secondary["380"]}
            />
          </OptionIcon>
          <OptionText {...buildTestIds("option-text-turn-back-to-home")}>
            Ir para página inicial
          </OptionText>
        </Option>
      </>
    );
  };

  return (
    <NoOrderContainer padding={padding} {...buildTestIds("no-order-container")}>
      <NoOrderIcon {...buildTestIds("no-order-icon")}>{icon}</NoOrderIcon>
      <DescriptionContainer {...buildTestIds("description-container")}>
        <NoOrderTitle {...buildTestIds("no-order-title")}>{title}</NoOrderTitle>
        <NoOrderDescription {...buildTestIds("no-order-description")}>
          {description}
        </NoOrderDescription>
      </DescriptionContainer>
      <ActionOptionsWrapper {...buildTestIds("action-options-wrapper")}>
        <ActionOptionsText {...buildTestIds("action-options-text")}>
          O que você pode fazer?
        </ActionOptionsText>
        <OptionsContainer {...buildTestIds("options-container")}>
          {renderOptions()}
        </OptionsContainer>
      </ActionOptionsWrapper>
    </NoOrderContainer>
  );
};

export { NoOrders };
