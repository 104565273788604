import { IAppMonitoringClient, ICookie } from "app-domain";
import { deleteLoggedUserCookies } from "../logged-user-cookies";
import { accessTokenCookieKey, refreshTokenCookieKey } from "../keys";
import { EnvsApi } from "../../../../services/apis/Envs";

const isAccessTokenIssuerValid = async (
  issuer: string,
  envsApi: EnvsApi,
  cookie: ICookie,
  appMonitoringClient: IAppMonitoringClient
) => {
  const { data: allConfigs } = await envsApi.getAllConfigs();
  const { identityIssuer } = allConfigs || { identityIssuer: "" };

  if (identityIssuer && issuer !== identityIssuer) {
    deleteLoggedUserCookies(
      cookie,
      accessTokenCookieKey,
      refreshTokenCookieKey
    );

    appMonitoringClient.captureException(
      `O access token do usuário possui um issuer inválido (diferente de ${identityIssuer})`,
      {
        level: "error",
        tags: {
          fcx_labs_error_source: "access_token",
        },
      }
    );

    window?.location?.replace(
      `/login?redirectTo=${window?.location?.pathname}`
    );

    return false;
  }

  return true;
};

export { isAccessTokenIssuerValid };
