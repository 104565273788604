import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const TrackingStatusOrderOnTheCarrier = ({
  width = 36,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Pedido na transportadora",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M24.0712 17.7261H19.6268C19.1666 17.7261 18.7935 17.353 18.7935 16.8928V14.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="23.4165"
        cy="22.25"
        rx="1.25"
        ry="1.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3335 14.3333H19.1807C20.3611 14.3333 21.4725 14.889 22.1807 15.8333L23.0816 17.0344C23.2368 17.2414 23.4381 17.4094 23.6695 17.5251L25.4122 18.3964C25.9768 18.6787 26.3335 19.2558 26.3335 19.8871V21.2083C26.3335 21.7835 25.8671 22.2499 25.2918 22.2499H24.6668"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1665 22.2499H19.6665"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3318 23.5001H14.2484C14.0274 23.5001 13.8155 23.4122 13.6594 23.2559C13.5032 23.0995 13.4157 22.8875 13.416 22.6665V20.5831C13.416 20.3621 13.5039 20.1502 13.6602 19.9941C13.8166 19.8379 14.0287 19.7504 14.2496 19.7507H16.333C16.554 19.7507 16.7659 19.8386 16.922 19.995C17.0782 20.1513 17.1657 20.3634 17.1654 20.5843V22.6678C17.1654 22.8887 17.0775 23.1006 16.9212 23.2568C16.7648 23.4129 16.5528 23.5004 16.3318 23.5001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5823 23.5001H10.5001C10.2789 23.5004 10.0668 23.4126 9.91037 23.2562C9.75398 23.0999 9.66624 22.8877 9.6665 22.6665V20.5831C9.6665 20.3621 9.75437 20.1502 9.91074 19.9941C10.0671 19.8379 10.2791 19.7504 10.5001 19.7507H12.5835C12.8045 19.7507 13.0164 19.8386 13.1725 19.995C13.3287 20.1513 13.4162 20.3634 13.4159 20.5843V22.6678C13.4159 22.8887 13.328 23.1006 13.1716 23.2568C13.0153 23.4129 12.8032 23.5004 12.5823 23.5001V23.5001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.457 19.7506H12.3736C12.1525 19.7507 11.9405 19.6629 11.7841 19.5065C11.6278 19.3502 11.54 19.1381 11.54 18.917V16.8336C11.54 16.6125 11.6278 16.4004 11.7841 16.2441C11.9405 16.0878 12.1525 15.9999 12.3736 16H14.457C14.6782 15.9999 14.8902 16.0878 15.0466 16.2441C15.2029 16.4004 15.2907 16.6125 15.2907 16.8336V18.917C15.2909 19.1382 15.2032 19.3504 15.0468 19.5068C14.8904 19.6632 14.6782 19.7509 14.457 19.7506V19.7506Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5 14.3334V12.6993C25.5 11.9427 25.0898 11.2456 24.4285 10.8781L19.2146 7.98139C18.4595 7.56187 17.5413 7.56187 16.7863 7.98139L11.5715 10.8786C10.9102 11.246 10.5 11.9432 10.5 12.6997V13.5001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
