import ReactImageMagnify from "react-image-magnify";
import { ZoomImagesProps } from "typing";

export const ImageMagnify = ({
  smallImage,
  largeImage,
  enlargedImagePosition,
  style,
}: ZoomImagesProps) => {
  return (
    <ReactImageMagnify
      {...{
        smallImage,
        largeImage,
        enlargedImagePosition,
        style,
      }}
    />
  );
};
