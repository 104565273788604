import { appMonitoringClient } from "implementations";
import {
  Title,
  ShowTitle,
  ShowSubTitle,
  SubTitle,
  CustomCountdownProperty,
  Format,
  DesktopImage,
  MobileImage,
  Date,
  Colors,
  ShowProperties,
  BackgroundType,
  BackgroundColor,
  BranchDto,
} from "typing";

const customCountdownData = (properties: CustomCountdownProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const showTitle = (
    properties?.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const subTitle = (
    properties?.find(({ key }) => key === "subTitle") as SubTitle
  )?.value;

  const showSubTitle = (
    properties?.find(({ key }) => key === "showSubTitle") as ShowSubTitle
  )?.value;

  const date = (properties?.find(({ key }) => key === "date") as Date)?.value;

  const titleColor = (
    properties?.find(({ key }) => key === "titleColor") as Colors
  )?.value;

  const subTitleColor = (
    properties?.find(({ key }) => key === "subTitleColor") as Colors
  )?.value;

  const separatorColor = (
    properties?.find(({ key }) => key === "separatorColor") as Colors
  )?.value;

  const showSeparator = (
    properties?.find(({ key }) => key === "showSeparator") as ShowProperties
  )?.value;

  const showUnits = (
    properties?.find(({ key }) => key === "showUnits") as ShowProperties
  )?.value;

  const format = (properties?.find(({ key }) => key === "format") as Format)
    ?.value;

  const desktopImage = (
    properties?.find(({ key }) => key === "desktopImage") as DesktopImage
  )?.value;

  const mobileImage = (
    properties?.find(({ key }) => key === "mobileImage") as MobileImage
  )?.value;

  const backgroundType = (
    properties?.find(({ key }) => key === "background") as BackgroundType
  )?.value;

  const backgroundColor = (
    properties?.find(({ key }) => key === "backgroundColor") as BackgroundColor
  )?.value;

  const dateColor = (
    properties?.find(({ key }) => key === "dateColor") as Colors
  )?.value;

  return {
    title,
    showTitle,
    subTitle,
    date,
    titleColor,
    subTitleColor,
    showSubTitle,
    showUnits,
    showSeparator,
    separatorColor,
    format,
    desktopImage,
    mobileImage,
    backgroundColor,
    backgroundType,
    dateColor,
  };
};

const handleComponentWarning = (
  branches: BranchDto[] | undefined,
  currentPathName: string,
  position: number | undefined
) => {
  if (!branches?.length) {
    appMonitoringClient.captureMessage(
      `Um componente de Countdown, localizado em ${currentPathName} na posição ${position}, não possui nenhuma configuração de branches.`,
      "warning"
    );
  }
};

export { customCountdownData, handleComponentWarning };
