/// <reference types="@welldone-software/why-did-you-render" />

import React from "react";

if (process.env.NODE_ENV === "development" && typeof window === "object") {
  // eslint-disable-next-line
  const whyDidYouRender = require("@welldone-software/why-did-you-render");

  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    // trackExtraHooks: [
    //   "useClickOutside",
    //   "useMediaQuery",
    //   "useCurrentPathName",
    //   "useDebounce",
    //   "useToast",
    // ],
  });
}

export {};
