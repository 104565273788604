import styled from "styled-components";
import { theme } from "ui";

export const GridPlaylistsCardStyled = styled.div`
  display: flex;
  flex-basis: 153px;
  flex-grow: 1;
  flex-direction: column;
  height: 120px;
  width: 153px;
  max-width: 153px;
  background-color: ${theme.colors.neutral.white};
  border: 1px solid ${theme.colors.neutral["100"]};
`;

export const GridPlaylistsImage = styled.figure`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const GridPlaylistsCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const GridPlaylistsDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const GridPlaylistsDescription = styled.span`
  max-height: 30px;
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${theme.colors.neutral["520"]};
  padding: 0px 12px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${theme.typography.fontWeight["400"]};
`;
