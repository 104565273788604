import { IUniqueClientActions } from "typing";
import { useUniqueClientModalData } from "./data";
import { UniqueClientModalUI } from "./ui";

const UniqueClientModal = () => {
  const { getUniqueClientModalContent } = useUniqueClientModalData();

  return (
    <UniqueClientModalUI
      {...(Object.fromEntries(
        getUniqueClientModalContent()
      ) as unknown as IUniqueClientActions)}
    />
  );
};

export { UniqueClientModal };
