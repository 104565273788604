import { formatCurrency } from "../formatCurrency";

interface IDescriptionParcelsText {
  spotPrice: number | null;
  salePrice: number;
  maxNumberInstallments: number | null;
  shortDescription?: boolean;
}
const descriptionParcelsText = ({
  maxNumberInstallments,
  spotPrice,
  salePrice,
  shortDescription = false,
}: IDescriptionParcelsText): string => {
  const hasSpotPriceDescriptionText =
    spotPrice && spotPrice !== salePrice && spotPrice <= salePrice;
  const maxNumberInstallmentsEnforced = maxNumberInstallments || 0;
  const formattedSalePrice = formatCurrency(salePrice || 0);
  const formattedInstallmentPrice = formatCurrency(
    (salePrice || 0) / +maxNumberInstallmentsEnforced
  );

  if (shortDescription) {
    return hasSpotPriceDescriptionText
      ? `ou ${maxNumberInstallmentsEnforced}x de ${formattedInstallmentPrice} s/ juros`
      : `em ${maxNumberInstallmentsEnforced}x de ${formattedInstallmentPrice} s/ juros`;
  }

  return hasSpotPriceDescriptionText
    ? `ou ${formattedSalePrice} em até ${maxNumberInstallmentsEnforced}x de ${formattedInstallmentPrice} sem juros`
    : `em até ${maxNumberInstallmentsEnforced}x de ${formattedInstallmentPrice} sem juros`;
};

export { descriptionParcelsText };
