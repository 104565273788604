import { NotificationApi, useToast } from "application";
import {
  useLoginAuthentication,
  useShallow,
  useTwoFactor,
} from "application/state-manager";
import { appMonitoringClient } from "implementations";
import { useCallback, useMemo, useState } from "react";
import {
  ClientDto,
  FAChannelsValues,
  FATypeValues,
  TwoFactorPublicCodeDto,
} from "typing";
import { EmailIcon, MessagesIcon } from "ui";
import {
  maskEmail,
  maskPhoneNumber,
} from "../../../../../../2fa/Content/useTwoFactorContent";

interface UseLoginAuthenticationModalProps {
  accessToken: string;
  loggedClient: ClientDto | null | undefined;
  notificationApi: NotificationApi;
}

const feedbackErrorTitle = "Erro ao enviar o código";
const defaultErrorMessage =
  "Ocorreu um erro ao enviar o código de verificação.";

const methods = (
  changeCurrentStep: (step: "firstStep" | "secondStep") => void,
  sendTokenByChannel: (faType: number, channel: number) => void,
  userIdentification: ClientDto | null | undefined
) => {
  return [
    {
      title: "SMS",
      description: maskPhoneNumber(userIdentification?.phoneNumber as string),
      handleOptionAction: () => {
        sendTokenByChannel(
          FATypeValues.AccountValidatePhone2FA,
          FAChannelsValues.SMS
        );
        changeCurrentStep("secondStep");
      },
      icon: <MessagesIcon />,
      isValid: true,
    },
    {
      title: "E-mail",
      description: maskEmail(userIdentification?.email as string),
      handleOptionAction: () => {
        sendTokenByChannel(
          FATypeValues.AccountValidateEMail2FA,
          FAChannelsValues.Email
        );
        changeCurrentStep("secondStep");
      },
      icon: <EmailIcon />,
      isValid: true,
    },
  ];
};

const useLoginAuthenticationModal = ({
  accessToken,
  notificationApi,
  loggedClient,
}: UseLoginAuthenticationModalProps) => {
  const [isUnderAction, setIsUnderAction] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { addToast } = useToast();
  const {
    tokenCode,
    setChannelSelected,
    channelSelected,
    setIsValidationActive,
    setIsBlockedToSendCode,
    setIsTokenCodeValid,
    setValidateCode,
    setFaType,
    clearTimer,
    getTimer,
    availableMethods,
  } = useTwoFactor(
    useShallow((state) => ({
      tokenCode: state.tokenCode,
      setChannelSelected: state.setChannelSelected,
      channelSelected: state.channelSelected,
      setIsValidationActive: state.setIsValidationActive,
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      setValidateCode: state.setValidateCode,
      setFaType: state.setFaType,
      clearTimer: state.clearTimer,
      getTimer: state.getTimer,
      availableMethods: state.availableMethods,
    }))
  );

  const { setIsLoginAuthenticationModalOpen, currentStep, changeLoginStep } =
    useLoginAuthentication(
      useShallow((state) => ({
        setIsLoginAuthenticationModalOpen:
          state.setIsLoginAuthenticationModalOpen,
        currentStep: state.currentStep,
        changeLoginStep: state.changeLoginStep,
      }))
    );

  const handleCloseLoginAuthenticationModal = () => {
    setIsValidationActive(false);
    setIsLoginAuthenticationModalOpen(false);
  };

  const canChooseAnotherMethod = useMemo(() => {
    return (
      (availableMethods?.emailConfirmed &&
        availableMethods?.phoneNumberConfirmed) ||
      (!availableMethods?.emailConfirmed &&
        !availableMethods?.phoneNumberConfirmed)
    );
  }, [
    availableMethods?.emailConfirmed,
    availableMethods?.phoneNumberConfirmed,
  ]);

  const handleValidateAccountInfo = useCallback(async () => {
    try {
      setIsUnderAction(true);
      const faType =
        channelSelected === 2
          ? FATypeValues.AccountValidateEMail2FA
          : channelSelected === 1
          ? FATypeValues.AccountValidatePhone2FA
          : null;

      const body = {
        email: loggedClient?.email,
        cpf: loggedClient?.cpf,
        phoneNumber: loggedClient?.phoneNumber,
        channel: Number(channelSelected),
        token: tokenCode,
        faType,
        jwt: accessToken,
      };

      const { isValid, errors } = await notificationApi.validateTwoFactorCode(
        body
      );

      if (!isValid && errors?.statusCode?.includes("222")) {
        setIsBlockedToSendCode(true);
        setIsValidationActive(true);
        setHasError(true);
        return;
      }

      if (!isValid && !errors?.statusCode?.includes("222")) {
        setIsTokenCodeValid(false);
        setValidateCode(true);
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: "Erro ao validar o código",
          description:
            `${errors?.messages?.[0]}` || "Ocorreu um erro ao validar o código",
          timerInMilliseconds: 3000,
        });
        return;
      }

      clearTimer(Number(faType), channelSelected);

      addToast({
        title: "Código validado com sucesso!",
        description: "Parabéns, sua conta agora está mais protegida!",
        isNewToast: true,
        newToastTheme: "light",
        type: "success",
        timerInMilliseconds: 3000,
      });
      setIsValidationActive(false);
      setIsLoginAuthenticationModalOpen(false);
    } catch (error) {
      appMonitoringClient.captureMessage(
        `Ocorreu um erro no processo de validação do código função handleValidateAccountInfo em LoginAuthenticationModal.\nErro: ${error}`,
        {
          level: "error",
        }
      );
      setHasError(true);
      setIsTokenCodeValid(false);
      addToast({
        isNewToast: true,
        newToastTheme: "dark",
        type: "error",
        title: "Erro ao validar o código",
        description: "Ocorreu um erro ao validar o código",
        timerInMilliseconds: 3000,
      });
    } finally {
      setIsUnderAction(false);
    }
  }, [
    accessToken,
    addToast,
    channelSelected,
    clearTimer,
    loggedClient?.cpf,
    loggedClient?.email,
    loggedClient?.phoneNumber,
    notificationApi,
    setIsBlockedToSendCode,
    setIsLoginAuthenticationModalOpen,
    setIsTokenCodeValid,
    setIsValidationActive,
    setValidateCode,
    tokenCode,
  ]);

  const sendTokenByChannel = async (faType: number, channel: number) => {
    try {
      setIsUnderAction(true);
      setChannelSelected(channel);
      setFaType(faType);

      const { isTimerRunning } = getTimer(faType, channel);

      if (isTimerRunning) {
        setIsValidationActive(true);
        if (currentStep === "verifyIdentity") {
          changeLoginStep("secondStep");
        }
        return;
      }

      if (canChooseAnotherMethod) {
        changeLoginStep("verifyIdentity");
      }

      const requestBody: TwoFactorPublicCodeDto = {
        email: loggedClient?.email,
        cpf: loggedClient?.cpf,
        faType: Number(faType),
        channel: Number(channel),
        phoneNumber: loggedClient?.phoneNumber,
        token: accessToken,
      };
      const { data } = await notificationApi.sendTwoFactorCode(requestBody);

      if (
        data?.validationResult?.errors?.[0]?.errorCode === "222" ||
        [
          "Tente novamente em 24 horas.",
          "Operação bloqueada devido ao número de tentativas excessivas na solicitação do código.",
        ]?.includes(String(data?.validationResult?.errors?.[0]?.errorMessage))
      ) {
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: feedbackErrorTitle,
          description: defaultErrorMessage,
          timerInMilliseconds: 3000,
        });
        setIsLoginAuthenticationModalOpen(false);
        return;
      }

      if (
        data?.validationResult?.errors?.[0]?.errorMessage ===
        "Cliente não encontrado"
      ) {
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: feedbackErrorTitle,
          description: `${data?.validationResult?.errors?.[0]?.errorMessage}`,
          timerInMilliseconds: 3000,
        });
        return;
      }

      if (data?.validationResult?.isValid) {
        addToast({
          title: "Código enviado!",
          isNewToast: true,
          newToastTheme: "light",
          type: "success",
          timerInMilliseconds: 3000,
        });
        changeLoginStep("secondStep");
        return;
      }

      addToast({
        isNewToast: true,
        newToastTheme: "dark",
        type: "error",
        title: feedbackErrorTitle,
        description: defaultErrorMessage,
        timerInMilliseconds: 3000,
      });
    } catch (error) {
      appMonitoringClient.captureMessage(
        `Ocorreu um erro ao enviar o código de verificação pela função sendTokenByChannel em LoginAuthenticationModal.\nErro: ${error}`,
        {
          level: "error",
        }
      );
      addToast({
        isNewToast: true,
        newToastTheme: "dark",
        type: "error",
        title: feedbackErrorTitle,
        description: defaultErrorMessage,
        timerInMilliseconds: 3000,
      });
      setHasError(true);
      setIsLoginAuthenticationModalOpen(false);
    } finally {
      setIsUnderAction(false);
    }
  };

  return {
    handleCloseLoginAuthenticationModal,
    handleValidateAccountInfo,
    sendTokenByChannel,
    isUnderAction,
    setIsUnderAction,
    hasError,
  };
};

export { feedbackErrorTitle, methods, useLoginAuthenticationModal };
