import { IconProps } from "typing";
import { theme } from "../themes";

export const LinkedIn = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["280"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M16.3636 0H1.63636C0.732273 0 0 0.732273 0 1.63636V16.3636C0 17.2677 0.732273 18 1.63636 18H16.3636C17.2677 18 18 17.2677 18 16.3636V1.63636C18 0.732273 17.2677 0 16.3636 0ZM5.68964 14.7273H3.276V6.96109H5.68964V14.7273ZM4.45827 5.85082C3.68018 5.85082 3.051 5.22 3.051 4.44355C3.051 3.66709 3.681 3.03709 4.45827 3.03709C5.23391 3.03709 5.86473 3.66791 5.86473 4.44355C5.86473 5.22 5.23391 5.85082 4.45827 5.85082ZM14.7305 14.7273H12.3185V10.9505C12.3185 10.0497 12.3022 8.89118 11.0643 8.89118C9.80836 8.89118 9.61527 9.87218 9.61527 10.8851V14.7273H7.20327V6.96109H9.51873V8.02227H9.55145C9.87382 7.41191 10.6609 6.768 11.835 6.768C14.2789 6.768 14.7305 8.37655 14.7305 10.4678V14.7273Z"
        fill={color}
      />
    </svg>
  );
};
