import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ICookie, IHeatmapClient } from "app-domain/abstractions";

export interface CookiesContextData {
  statementCookiesModal: boolean;
  handleCloseCookiesModal(): void;
}

interface CookiesProviderProps {
  children: ReactNode;
  cookie: ICookie;
  heatmapClient: IHeatmapClient;
}

const cookieForCookies = "@FC:Ecom:Cookie:HadVisualization";
const optionsMaxAgeEqualOneYear = {
  options: {
    maxAge: 60 * 60 * 24 * 365,
  },
};

const CookiesContext = createContext({} as CookiesContextData);

export const CookiesProvider = ({
  children,
  cookie,
  heatmapClient,
}: CookiesProviderProps) => {
  const [statementCookiesModal, setStatementCookiesModal] =
    useState<boolean>(false);

  const handleCloseCookiesModal = useCallback(() => {
    setStatementCookiesModal(false);

    heatmapClient.cookieConsent();

    cookie.setCookie({
      name: cookieForCookies,
      value: String(true),
      options: optionsMaxAgeEqualOneYear.options,
    });
  }, [cookie, heatmapClient]);

  const onShowCookiesModal = useCallback(() => {
    const hadVisualization = cookie.getCookie({
      name: cookieForCookies,
    });

    if (!hadVisualization) {
      setStatementCookiesModal(true);
    }
  }, [cookie]);

  useEffect(() => onShowCookiesModal(), [onShowCookiesModal]);

  const cookiesContextProviderValue = useMemo(() => {
    return {
      statementCookiesModal,
      handleCloseCookiesModal,
    };
  }, [handleCloseCookiesModal, statementCookiesModal]);

  return (
    <CookiesContext.Provider value={cookiesContextProviderValue}>
      {children}
    </CookiesContext.Provider>
  );
};

export const useCookies = () => {
  const cookiesContext = useContext(CookiesContext);
  if (!cookiesContext) {
    throw new Error("useCookies must be used within an CookiesProvider");
  }
  return cookiesContext;
};
