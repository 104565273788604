import styled from "styled-components";
import { theme } from "ui/themes";

export const Container = styled.div`
  .active {
    border-color: ${theme.colors.primary["200"]} !important;
  }
  .disabled:hover {
    border-color: rgba(255, 0, 0, 0.2) !important;
    filter: brightness(0.8);
    transition: border 100ms !important;
    transition: filter 100ms !important;
  }
`;

export const BoxMiniatureImage = styled.div`
  height: 4rem;
  width: 4rem;
  border: 2px solid ${theme.colors.neutral["95"]};
  overflow: hidden;
  cursor: pointer;
  transition: border 500ms;

  .active {
    border-color: ${theme.colors.primary["200"]};
  }
  .disabled:hover {
    border-color: ${theme.colors.primary["200"]};
  }
`;
