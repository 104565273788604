import { IconProps } from "typing";
import { theme } from "../themes";

export const DeliveryTruckPinIcon = ({
  width = 20,
  height = 20,
  strokeWidth = "1.5",
  color = theme.colors.neutral["350"],
  alt = "Caminhão de entrega com pino de localização",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3333 11.6632H15.4167C15.1865 11.6632 15 11.4766 15 11.2465V8.32983H17.6667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.464 15.3661C15.8215 15.7236 15.9284 16.2612 15.735 16.7283C15.5415 17.1954 15.0858 17.5 14.5802 17.5C14.0746 17.5 13.6188 17.1955 13.4253 16.7284C13.2318 16.2614 13.3387 15.7237 13.6962 15.3662L13.6963 15.3661C14.1844 14.878 14.9758 14.878 15.4639 15.3661V15.3661"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.29696 15.3662C6.65448 15.7237 6.76145 16.2613 6.568 16.7284C6.37454 17.1955 5.91876 17.5001 5.41319 17.5001C4.90761 17.5002 4.4518 17.1956 4.2583 16.7285C4.0648 16.2615 4.17171 15.7238 4.52919 15.3663V15.3663L4.52944 15.366C5.01749 14.878 5.80876 14.878 6.2968 15.366L6.29694 15.3662V15.3662"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.401 15.8334H6.5918"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7587 15.8333H17.5C17.9602 15.8333 18.3333 15.4602 18.3333 14.9999V10.3176C18.3333 10.1057 18.2929 9.89576 18.2142 9.699L17.0852 6.87743C16.8321 6.24472 16.2193 5.82984 15.5378 5.82983H12.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.23498 15.8333H2.50033C2.04009 15.8333 1.66699 15.4602 1.66699 15V10"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 12.4967H11.667C12.1272 12.4967 12.5003 12.1236 12.5003 11.6633V4.99666C12.5003 4.53643 12.1272 4.16333 11.667 4.16333H10.0003"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 4.58325C1.66699 5.94869 3.15699 7.40727 4.00699 8.12855C4.34268 8.40149 4.82381 8.40149 5.15949 8.12855C6.01033 7.40727 7.50033 5.94869 7.50033 4.58325"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58449 4.36675C4.70405 4.36721 4.80063 4.46442 4.80033 4.58397C4.80002 4.70353 4.70294 4.80024 4.58338 4.80009C4.46383 4.79993 4.36699 4.70297 4.36699 4.58342C4.36669 4.52572 4.38953 4.4703 4.43041 4.42958C4.47129 4.38885 4.52679 4.36622 4.58449 4.36675"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 4.58342V4.58342C1.66699 2.97258 2.97283 1.66675 4.58366 1.66675V1.66675C6.19449 1.66675 7.50033 2.97258 7.50033 4.58342V4.58342"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
