import { useAuth } from "application/context/Identity/AuthContext";
import { useState } from "react";
import {
  AppleOAuthIcon,
  ButtonAuthenticationOAuth,
  GoogleOAuthIcon,
  WrapperOAuthButtons,
} from "./styles";

const FormHeader = () => {
  const { onAuthenticateUserOAuth } = useAuth();

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <WrapperOAuthButtons>
      <ButtonAuthenticationOAuth
        type="button"
        provider="google"
        onClick={() => onAuthenticateUserOAuth("google")}
        data-cy="button-auth-google"
      >
        <GoogleOAuthIcon />
      </ButtonAuthenticationOAuth>
      <ButtonAuthenticationOAuth
        type="button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        provider="apple"
        onClick={() => onAuthenticateUserOAuth("apple")}
        data-cy="button-auth-apple"
      >
        <AppleOAuthIcon isHovered={isHovered} />
      </ButtonAuthenticationOAuth>
    </WrapperOAuthButtons>
  );
};

export default FormHeader;
