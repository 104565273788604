import {
  CustomStoriesProperty,
  Title,
  ShowTitle,
  TextAlign,
  SubTitle,
  ShowSubTitle,
  FormatStories,
  Size,
  HasShadow,
} from "typing";

const customStoriesData = (properties: CustomStoriesProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const showTitle = (
    properties.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const textAlign = (
    properties.find(({ key }) => key === "textAlign") as TextAlign
  )?.value;

  const subTitle = (
    properties.find(({ key }) => key === "subTitle") as SubTitle
  )?.value;

  const showSubTitle = (
    properties.find(({ key }) => key === "showSubTitle") as ShowSubTitle
  )?.value;

  const format = (
    properties.find(({ key }) => key === "format") as FormatStories
  )?.value;

  const size = (properties.find(({ key }) => key === "size") as Size)?.value;

  const hasShadow = (
    properties.find(({ key }) => key === "hasShadow") as HasShadow
  )?.value;

  return {
    title,
    showTitle,
    textAlign,
    subTitle,
    showSubTitle,
    format,
    size,
    hasShadow,
  };
};

export { customStoriesData };
