import { IconProps } from "typing";
import { theme } from "../themes";

export const ShopIcon = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M5.66919 3.75195H18.5623L21.4309 8.74808H2.85544L5.66919 3.75195Z"
        stroke={color}
      />
      <path
        d="M2.85677 8.70435C2.28022 11.3894 7.00793 12.6578 7.48564 8.83613C7.25502 11.62 11.7356 12.3943 12.1145 8.96791C12.1619 11.7042 16.4329 12.1972 16.8093 9.08322C17.4188 12.5919 22.013 11.289 21.4382 8.77024"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M4.40503 11.373V19.5378C4.40503 19.8139 4.62889 20.0378 4.90503 20.0378H19.3645C19.6406 20.0378 19.8645 19.8139 19.8645 19.5378V11.373"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M10.9118 20.252V14.1357C10.9118 13.5835 10.4641 13.1357 9.91179 13.1357H8.09009C7.5378 13.1357 7.09009 13.5835 7.09009 14.1357V20.252"
        stroke={color}
      />
      <path
        d="M13.2627 13.1414H16.6328C16.909 13.1414 17.1329 13.3652 17.1329 13.6414V16.3855C17.1329 16.6617 16.909 16.8855 16.6329 16.8855H13.2627C12.9866 16.8855 12.7627 16.6617 12.7627 16.3855V13.6414C12.7627 13.3652 12.9866 13.1414 13.2627 13.1414Z"
        stroke={color}
        strokeLinejoin="round"
      />
    </svg>
  );
};
