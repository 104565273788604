import Link from "next/link";
import styled from "styled-components";
import { theme } from "ui";

export const LinkStyled = styled(Link)`
  display: flex;
`;

export const InnerLink = styled.a``;

export const ArrowStyled = styled.div`
  &:after {
    content: " ";
    display: block;
    width: 5px;
    height: 5px;
    border-top: 1px solid ${theme.colors.neutral["315"]};
    border-left: 1px solid ${theme.colors.neutral["315"]};
    transform: rotate(135deg);
    top: 5px;
    left: 90%;
    z-index: 2;
  }
`;

export const CustomBreadcrumbNavigation = styled.nav``;

export const CustomBreadcrumbList = styled.ol``;

export const CustomBreadcrumbItem = styled.li``;

export const BreadcrumbsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.space["x2.5"]};

  ol {
    display: flex;
    align-items: center;
    list-style: none;

    li {
      /* &:last-child a {
        pointer-events: none;
        cursor: default;
      } */

      :last-child a::after {
        border: 0;
      }
    }

    li a {
      position: relative;
      margin-right: 15px;
      padding-right: 15px;
      text-decoration: none;
      color: ${theme.colors.neutral["450"]};
    }

    li a::after {
      content: " ";
      display: block;
      margin: 30px auto;
      width: 5px;
      height: 5px;
      border-top: 1px solid ${theme.colors.neutral["315"]};
      border-left: 1px solid ${theme.colors.neutral["315"]};
      transform: rotate(135deg);
      position: absolute;
      top: 5px;
      margin-top: 0;
      left: 90%;
      z-index: 2;
    }
  }
`;
