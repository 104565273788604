import { CircleCheckIcon, CircleErrorIcon, theme } from "ui";
import { useEffect } from "react";
import { buildTestIds, useValidatePassword } from "application";
import {
  PasswordStrength,
  PasswordValidationContainer,
  StrengthIndicator,
  StrengthIndicatorItem,
  StrengthIndicatorItems,
  StrengthIndicatorText,
} from "./styles";

interface PasswordValidationProps {
  userPassword: string;
  userConfirmPassword?: string;
  marginTop?: string;
  showComponent: boolean;
  onChangePasswordStrength: (value: number) => void;
}

const PasswordValidation = ({
  userPassword,
  userConfirmPassword,
  marginTop,
  showComponent,
  onChangePasswordStrength,
}: PasswordValidationProps) => {
  const {
    passwordHasLowerCaseLetters,
    passwordHasMoreThanSevenCharacters,
    passwordHasNumbers,
    passwordHasUpperCaseLetters,
    passwordsMatch,
    validItems,
    strongPassword,
  } = useValidatePassword({ userPassword, userConfirmPassword });

  const verifyPasswordStrength = () => {
    if (strongPassword) return "forte";
    if (validItems === 5) return "média";
    return "baixa";
  };

  const iconVerification = (verification: boolean) => {
    return verification ? <CircleCheckIcon /> : <CircleErrorIcon />;
  };

  const textColor = (verification: boolean) => {
    return verification
      ? theme.colors.secondary["380"]
      : theme.colors.primary["155"];
  };

  useEffect(() => {
    onChangePasswordStrength(validItems);
  }, [onChangePasswordStrength, validItems]);

  if (!showComponent) return null;

  return (
    <PasswordValidationContainer
      {...buildTestIds("password-validation-container")}
      marginTop={marginTop}
    >
      <StrengthIndicator {...buildTestIds("strength-indicator")}>
        Força da senha:
        <PasswordStrength {...buildTestIds("password-strength")}>
          {verifyPasswordStrength()}
        </PasswordStrength>
      </StrengthIndicator>
      <StrengthIndicatorItems {...buildTestIds("strength-indicator-items")}>
        <StrengthIndicatorItem
          {...buildTestIds("strength-indicator-characters")}
        >
          {iconVerification(passwordHasMoreThanSevenCharacters)}
          <StrengthIndicatorText
            color={textColor(passwordHasMoreThanSevenCharacters)}
            {...buildTestIds("strength-indicator-characters-text")}
          >
            Contém pelo menos 8 caracteres
          </StrengthIndicatorText>
        </StrengthIndicatorItem>
        <StrengthIndicatorItem {...buildTestIds("strength-indicator-numbers")}>
          {iconVerification(passwordHasNumbers)}
          <StrengthIndicatorText
            {...buildTestIds("strength-indicator-numbers-text")}
            color={textColor(passwordHasNumbers)}
          >
            Contém número
          </StrengthIndicatorText>
        </StrengthIndicatorItem>
        <StrengthIndicatorItem
          {...buildTestIds("strength-indicator-upper-case-letters")}
        >
          {iconVerification(passwordHasUpperCaseLetters)}
          <StrengthIndicatorText
            {...buildTestIds("strength-indicator-upper-case-letters-text")}
            color={textColor(passwordHasUpperCaseLetters)}
          >
            Contém letra maiúscula
          </StrengthIndicatorText>
        </StrengthIndicatorItem>
        <StrengthIndicatorItem
          {...buildTestIds("strength-indicator-lower-case-letters")}
        >
          {iconVerification(passwordHasLowerCaseLetters)}
          <StrengthIndicatorText
            {...buildTestIds("strength-indicator-lower-case-letters-text")}
            color={textColor(passwordHasLowerCaseLetters)}
          >
            Contém letra minúscula
          </StrengthIndicatorText>
        </StrengthIndicatorItem>
        <StrengthIndicatorItem
          {...buildTestIds("strength-indicator-passwords-match")}
        >
          {iconVerification(passwordsMatch)}

          <StrengthIndicatorText
            {...buildTestIds("strength-indicator-passwords-match-text")}
            color={textColor(passwordsMatch)}
          >
            Senhas correspondem
          </StrengthIndicatorText>
        </StrengthIndicatorItem>
      </StrengthIndicatorItems>
    </PasswordValidationContainer>
  );
};
export { PasswordValidation };
