import { useState } from "react";
import { IconDto, UtilInfoDto } from "typing";
import {
  Accordion,
  Column,
  theme,
  FlexContainer,
  Row,
  SpaceWrapper,
  Container,
  ArrowDown,
  ArrowUp,
  mappedIcons,
} from "ui";
import UtilInfoOption from "../../../Option";
import { SupportContactTitle } from "../../../../SupportContact/styles";

interface UtilInfoLinkOptionMobileListProps extends UtilInfoDto {
  isBlackTheme: boolean;
}

const iconsColor = (icon: IconDto, isBlackFridayTheme: boolean) => {
  return isBlackFridayTheme
    ? theme.colors.neutral.white
    : icon?.color || undefined;
};

const UtilInfoLinkOptionMobileList = ({
  title,
  icon,
  options,
  isBlackTheme,
}: UtilInfoLinkOptionMobileListProps) => {
  const [titleClicked, setTitleClicked] = useState(false);

  const toggleTitleClicked = () => {
    setTitleClicked((state) => !state);
  };

  return (
    <>
      <Container>
        <Row
          display="flex"
          alignItems="center"
          justifyContent="center"
          data-cy={`footer-options-title-clicked-${icon?.title}`}
          onClick={toggleTitleClicked}
        >
          <Column xs="11">
            <FlexContainer gap={theme.space.x4} alignItems="center">
              {icon &&
                mappedIcons(
                  icon?.title,
                  {
                    width: icon?.width ? Number(icon.width) : undefined,
                    height: icon?.height ? Number(icon.height) : undefined,
                    color: iconsColor(icon, isBlackTheme),
                  },
                  {
                    secondColor: iconsColor(icon, isBlackTheme),
                  }
                )}
              <SupportContactTitle
                isBlackTheme={isBlackTheme}
                fontSize={theme.typography.fontSize.sm.x5}
                fontWeight={theme.typography.fontWeight["400"]}
              >
                {title}
              </SupportContactTitle>
            </FlexContainer>
          </Column>
          <Column xs="1">
            {titleClicked ? (
              <ArrowUp
                color={isBlackTheme ? theme.colors.neutral.white : "initial"}
              />
            ) : (
              <ArrowDown
                color={isBlackTheme ? theme.colors.neutral.white : "initial"}
              />
            )}
          </Column>
        </Row>
      </Container>

      <Accordion
        show={titleClicked}
        maxHeight={theme.space.x160}
        data-cy={`footer-wrapper-${icon?.title}`}
      >
        <SpaceWrapper
          data-cy={`footer-option-title-${icon?.title}`}
          margin={`${theme.space.x2} ${theme.space.x11}`}
        >
          {options?.length &&
            options.map((option) => (
              <UtilInfoOption
                isBlackTheme={isBlackTheme}
                key={option.title}
                {...option}
              />
            ))}
        </SpaceWrapper>
      </Accordion>
    </>
  );
};

export { UtilInfoLinkOptionMobileList };
