import { IconProps } from "typing";
import { theme } from "../themes";

export const PickupStore = ({
  width = 24,
  height = 24,
  alt = "Retirada em loja",
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-labelledby={alt}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 20H12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99995 20L4.00005 9.98749"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7236 6.47765C22.0187 7.03345 22.0774 7.68474 21.8863 8.28433C21.5559 9.32453 20.5797 10.0228 19.4885 9.99943C18.3973 9.9761 17.4519 9.23678 17.1662 8.18341C17.1481 8.12223 17.0919 8.08025 17.0281 8.08025C16.9643 8.08025 16.9081 8.12223 16.89 8.18341C16.5982 9.25555 15.6246 9.99951 14.5135 9.99939C13.4024 9.99927 12.4289 9.2551 12.1374 8.1829C12.1193 8.12169 12.0631 8.07968 11.9993 8.07968C11.9355 8.07968 11.8793 8.12169 11.8612 8.1829C11.5695 9.2551 10.596 9.99918 9.48483 9.99914C8.37366 9.9991 7.40016 9.25495 7.10858 8.18273C7.09045 8.12156 7.03425 8.0796 6.97045 8.0796C6.90665 8.0796 6.85045 8.12156 6.83232 8.18273C6.54658 9.23599 5.60124 9.97521 4.51016 9.99858C3.41908 10.0219 2.44296 9.32387 2.1124 8.28381C1.92126 7.68412 1.97992 7.0327 2.27512 6.47681L3.98184 3.09822C4.32207 2.4247 5.01242 2 5.767 2H18.2313C18.9859 2 19.6762 2.4247 20.0165 3.09822L21.7236 6.47765Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 16.5C15 18.1385 16.788 19.8888 17.808 20.7544C18.2108 21.0819 18.7882 21.0819 19.191 20.7544C20.212 19.8888 22 18.1385 22 16.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5002 16.45C18.5278 16.4501 18.5501 16.4725 18.55 16.5001C18.5499 16.5277 18.5275 16.55 18.4999 16.55C18.4723 16.55 18.45 16.5276 18.45 16.5C18.4499 16.4867 18.4552 16.4739 18.4646 16.4645C18.4741 16.4551 18.4869 16.4499 18.5002 16.45"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 16.5V16.5C15 14.567 16.567 13 18.5 13V13C20.433 13 22 14.567 22 16.5V16.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13H8V16H10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
