import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const FurnitureGiftIcon = ({
  width = 324,
  height = 319,
  color = `${theme.colors.neutral["150"]}`,
  secondColor = `${theme.colors.neutral["white-075"]}`,
  thirdColor = `${theme.colors.primary["900"]}`,
  fourthColor = `${theme.colors.primary["180"]}`,
  fifthColor = `${theme.colors.secondary["410"]}`,
  sixthColor = `${theme.colors.yellow["1100"]}`,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 324 319"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M106 2H0V108H106V2Z" fill={secondColor} />
      <path
        d="M42.5399 72.7001C43.7162 72.7001 44.6711 73.655 44.6711 74.8313L44.6711 86.5114L48.2693 89.3622L53.8325 89.3622L57.4307 86.5114L57.4307 74.8313C57.4307 73.655 58.3856 72.7001 59.5619 72.7001C60.7382 72.7001 61.6931 73.655 61.6931 74.8313L61.6931 87.5493C61.6931 88.1998 61.3887 88.8225 60.8905 89.2239L55.9084 93.1818C55.5348 93.4863 55.0642 93.6523 54.5799 93.6523L47.522 93.6523C47.0376 93.6523 46.5671 93.4863 46.1934 93.1818L41.2114 89.2239C40.6993 88.8225 40.4087 88.1998 40.4087 87.5493L40.4087 74.8313C40.4087 73.655 41.3636 72.7001 42.5399 72.7001Z"
        fill={color}
      />
      <path
        d="M42.5398 80.2923L59.5618 80.2923C60.7381 80.2923 61.693 81.2472 61.693 82.4235C61.693 83.5998 60.7381 84.5547 59.5618 84.5547L42.5398 84.5547C41.3635 84.5547 40.4086 83.5998 40.4086 82.4235C40.4086 81.2472 41.3635 80.2923 42.5398 80.2923Z"
        fill={color}
      />
      <path
        d="M51.0508 12.5974C67.5054 12.5974 80.8877 25.6199 80.8877 41.6179C80.8877 47.5133 79.0748 53.1734 75.6704 58.0171C72.6258 62.321 68.4879 65.7254 63.6443 67.9258L63.6443 72.3128C63.6443 74.8869 61.5131 76.9766 58.8975 76.9766L43.204 76.9766C40.5885 76.9766 38.4572 74.8869 38.4572 72.3128L38.4572 67.9258C33.6136 65.7393 29.4757 62.3349 26.4311 58.0171C23.0129 53.1873 21.2 47.5133 21.2 41.6179C21.2 25.6199 34.5823 12.5974 51.0369 12.5974M58.8836 72.7003C59.1327 72.7003 59.3542 72.5204 59.3542 72.3128L59.3542 66.5004C59.3542 65.6286 59.8939 64.8397 60.7104 64.5076C70.37 60.688 76.6114 51.6927 76.6114 41.604C76.6114 27.9587 65.1389 16.8598 51.0508 16.8598C36.9626 16.8598 25.4901 27.9587 25.4901 41.604C25.4901 51.6927 31.7315 60.6742 41.3911 64.5076C42.2076 64.8259 42.7335 65.6147 42.7335 66.5004L42.7335 72.3128C42.7335 72.5204 42.9549 72.7003 43.204 72.7003L58.8975 72.7003L58.8836 72.7003Z"
        fill={color}
      />
      <path
        d="M214.07 110L107.93 110L107.93 216.14H214.07V110Z"
        fill={thirdColor}
      />
      <path
        d="M214.07 173.037H155.192C153.597 173.037 152.307 171.747 152.307 170.151V143.876H147.567V196.641H152.307V190.847C152.307 189.252 153.597 187.962 155.192 187.962H214.07V193.733H158.076V199.526C158.076 201.122 156.787 202.412 155.192 202.412H144.683C143.088 202.412 141.798 201.122 141.798 199.526V140.991C141.798 139.395 143.088 138.105 144.683 138.105H155.192C156.787 138.105 158.076 139.395 158.076 140.991V167.266H214.07V173.037Z"
        fill={fourthColor}
      />
      <path
        d="M214.07 158.249H162.137C159.897 158.249 158.076 160.071 158.076 162.312C158.076 163.907 156.787 165.197 155.192 165.197C153.597 165.197 152.307 163.907 152.307 162.312C152.307 156.892 156.719 152.479 162.137 152.479H214.07V158.249Z"
        fill={fourthColor}
      />
      <path
        d="M214.07 129.519H162.137C159.897 129.519 158.076 131.341 158.076 133.581V140.993C158.076 142.588 156.787 143.878 155.192 143.878C153.597 143.878 152.307 142.588 152.307 140.993V133.581C152.307 128.161 156.719 123.748 162.137 123.748L214.07 123.748V129.519Z"
        fill={fourthColor}
      />
      <path d="M106 214.141H0V320.141H106V214.141Z" fill={fifthColor} />
      <path
        d="M44.8376 299.369H22.8889C21.3528 299.369 20.1073 298.124 20.1073 296.588V258.807C20.1073 257.631 21.0622 256.676 22.2385 256.676H44.201C45.3774 256.676 46.3323 257.631 46.3323 258.807V295.688C46.7336 296.075 46.9827 296.615 46.9827 297.224C46.9827 298.4 46.0278 299.369 44.8515 299.369M24.3836 295.093H42.0698V260.952H24.3836V295.093Z"
        fill={sixthColor}
      />
      <path
        d="M80.639 299.369H58.6903C57.514 299.369 56.5591 298.414 56.5591 297.224C56.5591 296.629 56.8082 296.089 57.2095 295.688V258.807C57.2095 257.631 58.1644 256.676 59.3407 256.676H81.2894C82.4657 256.676 83.4206 257.631 83.4206 258.807V296.588C83.4206 298.124 82.1751 299.369 80.639 299.369ZM61.4719 295.093H79.1582V260.952H61.4719V295.093Z"
        fill={sixthColor}
      />
      <path
        d="M84.8462 260.955H59.3271C58.1507 260.955 57.1958 260 57.1958 258.824V245.22C56.7945 244.833 56.5454 244.293 56.5454 243.684C56.5454 242.508 57.5003 241.553 58.6766 241.553H84.8462C86.3823 241.553 87.6279 242.798 87.6279 244.334V258.173C87.6279 259.71 86.3823 260.955 84.8462 260.955ZM61.4721 256.679H83.3654V245.815H61.4721V256.679Z"
        fill={sixthColor}
      />
      <path
        d="M44.2008 260.955H18.6817C17.1455 260.955 15.9 259.71 15.9 258.173V244.334C15.9 242.798 17.1455 241.553 18.6817 241.553H44.8513C46.0276 241.553 46.9825 242.508 46.9825 243.684C46.9825 244.279 46.7334 244.833 46.332 245.22V258.824C46.332 260 45.3771 260.955 44.2008 260.955ZM20.1763 256.679H42.0696V245.815H20.1763V256.679Z"
        fill={sixthColor}
      />
      <path
        d="M52.4624 245.814H35.8003C30.749 245.814 25.7808 241.233 25.767 236.569C25.767 234.798 26.2375 231.629 29.3651 230.452C32.4097 229.304 37.1011 230.563 43.3287 234.203C48.2139 237.054 52.504 240.486 54.0678 242.271C54.6213 242.907 54.7459 243.793 54.3999 244.568C54.0539 245.329 53.2928 245.827 52.4486 245.827M31.8285 234.327C31.4271 234.327 31.1088 234.383 30.8597 234.466C30.6383 234.549 30.0294 234.784 30.0294 236.583C30.0294 238.908 33.1017 241.551 35.7864 241.551H46.6777C45.1139 240.389 43.218 239.116 41.1698 237.912C36.6168 235.254 33.5307 234.327 31.8285 234.327Z"
        fill={sixthColor}
      />
      <path
        d="M67.7136 245.812H51.0514C50.2211 245.812 49.46 245.327 49.1001 244.552C48.7542 243.791 48.8787 242.892 49.4323 242.255C50.9822 240.47 55.2862 237.038 60.1714 234.201C66.3989 230.575 71.0903 229.302 74.1349 230.451C77.2625 231.627 77.7331 234.782 77.7331 236.567C77.7331 241.231 72.7649 245.812 67.6998 245.812M56.8085 241.536H67.6998C70.3845 241.536 73.4568 238.892 73.4568 236.567C73.4568 234.768 72.8617 234.547 72.6265 234.464C71.3256 233.979 67.935 234.63 62.3164 237.91C60.2544 239.114 58.3723 240.387 56.8085 241.549V241.536Z"
        fill={sixthColor}
      />
      <path
        d="M58.6211 299.374H44.9066C43.3428 299.374 42.0558 298.101 42.0558 296.523V244.392C42.0558 242.814 43.329 241.541 44.9066 241.541H58.6211C60.1849 241.541 61.4719 242.814 61.4719 244.392V296.523C61.4719 298.087 60.1987 299.374 58.6211 299.374ZM46.3321 295.098H57.1957V245.817H46.3321V295.098Z"
        fill={sixthColor}
      />
      <path d="M324 212.141H214V322.141H324V212.141Z" fill={secondColor} />
      <path
        d="M304.262 263.451H233.738V307.179H304.262V263.451ZM233.094 259.967H304.906C305.678 259.967 306.393 260.284 306.908 260.8C307.423 261.316 307.739 262.031 307.739 262.805V307.812C307.739 308.586 307.423 309.302 306.908 309.818C306.393 310.334 305.69 310.662 304.906 310.662H233.094C232.322 310.662 231.607 310.346 231.092 309.83C230.577 309.314 230.261 308.598 230.261 307.824V262.817C230.261 262.043 230.577 261.327 231.092 260.811C231.607 260.295 232.31 259.967 233.094 259.967Z"
        fill={color}
      />
      <path d="M306.783 264.75H233.13V268.576H306.783V264.75Z" fill={color} />
      <path
        d="M304.262 227.14H233.738V246.905H304.262V227.14ZM233.094 223.631H304.906C305.678 223.631 306.393 223.95 306.908 224.47C307.423 225.001 307.739 225.71 307.739 226.49V247.543C307.739 248.322 307.423 249.043 306.908 249.563C306.393 250.083 305.69 250.402 304.906 250.402H233.094C232.322 250.402 231.607 250.083 231.092 249.563C230.577 249.031 230.261 248.322 230.261 247.543V226.49C230.261 225.71 230.577 224.99 231.092 224.47C231.607 223.95 232.31 223.619 233.094 223.619V223.631Z"
        fill={color}
      />
      <path d="M258.957 224.576H255.13V248.489H258.957V224.576Z" fill={color} />
      <path d="M283.826 224.576H280V248.489H283.826V224.576Z" fill={color} />
      <path
        d="M250.348 234.777C250.348 233.9 250.993 233.184 251.783 233.184C252.572 233.184 253.217 233.9 253.217 234.777V238.286C253.217 239.163 252.572 239.879 251.783 239.879C250.993 239.879 250.348 239.163 250.348 238.286V234.777Z"
        fill={color}
      />
      <path
        d="M275.217 234.777C275.217 233.9 275.863 233.184 276.652 233.184C277.442 233.184 278.087 233.9 278.087 234.777V238.286C278.087 239.163 277.442 239.879 276.652 239.879C275.863 239.879 275.217 239.163 275.217 238.286V234.777Z"
        fill={color}
      />
      <path
        d="M300.087 234.777C300.087 233.9 300.732 233.184 301.522 233.184C302.311 233.184 302.956 233.9 302.956 234.777V238.286C302.956 239.163 302.311 239.879 301.522 239.879C300.732 239.879 300.087 239.163 300.087 238.286V234.777Z"
        fill={color}
      />
      <path
        d="M258.957 286.43C258.957 285.553 259.817 284.836 260.87 284.836C261.922 284.836 262.783 285.553 262.783 286.43V289.938C262.783 290.815 261.922 291.532 260.87 291.532C259.817 291.532 258.957 290.815 258.957 289.938V286.43Z"
        fill={color}
      />
      <path
        d="M276.174 286.43C276.174 285.553 276.819 284.836 277.609 284.836C278.398 284.836 279.043 285.553 279.043 286.43V289.938C279.043 290.815 278.398 291.532 277.609 291.532C276.819 291.532 276.174 290.815 276.174 289.938V286.43Z"
        fill={color}
      />
      <path
        d="M271.391 266.662H267.565V308.749H271.391V266.662Z"
        fill={color}
      />
    </svg>
  );
};
