import { IconProps } from "typing";
import { theme } from "../themes";

export const Lock = ({
  width = 28,
  height = 30,
  color = theme.colors.neutral.white,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 30"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.244.75c4.673 0 8.483 3.52 8.483 7.862v2.507c1.762.486 3.011 2.12 3.011 3.998v9.986c0 2.286-1.829 4.147-4.08 4.147H6.83c-2.252 0-4.081-1.86-4.081-4.146v-9.986c0-1.879 1.25-3.513 3.011-3.999V8.612c0-4.341 3.81-7.862 8.483-7.862ZM6.831 12.695c-1.254 0-2.286.997-2.37 2.256l-.005.166v9.986c0 1.281.98 2.332 2.212 2.417l.163.006h14.827c1.253 0 2.285-.998 2.369-2.257l.005-.166v-9.986c0-1.336-1.067-2.422-2.374-2.422H6.83Zm9.873 4.86c0-1.376-1.102-2.496-2.46-2.496-1.358 0-2.46 1.12-2.46 2.496v3.066c0 1.376 1.102 2.497 2.46 2.497 1.358 0 2.46-1.12 2.46-2.497v-3.066Zm-2.46-.772c-.38 0-.697.29-.747.667l-.007.105v3.066c0 .427.34.772.754.772.38 0 .697-.29.747-.667l.007-.105v-3.066a.764.764 0 0 0-.754-.772Zm0-14.309c-3.66 0-6.644 2.64-6.772 5.919l-.004.22v2.56H21.02v-2.56c0-3.38-3.035-6.139-6.777-6.139Z"
      />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x={2}
        y={0}
        width={width}
        height={height}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.244.75c4.673 0 8.483 3.52 8.483 7.862v2.507c1.762.486 3.011 2.12 3.011 3.998v9.986c0 2.286-1.829 4.147-4.08 4.147H6.83c-2.252 0-4.081-1.86-4.081-4.146v-9.986c0-1.879 1.25-3.513 3.011-3.999V8.612c0-4.341 3.81-7.862 8.483-7.862ZM6.831 12.695c-1.254 0-2.286.997-2.37 2.256l-.005.166v9.986c0 1.281.98 2.332 2.212 2.417l.163.006h14.827c1.253 0 2.285-.998 2.369-2.257l.005-.166v-9.986c0-1.336-1.067-2.422-2.374-2.422H6.83Zm9.873 4.86c0-1.376-1.102-2.496-2.46-2.496-1.358 0-2.46 1.12-2.46 2.496v3.066c0 1.376 1.102 2.497 2.46 2.497 1.358 0 2.46-1.12 2.46-2.497v-3.066Zm-2.46-.772c-.38 0-.697.29-.747.667l-.007.105v3.066c0 .427.34.772.754.772.38 0 .697-.29.747-.667l.007-.105v-3.066a.764.764 0 0 0-.754-.772Zm0-14.309c-3.66 0-6.644 2.64-6.772 5.919l-.004.22v2.56H21.02v-2.56c0-3.38-3.035-6.139-6.777-6.139Z"
          fill={color}
        />
      </mask>
      <g mask="url(#a)">
        <path d="M0 1h28v28H0z" />
      </g>
    </svg>
  );
};
