import { useBranchLocation } from "application/context/BranchLocationContext";
import { theme } from "ui";
import { buildTestIds, useAppContext } from "application";
import { DropdownBranchLocation } from "../../DropdownBranchLocation";
import { ModalBranchLocation } from "../../ModalBranchLocation";
import {
  LocationIcon,
  NavBarMobileContainer,
  RegionText,
  RegionTextStrong,
  Wrapper,
} from "./styles";

const NavBarMobile = () => {
  const { isPageWeddingList } = useAppContext();
  const { regionClientLocation, onOpenModalBranchLocation } =
    useBranchLocation();

  return (
    <>
      <ModalBranchLocation />
      <DropdownBranchLocation />
      <NavBarMobileContainer {...buildTestIds("nav-bar-mobile-container")}>
        <Wrapper
          {...buildTestIds("wrapper-navbar-location-mobile-header")}
          onClick={onOpenModalBranchLocation}
        >
          <LocationIcon
            width={16}
            height={16}
            alt="ícone de localização"
            color={
              isPageWeddingList
                ? theme.colors.neutral.white
                : theme.colors.primary["200"]
            }
          />
          <RegionText
            {...buildTestIds("nav-bar-region-client-container-mobile")}
            isPageWeddingList={isPageWeddingList}
          >
            {regionClientLocation ? (
              <>
                {regionClientLocation === "Demais Regiões"
                  ? ""
                  : "Você está em "}
                <RegionTextStrong
                  {...buildTestIds("nav-bar-region-client-mobile")}
                >
                  {regionClientLocation ?? "Demais Regiões"}
                </RegionTextStrong>
              </>
            ) : (
              <>Demais Regiões</>
            )}
          </RegionText>
        </Wrapper>
      </NavBarMobileContainer>
    </>
  );
};

export { NavBarMobile };
