import { IconProps } from "typing";
import { theme } from "../themes";

export const Close = ({
  alt = "Fechar",
  width = 32,
  height = 32,
  color = theme.colors.neutral["999"],
  strokeWidth = "1.5",
  click,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      onClick={click}
    >
      <g clipPath="url(#clip0_1181_37)">
        <path
          d="M6.66667 6.66669L17.3333 17.3334"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3333 6.66669L6.66667 17.3334"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1181_37">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
