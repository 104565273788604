import { IAppMonitoringClient } from "app-domain";

const handleGetCardsError = (
  getCardsError: unknown,
  appMonitoringClient: IAppMonitoringClient
) => {
  if (getCardsError) {
    appMonitoringClient.captureException(getCardsError, {
      level: "error",
      tags: {
        fcx_labs_event: "get_cards",
        fcx_labs_error_source: "payment_api",
      },
    });
  }
};

const handleGetGiftError = (
  getGiftError: unknown,
  appMonitoringClient: IAppMonitoringClient
) => {
  if (
    getGiftError &&
    (getGiftError as { props: { statusCode: number } })?.props?.statusCode !==
      404
  ) {
    appMonitoringClient.captureException(getGiftError, {
      level: "error",
      tags: {
        fcx_labs_event: "get_gift",
        fcx_labs_error_source: "payment_api",
      },
    });
  }
};

export type OrderPaymentEventError = "payment" | "payment_by_phone";

const handleOrderPaymentError = (
  orderPaymentError: unknown,
  appMonitoringClient: IAppMonitoringClient,
  eventError: OrderPaymentEventError
) => {
  if (orderPaymentError) {
    appMonitoringClient.captureException(orderPaymentError, {
      level: "error",
      tags: {
        fcx_labs_event: eventError,
        fcx_labs_error_source: "payment_api",
      },
    });
  }
};

export { handleGetCardsError, handleGetGiftError, handleOrderPaymentError };
