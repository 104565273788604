import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const MyListFingerPoiting = ({
  width = 130,
  height = 150,
  color = theme.colors.secondary["270"],
  secondColor = theme.colors.secondary["395"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M358.077 273.82C351.385 273.82 345.207 276.121 340.347 279.956C336.042 269.515 325.729 262.143 313.752 262.143C306.379 262.143 299.646 264.955 294.529 269.516C289.627 260.692 280.209 254.726 269.385 254.726C263.632 254.726 258.305 256.429 253.786 259.328V200.145C253.786 184.29 240.872 171.376 225.018 171.376C209.164 171.376 196.249 184.29 196.249 200.145V325.132L170.635 296.153C166.075 290.995 159.811 287.671 152.991 286.735C146.171 285.798 139.269 287.374 133.472 291.167C121.242 299.179 116.978 315.033 123.584 328.075L175.41 430.528C181.546 442.675 190.923 452.86 202.517 459.976C214.107 467.093 227.405 470.845 241 470.845H307.316C328.539 470.845 348.487 462.578 363.488 447.577C378.489 432.576 386.756 412.628 386.756 391.404V302.59C386.756 286.735 373.842 273.821 357.988 273.821L358.077 273.82ZM373.675 391.403C373.675 427.927 343.971 457.632 307.446 457.632H241.131C229.965 457.632 219.054 454.564 209.55 448.68C200.046 442.841 192.331 434.488 187.305 424.514L135.479 322.061C131.899 315.03 134.2 306.463 140.806 302.158C147.198 297.981 155.807 299.132 160.837 304.843L197.959 346.864C199.79 348.909 202.688 349.635 205.245 348.654C207.802 347.673 209.508 345.244 209.508 342.473V200.096C209.508 191.487 216.497 184.497 225.107 184.497C233.716 184.497 240.705 191.487 240.705 200.096V308.074C240.705 311.697 243.645 314.679 247.311 314.679C250.935 314.679 253.917 311.739 253.917 308.074V283.441C253.917 274.873 260.906 267.884 269.515 267.884C278.124 267.884 285.114 274.873 285.114 283.483V314.849C285.114 318.473 288.054 321.455 291.719 321.455C295.385 321.455 298.325 318.515 298.325 314.849V290.897C298.325 282.288 305.314 275.298 313.923 275.298C322.533 275.298 329.522 282.287 329.522 290.897V321.797C329.522 325.421 332.462 328.403 336.128 328.403C339.793 328.403 342.733 325.462 342.733 321.797V302.574C342.733 293.965 349.723 286.976 358.332 286.976C366.941 286.976 373.93 293.965 373.93 302.574V391.389L373.675 391.403Z"
        fill={color}
      />
      <rect
        x="68.8807"
        y="67.523"
        width="89.4495"
        height="110.092"
        rx="6.88073"
        stroke={secondColor}
        strokeWidth="13.7615"
      />
      <rect
        x="209.936"
        y="36.8807"
        width="89.4495"
        height="110.092"
        rx="6.88073"
        fill={color}
        strokeWidth="13.7615"
      />
      <rect
        x="340.67"
        y="67.523"
        width="89.4495"
        height="110.092"
        rx="6.88073"
        stroke={secondColor}
        strokeWidth="13.7615"
      />
    </svg>
  );
};
