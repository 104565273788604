import { getComponentTheme } from "application";
import {
  ConfigMenuHeaderTitle,
  CustomFilterProductsProperty,
  FilterProductsThemeDto,
  Title,
} from "typing";
import { theme } from "ui";

const customFilterProductsBlackTheme = (isBlackTheme: boolean) => {
  return isBlackTheme
    ? {
        key: "blackFridayTheme",
        value: JSON.stringify({
          backgroundColor: `${theme.colors.blackFriday["125"]}`,
          secondaryBackgroundColor: `${theme.colors.blackFriday["110"]}`,
          defaultColor: `${theme.colors.blackFriday.white}`,
          textColor: `${theme.colors.blackFriday.white}`,
          borderColor: `${theme.colors.blackFriday["75"]}`,
        }),
      }
    : undefined;
};

const customFilterProductsData = (
  properties: CustomFilterProductsProperty[],
  isBlackTheme: boolean
) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const configMenuHeaderTitle = (
    properties.find(
      ({ key }) => key === "configMenuHeaderTitle"
    ) as ConfigMenuHeaderTitle
  )?.value;

  const blackFridayTheme = customFilterProductsBlackTheme(!!isBlackTheme);

  if (blackFridayTheme) {
    properties?.push(blackFridayTheme as FilterProductsThemeDto);
  }

  const filterProductsBlackFridayTheme =
    getComponentTheme<FilterProductsThemeDto>(blackFridayTheme);

  return {
    title,
    configMenuHeaderTitle,
    filterProductsBlackFridayTheme,
  };
};

export { customFilterProductsData };
