import { IconProps } from "typing";
import { theme } from "../themes";

export const Facebook = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["280"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M14 0H4C1.791 0 0 1.791 0 4V14C0 16.209 1.791 18 4 18H9.621V11.039H7.278V8.314H9.621V6.309C9.621 3.985 11.042 2.718 13.116 2.718C13.815 2.716 14.513 2.752 15.208 2.823V5.253H13.78C12.65 5.253 12.43 5.787 12.43 6.575V8.31H15.13L14.779 11.035H12.414V18H14C16.209 18 18 16.209 18 14V4C18 1.791 16.209 0 14 0Z"
        fill={color}
      />
    </svg>
  );
};
