import { ModalAlertProps } from "typing";
import { CloseIcon } from "ui";
import { buildTestIds } from "application";
import {
  BodyModal,
  ButtonClose,
  ButtonLine,
  ButtonSolid,
  ContentModalAlert,
  FooterModal,
  HeaderModal,
  TitleModal,
} from "./styles";

const ModalAlertConfirm = ({
  isOpen,
  typeInfo,
  backgroundColor,
  textHeader,
  descriptionBody,
  actionCloseButton,
  footerProps,
}: ModalAlertProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ContentModalAlert
      {...buildTestIds(`modal-alert-confirm`)}
      typeInfo={typeInfo}
      backgroundColor={backgroundColor}
    >
      <ButtonClose
        {...buildTestIds(`modal-alert-confirm-button-close`)}
        onClick={actionCloseButton}
        typeInfo={typeInfo}
      >
        <CloseIcon height={25} width={25} />
      </ButtonClose>
      {textHeader && (
        <HeaderModal {...buildTestIds(`modal-alert-confirm-header`)}>
          <TitleModal
            {...buildTestIds(`modal-alert-confirm-header-title`)}
            lowerContent={Boolean(descriptionBody)}
          >
            {textHeader}
          </TitleModal>
        </HeaderModal>
      )}
      {descriptionBody && (
        <BodyModal {...buildTestIds(`modal-alert-confirm-body`)}>
          {descriptionBody}
        </BodyModal>
      )}
      {footerProps?.isActive ? (
        <FooterModal {...buildTestIds(`modal-alert-confirm-footer`)}>
          {footerProps?.children ? (
            footerProps.children
          ) : (
            <>
              {footerProps?.buttonLine && (
                <ButtonLine
                  {...buildTestIds(`modal-alert-confirm-footer-button-line`)}
                  onClick={footerProps?.actionButtonLine}
                >
                  {footerProps?.textButtonLine ?? "Cancelar"}
                </ButtonLine>
              )}
              {footerProps?.buttonSolid && (
                <ButtonSolid
                  {...buildTestIds(`modal-alert-confirm-footer-button-solid`)}
                  onClick={footerProps?.actionButtonSolid}
                >
                  {footerProps?.textButtonSolid ?? "Confirmar"}
                </ButtonSolid>
              )}
            </>
          )}
        </FooterModal>
      ) : null}
    </ContentModalAlert>
  );
};

export { ModalAlertConfirm };
