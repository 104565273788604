import { Option } from "typing";
import { theme, Text } from "ui";
import { useAppContext } from "application/context/state";
import { buildTestIds } from "application";
import { Link } from "../../../Link";
import { Image } from "../../../Image";
import { ImageInfoOptionContainer, UtilInfoOptionContainer } from "./styles";

const UtilInfoOption = ({
  title: optionTitle,
  link: optionLink,
  images: optionImages,
  isBlackTheme,
}: Option) => {
  const { isClientMobile } = useAppContext();

  return (
    <UtilInfoOptionContainer
      data-testid={`util-info-option-${optionTitle}`}
      data-cy={`util-info-option-${optionTitle}`}
      key={optionTitle}
      display="flex"
      flexDirection="column"
      alignItems="start"
      gap={theme.space["x3.5"]}
      margin={`${theme.space.x4} ${theme.space.x0} ${theme.space.x0}`}
      mobile={{
        margin: `${theme.space.x4} ${theme.space.x0}`,
      }}
      tablet={{
        margin: `${theme.space.x4} ${theme.space.x0}`,
      }}
      laptop={{
        margin: `${theme.space.x4} ${theme.space.x0}`,
      }}
    >
      {optionLink ? (
        <Link
          {...buildTestIds(`option-link-${optionTitle}`)}
          href={optionLink?.href}
          fontSize={
            isClientMobile
              ? theme.typography.fontSize.sm.x5
              : theme.typography.fontSize.sm.x3
          }
          color={
            isBlackTheme
              ? theme.colors.neutral.white
              : theme.colors.neutral["999"]
          }
          mouseOver={{
            color: theme.colors.primary["200"],
          }}
        >
          {optionLink?.title}
        </Link>
      ) : (
        <Text
          fontSize={theme.typography.fontSize.sm.x3}
          color={
            isBlackTheme
              ? theme.colors.neutral.white
              : theme.colors.neutral["520"]
          }
          margin={theme.space.x0}
          data-cy={`footer-payment-option-title-${optionTitle}`}
        >
          {optionTitle}
        </Text>
      )}

      {optionImages?.length && (
        <ImageInfoOptionContainer
          display="flex"
          gap={theme.space.x1}
          mobile={{
            margin: `${theme.space.x1} ${theme.space.x0}`,
          }}
        >
          {optionImages?.map(
            ({ src, alt, width, height, layout = "fixed" }) => (
              <Image
                key={src}
                src={src}
                alt={alt}
                width={width}
                height={height}
                layout={layout}
              />
            )
          )}
        </ImageInfoOptionContainer>
      )}
    </UtilInfoOptionContainer>
  );
};

export default UtilInfoOption;
