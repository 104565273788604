import styled, { css } from "styled-components";
import { theme } from "ui";
import {
  CardCTAImage,
  CardCTASubComponentContent,
  CardCTASubComponentImage,
  ComponentHeaderContainer,
  CustomCta,
  CardCTASubComponentDescription,
  HeaderSubTitle,
  HeaderTitle,
  CustomCardCTASubComponentContainer,
} from "./SubComponents/styles";

interface CustomCardCTAContainerProps {
  isOneCard: boolean;
  isTwoCards: boolean;
  isThreeCards: boolean;
  customTheme?: boolean;
}

export const CustomCardCTAContainer = styled.div<CustomCardCTAContainerProps>`
  display: flex;
  gap: ${theme.space["x4.5"]};
  justify-content: center;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    padding: ${theme.space.x2} ${theme.space.x4};
    gap: ${theme.space.x0};
  }

  ${({ isOneCard }) =>
    isOneCard
      ? css`
          ${CustomCardCTASubComponentContainer} {
            flex-direction: row-reverse;

            @media ${theme.device.desktopFirst.tablet} {
              flex-direction: column;
            }
          }

          ${CardCTASubComponentImage} {
            height: 350px;

            @media ${theme.device.desktopFirst.tablet} {
              height: 379px;
            }

            @media ${theme.device.desktopFirst.mobileXL} {
              height: 183px;
            }
            ${CardCTAImage} {
              border-radius: 0 ${theme.space.x3} ${theme.space.x3} 0;

              @media ${theme.device.desktopFirst.tablet} {
                border-radius: ${theme.space.x2} ${theme.space.x2} 0 0;
              }
            }
          }

          ${ComponentHeaderContainer} {
            @media ${theme.device.desktopFirst.tablet} {
              padding: ${theme.space.x0} !important;
            }
          }

          ${HeaderTitle} {
            font-size: ${theme.typography.fontSize.lg.x4};

            @media ${theme.device.desktopFirst.tablet} {
              font-size: ${theme.typography.fontSize.md.x3};
            }
            @media ${theme.device.desktopFirst.mobileXL} {
              font-size: 1.8rem;
            }
          }

          ${CardCTAImage} {
            border-radius: 0 ${theme.space.x3} ${theme.space.x3} 0;

            @media ${theme.device.desktopFirst.tablet} {
              border-radius: ${theme.space.x2} ${theme.space.x2} 0 0;
            }
          }

          ${CustomCta} {
            margin-top: 15px;
            @media ${theme.device.desktopFirst.tablet} {
              margin-top: ${theme.space.x8};
            }
          }

          ${CardCTASubComponentDescription} {
            font-size: ${theme.typography.fontSize.sm.x7};

            @media ${theme.device.desktopFirst.mobileM} {
              font-size: ${theme.typography.fontSize.sm.x5};
            }
          }

          ${CardCTASubComponentContent} {
            padding: ${theme.space.x10};
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 350px;
            border-radius: ${theme.space.x2};

            @media ${theme.device.desktopFirst.tablet} {
              padding: ${theme.space.x8} ${theme.space.x8} ${theme.space.x8}
                ${theme.space.x6};
              height: 100%;
            }

            @media ${theme.device.desktopFirst.mobileXL} {
              padding: ${theme.space.x6} ${theme.space.x8} ${theme.space.x8};
            }
          }
        `
      : ""}

  ${({ isTwoCards }) =>
    isTwoCards
      ? css`
          @media ${theme.device.desktopFirst.tablet} {
            gap: ${theme.space.x2};
            justify-content: center;
          }

          @media ${theme.device.desktopFirst.mobileXL} {
            flex-direction: column;
            gap: ${theme.space.x0};
            margin-bottom: 3.175rem;
          }

          ${CustomCardCTASubComponentContainer} {
            max-width: 628px;

            @media ${theme.device.desktopFirst.tablet} {
              max-width: 328px;
            }

            @media ${theme.device.desktopFirst.mobileXL} {
              max-width: 448px;
            }
          }

          ${CardCTASubComponentImage} {
            height: 350px;
            @media ${theme.device.desktopFirst.tablet} {
              height: 183px;
            }
            ${CardCTAImage} {
              border-radius: ${theme.space.x2} ${theme.space.x2} 0 0;
            }
          }

          ${HeaderSubTitle} {
            display: none;
          }

          ${CardCTASubComponentContent} {
            padding: ${theme.space.x4} ${theme.space.x6} ${theme.space.x6};
            margin-top: ${theme.space.x2};
            border-radius: ${theme.space.x2};

            @media ${theme.device.desktopFirst.tablet} {
              padding: ${theme.space.x2} ${theme.space.x4} ${theme.space.x6};
              margin-top: ${theme.space.x4};
            }
          }
          ${CardCTASubComponentDescription} {
            height: 100%;
            text-align: start;
            font-size: ${theme.typography.fontSize.sm.x5};
          }
        `
      : ""}
      
      ${({ isThreeCards, customTheme }) =>
    isThreeCards
      ? css`
          .slick-track {
            display: flex;
            gap: ${theme.space.x2};
          }

          @media ${theme.device.desktopFirst.tablet} {
            margin-bottom: 3.175rem;
          }

          ${CardCTASubComponentImage} {
            height: 234px;

            @media ${theme.device.desktopFirst.tablet} {
              height: 379px;
            }

            @media ${theme.device.desktopFirst.mobileXL} {
              height: 183px;
            }
            ${CardCTAImage} {
              border-radius: ${theme.space.x2} ${theme.space.x2} 0 0;
            }
          }

          ${CardCTAImage} {
            height: 234px;

            @media ${theme.device.desktopFirst.tablet} {
              height: 379px;
            }
            @media ${theme.device.desktopFirst.mobileXL} {
              height: 183px;
            }
          }

          ${CustomCardCTASubComponentContainer} {
            max-width: 415px;
            ${customTheme &&
            css`
              background-color: ${theme?.colors?.blackFriday?.["175"]};
              color: ${theme?.colors?.blackFriday?.white};
              ${customTheme &&
              css`
                ${CustomCta} {
                  background-color: ${theme?.colors?.secondary?.["385"]};
                }
              `}
            `}

            @media ${theme.device.desktopFirst.tablet} {
              max-width: 737px;
            }

            @media ${theme.device.desktopFirst.mobileXL} {
              max-width: 448px;
            }
          }

          ${HeaderSubTitle} {
            display: none;
          }

          @media ${theme.device.desktopFirst.tablet} {
            display: block;
          }

          ${CardCTASubComponentContent} {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: ${theme.space.x4};
            border-radius: ${theme.space.x2};
            @media ${theme.device.desktopFirst.tablet} {
              margin-top: 0;
              padding: ${theme.space.x8} ${theme.space.x8} ${theme.space.x8}
                ${theme.space.x6};
            }

            @media ${theme.device.desktopFirst.mobileXL} {
              padding: ${theme.space.x2} ${theme.space.x4}${theme.space.x2};
              margin-top: ${theme.space.x4};
            }
          }

          ${CustomCta} {
            @media ${theme.device.desktopFirst.tablet} {
              margin-top: ${theme.space.x8};
            }
            @media ${theme.device.desktopFirst.mobileXL} {
              margin-top: ${theme.space.x4};
            }
          }

          ${CardCTASubComponentDescription} {
            text-align: start;

            font-size: ${theme.typography.fontSize.sm.x5};

            @media ${theme.device.desktopFirst.tablet} {
              font-size: ${theme.typography.fontSize.sm.x7};
            }

            @media ${theme.device.desktopFirst.mobileXL} {
              font-size: ${theme.typography.fontSize.sm.x5};
            }
          }
        `
      : ""}
`;

export const CustomCardCTAContent = styled.div`
  max-width: 1280px;
  width: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    margin-bottom: ${theme.space.x4};
    padding: ${theme.space.x0} ${theme.space.x2};
  }
`;
