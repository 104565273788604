import { notificationApi } from "implementations";
import { useAuth, useToast } from "application";
import { useCallback } from "react";
import { SendCodeAgainContainer } from "./styles";

interface SendCodeAgainProps {
  typeSMS: 1 | 2 | 3;
  color?: string;
  fontSize?: string;
  onSendCodeAgain?: (value: boolean) => void;
}

const SendCodeAgain = ({
  typeSMS,
  color,
  fontSize,
  onSendCodeAgain,
}: SendCodeAgainProps) => {
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token as string;
  const { addToast } = useToast();

  const handleSendCodeAgain = useCallback(() => {
    const onSendSMS = async (type: 1 | 2 | 3) => {
      await notificationApi.sendTwoFactorSMS(type, accessToken);
    };

    if (onSendCodeAgain) {
      onSendCodeAgain(true);
    }

    onSendSMS(typeSMS);
    addToast({ title: "Código reenviado!", type: "success" });
  }, [onSendCodeAgain, typeSMS, addToast, accessToken]);

  return (
    <SendCodeAgainContainer
      color={color}
      fontSize={fontSize}
      onClick={handleSendCodeAgain}
    >
      Reenviar código
    </SendCodeAgainContainer>
  );
};

export { SendCodeAgain };
