// eslint-disable-next-line import/no-extraneous-dependencies
import { z } from "zod";

export const FormAddressSchema = z.object({
  addressComplement: z.string().trim().nullish(),
  city: z.string({
    required_error: "O nome da cidade é obrigatório.",
    invalid_type_error: "O nome da cidade deve ser um texto válido.",
  }),
  district: z.string({
    required_error: "O nome do bairro é obrigatório.",
    invalid_type_error: "O nome do bairro deve ser um texto válido.",
  }),
  noNumber: z
    .boolean({
      invalid_type_error: "A opção 'Sem número' deve ser verdadeira ou falsa.",
    })
    .default(false)
    .optional(),
  number: z
    .number()
    .nonnegative("O número do endereço deve ser um valor positivo.")
    .lte(999999, "O número do endereço deve ter no máximo 6 dígitos.")
    .or(
      z
        .string()
        .max(6, "O número do endereço deve ter no máximo 6 dígitos.")
        .regex(
          /^\d*$/,
          "O número do endereço não pode receber letras ou caracteres especiais"
        )
        .nullish()
    )
    .nullish(),
  recipient: z.string({
    required_error: "O nome do destinatário é obrigatório.",
    invalid_type_error: "O nome do destinatário deve ser um texto válido.",
  }),
  referencePoint: z
    .string()
    .max(100, "O ponto de referência deve conter no máximo 100 caracteres.")
    .nullish(),
  state: z.string({
    required_error: "O estado é obrigatório.",
    invalid_type_error: "O estado deve ser um texto válido.",
  }),
  street: z.string({
    required_error: "O nome da rua é obrigatório.",
    invalid_type_error: "O nome da rua deve ser um texto válido.",
  }),
  zipCode: z.string({
    required_error: "O CEP é obrigatório.",
    invalid_type_error: "O CEP deve ser um texto válido.",
  }),
});
