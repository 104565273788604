import React from "react";
import { IconProps } from "typing";

export const DeviceAccountIcon = ({
  width = "221",
  height = "171",
  alt,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 221 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <circle cx="70" cy="100.539" r="69.5" fill="#F6F7F7" />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.2652 86.4754C35.5007 86.4754 34.0693 87.8113 34.0693 89.459C34.0693 91.1066 35.5007 92.4417 37.2652 92.4417H46.0547C47.82 92.4417 49.2514 91.1058 49.2514 89.459C49.2514 87.8121 47.82 86.4762 46.0556 86.4762C46.0556 84.2106 44.0878 82.374 41.6604 82.374C39.2329 82.374 37.2652 84.2098 37.2652 86.4754Z"
          stroke="#A0A2A4"
          strokeWidth="0.830869"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9741 104.019C11.2096 104.019 9.77832 105.355 9.77832 107.003C9.77832 108.651 11.2096 109.986 12.9741 109.986H21.7637C23.529 109.986 24.9604 108.65 24.9604 107.003C24.9604 105.356 23.529 104.02 21.7645 104.02C21.7645 101.755 19.7968 99.918 17.3693 99.918C14.9419 99.918 12.9741 101.754 12.9741 104.019Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.9448 148.553C92.1804 148.553 90.749 149.888 90.749 151.536C90.749 153.184 92.1804 154.519 93.9448 154.519H102.734C104.5 154.519 105.931 153.183 105.931 151.536C105.931 149.889 104.5 148.553 102.735 148.553C102.735 146.288 100.767 144.451 98.34 144.451C95.9126 144.451 93.9448 146.287 93.9448 148.553Z"
          stroke="#B2B3B5"
          strokeWidth="0.830869"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4683 124.262C24.7038 124.262 23.2725 125.597 23.2725 127.245C23.2725 128.893 24.7038 130.228 26.4683 130.228H35.2578C37.0232 130.228 38.4545 128.892 38.4545 127.245C38.4545 125.598 37.0232 124.262 35.2587 124.262C35.2587 121.997 33.2909 120.16 30.8635 120.16C28.436 120.16 26.4683 121.996 26.4683 124.262Z"
          stroke="#B2B3B5"
          strokeWidth="0.830869"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.935 117.514C119.171 117.514 117.739 118.85 117.739 120.498C117.739 122.146 119.171 123.481 120.935 123.481H129.725C131.49 123.481 132.921 122.145 132.921 120.498C132.921 118.851 131.49 117.515 129.725 117.515C129.725 115.25 127.758 113.413 125.33 113.413C122.903 113.413 120.935 115.249 120.935 117.514Z"
          stroke="#B2B3B5"
          strokeWidth="0.830869"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.1968 45.9901C85.4323 45.9901 84.001 47.326 84.001 48.9736C84.001 50.6213 85.4323 51.9564 87.1968 51.9564H95.9864C97.7517 51.9564 99.183 50.6205 99.183 48.9736C99.183 47.3268 97.7517 45.9909 95.9872 45.9909C95.9872 43.7252 94.0194 41.8887 91.592 41.8887C89.1646 41.8887 87.1968 43.7245 87.1968 45.9901Z"
          stroke="#8F9194"
          strokeWidth="0.830869"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.539 70.2811C113.774 70.2811 112.343 71.617 112.343 73.2646C112.343 74.9123 113.774 76.2474 115.539 76.2474H124.328C126.093 76.2474 127.525 74.9115 127.525 73.2646C127.525 71.6178 126.093 70.2819 124.329 70.2819C124.329 68.0163 122.361 66.1797 119.934 66.1797C117.506 66.1797 115.539 68.0155 115.539 70.2811Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.863 104.374C100.569 104.374 99.5195 105.328 99.5195 106.505C99.5195 107.682 100.569 108.636 101.863 108.636H108.309C109.603 108.636 110.653 107.681 110.653 106.505C110.653 105.329 109.603 104.374 108.309 104.374C108.309 102.756 106.866 101.444 105.086 101.444C103.306 101.444 101.863 102.756 101.863 104.374Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.5819 42.2968C49.2879 42.2968 48.2383 43.251 48.2383 44.4279C48.2383 45.6048 49.2879 46.5584 50.5819 46.5584H57.0276C58.3221 46.5584 59.3718 45.6042 59.3718 44.4279C59.3718 43.2515 58.3221 42.2973 57.0282 42.2973C57.0282 40.679 55.5852 39.3672 53.805 39.3672C52.0249 39.3672 50.5819 40.6785 50.5819 42.2968Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.1366 150.258C39.8426 150.258 38.793 151.212 38.793 152.389C38.793 153.566 39.8426 154.519 41.1366 154.519H47.5822C48.8768 154.519 49.9265 153.565 49.9265 152.389C49.9265 151.212 48.8768 150.258 47.5829 150.258C47.5829 148.64 46.1398 147.328 44.3597 147.328C42.5796 147.328 41.1366 148.639 41.1366 150.258Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6405 63.8886C26.3465 63.8886 25.2969 64.8428 25.2969 66.0197C25.2969 67.1966 26.3465 68.1502 27.6405 68.1502H34.0862C35.3807 68.1502 36.4304 67.196 36.4304 66.0197C36.4304 64.8433 35.3807 63.8891 34.0868 63.8891C34.0868 62.2708 32.6437 60.959 30.8636 60.959C29.0835 60.959 27.6405 62.2703 27.6405 63.8886Z"
          fill="#A0A2A4"
        />
      </g>
      <ellipse
        cx="70.0115"
        cy="142.125"
        rx="17.4393"
        ry="1.16711"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.1434 54.6973H81.8267C88.4389 54.6973 93.7944 60.0735 93.7944 66.7112V120.774C93.7944 127.412 88.4389 132.788 81.8267 132.788H57.1434C50.5313 132.788 45.1758 127.412 45.1758 120.774V66.7112C45.1758 60.0735 50.5313 54.6973 57.1434 54.6973Z"
        fill="#F6F7F7"
        stroke="#53565A"
        strokeWidth="4.76405"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.2344 119.273H74.7061H64.2344Z"
        fill="white"
      />
      <path
        d="M64.2344 119.273H74.7061"
        stroke="#53565A"
        strokeWidth="4.76405"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="69.4866"
        cy="79.1014"
        rx="8.4554"
        ry="8.4881"
        fill="#F8FCF6"
      />
      <ellipse
        cx="72.5162"
        cy="90.1795"
        rx="6.07282"
        ry="2.02427"
        fill="#68A439"
      />
      <path
        d="M75.6549 72.9115C79.0601 76.3299 79.0601 81.8722 75.6549 85.2905C72.2496 88.7089 66.7287 88.7089 63.3235 85.2905C59.9182 81.8721 59.9182 76.3298 63.3235 72.9115C66.7287 69.4931 72.2496 69.4931 75.6549 72.9115"
        stroke="#252728"
        strokeWidth="1.79982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.4162 75.5259C72.4805 76.5944 72.4805 78.3267 71.4162 79.3951C70.3518 80.4635 68.6262 80.4635 67.5619 79.3951C66.4976 78.3267 66.4976 76.5944 67.5619 75.5259C68.6262 74.4575 70.3518 74.4575 71.4162 75.5259Z"
        fill="#68A439"
      />
      <path
        d="M71.4162 75.5259C72.4805 76.5944 72.4805 78.3267 71.4162 79.3951C70.3518 80.4635 68.6262 80.4635 67.5619 79.3951C66.4976 78.3267 66.4976 76.5944 67.5619 75.5259C68.6262 74.4575 70.3518 74.4575 71.4162 75.5259"
        stroke="#252728"
        strokeWidth="1.79982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0174 85.8682C73.6271 84.3986 71.6662 83.4775 69.4882 83.4775C67.3102 83.4775 65.3493 84.3986 63.959 85.8692"
        fill="#68A439"
      />
      <path
        d="M75.0174 85.8682C73.6271 84.3986 71.6662 83.4775 69.4882 83.4775C67.3102 83.4775 65.3493 84.3986 63.959 85.8692"
        stroke="#252728"
        strokeWidth="1.79982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.17"
        d="M218.289 62.5086L80.7683 104.032L59.6304 96.3869L144.064 29.5103L218.289 62.5086Z"
        fill="url(#paint0_linear_2364_388677)"
        stroke="#68A439"
        strokeWidth="1.34951"
      />
      <rect
        x="140.143"
        y="25.9893"
        width="80.3263"
        height="36.0744"
        rx="5.23584"
        fill="#68A439"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.673 43.5147C163.673 43.079 163.323 42.7273 162.889 42.7305C162.455 42.7305 162.104 43.0823 162.104 43.5179C162.104 43.9536 162.455 44.3054 162.889 44.3054C163.319 44.3054 163.673 43.9536 163.673 43.5147Z"
        fill="#68A439"
      />
      <path
        d="M163.673 43.5147C163.673 43.079 163.323 42.7273 162.889 42.7305C162.455 42.7305 162.104 43.0823 162.104 43.5179C162.104 43.9536 162.455 44.3054 162.889 44.3054C163.319 44.3054 163.673 43.9536 163.673 43.5147"
        stroke="#FEF4F5"
        strokeWidth="6.46981"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.534 43.5147C176.534 43.079 176.184 42.7273 175.75 42.7305C175.316 42.7305 174.966 43.0823 174.966 43.5179C174.966 43.9536 175.316 44.3054 175.75 44.3054C176.181 44.3054 176.534 43.9536 176.534 43.5147Z"
        fill="#68A439"
      />
      <path
        d="M176.534 43.5147C176.534 43.079 176.184 42.7273 175.75 42.7305C175.316 42.7305 174.966 43.0823 174.966 43.5179C174.966 43.9536 175.316 44.3054 175.75 44.3054C176.181 44.3054 176.534 43.9536 176.534 43.5147"
        stroke="#FEF4F5"
        strokeWidth="6.46981"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.392 43.5147C189.392 43.079 189.041 42.7273 188.607 42.7305C188.173 42.7305 187.823 43.0823 187.823 43.5179C187.823 43.9536 188.173 44.3054 188.607 44.3054C189.038 44.3054 189.392 43.9536 189.392 43.5147Z"
        fill="#68A439"
      />
      <path
        d="M189.392 43.5147C189.392 43.079 189.041 42.7273 188.607 42.7305C188.173 42.7305 187.823 43.0823 187.823 43.5179C187.823 43.9536 188.173 44.3054 188.607 44.3054C189.038 44.3054 189.392 43.9536 189.392 43.5147"
        stroke="#FEF4F5"
        strokeWidth="6.46981"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.249 43.5147C202.249 43.079 201.899 42.7273 201.465 42.7305C201.031 42.7305 200.68 43.0823 200.68 43.5179C200.68 43.9536 201.031 44.3054 201.465 44.3054C201.899 44.3054 202.249 43.9536 202.249 43.5147Z"
        fill="#68A439"
      />
      <path
        d="M202.249 43.5147C202.249 43.079 201.899 42.7273 201.465 42.7305C201.031 42.7305 200.68 43.0823 200.68 43.5179C200.68 43.9536 201.031 44.3054 201.465 44.3054C201.899 44.3054 202.249 43.9536 202.249 43.5147"
        stroke="#FEF4F5"
        strokeWidth="6.46981"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="57.8594"
        y="95.0146"
        width="23.6254"
        height="10.6101"
        rx="1.53995"
        fill="#68A439"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7777 100.17C64.7777 100.042 64.6746 99.9383 64.547 99.9393C64.4193 99.9393 64.3163 100.043 64.3163 100.171C64.3163 100.299 64.4193 100.402 64.547 100.402C64.6737 100.402 64.7777 100.299 64.7777 100.17Z"
        fill="#5C5E62"
      />
      <path
        d="M64.7777 100.17C64.7777 100.042 64.6746 99.9383 64.547 99.9393C64.4193 99.9393 64.3163 100.043 64.3163 100.171C64.3163 100.299 64.4193 100.402 64.547 100.402C64.6737 100.402 64.7777 100.299 64.7777 100.17"
        stroke="#36383B"
        strokeWidth="1.90289"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5629 100.17C68.5629 100.042 68.4598 99.9383 68.3321 99.9393C68.2045 99.9393 68.1014 100.043 68.1014 100.171C68.1014 100.299 68.2045 100.402 68.3321 100.402C68.4588 100.402 68.5629 100.299 68.5629 100.17Z"
        fill="#5C5E62"
      />
      <path
        d="M68.5629 100.17C68.5629 100.042 68.4598 99.9383 68.3321 99.9393C68.2045 99.9393 68.1014 100.043 68.1014 100.171C68.1014 100.299 68.2045 100.402 68.3321 100.402C68.4588 100.402 68.5629 100.299 68.5629 100.17"
        stroke="#36383B"
        strokeWidth="1.90289"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.3461 100.17C72.3461 100.042 72.243 99.9383 72.1153 99.9393C71.9877 99.9393 71.8846 100.043 71.8846 100.171C71.8846 100.299 71.9877 100.402 72.1153 100.402C72.242 100.402 72.3461 100.299 72.3461 100.17Z"
        fill="#5C5E62"
      />
      <path
        d="M72.3461 100.17C72.3461 100.042 72.243 99.9383 72.1153 99.9393C71.9877 99.9393 71.8846 100.043 71.8846 100.171C71.8846 100.299 71.9877 100.402 72.1153 100.402C72.242 100.402 72.3461 100.299 72.3461 100.17"
        stroke="#36383B"
        strokeWidth="1.90289"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.1293 100.17C76.1293 100.042 76.0262 99.9383 75.8985 99.9393C75.7709 99.9393 75.6678 100.043 75.6678 100.171C75.6678 100.299 75.7709 100.402 75.8985 100.402C76.0262 100.402 76.1293 100.299 76.1293 100.17Z"
        fill="#5C5E62"
      />
      <path
        d="M76.1293 100.17C76.1293 100.042 76.0262 99.9383 75.8985 99.9393C75.7709 99.9393 75.6678 100.043 75.6678 100.171C75.6678 100.299 75.7709 100.402 75.8985 100.402C76.0262 100.402 76.1293 100.299 76.1293 100.17"
        stroke="#36383B"
        strokeWidth="1.90289"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2364_388677"
          x1="65.1115"
          y1="114.522"
          x2="172.504"
          y2="12.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#74B740" />
          <stop offset="1" stopColor="#74B740" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
