import styled from "styled-components";
import { theme } from "ui";
import { Loading } from "../Loading";

export const AuthLoadingContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  height: 100vh;
`;

export const WrapperLoadingAuth = styled.div``;

export const LoadingAuthCustom = styled(Loading).attrs({
  color: theme.colors.tertiary["300"],
  size: "extraLarge",
})``;

export const AuthLoadingText = styled.h2`
  font-weight: ${theme.typography.fontWeight["400"]};
  font-family: ${theme.typography.fontFamily.chivo}, sans-serif;
`;
