import { EnvironmentDto, GiftsListProduct } from "typing";
import { useEffect, useState } from "react";
import {
  ActionButton,
  CancelSecectionButton,
  Container,
  RemoveSelectionFirst,
  RemoveSelectionSecond,
  RemoveSelectionWrapper,
  SelectedQuantityBox,
} from "./styles";

interface RemoveSelectionProps {
  selectedQuantity: number;
  onCancelSelection: () => void;
  onRemoveSelection: () => void;
  onActionSelection?: () => void;
  actionButtonText?: string;
  visible?: boolean;
  selectedProducts?: GiftsListProduct[];
  environments?: EnvironmentDto[];
}

const RemoveSelection = ({
  selectedQuantity,
  onCancelSelection,
  onRemoveSelection,
  onActionSelection,
  actionButtonText,
  visible,
  selectedProducts,
  environments,
}: RemoveSelectionProps) => {
  const [showButton, setShowButton] = useState<boolean>(true);

  useEffect(() => {
    let hasVoucher = [];

    const environmentGiftCard = environments?.filter(
      (environment: EnvironmentDto) => {
        return environment.id === -2;
      }
    ) as EnvironmentDto[];

    if (environmentGiftCard?.length > 0) {
      hasVoucher = selectedProducts?.filter((product: GiftsListProduct) => {
        return environmentGiftCard?.[0].products.find((giftCard) => {
          return giftCard.productId === product.productId;
        });
      }) as GiftsListProduct[];
    }

    setShowButton(hasVoucher?.length === 0);
  }, [selectedProducts, environments, setShowButton, showButton]);

  return (
    <RemoveSelectionWrapper isVisible={visible}>
      <Container>
        <RemoveSelectionFirst>
          <SelectedQuantityBox>
            {selectedQuantity <= 1
              ? `${selectedQuantity} item selecionado`
              : `${selectedQuantity} itens selecionados`}
          </SelectedQuantityBox>
          <CancelSecectionButton onClick={onCancelSelection}>
            cancelar seleção
          </CancelSecectionButton>
        </RemoveSelectionFirst>
        {actionButtonText && showButton && (
          <ActionButton onClick={onActionSelection}>
            {actionButtonText}
          </ActionButton>
        )}
        <RemoveSelectionSecond onClick={onRemoveSelection}>
          Remover
        </RemoveSelectionSecond>
      </Container>
    </RemoveSelectionWrapper>
  );
};

export { RemoveSelection };
