import { IconProps } from "typing";
import { theme } from "../themes";

export const File = ({
  alt = "Arquivo",
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M6.82499 3C5.70854 3 4.79999 3.90855 4.79999 5.025V18.975C4.79999 20.0914 5.70854 21 6.82499 21H17.175C18.2914 21 19.2 20.0914 19.2 18.975V10.2H14.025C12.9085 10.2 12 9.29145 12 8.175V3H6.82499ZM13.35 3.39551V8.175C13.35 8.54715 13.6528 8.85 14.025 8.85H18.8045L13.35 3.39551Z"
        fill={color}
      />
    </svg>
  );
};
