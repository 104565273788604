import { replaceImageBaseUrl } from "application";
import styled from "styled-components";
import { theme } from "ui";

export const ContainerImage = styled.a`
  height: 4rem;
  width: 4rem;
  border: 2px solid ${theme.colors.neutral["95"]};
  overflow: hidden;
  cursor: pointer;
  transition: border 500ms;

  .active {
    border-color: ${theme.colors.primary["200"]};
  }
  .disabled:hover {
    border-color: ${theme.colors.primary["200"]};
  }
  :hover {
    border-color: rgba(255, 0, 0, 0.2) !important;
    filter: brightness(0.8);
    transition: border 100ms !important;
    transition: filter 100ms !important;
  }
`;
export const ImageThumb = styled.img.attrs<{ src: string }>((props) => ({
  src: replaceImageBaseUrl(props.src),
}))`
  width: 100%;
`;

export const ViewMoreImagesZoom = styled.div`
  display: none;
`;
export const ContainerZoomImage = styled.a`
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  overflow: hidden;

  @media ${theme.device.desktopFirst.tablet} {
    height: 676px;
    width: 676px;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    height: auto;
  }

  img {
    width: inherit;
  }
`;

export const MagicZoomImage = styled.img``;
