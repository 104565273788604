import { CRMProperties, ComponentDto, ProductCardDto } from "typing";

const productsRecommendationDetails = (
  branchId: string,
  crmUrl?: string,
  crmLinkId?: string,
  crm?: CRMProperties | null,
  productCards?: ProductCardDto[] | null,
  productQuantity = 10
): ComponentDto => {
  return {
    type: "productList",
    subComponents: null,
    displayName: "Lista de Produtos",
    config: {
      branches: [
        {
          typeLink: "insider",
          linkUrl: crmUrl ?? null,
          id: branchId,
          linkId: crmLinkId ?? "0",
          visibleOnDesktop: true,
          visibleOnMobile: true,
        },
      ],
    },
    crm,
    productCards,
    properties: [
      {
        key: "quantity",
        value: productQuantity,
      },
      {
        key: "showCta",
        value: "true",
      },
      {
        key: "ctaText",
        value: "Ver todos",
      },
      {
        key: "showTitle",
        value: "true",
      },
      {
        key: "title",
        value: "Mais populares",
      },
      {
        key: "showArrows",
        value: "true",
      },
    ],
  };
};

export { productsRecommendationDetails };
