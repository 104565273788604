import { ICookie } from "app-domain";
import { AuthenticationProps } from "typing";

// deepcode ignore HardcodedNonCryptoSecret: não é uma secret, apenas o valor da key que terá o secret como value
const accessTokenGoogleCookieKey = "@FC:Ecom:Token:Access:google";
// deepcode ignore HardcodedNonCryptoSecret: não é uma secret, apenas o valor da key que terá o secret como value
const accessTokenAppleCookieKey = "@FC:Ecom:Token:Access:apple";

const deleteLoggedUserCookies = (
  cookie: ICookie,
  accessTokenCookieKey: string,
  refreshTokenCookieKey: string
) => {
  cookie.deleteCookie({ name: accessTokenCookieKey });
  cookie.deleteCookie({ name: refreshTokenCookieKey });
  cookie.deleteCookie({ name: accessTokenGoogleCookieKey });
  cookie.deleteCookie({ name: accessTokenAppleCookieKey });
  cookie.deleteCookie({ name: "@FC:Ecom:Token:userList" });
};

const updateLoggedUserTokens = (
  cookie: ICookie,
  authentication: AuthenticationProps
) => {
  if (authentication?.access_token) {
    cookie.setCookie({
      name: "@FC:Ecom:Token:Access",
      value: authentication?.access_token,
    });
  }

  if (authentication?.refresh_token) {
    cookie.setCookie({
      name: "@FC:Ecom:Token:Refresh",
      value: authentication?.refresh_token,
    });
  }
};

export { deleteLoggedUserCookies, updateLoggedUserTokens };
