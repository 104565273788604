import { IHeatmapClient } from "app-domain";
import { obfuscate, useEnvs } from "application";
import Script from "next/script";
import { ClarityEventType } from "typing";

declare const window: Window &
  // eslint-disable-next-line no-undef
  typeof globalThis & {
    clarity: (
      event: ClarityEventType,
      key?: string | string[],
      value?: string | string[],
      customPageId?: string,
      friendlyName?: string
    ) => void;
  };

const isClarityDefined = (): boolean => {
  return !!window?.clarity;
};

const clarityRaw: IHeatmapClient = {
  Id(): string {
    const allConfigs = useEnvs((state) => state.allConfigs);

    return allConfigs?.clarityId ?? "";
  },
  cookieConsent(): void {
    if (isClarityDefined()) {
      window?.clarity("consent");
    }
  },
  customIdentifiers(
    customId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string
  ): void {
    if (isClarityDefined()) {
      window?.clarity(
        "identify",
        customId,
        customSessionId,
        customPageId,
        friendlyName
      );
    }
  },
  customTags(key: string, value: string | string[]): void {
    if (isClarityDefined()) {
      window?.clarity("set", key, value);
    }
  },
  upgradeSession(upgradeReason: string): void {
    if (isClarityDefined()) {
      window?.clarity("upgrade", upgradeReason);
    }
  },
  resetCustomIdentifiers(): void {
    if (isClarityDefined()) {
      window?.clarity("identify", "", "");
    }
  },
  Script(): JSX.Element | null {
    const allConfigs = useEnvs((state) => state.allConfigs);

    if (!["production", "qas"].includes(String(allConfigs?.hostEnv))) {
      return null;
    }

    const clarityId = obfuscate(allConfigs?.clarityId);

    return (
      <Script id="clarity-script" strategy="afterInteractive">
        {`(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "${clarityId}");`}
      </Script>
    );
  },
};

export { clarityRaw };
