import {
  CustomMarqueeProperty,
  Title,
  ShowTitle,
  DesktopImage,
  MobileImage,
  BackgroundColor,
  Text,
} from "typing";

const customMarqueeData = (properties: CustomMarqueeProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const showTitle = (
    properties.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const desktopImage = (
    properties.find(({ key }) => key === "desktopImage") as DesktopImage
  )?.value;

  const mobileImage = (
    properties.find(({ key }) => key === "mobileImage") as MobileImage
  )?.value;

  const backgroundColor = (
    properties.find(({ key }) => key === "backgroundColor") as BackgroundColor
  )?.value;

  const text = (properties.find(({ key }) => key === "text") as Text)?.value;

  return {
    title,
    showTitle,
    desktopImage,
    mobileImage,
    backgroundColor,
    text,
  };
};

export { customMarqueeData };
