import { IconProps } from "typing";
import { theme } from "../themes";

export const Cart = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M7.1279 7.63562H20.2109C20.3721 7.63562 20.4912 7.7859 20.4543 7.94281L18.8003 14.9818C18.7738 15.0948 18.673 15.1746 18.557 15.1746H8.4998C8.38017 15.1746 8.27731 15.0899 8.25442 14.9725L6.88252 7.93345C6.85243 7.77909 6.97064 7.63562 7.1279 7.63562Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M3 3L5.33909 3.38137C5.75366 3.44897 6.08181 3.76844 6.16047 4.18105L6.83623 7.72531"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle
        cx="9.38818"
        cy="19.514"
        r="1.73614"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="16.7643"
        cy="19.514"
        r="1.73614"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
