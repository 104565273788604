import { useCallback, useState } from "react";
import { IAppMonitoringClient } from "app-domain";
import { PurchaseApi } from "../../services";
import { useToast } from "../Toast";
import { getFileByLink } from "../../utils";

export const useGetFiscalNote = (
  purchaseApi: PurchaseApi,
  accessToken: string,
  appMonitoringClient: IAppMonitoringClient,
  documentType?: "Danfe" | "Xml"
) => {
  const [fiscalNote, setFiscalNote] = useState({
    url: "",
    filename: "",
  });

  const { addToast } = useToast();
  const getFiscalNote = useCallback(
    async (
      packageId: number,
      branchIdForNfe: number,
      showToast = true,
      shouldDownloadFile = false
    ) => {
      if (showToast) {
        addToast({
          title: "Aguarde, sua nota fiscal está sendo carregada...",
          description: "",
          timerInMilliseconds: 8000,
          type: "success",
          newToastTheme: "light",
          isNewToast: true,
        });
      }
      purchaseApi
        .getFiscalDocument(
          packageId,
          accessToken ?? "",
          String(branchIdForNfe),
          documentType
        )
        .then((response) => {
          if (shouldDownloadFile) {
            getFileByLink(String(response?.data), response?.fileName);
          }
          setFiscalNote({
            url: String(response?.data || ""),
            filename: response?.fileName,
          });
          if (response.error) {
            addToast({
              title: "Opa! Encontramos um problema ao abrir a nota fiscal",
              description:
                "Por favor, tente novamente mais tarde ou entre em contato com o suporte para obter ajuda",
              type: "error",
              newToastTheme: "light",
              isNewToast: true,
            });
            appMonitoringClient.captureException(response.error);
          }
        })
        .catch((error) => {
          appMonitoringClient.captureException(error);
          addToast({
            title: "Opa! Encontramos um problema ao abrir a nota fiscal",
            description:
              "Por favor, tente novamente mais tarde ou entre em contato com o suporte para obter ajuda",
            type: "error",
            newToastTheme: "light",
            isNewToast: true,
          });
        });
    },
    [accessToken, addToast, appMonitoringClient, documentType, purchaseApi]
  );
  return { fiscalNote, getFiscalNote };
};
