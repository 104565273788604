import { IconProps } from "typing";
import { theme } from "../themes";

export const OrderCancelledIcon = ({
  width = 190,
  height = 190,
  color = theme.colors.neutral["275"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0039 44H58.0039V78H92.0039V44ZM50.0039 86V36H100.004H200.004H250.004V86V169.165C264.493 173.985 292.504 187.707 292.504 205.5C292.504 226.332 263.786 234.735 250.004 234.278V236H235.375C209.041 244.944 99.4194 260.088 76.5039 262.5C38.5039 266.5 8.50406 251 8.00406 221.5C7.61728 198.68 34.9377 180.878 50.0039 174.151V86ZM242.004 78V44H208.004V78H242.004ZM200.004 44V86H242.004V228H100.004V186H58.0039V86H100.004V44H200.004ZM92.0039 194V228H58.0039V194H92.0039ZM216.004 52H234.004V70H216.004V52ZM84.0039 52H66.0039V70H84.0039V52ZM66.0039 202H84.0039V220H66.0039V202ZM135.832 105.172C137.394 106.734 137.394 109.266 135.832 110.828L122.161 124.5L135.832 138.172C137.394 139.734 137.394 142.266 135.832 143.828C134.27 145.391 131.738 145.391 130.175 143.828L116.504 130.157L102.832 143.828C101.27 145.391 98.7378 145.391 97.1753 143.828C95.6132 142.266 95.6132 139.734 97.1753 138.172L110.847 124.5L97.1753 110.828C95.6132 109.266 95.6132 106.734 97.1753 105.172C98.7378 103.609 101.27 103.609 102.832 105.172L116.504 118.843L130.175 105.172C131.738 103.609 134.27 103.609 135.832 105.172ZM170.332 105.172C168.77 103.609 166.238 103.609 164.675 105.172C163.113 106.734 163.113 109.266 164.675 110.828L178.347 124.5L164.675 138.172C163.113 139.734 163.113 142.266 164.675 143.828C166.238 145.391 168.77 145.391 170.332 143.828L184.004 130.157L197.675 143.828C199.238 145.391 201.77 145.391 203.332 143.828C204.894 142.266 204.894 139.734 203.332 138.172L189.661 124.5L203.332 110.828C204.894 109.266 204.894 106.734 203.332 105.172C201.77 103.609 199.238 103.609 197.675 105.172L184.004 118.843L170.332 105.172ZM170.004 184C170.004 172.954 161.05 164 150.004 164C138.958 164 130.004 172.954 130.004 184H170.004Z"
        fill={color}
      />
    </svg>
  );
};
