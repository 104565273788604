import styled from "styled-components";
import { theme } from "ui";

export const FormContainer = styled.section`
  display: flex;
  justify-content: center;
`;

export const FormBox = styled.section`
  background-color: ${theme.colors.neutral.white};
  box-shadow: 0px 2px 4px 0px ${theme.colors.neutral["999-opacity-10"]};
  border-radius: ${theme.space.x4};
  font-size: 1.25rem;

  input:not([type="checkbox"]):not([type="radio"]) {
    font-size: 1.25rem;
    padding: 1.2rem 0.9rem;
  }

  @media ${theme.device.desktopFirst.tabletXL} {
    width: 55.5rem;
  }

  @media ${theme.device.desktopFirst.tabletM} {
    box-shadow: none;
    border-radius: none;
    background-color: transparent;
    width: 100%;
  }
`;
