import { IResponsiveModalContent, IResponsiveModalActions } from "typing";
import { theme } from "ui";
import { BottomSheet } from "../../../BottomSheet";
import { ModalGeneral } from "../../../ModalGeneral";
import { ResponsiveModalContent } from "../ResponsiveModalContent";
import { InputProps } from "../../../Form/Input";

const ResponsiveComponentUI = ({
  closeModal,
  modalTitle,
  isModalOpen,
  isBottomSheet,
  hasHeader = false,
  hideheaderline = false,
  headerPadding,
  contentType: type,
  ...props
}: IResponsiveModalActions & IResponsiveModalContent & InputProps) => {
  if (isBottomSheet) {
    return (
      <BottomSheet
        hideheaderline={hideheaderline}
        omitHeader={!hasHeader}
        headerpadding={headerPadding}
        title={modalTitle}
        onCloseBottomSheet={closeModal}
        stateBottomSheet={isModalOpen}
        noFooter
        justifyContent="space-between"
        display="flex"
        onDismiss={closeModal}
        hasCloseButton
        padding={`${theme.space.x4} ${theme.space.x0}`}
        paddingContent={`${theme.space.x0} ${theme.space.x4}`}
      >
        <ResponsiveModalContent {...props} />
      </BottomSheet>
    );
  }

  if (type === "component") {
    return <ResponsiveModalContent {...props} />;
  }

  return (
    <ModalGeneral
      hasHeader={hasHeader}
      contentGap={theme.space.x0}
      isOpen={isModalOpen}
      title={modalTitle}
      onCloseModal={closeModal}
      closeButtonSize={24}
      fontWeight={theme.typography.fontWeight["400"]}
      bodyContent={<ResponsiveModalContent {...props} />}
      ariaLabelForModal={modalTitle}
    />
  );
};

export { ResponsiveComponentUI };
