import styled from "styled-components";
import { theme } from "ui";
import { ButtonSubmit } from "../../../../ButtonSubmit";

export const TwoFactorContentBodyContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.33rem;
`;

export const CodeInfosContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.16667rem;
  font-weight: ${theme.typography.fontWeight["400"]};
`;

interface ResendCodeProps {
  activeTimer: boolean;
}

export const ResendCode = styled.div<ResendCodeProps>`
  color: ${(activeTimer) =>
    activeTimer.activeTimer
      ? theme.colors.neutral["110"]
      : theme.colors.primary["350"]} !important;
  display: flex;
  justify-content: center;
  cursor: ${(activeTimer) => (activeTimer.activeTimer ? "default" : "pointer")};
`;
export const PasteCode = styled.div`
  display: flex;
  color: ${theme.colors.neutral["110"]} !important;
  justify-content: center;
  cursor: pointer;
`;

export const VerificationCodeInfo = styled.p`
  color: ${theme.colors.neutral["330"]} !important;
  font-size: ${theme.space.x3};
  font-weight: ${theme.typography.fontWeight["400"]};
  white-space: wrap;
`;

export const SelectAnotherTwoFactorType = styled.p`
  display: flex;
  align-self: center;
  color: ${theme.colors.neutral["330"]} !important;
  font-size: ${theme.space["x3.5"]};
  text-decoration: underline;
  cursor: pointer;
`;

interface TwoFactorContentBodyButtonSubmitProps {
  height?: string;
  borderRadius?: string;
}

export const TwoFactorContentBodyButtonSubmit = styled(
  ButtonSubmit
).attrs<TwoFactorContentBodyButtonSubmitProps>(
  ({ height, borderRadius }: TwoFactorContentBodyButtonSubmitProps) => ({
    fontSize: theme.typography.fontSize.sm.x7,
    height: height ?? theme.space["x11.5"],
    borderRadius: borderRadius ?? theme.space.x1,
    fontWeight: theme.typography.fontWeight["400"],
    fontFamily: theme.typography.fontFamily.chivo,
    width: "100%",
  })
)<TwoFactorContentBodyButtonSubmitProps>`
  min-width: 100%;
`;
