import { buildTestIds, useAppContext, useAuth, useToast } from "application";
import { validatePhoneNumber } from "application/utils/validations";
import { profileApi } from "implementations";
import { useState } from "react";
import { SubmitHandler, UseFormRegister, useForm } from "react-hook-form";
import { ChangePhoneDto, IFormValues } from "typing";

import { AccountBaseModal } from "../AccountBaseModal";
import { ChangePhoneNumberInput, ChangePhoneNumberLabel } from "./style";

type ChangePhoneNumberFormValues = {
  phoneNumber: string;
  newPhoneNumber: string;
};

type ChangePhoneNumberProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  finishRegisterNewPhoneNumber: () => void;
  modalHeaderTitle?: string;
  modalInputLabelText?: string;
};

const ChangePhoneNumber = ({
  isModalOpen,
  setIsModalOpen,
  finishRegisterNewPhoneNumber,
  modalHeaderTitle = "Alterar número",
  modalInputLabelText,
}: ChangePhoneNumberProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = useForm<ChangePhoneNumberFormValues>();

  const { isClientTablet } = useAppContext();

  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;

  const { addToast } = useToast();

  const [newPhoneNumberValue, setNewPhoneNumberValue] = useState("");
  const [changingPhoneNumber, setChangingPhoneNumber] = useState(false);

  const newPhoneNumberErrorMessage = validatePhoneNumber(newPhoneNumberValue);

  watch("newPhoneNumber");

  const isFormInvalid = !!newPhoneNumberErrorMessage;

  const onSaveNewPhoneNumber: SubmitHandler<
    ChangePhoneNumberFormValues
  > = async () => {
    if (accessToken) {
      if (newPhoneNumberErrorMessage) {
        addToast({
          title: newPhoneNumberErrorMessage,
          type: "error",
        });
        return;
      }

      setChangingPhoneNumber(true);

      const changePhoneBody: ChangePhoneDto = {
        newPhoneNumber: newPhoneNumberValue,
      };

      const { data, error: changePhoneError } = await profileApi.changePhone(
        accessToken,
        changePhoneBody
      );

      setChangingPhoneNumber(false);

      if (changePhoneError) {
        addToast({
          title: changePhoneError,
          type: "error",
        });
        return;
      }

      if (!data) {
        addToast({
          title: "Ocorreu um erro inesperado, tente novamente",
          type: "error",
        });
        return;
      }

      finishRegisterNewPhoneNumber();

      addToast({
        title: "Número salvo com sucesso!",
        type: "success",
      });
      setIsModalOpen(false);
    }
  };

  return (
    <AccountBaseModal
      isOpen={isModalOpen}
      contentLabel="Edição de número do telefone"
      onCloseModal={() => {
        setIsModalOpen(false);
      }}
      width={isClientTablet ? "90%" : "fit-content"}
      height="22rem"
      title={modalHeaderTitle}
      titleFontSize="16px"
      buttonText="Salvar"
      buttonTextUnderAction="Salvando..."
      buttonUnderAction={changingPhoneNumber}
      buttonWidth="10rem"
      onClickOnButton={handleSubmit(onSaveNewPhoneNumber)}
      isFormInvalid={isFormInvalid}
    >
      {modalInputLabelText && (
        <ChangePhoneNumberLabel
          htmlFor="new-phone-number"
          text={modalInputLabelText}
          {...buildTestIds("my-data-label-new-phone-number")}
          hasSuccessfully={
            !newPhoneNumberErrorMessage && !!newPhoneNumberValue?.length
          }
        />
      )}
      <ChangePhoneNumberInput
        id="new-phone-number"
        name="new-phone-number"
        type="tel"
        mask="(99) 99999-9999"
        placeholder="Digite seu telefone"
        value={newPhoneNumberValue}
        register={register as unknown as UseFormRegister<IFormValues>}
        hasError={newPhoneNumberErrorMessage && !!newPhoneNumberValue?.length}
        hasSuccessfully={
          !newPhoneNumberErrorMessage && !!newPhoneNumberValue?.length
        }
        textError={newPhoneNumberErrorMessage}
        width="100%"
        onChange={({ target }) => {
          const typedPhoneNumber = target?.value?.replace(/\D/g, "");
          setValue("newPhoneNumber", typedPhoneNumber);
          setNewPhoneNumberValue(typedPhoneNumber);
        }}
        disabled={changingPhoneNumber}
        {...buildTestIds("my-data-input-new-phone-number")}
      />
    </AccountBaseModal>
  );
};

export { ChangePhoneNumber };
