import { useAuthentication, useShallow } from "application";
import { useRouter } from "next/router";
import { ChangePasswordFeedback } from "../components/ChangePasswordFeedback";
import { useNewForgotPassword } from "../ForgotPassword/NewForgotPassword/data";

const ChangePasswordDuePurchaseTime = () => {
  const { closeAuthenticationModal } = useAuthentication(
    useShallow((state) => ({
      closeAuthenticationModal: state.closeAuthenticationModal,
    }))
  );

  const router = useRouter();

  const { onVerifyIdentity } = useNewForgotPassword();

  return (
    <ChangePasswordFeedback
      hasReturn={!router?.pathname?.includes("login")}
      onCloseFeedback={closeAuthenticationModal}
      onCallAction={onVerifyIdentity}
      title="Redefinir senha"
      buttonText="Alterar senha"
      description="Novas medidas de segurança foram implementadas para aumentar sua proteção. Por favor, redefina sua senha."
    />
  );
};

export { ChangePasswordDuePurchaseTime };
