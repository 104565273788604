import { IconProps } from "typing";
import { theme } from "../themes";

export const ArrowWidth = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      {" "}
      <path
        d="M12.0687 9.862L14 7.93133L12.0687 6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M4.66669 7.93331H14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M3.93133 6L2 7.93067L3.93133 9.862"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
      <path
        d="M11.3333 7.92867H2"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />{" "}
    </svg>
  );
};
