import { buildTestIds, useToast } from "application";
import {
  useConfirmationTypes,
  useLoginAuthentication,
  useShallow,
  useTwoFactor,
} from "application/state-manager";
import { appMonitoringClient, notificationApi } from "implementations";
import { useCallback, useMemo } from "react";
import { ClientDto, FATypeValues, TwoFactorPublicCodeDto } from "typing";
import { theme } from "ui";
import {
  DoItLaterButton,
  ModalBottom,
  ModalButton,
  ModalContainer,
  ModalDescription,
  ModalDescriptionSubtitle,
  ModalDescriptionTitle,
} from "./styles";

interface ModalBodyProps {
  loggedClient: ClientDto | null | undefined;
  token: string;
}

const defaultErrorMessage =
  "Ocorreu um erro ao enviar o código de verificação.";
const feedbackErrorTitle = "Erro ao enviar o código";

const ModalBody = ({ loggedClient, token }: ModalBodyProps) => {
  const { setIsLoginAuthenticationModalOpen, changeLoginStep } =
    useLoginAuthentication(
      useShallow((state) => ({
        setIsLoginAuthenticationModalOpen:
          state.setIsLoginAuthenticationModalOpen,
        changeLoginStep: state.changeLoginStep,
      }))
    );

  const {
    setChannelSelected,
    setIsBlockedToSendCode,
    setIsValidationActive,
    setInitTimer,
    currentStep,
    setFaType,
    setClientAction,
    getTimer,
  } = useTwoFactor(
    useShallow((state) => ({
      channelSelected: state.channelSelected,
      setChannelSelected: state.setChannelSelected,
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      setIsValidationActive: state.setIsValidationActive,
      setInitTimer: state.setInitTimer,
      setClientAction: state.setClientAction,
      setFaType: state.setFaType,
      currentStep: state.currentStep,
      getTimer: state.getTimer,
    }))
  );

  const handleCloseLoginAuthenticationModal = () => {
    setIsValidationActive(false);
    setIsLoginAuthenticationModalOpen(false);
  };

  const { addToast } = useToast();

  const { availableMethods, canChooseAnotherMethod } = useConfirmationTypes(
    useShallow((state) => ({
      availableMethods: state.availableMethods,
      canChooseAnotherMethod: state.canChooseAnotherMethod,
    }))
  );

  const requestBody = useMemo(() => {
    return {
      email: loggedClient?.email,
      cpf: loggedClient?.cpf,
      faType: FATypeValues.AccountValidatePhone2FA,
      channel: availableMethods?.phoneNumberConfirmed
        ? 1
        : availableMethods?.emailConfirmed
        ? 2
        : 1,
      phoneNumber: loggedClient?.phoneNumber,
      token,
    };
  }, [
    availableMethods?.emailConfirmed,
    availableMethods?.phoneNumberConfirmed,
    loggedClient?.cpf,
    loggedClient?.email,
    loggedClient?.phoneNumber,
    token,
  ]);

  const sendTokenByChannel = useCallback(
    async (body: TwoFactorPublicCodeDto) => {
      try {
        setFaType(FATypeValues.AccountValidatePhone2FA);
        setChannelSelected(body.channel);

        const { isTimerRunning } = getTimer(13, body.channel);

        if (isTimerRunning) {
          setIsValidationActive(true);
          if (currentStep === "verifyIdentity") {
            changeLoginStep("secondStep");
          }
          return;
        }

        if (canChooseAnotherMethod) {
          changeLoginStep("verifyIdentity");
          return;
        }

        setClientAction("loginValidation");

        const { data } = await notificationApi.sendTwoFactorCode(body);

        if (
          data?.validationResult?.errors?.[0]?.errorCode === "222" ||
          [
            "Tente novamente em 24 horas.",
            "Operação bloqueada devido ao número de tentativas excessivas na solicitação do código.",
          ]?.includes(String(data?.validationResult?.errors?.[0]?.errorMessage))
        ) {
          setIsBlockedToSendCode(true);
          setIsValidationActive(true);
          addToast({
            isNewToast: true,
            newToastTheme: "dark",
            type: "error",
            title: feedbackErrorTitle,
            description:
              data?.validationResult?.errors?.[0]?.errorMessage ||
              defaultErrorMessage,
            timerInMilliseconds: 3000,
          });
          return;
        }

        if (
          data?.validationResult?.errors?.[0]?.errorMessage ===
          "Cliente não encontrado"
        ) {
          addToast({
            isNewToast: true,
            newToastTheme: "dark",
            type: "error",
            title: feedbackErrorTitle,
            description: `${
              data?.validationResult?.errors?.[0]?.errorMessage ===
              "Cliente não encontrado"
            }`,
            timerInMilliseconds: 3000,
          });
          setIsBlockedToSendCode(false);
          return;
        }

        if (data?.validationResult?.isValid) {
          addToast({
            title: "Código enviado!",
            isNewToast: true,
            newToastTheme: "light",
            type: "success",
            timerInMilliseconds: 3000,
          });
          setInitTimer(true);
          changeLoginStep("secondStep");
          setIsValidationActive(true);
          setIsBlockedToSendCode(false);
          return;
        }

        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: feedbackErrorTitle,
          description:
            data?.validationResult?.errors?.[0]?.errorMessage ||
            defaultErrorMessage,
          timerInMilliseconds: 3000,
        });
      } catch (error) {
        appMonitoringClient.captureMessage(
          `Ocorreu um erro ao enviar o código de verificação pela função sendTokenByChannel em LoginAuthenticationModal/ModalBody.\nErro: ${error}`,
          {
            level: "error",
          }
        );
        setIsBlockedToSendCode(true);
        setIsValidationActive(true);
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: feedbackErrorTitle,
          description: defaultErrorMessage,
          timerInMilliseconds: 3000,
        });
      }
    },
    [
      addToast,
      canChooseAnotherMethod,
      changeLoginStep,
      currentStep,
      getTimer,
      setChannelSelected,
      setClientAction,
      setFaType,
      setInitTimer,
      setIsBlockedToSendCode,
      setIsValidationActive,
    ]
  );

  return (
    <ModalContainer>
      <ModalDescription>
        <ModalDescriptionTitle>
          Vamos proteger a sua conta?
        </ModalDescriptionTitle>
        <ModalDescriptionSubtitle>
          Torne a sua navegação ainda mais segura com a verificação em duas
          etapas. É bem rápido e prático, vamos começar?
        </ModalDescriptionSubtitle>
      </ModalDescription>
      <ModalBottom>
        <ModalButton
          {...buildTestIds("login-modal-button")}
          text="Confirmar"
          buttonType="button"
          onClickAction={() => {
            sendTokenByChannel(requestBody);
          }}
          fontWeight={theme.typography.fontWeight["400"]}
          color={`${theme.colors.secondary["380"]}`}
          hoveredColor={`${theme.colors.secondary["350"]}`}
        />
        <DoItLaterButton onClick={handleCloseLoginAuthenticationModal}>
          Deixar pra depois
        </DoItLaterButton>
      </ModalBottom>
    </ModalContainer>
  );
};

export { ModalBody };
