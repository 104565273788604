import styled from "styled-components";
import { theme } from "ui";

interface ResendCodeProps {
  activeTimer: boolean;
}

export const ResendCode = styled.div<ResendCodeProps>`
  color: ${(activeTimer) =>
    activeTimer.activeTimer
      ? theme.colors.neutral["110"]
      : theme.colors.primary["350"]} !important;
  display: flex;
  justify-content: center;
  cursor: ${(activeTimer) => (activeTimer.activeTimer ? "default" : "pointer")};
`;
