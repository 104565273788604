import { IconProps } from "typing";

export const EmailValidationErrorIcon = ({
  alt = "Erro na validação por email",
  height = 121,
  width = 121,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 121 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60.5" cy="60.5" r="59.875" fill="#F6F7F7" />
      <path
        d="M90.0003 76.4409L94.1726 69.2069"
        stroke="#B2B3B5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.449 74.9697L89.9996 76.4408"
        stroke="#B2B3B5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.1404 72.0378L91.691 73.5089"
        stroke="#B2B3B5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.7818 69.549C94.9472 70.0415 93.0607 68.9535 92.5683 67.1189C92.0758 65.2843 93.1639 63.3978 94.9984 62.9054C96.833 62.4129 98.7195 63.5009 99.2119 65.3355C99.7044 67.1701 98.6164 69.0566 96.7818 69.549"
        stroke="#B2B3B5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.778 78.2856L110.621 74.3484"
        stroke="#C3C4C5"
        strokeWidth="1.04397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.391 79.3989L113.779 78.2856"
        stroke="#C3C4C5"
        strokeWidth="1.04397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.112 77.8033L112.5 76.69"
        stroke="#C3C4C5"
        strokeWidth="1.04397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.388 72.9539C111.262 74.095 110.236 74.9184 109.094 74.793C107.953 74.6676 107.13 73.6409 107.255 72.4998C107.381 71.3587 108.407 70.5354 109.549 70.6607C110.69 70.7861 111.513 71.8128 111.388 72.9539"
        stroke="#C3C4C5"
        strokeWidth="1.04397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.9067 40.1106L81.3091 33.9133"
        stroke="#C3C4C5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.7215 42.0845L86.9066 40.1108"
        stroke="#C3C4C5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.452 39.5729L84.6371 37.5992"
        stroke="#C3C4C5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.438 31.5355C82.3416 33.4326 80.7255 34.8923 78.8284 34.7958C76.9313 34.6994 75.4716 33.0833 75.568 31.1862C75.6645 29.2891 77.2806 27.8294 79.1777 27.9259C81.0748 28.0223 82.5345 29.6384 82.438 31.5355"
        stroke="#C3C4C5"
        strokeWidth="1.72747"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6689 73.2703L29.1242 67.2794"
        stroke="#B2B3B5"
        strokeWidth="1.4306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5564 72.052L25.6687 73.2703"
        stroke="#B2B3B5"
        strokeWidth="1.4306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9568 69.6239L27.0691 70.8422"
        stroke="#B2B3B5"
        strokeWidth="1.4306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2855 67.5627C29.7662 67.9706 28.2039 67.0695 27.7961 65.5502C27.3882 64.0309 28.2893 62.4686 29.8086 62.0608C31.3279 61.653 32.8902 62.554 33.298 64.0733C33.7058 65.5926 32.8048 67.1549 31.2855 67.5627"
        stroke="#B2B3B5"
        strokeWidth="1.4306"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.379 35.9988L102.481 32.3543"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.0948 35.2577L100.38 35.9988"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.9464 33.7804L101.231 34.5215"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.797 32.5268C102.873 32.7749 101.923 32.2268 101.675 31.3025C101.426 30.3783 101.975 29.4279 102.899 29.1798C103.823 28.9317 104.774 29.4798 105.022 30.4041C105.27 31.3284 104.722 32.2787 103.797 32.5268"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.1392 19.2894L73.2412 15.6449"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.8546 18.5482L71.1396 19.2894"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.7062 17.0711L71.9912 17.8122"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5571 15.8174C73.6328 16.0655 72.6825 15.5173 72.4344 14.5931C72.1863 13.6688 72.7344 12.7184 73.6587 12.4703C74.5829 12.2222 75.5333 12.7704 75.7814 13.6946C76.0295 14.6189 75.4814 15.5693 74.5571 15.8174"
        stroke="#C3C4C5"
        strokeWidth="0.870292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8511 26.2654L39.227 18.6783"
        stroke="#B2B3B5"
        strokeWidth="1.81175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.1775 24.7226L34.8525 26.2655"
        stroke="#B2B3B5"
        strokeWidth="1.81175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9509 21.6475L36.626 23.1904"
        stroke="#B2B3B5"
        strokeWidth="1.81175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.9652 19.0375C40.0411 19.554 38.0626 18.4129 37.5461 16.4888C37.0296 14.5647 38.1708 12.5862 40.0949 12.0697C42.019 11.5532 43.9975 12.6943 44.5139 14.6184C45.0304 16.5425 43.8893 18.521 41.9652 19.0375"
        stroke="#B2B3B5"
        strokeWidth="1.81175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7855 45.3665L19.6329 47.0622C18.9531 47.2495 18.2506 46.8507 18.0633 46.1708L16.8763 41.8639C16.6889 41.1841 17.0878 40.4817 17.7676 40.2943L23.9203 38.5986C24.6001 38.4112 25.3026 38.8101 25.4899 39.49L26.6769 43.7968C26.8643 44.4767 26.4654 45.1791 25.7855 45.3665Z"
        stroke="#C3C4C5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3823 40.1247L17.8736 38.2789V38.2789C17.499 36.9198 18.2972 35.5142 19.6564 35.1396V35.1396C21.0155 34.765 22.4211 35.5632 22.7957 36.9224V36.9224L23.3044 38.7681"
        stroke="#C3C4C5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.8324 95.8263L91.6904 92.046C91.1223 91.6283 91.0004 90.8298 91.4181 90.2616L94.0643 86.6622C94.482 86.094 95.2805 85.9721 95.8487 86.3898L100.991 90.1701C101.559 90.5878 101.681 91.3863 101.263 91.9545L98.6169 95.5539C98.1992 96.1221 97.4006 96.244 96.8324 95.8263Z"
        stroke="#C3C4C5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.3636 86.7678L97.4977 85.2253V85.2253C98.3327 84.0894 99.9307 83.8455 101.067 84.6806V84.6806C102.202 85.5156 102.446 87.1136 101.611 88.2494V88.2494L100.477 89.792"
        stroke="#C3C4C5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.332 55.1134L103.18 56.8091C102.5 56.9965 101.798 56.5976 101.61 55.9177L100.423 51.6109C100.236 50.931 100.635 50.2286 101.315 50.0412L107.467 48.3455C108.147 48.1581 108.849 48.557 109.037 49.2369L110.224 53.5437C110.411 54.2236 110.012 54.926 109.332 55.1134Z"
        stroke="#B2B3B5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.929 49.8715L101.42 48.0257V48.0257C101.046 46.6666 101.844 45.261 103.203 44.8864V44.8864C104.562 44.5118 105.968 45.3101 106.343 46.6692V46.6692L106.851 48.515"
        stroke="#B2B3B5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.293 55.5513L90.3366 58.4938C89.8994 58.8189 89.282 58.7282 88.9568 58.291L86.8971 55.5215C86.5719 55.0843 86.6627 54.4669 87.0999 54.1417L91.0563 51.1993C91.4935 50.8741 92.1109 50.9649 92.4361 51.4021L94.4958 54.1716C94.821 54.6087 94.7302 55.2262 94.293 55.5513Z"
        stroke="#C3C4C5"
        strokeWidth="1.00668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.4953 53.8475L86.6125 52.6606V52.6606C85.9625 51.7866 86.1441 50.551 87.0181 49.901V49.901C87.8921 49.251 89.1277 49.4326 89.7777 50.3066V50.3066L90.6604 51.4935"
        stroke="#C3C4C5"
        strokeWidth="1.00668"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7097 64.2546L6.64156 62.976C6.19203 62.8348 5.94236 62.3562 6.08365 61.9067L6.97866 59.059C7.11994 58.6095 7.59847 58.3598 8.048 58.5011L12.1162 59.7797C12.5657 59.9209 12.8153 60.3995 12.6741 60.849L11.7791 63.6967C11.6378 64.1462 11.1592 64.3959 10.7097 64.2546Z"
        stroke="#B2B3B5"
        strokeWidth="0.870636"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4546 58.6288L8.83818 57.4084V57.4084C9.12061 56.5097 10.0782 56.0101 10.9769 56.2926V56.2926C11.8755 56.575 12.3751 57.5326 12.0927 58.4313V58.4313L11.7091 59.6517"
        stroke="#B2B3B5"
        strokeWidth="0.870636"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4744 53.9527L33.4019 55.274C33.0624 55.42 32.6692 55.2633 32.5232 54.9238L31.5982 52.7731C31.4522 52.4336 31.609 52.0403 31.9485 51.8943L35.0209 50.573C35.3605 50.427 35.7537 50.5837 35.8997 50.9232L36.8246 53.074C36.9706 53.4135 36.8139 53.8067 36.4744 53.9527Z"
        stroke="#C3C4C5"
        strokeWidth="0.682843"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.255 51.7622L31.8586 50.8404V50.8404C31.5667 50.1617 31.8804 49.3748 32.5591 49.0829V49.0829C33.2378 48.791 34.0247 49.1047 34.3166 49.7834V49.7834L34.713 50.7051"
        stroke="#C3C4C5"
        strokeWidth="0.682843"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.4003 23.5915L82.2582 21.24C81.69 20.9802 81.4402 20.3095 81.7001 19.7413L83.3461 16.1418C83.6059 15.5736 84.2766 15.3239 84.8448 15.5837L89.9869 17.9352C90.5551 18.195 90.8049 18.8657 90.545 19.4339L88.899 23.0333C88.6392 23.6015 87.9685 23.8513 87.4003 23.5915Z"
        stroke="#B2B3B5"
        strokeWidth="1.15441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.3598 15.8187L86.0653 14.2761V14.2761C86.5847 13.1402 87.9268 12.6404 89.0627 13.1598V13.1598C90.1986 13.6793 90.6984 15.0214 90.1789 16.1573V16.1573L89.4735 17.6999"
        stroke="#B2B3B5"
        strokeWidth="1.15441"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1781 94.1016L21.0255 95.7973C20.3456 95.9847 19.6432 95.5858 19.4558 94.9059L18.2688 90.5991C18.0815 89.9192 18.4803 89.2168 19.1602 89.0294L25.3128 87.3337C25.9927 87.1463 26.6951 87.5452 26.8825 88.2251L28.0695 92.5319C28.2569 93.2118 27.858 93.9142 27.1781 94.1016Z"
        stroke="#B2B3B5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7749 88.8598L19.2662 87.014V87.014C18.8916 85.6549 19.6898 84.2493 21.0489 83.8747V83.8747C22.4081 83.5001 23.8137 84.2983 24.1883 85.6575V85.6575L24.697 87.5032"
        stroke="#B2B3B5"
        strokeWidth="1.303"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="59.8034"
        cy="105.197"
        rx="22.9753"
        ry="1.53169"
        fill="#C3C4C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4447 87.5354H77.9122C83.7328 87.5354 88.4514 82.8168 88.4514 76.9962V52.8438C88.4514 49.21 86.5794 45.8324 83.4979 43.9065L65.9326 32.9282C62.515 30.7922 58.1786 30.7922 54.761 32.9282L37.1956 43.9065C34.1141 45.8324 32.2422 49.21 32.2422 52.8438L32.2422 65.6932"
        fill="white"
      />
      <path
        d="M57.4447 87.5354H77.9122C83.7328 87.5354 88.4514 82.8168 88.4514 76.9962V52.8438C88.4514 49.21 86.5794 45.8324 83.4979 43.9065L65.9326 32.9282C62.515 30.7922 58.1786 30.7922 54.761 32.9282L37.1956 43.9065C34.1141 45.8324 32.2422 49.21 32.2422 52.8438L32.2422 65.6932"
        stroke="#47494D"
        strokeWidth="3.32639"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.3265 32.2449C65.9071 31.5996 86.5892 47.4085 86.2758 48.699C85.9623 49.9895 62.1469 59.6684 60.2666 59.6684C58.3863 59.6684 51.0273 56.3124 45.8519 54.1837C40.8671 52.1335 33.1607 50.3122 33.944 48.699C34.7273 47.0859 40.5247 42.8917 47.1054 39.0201C51.9701 35.7548 56.4287 32.5291 59.3265 32.2449Z"
        fill="#E5E6E6"
      />
      <path
        d="M85.4466 48.8577L64.5131 58.8209C62.4811 59.951 60.0955 59.951 58.0635 58.8209L35.5508 48.8577"
        stroke="#47494D"
        strokeWidth="3.32639"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="46.7538"
        cy="74.247"
        r="15.5203"
        fill="#BE0F2C"
        stroke="white"
        strokeWidth="3.32639"
      />
      <path
        d="M40.541 67.9845L53.2922 80.7357"
        stroke="#F6F7F7"
        strokeWidth="2.8429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.2922 67.9845L40.541 80.7357"
        stroke="#F6F7F7"
        strokeWidth="2.8429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
