import styled from "styled-components";
import { theme } from "ui";

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 3.75rem;
  padding: 0.65rem 4.9125rem;
  text-align: center;
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1.15rem;
  font-weight: ${theme.typography.fontWeight["400"]};
  line-height: 1.3125rem;
  letter-spacing: 0.005rem;

  border-radius: 4px;
  color: ${theme.colors.neutral.white};
  background: ${theme.colors.primary["350"]};
  cursor: pointer;
  border: 0;
`;

export const ButtonResendModalConfirmEmailError = styled(BaseButton)`
  color: ${theme.colors.neutral.white};
  background: ${theme.colors.primary["350"]};
  transition: background 0.2s ease-in-out;

  &:hover {
    background: ${theme.colors.primary["400"]};
  }
`;

export const ButtonCloseModalConfirmEmailError = styled(BaseButton)`
  color: ${theme.colors.primary["350"]};
  background: ${theme.colors.neutral.white};
  border: 1px solid ${theme.colors.primary["350"]};

  /* transition: background 0.2s ease-in-out; */
  /* &:hover {
    background: ${theme.colors.primary["400"]};
  } */
`;
