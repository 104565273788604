import { useAppContext, useCurrentPathName } from "application";
import { ComponentType } from "react";
import { PageThemeMap } from "typing";

interface IsPageWithBlackTheme {
  (currentPathNameInLowerCase: string, pageThemeMap: PageThemeMap): boolean;
}

const isHomePageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return currentPathNameInLowerCase === "/" && pageThemeMap?.home === "black";
};

const isCategoryPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase?.includes("/categoria/") &&
    pageThemeMap?.category === "black"
  );
};

const isHighlightPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase?.includes("/destaque/") &&
    pageThemeMap?.highlight === "black"
  );
};

const isSearchPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase?.includes("/pesquisa") &&
    pageThemeMap?.searchResult === "black"
  );
};

const isLoginPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase?.includes("/login") &&
    pageThemeMap.login === "black"
  );
};

const isProductPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase?.includes("/produto") &&
    pageThemeMap?.product === "black"
  );
};

const isCartPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase === "/checkout/meu-carrinho" &&
    pageThemeMap?.cart === "black"
  );
};

const isMyAddressPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase === "/conta/meus-enderecos" &&
    pageThemeMap?.myAddresses === "black"
  );
};

const isMyOrdersByPhonePageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase === "/conta/pedidos-eletronicos" &&
    pageThemeMap?.myOrdersByPhone === "black"
  );
};

const isMyOrdersPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase === "/conta/meus-pedidos" &&
    pageThemeMap?.myOrders === "black"
  );
};

const isDetailedOrderPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase?.includes("/detalhamento-do-pedido") &&
    pageThemeMap?.detailedOrder === "black"
  );
};

const isMyCreditCardsPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase === "/conta/meus-cartoes" &&
    pageThemeMap?.myCreditCards === "black"
  );
};

const isMyListPageWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase === "/conta/minhas-listas" &&
    pageThemeMap?.myWeddingLists === "black"
  );
};

const isChangePasswordWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase === "/conta/seguranca/alterar-senha" &&
    pageThemeMap?.changePassword === "black"
  );
};

const isMyDataWithBlackTheme: IsPageWithBlackTheme = (
  currentPathNameInLowerCase: string,
  pageThemeMap: PageThemeMap
) => {
  return (
    currentPathNameInLowerCase === "/conta/meus-dados" &&
    pageThemeMap?.myData === "black"
  );
};

const withBlackThemeValidation = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  const ComponentWithLogic = (
    props: T & JSX.IntrinsicAttributes
  ): JSX.Element => {
    const currentPathName = useCurrentPathName();
    const { pageThemeMap } = useAppContext();

    const currentPathNameInLowerCase = currentPathName?.toLowerCase();

    const isBlackTheme =
      isHomePageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isCategoryPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isHighlightPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isSearchPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isLoginPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isProductPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isCartPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isMyAddressPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isMyOrdersPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isMyCreditCardsPageWithBlackTheme(
        currentPathNameInLowerCase,
        pageThemeMap
      ) ||
      isMyOrdersByPhonePageWithBlackTheme(
        currentPathNameInLowerCase,
        pageThemeMap
      ) ||
      isMyListPageWithBlackTheme(currentPathNameInLowerCase, pageThemeMap) ||
      isDetailedOrderPageWithBlackTheme(
        currentPathNameInLowerCase,
        pageThemeMap
      ) ||
      isChangePasswordWithBlackTheme(
        currentPathNameInLowerCase,
        pageThemeMap
      ) ||
      isMyDataWithBlackTheme(currentPathNameInLowerCase, pageThemeMap);

    const allProps = {
      ...props,
      isBlackTheme,
    };

    return <WrappedComponent {...allProps} />;
  };

  ComponentWithLogic.displayName = "CustomComponentWithBlackThemeValidation";

  return ComponentWithLogic;
};

export { withBlackThemeValidation };
