import { TwoFactorContentProps } from "typing";
import { buildTestIds } from "application";
import { TwoFactorContent } from "../../../2fa";
import { SkeletonWrapper, UniqueClientValidationContainer } from "./styles";
import { Skeleton } from "../../../Skeleton";
import { UniqueClientFeedback } from "../UniqueClientFeedback";

const UniqueClientValidationUI = ({
  isMobile,
  isLoading,
  shouldShowComponent,
  currentStep,
  ...props
}: TwoFactorContentProps & {
  isLoading?: boolean;
  shouldShowComponent?: boolean;
  currentStep: string;
}) => {
  if (!shouldShowComponent) return null;
  if (isLoading)
    return (
      <SkeletonWrapper>
        <Skeleton count={1} height={100} width="100vw" />
      </SkeletonWrapper>
    );

  if (
    ["feedbackSuccess", "feedbackError"]?.includes(currentStep) &&
    !isMobile
  ) {
    return (
      <UniqueClientValidationContainer
        {...buildTestIds("unique-client-validation-container")}
      >
        <UniqueClientFeedback />
      </UniqueClientValidationContainer>
    );
  }

  if (isMobile) {
    return (
      <UniqueClientValidationContainer
        {...buildTestIds("unique-client-validation-container")}
      >
        {!["feedbackSuccess", "feedbackError"]?.includes(currentStep) ? (
          <TwoFactorContent {...props} />
        ) : (
          <UniqueClientFeedback />
        )}
      </UniqueClientValidationContainer>
    );
  }

  return <TwoFactorContent {...props} />;
};

export { UniqueClientValidationUI };
