import { ReactNode } from "react";
import { buildTestIds } from "application";
import { ResponsiveModalContentImage } from "./styles";

export interface IResponsiveModalImage {
  image: ReactNode;
  margin?: string;
}

const ResponsiveModalImage = ({ image, margin }: IResponsiveModalImage) => {
  return (
    <ResponsiveModalContentImage
      margin={margin}
      {...buildTestIds("responsive-modal-content-image")}
    >
      {image}
    </ResponsiveModalContentImage>
  );
};

export { ResponsiveModalImage };
