import { buildTestIds } from "application";
import { TwoFactorOptionsProps } from "typing";
import { ArrowRight, theme } from "ui";
import {
  TwoFactorOptionArrowWrapper,
  TwoFactorOptionContainer,
  TwoFactorOptionDescription,
  TwoFactorOptionIconWrapper,
  TwoFactorOptionInformation,
  TwoFactorOptionTitle,
  TwoFactorOptionWrapper,
  TwoFactorOptionsContainer,
} from "./styles";

const TwoFactorOptions = ({ options }: TwoFactorOptionsProps) => {
  return (
    <TwoFactorOptionsContainer
      {...buildTestIds("two-factor-options-container")}
    >
      {options?.map((option) => (
        <TwoFactorOptionContainer
          onClick={option?.handleOptionAction}
          {...buildTestIds("two-factor-option-container")}
          isValid={option?.isValid}
          key={option?.title}
        >
          <TwoFactorOptionWrapper
            {...buildTestIds("two-factor-option-wrapper")}
          >
            <TwoFactorOptionIconWrapper
              {...buildTestIds("two-factor-option-icon-wrapper")}
            >
              {option?.icon}
            </TwoFactorOptionIconWrapper>
            <TwoFactorOptionInformation
              {...buildTestIds("two-factor-option-information")}
            >
              <TwoFactorOptionTitle
                {...buildTestIds("two-factor-option-title")}
              >
                {option?.title}
              </TwoFactorOptionTitle>
              <TwoFactorOptionDescription
                {...buildTestIds("two-factor-option-description")}
              >
                {option?.description}
              </TwoFactorOptionDescription>
            </TwoFactorOptionInformation>
          </TwoFactorOptionWrapper>
          <TwoFactorOptionArrowWrapper
            {...buildTestIds("two-factor-option-arrow-wrapper")}
          >
            <ArrowRight color={theme.colors.neutral["550"]} />
          </TwoFactorOptionArrowWrapper>
        </TwoFactorOptionContainer>
      ))}
    </TwoFactorOptionsContainer>
  );
};

export { TwoFactorOptions };
