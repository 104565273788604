import { memo } from "react";
import { WafInfos } from "typing";

const WafScript = memo((wafInfos: WafInfos) => {
  if (!wafInfos) {
    return null;
  }

  const { isWafAvailable, awsWafChallengeUrl, awsWafJsApiUrl } = wafInfos;

  if (isWafAvailable !== "true" || !awsWafChallengeUrl || !awsWafJsApiUrl) {
    return null;
  }

  return (
    <>
      <script type="text/javascript" src={awsWafChallengeUrl} defer />
      <script type="text/javascript" src={awsWafJsApiUrl} defer />
    </>
  );
});

WafScript.displayName = "WafScript";

export { WafScript };
