import { ICookie } from "app-domain";
import { useRouter } from "next/router";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { LocationProps, WebSocketEventAction } from "typing";
import { useToast } from "../../hooks/Toast";
import {
  cookieBranchLocationStr,
  cookiePostalCodeStr,
  cookieRegionStr,
  getClientLocationDetails,
  optionsMaxAgeEqualOneYear,
} from "../../utils/misc/branchLocation";
import { usePurchase } from "../Purchase/PurchaseContext";
import { useCartContext } from "../ShoppingHookContext/CartContext";
import { useWebSocket } from "../WebSocket";
import { useBranchLocation } from "../BranchLocationContext";

const checkoutPaymentUrlPath = "/checkout/pagamento";

const defaultLocation = "Demais Regiões";

export interface EventListenerProviderProps {
  children: ReactNode;
  cookie: ICookie;
}

export const EventListenerContext = createContext(null);

export const EventListenerProvider = ({
  children,
  cookie,
}: EventListenerProviderProps) => {
  const { step, changeStep, clearPackageShipping } = usePurchase();
  const { fetchCart } = useCartContext();

  const router = useRouter();

  const { addToast } = useToast();
  const { subscribeToEvents, unsubscribeFromEvents } = useWebSocket();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { triggerGetCartOptions } = usePurchase();

  const { setRegionClientLocation } = useBranchLocation();

  const handleChangesOnClientBranch = useCallback(
    (
      {
        clientLocation = undefined,
        clientPostalCode = undefined,
        clientBranchId = undefined,
      }: Partial<LocationProps>,
      shouldRedirectToHome = false,
      currentPathname = ""
    ) => {
      const {
        clientLocation: currentClientLocation,
        clientPostalCode: currentClientPostalCode,
        clientBranchId: currentClientBranchId,
      } = getClientLocationDetails(cookie);

      setRegionClientLocation(
        clientLocation || currentClientLocation || defaultLocation
      );

      if (
        clientLocation !== undefined &&
        clientLocation !== currentClientLocation
      ) {
        cookie.setCookie({
          name: cookieRegionStr,
          value: `${clientLocation || defaultLocation}`,
          options: optionsMaxAgeEqualOneYear.options,
        });
      }

      if (
        clientPostalCode !== undefined &&
        clientPostalCode !== currentClientPostalCode
      ) {
        cookie.setCookie({
          name: cookiePostalCodeStr,
          value: `${clientPostalCode}`,
          options: optionsMaxAgeEqualOneYear.options,
        });
      }

      if (
        clientBranchId !== undefined &&
        Number(clientBranchId) !== currentClientBranchId
      ) {
        cookie.setCookie({
          name: cookieBranchLocationStr,
          value: `${clientBranchId}`,
          options: optionsMaxAgeEqualOneYear.options,
        });

        if (shouldRedirectToHome) {
          router.push("/");
        }

        if (currentPathname?.includes("/produto/")) {
          router.replace(currentPathname, undefined, {
            shallow: true,
          });
        }

        setTimeout(() => {
          router.reload();
        }, 3000);

        return clientLocation || "";
      }

      return "";
    },
    [cookie, router, setRegionClientLocation]
  );

  useEffect(() => {
    if (isFirstRender) {
      subscribeToEvents(["cartModified", "branchChanged", "login"], (data) => {
        fetchCart(true);

        const currentPathname = window?.location?.pathname;

        if (currentPathname === checkoutPaymentUrlPath) {
          clearPackageShipping();
          changeStep(2);
          if (triggerGetCartOptions) {
            triggerGetCartOptions();
          }
        }

        const formattedData = data as {
          type: WebSocketEventAction;
          detail?: LocationProps;
        };

        const newClientLocation = handleChangesOnClientBranch(
          formattedData?.detail || {},
          [checkoutPaymentUrlPath].includes(currentPathname),
          currentPathname
        );

        addToast({
          title: newClientLocation
            ? `O seu carrinho foi alterado para ${newClientLocation}`
            : "O seu carrinho foi atualizado",
          type: "info",
          timerInMilliseconds: 3000,
        });
      });

      setIsFirstRender(false);
    }
  }, [
    addToast,
    changeStep,
    clearPackageShipping,
    cookie,
    fetchCart,
    handleChangesOnClientBranch,
    isFirstRender,
    router,
    step,
    subscribeToEvents,
    triggerGetCartOptions,
    unsubscribeFromEvents,
  ]);

  return (
    <EventListenerContext.Provider value={null}>
      {children}
    </EventListenerContext.Provider>
  );
};

export const useEventListener = () => {
  const context = useContext(EventListenerContext);

  if (!context) {
    throw new Error(
      "useEventListener must be used within an EventListenerProvider"
    );
  }

  return context;
};
