import { useAuth, useToast, useUser } from "application";
import {
  useLoginAuthentication,
  useShallow,
  useTwoFactor,
} from "application/state-manager";
import { notificationApi } from "implementations";
import { useCallback } from "react";
import {
  FATypeValues,
  IResponsiveModalProps,
  TwoFactorPublicCodeDto,
} from "typing";
import { TelephoneAuthenticationIcon } from "ui";

const usePhoneValidationContentData = () => {
  const {
    setIsValidationActive,
    setChannelSelected,
    setFaType,
    setIsRequesting,
    setIsBlockedToSendCode,
    isBlockedToSendCode,
    setIsTokenCodeValid,
  } = useTwoFactor(
    useShallow((state) => ({
      setIsValidationActive: state.setIsValidationActive,
      setChannelSelected: state.setChannelSelected,
      setFaType: state.setFaType,
      setIsRequesting: state.setIsRequesting,
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      isBlockedToSendCode: state.isBlockedToSendCode,
    }))
  );

  const {
    setIsPhoneValidationModalOpen,
    isPhoneValidationModalOpen,
    changeValidationStep,
  } = useLoginAuthentication(
    useShallow((state) => ({
      setIsPhoneValidationModalOpen: state.setIsPhoneValidationModalOpen,
      isPhoneValidationModalOpen: state.isPhoneValidationModalOpen,
      changeValidationStep: state.changeValidationStep,
    }))
  );

  const { getTokens } = useAuth();

  const accessToken = getTokens()?.token;
  const { loggedClient } = useUser();
  const { addToast } = useToast();

  const checkError = useCallback(
    (errorCode?: string, errorMessage?: string, isValid?: boolean) => {
      if (
        errorCode === "222" ||
        [
          "Tente novamente em 24 horas.",
          "Operação bloqueada devido ao número de tentativas excessivas na solicitação do código.",
        ]?.includes(String(errorMessage))
      ) {
        setIsBlockedToSendCode(true);
        setIsValidationActive(true);
        changeValidationStep("validation");
        setIsTokenCodeValid(false);
        return;
      }

      if (errorMessage === "Cliente não encontrado") {
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: "Erro ao enviar o código",
          description: `${errorMessage}`,
          timerInMilliseconds: 3000,
        });
        setIsBlockedToSendCode(false);
        setIsValidationActive(true);
        return;
      }

      if (!errorMessage && !isValid) {
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: "Erro ao enviar o código",
          description: "Ocorreu um erro ao enviar o código de verificação.",
          timerInMilliseconds: 3000,
        });
      }
    },
    [
      addToast,
      setIsBlockedToSendCode,
      setIsTokenCodeValid,
      setIsValidationActive,
      changeValidationStep,
    ]
  );

  const sendTwoFactorToken = useCallback(
    async ({
      channel,
      faType,
      cpf,
      email,
      phoneNumber,
    }: TwoFactorPublicCodeDto) => {
      setFaType(Number(faType));
      setChannelSelected(channel);
      setIsRequesting(true);

      const storagedTime = localStorage.getItem(
        `timeSent_${faType}_${channel}`
      );

      const actualTime = Date.now();

      const diffTime = Math.floor(
        Number(storagedTime) - Number(actualTime) / 1000
      );

      if (diffTime && diffTime > 0 && storagedTime && !isBlockedToSendCode) {
        setIsValidationActive(true);
        changeValidationStep("validation");
        setIsRequesting(false);
        return;
      }

      const { data } = await notificationApi.sendTwoFactorCode({
        channel,
        faType,
        cpf: String(loggedClient?.cpf) ?? cpf,
        email: String(loggedClient?.email) ?? email,
        phoneNumber: String(loggedClient?.phoneNumber) ?? phoneNumber,
        token: accessToken,
      });

      if (!data?.validationResult?.isValid) {
        checkError(
          data?.validationResult?.errors?.[0]?.errorCode,
          data?.validationResult?.errors?.[0]?.errorMessage,
          data?.validationResult?.isValid
        );
        return;
      }

      setIsRequesting(false);
      setIsValidationActive(true);
      setIsBlockedToSendCode(false);
      changeValidationStep("validation");
    },
    [
      setFaType,
      setChannelSelected,
      setIsRequesting,
      loggedClient?.cpf,
      loggedClient?.email,
      loggedClient?.phoneNumber,
      accessToken,
      checkError,
      setIsValidationActive,
      setIsBlockedToSendCode,
      changeValidationStep,
      isBlockedToSendCode,
    ]
  );

  const onClickOnPrimaryButton = () => {
    setFaType(FATypeValues.AccountValidatePhone2FA);
    setChannelSelected(1);
    sendTwoFactorToken({
      channel: 1,
      faType: FATypeValues.AccountValidatePhone2FA,
      cpf: loggedClient?.cpf ?? "",
      email: loggedClient?.email ?? "",
      phoneNumber: loggedClient?.phoneNumber ?? "",
    });
  };

  const onClickOnSecondaryButton = () => {
    setIsPhoneValidationModalOpen(false);
  };

  const getPhoneValidationContent = (): IResponsiveModalProps => {
    return {
      actions: {
        closeModal: () => {
          setIsPhoneValidationModalOpen(false);
        },
        isModalOpen: isPhoneValidationModalOpen,
        modalTitle: "Validar número de telefone",
        hasHeader: true,
      },
      content: {
        title: "Você ainda não validou seu número de telefone",
        description:
          "Confirme o seu telefone para deixar sua conta ainda mais segura.",
        primaryButtonLabel: "Continuar",
        secondaryButtonLabel: "Deixar para depois",
        image: <TelephoneAuthenticationIcon />,
        onPrimaryButtonClick: onClickOnPrimaryButton,
        onSecondaryButtonClick: onClickOnSecondaryButton,
        margin: "0px 0px 16px",
      },
    };
  };

  return { getPhoneValidationContent };
};

export { usePhoneValidationContentData };
