import { getComponentTheme } from "application";
import {
  Title,
  TextAlign,
  ShowTitle,
  ShowSubTitle,
  SubTitle,
  CustomFAQProperty,
  FAQThemeDto,
} from "typing";
import { theme } from "ui";

const customfAQBlackTheme = (isBlackTheme: boolean) => {
  return isBlackTheme
    ? {
        key: "blackFridayTheme",
        value: JSON.stringify({
          backgroundColor: `${theme.colors.blackFriday["125"]}`,
          secondaryBackgroundColor: `${theme.colors.blackFriday["110"]}`,
          defaultColor: `${theme.colors.blackFriday.white}`,
          textColor: `${theme.colors.blackFriday.white}`,
          borderColor: `${theme.colors.blackFriday["75"]}`,
        }),
      }
    : undefined;
};

const customFAQData = (
  properties: CustomFAQProperty[],
  isBlackTheme: boolean
) => {
  const title = (properties?.find(({ key }) => key === "title") as Title)
    ?.value;

  const textAlign = (
    properties?.find(({ key }) => key === "textAlign") as TextAlign
  )?.value;

  const showTitle = (
    properties?.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const subTitle = (
    properties?.find(({ key }) => key === "subTitle") as SubTitle
  )?.value;

  const showSubTitle = (
    properties?.find(({ key }) => key === "showSubTitle") as ShowSubTitle
  )?.value;

  const blackFridayTheme = customfAQBlackTheme(!!isBlackTheme);

  if (blackFridayTheme) {
    properties?.push(blackFridayTheme as FAQThemeDto);
  }

  const faqBlackFridayTheme = getComponentTheme<FAQThemeDto>(blackFridayTheme);

  return {
    title,
    textAlign,
    showTitle,
    subTitle,
    showSubTitle,
    blackFridayTheme,
    faqBlackFridayTheme,
  };
};

export { customFAQData };
