import { IAppMonitoringClient, ICookie } from "app-domain";
import { CartDto, CartItemsDto, IHttpResponse } from "typing";

import { ShoppingApi } from "../../services";
import {
  cookieBranchLocationStr,
  cookiePostalCodeStr,
  cookieRegionStr,
  optionsMaxAgeEqualOneYear,
} from "../../utils";
import {
  CartIsLimitItemCart,
  CartIsUpdateQuantityItem,
  CartItemsContextReduce,
  ErrorDto,
} from "./typesContextCart";

const NAME_COOKIE_TOKEN_ANONYMOUS = "@FC:Ecom:TokenAnonymous";

export const deleteTokenCartANONYMOUS = (cookieControl: ICookie) => {
  return cookieControl.deleteCookie({ name: NAME_COOKIE_TOKEN_ANONYMOUS });
};

export const getTokenCartANONYMOUS = (cookieControl: ICookie) => {
  return cookieControl.getCookie({ name: NAME_COOKIE_TOKEN_ANONYMOUS });
};

export const createTokenCartANONYMOUS = (
  cookieControl: ICookie,
  value: string
) => {
  return cookieControl.setCookie({ name: NAME_COOKIE_TOKEN_ANONYMOUS, value });
};

export const removeItemAction = (
  itemsList: CartItemsDto[],
  payload: CartIsUpdateQuantityItem
) => {
  return itemsList.filter((item) => item.id !== payload.productIdCart);
};

export const restoreItemInListAction = (
  itemsList: CartItemsDto[],
  itemOldCart: CartItemsDto | null
): CartItemsDto[] => {
  // console.log(itemOldCart, itemsList);
  if (!itemOldCart) {
    return itemsList;
  }

  return itemsList.map((item) => {
    if (item.id === itemOldCart.id) {
      return itemOldCart;
    }
    return item;
  });
};

export const setItemLimit = (
  productList: CartItemsDto[],
  payload: CartIsLimitItemCart
): CartItemsDto[] => {
  return productList.map((item) => {
    if (item.id === payload.productIdCart) {
      return { ...item, limitTotalItens: payload.limitItemCart };
    }
    return item;
  });
};

export const isUpdateQuantityAction = (
  itemsList: CartItemsDto[],
  payload: CartIsUpdateQuantityItem
): CartItemsDto[] => {
  return itemsList.map((item) => {
    if (item.id === payload.productIdCart) {
      return { ...item, quantity: payload.quantityItem || 1 };
    }
    return item;
  });
};

export const findItemCart = (
  itemsList: CartItemsDto[],
  { productIdCart }: CartIsUpdateQuantityItem
): CartItemsDto | undefined => {
  return itemsList.find((item) => item.id === productIdCart);
};

export const addItemList = (
  itemsList: CartItemsDto[],
  payload: CartItemsDto
): CartItemsDto[] => {
  itemsList.push(payload);

  return itemsList;
};

export const persistFromLimitOnCartItem = (
  currentCartItems: CartItemsContextReduce[],
  payload: CartItemsDto[]
): CartItemsContextReduce[] => {
  return payload?.map((product) => {
    const productFound = currentCartItems?.find((currentProduct) => {
      return (
        currentProduct.id === product.id && "limitTotalItens" in currentProduct
      );
    });
    if (productFound) {
      return { ...product, limitTotalItens: productFound.limitTotalItens };
    }
    return product;
  });
};

type ActionType = "add" | "update";

const sendErrorToAppMonitoringClient = (
  actionType: ActionType,
  appMonitoringClient: IAppMonitoringClient,
  reason = "branch_id_changed"
) => {
  const message = {
    add: "Usuário tentou adicionar item no carrinho com uma branch diferente da branch do carrinho",
    update: "Usuário tentou atualizar um item que não existe mais no carrinho",
  }[`${actionType}`];

  const event = {
    add: "add_item_to_cart",
    update: "update_item_to_cart",
  }[`${actionType}`];

  appMonitoringClient.captureMessage(message, {
    level: "error",
    tags: {
      fcx_labs_event: event,
      fcx_labs_error_source: "shopping_api",
      fcx_labs_error_reason: reason,
    },
  });
};

export const handleCartHasChanges = async (
  actionType: ActionType,
  error: ErrorDto,
  accessToken: string,
  shoppingApi: ShoppingApi,
  cookie: ICookie,
  appMonitoringClient: IAppMonitoringClient,
  clientBranchId: number
): Promise<{
  isBranchIdChanged: boolean;
  branchId?: number;
  hasSomeError?: boolean;
}> => {
  const isAddItemError =
    actionType === "add" &&
    "isValid" in error &&
    !error?.isValid &&
    error?.errors?.[0]?.propertyName === "CartItemToAddInCart.BranchId";

  const isUpdateItemError = actionType === "update" && error?.status === 404;

  const hasSomeError = isAddItemError || isUpdateItemError;

  if (hasSomeError) {
    const { data } = (await shoppingApi.fetchCartAuthenticated(
      accessToken as string
    )) as unknown as IHttpResponse<CartDto, unknown>;

    const isBranchIdChanged =
      isAddItemError || clientBranchId !== data?.branchId;

    if (isBranchIdChanged) {
      cookie.setCookie({
        name: cookieBranchLocationStr,
        value: `${data?.branchId}`,
        options: optionsMaxAgeEqualOneYear.options,
      });

      cookie.deleteCookie({
        name: cookieRegionStr,
      });

      cookie.deleteCookie({
        name: cookiePostalCodeStr,
      });
    }

    sendErrorToAppMonitoringClient(
      actionType,
      appMonitoringClient,
      isBranchIdChanged ? undefined : "item_does_not_exist"
    );

    return {
      isBranchIdChanged,
      branchId: data?.branchId as number,
      hasSomeError,
    };
  }

  return {
    isBranchIdChanged: false,
  };
};
