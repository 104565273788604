import styled from "styled-components";
import { theme } from "ui";

export const InputCep = styled.input`
  border: none;
  max-height: 4rem;
  max-width: 16vh;
  padding-left: 0.4rem;
  font-size: 1.2rem;
  color: ${theme.colors.neutral["900"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    max-width: 8rem;
    font-size: 1.2rem;
  }
`;
export const ButtonSearch = styled.button<{ isLoading: boolean }>`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 6rem;
  height: 3rem;
  cursor: pointer;

  border: ${({ isLoading }) =>
    isLoading ? `1px solid ${theme.colors.secondary["265"]}` : `none`};
  background-color: ${({ isLoading }) =>
    isLoading ? theme.colors.neutral.white : theme.colors.secondary["265"]};
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "")};
`;

export const BoxActionZipCode = styled.div`
  align-items: center;
  flex: 0.52;
  border: thin solid ${theme.colors.neutral["95"]};
  border-radius: 8px;
  background-color: ${theme.colors.neutral.white};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  max-height: 4rem;
  padding: 0.6rem;

  @media ${theme.device.desktopFirst.tablet} {
    max-width: 20rem;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    flex: inherit;
    min-width: 240px;
  }
`;

export const ZipCodeForm = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
