import styled from "styled-components";
import { theme } from "ui";

interface IResponsiveModalContentImage {
  margin?: string;
}

export const ResponsiveModalContentImage = styled.div<IResponsiveModalContentImage>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ margin }) => margin ?? `0 0 ${theme.space.x6}`};

  @media ${theme.device.desktopFirst.mobileXL} {
    margin: ${({ margin }) => margin ?? `${theme.space.x4} 0 0`};
  }
`;
