import { CustomMarqueeDto } from "typing";
import { memo } from "react";
import { buildTestIds, useCurrentPathName } from "application";
import { appMonitoringClient } from "implementations";
import { customMarqueeData } from "./data";
import {
  CustomMarqueeContainer,
  ImageSection,
  MarqueeTitle,
  MarqueeTextSection,
  HorizontalLine,
  MainContentWrapper,
  MainContent,
} from "./styles";

const CustomMarquee = memo(
  ({
    properties,
    isClientMobile,
    position,
    preloadComponent,
  }: CustomMarqueeDto) => {
    const {
      title,
      showTitle,
      desktopImage,
      mobileImage,
      backgroundColor,
      text,
    } = customMarqueeData(properties);

    const currentPathName = useCurrentPathName();

    if (!desktopImage) {
      appMonitoringClient.captureMessage(
        `O componente de Letreiro, localizado em "${currentPathName}", na posição ${position}, não possui imagem na versão desktop.`,
        "warning"
      );
    }

    if (!mobileImage) {
      appMonitoringClient.captureMessage(
        `O componente de Letreiro, localizado em "${currentPathName}", na posição ${position}, não possui imagem na versão mobile.`,
        "warning"
      );
    }

    const image = isClientMobile ? mobileImage ?? "" : desktopImage ?? "";

    return (
      <CustomMarqueeContainer {...buildTestIds("custom-marquee-container")}>
        {image && (
          <ImageSection
            alt={title as string}
            src={image}
            opacity={backgroundColor}
            layout="fill"
            objectFit="cover"
            priority={preloadComponent}
            {...buildTestIds("custom-marquee-image")}
          />
        )}
        <MainContentWrapper {...buildTestIds("main-content-wrapper")}>
          <MainContent {...buildTestIds("main-content")}>
            <MarqueeTitle
              showTitle={showTitle}
              {...buildTestIds("marquee-title")}
            >
              {title}
            </MarqueeTitle>
            <HorizontalLine {...buildTestIds("horizontal-line")} />
            <MarqueeTextSection
              {...buildTestIds("marquee-text-section")}
              dangerouslySetInnerHTML={{ __html: text ?? "" }}
            />
          </MainContent>
        </MainContentWrapper>
      </CustomMarqueeContainer>
    );
  }
);

CustomMarquee.displayName = "CustomMarquee";

export { CustomMarquee };
