import { StyledFieldInfo } from "./style";

type FieldInfoProps = {
  text: string;
  color?: string;
  fontSize?: string;
  padding?: string;
};

const FieldInfo = ({ text, ...props }: FieldInfoProps) => {
  return <StyledFieldInfo {...props}>{text}</StyledFieldInfo>;
};

export { FieldInfo };
