import { IconProps } from "typing";
import { theme } from "../themes";

export const AlertWithDetail = ({
  width = 28,
  height = 28,
  alt = "atenção",
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00033 3.48633V3.48633C10.4937 3.48633 12.5143 5.50699 12.5143 8.00033V8.00033C12.5143 10.4937 10.4937 12.5143 8.00033 12.5143V12.5143C5.50699 12.5143 3.48633 10.4937 3.48633 8.00033V8.00033C3.48633 5.50699 5.50699 3.48633 8.00033 3.48633Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00033 8.25147V5.74414"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99966 10.0312C7.94433 10.0312 7.89966 10.0759 7.90033 10.1312C7.90033 10.1865 7.945 10.2312 8.00033 10.2312C8.05566 10.2312 8.10033 10.1865 8.10033 10.1312C8.10033 10.0765 8.05566 10.0312 7.99966 10.0312"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5508 3.83789C15.4028 6.29922 15.4028 9.69989 13.5508 12.1612"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.44466 12.1622C0.592664 9.70087 0.592664 6.3002 2.44466 3.83887"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
