import {
  useAuthentication as defaultUseAuthentication,
  useShallow,
  useTwoFactor as defaultUseTwoFactor,
} from "application/state-manager";
import { ChangePasswordFeedback } from "../components/ChangePasswordFeedback";
import { getFeedbackContent } from "./data";

interface PasswordFeedbackProps {
  useAuthentication?: typeof defaultUseAuthentication;
  useTwoFactor?: typeof defaultUseTwoFactor;
}

const PasswordFeedback = ({
  useAuthentication = defaultUseAuthentication,
  useTwoFactor = defaultUseTwoFactor,
}: PasswordFeedbackProps) => {
  const { closeAuthenticationModal, changeCurrentStep } = useAuthentication(
    useShallow((state) => ({
      closeAuthenticationModal: state.closeAuthenticationModal,
      changeCurrentStep: state.changeCurrentStep,
    }))
  );

  const { isTokenCodeValid } = useTwoFactor(
    useShallow((state) => ({
      isTokenCodeValid: state.isTokenCodeValid,
    }))
  );

  const feedbackContent = getFeedbackContent(isTokenCodeValid);

  return (
    <ChangePasswordFeedback
      onCloseFeedback={closeAuthenticationModal}
      onCallAction={() => changeCurrentStep("login")}
      title={feedbackContent.title}
      buttonText="Ir para login"
      description={feedbackContent.description}
    />
  );
};

export { PasswordFeedback };
