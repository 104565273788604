import { zodResolver } from "@hookform/resolvers/zod";
import { buildTestIds, validateCpf } from "application";
import { identityApi } from "implementations";
import { FieldErrors, UseFormRegister, useForm } from "react-hook-form";
import { theme } from "ui";
import { IFormValues } from "typing";
import { useAuthentication, useShallow } from "application/state-manager";
import { useState } from "react";
import {
  ContentInformation,
  RecoverAccessText,
  SelectWrapper,
  CreateAccount,
  ForgotPasswordContent,
  RecoverInfoText,
  CreateAccountLink,
  LabelSelect,
  ForgotPasswordContainer,
  TitleInformation,
  ContentInformationText,
  ForgotPasswordWrapper,
  FormContainer,
  WrapperIcon,
  ArrowLeftIcon,
  CustomSelect,
  RecoverPasswordInformation,
  StyledInput,
  AlmostThereContainer,
} from "./styles";
import {
  RecoverPasswordFormProps,
  ValidateCpfProps,
  recoverPasswordFormValidateSchema,
  validateCpfSchema,
} from "./schema";
import { ButtonSubmit } from "../../../../../../ButtonSubmit";
import { Link } from "../../../../../../Link";

const OldForgotPasswordLogin = () => {
  const [identificationType, setIdentificationType] = useState<string>("email");

  const [isSubmittingEmail, setIsSubmittingEmail] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const { changeCurrentStep } = useAuthentication(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
    }))
  );

  const options = [
    { value: "email", label: "E-mail" },
    { value: "cpf", label: "CPF" },
  ];

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RecoverPasswordFormProps | ValidateCpfProps>({
    resolver: zodResolver(
      identificationType === "email"
        ? recoverPasswordFormValidateSchema
        : validateCpfSchema
    ),
  });

  const email = watch("recipientEmailForgotPassword");
  const cpf = watch("recipientCPFForgotPassword");
  const isSubmitDisabled = !(email || cpf);

  const onRequestRecoverPassword = async () => {
    if (identificationType === "email") {
      setIsSubmittingEmail(true);
      await identityApi
        .recoverPassword(email as string)
        .then(() => {
          setIsEmailSent(true);
        })
        .catch(() => {
          setIsEmailSent(false);
        })
        .finally(() => {
          setIsSubmittingEmail(false);
        });
    } else {
      setIsSubmittingEmail(true);
      await identityApi
        .recoverPassword(cpf as string)
        .then(() => {
          setIsEmailSent(true);
        })
        .catch(() => {
          setIsEmailSent(false);
        })
        .finally(() => {
          setIsSubmittingEmail(false);
        });
    }
  };

  const emailErrorMessage = (
    errors as unknown as FieldErrors<{
      recipientEmailForgotPassword: string;
    }>
  )?.recipientEmailForgotPassword?.message;

  const checkEmailErrorMessage =
    !isSubmitDisabled && emailErrorMessage && email;

  return (
    <ForgotPasswordContainer {...buildTestIds("forgot-password-container")}>
      <ForgotPasswordWrapper {...buildTestIds("forgot-password-wrapper")}>
        {!isEmailSent ? (
          <ForgotPasswordContent {...buildTestIds("forgot-password-content")}>
            <ContentInformation {...buildTestIds("content-information")}>
              <RecoverAccessText {...buildTestIds("recover-access-text")}>
                Recupere o acesso à sua conta
              </RecoverAccessText>
              <RecoverInfoText {...buildTestIds("recover-info-text")}>
                Digite o seu e-mail ou CPF para continuar.
              </RecoverInfoText>
            </ContentInformation>

            <FormContainer {...buildTestIds("forgot-password-form-container")}>
              <SelectWrapper
                {...buildTestIds("forgot-password-select-wrapper")}
              >
                <LabelSelect
                  {...buildTestIds("label-recipient-forgot-password")}
                >
                  Modo de identificação
                </LabelSelect>
                <CustomSelect
                  {...buildTestIds("forgot-password-select")}
                  autoFocus={false}
                  blurInputOnSelect={false}
                  isSearchable={false}
                  instanceId={1}
                  options={options}
                  defaultValue={options[0]}
                  classNamePrefix="react-select"
                  onChange={(choice) => {
                    const selectedOption = choice as {
                      value: string;
                    };
                    if (selectedOption?.value) {
                      setIdentificationType(selectedOption.value);
                    }
                  }}
                />
              </SelectWrapper>
              {identificationType === "email" ? (
                <StyledInput
                  {...buildTestIds("input-recipient-email-forgot-password")}
                  id="recipientEmailForgotPassword"
                  name="recipientEmailForgotPassword"
                  register={register as unknown as UseFormRegister<IFormValues>}
                  type="text"
                  inputMode="email"
                  placeholder="Digite seu e-mail"
                  hasError={checkEmailErrorMessage}
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                    setValue("recipientEmailForgotPassword", target.value)
                  }
                  height="45px"
                  textError={emailErrorMessage}
                  width="100%"
                  padding={`${theme.space.x3}`}
                  paddingErrorMessage={`${theme.space.x0}`}
                  margin="0px"
                  fontSize={`${theme.typography.fontSize.sm.x5}`}
                  borderRadius={`${theme.space.x1}`}
                />
              ) : (
                <StyledInput
                  {...buildTestIds("input-recipient-cpf-forgot-password")}
                  id="recipientCPFForgotPassword"
                  name="recipientCPFForgotPassword"
                  register={register as unknown as UseFormRegister<IFormValues>}
                  type="tel"
                  mask="999.999.999-99"
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                    setValue(
                      "recipientCPFForgotPassword",
                      target.value.replace(/[^0-9]/g, "")
                    )
                  }
                  placeholder="Digite seu CPF"
                  hasError={!!validateCpf(cpf) && cpf?.length === 11}
                  height="45px"
                  textError="CPF inválido"
                  width="100%"
                  padding={`${theme.space.x3}`}
                  paddingErrorMessage={`${theme.space["x0.5"]} ${theme.space.x0} ${theme.space.x0}`}
                  margin="0px"
                  fontSize={`${theme.typography.fontSize.sm.x5}`}
                  borderRadius={`${theme.space.x1}`}
                />
              )}
              <ButtonSubmit
                text="Enviar"
                textUnderAction="Enviando..."
                onClickAction={handleSubmit(onRequestRecoverPassword)}
                underAction={isSubmittingEmail}
                isDisabled={isSubmitDisabled || isSubmittingEmail}
                minWidth="100%"
                height="37px"
                fontWeight={500}
                borderRadius="8px"
                color={`${theme.colors.secondary["380"]}`}
                hoveredColor={`${theme.colors.secondary["350"]}`}
                {...buildTestIds("button-submit-forgot-password")}
              />
              <CreateAccount {...buildTestIds("create-account-container")}>
                Ainda não tem uma conta?
                <CreateAccountLink {...buildTestIds("create-account-link")}>
                  <Link fontSize="1rem" href="/cadastro" target="_self">
                    Crie uma conta
                  </Link>
                </CreateAccountLink>
              </CreateAccount>
            </FormContainer>
          </ForgotPasswordContent>
        ) : (
          <AlmostThereContainer {...buildTestIds("forgot-password-content")}>
            <WrapperIcon
              onClick={() => changeCurrentStep("login")}
              {...buildTestIds("forgot-password-back-button")}
            >
              <ArrowLeftIcon />
            </WrapperIcon>
            <RecoverPasswordInformation
              {...buildTestIds("content-information-recover-password")}
            >
              <TitleInformation
                {...buildTestIds("title-information-recover-password")}
              >
                Quase lá!
              </TitleInformation>
              <ContentInformationText
                {...buildTestIds("content-information-text")}
              >
                Se os dados estiverem corretos, nós enviaremos um e-mail com as
                instruções para você acessar sua conta.
              </ContentInformationText>
              <ContentInformationText
                {...buildTestIds("content-information-text")}
              >
                Lembra de conferir o lixo eletrônico :)
              </ContentInformationText>
            </RecoverPasswordInformation>
          </AlmostThereContainer>
        )}
      </ForgotPasswordWrapper>
    </ForgotPasswordContainer>
  );
};

export { OldForgotPasswordLogin };
