import { buildTestIds, useAppContext } from "application";
import { useTwoFactor } from "application/state-manager";
import { useShallow } from "application/state-manager/adapter";
import { memo, useEffect } from "react";
import { OnClickIsInvalidClientInfo, SendCodeType, ViewType } from "typing";
import { useRouter } from "next/router";
import { ModalGeneral } from "../../../ModalGeneral";
import { TwoFactorContentBody } from "./Body";
import { TwoFactorDescriptionText } from "./DescriptionText";
import {
  TwoFactorInformation,
  TwoFactorTitle,
  TwoFactorViewContainer,
} from "./styles";

type ViewTwoFactorContentProps = {
  title: string;
  sendCodeType: SendCodeType;
  viewType: ViewType;
  onClickIsInvalidClientInfo?: OnClickIsInvalidClientInfo;
  buttonHeight?: string;
  buttonBorderRadius?: string;
  marginDescription?: string;
  showButton?: boolean;
  shouldMask?: boolean;
  onCallAction: () => void;
  handleSelectAnotherTwoFactorType?: () => void;
  noTitle?: boolean;
  backgroundColor?: string;
  preventDefault?: boolean;
  onClickOnCloseButton?: () => void;
};

const ViewTwoFactorContent = memo(
  ({
    title,
    sendCodeType,
    viewType,
    onCallAction,
    onClickIsInvalidClientInfo,
    buttonBorderRadius,
    buttonHeight,
    marginDescription,
    showButton,
    shouldMask,
    handleSelectAnotherTwoFactorType,
    noTitle,
    backgroundColor,
    onClickOnCloseButton,
    preventDefault = false,
  }: ViewTwoFactorContentProps) => {
    const { isClientMobileS } = useAppContext();
    const router = useRouter();
    const {
      isValidationActive,
      setIsValidationActive,
      setIsBlockedToSendCode,
    } = useTwoFactor(
      useShallow((state) => ({
        isValidationActive: state.isValidationActive,
        setIsValidationActive: state.setIsValidationActive,
        setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      }))
    );

    useEffect(() => {
      const closeValidation = () => {
        setIsValidationActive(false);
        setIsBlockedToSendCode(false);
      };
      router.events.on("routeChangeStart", closeValidation);
      return () => router.events.off("routeChangeStart", closeValidation);
    }, [router, setIsBlockedToSendCode, setIsValidationActive]);

    if (viewType === "component") {
      return isValidationActive ? (
        <TwoFactorViewContainer
          backgroundColor={backgroundColor}
          {...buildTestIds("two-factor-view-container")}
        >
          <TwoFactorInformation>
            {!noTitle && (
              <TwoFactorTitle {...buildTestIds("two-factor-title")}>
                {title}
              </TwoFactorTitle>
            )}
            <TwoFactorDescriptionText
              sendCodeType={sendCodeType}
              marginDescription={marginDescription}
              shouldMask={shouldMask}
              onClickIsInvalidClientInfo={onClickIsInvalidClientInfo}
            />
          </TwoFactorInformation>
          <TwoFactorContentBody
            showButton={showButton}
            buttonBorderRadius={buttonBorderRadius}
            buttonHeight={buttonHeight}
            sendCodeType={sendCodeType}
            isClientMobileS={isClientMobileS}
            handleFinishTwoFactorAction={onCallAction}
            handleSelectAnotherTwoFactorType={handleSelectAnotherTwoFactorType}
            preventDefault={preventDefault}
          />
        </TwoFactorViewContainer>
      ) : null;
    }

    return (
      <ModalGeneral
        ariaHideApp={false}
        isOpen={isValidationActive}
        onCloseModal={() => {
          setIsValidationActive(false);
          if (onClickOnCloseButton) onClickOnCloseButton();
        }}
        ariaLabelForModal={title}
        title={title}
        bodyDescription={
          <TwoFactorDescriptionText
            sendCodeType={sendCodeType}
            shouldMask={shouldMask}
            onClickIsInvalidClientInfo={onClickIsInvalidClientInfo}
          />
        }
        maxWidth="489px"
        bodyContent={
          <TwoFactorContentBody
            showButton={showButton}
            buttonBorderRadius={buttonBorderRadius}
            buttonHeight={buttonHeight}
            sendCodeType={sendCodeType}
            handleFinishTwoFactorAction={onCallAction}
            handleSelectAnotherTwoFactorType={handleSelectAnotherTwoFactorType}
            preventDefault={preventDefault}
          />
        }
        hasTitleBorder={false}
        containerGap="0"
      />
    );
  }
);

ViewTwoFactorContent.displayName = "ViewTwoFactorContent";

export { ViewTwoFactorContent };
