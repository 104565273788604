import styled from "styled-components";
import { theme } from "ui";

interface DropdownBranchLocationContainerProps {
  isHadOpen: boolean;
}

export const Parent = styled.section``;

export const DropdownBranchLocationContainer = styled.section<DropdownBranchLocationContainerProps>`
  z-index: 7;
  max-width: 335px;
  width: 100%;
  height: 8.875rem !important;
  position: absolute;
  margin-top: 2.5rem;
  display: ${({ isHadOpen }) => (isHadOpen ? "flex" : "none")};
  opacity: ${({ isHadOpen }) => (isHadOpen ? 1 : 0)};
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.colors.neutral.white};
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 0.25rem 1rem 0 ${theme.colors.neutral["400"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-top: 7rem;
    max-width: 380px;
    left: 50%;
    transform: translate(-50%, -50%);

    &::after {
      content: none !important;
    }

    span {
      margin-bottom: 0rem !important;
    }
  }

  @media ${theme.device.desktopFirst.mobileM} {
    max-width: 330px !important;
  }

  @media ${theme.device.desktopFirst.mobileS} {
    max-width: 296px !important;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 24px;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    transform: rotate(-45deg);
    transform-origin: left top;
    background: ${theme.colors.neutral.white};
  }
`;
export const DropdownBranchLocationContent = styled.span`
  font-size: 1.125rem;
`;

export const TitleRegionClient = styled.strong`
  font-size: 1.125rem;
  color: ${theme.colors.primary["200"]};
`;

export const WrapperButtons = styled.aside`
  display: flex;
  gap: 0.5rem;
`;

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 2.5rem;
  border-radius: 8px;
  padding: 0.875rem 1rem;
  margin: 0.75rem 0;
  cursor: pointer;
  border: 0;
  transition: all 0.2s ease;
  font-weight: bold;
`;

export const ContinueButton = styled(BaseButton)`
  background: transparent;
  color: ${theme.colors.primary["200"]};

  &:hover {
    background: rgb(218, 8, 18, 0.05);
  }

  &:focus {
    border: thin solid ${theme.colors.primary["200"]};
  }
`;

export const ChangeLocationButton = styled(BaseButton)`
  background: ${theme.colors.primary["200"]};
  color: ${theme.colors.neutral.white};

  &:hover {
    background: ${theme.colors.primary["400"]};
  }

  &:focus {
    border: thin solid ${theme.colors.primary["200"]};
  }
`;
