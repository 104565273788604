import { IHttpClient } from "app-domain/abstractions";
import { defaultHeaders } from "application/utils/http";
import { IHttpResponse } from "typing";

const errorToCallApi = "Erro na chamada da API";

export const FetchHttpClient: IHttpClient = {
  useGet: <Data = unknown, Error = unknown>(url: string) => {
    return fetch(url, {
      headers: { ...defaultHeaders },
    })
      .then((response) => {
        return {
          data: response.json(),
          error: null,
        } as unknown as IHttpResponse<Data, Error>;
      })
      .catch((err) => {
        // console.log(err);
        return {
          data: null,
          error: err || { message: errorToCallApi },
        } as IHttpResponse<Data, Error>;
      });
  },
  usePost: async <Data = unknown, Error = unknown, Response = Data>(
    url: string,
    body: BodyInit | Data
  ) => {
    const formattedBody = body as BodyInit;
    return fetch(url, {
      body: formattedBody,
      method: "POST",
      headers: { ...defaultHeaders },
    })
      .then((response) => {
        return {
          data: response.json(),
          error: null,
        } as unknown as IHttpResponse<Response, Error>;
      })
      .catch((err) => {
        // console.log(err);
        return {
          data: null,
          error: err || { message: errorToCallApi },
        } as IHttpResponse<Response, Error>;
      });
  },
  usePut<Data = unknown>(
    url: string,
    headers: RequestInit["headers"],
    body: BodyInit | Data
  ) {
    const formattedBody = body as BodyInit;

    return fetch(url, {
      headers: { ...headers, ...defaultHeaders },
      body: formattedBody,
      method: "PUT",
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  },
  usePatch<Data = unknown, Error = unknown>(
    url: string,
    headers: RequestInit["headers"],
    body: BodyInit | Data
  ): IHttpResponse<Data, Error> | Promise<IHttpResponse<Data, Error>> {
    const formattedBody = body as BodyInit;

    return fetch(url, {
      headers: { ...headers, ...defaultHeaders },
      body: formattedBody,
      method: "PATCH",
    })
      .then((response) => {
        return {
          data: response.json(),
          error: null,
        } as unknown as IHttpResponse<Data, Error>;
      })
      .catch((err) => {
        // console.log(err);
        return {
          data: null,
          error: err || { message: errorToCallApi },
        } as IHttpResponse<Data, Error>;
      });
  },
  useDelete<Data = unknown, Error = unknown>(
    url: string,
    headers: RequestInit["headers"],
    body?: BodyInit | Data
  ): IHttpResponse<Data, Error> | Promise<IHttpResponse<Data, Error>> {
    const formattedBody = body as BodyInit;

    return fetch(url, {
      headers: { ...headers, ...defaultHeaders },
      body: formattedBody,
      method: "DELETE",
    })
      .then((response) => {
        return {
          data: response.json(),
          error: null,
        } as unknown as IHttpResponse<Data, Error>;
      })
      .catch((err) => {
        // console.log(err);
        return {
          data: null,
          error: err || { message: errorToCallApi },
        } as IHttpResponse<Data, Error>;
      });
  },
};
