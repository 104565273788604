import { CategoryBlockProps } from "typing";
import { buildTestIds } from "application";
import {
  InnerItemsWrapper,
  InnerItemTitleLink,
  ListCategoriesInnerWrapper,
  ListCategoryInnerContainer,
  SubCategory,
  SubCategoryTitle,
  SubCategoryTitleLink,
} from "./styles";

export const ListCategoriesInner = ({
  category: { categories: data },
}: CategoryBlockProps) => {
  return (
    <ListCategoriesInnerWrapper
      {...buildTestIds("list-categories-inner-wrapper")}
    >
      <ListCategoryInnerContainer
        {...buildTestIds("list-category-inner-container-1")}
        // onClick={(event) => {
        //   event?.preventDefault();
        //   event?.stopPropagation();
        // }}
      >
        {data?.map(
          (subCategory) =>
            subCategory.colunm === 1 && (
              <SubCategory
                {...buildTestIds(`sub-category-${subCategory.title}`)}
                key={`${subCategory.id}#${subCategory.title}`}
              >
                <SubCategoryTitleLink
                  {...buildTestIds(
                    `sub-category-title-link-${subCategory.title}`
                  )}
                  href={`/${subCategory?.descriptionUrl}`}
                  target="_self"
                >
                  <SubCategoryTitle
                    {...buildTestIds(`sub-category-title-${subCategory.title}`)}
                  >
                    {subCategory.title}
                  </SubCategoryTitle>
                </SubCategoryTitleLink>
                <InnerItemsWrapper
                  {...buildTestIds(`inner-items-wrapper-${subCategory.title}`)}
                >
                  {subCategory?.categories?.map((innerSubCategory) => (
                    <InnerItemTitleLink
                      {...buildTestIds(
                        `inner-item-title-link-${innerSubCategory.title}`
                      )}
                      key={`${innerSubCategory.id}#${innerSubCategory.title}#${innerSubCategory.descriptionUrl}}`}
                      href={`/${innerSubCategory?.descriptionUrl}`}
                      target="_self"
                    >
                      {innerSubCategory.title}
                    </InnerItemTitleLink>
                  ))}
                </InnerItemsWrapper>
              </SubCategory>
            )
        )}
      </ListCategoryInnerContainer>
      <ListCategoryInnerContainer
        {...buildTestIds("list-category-inner-container-2")}

        // onClick={(event) => {
        //   event?.preventDefault();
        //   event?.stopPropagation();
        // }}
      >
        {data?.map(
          (subCategory) =>
            subCategory.colunm === 2 && (
              <SubCategory
                {...buildTestIds("sub-category")}
                key={`${subCategory.id}#${subCategory.title}`}
              >
                <SubCategoryTitleLink
                  {...buildTestIds("sub-category-title-link")}
                  href={`/${subCategory?.descriptionUrl}`}
                  target="_self"
                >
                  <SubCategoryTitle {...buildTestIds("sub-category-title")}>
                    {subCategory.title}
                  </SubCategoryTitle>
                </SubCategoryTitleLink>
                <InnerItemsWrapper {...buildTestIds("inner-items-wrapper")}>
                  {subCategory?.categories?.map((innerSubCategory) => (
                    <InnerItemTitleLink
                      {...buildTestIds("inner-item-title-link")}
                      key={`${innerSubCategory.id}#${innerSubCategory.title}#${innerSubCategory.descriptionUrl}}`}
                      href={`/${innerSubCategory?.descriptionUrl}`}
                      target="_self"
                    >
                      {innerSubCategory.title}
                    </InnerItemTitleLink>
                  ))}
                </InnerItemsWrapper>
              </SubCategory>
            )
        )}
      </ListCategoryInnerContainer>
      <ListCategoryInnerContainer
        {...buildTestIds("list-category-inner-container-3")}

        // onClick={(event) => {
        //   event?.preventDefault();
        //   event?.stopPropagation();
        // }}
      >
        {data?.map(
          (subCategory) =>
            subCategory.colunm === 3 && (
              <SubCategory
                {...buildTestIds("sub-category")}
                key={`${subCategory.id}#${subCategory.title}`}
              >
                <SubCategoryTitleLink
                  {...buildTestIds("sub-category-title-link")}
                  href={`/${subCategory?.descriptionUrl}`}
                  target="_self"
                >
                  <SubCategoryTitle {...buildTestIds("sub-category-title")}>
                    {subCategory.title}
                  </SubCategoryTitle>
                </SubCategoryTitleLink>
                <InnerItemsWrapper {...buildTestIds("inner-items-wrapper")}>
                  {subCategory?.categories?.map((innerSubCategory) => (
                    <InnerItemTitleLink
                      {...buildTestIds("inner-item-title-link")}
                      key={`${innerSubCategory.id}#${innerSubCategory.title}#${innerSubCategory.descriptionUrl}}`}
                      href={`/${innerSubCategory?.descriptionUrl}`}
                      target="_self"
                    >
                      {innerSubCategory.title}
                    </InnerItemTitleLink>
                  ))}
                </InnerItemsWrapper>
              </SubCategory>
            )
        )}
      </ListCategoryInnerContainer>
    </ListCategoriesInnerWrapper>
  );
};
