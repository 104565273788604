import styled, { css } from "styled-components";
import { FlexContainer } from "../Container";
import { theme } from "../themes";

interface DiscountDefaultProps {
  fontSizeDiscount?: string;
  notShouldShowOldPrice?: boolean;
  marginRight?: string;
}

export const OldPrice = styled.span<DiscountDefaultProps>`
  font-size: ${({ fontSizeDiscount }) => fontSizeDiscount ?? "1.15rem"};
  color: ${theme.colors.neutral["350"]};
  text-decoration: line-through;
  margin-right: ${({ marginRight }) => marginRight || `${theme.space.x2}`};
  font-weight: ${theme.typography.fontWeight["500"]};
  font-family: ${theme.typography.fontFamily.chivo};

  display: ${({ notShouldShowOldPrice }) =>
    notShouldShowOldPrice ? "none" : "initial"};
  user-select: none;
`;

export const DiscountTagContainer = styled.section`
  width: 5.875rem;
  height: 1.75rem;
  padding: 0 0.35rem 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 0.25rem;

  border-radius: 1rem 0.35rem 0.35rem 1rem;
  background: ${theme.colors.tertiary["930"]};
`;

export const DiscountTagDot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: ${theme.colors.neutral.white};
  padding: 0.265rem;
  margin-left: 4px;
`;

export const DiscountTagText = styled.span<DiscountDefaultProps>`
  font-size: ${({ fontSizeDiscount }) => fontSizeDiscount ?? "1.15rem"};
  font-weight: ${theme.typography.fontWeight["600"]};
  font-family: ${theme.typography.fontFamily.chivo};
  color: ${theme.colors.tertiary["150"]};

  white-space: nowrap;

  @media ${theme.device.desktopFirst.tabletS} {
    display: flex;
    justify-content: center;
  }
  line-height: 150%;
  letter-spacing: -0.0235rem;
  user-select: none;
`;

export const DiscountPercentageContainer = styled(FlexContainer)`
  align-items: center;
  width: fit-content;
  padding: 0 ${theme.space.x2} 0 ${theme.space.x1};
  border-radius: 12px 4px 4px 12px;
  background: ${theme.colors.tertiary["930"]};
  gap: ${theme.space.x1};
`;

interface PriceValueDetailProps {
  fontSize?: string;
  isBlackTheme?: boolean;
}

export const PriceValueDetail = styled.span<PriceValueDetailProps>`
  color: ${theme.colors.primary["200"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${({ fontSize }) => fontSize || "1.15rem"};
  font-weight: ${theme.typography.fontWeight["500"]};
`;

export const DiscountContainer = styled(FlexContainer)`
  align-items: center;
  margin-top: ${theme.space.x2};
  gap: ${theme.space.x2};

  @media ${theme.device.desktopFirst.tablet} {
    margin-top: ${theme.space["x0.5"]};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-top: 0px;
  }
`;

interface PriceDefaultProps {
  display?: string;
  gap?: string;
  fontSize?: string;
  fontSizeLaptop?: string;
  fontSizeTablet?: string;
  fontSizeMobile?: string;
  fontSizeMobileLarge?: string;
  marginTop?: string;
  marginBottom?: string;
  isBlackTheme?: boolean;
  isFlexDirectionColumn?: boolean;
  margin?: string;
}

export const PriceValue = styled.h2<PriceDefaultProps>`
  display: ${({ display, isFlexDirectionColumn }) =>
    isFlexDirectionColumn ? "flex" : display || "block"};
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
  font-size: ${({ fontSize }) => fontSize ?? "3rem"};
  font-weight: ${theme.typography.fontWeight["700"]};
  color: ${theme.colors.primary["200"]};
  flex-direction: ${({ isFlexDirectionColumn }) =>
    isFlexDirectionColumn ? "column" : "row"};

  ${({ fontSizeLaptop }) =>
    fontSizeLaptop &&
    css`
      @media ${theme.device.desktopFirst.laptop} {
        font-size: ${fontSizeLaptop};
      }
    `}
  ${({ fontSizeTablet }) =>
    fontSizeTablet &&
    css`
      @media ${theme.device.desktopFirst.tablet} {
        font-size: ${fontSizeTablet};
      }
    `}
    ${({ fontSizeMobileLarge }) =>
    fontSizeMobileLarge &&
    css`
      @media ${theme.device.desktopFirst.mobileXL} {
        font-size: ${fontSizeMobileLarge};
      }
    `}
    ${({ fontSizeMobile }) =>
    fontSizeMobile &&
    css`
      @media ${theme.device.desktopFirst.mobileL} {
        font-size: ${fontSizeMobile};
      }
    `}
`;

export const SpotPriceText = styled.span`
  font-size: ${theme.typography.fontSize.sm.x5};
  white-space: nowrap;
  align-self: flex-end;
  font-weight: ${theme.typography.fontWeight["400"]};

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    align-self: flex-start;
    font-size: ${theme.typography.fontSize.sm.x1};
  }
`;

export const PriceValueText = styled.span``;

interface PriceMainContainerProps {
  childrenQuantity: number;
}

export const PriceMainContainer = styled(
  FlexContainer
)<PriceMainContainerProps>`
  flex-direction: column;
  align-self: flex-start;
  gap: ${theme.space.x2};

  ${({ childrenQuantity }) =>
    childrenQuantity > 1 &&
    css`
      @media ${theme.device.desktopFirst.tablet} {
        flex-direction: column;
        gap: ${theme.space.x1};

        ${DiscountContainer} {
          flex-direction: row;
        }
      }

      @media ${theme.device.desktopFirst.mobileL} {
        gap: ${theme.space.x1};
      }
    `}
`;

interface PriceInstallmentsProps {
  color?: string;
}

export const PriceInstallments = styled.span<PriceInstallmentsProps>`
  width: 100%;
  display: flex;
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${({ color }) => color ?? theme.colors.neutral["999"]};
  line-height: 17px;
  letter-spacing: 0;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }

  @media ${theme.device.desktopFirst.tabletS} {
    font-size: ${theme.typography.fontSize.sm.x3};
    line-height: ${theme.typography.fontSize.sm.x5};
  }
`;

export const SaleTagContainer = styled(FlexContainer)`
  flex-direction: row;
  align-items: center;
`;

export const SaleTagCircle = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid ${theme.colors.neutral.white};
  border-radius: 100%;
  background-color: ${theme.colors.primary["200"]};
  z-index: 2;

  @media ${theme.device.desktopFirst.tabletS} {
    width: 30px;
    height: 30px;
  }
`;

export const SaleTagTextContainer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.primary["200"]};
  color: ${theme.colors.neutral.white};
  width: 135px;
  height: 30px;
  border: 1px solid ${theme.colors.neutral["100"]};
  border-radius: 15px;
  margin-left: -40px;
  padding-left: 15px;

  @media ${theme.device.desktopFirst.tabletS} {
    width: 83px;
    height: 20px;
    margin-left: -20px;
  }
`;
