import styled from "styled-components";
import { theme } from "ui";
import {
  ButtonArrowNext,
  ButtonArrowPrev,
  ButtonViewMore,
  ContainerCarousel,
  ContainerProduct,
  ContainerView,
  ProductCardDiv,
} from "../backoffice/CustomProductList/styles";
import ProductCard from "../ProductCard";

export const RecommendedProductListTitle = styled.div`
  font-weight: ${theme.typography.fontWeight[400]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.md.x3};
  color: ${({ color }) => color || theme.colors.neutral["520"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x9};
  }
`;

export const RecommendedContainerCarousel = styled(ContainerCarousel)``;

export const RecommendedContainerProduct = styled(ContainerProduct)``;

export const RecommendedProductListHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${theme.space.x5};
  padding: ${theme.space.x0} ${theme.space.x5} ${theme.space.x0}
    ${theme.space.x4};

  @media ${theme.device.desktopFirst.tablet} {
    padding: ${`${theme.space.x0} ${theme.space["x2.5"]} ${theme.space.x0}
    ${theme.space.x3}`};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: ${theme.space.x3};
    margin-bottom: ${theme.space.x0};
  }
`;

export const RecommendedButtonArrowPrev = styled(ButtonArrowPrev)``;

export const RecommendedButtonArrowNext = styled(ButtonArrowNext)``;

export const RecommendedButtonViewMore = styled(ButtonViewMore)``;

export const RecommendedProductCardDiv = styled(ProductCardDiv)``;

export const RecommendedContainerView = styled(ContainerView)``;

export const RecommendedProductCardContainer = styled.div`
  display: flex;
  padding: ${theme.space.x0} ${theme.space["x2.5"]};
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: ${theme.space.x3};
  height: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    gap: ${theme.space.x2};

    & > a {
      min-width: 190px;
    }
  }
`;
type RecommendedProductBoxCardProps = {
  height?: number;
};

export const RecommendedProductBoxCard = styled.div<RecommendedProductBoxCardProps>`
  display: flex;
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
`;

type RecommendedProductCardProps = {
  className?: string;
};

export const RecommendedProductCard = styled(
  ProductCard
)<RecommendedProductCardProps>``;

type RecommendedProductListContainerProps = {
  isBlackTheme?: boolean;
};
export const RecommendedProductListContainer = styled.section<RecommendedProductListContainerProps>`
  @media (min-width: 1190px) {
    padding: 0 1.34rem;
  }

  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (min-width: 1190px) {
    padding: 0 1.34rem;
  }

  .slick-track {
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    transition: all 0.5ms ease-in-out;

    @media ${theme.device.desktopFirst.tablet} {
      margin-left: 10px;
    }
  }

  .slick-slide:first-child {
    margin: 0px 9px 0px 0px;
  }

  @media ${theme.device.desktopFirst.laptop} {
    .slick-slide {
      width: 190px !important;
      margin: 0px 5px;
    }

    .slick-slide:first-child {
      margin: 0px 5px 0px 12px;
    }
  }

  .slick-slide > div {
    width: 226px;
    height: 100%;

    @media (max-width: 1024px) {
      width: 205px;
    }
  }

  @media (max-width: 1014px) and (min-width: 769px) {
    ${RecommendedProductCardContainer} {
      overflow-x: auto;
    }
  }

  @media (min-width: 769px) and (max-width: 1260px) {
    .slick-slide > div {
      width: 205px;
    }
  }

  .slick-list {
    padding: 0.6rem 0;
    width: 100%;
  }

  .slick-track {
    width: fit-content !important;
    overflow: hidden;

    @media ${theme.device.desktopFirst.tablet} {
      padding-top: ${theme.space.x3};
    }
  }

  .slick-prev:before {
    display: none;
  }

  .slick-next:before {
    display: none;
  }

  .slick-disabled {
    background: ${theme.colors.neutral["50"]};
    opacity: 1;
    visibility: visible;

    &:hover {
      background: ${theme.colors.neutral["50"]};
    }
    cursor: default;

    path {
      stroke: ${theme.colors.neutral["99"]};
    }

    &:hover path {
      stroke: ${theme.colors.neutral["99"]};
    }
  }

  .slick-slide {
    width: 226px !important;
    margin: 0px 9px;

    @media ${theme.device.desktopFirst.laptop} {
      width: 203px !important;
    }

    @media ${theme.device.desktopFirst.desktop} {
      .slick-slide:first-child {
        margin-left: 0px;
      }

      .slick-slide:last-child {
        margin-right: 0px;
      }

      .slick-slide {
        margin: 0px 9px;
      }
    }
  }

  .slick-slide:last-child {
    margin: 0px 12px 0px 5px;
  }

  .slick-track {
    padding-top: 0.5rem;
  }

  .slick-slider {
    @media ${theme.device.desktopFirst.desktop} {
      padding: 0px 15px;
    }

    @media ${theme.device.desktopFirst.laptop} {
      padding: 0px;
    }
  }

  @media (min-width: 1025px) and (max-width: 1260px) {
    .slick-slide > div {
      width: 100%;
    }

    .slick-slider {
      padding: 0px;
    }

    .slick-slide:first-child {
      margin-left: 12px;
    }

    .slick-slide:last-child {
      margin-right: 12px;
    }

    .slick-track {
      width: 161.7% !important;
    }

    .slick-slide:last-child {
      margin-right: 28px;
    }
  }
`;
