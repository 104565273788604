import { IconProps } from "typing";
import { theme } from "../themes";

export const AddressListIcon = ({
  width = 28,
  height = 28,
  alt = "meus endereços",
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6663 28H7.99967C7.29262 28.0002 6.61445 27.7195 6.11436 27.2197C5.61426 26.7199 5.3332 26.0419 5.33301 25.3348V6.66667C5.33281 5.95962 5.6135 5.28145 6.11332 4.78135C6.61314 4.28125 7.29116 4.00019 7.99821 4H23.9997C24.7067 3.9998 25.3849 4.28049 25.885 4.78031C26.3851 5.28013 26.6661 5.95815 26.6663 6.6652V13.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16309 10.6667H7.33315"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16309 15.9997H7.33315"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 21.3337H7.33333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.667 19.9997V25.333C14.667 26.8058 15.8609 27.9997 17.3337 27.9997H25.3337C26.8064 27.9997 28.0003 26.8058 28.0003 25.333V19.9997C28.0003 18.5269 26.8064 17.333 25.3337 17.333H17.3337C15.8609 17.333 14.667 18.5269 14.667 19.9997Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.333 21.333L20.7405 22.5274C21.1138 22.7127 21.5523 22.7127 21.9257 22.5274L24.333 21.333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
