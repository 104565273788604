import styled from "styled-components";
import { theme } from "ui";
import { Image } from "../../../Image";

export const Container = styled.section`
  display: flex;
`;

export const ImageContainer = styled.figure`
  position: relative;
  width: 100%;
  height: 100%;

  & > span {
    position: unset !important;
    height: 100% !important;
    width: 100% !important;
  }
`;

export const BannerImage = styled(Image)`
  width: 100% !important;
  position: relative !important;
  height: auto !important;
  border-radius: ${theme.space.x2} ${theme.space.x3} ${theme.space.x3}
    ${theme.space.x2} !important;
`;
