import { IconProps } from "typing";

export const ListIcon = ({ alt, color, height, width }: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3335 21.99C17.3981 23.3247 17.9303 24.5942 18.8368 25.576C19.5608 26.417 20.4247 27.1265 21.3904 27.6732C22.2313 28.1423 23.2619 28.112 24.0737 27.5941C24.949 27.0347 25.736 26.3478 26.4088 25.5562C27.332 24.5844 27.8951 23.326 28.0047 21.99C28.011 21.1952 27.7138 20.4279 27.1737 19.8447C26.6547 19.2792 25.9242 18.9546 25.1566 18.9483C24.1929 18.8809 23.2615 19.3106 22.6874 20.0877L22.6692 20.1144L22.6507 20.0877C22.1283 19.3525 21.2744 18.9254 20.3728 18.9483C19.5622 18.9345 18.7807 19.2507 18.2077 19.8242C17.6346 20.3977 17.3191 21.1794 17.3335 21.99Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.334 13.332H22.6695"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33008 12.9664L10.5559 14.0709L13.0236 11.8486"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3323 28.0051H10.6646C6.98113 28.0051 3.99512 25.0191 3.99512 21.3357V10.6646C3.99512 6.98113 6.98113 3.99512 10.6646 3.99512H21.3357C25.0191 3.99512 28.0051 6.98113 28.0051 10.6646V14.6662"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33008 19.6364L10.5559 20.7408L13.0236 18.5186"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
