import { useAppContext, useAuth } from "application";
import {
  useLoginAuthentication,
  useRegisterPhoneNumber,
  useShallow,
  useTwoFactor,
  useUniqueClient,
} from "application/state-manager";
import { notificationApi, profileApi } from "implementations";
import { useRouter } from "next/router";
import { AuthenticationIcon, CloseIcon } from "ui";
import { TwoFactorContent } from "../../../../../../2fa";
import { BottomSheet } from "../../../../../../BottomSheet";
import { ModalGeneral } from "../../../../../../ModalGeneral";
import { methods, useLoginAuthenticationModal } from "./data";
import { UniqueClient } from "../../../../../../UniqueClient";
import { PhoneValidation } from "./components/PhoneValidation";
import { ModalBody } from "./components/ModalBody";
import { RegisterPhoneNumber } from "../../../../../../RegisterPhoneNumber";

const LoginAuthenticationModal = () => {
  const {
    isLoginAuthenticationModalOpen,
    currentStep,
    changeLoginStep,
    isPhoneValidationModalOpen,
  } = useLoginAuthentication(
    useShallow((state) => ({
      isLoginAuthenticationModalOpen: state.isLoginAuthenticationModalOpen,
      currentStep: state.currentStep,
      changeLoginStep: state.changeLoginStep,
      isPhoneValidationModalOpen: state.isPhoneValidationModalOpen,
    }))
  );

  const { isRegisterPhoneNumberModalOpen } = useRegisterPhoneNumber(
    useShallow((state) => ({
      isRegisterPhoneNumberModalOpen: state.isRegisterPhoneNumberModalOpen,
    }))
  );

  const { getTokens } = useAuth();

  const accessToken = getTokens()?.token as string;

  const { data: loggedClient } = profileApi.getClient(
    accessToken,
    !!accessToken
  );

  const { isClientMobile } = useAppContext();
  const router = useRouter();

  const { isUniqueClientModalOpen } = useUniqueClient(
    useShallow((state) => ({
      isUniqueClientModalOpen: state.isUniqueClientModalOpen,
    }))
  );

  const {
    handleCloseLoginAuthenticationModal,
    handleValidateAccountInfo,
    sendTokenByChannel,
    isUnderAction,
    hasError,
  } = useLoginAuthenticationModal({
    accessToken,
    loggedClient,
    notificationApi,
  });

  const { channelSelected } = useTwoFactor(
    useShallow((state) => ({
      channelSelected: state.channelSelected,
    }))
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderAuthenticationContent = () => {
    switch (currentStep) {
      case "firstStep":
        return isClientMobile ? (
          <BottomSheet
            alignItems="center"
            display="flex"
            title="Verificação em duas etapas"
            textButton={<CloseIcon />}
            justifyContent="space-between"
            hasActionInHeader
            paddingContent="16px 16px 0px"
            containerHeight="420px"
            headerAction={handleCloseLoginAuthenticationModal}
            noFooter
            formId="form-new-address"
            stateBottomSheet={isLoginAuthenticationModalOpen}
            onDismiss={handleCloseLoginAuthenticationModal}
            onCloseBottomSheet={handleCloseLoginAuthenticationModal}
          >
            <>
              <AuthenticationIcon />
              <ModalBody loggedClient={loggedClient} token={accessToken} />
            </>
          </BottomSheet>
        ) : (
          <ModalGeneral
            ariaHideApp={false}
            isOpen={isLoginAuthenticationModalOpen}
            onCloseModal={handleCloseLoginAuthenticationModal}
            ariaLabelForModal="Verificação em duas etapas"
            title="Verificação em duas etapas"
            bodyDescription={<AuthenticationIcon />}
            maxWidth="489px"
            bodyContent={
              <ModalBody loggedClient={loggedClient} token={accessToken} />
            }
            contentGap="0"
          />
        );
      case "secondStep":
        return isClientMobile ? (
          <BottomSheet
            alignItems="center"
            display="flex"
            title="Verificação em duas etapas"
            noFooter
            textButton={<CloseIcon />}
            justifyContent="space-between"
            hasActionInHeader
            paddingContent="16px 16px 0px"
            containerHeight="420px"
            headerAction={handleCloseLoginAuthenticationModal}
            formId="form-new-address"
            stateBottomSheet={isLoginAuthenticationModalOpen}
            onDismiss={handleCloseLoginAuthenticationModal}
            onCloseBottomSheet={handleCloseLoginAuthenticationModal}
          >
            <TwoFactorContent
              options={methods(
                changeLoginStep,
                sendTokenByChannel,
                loggedClient
              )}
              onClickIsInvalidClientInfo={() => []}
              handleSelectAnotherTwoFactorType={() =>
                changeLoginStep("verifyIdentity")
              }
              preventDefault
              noTitle
              sendCodeType={channelSelected === 1 ? "sms" : "email"}
              clientAction="loginValidation"
              email={String(loggedClient?.email)}
              phoneNumber={String(loggedClient?.phoneNumber)}
              viewType={isClientMobile ? "component" : "modal"}
              canChooseAnotherTwoFactorMethod
              hasError={hasError}
              isUnderAction={isUnderAction}
              onClickFailureButton={() => {
                router.push("/");
              }}
              onCallAction={handleValidateAccountInfo}
            />
          </BottomSheet>
        ) : (
          <TwoFactorContent
            options={methods(changeLoginStep, sendTokenByChannel, loggedClient)}
            handleSelectAnotherTwoFactorType={() =>
              changeLoginStep("verifyIdentity")
            }
            onClickIsInvalidClientInfo={() => []}
            sendCodeType={channelSelected === 1 ? "sms" : "email"}
            clientAction="loginValidation"
            preventDefault
            email={String(loggedClient?.email)}
            phoneNumber={String(loggedClient?.phoneNumber)}
            viewType={isClientMobile ? "component" : "modal"}
            canChooseAnotherTwoFactorMethod
            hasError={hasError}
            isUnderAction={isUnderAction}
            onClickFailureButton={() => {
              router.push("/");
            }}
            onCallAction={handleValidateAccountInfo}
          />
        );
      case "verifyIdentity":
        return isClientMobile ? (
          <BottomSheet
            alignItems="center"
            display="flex"
            title="Verificação em duas etapas"
            noFooter
            formId="form-new-address"
            textButton={<CloseIcon />}
            justifyContent="space-between"
            hasActionInHeader
            paddingContent="16px 16px 0px"
            containerHeight="420px"
            headerAction={handleCloseLoginAuthenticationModal}
            stateBottomSheet={isLoginAuthenticationModalOpen}
            onDismiss={handleCloseLoginAuthenticationModal}
            onCloseBottomSheet={handleCloseLoginAuthenticationModal}
          >
            <TwoFactorContent
              options={methods(
                changeLoginStep,
                sendTokenByChannel,
                loggedClient
              )}
              noTitle
              onClickIsInvalidClientInfo={() => []}
              sendCodeType="sms"
              preventDefault
              clientAction="loginValidation"
              email={String(loggedClient?.email)}
              phoneNumber={String(loggedClient?.phoneNumber)}
              viewType={isClientMobile ? "component" : "modal"}
              canChooseAnotherTwoFactorMethod
              isVerifyIdentity
              verifyIdentityTitle="Verificação de identidade"
              hasError={hasError}
              isUnderAction={isUnderAction}
              onClickFailureButton={() => {
                router.push("/");
              }}
              onCallAction={handleValidateAccountInfo}
            />
          </BottomSheet>
        ) : (
          <TwoFactorContent
            options={methods(changeLoginStep, sendTokenByChannel, loggedClient)}
            onClickIsInvalidClientInfo={() => []}
            sendCodeType="sms"
            clientAction="loginValidation"
            email={String(loggedClient?.email)}
            phoneNumber={String(loggedClient?.phoneNumber)}
            viewType={isClientMobile ? "component" : "modal"}
            canChooseAnotherTwoFactorMethod
            isVerifyIdentity
            preventDefault
            verifyIdentityTitle="Verificação de identidade"
            hasError={hasError}
            isUnderAction={isUnderAction}
            onClickFailureButton={() => {
              router.push("/");
            }}
            onCallAction={handleValidateAccountInfo}
          />
        );
      default:
        return null;
    }
  };

  if (isUniqueClientModalOpen) {
    return <UniqueClient />;
  }

  if (isRegisterPhoneNumberModalOpen) {
    return <RegisterPhoneNumber />;
  }

  if (isPhoneValidationModalOpen) {
    return <PhoneValidation />;
  }

  return <>{renderAuthenticationContent()}</>;
};

export { LoginAuthenticationModal };
