export const shareText = (text: string): Promise<string> | undefined => {
  if (navigator.share) {
    return navigator
      .share({
        text,
      })
      .catch((error) => error);
  }
  return undefined;
};
