import {
  useAuthentication as defaultUseAuthentication,
  useEnvs,
} from "application/state-manager";
import { useRouter as defaultUseRouter } from "next/router";
import { NewForgotPasswordLogin } from "./NewForgotPassword";
import { OldForgotPasswordLogin } from "./OldForgotPassword";

interface ForgotPasswordLoginProps {
  useAuthentication?: typeof defaultUseAuthentication;
  useRouter?: typeof defaultUseRouter;
}

const ForgotPasswordLogin = ({
  useAuthentication = defaultUseAuthentication,
  useRouter = defaultUseRouter,
}: ForgotPasswordLoginProps) => {
  const { allConfigs } = useEnvs();

  return allConfigs?.isNewForgotPasswordFlowAvailable === "true" ? (
    <NewForgotPasswordLogin
      useAuthentication={useAuthentication}
      useRouter={useRouter}
    />
  ) : (
    <OldForgotPasswordLogin />
  );
};

export { ForgotPasswordLogin };
