import styled, { css } from "styled-components";
import { theme } from "ui";

type StyledSelectProps = {
  hasError: boolean;
  hasSuccessfully?: boolean;
  width?: string;
  padding?: string;
  height?: string;
  borderRadius?: string;
  borderWidth?: string;
  hasIcon?: boolean;
  margin?: string;
  fontSize?: string;
  color?: string;
  backgroundColor?: string;
  cursor?: string;
  isDisabled?: boolean;
  borderColor?: string;
  hasIconBorder?: boolean;
};

interface SelectContainerProps {
  width?: string;
  hasIcon?: boolean;
  isDisabled?: boolean;
}

export const StyledSelect = styled.div<StyledSelectProps>`
  width: ${({ width }) => (width ? "100%" : "initial")};
  max-width: ${({ width }) => width || "200px"};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  select {
    width: ${({ width }) => (width ? "100%" : "initial")};
    height: ${({ height }) => height || "auto"};
    max-width: ${({ width }) => width || "initial"};
    padding: ${({ padding }) =>
      padding ?? `${theme.space.x4} ${theme.space.x5}`};
    margin: ${({ margin }) => margin || `${theme.space.x2} ${theme.space.x0}`};
    border: ${`${theme.space.xpx} solid ${theme.colors.neutral["200"]}`};
    border-radius: ${({ borderRadius }) => borderRadius || `${theme.space.x2}`};
    border-width: ${({ borderWidth }) => borderWidth || `${theme.space.xpx}`};
    background-color: ${({ backgroundColor }) =>
      backgroundColor ?? "transparent"};
    border-color: ${({ borderColor }) => borderColor};

    &::placeholder {
      color: ${({ color }) => color} !important;
      font-size: ${({ fontSize }) => fontSize};
    }

    ${({ hasSuccessfully }) =>
      hasSuccessfully &&
      css`
        border-color: ${theme.colors.secondary["200"]} !important;
      `}

    border-color: ${({ hasError }) =>
      hasError ? theme.colors.primary["200"] : theme.colors.neutral["200"]};
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    &:hover {
      color: ${({ color }) => color} !important;
    }

    @media ${theme.device.desktopFirst.mobileL} {
      &:hover {
        border: ${({ hasError, borderColor }) =>
          `1px solid ${
            hasError ? theme.colors.primary["200"] : borderColor ?? "none"
          }`};
      }
    }

    ${({ hasIcon, hasIconBorder }) =>
      hasIcon && hasIconBorder
        ? css`
            border-radius: ${theme.space.x1} ${theme.space.x0} ${theme.space.x0}
              ${theme.space.x1};
            border-right: 1px solid ${theme.colors.neutral["580"]} !important;
          `
        : hasIcon && !hasIconBorder
        ? css`
            border-radius: ${theme.space.x1} ${theme.space.x0} ${theme.space.x0}
              ${theme.space.x1};
            border-right: none !important;
          `
        : css``}
  }
`;

export const IconContainer = styled.div<StyledSelectProps>`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  min-width: 40px;
  max-width: 40px;
  width: 100%;
  cursor: ${({ cursor }) => cursor ?? "pointer"};
  border-left: none;
  margin: ${({ margin }) => margin ?? `${theme.space.x2} ${theme.space.x0}`};

  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: ${({ hasError }) =>
    hasError ? theme.colors.primary["200"] : theme.colors.neutral["580"]};
  border-radius: 0px 4px 4px 0px;

  ${({ hasSuccessfully }) =>
    hasSuccessfully &&
    css`
      border-color: ${theme.colors.secondary["200"]} !important;
    `}
`;

export const SelectContainer = styled.div<SelectContainerProps>`
  width: ${({ width }) => width ?? "initial"};

  select {
    hover {
      color: initial !important;
    }
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      display: flex;
    `}
`;

export const Select = styled.select``;

export const Option = styled.option``;
