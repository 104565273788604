import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Hiper = ({
  width = 65,
  height = 40,
  alt = "Hiper",
  color = theme.colors.yellow["300"],
  secondColor = theme.colors.neutral.white,
  thirdColor = theme.colors.yellow["400"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 28 21"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M4.04243 3.41551L18.4603 0.100072C20.5643 -0.383859 22.683 0.934831 23.1686 3.03045L26.4972 17.3912C26.7543 18.5001 26.5045 19.6129 25.9006 20.487H3.97049L1.10039 8.10491C0.61475 6.00951 1.93869 3.89923 4.04243 3.41551Z"
        fill={color}
      />
      <path
        d="M8.69835 15.4472H7.51018V12.8676H5.08645V15.4472H3.8985V9.49053H5.08645V11.8164H7.51018V9.49053H8.69835V15.4472Z"
        fill={secondColor}
      />
      <path
        d="M11.053 9.71574C11.053 9.81376 11.0344 9.90562 10.9945 9.9913C10.9561 10.077 10.904 10.152 10.8378 10.2163C10.7717 10.2807 10.6933 10.3311 10.6026 10.3679C10.5136 10.4061 10.4182 10.4244 10.32 10.4244C10.223 10.4244 10.1323 10.4061 10.0463 10.3679C9.96175 10.3311 9.8864 10.2807 9.82193 10.2163C9.75725 10.152 9.70666 10.077 9.66823 9.9913C9.6313 9.90562 9.61273 9.81376 9.61273 9.71574C9.61273 9.61624 9.6313 9.52438 9.66823 9.43721C9.70666 9.35131 9.75725 9.27477 9.82193 9.21055C9.8864 9.14634 9.96175 9.09574 10.0463 9.05895C10.1323 9.02068 10.223 9.0024 10.32 9.0024C10.4182 9.0024 10.5136 9.02068 10.6026 9.05895C10.6933 9.09574 10.7717 9.14634 10.8378 9.21055C10.904 9.27477 10.9561 9.35131 10.9945 9.43721C11.0344 9.52438 11.053 9.61624 11.053 9.71574Z"
        fill={thirdColor}
      />
      <path
        d="M12.0258 16.8907V15.514L11.966 15.5146C11.6569 15.5146 11.3696 15.464 11.1021 15.3615C11.037 15.3369 10.974 15.3092 10.9132 15.2786C10.2034 14.9148 9.84608 14.1706 9.76965 13.1235V11.1397H10.9132C10.9132 11.6955 10.9465 12.6431 10.9132 13.3835C10.9132 13.5536 10.9655 13.7864 11.0253 13.9455C11.0853 14.103 11.1651 14.2331 11.2635 14.3344C11.3634 14.4368 11.4804 14.5119 11.6155 14.5608C11.7398 14.6074 11.8757 14.6316 12.0258 14.6352V11.1397H12.7283C12.8005 11.1397 12.8624 11.1561 12.9111 11.1886C12.9606 11.2218 12.9911 11.2732 13.008 11.3417L13.1032 11.7321C13.1924 11.6325 13.2878 11.5407 13.386 11.458C13.4859 11.374 13.5922 11.3021 13.7088 11.2422C13.8241 11.1828 13.9485 11.1359 14.0824 11.1029C14.2158 11.0702 14.3605 11.0538 14.5157 11.0538C14.7539 11.0538 14.9704 11.105 15.1689 11.2069C15.3668 11.3085 15.5389 11.4554 15.6821 11.6479C16.0871 12.1913 16.137 12.4966 16.137 13.1862C16.137 13.5183 16.0926 13.826 16.0019 14.1107C15.9127 14.3939 15.7852 14.6403 15.6191 14.8487C15.4548 15.0568 15.255 15.2191 15.0229 15.3371C14.7907 15.4549 14.5296 15.5146 14.242 15.5146C13.9993 15.5146 13.7933 15.4778 13.6243 15.4043C13.4567 15.3307 13.3045 15.2314 13.1694 15.1057V16.8907H12.0258ZM14.1253 11.8099C13.9116 11.8099 13.7319 11.8528 13.5843 11.937C13.4368 12.0227 13.2983 12.1467 13.1694 12.3074V14.2806C13.2846 14.4183 13.4076 14.5149 13.5412 14.5699C13.6749 14.6252 13.8179 14.6526 13.9716 14.6526C14.1191 14.6526 14.2529 14.6235 14.3744 14.5655C14.4956 14.5072 14.6002 14.4183 14.6878 14.2991C14.7738 14.1796 14.8415 14.0282 14.8876 13.8445C14.9337 13.6591 14.9582 13.4403 14.9582 13.1862C14.9582 12.9351 14.9384 12.7222 14.9 12.5477C14.8598 12.3733 14.8045 12.2309 14.7324 12.1222C14.6602 12.0119 14.574 11.9338 14.4711 11.8834C14.3697 11.8343 14.2544 11.8099 14.1253 11.8099Z"
        fill={secondColor}
      />
      <path
        d="M18.8482 11.0676C19.131 11.0676 19.391 11.1131 19.6274 11.2039C19.8641 11.2949 20.0682 11.428 20.2392 11.5989C20.4106 11.7705 20.5444 11.8379 20.6403 12.0884C20.7363 12.3397 20.7848 12.6259 20.7848 12.9489C20.7848 13.036 20.7801 13.1064 20.7709 13.1615C20.7617 13.2168 20.7479 13.2612 20.7278 13.2948C20.7094 13.3269 20.6817 13.3499 20.648 13.3637C20.6125 13.3775 20.5696 13.3835 20.5188 13.3835H17.8447C17.863 13.5995 17.9015 13.7862 17.9614 13.9454C18.0212 14.103 18.1013 14.2331 18.1997 14.3343C18.2996 14.4368 18.4163 14.5119 18.5515 14.5608C18.6868 14.6114 18.8358 14.6358 19.0019 14.6358C19.1663 14.6358 19.3093 14.6158 19.4308 14.5746C19.5537 14.5348 19.6596 14.4904 19.7518 14.4444C19.8425 14.3985 19.9241 14.3556 19.9962 14.3143C20.0686 14.2746 20.1393 14.2546 20.2084 14.2546C20.293 14.2546 20.3606 14.2882 20.4159 14.3571L20.7449 14.7781C20.6202 14.922 20.4851 15.0415 20.3344 15.1348C20.1837 15.2297 20.0285 15.3047 19.8688 15.3598C19.7074 15.4151 19.5445 15.4548 19.3799 15.4793C19.2139 15.5022 19.0557 15.5146 18.902 15.5146C16.8542 15.5146 16.2686 13.1685 17.279 11.7152C17.4337 11.4926 17.688 11.3582 17.9521 11.2421C18.2163 11.1258 18.5148 11.0676 18.8482 11.0676ZM18.8712 11.7333C18.5792 11.7333 18.3519 11.8175 18.1858 11.9874C18.0212 12.1558 17.9153 12.3945 17.866 12.7024H19.758C19.758 12.5738 19.7411 12.4513 19.7057 12.335C19.672 12.2187 19.6182 12.1145 19.5445 12.0256C19.4707 11.9355 19.3785 11.8649 19.2679 11.8113C19.1556 11.7594 19.0235 11.7333 18.8712 11.7333Z"
        fill={secondColor}
      />
      <path
        d="M21.5596 15.4472V11.1397H22.2311C22.3479 11.1397 22.4292 11.1616 22.4754 11.2024C22.5225 11.2437 22.5558 11.3174 22.5693 11.4212L22.6276 11.8743C22.7629 11.6202 22.9202 11.4199 23.0993 11.2743C23.2793 11.128 23.4883 11.0538 23.725 11.0538C23.9201 11.0538 24.0815 11.0999 24.2077 11.1933L24.1214 11.9002C24.1152 11.9532 24.0975 11.9915 24.0676 12.0136C24.0382 12.0353 23.997 12.0472 23.9462 12.0472C23.9018 12.0472 23.8371 12.041 23.7528 12.0272C23.6682 12.0136 23.5914 12.0074 23.519 12.0074C23.4129 12.0074 23.3192 12.0227 23.2379 12.0518C23.1548 12.0825 23.081 12.1269 23.0165 12.1849C22.9518 12.2432 22.8935 12.3136 22.8427 12.3978C22.7921 12.4805 22.7458 12.5768 22.7029 12.684V15.4472H21.5596Z"
        fill={secondColor}
      />
    </svg>
  );
};
