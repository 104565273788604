import styled from "styled-components";
import { theme } from "ui";

interface RequestFeedbackModalProps {
  isOpen: boolean;
}

export const RequestFeedbackOverlay = styled.section<RequestFeedbackModalProps>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.neutral["999-opacity-50"]};
`;

export const RequestFeedbackModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.space.x4};
  border-radius: ${theme.space.x2};
  min-height: 480px;
  max-width: 694px;
  width: 100%;
  gap: ${theme.space.x6};
  background-color: ${theme.colors.neutral.white};
  box-shadow: 0px 2px 8px 0px ${theme.colors.neutral["900-opacity-32"]};
`;

export const RequestFeedbackModalHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const CloseRequestModalFeedback = styled.div`
  cursor: pointer;
`;

export const RequestSentIcon = styled.div`
  margin-top: ${theme.space.x10};
`;

export const RequestContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.space.x4};
`;

export const RequestSentTitle = styled.span`
  color: ${theme.colors.neutral["550"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.md.x1};
  font-weight: ${theme.typography.fontWeight.bold};
`;

export const RequestSentDescription = styled.span`
  color: ${theme.colors.neutral["585"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x7};
  font-weight: ${theme.typography.fontWeight["400"]};
  text-align: center;
`;

export const GoToInitialPageContainer = styled.div`
  display: flex;
  gap: ${theme.space.x4};
  align-items: center;
  cursor: pointer;
`;

export const GoToInitialPageText = styled.span`
  color: ${theme.colors.secondary["380"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["400"]};
`;
