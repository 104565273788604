import { buildTestIds, formatTestId } from "application";
import { StyledFieldError } from "./style";

type FieldErrorProps = {
  text: string;
  color?: string;
  fontSize?: string;
  padding?: string;
};

const FieldError = ({ text, ...props }: FieldErrorProps) => {
  return (
    <StyledFieldError
      {...buildTestIds(`styled-field-error-${formatTestId(text)}`)}
      {...props}
    >
      {text}
    </StyledFieldError>
  );
};

export default FieldError;
