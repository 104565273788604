import { ChangeEvent, FocusEvent } from "react";
import {
  Path,
  UseFormRegister,
  UseFormSetValue,
  ValidationRule,
} from "react-hook-form";
import { IFormValues } from "typing";
import { buildTestIds } from "application";
import FieldError from "../FieldError";
import { FieldInfo } from "../FieldInfo";
import { InputContainer, MonetaryPrefixWrapper, StyledInput } from "./style";

export type MonetaryInputProps = {
  id: string;
  name: string;
  className?: string;
  role?: string;
  label?: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  setValue?: UseFormSetValue<IFormValues>;
  required?: string | boolean;
  pattern?: ValidationRule<RegExp>;
  minLength?: ValidationRule<number>;
  maxLength?: ValidationRule<number>;
  placeholder?: string;
  value?: string | number | string[];
  hasError: boolean | string | number | undefined;
  textError?: string;
  textInfo?: string | boolean;
  hasSuccessfully?: boolean;
  disabled?: boolean;
  width?: string;
  padding?: string;
  paddingErrorMessage?: string;
  max?: number;
  height?: string;
  borderRadius?: string;
  hasIcon?: boolean;
  margin?: string;
  color?: string;
  fontSize?: string;
  paddingInfo?: string;
  backgroundColor?: string;
  fontSizeInfo?: string;
  colorInfo?: string;
  borderColor?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onKeyUp?: (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onMouseOut?: (event: React.MouseEvent<HTMLInputElement>) => void;
};

const MonetaryInput = ({
  id,
  label,
  padding,
  paddingErrorMessage,
  hasError,
  hasSuccessfully,
  width,
  color,
  fontSize,
  borderRadius,
  hasIcon,
  margin,
  register,
  setValue,
  onChange,
  onBlur,
  required,
  pattern,
  minLength,
  maxLength,
  name,
  textError,
  textInfo,
  paddingInfo,
  max,
  onKeyUp,
  onKeyDown,
  height,
  backgroundColor,
  fontSizeInfo,
  colorInfo,
  disabled,
  borderColor,
  ...props
}: // eslint-disable-next-line sonarjs/cognitive-complexity
MonetaryInputProps) => {
  return (
    <StyledInput
      hasSuccessfully={hasSuccessfully}
      hasError={hasError as unknown as boolean}
      width={width}
      height={height}
      hasIcon={hasIcon}
      borderRadius={borderRadius}
      borderColor={borderColor}
      padding={padding}
      margin={margin}
      color={color}
      fontSize={fontSize}
      backgroundColor={backgroundColor}
      {...buildTestIds(`form-styled-input-${id}`)}
      isDisabled={disabled}
    >
      {label && (
        <label htmlFor={id} {...buildTestIds(`form-input-label-${id}`)}>
          {label}
        </label>
      )}
      <InputContainer width={width} hasIcon={hasIcon}>
        <MonetaryPrefixWrapper>R$</MonetaryPrefixWrapper>
        <input
          height={height}
          id={id}
          {...buildTestIds(`form-input-${id}`)}
          type="tel"
          inputMode="numeric"
          {...props}
          {...setValue}
          {...register(name, {
            required,
            pattern,
            minLength,
            maxLength,
          })}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          maxLength={maxLength as number}
          max={max}
          disabled={disabled}
        />
      </InputContainer>

      {textError && hasError ? (
        <FieldError
          text={textError}
          data-cy={`error-${id}`}
          padding={paddingErrorMessage}
          fontSize={fontSizeInfo}
          data-testid={`form-field-error-input-${id}`}
        />
      ) : null}
      {textInfo && (
        <FieldInfo
          text={textInfo as string}
          data-cy={`info-${id}`}
          fontSize={fontSizeInfo}
          color={colorInfo}
          data-testid={`form-field-info-input-${id}`}
          padding={paddingInfo}
        />
      )}
    </StyledInput>
  );
};

export { MonetaryInput };
