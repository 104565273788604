import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

const EmailLetterMessage = ({
  alt = "Email usuário logado",
  width = 48,
  height = 48,
  color = theme.colors.secondary["33"],
  secondColor = theme.colors.secondary["380"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25171 34.3317C3.89361 26.6843 5.27022 16.2098 12.4224 10.2066C19.5745 4.2033 30.129 4.66319 36.7319 11.2658C43.3348 17.8684 43.7951 28.4229 37.7921 35.5753C31.7892 42.7277 21.3148 44.1047 13.6671 38.7469L7.99023 40.0084L9.25171 34.3317Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2468 20.4985V27.5014C15.2468 29.4352 16.8145 31.0029 18.7483 31.0029H29.2527C31.1865 31.0029 32.7541 29.4352 32.7541 27.5014V20.4985C32.7541 18.5647 31.1865 16.9971 29.2527 16.9971H18.7483C17.8196 16.9971 16.929 17.366 16.2724 18.0226C15.6157 18.6793 15.2468 19.5699 15.2468 20.4985Z"
        fill={secondColor}
      />
      <path
        d="M20.0194 21.9991L22.9959 23.7246C23.6301 24.0918 24.4122 24.0918 25.0464 23.7246L28.0227 21.9991"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { EmailLetterMessage };
