import { useAuth } from "application";
import { useRouter } from "next/router";
import { ComponentType } from "react";

const withRedirectAfterLogout = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  const ComponentWithLogic = (props: P): JSX.Element => {
    const { isAuthenticated } = useAuth();
    const router = useRouter();

    if (isAuthenticated !== undefined && isAuthenticated === false) {
      router.push("/");
    }

    return <WrappedComponent {...props} />;
  };

  ComponentWithLogic.displayName = "CustomComponent";

  return ComponentWithLogic;
};

export { withRedirectAfterLogout };
