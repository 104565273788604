import { ReactNode } from "react";
import { HeadProps } from "typing";
import { Header } from "../../Header";
import { Footer } from "../Footer";
import { Head } from "../Head";
import { Header as HeaderDefault } from "../Header";
import { Main } from "../Main";
import HeaderCart from "./HeaderCart";

export interface LayoutProps {
  children: ReactNode;
  headerComponent?: boolean;
  footerIsOpen?: boolean;
  defaultHeader?: boolean;
  hasHeader?: boolean;
  title?: string;
  subTitle?: string;
  shouldIndexPage?: boolean;
}

const headProps: HeadProps = {
  keywords: [
    "Ferreira Costa",
    "Home Center",
    "Construção",
    "Casa",
    "Ofertas",
    "Decoração",
  ],
};

const Layout = ({
  children,
  footerIsOpen = true,
  headerComponent,
  hasHeader = true,
  defaultHeader = true,
  title = "Ferreira Costa",
  subTitle = "Encontre as melhores ofertas de eletrodomésticos, móveis, pisos, utilidades domésticas e muito mais para sua casa! Aproveite!",
  shouldIndexPage = true,
}: LayoutProps) => {
  const renderHeader = () => {
    if (!hasHeader) {
      return null;
    }

    if (headerComponent) {
      return <HeaderCart />;
    }

    return <HeaderDefault />;
  };

  return (
    <>
      {defaultHeader ? (
        <>
          <Head
            title={title ?? headProps.title}
            subtitle={subTitle ?? headProps.subtitle}
            shouldIndexPage={shouldIndexPage}
            {...headProps}
          />
          {renderHeader()}
        </>
      ) : (
        <Header />
      )}
      <Main>{children}</Main>
      {footerIsOpen ? <Footer /> : null}
    </>
  );
};

export { Layout };
