import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const AddCard = ({
  width = 20,
  height = 20,
  color = theme.colors.tertiary["110"],
  secondColor = theme.colors.tertiary["210"],
  thirdColor = theme.colors.neutral["40"],
  fourthColor = theme.colors.secondary["255"],
  fifthColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="transparent" />
      <rect x="4" y="7" width="15" height="10" rx="1.5" fill={color} />
      <rect x="4" y="9" width="15" height="2.5" fill={secondColor} />
      <rect
        width="3"
        height="0.5"
        rx="0.25"
        transform="matrix(1 0 0 -1 6 14)"
        fill={thirdColor}
      />
      <rect
        width="5"
        height="0.5"
        rx="0.25"
        transform="matrix(1 0 0 -1 6 15)"
        fill={thirdColor}
      />
      <circle cx="18.5" cy="14.5" r="3.5" fill={fourthColor} />
      <rect x="18" y="12" width="1" height="5" rx="0.5" fill="white" />
      <rect
        x="16"
        y="15"
        width="1"
        height="5"
        rx="0.5"
        transform="rotate(-90 16 15)"
        fill={fifthColor}
      />
    </svg>
  );
};
