const validateAccessTokenIsExpired = (
  expirationDateTime: number,
  timeToRefreshAccessTokenBeforeItExpiresInSeconds: number
) => {
  const tokenExpirationTimeInMilliseconds = expirationDateTime * 1000;
  const timeToRefreshAccessTokenBeforeItExpiresInMilliseconds =
    timeToRefreshAccessTokenBeforeItExpiresInSeconds * 1000;

  const thresholdTime =
    tokenExpirationTimeInMilliseconds -
    timeToRefreshAccessTokenBeforeItExpiresInMilliseconds;

  const nowDateTime = new Date().getTime();

  return nowDateTime >= thresholdTime;
};

export { validateAccessTokenIsExpired };
