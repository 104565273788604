import { IconProps } from "typing";

export const Smartphone = ({
  alt = "Smartphone",
  color = "#53565A",
  height,
  width,
  strokeWidth,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.498 2.99622V4.79697C9.47304 5.10001 9.69563 5.36713 9.99821 5.39722H13.9999C14.3025 5.36713 14.5251 5.10001 14.5001 4.79697V2.99622"
        stroke={color}
        strokeWidth={strokeWidth ?? "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99805 18.0025H13.9997"
        stroke={color}
        strokeWidth={strokeWidth ?? "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0003 21.0037H7.99693C6.8919 21.0037 5.99609 20.1079 5.99609 19.0029V4.99705C5.99609 3.89202 6.8919 2.99622 7.99693 2.99622H16.0003C17.1053 2.99622 18.0011 3.89202 18.0011 4.99705V19.0029C18.0011 20.1079 17.1053 21.0037 16.0003 21.0037Z"
        stroke={color}
        strokeWidth={strokeWidth ?? "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
