import { IconProps } from "typing";
import { theme } from "../themes";

export const CopyIcon = ({
  alt = "Ícone de copiar",
  width = 25,
  height = 24,
  color = theme.colors.neutral.white,
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5007 19.4031V10.8625C15.5007 10.4377 15.332 10.0303 15.0315 9.73006L12.7685 7.46712C12.4683 7.16665 12.0609 6.99792 11.6361 6.99792H6.09676C5.21274 6.99792 4.49609 7.71457 4.49609 8.59859V19.4031C4.49609 20.2871 5.21274 21.0038 6.09676 21.0038H13.9C14.784 21.0038 15.5007 20.2871 15.5007 19.4031V19.4031Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5005 17.002H18.902C19.786 17.002 20.5026 16.2854 20.5026 15.4014V4.99705C20.5026 3.89202 19.6068 2.99622 18.5018 2.99622H11.0987C10.2147 2.99622 9.49805 3.71286 9.49805 4.59688V6.99788"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4997 10.9996H12.2984C11.8564 10.9996 11.498 10.6413 11.498 10.1993V6.99792"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
