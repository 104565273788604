import { getComponentTheme } from "application";
import {
  FormatCarousel,
  CustomCarouselProperty,
  SlideCarousel,
  CarouselThemeDto,
} from "typing";
import { blackFridayCarouselTheme } from "ui";

const getBlackFridayCustomCarouselTheme = (isBlackFriday?: boolean) => {
  return isBlackFriday
    ? {
        key: "blackFridayTheme",
        value: JSON.stringify(blackFridayCarouselTheme),
      }
    : undefined;
};

const customCarouselData = (
  properties: CustomCarouselProperty[],
  isBlackFriday: boolean
) => {
  const format = (
    properties?.find(({ key }) => key === "format") as FormatCarousel
  )?.value;

  const slide = (
    properties?.find(({ key }) => key === "automaticSlide") as SlideCarousel
  )?.value;

  const blackFridayTheme = getBlackFridayCustomCarouselTheme(isBlackFriday);

  const carouselBlackFridayTheme =
    getComponentTheme<CarouselThemeDto>(blackFridayTheme);

  return {
    format,
    slide,
    carouselBlackFridayTheme,
  };
};

export { customCarouselData };
