import { useCurrentPathName, useEnvs } from "application";
import { ReactNode } from "react";
import { Link } from "../../Link";

interface LinkRedirectHomeProps {
  children: ReactNode;
  hrefQueryParams?: string;
}

const LinkRedirectHome = ({
  children,
  hrefQueryParams = "",
}: LinkRedirectHomeProps) => {
  const currentPathName = useCurrentPathName();

  const queryParams = hrefQueryParams ? `?${hrefQueryParams}` : "";

  const allConfigs = useEnvs((state) => state.allConfigs);
  const nodeEnv = allConfigs?.nodeEnv || "";

  return nodeEnv !== "development" &&
    currentPathName?.includes("lista-de-casamento") ? (
    <a target="_self" href={`/${queryParams}`}>
      {children}
    </a>
  ) : (
    <Link id="redirect-home" target="_self" href={`/${queryParams}`}>
      {children}
    </Link>
  );
};

export { LinkRedirectHome };
