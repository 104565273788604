import styled from "styled-components";
import { theme } from "ui";
import { ButtonSubmit } from "../../../ButtonSubmit";

interface TwoFactorViewContainerProps {
  backgroundColor?: string;
}
export const TwoFactorViewContainer = styled.div<TwoFactorViewContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  display: flex;
  flex-direction: column !important;
`;

export const TwoFactorTitle = styled.h2`
  font-size: ${theme.typography.fontSize.sm.x7};
  color: ${theme.colors.neutral["750"]} !important;
`;

export const TwoFactorInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
`;

export const TwoFactorDescription = styled.p`
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${theme.colors.neutral["550"]} !important;
  margin: ${theme.space.x2} ${theme.space.x0};
`;

interface FailureTitleProps {
  isDefault?: boolean;
}

export const FailureTitle = styled.div<FailureTitleProps>`
  display: flex;
  justify-content: ${({ isDefault }) => (isDefault ? "center" : "start")};
  align-items: center;
  gap: 0.66667rem;
  color: ${({ isDefault }) =>
    isDefault
      ? `${theme.colors.primary["150"]} !important`
      : `${theme.colors.neutral["750"]} !important`};

  padding-bottom: ${theme.space.x2};
  font-weight: ${theme.typography.fontWeight["600"]};
  font-size: 1.33333rem;

  @media (max-width: 480px) {
    justify-content: start;
    padding-bottom: ${theme.space.x8};
  }
`;

export const FailureButton = styled(ButtonSubmit).attrs({
  fontSize: theme.typography.fontSize.sm.x7,
  height: theme.space["x11.5"],
  fontWeight: theme.typography.fontWeight["400"],
  fontFamily: theme.typography.fontFamily.chivo,
  width: "100%",
})`
  min-width: 100%;
  margin-top: ${theme.space.x8};
`;

export const FailureText = styled.p`
  color: ${theme.colors.neutral["525"]} !important;
  font-size: 1.16667rem;
  font-weight: ${theme.typography.fontWeight["400"]};
  white-space: wrap;
`;

export const FailureContainer = styled.div``;
