import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Interrogation = ({
  width = 16,
  height = 16,
  color = theme.colors.tertiary["115"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1125_1157)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6ZM6.12904 4.01215C5.89628 3.97223 5.6569 4.01597 5.4533 4.13563C5.2497 4.25529 5.09502 4.44314 5.01665 4.66592C4.92501 4.92641 4.63956 5.0633 4.37906 4.97166C4.11857 4.88003 3.98168 4.59457 4.07332 4.33407C4.23005 3.88852 4.53942 3.51281 4.94662 3.27349C5.35382 3.03418 5.83258 2.9467 6.2981 3.02655C6.76362 3.1064 7.18586 3.34842 7.49003 3.70976C7.79415 4.07102 7.96062 4.52823 7.95998 5.00045C7.95976 5.76563 7.39245 6.27094 6.98733 6.54102C6.76952 6.68623 6.55526 6.793 6.39743 6.86315C6.3178 6.89854 6.25075 6.9254 6.20243 6.9438C6.17823 6.95302 6.15862 6.96016 6.14433 6.96524L6.12697 6.97132L6.12144 6.97321L6.11949 6.97387L6.11872 6.97413C6.11872 6.97413 6.1181 6.97434 5.95998 6.49999L6.1181 6.97434C5.85612 7.06166 5.57297 6.92008 5.48564 6.65811C5.39838 6.39631 5.53971 6.11335 5.80135 6.02583L5.80932 6.02303C5.81701 6.02029 5.82962 6.01572 5.84644 6.00931C5.88015 5.99647 5.93029 5.97645 5.99129 5.94934C6.11471 5.89449 6.27545 5.81376 6.43263 5.70897C6.77743 5.4791 6.95998 5.23456 6.95998 4.99999L6.95998 4.99925C6.96034 4.76309 6.8771 4.53443 6.72501 4.35376C6.57292 4.17309 6.3618 4.05208 6.12904 4.01215ZM5.49998 8.49999C5.49998 8.22385 5.72384 7.99999 5.99998 7.99999H6.00498C6.28113 7.99999 6.50498 8.22385 6.50498 8.49999C6.50498 8.77614 6.28113 8.99999 6.00498 8.99999H5.99998C5.72384 8.99999 5.49998 8.77614 5.49998 8.49999Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1125_1157">
          <rect width={width} height={height} fill={secondColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
