import {
  AuthLoadingContainer,
  AuthLoadingText,
  LoadingAuthCustom,
  WrapperLoadingAuth,
} from "./style";

const AuthLoading = () => {
  return (
    <AuthLoadingContainer>
      <WrapperLoadingAuth>
        <LoadingAuthCustom />
      </WrapperLoadingAuth>
      <AuthLoadingText>Um momento...</AuthLoadingText>
    </AuthLoadingContainer>
  );
};

export { AuthLoading };
