import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const WarningIcon = ({
  width = 16,
  height = 16,
  color = theme.colors.yellow["45"],
  secondColor = theme.colors.neutral["999"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35258 2.75913L14.4586 11.6951C15.0519 12.7338 14.3019 14.0265 13.1059 14.0265H2.89391C1.69725 14.0265 0.947246 12.7338 1.54125 11.6951L6.64725 2.75913C7.24525 1.7118 8.75458 1.7118 9.35258 2.75913Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8.74675V6.25342"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99933 10.7502C7.90733 10.7502 7.83266 10.8248 7.83333 10.9168C7.83333 11.0088 7.90799 11.0835 7.99999 11.0835C8.09199 11.0835 8.16666 11.0088 8.16666 10.9168C8.16666 10.8248 8.09199 10.7502 7.99933 10.7502"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
