// eslint-disable-next-line no-shadow
enum FATypeValues {
  GiftCard2FA = 1,
  Address2FA = 2,
  PickUp2FA = 3,
  GiftCardForStore2FA = 4,
  AccountCreate2FA = 5,
  AccountUpdatePhone2FA = 6,
  AccountUpdateAddress2FA = 7,
  AccountUpdateEmail2FA = 8,
  RecoverAccount2FA = 9,
  AccountUpdatePassword2FA = 10,
  AccountValidateEMail2FA = 12,
  AccountValidatePhone2FA = 13,
  ReportSecurityProblem2FA = 14,
  UpdatePickupResponsible2FA = 15,
}

// eslint-disable-next-line no-shadow
enum FAChannelsValues {
  SMS = 1,
  Email = 2,
  AppAuthenticator = 3,
}

export { FATypeValues, FAChannelsValues };
