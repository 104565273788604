import styled, { css } from "styled-components";

import { ArrowDownSolid, Title, theme } from "ui";

interface CollapsibleFieldAndCtaContainerProps {
  hidingText: boolean;
}

interface CustomTextBlockContainerProps {
  customTheme?: boolean;
}

export const ArrowDownIcon = styled(ArrowDownSolid)``;

export const CustomTextBlockHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x1};
  margin-bottom: ${theme.space.x4};
`;

export const CustomTextBlockTitle = styled(Title)`
  font-size: 30px;
  color: ${theme.colors.neutral["520"]};

  @media (max-width: 712px) {
    font-size: 22px;
  }

  @media (max-width: 360px) {
    font-size: 22px;
  }
`;

export const CustomTextBlockSubTitle = styled.h4`
  font-size: ${theme.typography.fontSize.md.x3};
  color: ${theme.colors.neutral["330"]};

  @media (max-width: 712px) {
    font-size: ${theme.typography.fontSize.sm.x7};
  }

  @media (max-width: 360px) {
    font-size: ${theme.typography.fontSize.sm.x7};
  }
`;

export const CustomTextBlockContent = styled.div`
  color: ${theme.colors.neutral["520"]};

  > * {
    word-break: keep-all;
  }

  a {
    font-weight: ${theme.typography.fontWeight.bold};
    text-decoration: underline;
  }

  ol {
    margin-left: ${theme.space.x4};
  }
`;

export const CustomTextBlockCollapsibleField = styled.section<CollapsibleFieldAndCtaContainerProps>`
  & > svg {
    ${({ hidingText }) => (hidingText ? "" : "transform: rotate(180deg)")};
  }
`;

export const CustomTextBlockCtaContainer = styled.section`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: ${theme.space.x2};
  cursor: pointer;
  margin: ${theme.space.x4} auto ${theme.space.x0};
`;

export const CustomTextBlockCtaText = styled.span`
  color: ${theme.colors.primary["200"]};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight.bold};
`;

export const CustomTextBlockContainer = styled.section<CustomTextBlockContainerProps>`
  max-width: ${theme?.device?.size?.laptopL};
  width: 100%;
  margin: ${theme.space.x0} auto;
  padding: ${theme.space.x4} ${theme.space.x20};

  @media (max-width: 712px) {
    padding: ${theme.space.x4};
  }

  @media (max-width: 360px) {
    padding: ${theme.space.x4};
  }
  ${({ customTheme }) => {
    return css`
      ${CustomTextBlockTitle}, ${CustomTextBlockSubTitle}, ${CustomTextBlockCtaText} {
        ${customTheme &&
        css`
          color: ${theme?.colors?.blackFriday?.white};
        `}
      }
      ${CustomTextBlockContent} {
        ${customTheme &&
        css`
          color: ${theme?.colors?.blackFriday?.["20"]};
        `}
      }
      ${CustomTextBlockCollapsibleField} {
        ${customTheme &&
        css`
          svg {
            ${customTheme &&
            css`
              path {
                fill: ${theme?.colors?.blackFriday?.white};
              }
            `}
          }
        `}
      }
    `;
  }}
`;
