import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Visa = ({
  width = 65,
  height = 40,
  alt = "Visa",
  color = theme.colors.tertiary["700"],
  secondColor = theme.colors.yellow["200"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 48 19"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M21.2912 16.8043H17.4731L19.8613 2.24283H23.6791L21.2912 16.8043Z"
        fill={color}
      />
      <path
        d="M35.1322 2.59879C34.3791 2.30416 33.1846 1.97886 31.7077 1.97886C27.9372 1.97886 25.282 3.96159 25.2657 6.7963C25.2344 8.88774 27.1667 10.0494 28.612 10.7467C30.0891 11.4593 30.5913 11.9244 30.5913 12.5596C30.5762 13.5351 29.3976 13.9848 28.2984 13.9848C26.774 13.9848 25.9572 13.7529 24.716 13.2102L24.2132 12.9776L23.679 16.2463C24.5744 16.6486 26.2243 17.0056 27.9372 17.0213C31.9434 17.0213 34.5516 15.0692 34.5825 12.0484C34.5977 10.3907 33.5773 9.1206 31.3776 8.08271C30.0422 7.41644 29.2243 6.96719 29.2243 6.28548C29.24 5.66575 29.9161 5.03099 31.4235 5.03099C32.6647 4.9999 33.5767 5.29412 34.2676 5.58854L34.6129 5.74316L35.1322 2.59879Z"
        fill={color}
      />
      <path
        d="M40.2068 11.6457C40.5212 10.8091 41.7311 7.5715 41.7311 7.5715C41.7153 7.60259 42.0449 6.71952 42.2335 6.17741L42.5003 7.43211C42.5003 7.43211 43.2233 10.9176 43.3803 11.6457C42.7836 11.6457 40.9609 11.6457 40.2068 11.6457ZM44.9197 2.24283H41.9664C41.0557 2.24283 40.3638 2.50596 39.9708 3.451L34.2996 16.8041H38.3058C38.3058 16.8041 38.9653 15.0068 39.1071 14.6198C39.5466 14.6198 43.4438 14.6198 44.0092 14.6198C44.1188 15.131 44.4648 16.8041 44.4648 16.8041H48L44.9197 2.24283Z"
        fill={color}
      />
      <path
        d="M14.2841 2.24283L10.545 12.1723L10.1364 10.1585C9.44508 7.83483 7.27703 5.3102 4.8576 4.05488L8.28255 16.7888H12.3201L18.3215 2.24283H14.2841Z"
        fill={color}
      />
      <path
        d="M7.07285 2.24283H0.92991L0.867065 2.53705C5.65894 3.74542 8.83251 6.65816 10.1364 10.1591L8.80098 3.46685C8.58113 2.53684 7.90549 2.27351 7.07285 2.24283Z"
        fill={secondColor}
      />
    </svg>
  );
};
