import { Coupon, Product, ProductCardDto } from "typing";

const getDiscountValue = (spotPrice = 0, priceList = 0, salePrice = 0) => {
  const finalPrice = spotPrice || salePrice;
  const discountValue = spotPrice || salePrice ? priceList - finalPrice : 0;
  const roundedDiscountValue = Number(discountValue.toFixed(2));

  return roundedDiscountValue >= 0 ? roundedDiscountValue : 0;
};

const calculateDiscountPercentage = (discountValue: string) => {
  if (!discountValue) {
    return 0;
  }

  const numericValue = parseFloat(discountValue);

  return numericValue / 100;
};

const getDiscountPercentage = (product: ProductCardDto | Product) => {
  const prices = product?.prices || [];
  const priceWithDiscount =
    prices.find(
      (price) =>
        price?.packingQuantity === 1 &&
        Number(price?.discountPercentage ?? 0) > 0
    ) || prices.find((price) => Number(price?.discountPercentage ?? 0) > 0);

  if (
    product?.prices?.[0]?.priceList !== product?.prices?.[0]?.salePrice &&
    priceWithDiscount
  ) {
    return priceWithDiscount.discountPercentage;
  }

  return null;
};

const getDiscountMessage = (coupon: Coupon): string => {
  if (coupon?.discountPercentage) {
    return `${coupon.discountPercentage}% OFF`;
  }

  if (coupon?.discountValue) {
    return `R$ ${coupon.discountValue} OFF`;
  }

  return "";
};

export {
  calculateDiscountPercentage,
  getDiscountMessage,
  getDiscountPercentage,
  getDiscountValue,
};
