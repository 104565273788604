import styled from "styled-components";
import { theme } from "ui";

interface ContainerHeaderProps {
  customTheme?: boolean;
}

export const ContainerHeader = styled.header<ContainerHeaderProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ customTheme }) => customTheme && "#252728"};
  color: ${({ customTheme }) => customTheme && "#FFF"};
`;
export const BoxDescription = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: ${theme.space.x4};
`;
export const BoxInfoSecurity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${theme.space.x2};
`;
export const TextSecurity = styled.h5<ContainerHeaderProps>`
  font-size: 1.2rem;
  color: ${({ customTheme }) => customTheme && theme.colors.neutral["400"]};
  font-weight: 600;
`;
export const BoxDescriptionTitlePage = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${theme.colors.neutral["300"]};
  border-bottom: ${theme.space.x1} solid ${theme.colors.primary["200"]};
  padding: ${theme.space.x4};
`;
export const TextCartTitle = styled.h5`
  font-size: 1.3rem;
  font-weight: 500;
`;
