import {
  buildTestIds,
  descriptionParcelsText,
  formatStringUrl,
  getDiscountPercentage,
  getMaxInstallments,
  getPriceList,
  getProductImageUrl,
  getSalePrice,
  getSpotPrice,
} from "application";
import { appMonitoringClient, cookie } from "implementations";
import { memo, MouseEvent } from "react";
import { ProductCardProps } from "typing";
import { CouponTag, theme } from "ui";
import {
  ProductCardContent,
  ProductCardCurrentPrice,
  ProductCardImageContainer,
  ProductCardContainer,
  ProductCardTitle,
  TagBorderLine,
  ProductCardImageContent,
  TagCampaignProductCard,
  TagTextCampaignProductCard,
  ProductCardInfoArea,
  ProductCardInfoContainer,
  TagCampaignProductCardContainer,
  ProductCardDiscountPercentageContainer,
  ProductCardDiscountPercentageText,
  ProductCardDiscountPriceContainer,
  ProductCardDiscountPriceText,
  ProductCardSpotPriceDescription,
  ProductCardInstallmentsText,
  ProductCardInstallmentsTextContainer,
  ProductCardTagPriceOnlySiteContainer,
  ProductCardTagPriceOnlySiteText,
  CouponTagWrapper,
  ProductCardDiscountPercentageDot,
  ProductCardDiscountPriceContainerSpace,
} from "./styles";
import {
  DynamicProductCardImage,
  handleSelectItemGtag,
  useGetProductCardInfo,
} from "./data";
import { Link } from "../Link";

const ProductCard = memo(
  ({
    item,
    playlistId,
    playlistName,
    searchTerm,
    isVertical,
    shouldShowInGridOnMobile,
    index,
    isPlaylist,
    className,
    preloadComponent = false,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  ProductCardProps) => {
    const {
      isSearchPath,
      isExpandedPlaylistPath,
      hideSecondBorderRightOnMobile,
      hasInstallments,
      productTagColor,
      productTagTitle,
      productCardVertical,
      isProductScreen,
    } = useGetProductCardInfo(item, !!isVertical, index);

    const selectItemGtag = handleSelectItemGtag({
      item,
      isSearchPath,
      searchTerm,
      playlistName,
      isExpandedPlaylistPath,
      playlistId,
    });

    const maxNumberInstallments = getMaxInstallments(item) || 0;
    const salePrice =
      item?.prices?.find((price) => price?.packingQuantity === 1)?.salePrice ||
      item?.prices?.[0]?.salePrice ||
      0;
    const spotPrice =
      item?.prices?.find((price) => price?.packingQuantity === 1)?.spotPrice ||
      item?.prices?.[0]?.spotPrice ||
      null;

    const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      const destination = event?.currentTarget?.getAttribute("href");

      if (destination && window && window?.location?.href !== destination) {
        cookie.setCookie({
          name: `currentPage_default`,
          value: "1",
          options: {
            maxAge: 600,
          },
        });
        window.location.href = destination;
      }
    };

    return (
      <ProductCardContainer
        className={className}
        {...buildTestIds(
          `product-card-container${
            isProductScreen ? "-as-tag-link" : "-as-component-link"
          }`
        )}
        as={Link}
        target="_blank"
        onClick={(event: MouseEvent<HTMLAnchorElement>) => {
          handleLinkClick(event);
          selectItemGtag();
        }}
        href={`/produto/${item?.id}/${formatStringUrl(item?.description)}`}
        shouldShowInGridOnMobile={shouldShowInGridOnMobile}
        hideSecondBorderRightOnMobile={hideSecondBorderRightOnMobile}
        productCardVertical={productCardVertical}
        isPlaylist={isPlaylist}
      >
        {item?.coupons?.length > 0 && (
          <CouponTagWrapper
            {...buildTestIds("product-card-coupon-tag-wrapper")}
          >
            <CouponTag alt="cupom" />
          </CouponTagWrapper>
        )}
        <ProductCardContent
          productCardVertical={productCardVertical}
          {...buildTestIds("product-card-content")}
        >
          <ProductCardImageContainer {...buildTestIds("product-card-image")}>
            <ProductCardImageContent
              {...buildTestIds("product-card-image-content")}
              productCardVertical={productCardVertical}
            >
              <DynamicProductCardImage
                {...buildTestIds("product-card-dynamic-image")}
                imageUrl={getProductImageUrl(item) as string}
                description={item?.description}
                preloadComponent={preloadComponent}
              />
            </ProductCardImageContent>
          </ProductCardImageContainer>
          <ProductCardInfoArea
            {...buildTestIds("product-card-info-area")}
            productCardVertical={productCardVertical}
          >
            {item?.isBlackFriday ? (
              <TagCampaignProductCardContainer
                {...buildTestIds(
                  "tag-campaign-product-card-black-friday-container"
                )}
              >
                <TagCampaignProductCard
                  background={theme.colors.blackFriday["125"]}
                  {...buildTestIds("tag-campaign-product-card-black-friday")}
                >
                  <TagTextCampaignProductCard
                    {...buildTestIds(
                      "tag-text-campaign-product-card-black-friday"
                    )}
                  >
                    Oferta Black Friday
                  </TagTextCampaignProductCard>
                </TagCampaignProductCard>
              </TagCampaignProductCardContainer>
            ) : null}
            <ProductCardInfoContainer
              {...buildTestIds("product-card-info-container")}
              productCardVertical={productCardVertical}
            >
              <ProductCardTitle
                {...buildTestIds("product-card-title")}
                hasDiscountPercentage={!getDiscountPercentage(item)}
                {...buildTestIds("product-card-title")}
              >
                {item?.description}
              </ProductCardTitle>
              <ProductCardDiscountPriceContainerSpace
                {...buildTestIds("product-card-discount-price-container-space")}
                hasDiscountPercentage={!getDiscountPercentage(item)}
              >
                {getDiscountPercentage(item) ? (
                  <ProductCardDiscountPriceContainer
                    {...buildTestIds("product-card-discount-price-container")}
                  >
                    <ProductCardDiscountPriceText
                      {...buildTestIds("product-card-discount-price-text")}
                    >
                      {getPriceList(item)}
                    </ProductCardDiscountPriceText>
                    <ProductCardDiscountPercentageContainer
                      {...buildTestIds(
                        "product-card-discount-percentage-container"
                      )}
                    >
                      <ProductCardDiscountPercentageDot
                        {...buildTestIds(
                          "product-card-discount-percentage-dot"
                        )}
                      />
                      <ProductCardDiscountPercentageText
                        {...buildTestIds(
                          "product-card-discount-percentage-text"
                        )}
                      >
                        {getDiscountPercentage(item)}% OFF
                      </ProductCardDiscountPercentageText>
                    </ProductCardDiscountPercentageContainer>
                  </ProductCardDiscountPriceContainer>
                ) : null}
              </ProductCardDiscountPriceContainerSpace>
              {getSpotPrice(item) ? (
                <>
                  <ProductCardCurrentPrice
                    {...buildTestIds("product-card-current-price")}
                  >
                    {getSpotPrice(item)}
                  </ProductCardCurrentPrice>
                  <ProductCardSpotPriceDescription
                    {...buildTestIds("product-card-spot-price-description")}
                  >
                    à vista no pix ou no cartão
                  </ProductCardSpotPriceDescription>
                </>
              ) : (
                <ProductCardCurrentPrice
                  {...buildTestIds("product-card-current-price")}
                >
                  {getSalePrice(item, appMonitoringClient)}
                </ProductCardCurrentPrice>
              )}
              <ProductCardInstallmentsTextContainer
                {...buildTestIds("product-card-installments-text-container")}
              >
                {hasInstallments ? (
                  <ProductCardInstallmentsText
                    {...buildTestIds("product-card-installments-text")}
                  >
                    {descriptionParcelsText({
                      shortDescription: true,
                      maxNumberInstallments,
                      salePrice,
                      spotPrice,
                    })}
                  </ProductCardInstallmentsText>
                ) : null}
              </ProductCardInstallmentsTextContainer>
              {productTagTitle ? (
                <ProductCardTagPriceOnlySiteContainer
                  {...buildTestIds(
                    "product-card-tag-price-only-site-container"
                  )}
                >
                  <ProductCardTagPriceOnlySiteText
                    {...buildTestIds("product-card-tag-price-only-site-text")}
                  >
                    {productTagTitle}
                  </ProductCardTagPriceOnlySiteText>
                </ProductCardTagPriceOnlySiteContainer>
              ) : null}
            </ProductCardInfoContainer>
          </ProductCardInfoArea>
        </ProductCardContent>
        {shouldShowInGridOnMobile ? null : (
          <TagBorderLine
            {...buildTestIds("tag-border-line")}
            tagColor={productTagColor}
          />
        )}
      </ProductCardContainer>
    );
  }
);

ProductCard.displayName = "ProductCard";

export default ProductCard;

export { ProductCard as ProductCardComponent };
