import { theme } from "../themes";

export const AddNewEnvironment = ({
  width = 30,
  height = 30,
  color = theme.colors.secondary["265"],
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2363 7C19.6036 7 19.9013 7.29773 19.9013 7.665V9.57133H21.814C22.1848 9.57133 22.4853 9.8719 22.4853 10.2427V10.2427C22.4853 10.6134 22.1848 10.914 21.814 10.914H19.9013V12.8077C19.9013 13.1749 19.6036 13.4727 19.2363 13.4727V13.4727C18.8691 13.4727 18.5713 13.1749 18.5713 12.8077V10.914H16.6713C16.3006 10.914 16 10.6134 16 10.2427V10.2427C16 9.8719 16.3006 9.57133 16.6713 9.57133H18.5713V7.665C18.5713 7.29773 18.8691 7 19.2363 7V7Z"
        fill={color}
      />
      <path
        d="M3 12H13.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3 17H22.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3 7H13.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
