import { ToastContainerProps } from "typing";
import { Toast } from "./Toast";

const ToastContainer = ({ toastProps }: ToastContainerProps) => {
  return (
    <>
      {toastProps.map((toast) => (
        <Toast
          id={toast.id}
          key={toast.id}
          title={toast.title}
          type={toast.type}
          isNewToast={toast.isNewToast}
          description={toast.description}
          timerInMilliseconds={toast.timerInMilliseconds}
          showProgressBar={toast.showProgressBar}
          newToastTheme={toast.newToastTheme}
        />
      ))}
    </>
  );
};

export { ToastContainer };
