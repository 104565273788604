import styled from "styled-components";
import { FlexContainer, theme } from "ui";

export const ItemLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperSuggestions = styled(FlexContainer)`
  cursor: pointer;
  align-items: center;
  padding: 0.75rem 0.5rem;
  ${ItemLinkContainer} {
    width: 100%;
  }
`;

export const ItemContent = styled.div`
  color: ${theme.colors.neutral["520"]};
  font-size: 1.25rem;
  transition: all 0.2s ease;
  cursor: pointer;
  width: 100%;

  &:hover {
    color: ${theme.colors.neutral["900"]};
  }
`;

export const ActionButtonContainer = styled.div`
  svg {
    cursor: pointer;
    width: ${theme.space.x6};
    height: ${theme.space.x6};
    fill: ${theme.colors.neutral["350"]};
    transition: all 0.2s ease;
  }

  & > svg > g > path {
    cursor: pointer;
    stroke: ${theme.colors.neutral["350"]};
    stroke-width: 2;
    transition: all 0.2s ease;
  }
`;
