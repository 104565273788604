import {
  ImagesGridSubComponentProperty,
  Title,
  DesktopImage,
  MobileImage,
} from "typing";

const customImagesGridSubComponentData = (
  properties: ImagesGridSubComponentProperty[]
) => {
  const title = (properties?.find(({ key }) => key === "title") as Title)
    ?.value;

  const desktopImage = (
    properties?.find(({ key }) => key === "desktopImage") as DesktopImage
  )?.value;

  const mobileImage = (
    properties?.find(({ key }) => key === "mobileImage") as MobileImage
  )?.value;

  return {
    title,
    desktopImage,
    mobileImage,
  };
};

export { customImagesGridSubComponentData };
