import styled from "styled-components";
import { theme } from "../themes";

export const UserFirstCharacterFromName = styled.strong`
  text-decoration: none;
  align-self: center;
  font-size: 1.5rem;
  color: ${theme.colors.neutral.white};
`;

interface ImageCircleProps {
  width?: string;
  height?: string;
}

export const ImageCircle = styled.div<ImageCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || "3.25rem"};
  height: ${({ height }) => height || "3.25rem"};
  border-radius: 50%;
  text-align: center;
  background: ${theme.colors.secondary["200"]};
  border: 0;
  padding: 0 1rem;

  ${UserFirstCharacterFromName} {
    padding: 0 !important;
    text-decoration: none;
    align-self: center;
    font-size: ${theme?.typography?.fontSize?.sm?.x6} !important;
    color: ${theme.colors.neutral.white} !important;
  }
`;

export const UserAuthenticatedImageCircle = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.secondary["350"]};
  border: 0;
`;

export const UserFullName = styled.span`
  font-size: 1.6rem;
  color: ${theme.colors.neutral["520"]};
  font-weight: bold;
`;

export const UserWrapper = styled.section`
  gap: ${theme.space.x2} !important;
`;

/**
 * não utilizar valores negativos para o z-index abaixo,
 * no caso de de um valor negativo o dropdown de usuário autenticado
 * NÃO IRÁ ABRIR!!!
 */
export const BoxButtonAuth = styled.section``;

export const UserInitials = styled.span`
  font-weight: bold;
  padding: 0;
  text-decoration: none;
  align-self: center;
  font-size: ${theme?.typography?.fontSize?.sm?.x6};
  color: ${theme.colors.neutral.white};
`;
