import { replaceImageBaseUrl } from "application";
import { AddToHead } from "../AddToHead";

interface PreloadImageProps {
  imgSrc?: string;
}

const PreloadImage = ({ imgSrc }: PreloadImageProps) => {
  if (!imgSrc) {
    return null;
  }

  const formattedImgSrc = replaceImageBaseUrl(imgSrc);

  return (
    <AddToHead>
      <link rel="preload" as="image" href={formattedImgSrc} />
    </AddToHead>
  );
};

export { PreloadImage };
