import { useCallback, useMemo, useRef, useState } from "react";
import { SearchResultByTermDto } from "typing";
import { theme } from "ui";
import { FilterParams, SelectedFilters } from "./types";
import { cookiesKey, setCookiesItem } from "./utils";

const useLogicFilterProducts = (
  hasClickedFilter: boolean,
  currentPage?: string
) => {
  const [params, setParams] = useState<FilterParams | null>();
  const [searchedData, setSearchedData] =
    useState<SearchResultByTermDto | null>();
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState<
    SelectedFilters[]
  >([]);

  const productListId = useMemo(() => {
    return searchedData?.products?.find((product) => product)
      ?.categoryId as number;
  }, [searchedData?.products]);

  const itemListIdInfo = useMemo(() => {
    return String(
      searchedData?.products?.find((product) => product)?.categoryId
    );
  }, [searchedData?.products]);

  const hasUpdatedFilterOnParams = useCallback(
    (updatedParams: FilterParams, oldParams: FilterParams | null) => {
      if (
        updatedParams?.selectedFilterCategories?.find(
          (filterCategory, index) =>
            filterCategory !== oldParams?.selectedFilterCategories?.[`${index}`]
        ) ||
        oldParams?.selectedFilterCategories?.length
      ) {
        return true;
      }

      if (
        updatedParams?.selectedFilterBrands?.find(
          (filterCategory, index) =>
            filterCategory !== oldParams?.selectedFilterBrands?.[`${index}`]
        ) ||
        oldParams?.selectedFilterBrands?.length
      ) {
        return true;
      }

      if (
        updatedParams?.selectedFilterModels?.find(
          (filterCategory, index) =>
            filterCategory !== oldParams?.selectedFilterModels?.[`${index}`]
        ) ||
        oldParams?.selectedFilterModels?.length
      ) {
        return true;
      }

      if (
        updatedParams?.selectedFilterAttrAgg?.find(
          (filterCategory, index) =>
            filterCategory !== oldParams?.selectedFilterAttrAgg?.[`${index}`]
        ) ||
        oldParams?.selectedFilterAttrAgg?.length
      ) {
        return true;
      }

      if (updatedParams?.pageIndex !== oldParams?.pageIndex) {
        return true;
      }

      return updatedParams?.sortByOption !== oldParams?.sortByOption;
    },
    []
  );

  const filterChangeHandler = useCallback(
    (
      selectedFilterCategories: string[],
      selectedFilterBrands: string[],
      selectedFilterModels: string[],
      selectedFilterAttrAgg: string[]
    ) => {
      const updatedParams: FilterParams = {
        ...params,
        selectedFilterCategories,
        selectedFilterBrands,
        selectedFilterModels,
        selectedFilterAttrAgg,
        pageIndex: "1",
      };

      if (
        hasUpdatedFilterOnParams(updatedParams, params as FilterParams) &&
        hasClickedFilter
      ) {
        setParams(updatedParams);
        setCookiesItem("1");
      }
    },
    [hasClickedFilter, hasUpdatedFilterOnParams, params]
  );

  const pageChangeHandler = useCallback(
    (newCurrentPage: number) => {
      if (Number(currentPage) !== Number(newCurrentPage)) {
        const updatedParams = {
          ...params,
          pageIndex: newCurrentPage.toString(),
        };

        setParams(updatedParams);
        setCookiesItem(newCurrentPage.toString(), cookiesKey);
      }
    },
    [currentPage, params]
  );

  const resetFilters = useCallback(() => {
    setSearchedData(null);
    setCurrentSelectedFilter([]);
  }, []);

  return {
    filterChangeHandler,
    params,
    setParams,
    pageChangeHandler,
    setSearchedData,
    searchedData,
    productListId,
    currentSelectedFilter,
    resetFilters,
    setCurrentSelectedFilter,
    itemListIdInfo,
  };
};

const useFilterProductsUtilities = (isClientMobile = false) => {
  const spaceWrapperResponsivePadding = isClientMobile
    ? "0"
    : `${theme.space.x0} ${theme.space.x4}`;

  const searchResultContentResponsivePadding = isClientMobile
    ? "0"
    : `${theme.space.x5} ${theme.space.x0}`;

  const filterProductsContainerRef = useRef<HTMLDivElement>(null);

  const scrollIntoSelfContainer = () => {
    if (filterProductsContainerRef?.current) {
      filterProductsContainerRef.current.scrollIntoView();
    }
  };

  const searchResultContent = !isClientMobile
    ? `${theme.space.x0} ${theme.space.x4}`
    : `${theme.space.x0} ${theme.space.x2}`;

  return {
    spaceWrapperResponsivePadding,
    searchResultContentResponsivePadding,
    filterProductsContainerRef,
    scrollIntoSelfContainer,
    searchResultContent,
  };
};

export { useFilterProductsUtilities, useLogicFilterProducts };
