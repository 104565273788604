import { IJwtDecoder } from "app-domain";
import jwtDecode, { JwtDecodeOptions } from "jwt-decode";

class JwtDecode implements IJwtDecoder {
  // eslint-disable-next-line class-methods-use-this
  decode<T>(token: string, options: unknown = undefined) {
    const formattedOptions = options as JwtDecodeOptions | undefined;
    return jwtDecode<T>(token, formattedOptions);
  }
}

export { JwtDecode };
