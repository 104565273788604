import html2canvas from "html2canvas";
import { ReactNode } from "react";
import { buildTestIds } from "application";
import { DownloadLink } from "./style";

type DownloadImageProps = {
  className: string;
  fileName: string;
  colorLink: string;
  iconLink?: ReactNode;
  textLink: string;
};

const Html2Canvas = ({
  className,
  fileName,
  colorLink,
  iconLink,
  textLink,
}: DownloadImageProps) => {
  const downloadToImage = () => {
    const element = document.querySelector<HTMLElement>(`.${className}`);
    const button = element?.querySelector<HTMLElement>(".downloadLink");

    if (element && button) {
      button.style.display = "none";

      html2canvas(element, {
        allowTaint: false,
        useCORS: true,
      }).then((canvas) => {
        const link = document.createElement("a");
        link.download = fileName;
        link.href = canvas.toDataURL("image/png");
        link.click();
      });
    }
  };

  return (
    <DownloadLink
      {...buildTestIds(`download-link-${className}`)}
      type="button"
      colorLink={colorLink}
      onClick={downloadToImage}
      className="downloadLink"
    >
      {iconLink} {textLink}
    </DownloadLink>
  );
};

export { Html2Canvas };
