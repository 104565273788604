const x1bar3 = "33.333333%";
const x1bar2 = "50%";
const x2bar3 = "66.666667%";
const x1bar6 = "16.666667%";

const partition = {
  "x1/1": "100%",
  "x1/2": x1bar2,
  "x1/3": x1bar3,
  "x2/3": x2bar3,
  "x1/4": "25%",
  "x2/4": x1bar2,
  "x3/4": "75%",
  "x1/5": "20%",
  "x2/5": "40%",
  "x3/5": "60%",
  "x4/5": "80%",
  "x1/6": x1bar6,
  "x2/6": x1bar3,
  "x3/6": x1bar2,
  "x4/6": x2bar3,
  "x5/6": "83.333333%",
  "x1/12": "8.333333%",
  "x2/12": x1bar6,
  "x3/12": "25%",
  "x4/12": x1bar3,
  "x5/12": "41.666667%",
  "x6/12": x1bar2,
  "x7/12": "58.333333%",
  "x8/12": x2bar3,
  "x9/12": "75%",
  "x10/12": "83.333333%",
  "x11/12": "91.666667%",
} as const;

const space = {
  ...partition,
  x0: "0",
  xpx: "1px",
  "x0.5": "2px", // 0.125rem
  x1: "4px", // 0.25rem
  "x1.25": "5px",
  "x1.5": "6px", // 0.375rem
  x2: "8px", // 0.5rem
  "x2.5": "10px", // 0.625rem
  x3: "12px", // 0.75rem
  "x3.5": "14px", // 0.875rem
  x4: "16px", // 1rem
  "4.25": "17px",
  "x4.5": "18px",
  x5: "20px", // 1.25rem'
  "x5.5": "22px", // 1.375rem
  x6: "24px", // 1.5rem
  x7: "28px", // 1.75rem
  "x7.5": "30px", // 1.875rem
  x8: "32px", // 2rem
  x9: "36px", // 2.25rem
  x10: "40px", // 2.5rem
  "x10.5": "42px", // 2.625rem
  "x10.75": "43px",
  x11: "44px", // 2.75rem
  "x11.5": "45px", // 2.8rem
  x12: "48px", // 3rem
  "x13.5": "54px", // 3.375rem
  x14: "56px", // 3.5rem
  x15: "60px", // 3.75rem
  x16: "64px", // 4rem
  x17: "68px",
  "x17.5": "70px",
  x18: "72px",
  "x18.5": "74px",
  "x19.5": "78px",
  x20: "80px", // 5rem
  "x22.5": "90px",
  x24: "96px", // 6rem
  x28: "112px", // 7rem
  "x31.5": "126px",
  x32: "128px", // 8rem
  x36: "144px", // 9rem
  x39: "156px",
  x40: "160px", // 10rem
  x42: "168px", // 10.5rem
  x44: "176px", // 11rem
  x46: "188px",
  x48: "192px", // 12rem
  x52: "208px", // 13rem
  x56: "224px", // 14rem
  x60: "240px", // 15rem
  x63: "252px",
  x64: "256px", // 16rem
  x72: "288px", // 18rem
  x78: "305px", // 18.75rem
  x80: "320px", // 20rem
  x88: "352px", // 22rem
  x90: "360px", // 22.5rem
  x96: "384px", // 24rem
  x104: "412px", // 26rem
  x112: "448px", // 28rem
  x118: "489px",
  x128: "512px", // 32rem
  x144: "576px", // 36rem
  x155: "600px", // 37.5rem
  x160: "640px", // 40rem
  x166: "668px",
  x188: "752px", // 47rem
  x192: "768px", // 48rem
  x194: "778px",
  x208: "832px", // 52rem
  x212: "848px", // 53rem
  x216: "864px", // 54rem
  x224: "896px", // 56rem
  x228: "912px", // 57rem
  x244: "928px", // 58rem
  x260: "944px",
  x308: "1232px", // 77rem
  x312: "1248px", // 78rem
} as const;

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileXL: "480px",
  tabletS: "576px",
  tabletSM: "560px",
  tabletM: "600px",
  tabletML: "678px",
  tabletXM: "700px",
  tablet: "768px",
  tabletL: "826px",
  tabletXL: "850px",
  laptopS: "900px",
  laptop: "1024px",
  laptopM: "1330px",
  laptopL: "1440px",
  laptopXL: "1600px",
  desktopS: "1980px",
  desktop: "2560px",
} as const;

const getDevices = (mobileFirst = false) => {
  const mobileFirstAsString = mobileFirst ? "min-width" : "max-width";

  return {
    mobileS: `(${mobileFirstAsString}: ${size.mobileS})`,
    mobileM: `(${mobileFirstAsString}: ${size.mobileM})`,
    mobileL: `(${mobileFirstAsString}: ${size.mobileL})`,
    mobileXL: `(${mobileFirstAsString}: ${size.mobileXL})`,
    tabletS: `(${mobileFirstAsString}: ${size.tabletS})`,
    tabletSM: `(${mobileFirstAsString}: ${size.tabletSM})`, // Tamanho específico para comportamento do componente de Contador
    tabletM: `(${mobileFirstAsString}: ${size.tabletM})`,
    tabletXM: `(${mobileFirstAsString}: ${size.tabletXM})`,
    tablet: `(${mobileFirstAsString}: ${size.tablet})`,
    tabletL: `(${mobileFirstAsString}: ${size.tabletL})`,
    tabletML: `(${mobileFirstAsString}: ${size.tabletML})`,
    tabletXL: `(${mobileFirstAsString}: ${size.tabletXL})`,
    laptopS: `(${mobileFirstAsString}: ${size.laptopS})`,
    laptop: `(${mobileFirstAsString}: ${size.laptop})`,
    laptopM: `(${mobileFirstAsString}: ${size.laptopM})`, // Tamanho específico para comportamento do componente de letreiro
    laptopL: `(${mobileFirstAsString}: ${size.laptopL})`,
    laptopXL: `(${mobileFirstAsString}: ${size.laptopXL})`,
    desktopS: `(${mobileFirstAsString}: ${size.desktopS})`,
    desktop: `(${mobileFirstAsString}: ${size.desktop})`,
  };
};

export const theme = {
  breakpoints: {
    "Breakpoints.xs": "0",
    "Breakpoints.sm": 480,
    "Breakpoints.md": 768,
    "Breakpoints.lg": 992,
    "Breakpoints.xl": 1200,
  },
  colors: {
    primary: {
      alert: "#E8273F",
      "10": "#FFE6E7",
      "25": "#ff0000",
      "50": "#FF0915",
      "60": "#FBDDE1",
      "75": "#FEF4F5",
      "77": "#F499A4",
      "80": "#C65555",
      "85": "#D13241",
      "95": "#ED5568",
      "100": "#EE0813",
      "150": "#E71C35",
      "155": "#AA0E27",
      "180": "#E3132F",
      "190": "#EA4335",
      "200": "#DA0812",
      "225": "#DB071E",
      "250": "#DF2D36",
      "300": "#C50710",
      "300-rgb": "rgb(197,7,16)",
      "330": "#CA020C",
      "350": "#BE0F2C",
      "355": "#460610",
      "360": "#B53535",
      "380": "#E8273F",
      "390": "#BA0F2B",
      "400": "#B1060E",
      "500": "#9C050C",
      "550": "#960C23",
      "600": "#88050B",
      "700": "#EB001B",
      "705": "#E3132F",
      "710": "#AE1D2F",
      "725": "#da1f31",
      "730": "#D23A52",
      "750": "#BE1833",
      "800": "#B82126",
      "850": "#EE4023",
      "855": "#CD0027",
      "900": "#AE1D2F",
      "200-opacity-15": "rgba(218,8,18,0.15)",
    },
    secondary: {
      "20": "#f2fbf5",
      "25": "#EBF4EE",
      "28": "#EBF6E3",
      "30": "#f8fcf2",
      "33": "#D0E9BD",
      "35": "#f2f9e9",
      "37": "#A9D785",
      "38": "#5DAA53",
      "39": "#c3e3aa",
      "40": "#c1d82f",
      "45": "#C1D910",
      "50": "#00EF45",
      "100": "#00DA3F",
      "200": "#00C639",
      "225": "#1c9f40",
      "230": "#7AC143",
      "250": "#74B740",
      "255": "#78BE20",
      "260": "#6cb33f",
      "265": "#00B233",
      "270": "#04B437",
      "275": "#00BB23",
      "300": "#00B234",
      "310": "#00B233",
      "315": "#048729",
      "325": "#04892a",
      "340": "#20B038",
      "345": "#5d9233",
      "350": "#5f9834",
      "355": "#5CBE1D",
      "357": "#5A8F19",
      "360": "#629C1B",
      "365": "#60D66A",
      "370": "#6AB760",
      "380": "#68A439",
      "385": "#7AB740",
      "390": "#7ac143",
      "395": "#7FD899",
      "400": "#009D2E",
      "410": "#5CBE1D",
      "415": "#5A8F19",
      "420": "#5A9018",
      "425": "#4F7D2C",
      "430": "#40b770",
      "435": "#4A6538",
      "440": "#34A853",
      "450": "#32bcad",
      "500": "#008928",
      "600": "#007422",
      "650": "#40A737",
      "700": "#048729",
      "750": "#00D95F",
      "755": "rgba(116,183,64,0.25)",
      "760": "rgba(104,164,57,0.2)",
      "850": "#F6F9E0",
      "920": "#def0d0",
      "950": "#7D8C1F",
      "960": "#548f19",
    },
    tertiary: {
      "50": "#00A2A2",
      "55": "#7F86DF",
      "100": "#1E90FF",
      "105": "#1478DD",
      "110": "#2196F3",
      "115": "#3E90FD",
      "150": "#48A4F9",
      "200": "#187DE9",
      "205": "#5B5B76",
      "210": "#455A64",
      "250": "#4688f1",
      "260": "#4285F4",
      "280": "#4498E5",
      "300": "#126AD2",
      "400": "#0C56BC",
      "500": "#0643A5",
      "600": "#00308F",
      "700": "#00579F",
      "750": "#0079BE",
      "800": "#26A6D1",
      "850": "#0066B1",
      "900": "#00A3DF",
      "930": "rgba(72,164,249,0.15)",
      "950": "#12122c",
    },
    neutral: {
      "gray-opacity-23": "rgba(160,162,164,0.23)",
      "gray-opacity-35": "#70707059",
      "gray-opacity-50": "rgba(206,206,206,0.5)",
      white: "#FFFFFF",
      // Em Alinhamento com Wellington não colocar espaço nos rgba para não quebrar os testes
      "white-opacity-20": "rgba(255,255,255,0.2)",
      "white-opacity-25": "rgba(255,255,255,0.25)",
      "white-opacity-50": "rgba(255,255,255,0.5)",
      "white-010": "#FFFCF2",
      "white-050": "#F7F7F7",
      "white-075": "#F6F7F7",
      "13": "#FFFCF2",
      "25": "#FBFBFB",
      "30": "#F2F2F2",
      "35": "#F2F9F9",
      "40": "#FAFAFA",
      "45": "#F4F4F4",
      "50": "#F8F8F8",
      "55": "#F9F9F9",
      "60": "#F2F2F2",
      "70": "#E0E0E0",
      "75": "#EFEFEF",
      "80": "#F8F8F8",
      "85": "#DAD7D7",
      "88": "#D4D5D6",
      "90": "#DFE0E0",
      "95": "#DDDDDD",
      "98": "#DBDBDB",
      "99": "#E5E5E5",
      "100": "#E4E4E4",
      "105": "#E5E5E6",
      "110": "#5C5E62",
      "125": "#EBEBEB",
      "150": "#E5E6E6",
      "155": "#EDEDED",
      "170": "#E3E6E4",
      "175": "#CBCBCB",
      "200": "#CFCFCF",
      "225": "#C6C7C9",
      "230": "#C4C4C4",
      "240": "#B2B3B5",
      "245": "#F5F5F5",
      "250": "#BBBBBB",
      "255": "#F3F3F3",
      "260": "#DFE0E0",
      "275": "#D8D8D8",
      "278": "#C4C4C4",
      "280": "#B4B4B4",
      "300": "#B1B1B1",
      "305": "#DADADA",
      "310": "#E5E5E5",
      "315": "#BDBDBD",
      "320": "#CCCCCC",
      "325": "#505050",
      "330": "#7E8083",
      "335": "#E7E7E7",
      "340": "#484848",
      "350": "#8F9194",
      "375": "#AEB0B2",
      "385": "#8D8D8D",
      "390": "#888888",
      "400": "#9E9E9E",
      "425": "#808080",
      "450": "#9B9B9B",
      "465": "#727578",
      "475": "#979797",
      "480": "#818181",
      "485": "#333333",
      "490": "#36383B",
      "495": "#3D3D3D",
      "500": "#7E7E7E",
      "510": "#727578",
      "520": "#4A4A4A",
      "525": "#4C4A44",
      "530": "#4D4D4D",
      "550": "#53565A",
      "555": "#E5E6E6",
      "560": "#C3C4C5",
      "565": "#433430",
      "570": "#444444",
      "580": "#D4D5D6",
      "585": "#6D6F73",
      "590": "#606060",
      "600": "#626262",
      "625": "#606060",
      "630": "#727578",
      "650": "#777777",
      "655": "#535659",
      "665": "#838383",
      "670": "#252728",
      "675": "#222235",
      "680": "#2E2F32",
      "690": "#36383B",
      "695": "#3E4144",
      "700": "#565656",
      "705": "#1D1D1B",
      "710": "#575757",
      "715": "#1D1E1F",
      "720": "#4F5256",
      "730": "#5c5e62",
      "740": "#A43939",
      "750": "#47494D",
      "760": "#A0A2A4",
      "805": "#0A0A0A",
      "810": "#00000066",
      "815": "#484848",
      "820": "#00000060",
      "825": "#45454591",
      "830": "#00000095",
      "835": "#0000001A",
      "850": "#222235",
      "875": "#00000012",
      "900": "#161616",
      "925": "#373738",
      "940": "#040405",
      "999": "#000000",

      // Em Alinhamento com Wellington não colocar espaço nos rgba para não quebrar os testes
      "999-opacity-05": "rgba(0,0,0,0.05)",
      "760-opacity-23": "rgba(160,162,164,0.23)",
      "900-opacity-25": "rgba(21,22,23,0.25)",
      "900-opacity-32": "rgba(83,86,90,0.32)",
      "999-opacity-025": "rgba(0,0,0,0.025)",
      "999-opacity-10": "rgba(0,0,0,0.1)",
      "999-opacity-11": "rgba(0,0,0,0.11)",
      "999-opacity-12": "rgba(0,0,0,0.12)",
      "999-opacity-15": "rgba(0,0,0,0.15)",
      "999-opacity-19": "rgba(0,0,0,0.19)",
      "999-opacity-20": "rgba(0,0,0,0.2)",
      "999-opacity-25": "rgba(0,0,0,0.25)",
      "999-opacity-50": "rgba(0,0,0,0.5)",
      "999-opacity-55": "rgba(0,0,0,.7)",
      "999-opacity-60": "rgba(0,0,0,.25)",
      "999-opacity-65": "rgba(0,0,0,0.06)",
      "999-opacity-70": "rgba(151,151,151,0.47)",
      "999-opacity-75": "rgba(113,113,113)",
    },
    orange: {
      "100": "#DC841B",
      "200": "#d34836",
      "300": "#b33e2f",
      "400": "#FF5F00",
    },
    yellow: {
      "40": "#FFA51E",
      "45": "#FFD540",
      "50": "#F5A623",
      "55": "#F8D35E",
      "70": "#FBBC05",
      "80": "#bf9500",
      "100": "#c78517",
      "110": "#EFD912",
      "150": "#E58604",
      "170": "#E7A807",
      "200": "#FAA61A",
      "300": "#F07C00",
      "400": "#FFEC00",
      "500": "#FFF100",
      "600": "#F0D04E",
      "650": "#F2BD00",
      "850": "#FFF7D9",
      "950": "#F79E1B",
      "1000": "#E8B227",
      "1020": "#D9A900",
      "1040": "#A68100",
      "1050": "#BF9500",
      "1100": "#C1D910",
      "1150": "#8C6D00",
      "1200": "#BF9500",
    },
    pink: {
      "50": "#ffe6e7",
      "55": "#ffd4d4",
      "60": "#faf9fa",
      "65": "#F6D6DB",
      "70": "#EBA6B1",
    },
    blackFriday: {
      white: "#FFFFFF",
      black: "#000000",
      "5": "#F8F8F8",
      "10": "#FBFBFB",
      "15": "#F6F7F7",
      "17": "#EBF6E3",
      "20": "#E5E6E6",
      "25": "#D4D5D6",
      "30": "#DDDDDD",
      "33": "#CFCFCF",
      "35": "#B2B3B5",
      "40": "#A0A2A4",
      "45": "#8F9194",
      "50": "#6D6F73",
      "75": "#5C5E62",
      "85": "#53565A",
      "80": "#7E7E7E",
      "100": "#47494D",
      "110": "#3E4144",
      "115": "#4A4A4A",
      "120": "#4D4D4D",
      "125": "#36383B",
      "150": "#2E2F32",
      "175": "#252728",
      "185": "#1D1E1F",
      "200": "#151617",
      "210": "#0C0D0E",
      "230": "#0A0A0A",
      "300": "#81C44C",
      "320": "#74B740",
      "400": "#FEF4F5",
      "420": "#E71C35",
      "480": "#DA0812",
    },
  },
  device: {
    mobileFirst: getDevices(true),
    desktopFirst: getDevices(false),
    size,
  },
  typography: {
    fontFamily: {
      barlow: "Barlow",
      openSans: "Open Sans",
      roboto: "Roboto",
      chivo: "Chivo",
      playfairDisplay: "Playfair Display",
      inter: "Inter",
    },
    fontSize: {
      /**
       * sm - medidas entre 10px e 18px
       */
      sm: {
        x1: "10px", // 0.83rem
        x2: "11px", // 0.92rem
        x3: "12px", // 1rem
        x4: "13px", // 1.08rem
        x5: "14px", // 1.17rem
        x6: "15px", // 1.25rem
        x7: "16px", // 1.33rem
        x8: "17px", // 1.42rem
        x9: "18px", // 1.5rem
      },
      /**
       * md - medidas entre 20px e 28px
       */
      md: {
        x1: "20px", // 1.67rem
        x2: "22px", // 1.83rem
        x3: "24px", // 2rem
        x4: "26px", // 2.17rem
        x5: "28px", // 2.33rem
      },
      /**
       * lg - medidas entre 30px e 64px
       */
      lg: {
        x1: "30px", // 2.5rem
        x2: "32px", // 2.67rem
        x3: "36px", // 3rem
        x4: "40px", // 3.33rem
        x5: "50px", // 4.17rem
        x6: "56px", // 4.67rem
        x7: "60px", // 5rem
        x8: "64px", // 5.33rem
      },
      // xxxs: "11px",
      // xxs: "12px",
      // xs: "14px",
      // ss: "15px",
      // ssm: "16px",
      // sm: "18px",
      // xmd: "20px",
      // md: "24px",
      // lmd: "25px",
      // mllg: "28px",
      // llg: "26px",
      // slg: "28px",
      // lg: "32px",
      // smxl: "36px",
      // sxl: "40px",
      // mxl: "50px",
      // lxl: "56px",
      // mlxl: "60px",
      // xl: "64px",
    },
    fontWeight: {
      "100": 100,
      "200": 200,
      "300": 300,
      "400": 400,
      "500": 500,
      "600": 600,
      "700": 700,
      "800": 800,
      "900": 900,
      bold: "bold",
      bolder: "bolder",
      normal: "normal",
    },
  },
  space,
} as const;

export type ThemeType = typeof theme;
