import { IAppMonitoringClient } from "app-domain";

type CartEventError =
  | "get_cart_anonymous"
  | "get_cart_authenticated"
  | "create_cart_anonymous"
  | "create_cart_authenticated"
  | "add_item_to_cart"
  | "update_item_to_cart"
  | "remove_item_from_cart"
  | "delete_item_in_payment";

const handleCartError = (
  error: unknown,
  appMonitoringClient: IAppMonitoringClient,
  eventError: CartEventError
) => {
  if (error) {
    appMonitoringClient.captureException(error, {
      level: "error",
      tags: {
        fcx_labs_event: eventError,
        fcx_labs_error_source: "shopping_api",
      },
    });
  }
};

export { handleCartError };
