import { IconProps } from "typing";
import { theme } from "../themes";

export const Banknote = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["550"],
  alt = "dinheiro",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.3882C13.1046 14.3882 14 13.4928 14 12.3882C14 11.2837 13.1046 10.3882 12 10.3882C10.8954 10.3882 10 11.2837 10 12.3882C10 13.4928 10.8954 14.3882 12 14.3882Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 14.4783V11.4783"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 13.4783V10.4783"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.804 18.6913V18.6913C17.299 18.1903 14.715 18.2523 12.237 18.8713L12 18.9313C9.366 19.5893 6.619 19.6553 3.957 19.1223L3.804 19.0913C3.336 18.9983 3 18.5873 3 18.1113V7.27229C3 6.64129 3.577 6.16829 4.196 6.29129V6.29129C6.701 6.79229 9.285 6.73029 11.763 6.11129L12.236 5.99329C14.714 5.37429 17.299 5.31229 19.803 5.81329L20.195 5.89129C20.664 5.98529 21 6.39529 21 6.87229V17.7113C21 18.3423 20.423 18.8153 19.804 18.6913V18.6913Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
