import { IconProps } from "typing";
import { theme } from "../themes";

export const NewShareIcon = ({
  width = 24,
  height = 25,
  color = theme.colors.secondary["380"],
  strokeWidth = "1.5",
  alt = "Ícone de compartilhamento",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            d="M13.5307 8.02002L9.4707 11.06"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M16.4044 5.14216C17.1979 5.9357 17.1979 7.2223 16.4044 8.01584C15.6108 8.80938 14.3242 8.80938 13.5307 8.01584C12.7372 7.22229 12.7372 5.9357 13.5307 5.14216C14.3243 4.34861 15.6108 4.34861 16.4044 5.14216"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_3"
            d="M13.5307 16.9799L9.4707 13.9399"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_4"
            d="M16.4044 16.9842C17.1979 17.7777 17.1979 19.0643 16.4044 19.8579C15.6108 20.6514 14.3242 20.6514 13.5307 19.8579C12.7372 19.0643 12.7372 17.7777 13.5307 16.9842C14.3243 16.1907 15.6108 16.1907 16.4044 16.9842"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_5"
            d="M9.46542 11.0645C10.2582 11.8573 10.2582 13.1426 9.46542 13.9354C8.67266 14.7282 7.38733 14.7282 6.59457 13.9354C5.80181 13.1426 5.80181 11.8573 6.59457 11.0645C7.38734 10.2718 8.67266 10.2718 9.46542 11.0645"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
