import { MultipleColorsIconProps } from "typing";

const DevicePasswordChange = ({
  alt = "Alterar nova senha ícone",
  width = 203,
  height = 155,
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 203 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="64.1421" cy="91" r="64" fill="#F6F7F7" />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.3667 78.4854C31.7567 78.4854 30.4507 79.7043 30.4507 81.2077C30.4507 82.7111 31.7567 83.9293 33.3667 83.9293H41.3867C42.9975 83.9293 44.3035 82.7104 44.3035 81.2077C44.3035 79.705 42.9975 78.4861 41.3875 78.4861C41.3875 76.4188 39.592 74.743 37.3771 74.743C35.1622 74.743 33.3667 76.4181 33.3667 78.4854Z"
          stroke="#A0A2A4"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3667 94.4854C9.7567 94.4854 8.45068 95.7043 8.45068 97.2077C8.45068 98.7111 9.7567 99.9293 11.3667 99.9293H19.3867C20.9975 99.9293 22.3035 98.7104 22.3035 97.2077C22.3035 95.705 20.9975 94.4861 19.3875 94.4861C19.3875 92.4188 17.592 90.743 15.3771 90.743C13.1622 90.743 11.3667 92.4181 11.3667 94.4854Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.3667 135.485C83.7567 135.485 82.4507 136.704 82.4507 138.208C82.4507 139.711 83.7567 140.929 85.3667 140.929H93.3867C94.9975 140.929 96.3035 139.71 96.3035 138.208C96.3035 136.705 94.9975 135.486 93.3875 135.486C93.3875 133.419 91.592 131.743 89.3771 131.743C87.1622 131.743 85.3667 133.418 85.3667 135.485Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3667 112.485C22.7567 112.485 21.4507 113.704 21.4507 115.208C21.4507 116.711 22.7567 117.929 24.3667 117.929H32.3867C33.9975 117.929 35.3035 116.71 35.3035 115.208C35.3035 113.705 33.9975 112.486 32.3875 112.486C32.3875 110.419 30.592 108.743 28.3771 108.743C26.1622 108.743 24.3667 110.418 24.3667 112.485Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.367 106.485C108.757 106.485 107.451 107.704 107.451 109.208C107.451 110.711 108.757 111.929 110.367 111.929H118.387C119.997 111.929 121.303 110.71 121.303 109.208C121.303 107.705 119.997 106.486 118.387 106.486C118.387 104.419 116.592 102.743 114.377 102.743C112.162 102.743 110.367 104.418 110.367 106.485Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.3667 41.4854C77.7567 41.4854 76.4507 42.7043 76.4507 44.2077C76.4507 45.7111 77.7567 46.9293 79.3667 46.9293H87.3867C88.9975 46.9293 90.3035 45.7104 90.3035 44.2077C90.3035 42.705 88.9975 41.4861 87.3875 41.4861C87.3875 39.4188 85.592 37.743 83.3771 37.743C81.1622 37.743 79.3667 39.4181 79.3667 41.4854Z"
          stroke="#8F9194"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.367 63.4854C103.757 63.4854 102.451 64.7043 102.451 66.2077C102.451 67.7111 103.757 68.9293 105.367 68.9293H113.387C114.997 68.9293 116.303 67.7104 116.303 66.2077C116.303 64.705 114.997 63.4861 113.387 63.4861C113.387 61.4188 111.592 59.743 109.377 59.743C107.162 59.743 105.367 61.4181 105.367 63.4854Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.9734 94.4892C91.7927 94.4892 90.835 95.3831 90.835 96.4856C90.835 97.5881 91.7927 98.4815 92.9734 98.4815H98.8547C100.036 98.4815 100.994 97.5876 100.994 96.4856C100.994 95.3837 100.036 94.4898 98.8553 94.4898C98.8553 92.9738 97.5386 91.7449 95.9143 91.7449C94.2901 91.7449 92.9734 92.9733 92.9734 94.4892Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.9734 37.4892C44.7927 37.4892 43.835 38.3831 43.835 39.4856C43.835 40.5881 44.7927 41.4815 45.9734 41.4815H51.8547C53.0359 41.4815 53.9937 40.5876 53.9937 39.4856C53.9937 38.3837 53.0359 37.4898 51.8553 37.4898C51.8553 35.9738 50.5386 34.7449 48.9143 34.7449C47.2901 34.7449 45.9734 35.9733 45.9734 37.4892Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9734 136.489C35.7927 136.489 34.835 137.383 34.835 138.486C34.835 139.588 35.7927 140.481 36.9734 140.481H42.8547C44.0359 140.481 44.9937 139.588 44.9937 138.486C44.9937 137.384 44.0359 136.49 42.8553 136.49C42.8553 134.974 41.5386 133.745 39.9143 133.745C38.2901 133.745 36.9734 134.973 36.9734 136.489Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9734 57.4892C23.7927 57.4892 22.835 58.3831 22.835 59.4856C22.835 60.5881 23.7927 61.4815 24.9734 61.4815H30.8547C32.0359 61.4815 32.9937 60.5876 32.9937 59.4856C32.9937 58.3837 32.0359 57.4898 30.8553 57.4898C30.8553 55.9738 29.5386 54.7449 27.9143 54.7449C26.2901 54.7449 24.9734 55.9733 24.9734 57.4892Z"
          fill="#A0A2A4"
        />
      </g>
      <ellipse
        cx="63.9358"
        cy="128.926"
        rx="16.0042"
        ry="1.06694"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1249 49.0025H74.777C80.845 49.0025 85.7598 53.9173 85.7598 59.9853V109.408C85.7598 115.476 80.845 120.391 74.777 120.391H52.1249C46.0569 120.391 41.1421 115.476 41.1421 109.408V59.9853C41.1421 53.9173 46.0569 49.0025 52.1249 49.0025Z"
        fill="#F6F7F7"
        stroke="#53565A"
        strokeWidth="3.53019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.6323 108.035H68.2423H58.6323Z"
        fill="white"
      />
      <path
        d="M58.6323 108.035H68.2423"
        stroke="#53565A"
        strokeWidth="3.53019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="63.4505" cy="71.3114" r="7.75959" fill="#F8FCF6" />
      <ellipse cx="63.6416" cy="77.4999" rx="4.5" ry="1.5" fill="#F2BD00" />
      <path
        d="M69.1096 65.653C72.2346 68.778 72.2346 73.8447 69.1096 76.9697C65.9846 80.0947 60.918 80.0947 57.793 76.9697C54.668 73.8446 54.668 68.778 57.793 65.653C60.918 62.528 65.9846 62.528 69.1096 65.653"
        stroke="#252728"
        strokeWidth="1.33368"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.2203 68.0429C66.197 69.0196 66.197 70.6032 65.2203 71.5799C64.2436 72.5567 62.66 72.5567 61.6832 71.5799C60.7065 70.6032 60.7065 69.0196 61.6832 68.0429C62.66 67.0661 64.2436 67.0661 65.2203 68.0429Z"
        fill="#F2BD00"
      />
      <path
        d="M65.2203 68.0429C66.197 69.0196 66.197 70.6032 65.2203 71.5799C64.2436 72.5567 62.66 72.5567 61.6832 71.5799C60.7065 70.6032 60.7065 69.0196 61.6832 68.0429C62.66 67.0661 64.2436 67.0661 65.2203 68.0429"
        stroke="#252728"
        strokeWidth="1.33368"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5254 77.4978C67.2495 76.1544 65.4499 75.3124 63.4512 75.3124C61.4524 75.3124 59.6528 76.1544 58.377 77.4987"
        fill="#F2BD00"
      />
      <path
        d="M68.5254 77.4978C67.2495 76.1544 65.4499 75.3124 63.4512 75.3124C61.4524 75.3124 59.6528 76.1544 58.377 77.4987"
        stroke="#252728"
        strokeWidth="1.33368"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.17"
        d="M200.349 56.1655L73.8035 94.2279L54.1647 87.1523L131.872 25.8401L200.349 56.1655Z"
        fill="url(#paint0_linear_123_168517)"
        stroke="#F2BD00"
      />
      <rect
        x="128.295"
        y="22.7583"
        width="73.7162"
        height="32.9783"
        rx="3.8798"
        fill="#F2BD00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.889 38.7797C149.889 38.3815 149.568 38.0599 149.169 38.0628C148.771 38.0628 148.45 38.3844 148.45 38.7827C148.45 39.181 148.771 39.5026 149.169 39.5026C149.565 39.5026 149.889 39.181 149.889 38.7797Z"
        fill="#F2BD00"
      />
      <path
        d="M149.889 38.7797C149.889 38.3815 149.568 38.0599 149.169 38.0628C148.771 38.0628 148.45 38.3844 148.45 38.7827C148.45 39.181 148.771 39.5026 149.169 39.5026C149.565 39.5026 149.889 39.181 149.889 38.7797"
        stroke="#FEF4F5"
        strokeWidth="4.79418"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.691 38.7797C161.691 38.3815 161.369 38.0599 160.971 38.0628C160.572 38.0628 160.251 38.3844 160.251 38.7827C160.251 39.181 160.572 39.5026 160.971 39.5026C161.366 39.5026 161.691 39.181 161.691 38.7797Z"
        fill="#F2BD00"
      />
      <path
        d="M161.691 38.7797C161.691 38.3815 161.369 38.0599 160.971 38.0628C160.572 38.0628 160.251 38.3844 160.251 38.7827C160.251 39.181 160.572 39.5026 160.971 39.5026C161.366 39.5026 161.691 39.181 161.691 38.7797"
        stroke="#FEF4F5"
        strokeWidth="4.79418"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.491 38.7797C173.491 38.3815 173.17 38.0599 172.772 38.0628C172.373 38.0628 172.052 38.3844 172.052 38.7827C172.052 39.181 172.373 39.5026 172.772 39.5026C173.167 39.5026 173.491 39.181 173.491 38.7797Z"
        fill="#F2BD00"
      />
      <path
        d="M173.491 38.7797C173.491 38.3815 173.17 38.0599 172.772 38.0628C172.373 38.0628 172.052 38.3844 172.052 38.7827C172.052 39.181 172.373 39.5026 172.772 39.5026C173.167 39.5026 173.491 39.181 173.491 38.7797"
        stroke="#FEF4F5"
        strokeWidth="4.79418"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.293 38.7797C185.293 38.3815 184.971 38.0599 184.573 38.0628C184.175 38.0628 183.853 38.3844 183.853 38.7827C183.853 39.181 184.175 39.5026 184.573 39.5026C184.971 39.5026 185.293 39.181 185.293 38.7797Z"
        fill="#F2BD00"
      />
      <path
        d="M185.293 38.7797C185.293 38.3815 184.971 38.0599 184.573 38.0628C184.175 38.0628 183.853 38.3844 183.853 38.7827C183.853 39.181 184.175 39.5026 184.573 39.5026C184.971 39.5026 185.293 39.181 185.293 38.7797"
        stroke="#FEF4F5"
        strokeWidth="4.79418"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="52.7817"
        y="85.8606"
        width="21.6812"
        height="9.69949"
        rx="1.14112"
        fill="#F2BD00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1329 90.5728C59.1329 90.4557 59.0383 90.3611 58.9212 90.362C58.804 90.362 58.7094 90.4566 58.7094 90.5737C58.7094 90.6908 58.804 90.7854 58.9212 90.7854C59.0374 90.7854 59.1329 90.6908 59.1329 90.5728Z"
        fill="#5C5E62"
      />
      <path
        d="M59.1329 90.5728C59.1329 90.4557 59.0383 90.3611 58.9212 90.362C58.804 90.362 58.7094 90.4566 58.7094 90.5737C58.7094 90.6908 58.804 90.7854 58.9212 90.7854C59.0374 90.7854 59.1329 90.6908 59.1329 90.5728"
        stroke="#36383B"
        strokeWidth="1.41005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.6036 90.5728C62.6036 90.4557 62.509 90.3611 62.3919 90.362C62.2747 90.362 62.1801 90.4566 62.1801 90.5737C62.1801 90.6908 62.2747 90.7854 62.3919 90.7854C62.5081 90.7854 62.6036 90.6908 62.6036 90.5728Z"
        fill="#5C5E62"
      />
      <path
        d="M62.6036 90.5728C62.6036 90.4557 62.509 90.3611 62.3919 90.362C62.2747 90.362 62.1801 90.4566 62.1801 90.5737C62.1801 90.6908 62.2747 90.7854 62.3919 90.7854C62.5081 90.7854 62.6036 90.6908 62.6036 90.5728"
        stroke="#36383B"
        strokeWidth="1.41005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.0748 90.5728C66.0748 90.4557 65.9802 90.3611 65.8631 90.362C65.7459 90.362 65.6513 90.4566 65.6513 90.5737C65.6513 90.6908 65.7459 90.7854 65.8631 90.7854C65.9793 90.7854 66.0748 90.6908 66.0748 90.5728Z"
        fill="#5C5E62"
      />
      <path
        d="M66.0748 90.5728C66.0748 90.4557 65.9802 90.3611 65.8631 90.362C65.7459 90.362 65.6513 90.4566 65.6513 90.5737C65.6513 90.6908 65.7459 90.7854 65.8631 90.7854C65.9793 90.7854 66.0748 90.6908 66.0748 90.5728"
        stroke="#36383B"
        strokeWidth="1.41005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.5455 90.5728C69.5455 90.4557 69.4509 90.3611 69.3338 90.362C69.2166 90.362 69.122 90.4566 69.122 90.5737C69.122 90.6908 69.2166 90.7854 69.3338 90.7854C69.4509 90.7854 69.5455 90.6908 69.5455 90.5728Z"
        fill="#5C5E62"
      />
      <path
        d="M69.5455 90.5728C69.5455 90.4557 69.4509 90.3611 69.3338 90.362C69.2166 90.362 69.122 90.4566 69.122 90.5737C69.122 90.6908 69.2166 90.7854 69.3338 90.7854C69.4509 90.7854 69.5455 90.6908 69.5455 90.5728"
        stroke="#36383B"
        strokeWidth="1.41005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_123_168517"
          x1="59.436"
          y1="103.693"
          x2="157.629"
          y2="9.6697"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#74B740" />
          <stop offset="1" stopColor="#74B740" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { DevicePasswordChange };
