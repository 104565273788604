import styled from "styled-components";
import { theme } from "ui/themes";

export const ButtonArrowPrev = styled.div`
  top: -1%;
  left: 14%;
  right: inherit;
  margin: auto;
  z-index: 1;
  margin-top: -20px;
  background: none !important;

  &:hover {
    opacity: 0.5;
  }

  &:before {
    display: none;
  }
`;

export const ButtonArrowNext = styled.div`
  top: inherit;
  bottom: -7%;
  left: 11.5%;
  right: inherit;
  /* padding: 2rem; */
  margin: auto;
  background: none !important;

  z-index: 1;

  &:hover {
    opacity: 0.5;
  }

  &:before {
    display: none;
  }
`;

export const CarouselLib = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 4rem;
  max-width: 6rem;
  min-width: 6rem;
  @media ${theme.device.desktopFirst.tablet} {
    display: none;
  }

  .slick-list {
    width: 50px;
  }
`;

export const ContainerList = styled.div`
  display: none;

  @media ${theme.device.desktopFirst.tablet} {
    display: flex;
    max-width: 100%;
    order: 2;
    padding: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: auto;
    order: 2;
  }
`;
export const Container = styled.div`
  display: flex;
`;
