import { CardCTASubComponentDto, ComponentDto, SliderProps } from "typing";
import { buildTestIds, useAppContext } from "application";
import { memo, useEffect, useState } from "react";
import CustomCardCTASubComponent from "../SubComponents";
import { CustomCardCTAWrapper } from "./styles";
import { Slider } from "../../../Slider";

interface CardCTASliderProps {
  subComponents: ComponentDto[];
  positionComponent: number | undefined;
}

const CardCTASlider = memo(
  ({ subComponents, positionComponent }: CardCTASliderProps) => {
    const { isClientMobile, isClientTablet } = useAppContext();

    const [slidesToShow, setSlidesToShow] = useState(
      isClientTablet || isClientMobile ? 1 : 3
    );

    const [slidesToScroll, setSlidesToScroll] = useState(
      isClientTablet || isClientMobile ? 1 : 3
    );

    useEffect(() => {
      setSlidesToShow(isClientTablet || isClientMobile ? 1 : 3);
      setSlidesToScroll(isClientTablet || isClientMobile ? 1 : 3);
    }, [isClientTablet, isClientMobile]);

    const settings: SliderProps = {
      speed: 500,
      slidesToShow,
      slidesToScroll,
      touchMove: true,
      swipe: true,
      dots: true,
      autoplay: true,
      infinite: true,
    };

    return (
      <Slider {...settings}>
        {subComponents?.map((subComponent) => (
          <CustomCardCTAWrapper
            {...buildTestIds("custom-card-cta-slider-wrapper")}
            key={`${subComponent}`}
          >
            <CustomCardCTASubComponent
              {...(subComponent as CardCTASubComponentDto)}
              positionComponent={positionComponent}
            />
          </CustomCardCTAWrapper>
        ))}
      </Slider>
    );
  }
);

CardCTASlider.displayName = "CardCTASlider";

export default CardCTASlider;
