import { theme } from "../themes";

export interface IconProps {
  width?: number | string;
  height?: number | string;
  // eslint-disable-next-line react/no-unused-prop-types
  codeMyCard?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  color?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  strokeWidth?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  click?: () => void;
  alt?: string;
}
export const PinLocation = ({
  alt = "localização",
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-labelledby={alt}
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4553 12.8674C11.8272 12.8674 12.9394 11.7552 12.9394 10.3833C12.9394 9.01143 11.8272 7.89929 10.4553 7.89929C9.08345 7.89929 7.97131 9.01143 7.97131 10.3833C7.97131 11.7552 9.08345 12.8674 10.4553 12.8674Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8915 10.9235C18.1106 7.49614 15.9599 4.3625 12.683 3.33451C9.40605 2.30653 5.85084 3.65023 4.07302 6.58868C2.29519 9.52713 2.75483 13.2999 5.18616 15.7255L10.4554 21.0037"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.01541 10.9264C2.79494 7.50122 4.95851 4.36963 8.25512 3.34232C11.5517 2.315 15.1283 3.65783 16.9168 6.59436C18.7053 9.53088 17.9459 13.0759 15.5 15.5L10.4961 21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
