import { buildTestIds, useAppContext } from "application";
import { useEffect, useState } from "react";
import { MediaLink } from "typing";
import { ZoomImages } from "../ZoomImages";
import {
  ContainerImage,
  ImageProductContainer,
  ImageProductDefault,
  MainImage,
  SectionImages,
} from "./styles";

import { MediaPlayer } from "../patterns/MediaPlayer";
import { ListThumb } from "./ListThumb";
import { useMedia } from "./data";

interface ImagesProductProps {
  mediaLinks: MediaLink[];
}

const ImagesProduct = ({ mediaLinks }: ImagesProductProps) => {
  const [
    sequenceImageNumberCurrentActive,
    setSequenceImageNumberCurrentActive,
  ] = useState<number>(0);

  const [mediaCurrentSelect, setMediaCurrentSelect] = useState<MediaLink>();

  const { isClientMobile } = useAppContext();

  const { listImages, sortImages } = useMedia(
    setSequenceImageNumberCurrentActive
  );

  useEffect(() => {
    if (listImages?.medias?.length) {
      setMediaCurrentSelect(listImages?.medias[0]);
    }
  }, [listImages]);

  useEffect(() => {
    const hasImage = listImages?.images?.some((media) => {
      if (media.sequenceNumber === sequenceImageNumberCurrentActive) {
        setMediaCurrentSelect(media);
        return true;
      }
      return false;
    });

    if (!hasImage) {
      listImages?.medias?.some((media) => {
        if (media.sequenceNumber === sequenceImageNumberCurrentActive) {
          setMediaCurrentSelect(media);
          return true;
        }
        return false;
      });
    }
  }, [
    listImages?.images,
    listImages?.medias,
    sequenceImageNumberCurrentActive,
  ]);

  useEffect(() => {
    sortImages(mediaLinks);
  }, [mediaLinks, sortImages]);

  const NUMBER_INDEX_SELECTED = listImages?.zoomItems?.findIndex(
    (item) => item.sequenceNumber === mediaCurrentSelect?.sequenceNumber
  );

  const imageUrl = mediaCurrentSelect?.imageUrl;
  const videoUrl = mediaCurrentSelect?.videoUrl;
  const isVideo = mediaCurrentSelect?.linkType === "VIDEO" && videoUrl;

  return (
    <ImageProductContainer {...buildTestIds("media-product-container")}>
      {listImages?.thumb.length ? (
        <SectionImages {...buildTestIds("section-medias")}>
          <ListThumb
            thumbs={listImages?.thumb}
            sequenceImageNumberCurrentActive={sequenceImageNumberCurrentActive}
            setSequenceImageNumberCurrentActive={
              setSequenceImageNumberCurrentActive
            }
          />
        </SectionImages>
      ) : null}
      <MainImage isVideo={!!isVideo} {...buildTestIds("main-media-container")}>
        {mediaCurrentSelect?.linkType === "IMAGEM" && imageUrl ? (
          <ZoomImages
            {...buildTestIds("zoom-medias")}
            largeImage={imageUrl}
            moreImages={listImages?.zoomItems}
            indexImageCurrent={NUMBER_INDEX_SELECTED}
          />
        ) : isVideo ? (
          <MediaPlayer
            {...buildTestIds("video-media-player")}
            controls
            loop
            volume={0.5}
            url={videoUrl}
          />
        ) : (
          <ContainerImage {...buildTestIds("container-default-image")}>
            <ImageProductDefault
              alt="imagem padrão de produto"
              isClientMobile={isClientMobile}
            />
          </ContainerImage>
        )}
      </MainImage>
    </ImageProductContainer>
  );
};

export { ImagesProduct };
