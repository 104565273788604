import { useCallback, useEffect, useState } from "react";
import { Buckets, GiftListFacets } from "typing";
import { ArrowLeft, theme } from "ui";
import FacetsFilterList from "../FacetsFilterList";
import { BackButton, SidebarGiftFilter } from "./styles";

type SelectedFilters = {
  title: string;
  option: string;
};

interface GiftsListFilterSidebarProps {
  facets?: GiftListFacets;
  openMobile: boolean;
  isLoading: boolean;
  onApplyFilters: (
    selectedCategories: string[],
    selectedBrands: string[],
    selectedModels: string[]
  ) => void;
  handlerClick: () => void;
  handlerOpenFilterMobile: (openFilter: boolean) => void;
  // selectedFilters: (activeFilters: SelectedFilters[]) => void;
}

const GiftsListFilterSidebar = ({
  facets,
  openMobile,
  isLoading,
  onApplyFilters,
  handlerClick,
  handlerOpenFilterMobile,
}: // selectedFilters,
GiftsListFilterSidebarProps) => {
  const [filters, setFilters] = useState<object[]>([]);

  const [openMobileFilter, setOpenMobileFilter] = useState(openMobile);

  const [categoryFilterParams, setCategoryFilterParams] = useState<string[]>(
    []
  );
  const [brandFilterParams, setBrandFilterParams] = useState<string[]>([]);
  const [modelFilterParams, setModelFilterParams] = useState<string[]>([]);

  const [brandFilterTitle, setBrandFilterTitle] = useState<string>("");
  const [brandFilterOptions, setBrandFilterOptions] = useState<Buckets[]>([]);

  const [lastClickedFilter, setLastClickedFilter] = useState<string>("");

  useEffect(() => {
    if (facets && facets.aggregations && facets.aggregations.topk) {
      const mappedFilters = Object.entries(facets.aggregations.topk).map(
        (filter) => ({
          [filter[0]]: filter[1],
        }),
        [facets]
      );

      setFilters(mappedFilters);
    }
  }, [facets]);

  const [categoryFilterTitle, setCategoryFilterTitle] = useState<string>("");
  const [categoryFilterOptions, setCategoryFilterOptions] = useState<Buckets[]>(
    []
  );
  const [modelFilterTitle, setModelFilterTitle] = useState<string>("");
  const [modelFilterOptions, setModelFilterOptions] = useState<Buckets[]>([]);

  const [activeFilters, setActiveFilters] = useState<SelectedFilters[]>([]);

  // useEffect(() => {
  //   selectedFilters(activeFilters);
  // }, [activeFilters, selectedFilters]);

  useEffect(() => {
    const auxSelectedCategoryFilters = activeFilters.map((activeFilter) =>
      activeFilter.title === "Categoria" ? activeFilter.option : ""
    );

    const auxSelectedBrandFilters = activeFilters.map((activeFilter) =>
      activeFilter.title === "Marcas" ? activeFilter.option : ""
    );

    const auxSelectedModelFilters: string[] = activeFilters.map(
      (activeFilter) =>
        activeFilter.title === "Modelos" ? activeFilter.option : ""
    );

    setCategoryFilterParams(
      auxSelectedCategoryFilters.filter((element) => element !== "")
    );

    setBrandFilterParams(
      auxSelectedBrandFilters.filter((element) => element !== "")
    );

    setModelFilterParams(
      auxSelectedModelFilters.filter((element) => element !== "")
    );

    onApplyFilters(
      auxSelectedCategoryFilters.filter((element) => element !== ""),
      auxSelectedBrandFilters.filter((element) => element !== ""),
      auxSelectedModelFilters.filter((element) => element !== "")
    );
    // italo.albuquerque resolve problema de re-renders infinitos aqui e, posteriormente, remover o eslint-disable abaixo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const getBrandFacet = useCallback(() => {
    if (filters[1]) {
      Object.entries(filters[1]).map(([title, options]) => {
        setBrandFilterTitle(title);
        Object.entries(options).map(([key, value]) => {
          if (key === "buckets" && lastClickedFilter !== "Marcas") {
            setBrandFilterOptions(value as Buckets[]);
          }
          return null;
        });
        return null;
      });
    }
  }, [filters, lastClickedFilter]);

  const getCategoryFacet = useCallback(() => {
    if (filters[2]) {
      Object.entries(filters[2]).map(([title, options]) => {
        setCategoryFilterTitle(title);
        Object.entries(options).map(([key, value]) => {
          if (key === "buckets" && lastClickedFilter !== "Categoria") {
            setCategoryFilterOptions(value as Buckets[]);
          }
          return null;
        });
        return null;
      });
    }
  }, [filters, lastClickedFilter]);

  const getModelFacet = useCallback(() => {
    if (filters[4]) {
      Object.entries(filters[4]).map(([title, options]) => {
        setModelFilterTitle(title);
        Object.entries(options).map(([key, value]) => {
          if (key === "buckets" && lastClickedFilter !== "Modelos") {
            setModelFilterOptions(value as Buckets[]);
          }
          return null;
        });
        return null;
      });
    }
  }, [filters, lastClickedFilter]);

  // const handleOnApplyFilters = useCallback(() => {
  //   const selectedCategories = categoryFilterParams;
  //   const selectedBrands = brandFilterParams;
  //   const selectedModels = modelFilterParams;

  //   onApplyFilters(selectedCategories, selectedBrands, selectedModels);
  //   selectedFilters(activeFilters);
  // }, [
  //   brandFilterParams,
  //   categoryFilterParams,
  //   modelFilterParams,
  //   onApplyFilters,
  //   activeFilters,
  //   selectedFilters,
  // ]);

  useEffect(() => {
    getCategoryFacet();
    getBrandFacet();
    getModelFacet();
  }, [getCategoryFacet, getBrandFacet, getModelFacet]);

  useEffect(() => {
    setOpenMobileFilter(openMobile);
  }, [openMobile]);

  useEffect(() => {
    handlerOpenFilterMobile(openMobileFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMobileFilter]);

  // useEffect(() => {
  //   if (filters) {
  //     handleOnApplyFilters();
  //   }
  // }, [filters]);

  return (
    <SidebarGiftFilter
      openMobile={openMobileFilter}
      onClick={() => {
        handlerClick();
      }}
    >
      {openMobile && (
        <BackButton
          width="28px"
          height="28px"
          onClick={() => setOpenMobileFilter(!openMobileFilter)}
        >
          <ArrowLeft color={`${theme.colors.neutral[520]}`} />
        </BackButton>
      )}

      <>
        <FacetsFilterList
          isLoadingFacets={isLoading}
          hasFacets={!!facets}
          filterTitle={categoryFilterTitle}
          filterOptions={categoryFilterOptions}
          activeOptions={categoryFilterParams}
          handleTitleFilter={(clickedOptionTitle: string) => {
            if (clickedOptionTitle) {
              setLastClickedFilter(clickedOptionTitle);
            }
          }}
          handleOptionFilter={(clickedOption: string) => {
            const auxClickedOption = [...categoryFilterParams, clickedOption];
            const auxActiveFilters = [
              ...activeFilters,
              { title: "Categoria", option: clickedOption },
            ];

            // console.log(auxActiveFilters);

            if (!categoryFilterParams.length) {
              setCategoryFilterParams(auxClickedOption);
            }

            const newAuxClickedOption = categoryFilterParams.find(
              (activeItem) => activeItem === clickedOption
            );

            setCategoryFilterParams(
              auxClickedOption.filter(
                (option) => option !== newAuxClickedOption
              )
            );

            if (!activeFilters.length) {
              setActiveFilters(auxActiveFilters);
            }

            const newAuxActiveFilters = activeFilters.find(
              (activeItem) => activeItem.option === clickedOption
            );

            setActiveFilters(
              auxActiveFilters.filter(
                (selectedFilter) =>
                  selectedFilter.option !== newAuxActiveFilters?.option
              )
            );
          }}
        />

        <FacetsFilterList
          isLoadingFacets={isLoading}
          hasFacets={!!facets}
          filterTitle={brandFilterTitle}
          filterOptions={brandFilterOptions}
          activeOptions={brandFilterParams}
          handleTitleFilter={(clickedTitle) => {
            if (clickedTitle) {
              setLastClickedFilter(clickedTitle);
            }
          }}
          handleOptionFilter={(clickedOption) => {
            const auxClickedOption = [...brandFilterParams, clickedOption];
            const auxActiveFilters = [
              ...activeFilters,
              { title: "Marcas", option: clickedOption },
            ];

            if (!brandFilterParams.length) {
              setBrandFilterParams(auxClickedOption);
            }

            const newAuxClickedOption = brandFilterParams.find(
              (activeItem) => activeItem === clickedOption
            );

            setBrandFilterParams(
              auxClickedOption.filter(
                (option) => option !== newAuxClickedOption
              )
            );

            if (!activeFilters.length) {
              setActiveFilters(auxActiveFilters);
            }

            const newAuxActiveFilters = activeFilters.find(
              (activeItem) => activeItem.option === clickedOption
            );

            setActiveFilters(
              auxActiveFilters.filter(
                (selectedFilter) =>
                  selectedFilter.option !== newAuxActiveFilters?.option
              )
            );
          }}
        />

        <FacetsFilterList
          isLoadingFacets={isLoading}
          hasFacets={!!facets}
          filterTitle={modelFilterTitle}
          filterOptions={modelFilterOptions}
          activeOptions={modelFilterParams}
          handleTitleFilter={(clickedOptionTitle) => {
            if (clickedOptionTitle) {
              setLastClickedFilter(clickedOptionTitle);
            }
          }}
          handleOptionFilter={(clickedOption) => {
            const auxClickedOption = [...modelFilterParams, clickedOption];
            const auxActiveFilters = [
              ...activeFilters,
              { title: "Modelos", option: clickedOption },
            ];

            if (!modelFilterParams.length) {
              setModelFilterParams(auxClickedOption);
            }

            const newAuxClickedOption = modelFilterParams.find(
              (activeItem) => activeItem === clickedOption
            );

            setModelFilterParams(
              auxClickedOption.filter(
                (option) => option !== newAuxClickedOption
              )
            );

            if (!activeFilters.length) {
              setActiveFilters(auxActiveFilters);
            }

            const newAuxActiveFilters = activeFilters.find(
              (activeItem) => activeItem.option === clickedOption
            );

            setActiveFilters(
              auxActiveFilters.filter(
                (selectedFilter) =>
                  selectedFilter.option !== newAuxActiveFilters?.option
              )
            );
          }}
        />
      </>
    </SidebarGiftFilter>
  );
};

export { GiftsListFilterSidebar };
