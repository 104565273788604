import { FAQSubComponentProperty, Question, Text, Title } from "typing";

const customFAQSubComponentData = (properties: FAQSubComponentProperty[]) => {
  const question = (
    properties?.find(({ key }) => key === "question") as Question
  )?.value;

  const text = (properties?.find(({ key }) => key === "text") as Text)?.value;
  const title = (properties?.find(({ key }) => key === "question") as Title)
    ?.value;

  return {
    question,
    text,
    title,
  };
};

export { customFAQSubComponentData };
