import { IconProps } from "typing";
import { theme } from "../themes";

export const SpotifyIconFooter = ({
  alt = "Ícone do Spotify",
  width = 28,
  height = 28,
  color = theme.colors.neutral["150"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM15.5483 16.5C15.3833 16.5 15.3007 16.4175 15.1357 16.335C13.8982 15.5918 12.3307 15.18 10.6808 15.18C9.77325 15.18 8.78325 15.345 7.95825 15.51C7.79325 15.51 7.62825 15.5925 7.54575 15.5925C7.21575 15.5925 6.96825 15.345 6.96825 15.015C6.96825 14.685 7.13325 14.4375 7.46325 14.4375C8.53575 14.19 9.609 14.025 10.764 14.025C12.6615 14.025 14.394 14.52 15.879 15.345C16.044 15.51 16.209 15.5925 16.209 15.9225C16.0425 16.335 15.795 16.5 15.5483 16.5ZM16.455 14.1907C16.29 14.1907 16.125 14.1082 15.96 14.0257C14.5575 13.1182 12.5767 12.6232 10.4325 12.6232C9.36 12.6232 8.36925 12.7882 7.6275 12.9532C7.4625 13.0357 7.38 13.0357 7.215 13.0357C6.8025 13.0357 6.55425 12.7057 6.55425 12.375C6.55425 11.9625 6.71925 11.7975 7.13175 11.6317C8.12175 11.3842 9.1125 11.1367 10.515 11.1367C12.825 11.1367 14.97 11.7142 16.7857 12.7867C17.0332 12.9517 17.1982 13.1992 17.1982 13.4475C17.115 13.86 16.8682 14.1907 16.455 14.1907ZM17.5275 11.55C17.3625 11.55 17.28 11.4675 17.0325 11.385C15.3825 10.4775 12.99 9.9 10.68 9.9C9.525 9.9 8.2875 10.065 7.21425 10.3125C7.04925 10.3125 6.96675 10.395 6.71925 10.395C6.22425 10.4782 5.89425 10.065 5.89425 9.57075C5.89425 9.0765 6.14175 8.8275 6.47175 8.74575C7.7925 8.415 9.195 8.25 10.68 8.25C13.2375 8.25 15.8775 8.8275 17.9407 9.9C18.1882 10.065 18.4357 10.3125 18.4357 10.725C18.3532 11.22 18.0225 11.55 17.5275 11.55Z"
        fill={color}
      />
    </svg>
  );
};
