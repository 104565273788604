import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { BrowserChannelsNames } from "typing";
import { IBroadcastChannelClient } from "../../entities/BroadcastChannelClient";

interface BroadcastChannelContextProps {
  createChannel: (channelName: BrowserChannelsNames) => void;
  closeChannel: () => void;
  postMessage: (message: string) => void;
  onMessage: (callback: (message: string) => void) => void;
  getChannelName: () => string | null;
  isChannelOpen: boolean;
}

export const BroadcastChannelContext = createContext(
  {} as BroadcastChannelContextProps
);

export interface BroadcastChannelContextProviderProps {
  children: ReactNode;
  broadcastChannelClient: IBroadcastChannelClient;
  //   appMonitoringClient: IAppMonitoringClient;
}

export const BroadcastChannelProvider = ({
  children,
  broadcastChannelClient,
}: //   appMonitoringClient,
BroadcastChannelContextProviderProps) => {
  const createChannel = useCallback(
    (channelName: BrowserChannelsNames) => {
      broadcastChannelClient.createChannel(channelName);
    },
    [broadcastChannelClient]
  );

  const closeChannel = useCallback(() => {
    broadcastChannelClient.closeChannel();
  }, [broadcastChannelClient]);

  const postMessage = useCallback(
    (message: string) => {
      broadcastChannelClient.postMessage(message);
    },
    [broadcastChannelClient]
  );

  const onMessage = useCallback(
    (callback: (message: string) => void) => {
      broadcastChannelClient.onMessage(callback);
    },
    [broadcastChannelClient]
  );

  const getChannelName = useCallback(() => {
    return broadcastChannelClient.getChannelName();
  }, [broadcastChannelClient]);

  const isChannelOpen = useMemo(() => {
    return broadcastChannelClient.getIsChannelOpen();
  }, [broadcastChannelClient]);

  const contextValue = useMemo(
    () => ({
      createChannel,
      closeChannel,
      postMessage,
      onMessage,
      getChannelName,
      isChannelOpen,
    }),
    [
      createChannel,
      closeChannel,
      postMessage,
      onMessage,
      getChannelName,
      isChannelOpen,
    ]
  );

  return (
    <BroadcastChannelContext.Provider value={contextValue}>
      {children}
    </BroadcastChannelContext.Provider>
  );
};

export const useBrowserBroadcast = () => {
  const context = useContext(BroadcastChannelContext);

  if (!context) {
    throw new Error(
      "useBrowserBroadcast must be used within an BroadcastChannelContext"
    );
  }

  return context;
};
