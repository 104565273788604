import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const WarningValidateIcon = ({
  width = 14,
  height = 14,
  color = theme.colors.yellow["1050"],
  alt,
}: MultipleColorsIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      aria-label={alt}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25C12.7283 2.25 15.75 5.27175 15.75 9C15.75 12.7283 12.7283 15.75 9 15.75C5.27175 15.75 2.25 12.7283 2.25 9C2.25 5.27175 5.27175 2.25 9 2.25Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9.375V5.625"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99925 12C8.89575 12 8.81175 12.084 8.8125 12.1875C8.8125 12.291 8.8965 12.375 9 12.375C9.1035 12.375 9.1875 12.291 9.1875 12.1875C9.1875 12.084 9.1035 12 8.99925 12"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
