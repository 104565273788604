import { IconProps } from "typing";
import { theme } from "../themes";

export const GiftCardWeddingListHome = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["550"],
}: IconProps) => {
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 20"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9038 18H5.98077C3.79135 18 2 16.8 2 15.3333V4.66667C2 3.2 3.79135 2 5.98077 2H21.9038C24.0933 2 25.8846 3.2 25.8846 4.66667V15.3333C25.8846 16.8 24.0933 18 21.9038 18Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.27013 9.38171C9.27013 7.42171 10.6833 5.83337 12.4262 5.83337C13.1765 5.83337 13.7848 6.41921 13.7848 7.14171C13.7848 8.72004 11.7624 10 9.2684 10C6.77436 10 4.75195 8.72087 4.75195 7.14171C4.75195 6.41921 5.36032 5.83337 6.11147 5.83337C7.85436 5.83337 9.26753 7.42171 9.26753 9.38171"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.80762 13.2117C7.71839 13.2117 9.26742 11.47 9.26742 9.32166L9.27089 9.33332C9.27089 11.4817 10.8199 13.2233 12.7307 13.2233"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.26961 2.5V17.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8846 10H2"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>;
};
