import styled from "styled-components";
import { ArrowRight, theme } from "ui";

export const ListedCategoriesWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
  background-color: white;
`;

export const CategoriesTitle = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  color: ${theme.colors.neutral["520"]};
  text-decoration: none;
  background-color: ${theme.colors.neutral.white};
`;

export const SubCategoriesListWrapper = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  /* height: calc(100% - 190px); */
  padding-bottom: 20rem;
`;

export const ListedCategories = styled.div`
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-family: "Chivo", sans-serif;
  color: ${theme.colors.neutral["520"]};
  width: 100%;
  z-index: 9;
  height: 30px;
  transition: all 0.2s ease-in-out;
  padding: 0.5rem 1rem;
  /* margin: 0.5rem; */
  height: 2.5rem;
  background-color: ${theme.colors.neutral["white-050"]};
  border-bottom: 1px solid ${theme.colors.neutral["150"]};
`;

export const LinkCategories = styled.a`
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.125rem 0.5rem 0.125rem 0;
  color: ${theme.colors.neutral["500"]};
  background: ${theme.colors.neutral.white};
  gap: 1rem;
  width: 96%;
  height: 2.5rem;
  border-bottom: 1px solid ${theme.colors.neutral["150"]};
`;

export const ArrowRightIcon = styled(ArrowRight).attrs({
  width: "2.1rem",
  height: "2.1rem",
  color: theme.colors.neutral["400"],
})``;

export const LinkCategoriesIcon = styled(ArrowRight).attrs({
  color: theme.colors.neutral["500"],
  width: theme.space.x6,
  height: theme.space.x6,
})``;
