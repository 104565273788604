import { IAppMonitoringClient, IHttpClient } from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import {
  AddProductDto,
  AddProductResponseDto,
  BarCodeDto,
  CoupleDetailDto,
  CoupleNamesDto,
  CreateGiftsDto,
  CreateWeddingListDto,
  CreateWeddingListErrorType,
  EnvironmentAddDto,
  EnvironmentsDto,
  ErrorDto,
  Errors,
  GiftCardNumberDto,
  GiftMessageDto,
  GiftProducts,
  GiftProductsParams,
  GiftReportDto,
  GiftVoucherDto,
  HeaderDto,
  IHttpResponse,
  IdByListLinkDto,
  ImageTypeDto,
  LinkRecommendationPerson,
  PostMyListForEmailDto,
  SendMyListForEmailDto,
  StatementActivityHistoryDto,
  UpdateGiftDto,
  ValidateLinkDto,
  WeddingGiftsAndCredits,
  WeddingListDtoFull,
  WeddingListEditInformation,
} from "typing";
import { getApisUrlFallback, isObjectEmpty } from "../../../utils";
import { defaultHeaders } from "../../../utils/http";
import { EnvsApi } from "../Envs";

const applicationJsonStr = "application/json";

class WeddingGiftListApi implements IApi {
  baseUrl = "";

  constructor(
    private httpClient: IHttpClient,
    private appMonitoringClient: IAppMonitoringClient,
    private envsApi: EnvsApi
  ) {
    this.httpClient = httpClient;
    this.appMonitoringClient = appMonitoringClient;

    if (!this.baseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}weddinglist/v1`;
    }
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getWeddingListNameEndPoint = (name?: string) => {
    return `${this.baseUrl}?name=${name}`;
  };

  getWeddingListName = async (name?: string) => {
    try {
      // italo.albuquerque usar o httpClient (hook) e não o fetch (promise - async/await) diretamente
      const response = await fetch(this.getWeddingListNameEndPoint(name), {
        headers: { ...defaultHeaders },
      });

      return {
        data: (await response.json()) as CoupleDetailDto,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);

      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  getWeddingListFilterEndPoint = (
    name: string,
    startDate: string,
    endDate: string,
    pageIndex: number,
    pageSize: number,
    status: string
  ) => {
    return `${this.baseUrl}?name=${name}&status=${status}&startDate=${startDate}&endDate=${endDate}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
  };

  getWeddingListFilter = (
    name: string,
    startDate: string,
    endDate: string,
    pageIndex: number,
    pageSize: number,
    status: string,
    condition = true
  ) => {
    try {
      const { data, error, isLoading, trigger } = this.httpClient.useGet<
        CoupleDetailDto[]
      >(
        this.getWeddingListFilterEndPoint(
          name,
          startDate,
          endDate,
          pageIndex,
          pageSize,
          status
        ),
        {},
        condition
      ) as IHttpResponse<CoupleDetailDto[], unknown>;

      return {
        data,
        pagination: data ? data[0]?.headers : undefined,
        error,
        isLoading,
        trigger,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  getLinkRecommendationEndPoint = (
    primaryHostName: string,
    secondaryHostName: string
  ) => {
    return `${this.baseUrl}/list-link-recommendation?primaryHostName=${primaryHostName}&secondaryHostName=${secondaryHostName}`;
  };

  getLinkRecommendation = (
    accessToken: string | undefined,
    primaryHostName: string,
    secondaryHostName: string
  ): IHttpResponse<LinkRecommendationPerson, unknown> => {
    const { data, error, isLoading, trigger } =
      this.httpClient.useGet<LinkRecommendationPerson>(
        this.getLinkRecommendationEndPoint(
          primaryHostName || "",
          secondaryHostName || ""
        ),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        primaryHostName?.length > 0 && secondaryHostName?.length > 0
      ) as IHttpResponse<LinkRecommendationPerson, unknown>;

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  getWeddingListEndpoint = (listId?: number) => {
    return `${this.baseUrl}/${listId || "user"}?originSystem=Site`;
  };

  getWeddingList = (
    accessToken: string | undefined,
    listId: number | undefined = undefined,
    condition = true
  ) => {
    if (process?.browser) {
      const { data, error, isLoading, trigger } =
        this.httpClient.useGet<CoupleNamesDto>(
          this.getWeddingListEndpoint(listId),
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
          condition
        ) as IHttpResponse<CoupleNamesDto, unknown>;

      return {
        data,
        isLoading,
        error,
        trigger,
      };
    }

    return fetch(this.getWeddingListEndpoint(listId), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({
            data,
            isLoading: false,
            error: null,
          }))
          .catch((error) => {
            this.appMonitoringClient.captureException(error);
            return { data: null, isLoading: false, error };
          })
      )
      .catch((error) => {
        this.appMonitoringClient.captureException(error);
        return { data: null, isLoading: false, error };
      });
  };

  getCardNumberEndpoint = (listId?: number) => {
    return `${this.baseUrl}/user/giftcardNumber/${listId}`;
  };

  getCardNumber = (
    accessToken?: string,
    listId?: number,
    condition = false
  ): IHttpResponse<GiftCardNumberDto, unknown> => {
    const { data, error, isLoading, trigger } =
      this.httpClient.useGet<GiftCardNumberDto>(
        this.getCardNumberEndpoint(listId),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        !!condition
      ) as unknown as IHttpResponse<GiftCardNumberDto, unknown>;

    return {
      data,
      error,
      isLoading,
      trigger,
    };
  };

  getGiftAllMessagesEndpoint = (
    listId: number | undefined,
    pageSize: number,
    currentPage: number
  ) => {
    if (pageSize) {
      return `${this.baseUrl}/messages/all-messages?listId=${listId}&pageIndex=${currentPage}&pageSize=${pageSize}`;
    }
    return `${this.baseUrl}/messages/all-messages?listId=${listId}`;
  };

  getGiftAllMessages = (
    accessToken: string | undefined,
    listId: number | undefined,
    pageSize?: number,
    currentPage?: number,
    condition = true
  ): IHttpResponse<GiftMessageDto[], unknown> => {
    try {
      const { data, error, isLoading, trigger } = this.httpClient.useGet<
        GiftMessageDto[]
      >(
        this.getGiftAllMessagesEndpoint(
          listId,
          pageSize as number,
          currentPage as number
        ),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        condition
      ) as unknown as IHttpResponse<GiftMessageDto[], unknown>;
      return {
        data,
        error,
        isLoading,
        trigger,
      };
    } catch (err) {
      this.appMonitoringClient.captureException(err);
      return {
        data: null,
        isLoading: false,
        error: err,
      };
    }
  };

  deleteGiftMessageEndpoint = (orderId: number) => {
    return `${this.baseUrl}/messages/change-status?orderId=${orderId}`;
  };

  deleteGiftMessage = (orderId: number, jwt: string) => {
    try {
      return this.httpClient.usePut(
        this.deleteGiftMessageEndpoint(orderId),
        {
          Authorization: `Bearer ${jwt}`,
        },
        {}
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
    // getWeddingListsEndpoint;
  };

  getGiftsReportEndpoint = (listId: number | string | undefined) => {
    return `${this.baseUrl}/giftorders?ListId=${listId}&Status=All&PageSize=160`;
  };

  getGiftsReport = (
    accessToken: string | undefined,
    listId: string | number | undefined
  ): IHttpResponse<GiftReportDto[], unknown> => {
    const { data, isLoading, error, trigger } = this.httpClient.useGet<
      GiftReportDto[]
    >(
      this.getGiftsReportEndpoint(listId),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      !!listId
    ) as IHttpResponse<GiftReportDto[], unknown>;

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  private addProductsToWeddingListEndpoint = () => {
    return `${this.baseUrl}/products`;
  };

  addProductsToWeddingList = async (
    accessToken: string | undefined,
    listProducts: AddProductDto[]
  ): Promise<IHttpResponse<AddProductResponseDto, unknown>> => {
    return (await this.httpClient.usePost<AddProductResponseDto>(
      this.addProductsToWeddingListEndpoint(),
      listProducts as unknown as BodyInit,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )) as unknown as Promise<IHttpResponse<AddProductResponseDto, unknown>>;
  };

  closeList = async (jwt: string, listId: number) => {
    try {
      return (await this.httpClient.usePut<Errors>(
        `${this.baseUrl}/close?listId=${listId}`,
        {
          Authorization: `Bearer ${jwt}`,
        },
        listId as unknown as BodyInit
      )) as Promise<IHttpResponse<string, Errors>>;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  getGiftsAndCredits = (
    accessToken: string | undefined,
    listId: string | number | undefined,
    condition?: boolean
  ): IHttpResponse<WeddingGiftsAndCredits, unknown> => {
    const { data, error, isLoading, trigger } =
      this.httpClient.useGet<WeddingGiftsAndCredits>(
        `${this.baseUrl}/giftcards/wallet/${listId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        condition
      ) as IHttpResponse<WeddingGiftsAndCredits, unknown>;

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  deleteListEndpoint = (listId: number) => {
    return `${this.baseUrl}/delete?listId=${listId}`;
  };

  deleteList = async (jwt: string, listId: number) => {
    try {
      return await this.httpClient.useDelete<ErrorDto[]>(
        this.deleteListEndpoint(listId),
        {
          Authorization: `Bearer ${jwt}`,
        }
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  verifyUserEndpoint = (userEmail: string) => {
    return `${this.baseUrl}/verify-user?userEmail=${userEmail}`;
  };

  verifyUser = (
    jwt: string,
    userEmail: string
  ): IHttpResponse<WeddingListDtoFull, unknown> => {
    const { data, isLoading, error, trigger } =
      this.httpClient.useGet<WeddingListDtoFull>(
        this.verifyUserEndpoint(userEmail),
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      ) as IHttpResponse<WeddingListDtoFull, unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  private getGiftsVoucherEndpoint = () => {
    return `${this.baseUrl}/gift-voucher`;
  };

  getGiftsVoucher = (
    jwt: string,
    condition = false
  ): IHttpResponse<GiftVoucherDto[], unknown> => {
    const { data, isLoading, error, trigger } = this.httpClient.useGet<
      GiftVoucherDto[]
    >(
      this.getGiftsVoucherEndpoint(),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition
    ) as IHttpResponse<GiftVoucherDto[], unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  getStatementActivityHistory = (
    jwt: string,
    listId: number | undefined,
    pageSize: number,
    pageIndex: number,
    typeOperation: string,
    condition = false
  ): IHttpResponse<StatementActivityHistoryDto[], unknown> => {
    const { data, trigger, error, isLoading } = this.httpClient.useGet<
      StatementActivityHistoryDto[]
    >(
      `${this.baseUrl}/giftcards/statement?ListId=${listId}&PageIndex=${pageIndex}&PageSize=${pageSize}&TypeOperation=${typeOperation}&FrontOffice=Yes`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition
    ) as IHttpResponse<StatementActivityHistoryDto[], unknown>;

    return {
      data,
      isLoading,
      error,
      pagination: data ? data[0]?.headers : undefined,
      trigger,
    };
  };

  private updateGiftsEndpoint = (listId: number | undefined) => {
    return `${this.baseUrl}/products?listId=${listId}`;
  };

  updateGifts = async (
    listId: number | undefined,
    body: UpdateGiftDto[] | undefined,
    jwt: string
  ) => {
    try {
      return (await this.httpClient.usePut<IHttpResponse<string, unknown>>(
        this.updateGiftsEndpoint(listId),
        {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": `${applicationJsonStr}-patch+json`,
        },
        body as unknown as BodyInit
      )) as unknown as Promise<IHttpResponse<ErrorDto, unknown>>;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  private createGiftEndpoint = () => {
    return `${this.baseUrl}/products`;
  };

  createGift = async (body: CreateGiftsDto[], jwt: string) => {
    try {
      return await this.httpClient.usePost<CreateGiftsDto[]>(
        this.createGiftEndpoint(),
        body as unknown as BodyInit,
        {
          headers: {
            accept: applicationJsonStr,
            Authorization: `Bearer ${jwt}`,
            "Content-Type": `${applicationJsonStr}-patch+json`,
          },
        }
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  private giftsReceivedEndpoint = (listId: number) => {
    return `${this.baseUrl}/giftorders/pdf-gifts-received?listId=${listId}`;
  };

  giftsReceived = async (jwt: string, listId: number) => {
    try {
      const response = await fetch(this.giftsReceivedEndpoint(listId), {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        method: "POST",
      });
      const data = await response.json();

      return data as string;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  private getEnvironmentsEndpoint = (ListId: number | undefined) => {
    return `${this.baseUrl}/environments?listId=${ListId}`;
  };

  getEnvironments = (
    // accessToken: string,
    listId: number | undefined,
    condition = true
  ) => {
    const { data, isLoading, error, trigger } =
      this.httpClient.useGet<EnvironmentsDto>(
        this.getEnvironmentsEndpoint(listId),
        {},
        condition
      ) as unknown as IHttpResponse<EnvironmentsDto, unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  getEnvironmentsByListLink = (
    listLink: string | undefined,
    condition = true
  ) => {
    try {
      const { data, isLoading, error, trigger } =
        this.httpClient.useGet<EnvironmentsDto>(
          this.getEnvironmentsByListLinkEndpoint(listLink),
          {},
          condition
        ) as unknown as IHttpResponse<EnvironmentsDto, unknown>;
      return {
        data,
        isLoading,
        error,
        trigger,
      };
    } catch (err) {
      this.appMonitoringClient.captureException(err);
      return {
        data: null,
        isLoading: false,
        error: err,
      };
    }
  };

  getEnvironmentsByListLinkEndpoint = (listLink: string | undefined) => {
    return `${this.baseUrl}/environments?${listLink}`;
  };

  getFilteredGiftsEndpoint = (
    ListId: number | undefined,
    category?: string,
    brand?: string,
    model?: string
  ) => {
    return `${this.baseUrl}/environments?listId=${ListId}&Category=${category}&Brand=${brand}&Model=${model}`;
  };

  getFilteredGifts = (
    // accessToken: string,
    listId: number | undefined,
    category?: string | undefined,
    brand?: string | undefined,
    model?: string | undefined,
    condition = true
  ) => {
    const { data, isLoading, error, trigger } =
      this.httpClient.useGet<EnvironmentsDto>(
        this.getFilteredGiftsEndpoint(listId, category, brand, model),
        {},
        condition
      ) as unknown as IHttpResponse<EnvironmentsDto, unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  getWeddingListsEndpoint() {
    return `${this.baseUrl}/wedding-lists`;
  }

  getWeddingLists = (
    jwt: string,
    condition = true
  ): IHttpResponse<WeddingListDtoFull[], unknown> => {
    const { data, isLoading, error, trigger } = this.httpClient.useGet<
      WeddingListDtoFull[]
    >(
      this.getWeddingListsEndpoint(),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition
    ) as IHttpResponse<WeddingListDtoFull[], unknown>;

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  sendMyListForEmailEndpoint(listId: number) {
    return `${this.baseUrl}/share/${listId}`;
  }

  private defaultError = "Ocorreu um erro inesperado, tente novamente";

  private sendMyListMappedErrors = (status: string) => {
    return (
      {
        "400": "Você precisa preencher os campos obrigatórios.",
        "500": this.defaultError,
      }[`${status}`] || this.defaultError
    );
  };

  sendMyListForEmail = async ({
    accessToken,
    listId,
    emails,
    message,
  }: SendMyListForEmailDto) => {
    try {
      const response = (await this.httpClient.usePost<PostMyListForEmailDto>(
        `${this.sendMyListForEmailEndpoint(listId)}`,
        {
          emails,
          message,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )) as { status?: number };

      const responseStatus = response?.status?.toString();

      if (responseStatus) {
        const error = this.sendMyListMappedErrors(responseStatus);

        return {
          data: null,
          error,
        };
      }

      return { data: response, error: null };
    } catch (err) {
      const error = isObjectEmpty(err as { [key: string]: string })
        ? null
        : JSON.stringify(err);
      this.appMonitoringClient.captureException(error ?? this.defaultError);
      return { data: null, error };
    }
  };

  createWeddingListEndPoint = () => {
    return `${this.baseUrl}/`;
  };

  createWeddingList = async (body: CreateWeddingListDto, jwt: string) => {
    try {
      return (await this.httpClient.usePost<
        CreateWeddingListDto,
        CreateWeddingListErrorType
      >(this.createWeddingListEndPoint(), body as unknown as BodyInit, {
        headers: {
          "Content-Type": applicationJsonStr,
          Authorization: `Bearer ${jwt}`,
        },
      })) as unknown as Promise<
        CreateWeddingListDto | CreateWeddingListErrorType
      >;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      const errorObj = {
        error,
      };
      throw new Error(`Erro ao criar lista de casamento: ${errorObj}`);
    }
  };

  environmentsEndpoint = () => {
    return `${this.baseUrl}/environments`;
  };

  createEnvironment = async (body: EnvironmentAddDto, jwt: string) => {
    try {
      return await this.httpClient.usePost<EnvironmentAddDto>(
        this.environmentsEndpoint(),

        body as unknown as BodyInit,

        {
          headers: {
            "Content-Type": applicationJsonStr,

            Authorization: `Bearer ${jwt}`,
          },
        }
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);

      return error;
    }
  };

  renameEnvironment = async (body: EnvironmentAddDto, jwt: string) => {
    try {
      return await this.httpClient.usePut<EnvironmentAddDto>(
        `${this.environmentsEndpoint()}/rename/${body.id}`,
        {
          "Content-Type": applicationJsonStr,
          Authorization: `Bearer ${jwt}`,
        },
        body.name as unknown as BodyInit
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  getBarCodeEndpoint = (listId: number | undefined) => {
    return `${this.baseUrl}/giftcards/${listId}`;
  };

  getBarCode = (
    jwt: string,
    listId: number | undefined,
    condition = true
  ): IHttpResponse<BarCodeDto, unknown> => {
    const { data, error, isLoading, trigger } =
      this.httpClient.useGet<BarCodeDto>(
        this.getBarCodeEndpoint(listId),
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
        condition
      ) as unknown as IHttpResponse<BarCodeDto, unknown>;

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  getIdByListLinkEndpoint = (listLink: string) => {
    return `${this.baseUrl}/link/${listLink}`;
  };

  getIdByListLink = (listLink: string, condition = false) => {
    if (process?.browser) {
      const { data, error, isLoading, trigger } =
        this.httpClient.useGet<IdByListLinkDto>(
          this.getIdByListLinkEndpoint(listLink),
          undefined,
          condition
        ) as unknown as IHttpResponse<IdByListLinkDto, unknown>;

      return {
        data,
        isLoading,
        error,
        trigger,
      };
    }

    return fetch(this.getIdByListLinkEndpoint(listLink))
      .then((response) =>
        response
          .json()
          .then((data) => ({
            data,
            isLoading: false,
            error: null,
          }))
          .catch((error) => {
            this.appMonitoringClient.captureException(error);
            return { data: null, isLoading: false, error };
          })
      )
      .catch((error) => {
        this.appMonitoringClient.captureException(error);
        return { data: null, isLoading: false, error };
      });
  };

  getValidateLinkEndPoint = (link: string) => {
    return `${this.baseUrl}/validate-link/${link}`;
  };

  getValidateLink = (
    jwt: string,
    link: string,
    condition = false
  ): IHttpResponse<ValidateLinkDto, unknown> => {
    try {
      const { data, error, isLoading } =
        this.httpClient.useGet<ValidateLinkDto>(
          this.getValidateLinkEndPoint(link),
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          },
          condition
        ) as unknown as IHttpResponse<ValidateLinkDto, unknown>;
      this.appMonitoringClient.captureException(error);
      return {
        data,
        isLoading,
        error,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  editInformationEndpoint = () => {
    return `${this.baseUrl}/`;
  };

  editInformation = async (body: WeddingListEditInformation, jwt: string) => {
    try {
      return await this.httpClient.usePut<WeddingListEditInformation>(
        this.editInformationEndpoint(),
        {
          Authorization: `Bearer ${jwt}`,
        },
        body as unknown as BodyInit
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  headerInfosEndpoint = (listId?: number) => {
    return `${this.baseUrl}/header/${listId}`;
  };

  fetchHeaderInfos = (listId: number | undefined, condition = false) => {
    const { data, isLoading, error, trigger } =
      this.httpClient.useGet<HeaderDto>(
        this.headerInfosEndpoint(listId),
        {},
        condition
      ) as IHttpResponse<HeaderDto, unknown>;

    return { data, isLoading, error, trigger };
  };

  deleteEnvironment = async (id: number | null | undefined, jwt: string) => {
    try {
      return await this.httpClient.useDelete<number>(
        `${this.environmentsEndpoint()}/${id}`,
        {
          Authorization: `Bearer ${jwt}`,
        }
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  private updateImageEndpoint = (listId: number, imageType: ImageTypeDto) => {
    return `${this.baseUrl}/images/${listId}?imageType=${imageType}`;
  };

  updateImage = async (
    listId: number,
    imageType: ImageTypeDto,
    updateImage: File,
    accessToken: string
  ) => {
    const formData = new FormData();
    formData.append("file", updateImage);

    try {
      return await this.httpClient.usePut(
        this.updateImageEndpoint(listId, imageType),
        {
          Authorization: `Bearer ${accessToken}`,
        },
        formData,
        "file"
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error;
    }
  };

  private getGiftProductEndpoint = (
    giftProductsParams: GiftProductsParams[]
  ) => {
    return `${this.baseUrl}/products?getProductsDTO=${JSON.stringify(
      giftProductsParams
    )}`;
  };

  getGiftProduct = (
    giftProductsParams: GiftProductsParams[],
    jwt: string | undefined,
    condition = true
  ) => {
    const { data, error, isLoading } = this.httpClient.useGet<GiftProducts[]>(
      this.getGiftProductEndpoint(giftProductsParams),
      jwt
        ? {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        : {},
      condition
    ) as IHttpResponse<GiftProducts[], unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };
}

export { WeddingGiftListApi };
