export const getAriaLabel = (iconTitle: string) => {
  const socialMediaLabels = {
    instagram: "Link do Instagram",
    facebook: "Link do Facebook",
    twitter: "Link do Twitter",
    linkedin: "Link do LinkedIn",
    pinterest: "Link do Pinterest",
    spotify: "Link do Spotify",
    youtube: "Link do YouTube",
  };

  return (
    socialMediaLabels[iconTitle as keyof typeof socialMediaLabels] ||
    "Link das redes sociais"
  );
};
