import { IconProps } from "typing";

const DeviceCheckError = ({
  alt = "Erro na confirmação de e-mail ícone",
  width = 101,
  height = 100,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50.5" cy="50" r="50" fill="#F6F7F7" />
      <path
        d="M75.1351 63.3116L78.6193 57.2706"
        stroke="#B2B3B5"
        strokeWidth="0.869069"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.0051 62.0832L75.135 63.3116"
        stroke="#B2B3B5"
        strokeWidth="0.869069"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.4172 59.6351L76.5471 60.8635"
        stroke="#B2B3B5"
        strokeWidth="0.869069"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.7987 57.5566C79.2667 57.9679 77.6913 57.0593 77.2801 55.5273C76.8689 53.9952 77.7774 52.4199 79.3095 52.0087C80.8415 51.5974 82.4168 52.506 82.828 54.038C83.2393 55.5701 82.3307 57.1454 80.7987 57.5566"
        stroke="#B2B3B5"
        strokeWidth="0.869069"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.9912 64.8521L92.3544 61.5644"
        stroke="#C3C4C5"
        strokeWidth="0.525209"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.832 65.7818L94.9912 64.8521"
        stroke="#C3C4C5"
        strokeWidth="0.525209"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.7634 64.4494L93.9226 63.5197"
        stroke="#C3C4C5"
        strokeWidth="0.525209"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.9938 60.3999C92.8891 61.3528 92.0318 62.0404 91.0789 61.9357C90.126 61.8309 89.4384 60.9736 89.5431 60.0207C89.6478 59.0678 90.5052 58.3802 91.4581 58.4849C92.411 58.5896 93.0986 59.447 92.9938 60.3999"
        stroke="#C3C4C5"
        strokeWidth="0.525209"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.5514 32.9733L67.877 27.7981"
        stroke="#C3C4C5"
        strokeWidth="0.869069"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.7268 34.6215L72.5515 32.9733"
        stroke="#C3C4C5"
        strokeWidth="0.869069"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.8325 32.5241L70.6572 30.876"
        stroke="#C3C4C5"
        strokeWidth="0.869069"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.8204 25.8126C68.7399 27.3968 67.3903 28.6157 65.8061 28.5352C64.2219 28.4546 63.003 27.1051 63.0835 25.5209C63.164 23.9367 64.5136 22.7177 66.0978 22.7983C67.682 22.8788 68.901 24.2284 68.8204 25.8126"
        stroke="#C3C4C5"
        strokeWidth="0.869069"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4131 60.6641L24.2986 55.6612"
        stroke="#B2B3B5"
        strokeWidth="0.71972"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6492 59.6467L21.4132 60.6641"
        stroke="#B2B3B5"
        strokeWidth="0.71972"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8184 57.6193L22.5823 58.6366"
        stroke="#B2B3B5"
        strokeWidth="0.71972"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1034 55.8981C24.8347 56.2387 23.5301 55.4862 23.1895 54.2175C22.8489 52.9487 23.6014 51.6441 24.8701 51.3036C26.1389 50.963 27.4435 51.7154 27.784 52.9842C28.1246 54.2529 27.3722 55.5575 26.1034 55.8981"
        stroke="#B2B3B5"
        strokeWidth="0.71972"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.8036 29.5397L85.559 26.4963"
        stroke="#C3C4C5"
        strokeWidth="0.437834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.7306 28.9208L83.8037 29.5397"
        stroke="#C3C4C5"
        strokeWidth="0.437834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.4418 27.6874L84.5148 28.3063"
        stroke="#C3C4C5"
        strokeWidth="0.437834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.657 26.6403C85.8852 26.8475 85.0916 26.3898 84.8844 25.6179C84.6772 24.8461 85.135 24.0525 85.9068 23.8453C86.6786 23.6381 87.4723 24.0958 87.6794 24.8677C87.8866 25.6395 87.4289 26.4331 86.657 26.6403"
        stroke="#C3C4C5"
        strokeWidth="0.437834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.3849 15.5862L61.1403 12.5428"
        stroke="#C3C4C5"
        strokeWidth="0.437834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.3119 14.9673L59.385 15.5862"
        stroke="#C3C4C5"
        strokeWidth="0.437834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.0231 13.7339L60.0961 14.3528"
        stroke="#C3C4C5"
        strokeWidth="0.437834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.2383 12.6868C61.4665 12.894 60.6729 12.4363 60.4657 11.6644C60.2585 10.8926 60.7163 10.099 61.4881 9.89179C62.2599 9.68461 63.0536 10.1423 63.2607 10.9142C63.4679 11.686 63.0102 12.4796 62.2383 12.6868"
        stroke="#C3C4C5"
        strokeWidth="0.437834"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0813 21.4115L32.7354 15.0757"
        stroke="#B2B3B5"
        strokeWidth="0.911474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8474 20.1231L29.0812 21.4115"
        stroke="#B2B3B5"
        strokeWidth="0.911474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3283 17.5554L30.5622 18.8438"
        stroke="#B2B3B5"
        strokeWidth="0.911474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0213 15.3757C33.4145 15.807 31.7623 14.8541 31.331 13.2473C30.8997 11.6405 31.8526 9.98834 33.4594 9.55704C35.0662 9.12574 36.7184 10.0786 37.1497 11.6854C37.581 13.2922 36.6281 14.9444 35.0213 15.3757"
        stroke="#B2B3B5"
        strokeWidth="0.911474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5105 37.3622L16.3726 38.7783C15.8048 38.9347 15.2183 38.6016 15.0618 38.0339L14.0706 34.4374C13.9141 33.8696 14.2472 33.2831 14.8149 33.1266L19.9528 31.7105C20.5206 31.5541 21.1071 31.8872 21.2636 32.4549L22.2548 36.0514C22.4113 36.6192 22.0782 37.2057 21.5105 37.3622Z"
        stroke="#C3C4C5"
        strokeWidth="0.655523"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3289 32.985L14.9041 31.4436V31.4436C14.5913 30.3086 15.2578 29.1348 16.3928 28.822V28.822C17.5278 28.5092 18.7016 29.1758 19.0144 30.3107V30.3107L19.4392 31.8521"
        stroke="#C3C4C5"
        strokeWidth="0.655523"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.8399 79.5001L76.546 76.3433C76.0715 75.9945 75.9697 75.3277 76.3186 74.8532L78.5283 71.8474C78.8771 71.373 79.544 71.2712 80.0185 71.62L84.3124 74.7768C84.7869 75.1256 84.8886 75.7924 84.5398 76.2669L82.3301 79.2727C81.9813 79.7472 81.3144 79.8489 80.8399 79.5001Z"
        stroke="#C3C4C5"
        strokeWidth="0.655523"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.4477 71.9357L81.3948 70.6475V70.6475C82.0921 69.699 83.4265 69.4953 84.375 70.1926V70.1926C85.3236 70.89 85.5272 72.2244 84.8299 73.1729V73.1729L83.8829 74.4611"
        stroke="#C3C4C5"
        strokeWidth="0.655523"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.2778 45.5017L86.1399 46.9178C85.5722 47.0743 84.9856 46.7412 84.8291 46.1734L83.8379 42.5769C83.6814 42.0092 84.0145 41.4226 84.5823 41.2661L89.7202 39.8501C90.2879 39.6936 90.8745 40.0267 91.0309 40.5944L92.0222 44.191C92.1786 44.7587 91.8455 45.3453 91.2778 45.5017Z"
        stroke="#B2B3B5"
        strokeWidth="0.655523"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.0962 41.1245L84.6714 39.5831V39.5831C84.3586 38.4482 85.0252 37.2743 86.1601 36.9615V36.9615C87.2951 36.6487 88.4689 37.3153 88.7817 38.4503V38.4503L89.2065 39.9916"
        stroke="#B2B3B5"
        strokeWidth="0.655523"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7186 45.8676L75.4147 48.3247C75.0496 48.5963 74.534 48.5205 74.2625 48.1554L72.5425 45.8427C72.2709 45.4776 72.3467 44.962 72.7118 44.6904L76.0157 42.2333C76.3808 41.9618 76.8964 42.0375 77.1679 42.4026L78.888 44.7154C79.1595 45.0804 79.0837 45.5961 78.7186 45.8676Z"
        stroke="#C3C4C5"
        strokeWidth="0.506449"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.0423 44.4448L72.3051 43.4536V43.4536C71.7623 42.7237 71.914 41.6919 72.6438 41.1491V41.1491C73.3737 40.6064 74.4055 40.758 74.9483 41.4878V41.4878L75.6854 42.479"
        stroke="#C3C4C5"
        strokeWidth="0.506449"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92095 53.1352L5.52376 52.0675C5.14837 51.9495 4.93988 51.5499 5.05786 51.1745L5.80526 48.7965C5.92324 48.4211 6.32285 48.2126 6.69824 48.3306L10.0954 49.3983C10.4708 49.5163 10.6793 49.9159 10.5613 50.2913L9.81394 52.6693C9.69595 53.0447 9.29634 53.2532 8.92095 53.1352Z"
        stroke="#B2B3B5"
        strokeWidth="0.438007"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.038 48.4374L7.35832 47.4182V47.4182C7.59417 46.6678 8.39384 46.2506 9.14428 46.4864V46.4864C9.89472 46.7223 10.3119 47.5219 10.0761 48.2724V48.2724L9.75576 49.2915"
        stroke="#B2B3B5"
        strokeWidth="0.438007"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4349 44.5325L27.8692 45.6359C27.5857 45.7578 27.2573 45.6269 27.1354 45.3434L26.363 43.5474C26.2411 43.2639 26.372 42.9355 26.6555 42.8136L29.2212 41.7102C29.5047 41.5882 29.8331 41.7191 29.955 42.0026L30.7274 43.7987C30.8493 44.0822 30.7185 44.4106 30.4349 44.5325Z"
        stroke="#C3C4C5"
        strokeWidth="0.343531"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.912 42.7032L26.581 41.9335V41.9335C26.3373 41.3667 26.5992 40.7096 27.1659 40.4659V40.4659C27.7327 40.2221 28.3899 40.484 28.6336 41.0508V41.0508L28.9646 41.8205"
        stroke="#C3C4C5"
        strokeWidth="0.343531"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.964 19.1785L68.67 17.2148C68.1955 16.9979 67.9869 16.4378 68.2039 15.9633L69.5785 12.9575C69.7954 12.483 70.3555 12.2744 70.83 12.4914L75.124 14.4551C75.5985 14.6721 75.8071 15.2321 75.5901 15.7066L74.2155 18.7124C73.9986 19.1869 73.4385 19.3955 72.964 19.1785Z"
        stroke="#B2B3B5"
        strokeWidth="0.58077"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.2595 12.6878L71.8486 11.3996V11.3996C72.2823 10.4511 73.4031 10.0337 74.3516 10.4675V10.4675C75.3002 10.9012 75.7176 12.022 75.2838 12.9705V12.9705L74.6947 14.2587"
        stroke="#B2B3B5"
        strokeWidth="0.58077"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6731 78.0599L17.5352 79.4759C16.9674 79.6324 16.3809 79.2993 16.2244 78.7315L15.2332 75.135C15.0767 74.5673 15.4098 73.9807 15.9775 73.8242L21.1154 72.4082C21.6832 72.2517 22.2697 72.5848 22.4262 73.1525L23.4174 76.7491C23.5739 77.3168 23.2408 77.9034 22.6731 78.0599Z"
        stroke="#B2B3B5"
        strokeWidth="0.655523"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4915 73.6826L16.0667 72.1412V72.1412C15.7539 71.0063 16.4204 69.8324 17.5554 69.5196V69.5196C18.6904 69.2068 19.8642 69.8734 20.177 71.0084V71.0084L20.6018 72.5497"
        stroke="#B2B3B5"
        strokeWidth="0.655523"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="49.9187"
        cy="87.3256"
        rx="19.186"
        ry="1.27907"
        fill="#C3C4C5"
      />
      <path
        d="M45.8486 20.9619V18.0549C45.8486 15.9144 47.5841 14.179 49.7246 14.179C51.8651 14.179 53.6006 15.9144 53.6006 18.0549V20.9619"
        stroke="#53565A"
        strokeWidth="1.19186"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="33.3285"
        y="21.2007"
        width="33.1802"
        height="59.9244"
        rx="1.89261"
        fill="#53565A"
        stroke="#53565A"
        strokeWidth="0.540746"
      />
      <rect
        x="34.4581"
        y="23.4933"
        width="28.5949"
        height="54.1763"
        rx="1.66191"
        stroke="#7AC143"
        strokeWidth="0.474833"
      />
      <path
        d="M44.686 23.2559H53.1256C52.6084 24.2903 51.5511 24.9438 50.3945 24.9438H47.4172C46.2606 24.9438 45.2033 24.2903 44.686 23.2559Z"
        fill="#7AC143"
      />
      <rect
        x="34.4581"
        y="22.3305"
        width="30.9205"
        height="57.6647"
        rx="1.66191"
        fill="#F6F7F7"
        stroke="#53565A"
        strokeWidth="0.474833"
      />
      <path
        d="M48.0264 23.9409C47.25 23.9409 46.5231 23.6353 45.9856 23.1167H52.7887C52.2512 23.6353 51.5243 23.9409 50.7479 23.9409H48.0264Z"
        fill="#53565A"
        stroke="#53565A"
        strokeWidth="1.3841"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8169 61C44.0376 61 39.3535 56.3159 39.3535 50.5366C39.3535 44.7573 44.0376 40.0732 49.8169 40.0732C55.5962 40.0732 60.2803 44.7573 60.2803 50.5366C60.2803 56.3159 55.5962 61 49.8169 61Z"
        fill="#BE0F2C"
      />
      <path
        d="M53.1072 47.2465L46.5269 53.8268"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1072 53.8268L46.5269 47.2465"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { DeviceCheckError };
