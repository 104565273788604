import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Diners = ({
  width = 65,
  height = 40,
  alt = "Diners",
  color = theme.colors.tertiary["750"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 44 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M2.24 0H41.44C42.6772 0 43.68 1.0029 43.68 2.24V25.76C43.68 26.9972 42.6772 28 41.44 28H2.24C1.0029 28 0 26.9972 0 25.76V2.24C0 1.0029 1.0029 0 2.24 0Z"
        fill={color}
      />
      <path
        d="M33.5961 14.0812C33.5961 8.51396 28.9492 4.66592 23.8577 4.6676H19.4761C14.324 4.66576 10.0833 8.51508 10.0833 14.0812C10.0833 19.1733 14.324 23.357 19.4761 23.3324H23.8577C28.9489 23.3568 33.5961 19.1719 33.5961 14.0812Z"
        fill={secondColor}
      />
      <path
        d="M19.5037 5.45605C14.7958 5.45757 10.9811 9.2813 10.98 14.0005C10.9811 18.719 14.796 22.5422 19.5037 22.5439C24.2128 22.5424 28.0286 18.7189 28.0292 14.0005C28.0286 9.2813 24.2128 5.45773 19.5037 5.45605Z"
        fill={color}
      />
      <path
        d="M14.116 13.9776C14.1204 11.6714 15.5577 9.70492 17.5846 8.92355V19.0304C15.5577 18.2493 14.1204 16.2839 14.116 13.9776ZM21.452 19.0329V8.92322C23.4796 9.70274 24.9192 11.6704 24.9228 13.9776C24.9192 16.2855 23.4796 18.2516 21.452 19.0327V19.0329Z"
        fill={secondColor}
      />
    </svg>
  );
};
