import { buildTestIds, useAuth } from "application";
import { cookie } from "implementations";
import { Text, theme } from "ui";
import { useAuthentication, useShallow } from "application/state-manager";
import { useCallback } from "react";
import { UserAuthenticatedAction } from "../DropdownUserAuthenticated/data";
import {
  CloseIcon,
  DropdownUserAuthenticatedWrapperLink,
} from "../DropdownUserAuthenticated/styles";
import { CategoryContainer } from "./style";

interface CategoryProps {
  action: UserAuthenticatedAction | null;
  isCloseIcon?: boolean;
  handleCloseDropdownUserAuthenticated(): void;
}

const Category = ({
  action,
  handleCloseDropdownUserAuthenticated,
  isCloseIcon = false,
}: CategoryProps) => {
  const { changeCurrentStep } = useAuthentication(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
    }))
  );

  const { signOutAccount } = useAuth();
  // const router = useRouter();

  const iconColor = `${theme.colors.neutral["760"]}`;

  const handleCloseAction = useCallback(() => {
    signOutAccount(cookie);
    changeCurrentStep("login");
  }, [signOutAccount, changeCurrentStep]);

  if (action && !isCloseIcon) {
    const { id, title, path, icon } = action;

    return (
      <CategoryContainer
        key={id}
        {...buildTestIds("option-user-authenticated")}
      >
        <DropdownUserAuthenticatedWrapperLink
          {...buildTestIds("option-user-authenticated-link")}
          key={id}
          target="_self"
          href={path}
          onClick={() => handleCloseDropdownUserAuthenticated()}
          padding={`${theme.space.x2} ${theme.space.x3} ${theme.space.x2} ${theme.space.x4}`}
        >
          {icon(iconColor) || <CloseIcon color={iconColor} />}

          <Text
            {...buildTestIds("option-user-authenticated-text")}
            color={theme.colors.neutral["550"]}
            fontSize={theme.typography.fontSize.sm.x5}
            margin="0px !important"
          >
            {title}
          </Text>
        </DropdownUserAuthenticatedWrapperLink>
        {/* {id === 4 && <TagNew onClick={() => router.push(path)}>novo</TagNew>} */}
      </CategoryContainer>
    );
  }

  return (
    <CategoryContainer {...buildTestIds("option-user-authenticated")}>
      <DropdownUserAuthenticatedWrapperLink
        {...buildTestIds("option-user-authenticated-link")}
        padding={`${theme.space.x2} ${theme.space.x4}`}
        target="_self"
        href="/"
        onClick={handleCloseAction}
      >
        <CloseIcon color={iconColor} />
        <Text
          {...buildTestIds("option-user-authenticated-text")}
          color={theme.colors.neutral["550"]}
          fontSize={theme.typography.fontSize.sm.x5}
          margin="0px !important"
        >
          Sair
        </Text>
      </DropdownUserAuthenticatedWrapperLink>
    </CategoryContainer>
  );
};
export { Category };
