const isPageReloadByZipCodeSearch = () => {
  return localStorage?.getItem("isPageReloadByZipCodeSearch");
};

const setIsPageReloadByZipCodeSearch = (value: boolean) => {
  if (value) {
    localStorage?.setItem("isPageReloadByZipCodeSearch", "true");
    return;
  }

  localStorage?.removeItem("isPageReloadByZipCodeSearch");
};

export { isPageReloadByZipCodeSearch, setIsPageReloadByZipCodeSearch };
