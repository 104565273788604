import { CategoryDto } from "typing";
import { memo } from "react";
import { buildTestIds } from "application";
import {
  ListedCategories,
  LinkCategories,
  CategoriesTitle,
  SubCategoriesListWrapper,
  ArrowRightIcon,
  ListedCategoriesWrapper,
} from "./styles";

interface ListContentSidebarMenuSubCategoriesProps {
  selectedCategory: CategoryDto;
}

const formatTestId = (testId: string) => {
  return testId.replace(/\s/g, "-").toLowerCase();
};

export const ListContentSidebarMenuSubCategories = memo(
  ({
    selectedCategory: { title, descriptionUrl, categories: subCategoriesList },
  }: ListContentSidebarMenuSubCategoriesProps) => {
    return (
      <>
        {/* inserir rota para pesquisa "pesquisa/(nome da categoria)" somente no título da subcategoria. */}

        <CategoriesTitle
          href={`/${descriptionUrl}`}
          {...buildTestIds(
            `${formatTestId(title)}-categories-title-sidebar-menu-mobile`
          )}
        >
          {title}
          <ArrowRightIcon />
        </CategoriesTitle>
        <SubCategoriesListWrapper>
          {subCategoriesList?.map(
            ({
              title: subCategoryTitle,
              categories: subCategoryCategoriesList,
            }) => (
              <>
                <ListedCategories
                  {...buildTestIds(
                    `${formatTestId(
                      subCategoryTitle
                    )}-subcategory-title-sidebar-menu-mobile`
                  )}
                >
                  {subCategoryTitle}
                </ListedCategories>
                <ListedCategoriesWrapper
                  {...buildTestIds(
                    `${formatTestId(
                      subCategoryTitle
                    )}-subcategory-wrapper-sidebar-menu-mobile`
                  )}
                >
                  {subCategoryCategoriesList?.map(
                    ({
                      id: innerSubCategoryId,
                      title: innerSubCategoryTitle,
                      descriptionUrl: innerSubCategoryDescriptionUrl,
                    }) => (
                      <LinkCategories
                        {...buildTestIds(
                          `${formatTestId(
                            innerSubCategoryTitle
                          )}-link-subcategory-title-sidebar-menu-mobile`
                        )}
                        key={innerSubCategoryId}
                        href={`/${innerSubCategoryDescriptionUrl}`}
                        target="_self"
                      >
                        {innerSubCategoryTitle}
                      </LinkCategories>
                    )
                  )}
                </ListedCategoriesWrapper>
              </>
            )
          )}
        </SubCategoriesListWrapper>
      </>
    );
  }
);

ListContentSidebarMenuSubCategories.displayName =
  "ListContentSidebarMenuSubCategories";
