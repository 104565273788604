import { EnvsApi, useEnvs, useRecaptcha } from "application";
import { useEffect, useState } from "react";
import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";

export type RecaptchaProps = Omit<ReCAPTCHAProps, "sitekey"> & {
  envsApi: EnvsApi;
};

const Recaptcha = ({ ...rest }: RecaptchaProps) => {
  const {
    handleCheckIfTheCustomerIsNotARobotSignUp,
    handleCheckIfTheCustomerIsNotARobotSignIn,
  } = useRecaptcha();

  const [siteKey, setSiteKey] = useState<string | null>(null);

  const allConfigs = useEnvs((state) => state.allConfigs);

  useEffect(() => {
    const handleSetGoogleRecaptchaKey = async (googleRecaptchaKey: string) => {
      setSiteKey(googleRecaptchaKey);
    };

    if (allConfigs?.googleRecaptchaKey) {
      handleSetGoogleRecaptchaKey(allConfigs.googleRecaptchaKey);
    }
  }, [allConfigs?.googleRecaptchaKey]);

  if (!siteKey) {
    return null;
  }

  return (
    <ReCAPTCHA
      sitekey={siteKey}
      onChange={(token) => {
        if (!token) {
          handleCheckIfTheCustomerIsNotARobotSignUp(true);
          handleCheckIfTheCustomerIsNotARobotSignIn(true);
        }

        if (token) {
          handleCheckIfTheCustomerIsNotARobotSignUp(false);
          handleCheckIfTheCustomerIsNotARobotSignIn(false);
        }
      }}
      {...rest}
    />
  );
};

export { Recaptcha };
