import { useLoginAuthentication, useShallow } from "application/state-manager";

const usePhoneValidationData = () => {
  const { validationStep } = useLoginAuthentication(
    useShallow((state) => ({
      validationStep: state.validationStep,
    }))
  );

  return { validationStep };
};

export { usePhoneValidationData };
