import { IconProps } from "typing";
import { theme } from "../themes";

export const CalendarOutlineIcon = ({
  width = 24,
  height = 24,
  alt = "Calendário",
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-labelledby={alt}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 2V6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 2V6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 9H21.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 4H5.5C4.395 4 3.5 4.895 3.5 6V19C3.5 20.105 4.395 21 5.5 21H19.5C20.605 21 21.5 20.105 21.5 19V6C21.5 4.895 20.605 4 19.5 4Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.51316 12.729C7.37516 12.729 7.26316 12.841 7.26416 12.979C7.26416 13.117 7.37616 13.229 7.51416 13.229C7.65216 13.229 7.76416 13.117 7.76416 12.979C7.76416 12.841 7.65216 12.729 7.51316 12.729"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5132 12.729C12.3752 12.729 12.2632 12.841 12.2642 12.979C12.2642 13.117 12.3762 13.229 12.5142 13.229C12.6522 13.229 12.7642 13.117 12.7642 12.979C12.7642 12.841 12.6522 12.729 12.5132 12.729"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5132 12.729C17.3752 12.729 17.2632 12.841 17.2642 12.979C17.2642 13.117 17.3762 13.229 17.5142 13.229C17.6522 13.229 17.7642 13.117 17.7642 12.979C17.7642 12.841 17.6522 12.729 17.5132 12.729"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.51316 16.729C7.37516 16.729 7.26316 16.841 7.26416 16.979C7.26416 17.117 7.37616 17.229 7.51416 17.229C7.65216 17.229 7.76416 17.117 7.76416 16.979C7.76416 16.841 7.65216 16.729 7.51316 16.729"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5132 16.729C12.3752 16.729 12.2632 16.841 12.2642 16.979C12.2642 17.117 12.3762 17.229 12.5142 17.229C12.6522 17.229 12.7642 17.117 12.7642 16.979C12.7642 16.841 12.6522 16.729 12.5132 16.729"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
