import { validateCpf } from "application";
import { ReceivedProps } from "typing";
import * as zod from "zod";

export const recoverPasswordFormValidateSchema = zod.object({
  recipientEmailForgotPassword: zod
    .string()
    .min(11, { message: "O valor de caracteres deve ser no mínimo 11" })
    .email({ message: "E-mail não é válido" }),
});

export const validateCpfSchema = zod.object({
  recipientCPFForgotPassword: zod
    .string()
    .superRefine((value: string, context: zod.RefinementCtx) => {
      const response = validateCpf(value);
      const received = response as unknown as ReceivedProps;

      if (response)
        context.addIssue({
          code: "invalid_type",
          expected: "string",
          received,
          message: `${response}`,
        });
    }),
});

export type RecoverPasswordFormProps = zod.infer<
  typeof recoverPasswordFormValidateSchema
>;

export type ValidateCpfProps = zod.infer<typeof validateCpfSchema>;
