import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const HomeImprovementsSmallIcon = ({
  width = 136,
  height = 207,
  color = `${theme.colors.neutral["150"]}`,
  secondColor = `${theme.colors.yellow["1100"]}`,
  thirdColor = `${theme.colors.neutral["white-075"]}`,
  fourthColor = `${theme.colors.primary["900"]}`,
  fifthColor = `${theme.colors.primary["180"]}`,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 136 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M69 70H2V137H69V70Z" fill={`${theme.colors.secondary["250"]}`} />
      <path
        d="M22.7392 91.765C22.3981 91.765 22.0482 91.6337 21.7858 91.3713C19.0304 88.6159 19.0304 84.1198 21.7858 81.3644C24.5499 78.609 29.0373 78.609 31.7927 81.3644L37.6971 87.2688C38.222 87.7937 38.222 88.6509 37.6971 89.1757C37.1723 89.7006 36.3151 89.7006 35.7902 89.1757L29.8858 83.2713C28.1801 81.5656 25.4072 81.5656 23.7014 83.2713C21.9957 84.977 21.9957 87.7499 23.7014 89.4557C24.2263 89.9805 24.2263 90.8377 23.7014 91.3626C23.439 91.625 23.0891 91.7562 22.748 91.7562"
        fill={secondColor}
      />
      <path
        d="M34.2502 123.661C33.9003 123.661 33.5591 123.53 33.2967 123.267L13.4053 103.376C12.8805 102.851 12.8805 101.994 13.4053 101.469L36.6819 78.1925C37.2068 77.6676 38.064 77.6676 38.5888 78.1925L58.4802 98.0838C59.0051 98.6087 59.0051 99.4659 58.4802 99.9907L35.2036 123.267C34.9412 123.53 34.5913 123.661 34.2502 123.661ZM16.2657 102.414L34.2502 120.398L55.6199 99.0285L37.6354 81.0441L16.2657 102.414Z"
        fill={secondColor}
      />
      <path
        d="M36.7354 91.7656C34.7585 91.7656 33.1577 90.1561 33.1577 88.188C33.1577 86.2199 34.7672 84.6104 36.7354 84.6104C38.7035 84.6104 40.313 86.2199 40.313 88.188C40.313 90.1561 38.7035 91.7656 36.7354 91.7656ZM36.7354 87.3045C36.2543 87.3045 35.8519 87.6981 35.8519 88.188C35.8519 88.6778 36.2455 89.0715 36.7354 89.0715C37.2252 89.0715 37.6188 88.6778 37.6188 88.188C37.6188 87.6981 37.2252 87.3045 36.7354 87.3045Z"
        fill={secondColor}
      />
      <path
        d="M56.8794 122.665C54.8413 122.665 53.7304 121.641 53.1531 120.784C51.7447 118.676 51.7622 114.967 53.2143 109.448C54.229 105.599 55.5673 102.327 55.6286 102.187C55.8385 101.68 56.3283 101.348 56.8794 101.348C57.4305 101.348 57.9203 101.68 58.1303 102.187C58.1828 102.327 59.5299 105.599 60.5445 109.448C61.9966 114.967 62.0141 118.676 60.6058 120.784C60.0284 121.641 58.9175 122.665 56.8794 122.665ZM56.8794 106.649C56.5295 107.698 56.1534 108.905 55.821 110.174C55.2699 112.282 54.124 117.381 55.4011 119.288C55.6111 119.603 55.9697 119.971 56.8794 119.971C57.7891 119.971 58.1478 119.612 58.3577 119.288C58.7863 118.65 59.5998 116.489 57.9378 110.174C57.6054 108.914 57.2293 107.698 56.8794 106.649Z"
        fill={secondColor}
      />
      <path
        d="M24.5326 93.5665C24.1827 93.5665 23.8415 93.4353 23.5791 93.1728L21.7772 91.3796C21.2523 90.8548 21.2523 89.9976 21.7772 89.4727C22.302 88.9479 23.1592 88.9479 23.6841 89.4727L25.486 91.2747C26.0109 91.7995 26.0109 92.6568 25.486 93.1816C25.2236 93.444 24.8737 93.5752 24.5326 93.5752"
        fill={secondColor}
      />
      <path
        d="M34.9855 124.407C34.6356 124.407 34.2945 124.275 34.032 124.013L12.4437 102.425C11.9188 101.9 11.9188 101.042 12.4437 100.518C12.9685 99.9928 13.8258 99.9928 14.3506 100.518L35.9389 122.106C36.4638 122.631 36.4638 123.488 35.9389 124.013C35.6765 124.275 35.3266 124.407 34.9855 124.407Z"
        fill={secondColor}
      />
      <path
        d="M58.1222 100.983C57.7723 100.983 57.4312 100.852 57.1688 100.589L35.5804 79.0007C35.0556 78.4759 35.0556 77.6187 35.5804 77.0938C36.1052 76.569 36.9625 76.569 37.4873 77.0938L59.0757 98.6822C59.6005 99.207 59.6005 100.064 59.0757 100.589C58.8132 100.852 58.4634 100.983 58.1222 100.983Z"
        fill={secondColor}
      />
      <path
        d="M43.6804 113.368C42.762 113.368 41.9047 113.009 41.2574 112.37L40.6888 111.802C39.3593 110.472 39.3593 108.303 40.6888 106.964L47.7217 99.9316C48.2465 99.4068 49.1038 99.4068 49.6286 99.9316C50.1534 100.456 50.1534 101.314 49.6286 101.839L42.5958 108.871C42.3158 109.151 42.3158 109.615 42.5958 109.886L43.1643 110.455C43.348 110.638 43.558 110.665 43.6717 110.665C43.7854 110.665 44.0041 110.638 44.179 110.455L56.5652 98.0772C57.09 97.5524 57.9473 97.5524 58.4809 98.0772C59.0057 98.6021 59.0057 99.4593 58.4809 99.9841L46.0947 112.37C45.4474 113.018 44.5901 113.368 43.6717 113.368"
        fill={secondColor}
      />
      <path
        d="M48.6837 102.233C48.3338 102.233 47.9927 102.102 47.7302 101.84C47.2054 101.315 47.2054 100.458 47.7302 99.9327C47.9139 99.749 47.9402 99.5391 47.9402 99.4253C47.9402 99.3116 47.9139 99.1017 47.7302 98.918L47.1617 98.3494C46.978 98.1657 46.768 98.1395 46.6543 98.1395C46.5406 98.1395 46.3219 98.1657 46.147 98.3494C45.6221 98.8743 44.7649 98.8743 44.2401 98.3494C43.7152 97.8246 43.7152 96.9673 44.2401 96.4425C45.5697 95.1129 47.7477 95.1129 49.0773 96.4425L49.6459 97.0111C50.2932 97.6584 50.6431 98.5156 50.6431 99.4341C50.6431 100.353 50.2845 101.21 49.6459 101.857C49.3835 102.12 49.0336 102.251 48.6924 102.251"
        fill={secondColor}
      />
      <path
        d="M39.8838 103.189C39.0091 103.189 38.1343 102.856 37.4608 102.192L36.8922 101.623C35.5626 100.293 35.5626 98.1153 36.8922 96.7857L45.9982 87.6798C46.523 87.1549 47.3802 87.1549 47.9051 87.6798C48.4299 88.2046 48.4299 89.0618 47.9051 89.5867L38.7991 98.6926C38.5192 98.9725 38.5192 99.4361 38.7991 99.7073L39.3677 100.276C39.6389 100.556 40.1112 100.556 40.3824 100.276L44.2225 96.4358C44.7473 95.911 45.6045 95.911 46.1294 96.4358C46.6542 96.9606 46.6542 97.8179 46.1294 98.3427L42.2893 102.183C41.6245 102.848 40.7498 103.18 39.8663 103.18"
        fill={secondColor}
      />
      <path d="M137 2H70V69H137V2Z" fill={thirdColor} />
      <path
        d="M97.7635 31.6698H78.047C77.3035 31.6698 76.7 31.0662 76.7 30.3227V20.0971C76.7 19.3536 77.3035 18.75 78.047 18.75H97.7635C98.507 18.75 99.1106 19.3536 99.1106 20.0971V30.3227C99.1106 31.0662 98.507 31.6698 97.7635 31.6698ZM79.3941 28.9668H96.4076V21.4442H79.3941V28.9668Z"
        fill={color}
      />
      <path
        d="M97.7635 52.1229H78.047C77.3035 52.1229 76.7 51.5193 76.7 50.7758V40.5502C76.7 39.8067 77.3035 39.2031 78.047 39.2031H97.7635C98.507 39.2031 99.1106 39.8067 99.1106 40.5502V50.7758C99.1106 51.5193 98.507 52.1229 97.7635 52.1229ZM79.3941 49.42H96.4076V41.8885H79.3941V49.42Z"
        fill={color}
      />
      <path
        d="M117.436 52.1229H97.7198C96.9763 52.1229 96.364 51.5193 96.364 50.7758V40.5502C96.364 39.8067 96.9676 39.2031 97.7198 39.2031H117.436C118.18 39.2031 118.783 39.8067 118.783 40.5502V50.7758C118.783 51.5193 118.18 52.1229 117.436 52.1229ZM99.0669 49.42H116.08V41.8885H99.0669V49.42Z"
        fill={color}
      />
      <path
        d="M107.595 41.8983H87.8788C87.1353 41.8983 86.5317 41.2947 86.5317 40.5512V30.3256C86.5317 29.582 87.1353 28.9697 87.8788 28.9697H107.595C108.339 28.9697 108.951 29.5733 108.951 30.3256V40.5512C108.951 41.2947 108.348 41.8983 107.595 41.8983ZM89.2259 39.1953H106.239V31.6726H89.2259V39.1953Z"
        fill={color}
      />
      <path
        d="M127.268 41.8983H107.552C106.808 41.8983 106.205 41.2947 106.205 40.5512V30.3256C106.205 29.582 106.808 28.9697 107.552 28.9697H127.268C128.012 28.9697 128.615 29.5733 128.615 30.3256V40.5512C128.615 41.2947 128.012 41.8983 127.268 41.8983ZM108.899 39.1953H125.912V31.6726H108.899V39.1953Z"
        fill={color}
      />
      <path d="M137 69H70V136H137V69Z" fill={fourthColor} />
      <path
        d="M103.753 110.322C103.403 110.322 103.062 110.191 102.8 109.928C100.018 107.147 98.2775 103.464 97.8926 99.5541C97.5515 96.0727 98.295 92.5475 99.992 89.5209L94.0351 83.564C92.2419 81.7708 92.2419 78.8492 94.0351 77.0473C94.9098 76.1726 96.0645 75.7002 97.2978 75.7002C98.5312 75.7002 99.6858 76.1813 100.561 77.0473L106.517 83.0042C109.544 81.3072 113.069 80.5637 116.551 80.9048C120.461 81.2897 124.143 83.0304 126.925 85.8121C127.45 86.3369 127.45 87.1942 126.925 87.719L104.724 109.92C104.462 110.182 104.112 110.313 103.771 110.313M97.3066 78.3944C96.7992 78.3944 96.3181 78.5956 95.9595 78.9542C95.216 79.6977 95.216 80.9048 95.9595 81.6484L102.66 88.3488C103.115 88.8037 103.185 89.5034 102.835 90.0458C101.068 92.7574 100.272 96.0377 100.587 99.2829C100.867 102.117 101.995 104.82 103.806 107.007L124.003 86.8093C121.817 84.9986 119.114 83.8702 116.279 83.5903C113.034 83.2666 109.754 84.0714 107.042 85.8383C106.509 86.1882 105.8 86.1182 105.345 85.6634L98.6449 78.9629C98.2863 78.6043 97.8052 78.4031 97.2978 78.4031L97.3066 78.3944Z"
        fill={fifthColor}
      />
      <path
        d="M114.853 105.451C112.911 105.451 110.969 104.716 109.491 103.238C108.966 102.713 108.966 101.856 109.491 101.331C110.016 100.806 110.873 100.806 111.407 101.331C113.305 103.229 116.401 103.229 118.308 101.331C120.206 99.4242 120.206 96.3276 118.308 94.4294C117.783 93.9046 117.783 93.0474 118.308 92.5225C118.833 91.9977 119.69 91.9977 120.215 92.5225C123.172 95.4791 123.172 100.29 120.215 103.247C118.737 104.725 116.795 105.46 114.853 105.46"
        fill={fifthColor}
      />
      <path
        d="M96.6851 131.673C96.1953 131.673 95.7229 131.402 95.478 130.938L83.5467 107.496C83.3105 107.032 83.363 106.472 83.6779 106.061L98.4258 86.6946C98.8807 86.0998 99.7292 85.986 100.315 86.4409C100.91 86.8958 101.024 87.7442 100.569 88.3391L86.3283 107.032L97.8748 129.723C98.2159 130.387 97.9447 131.201 97.28 131.542C97.0875 131.647 96.8776 131.691 96.6676 131.691"
        fill={fifthColor}
      />
      <path d="M139 134H68V205H139V134Z" fill={thirdColor} />
      <path
        d="M93.7952 195.571C93.7574 195.571 93.712 195.571 93.6741 195.571C92.7358 195.503 92.0245 194.685 92.0926 193.747L94.8848 154.568C94.9529 153.629 95.7701 152.925 96.7084 152.986C97.6467 153.054 98.3505 153.872 98.2899 154.81L95.4977 193.989C95.4372 194.89 94.6881 195.571 93.8028 195.571"
        fill={color}
      />
      <path
        d="M113.204 195.571C112.319 195.571 111.57 194.882 111.509 193.989L108.717 154.81C108.649 153.872 109.36 153.054 110.298 152.986C111.237 152.925 112.054 153.629 112.122 154.568L114.914 193.747C114.982 194.685 114.271 195.503 113.333 195.571C113.295 195.571 113.249 195.571 113.212 195.571"
        fill={color}
      />
      <path
        d="M113.969 155.588H93.0387C92.1004 155.588 91.3362 154.824 91.3362 153.885V149.404C91.3362 148.466 92.1004 147.701 93.0387 147.701H113.969C114.907 147.701 115.671 148.466 115.671 149.404V153.885C115.671 154.824 114.907 155.588 113.969 155.588ZM94.7413 152.182H112.266V151.107H94.7413V152.182Z"
        fill={color}
      />
      <path
        d="M111.298 180.475H95.6947C94.7564 180.475 93.9922 179.71 93.9922 178.771C93.9922 177.833 94.7564 177.068 95.6947 177.068H111.298C112.236 177.068 113 177.833 113 178.771C113 179.71 112.236 180.475 111.298 180.475Z"
        fill={color}
      />
      <path
        d="M111.85 186.064H95.1503C94.212 186.064 93.4478 185.3 93.4478 184.361C93.4478 183.423 94.212 182.658 95.1503 182.658H111.85C112.789 182.658 113.553 183.423 113.553 184.361C113.553 185.3 112.789 186.064 111.85 186.064Z"
        fill={color}
      />
    </svg>
  );
};
