import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const MapPin = ({
  width = 32,
  height = 32,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.21277C0 7.10831 1.5607 9.95575 4.67102 13.8396C4.84234 14.0535 5.15766 14.0535 5.32898 13.8396C8.4393 9.95575 10 7.10831 10 5.21277C10 2.33384 7.76142 0 5 0C2.23858 0 0 2.33384 0 5.21277ZM3 5C3 6.10457 3.89543 7 5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3C3.89543 3 3 3.89543 3 5Z"
        fill={color}
      />
      <mask
        id="mask0_1124_465"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.21277C0 7.10831 1.5607 9.95575 4.67102 13.8396C4.84234 14.0535 5.15766 14.0535 5.32898 13.8396C8.4393 9.95575 10 7.10831 10 5.21277C10 2.33384 7.76142 0 5 0C2.23858 0 0 2.33384 0 5.21277ZM3 5C3 6.10457 3.89543 7 5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3C3.89543 3 3 3.89543 3 5Z"
          fill={secondColor}
        />
      </mask>
      <g mask="url(#mask0_1124_465)" />
    </svg>
  );
};
