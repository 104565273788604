import { IHttpResponse, CategoryDto } from "typing";
import { CatalogApi } from "../services";
import { IStateManager } from "../entities";

export const getCategories = (
  catalogApi: CatalogApi,
  stateManager: IStateManager
): IHttpResponse<CategoryDto[], unknown> => {
  const response = catalogApi.getHomeCategories();
  return stateManager.produceNewState<IHttpResponse<CategoryDto[], unknown>>(
    response,
    ({ data }) => {
      data?.forEach((secondLevel) => {
        secondLevel.categories?.forEach((thirdLevel) => {
          thirdLevel.categories?.sort((a, b) => a.ordination - b.ordination);
        });
      });
    }
  );
};
