import { buildTestIds, useClickOutside } from "application";
import { useRef, useState } from "react";
import { ContentOption, ContentProps } from "typing";
import {
  Dots,
  DropdownEllipsisContent,
  DropdownEllipsisContentOption,
  DropdownEllipsisStyled,
  DropdownEllipsisWrapper,
} from "./style";

interface DropdownEllipsisProps {
  //   showEllipsis: (forceIsShowEllipsis?: boolean) => void;
  //   isContentOpened: boolean;
  content: {
    props: ContentProps;
    options: ContentOption[];
  };
}

const DropdownEllipsis = ({
  //   showEllipsis,
  //   isContentOpened,
  content: {
    props: { marginTop = "initial", marginLeft = "initial", width = "initial" },
    options,
  },
}: DropdownEllipsisProps) => {
  const [isContentOpened, setIsContentOpened] = useState(false);
  const dropdownEllipsisWrapperRef = useRef<HTMLSpanElement>(null);

  useClickOutside({
    ref: dropdownEllipsisWrapperRef,
    callback: () => {
      if (isContentOpened) {
        setIsContentOpened(false);
      }
    },
  });

  return (
    <DropdownEllipsisStyled {...buildTestIds("dropdown-ellipsis-styled")}>
      <DropdownEllipsisWrapper
        {...buildTestIds("dropdown-ellipsis-wrapper")}
        ref={dropdownEllipsisWrapperRef}
        onClick={() => {
          setIsContentOpened(!isContentOpened);
        }}
        data-testid="dropdown-ellipsis-wrapper"
        data-cy="dropdown-ellipsis-wrapper"
      >
        <Dots
          data-testid="dropdown-ellipsis-wrapper-dot-one"
          data-cy="dropdown-ellipsis-wrapper-dot-one"
        />
        <Dots
          data-testid="dropdown-ellipsis-wrapper-dot-two"
          data-cy="dropdown-ellipsis-wrapper-dot-two"
        />
        <Dots
          data-testid="dropdown-ellipsis-wrapper-dot-three"
          data-cy="dropdown-ellipsis-wrapper-dot-three"
        />
      </DropdownEllipsisWrapper>

      <DropdownEllipsisContent
        isVisible={isContentOpened}
        marginTop={marginTop}
        marginLeft={marginLeft}
        width={width}
        data-testid="dropdown-ellipsis-content"
      >
        {options?.map(({ title, fontSize = "inherit", onClickAction }) => (
          <DropdownEllipsisContentOption
            key={title}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onClickAction();
            }}
            fontSize={fontSize}
            data-testid={`dropdown-ellipsis-content-option-${title}`}
            data-cy={`dropdown-ellipsis-content-option-${title}`}
          >
            {title}
          </DropdownEllipsisContentOption>
        ))}
      </DropdownEllipsisContent>
    </DropdownEllipsisStyled>
  );
};

export { DropdownEllipsis };
