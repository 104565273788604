import { useCallback } from "react";
import {
  ComponentDetails,
  ComponentDto,
  ComponentType,
  CustomBannerDto,
  CustomCardCTADto,
  CustomCarouselDto,
  CustomDynamicBannerDto,
  CustomFilterProductsDto,
  CustomHeaderDto,
  CustomHighlightCategoryDto,
  CustomImagesGridDto,
  CustomMarqueeDto,
  CustomProductListDto,
  CustomGridPlaylistsDto,
  CustomTextBlockDto,
  CustomFAQDto,
  CustomStoriesDto,
  CustomCountdownDto,
} from "typing";
import { CustomBanner } from "../CustomBanner";
import { CustomCarousel } from "../CustomCarousel";
import { CustomDynamicBanner } from "../CustomDynamicBanner";
import { CustomFilterProducts } from "../CustomFilterProducts";
import { CustomHeader } from "../CustomHeader";
import { CustomHighlightCategories } from "../CustomHighlightCategories";
import { CustomImagesGrid } from "../CustomImagesGrid";
import { CustomProductList } from "../CustomProductList";
import { CustomGridPlaylists } from "../CustomGridPlaylists";
import { CustomTextBlock } from "../CustomTextBlock";
import { CustomMarquee } from "../CustomMarquee";
import { CustomCardCTA } from "../CustomCardCTA";
import { CustomFAQ } from "../CustomFAQ";
import { CustomStories } from "../CustomStories";
import { CustomCountdown } from "../CustomCountdown";

const useMappedBackofficeComponent = (
  component: ComponentDto | null | undefined,
  details?: ComponentDetails,
  highlightCategories?: CustomHighlightCategoryDto[] | null
): JSX.Element | null => {
  const constructMapComponentTypeAndElement = useCallback(() => {
    if (!component) {
      return new Map();
    }

    const componentProps = {
      ...details,
      ...component,
    };

    const mapComponentTypeAndElement: Map<ComponentType, JSX.Element> =
      new Map();

    mapComponentTypeAndElement.set(
      "banner",
      <CustomBanner
        {...(componentProps as CustomBannerDto)}
        isinsidecarousel={false}
      />
    );

    mapComponentTypeAndElement.set(
      "carousel",
      <CustomCarousel {...(componentProps as CustomCarouselDto)} />
    );

    mapComponentTypeAndElement.set(
      "dynamicBanner",
      <CustomDynamicBanner {...(componentProps as CustomDynamicBannerDto)} />
    );

    mapComponentTypeAndElement.set(
      "filterProducts",
      <CustomFilterProducts {...(componentProps as CustomFilterProductsDto)} />
    );

    mapComponentTypeAndElement.set(
      "imagesGrid",
      <CustomImagesGrid {...(componentProps as CustomImagesGridDto)} />
    );

    mapComponentTypeAndElement.set(
      "gridPlaylists",
      <CustomGridPlaylists {...(componentProps as CustomGridPlaylistsDto)} />
    );

    mapComponentTypeAndElement.set(
      "header",
      <CustomHeader {...(componentProps as CustomHeaderDto)} />
    );

    const customHighlightCategoriesDetails = {
      subComponents: highlightCategories,
    };

    mapComponentTypeAndElement.set(
      "highlightCategories",
      <CustomHighlightCategories {...customHighlightCategoriesDetails} />
    );

    mapComponentTypeAndElement.set(
      "productList",
      <CustomProductList {...(componentProps as CustomProductListDto)} />
    );

    mapComponentTypeAndElement.set(
      "stories",
      <CustomStories {...(componentProps as CustomStoriesDto)} />
    );

    mapComponentTypeAndElement.set(
      "textBlock",
      <CustomTextBlock {...(componentProps as CustomTextBlockDto)} />
    );

    mapComponentTypeAndElement.set(
      "marquee",
      <CustomMarquee {...(componentProps as CustomMarqueeDto)} />
    );

    mapComponentTypeAndElement.set(
      "cardCTA",
      <CustomCardCTA {...(componentProps as CustomCardCTADto)} />
    );

    mapComponentTypeAndElement.set(
      "countdown",
      <CustomCountdown {...(componentProps as CustomCountdownDto)} />
    );

    mapComponentTypeAndElement.set(
      "faq",
      <CustomFAQ {...(componentProps as CustomFAQDto)} />
    );

    return mapComponentTypeAndElement;
  }, [component, details, highlightCategories]);

  return constructMapComponentTypeAndElement().get(component?.type) || null;
};

export { useMappedBackofficeComponent };
