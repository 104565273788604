import styled, { css } from "styled-components";
import { ArrowRight, theme } from "ui";
import { Image } from "../../../Image";

interface CustomCtaProps {
  backgroundColor?: string;
  isBlackTheme?: boolean;
}

export const CardCTASubComponentImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const HeaderTitle = styled.h3<CustomCtaProps>`
  font-size: ${theme.typography.fontSize.sm.x7};
`;

export const HeaderSubTitle = styled.h4<CustomCtaProps>`
  margin-top: ${theme.space.x2};
  font-size: ${theme.typography.fontSize.md.x3};
  font-weight: ${theme.typography.fontWeight["400"]};

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x9};
  }
`;

export const ComponentHeaderContainer = styled.div<CustomCtaProps>`
  margin-bottom: ${theme.space.x4};

  @media ${theme.device.desktopFirst.tablet} {
    padding: ${theme.space.x0};
    display: flex;
    flex-direction: column;
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      ${HeaderSubTitle}, ${HeaderTitle} {
        color: ${`${theme.colors.neutral.white} !important`};
      }
    `}
`;
export const CardCTASubComponentContent = styled.div<CustomCtaProps>`
  width: 100%;
  padding: ${theme.space.x2} ${theme.space.x4} ${theme.space.x6};

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      background-color: ${`${theme.colors.blackFriday["125"]} !important`};
    `}
`;

interface DescriptionProps {
  textAlignCenter?: boolean;
}

export const CardCTASubComponentDescription = styled.div<DescriptionProps>`
  ${({ textAlignCenter }) =>
    textAlignCenter
      ? css`
          text-align: center;
          height: 215px;
          display: flex;
          align-items: center;

          @media ${theme.device.desktopFirst.tablet} {
            text-align: start;
            display: block;
            height: 100%;
          }
        `
      : ""}
`;

export const CardCTAImage = styled(Image)`
  height: 100%;
  width: 100%;
`;

export const CustomCta = styled.div<CustomCtaProps>`
  width: 166px;
  height: 42px;

  ${({ isBlackTheme }) =>
    isBlackTheme
      ? css`
          background-color: ${`${theme.colors.secondary["385"]} !important`};
        `
      : css`
          background-color: ${({ backgroundColor }: CustomCtaProps) =>
            backgroundColor};
        `}

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.space.x4};
  border-radius: ${theme.space.x1};
`;

export const CtaText = styled.span`
  font-size: ${theme.typography.fontSize.sm.x7};
  color: ${theme.colors.neutral.white};
  margin-right: ${theme.space.x1};
  word-break: break-all;
`;

export const CardCTASubComponentText = styled.div``;

export const ArrowRightIcon = styled(ArrowRight)`
  & > path {
    width: 18px;
    height: 18px;
  }
`;

export const CardCTAText = styled.div``;

export const CustomCardCTASubComponentContainer = styled.div<CustomCtaProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0 2px 4px ${theme.colors.neutral["999-opacity-10"]};
  border-radius: ${theme.space.x2};
  background-color: ${theme.colors.neutral.white};

  @media ${theme.device.desktopFirst.tablet} {
    margin-bottom: ${theme.space["x0.5"]};
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      background-color: ${theme?.colors?.blackFriday?.["125"]};
    `}

  ${CardCTAText} {
    ${({ isBlackTheme }) =>
      isBlackTheme &&
      css`
        color: ${theme.colors.blackFriday.white} !important;
        background-color: ${theme?.colors?.blackFriday?.["125"]};
      `}
  }
`;
