import { buildTestIds } from "application";
import NextHead from "next/head";
import { ReactNode } from "react";

interface NextHeadProps {
  children: ReactNode;
}

const Head = ({ children }: NextHeadProps) => {
  return (
    <NextHead {...buildTestIds("head-component-category")}>{children}</NextHead>
  );
};

export { Head };
