export const leadingZero = (value: number, countNumber = 1) => {
  const stringValue = String(value);

  if (value < 10) {
    return `0${stringValue}`?.slice(-2);
  }

  if (stringValue.length <= countNumber) {
    return ("0".repeat(countNumber) + stringValue)?.slice(-countNumber);
  }

  return stringValue;
};
