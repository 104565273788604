import { IAppMonitoringClient, ICookie } from "app-domain";
import axios from "axios";
import { NextRouter } from "next/router";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LocationProps, ShippingBranchDto } from "typing";
import { useToast } from "../hooks/Toast";
import { ShippingApi } from "../services";
import {
  cookieBranchLocationStr,
  cookieModalBranchLocationStr,
  cookieRegionStr,
  formatLocationName,
  optionsMaxAgeEqualOneYear,
} from "../utils";
import { useBrowserBroadcast } from "./BroadcastChannel";
import { useWebSocket } from "./WebSocket";

interface BranchLocationContextData {
  clientLocation: LocationProps;
  isOpen: boolean;
  regionClientLocation: string | null;
  statementDropdownBranchLocation: boolean;
  isChangingClientLocation: boolean;
  onOpenModalBranchLocation(): void;
  onCloseModalBranchLocation(cookie: ICookie, branchId?: string): void;
  handleClientLocationByHimCurrentLocation(defaultRouter: NextRouter): void;
  handleLocationBySubmit(
    zipCode: string,
    cityAndUf: string,
    clientBranchId: number,
    router: NextRouter
  ): void;
  setDefaultBranchLocation(branchId?: string): void;
  getBranchLocationClient(): string;
  setRegionClientLocation: Dispatch<SetStateAction<string | null>>;
}

interface BranchLocationProviderProps {
  children: ReactNode;
  cookie: ICookie;
  appMonitoringClient: IAppMonitoringClient;
  shippingApi: ShippingApi;
}

export const BranchLocationContext = createContext(
  {} as BranchLocationContextData
);

interface LocationBranchProps {
  clientBranchId: number;
  clientLocation: string;
}

export const BranchLocationProvider = ({
  children,
  cookie,
  appMonitoringClient,
  shippingApi,
}: BranchLocationProviderProps) => {
  const [locationBranches, setLocationBranches] = useState<ShippingBranchDto[]>(
    []
  );
  const { addToast } = useToast();
  const { emitEvent } = useWebSocket();
  const {
    createChannel,
    postMessage,
    onMessage,
    isChannelOpen,
    getChannelName,
  } = useBrowserBroadcast();
  const { data: branches } = shippingApi.getBranches();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [statementDropdownBranchLocation, setStatementDropdownBranchLocation] =
    useState<boolean>(false);

  const [isChangingClientLocation, setIsChangingClientLocation] =
    useState(false);
  const [regionClientLocation, setRegionClientLocation] = useState<
    string | null
  >("");

  const [clientLocation, setClientLocation] = useState<LocationProps>(
    {} as LocationProps
  );

  const onOpenModalBranchLocation = () => {
    setIsOpen(true);
  };

  const onCloseModalBranchLocation = useCallback(
    (cookieImpl: ICookie, branchId?: string) => {
      cookieImpl.setCookie({
        name: cookieModalBranchLocationStr,
        value: branchId ?? "2",
        options: optionsMaxAgeEqualOneYear.options,
      });
      setIsOpen(false);
    },
    []
  );

  const otherRegionsStr = "Demais Regiões";

  const defineClientLocation = useCallback(
    (location: LocationProps) => {
      setIsChangingClientLocation(true);
      cookie.setCookie({
        name: cookieBranchLocationStr,
        value: `${location?.clientBranchId}`,
        options: optionsMaxAgeEqualOneYear.options,
      });

      cookie.setCookie({
        name: cookieRegionStr,
        value: `${location?.clientLocation}`,
        options: optionsMaxAgeEqualOneYear.options,
      });

      setClientLocation(location);
      onCloseModalBranchLocation(cookie, String(location?.clientBranchId));
      setRegionClientLocation(location?.clientLocation);
    },
    [cookie, onCloseModalBranchLocation]
  );

  const applyClientLocation = useCallback((defaultRouter?: NextRouter) => {
    if (defaultRouter) {
      const currentUrlWithoutQueryParams =
        defaultRouter.asPath?.split("?")?.[0];

      defaultRouter.replace(currentUrlWithoutQueryParams);

      setTimeout(() => {
        defaultRouter.reload();
        setIsChangingClientLocation(false);
      }, 250);
    } else {
      const currentUrlWithoutQueryParams = window.location.href.split("?")[0];

      window.location.replace(currentUrlWithoutQueryParams);

      setTimeout(() => {
        window.location.reload();
        setIsChangingClientLocation(false);
      }, 250);
    }
  }, []);

  const setDefaultBranchLocation = useCallback(
    (branchId?: string) => {
      const locationName = branchId
        ? locationBranches?.find(
            (locationBranch) => locationBranch?.branchId === Number(branchId)
          )?.city || otherRegionsStr
        : otherRegionsStr;
      const locationBranchId = branchId || "2";

      if (branchId && locationName !== otherRegionsStr) {
        setRegionClientLocation(locationName);
      }

      cookie.setCookie({
        name: cookieBranchLocationStr,
        value: locationBranchId,
        options: optionsMaxAgeEqualOneYear.options,
      });

      cookie.setCookie({
        name: cookieRegionStr,
        value: locationName,
        options: optionsMaxAgeEqualOneYear.options,
      });
    },
    [cookie, locationBranches]
  );

  const onShowDropdown = useCallback(() => {
    const hadVisualization = cookie.getCookie({
      name: cookieBranchLocationStr,
    });

    if (!hadVisualization) {
      setStatementDropdownBranchLocation(true);
    }
  }, [cookie]);

  const getLocationBranch = useCallback(
    (formattedClientLocation: string, city: string): LocationBranchProps => {
      const clientBranchId = locationBranches?.find(
        (locationBranch) =>
          locationBranch?.city?.toLowerCase() === city?.toLowerCase()
      )?.branchId as number;

      if (!clientBranchId) {
        return {
          clientBranchId: 2,
          clientLocation: formattedClientLocation,
        };
      }

      return { clientBranchId, clientLocation: formattedClientLocation };
    },
    [locationBranches]
  );

  const getBranchLocationClient = useCallback(() => {
    const branchLocation = cookie.getCookie({
      name: cookieBranchLocationStr,
    });

    if (!branchLocation) {
      return "2";
    }

    return branchLocation;
  }, [cookie]);

  const handleClientLocationByHimCurrentLocation = useCallback(
    (defaultRouter: NextRouter) => {
      const onClientSelectHimCurrentLocation = async () => {
        navigator?.geolocation?.getCurrentPosition(
          async () => {
            // `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=YOUR_API_KEY`

            setIsChangingClientLocation(true);

            const { data } = await axios.get("https://ipapi.co/json/");

            const { clientBranchId, clientLocation: currentClientLocation } =
              getLocationBranch(
                `${data.city} - ${data.region_code}`,
                data.city
              );

            const location: LocationProps = {
              clientBranchId,
              clientLocation: currentClientLocation,
              clientPostalCode: data.postal,
            };

            const lastBranchLocationId = cookie.getCookie({
              name: cookieBranchLocationStr,
            });

            defineClientLocation(location);

            if (lastBranchLocationId === String(clientBranchId)) {
              setIsChangingClientLocation(false);
              return;
            }

            localStorage?.setItem(
              "clientBranchIdChangedMessage",
              `Alteramos o seu carrinho para ${location?.clientLocation}`
            );

            emitEvent("branchChanged", location);
            postMessage(JSON.stringify(location));

            applyClientLocation(defaultRouter);
          },
          (error) => {
            appMonitoringClient.captureException(error, {
              level: "error",
              tags: {
                fcx_labs_error_source:
                  "navigator_geolocation_getCurrentPosition",
              },
            });
          }
        );
      };

      onClientSelectHimCurrentLocation();
    },
    [
      appMonitoringClient,
      applyClientLocation,
      cookie,
      defineClientLocation,
      emitEvent,
      getLocationBranch,
      postMessage,
    ]
  );

  const handleLocationBySubmit = useCallback(
    (
      zipCode: string,
      cityAndState: string,
      clientBranchId: number,
      defaultRouter: NextRouter
    ) => {
      const fetchZipCode = () => {
        setIsChangingClientLocation(true);

        const currentClientLocation = formatLocationName(cityAndState);

        const lastBranchLocationId = cookie.getCookie({
          name: cookieBranchLocationStr,
        });

        const location: LocationProps = {
          clientBranchId,
          clientLocation: currentClientLocation,
          clientPostalCode: zipCode || "",
        };

        defineClientLocation(location);

        if (lastBranchLocationId === String(clientBranchId)) {
          setIsChangingClientLocation(false);
          return;
        }

        localStorage?.setItem(
          "clientBranchIdChangedMessage",
          `Alteramos o seu carrinho para ${location?.clientLocation}`
        );

        emitEvent("branchChanged", location);
        postMessage(JSON.stringify(location));

        applyClientLocation(defaultRouter);
      };

      fetchZipCode();
    },
    [applyClientLocation, cookie, defineClientLocation, emitEvent, postMessage]
  );

  useMemo(() => {
    setLocationBranches(branches as ShippingBranchDto[]);
  }, [branches]);

  useEffect(() => onShowDropdown(), [onShowDropdown]);

  useEffect(() => {
    const getRegionClient = () => {
      const region = cookie.getCookie({
        name: cookieRegionStr,
      });

      if (!region) {
        return null;
      }

      return region;
    };

    setRegionClientLocation(getRegionClient());
  }, [cookie]);

  useEffect(() => {
    if (isChannelOpen) {
      onMessage((message) => {
        const broadCastedLocation = JSON.parse(message) as LocationProps;

        if (
          broadCastedLocation.clientLocation !== clientLocation.clientLocation
        ) {
          addToast({
            title: "Alteramos o seu carrinho para outra região",
            type: "info",
            timerInMilliseconds: 3500,
          });

          defineClientLocation(broadCastedLocation);

          setIsChangingClientLocation(false);

          localStorage?.setItem(
            "clientBranchIdChangedMessage",
            `Alteramos o seu carrinho para ${broadCastedLocation?.clientLocation}`
          );

          applyClientLocation();
        }
      });
    }
  }, [
    addToast,
    applyClientLocation,
    clientLocation.clientBranchId,
    clientLocation.clientLocation,
    defineClientLocation,
    isChannelOpen,
    onMessage,
  ]);

  useEffect(() => {
    const clientBranchIdChangedMessage = localStorage?.getItem(
      "clientBranchIdChangedMessage"
    );

    const clientHasCartItems = localStorage?.getItem("clientHasCartItems");

    if (clientBranchIdChangedMessage) {
      if (clientHasCartItems && clientHasCartItems === "true") {
        setTimeout(() => {
          addToast({
            title: clientBranchIdChangedMessage,
            type: "info",
            timerInMilliseconds: 3500,
          });
        }, 400);

        localStorage?.removeItem("clientHasCartItems");
      }

      localStorage?.removeItem("clientBranchIdChangedMessage");
    }
  }, [addToast]);

  useEffect(() => {
    if (!isChannelOpen && getChannelName() !== "user-branch") {
      createChannel("user-branch");
    }
  }, [createChannel, getChannelName, isChannelOpen]);

  const branchLocationContextProviderValue = useMemo(() => {
    return {
      clientLocation,
      isOpen,
      statementDropdownBranchLocation,
      onOpenModalBranchLocation,
      onCloseModalBranchLocation,
      handleClientLocationByHimCurrentLocation,
      handleLocationBySubmit,
      regionClientLocation,
      setDefaultBranchLocation,
      getBranchLocationClient,
      setRegionClientLocation,
      isChangingClientLocation,
    };
  }, [
    clientLocation,
    isOpen,
    statementDropdownBranchLocation,
    onCloseModalBranchLocation,
    handleClientLocationByHimCurrentLocation,
    handleLocationBySubmit,
    regionClientLocation,
    setDefaultBranchLocation,
    getBranchLocationClient,
    isChangingClientLocation,
  ]);
  return (
    <BranchLocationContext.Provider value={branchLocationContextProviderValue}>
      {children}
    </BranchLocationContext.Provider>
  );
};

export const useBranchLocation = (): BranchLocationContextData => {
  const context = useContext(BranchLocationContext);

  if (!context) {
    throw new Error(
      "useBranchLocation must be used within an BranchLocationProvider"
    );
  }

  return context;
};
