import { IconProps } from "typing";
import { theme } from "../themes";

export const CardHeart = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect x="3.5" y="5.5" width="17" height="12" rx="1" stroke={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8303 9.51726L12 9.68957L12.1754 9.50416C12.4731 9.18656 12.8877 9.00445 13.3229 9.00008C13.7582 8.99571 14.1764 9.16946 14.4804 9.48102L14.5161 9.51723C15.1613 10.1803 15.1613 11.2366 14.5161 11.8997L12.7618 13.6809C12.5608 13.8851 12.2864 14 12 14C11.7135 14 11.4391 13.8851 11.2382 13.6809L9.48395 11.8997C8.83868 11.2366 8.83868 10.1803 9.48395 9.51726C9.79338 9.20291 10.216 9.0259 10.6571 9.0259C11.0982 9.0259 11.5208 9.20291 11.8303 9.51726Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4 6L7.5 9.5" stroke={color} strokeLinecap="round" />
      <path d="M20 6L16.5 9.5" stroke={color} strokeLinecap="round" />
      <path d="M16 13L20 17" stroke={color} strokeLinecap="round" />
      <path d="M8 13L4 17" stroke={color} strokeLinecap="round" />
    </svg>
  );
};
