import styled from "styled-components";
import { BlackFridayThemeProps } from "typing";
import { FlexContainer, MapPin, theme } from "ui";
import { Link } from "../../../../Link";

export interface ComponentPresentInWeddingListProps {
  isPageWeddingList: boolean;
  isBlackFriday?: BlackFridayThemeProps;
}

export const NavBarDesktopContainer = styled.nav<ComponentPresentInWeddingListProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: ${({ isPageWeddingList }) => (isPageWeddingList ? 0 : "0")};

  @media (max-width: 820px) {
    padding: 0 !important;
    flex-direction: column;
  }

  @media ${theme.device.desktopFirst.laptop} {
    padding: 0 0.75rem;
  }
`;

export const ContentCompanyProducts = styled.section<ComponentPresentInWeddingListProps>`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: ${({ isPageWeddingList }) => (isPageWeddingList ? "1rem" : "2rem")};
`;

export const CompanyProductLink = styled(
  Link
)<ComponentPresentInWeddingListProps>`
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: left;
  font-size: 1.167rem;

  color: ${({ isPageWeddingList }) =>
    isPageWeddingList
      ? theme.colors.neutral.white
      : theme.colors.neutral["999"]};
  cursor: pointer;

  &:last-child {
    span {
      display: none;
    }
  }

  @media (max-width: 820px) {
    font-size: 1rem !important;
    gap: 1rem;
  }
`;

export const DividerCompanyProducts = styled.span<ComponentPresentInWeddingListProps>`
  margin-left: 1rem;

  color: ${({ isPageWeddingList }) =>
    isPageWeddingList
      ? theme.colors.neutral.white
      : theme.colors.neutral["999"]};
`;

type ContentLocationProps = {
  arrowDownColor?: string;
};

export const ContentLocation = styled(FlexContainer)<ContentLocationProps>`
  position: relative;
  cursor: pointer;
  align-items: center;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-top: 7px solid;
    border-top-color: inherit;
    border-left: 4px solid transparent;
    position: relative;
    left: 8px;
    align-self: center;
    top: 1px;
    color: ${({ arrowDownColor }) => arrowDownColor || "initial"};
  }
`;

export const RegionText = styled.span<ComponentPresentInWeddingListProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ isPageWeddingList }) =>
    isPageWeddingList
      ? theme.colors.neutral.white
      : theme.colors.neutral["900"]};
  font-size: 0.825rem;
  position: relative;
  height: 2.7rem;
`;

export const RegionClientLocation = styled.strong<ComponentPresentInWeddingListProps>`
  margin-left: 4px;
  color: ${({ isPageWeddingList }) =>
    isPageWeddingList
      ? theme.colors.neutral.white
      : theme.colors.neutral["999"]};
`;

export const LocationIcon = styled(MapPin)`
  font-size: 1.125rem;
  margin-right: 4px;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: 1rem;
  }
`;
