import { buildTestIds, useAppContext } from "application";
import { ReactNode } from "react";
import {
  CloseModalGeneralIcon,
  CloseModalGeneralWrapper,
  DescriptionModalGeneral,
  HeaderModalGeneralContainer,
  IllustrationModalGeneralWrapper,
  ModalGeneralContainer,
  ModalGeneralContent,
  ModalGeneralRoot,
  TitleModalGeneral,
  stylesModalGeneral,
} from "./styles";

interface ModalGeneralProps {
  title: JSX.Element | string;
  bodyDescription?: JSX.Element | string;
  ariaLabelForModal: string;
  isOpen: boolean;
  illustration?: ReactNode;
  bodyContent?: JSX.Element;
  footerContent?: JSX.Element;
  onCloseModal?(): void;
  ariaHideApp?: boolean;
  maxWidth?: string;
  titleFontSize?: string;
  descriptionFontSize?: string;
  textAlign?: string;
  padding?: string;
  descriptionColor?: string;
  hideCloseButton?: boolean;
  alignItems?: string;
  contentGap?: string;
  noCloseButton?: boolean;
  hasTitleBorder?: boolean;
  containerGap?: string;
  centeredTitle?: boolean;
  hasHeader?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  fontWeight?: number;
  closeButtonSize?: number;
}

const ModalGeneral = ({
  title,
  bodyDescription,
  ariaLabelForModal,
  illustration,
  bodyContent,
  footerContent,
  isOpen,
  onCloseModal,
  ariaHideApp,
  maxWidth,
  titleFontSize,
  descriptionFontSize,
  padding,
  descriptionColor,
  textAlign,
  hideCloseButton,
  alignItems,
  contentGap,
  noCloseButton,
  centeredTitle,
  fontWeight,
  closeButtonSize,
  hasTitleBorder = true,
  containerGap = "1rem",
  hasHeader = true,
  shouldCloseOnOverlayClick = true,
}: ModalGeneralProps) => {
  const { isClientMobile } = useAppContext();

  return (
    <ModalGeneralRoot
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      contentLabel={ariaLabelForModal}
      onCloseModal={onCloseModal as () => void}
      styles={stylesModalGeneral(isClientMobile)}
      ariaHideApp={ariaHideApp}
      testId="modal-general-root"
      {...buildTestIds("modal-general-root")}
    >
      <ModalGeneralContainer
        padding={padding}
        maxWidth={maxWidth}
        containerGap={containerGap}
        {...buildTestIds("modal-general-container")}
      >
        {hasHeader && (
          <HeaderModalGeneralContainer
            {...buildTestIds("header-modal-general-container")}
            hasTitleBorder={hasTitleBorder}
          >
            <TitleModalGeneral
              fontWeight={fontWeight}
              centeredTitle={centeredTitle}
              titleFontSize={titleFontSize}
              {...buildTestIds("title-modal-general")}
            >
              {title}
            </TitleModalGeneral>
            {!noCloseButton && (
              <CloseModalGeneralWrapper
                {...buildTestIds("close-modal-general-wrapper")}
                onClick={onCloseModal}
                closeModalSize={closeButtonSize}
                hideCloseButton={hideCloseButton}
              >
                <CloseModalGeneralIcon closeButtonSize={closeButtonSize} />
              </CloseModalGeneralWrapper>
            )}
          </HeaderModalGeneralContainer>
        )}
        <ModalGeneralContent
          alignItems={alignItems}
          contentGap={contentGap}
          {...buildTestIds("modal-general-content")}
        >
          {illustration && (
            <IllustrationModalGeneralWrapper
              {...buildTestIds("illustration-modal-general-wrapper")}
            >
              {illustration}
            </IllustrationModalGeneralWrapper>
          )}
          <DescriptionModalGeneral
            descriptionColor={descriptionColor}
            textAlign={textAlign}
            descriptionFontSize={descriptionFontSize}
            {...buildTestIds("description-modal-general")}
          >
            {bodyDescription}
          </DescriptionModalGeneral>
          {bodyContent}
        </ModalGeneralContent>
        {footerContent}
      </ModalGeneralContainer>
    </ModalGeneralRoot>
  );
};

export type { ModalGeneralProps };

export { ModalGeneral };
