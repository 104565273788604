import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const OrderWaitingPayment = ({
  width = 44,
  height = 44,
  alt = "pedido aguardando pagamento",
  color = theme.colors.yellow["50"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="22" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5556 18.6667H13.1111C12.4978 18.6667 12 18.1689 12 17.5556V13.1111C12 12.4978 12.4978 12 13.1111 12H17.5556C18.1689 12 18.6667 12.4978 18.6667 13.1111V17.5556C18.6667 18.1689 18.1689 18.6667 17.5556 18.6667Z"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5556 32H13.1111C12.4978 32 12 31.5022 12 30.8889V26.4444C12 25.8311 12.4978 25.3333 13.1111 25.3333H17.5556C18.1689 25.3333 18.6667 25.8311 18.6667 26.4444V30.8889C18.6667 31.5022 18.1689 32 17.5556 32Z"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8889 18.6667H26.4444C25.8311 18.6667 25.3333 18.1689 25.3333 17.5556V13.1111C25.3333 12.4978 25.8311 12 26.4444 12H30.8889C31.5022 12 32 12.4978 32 13.1111V17.5556C32 18.1689 31.5022 18.6667 30.8889 18.6667Z"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 14.2222V12"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4444 22H23.1111C22.4978 22 22 21.5022 22 20.8889V18.6667"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1111 22H12"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3333 28.6667H23.1111C22.4978 28.6667 22 29.1644 22 29.7778V32"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 25.3333H28.6667"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 25.3333V30.8889C32 31.5022 31.5022 32 30.8889 32H25.3333"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3333 15.3222C15.33 15.3222 15.3278 15.3244 15.3278 15.3278C15.3278 15.3311 15.33 15.3333 15.3333 15.3333C15.3367 15.3333 15.3389 15.3311 15.3389 15.3278C15.3389 15.3244 15.3367 15.3222 15.3333 15.3222"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.6611 15.3278C28.6578 15.3278 28.6555 15.33 28.6555 15.3333C28.6555 15.3367 28.6578 15.3389 28.6611 15.3389C28.6644 15.3389 28.6666 15.3367 28.6666 15.3333C28.6666 15.33 28.6644 15.3278 28.6611 15.3278"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7722 21.9944C29.7689 21.9944 29.7667 21.9967 29.7667 22C29.7667 22.0033 29.7689 22.0056 29.7722 22.0056C29.7756 22.0056 29.7778 22.0033 29.7778 22C29.7778 21.9967 29.7756 21.9944 29.7722 21.9944"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.6611 28.6611C28.6578 28.6611 28.6555 28.6633 28.6555 28.6667C28.6555 28.67 28.6578 28.6722 28.6611 28.6722C28.6644 28.6722 28.6666 28.67 28.6666 28.6667C28.6666 28.6633 28.6644 28.6611 28.6611 28.6611"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3389 28.6611C15.3355 28.6611 15.3333 28.6633 15.3333 28.6667C15.3333 28.67 15.3355 28.6722 15.3389 28.6722C15.3422 28.6722 15.3444 28.67 15.3444 28.6667C15.3444 28.6633 15.3422 28.6611 15.3389 28.6611"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4444 22H18.6666"
        stroke={secondColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
