import { IEncryptionClient } from "app-domain";
import crypto from "crypto";

class Encryption implements IEncryptionClient {
  private key!: Buffer;

  private iv!: Buffer;

  initialize(keyValue: string, ivValue: string) {
    this.key = Buffer.from(keyValue, "hex");
    this.iv = Buffer.from(ivValue, "hex");
  }

  encrypt(data: string) {
    try {
      const cipher = crypto.createCipheriv("aes-256-cbc", this.key, this.iv);
      let encrypted = cipher.update(data, "utf-8", "hex");
      encrypted += cipher.final("hex");
      return encrypted;
    } catch {
      return "";
    }
  }

  decrypt(data: string) {
    try {
      const decipher = crypto.createDecipheriv(
        "aes-256-cbc",
        this.key,
        this.iv
      );
      let decrypted = decipher.update(data, "hex", "utf-8");
      decrypted += decipher.final("utf-8");
      return decrypted;
    } catch {
      return "";
    }
  }
}

export { Encryption };
