import { AccountDeletionIcon } from "./AccountDeletionIcon";
import { AddCard } from "./AddCard";
import { AddNewEnvironment } from "./AddNewEnvironment";
import { Alert } from "./Alert";
import { AlertWithStroke } from "./AlertWithStroke";
import { Alliances } from "./Alliances";
import { Amex } from "./Amex";
import { Apple } from "./Apple";
import { ArrowCarouselLeft } from "./ArrowCarouselLeft";
import { ArrowCarouselRight } from "./ArrowCarouselRight";
import { ArrowDown } from "./ArrowDown";
import { ArrowDownSolid } from "./ArrowDownSolid";
import { ArrowFilledDown } from "./ArrowFilledDown";
import { ArrowLeft } from "./ArrowLeft";
import { ArrowLeftIcon } from "./ArrowLeftIcon";
import { ArrowLength } from "./ArrowLength";
import { ArrowRight } from "./ArrowRight";
import { ArrowUp } from "./ArrowUp";
import { ArrowWidth } from "./ArrowWidth";
import { AtEmailIcon } from "./AtEmailICon";
import { AuthenticationIcon } from "./AuthenticationIcon";
import { Bag } from "./Bag";
import { BagIcon } from "./BagIcon";
import { Bin } from "./Bin";
import { BlackFridayBag } from "./BlackFridayBag";
import { BoxGift } from "./BoxGift";
import { BreadcrumbArrowIcon } from "./BreadcrumbArrowIcon";
import { CalendarChecklistIcon } from "./CalendarChecklistIcon";
import { CalendarIcon } from "./CalendarIcon";
import { CalendarOutlineIcon } from "./CalendarOutlineIcon";
import { Camera } from "./Camera";
import { CancellationIcon } from "./CancellationIcon";
import { Card } from "./Card";
import { CardHeart } from "./CardHeart";
import { CardIcon } from "./CardIcon";
import { Cart } from "./Cart";
import { CartIcon } from "./CartIcon";
import { CartLogoFCBlack } from "./CartLogoFCBlack";
import { CartOutlineIcon } from "./CartOutlineIcon";
import { ChangeAddressIcon } from "./ChangeAddressIcon";
import { ChangePasswordIcon } from "./ChangePasswordIcon";
import { Check } from "./Check";
import { CheckDone } from "./CheckDone";
import { CheckIcon } from "./CheckIcon";
import { CheckValidateIcon } from "./CheckValidateIcon";
import { CheckmarkCircle } from "./CheckmarkCircle";
import { CircleCheckIcon } from "./CircleCheckIcon";
import { CircleErrorIcon } from "./CircleErrorIcon";
import { CircularTelephoneIcon } from "./CircularTelephoneIcon";
import { ClientIcon } from "./ClientIcon";
import { Clock } from "./Clock";
import { ClockIcon } from "./ClockIcon";
import { Close } from "./Close";
import { Coinpig } from "./Coinpig";
import { Copy } from "./Copy";
import { CopyIcon } from "./CopyIcon";
import { CouponTag } from "./CouponTag";
import { CouponTicket } from "./CouponTicket";
import { CreditCard } from "./CreditCard";
import { DefaultCard } from "./DefaultCard";
import { DefaultProductImage } from "./DefaultProduct";
import { DeliveredPackageTrackingStatusIcon } from "./DeliveredPackageTrackingStatusIcon";
import { DeliveringTrackingStatusIcon } from "./DeliveringTrackingStatusIcon";
import { DeliveryTruckPinIcon } from "./DeliveryTruckPinIcon";
import { DeviceAccountIcon } from "./DeviceAccountIcon";
import { DeviceCheck } from "./DeviceCheck";
import { DeviceCheckError } from "./DeviceCheckError";
import { DevicePasswordChange } from "./DevicePasswordChange";
import { DevicePasswordChanged } from "./DevicePasswordChanged";
import { Diners } from "./Diners";
import { DollarCircle } from "./DollarCircle";
import { DollarIcon } from "./DollarIcon";
import { DoubleArrowDown } from "./DoubleArrowDown";
import { DoubleCard } from "./DoubleCard";
import { DoubtsIcon } from "./DoubtsIcon";
import { Edit } from "./Edit";
import { EditPencilIcon } from "./EditPencilIcon";
import { EditRight } from "./EditRight";
import { Elo } from "./Elo";
import { EmailFailureIcon } from "./EmailFailureIcon";
import { EmailIcon } from "./EmailIcon";
import { EmailLetterMessage } from "./EmailLetterMessage";
import { EmailValidationIcon } from "./EmailValidationIcon";
import { EmployeeIcon } from "./EmployeeIcon";
import { EraseIcon } from "./EraseIcon";
import { ErrorIcon } from "./ErrorIcon";
import { EyeClose } from "./EyeClose";
import { EyeOpen } from "./EyeOpen";
import { FCLogoBlackFriday } from "./FCLogoBlackFriday";
import { Facebook } from "./Facebook";
import { FacebookIconFooter } from "./FacebookIconFooter";
import { FailureIcon } from "./FailureIcon";
import { FailurePaperAirplaneIcon } from "./FailurePaperAirplaneIcon";
import { File } from "./File";
import { Filter } from "./Filter";
import { FurnitureGiftIcon } from "./FurnitureGiftIcon";
import { FurnitureGiftSmallIcon } from "./FurnitureGiftSmallIcon";
import { GeometricBoxIcon } from "./GeometricBoxIcon";
import { Gift } from "./Gift";
import { GiftCardWeddingListHome } from "./GiftCardWeddingListHome";
import { GiftIcon } from "./GiftIcon";
import { GiftIconActivityHistory } from "./GiftIconActivityHistory";
import { GiftIconNew } from "./GiftIconNew";
import { GiftOutlineIcon } from "./GiftOutlineIcon";
import { Google } from "./Google";
import { GoogleColored } from "./GoogleColored";
import { GreenAddIcon } from "./GreenAddIcon";
import { HamburgerIcon } from "./HamburgerIcon";
import { HandPackageDeliveryIcon } from "./HandPackageDeliveryIcon";
import { HeadPhone } from "./HeadPhone";
import { Heart } from "./Heart";
import { HeartIcon } from "./HeartIcon";
import { Help } from "./Help";
import { Hiper } from "./Hiper";
import { Hipercard } from "./Hipercard";
import { Home } from "./Home";
import { HomeIcon } from "./HomeIcon";
import { HomeImprovementsIcon } from "./HomeImprovementsIcon";
import { HomeImprovementsSmallIcon } from "./HomeImprovementsSmallIcon";
import { House } from "./House";
import { HouseIcon } from "./HouseIcon";
import { IconLeftBottom } from "./IconLeftBottom";
import { IconLeftBottomMobile } from "./IconLeftBottomMobile";
import { IconRightTop } from "./IconRightTop";
import { IconRightTopMobile } from "./IconRightTopMobile";
import { IconStore } from "./IconStore";
import { IllustrationSendEmail } from "./IllustrationSendEmail";
import { ImageCardVirtualMyCards } from "./ImageCardVirtualMyCards";
import { ImagePhoneWhereToUse } from "./ImagePhoneWhereToUse";
import { IndifferentFaceIcon } from "./IndifferentFaceIcon";
import { InfoOutlineIcon } from "./InfoOutlineIcon";
import { Instagram } from "./Instagram";
import { InstagramIconFooter } from "./InstagramIconFooter";
import { Interrogation } from "./Interrogation";
import { InvoicedTrackingStatusIcon } from "./InvoicedTrackingStatusIcon";
import { Jcb } from "./Jcb";
import { LinkedIn } from "./LinkedIn";
import { LinkedinIconFooter } from "./LinkedinIconFooter";
import { Lock } from "./Lock";
import { LockIcon } from "./LockIcon";
import { LogoFCBlack } from "./LogoFCBlack";
import { LogoFerreiraCosta } from "./LogoFerreiraCosta";
import { Map } from "./Map";
import { MapPin } from "./MapPin";
import { Mastercard } from "./Mastercard";
import { MessagesIcon } from "./MessagesIcon";
import { MyListFingerOnPhone } from "./MyListFingerOnPhone";
import { MyListFingerPoiting } from "./MyListFingerPoiting";
import { MyListThumbsUp } from "./MyListThumbsUp";
import { NewEyeCloseIcon } from "./NewEyeCloseIcon";
import { NewEyeOpenIcon } from "./NewEyeOpenIcon";
import { NewGiftIcon } from "./NewGiftIcon";
import { NewShareIcon } from "./NewShareIcon";
import { NoAddress } from "./NoAddress";
import { NoOrder } from "./NoOrder";
import { NoOrderIcon } from "./NoOrderIcon";
import { NoPackages } from "./NoPackages";
import { OfflineIcon } from "./OfflineIcon";
import { OpsIcon } from "./OpsIcon";
import { OrderApproved } from "./OrderApproved";
import { OrderCancelled } from "./OrderCancelled";
import { OrderCancelledIcon } from "./OrderCancelledIcon";
import { OrderCancelling } from "./OrderCancelling";
import { OrderDelivered } from "./OrderDelivered";
import { OrderDelivering } from "./OrderDelivering";
import { OrderWaitingPayment } from "./OrderWaitingPayment";
import { OrderWithdrawAvailable } from "./OrderWithdrawAvailable";
import { OutForDeliveryTrackingStatusIcon } from "./OutForDeliveryTrackingStatusIcon";
import { Package } from "./Package";
import { PackageIcon } from "./PackageIcon";
import { PackageThumbsUpIcon } from "./PackageThumbsUpIcon";
import { PasteIcon } from "./PasteIcon";
import { PaymentCancelledIcon } from "./PaymentCancelledIcon";
import { PaymentDoneIcon } from "./PaymentDoneIcon";
import { PaymentMethods } from "./PaymentMethods";
import { Pencil } from "./Pencil";
import { PercentageDiscount } from "./PercentageDiscount";
import { Phone } from "./Phone";
import { PhoneCallIcon } from "./PhoneCallIcon";
import { PickupStore } from "./PickUpStore";
import { PigIcon } from "./PigIcon";
import { PinLocation } from "./PinLocation";
import { PinLocationOutline } from "./PinLocationOutline";
import { PinterestIcon } from "./PinterestIcon";
import { PixIcon } from "./PixIcon";
import { PlusInCircleIcon } from "./PlusInCircleIcon";
import { PreparingTrackingStatusIcon } from "./PreparingTrackingStatusIcon";
import { PrintedInvoiceIcon } from "./PrintedInvoiceIcon";
import { ProfileIcon } from "./ProfileIcon";
import { QrCode } from "./QrCode";
import { Question } from "./Question";
import { ReadyForPickupTrackingStatusIcon } from "./ReadyForPickupTrackingStatusIcon";
import { ReportSecurityProblemIcon } from "./ReportSecurityProblemIcon";
import { RequestErrorIcon } from "./RequestErrorIcon";
import { ResumeOrder } from "./ResumeOrder";
import { RightArrowProduct } from "./RightArrowProduct";
import { RotateIcon } from "./RotateIcon";
import { SaleTagIcon } from "./SaleTagIcon";
import { SatisfiedFaceIcon } from "./SatisfiedFaceIcon";
import { Search } from "./Search";
import { SearchBagIcon } from "./SearchBagIcon";
import { SearchBoxIcon } from "./SearchBoxIcon";
import { SearchIcon } from "./SearchIcon";
import { SecurityLockIcon } from "./SecurityLockIcon";
import { Settings } from "./Settings";
import { SettingsIcon } from "./SettingsIcon";
import { Share } from "./Share";
import { ShieldUserProtection } from "./ShieldUserProtection";
import { ShopIcon } from "./ShopIcon";
import { ShoppingBagIcon } from "./ShoppingBagIcon";
import { ShoppingCartIcon } from "./ShoppingCartIcon";
import { SingleUserIcon } from "./SingleUserIcon";
import { SocialMedia } from "./SocialMedia";
import { Spotify } from "./Spotify";
import { SpotifyIconFooter } from "./SpotifyIconFooter";
import { Star } from "./Star";
import { StarIcon } from "./StarIcon";
import { StatusIcon } from "./StatusIcon";
import { Store } from "./Store";
import { SuccessIcon } from "./SuccessIcon";
import { SuccessSyncIcon } from "./SuccessSyncIcon";
import { TelephoneAuthenticationIcon } from "./TelephoneAuthenticationIcon";
import { TelephoneErrorIcon } from "./TelephoneErrorIcon";
import { ThreeDotsHorizontal } from "./ThreeDotsHorizontal";
import { Truck } from "./Truck";
import { TruckFC } from "./TruckFC";
import { TwoStepVerificationIcon } from "./TwoStepVerificationIcon";
import { UnavailableIcon } from "./UnavailableIcon";
import { UniqueClientImage } from "./UniqueClientImage";
import { UniqueClientMobileImage } from "./UniqueClientMobileImage";
import { UniqueClientSecondaryMobileImage } from "./UniqueClientSecondaryMobileImage";
import { UniqueClientXSMobileImage } from "./UniqueClientXSMobileImage";
import { UnsatisfiedFaceIcon } from "./UnsatisfiedFaceIcon";
import { UserCardIcon } from "./UserCardIcon";
import { UserIcon } from "./UserIcon";
import { ValidIcon } from "./ValidIcon";
import { VerySatisfiedFaceIcon } from "./VerySatisfiedFaceIcon";
import { VeryUnsatisfiedFaceIcon } from "./VeryUnsatisfiedFaceIcon";
import { Visa } from "./Visa";
import { WarningIcon } from "./WarningIcon";
import { WarningRevalidationIcon } from "./WarningRevalidationIcon";
import { WarningValidateIcon } from "./WarningValidateIcon";
import { WeddingRingsIcon } from "./WeddingRingsIcon";
import { WeddingRingsDefaultIcon } from "./WeddingRingsDefaultIcon";
import { WeighIcon } from "./WeighIcon";
import { WhatsApp } from "./WhatsApp";
import { WhatsappIcon } from "./WhatsappIcon";
import { YoutubeIcon } from "./YoutubeIcon";
import { ShoppingFlowIcon } from "./ShoppingFlowIcon";
import { Wallet } from "./Wallet";
import { CloseIcon } from "./CloseIcon";

export {
  AccountDeletionIcon,
  AddCard,
  AddNewEnvironment,
  Alert,
  AlertWithStroke,
  Alliances,
  Amex,
  Apple,
  ArrowCarouselLeft,
  ArrowCarouselRight,
  ArrowDown,
  ArrowDownSolid,
  ArrowFilledDown,
  ArrowLeft,
  ArrowLeftIcon,
  ArrowLength,
  ArrowRight,
  ArrowUp,
  ArrowWidth,
  AtEmailIcon,
  AuthenticationIcon,
  Bag,
  BagIcon,
  Bin,
  BlackFridayBag,
  BoxGift,
  BreadcrumbArrowIcon,
  CalendarChecklistIcon,
  CalendarIcon,
  CalendarOutlineIcon,
  Camera,
  CancellationIcon,
  Card,
  CardHeart,
  CardIcon,
  Cart,
  CartIcon,
  CartLogoFCBlack,
  CartOutlineIcon,
  ChangeAddressIcon,
  ChangePasswordIcon,
  Check,
  CheckDone,
  CheckIcon,
  CheckmarkCircle,
  CheckValidateIcon,
  CircleCheckIcon,
  CircleErrorIcon,
  CircularTelephoneIcon,
  ClientIcon,
  Clock,
  ClockIcon,
  Close,
  Coinpig,
  Copy,
  CopyIcon,
  CouponTag,
  CouponTicket,
  CreditCard,
  DefaultCard,
  DefaultProductImage,
  DeliveredPackageTrackingStatusIcon,
  DeliveringTrackingStatusIcon,
  DeliveryTruckPinIcon,
  DeviceAccountIcon,
  DeviceCheck,
  DeviceCheckError,
  DevicePasswordChange,
  DevicePasswordChanged,
  Diners,
  DollarCircle,
  DollarIcon,
  DoubleArrowDown,
  DoubleCard,
  DoubtsIcon,
  Edit,
  EditPencilIcon,
  EditRight,
  Elo,
  EmailFailureIcon,
  EmailIcon,
  EmailLetterMessage,
  EmailValidationIcon,
  EmployeeIcon,
  EraseIcon,
  ErrorIcon,
  EyeClose,
  EyeOpen,
  Facebook,
  FacebookIconFooter,
  FailureIcon,
  FailurePaperAirplaneIcon,
  FCLogoBlackFriday,
  File,
  Filter,
  FurnitureGiftIcon,
  FurnitureGiftSmallIcon,
  GeometricBoxIcon,
  Gift,
  GiftCardWeddingListHome,
  GiftIcon,
  GiftIconActivityHistory,
  GiftIconNew,
  GiftOutlineIcon,
  Google,
  GoogleColored,
  GreenAddIcon,
  HamburgerIcon,
  HandPackageDeliveryIcon,
  HeadPhone,
  Heart,
  HeartIcon,
  Help,
  Hiper,
  Hipercard,
  Home,
  HomeIcon,
  HomeImprovementsIcon,
  HomeImprovementsSmallIcon,
  House,
  HouseIcon,
  IconLeftBottom,
  IconLeftBottomMobile,
  IconRightTop,
  IconRightTopMobile,
  IconStore,
  IllustrationSendEmail,
  ImageCardVirtualMyCards,
  ImagePhoneWhereToUse,
  IndifferentFaceIcon,
  InfoOutlineIcon,
  Instagram,
  InstagramIconFooter,
  Interrogation,
  InvoicedTrackingStatusIcon,
  Jcb,
  LinkedIn,
  LinkedinIconFooter,
  Lock,
  LockIcon,
  LogoFCBlack,
  LogoFerreiraCosta,
  Map,
  MapPin,
  Mastercard,
  MessagesIcon,
  MyListFingerOnPhone,
  MyListFingerPoiting,
  MyListThumbsUp,
  NewEyeCloseIcon,
  NewEyeOpenIcon,
  NewGiftIcon,
  NewShareIcon,
  NoAddress,
  NoOrder,
  NoOrderIcon,
  NoPackages,
  OfflineIcon,
  OpsIcon,
  OrderApproved,
  OrderCancelled,
  OrderCancelledIcon,
  OrderCancelling,
  OrderDelivered,
  OrderDelivering,
  OrderWaitingPayment,
  OrderWithdrawAvailable,
  OutForDeliveryTrackingStatusIcon,
  Package,
  PackageIcon,
  PackageThumbsUpIcon,
  PasteIcon,
  PaymentCancelledIcon,
  PaymentDoneIcon,
  PaymentMethods,
  Pencil,
  PercentageDiscount,
  Phone,
  PhoneCallIcon,
  PickupStore,
  PigIcon,
  PinLocation,
  PinLocationOutline,
  PinterestIcon,
  PixIcon,
  PlusInCircleIcon,
  PreparingTrackingStatusIcon,
  PrintedInvoiceIcon,
  ProfileIcon,
  QrCode,
  Question,
  ReadyForPickupTrackingStatusIcon,
  ReportSecurityProblemIcon,
  RequestErrorIcon,
  ResumeOrder,
  RightArrowProduct,
  RotateIcon,
  SaleTagIcon,
  SatisfiedFaceIcon,
  Search,
  SearchBagIcon,
  SearchBoxIcon,
  SearchIcon,
  SecurityLockIcon,
  Settings,
  SettingsIcon,
  Share,
  ShieldUserProtection,
  ShopIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  SingleUserIcon,
  SocialMedia,
  Spotify,
  SpotifyIconFooter,
  Star,
  StarIcon,
  StatusIcon,
  Store,
  SuccessIcon,
  TelephoneAuthenticationIcon,
  TelephoneErrorIcon,
  ThreeDotsHorizontal,
  Truck,
  TruckFC,
  TwoStepVerificationIcon,
  UnavailableIcon,
  UniqueClientImage,
  UniqueClientMobileImage,
  UniqueClientSecondaryMobileImage,
  UniqueClientXSMobileImage,
  UnsatisfiedFaceIcon,
  UserCardIcon,
  UserIcon,
  ValidIcon,
  VerySatisfiedFaceIcon,
  VeryUnsatisfiedFaceIcon,
  Visa,
  WarningIcon,
  WarningRevalidationIcon,
  WarningValidateIcon,
  WeddingRingsIcon,
  WeddingRingsDefaultIcon,
  WeighIcon,
  WhatsApp,
  WhatsappIcon,
  YoutubeIcon,
  ShoppingFlowIcon,
  SuccessSyncIcon,
  Wallet,
  CloseIcon,
};

export * from "./AddressListIcon";
export * from "./AlertWithDetail";
export * from "./AuthMethodsIcons";
export * from "./Banknote";
export * from "./BanknoteInput";
export * from "./Barcode";
export * from "./CPF";
export * from "./CallIcon";
export * from "./CenteredUserIcon";
export * from "./CheckPayment";
export * from "./EmailValidationError";
export * from "./ExclusionAccountFailedIcon";
export * from "./ExclusionAccountSuccessIcon";
export * from "./FCCredit";
export * from "./ListIcon";
export * from "./PackageOutlined";
export * from "./PhoneNumberValidationError";
export * from "./PixIconWithBG";
export * from "./SecurityIcon";
export * from "./Smartphone";
export * from "./TwoCardsIcon";
export * from "./TrackingStatusDeliveryManArrivedStore";
export * from "./TrackingStatusDeliveryManAtPlace";
export * from "./TrackingStatusDeliveryManOnTheWay";
export * from "./TrackingStatusOrderBeignSeparated";
export * from "./TrackingStatusOrderCanceled";
export * from "./TrackingStatusOrderInvoiced";
export * from "./TrackingStatusOrderNewDeliveryMan";
export * from "./TrackingStatusOrderOnTheCarrier";
export * from "./TrackingStatusOrderReceived";
export * from "./TrackingStatusPickingAvailable";
export * from "./TrackingStatusRescheduledOrder";
export * from "./TrackingStatusOrderOutForDelivery";

//  Este tipo é global para todos os SVGs desta arquivo, ela está sendo importada para todos.
export interface IconProps {
  width?: number | string;
  height?: number | string;
  // eslint-disable-next-line react/no-unused-prop-types
  codeMyCard?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  color?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  strokeWidth?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  click?: () => void;
  alt?: string;
}

export interface MultipleColorsIconProps extends IconProps {
  secondColor?: string;
}

// NÃO COLOCAR NENHUM ÍCONE ABAIXO DESSA LINHA. TODOS OS ÍCONES NOVOS DEVEM SER CRIADOS ACIMA E, POSTERIORMENTE, INSERIDOS ABAIXO NA FUNÇÃO
type IconsTable = { [key: string]: JSX.Element };

export const mappedIcons = (
  title: string,
  iconProps: IconProps,
  multipleColorsIconProps: MultipleColorsIconProps
) => {
  const iconsTable: IconsTable = {
    arrowDown: ArrowDown(iconProps),
    arrowDownSolid: ArrowDownSolid(iconProps),
    arrowUp: ArrowUp(iconProps),
    defaultProduct: DefaultProductImage(iconProps),
    arrowLeft: ArrowLeft(iconProps),
    arrowRight: ArrowRight(iconProps),
    bin: Bin(iconProps),
    phone: Phone(iconProps),
    headPhone: HeadPhone(iconProps),
    share: Share(iconProps),
    facebook: Facebook(iconProps),
    instagram: Instagram(iconProps),
    spotify: Spotify(iconProps),
    linkedIn: LinkedIn(iconProps),
    store: Store(iconProps),
    imageCardVirtualMyCards: ImageCardVirtualMyCards(iconProps),
    settings: Settings(iconProps),
    apple: Apple(iconProps),
    google: Google(iconProps),
    search: Search(iconProps),
    lock: Lock(iconProps),
    question: Question(iconProps),
    coinPig: Coinpig(iconProps),
    creditCard: CreditCard(iconProps),
    gift: Gift(iconProps),
    close: Close(iconProps),
    user: UserIcon(iconProps),
    dollarCircle: DollarCircle(iconProps),
    eyeOpen: EyeOpen(iconProps),
    eyeClose: EyeClose(iconProps),
    interrogation: Interrogation(iconProps),
    alert: Alert(iconProps),
    filter: Filter(iconProps),
    bag: Bag(iconProps),
    house: House(iconProps),
    cart: Cart(iconProps),
    card: Card(iconProps),
    alertWithStroke: AlertWithStroke(iconProps),
    checkDone: CheckDone(iconProps),
    whatsApp: WhatsApp(iconProps),
    home: Home(iconProps),
    percentageDiscount: PercentageDiscount(iconProps),
    saleTagIcon: SaleTagIcon(iconProps),
    myListThumbsUp: MyListThumbsUp(iconProps),
    myListFingerPoiting: MyListFingerPoiting(iconProps),
    myListFingerOnPhone: MyListFingerOnPhone(iconProps),
    pencil: Pencil(iconProps),
    giftIconActivityHistory: GiftIconActivityHistory(iconProps),
    clock: Clock(iconProps),
    unavailable: UnavailableIcon(iconProps),
    geometricBox: GeometricBoxIcon(iconProps),
    validIcon: ValidIcon(iconProps),
    warningIcon: WarningIcon(iconProps),
    phoneCallIcon: PhoneCallIcon(iconProps),
    atEmailIcon: AtEmailIcon(iconProps),
    youtube: YoutubeIcon(multipleColorsIconProps),
    pinterest: PinterestIcon(multipleColorsIconProps),
    pickupStore: PickupStore(iconProps),
    pinLocationOutline: PinLocationOutline(iconProps),
    calendarOutlineIcon: CalendarOutlineIcon(iconProps),
    infoOutlineIcon: InfoOutlineIcon(iconProps),
    giftOutlineIcon: GiftOutlineIcon(iconProps),
    cartOutlineIcon: CartOutlineIcon(iconProps),
  };
  return iconsTable[`${title}`] || null;
};
