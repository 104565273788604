const getMeasuredUnit = (measuredUnity: string) => {
  if (measuredUnity === "m²") {
    return `/${measuredUnity}`;
  }

  if (measuredUnity === "Rl") {
    return "/rolo";
  }

  return "";
};

export { getMeasuredUnit };
