import { IconProps } from "typing";
import { theme } from "../themes";

export const PixIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.secondary["450"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57383 5.62665C6.03687 5.62542 6.49556 5.71597 6.92338 5.89308C7.35121 6.07019 7.73969 6.33034 8.06637 6.65849L11.6789 10.2718C11.8045 10.397 11.9746 10.4673 12.1519 10.4673C12.3292 10.4673 12.4993 10.397 12.6249 10.2718L16.2245 6.67218C16.5509 6.34361 16.9393 6.08314 17.3672 5.90588C17.7951 5.72863 18.2539 5.63811 18.7171 5.63958H19.1505L14.5791 1.06816C14.2405 0.729517 13.8386 0.460887 13.3963 0.27761C12.9539 0.0943327 12.4798 0 12.001 0C11.5222 0 11.048 0.0943327 10.6057 0.27761C10.1633 0.460887 9.7614 0.729517 9.42289 1.06816L4.8682 5.62665H5.57383ZM18.7171 18.3585C18.2542 18.3602 17.7957 18.2701 17.3678 18.0935C16.94 17.917 16.5514 17.6573 16.2245 17.3297L12.6241 13.727C12.4968 13.6052 12.3273 13.5373 12.1511 13.5373C11.9749 13.5373 11.8055 13.6052 11.6782 13.727L8.06561 17.3396C7.73891 17.6677 7.35042 17.9278 6.9226 18.1049C6.49478 18.282 6.0361 18.3726 5.57307 18.3714H4.8682L9.4305 22.9337C10.1147 23.6165 11.0419 24 12.0086 24C12.9752 24 13.9024 23.6165 14.5867 22.9337L19.1581 18.3615L18.7171 18.3585ZM20.1694 6.65924L22.9318 9.42172V9.42096C23.2705 9.75947 23.5391 10.1614 23.7224 10.6037C23.9057 11.0461 24 11.5202 24 11.999C24 12.4779 23.9057 12.952 23.7224 13.3943C23.5391 13.8367 23.2705 14.2386 22.9318 14.5771L20.1694 17.3396C20.1071 17.3139 20.0405 17.3005 19.9732 17.3H18.717C18.0634 17.2984 17.4368 17.0388 16.9735 16.5777L13.3738 12.9788C12.7214 12.3264 11.5831 12.3264 10.93 12.9788L7.31738 16.5914C6.85416 17.0527 6.22758 17.3126 5.57382 17.3145H4.02568C3.96208 17.3155 3.8992 17.3281 3.84015 17.3518L1.06627 14.5779C0.38347 13.8936 0 12.9664 0 11.9998C0 11.0331 0.38347 10.106 1.06627 9.42172L3.84623 6.64708C3.90541 6.67029 3.96821 6.68291 4.03176 6.68434H5.57382C6.22759 6.68617 6.85421 6.94605 7.31738 7.40746L10.9307 11.0185C11.2549 11.3421 11.6942 11.5239 12.1523 11.5239C12.6103 11.5239 13.0496 11.3421 13.3738 11.0185L16.9735 7.42115C17.4368 6.96002 18.0634 6.70042 18.717 6.69878H19.9732C20.0405 6.69813 20.1071 6.68471 20.1694 6.65924Z"
        fill={color}
      />
    </svg>
  );
};
