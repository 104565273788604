/* eslint-disable camelcase */

import { IAppMonitoringClient, IHttpClient } from "app-domain";
import { IApi } from "app-domain/abstractions/apis/IApi";
import {
  ChangeResponsiblePickupDto,
  ChangeResponsiblePickupResponse,
  ErrorDto,
  IHttpResponse,
  ReportSecurityProblemDto,
  ReportSecurityProblemResponseDto,
  SendTokenResponseDto,
  TwoFactorPublicCodeDto,
  TwoFactorPublicResponseDto,
  TwoFactorPublicResponseErrorDto,
  UserLoggedProps,
  UserNotificationSettings,
  UserNotificationSettingsProps,
} from "typing";
import { getApisUrlFallback, isObjectEmpty } from "../../../utils";
import { EnvsApi } from "../Envs";

class NotificationApi implements IApi {
  baseUrl = "";

  rootBaseUrl = "";

  constructor(
    private httpClient: IHttpClient,
    private appMonitoringClient: IAppMonitoringClient,
    private envsApi: EnvsApi
  ) {
    if (!this.baseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}notification/`;
      this.rootBaseUrl = apisBaseUrl || fallback;
    }

    this.appMonitoringClient = appMonitoringClient;
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getUserNotificationsSettingsEndpoint = () => {
    return `${this.baseUrl}v1/notification/user-notification-settings`;
  };

  getUserNotificationsSettings = (
    accessToken: string | undefined,
    condition = true
  ): IHttpResponse<UserNotificationSettings, unknown> => {
    const { data, error, isLoading } =
      this.httpClient.useGet<UserNotificationSettings>(
        this.getUserNotificationsSettingsEndpoint(),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        condition
      ) as IHttpResponse<UserNotificationSettings, unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  setUserNotificationsSettingsWhatsAppEndpoint() {
    return `${this.baseUrl}v1/notification/user-notification-settings`;
  }

  async setUserNotificationsSettingsWhatsApp(
    user: UserLoggedProps,
    data: UserNotificationSettingsProps
  ) {
    try {
      const response =
        (await this.httpClient.usePut<UserNotificationSettingsProps>(
          this.setUserNotificationsSettingsWhatsAppEndpoint(),
          {
            Authorization: `${user.token_type} ${user.access_token}`,
          },
          {
            notificationChannelId: data.notificationChannelId,
            notificationTypeId: data.notificationTypeId,
            receiveNotification: data.receiveNotification,
          }
        )) as UserNotificationSettingsProps;

      return { data: response, error: null };
    } catch (err) {
      const error = isObjectEmpty(err as { [key: string]: string })
        ? null
        : JSON.stringify(err);
      this.appMonitoringClient.captureException(
        error ??
          "Erro ao salvar aceite de termos de acompanhar pedido por WhatsApp"
      );
      return { data: null, error };
    }
  }

  sendTwoFactorSMSEndpoint = (type: 1 | 2 | 3) => {
    return `${this.baseUrl}v1/2fa/type/${type}/send`;
  };

  public async sendTwoFactorSMS(type: 1 | 2 | 3, jwt: string) {
    try {
      const response = await this.httpClient.usePost(
        this.sendTwoFactorSMSEndpoint(type),
        {
          type,
          jwt,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      return response as unknown as IHttpResponse<
        SendTokenResponseDto,
        ErrorDto
      >;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        status: "",
      };
    }
  }

  sendTwoFactorEmailEndpoint = (type: number, email: string) => {
    return `${this.baseUrl}v1/2fa/type/${type}/email/${email}/send`;
  };

  public async sendTwoFactorEmail(type: number, email: string) {
    try {
      const response = (await this.httpClient.usePost(
        this.sendTwoFactorEmailEndpoint(type, email),
        {
          type,
          email,
        }
      )) as unknown as SendTokenResponseDto;

      return {
        isValidEmail: response?.validationResult?.isValid,
        isLoading: false,
        data: response,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return { isValidEmail: false, isLoading: false, error };
    }
  }

  validateTwoFactorTokenEmailEndpoint = (
    type: number,
    email: string,
    token: string
  ) => {
    return `${this.baseUrl}v1/2fa/type/${type}/email/${token}/validate?email=${email}`;
  };

  public async validateTwoFactorTokenEmail(
    type: number,
    email: string,
    token: string
  ): Promise<{ isValidEmail: boolean }> {
    try {
      const response = await this.httpClient.usePost(
        this.validateTwoFactorTokenEmailEndpoint(type, email, token),
        { type, email }
      );

      if (response?.isValid === true) {
        return { isValidEmail: true };
      }
      return { isValidEmail: false };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return { isValidEmail: false };
    }
  }

  validateTwoFactorSMSEndpoint = (type: 1 | 2 | 3, token: string) => {
    return `${this.baseUrl}v1/2fa/type/${type}/${token}/validate`;
  };

  public async validateTwoFactorSMS(
    type: 1 | 2 | 3,
    token: string,
    jwt: string
  ) {
    try {
      return (await this.httpClient.usePost(
        this.validateTwoFactorSMSEndpoint(type, token),
        {
          type,
          token,
          jwt,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )) as IHttpResponse<TwoFactorPublicResponseErrorDto, ErrorDto>;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        error,
        isLoading: false,
        status: "",
        errors: { Messages: [""] },
      };
    }
  }

  sendTwoFactorCodePublicEndpoint = () => {
    return `${this.baseUrl}v1/2fa/public/FAType/channel/send-token`;
  };

  public async sendTwoFactorCodePublic({
    channel,
    faType,
    cpf,
    email,
    phoneNumber,
    token,
    canSendToken = true,
  }: TwoFactorPublicCodeDto & {
    canSendToken?: boolean;
  }): Promise<
    IHttpResponse<TwoFactorPublicResponseDto, TwoFactorPublicResponseErrorDto>
  > {
    try {
      return (await this.httpClient.usePost(
        this.sendTwoFactorCodePublicEndpoint(),
        {
          email,
          cpf,
          faType,
          channel,
          phoneNumber,
          token,
        },
        {},
        canSendToken
      )) as IHttpResponse<
        TwoFactorPublicResponseDto,
        TwoFactorPublicResponseErrorDto
      >;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error as IHttpResponse<
        TwoFactorPublicResponseDto,
        TwoFactorPublicResponseErrorDto
      >;
    }
  }

  sendTwoFactorCodeEndpoint = () => {
    return `${this.baseUrl}v1/2fa/FAType/channel/send-token`;
  };

  public async sendTwoFactorCode({
    channel,
    faType,
    cpf,
    email,
    phoneNumber,
    token,
  }: TwoFactorPublicCodeDto) {
    try {
      const response = (await this.httpClient.usePost(
        this.sendTwoFactorCodeEndpoint(),
        {
          email,
          cpf,
          faType,
          channel,
          phoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )) as unknown as SendTokenResponseDto;

      if (response.errors) {
        throw new Error(response.errors.Messages[0]);
      }

      return {
        data: { ...response },
        error: null,
        isLoading: false,
        status: 200,
      } as IHttpResponse<SendTokenResponseDto, unknown>;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: {
          validationResult: {
            isValid: false,
          },
        },
        error,
        isLoading: false,
        status: 400,
      } as IHttpResponse<SendTokenResponseDto, unknown, string[]>;
    }
  }

  sendTokenForSMSEndpoint = (type: number, phone: string) => {
    return `${this.baseUrl}v1/2fa/type/${type}/phone/${phone}/send`;
  };

  sendTokenForSMS = async (
    type: number,
    phone: string
  ): Promise<IHttpResponse<SendTokenResponseDto, SendTokenResponseDto>> => {
    try {
      return (await this.httpClient.usePost(
        this.sendTokenForSMSEndpoint(type, phone),
        {}
      )) as IHttpResponse<SendTokenResponseDto, SendTokenResponseDto>;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error as IHttpResponse<SendTokenResponseDto, SendTokenResponseDto>;
    }
  };

  validateTwoFactorCodePublicEndpoint = () => {
    return `${this.baseUrl}v1/2fa/public/FAType/token/validate-token`;
  };

  public async validateTwoFactorCodePublic({
    email,
    cpf,
    faType,
    channel,
    token,
    phoneNumber,
  }: TwoFactorPublicCodeDto): Promise<
    IHttpResponse<TwoFactorPublicResponseDto, TwoFactorPublicResponseErrorDto>
  > {
    try {
      return (await this.httpClient.usePost(
        this.validateTwoFactorCodePublicEndpoint(),
        {
          email,
          cpf,
          faType,
          channel,
          token,
          phoneNumber,
        }
      )) as IHttpResponse<
        TwoFactorPublicResponseDto,
        TwoFactorPublicResponseErrorDto
      >;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error as IHttpResponse<
        TwoFactorPublicResponseDto,
        TwoFactorPublicResponseErrorDto
      >;
    }
  }

  validateTwoFactorCodeEndpoint = () => {
    return `${this.baseUrl}v1/2fa/FAType/token/validate-token`;
  };

  public async validateTwoFactorCode({
    email,
    cpf,
    faType,
    channel,
    token,
    phoneNumber,
    jwt,
  }: TwoFactorPublicCodeDto & { jwt?: string }): Promise<
    IHttpResponse<TwoFactorPublicResponseDto, TwoFactorPublicResponseErrorDto>
  > {
    try {
      return (await this.httpClient.usePost(
        this.validateTwoFactorCodeEndpoint(),
        {
          email,
          cpf,
          faType,
          channel,
          token,
          phoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )) as IHttpResponse<
        TwoFactorPublicResponseDto,
        TwoFactorPublicResponseErrorDto
      >;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return error as IHttpResponse<
        TwoFactorPublicResponseDto,
        TwoFactorPublicResponseErrorDto
      >;
    }
  }

  private getVerifyTokenEndpoint = (
    token: number,
    identifier: string,
    faType: number
  ) => {
    return `${this.baseUrl}v1/2fa/verifyToken/${token}/${identifier}/${faType}`;
  };

  public async getVerifyToken(
    token: number,
    identifier: string,
    faType: number
  ) {
    const response = await fetch(
      this.getVerifyTokenEndpoint(token, identifier, faType)
    );

    const data = await response.json();

    if (!response.ok) {
      return {
        data: null,
        error: response?.status,
        isLoading: false,
      };
    }

    return {
      data,
      isLoading: false,
      error: null,
    };
  }

  sendTwoFactorTokenByChannelEndpoint = () => {
    return `${this.baseUrl}v1/2fa/FAType/channel/send-token`;
  };

  public async sendTwoFactorTokenByChannel(
    jwt: string,
    body: TwoFactorPublicCodeDto
  ) {
    try {
      return await this.httpClient.usePost(
        this.sendTwoFactorTokenByChannelEndpoint(),
        body,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return { isValid: false, isLoading: false };
    }
  }

  reportSecurityProblemEndpoint = "/api/notification/report-security-issue";

  public async reportSecurityProblem({
    name,
    cpf,
    email,
    phoneNumber,
    reason,
    token,
    channel,
    faType,
    jwt,
    shouldReportProblem = false,
  }: ReportSecurityProblemDto & {
    jwt?: string;
    shouldReportProblem?: boolean;
  }): Promise<
    IHttpResponse<
      ReportSecurityProblemResponseDto,
      ReportSecurityProblemResponseDto
    >
  > {
    try {
      const response = (await this.httpClient.usePost(
        this.reportSecurityProblemEndpoint,
        { name, email, cpf, phoneNumber, reason, channel, token, faType },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
        shouldReportProblem
      )) as unknown as ReportSecurityProblemResponseDto;

      return {
        data: response,
        error: {
          errors: response?.errors,
          status: response?.status,
        },
        isLoading: false,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: { status: 400 },
        error: {
          errors: {
            Reason: error as Error,
          },
        },
        isLoading: false,
      };
    }
  }

  changeResponsiblePickupEndpoint = () => {
    return `${this.rootBaseUrl}bff-site/notification/FAType/channel/validate-token-picking`;
  };

  changeResponsiblePickup = async ({
    jwt,
    email,
    faType,
    token,
    channel,
  }: ChangeResponsiblePickupDto): Promise<
    IHttpResponse<
      ChangeResponsiblePickupResponse,
      ChangeResponsiblePickupResponse
    >
  > => {
    try {
      const response = (await this.httpClient.usePost(
        this.changeResponsiblePickupEndpoint(),
        {
          email,
          channel,
          phoneNumber: "",
          faType,
          token,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )) as ChangeResponsiblePickupResponse;

      return {
        data: {
          isValid: response.isValid,
        },
        error: {
          errors: response?.errors,
        },
        isLoading: false,
        status: response.status,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: { isValid: false },
        error: {
          errors: {
            Reason: error as Error,
          },
        },
        isLoading: false,
        status: 400,
      };
    }
  };
}

export { NotificationApi };
