import {
  ApiDefaultErrorDto,
  CategoryDto,
  CustomHighlightCategoryDto,
  CustomPageResponse,
  Dimension,
  FilterProductsByPlaylistDto,
  IHttpResponse,
  PlaylistProductsFilterDto,
  PlaylistUrlType,
  Price,
  Product,
  ProductCardDto,
  ProductSpec,
  RelatedCategory,
  RelatedProduct,
  SearchResultByTermDto,
  SuggestionListDto,
  UsefulLinks,
} from "typing";

import { IAppMonitoringClient, IHttpClient } from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import {
  formatBranchId,
  getApisUrlFallback,
  mapFilterProductsByPlaylistToSearchByTerm,
} from "../../../utils";
import { defaultHeaders } from "../../../utils/http";
import { EnvsApi } from "../Envs";

const headerPaginationTotalResultsStr = "x-pagination-totalresults";

export class CatalogApi implements IApi {
  baseUrl = "";

  baseUrlV2 = "";

  constructor(
    private httpClient: IHttpClient,
    private appMonitoringClient: IAppMonitoringClient,
    private envsApi: EnvsApi
  ) {
    if (!this.baseUrl || !this.baseUrlV2) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}catalog/`;
      this.baseUrlV2 = `${apisBaseUrl || fallback}bff-site/catalog/`;
    }

    this.appMonitoringClient = appMonitoringClient;
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getHomeCategoriesEndpoint = (parentId = 0, level = 3) => {
    return `${this.baseUrl}v1/playlist/home-categories?Platform=1&ParentId=${parentId}&Level=${level}`;
  };

  getHomeCategories = (
    parentId = 0,
    level = 3,
    condition = true
  ): IHttpResponse<CategoryDto[], unknown> => {
    const { data, error, isLoading, trigger } = this.httpClient.useGet<
      CategoryDto[]
    >(
      this.getHomeCategoriesEndpoint(parentId, level),

      {
        headers: {
          "Accept-Encoding": "gzip, deflate",
        },
      },
      condition
    ) as IHttpResponse<CategoryDto[], unknown>;

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  private getPlaylistProductsEndpoint = ({
    playlistId,
    branchId,
    currentPage = "1",
    pageSize = "45",
    category,
    brand,
    model,
    attrAgg,
    sortBy,
  }: PlaylistProductsFilterDto) => {
    const categoryQueryParam = category ? `&Category=${category}` : "";
    const brandQueryParam = brand ? `&Brand=${brand}` : "";
    const modelQueryParam = model ? `&Model=${model}` : "";
    const attrAggQueryParam = attrAgg ? `&AttrAgg=${attrAgg}` : "";
    const sortByQueryParam = sortBy ? `&SortBy=${sortBy}` : "";
    const branchIdValue = formatBranchId(branchId);

    return `${this.baseUrl}v1/playlist/home-category-products?PlaylistId=${playlistId}&BranchId=${branchIdValue}&PageIndex=${currentPage}&PageSize=${pageSize}${categoryQueryParam}${brandQueryParam}${modelQueryParam}${attrAggQueryParam}${sortByQueryParam}`;
  };

  getPlaylistProducts = (playlistProductsFIlter: PlaylistProductsFilterDto) => {
    return fetch(this.getPlaylistProductsEndpoint(playlistProductsFIlter), {
      headers: { ...defaultHeaders },
    })
      .then((response) => {
        if (response?.status === 204 || response?.status === 404) {
          return {
            data: null,
            error: "Nenhum produto foi retornado nessa playlist",
            totalRows: 0,
          };
        }

        return response
          .json()

          .then((responseJson) => {
            const jsonResponse = responseJson as FilterProductsByPlaylistDto;
            const data =
              mapFilterProductsByPlaylistToSearchByTerm(jsonResponse);

            return {
              data,
              totalRows:
                Number(response.headers.get(headerPaginationTotalResultsStr)) ||
                0,
              error: null,
            };
          })
          .catch((error) => {
            return {
              data: null,
              error: JSON.stringify(error),
              totalRows: 0,
            };
          });
      })
      .catch((error) => {
        this.appMonitoringClient.captureException(error);
        return {
          data: null,
          error: JSON.stringify(error),
          totalRows: 0,
        };
      });
  };

  getPlaylistProductsByTypeAndUrlEndpoint = (
    playlistType: PlaylistUrlType,
    playlistUrl: string,
    branchId: string,
    pageIndex?: string,
    pageSize = "45"
  ) => {
    const formattedBranchId = formatBranchId(branchId);
    return `${this.baseUrl}v1/playlist/${playlistType}/${playlistUrl}?branchId=${formattedBranchId}&pageIndex=${pageIndex}&PageSize=${pageSize}`;
  };

  getPlaylistProductsByTypeAndUrl = async (
    playlistType: PlaylistUrlType,
    playlistUrl: string,
    branchId: string,
    pageIndex?: string,
    pageSize?: string
  ): Promise<CustomPageResponse> => {
    try {
      const response = await fetch(
        this.getPlaylistProductsByTypeAndUrlEndpoint(
          playlistType,
          playlistUrl,
          branchId,
          pageIndex,
          pageSize
        ),
        {
          headers: { ...defaultHeaders },
        }
      );

      if (response.status === 204 || response.status === 404) {
        return {
          data: null,
          error: "Nenhum produto foi retornado nessa playlist",
          totalRows: 0,
        };
      }

      const jsonResponse =
        (await response.json()) as FilterProductsByPlaylistDto;
      const data = mapFilterProductsByPlaylistToSearchByTerm(jsonResponse);

      return {
        data,
        totalRows:
          Number(await response.headers.get(headerPaginationTotalResultsStr)) ||
          0,
        error: null,
      };
    } catch (err) {
      this.appMonitoringClient.captureException(err);
      return {
        data: null,
        error: JSON.stringify(err),
        totalRows: 0,
      };
    }
  };

  getPlaylistProductsByTypeAndUrlFilterEndpoint = (
    playlistType: PlaylistUrlType,
    playlistUrl: string,
    branchId: string,
    category?: string,
    brand?: string,
    model?: string,
    attrAgg?: string,
    pageIndex?: string,
    sortBy?: string,
    pageSize = "45"
  ) => {
    const categoryParam = category ? `&Category=${category}` : "";
    const brandParam = brand ? `&Brand=${brand}` : "";
    const modelParam = model ? `&Model=${model}` : "";
    const attrAggParam = attrAgg ? `&AttrAgg=${attrAgg}` : "";
    const sortByParam = sortBy ? `&SortBy=${sortBy}` : "";
    const formattedBranchId = formatBranchId(branchId);

    return `${this.baseUrl}v1/playlist/${playlistType}/${playlistUrl}?branchId=${formattedBranchId}${categoryParam}${brandParam}${modelParam}${attrAggParam}&PageIndex=${pageIndex}${sortByParam}&PageSize=${pageSize}`;
  };

  getPlaylistProductsByTypeAndUrlFilter = async (
    playlistType: PlaylistUrlType,
    playlistUrl: string,
    branchId: string,
    category?: string,
    brand?: string,
    model?: string,
    attrAgg?: string,
    pageIndex?: string,
    sortBy?: string,
    pageSize = "45"
  ) => {
    try {
      const response = await fetch(
        this.getPlaylistProductsByTypeAndUrlFilterEndpoint(
          playlistType,
          playlistUrl,
          branchId,
          category,
          brand,
          model,
          attrAgg,
          pageIndex,
          sortBy,
          pageSize
        ),
        {
          headers: { ...defaultHeaders },
        }
      );

      const jsonResponse =
        (await response.json()) as FilterProductsByPlaylistDto;
      const data = mapFilterProductsByPlaylistToSearchByTerm(jsonResponse);

      return {
        data,
        totalRows: await response.headers.get(headerPaginationTotalResultsStr),
        isLoading: false,
        error: null,
      };
    } catch (err) {
      this.appMonitoringClient.captureException(err);
      return {
        data: [],
        totalRows: 0,
        isLoading: false,
        error: err,
      };
    }
  };

  getProductSpecsEndpoint = (productId: string) => {
    return `${this.baseUrl}v1/products/${productId}/specs`;
  };

  getProductSpecs = (
    productId: string,
    condition = true
  ): IHttpResponse<ProductSpec[], unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<ProductSpec[]>(
      this.getProductSpecsEndpoint(productId),
      {},
      condition
    ) as IHttpResponse<ProductSpec[], unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  getProductDimensionEndpoint = (productId: string) => {
    return `${this.baseUrl}v1/products/${productId}/dimensions`;
  };

  getProductDimension = (
    productId: string,
    condition = true
  ): IHttpResponse<Dimension, unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<Dimension>(
      this.getProductDimensionEndpoint(productId),
      {},
      condition
    ) as IHttpResponse<Dimension, unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  getProductUseLinksEndpoint = (productId: string) => {
    return `${this.baseUrl}v1/products/${productId}/useful-links`;
  };

  getProductUseLinks = (
    productId: string,
    condition = true
  ): IHttpResponse<UsefulLinks[], unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<UsefulLinks[]>(
      this.getProductUseLinksEndpoint(productId),
      {},
      condition
    ) as IHttpResponse<UsefulLinks[], unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  private getRelatedCategoryEndpoint = (
    productId: string,
    branchId: string
  ) => {
    const formattedBranchId = formatBranchId(branchId);
    return `${this.baseUrl}v1/products/${productId}/related-category?BranchId=${formattedBranchId}`;
  };

  getRelatedCategory = (
    productId: string,
    branchId: string,
    condition = true
  ): IHttpResponse<RelatedCategory[], unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<
      RelatedCategory[]
    >(
      this.getRelatedCategoryEndpoint(productId, branchId),
      {},
      condition
    ) as IHttpResponse<RelatedCategory[], unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  private getProductsListEndpoint = (productIds: string, branchId: string) => {
    const formattedBranchId = formatBranchId(branchId);

    return `${this.baseUrl}v1/products/list?productIds=${productIds}&branchId=${formattedBranchId}`;
  };

  getProductList = (
    productIds: string,
    branchId: string,
    condition = false
  ): IHttpResponse<ProductCardDto[], unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<ProductCardDto[]>(
      this.getProductsListEndpoint(productIds, branchId),
      {},
      condition
    ) as IHttpResponse<ProductCardDto[], unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  getProductListServerSide = async (
    categoryId: string,
    branchId: string
  ): Promise<IHttpResponse<ProductCardDto[], unknown>> => {
    try {
      const response = await fetch(
        this.getProductsListEndpoint(categoryId, branchId),
        {
          headers: { ...defaultHeaders },
        }
      );

      return {
        data: await response.json(),
        isLoading: false,
        error: null,
      };
    } catch (error) {
      const errorMessage = `Ocorreu um erro ao obter os dados da branchId: ${branchId} e os produtos de IDs: ${categoryId} não foram preenchidos no transaction object para mapeamento da Insider.`;

      this.appMonitoringClient.captureMessage(errorMessage, {
        level: "warning",
        tags: {
          fcx_labs_event: "get_product_list",
          fcx_labs_error_source: "api",
        },
      });

      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  private getRelatedProductsEndpoint = (
    productId: string,
    branchId: string,
    categoryId: string,
    pageIndex: number,
    pageSize: number
  ) => {
    const formattedBranchId = formatBranchId(branchId);

    return `${this.baseUrl}v1/products/${productId}/related-products?BranchId=${formattedBranchId}&CategoryId=${categoryId}&PageIndex=${pageIndex}&PageSize=${pageSize}`;
  };

  getRelatedProducts = (
    productId: string,
    branchId: string,
    categoryId: string,
    pageIndex: number,
    pageSize: number,
    condition = true
  ): IHttpResponse<RelatedProduct[], unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<RelatedProduct[]>(
      this.getRelatedProductsEndpoint(
        productId,
        branchId,
        categoryId,
        pageIndex,
        pageSize
      ),
      {},
      condition
    ) as IHttpResponse<RelatedProduct[], unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  private getProductCardsEndpoint = (
    branchId: number,
    productIdsList: string[],
    pageIndex?: number,
    pageSize?: number,
    categoryId?: number
  ) => {
    let productIdsListStr = ``;

    (productIdsList || []).forEach((productId) => {
      productIdsListStr += `&ProductsIdsList=${productId}`;
    });

    const branchIdValue = formatBranchId(branchId.toString());

    return `${this.baseUrl}v1/products/cards?BranchId=${branchIdValue}${
      pageIndex ? `&PageIndex=${pageIndex}` : ""
    }${pageSize ? `&PageSize=${pageSize}` : ""}${
      categoryId ? `&CategoryId=${categoryId}` : ""
    }${productIdsListStr}`;
  };

  getProductCards = (
    branchId: number,
    productIdsList: string[],
    pageIndex?: number,
    pageSize?: number,
    categoryId?: number,
    condition = false
  ) => {
    const { data, error, isLoading } = this.httpClient.useGet<
      (ProductCardDto & { headers: { totalResults: number } })[]
    >(
      this.getProductCardsEndpoint(
        branchId,
        productIdsList,
        pageIndex,
        pageSize,
        categoryId
      ),
      {},
      condition
    ) as IHttpResponse<
      (ProductCardDto & { headers?: { totalResults: number } })[],
      unknown
    >;

    return {
      data,
      isLoading,
      error,
    };
  };

  getProductPricesEndpoint = (
    productId: number,
    branchId = 2,
    packingLevel = 0,
    sellerId = 0
  ) => {
    const branchIdValue = formatBranchId(branchId.toString());

    return `${this.baseUrl}v1/products/${productId}/prices?BranchId=${branchIdValue}&PackingLevel=${packingLevel}&SellerId=${sellerId}`;
  };

  getProductPrices = (
    productId: number,
    branchId: number,
    packingLevel: number,
    sellerId: number,
    condition = false
  ): IHttpResponse<Price[], unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<Price[]>(
      this.getProductPricesEndpoint(
        productId,
        branchId,
        packingLevel,
        sellerId
      ),
      {},
      condition
    ) as IHttpResponse<Price[], unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  getProductEndpoint = (productId: number, branchId = 2) => {
    const sanitizedProductId = String(productId).split(".")[0];
    const branchIdValue = formatBranchId(branchId.toString());

    return `${this.baseUrl}v1/products/id/${sanitizedProductId}?branchId=${branchIdValue}`;
  };

  fetchProduct = async (
    productId: number,
    branchId?: number
  ): Promise<IHttpResponse<Product | ApiDefaultErrorDto, unknown>> => {
    try {
      const response = await fetch(
        this.getProductEndpoint(productId, branchId),
        {
          headers: { ...defaultHeaders },
        }
      );

      if (!response?.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      if (response?.status === 204) {
        return {
          data: null,
          isLoading: false,
          error: null,
        };
      }

      const data: ApiDefaultErrorDto = await response.json();

      return {
        data,
        isLoading: false,
        error: null,
      };
    } catch (err) {
      this.appMonitoringClient.captureException(err, {
        level: "error",
        tags: {
          fcx_labs_event: "get_product",
          fcx_labs_error_source: "catalog_api",
        },
      });
      return {
        data: null,
        isLoading: false,
        error: err,
      };
    }
  };

  getProduct = (
    productId: number,
    branchId?: number,
    condition = true,
    fallbackData: Product | undefined = undefined
  ): IHttpResponse<Product, unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<Product>(
      this.getProductEndpoint(productId, branchId),
      {},
      condition,
      fallbackData
    ) as IHttpResponse<Product, unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  getSuggestionsEndpoint = (searchProduct: string, branchId: number) => {
    const branchIdValue = formatBranchId(branchId.toString());
    return `${this.baseUrlV2}suggestion?term=${searchProduct}&branchId=${branchIdValue}`;
  };

  getSuggestions = (
    searchProduct: string,
    branchId: number
  ): IHttpResponse<SuggestionListDto, unknown> => {
    const { data, error, isLoading } =
      this.httpClient.useGet<SuggestionListDto>(
        this.getSuggestionsEndpoint(searchProduct, branchId),
        undefined,
        !!searchProduct
      ) as IHttpResponse<SuggestionListDto, unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  getProductsSearchByTermsTotalRowsEndpoint = (
    branchId: string,
    term?: string
  ) => {
    const formattedBranchId = formatBranchId(branchId);

    return `${this.baseUrl}v1/products/search?BranchId=${formattedBranchId}&SearchByTerm=${term}`;
  };

  getProductsSearchByTermsTotalRows = async (
    branchId: string,
    term?: string
  ) => {
    if (process?.browser) {
      const { data, error, isLoading } = this.httpClient.useGet<
        SearchResultByTermDto[]
      >(
        this.getProductsSearchByTermsTotalRowsEndpoint(branchId, term),
        undefined,
        !!term
      ) as IHttpResponse<SearchResultByTermDto[], unknown>;

      return {
        data,
        isLoading,
        error,
      };
    }

    try {
      const response = await fetch(
        this.getProductsSearchByTermsTotalRowsEndpoint(branchId, term),
        {
          headers: { ...defaultHeaders },
        }
      );

      return {
        data: await response.json(),
        isLoading: false,
        error: null,
      };
    } catch (err) {
      this.appMonitoringClient.captureException(err);
      return {
        data: null,
        isLoading: false,
        error: err,
      };
    }
  };

  getProductsSearchByTermsEndpoint = (
    branchId: string,
    term?: string,
    pageIndex?: string,
    pageSize = "45"
  ) => {
    const formattedBranchId = formatBranchId(branchId);
    const paginationIndex = pageIndex || 1;

    return `${this.baseUrl}v1/products/search?BranchId=${formattedBranchId}&SearchByTerm=${term}&PageIndex=${paginationIndex}&PageSize=${pageSize}`;
  };

  getProductsSearchByTerm = (
    branchId: string,
    term?: string,
    pageIndex?: string,
    condition = true,
    fallbackData: SearchResultByTermDto | undefined = undefined
  ): IHttpResponse<SearchResultByTermDto, unknown> => {
    const { data, error, isLoading } =
      this.httpClient.useGet<SearchResultByTermDto>(
        this.getProductsSearchByTermsEndpoint(branchId, term, pageIndex),
        {},
        condition,
        fallbackData
      ) as IHttpResponse<SearchResultByTermDto, unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  getProductsSearchByTerms = async (
    branchId: string,
    term?: string,
    pageIndex?: string,
    pageSize?: string
  ) => {
    try {
      const response = await fetch(
        this.getProductsSearchByTermsEndpoint(
          branchId,
          term,
          pageIndex,
          pageSize
        ),
        {
          headers: { ...defaultHeaders },
        }
      );

      if (response?.status === 500) {
        this.appMonitoringClient.captureMessage(
          `A requisição "${this.getProductsSearchByTermsEndpoint(
            branchId,
            term,
            pageIndex
          )} retornou erro 500"`,
          {
            level: "error",
            tags: {
              fcx_labs_error_source: "api",
            },
          }
        );

        return {
          data: null,
          totalRows: 0,
          isLoading: false,
        };
      }

      if (response?.status === 404) {
        return {
          data: null,
          totalRows: 0,
          isLoading: false,
        };
      }

      return {
        data: await response.json(),
        totalRows: await response.headers.get(headerPaginationTotalResultsStr),
        isLoading: false,
        error: null,
      };
    } catch (err) {
      this.appMonitoringClient.captureException(err);
      return {
        data: null,
        isLoading: false,
        error: err,
      };
    }
  };

  getProductsSearchByTermsFilterEndpoint = (
    branchId: string,
    term?: string,
    category?: string,
    brand?: string,
    model?: string,
    attrAgg?: string,
    pageIndex?: string,
    sortBy?: string,
    pageSize = "45"
  ) => {
    const categoryParam = category ? `&Category=${category}` : "";
    const brandParam = brand ? `&Brand=${brand}` : "";
    const modelParam = model ? `&Model=${model}` : "";
    const attrAggParam = attrAgg ? `&AttrAgg=${attrAgg}` : "";
    const sortByParam = sortBy ? `&SortBy=${sortBy}` : "";
    const formattedBranchId = formatBranchId(branchId);
    const paginationIndex = pageIndex || 1;

    return `${this.baseUrl}v1/products/search?BranchId=${formattedBranchId}&SearchByTerm=${term}${categoryParam}${brandParam}${modelParam}${attrAggParam}&PageIndex=${paginationIndex}${sortByParam}&PageSize=${pageSize}`;
  };

  getProductsSearchByTermsFilter = (
    branchId: string,
    term: string,
    category?: string,
    brand?: string,
    model?: string,
    attrAgg?: string,
    pageIndex?: string,
    sortBy?: string,
    pageSize?: string,
    condition = true,
    fallbackData: SearchResultByTermDto | undefined = undefined
  ): IHttpResponse<SearchResultByTermDto, unknown> => {
    const { data, error, isLoading } =
      this.httpClient.useGet<SearchResultByTermDto>(
        this.getProductsSearchByTermsFilterEndpoint(
          branchId,
          term,
          category,
          brand,
          model,
          attrAgg,
          pageIndex,
          sortBy,
          pageSize
        ),
        undefined,
        condition,
        fallbackData
      ) as IHttpResponse<SearchResultByTermDto, unknown>;

    return {
      data,
      isLoading,
      error,
    };
  };

  private getHighlightCategoriesEndpoint = (branchId: string) => {
    const formattedBranchId = formatBranchId(branchId);

    return `${this.baseUrl}v1/playlist/highlight-categories?branchId=${formattedBranchId}`;
  };

  getHighlightCategories = (branchId: string, condition = true) => {
    const { data, error, isLoading } = this.httpClient.useGet<
      CustomHighlightCategoryDto[]
    >(
      this.getHighlightCategoriesEndpoint(branchId),
      {
        headers: {
          "Accept-Encoding": "gzip, deflate",
        },
      },
      !!condition
    ) as IHttpResponse<
      CustomHighlightCategoryDto[] | ApiDefaultErrorDto,
      unknown
    >;

    return {
      data,
      isLoading,
      error,
    };
  };
}
