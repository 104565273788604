import { LoginSteps, ValidationStep } from "typing";
import { createStore } from "../adapter";

export interface LoginAuthenticationState {
  isLoginAuthenticationModalOpen: boolean;
  currentStep: LoginSteps;
  isPhoneValidationModalOpen: boolean;
  validationStep: ValidationStep;
}

export interface LoginAuthenticationDispatch {
  setIsLoginAuthenticationModalOpen: (value: boolean) => void;
  changeLoginStep: (value: LoginSteps) => void;
  changeValidationStep: (value: ValidationStep) => void;
  setIsPhoneValidationModalOpen: (value: boolean) => void;
}

type LoginAuthentication = LoginAuthenticationState &
  LoginAuthenticationDispatch;

const useLoginAuthentication = createStore<LoginAuthentication>((set) => ({
  currentStep: "firstStep",
  isLoginAuthenticationModalOpen: false,
  changeLoginStep: (value) => {
    set({
      currentStep: value,
    });
  },
  setIsLoginAuthenticationModalOpen: (value: boolean) =>
    set({ isLoginAuthenticationModalOpen: value }),
  isPhoneValidationModalOpen: false,
  setIsPhoneValidationModalOpen: (value) =>
    set({ isPhoneValidationModalOpen: value }),
  validationStep: "modalCall",
  changeValidationStep: (value) => {
    set({ validationStep: value });
  },
}));

export { useLoginAuthentication };
