import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ErrorIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.primary["350"],
  secondColor = theme.colors.neutral["680"],
  alt = "ícone de erro",
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label={alt}
      aria-labelledby={alt}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="8" fill={color} />
      <path
        d="M8 7L17 18"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 7L8 18"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
