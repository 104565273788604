import { buildTestIds, useAppContext } from "application";
import { useCallback, useEffect, useState } from "react";
import { OrderStatus } from "typing";
import { ArrowLeft, ArrowRight, FlexContainer, theme } from "ui";
import { cookie } from "implementations";
import {
  ArrowWrapper,
  Page,
  PageChangeButton,
  PageCounter,
  PaginatorContainer,
} from "./styles";

type JustifyContent =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-around"
  | "space-between"
  | "space-evenly";

export interface PaginatorProps {
  identifier?: string;
  visiblePages: number;
  pageSize: number;
  totalRows: number;
  justifyContent?: JustifyContent;
  loading: boolean;
  page: number;
  pageChangeHandler?: (currentPage: number) => void;
  pageClickHandler?: () => void;
  indexBased?: 0 | 1;
  filteredLabel?: string;
  isBlackTheme?: boolean;
}

const Paginator = ({
  identifier = "default",
  visiblePages,
  pageSize,
  totalRows,
  justifyContent,
  loading,
  page,
  pageChangeHandler,
  pageClickHandler,
  indexBased = 1,
  filteredLabel,
  isBlackTheme,
}: PaginatorProps) => {
  const [totalPages, setTotalPages] = useState(Math.ceil(totalRows / pageSize));
  const [pagesArray, setPagesArray] = useState([...new Array(totalPages)]);
  const cookiesKey = `currentPage_${identifier}`;

  useEffect(() => {
    if (identifier === "default") {
      cookie.setCookie({
        name: cookiesKey,
        value: "",
        options: {
          maxAge: 600,
        },
      });
    }
  }, [cookiesKey, identifier]);

  const storedPage = cookie.getCookie({ name: cookiesKey });

  const [currentPage, setCurrentPage] = useState(() => {
    return storedPage ? parseInt(storedPage, 10) : page;
  });

  const [previousFilteredLabel, setPreviousFilteredLabel] =
    useState(filteredLabel);
  const { isClientMobile } = useAppContext();

  useEffect(() => {
    if (totalRows) {
      const newTotalPages = Math.ceil(totalRows / pageSize);
      setTotalPages(newTotalPages);
      setPagesArray([...new Array(newTotalPages)]);

      if (currentPage > newTotalPages) {
        setCurrentPage(newTotalPages);
      }
    } else {
      setTotalPages(0);
      setPagesArray([]);
      setCurrentPage(indexBased);
    }
  }, [totalRows, pageSize, currentPage, totalPages, indexBased]);

  useEffect(() => {
    if (filteredLabel !== previousFilteredLabel) {
      setCurrentPage(indexBased);
      setPreviousFilteredLabel(filteredLabel as OrderStatus);
    }
  }, [filteredLabel, indexBased, previousFilteredLabel]);

  const onNextPage = useCallback(() => {
    setCurrentPage((current) => current + 1);
  }, []);

  const onPrevPage = useCallback(() => {
    setCurrentPage((current) => current - 1);
  }, []);

  const onPageSelect = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const [visiblePagesArray, setVisiblePagesArray] = useState([] as number[]);

  const handleVisiblePages = (newCurrentPage: number) => {
    const middleNumber = Math.floor(visiblePages / 2);
    const tempVisiblePagesArray = [] as number[];
    let count = 0;

    if (newCurrentPage <= middleNumber) {
      count = indexBased;
      while (count <= visiblePages) {
        tempVisiblePagesArray.push(count);
        count += 1;
      }
      setVisiblePagesArray(tempVisiblePagesArray);
      return;
    }

    if (
      newCurrentPage > middleNumber &&
      newCurrentPage < totalPages - middleNumber
    ) {
      count = newCurrentPage - middleNumber;
      while (count <= newCurrentPage + middleNumber) {
        tempVisiblePagesArray.push(count);
        count += 1;
      }
      setVisiblePagesArray(tempVisiblePagesArray);
      return;
    }

    if (newCurrentPage >= totalPages - middleNumber) {
      count = totalPages - visiblePages + 1;
      while (count <= totalPages) {
        tempVisiblePagesArray.push(count);
        count += 1;
      }
      setVisiblePagesArray(tempVisiblePagesArray);
    }
  };

  useEffect(() => {
    if (!Number.isNaN(currentPage)) {
      if (pageChangeHandler) {
        pageChangeHandler(currentPage);
      }
      handleVisiblePages(currentPage);
    }
    // Remover o eslint-disable abaixo e resolver dependências do useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <PaginatorContainer
      {...buildTestIds("paginator-container")}
      isBlackTheme={isBlackTheme}
    >
      <FlexContainer
        data-testid="paginator-flex-container"
        flex-flexDirection="row"
        alignItems="center"
        justifyContent={justifyContent}
      >
        <PageChangeButton
          {...buildTestIds("paginator-back-button")}
          onClick={() => {
            onPrevPage();
            if (pageClickHandler) {
              pageClickHandler();
            }
          }}
          isDisabled={loading || currentPage === indexBased}
        >
          <ArrowLeft
            color={theme.colors.neutral["450"]}
            data-testid="component-paginator-arrow-left"
          />
        </PageChangeButton>
        <PageCounter {...buildTestIds("page-counter")}>
          {currentPage > indexBased + 2 &&
            totalPages > indexBased + 4 &&
            !isClientMobile && (
              <Page
                isSelected={false}
                isLoading={loading}
                ellipsis
                {...buildTestIds("page-ellipsis-left")}
              >
                ...
              </Page>
            )}

          {pagesArray.map((num, index) =>
            visiblePagesArray.includes(index + indexBased) ? (
              <Page
                // eslint-disable-next-line react/no-array-index-key
                key={index.toString()}
                isSelected={currentPage === index + indexBased}
                {...buildTestIds(
                  currentPage === index + indexBased
                    ? "page-selected"
                    : `page-${index + 1}`
                )}
                isLoading={loading}
                onClick={() => {
                  onPageSelect(index + indexBased);
                  if (pageClickHandler) {
                    pageClickHandler();
                  }
                }}
                className={
                  currentPage === index + indexBased
                    ? "page-number-selected"
                    : "page-number"
                }
              >
                {index + 1}
              </Page>
            ) : null
          )}
          {pagesArray.length - indexBased - 1 > currentPage &&
            totalPages > indexBased + 4 &&
            !isClientMobile && (
              <Page
                isSelected={false}
                isLoading={loading}
                ellipsis
                {...buildTestIds("page-ellipsis-right")}
              >
                ...
              </Page>
            )}
        </PageCounter>
        <PageChangeButton
          {...buildTestIds("paginator-forward-button")}
          isDisabled={
            loading ||
            (indexBased === 1
              ? currentPage === totalPages
              : currentPage + 1 === totalPages)
          }
          onClick={() => {
            onNextPage();
            if (pageClickHandler) {
              pageClickHandler();
            }
          }}
        >
          <ArrowWrapper {...buildTestIds("paginator-arrow-right")}>
            <ArrowRight
              color={theme.colors.neutral["450"]}
              data-testid="component-paginator-arrow-right"
            />
          </ArrowWrapper>
        </PageChangeButton>
      </FlexContainer>
    </PaginatorContainer>
  );
};

export { Paginator };
