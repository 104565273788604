import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import { buildTestIds } from "application";
import {
  BottomSheetContainer,
  BottomSheetContent,
  Close,
  ConfirmButton,
  FooterBottomSheet,
  HeaderActionText,
  HeaderBottomSheet,
  TitleBottomSheet,
  TopBackdropBottomSheet,
} from "./styles";

type SizeReactSpringBottomSheetProps = "large" | "small" | "medium";

export interface ReactSpringBottomSheetProps {
  display?: string;
  alignItems?: string;
  title: string;
  textButton?: string | ReactNode;
  onActionTextButton?: string | ReactNode;
  formId?: string;
  typeButtonFooter?: "button" | "submit" | "reset";
  stateBottomSheet: boolean;
  autoFocus?: boolean;
  hasSuccessfully?: boolean;
  children?: ReactNode;
  size?: SizeReactSpringBottomSheetProps;
  noFooter?: boolean;
  omitHeader?: boolean;
  hasActionInHeader?: boolean;
  titleFontSize?: string;
  titleColor?: string;
  isButtonDisabled?: boolean;
  titleFontFamily?: string;
  titleFontWeight?: number;
  justifyContent?: string;
  boxShadow?: string;
  padding?: string;
  hasCloseButton?: boolean;
  headerActionColor?: string;
  headerActionFontSize?: string;
  headerActionFontFamily?: string;
  headerActionFontWeight?: number;
  paddingContent?: string;
  heightContent?: string;
  marginBottomContent?: string;
  hideheaderline?: boolean;
  headerpadding?: string;
  containerHeight?: string;
  buttonBorderRadius?: string;
  buttonFontSize?: string;
  buttonFontWeight?: number;
  onDismiss?: () => void;
  onCloseBottomSheet: () => void;
  onClickFooterButton?: () => void;
  headerAction?: () => void;
  bottomHeight?: number;
  hasOverflow?: boolean;
  isSafari?: boolean;
  buttonHeight?: string;
}

const ReactSpringBottomSheet = ({
  title,
  textButton,
  onActionTextButton,
  formId,
  typeButtonFooter,
  stateBottomSheet,
  children,
  onDismiss,
  onCloseBottomSheet,
  onClickFooterButton,
  hasSuccessfully,
  size = "large",
  autoFocus = false,
  noFooter,
  omitHeader,
  hasActionInHeader,
  titleFontSize,
  titleColor,
  titleFontFamily,
  titleFontWeight,
  justifyContent,
  boxShadow,
  padding,
  hasCloseButton,
  headerAction,
  headerActionColor,
  headerActionFontSize,
  containerHeight,
  headerActionFontFamily,
  headerActionFontWeight,
  paddingContent,
  heightContent,
  display,
  alignItems,
  buttonBorderRadius,
  isButtonDisabled,
  buttonFontSize,
  buttonFontWeight,
  marginBottomContent,
  hideheaderline = false,
  headerpadding,
  bottomHeight,
  hasOverflow,
  isSafari,
  buttonHeight,
}: ReactSpringBottomSheetProps) => {
  const [buttonOnAction, setButtonOnAction] = useState(false);
  const bottomSheet = useRef<BottomSheetRef>();
  const calculateHeight = {
    large: 1.125,
    medium: 1.5,
    small: 1.5,
  };

  const handleClickFooterButton = () => {
    setButtonOnAction(true);
    if (onClickFooterButton) onClickFooterButton();
    if (hasSuccessfully) {
      onCloseBottomSheet();
    }
  };

  useEffect(() => {
    if (hasSuccessfully && buttonOnAction) {
      setButtonOnAction(false);
    }
    return () => {
      setButtonOnAction(false);
    };
  }, [hasSuccessfully, buttonOnAction]);

  const noFooterMaxHeight = noFooter ? 465 : undefined;

  return (
    <BottomSheetContainer
      isSafari={isSafari}
      maxHeight={bottomHeight ?? noFooterMaxHeight}
      {...buildTestIds("bottom-sheet-container")}
      ref={bottomSheet as MutableRefObject<BottomSheetRef>}
      open={stateBottomSheet}
      onDismiss={onDismiss}
      containerHeight={containerHeight}
      sibling={
        stateBottomSheet &&
        hasCloseButton && (
          <TopBackdropBottomSheet
            {...buildTestIds("top-backdrop-bottom-sheet")}
            onClick={onCloseBottomSheet}
          >
            <Close />
          </TopBackdropBottomSheet>
        )
      }
      defaultSnap={({ maxHeight }) => maxHeight}
      snapPoints={({ maxHeight }) => [
        maxHeight / calculateHeight[size as SizeReactSpringBottomSheetProps],
      ]}
      hideheaderline={hideheaderline.toString()}
      headerpadding={headerpadding}
      header={
        omitHeader ? (
          <div />
        ) : (
          <HeaderBottomSheet
            padding={padding}
            boxShadow={boxShadow}
            justifyContent={justifyContent}
            {...buildTestIds("header-bottom-sheet")}
          >
            <TitleBottomSheet
              {...buildTestIds("title-bottom-sheet")}
              titleColor={titleColor}
              titleFontFamily={titleFontFamily}
              titleFontWeight={titleFontWeight}
              titleFontSize={titleFontSize}
            >
              {title}
            </TitleBottomSheet>
            {hasActionInHeader ? (
              <HeaderActionText
                {...buildTestIds("header-action-text")}
                onClick={headerAction}
                headerActionColor={headerActionColor}
                headerActionFontFamily={headerActionFontFamily}
                headerActionFontSize={headerActionFontSize}
                headerActionFontWeight={headerActionFontWeight}
              >
                {onActionTextButton ?? textButton}
              </HeaderActionText>
            ) : null}
          </HeaderBottomSheet>
        )
      }
      expandOnContentDrag
    >
      <>
        <BottomSheetContent
          isSafari={isSafari}
          display={display}
          alignItems={alignItems}
          height={heightContent}
          marginBottom={marginBottomContent}
          padding={paddingContent}
          noFooter={noFooter}
          omitHeader={omitHeader}
          hasOverflow={hasOverflow}
          {...buildTestIds("bottom-sheet-content")}
        >
          {children}
        </BottomSheetContent>
        {noFooter ? null : (
          <FooterBottomSheet
            padding={paddingContent}
            {...buildTestIds("footer-bottom-sheet")}
          >
            <ConfirmButton
              {...buildTestIds("confirm-button")}
              type={typeButtonFooter ?? "submit"}
              form={formId}
              onClick={handleClickFooterButton}
              autoFocus={autoFocus}
              fontSize={buttonFontSize}
              fontWeight={buttonFontWeight}
              borderRadius={buttonBorderRadius}
              disabled={isButtonDisabled}
              height={buttonHeight}
            >
              {buttonOnAction && onActionTextButton
                ? onActionTextButton
                : textButton}
            </ConfirmButton>
          </FooterBottomSheet>
        )}
      </>
    </BottomSheetContainer>
  );
};

export { ReactSpringBottomSheet };
