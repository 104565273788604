import { buildTestIds } from "application";
import {
  ComponentHeaderContainer,
  HeaderSubTitle,
  HeaderTitle,
} from "../styles";

interface SubComponentHeaderProps {
  showTitle: string;
  showSubTitle: string;
  title: string;
  subTitle: string;
  isBlackTheme?: boolean;
}

const SubComponentHeader = ({
  showTitle,
  showSubTitle,
  title,
  subTitle,
  isBlackTheme,
}: SubComponentHeaderProps) => {
  const hasOnlySubTitle =
    (!showTitle || showTitle === "false") &&
    showSubTitle &&
    showSubTitle === "true";

  const hasOnlyTitle =
    (!showSubTitle || showSubTitle === "false") &&
    showTitle &&
    showTitle === "true";

  const hasTitleAndSubtitle =
    showSubTitle &&
    showSubTitle === "true" &&
    showTitle &&
    showTitle === "true";

  return (
    <ComponentHeaderContainer
      isBlackTheme={isBlackTheme}
      {...buildTestIds("card-cta-header-container")}
    >
      {hasOnlySubTitle ? (
        <HeaderSubTitle {...buildTestIds("card-cta-subtitle")}>
          {subTitle}
        </HeaderSubTitle>
      ) : hasOnlyTitle ? (
        <HeaderTitle {...buildTestIds("card-cta-title")}>{title}</HeaderTitle>
      ) : hasTitleAndSubtitle ? (
        <>
          <HeaderTitle {...buildTestIds("card-cta-title")}>{title}</HeaderTitle>
          <HeaderSubTitle {...buildTestIds("card-cta-subtitle")}>
            {subTitle}
          </HeaderSubTitle>
        </>
      ) : null}
    </ComponentHeaderContainer>
  );
};

export default SubComponentHeader;
