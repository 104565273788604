import { IconProps } from "typing";
import { theme } from "../themes";

export const BanknoteInput = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["550"],
  alt = "depósito",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5851 16.8943C10.0129 16.3221 9.84169 15.4615 10.1514 14.7138C10.4611 13.9661 11.1906 13.4786 11.9999 13.4786C12.8092 13.4786 13.5388 13.9661 13.8485 14.7138C14.1581 15.4615 13.9869 16.3221 13.4147 16.8943C13.0395 17.2696 12.5306 17.4804 11.9999 17.4804C11.4693 17.4804 10.9604 17.2696 10.5851 16.8943"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5022 17.4804V13.4787"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49826 17.4804V13.4787"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0002 3.47449V8.47657"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99902 6.47577L11.9999 8.4766L14.0007 6.47577"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0015 9.47699H19.0028C19.5335 9.47684 20.0425 9.6876 20.4177 10.0629C20.793 10.4381 21.0036 10.9471 21.0036 11.4778V19.4812C21.0036 20.0119 20.793 20.5209 20.4177 20.8961C20.0425 21.2714 19.5335 21.4821 19.0028 21.482H4.99693C4.46623 21.4821 3.95722 21.2714 3.58196 20.8961C3.2067 20.5209 2.99609 20.0119 2.99609 19.4812V11.4778C2.99609 10.9471 3.2067 10.4381 3.58196 10.0629C3.95722 9.6876 4.46623 9.47684 4.99693 9.47699H7.99818"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
