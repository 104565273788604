import React, { memo } from "react";
import ReactSlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SliderProps } from "typing";
import { buildTestIds } from "application";

const ReactSlick = memo(({ children, ...restProps }: SliderProps) => {
  return (
    <ReactSlickSlider {...buildTestIds("react-slick-slider")} {...restProps}>
      {children}
    </ReactSlickSlider>
  );
});

ReactSlick.displayName = "ReactSlick";

export { ReactSlick };
