import { ImageZoomActions, ImagesProductProps } from "typing";
// eslint-disable-next-line import/no-extraneous-dependencies
// import { appMonitoringClient } from "implementations";

import Head from "next/head";
import { useEffect, useRef, useState } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import { buildTestIds } from "application";
import {
  ContainerImage,
  ContainerZoomImage,
  ImageThumb,
  MagicZoomImage,
  ViewMoreImagesZoom,
} from "./styles";
import "./stylesMagicZoomPerson.css";

const MagicZoomComponents = ({
  largeImage,
  descriptionImage,
  zoomPosition = "inner",
  zoomMode = "zoom",
  activeZoom = "hover",
  expandStyle = "window",
  zoomModeExpanded = "zoom",
  zoomTriggerInExpandedMode = "zoom",
  closeOnClickOutside = true,
  expandCaption = false,
  titlePreviousButtonExpanded = "Previous",
  titleNextButtonExpanded = "Next",
  titleCloseButtonExpanded = "Close",
  hint = "once",
  textExpandHint = "",
  textHoverZoomHint = "",
  textClickZoomHint = "",
  mobileCofig = {
    zoomMode: "zoom",
    textExpandHint: "",
    textTouchZoomHint: "",
    textClickZoomHint: "",
  },
  moreImages = [],
  indexImageCurrent,
}: ImagesProductProps) => {
  const router = useRouter();
  const { idProduct } = router.query;
  const refMagicZoom = useRef<HTMLAnchorElement | null>(null);
  const [magicZoomRef, setMagicZoomRef] = useState<
    ImageZoomActions | undefined
  >();
  const [productId, setProductId] = useState<string | undefined | string[]>("");
  const configZoom = [
    `zoomPosition: ${zoomPosition}`,
    `zoomMode: ${zoomMode}`,
    `zoomOn: ${activeZoom}`,
    `expand: ${expandStyle}`,
    `expandZoomMode:${zoomModeExpanded}`,
    `expandZoomOn: ${zoomTriggerInExpandedMode}`,
    `closeOnClickOutside: ${closeOnClickOutside}`,
    `expandCaption: ${expandCaption}`,
    `textBtnPrev: ${titlePreviousButtonExpanded}`,
    `textBtnClose: ${titleCloseButtonExpanded}`,
    `textBtnNext: ${titleNextButtonExpanded}`,
    `textHoverZoomHint: ${textHoverZoomHint}`,
    `textExpandHint: ${textExpandHint}`,
    `textClickZoomHint: ${textClickZoomHint}`,
    `hint: ${hint}`,
  ];
  const configMobileZoom = [
    `zoomMode: ${mobileCofig.zoomMode}`,
    `textExpandHint: ${mobileCofig.textExpandHint}`,
    `textTouchZoomHint: ${mobileCofig.textTouchZoomHint}`,
    `textClickZoomHint: ${mobileCofig.textClickZoomHint}`,
  ];

  useEffect(() => {
    if (productId !== idProduct) {
      magicZoomRef?.stop();
      setProductId(idProduct);
    }
  }, [idProduct, magicZoomRef, productId]);

  useEffect(() => {
    if (!productId) {
      setProductId(idProduct);
    }

    if (largeImage) {
      try {
        // eslint-disable-next-line no-eval
        const magicZoomLib: ImageZoomActions | undefined = eval("MagicZoom");
        setMagicZoomRef(magicZoomLib);
        if (refMagicZoom && magicZoomLib) {
          magicZoomRef?.start(refMagicZoom?.current);
        }
      } catch {
        // appMonitoringClient.captureMessage(
        //   `Erro na inicialização do Magic Zoom Plus: ${error}`,
        //   {
        //     level: "error",
        //     tags: {
        //       component: "MagicZoom",
        //       function: "useEffect",
        //       type: "error",
        //     },
        //   }
        // );
      }
    }
  }, [largeImage, idProduct, productId, magicZoomRef, indexImageCurrent]);

  useEffect(() => {
    if (productId === idProduct) {
      magicZoomRef?.switchTo(refMagicZoom?.current, indexImageCurrent);
    }
  }, [idProduct, magicZoomRef, indexImageCurrent, productId]);

  return (
    <>
      <Head {...buildTestIds("head-magic-zoom")}>
        <link
          {...buildTestIds("link-magic-zoom")}
          rel="stylesheet"
          href="https://fcx-s3-fercos-backoffice.ferreiracosta.com/scripts/magiczoomplus-commercial/magiczoomplus/magiczoomplus.css"
        />
      </Head>
      <Script
        {...buildTestIds("script-magic-zoom")}
        id="MagicZoomScript"
        src="https://fcx-s3-fercos-backoffice.ferreiracosta.com/scripts/magiczoomplus-commercial/magiczoomplus/magiczoomplus.js"
        strategy="lazyOnload"
      />
      <Script
        id="MagicZoomOptions"
        dangerouslySetInnerHTML={{
          __html: `
            var mzOptions = {};
            mzOptions = {
                onUpdate: function(abc, def) {
                  const selectedThumbSequenceNumber = arguments[2]?.getAttribute("data-sequence-number");

                  let currentSequenceNumber = 1;
                  let boxMiniatureElements = Array.from(document.querySelectorAll('[data-thumb-number="thumb-list-item-box-miniature-' + currentSequenceNumber + '"]'));

                  while (boxMiniatureElements?.length) {
                    if (currentSequenceNumber === Number(selectedThumbSequenceNumber)) {
                      for (const element of boxMiniatureElements) {
                        element.classList.remove("disabled");
                        element.classList.add("active");
                      }
                    } else {
                      for (const element of boxMiniatureElements) {
                        element.classList.remove("active");
                      }
                    }

                    currentSequenceNumber += 1;

                    boxMiniatureElements = Array.from(document.querySelectorAll('[data-thumb-number="thumb-list-item-box-miniature-' + currentSequenceNumber + '"]'));
                  }
                },
            };
          `,
        }}
      />
      <ContainerZoomImage
        {...buildTestIds("container-zoom-image")}
        ref={refMagicZoom}
        id="zoom-products"
        href={largeImage}
        className="MagicZoom"
        data-options={configZoom.join(";")}
        data-mobile-options={configMobileZoom.join(";")}
      >
        <MagicZoomImage
          {...buildTestIds("magic-zoom-image")}
          src={largeImage}
          alt={descriptionImage}
        />
      </ContainerZoomImage>
      <ViewMoreImagesZoom {...buildTestIds("view-more-images-zoom")}>
        {moreImages
          ? moreImages.map((item) => (
              <ContainerImage
                {...buildTestIds(`container-image`)}
                key={item?.sequenceNumber}
                href={item.smallImage}
                data-zoom-id="zoom-products"
                data-image={item.smallImage}
                data-sequence-number={item?.sequenceNumber}
              >
                <ImageThumb
                  {...buildTestIds(`image-thumb`)}
                  src={item.tinyImage}
                />
              </ContainerImage>
            ))
          : null}
      </ViewMoreImagesZoom>
    </>
  );
};

export { MagicZoomComponents as MagicZoom };
