const formatStringUrl = (text: string) => {
  if (typeof text !== "string") return "";

  type MapAccentsType =
    | "á"
    | "é"
    | "í"
    | "ó"
    | "ú"
    | "ã"
    | "õ"
    | "â"
    | "ê"
    | "î"
    | "ô"
    | "û"
    | "à"
    | "è"
    | "ì"
    | "ò"
    | "ù"
    | "ç";
  const mapaAcentos = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    ã: "a",
    õ: "o",
    â: "a",
    ê: "e",
    î: "i",
    ô: "o",
    û: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ç: "c",
  };

  let newText = text.replace(" - ", "-");
  newText = newText.replace(/\s/g, "-");
  newText = newText.replace(/[ÀÁÂÃÄÅ]/g, "A");
  newText = newText.replace(/[àáâãäå]/g, "a");
  newText = newText.replace(/[ÈÉÊË]/g, "E");
  newText = newText.replace(/[/]/g, "");
  newText = newText.replace(/%/g, "");

  newText = newText.replace(/[áéíóúãõâêîôûàèìòùç]/gi, (match: string) => {
    return mapaAcentos[match as MapAccentsType] || match;
  });
  return newText.replace(/%/g, "").toLowerCase();
};

export { formatStringUrl };
