import { useAuth, validatePhoneNumber } from "application";
import { appMonitoringClient, profileApi } from "implementations";
import { ComponentType } from "react";

const withUserPhoneNumberValidation = <T extends object>(
  WrappedComponent: ComponentType<T>
) => {
  const ComponentWithLogic = (
    props: T & JSX.IntrinsicAttributes
  ): JSX.Element => {
    const { getTokens } = useAuth();
    const accessToken = getTokens()?.token;

    const {
      data: loggedClient,
      isLoading: isLoadingUserPhoneNumber,
      error,
    } = profileApi.getClient(accessToken, !!accessToken);

    if (error) {
      appMonitoringClient.captureException(error);
    }

    const userHasValidPhoneNumberRegistered = !validatePhoneNumber(
      loggedClient?.phoneNumber
    );

    const allProps = {
      ...props,
      isLoadingUserPhoneNumber,
      userHasValidPhoneNumberRegistered,
    };

    return <WrappedComponent {...allProps} />;
  };

  ComponentWithLogic.displayName =
    "CustomComponentWithUserPhoneNumberValidation";

  return ComponentWithLogic;
};

export { withUserPhoneNumberValidation };
