import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const OrderCancelling = ({
  width = 44,
  height = 44,
  alt = "pedido cancelado",
  color = theme.colors.primary["200"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox={`0 0 ${width} ${width}`}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="22" fill={color} />
      <path
        d="M32 12L12 32"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12L32 32"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
