import { ArrowNext } from "./style";

type ArrowNextButtonProps = {
  hasNext: boolean;
  clickHandler: () => void;
  showArrows: boolean;
};

const ArrowNextButton = ({
  hasNext,
  clickHandler,
  showArrows,
}: ArrowNextButtonProps) => {
  if (!showArrows) {
    return null;
  }

  return (
    <ArrowNext
      aria-label="Passar"
      hasNext={hasNext}
      onClick={(event) => {
        clickHandler();
        event?.stopPropagation();
        event?.preventDefault();
      }}
    />
  );
};

export { ArrowNextButton };
