import { IconProps } from "typing";
import { theme } from "../themes";

export const PackageThumbsUpIcon = ({
  color = theme.colors.neutral["350"],
  width = 21,
  height = 20,
  strokeWidth = "1.5",
  alt = "Pacote com sinal de positivo",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99707 9.99995V6.78194C2.99707 6.52854 3.04879 6.2778 3.1488 6.04497L4.1784 3.63563C4.47367 2.94507 5.15224 2.49707 5.90328 2.49683H15.0979C15.8489 2.49729 16.5274 2.94522 16.8228 3.63563L17.8524 6.04497C17.9519 6.27795 18.0033 6.52863 18.0033 6.78194V15.6273C18.0033 16.6633 17.1635 17.5031 16.1275 17.5031H13.0012"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.08105 13.9057L6.62003 11.3872C6.88323 10.9591 7.39833 10.7574 7.88224 10.8929C8.36615 11.0283 8.7016 11.4682 8.70423 11.9707V13.3005H9.96309C10.3414 13.3006 10.6945 13.4903 10.9035 13.8057C11.0895 14.0853 11.1434 14.4324 11.051 14.7552L10.4408 16.8911C10.3375 17.2512 10.0084 17.4994 9.63378 17.4997H6.65004C6.40651 17.5 6.17056 17.4151 5.9831 17.2596L5.08105 16.5093"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66443 17.5032H3.41391C3.1837 17.5032 2.99707 17.3166 2.99707 17.0864V13.3348C2.99707 13.1046 3.1837 12.918 3.41391 12.918H4.66443C4.89465 12.918 5.08127 13.1046 5.08127 13.3348V17.0864C5.08127 17.3166 4.89465 17.5032 4.66443 17.5032Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4998 6.66523V2.49683"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.835 14.1683H14.6686"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99707 10V8.33264C2.99707 7.41179 3.74357 6.66528 4.66443 6.66528H16.336C17.2568 6.66528 18.0033 7.41179 18.0033 8.33264V14.1684"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
