import { useAuth, useToast, useUser } from "application";
import {
  useShallow,
  useTwoFactor,
  useUniqueClient,
} from "application/state-manager";
import { notificationApi } from "implementations";
import { useCallback } from "react";
import { TwoFactorPublicCodeDto } from "typing";

const useUniqueClientUtils = () => {
  const { changeUniqueClientStep, uniqueClientInfo } = useUniqueClient(
    useShallow((state) => ({
      changeUniqueClientStep: state.changeUniqueClientStep,
      uniqueClientInfo: state.uniqueClientInfo,
    }))
  );

  const needsToValidateEmail =
    uniqueClientInfo?.emailValidation?.isSameInPhysicalStoreAndEcommerce &&
    !uniqueClientInfo?.emailValidation?.isEmailVerified;

  const needsToValidatePhoneNumber =
    uniqueClientInfo?.phoneNumberValidation
      ?.isSameInPhysicalStoreAndEcommerce &&
    !uniqueClientInfo?.phoneNumberValidation?.isPhoneNumberVerified;

  const {
    setIsValidationActive,
    setChannelSelected,
    setFaType,
    setIsRequesting,
    setIsBlockedToSendCode,
    setIsTokenCodeValid,
  } = useTwoFactor(
    useShallow((state) => ({
      setIsValidationActive: state.setIsValidationActive,
      setChannelSelected: state.setChannelSelected,
      setFaType: state.setFaType,
      setIsRequesting: state.setIsRequesting,
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
    }))
  );
  const { getTokens } = useAuth();

  const accessToken = getTokens()?.token;
  const { loggedClient } = useUser();
  const { addToast } = useToast();

  const checkError = useCallback(
    (errorCode?: string, errorMessage?: string, isValid?: boolean) => {
      if (
        errorCode === "222" ||
        [
          "Tente novamente em 24 horas.",
          "Operação bloqueada devido ao número de tentativas excessivas na solicitação do código.",
        ]?.includes(String(errorMessage))
      ) {
        setIsBlockedToSendCode(true);
        setIsValidationActive(true);
        setIsTokenCodeValid(false);
        changeUniqueClientStep("verifyIdentity");
        return;
      }

      if (errorMessage === "Cliente não encontrado") {
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: "Erro ao enviar o código",
          description: `${errorMessage}`,
          timerInMilliseconds: 3000,
        });
        setIsBlockedToSendCode(false);
        setIsValidationActive(true);
        return;
      }

      if (!errorMessage && !isValid) {
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          title: "Erro ao enviar o código",
          description: "Ocorreu um erro ao enviar o código de verificação.",
          timerInMilliseconds: 3000,
        });
      }
    },
    [
      addToast,
      changeUniqueClientStep,
      setIsBlockedToSendCode,
      setIsTokenCodeValid,
      setIsValidationActive,
    ]
  );

  const sendTwoFactorToken = useCallback(
    async ({
      channel,
      faType,
      cpf,
      email,
      phoneNumber,
    }: TwoFactorPublicCodeDto) => {
      setFaType(Number(faType));
      setChannelSelected(channel);
      setIsRequesting(true);

      const storagedTime = localStorage.getItem(
        `timeSent_${faType}_${channel}`
      );

      const actualTime = Date.now();

      const diffTime = Math.floor(
        Number(storagedTime) - Number(actualTime) / 1000
      );

      if (diffTime && diffTime > 0 && storagedTime) {
        setIsValidationActive(true);
        setIsRequesting(false);

        changeUniqueClientStep("verifyIdentity");
        return;
      }

      const { data } = await notificationApi.sendTwoFactorCode({
        channel,
        faType,
        cpf: String(loggedClient?.cpf) ?? cpf,
        email: String(loggedClient?.email) ?? email,
        phoneNumber: String(loggedClient?.phoneNumber) ?? phoneNumber,
        token: accessToken,
      });

      checkError(
        data?.validationResult?.errors?.[0]?.errorCode,
        data?.validationResult?.errors?.[0]?.errorMessage,
        data?.validationResult?.isValid
      );

      setIsRequesting(false);
      setIsValidationActive(true);
      setIsBlockedToSendCode(false);
      changeUniqueClientStep("verifyIdentity");
    },
    [
      setFaType,
      setChannelSelected,
      setIsRequesting,
      loggedClient?.cpf,
      loggedClient?.email,
      loggedClient?.phoneNumber,
      accessToken,
      setIsValidationActive,
      setIsBlockedToSendCode,
      changeUniqueClientStep,
      checkError,
    ]
  );

  return {
    sendTwoFactorToken,
    needsToValidateEmail,
    needsToValidatePhoneNumber,
  };
};

export { useUniqueClientUtils };
