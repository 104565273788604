import { useBlackFriday, useShallow } from "application";
import { A11y, FreeMode, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import { SwiperProps as SwiperProperties } from "typing";
import { ArrowCarouselLeft, ArrowCarouselRight } from "ui";
import {
  NavButtonNext,
  NavButtonPrev,
  NavButtonsContainer,
  SwiperContainer,
} from "./styles";

interface SwiperProps extends SwiperProperties {
  getBreakpoints: () => number;
}

const SwiperSlider = ({
  subComponents,
  position,
  getBreakpoints,
  configs,
}: SwiperProps) => {
  const { isBlackFridayTheme } = useBlackFriday(
    useShallow((state) => ({
      isBlackFridayTheme: state.isBlackFridayTheme,
    }))
  );
  const nextButtonId = `nextButton-${position}`;
  const prevButtonId = `prevButton-${position}`;

  const breakPoint: number = getBreakpoints();

  return (
    <SwiperContainer>
      <Swiper
        modules={[FreeMode, Scrollbar, A11y, Pagination, Navigation]}
        navigation={{
          nextEl: `#${nextButtonId}`,
          prevEl: `#${prevButtonId}`,
        }}
        {...configs}
      >
        {subComponents?.map((subComponent, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${index}`}>{subComponent}</SwiperSlide>
          );
        })}
      </Swiper>
      {configs?.breakpoints?.[`${breakPoint}`]?.showNavigation && (
        <NavButtonsContainer>
          <NavButtonPrev isBlackTheme={isBlackFridayTheme} id={prevButtonId}>
            <ArrowCarouselLeft />
          </NavButtonPrev>
          <NavButtonNext isBlackTheme={isBlackFridayTheme} id={nextButtonId}>
            <ArrowCarouselRight />
          </NavButtonNext>
        </NavButtonsContainer>
      )}
    </SwiperContainer>
  );
};

export { SwiperSlider };
