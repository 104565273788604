import { buildTestIds } from "application";
import { useTwoFactor } from "application/state-manager";
import { useShallow } from "application/state-manager/adapter";
import { ReactNode } from "react";
import { ViewType } from "typing";
import { FailureIcon, theme } from "ui";
import { ModalGeneral } from "../../../ModalGeneral";
import {
  FailureButton,
  FailureContainer,
  FailureText,
  FailureTitle,
} from "./styles";

type ViewTwoFactorContentFailureProps = {
  title: string;
  viewType: ViewType;
  text?: string | JSX.Element;
  buttonText: string;
  hasHeader?: boolean;
  hasModalHeader?: boolean;
  illustration?: ReactNode;
  showButton?: boolean;
  titleFailure?: string;
  onClickFailureButton?: () => void;
};

const ViewTwoFactorContentFailure = ({
  title,
  viewType,
  text = "Parece que você atingiu o limite de envios de código por dia. Para sua segurança, pausamos temporariamente as tentativas de envio do código de verificação. Por favor, aguarde 24 horas antes de tentar novamente. Agradecemos sua compreensão.",
  buttonText,
  hasHeader = true,
  onClickFailureButton,
  showButton = true,
  hasModalHeader = true,
  titleFailure,
  illustration,
}: ViewTwoFactorContentFailureProps) => {
  const { isValidationActive, setIsValidationActive } = useTwoFactor(
    useShallow((state) => ({
      isValidationActive: state.isValidationActive,
      setIsValidationActive: state.setIsValidationActive,
    }))
  );

  const handleAction = () => {
    setIsValidationActive(false);
    if (onClickFailureButton) {
      onClickFailureButton();
    }
  };

  if (viewType === "component") {
    return isValidationActive ? (
      <FailureContainer
        {...buildTestIds("two-factor-content-failure-container")}
      >
        {hasHeader ? (
          <FailureTitle
            {...buildTestIds("two-factor-content-failure-container-title")}
          >
            <FailureIcon /> {title}
          </FailureTitle>
        ) : null}
        {titleFailure ? (
          <FailureTitle
            isDefault={false}
            {...buildTestIds("two-factor-content-failure-container-title")}
          >
            {titleFailure}
          </FailureTitle>
        ) : null}
        <FailureText
          {...buildTestIds("two-factor-content-failure-container-text")}
        >
          {text}
        </FailureText>
        {onClickFailureButton ? (
          <FailureButton
            {...buildTestIds("two-factor-content-failure-content-button")}
            text={buttonText}
            textUnderAction="Aguarde..."
            // underAction={formUnderAction}
            buttonType="submit"
            onClickAction={handleAction}
            fontWeight={theme.typography.fontWeight["400"]}
            color={`${theme.colors.secondary["380"]}`}
            hoveredColor={`${theme.colors.secondary["350"]}`}
          />
        ) : null}
      </FailureContainer>
    ) : null;
  }

  return (
    <ModalGeneral
      ariaHideApp={false}
      isOpen={isValidationActive}
      hasHeader={hasModalHeader}
      onCloseModal={() => setIsValidationActive(false)}
      ariaLabelForModal={title}
      illustration={illustration}
      title={
        <FailureTitle {...buildTestIds("two-factor-modal-failure-title")}>
          <FailureIcon /> {title}
        </FailureTitle>
      }
      bodyDescription={text}
      maxWidth="489px"
      bodyContent={
        showButton ? (
          <FailureButton
            {...buildTestIds("two-factor-modal-failure-button")}
            text={buttonText}
            textUnderAction="Aguarde..."
            // underAction={formUnderAction}
            buttonType="submit"
            onClickAction={handleAction}
            fontWeight={theme.typography.fontWeight["400"]}
            color={`${theme.colors.secondary["380"]}`}
            hoveredColor={`${theme.colors.secondary["350"]}`}
          />
        ) : undefined
      }
      contentGap="0"
      noCloseButton
    />
  );
};

export { ViewTwoFactorContentFailure };
