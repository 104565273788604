import { IconProps } from "typing";
import { theme } from "../themes";

export const HouseIcon = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["760"],
  alt = "ícone de casa",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49707 9.74491L9.11237 4.07453C9.62325 3.63661 10.3771 3.63661 10.888 4.07453L17.5033 9.74491"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6697 17.5026V3.74692C16.6697 3.5167 16.4831 3.33008 16.2528 3.33008H13.7518C13.5216 3.33008 13.335 3.5167 13.335 3.74692V5.91949"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9541 17.5029V12.9177C7.9541 12.4572 8.32735 12.084 8.78778 12.084H11.213C11.6735 12.084 12.0467 12.4572 12.0467 12.9177V17.5029"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5687 8.82617V17.5017"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5033 17.5028H2.49707"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
