import styled from "styled-components";
// artur.castro NewWindow precisa estar em infra e ser utilizado por algum component
import NewWindow from "react-new-window";

export const AuthWindowContainer = styled(NewWindow).attrs({
  features: {
    width: 565,
    height: 565,
    top: 0,
    left: 0,
  },
})``;
