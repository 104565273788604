import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ValidIcon = ({
  width = 18,
  height = 18,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9"
        cy="9.00006"
        r="6.0025"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6283 9.22613L8.07356 10.6714L8.06423 10.6621L11.3249 7.40137"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
