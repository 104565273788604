import { IconProps } from "typing";
import { theme } from "../themes";

export const WeddingRingsIcon = ({
  width = 24,
  height = 25,
  color = theme.colors.neutral["550"],
  alt = "Ícone de alianças de casamento",
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 6.5L16 9.5L12.5 6.5L13.375 4.5H18.625L19.5 6.5Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.74156 9.75931C7.03116 8.93128 4.11039 10.107 2.72954 12.5819C1.34869 15.0568 1.88218 18.1598 4.01033 20.0314C6.13847 21.903 9.28419 22.0357 11.5624 20.35C13.8406 18.6643 14.6336 15.6172 13.466 13.0348"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2583 21.2414C16.9687 22.0695 19.8894 20.8937 21.2703 18.4188C22.6511 15.9439 22.1176 12.841 19.9895 10.9694C17.8613 9.09776 14.7156 8.96505 12.4374 10.6508C10.1592 12.3365 9.36626 15.3835 10.5338 17.9659"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
