import styled from "styled-components";
import { theme } from "ui";

export const ResponsiveModalContentInformation = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
  width: 100%;
`;

export const ResponsiveModalContentTitle = styled.h2`
  font-size: ${theme.typography.fontSize.sm.x7};
  color: ${theme.colors.neutral["550"]};
  font-weight: ${theme.typography.fontWeight["700"]};
  font-family: ${theme.typography.fontFamily.chivo};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const ResponsiveModalContentDescription = styled.p`
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.neutral["110"]};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-family: ${theme.typography.fontFamily.chivo};
`;
