import styled from "styled-components";
import { theme } from "ui";

interface InputCodeValidationContainerProps {
  flexDirection?: string;
}

export const InputCodeValidationContainer = styled.div<InputCodeValidationContainerProps>`
  color: ${theme.colors.primary["155"]} !important;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: center;
  width: 100%;
`;

export const IncorrectCode = styled.div`
  color: ${theme.colors.primary["155"]} !important;
  font-size: ${theme.space.x3};
  font-weight: ${theme.typography.fontWeight["400"]};
  display: flex;
  justify-content: end;
  width: 100%;
`;
