import {
  IGetCookiesProps,
  ICookie,
  ISetCookiesProps,
  IDeleteCookie,
} from "app-domain/abstractions";

const keyAndValueMap = new Map<string, string>();

export const MockedCookie: ICookie = {
  setCookie({ name, value }: ISetCookiesProps): void {
    keyAndValueMap.set(String(name), String(value));
  },

  getCookie({ name }: IGetCookiesProps): string | undefined {
    return keyAndValueMap.get(String(name));
  },

  deleteCookie({ name }: IDeleteCookie): void {
    keyAndValueMap.delete(String(name));
  },
};
