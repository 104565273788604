import { IUniqueClientContent } from "typing";
import { useUniqueClientData } from "./data";
import { UniqueClientContentUI } from "./ui";

const UniqueClientContent = () => {
  const { getUniqueClientContent } = useUniqueClientData();

  const uniqueClientContent = Object.fromEntries(
    getUniqueClientContent()
  ) as unknown as IUniqueClientContent;

  return <UniqueClientContentUI {...uniqueClientContent} />;
};

export { UniqueClientContent };
