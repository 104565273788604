import { IconProps } from "typing";
import { theme } from "../themes";

export const BreadcrumbArrowIcon = ({
  alt = "Seta para direita",
  width = 17,
  height = 16,
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16669 10.6666L9.83335 7.99996L7.16669 5.33329"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
