import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Help = ({
  width = 24,
  height = 24,
  color = theme.colors.tertiary["105"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5968 7.88475C13.1692 7.62825 12.654 7.5 12.051 7.5H11.97C11.358 7.5 10.827 7.635 10.377 7.905C9.927 8.175 9.585 8.517 9.351 8.931C9.117 9.345 9 9.768 9 10.2C9 10.4833 9.22967 10.713 9.51298 10.713L9.86327 10.713C9.8718 10.713 9.88033 10.7128 9.88885 10.7123C10.1502 10.6982 10.3506 10.4749 10.3365 10.2135C10.3365 9.7635 10.4827 9.399 10.7753 9.12C11.0678 8.841 11.4615 8.7015 11.9565 8.7015C12.4065 8.7015 12.7845 8.841 13.0905 9.12C13.3965 9.399 13.5495 9.75 13.5495 10.173C13.5495 10.47 13.464 10.7355 13.293 10.9695C13.122 11.2035 12.861 11.4915 12.51 11.8335C12.159 12.1665 11.8935 12.4567 11.7135 12.7043C11.5335 12.9518 11.4435 13.2285 11.4435 13.5345V13.7235C11.4435 14.0963 11.7457 14.3985 12.1185 14.3985H12.132C12.4973 14.3985 12.7935 14.1023 12.7935 13.737C12.7935 13.53 12.8587 13.341 12.9892 13.17C13.1198 12.999 13.3245 12.7875 13.6035 12.5355C14.0085 12.1845 14.3235 11.8448 14.5485 11.5163C14.7735 11.1877 14.886 10.767 14.886 10.254C14.886 9.777 14.778 9.327 14.562 8.904C14.346 8.481 14.0243 8.14125 13.5968 7.88475ZM12.8204 16.194V16.0995C12.8204 15.7118 12.5061 15.3975 12.1184 15.3975C11.7307 15.3975 11.4164 15.7118 11.4164 16.0995V16.194C11.4164 16.5817 11.7307 16.896 12.1184 16.896C12.5061 16.896 12.8204 16.5817 12.8204 16.194Z"
        fill={secondColor}
      />
    </svg>
  );
};
