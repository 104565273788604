import { MultipleColorsIconProps } from "typing";

export const SecurityIcon = ({
  color,
  secondColor,
  width,
  height,
  alt,
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.667 16H13.3337C11.8603 16 10.667 14.8067 10.667 13.3333V10.6667C10.667 9.19333 11.8603 8 13.3337 8H18.667C20.1403 8 21.3337 9.19333 21.3337 10.6667V13.3333C21.3337 14.8067 20.1403 16 18.667 16Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6663 28H9.33301C7.12367 28 5.33301 26.2093 5.33301 24V24C5.33301 21.7907 7.12367 20 9.33301 20H22.6663C24.8757 20 26.6663 21.7907 26.6663 24V24C26.6663 26.2093 24.8757 28 22.6663 28Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99967 23.9871C9.99567 23.9871 9.99301 23.9898 9.99301 23.9938C9.99301 23.9978 9.99567 24.0005 9.99967 24.0005C10.0037 24.0005 10.0063 23.9978 10.0063 23.9938C10.0063 23.9898 10.0037 23.9871 9.99967 23.9871"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9997 23.9998C13.9957 23.9998 13.993 24.0025 13.993 24.0065C13.993 24.0105 13.9957 24.0132 13.9997 24.0132C14.0037 24.0132 14.0063 24.0105 14.0063 24.0065C14.0063 24.0025 14.0037 23.9998 13.9997 23.9998"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9938 23.993C17.9898 23.993 17.9871 23.9957 17.9871 23.9997C17.9871 24.0037 17.9898 24.0063 17.9938 24.0063C17.9978 24.0063 18.0005 24.0037 18.0005 23.9997C18.0005 23.9957 17.9978 23.993 17.9938 23.993"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9938 23.993C21.9898 23.993 21.9871 23.9957 21.9871 23.9997C21.9871 24.0037 21.9898 24.0063 21.9938 24.0063C21.9978 24.0063 22.0005 24.0037 22.0005 23.9997C22.0005 23.9957 21.9978 23.993 21.9938 23.993"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6663 8.00033V5.16699C18.6663 3.78566 17.473 2.66699 15.9997 2.66699C14.5263 2.66699 13.333 3.78566 13.333 5.16699V8.00033"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
