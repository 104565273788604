import styled from "styled-components";
import { theme } from "ui";

interface BadgeProps {
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  padding?: string;
  margin?: string;
  borderRadius?: string;
  border?: string;
  width?: string;
  height?: string;
  gap?: string;
}

export const BadgeContainer = styled.div<BadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${theme.typography.fontFamily.chivo};
  gap: ${({ gap }) => gap || theme.space.x1};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius || theme.space.x2};
  border: ${({ border }) => border};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width || "6.25rem"};
  height: ${({ height }) => height || "2rem"};
  padding: ${({ padding }) => padding};
  cursor: pointer;
`;

export const BadgeText = styled.span<BadgeProps>`
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x3};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
`;
