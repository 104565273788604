import { MultipleColorsIconProps } from "typing";

export const UniqueClientXSMobileImage = ({
  width = 32,
  height = 93,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        borderRadius: "8px 0 0 8px",
        height: "100%",
        width: "100%",
        maxHeight: "93px",
        maxWidth: "32px",
      }}
    >
      <path d="M32 0H0V32H32V0Z" fill="#AA0E27" />
      <path
        d="M13.5359 25.7298H6.90987C6.44613 25.7298 6.07013 25.3538 6.07013 24.89V13.4846C6.07013 13.1295 6.3584 12.8412 6.71351 12.8412H13.3437C13.6988 12.8412 13.9871 13.1295 13.9871 13.4846V24.6185C14.1083 24.7355 14.1835 24.8984 14.1835 25.0822C14.1835 25.4373 13.8952 25.7298 13.5401 25.7298M7.36108 24.4388H12.7003V14.1322H7.36108V24.4388V24.4388Z"
        fill="#ED5568"
      />
      <path
        d="M24.3438 25.7298H17.7178C17.3627 25.7298 17.0744 25.4415 17.0744 25.0822C17.0744 24.9026 17.1496 24.7396 17.2708 24.6185V13.4846C17.2708 13.1295 17.559 12.8412 17.9141 12.8412H24.5402C24.8953 12.8412 25.1835 13.1295 25.1835 13.4846V24.89C25.1835 25.3538 24.8075 25.7298 24.3438 25.7298ZM18.5575 24.4388H23.8968V14.1322H18.5575V24.4388Z"
        fill="#ED5568"
      />
      <path
        d="M25.614 14.1321H17.9101C17.5549 14.1321 17.2667 13.8438 17.2667 13.4887V9.3819C17.1455 9.26492 17.0703 9.10199 17.0703 8.91816C17.0703 8.56305 17.3586 8.27478 17.7137 8.27478H25.614C26.0777 8.27478 26.4537 8.65078 26.4537 9.11452V13.2923C26.4537 13.7561 26.0777 14.1321 25.614 14.1321ZM18.5576 12.8411H25.1669V9.56155H18.5576V12.8411Z"
        fill="#ED5568"
      />
      <path
        d="M13.3437 14.1321H5.63979C5.17605 14.1321 4.80005 13.7561 4.80005 13.2923V9.11452C4.80005 8.65078 5.17605 8.27478 5.63979 8.27478H13.54C13.8952 8.27478 14.1834 8.56305 14.1834 8.91816C14.1834 9.09781 14.1082 9.26492 13.9871 9.3819V13.4887C13.9871 13.8438 13.6988 14.1321 13.3437 14.1321ZM6.09099 12.8411H12.7003V9.56155H6.09099V12.8411V12.8411Z"
        fill="#ED5568"
      />
      <path
        d="M15.8379 9.56154H10.8078C9.28289 9.56154 7.78305 8.17868 7.77887 6.77075C7.77887 6.23599 7.92092 5.27927 8.8651 4.92416C9.78422 4.5774 11.2005 4.95758 13.0805 6.05635C14.5553 6.91698 15.8504 7.95308 16.3225 8.49201C16.4896 8.68419 16.5272 8.95158 16.4228 9.18553C16.3183 9.41531 16.0886 9.56571 15.8337 9.56571M9.60875 6.09395C9.4876 6.09395 9.39151 6.11066 9.31631 6.13573C9.24946 6.16079 9.06564 6.23182 9.06564 6.77493C9.06564 7.47681 9.99311 8.27477 10.8036 8.27477H14.0916C13.6195 7.92383 13.0471 7.53947 12.4288 7.176C11.0543 6.37386 10.1226 6.09395 9.60875 6.09395Z"
        fill="#ED5568"
      />
      <path
        d="M20.4418 9.56157H15.4117C15.161 9.56157 14.9313 9.41535 14.8226 9.18139C14.7182 8.95161 14.7558 8.68005 14.9229 8.48787C15.3908 7.94894 16.6901 6.91284 18.1649 6.05638C20.0449 4.96179 21.4612 4.57744 22.3803 4.92419C23.3245 5.27931 23.4665 6.23185 23.4665 6.77079C23.4665 8.17872 21.9667 9.56157 20.4376 9.56157M17.1497 8.27063H20.4376C21.2481 8.27063 22.1756 7.47266 22.1756 6.77079C22.1756 6.22767 21.996 6.16083 21.9249 6.13576C21.5322 5.98954 20.5087 6.1859 18.8125 7.17604C18.19 7.53951 17.6218 7.92387 17.1497 8.27481V8.27063Z"
        fill="#ED5568"
      />
      <path
        d="M17.697 25.7298H13.5567C13.0846 25.7298 12.6961 25.3455 12.6961 24.8692V9.13135C12.6961 8.65508 13.0805 8.27072 13.5567 8.27072H17.697C18.169 8.27072 18.5576 8.65508 18.5576 9.13135V24.8692C18.5576 25.3413 18.1732 25.7298 17.697 25.7298ZM13.9871 24.4389H17.2666V9.56167H13.9871V24.4389Z"
        fill="#ED5568"
      />
      <path d="M32 32H0V64H32V32Z" fill="#C1D82F" />
      <path
        d="M17.6796 56.2422H8.22591C7.18232 56.2422 6.33313 55.3928 6.33313 54.3489V41.6683C6.33313 40.6243 7.18232 39.7749 8.22591 39.7749H17.6796C18.7232 39.7749 19.5724 40.6243 19.5724 41.6683V44.4248C19.5724 44.7147 19.3371 44.9501 19.0472 44.9501C18.7573 44.9501 18.522 44.7147 18.522 44.4248V41.6683C18.522 41.2043 18.1434 40.829 17.683 40.829H8.22932C7.76551 40.829 7.39036 41.2043 7.39036 41.6683V54.3489C7.39036 54.8128 7.76551 55.1881 8.22932 55.1881H17.683C18.1468 55.1881 18.522 54.8128 18.522 54.3489V51.739C18.522 51.4491 18.7573 51.2137 19.0472 51.2137C19.3371 51.2137 19.5724 51.4491 19.5724 51.739V54.3489C19.5724 55.3928 18.7232 56.2422 17.6796 56.2422Z"
        fill="#E3EDA1"
      />
      <path
        d="M16.7348 43.5412H9.16369C8.87381 43.5412 8.63849 43.3058 8.63849 43.0158C8.63849 42.7259 8.87381 42.487 9.16369 42.487H16.7348C17.0247 42.487 17.26 42.7224 17.26 43.0158C17.26 43.3092 17.0247 43.5412 16.7348 43.5412Z"
        fill="#E3EDA1"
      />
      <path
        d="M14.6204 46.864H9.16369C8.87381 46.864 8.63849 46.6286 8.63849 46.3386C8.63849 46.0486 8.87381 45.8132 9.16369 45.8132H14.6204C14.9103 45.8132 15.1456 46.0486 15.1456 46.3386C15.1456 46.6286 14.9103 46.864 14.6204 46.864Z"
        fill="#E3EDA1"
      />
      <path
        d="M14.5386 50.1902H9.16717C8.87729 50.1902 8.64197 49.9548 8.64197 49.6648C8.64197 49.3748 8.87729 49.1394 9.16717 49.1394H14.5386C14.8285 49.1394 15.0638 49.3748 15.0638 49.6648C15.0638 49.9548 14.8285 50.1902 14.5386 50.1902Z"
        fill="#E3EDA1"
      />
      <path
        d="M16.7348 53.513H9.16369C8.87381 53.513 8.63849 53.2776 8.63849 52.9877C8.63849 52.6977 8.87381 52.4623 9.16369 52.4623H16.7348C17.0247 52.4623 17.26 52.6977 17.26 52.9877C17.26 53.2776 17.0247 53.513 16.7348 53.513Z"
        fill="#E3EDA1"
      />
      <path
        d="M24.3026 55.7817C23.9377 55.7817 23.5932 55.6384 23.3375 55.3825L19.8213 51.8653C19.1119 52.2098 18.3139 52.3497 17.5261 52.2644C16.5814 52.1621 15.6913 51.7356 15.0194 51.0636C14.2282 50.2721 13.7917 49.2179 13.7917 48.0955C13.7917 46.9732 14.2282 45.919 15.0194 45.1275C15.8107 44.3361 16.8645 43.8994 17.9865 43.8994C19.1085 43.8994 20.1589 44.3361 20.9536 45.1275C21.6254 45.7996 22.0517 46.69 22.154 47.635C22.2393 48.4231 22.0995 49.2248 21.755 49.9309L25.2712 53.4482C25.5304 53.7075 25.6736 54.0486 25.6736 54.4171C25.6736 54.7855 25.5304 55.1267 25.2712 55.386C25.012 55.6452 24.6709 55.7851 24.3026 55.7851M19.91 50.6883C20.0464 50.6883 20.1794 50.7395 20.2817 50.8418L24.0775 54.6388C24.1355 54.6968 24.2139 54.7309 24.2992 54.7309C24.3845 54.7309 24.4629 54.6968 24.5209 54.6388C24.5789 54.5808 24.613 54.5024 24.613 54.4171C24.613 54.3318 24.5789 54.2533 24.5209 54.1953L20.7251 50.3983C20.5511 50.2243 20.5204 49.9548 20.65 49.7467C21.0184 49.1531 21.1787 48.4435 21.1002 47.7476C21.0218 47.0312 20.7114 46.383 20.1999 45.8712C19.6065 45.2776 18.8186 44.9501 17.9797 44.9501C17.1407 44.9501 16.3495 45.2776 15.7561 45.8712C15.1627 46.4648 14.8353 47.2529 14.8353 48.0921C14.8353 48.9314 15.1627 49.7228 15.7561 50.3164C16.2677 50.8282 16.9156 51.1386 17.6318 51.2171C18.3275 51.2921 19.0369 51.1318 19.6303 50.7633C19.7156 50.7122 19.8111 50.6849 19.9066 50.6849L19.91 50.6883Z"
        fill="#E3EDA1"
      />
      <path
        d="M0.688866 65.436C1.06932 65.436 1.37773 65.1146 1.37773 64.718C1.37773 64.3215 1.06932 64 0.688866 64C0.308416 64 0 64.3215 0 64.718C0 65.1146 0.308416 65.436 0.688866 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 65.436C3.85362 65.436 4.16203 65.1146 4.16203 64.718C4.16203 64.3215 3.85362 64 3.47317 64C3.09272 64 2.7843 64.3215 2.7843 64.718C2.7843 65.1146 3.09272 65.436 3.47317 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 65.436C6.63762 65.436 6.94603 65.1146 6.94603 64.718C6.94603 64.3215 6.63762 64 6.25716 64C5.87671 64 5.5683 64.3215 5.5683 64.718C5.5683 65.1146 5.87671 65.436 6.25716 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 65.436C9.42192 65.436 9.73033 65.1146 9.73033 64.718C9.73033 64.3215 9.42192 64 9.04147 64C8.66102 64 8.3526 64.3215 8.3526 64.718C8.3526 65.1146 8.66102 65.436 9.04147 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 65.436C12.2024 65.436 12.5108 65.1146 12.5108 64.718C12.5108 64.3215 12.2024 64 11.822 64C11.4415 64 11.1331 64.3215 11.1331 64.718C11.1331 65.1146 11.4415 65.436 11.822 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 65.436C14.9867 65.436 15.2951 65.1146 15.2951 64.718C15.2951 64.3215 14.9867 64 14.6062 64C14.2258 64 13.9174 64.3215 13.9174 64.718C13.9174 65.1146 14.2258 65.436 14.6062 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 65.436C17.7707 65.436 18.0791 65.1146 18.0791 64.718C18.0791 64.3215 17.7707 64 17.3902 64C17.0098 64 16.7014 64.3215 16.7014 64.718C16.7014 65.1146 17.0098 65.436 17.3902 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1745 65.436C20.555 65.436 20.8634 65.1146 20.8634 64.718C20.8634 64.3215 20.555 64 20.1745 64C19.7941 64 19.4857 64.3215 19.4857 64.718C19.4857 65.1146 19.7941 65.436 20.1745 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 65.436C23.3392 65.436 23.6476 65.1146 23.6476 64.718C23.6476 64.3215 23.3392 64 22.9587 64C22.5783 64 22.2698 64.3215 22.2698 64.718C22.2698 65.1146 22.5783 65.436 22.9587 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 65.436C26.1233 65.436 26.4317 65.1146 26.4317 64.718C26.4317 64.3215 26.1233 64 25.7428 64C25.3624 64 25.054 64.3215 25.054 64.718C25.054 65.1146 25.3624 65.436 25.7428 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 65.436C28.9075 65.436 29.2159 65.1146 29.2159 64.718C29.2159 64.3215 28.9075 64 28.527 64C28.1466 64 27.8381 64.3215 27.8381 64.718C27.8381 65.1146 28.1466 65.436 28.527 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 65.436C31.6916 65.436 32 65.1146 32 64.718C32 64.3215 31.6916 64 31.3111 64C30.9307 64 30.6223 64.3215 30.6223 64.718C30.6223 65.1146 30.9307 65.436 31.3111 65.436Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 68.2146C1.06932 68.2146 1.37773 67.8931 1.37773 67.4966C1.37773 67.1001 1.06932 66.7786 0.688866 66.7786C0.308416 66.7786 0 67.1001 0 67.4966C0 67.8931 0.308416 68.2146 0.688866 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 68.2146C3.85362 68.2146 4.16203 67.8931 4.16203 67.4966C4.16203 67.1001 3.85362 66.7786 3.47317 66.7786C3.09272 66.7786 2.7843 67.1001 2.7843 67.4966C2.7843 67.8931 3.09272 68.2146 3.47317 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 68.2146C6.63762 68.2146 6.94603 67.8931 6.94603 67.4966C6.94603 67.1001 6.63762 66.7786 6.25716 66.7786C5.87671 66.7786 5.5683 67.1001 5.5683 67.4966C5.5683 67.8931 5.87671 68.2146 6.25716 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 68.2146C9.42192 68.2146 9.73033 67.8931 9.73033 67.4966C9.73033 67.1001 9.42192 66.7786 9.04147 66.7786C8.66102 66.7786 8.3526 67.1001 8.3526 67.4966C8.3526 67.8931 8.66102 68.2146 9.04147 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 68.2146C12.2024 68.2146 12.5108 67.8931 12.5108 67.4966C12.5108 67.1001 12.2024 66.7786 11.822 66.7786C11.4415 66.7786 11.1331 67.1001 11.1331 67.4966C11.1331 67.8931 11.4415 68.2146 11.822 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 68.2146C14.9867 68.2146 15.2951 67.8931 15.2951 67.4966C15.2951 67.1001 14.9867 66.7786 14.6062 66.7786C14.2258 66.7786 13.9174 67.1001 13.9174 67.4966C13.9174 67.8931 14.2258 68.2146 14.6062 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 68.2146C17.7707 68.2146 18.0791 67.8931 18.0791 67.4966C18.0791 67.1001 17.7707 66.7786 17.3902 66.7786C17.0098 66.7786 16.7014 67.1001 16.7014 67.4966C16.7014 67.8931 17.0098 68.2146 17.3902 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 68.2146C20.5549 68.2146 20.8633 67.8931 20.8633 67.4966C20.8633 67.1001 20.5549 66.7786 20.1744 66.7786C19.7939 66.7786 19.4855 67.1001 19.4855 67.4966C19.4855 67.8931 19.7939 68.2146 20.1744 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 68.2146C23.3392 68.2146 23.6476 67.8931 23.6476 67.4966C23.6476 67.1001 23.3392 66.7786 22.9587 66.7786C22.5783 66.7786 22.2698 67.1001 22.2698 67.4966C22.2698 67.8931 22.5783 68.2146 22.9587 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 68.2146C26.1233 68.2146 26.4317 67.8931 26.4317 67.4966C26.4317 67.1001 26.1233 66.7786 25.7428 66.7786C25.3624 66.7786 25.054 67.1001 25.054 67.4966C25.054 67.8931 25.3624 68.2146 25.7428 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 68.2146C28.9075 68.2146 29.2159 67.8931 29.2159 67.4966C29.2159 67.1001 28.9075 66.7786 28.527 66.7786C28.1466 66.7786 27.8381 67.1001 27.8381 67.4966C27.8381 67.8931 28.1466 68.2146 28.527 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 68.2146C31.6916 68.2146 32 67.8931 32 67.4966C32 67.1001 31.6916 66.7786 31.3111 66.7786C30.9307 66.7786 30.6223 67.1001 30.6223 67.4966C30.6223 67.8931 30.9307 68.2146 31.3111 68.2146Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 70.9934C1.06932 70.9934 1.37773 70.6719 1.37773 70.2754C1.37773 69.8788 1.06932 69.5574 0.688866 69.5574C0.308416 69.5574 0 69.8788 0 70.2754C0 70.6719 0.308416 70.9934 0.688866 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 70.9934C3.85362 70.9934 4.16203 70.6719 4.16203 70.2754C4.16203 69.8788 3.85362 69.5574 3.47317 69.5574C3.09272 69.5574 2.7843 69.8788 2.7843 70.2754C2.7843 70.6719 3.09272 70.9934 3.47317 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 70.9934C6.63762 70.9934 6.94603 70.6719 6.94603 70.2754C6.94603 69.8788 6.63762 69.5574 6.25716 69.5574C5.87671 69.5574 5.5683 69.8788 5.5683 70.2754C5.5683 70.6719 5.87671 70.9934 6.25716 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 70.9934C9.42192 70.9934 9.73033 70.6719 9.73033 70.2754C9.73033 69.8788 9.42192 69.5574 9.04147 69.5574C8.66102 69.5574 8.3526 69.8788 8.3526 70.2754C8.3526 70.6719 8.66102 70.9934 9.04147 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 70.9934C12.2024 70.9934 12.5108 70.6719 12.5108 70.2754C12.5108 69.8788 12.2024 69.5574 11.822 69.5574C11.4415 69.5574 11.1331 69.8788 11.1331 70.2754C11.1331 70.6719 11.4415 70.9934 11.822 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 70.9934C14.9867 70.9934 15.2951 70.6719 15.2951 70.2754C15.2951 69.8788 14.9867 69.5574 14.6062 69.5574C14.2258 69.5574 13.9174 69.8788 13.9174 70.2754C13.9174 70.6719 14.2258 70.9934 14.6062 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 70.9934C17.7707 70.9934 18.0791 70.6719 18.0791 70.2754C18.0791 69.8788 17.7707 69.5574 17.3902 69.5574C17.0098 69.5574 16.7014 69.8788 16.7014 70.2754C16.7014 70.6719 17.0098 70.9934 17.3902 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 70.9934C20.5549 70.9934 20.8633 70.6719 20.8633 70.2754C20.8633 69.8788 20.5549 69.5574 20.1744 69.5574C19.7939 69.5574 19.4855 69.8788 19.4855 70.2754C19.4855 70.6719 19.7939 70.9934 20.1744 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 70.9934C23.3392 70.9934 23.6476 70.6719 23.6476 70.2754C23.6476 69.8788 23.3392 69.5574 22.9587 69.5574C22.5783 69.5574 22.2698 69.8788 22.2698 70.2754C22.2698 70.6719 22.5783 70.9934 22.9587 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 70.9934C26.1233 70.9934 26.4317 70.6719 26.4317 70.2754C26.4317 69.8788 26.1233 69.5574 25.7428 69.5574C25.3624 69.5574 25.054 69.8788 25.054 70.2754C25.054 70.6719 25.3624 70.9934 25.7428 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 70.9934C28.9075 70.9934 29.2159 70.6719 29.2159 70.2754C29.2159 69.8788 28.9075 69.5574 28.527 69.5574C28.1466 69.5574 27.8381 69.8788 27.8381 70.2754C27.8381 70.6719 28.1466 70.9934 28.527 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 70.9934C31.6916 70.9934 32 70.6719 32 70.2754C32 69.8788 31.6916 69.5574 31.3111 69.5574C30.9307 69.5574 30.6223 69.8788 30.6223 70.2754C30.6223 70.6719 30.9307 70.9934 31.3111 70.9934Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 73.7716C1.06932 73.7716 1.37773 73.4501 1.37773 73.0536C1.37773 72.657 1.06932 72.3356 0.688866 72.3356C0.308416 72.3356 0 72.657 0 73.0536C0 73.4501 0.308416 73.7716 0.688866 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 73.7716C3.85362 73.7716 4.16203 73.4501 4.16203 73.0536C4.16203 72.657 3.85362 72.3356 3.47317 72.3356C3.09272 72.3356 2.7843 72.657 2.7843 73.0536C2.7843 73.4501 3.09272 73.7716 3.47317 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 73.7716C6.63762 73.7716 6.94603 73.4501 6.94603 73.0536C6.94603 72.657 6.63762 72.3356 6.25716 72.3356C5.87671 72.3356 5.5683 72.657 5.5683 73.0536C5.5683 73.4501 5.87671 73.7716 6.25716 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 73.7716C9.42192 73.7716 9.73033 73.4501 9.73033 73.0536C9.73033 72.657 9.42192 72.3356 9.04147 72.3356C8.66102 72.3356 8.3526 72.657 8.3526 73.0536C8.3526 73.4501 8.66102 73.7716 9.04147 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 73.7716C12.2024 73.7716 12.5108 73.4501 12.5108 73.0536C12.5108 72.657 12.2024 72.3356 11.822 72.3356C11.4415 72.3356 11.1331 72.657 11.1331 73.0536C11.1331 73.4501 11.4415 73.7716 11.822 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 73.7716C14.9867 73.7716 15.2951 73.4501 15.2951 73.0536C15.2951 72.657 14.9867 72.3356 14.6062 72.3356C14.2258 72.3356 13.9174 72.657 13.9174 73.0536C13.9174 73.4501 14.2258 73.7716 14.6062 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 73.7716C17.7707 73.7716 18.0791 73.4501 18.0791 73.0536C18.0791 72.657 17.7707 72.3356 17.3902 72.3356C17.0098 72.3356 16.7014 72.657 16.7014 73.0536C16.7014 73.4501 17.0098 73.7716 17.3902 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 73.7716C20.5549 73.7716 20.8633 73.4501 20.8633 73.0536C20.8633 72.657 20.5549 72.3356 20.1744 72.3356C19.7939 72.3356 19.4855 72.657 19.4855 73.0536C19.4855 73.4501 19.7939 73.7716 20.1744 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 73.7716C23.3392 73.7716 23.6476 73.4501 23.6476 73.0536C23.6476 72.657 23.3392 72.3356 22.9587 72.3356C22.5783 72.3356 22.2698 72.657 22.2698 73.0536C22.2698 73.4501 22.5783 73.7716 22.9587 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 73.7716C26.1233 73.7716 26.4317 73.4501 26.4317 73.0536C26.4317 72.657 26.1233 72.3356 25.7428 72.3356C25.3624 72.3356 25.054 72.657 25.054 73.0536C25.054 73.4501 25.3624 73.7716 25.7428 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 73.7716C28.9075 73.7716 29.2159 73.4501 29.2159 73.0536C29.2159 72.657 28.9075 72.3356 28.527 72.3356C28.1466 72.3356 27.8381 72.657 27.8381 73.0536C27.8381 73.4501 28.1466 73.7716 28.527 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 73.7716C31.6916 73.7716 32 73.4501 32 73.0536C32 72.657 31.6916 72.3356 31.3111 72.3356C30.9307 72.3356 30.6223 72.657 30.6223 73.0536C30.6223 73.4501 30.9307 73.7716 31.3111 73.7716Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 76.5503C1.06932 76.5503 1.37773 76.2288 1.37773 75.8323C1.37773 75.4357 1.06932 75.1143 0.688866 75.1143C0.308416 75.1143 0 75.4357 0 75.8323C0 76.2288 0.308416 76.5503 0.688866 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 76.5503C3.85362 76.5503 4.16203 76.2288 4.16203 75.8323C4.16203 75.4357 3.85362 75.1143 3.47317 75.1143C3.09272 75.1143 2.7843 75.4357 2.7843 75.8323C2.7843 76.2288 3.09272 76.5503 3.47317 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 76.5503C6.63762 76.5503 6.94603 76.2288 6.94603 75.8323C6.94603 75.4357 6.63762 75.1143 6.25716 75.1143C5.87671 75.1143 5.5683 75.4357 5.5683 75.8323C5.5683 76.2288 5.87671 76.5503 6.25716 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 76.5503C9.42192 76.5503 9.73033 76.2288 9.73033 75.8323C9.73033 75.4357 9.42192 75.1143 9.04147 75.1143C8.66102 75.1143 8.3526 75.4357 8.3526 75.8323C8.3526 76.2288 8.66102 76.5503 9.04147 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 76.5503C12.2024 76.5503 12.5108 76.2288 12.5108 75.8323C12.5108 75.4357 12.2024 75.1143 11.822 75.1143C11.4415 75.1143 11.1331 75.4357 11.1331 75.8323C11.1331 76.2288 11.4415 76.5503 11.822 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 76.5503C14.9867 76.5503 15.2951 76.2288 15.2951 75.8323C15.2951 75.4357 14.9867 75.1143 14.6062 75.1143C14.2258 75.1143 13.9174 75.4357 13.9174 75.8323C13.9174 76.2288 14.2258 76.5503 14.6062 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 76.5503C17.7707 76.5503 18.0791 76.2288 18.0791 75.8323C18.0791 75.4357 17.7707 75.1143 17.3902 75.1143C17.0098 75.1143 16.7014 75.4357 16.7014 75.8323C16.7014 76.2288 17.0098 76.5503 17.3902 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 76.5503C20.5549 76.5503 20.8633 76.2288 20.8633 75.8323C20.8633 75.4357 20.5549 75.1143 20.1744 75.1143C19.7939 75.1143 19.4855 75.4357 19.4855 75.8323C19.4855 76.2288 19.7939 76.5503 20.1744 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 76.5503C23.3392 76.5503 23.6476 76.2288 23.6476 75.8323C23.6476 75.4357 23.3392 75.1143 22.9587 75.1143C22.5783 75.1143 22.2698 75.4357 22.2698 75.8323C22.2698 76.2288 22.5783 76.5503 22.9587 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 76.5503C26.1233 76.5503 26.4317 76.2288 26.4317 75.8323C26.4317 75.4357 26.1233 75.1143 25.7428 75.1143C25.3624 75.1143 25.054 75.4357 25.054 75.8323C25.054 76.2288 25.3624 76.5503 25.7428 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 76.5503C28.9075 76.5503 29.2159 76.2288 29.2159 75.8323C29.2159 75.4357 28.9075 75.1143 28.527 75.1143C28.1466 75.1143 27.8381 75.4357 27.8381 75.8323C27.8381 76.2288 28.1466 76.5503 28.527 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 76.5503C31.6916 76.5503 32 76.2288 32 75.8323C32 75.4357 31.6916 75.1143 31.3111 75.1143C30.9307 75.1143 30.6223 75.4357 30.6223 75.8323C30.6223 76.2288 30.9307 76.5503 31.3111 76.5503Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 79.3285C1.06932 79.3285 1.37773 79.007 1.37773 78.6105C1.37773 78.2139 1.06932 77.8925 0.688866 77.8925C0.308416 77.8925 0 78.2139 0 78.6105C0 79.007 0.308416 79.3285 0.688866 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 79.3285C3.85362 79.3285 4.16203 79.007 4.16203 78.6105C4.16203 78.2139 3.85362 77.8925 3.47317 77.8925C3.09272 77.8925 2.7843 78.2139 2.7843 78.6105C2.7843 79.007 3.09272 79.3285 3.47317 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 79.3285C6.63762 79.3285 6.94603 79.007 6.94603 78.6105C6.94603 78.2139 6.63762 77.8925 6.25716 77.8925C5.87671 77.8925 5.5683 78.2139 5.5683 78.6105C5.5683 79.007 5.87671 79.3285 6.25716 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 79.3285C9.42192 79.3285 9.73033 79.007 9.73033 78.6105C9.73033 78.2139 9.42192 77.8925 9.04147 77.8925C8.66102 77.8925 8.3526 78.2139 8.3526 78.6105C8.3526 79.007 8.66102 79.3285 9.04147 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 79.3285C12.2024 79.3285 12.5108 79.007 12.5108 78.6105C12.5108 78.2139 12.2024 77.8925 11.822 77.8925C11.4415 77.8925 11.1331 78.2139 11.1331 78.6105C11.1331 79.007 11.4415 79.3285 11.822 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 79.3285C14.9867 79.3285 15.2951 79.007 15.2951 78.6105C15.2951 78.2139 14.9867 77.8925 14.6062 77.8925C14.2258 77.8925 13.9174 78.2139 13.9174 78.6105C13.9174 79.007 14.2258 79.3285 14.6062 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 79.3285C17.7707 79.3285 18.0791 79.007 18.0791 78.6105C18.0791 78.2139 17.7707 77.8925 17.3902 77.8925C17.0098 77.8925 16.7014 78.2139 16.7014 78.6105C16.7014 79.007 17.0098 79.3285 17.3902 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 79.3285C20.5549 79.3285 20.8633 79.007 20.8633 78.6105C20.8633 78.2139 20.5549 77.8925 20.1744 77.8925C19.7939 77.8925 19.4855 78.2139 19.4855 78.6105C19.4855 79.007 19.7939 79.3285 20.1744 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 79.3285C23.3392 79.3285 23.6476 79.007 23.6476 78.6105C23.6476 78.2139 23.3392 77.8925 22.9587 77.8925C22.5783 77.8925 22.2698 78.2139 22.2698 78.6105C22.2698 79.007 22.5783 79.3285 22.9587 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 79.3285C26.1233 79.3285 26.4317 79.007 26.4317 78.6105C26.4317 78.2139 26.1233 77.8925 25.7428 77.8925C25.3624 77.8925 25.054 78.2139 25.054 78.6105C25.054 79.007 25.3624 79.3285 25.7428 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 79.3285C28.9075 79.3285 29.2159 79.007 29.2159 78.6105C29.2159 78.2139 28.9075 77.8925 28.527 77.8925C28.1466 77.8925 27.8381 78.2139 27.8381 78.6105C27.8381 79.007 28.1466 79.3285 28.527 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 79.3285C31.6916 79.3285 32 79.007 32 78.6105C32 78.2139 31.6916 77.8925 31.3111 77.8925C30.9307 77.8925 30.6223 78.2139 30.6223 78.6105C30.6223 79.007 30.9307 79.3285 31.3111 79.3285Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 82.1071C1.06932 82.1071 1.37773 81.7856 1.37773 81.3891C1.37773 80.9925 1.06932 80.6711 0.688866 80.6711C0.308416 80.6711 0 80.9925 0 81.3891C0 81.7856 0.308416 82.1071 0.688866 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 82.1071C3.85362 82.1071 4.16203 81.7856 4.16203 81.3891C4.16203 80.9925 3.85362 80.6711 3.47317 80.6711C3.09272 80.6711 2.7843 80.9925 2.7843 81.3891C2.7843 81.7856 3.09272 82.1071 3.47317 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 82.1071C6.63762 82.1071 6.94603 81.7856 6.94603 81.3891C6.94603 80.9925 6.63762 80.6711 6.25716 80.6711C5.87671 80.6711 5.5683 80.9925 5.5683 81.3891C5.5683 81.7856 5.87671 82.1071 6.25716 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 82.1071C9.42192 82.1071 9.73033 81.7856 9.73033 81.3891C9.73033 80.9925 9.42192 80.6711 9.04147 80.6711C8.66102 80.6711 8.3526 80.9925 8.3526 81.3891C8.3526 81.7856 8.66102 82.1071 9.04147 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 82.1071C12.2024 82.1071 12.5108 81.7856 12.5108 81.3891C12.5108 80.9925 12.2024 80.6711 11.822 80.6711C11.4415 80.6711 11.1331 80.9925 11.1331 81.3891C11.1331 81.7856 11.4415 82.1071 11.822 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 82.1071C14.9867 82.1071 15.2951 81.7856 15.2951 81.3891C15.2951 80.9925 14.9867 80.6711 14.6062 80.6711C14.2258 80.6711 13.9174 80.9925 13.9174 81.3891C13.9174 81.7856 14.2258 82.1071 14.6062 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 82.1071C17.7707 82.1071 18.0791 81.7856 18.0791 81.3891C18.0791 80.9925 17.7707 80.6711 17.3902 80.6711C17.0098 80.6711 16.7014 80.9925 16.7014 81.3891C16.7014 81.7856 17.0098 82.1071 17.3902 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 82.1071C20.5549 82.1071 20.8633 81.7856 20.8633 81.3891C20.8633 80.9925 20.5549 80.6711 20.1744 80.6711C19.7939 80.6711 19.4855 80.9925 19.4855 81.3891C19.4855 81.7856 19.7939 82.1071 20.1744 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 82.1071C23.3392 82.1071 23.6476 81.7856 23.6476 81.3891C23.6476 80.9925 23.3392 80.6711 22.9587 80.6711C22.5783 80.6711 22.2698 80.9925 22.2698 81.3891C22.2698 81.7856 22.5783 82.1071 22.9587 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 82.1071C26.1233 82.1071 26.4317 81.7856 26.4317 81.3891C26.4317 80.9925 26.1233 80.6711 25.7428 80.6711C25.3624 80.6711 25.054 80.9925 25.054 81.3891C25.054 81.7856 25.3624 82.1071 25.7428 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 82.1071C28.9075 82.1071 29.2159 81.7856 29.2159 81.3891C29.2159 80.9925 28.9075 80.6711 28.527 80.6711C28.1466 80.6711 27.8381 80.9925 27.8381 81.3891C27.8381 81.7856 28.1466 82.1071 28.527 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 82.1071C31.6916 82.1071 32 81.7856 32 81.3891C32 80.9925 31.6916 80.6711 31.3111 80.6711C30.9307 80.6711 30.6223 80.9925 30.6223 81.3891C30.6223 81.7856 30.9307 82.1071 31.3111 82.1071Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 84.8857C1.06932 84.8857 1.37773 84.5642 1.37773 84.1677C1.37773 83.7711 1.06932 83.4497 0.688866 83.4497C0.308416 83.4497 0 83.7711 0 84.1677C0 84.5642 0.308416 84.8857 0.688866 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 84.8857C3.85362 84.8857 4.16203 84.5642 4.16203 84.1677C4.16203 83.7711 3.85362 83.4497 3.47317 83.4497C3.09272 83.4497 2.7843 83.7711 2.7843 84.1677C2.7843 84.5642 3.09272 84.8857 3.47317 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 84.8857C6.63762 84.8857 6.94603 84.5642 6.94603 84.1677C6.94603 83.7711 6.63762 83.4497 6.25716 83.4497C5.87671 83.4497 5.5683 83.7711 5.5683 84.1677C5.5683 84.5642 5.87671 84.8857 6.25716 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 84.8857C9.42192 84.8857 9.73033 84.5642 9.73033 84.1677C9.73033 83.7711 9.42192 83.4497 9.04147 83.4497C8.66102 83.4497 8.3526 83.7711 8.3526 84.1677C8.3526 84.5642 8.66102 84.8857 9.04147 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 84.8857C12.2024 84.8857 12.5108 84.5642 12.5108 84.1677C12.5108 83.7711 12.2024 83.4497 11.822 83.4497C11.4415 83.4497 11.1331 83.7711 11.1331 84.1677C11.1331 84.5642 11.4415 84.8857 11.822 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 84.8857C14.9867 84.8857 15.2951 84.5642 15.2951 84.1677C15.2951 83.7711 14.9867 83.4497 14.6062 83.4497C14.2258 83.4497 13.9174 83.7711 13.9174 84.1677C13.9174 84.5642 14.2258 84.8857 14.6062 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 84.8857C17.7707 84.8857 18.0791 84.5642 18.0791 84.1677C18.0791 83.7711 17.7707 83.4497 17.3902 83.4497C17.0098 83.4497 16.7014 83.7711 16.7014 84.1677C16.7014 84.5642 17.0098 84.8857 17.3902 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 84.8857C20.5549 84.8857 20.8633 84.5642 20.8633 84.1677C20.8633 83.7711 20.5549 83.4497 20.1744 83.4497C19.7939 83.4497 19.4855 83.7711 19.4855 84.1677C19.4855 84.5642 19.7939 84.8857 20.1744 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 84.8857C23.3392 84.8857 23.6476 84.5642 23.6476 84.1677C23.6476 83.7711 23.3392 83.4497 22.9587 83.4497C22.5783 83.4497 22.2698 83.7711 22.2698 84.1677C22.2698 84.5642 22.5783 84.8857 22.9587 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 84.8857C26.1233 84.8857 26.4317 84.5642 26.4317 84.1677C26.4317 83.7711 26.1233 83.4497 25.7428 83.4497C25.3624 83.4497 25.054 83.7711 25.054 84.1677C25.054 84.5642 25.3624 84.8857 25.7428 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 84.8857C28.9075 84.8857 29.2159 84.5642 29.2159 84.1677C29.2159 83.7711 28.9075 83.4497 28.527 83.4497C28.1466 83.4497 27.8381 83.7711 27.8381 84.1677C27.8381 84.5642 28.1466 84.8857 28.527 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 84.8857C31.6916 84.8857 32 84.5642 32 84.1677C32 83.7711 31.6916 83.4497 31.3111 83.4497C30.9307 83.4497 30.6223 83.7711 30.6223 84.1677C30.6223 84.5642 30.9307 84.8857 31.3111 84.8857Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 87.6644C1.06932 87.6644 1.37773 87.343 1.37773 86.9464C1.37773 86.5499 1.06932 86.2284 0.688866 86.2284C0.308416 86.2284 0 86.5499 0 86.9464C0 87.343 0.308416 87.6644 0.688866 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 87.6644C3.85362 87.6644 4.16203 87.343 4.16203 86.9464C4.16203 86.5499 3.85362 86.2284 3.47317 86.2284C3.09272 86.2284 2.7843 86.5499 2.7843 86.9464C2.7843 87.343 3.09272 87.6644 3.47317 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 87.6644C6.63762 87.6644 6.94603 87.343 6.94603 86.9464C6.94603 86.5499 6.63762 86.2284 6.25716 86.2284C5.87671 86.2284 5.5683 86.5499 5.5683 86.9464C5.5683 87.343 5.87671 87.6644 6.25716 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 87.6644C9.42192 87.6644 9.73033 87.343 9.73033 86.9464C9.73033 86.5499 9.42192 86.2284 9.04147 86.2284C8.66102 86.2284 8.3526 86.5499 8.3526 86.9464C8.3526 87.343 8.66102 87.6644 9.04147 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 87.6644C12.2024 87.6644 12.5108 87.343 12.5108 86.9464C12.5108 86.5499 12.2024 86.2284 11.822 86.2284C11.4415 86.2284 11.1331 86.5499 11.1331 86.9464C11.1331 87.343 11.4415 87.6644 11.822 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 87.6644C14.9867 87.6644 15.2951 87.343 15.2951 86.9464C15.2951 86.5499 14.9867 86.2284 14.6062 86.2284C14.2258 86.2284 13.9174 86.5499 13.9174 86.9464C13.9174 87.343 14.2258 87.6644 14.6062 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 87.6644C17.7707 87.6644 18.0791 87.343 18.0791 86.9464C18.0791 86.5499 17.7707 86.2284 17.3902 86.2284C17.0098 86.2284 16.7014 86.5499 16.7014 86.9464C16.7014 87.343 17.0098 87.6644 17.3902 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 87.6644C20.5549 87.6644 20.8633 87.343 20.8633 86.9464C20.8633 86.5499 20.5549 86.2284 20.1744 86.2284C19.7939 86.2284 19.4855 86.5499 19.4855 86.9464C19.4855 87.343 19.7939 87.6644 20.1744 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 87.6644C23.3392 87.6644 23.6476 87.343 23.6476 86.9464C23.6476 86.5499 23.3392 86.2284 22.9587 86.2284C22.5783 86.2284 22.2698 86.5499 22.2698 86.9464C22.2698 87.343 22.5783 87.6644 22.9587 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 87.6644C26.1233 87.6644 26.4317 87.343 26.4317 86.9464C26.4317 86.5499 26.1233 86.2284 25.7428 86.2284C25.3624 86.2284 25.054 86.5499 25.054 86.9464C25.054 87.343 25.3624 87.6644 25.7428 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 87.6644C28.9075 87.6644 29.2159 87.343 29.2159 86.9464C29.2159 86.5499 28.9075 86.2284 28.527 86.2284C28.1466 86.2284 27.8381 86.5499 27.8381 86.9464C27.8381 87.343 28.1466 87.6644 28.527 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 87.6644C31.6916 87.6644 32 87.343 32 86.9464C32 86.5499 31.6916 86.2284 31.3111 86.2284C30.9307 86.2284 30.6223 86.5499 30.6223 86.9464C30.6223 87.343 30.9307 87.6644 31.3111 87.6644Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 90.4431C1.06932 90.4431 1.37773 90.1217 1.37773 89.7251C1.37773 89.3286 1.06932 89.0071 0.688866 89.0071C0.308416 89.0071 0 89.3286 0 89.7251C0 90.1217 0.308416 90.4431 0.688866 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 90.4431C3.85362 90.4431 4.16203 90.1217 4.16203 89.7251C4.16203 89.3286 3.85362 89.0071 3.47317 89.0071C3.09272 89.0071 2.7843 89.3286 2.7843 89.7251C2.7843 90.1217 3.09272 90.4431 3.47317 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 90.4431C6.63762 90.4431 6.94603 90.1217 6.94603 89.7251C6.94603 89.3286 6.63762 89.0071 6.25716 89.0071C5.87671 89.0071 5.5683 89.3286 5.5683 89.7251C5.5683 90.1217 5.87671 90.4431 6.25716 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 90.4431C9.42192 90.4431 9.73033 90.1217 9.73033 89.7251C9.73033 89.3286 9.42192 89.0071 9.04147 89.0071C8.66102 89.0071 8.3526 89.3286 8.3526 89.7251C8.3526 90.1217 8.66102 90.4431 9.04147 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 90.4431C12.2024 90.4431 12.5108 90.1217 12.5108 89.7251C12.5108 89.3286 12.2024 89.0071 11.822 89.0071C11.4415 89.0071 11.1331 89.3286 11.1331 89.7251C11.1331 90.1217 11.4415 90.4431 11.822 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 90.4431C14.9867 90.4431 15.2951 90.1217 15.2951 89.7251C15.2951 89.3286 14.9867 89.0071 14.6062 89.0071C14.2258 89.0071 13.9174 89.3286 13.9174 89.7251C13.9174 90.1217 14.2258 90.4431 14.6062 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 90.4431C17.7707 90.4431 18.0791 90.1217 18.0791 89.7251C18.0791 89.3286 17.7707 89.0071 17.3902 89.0071C17.0098 89.0071 16.7014 89.3286 16.7014 89.7251C16.7014 90.1217 17.0098 90.4431 17.3902 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 90.4431C20.5549 90.4431 20.8633 90.1217 20.8633 89.7251C20.8633 89.3286 20.5549 89.0071 20.1744 89.0071C19.7939 89.0071 19.4855 89.3286 19.4855 89.7251C19.4855 90.1217 19.7939 90.4431 20.1744 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 90.4431C23.3392 90.4431 23.6476 90.1217 23.6476 89.7251C23.6476 89.3286 23.3392 89.0071 22.9587 89.0071C22.5783 89.0071 22.2698 89.3286 22.2698 89.7251C22.2698 90.1217 22.5783 90.4431 22.9587 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 90.4431C26.1233 90.4431 26.4317 90.1217 26.4317 89.7251C26.4317 89.3286 26.1233 89.0071 25.7428 89.0071C25.3624 89.0071 25.054 89.3286 25.054 89.7251C25.054 90.1217 25.3624 90.4431 25.7428 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 90.4431C28.9075 90.4431 29.2159 90.1217 29.2159 89.7251C29.2159 89.3286 28.9075 89.0071 28.527 89.0071C28.1466 89.0071 27.8381 89.3286 27.8381 89.7251C27.8381 90.1217 28.1466 90.4431 28.527 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 90.4431C31.6916 90.4431 32 90.1217 32 89.7251C32 89.3286 31.6916 89.0071 31.3111 89.0071C30.9307 89.0071 30.6223 89.3286 30.6223 89.7251C30.6223 90.1217 30.9307 90.4431 31.3111 90.4431Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 93.2213C1.06932 93.2213 1.37773 92.8999 1.37773 92.5033C1.37773 92.1068 1.06932 91.7853 0.688866 91.7853C0.308416 91.7853 0 92.1068 0 92.5033C0 92.8999 0.308416 93.2213 0.688866 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 93.2213C3.85362 93.2213 4.16203 92.8999 4.16203 92.5033C4.16203 92.1068 3.85362 91.7853 3.47317 91.7853C3.09272 91.7853 2.7843 92.1068 2.7843 92.5033C2.7843 92.8999 3.09272 93.2213 3.47317 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 93.2213C6.63762 93.2213 6.94603 92.8999 6.94603 92.5033C6.94603 92.1068 6.63762 91.7853 6.25716 91.7853C5.87671 91.7853 5.5683 92.1068 5.5683 92.5033C5.5683 92.8999 5.87671 93.2213 6.25716 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 93.2213C9.42192 93.2213 9.73033 92.8999 9.73033 92.5033C9.73033 92.1068 9.42192 91.7853 9.04147 91.7853C8.66102 91.7853 8.3526 92.1068 8.3526 92.5033C8.3526 92.8999 8.66102 93.2213 9.04147 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 93.2213C12.2024 93.2213 12.5108 92.8999 12.5108 92.5033C12.5108 92.1068 12.2024 91.7853 11.822 91.7853C11.4415 91.7853 11.1331 92.1068 11.1331 92.5033C11.1331 92.8999 11.4415 93.2213 11.822 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 93.2213C14.9867 93.2213 15.2951 92.8999 15.2951 92.5033C15.2951 92.1068 14.9867 91.7853 14.6062 91.7853C14.2258 91.7853 13.9174 92.1068 13.9174 92.5033C13.9174 92.8999 14.2258 93.2213 14.6062 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 93.2213C17.7707 93.2213 18.0791 92.8999 18.0791 92.5033C18.0791 92.1068 17.7707 91.7853 17.3902 91.7853C17.0098 91.7853 16.7014 92.1068 16.7014 92.5033C16.7014 92.8999 17.0098 93.2213 17.3902 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 93.2213C20.5549 93.2213 20.8633 92.8999 20.8633 92.5033C20.8633 92.1068 20.5549 91.7853 20.1744 91.7853C19.7939 91.7853 19.4855 92.1068 19.4855 92.5033C19.4855 92.8999 19.7939 93.2213 20.1744 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 93.2213C23.3392 93.2213 23.6476 92.8999 23.6476 92.5033C23.6476 92.1068 23.3392 91.7853 22.9587 91.7853C22.5783 91.7853 22.2698 92.1068 22.2698 92.5033C22.2698 92.8999 22.5783 93.2213 22.9587 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 93.2213C26.1233 93.2213 26.4317 92.8999 26.4317 92.5033C26.4317 92.1068 26.1233 91.7853 25.7428 91.7853C25.3624 91.7853 25.054 92.1068 25.054 92.5033C25.054 92.8999 25.3624 93.2213 25.7428 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 93.2213C28.9075 93.2213 29.2159 92.8999 29.2159 92.5033C29.2159 92.1068 28.9075 91.7853 28.527 91.7853C28.1466 91.7853 27.8381 92.1068 27.8381 92.5033C27.8381 92.8999 28.1466 93.2213 28.527 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 93.2213C31.6916 93.2213 32 92.8999 32 92.5033C32 92.1068 31.6916 91.7853 31.3111 91.7853C30.9307 91.7853 30.6223 92.1068 30.6223 92.5033C30.6223 92.8999 30.9307 93.2213 31.3111 93.2213Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.688866 96.0001C1.06932 96.0001 1.37773 95.6786 1.37773 95.2821C1.37773 94.8855 1.06932 94.5641 0.688866 94.5641C0.308416 94.5641 0 94.8855 0 95.2821C0 95.6786 0.308416 96.0001 0.688866 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M3.47317 96.0001C3.85362 96.0001 4.16203 95.6786 4.16203 95.2821C4.16203 94.8855 3.85362 94.5641 3.47317 94.5641C3.09272 94.5641 2.7843 94.8855 2.7843 95.2821C2.7843 95.6786 3.09272 96.0001 3.47317 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M6.25716 96.0001C6.63762 96.0001 6.94603 95.6786 6.94603 95.2821C6.94603 94.8855 6.63762 94.5641 6.25716 94.5641C5.87671 94.5641 5.5683 94.8855 5.5683 95.2821C5.5683 95.6786 5.87671 96.0001 6.25716 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.04147 96.0001C9.42192 96.0001 9.73033 95.6786 9.73033 95.2821C9.73033 94.8855 9.42192 94.5641 9.04147 94.5641C8.66102 94.5641 8.3526 94.8855 8.3526 95.2821C8.3526 95.6786 8.66102 96.0001 9.04147 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M11.822 96.0001C12.2024 96.0001 12.5108 95.6786 12.5108 95.2821C12.5108 94.8855 12.2024 94.5641 11.822 94.5641C11.4415 94.5641 11.1331 94.8855 11.1331 95.2821C11.1331 95.6786 11.4415 96.0001 11.822 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M14.6062 96.0001C14.9867 96.0001 15.2951 95.6786 15.2951 95.2821C15.2951 94.8855 14.9867 94.5641 14.6062 94.5641C14.2258 94.5641 13.9174 94.8855 13.9174 95.2821C13.9174 95.6786 14.2258 96.0001 14.6062 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.3902 96.0001C17.7707 96.0001 18.0791 95.6786 18.0791 95.2821C18.0791 94.8855 17.7707 94.5641 17.3902 94.5641C17.0098 94.5641 16.7014 94.8855 16.7014 95.2821C16.7014 95.6786 17.0098 96.0001 17.3902 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M20.1744 96.0001C20.5549 96.0001 20.8633 95.6786 20.8633 95.2821C20.8633 94.8855 20.5549 94.5641 20.1744 94.5641C19.7939 94.5641 19.4855 94.8855 19.4855 95.2821C19.4855 95.6786 19.7939 96.0001 20.1744 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M22.9587 96.0001C23.3392 96.0001 23.6476 95.6786 23.6476 95.2821C23.6476 94.8855 23.3392 94.5641 22.9587 94.5641C22.5783 94.5641 22.2698 94.8855 22.2698 95.2821C22.2698 95.6786 22.5783 96.0001 22.9587 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.7428 96.0001C26.1233 96.0001 26.4317 95.6786 26.4317 95.2821C26.4317 94.8855 26.1233 94.5641 25.7428 94.5641C25.3624 94.5641 25.054 94.8855 25.054 95.2821C25.054 95.6786 25.3624 96.0001 25.7428 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M28.527 96.0001C28.9075 96.0001 29.2159 95.6786 29.2159 95.2821C29.2159 94.8855 28.9075 94.5641 28.527 94.5641C28.1466 94.5641 27.8381 94.8855 27.8381 95.2821C27.8381 95.6786 28.1466 96.0001 28.527 96.0001Z"
        fill="#E5E6E6"
      />
      <path
        d="M31.3111 96.0001C31.6916 96.0001 32 95.6786 32 95.2821C32 94.8855 31.6916 94.5641 31.3111 94.5641C30.9307 94.5641 30.6223 94.8855 30.6223 95.2821C30.6223 95.6786 30.9307 96.0001 31.3111 96.0001Z"
        fill="#E5E6E6"
      />
    </svg>
  );
};
