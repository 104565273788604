import { IconProps } from "typing";
import { theme } from "../themes";

export const InstagramIconFooter = ({
  alt = "Ícone do instagram",
  width = 28,
  height = 28,
  color = theme.colors.neutral["280"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M8.44737 3C5.44374 3 3 5.44374 3 8.44737V15.5526C3 18.5563 5.44374 21 8.44737 21H15.5526C18.5563 21 21 18.5563 21 15.5526V8.44737C21 5.44374 18.5563 3 15.5526 3H8.44737ZM16.7368 6.31579C17.2603 6.31579 17.6842 6.73974 17.6842 7.26316C17.6842 7.78611 17.2603 8.21053 16.7368 8.21053C16.2134 8.21053 15.7895 7.78611 15.7895 7.26316C15.7895 6.73974 16.2134 6.31579 16.7368 6.31579ZM12 7.26316C14.6119 7.26316 16.7368 9.38811 16.7368 12C16.7368 14.6119 14.6119 16.7368 12 16.7368C9.38811 16.7368 7.26316 14.6119 7.26316 12C7.26316 9.38811 9.38811 7.26316 12 7.26316ZM12 8.68421C11.5646 8.68421 11.1334 8.76998 10.7311 8.93661C10.3288 9.10324 9.96328 9.34748 9.65538 9.65538C9.34748 9.96328 9.10324 10.3288 8.93661 10.7311C8.76998 11.1334 8.68421 11.5646 8.68421 12C8.68421 12.4354 8.76998 12.8666 8.93661 13.2689C9.10324 13.6712 9.34748 14.0367 9.65538 14.3446C9.96328 14.6525 10.3288 14.8968 10.7311 15.0634C11.1334 15.23 11.5646 15.3158 12 15.3158C12.4354 15.3158 12.8666 15.23 13.2689 15.0634C13.6712 14.8968 14.0367 14.6525 14.3446 14.3446C14.6525 14.0367 14.8968 13.6712 15.0634 13.2689C15.23 12.8666 15.3158 12.4354 15.3158 12C15.3158 11.5646 15.23 11.1334 15.0634 10.7311C14.8968 10.3288 14.6525 9.96328 14.3446 9.65538C14.0367 9.34748 13.6712 9.10324 13.2689 8.93661C12.8666 8.76998 12.4354 8.68421 12 8.68421Z"
        fill={color}
      />
    </svg>
  );
};
