import { CustomFilterProductsDto } from "typing";
import { memo } from "react";
import { customFilterProductsData } from "./data";
import { FilterProducts } from "../../patterns/FilterProducts";

const CustomFilterProducts = memo(
  ({
    properties,
    filterProducts,
    source,
    isBlackTheme,
    preloadComponent = false,
  }: CustomFilterProductsDto) => {
    const { configMenuHeaderTitle, title } = customFilterProductsData(
      properties,
      !!isBlackTheme
    );

    return (
      <FilterProducts
        {...filterProducts}
        title={title ?? configMenuHeaderTitle}
        source={source}
        isBlackTheme={isBlackTheme}
        preloadComponent={preloadComponent}
      />
    );
  }
);

CustomFilterProducts.displayName = "CustomFilterProducts";

export { CustomFilterProducts };
