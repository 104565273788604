import styled, { css } from "styled-components";
// italo.albuquerquer o Select do react-select precisa ser implementado em infra e chamado em components/Select. Desse modo, todo lugar do projeto usará o Select de components, e não de react-select.
import Select from "react-select";
import { Button, Container, Filter, theme } from "ui";

export interface FilterProductsSearchContainerProps {
  isBlackTheme?: boolean;
}

export const FilterProductsContainer = styled(Container)`
  scroll-behavior: smooth;
  width: -webkit-fill-available;
  padding: 0 !important;

  max-width: 1402px;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: 0;
  }
`;

export interface SearchResultContentProps {
  padding?: string;
  isMobile?: boolean;
  isBlackTheme?: boolean;
}

const handleFilterIcon = (
  mobileFilterIconWidth: number,
  deskTopFilterIconWidth: number
) => {
  return theme.device.desktopFirst.mobileS
    ? mobileFilterIconWidth
    : deskTopFilterIconWidth;
};

export const SearchResultHeader = styled.div<SearchResultContentProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ padding }) => padding || "0 0"};
  border-bottom: 1px solid ${theme.colors.neutral["278"]};

  @media ${theme.device.desktopFirst.tabletS} {
    flex-direction: column;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    width: 100%;
  }
`;

export const SearchResultTitle = styled.h2<SearchResultContentProps>`
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 1.3rem;
  color: ${theme.colors.neutral["520"]};

  @media ${theme.device.desktopFirst.tabletXL} {
    width: 80%;
  }

  @media ${theme.device.desktopFirst.tablet} {
    width: 80%;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    width: 100%;
    font-size: 1.5rem;
  }
  /* 
  @media ${theme.device.desktopFirst.mobileM} {
    width: 100%;
    font-size: 1.5rem;
  }

  @media ${theme.device.desktopFirst.mobileS} {
    width: 100%;
    font-size: 1.5rem;
  } */
`;

type CustomSelectProps = {
  borderRadius?: string;
  cursor?: string;
};

export const CustomSelect = styled(Select)<CustomSelectProps>`
  > * {
    box-shadow: none !important;
    border-radius: ${({ borderRadius }) => borderRadius || "0"};
    cursor: ${({ cursor }) => cursor || "auto"};
  }

  .react-select__menu {
    margin-top: 0;
    background-color: transparent;
    z-index: 10;
  }

  .react-select__option {
    color: ${theme.colors.primary["200"]};
    cursor: pointer;
    background-color: ${theme.colors.neutral.white};

    :first-child {
      border-radius: ${theme.space.x1} ${theme.space.x1} 0 0;
    }
    :last-child {
      border-radius: 0 0 ${theme.space.x1} ${theme.space.x1};
    }
  }

  .react-select__option:hover {
    background-color: ${theme.colors.pink["50"]};
    color: ${theme.colors.primary["100"]};
  }

  .react-select__option--is-selected {
    background-color: ${theme.colors.primary["250"]};
    color: ${theme.colors.neutral.white};
  }

  .react-select__option--is-selected:hover {
    background-color: ${theme.colors.primary["250"]};
    color: ${theme.colors.neutral.white};
  }
`;

export const SearchResultCounter = styled.div<SearchResultContentProps>`
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  color: ${theme.colors.neutral["520"]};
`;

export const SearchResultContent = styled.div<SearchResultContentProps>`
  display: grid;
  grid-template-columns: 20% 80%;
  padding: ${({ padding }) => padding || "0 0"};

  @media ${theme.device.desktopFirst.laptop} {
    grid-template-columns: 22% 78%;
  }

  @media ${theme.device.desktopFirst.tabletXL} {
    grid-template-columns: 100%;
  }

  @media ${theme.device.desktopFirst.tablet} {
    grid-template-columns: 100%;
  }
`;

export const BreadcrumbBox = styled.div<SearchResultContentProps>``;

export const BoxSelect = styled.div<SearchResultContentProps>``;

export const SelectSortBy = styled.div<SearchResultContentProps>`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  position: relative;
  z-index: 6;

  label {
    margin: 0.5rem 0 1.33rem 0rem;
  }

  .react-select {
    &__control {
      width: 15rem;
      border-radius: 4px;
      height: 3.5rem;
      position: relative;
      border: thin solid ${theme.colors.primary["200"]};
      background-color: transparent;
      appearance: none;
      font-size: ${(isMobile) => (isMobile ? "1.2rem" : "1.3rem")};
      color: ${theme.colors.primary["200"]};
      outline: none;
      cursor: pointer;

      &:hover {
        border-color: ${theme.colors.primary["300"]};
      }
    }

    &__single-value {
      color: ${theme.colors.primary["200"]};
      text-overflow: initial;
      overflow: visible;
    }

    &__indicator-separator {
      opacity: 0;
    }

    &__indicator {
      color: ${theme.colors.primary["200"]};
      background: transparent;
    }

    &__option {
      font-size: 1.3rem;
    }
  }

  @media ${theme.device.desktopFirst.tabletXL} {
    .react-select {
      &__control {
        width: calc(16.65rem - 3rem);
      }
    }
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    .react-select {
      &__control {
        width: calc(14.15rem - 0.5rem);
      }
    }
  }

  @media ${theme.device.desktopFirst.mobileS} {
    .react-select {
      &__control {
        width: calc(13.35rem - 3rem);
      }
    }
  }
`;

export const FilterIcon = styled(Filter).attrs({
  width: handleFilterIcon(14, 20),
  height: handleFilterIcon(14, 20),
  color: theme.colors.neutral.white,
})``;

export const FilterButton = styled(Button)`
  margin-right: 10px;
  border-radius: 4px;

  &:hover {
    background-color: ${theme.colors.primary["200"]};
  }
`;

export const FilterProductsSearchContainer = styled.section<FilterProductsSearchContainerProps>`
  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      ${BreadcrumbBox} {
        a > svg > path {
          fill: ${theme.colors.neutral["560"]};
        }
        ol > li > a {
          color: ${theme.colors.neutral["560"]};
        }
      }

      ${SearchResultHeader} {
        border-bottom-color: ${theme.colors.blackFriday["100"]};
      }

      ${SearchResultTitle} {
        color: ${theme.colors.neutral.white};
      }

      ${SearchResultCounter} {
        color: ${theme.colors.neutral.white};
      }

      ${CustomSelect} {
        .react-select__option {
          color: ${theme.colors.neutral.white};
          background-color: ${theme.colors.blackFriday["200"]};
          border-top: 1px solid ${theme.colors.neutral.white};
          border-left: 1px solid ${theme.colors.neutral.white};
          border-right: 1px solid ${theme.colors.neutral.white};

          &:last-of-type {
            border-bottom: 1px solid ${theme.colors.neutral.white};
          }
        }

        .react-select__option:hover {
          color: ${theme.colors.neutral.white};
          background-color: ${theme.colors.neutral["110"]};
        }

        .react-select__option--is-selected {
          background-color: ${theme.colors.primary["250"]};
          color: ${theme.colors.neutral.white};
          border-color: ${theme.colors.primary["250"]};

          &:last-of-type {
            border-color: ${theme.colors.primary["250"]};
          }
        }

        .react-select__option--is-selected:hover {
          background-color: ${theme.colors.primary["250"]};
          color: ${theme.colors.neutral.white};
          border-color: ${theme.colors.primary["250"]};
        }
      }

      ${SelectSortBy} {
        label {
          color: ${theme.colors.neutral.white};
        }
        .react-select {
          &__control {
            color: ${theme.colors.neutral.white};
            border-color: ${theme.colors.neutral.white};

            &:hover {
              border-color: ${theme.colors.neutral["100"]};
            }
          }

          &__single-value {
            color: ${theme.colors.neutral.white};
            text-overflow: initial;
            overflow: visible;
          }

          &__indicator {
            color: ${theme.colors.neutral.white};
          }
        }
      }
    `}
`;

export const SkeletonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  margin-left: 0;
  gap: 7px;

  @media ${theme.device.desktopFirst.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
