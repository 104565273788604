import styled from "styled-components";
import { theme } from "../../themes";

type Display = "flex" | "inline-flex";
type FlexDirection = "row" | "row-reverse" | "column" | "column-reverse";
type FlexWrap = "wrap" | "nowrap" | "wrap-reverse";
type JustifyContent =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-around"
  | "space-between"
  | "space-evenly";
type AlignItems = "center" | "flex-start" | "flex-end" | "stretch" | "baseline";
type AlignContent =
  | "space-between"
  | "space-around"
  | "stretch"
  | "center"
  | "flex-start"
  | "flex-end";
type TextAlign = "initial" | "start" | "center" | "end" | "justify";

export interface FlexContainerProps {
  display?: Display;
  flexDirection?: FlexDirection;
  flexWrap?: FlexWrap;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  alignContent?: AlignContent;
  textAlign?: TextAlign;
  gap?: string;
  alignSelf?: string;
  margin?: string;
  backgroundColor?: string;
  whiteSpace?: string;
  height?: string;
  width?: string;
  position?: string;
}

export const FlexContainer = styled.div<Partial<FlexContainerProps>>`
  position: ${({ position }) => position || "static"};
  display: ${({ display }) => display || "flex"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "nowrap"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "stretch"};
  align-content: ${({ alignContent }) => alignContent || "stretch"};
  gap: ${({ gap }) => gap || theme.space.x0};
  text-align: ${({ textAlign }) => textAlign || "initial"};
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
  margin: ${({ margin }) => margin || "0px"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};
  white-space: ${({ whiteSpace }) => whiteSpace || "normal"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
`;
