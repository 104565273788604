import styled, { css } from "styled-components";
import { theme } from "ui";
import { CarouselThemeDto } from "typing";

interface CustomGridCarouselProps {
  customTheme?: CarouselThemeDto;
}

export const CustomGridCarousel = styled.section<CustomGridCarouselProps>`
  max-width: 1300px;
  margin: auto;
  width: 100%;

  .slick-list {
    max-width: 1300px;
    width: 100%;
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  ${({ customTheme }) =>
    customTheme &&
    css`
      .slick-disabled {
        background: ${customTheme?.sliderButton?.backgroundColor?.disabled};
        opacity: 1;
        visibility: visible;

        &:hover {
          background: ${customTheme?.sliderButton?.backgroundColor?.disabled};
        }
        cursor: default;

        path {
          stroke: ${customTheme?.sliderButton?.arrowColor?.disabled};
        }

        &:hover path {
          stroke: ${customTheme?.sliderButton?.arrowColor?.disabled};
        }
      }
    `}

  @media ${theme.device.desktopFirst.tablet} {
    .slick-track {
      display: block;
    }
  }
`;
