import { IAppMonitoringClient, IHttpClient } from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import {
  CartAddItems,
  CartDeleteItem,
  CartDto,
  CartItemsDto,
  CartUpdateItem,
  IHttpResponse,
} from "typing";
import {
  defaultHeaders,
  formatBranchId,
  getApisUrlFallback,
} from "../../../utils";
import { EnvsApi } from "../Envs";
import { handleCartError } from "./data";

const applicationJson = "application/json";
const aplicationJsonPatch = "application/json-patch+json";

class ShoppingApi implements IApi {
  baseUrl = "";

  constructor(
    private httpClient: IHttpClient,
    private appMonitoringClient: IAppMonitoringClient,
    private envsApi: EnvsApi
  ) {
    if (!this.baseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}shopping/`;
    }

    this.appMonitoringClient = appMonitoringClient;
  }

  createdCartAuthenticatedEndpoint = () => {
    return `${this.baseUrl}v1/carts`;
  };

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getCart = (
    jwt: string,
    condition: boolean
  ): IHttpResponse<CartDto, unknown> => {
    const { data, error, isLoading, trigger } = this.httpClient.useGet<CartDto>(
      this.createdCartAuthenticatedEndpoint(),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition
    ) as IHttpResponse<CartDto, unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  createdCartAuthenticated = (
    branchId: number,
    appMonitoringClient: IAppMonitoringClient,
    jwt: string
  ): IHttpResponse<CartDto, unknown> | null => {
    const branchIdValue = formatBranchId(branchId.toString());

    try {
      return this.httpClient.usePut<{ branchId: number }>(
        this.createdCartAuthenticatedEndpoint(),
        {
          Authorization: `Bearer ${jwt}`,
        },
        { branchId: Number(branchIdValue) }
      ) as unknown as IHttpResponse<CartDto, unknown>;
    } catch (error) {
      handleCartError(error, appMonitoringClient, "create_cart_authenticated");
      return null;
    }
  };

  getCartAuthEndpoint = () => {
    return `${this.baseUrl}v1/carts?originSystem=Site`;
  };

  fetchCartAuthenticated = async (
    jwt: string
  ): Promise<IHttpResponse<CartDto, unknown>> => {
    try {
      const response = await fetch(this.getCartAuthEndpoint(), {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      const data = (await response.json()) as CartDto;

      return {
        data,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureMessage(JSON.stringify(error));

      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  getCartAuthenticated = (
    jwt: string,
    appMonitoringClient: IAppMonitoringClient,
    condition = true,
    fallbackData: CartDto | undefined = undefined
  ): IHttpResponse<CartDto, unknown> => {
    const { data, isLoading, trigger, error } = this.httpClient.useGet<CartDto>(
      this.getCartAuthEndpoint(),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition,
      fallbackData
    ) as IHttpResponse<CartDto, unknown>;

    handleCartError(error, appMonitoringClient, "get_cart_authenticated");

    return {
      data,
      isLoading,
      trigger,
      error,
    };
  };

  getCartAnonymousEndpoint = (cookieId: string) => {
    return `${this.baseUrl}v1/carts/anonymous?cookieId=${cookieId}&originSystem=Site`;
  };

  fetchCartAnonymous = async (cookieId: string) => {
    try {
      const response = await fetch(this.getCartAnonymousEndpoint(cookieId), {
        headers: {
          ...defaultHeaders,
          "content-type": applicationJson,
        },
      });

      const data = (await response.json()) as CartDto;

      return {
        data,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureMessage(JSON.stringify(error));
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  getCartAnonymous = (
    cookieId: string,
    appMonitoringClient: IAppMonitoringClient,
    condition = true
  ): IHttpResponse<CartDto, unknown> => {
    const { data, isLoading, trigger, error } = this.httpClient.useGet<CartDto>(
      this.getCartAnonymousEndpoint(cookieId),
      {},
      condition
    ) as IHttpResponse<CartDto, unknown>;

    handleCartError(error, appMonitoringClient, "get_cart_anonymous");

    return {
      data,
      isLoading,
      trigger,
      error,
    };
  };

  createCartAnonymousEndpoint = () => {
    return `${this.baseUrl}v1/carts/anonymous`;
  };

  createCartAnonymous = async (
    branchId: number,
    appMonitoringClient: IAppMonitoringClient
  ) => {
    const branchIdValue = formatBranchId(branchId.toString());
    try {
      return (await this.httpClient.usePost<CartDto>(
        this.createCartAnonymousEndpoint(),
        { branchId: Number(branchIdValue) } as unknown as BodyInit
      )) as IHttpResponse<CartDto, unknown>;
    } catch (error) {
      handleCartError(error, appMonitoringClient, "create_cart_anonymous");
      return null;
    }
  };

  addItemsCartEndpoint = (cartId: number) => {
    return `${this.baseUrl}v1/carts/${cartId}/cart-items`;
  };

  addItemsCart = async (
    { cartId, ...args }: CartAddItems,
    appMonitoringClient: IAppMonitoringClient,
    jwt?: string
  ): Promise<IHttpResponse<CartItemsDto, unknown> | null> => {
    try {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      const headers: { Authorization?: string; "Content-Type": string } = {
        "Content-Type": applicationJson,
      };

      if (jwt) {
        headers.Authorization = `Bearer ${jwt}`;
      }
      return (await this.httpClient.usePost<CartItemsDto>(
        this.addItemsCartEndpoint(cartId),
        {
          cartId,
          ...args,
        } as unknown as BodyInit,
        {
          headers,
        }
      )) as IHttpResponse<CartItemsDto, unknown>;
    } catch (error) {
      handleCartError(error, appMonitoringClient, "add_item_to_cart");
      return null;
    }
  };

  addItemsCartByServer = async (
    { cartId, ...args }: CartAddItems,
    jwt?: string
  ): Promise<IHttpResponse<CartItemsDto, unknown> | null> => {
    try {
      const headers: { Authorization?: string; "Content-Type": string } = {
        "Content-Type": applicationJson,
      };

      if (jwt) {
        headers.Authorization = `Bearer ${jwt}`;
      }
      const response = await fetch(this.addItemsCartEndpoint(cartId ?? 0), {
        method: "POST",
        headers: {
          "Content-Type": applicationJson,
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          cartId,
          ...args,
        }),
      });

      const data = await response.json();

      return {
        data,
        error: null,
        isLoading: false,
        status: response.status,
        statusCode: response.status,
      } as IHttpResponse<CartItemsDto, unknown>;
    } catch (error) {
      return null;
    }
  };

  updateQuantityItemEndpoint = (cartId: number, cartItemId: number) => {
    return `${this.baseUrl}v1/carts/${cartId}/cart-items/${cartItemId}`;
  };

  updateQuantityItemCart = async (
    { cartId, idProductCart, quantity }: CartUpdateItem,
    appMonitoringClient: IAppMonitoringClient,
    jwt?: string
  ): Promise<IHttpResponse<CartItemsDto, unknown> | null> => {
    const body = [{ op: "replace", path: "/Quantity", value: quantity }];
    const headers: { Authorization?: string; "Content-Type": string } = {
      "Content-Type": aplicationJsonPatch,
    };

    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`;
    }
    try {
      return (await this.httpClient.usePatch(
        this.updateQuantityItemEndpoint(cartId, idProductCart),
        headers,
        body as unknown as BodyInit
      )) as unknown as Promise<IHttpResponse<CartItemsDto, Error>>;
    } catch (error) {
      handleCartError(error, appMonitoringClient, "update_item_to_cart");
      return null;
    }
  };

  updateQuantityItemCartByServer = async (
    { cartId, idProductCart, quantity }: CartUpdateItem,
    appMonitoringClient: IAppMonitoringClient,
    jwt?: string
  ): Promise<IHttpResponse<CartItemsDto, unknown> | null> => {
    const body = [{ op: "replace", path: "/Quantity", value: quantity }];
    const headers: { Authorization?: string; "Content-Type": string } = {
      "Content-Type": aplicationJsonPatch,
    };

    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`;
    }
    try {
      const response = fetch(
        this.updateQuantityItemEndpoint(cartId, idProductCart),
        {
          method: "PATCH",
          headers: {
            "Content-Type": aplicationJsonPatch,
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify(body),
        }
      );

      const data = (await response).json();
      return {
        data,
        error: null,
        isLoading: false,
      } as unknown as IHttpResponse<CartItemsDto, Error>;
    } catch (error) {
      handleCartError(error, appMonitoringClient, "update_item_to_cart");
      return null;
    }
  };

  removeItemCartEndpoint = (cartId: number, cartItemId: number) => {
    return `${this.baseUrl}v1/carts/${cartId}/cart-items/${cartItemId}`;
  };

  removeItemCart = async (
    { cartId, idProductCart }: CartDeleteItem,
    appMonitoringClient: IAppMonitoringClient,
    jwt?: string
  ): Promise<IHttpResponse<undefined, unknown> | null | boolean> => {
    const headers: { Authorization?: string } = {};
    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`;
    }
    try {
      return (await this.httpClient.useDelete(
        this.removeItemCartEndpoint(cartId, idProductCart),
        headers,
        {
          id: idProductCart,
          cartId,
        } as unknown as BodyInit
      )) as unknown as IHttpResponse<undefined, unknown>;
    } catch (error) {
      handleCartError(error, appMonitoringClient, "remove_item_from_cart");
      return !!error;
    }
  };

  deleteProductsCartEndpoint = (cartId: string) => {
    return `${this.baseUrl}v1/carts/${cartId}/cart-items/package`;
  };

  deleteProductsCart = async (
    cartId: string,
    appMonitoringClient: IAppMonitoringClient,
    productIdList?: number[],
    accessToken?: string
  ) => {
    try {
      return await this.httpClient.useDelete(
        this.deleteProductsCartEndpoint(cartId),
        {
          Authorization: `Bearer ${accessToken}`,
        },
        productIdList as unknown as BodyInit
      );
    } catch (error) {
      handleCartError(error, appMonitoringClient, "delete_item_in_payment");
      return !!error;
    }
  };
}

export { ShoppingApi };
