import styled from "styled-components";
import { theme } from "ui";
import { Input } from "../../Form";

// artur.castro remover os !important
export const InputCep = styled(Input)`
  border: none !important;
  max-height: 4rem !important;
  max-width: 11rem !important;
  padding: 16px 8px !important;
  font-size: 1.2rem !important;
  line-height: 4rem !important;
  color: ${theme.colors.neutral["900"]} !important;
  margin: 0 !important;

  @media ${theme.device.desktopFirst.mobileL} {
    max-width: 8rem !important;
    font-size: 1.2rem !important;
  }

  & > input {
    margin: 0;
    border: none;
  }
`;
