import { ArrowDown, ArrowUp, theme } from "ui";
import { buildTestIds, useAppContext } from "application";
import {
  ButtonArrowPrev,
  ButtonArrowNext,
  Container,
  ContainerList,
  CarouselLib,
} from "./styles";
import { Slider } from "../../Slider";

interface CarouselThumbProps {
  children: JSX.Element[] | JSX.Element;
}

const CarouselThumb = ({ children }: CarouselThumbProps) => {
  const { isClientTablet } = useAppContext();

  return (
    <Container {...buildTestIds("carousel-thumb-container")}>
      {isClientTablet ? (
        <ContainerList {...buildTestIds("carousel-thumb-container-list")}>
          {children}
        </ContainerList>
      ) : (
        <CarouselLib {...buildTestIds("carousel-lib-wrapper")}>
          <Slider
            {...{
              speed: 500,
              slidesToShow: 8,
              slidesToScroll: 2,
              vertical: true,
              arrowsIsActive: true,
              touchMove: true,
              verticalSwiping: true,
              swipe: false,
              PrevButton: (
                <ButtonArrowPrev
                  {...buildTestIds("carousel-thumb-button-arrow-prev")}
                >
                  <ArrowUp
                    alt="seta para cima"
                    width={35}
                    height={35}
                    color={theme.colors.primary["200"]}
                  />
                </ButtonArrowPrev>
              ),
              NextButton: (
                <ButtonArrowNext
                  {...buildTestIds("carousel-thumb-button-arrow-next")}
                >
                  <ArrowDown
                    alt="seta para baixo"
                    width={35}
                    height={35}
                    color={theme.colors.primary["200"]}
                  />
                </ButtonArrowNext>
              ),
            }}
          >
            {children}
          </Slider>
        </CarouselLib>
      )}
    </Container>
  );
};

export { CarouselThumb };
