import { buildTestIds } from "application";
import { useMemo } from "react";
import { useController } from "react-hook-form";
import FieldError from "../FieldError";
import { FieldInfo } from "../FieldInfo";
import Label from "../Label";
import {
  IconContainer,
  Option,
  Select,
  SelectContainer,
  StyledSelect,
} from "./style";
import { CustomSelectProps } from "./type";

export const CustomSelect = (props: CustomSelectProps) => {
  const {
    name,
    autoComplete,
    backgroundColor,
    borderColor,
    borderRadius,
    borderWidth,
    color,
    colorInfo,
    cursor,
    fontSize,
    fontSizeInfo,
    hasError,
    hasIcon,
    hasIconBorder,
    hasSuccessfully,
    icon,
    label,
    margin,
    onClickIcon,
    padding,
    paddingErrorMessage,
    paddingInfo,
    textError,
    textInfo,
    width,
    control,
    defaultValue,
    required,
    rules,
    shouldUnregister,
    disabled,
    onBlur,
    onChange,
    onFocus,
    onMouseOut,
    onMouseOver,
    placeholder,
    role,
    value,
    height,
    id,
    ref,
    showOptionalText,
    optionsList,
    deps,

    limit,
    multiple,
  } = props;

  const requiredText = useMemo(() => {
    return typeof required === "string" ? required : "Campo obrigatório";
  }, [required]);

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules: {
      ...rules,
      required: { value: required as boolean, message: requiredText },
      onChange,
      onBlur,
      shouldUnregister,
      value,
      deps,
    },
    shouldUnregister,
  });

  const hasSuccessState = useMemo(
    () => hasSuccessfully || Boolean(field.value),
    [field.value, hasSuccessfully]
  );

  const hasErrorState = useMemo(
    () =>
      Boolean(
        (hasError as unknown as boolean) ||
          Boolean(fieldState.error || fieldState.invalid)
      ) && !hasSuccessState,
    [fieldState.error, fieldState.invalid, hasError, hasSuccessState]
  );

  const errorMessageText = useMemo(
    () =>
      textError ??
      fieldState.error?.root?.message ??
      fieldState.error?.message ??
      requiredText,
    [
      fieldState.error?.message,
      fieldState.error?.root?.message,
      requiredText,
      textError,
    ]
  );

  return (
    <StyledSelect
      hasSuccessfully={hasSuccessState}
      hasError={hasErrorState}
      width={width}
      height={height}
      hasIcon={hasIcon}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderColor={borderColor}
      padding={padding}
      margin={margin}
      color={color}
      fontSize={fontSize}
      backgroundColor={backgroundColor}
      hasIconBorder={hasIconBorder}
      {...buildTestIds(`form-styled-select-${id}`)}
      isDisabled={disabled}
    >
      {label && (
        <Label
          text={label}
          isRequired={Boolean(required)}
          hasSuccessfully={hasSuccessState}
          htmlFor={id}
          showOptionalText={showOptionalText}
          typeFontWeight={400}
          {...buildTestIds(`form-select-label-${id}`)}
        />
      )}

      <SelectContainer width={width} hasIcon={hasIcon}>
        {hasIcon && (
          <IconContainer
            cursor={cursor}
            hasSuccessfully={hasSuccessfully}
            margin={margin}
            hasError={hasError as unknown as boolean}
            onClick={onClickIcon}
            {...buildTestIds(`form-icon-container-${id}`)}
          >
            {icon}
          </IconContainer>
        )}

        <Select
          {...field}
          id={id}
          autoComplete={autoComplete}
          onChange={(e) => {
            if (onChange) onChange(e);

            if (field) field.onChange(e);
          }}
          onBlur={(e) => {
            if (onBlur) onBlur(e);

            if (field) field.onBlur();
          }}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          onFocus={onFocus}
          required={Boolean(required)}
          disabled={disabled}
          placeholder={placeholder}
          role={role}
          value={value || field.value}
          key={id}
          ref={ref as React.Ref<HTMLSelectElement>}
          multiple={multiple}
          size={limit}
          {...buildTestIds(`form-select-${id}`)}
        >
          <Option key={0} value="">
            {placeholder ?? "Selecione uma opção"}
          </Option>
          {optionsList.map((option) => (
            <Option key={option.index} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </SelectContainer>

      {errorMessageText && hasErrorState && (
        <FieldError
          text={errorMessageText}
          padding={paddingErrorMessage}
          fontSize={fontSizeInfo}
          {...buildTestIds(`form-field-error-select-${id}`)}
        />
      )}

      {textInfo && (
        <FieldInfo
          padding={paddingInfo}
          text={textInfo}
          fontSize={fontSizeInfo}
          color={colorInfo}
          {...buildTestIds(`form-field-info-select-${id}`)}
        />
      )}
    </StyledSelect>
  );
};
