import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const VeryUnsatisfiedFaceIcon = ({
  alt = "Ícone de Avaliação - Muito Insatisfeito",
  width = 40,
  height = 40,
  color = theme.colors.primary["95"],
  secondColor = theme.colors.primary["550"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle
        cx="20"
        cy="20"
        r="19"
        fill={color}
        stroke={secondColor}
        strokeWidth="2"
      />
      <path
        d="M26.405 32H14.4144C12.8859 32 11.9718 30.3814 12.8945 29.1629C14.5799 26.937 17.1903 24.1851 19.8936 24.0091C22.8664 23.8156 25.9154 26.7395 27.8758 29.1045C28.8765 30.3117 27.973 32 26.405 32Z"
        fill={color}
        stroke={secondColor}
        strokeWidth="2"
      />
      <path
        d="M9.11804 16.8969L12.285 15.0685C13.2416 14.5162 14.4647 14.8439 15.017 15.8005L16.8455 18.9675"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.1544 18.9667L24.9828 15.7998C25.5351 14.8432 26.7583 14.5155 27.7149 15.0678L30.8818 16.8962"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
