import { IconProps } from "typing";
import { theme } from "../themes";

export const Package = ({
  alt = "Pacote",
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M3.21063 4V8.39467H20.7893V4H3.21063ZM3.94308 9.12712V20.48H20.0569V9.12712H3.94308ZM10.2533 11.2258H13.7466C14.2531 11.2258 14.6622 11.6349 14.6622 12.1413C14.6622 12.6478 14.2531 13.0569 13.7466 13.0569H10.2533C9.74689 13.0569 9.33775 12.6478 9.33775 12.1413C9.33775 11.6349 9.74689 11.2258 10.2533 11.2258Z"
        fill={color}
      />
    </svg>
  );
};
