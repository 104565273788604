import styled, { css } from "styled-components";
import { theme } from "ui";

interface BreadcrumbsFilterProps {
  isBlackTheme?: boolean;
}

export const BreadcrumbsFilterList = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  background-color: ${theme.colors.neutral["white-050"]};
  padding: 0.5rem;
  overflow-x: auto;
`;

export const BreadcrumbFilterItem = styled.a`
  background-color: ${theme.colors.neutral.white};
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  border-radius: 4px;

  &:last-of-type {
    margin-right: 0rem;
  }
`;

export const FilterTitle = styled.small`
  font-size: 1rem;
  color: ${theme.colors.neutral["450"]};
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  white-space: nowrap;
  display: block;
  margin-bottom: 5px;
`;

export const FilterText = styled.p`
  font-size: 1.167rem;
  font-weight: 400;
  color: ${theme.colors.neutral["520"]};
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  white-space: nowrap;
  display: block;
`;

export const CloseFilter = styled.p`
  position: absolute;
  top: calc(50% - 10px);
  right: 5px;
`;

export const BreadcrumbsFilterStyled = styled.div<BreadcrumbsFilterProps>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  left: 0;
  margin-bottom: 3rem;

  @media ${theme.device.desktopFirst.tablet} {
    width: 100%;
    position: relative;
    left: 0px;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    width: 100%;
    position: relative;
    left: 0px;
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      ${BreadcrumbsFilterList} {
        padding: 0;
        background-color: transparent;
      }

      ${BreadcrumbFilterItem} {
        background-color: ${theme.colors.neutral["110"]};
      }

      ${FilterTitle} {
        color: ${theme.colors.neutral["150"]};
      }

      ${FilterText} {
        color: ${theme.colors.neutral.white};
      }

      ${CloseFilter} {
        path {
          stroke: ${theme.colors.neutral["150"]};
        }
      }
    `}
`;
