import styled from "styled-components";
import { FlexContainer, theme } from "ui";

type RemoveSelectionProps = {
  isVisible?: boolean;
};

export const RemoveSelectionWrapper = styled(
  FlexContainer
)<RemoveSelectionProps>`
  box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  background-color: white;
  bottom: 0;
  z-index: 100;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-top: 2px solid red;
  margin-left: 1rem;
  padding: 2rem;
  transition: all 0.2s ease;
  opacity: ${({ isVisible }) => (!isVisible ? `0` : `1`)};
  transform: ${({ isVisible }) =>
    !isVisible ? `translateY(100%)` : `translateY(0)`};
`;

export const RemoveSelectionFirst = styled(FlexContainer)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
`;

export const SelectedQuantityBox = styled(FlexContainer)`
  font-size: 16px;
  min-width: 13rem;
  justify-content: center;
`;

export const CancelSecectionButton = styled(FlexContainer)`
  color: ${theme.colors.primary["200"]};
  font-weight: ${theme.typography.fontWeight["700"]};
  font-size: 16px;
  width: fit-content;
  cursor: pointer;
`;

export const RemoveSelectionSecond = styled(FlexContainer)`
  border: thin solid ${theme.colors.primary["200"]};
  color: ${theme.colors.primary["200"]};
  font-size: 20px;
  font-weight: ${theme.typography.fontWeight["700"]};
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  transition: 300ms ease;
  padding: 1rem 2rem;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.pink["50"]};
  }
`;

export const ActionButton = styled(FlexContainer)`
  background-color: ${theme.colors.secondary["200"]};
  color: ${theme.colors.neutral.white};
  font-size: 20px;
  font-weight: ${theme.typography.fontWeight["700"]};
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  transition: 300ms ease;
  padding: 1rem 2rem;
  cursor: pointer;
`;

export const Container = styled(FlexContainer)`
  gap: 2rem;
`;
