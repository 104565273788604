import {
  CustomImagesGridProperty,
  Title,
  ShowTitle,
  TextAlign,
  SubTitle,
  ShowSubTitle,
  Orientation,
  FormatImagesGrid,
} from "typing";

const customImagesGridData = (properties: CustomImagesGridProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const showTitle = (
    properties.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const textAlign = (
    properties.find(({ key }) => key === "textAlign") as TextAlign
  )?.value;

  const subTitle = (
    properties.find(({ key }) => key === "subTitle") as SubTitle
  )?.value;

  const showSubTitle = (
    properties.find(({ key }) => key === "showSubTitle") as ShowSubTitle
  )?.value;

  const orientation = (
    properties.find(({ key }) => key === "orientation") as Orientation
  )?.value;

  const format = (
    properties.find(({ key }) => key === "format") as FormatImagesGrid
  )?.value;

  return {
    title,
    showTitle,
    textAlign,
    subTitle,
    showSubTitle,
    orientation,
    format,
  };
};

export { customImagesGridData };
