import { useAppContext } from "application";
import { Bag, BlackFridayBag, CartLogoFCBlack, Lock, theme } from "ui";
import { LogoWrapper, MobileLogoFC } from "../../Header/styles";
import { LinkRedirectHome } from "../../LinkRedirectHome";
import {
  BoxDescription,
  BoxDescriptionTitlePage,
  BoxInfoSecurity,
  ContainerHeader,
  TextCartTitle,
  TextSecurity,
} from "./styles";

const HeaderCart = () => {
  const { isBlackTheme } = useAppContext();

  const WIDTH_SCREEN = window.innerWidth;
  const WIDTH_DEFAULT_MOBILE = Number(
    theme.device.size.mobileXL.replace("px", "")
  );
  if (WIDTH_SCREEN > WIDTH_DEFAULT_MOBILE) {
    return null;
  }

  return (
    <ContainerHeader customTheme={isBlackTheme}>
      <BoxDescription>
        <LinkRedirectHome hrefQueryParams="IsOpen=False">
          {isBlackTheme ? (
            <CartLogoFCBlack />
          ) : (
            <LogoWrapper>
              <MobileLogoFC
                src="https://d2urakutajlof6.cloudfront.net/logo/logo_fc.svg"
                alt="Logo escrito Ferreira Costa, em branco com bordas vermelhas"
                layout="fill"
                priority
              />
            </LogoWrapper>
          )}
        </LinkRedirectHome>
        <BoxInfoSecurity>
          <Lock height={24} width={22} color={theme.colors.neutral["500"]} />
          <TextSecurity>Página Segura</TextSecurity>
        </BoxInfoSecurity>
      </BoxDescription>
      <BoxDescriptionTitlePage>
        <BoxInfoSecurity>
          {isBlackTheme ? <BlackFridayBag /> : <Bag />}
          <TextCartTitle>Carrinho</TextCartTitle>
        </BoxInfoSecurity>
      </BoxDescriptionTitlePage>
    </ContainerHeader>
  );
};

export default HeaderCart;
