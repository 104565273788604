import { buildTestIds, useAppContext } from "application";
import { useTwoFactor } from "application/state-manager";
import { useShallow } from "application/state-manager/adapter";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { theme } from "ui";
import { SignUpFormButton } from "../../Register/Form/Body/styles";

export interface TwoFactorButtonProps {
  title: string;
  isDisabled: string | boolean;
  handleOnClick: () => void;
}

const TwoFactorButton = ({
  title,
  isDisabled,
  handleOnClick,
}: TwoFactorButtonProps) => {
  const { isClientMobile } = useAppContext();
  const router = useRouter();

  const { isUnderAction, setIsValidationActive } = useTwoFactor(
    useShallow((state) => ({
      isUnderAction: state.isUnderAction,
      setIsValidationActive: state.setIsValidationActive,
    }))
  );

  useEffect(() => {
    if (isClientMobile && router.pathname !== "/") {
      setIsValidationActive(false);
    }
  }, [router.pathname, isClientMobile, setIsValidationActive]);

  return (
    <SignUpFormButton
      {...buildTestIds("two-factor-button")}
      text={title}
      buttonType="submit"
      preventDefault
      onClickAction={handleOnClick}
      isDisabled={!!(isDisabled || isUnderAction)}
      fontWeight={theme.typography.fontWeight["400"]}
      color={`${theme.colors.secondary["380"]}`}
      hoveredColor={`${theme.colors.secondary["350"]}`}
    />
  );
};

export { TwoFactorButton };
