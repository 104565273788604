import { IconProps } from "typing";
import { theme } from "../themes";

export const HamburgerIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
  strokeWidth = "2.05",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M2.99625 5.9975H21.0038"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M16 12H3.00001"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M3.00001 18.5H21"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
