import styled, { css, keyframes } from "styled-components";
import { CloseIcon as Close, FlexContainer, theme } from "ui";

type TypeProps = "success" | "error" | "info" | "default" | "success";
type ThemeProps = "dark" | "light";

interface ToastContainerProps {
  type?: TypeProps;
  hasClose: boolean;
  isNewToast?: boolean;
  newToastTheme?: ThemeProps;
}

interface TextProps {
  newToastTheme?: ThemeProps;
}

interface LineProgressBarProps {
  time: number;
  type?: TypeProps;
  isNewToast?: boolean;
}

const typeColors = {
  success: theme.colors.secondary["200"],
  error: theme.colors.primary["200"],
  info: theme.colors.tertiary["200"],
  default: theme.colors.neutral["325"],
};

const newToastThemeColors = {
  light: theme.colors.neutral.white,
  dark: theme.colors.neutral["680"],
};

const lineProgressBarColors = {
  success: theme.colors.secondary["250"],
  error: theme.colors.primary["350"],
  info: theme.colors.neutral.white,
  default: theme.colors.neutral.white,
};

const appearContainer = keyframes`
  from {
    opacity: 0;
    top: 0px;
  }

  to {
    opacity: 1;
    top: 14px;
  }
`;

export const ToastContainer = styled.div<ToastContainerProps>`
  display: ${({ hasClose }) => (hasClose ? "none" : "flex")};
  opacity: ${({ hasClose }) => (hasClose ? 0 : 1)};
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  z-index: 9999;
  top: 14px;
  left: 50%;
  padding: 0.5rem 1rem;
  transform: translateX(-50%);
  width: auto;
  height: fit-content;
  border-radius: 4px;
  background: ${({ type, isNewToast, newToastTheme }) =>
    !isNewToast && type
      ? typeColors[`${type}` as TypeProps]
      : newToastThemeColors[newToastTheme as ThemeProps]};
  box-shadow: 0 0.25rem 1rem 0 ${theme.colors.neutral["700"]};
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  animation: ${appearContainer} 0.4s normal;

  & + & {
    margin-top: 4.5rem;
  }

  &:last-child {
    margin-top: 4.5rem;
  }

  @media ${theme.device.desktopFirst.tabletS} {
    width: 90vw;
  }

  ${({ isNewToast }) =>
    isNewToast &&
    css`
      min-height: 5.165rem;
      left: 99.5% !important;
      width: 33.25rem;
      justify-content: flex-start;
      padding-left: 2.165rem;
      gap: ${theme.space.x8};
      border-radius: 8px 8px 4px 4px;
      box-shadow: -3px 7px 7px ${theme.colors.neutral["999-opacity-60"]};
      -webkit-backdrop-filter: none;
      transform: translateX(-100%);

      @media ${theme.device.desktopFirst.mobileXL} {
        left: 50% !important;
        transform: translateX(-50%);
      }
    `}
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space["x1.5"]};
`;

export const DescriptionToast = styled.span<TextProps>`
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${({ newToastTheme }) =>
    newToastTheme === "light"
      ? `${theme.colors.neutral["350"]}`
      : `${theme.colors.neutral["580"]}`};
  font-weight: ${theme.typography.fontWeight[400]};
`;

export const IconContainer = styled.div``;

export const TitleToast = styled.span`
  color: ${theme.colors.neutral.white};
  font-size: 1.45rem;
`;

export const TitleNewToast = styled.span<TextProps>`
  color: ${({ newToastTheme }) =>
    newToastTheme === "light"
      ? `${theme.colors.neutral["999"]}`
      : `${theme.colors.neutral.white}`};
  font-weight: ${theme.typography.fontWeight[400]};
  font-size: ${theme.typography.fontSize.sm.x5};
`;

export const WrapperIcon = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 0.5rem;
  width: fit-content;
`;

export const CloseIcon = styled(Close).attrs({
  width: "1.875rem",
  height: "1.875rem",
  color: theme.colors.neutral.white,
})``;

const progressBarAnimation = keyframes`
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
`;

export const LineProgressBar = styled.div<LineProgressBarProps>`
  position: fixed;
  bottom: 0;
  left: 2px;
  height: 4px;
  border-radius: 4px;
  background: ${({ type, isNewToast }) =>
    isNewToast
      ? lineProgressBarColors[type as TypeProps]
      : theme.colors.neutral.white};
  animation: ${progressBarAnimation} ${({ time }) => time}s forwards
    cubic-bezier(0, 0, 0, 0);
`;
