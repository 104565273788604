import styled, { keyframes } from "styled-components";
import { IconProps } from "typing";
import {
  ProfileIcon as Profile,
  House,
  Gift,
  Alliances,
  Card,
  UserIcon,
  ArrowRight,
  theme,
  ArrowLeft,
  SecurityLockIcon,
  CloseIcon,
} from "ui";
import { Link } from "../../../../../Link";

interface TitleWrapperProps {
  isAuthenticated?: boolean;
}
interface TextColorProps {
  menuColor?: boolean;
}

interface SidebarMenuProps {
  isActiveOverlay: boolean;
  onCloseSidebarMenu?: () => void;
}

interface ListContentSidebarMenuWrapperProps {
  isEnterpriseProduct?: boolean;
  isBorderActive?: boolean;
}

interface UsernameTitleProps {
  isTitleOnImage?: boolean;
}

interface CloseSideBarMenuIconProps extends IconProps {
  onClick: () => void;
}

interface CloseSidebarBoxProps {
  isOpenSidebarMenu: boolean;
}

export const SidebarContainerOverlay = styled.div<SidebarMenuProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ isActiveOverlay }) => (isActiveOverlay ? "-1" : "98")};
  opacity: ${({ isActiveOverlay }) => (isActiveOverlay ? "0" : "1")};
  display: flex;
  visibility: ${({ isActiveOverlay }) =>
    isActiveOverlay ? "hidden" : "visible"};
  background: ${theme.colors.neutral["999-opacity-50"]};
  transition: all 0.2s ease;
`;

export const SidebarMenuMobileContainer = styled.section<SidebarMenuProps>`
  width: 86vw !important;
  max-width: ${theme.space.x88};
  height: 100vh;
  z-index: 99;
  position: fixed;
  top: 0;
  left: ${({ isActiveOverlay }) => (isActiveOverlay ? "0" : "-100%")};
  transition: left 0.4s;
  background-color: ${theme.colors.neutral["white-050"]};
`;

export const SidebarMenuHeaderContainer = styled.section`
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding: 0.8rem;
`;

export const SidebarMenuHeader = styled.header`
  width: 100%;
  height: ${theme.space.x24};
  border-bottom: 3px solid ${theme.colors.primary["300"]};
  padding: ${theme.space["x2.5"]};
  position: relative;

  background-color: ${theme.colors.primary["200"]};
`;

export const SidebarMenuHeaderSection = styled.section`
  display: flex;
  /* justify-content: space-between; */
`;

export const SidebarMenuHeaderWrapper = styled.div`
  margin-top: 0.25rem;
  margin-left: 1rem;
`;

export const TitleWrapper = styled.section<TitleWrapperProps>`
  border-top: 1px solid ${theme.colors.neutral["150"]};
  background: ${theme.colors.neutral["80"]};
`;

export const UserSidebarMenuIcon = styled(UserIcon).attrs({
  color: theme.colors.neutral.white,
  width: theme.space.x6,
  height: theme.space.x6,
})``;

export const CloseSidebarMenuIcon = styled(CloseIcon).attrs({
  color: theme.colors.neutral.white,
  width: theme.space.x6,
  height: theme.space.x6,
})<CloseSideBarMenuIconProps>``;

export const CloseSidebarBox = styled.div<CloseSidebarBoxProps>`
  position: absolute;
  right: -2.5rem;
  width: 3rem;
  height: 3rem;
  background-color: ${theme.colors.primary["200"]};
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  top: 25px;
`;

const AllCategoriesButtonAnimation = keyframes`
  from{
    left: -1px;
    opacity:0;
  
  }

  to{
    left: 352px;
    opacity:1;
  }
`;

export const AllCategoriesButton = styled.button`
  font-family: ${theme.typography.fontFamily.chivo};
  color: ${theme.colors.neutral.white};
  background-color: ${theme.colors.primary["300"]};
  border: 0;
  width: 100%;
  height: 3.125rem;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.872rem;
  padding: 0 1.25rem;
  animation: ${AllCategoriesButtonAnimation} 0.3s ease;
  position: sticky;
  top: 0;
`;

export const ArrowLeftIcon = styled(ArrowLeft).attrs({
  width: "2.1rem",
  height: "2.1rem",
  color: theme.colors.neutral.white,
})``;

const BaseHyperlinkSidebarMenu = styled(Link)`
  align-self: center;
  text-decoration: none;
  text-align: left;

  color: ${theme.colors.neutral.white};
  font-family: "Chivo", Arial, Helvetica, sans-serif;
`;

export const TitleSidebarMenuHyperlink = styled(BaseHyperlinkSidebarMenu)`
  font-weight: bold;
  font-size: ${theme.space.x4};
`;

export const SidebarMenuHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 2.25rem;

  margin-top: 0.875rem;
  padding-left: 0.25rem;
`;

export const SubtitleSidebarMenuHyperlink = styled(BaseHyperlinkSidebarMenu)`
  font-size: ${theme.space.x3};
  width: 10rem;
`;

export const ListCategoriesSidebarMenu = styled.section`
  background: ${theme.colors.neutral.white};
  padding: 4px 0px 15rem;

  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  section {
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem 1rem;
  }

  section strong {
    color: ${theme.colors.neutral["520"]};
  }

  a {
    text-decoration: none;
  }
`;

export const LinkWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.neutral.white};
  border-bottom: 1px thin ${theme.colors.neutral["150"]};
  padding: 0.3rem 1rem;
`;

export const ListContentSidebarMenuWrapper = styled.div<ListContentSidebarMenuWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.125rem 0.5rem 0.125rem 0;
  padding-left: ${({ isEnterpriseProduct }) =>
    isEnterpriseProduct ? 0 : "1rem"};
  background: ${({ isEnterpriseProduct }) =>
    isEnterpriseProduct
      ? theme.colors.neutral["white-050"]
      : theme.colors.neutral.white};
  border-bottom: ${({ isBorderActive }) =>
    isBorderActive ? `1px solid ${theme.colors.neutral["150"]}` : "none"};
`;

export const ListContentSidebarMenuWrapperTwo = styled.div<ListContentSidebarMenuWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.125rem 0.5rem 0.125rem 0;
  margin-left: ${({ isEnterpriseProduct }) =>
    isEnterpriseProduct ? 0 : "1rem"};
  background: ${({ isEnterpriseProduct }) =>
    isEnterpriseProduct
      ? theme.colors.neutral["white-050"]
      : theme.colors.neutral.white};
  border-bottom: 1px solid ${theme.colors.neutral["150"]};
  & a {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
`;

const BaseSidebarMenu = styled.span`
  align-self: center;
  text-decoration: none;
  text-align: left;
  color: ${theme.colors.neutral.white};
  font-family: ${theme.typography.fontFamily.chivo};
`;

export const CategorySidebarMenu = styled(BaseSidebarMenu)<TextColorProps>`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 2.5rem;
  padding-left: 0;
  font-weight: ${({ menuColor }) => (menuColor ? "bold" : null)};
  color: ${({ menuColor }) =>
    menuColor ? theme.colors.primary["200"] : theme.colors.neutral["500"]};
  background: ${theme.colors.neutral.white};
  &:first-child {
    display: flex !important;
    flex-direction: row !important;
    flex: 1 !important;
  }
`;

export const UserAuthenticatedActionsSidebarMenu = styled(
  CategorySidebarMenu
)``;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;

  svg {
    stroke-width: 1.5;
  }
`;

type TitleActionProps = {
  hasBorderBottom?: boolean;
};

export const TitleAction = styled.span<TitleActionProps>`
  width: 100%;
  color: ${theme.colors.neutral["500"]};
  padding-bottom: 0.5rem;
  border-bottom: ${({ hasBorderBottom }) =>
    hasBorderBottom ? `1px solid ${theme.colors.neutral["150"]}` : "initial"};
  font-size: ${theme.typography.fontSize.sm.x5};
`;

export const EnterpriseProductSidebarMenu = styled(CategorySidebarMenu)`
  color: ${theme.colors.neutral["500"]};
  background: ${theme.colors.neutral["white-050"]};
  padding-left: 1rem;
`;

export const CategoryExploreIcon = styled(ArrowRight).attrs({
  color: theme.colors.neutral["500"],
  width: theme.space.x6,
  height: theme.space.x6,
})``;

export const UserAuthenticatedWrapper = styled.section`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const WeddingGiftListTitle = styled.span``;

export const UsernameTitle = styled.strong<UsernameTitleProps>`
  text-decoration: none;
  align-self: center;
  font-size: ${({ isTitleOnImage }) =>
    isTitleOnImage
      ? `${theme?.typography?.fontSize?.sm?.x3}`
      : `${theme?.typography?.fontSize?.sm?.x6}`};
  color: ${theme.colors.neutral.white};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserAuthenticatedImageCircle = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.secondary["350"]};
  border: 0;
`;

export const CardIcon = styled(Card).attrs({
  color: theme.colors.primary["200"],
  height: "18px",
  width: "18px",
})``;

export const HouseIcon = styled(House).attrs({
  color: theme.colors.primary["200"],
  height: "16px",
  width: "16px",
})``;

export const SecurityIcon = styled(SecurityLockIcon).attrs({
  color: theme.colors.primary["200"],
  height: "16px",
  width: "16px",
})``;

export const GiftBoxIcon = styled(Gift).attrs({
  color: theme.colors.primary["200"],
  height: "16px",
  width: "16px",
})``;

export const Close = styled(CloseIcon).attrs({
  color: theme.colors.primary["200"],
  height: "16px",
  width: "16px",
})``;

export const AlliancesIcon = styled(Alliances).attrs({
  color: theme.colors.primary["200"],
  height: "16px",
  width: "16px",
})``;

export const ProfileIcon = styled(Profile).attrs({
  color: theme.colors.primary["200"],
  height: "16px",
  width: "16px",
})``;

export const CategoriesDiv = styled.div``;

export const AuthenticatedOptionDefault = styled.div``;
export const AuthenticatedOptionWithLink = styled(Link)``;
