import { IconProps } from "typing";
import { theme } from "../themes";

export const PaymentDoneIcon = ({
  width = 21,
  height = 20,
  color = theme.colors.yellow["1020"],
  strokeWidth = "1.5",
  alt = "Pagamento efetuado",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49658 6.66538H17.5028"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91574 10.0001H5.83154"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1682 10.8337C16.0099 10.8337 17.5029 12.3267 17.5029 14.1685C17.5029 16.0102 16.0099 17.5032 14.1682 17.5032C12.3265 17.5032 10.8335 16.0102 10.8335 14.1685C10.8335 12.3267 12.3265 10.8337 14.1682 10.8337"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4655 13.5208L13.8448 15.1414L12.8711 14.1685"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33235 15.0021H4.99762C3.61634 15.0021 2.49658 13.8823 2.49658 12.5011V5.83161C2.49658 4.45032 3.61634 3.33057 4.99762 3.33057H15.0018C16.3831 3.33057 17.5028 4.45032 17.5028 5.83161V9.16633"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
