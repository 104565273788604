import { buildTestIds } from "application";
import { FocusEvent, useCallback, useEffect, useState } from "react";
import { Path, UseFormRegister } from "react-hook-form";
import { IFormValues } from "typing";
import {
  CheckboxLabel,
  CheckboxSpan,
  CheckboxWrapper,
  StyledCheckbox,
} from "./style";

export type CheckboxProps = {
  id: string;
  name: string;
  label?: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  required?: boolean;
  checked?: boolean;
  placeholder?: string;
  hasError?: boolean;
  color?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  borderColor?: string;
  borderRadius?: string;
  left?: string;
  sizeChecked?: string;
  heightChecked?: string;
  widthChecked?: string;
  textColor?: string;
  textSize?: string;
  textFontWeight?: string;
  textFontFamily?: string;
  display?: string;
  alignItems?: string;
  gap?: string;
  flexDirection?: string;
  justifyContent?: string;
  cursor?: string;
  checkboxWrapperWidth?: string;
  isCheckedEvent?: (isChecked?: boolean) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  onChange?: () => void;
  optionComponent?: React.ReactNode;
};

const Checkbox = ({
  id,
  register,
  required,
  name,
  label,
  checked,
  onBlur,
  isCheckedEvent,
  width,
  height,
  borderColor,
  borderRadius,
  left,
  sizeChecked,
  widthChecked,
  heightChecked,
  display,
  alignItems,
  gap,
  flexDirection,
  justifyContent,
  cursor,
  optionComponent,
  onChange,
  checkboxWrapperWidth,
  ...props
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(Boolean(checked));

  const handleCheckboxState = useCallback(() => {
    setIsChecked((prev) => !prev);
    if (isCheckedEvent) isCheckedEvent(!isChecked);
  }, [isChecked, isCheckedEvent]);

  useEffect(() => {
    setIsChecked(Boolean(checked));
  }, [checked]);

  return (
    <CheckboxWrapper
      checkboxWrapperWidth={checkboxWrapperWidth}
      cursor={cursor}
      {...buildTestIds("checkbox-wrapper")}
    >
      <CheckboxLabel
        display={display}
        alignItems={alignItems}
        gap={gap}
        flexDirection={flexDirection ?? "row"}
        justifyContent={justifyContent ?? "start"}
        cursor={cursor ?? "auto"}
        {...buildTestIds("checkbox-label")}
      >
        <StyledCheckbox
          {...buildTestIds("styled-checkbox")}
          width={width}
          borderColor={borderColor}
          borderRadius={borderRadius}
          height={height}
          sizeChecked={sizeChecked}
          widthChecked={widthChecked}
          left={left}
          heightChecked={heightChecked}
          id={id}
          checked={isChecked}
          {...props}
          {...register(name, { required })}
          onChange={onChange || handleCheckboxState}
          onBlur={onBlur}
        />
        {label && (
          <CheckboxSpan {...buildTestIds("checkbox-span")}>
            {label}
            {optionComponent}
          </CheckboxSpan>
        )}
      </CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default Checkbox;
