import { SwiperContainer } from "infra/components/lib/SwiperJS/styles";
import styled, { css } from "styled-components";
import { Title, H2, theme } from "ui";
import { Badge, TitleSection } from "./SubComponents/styles";

interface CustomStoriesContainerProps {
  format?: "circle" | "squircle" | "square" | "rectangle";
  customTheme?: boolean;
}

export const StoriesContent = styled.div`
  margin: 0 auto;
  max-width: 1320px;
`;

interface ComponentHeaderProps {
  textAlign?: "center" | "left" | "right";
}

export const ComponentHeader = styled.div<ComponentHeaderProps>`
  text-align: ${({ textAlign }) => textAlign ?? "left"};
`;

interface StoriesTitleProps {
  showTitle: "true" | "false";
  isBlackTheme?: boolean;
}

export const StoriesTitle = styled(Title)<StoriesTitleProps>`
  display: ${({ showTitle }) => (showTitle === "true" ? "block" : "none")};
`;

export const CustomStoriesTitle = styled(H2)<StoriesTitleProps>`
  display: ${({ showTitle }) => (showTitle === "true" ? "block" : "none")};
  font-size: 30px;
  font-weight: ${theme.typography.fontWeight.normal};
  color: ${({ isBlackTheme }) =>
    isBlackTheme ? theme.colors.neutral.white : theme.colors.neutral["520"]};
  margin-bottom: ${theme.space.x1};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: 22px;
  }
`;

interface StoriesSubTitleProps {
  showSubTitle: "true" | "false";
  size?: "big" | "small";
}

export const StoriesSubtitle = styled.h4<StoriesSubTitleProps>`
  display: ${({ showSubTitle }) =>
    showSubTitle === "true" ? "block" : "none"};
  font-size: ${theme.typography.fontSize.md.x3};
  font-weight: ${theme.typography.fontWeight.normal};
  color: ${theme.colors.neutral["330"]};
  margin-bottom: ${theme.space.x7};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x7};
    margin-bottom: ${({ size }) =>
      size === "small" ? `${theme.space.x3}` : `${theme.space.x7}`};
  }
`;

interface ComponentBodyProps {
  format?: "circle" | "squircle" | "square" | "rectangle";
}

export const ComponentBody = styled.section<ComponentBodyProps>`
  height: auto;

  ${SwiperContainer} {
    ${({ format }) =>
      format && format === "rectangle"
        ? css`
            .swiper-button-next,
            .swiper-button-prev {
              top: 80%;
              transform: translateY(-50%);
            }

            .swiper-initialized {
              max-width: 1280px;
            }
          `
        : null}
  }
`;

export const CustomStoriesContainer = styled.section<CustomStoriesContainerProps>`
  padding: ${({ format }) => `${theme.space.x1}
    ${format && format === "rectangle" ? theme.space.x4 : theme.space.x20}`};

  max-width: 1440px;
  margin: 0 auto;
  width: 100%;

  div.slick-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${theme.device.desktopFirst.laptop} {
    padding: ${({ format }) => `${theme.space.x1}
        ${format && format === "rectangle" ? theme.space.x2 : theme.space.x8}`};
  }

  ${({ customTheme }) => {
    return css`
      ${Badge} {
        ${customTheme &&
        css`
          border-color: ${theme?.colors?.blackFriday?.["185"]};
        `}
      }
      ${CustomStoriesTitle} {
        ${customTheme &&
        css`
          color: ${theme?.colors?.blackFriday?.["15"]};
        `}
      }
      ${StoriesSubtitle} {
        ${customTheme &&
        css`
          color: ${theme?.colors?.blackFriday?.["35"]};
        `}
      }
      ${TitleSection} {
        * {
          ${customTheme &&
          css`
            color: ${theme?.colors?.blackFriday?.["15"]} !important;
          `}
        }
      }
    `;
  }}
`;
