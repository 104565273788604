import { IconProps } from "typing";
import { theme } from "../themes";

export const GeometricBoxIcon = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={`${theme.colors.neutral.white}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8L12 3L20 8V16L12 21L4 16V8L12 12.375V21V12.375L20 8"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
