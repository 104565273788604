import { IconProps } from "typing";

export const SuccessSyncIcon = ({
  width = 120,
  height = 121,
  alt = "Sucesso ao sincronizar",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-labelledby={alt}
      aria-label={alt}
      viewBox="0 0 120 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="120" height="120" rx="12.1582" fill="#F6F7F7" />
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.6575 69.605C96.9142 69.605 95.5 70.9248 95.5 72.5527C95.5 74.1806 96.9142 75.4997 98.6575 75.4997H107.342C109.086 75.4997 110.5 74.1798 110.5 72.5527C110.5 70.9256 109.086 69.6057 107.342 69.6057C107.342 67.3673 105.398 65.5527 103 65.5527C100.602 65.5527 98.6575 67.3665 98.6575 69.605Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8318 12.7932C9.6511 12.7932 8.69336 13.6871 8.69336 14.7896C8.69336 15.8921 9.6511 16.7854 10.8318 16.7854H16.7131C17.8943 16.7854 18.8521 15.8915 18.8521 14.7896C18.8521 13.6876 17.8943 12.7937 16.7137 12.7937C16.7137 11.2777 15.397 10.0488 13.7727 10.0488C12.1485 10.0488 10.8318 11.2772 10.8318 12.7932Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.324 28.6947C103.992 28.6947 102.911 29.7035 102.911 30.9477C102.911 32.1918 103.992 33.2 105.324 33.2H111.962C113.295 33.2 114.375 32.1912 114.375 30.9477C114.375 29.7041 113.295 28.6953 111.962 28.6953C111.962 26.9845 110.476 25.5977 108.643 25.5977C106.81 25.5977 105.324 26.9839 105.324 28.6947Z"
          stroke="#8F9194"
          strokeWidth="0.509524"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.8318 107.793C98.6511 107.793 97.6934 108.687 97.6934 109.79C97.6934 110.892 98.6511 111.785 99.8318 111.785H105.713C106.894 111.785 107.852 110.892 107.852 109.79C107.852 108.688 106.894 107.794 105.714 107.794C105.714 106.278 104.397 105.049 102.773 105.049C101.148 105.049 99.8318 106.277 99.8318 107.793Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.2461 11.0157C77.6296 11.0157 76.3184 12.2396 76.3184 13.749C76.3184 15.2585 77.6296 16.4816 79.2461 16.4816H87.2985C88.9158 16.4816 90.2271 15.2578 90.2271 13.749C90.2271 12.2403 88.9158 11.0164 87.2993 11.0164C87.2993 8.94084 85.4966 7.2583 83.2727 7.2583C81.0489 7.2583 79.2461 8.94012 79.2461 11.0157Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.2246 51.9855C36.6146 51.9855 35.3086 53.2044 35.3086 54.7078C35.3086 56.2112 36.6146 57.4294 38.2246 57.4294H46.2446C47.8554 57.4294 49.1614 56.2105 49.1614 54.7078C49.1614 53.2051 47.8554 51.9862 46.2454 51.9862C46.2454 49.9189 44.4499 48.2432 42.235 48.2432C40.0201 48.2432 38.2246 49.9182 38.2246 51.9855Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.2246 45.9855C80.6146 45.9855 79.3086 47.2044 79.3086 48.7078C79.3086 50.2112 80.6146 51.4294 82.2246 51.4294H90.2446C91.8554 51.4294 93.1614 50.2105 93.1614 48.7078C93.1614 47.2051 91.8554 45.9862 90.2454 45.9862C90.2454 43.9189 88.4499 42.2432 86.235 42.2432C84.0201 42.2432 82.2246 43.9182 82.2246 45.9855Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.8318 78.9885C36.6511 78.9885 35.6934 79.8824 35.6934 80.9849C35.6934 82.0874 36.6511 82.9807 37.8318 82.9807H43.7131C44.8943 82.9807 45.8521 82.0868 45.8521 80.9849C45.8521 79.8829 44.8943 78.989 43.7137 78.989C43.7137 77.473 42.397 76.2441 40.7727 76.2441C39.1485 76.2441 37.8318 77.4725 37.8318 78.9885Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8318 101.553C10.6511 101.553 9.69336 102.447 9.69336 103.549C9.69336 104.652 10.6511 105.545 11.8318 105.545H17.7131C18.8943 105.545 19.8521 104.651 19.8521 103.549C19.8521 102.447 18.8943 101.553 17.7137 101.553C17.7137 100.037 16.397 98.8086 14.7727 98.8086C13.1485 98.8086 11.8318 100.037 11.8318 101.553Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.2246 105.985C47.6146 105.985 46.3086 107.204 46.3086 108.708C46.3086 110.211 47.6146 111.429 49.2246 111.429H57.2446C58.8554 111.429 60.1614 110.21 60.1614 108.708C60.1614 107.205 58.8554 105.986 57.2454 105.986C57.2454 103.919 55.4499 102.243 53.235 102.243C51.0201 102.243 49.2246 103.918 49.2246 105.985Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.22461 58.3224C6.61461 58.3224 5.30859 59.5413 5.30859 61.0447C5.30859 62.5481 6.61461 63.7663 8.22461 63.7663H16.2446C17.8554 63.7663 19.1614 62.5474 19.1614 61.0447C19.1614 59.5421 17.8554 58.3231 16.2454 58.3231C16.2454 56.2559 14.4499 54.5801 12.235 54.5801C10.0201 54.5801 8.22461 56.2551 8.22461 58.3224Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.9818 96.263V94.0448C95.9818 89.1444 92.0093 85.1719 87.1089 85.1719H69.3631C64.4628 85.1719 60.4902 89.1444 60.4902 94.0448V96.263"
        fill="white"
      />
      <path
        d="M95.9818 96.263V94.0448C95.9818 89.1444 92.0093 85.1719 87.1089 85.1719H69.3631C64.4628 85.1719 60.4902 89.1444 60.4902 94.0448V96.263"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="78.2362"
        cy="67.4256"
        r="8.8729"
        fill="white"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.6689 20.9189H89.3392C93.6808 20.9189 97.2055 24.4436 97.2055 28.7852V43.8661"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.7212 96.1854H27.0509C22.7093 96.1854 19.1846 92.6607 19.1846 88.3191V73.2383"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7985 21.9987C18.0921 22.7605 13.6777 27.506 13.6777 33.3116C13.6777 39.6496 18.9207 44.7852 25.3808 44.7852H51.1275C56.3002 44.7852 60.4899 40.6777 60.4899 35.6063C60.4899 30.535 56.3002 26.4275 51.1275 26.4275C51.1275 18.8228 44.8406 12.6592 37.0838 12.6592C30.914 12.6592 25.6804 16.5694 23.7985 21.9987Z"
        fill="#F6F7F7"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="35.714" cy="30.5465" r="9.9777" fill="#7AC143" />
      <path
        d="M31.7715 30.9219L34.1739 33.3243L34.1584 33.3088L39.5785 27.8887"
        stroke="white"
        strokeWidth="1.13983"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
