import { IconProps } from "typing";

export const ExclusionAccountFailedIcon = ({
  alt = "Exclusão de conta falhou",
  height = "151",
  width = "164",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 164 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.7138 11.7437C75.7049 3.01241 88.2951 3.01242 93.2862 11.7437L157.483 124.048C162.437 132.715 156.18 143.5 146.197 143.5H17.8028C7.82021 143.5 1.56254 132.715 6.51663 124.048L70.7138 11.7437Z"
        fill="#F6F7F7"
      />
      <g opacity="0.2">
        <path
          d="M79.447 23.6647C81.531 23.6647 83.2211 22.0873 83.2211 20.1421C83.2211 18.197 81.5318 16.6196 79.447 16.6196C77.3621 16.6196 75.6729 18.197 75.6729 20.1421"
          stroke="#040405"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.4509 23.6682C78.883 23.6682 78.345 23.5511 77.8617 23.3421L75.5391 23.8025L76.0247 21.6304C75.7992 21.1779 75.673 20.6736 75.673 20.1421"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.0459 16.6397C78.7703 16.05 78.6172 15.4072 78.6172 14.7327C78.6172 11.8473 81.3862 9.53223 84.774 9.53223C88.1618 9.53223 90.9308 11.8473 90.9308 14.7327C90.9308 16.4379 89.9596 17.9391 88.4688 18.8858C88.4696 19.4289 88.4681 20.1608 88.4681 20.9294L86.0523 19.8189C85.639 19.8929 85.2119 19.9331 84.774 19.9331C84.2299 19.9331 83.7019 19.8728 83.1986 19.76"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M52.7703 124.152H50.8455C50.4371 124.152 50.0454 124.304 49.7565 124.573C49.4677 124.843 49.3056 125.208 49.3057 125.59V127.386"
          stroke="#040405"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.3057 133.854V135.65C49.3056 136.031 49.4677 136.397 49.7565 136.666C50.0454 136.936 50.4371 137.087 50.8455 137.087H52.7703"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.6992 137.087H61.624C62.0324 137.087 62.4242 136.936 62.713 136.666C63.0018 136.397 63.164 136.031 63.1639 135.65V133.854"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.1639 127.386V125.59C63.164 125.208 63.0018 124.843 62.713 124.573C62.4242 124.304 62.0324 124.152 61.624 124.152H59.6992"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.3857 127.746H60.085"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.3857 130.62H60.085"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.3857 133.494H56.2353"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M88.7703 32.1523H86.8455C86.4371 32.1522 86.0454 32.3036 85.7565 32.5732C85.4677 32.8427 85.3056 33.2083 85.3057 33.5895V35.386"
          stroke="#040405"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.3057 41.8535V43.65C85.3056 44.0312 85.4677 44.3968 85.7565 44.6664C86.0454 44.9359 86.4371 45.0873 86.8455 45.0872H88.7703"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M95.6992 45.0872H97.624C98.0324 45.0873 98.4242 44.9359 98.713 44.6664C99.0018 44.3968 99.164 44.0312 99.1639 43.65V41.8535"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.1639 35.386V33.5895C99.164 33.2083 99.0018 32.8427 98.713 32.5732C98.4242 32.3036 98.0324 32.1522 97.624 32.1523H95.6992"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.3857 35.7455H96.085"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.3857 38.6196H96.085"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.3857 41.4941H92.2353"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M137.447 135.665C139.531 135.665 141.221 134.087 141.221 132.142C141.221 130.197 139.532 128.62 137.447 128.62C135.362 128.62 133.673 130.197 133.673 132.142"
          stroke="#040405"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.451 135.668C136.883 135.668 136.345 135.551 135.862 135.342L133.539 135.803L134.025 133.63C133.799 133.178 133.673 132.674 133.673 132.142"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.046 128.64C136.77 128.05 136.617 127.407 136.617 126.733C136.617 123.847 139.386 121.532 142.774 121.532C146.162 121.532 148.931 123.847 148.931 126.733C148.931 128.438 147.96 129.939 146.469 130.886C146.47 131.429 146.468 132.161 146.468 132.929L144.052 131.819C143.639 131.893 143.212 131.933 142.774 131.933C142.23 131.933 141.702 131.873 141.199 131.76"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M58 70C58 65.0294 62.0294 61 67 61H109C113.971 61 118 65.0294 118 70V88.9991C118 90.6354 117.554 92.2407 116.71 93.6424L107.477 108.972C106.726 110.22 105.683 111.266 104.438 112.022L90.1523 120.694C88.7444 121.548 87.1291 122 85.4823 122H67C62.0294 122 58 117.971 58 113V70Z"
        fill="#F6F7F7"
      />
      <path
        d="M78.9109 121.638H63.9488C59.8172 121.638 56.4678 118.54 56.4678 114.718V66.2814C56.4678 62.4598 59.8172 59.3618 63.9488 59.3618H108.835C112.967 59.3618 116.316 62.4598 116.316 66.2814V76.6607"
        stroke="#47494D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.1328 121.638L99.948 120.517C100.639 120.438 101.281 120.148 101.773 119.693L125.556 97.6959C128.19 95.2698 128.2 91.3283 125.578 88.8908L125.556 88.8735C122.933 86.437 118.671 86.4277 116.036 88.8527V88.8735L92.4706 110.67C91.9913 111.11 91.6812 111.684 91.5879 112.303L90.1328 121.638Z"
        fill="#D4D5D6"
        stroke="#47494D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5596 73.2011H99.4838"
        stroke="#B2B3B5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5596 85.31H84.5217"
        stroke="#B2B3B5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5596 99.6996H84.5217"
        stroke="#B2B3B5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.2">
        <path
          d="M61.447 52.6647C63.531 52.6647 65.2211 51.0873 65.2211 49.1421C65.2211 47.197 63.5318 45.6196 61.447 45.6196C59.3621 45.6196 57.6729 47.197 57.6729 49.1421"
          stroke="#040405"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.4509 52.6682C60.883 52.6682 60.345 52.5511 59.8617 52.3421L57.5391 52.8025L58.0247 50.6304C57.7992 50.1779 57.673 49.6736 57.673 49.1421"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.0459 45.6397C60.7703 45.05 60.6172 44.4072 60.6172 43.7327C60.6172 40.8473 63.3862 38.5322 66.774 38.5322C70.1618 38.5322 72.9308 40.8473 72.9308 43.7327C72.9308 45.4379 71.9596 46.9391 70.4688 47.8858C70.4696 48.4289 70.4681 49.1608 70.4681 49.9294L68.0523 48.8189C67.639 48.8929 67.2119 48.9331 66.774 48.9331C66.2299 48.9331 65.7019 48.8728 65.1986 48.76"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M127.77 103.152H125.846C125.437 103.152 125.045 103.304 124.757 103.573C124.468 103.843 124.306 104.208 124.306 104.59V106.386"
          stroke="#040405"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M124.306 112.854V114.65C124.306 115.031 124.468 115.397 124.757 115.666C125.045 115.936 125.437 116.087 125.846 116.087H127.77"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.699 116.087H136.624C137.032 116.087 137.424 115.936 137.713 115.666C138.002 115.397 138.164 115.031 138.164 114.65V112.854"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M138.164 106.386V104.59C138.164 104.208 138.002 103.843 137.713 103.573C137.424 103.304 137.032 103.152 136.624 103.152H134.699"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M127.386 106.746H135.085"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M127.386 109.62H135.085"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M127.386 112.494H131.235"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.1224 108.743C66.9487 100.675 63.9669 91.0246 56.2544 86.5148C48.5419 82.0049 38.658 84.1325 33.4893 91.4151C28.3206 98.6977 29.5823 108.719 36.3952 114.496C43.2081 120.274 53.3116 119.89 59.666 113.613L65.2247 113.891L63.1224 108.743Z"
        fill="#F28290"
        stroke="#47494D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9648 102.016L47.9648 90.5"
        stroke="#47494D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9605 110.098C47.6165 110.098 47.3361 110.358 47.3398 110.676C47.3398 110.99 47.6202 111.25 47.9642 111.25C48.3082 111.25 48.5886 110.99 48.5886 110.672C48.5886 110.354 48.3082 110.098 47.9605 110.098Z"
        fill="#68A439"
      />
      <path
        d="M47.9605 110.098C47.6165 110.098 47.3361 110.358 47.3398 110.676C47.3398 110.99 47.6202 111.25 47.9642 111.25C48.3082 111.25 48.5886 110.99 48.5886 110.672C48.5886 110.354 48.3082 110.098 47.9605 110.098"
        stroke="#47494D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.2">
        <path
          d="M18.447 136.665C20.531 136.665 22.2211 135.087 22.2211 133.142C22.2211 131.197 20.5318 129.62 18.447 129.62C16.3621 129.62 14.6729 131.197 14.6729 133.142"
          stroke="#040405"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.4509 136.668C17.883 136.668 17.345 136.551 16.8617 136.342L14.5391 136.803L15.0247 134.63C14.7992 134.178 14.673 133.674 14.673 133.142"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0459 129.64C17.7703 129.05 17.6172 128.407 17.6172 127.733C17.6172 124.847 20.3862 122.532 23.774 122.532C27.1618 122.532 29.9308 124.847 29.9308 127.733C29.9308 129.438 28.9596 130.939 27.4688 131.886C27.4696 132.429 27.4681 133.161 27.4681 133.929L25.0523 132.819C24.639 132.893 24.2119 132.933 23.774 132.933C23.2299 132.933 22.7019 132.873 22.1986 132.76"
          stroke="black"
          strokeWidth="0.84656"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <ellipse cx="86.5" cy="132" rx="29.5" ry="2" fill="#B2B3B5" />
    </svg>
  );
};
