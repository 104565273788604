import styled from "styled-components";
import { theme } from "ui";

type DownloadImageProps = {
  colorLink: string;
};

export const DownloadLink = styled.button<DownloadImageProps>`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${({ colorLink }) => colorLink || theme.colors.neutral[999]};
`;
