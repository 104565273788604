import { buildTestIds, useAuth, useToast, useUser } from "application";
import {
  SendCodeProps,
  useShallow,
  useTwoFactor,
} from "application/state-manager";
import { notificationApi } from "implementations";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { SendCodeType } from "typing";
import { InputBoxValidation } from "../../../../InputCodeValidation";
import { PasteCode } from "../../../PasteCode";
import { TwoFactorContentBodyButton } from "./Button";
import Counter from "./Counter";
import {
  CodeInfosContainer,
  ResendCode,
  SelectAnotherTwoFactorType,
  TwoFactorContentBodyContainer,
  VerificationCodeInfo,
} from "./styles";

export interface TwoFactorContentBodyProps {
  sendCodeType: SendCodeType;
  isClientMobileS?: boolean;
  buttonHeight?: string;
  buttonBorderRadius?: string;
  showButton?: boolean;
  preventDefault?: boolean;
  handleSelectAnotherTwoFactorType?: () => void;
  handleFinishTwoFactorAction: () => void;
}

const TwoFactorContentBody = ({
  isClientMobileS,
  sendCodeType,
  buttonBorderRadius,
  buttonHeight,
  preventDefault,
  handleFinishTwoFactorAction,
  handleSelectAnotherTwoFactorType,
  showButton = true,
}: TwoFactorContentBodyProps) => {
  const {
    email,
    phoneNumber,
    canChooseAnotherTwoFactorMethod,
    sendCode,
    isCodeTimerActive,
    setCodeTimerActive,
    setIsSendCodeAvailable,
    isEmailDifferent,
    setIsEmailDifferent,
    setInitTimer,
    initTimer,
    faType,
    setHasAuthenticationMethodChanged,
    hasAuthenticationMethodChanged,
  } = useTwoFactor(
    useShallow((state) => ({
      email: state.email,
      phoneNumber: state.phoneNumber,
      canChooseAnotherTwoFactorMethod: state.canChooseAnotherTwoFactorMethod,
      isCodeTimerActive: state.isCodeTimerActive,
      setCodeTimerActive: state.setCodeTimerActive,
      setIsSendCodeAvailable: state.setIsSendCodeAvailable,
      sendCode: state.sendCode,
      isEmailDifferent: state.isEmailDifferent,
      setIsEmailDifferent: state.setIsEmailDifferent,
      setInitTimer: state.setInitTimer,
      initTimer: state.initTimer,
      faType: state.faType,
      setHasAuthenticationMethodChanged:
        state.setHasAuthenticationMethodChanged,
      hasAuthenticationMethodChanged: state.hasAuthenticationMethodChanged,
    }))
  );

  const maxTimeValue = 120;
  const [countdownTime, setCountdownTime] = useState<number>(() => {
    const storedCountdownTime = localStorage.getItem("countdownTime");
    const storedTimestamp = localStorage.getItem("countdownTimeTimestamp");

    if (storedCountdownTime && storedTimestamp) {
      const elapsedTime = Math.floor(
        (Date.now() - parseInt(storedTimestamp, 10)) / 1000
      );
      const remainingTime = parseInt(storedCountdownTime, 10) - elapsedTime;

      if (remainingTime > 0) {
        return remainingTime;
      }
    }

    return 0;
  });

  const { addToast } = useToast();

  useEffect(() => {
    localStorage.setItem("countdownTime", countdownTime.toString());
    localStorage.setItem("countdownTimeTimestamp", Date.now().toString());
  }, [countdownTime]);

  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;
  const { loggedClient } = useUser();

  useEffect(() => {
    if (isCodeTimerActive && !initTimer && countdownTime === 0) {
      setInitTimer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      countdownTime > 0 &&
      !isEmailDifferent &&
      !hasAuthenticationMethodChanged
    ) {
      setCodeTimerActive(true);
      const intervalId = setInterval(() => {
        setCountdownTime((current) => current - 1);
      }, 1000);
      setIsSendCodeAvailable(false);
      return () => clearInterval(intervalId);
    }

    if (
      (initTimer && countdownTime === 0) ||
      isEmailDifferent ||
      hasAuthenticationMethodChanged
    ) {
      setCountdownTime(maxTimeValue);
      setInitTimer(false);
      setIsEmailDifferent(false);
      setHasAuthenticationMethodChanged(false);
    }

    if (countdownTime === 0) {
      setIsSendCodeAvailable(true);
      setCodeTimerActive(false);
    }

    return undefined;
  }, [
    countdownTime,
    hasAuthenticationMethodChanged,
    initTimer,
    isEmailDifferent,
    setCodeTimerActive,
    setHasAuthenticationMethodChanged,
    setInitTimer,
    setIsEmailDifferent,
    setIsSendCodeAvailable,
  ]);

  const handleResendCode = useCallback(
    ({
      sendCodeType: passedSendCodeType,
      notificationApi: passedNotificationApi,
      addToast: passedAddToast,
      infoValue,
      errorTitle,
    }: SendCodeProps) => {
      if (countdownTime === 0) {
        setInitTimer(true);
        sendCode({
          faType: faType ?? 5,
          sendCodeType: passedSendCodeType,
          notificationApi: passedNotificationApi,
          addToast: passedAddToast,
          infoValue,
          errorTitle,
          email,
          cpf: loggedClient?.cpf,
          phoneNumber: phoneNumber.replace(/\D/g, ""),
          token: accessToken,
        });
        setCodeTimerActive(false);
      }
    },
    [
      countdownTime,
      faType,
      sendCode,
      setCodeTimerActive,
      setInitTimer,
      email,
      loggedClient,
      phoneNumber,
      accessToken,
    ]
  );

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const getInfoValue = useCallback(
    (
      passedSendCodeType: Exclude<SendCodeType, "app">,
      passedEmail: string,
      passedPhoneNumber: string
    ) => {
      if (passedSendCodeType === "email") {
        return passedEmail?.replace(/@/g, "%40");
      }

      return passedPhoneNumber;
    },
    []
  );

  const { pathname } = useRouter();
  const isAccountCreation = pathname?.includes("/cadastro");

  return (
    <TwoFactorContentBodyContainer
      {...buildTestIds("two-factor-content-body-container")}
    >
      <InputBoxValidation
        numberType={2}
        width={isClientMobileS ? "39px" : "47px"}
        height="64px"
        flexDirection="column"
        typeItem="SendEmailCode"
        bottomSubtitle
        noTopSubtitle
      />
      {sendCodeType !== "app" && (
        <CodeInfosContainer {...buildTestIds("code-infos-container")}>
          {isAccountCreation ? (
            <ResendCode
              {...buildTestIds("resend-code")}
              activeTimer={countdownTime > 0}
              onClick={() =>
                handleResendCode({
                  sendCodeType,
                  notificationApi,
                  infoValue: getInfoValue(
                    sendCodeType,
                    email,
                    phoneNumber.replace(/\D/g, "")
                  ),
                  addToast,
                  cpf: loggedClient?.cpf,
                  email,
                  phoneNumber,
                  faType: faType ?? 5,
                  errorTitle: "Erro ao realizar a ação",
                })
              }
            >
              {countdownTime === 0
                ? "Reenviar código"
                : `Reenviar código em ${formatTime(countdownTime)}`}
            </ResendCode>
          ) : (
            <Counter
              channel={sendCodeType === "email" ? 2 : 1}
              faType={faType}
              sendCodeType={sendCodeType}
            />
          )}

          <PasteCode />
        </CodeInfosContainer>
      )}
      <VerificationCodeInfo {...buildTestIds("verification-code-info")}>
        O código de verificação expira em 10 minutos e pode ser reenviado até 5
        vezes por dia.
      </VerificationCodeInfo>
      {canChooseAnotherTwoFactorMethod && handleSelectAnotherTwoFactorType && (
        <SelectAnotherTwoFactorType
          {...buildTestIds("select-another-two-factor-type")}
          onClick={handleSelectAnotherTwoFactorType}
        >
          Use outro método para receber o código
        </SelectAnotherTwoFactorType>
      )}
      {showButton && (
        <TwoFactorContentBodyButton
          height={buttonHeight}
          borderRadius={buttonBorderRadius}
          handleFinishTwoFactorAction={handleFinishTwoFactorAction}
          preventDefault={preventDefault || false}
        />
      )}
    </TwoFactorContentBodyContainer>
  );
};

export { TwoFactorContentBody };
