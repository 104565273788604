import styled, { css } from "styled-components";
import { theme } from "ui";
import { Image } from "../../../Image";

interface ContainerProps {
  format?: "circle" | "squircle" | "square" | "rectangle";
  hasShadow?: "true" | "false";
  size?: "small" | "big";
}

export const Container = styled.div<ContainerProps>`
  width: ${({ format }) => {
    switch (format) {
      case "circle":
        return 152;
      case "squircle":
        return 142;
      case "square":
        return 158;
      case "rectangle":
        return 180;
      default:
        return 152;
    }
  }}px;

  ${({ format }) =>
    (format && format === "square") || format === "rectangle"
      ? css`
          margin: 2px 0 10px 0;
        `
      : null};

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  box-shadow: ${({ hasShadow, format }) =>
    hasShadow &&
    hasShadow === "true" &&
    (format === "rectangle" || format === "square")
      ? `${theme.space.x0} ${theme.space.x1} ${theme.space.x2} rgba(0, 0, 0, 0.1)`
      : "none"};

  @media ${theme.device.desktopFirst.tablet} {
    width: ${({ format }) => {
      switch (format) {
        case "circle":
          return 112;
        case "squircle":
          return 110;
        case "square":
          return 130;
        case "rectangle":
          return 180;
        default:
          return 112;
      }
    }}px;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    width: ${({ size, format }) => {
      if (size === "small") {
        switch (format) {
          case "circle":
            return "70px";
          case "squircle":
            return "70px";
          default:
            return "100%";
        }
      }
      switch (format) {
        case "circle":
          return "112px";
        case "squircle":
          return "110px";
        case "square":
          return "130px";
        case "rectangle":
          return "126px";
        default:
          return "112px";
      }
    }};
  }
`;

interface ImageSectionProps {
  size?: "big" | "small";
  format?: "circle" | "squircle" | "square" | "rectangle";
  color: string;
  hasShadow?: "true" | "false";
}

export const ImageSection = styled.section<ImageSectionProps>`
  position: relative;
  width: inherit;

  height: ${({ format }) => {
    switch (format) {
      case "circle":
        return 152;
      case "squircle":
        return 142;
      case "square":
        return 158;
      case "rectangle":
        return 120;
      default:
        return 152;
    }
  }}px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ color, format }) =>
    (format && format === "square") || (format && format === "rectangle")
      ? "white"
      : color === "green"
      ? `linear-gradient(45deg, ${theme.colors.secondary["430"]}, ${theme.colors.secondary["390"]}, ${theme.colors.secondary["40"]})`
      : color === "red"
      ? `${theme?.colors?.primary["150"]}`
      : "none"};

  box-shadow: ${({ hasShadow, format, color }) =>
    hasShadow === "true" &&
    color !== "" &&
    format !== "square" &&
    format !== "rectangle"
      ? "0 2px 4px rgba(0, 0, 0, 0.1)"
      : "none"};

  border-radius: ${({ format }) =>
    format && format === "circle" ? "50%" : format === "squircle" ? "30%" : 0};

  @media ${theme.device.desktopFirst.tablet} {
    width: inherit;
    height: ${({ format }) => {
      switch (format) {
        case "circle":
          return 112;
        case "squircle":
          return 110;
        case "square":
          return 130;
        case "rectangle":
          return 120;
        default:
          return 112;
      }
    }}px;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    height: ${({ size, format }) => {
      if (size === "small") {
        switch (format) {
          case "circle":
            return "70px";
          case "squircle":
            return "70px";
          default:
            return "100%";
        }
      }

      switch (format) {
        case "circle":
          return "112px";
        case "squircle":
          return "110px";
        case "square":
          return "130px";
        case "rectangle":
          return "90px";
        default:
          return "112px";
      }
    }};
  }
`;

interface StoriesImageContainerProps {
  format?: "circle" | "squircle" | "square" | "rectangle";
  size?: "small" | "big";
  color: string;
  hasShadow?: "true" | "false";
  themeBlackFriday?: boolean;
}

export const StoriesImageContainer = styled.figure<StoriesImageContainerProps>`
  display: flex;
  position: relative;

  width: ${({ format }) => {
    switch (format) {
      case "circle":
        return "146px";
      case "squircle":
        return "138px";
      case "square":
        return "100%";
      case "rectangle":
        return "100%";
      default:
        return "146px";
    }
  }};

  height: ${({ format }) => {
    switch (format) {
      case "circle":
        return "146px";
      case "squircle":
        return "138px";
      case "square":
        return "100%";
      case "rectangle":
        return "100%";
      default:
        return "146px";
    }
  }};

  background-color: ${({ themeBlackFriday }) =>
    themeBlackFriday ? "transparent" : theme.colors.neutral["white-075"]};

  border-radius: ${({ format }) =>
    format === "circle" ? "50%" : format === "squircle" ? "30%" : 0};

  border-color: ${({ color, format }) =>
    (format && format === "square") || format === "rectangle"
      ? "white"
      : color === "green"
      ? `linear-gradient(45deg, ${theme.colors.secondary["430"]}, ${theme.colors.secondary["390"]}, ${theme.colors.secondary["40"]})`
      : color === "red"
      ? `linear-gradient(330deg, ${theme.colors.primary["150"]}, ${theme.colors.yellow["40"]}, ${theme.colors.yellow["1000"]})`
      : "none"};

  @media ${theme.device.desktopFirst.tablet} {
    width: ${({ format }) => {
      switch (format) {
        case "circle":
          return "108px";
        case "squircle":
          return "106px";
        case "square":
          return "100%";
        case "rectangle":
          return "100%";
        default:
          return "108px";
      }
    }};

    height: ${({ format }) => {
      switch (format) {
        case "circle":
          return "108px";
        case "squircle":
          return "106px";
        case "square":
          return "100%";
        case "rectangle":
          return "100%";
        default:
          return "108px";
      }
    }};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    width: ${({ size, format }) => {
      if (size === "small") {
        switch (format) {
          case "circle":
            return "64px";
          case "squircle":
            return "64px";
          default:
            return "100%";
        }
      }
      switch (format) {
        case "circle":
          return "108px";
        case "squircle":
          return "106px";
        default:
          return "100%";
      }
    }};

    height: ${({ size, format }) => {
      if (size === "small") {
        switch (format) {
          case "circle":
            return "64px";
          case "squircle":
            return "64px";
          default:
            return "100%";
        }
      }
      switch (format) {
        case "circle":
          return "108px";
        case "squircle":
          return "106px";
        default:
          return "100%";
      }
    }};
  }

  box-shadow: ${({ hasShadow, color, format }) =>
    hasShadow && hasShadow === "true" && !color && format && format !== "square"
      ? `${theme.space.x0} ${theme.space.x1} ${theme.space.x2} ${theme.colors.neutral["999-opacity-10"]}`
      : "none"} !important;
`;

interface StoriesImageProps {
  format?: "circle" | "squircle" | "square" | "rectangle";
  hasShadow?: "true" | "false";
  color: string;
}

export const StoriesImage = styled(Image)<StoriesImageProps>`
  width: 100%;
  height: 100%;
  padding: ${({ color, format }) =>
    color !== "" && format !== "rectangle" && format !== "square"
      ? "3px"
      : null} !important;
  border-radius: ${({ format }) =>
    format && format === "circle" ? "50%" : format === "squircle" ? "30%" : 0};
`;

interface BadgeProps {
  color: string;
  size?: "small" | "big";
}

export const Badge = styled.div<BadgeProps>`
  position: absolute;
  bottom: ${theme.space.x0};

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px ${theme.space.x2};

  background: ${({ color }) =>
    color && color === "green"
      ? `linear-gradient(45deg, ${theme.colors.secondary["430"]}, ${theme.colors.secondary["390"]}, ${theme.colors.secondary["40"]})`
      : color === "red"
      ? `${theme.colors.primary["150"]}`
      : "none"};

  border: ${({ size }) =>
      size && size === "small" ? `${theme.space["x0.5"]}` : "3px"}
    solid ${theme.colors.neutral.white};
  border-radius: ${theme.space.x5};

  @media ${theme.device.desktopFirst.mobileXL} {
    ${({ size }) =>
      size && size === "small"
        ? css`
            width: ${theme.space.x10};
            height: ${theme.space.x5};
          `
        : null}

    border-size: 1.5px solid ${theme.colors.neutral.white};
    margin-bottom: -2px;
  }
`;

interface BadgeTextProps {
  size?: "small" | "big";
}

export const BadgeText = styled.div<BadgeTextProps>`
  font-size: ${theme.typography.fontSize.md.x1};
  font-weight: ${theme.typography.fontWeight["600"]};
  color: ${theme.colors.neutral.white};
  text-transform: uppercase;

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${({ size }) => (size && size === "small" ? "8" : "20")}px;
  }
`;

interface TitleSectionProps {
  hasBackground: boolean;
}

export const TitleSection = styled.section<TitleSectionProps>`
  text-align: center;

  background-color: ${({ hasBackground }) =>
    hasBackground ? `${theme.colors.neutral.white}` : "transparent"};

  line-height: 1;
`;

interface TitleProps {
  size?: "small" | "big";
  isBlackTheme?: boolean;
}

export const Title = styled.span<TitleProps>`
  font-size: 14px;
  color: ${({ isBlackTheme }) =>
    isBlackTheme ? theme.colors.neutral.white : theme.colors.neutral["550"]};
  word-break: break-word;

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${({ size }) =>
      size && size === "small" ? "10px" : `${theme.typography.fontSize.sm.x5}`};
  }
`;
