import { useCallback, useEffect } from "react";
import { buildTestIds, useCountdown } from "application";
import {
  TextWithCountdownContainer,
  TitleStyledTextWithCountdown,
  SubTitleTextWithCountdown,
} from "./styles";

interface TextWithCountdownProps {
  isModalOpen?: boolean;
  textLink: string;
  textCountdownEnded: string;
  textWaitingForEndOfCountdown: string;
  onClickTheLink(): void;
  onFinishedTime(): void;
}

const TextWithCountdown = ({
  textLink,
  textCountdownEnded,
  textWaitingForEndOfCountdown,
  onClickTheLink,
  onFinishedTime,
  isModalOpen,
}: TextWithCountdownProps) => {
  const {
    timeInSeconds,
    timeFormatted,
    hasFinishedCountdown,
    changeHasFinishedCountdown,
    changeTimeInSeconds,
  } = useCountdown();

  const handleClickOnTextLink = useCallback(() => {
    onClickTheLink();
    changeTimeInSeconds(60);
    changeHasFinishedCountdown(false);
  }, [changeHasFinishedCountdown, changeTimeInSeconds, onClickTheLink]);

  useEffect(() => {
    if (timeInSeconds === 0) {
      onFinishedTime();

      if (!isModalOpen) {
        changeTimeInSeconds(60);
        changeHasFinishedCountdown(false);
      }
    }
  }, [
    changeHasFinishedCountdown,
    changeTimeInSeconds,
    isModalOpen,
    onFinishedTime,
    timeInSeconds,
  ]);

  return (
    <TextWithCountdownContainer
      {...buildTestIds("text-with-countdown-container")}
    >
      {hasFinishedCountdown ? (
        <SubTitleTextWithCountdown
          {...buildTestIds("subtitle-finished-countdown")}
        >
          {textCountdownEnded}
          <TitleStyledTextWithCountdown
            {...buildTestIds("title-styled-finished-text-with-countdown")}
            onClick={handleClickOnTextLink}
          >
            {textLink}
          </TitleStyledTextWithCountdown>
        </SubTitleTextWithCountdown>
      ) : (
        <SubTitleTextWithCountdown
          {...buildTestIds("subtitle-unfinished-text-with-countdown")}
        >{`${textWaitingForEndOfCountdown} ${timeFormatted}`}</SubTitleTextWithCountdown>
      )}
    </TextWithCountdownContainer>
  );
};

export type { TextWithCountdownProps };

export { TextWithCountdown };
