import { buildTestIds, useAppContext, useHeaderContext } from "application";
import { useAuthentication, useTwoFactor } from "application/state-manager";
import { useShallow } from "application/state-manager/adapter";
import { Image } from "infra";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { Step, StepName } from "typing";
import { ArrowLeft, theme } from "ui";
import { useMappedBackofficeComponent } from "../backoffice/hooks";
import { LinkRedirectHome } from "../patterns/LinkRedirectHome";
import { Skeleton } from "../Skeleton";
import { StatusProgress } from "../StatusProgress";
import {
  BackButton,
  HeaderContainer,
  HeaderWrapper,
  ImageContainer,
} from "./styles";

type HeaderProps = {
  isBlackTheme?: boolean;
  marginBottom?: string;
  hasStepper?: boolean;
  icon?: ReactNode;
  steps?: Step[];
  stepTitle?: string;
  goBackAction?: () => void;
  rootStep?: StepName;
};

export const Header = ({
  isBlackTheme,
  marginBottom,
  hasStepper,
  steps,
  icon,
  stepTitle,
  goBackAction,
}: HeaderProps) => {
  const router = useRouter();
  const { isClientMobile, isClientTablet, isPageWeddingList } = useAppContext();
  const { closeAuthenticationModal, currentStep, changeCurrentStep } =
    useAuthentication(
      useShallow((state) => ({
        closeAuthenticationModal: state.closeAuthenticationModal,
        currentStep: state.currentStep,
        changeCurrentStep: state.changeCurrentStep,
      }))
    );

  const { setIsValidationActive, myDataStep, setUserIdentification } =
    useTwoFactor(
      useShallow((state) => ({
        setIsValidationActive: state.setIsValidationActive,
        myDataStep: state.currentStep,
        setUserIdentification: state.setUserIdentification,
      }))
    );

  const { customHeader, isLoadingCustomHeader } = useHeaderContext();

  const customHeaderComponent = useMappedBackofficeComponent(customHeader, {
    isPageWeddingList,
    isClientMobile,
    isClientTablet,
    isLoading: isLoadingCustomHeader,
  });

  const handleTwoFactorRouterLogic = (passedIsClientMobile: boolean) => {
    setIsValidationActive(false);

    if (myDataStep === "editData") {
      setIsValidationActive(false);
      setUserIdentification("");
      return;
    }

    if (
      [
        "changePasswordDuePurchaseTime",
        "forgotPassword",
        "verifyCode",
        "verifyIdentify",
      ]?.includes(currentStep)
    ) {
      changeCurrentStep("login");
      return;
    }

    if (
      !passedIsClientMobile ||
      ["login", "securityIssueForm", "exclusionAccountForm"].includes(
        currentStep
      )
    ) {
      closeAuthenticationModal();
      router.back();
      return;
    }

    router.back();
  };

  return (
    <>
      {isLoadingCustomHeader ? (
        <Skeleton count={1} hasMargin={false} />
      ) : (
        customHeaderComponent
      )}
      <HeaderWrapper {...buildTestIds("header-wrapper")}>
        <HeaderContainer
          isBlackTheme={!!isBlackTheme}
          marginBottom={marginBottom}
          {...buildTestIds("header-container")}
        >
          <BackButton
            {...buildTestIds("header-back-button")}
            onClick={() => {
              if (goBackAction) {
                goBackAction();
                return;
              }

              handleTwoFactorRouterLogic(isClientMobile);
            }}
          >
            <ArrowLeft color={`${theme.colors.neutral.white}`} />
          </BackButton>
          <ImageContainer onClick={closeAuthenticationModal}>
            <LinkRedirectHome>
              <Image
                src="https://d2urakutajlof6.cloudfront.net/logo/logo_fc.svg"
                width={isClientMobile ? "96px" : "132px"}
                height={isClientMobile ? "36px" : "49px"}
                alt="Ferreira Costa"
                layout="fixed"
                data-cy="logo-ferreira-costa"
              />
            </LinkRedirectHome>
          </ImageContainer>
        </HeaderContainer>
        {hasStepper && steps ? (
          <StatusProgress
            hasIcon={false}
            icon={icon}
            stepTitle={stepTitle}
            statusStep={steps}
          />
        ) : null}
      </HeaderWrapper>
    </>
  );
};
