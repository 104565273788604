import { useProductsRecommendation } from "application";
import { appMonitoringClient, catalogApi, cookie, crm } from "implementations";
import { ComponentDetails, ComponentDto } from "typing";
import { useMappedBackofficeComponent } from "../../hooks";

const CustomComponent = (
  component: ComponentDto,
  details: ComponentDetails
) => {
  const { isCrmComponent, isLoading, productCards, componentCrmProps } =
    useProductsRecommendation(
      crm,
      component,
      cookie,
      catalogApi,
      appMonitoringClient
    );

  return useMappedBackofficeComponent(
    {
      ...component,
      ...(isCrmComponent && { productCards }),
      ...(isCrmComponent && { crm: componentCrmProps || undefined }),
    },
    {
      ...details,
      ...(isCrmComponent && { isLoading }),
      isCrmComponent,
    }
  );
};

CustomComponent.displayName = "CustomComponent";

export { CustomComponent };
