import { IconProps } from "typing";
import { theme } from "../themes";

export const Card = ({
  alt = "Cartão",
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect
        x="4.0332"
        y="6.09996"
        width="15.8667"
        height="11.9667"
        rx="1"
        stroke={color}
      />
      <rect
        x="6.6333"
        y="8.69995"
        width="3.23333"
        height="2.96667"
        rx="1"
        stroke={color}
      />
      <path
        d="M6.6167 14.75H8.40003M9.63337 14.75H11.4167M12.75 14.75H14.5334M15.8667 14.75H17.65"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};
