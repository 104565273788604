import { IconProps } from "typing";
import { theme } from "../themes";

export const EditPencilIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["760"],
  alt = "Ícone de lápis num box de edição",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 13.067V18.972C21 20.092 20.099 21 18.987 21H5.013C3.901 21 3 20.092 3 18.972V6.028C3 4.908 3.901 4 5.013 4H12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.82812 17.1718L11.5591 16.2388C11.7351 16.1948 11.8951 16.1037 12.0241 15.9757L20.4141 7.58575C21.1951 6.80475 21.1951 5.53875 20.4141 4.75775L20.2421 4.58575C19.4611 3.80475 18.1951 3.80475 17.4141 4.58575L9.02412 12.9757C8.89612 13.1037 8.80513 13.2648 8.76113 13.4408L7.82813 17.1718"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8301 6.16992L18.8301 9.16992"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5468 16.2409C11.5608 16.1349 11.5788 16.0299 11.5788 15.9199C11.5788 14.5389 10.4598 13.4199 9.07881 13.4199C8.96881 13.4199 8.86381 13.4389 8.75781 13.4519"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
