import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Jcb = ({
  width = 65,
  height = 40,
  alt = "Jcb",
  color = theme.colors.neutral.white,
  secondColor = theme.colors.secondary["650"],
  thirdColor = theme.colors.tertiary["850"],
  fourthColor = theme.colors.primary["750"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 36 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M35.5184 22.3556C35.5184 25.4667 33.0434 28 30.0039 28H0V5.64444C0 2.53333 2.475 0 5.51447 0H35.5184V22.3556Z"
        fill={color}
      />
      <path
        d="M25.7485 16.6223H28.0281C28.0933 16.6223 28.2452 16.6001 28.3104 16.6001C28.7446 16.5112 29.1137 16.1112 29.1137 15.5557C29.1137 15.0223 28.7446 14.6223 28.3104 14.5112C28.2452 14.489 28.115 14.489 28.0281 14.489H25.7485V16.6223Z"
        fill={secondColor}
      />
      <path
        d="M27.7677 1.88879C25.5967 1.88879 23.8164 3.68879 23.8164 5.93324V10.1332H29.396C29.5263 10.1332 29.6782 10.1332 29.7868 10.1555C31.046 10.2221 31.9795 10.8888 31.9795 12.0443C31.9795 12.9555 31.3499 13.7332 30.1776 13.8888V13.9332C31.4585 14.0221 32.4355 14.7555 32.4355 15.8888C32.4355 17.111 31.3499 17.911 29.917 17.911H23.7947V26.1332H29.5914C31.7624 26.1332 33.5427 24.3332 33.5427 22.0888V1.88879H27.7677Z"
        fill={secondColor}
      />
      <path
        d="M28.8314 12.311C28.8314 11.7777 28.4624 11.4221 28.0281 11.3555C27.9847 11.3555 27.8762 11.3333 27.811 11.3333H25.7485V13.2888H27.811C27.8762 13.2888 28.0064 13.2888 28.0281 13.2666C28.4624 13.1999 28.8314 12.8444 28.8314 12.311Z"
        fill={secondColor}
      />
      <path
        d="M5.92688 1.88879C3.75583 1.88879 1.97557 3.68879 1.97557 5.93324V15.911C3.0828 16.4666 4.23346 16.8221 5.38412 16.8221C6.75188 16.8221 7.49004 15.9777 7.49004 14.8221V10.111H10.8769V14.7999C10.8769 16.6221 9.76965 18.111 6.01373 18.111C3.73412 18.111 1.95386 17.5999 1.95386 17.5999V26.111H7.75057C9.92162 26.111 11.7019 24.311 11.7019 22.0666V1.88879H5.92688Z"
        fill={thirdColor}
      />
      <path
        d="M16.8473 1.88879C14.6763 1.88879 12.896 3.68879 12.896 5.93324V11.2221C13.8947 10.3555 15.6315 9.79991 18.4322 9.93324C19.9302 9.99991 21.5368 10.4221 21.5368 10.4221V12.1332C20.7335 11.711 19.7782 11.3332 18.5407 11.2443C16.4131 11.0888 15.1322 12.1555 15.1322 14.0221C15.1322 15.911 16.4131 16.9777 18.5407 16.7999C19.7782 16.711 20.7335 16.311 21.5368 15.911V17.6221C21.5368 17.6221 19.9519 18.0444 18.4322 18.111C15.6315 18.2444 13.8947 17.6888 12.896 16.8221V26.1555H18.6927C20.8638 26.1555 22.644 24.3555 22.644 22.111V1.88879H16.8473Z"
        fill={fourthColor}
      />
    </svg>
  );
};
