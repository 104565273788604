import { IconProps } from "typing";

export const ShoppingFlowIcon = ({
  width = "121",
  height = "121",
  alt = "ícone de fluxo de compras",
  opacity = "0.2",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 121 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.486816"
        width="120"
        height="120"
        rx="12.1582"
        fill="#F6F7F7"
      />
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.1575 69.5918C97.4142 69.5918 96 70.9117 96 72.5396C96 74.1674 97.4142 75.4866 99.1575 75.4866H107.842C109.586 75.4866 111 74.1667 111 72.5396C111 70.9124 109.586 69.5926 107.842 69.5926C107.842 67.3541 105.898 65.5396 103.5 65.5396C101.102 65.5396 99.1575 67.3533 99.1575 69.5918Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3315 12.78C10.1509 12.78 9.19312 13.6739 9.19312 14.7764C9.19312 15.8789 10.1509 16.7722 11.3315 16.7722H17.2129C18.3941 16.7722 19.3518 15.8783 19.3518 14.7764C19.3518 13.6744 18.3941 12.7805 17.2134 12.7805C17.2134 11.2645 15.8967 10.0356 14.2725 10.0356C12.6482 10.0356 11.3315 11.264 11.3315 12.78Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.824 28.6815C104.491 28.6815 103.411 29.6903 103.411 30.9345C103.411 32.1786 104.491 33.1868 105.824 33.1868H112.461C113.794 33.1868 114.875 32.1781 114.875 30.9345C114.875 29.6909 113.794 28.6821 112.462 28.6821C112.462 26.9713 110.976 25.5845 109.143 25.5845C107.31 25.5845 105.824 26.9707 105.824 28.6815Z"
          stroke="#8F9194"
          strokeWidth="0.509524"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.332 107.78C99.1509 107.78 98.1931 108.673 98.1931 109.776C98.1931 110.878 99.1509 111.772 100.332 111.772H106.213C107.394 111.772 108.352 110.878 108.352 109.776C108.352 108.674 107.394 107.78 106.213 107.78C106.213 106.264 104.897 105.035 103.272 105.035C101.648 105.035 100.332 106.264 100.332 107.78Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.7459 11.0025C78.1294 11.0025 76.8181 12.2264 76.8181 13.7359C76.8181 15.2453 78.1294 16.4685 79.7459 16.4685H87.7983C89.4156 16.4685 90.7268 15.2446 90.7268 13.7359C90.7268 12.2271 89.4156 11.0033 87.7991 11.0033C87.7991 8.92766 85.9963 7.24512 83.7725 7.24512C81.5486 7.24512 79.7459 8.92694 79.7459 11.0025Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.7249 51.9723C37.1148 51.9723 35.8088 53.1912 35.8088 54.6946C35.8088 56.198 37.1148 57.4162 38.7249 57.4162H46.7449C48.3556 57.4162 49.6616 56.1973 49.6616 54.6946C49.6616 53.192 48.3556 51.973 46.7456 51.973C46.7456 49.9058 44.9501 48.23 42.7352 48.23C40.5203 48.23 38.7249 49.905 38.7249 51.9723Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.7249 45.9723C81.1148 45.9723 79.8088 47.1912 79.8088 48.6946C79.8088 50.198 81.1148 51.4162 82.7249 51.4162H90.7449C92.3556 51.4162 93.6616 50.1973 93.6616 48.6946C93.6616 47.192 92.3556 45.973 90.7456 45.973C90.7456 43.9058 88.9501 42.23 86.7352 42.23C84.5203 42.23 82.7249 43.905 82.7249 45.9723Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.3315 78.9758C37.1509 78.9758 36.1931 79.8697 36.1931 80.9722C36.1931 82.0747 37.1509 82.968 38.3315 82.968H44.2129C45.3941 82.968 46.3518 82.0741 46.3518 80.9722C46.3518 79.8702 45.3941 78.9763 44.2134 78.9763C44.2134 77.4604 42.8967 76.2314 41.2725 76.2314C39.6482 76.2314 38.3315 77.4598 38.3315 78.9758Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3315 101.539C11.1509 101.539 10.1931 102.433 10.1931 103.536C10.1931 104.638 11.1509 105.532 12.3315 105.532H18.2129C19.3941 105.532 20.3518 104.638 20.3518 103.536C20.3518 102.434 19.3941 101.54 18.2134 101.54C18.2134 100.024 16.8967 98.7949 15.2725 98.7949C13.6482 98.7949 12.3315 100.023 12.3315 101.539Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.7249 105.972C48.1148 105.972 46.8088 107.191 46.8088 108.694C46.8088 110.198 48.1148 111.416 49.7249 111.416H57.7449C59.3556 111.416 60.6616 110.197 60.6616 108.694C60.6616 107.191 59.3556 105.973 57.7456 105.973C57.7456 103.905 55.9501 102.229 53.7352 102.229C51.5203 102.229 49.7249 103.905 49.7249 105.972Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.72485 58.3092C7.11485 58.3092 5.80884 59.5281 5.80884 61.0315C5.80884 62.5349 7.11485 63.7532 8.72485 63.7532H16.7449C18.3556 63.7532 19.6616 62.5342 19.6616 61.0315C19.6616 59.5289 18.3556 58.3099 16.7456 58.3099C16.7456 56.2427 14.9501 54.5669 12.7352 54.5669C10.5203 54.5669 8.72485 56.242 8.72485 58.3092Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M34.5 24.4868H28.4987C24.084 24.4868 20.5 28.1298 20.5 32.6172V75.9868"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.5 24.4868H92.5013C96.916 24.4868 100.5 28.1298 100.5 32.6172V75.9868"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.1666 19.1535V17.8201V17.8201C55.1666 14.8748 57.5546 12.4868 60.5 12.4868V12.4868C63.4453 12.4868 65.8333 14.8748 65.8333 17.8201V17.8201V19.1535"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.2867 36.4867H52.7134C51.16 36.4867 49.936 35.164 50.0547 33.616L51.072 20.3853C51.1254 19.6893 51.704 19.1533 52.4014 19.1533H68.5987C69.296 19.1533 69.8747 19.6893 69.928 20.384L70.9454 33.6147C71.0654 35.164 69.84 36.4867 68.2867 36.4867Z"
        fill="white"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.4184 57.8794C47.516 58.4027 44.5 61.654 44.5 65.6297C44.5 69.9684 48.0824 73.4868 52.5 73.4868H70.1C73.6344 73.4868 76.5 70.6724 76.5 67.2011C76.5 63.7298 73.6344 60.9154 70.1 60.9154C70.1 55.7077 65.8024 51.4868 60.5 51.4868C56.2808 51.4868 52.7064 54.163 51.4184 57.8794Z"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.75 58.4868L63.6667 61.4035H55.5"
        stroke="#68A439"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4167 68.987L55.5 66.0703H63.6667"
        stroke="#68A439"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.25 103.153H13.25C12.5136 103.153 11.9166 102.556 11.9166 101.82V85.8197C11.9166 85.0833 12.5136 84.4863 13.25 84.4863H37.25C37.9863 84.4863 38.5833 85.0833 38.5833 85.8197V101.82C38.5833 102.556 37.9863 103.153 37.25 103.153Z"
        fill="white"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="21.25"
        y="103.153"
        width="8"
        height="5.33333"
        fill="white"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5833 99.1533H11.9166"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 108.486H31.25"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.25 96.4863C47.25 95.934 47.5924 95.4863 48.0147 95.4863H72.4853C72.9076 95.4863 73.25 95.934 73.25 96.4863C73.25 97.0386 72.9076 97.4863 72.4853 97.4863H48.0147C47.5924 97.4863 47.25 97.0386 47.25 96.4863Z"
        fill="#53565A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.25 87.153V105.82C103.25 107.292 102.056 108.486 100.583 108.486H89.9167C88.4439 108.486 87.25 107.292 87.25 105.82V87.153C87.25 85.6802 88.4439 84.4863 89.9167 84.4863H100.583C102.056 84.4863 103.25 85.6802 103.25 87.153Z"
        fill="white"
      />
      <path
        d="M103.25 87.153V105.82C103.25 107.292 102.056 108.486 100.583 108.486H89.9167C88.4439 108.486 87.25 107.292 87.25 105.82V87.153C87.25 85.6802 88.4439 84.4863 89.9167 84.4863H100.583C102.056 84.4863 103.25 85.6802 103.25 87.153"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.5834 104.486H97.9167"
        stroke="#53565A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
