import { memo } from "react";
import { SwiperProps as SwiperProperties } from "typing";
import { SwiperSlider } from "infra";
import { buildTestIds } from "application";

interface SwiperProps extends SwiperProperties {
  getBreakpoints: () => number;
}

const Swiper = memo(
  ({
    slidesPerView,
    pagination,
    scrollbar,
    freeMode,
    spaceBetween,
    sticky,
    slidesPerGroup,
    breakpoints,
    subComponents,
    navigation,
    slidesOffsetAfter,
    slidesOffsetBefore,
    width,
    effect,
    position,
    getBreakpoints,
    configs,
  }: SwiperProps) => {
    return (
      <SwiperSlider
        {...buildTestIds("swiper-slider-component")}
        {...{
          slidesPerView,
          pagination,
          scrollbar,
          freeMode,
          spaceBetween,
          sticky,
          slidesPerGroup,
          breakpoints,
          subComponents,
          navigation,
          slidesOffsetAfter,
          slidesOffsetBefore,
          width,
          effect,
          position,
          getBreakpoints,
          configs,
        }}
      />
    );
  }
);

Swiper.displayName = "Slider";

export { Swiper };
