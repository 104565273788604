const maskEmail = (email: string): string => {
  if (!email) {
    return "";
  }

  const atIndex = email?.indexOf("@");
  if (atIndex !== -1) {
    const [username, domain] = email?.split("@");
    const [domainName, ...extensions] = domain?.split(".");
    const hiddenUsername =
      username.substring(0, 2) +
      "*".repeat(username?.length - 4) +
      username.slice(-1);
    const visibleDomain = `${domainName[0]}${"*".repeat(
      domainName.length - 2
    )}${domainName.slice(-1)}.${extensions.join(".")}`;
    return `${hiddenUsername}@${visibleDomain}`;
  }
  return email;
};

const maskPhoneNumber = (phoneNumber: string): string => {
  const trimmedPhoneNumber = phoneNumber?.trim();
  const prefix = `(${trimmedPhoneNumber?.substring(0, 2)})`;
  const suffix = `*****-**${trimmedPhoneNumber?.substring(9)}`;

  return `${prefix} ${suffix}`;
};

export { maskEmail, maskPhoneNumber };
