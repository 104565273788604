import { Title, GridPlaylistsSubComponentProperty, Image } from "typing";

const customGridPlaylistsCardData = (
  properties: GridPlaylistsSubComponentProperty[]
) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const image = (properties.find(({ key }) => key === "image") as Image)?.value;

  return {
    title,
    image,
  };
};

export { customGridPlaylistsCardData };
