import { IChatBot } from "app-domain";
import { obfuscate } from "application";
import { useChatbot, useShallow } from "application/state-manager";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";
import { UserData } from "typing";
import { useRouter } from "next/router";

const GenesysChatBot: IChatBot = {
  Script: ({ jwtDecoder, purchaseApi }) => {
    const router = useRouter();
    const { showChatbot, showSaleChatbot } = useChatbot(
      useShallow((state) => ({
        showChatbot: state.showChatbot,
        showSaleChatbot: state.showSaleChatbot,
      }))
    );
    const token = getCookie("@FC:Ecom:Token:Access");

    const { data } = purchaseApi.getChatEligibleForClient(
      token as string,
      !!token
    );

    const [isClientMobileL, setIsClientMobileL] = useState(false);

    useEffect(() => {
      const mediaQuery = window.matchMedia("(max-width: 425px)");
      setIsClientMobileL(mediaQuery.matches);

      const handleResize = () => {
        setIsClientMobileL(mediaQuery.matches);
      };

      mediaQuery.addListener(handleResize);
      return () => mediaQuery.removeListener(handleResize);
    }, []);

    useEffect(() => {
      const genesysButton = document.getElementById("genesys-messenger");

      const isChatbotAvailable =
        data?.championClient && data?.clientHasOpenedPurchases;

      const showSaleChat =
        showSaleChatbot && !isChatbotAvailable && Boolean(token);

      if (
        (router.pathname.includes("/produto") ||
          router.pathname.includes("/checkout")) &&
        isClientMobileL &&
        !showSaleChat
      ) {
        genesysButton?.style.setProperty("display", "none");
        const script = document.getElementById("genesys");
        script?.remove();
        return;
      }

      const showChampionClientChat = isChatbotAvailable && showChatbot;

      if (showChampionClientChat || showSaleChat) {
        const genesysScript = document.getElementById("genesys");

        if (genesysScript) return;

        const genesysUrl = obfuscate(
          "https://fcx-s3-fercos-backoffice.ferreiracosta.com/scripts/genesys/genesys.min.js"
        );

        const genesysChampionClientId = obfuscate(
          "3fab9f2e-ed7a-4a54-bafd-e346eed40380"
        );

        const genesysSaleId = obfuscate("e38de0e3-d979-4adb-8d1c-d92e165e24a0");

        const deploymentId = showChampionClientChat
          ? genesysChampionClientId
          : genesysSaleId;

        const userData = jwtDecoder?.decode<UserData>(token as string);

        const script = document.createElement("script");

        script.type = "text/javascript";
        script.id = "genesys";
        script.setAttribute("data-testid", "genesys-script");
        script.setAttribute("data-cy", "genesys-script");
        script.innerHTML = `(function (g, e, n, es, ys) {
          g['_genesysJs'] = e;
          g[e] = g[e] || function () {
              (g[e].q = g[e].q || []).push(arguments)
            };
            g[e].t = 1 * new Date();
            g[e].c = es;
            ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
          })(window, 'Genesys', '${genesysUrl}', {
            environment: 'prod-sae1',
            deploymentId: '${deploymentId}'
          });
          
          Genesys("command", "Database.set", {
            messaging: {
              customAttributes: {
                "cgc_cpf": '${userData?.cpf}',
                "email":  '${userData?.email}',
              }
            }
          });`;

        document.head.appendChild(script);
        genesysButton?.style.setProperty("display", "block");

        return;
      }

      genesysButton?.style.setProperty("display", "none");
      const script = document.getElementById("genesys");
      script?.remove();
    }, [
      showChatbot,
      token,
      jwtDecoder,
      data?.championClient,
      data?.clientHasOpenedPurchases,
      router.pathname,
      showSaleChatbot,
      isClientMobileL,
      data,
    ]);

    return null;
  },
};

export { GenesysChatBot };
