import { buildTestIds } from "application";
import { Suggestion } from "typing";
import { TermSuggestionOption } from "../../subcomponents";

interface TermSuggestionResultProps {
  result?: Suggestion;
  searchProduct?: string;
  onClick: (searchTerm: string) => void;
}
export const TermSuggestionResult = ({
  result,
  searchProduct,
  onClick,
}: TermSuggestionResultProps) => {
  if (result && result?.suggestions?.length > 0) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {result?.suggestions.map((suggestion) => (
          <TermSuggestionOption
            key={suggestion}
            term={suggestion}
            onClick={() => {
              onClick(`/pesquisa/${suggestion.replace(/\//g, " ")}`);
            }}
          />
        ))}
      </>
    );
  }

  if (searchProduct) {
    return (
      <TermSuggestionOption
        key={searchProduct}
        term={searchProduct}
        onClick={() => {
          onClick(`/pesquisa/${searchProduct.replace(/\//g, " ")}`);
        }}
        {...buildTestIds("item-suggestion-not-found")}
      />
    );
  }

  return null;
};
