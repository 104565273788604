/* eslint-disable react/no-array-index-key */
import { FC, PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonProps } from "typing/types/SkeletonProps";
import { theme } from "ui";
import { buildTestIds } from "application";
import {
  BoxContainer,
  SkeletonCardWrapper,
  SkeletonChip,
  SkeletonCircle,
  SkeletonFlexContainer,
  SkeletonWrapper,
  SkeletonContainer,
  ItemWrapper,
} from "./style";

type ReactLoadingSkeletonProps = SkeletonProps;

const Box = ({ children }: PropsWithChildren<unknown>) => {
  return <BoxContainer>{children}</BoxContainer>;
};

export const ReactLoadingSkeleton: FC<ReactLoadingSkeletonProps> = ({
  count,
  type = "basic",
  width = "100%",
  height = 30,
  gap,
  inline = false,
  style,
  justifyContent,
  hasMargin = true,
}) => {
  const templates = {
    basic: (
      <Skeleton
        wrapper={Box}
        count={count}
        width={width}
        height={height}
        inline={inline}
      />
    ),
    button: (
      <Skeleton
        wrapper={Box}
        count={count}
        width={width || "150px"}
        height={height || "4rem"}
        inline={inline}
        borderRadius="4px"
      />
    ),
    card: (
      <SkeletonFlexContainer gap={gap}>
        {Array(count)
          .fill(1)
          .map((_, index) => {
            const fakeIndex = index;
            return (
              <SkeletonCardWrapper key={fakeIndex}>
                <Skeleton width={width} height={height} />
              </SkeletonCardWrapper>
            );
          })}
      </SkeletonFlexContainer>
    ),
    productListGridCard: (
      <SkeletonFlexContainer style={{ ...style }} gap={gap}>
        {Array(count)
          .fill(1)
          .map((_, index) => {
            const fakeIndex = index;
            return (
              <Skeleton
                key={fakeIndex}
                width={width}
                height={height}
                borderRadius="8px"
              />
            );
          })}
      </SkeletonFlexContainer>
    ),
    "card-user": (
      <SkeletonFlexContainer justifyContent={justifyContent}>
        {Array(count)
          .fill(1)
          .map((_, index) => {
            const fakeIndex = index;
            return (
              <SkeletonCardWrapper key={fakeIndex}>
                <SkeletonCircle />
                <Skeleton width={width} height={height} />
              </SkeletonCardWrapper>
            );
          })}
      </SkeletonFlexContainer>
    ),
    chip: (
      <SkeletonFlexContainer>
        {Array(count)
          .fill(1)
          .map((_, index) => {
            const fakeIndex = index;
            return <SkeletonChip key={fakeIndex} />;
          })}
      </SkeletonFlexContainer>
    ),
    user: (
      <ItemWrapper>
        {Array(count)
          .fill(1)
          .map((_, index) => {
            const fakeIndex = index;
            return (
              <SkeletonContainer key={fakeIndex}>
                <SkeletonFlexContainer alignItems="center">
                  <Skeleton
                    style={{ marginRight: "10px" }}
                    width="50px"
                    height="50px"
                    borderRadius="400px"
                  />
                  <SkeletonFlexContainer
                    flexDirection="column"
                    gap={theme.space.x1}
                  >
                    <Skeleton width="300px" height="16px" />
                    <Skeleton width="125px" height="16px" />
                  </SkeletonFlexContainer>
                </SkeletonFlexContainer>
              </SkeletonContainer>
            );
          })}
      </ItemWrapper>
    ),
    standardLibrary: <Skeleton width={width} height={height} />,
    sidebarFilters: (
      <SkeletonFlexContainer gap={gap} marginBottom="25px">
        <SkeletonCardWrapper width="100%">
          <Skeleton
            width="120px"
            height="22px"
            style={{ marginBottom: "20px" }}
          />
          {Array(count)
            .fill(1)
            .map((_, index) => {
              const fakeIndex = index;
              return (
                <SkeletonFlexContainer
                  key={fakeIndex}
                  gap="20px"
                  justifyContent="space-between"
                >
                  <Skeleton height="18px" style={{ ...style }} />
                  <Skeleton
                    width="30px"
                    height="18px"
                    style={{ borderRadius: "10px" }}
                  />
                </SkeletonFlexContainer>
              );
            })}
        </SkeletonCardWrapper>
      </SkeletonFlexContainer>
    ),
  };

  return (
    <SkeletonWrapper
      hasMargin={hasMargin}
      {...buildTestIds("skeleton-container")}
    >
      {templates[`${type}`]}
    </SkeletonWrapper>
  );
};

export default ReactLoadingSkeleton;
