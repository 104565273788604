import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const GiftIcon = ({
  width = 26,
  height = 26,
  alt = "Presente",
  color = theme.colors.primary["200"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1648_51" fill={secondColor}>
        <path d="M3.33334 11.7333C3.33334 11.1811 3.78105 10.7333 4.33334 10.7333H19.6667C20.219 10.7333 20.6667 11.1811 20.6667 11.7333V19.5667C20.6667 20.6712 19.7712 21.5667 18.6667 21.5667H5.33334C4.22877 21.5667 3.33334 20.6712 3.33334 19.5667V11.7333Z" />
      </mask>
      <path
        d="M3.33334 11.7333C3.33334 11.1811 3.78105 10.7333 4.33334 10.7333H19.6667C20.219 10.7333 20.6667 11.1811 20.6667 11.7333V19.5667C20.6667 20.6712 19.7712 21.5667 18.6667 21.5667H5.33334C4.22877 21.5667 3.33334 20.6712 3.33334 19.5667V11.7333Z"
        stroke={color}
        strokeWidth="3"
        mask="url(#path-1-inside-1_1648_51)"
      />
      <path
        d="M8.46666 6.91666C5.42666 6.91666 6.03333 3.96666 7.21667 3.28333C9.29609 2.05403 12.0233 5.31629 12 7.2C11.9178 5.4069 14.6627 2.16472 16.8 3.28334C17.9833 3.96667 18.59 6.91667 15.55 6.91667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12.05 20.5833V7.3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <rect
        x="2.75"
        y="6.91666"
        width="18.5"
        height="4.56667"
        rx="1.25"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
