import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Bin = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label="Lixeira"
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g id="Icons / Bin">
        <g id="Cores/Preto">
          <path
            id="Mask"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.01496 7H9.5V4.5C9.5 3.94772 9.90294 3.5 10.4 3.5H17.6C18.0971 3.5 18.5 3.94772 18.5 4.5V7H20.9851H23.3023C23.6876 7 24 7.33579 24 7.75C24 8.16421 23.6876 8.5 23.3023 8.5H21.9499L20.1896 24.1104C20.1325 24.6169 19.698 25 19.1809 25H8.81917C8.30205 25 7.86762 24.6169 7.81051 24.1104L6.05023 8.5H4.69767C4.31236 8.5 4 8.16421 4 7.75C4 7.33579 4.31236 7 4.69767 7H7.01496ZM20.4182 8.5H7.5819L9.27335 23.5H18.7267L20.4182 8.5ZM17.15 7H10.85V5H17.15V7Z"
            fill={color}
          />
          <mask
            id="mask0_0_2837"
            maskUnits="userSpaceOnUse"
            x="4"
            y="3"
            width="20"
            height="22"
          >
            <path
              id="Mask_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.01496 7H9.5V4.5C9.5 3.94772 9.90294 3.5 10.4 3.5H17.6C18.0971 3.5 18.5 3.94772 18.5 4.5V7H20.9851H23.3023C23.6876 7 24 7.33579 24 7.75C24 8.16421 23.6876 8.5 23.3023 8.5H21.9499L20.1896 24.1104C20.1325 24.6169 19.698 25 19.1809 25H8.81917C8.30205 25 7.86762 24.6169 7.81051 24.1104L6.05023 8.5H4.69767C4.31236 8.5 4 8.16421 4 7.75C4 7.33579 4.31236 7 4.69767 7H7.01496ZM20.4182 8.5H7.5819L9.27335 23.5H18.7267L20.4182 8.5ZM17.15 7H10.85V5H17.15V7Z"
              fill={secondColor}
            />
          </mask>
        </g>
      </g>
    </svg>
  );
};
