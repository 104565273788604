import styled, { css } from "styled-components";
import { FlexContainer, theme } from "ui";
import { Link } from "../../Link";

type TextsContainerProps = {
  isClientMobile?: boolean;
  isClientTablet?: boolean;
};

export const TextsContainer = styled(FlexContainer)<TextsContainerProps>`
  flex-direction: ${({ isClientMobile }) =>
    isClientMobile ? "column" : "row"};
  gap: ${theme.space.x0} ${theme.space.x2};
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const BannerTitle = styled.i``;

export const BannerMessage = styled.span``;

export const BannerCtaText = styled.strong``;

export const BannerLink = styled(Link)``;

type BannerProps = {
  backgroundColor: string;
  backgroundImage?: string;
  isToRedirect: boolean;
};

export const Banner = styled.div<Partial<BannerProps>>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  padding: 0.25rem 1rem;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || theme.colors.primary["200"]};
  text-align: center;
  min-height: 3rem;
  max-height: fit-content !important;
  cursor: ${({ isToRedirect }) => (isToRedirect ? "pointer" : "default")};

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage}) !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `}

  ${BannerMessage} {
    font-size: 1.25rem;
    font-weight: normal;
    color: ${theme.colors.neutral.white};
  }

  ${BannerTitle} {
    font-size: 1.25rem;
    color: ${theme.colors.neutral.white};
  }

  ${BannerCtaText} {
    font-size: 1.25rem;
    color: ${theme.colors.neutral.white};
  }

  ${BannerLink} {
    text-decoration: none;
    margin-left: 0.5rem;
    font-size: 1rem;
    color: ${theme.colors.neutral.white};
  }

  @media ${theme.device.desktopFirst.tablet} {
    ${BannerMessage} {
      font-size: 1.083rem;
      color: ${theme.colors.neutral.white};
    }

    ${BannerTitle} {
      font-size: 1.083rem;
      color: ${theme.colors.neutral.white};
    }

    ${BannerCtaText} {
      font-size: 1.083rem;
      color: ${theme.colors.neutral.white};
    }

    ${BannerLink} {
      width: 8rem;
    }
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    min-height: 5rem !important;
    max-height: fit-content !important;

    ${BannerMessage} {
      font-size: 1rem;
    }

    ${BannerTitle} {
      font-size: 1rem;
    }

    ${BannerCtaText} {
      font-size: 1rem;
    }

    ${BannerLink} {
      margin-top: 0.125rem;
      font-size: 1rem;
    }
  }

  @media ${theme.device.desktopFirst.mobileM} {
    padding: 0.75rem 0;
  }
`;
