import { buildTestIds } from "application";
import { useEffect, useRef, useState } from "react";
import { Buckets } from "typing";
import { Accordion, ArrowDown, ArrowUp } from "ui";
import { Skeleton } from "../Skeleton";
import { useScreenResizeSkeleton } from "./hooks";
import {
  FilterCounter,
  FilterText,
  SidebarFilterList,
  SidebarFilterListHeader,
  SidebarFilterListItem,
  ViewMoreButton,
} from "./styles";

export interface FacetsFilterListProps {
  filterTitle: string;
  filterOptions: Buckets[];
  activeOptions: string[];
  isLoadingFacets: boolean;
  hasFacets?: boolean;
  isBlackTheme?: boolean;
  handleTitleFilter: (selectedFilterTitle: string) => void;
  handleOptionFilter: (selectedFilterParams: string) => void;
  hasPreSelectFilter?: boolean;
}

type FilterTitle = "brand" | "category" | "model";

const FacetsFilterList = ({
  filterTitle,
  filterOptions,
  activeOptions,
  isLoadingFacets,
  hasFacets,
  isBlackTheme,
  handleTitleFilter,
  handleOptionFilter,
  hasPreSelectFilter = false,
}: FacetsFilterListProps) => {
  const INITIAL_MAX_HEIGHT = 1000;
  const collapseMenuRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const maxHeightRef = useRef(INITIAL_MAX_HEIGHT);
  const prevFilterOptionsRef = useRef<Buckets[]>([]);

  const [isOpen, setIsOpen] = useState(true);
  const [isOpenMore, setIsOpenMore] = useState(false);
  const [showItems, setShowItems] = useState(5);
  const [activeIndexes, setActiveIndexes] = useState(() =>
    filterOptions.map((item) => !!activeOptions.includes(item.key))
  );

  const prevFilterOptions = prevFilterOptionsRef.current;

  const handleShowMore = () => {
    setIsOpenMore((openMore) => !openMore);
    setShowItems((showMoreItems) =>
      showMoreItems >= filterOptions?.length
        ? showMoreItems
        : showMoreItems + 25
    );
  };

  useEffect(
    () =>
      setActiveIndexes(
        filterOptions.map((item) => !!activeOptions.includes(item.key))
      ),
    [activeOptions, filterOptions]
  );

  const [facetTitle, setFacetTitle] = useState<string>("");
  useEffect(() => {
    const getMappedTitle = {
      category: "Categoria",
      brand: "Marcas",
      model: "Modelos",
    };

    const getFilterTitle = () => {
      if (filterTitle) {
        const title = filterTitle as FilterTitle;
        setFacetTitle(getMappedTitle[`${title}`] || filterTitle);
      }
    };

    getFilterTitle();
  }, [facetTitle, filterTitle]);

  useEffect(() => {
    if (collapseMenuRef.current && !isFirstRender.current) {
      if (
        maxHeightRef.current > collapseMenuRef.current.offsetHeight &&
        maxHeightRef.current !== INITIAL_MAX_HEIGHT
      ) {
        return;
      }
      maxHeightRef.current = collapseMenuRef.current.offsetHeight;
    }
    if (isOpen && isOpenMore && isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [isOpen, isOpenMore]);

  useEffect(() => {
    prevFilterOptionsRef.current = filterOptions;
    if (
      prevFilterOptions[0]?.key !== filterOptions[0]?.key &&
      hasPreSelectFilter &&
      filterOptions.length === 1
    ) {
      handleOptionFilter(filterOptions[0].key);
    }
  }, [
    filterOptions,
    handleOptionFilter,
    hasPreSelectFilter,
    prevFilterOptions,
  ]);

  const screenResizeSkeleton = useScreenResizeSkeleton();

  if (isLoadingFacets) {
    return (
      <Skeleton
        {...buildTestIds("skeleton")}
        type="sidebarFilters"
        count={5}
        style={screenResizeSkeleton}
        gap="10px"
        width="100%"
        hasMargin={false}
      />
    );
  }

  return (
    <SidebarFilterList
      {...buildTestIds("sidebar-filter-list")}
      isBlackTheme={isBlackTheme}
    >
      {filterOptions?.length && filterTitle && hasFacets ? (
        <>
          <SidebarFilterListHeader
            {...buildTestIds("sidebar-filter-list-header")}
            onClick={() => setIsOpen((open) => !open)}
          >
            {facetTitle} {isOpen ? <ArrowUp /> : <ArrowDown />}
          </SidebarFilterListHeader>
          <Accordion
            {...buildTestIds("accordion")}
            ref={collapseMenuRef}
            show={isOpen}
            maxHeight={isOpen ? `${maxHeightRef.current}px` : `0`}
          >
            {filterOptions.slice(0, showItems).map((option: Buckets, index) => (
              <SidebarFilterListItem
                {...buildTestIds("sidebar-filter-list-item")}
                key={`${option?.key}#${option?.docCount}`}
                // eslint-disable-next-line sonarjs/no-nested-template-literals
                className={`${activeIndexes[`${index}`] ? "active" : ""}`}
                onClick={() => {
                  setActiveIndexes(
                    activeIndexes.map((bool, activeIndex) =>
                      activeIndex === index ? !bool : bool
                    )
                  );
                  handleTitleFilter(facetTitle);
                  handleOptionFilter(encodeURIComponent(option.key));
                }}
              >
                <FilterText
                  {...buildTestIds("filter-text")}
                  fontSize="1rem"
                  margin="0"
                >
                  {option.key}
                </FilterText>
                <FilterCounter
                  {...buildTestIds("filter-counter")}
                  // eslint-disable-next-line sonarjs/no-nested-template-literals
                  className={`${activeIndexes[`${index}`] ? "active" : ""}`}
                >
                  {option.docCount}
                </FilterCounter>
              </SidebarFilterListItem>
            ))}
            {showItems < filterOptions?.length && (
              <ViewMoreButton
                {...buildTestIds("view-more-button")}
                onClick={() => handleShowMore()}
              >
                ver +{filterOptions.length - showItems}
              </ViewMoreButton>
            )}
          </Accordion>
        </>
      ) : null}
    </SidebarFilterList>
  );
};

export default FacetsFilterList;
