import { useEffect } from "react";
import { EnvsApi } from "../../services";
import { useEnvs, useShallow } from "../../state-manager";

const useInitializeAllConfigs = (envsApi: EnvsApi) => {
  const { allConfigs, setAllConfigs } = useEnvs(useShallow((state) => state));

  useEffect(() => {
    if (!allConfigs) {
      const handleSetAllEnvs = async () => {
        const { data = null } = await envsApi.getAllConfigs();
        setAllConfigs(data);
      };

      handleSetAllEnvs();
    }
  }, [allConfigs, envsApi, setAllConfigs]);
};

export { useInitializeAllConfigs };
