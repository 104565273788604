import styled from "styled-components";
import {
  CloseIcon as Close,
  DollarIcon as Dollar,
  HouseIcon as House,
  ProfileIcon as Profile,
  theme,
  ShoppingCartIcon,
  SecurityLockIcon,
  ShoppingBagIcon,
  ClientIcon,
  NewGiftIcon,
} from "ui";
import { Link } from "../../../../../../Link";

interface DropdownUserAuthenticatedContainerProps {
  isOpenedDropdownUser: boolean;
}

export const DropdownUserAuthenticatedContainer = styled.article<DropdownUserAuthenticatedContainerProps>`
  position: absolute;
  background: ${theme.colors.neutral.white} !important;
  width: 21.5rem;
  height: auto;
  margin-top: 34rem;
  margin-right: 12rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 6px 4px 15px 0px ${theme.colors.neutral["999-opacity-60"]};
  border-radius: 8px;

  visibility: ${({ isOpenedDropdownUser }) =>
    isOpenedDropdownUser ? "visible" : "hidden"};
  opacity: ${({ isOpenedDropdownUser }) => (isOpenedDropdownUser ? 1 : 0)};
  transition: all 0.4s;

  z-index: 99;

  @media ${theme.device.desktopFirst.laptop} {
    margin-right: 9.5rem;
  }
`;

export const DropdownUserAuthenticatedHeader = styled.header`
  position: absolute;
  top: -12px;
  left: calc(63%);
  display: block;
  width: 0;
  height: 0;
  z-index: 1;
  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  border-bottom: 1.2rem solid ${theme.colors.primary["350"]};
`;

export const CloseIcon = styled(Close).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
  height: "20px",
  width: "20px",
}))``;

export const CartIcon = styled(ShoppingCartIcon).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
}))``;

export const UserIcon = styled(ClientIcon).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
}))``;

export const DollarIcon = styled(Dollar).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
}))``;

export const SecurityIcon = styled(SecurityLockIcon).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
  height: "20px",
  width: "20px",
}))``;

export const GiftIcon = styled(NewGiftIcon).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
}))``;

export const HouseIcon = styled(House).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
}))``;

export const BagIcon = styled(ShoppingBagIcon).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
}))``;

export const ProfileIcon = styled(Profile).attrs(({ color }) => ({
  color: color ?? theme.colors.neutral["760"],
  height: "18px",
  width: "18px",
}))``;

type DropdownUserAuthenticatedWrapperLinkProps = {
  padding?: string;
  gap?: string;
};

export const DropdownUserAuthenticatedWrapperLink = styled(
  Link
)<DropdownUserAuthenticatedWrapperLinkProps>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: start !important;
  margin-right: 0 !important;
  gap: ${({ gap }) => (gap ? `${gap}` : "1rem")};
  padding: ${({ padding }) => (padding ? `${padding}` : "0.75rem")};
  color: ${theme.colors.neutral["550"]};
  transition: all 0.2s ease;
  font-size: ${theme.typography.fontSize.sm.x5};

  &:hover {
    color: ${theme.colors.neutral["999"]};
  }
`;

export const WrapperIcon = styled.span``;

export const DropdownTop = styled.div`
  height: 10px;
  border-radius: 8px 8px 0px 0px;
  background-color: ${theme.colors.primary["350"]};
`;
