import { buildTestIds, useNotification } from "application";
import { useTwoFactor } from "application/state-manager";
import { useShallow } from "application/state-manager/adapter";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  AtEmailIcon,
  CartIcon,
  PhoneCallIcon,
  ValidIcon,
  WarningIcon,
  theme,
} from "ui";
import { CodeExpiration } from "../../CodeExpiration";
import { SendCodeAgain } from "../../SendCodeAgain";
import { IncorrectCode } from "../styles";
import {
  Contact,
  InformationText,
  InputCodeContainer,
  Inputs,
  MessageError,
  MessageErrorWrapper,
  MessageSuccess,
  MoreInformation,
  SupportText,
  SupportWrapper,
  ValidationInput,
} from "./styles";

interface InputCodeProps {
  length: number;
  height?: string;
  width?: string;
  numberType: 1 | 2 | 3;
  typeItem?: "ResendCodeComponent" | "SendEmailCode";
  noTopSubtitle?: boolean;
  bottomSubtitle?: boolean;
}

const InputCode = ({
  length,
  height,
  width,
  numberType,
  typeItem,
  noTopSubtitle,
  bottomSubtitle,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
InputCodeProps) => {
  const {
    changeIsCodeValid,
    changeCodeHasError,
    changeIsUserBlocked,
    validateTwoFactorSMS,
  } = useNotification();

  const [countdown, setCountdown] = useState(0);

  const {
    setTokenCode,
    isTokenCodeValid,
    setIsTokenCodeValid,
    tokenCode,
    email,
    validateCode,
    setIsCodeComplete,
    clipboardText,
    setClipboardText,
    setValidateCode,
  } = useTwoFactor(
    useShallow((state) => ({
      setTokenCode: state.setTokenCode,
      tokenCode: state.tokenCode,
      isTokenCodeValid: state.isTokenCodeValid,
      email: state.email,
      validateCode: state.validateCode,
      setIsCodeComplete: state.setIsCodeComplete,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      clipboardText: state.clipboardText,
      setClipboardText: state.setClipboardText,
      setValidateCode: state.setValidateCode,
    }))
  );

  const [messageError, setMessageError] = useState("");
  const [validCode, setValidCode] = useState("");
  const [hasError, setHasError] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [disappearCountdown, setDisappearCountdown] = useState(false);
  const [codeIsValid, setCodeIsValid] = useState(false);
  const [isSendingCodeAgain, setIsSendingCodeAgain] = useState(false);

  useEffect(() => {
    changeCodeHasError(hasError);
    changeIsUserBlocked(isUserBlocked);
    changeIsCodeValid(codeIsValid);
  }, [
    isUserBlocked,
    hasError,
    codeIsValid,
    changeIsCodeValid,
    changeCodeHasError,
    changeIsUserBlocked,
  ]);

  const onSendCode = useCallback(
    async (type: 1 | 2 | 3, token: string) => {
      const { status, errors } = await validateTwoFactorSMS(type, token);

      if (
        errors?.ErrorCodes?.includes("555") &&
        !errors?.ErrorCodes?.includes("222")
      ) {
        setCodeSent(true);
        setHasError(true);
        setMessageError(errors?.Messages?.[0]);
        setValidateCode(true);
        return;
      }

      if (errors?.ErrorCodes?.includes("222")) {
        setCodeSent(true);
        setHasError(true);
        setMessageError(
          "Seus créditos foram bloqueados temporariamente devido a tentativas excessivas de validação do código de segurança. Entre em contato com o suporte para regularizar a situação."
        );
        setIsUserBlocked(true);
        return;
      }

      if (
        status?.toString()?.startsWith("4") ||
        status?.toString()?.startsWith("5")
      ) {
        setHasError(true);
        setCodeSent(true);
        setMessageError(
          errors?.Messages?.length === 2
            ? errors?.Messages?.[1]
            : errors?.Messages?.[0] || "Ocorreu um erro"
        );

        if (errors?.Messages?.[1]) {
          setIsUserBlocked(true);
        }

        return;
      }

      if (!errors?.Messages) {
        setValidCode("Código válido");
        setHasError(false);
        setCodeSent(true);
        setDisappearCountdown(true);
        setCodeIsValid(true);
        return;
      }

      setMessageError("Ocorreu um erro");
      setHasError(true);
      setCodeSent(true);
    },
    [validateTwoFactorSMS, setValidateCode]
  );

  const [code, setCode] = useState(Array(length).fill(""));
  const isCodeComplete = code.every((digit) => digit !== "");
  const inputs = useRef<HTMLInputElement[]>([]);

  const clearInputFields = useCallback(() => {
    const newPin = Array(length).fill("");
    setCode(newPin);
    inputs?.current[0]?.focus();
  }, [length]);

  const isIncorrectCode = !isTokenCodeValid && isCodeComplete && validateCode;

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (e?.key === "ArrowLeft" && index > 0) {
        inputs?.current[index - 1]?.focus();
      }

      if (e?.key === "ArrowRight" && index < length - 1) {
        inputs?.current[index + 1]?.focus();
      }

      if (e?.key === "Backspace") {
        const newPin = [...code];
        if (newPin[index]) {
          newPin[index] = "";
          setCode(newPin);
        } else if (index > 0) {
          newPin[index - 1] = "";
          setCode(newPin);
          inputs?.current[index - 1]?.focus();
        }
      }

      const value = e.key;
      if (/^\d$/.test(value)) {
        const newPin = [...code];
        newPin[index] = value;
        setCode(newPin);

        if (index < length - 1) {
          inputs?.current[index + 1]?.focus();
        }
      }
    },
    [length, code]
  );

  const codeReceived = code.toString().replace(/[^0-9]/g, "");

  useEffect(() => {
    if (codeReceived.length === 6 && typeItem === "SendEmailCode") {
      setTokenCode(codeReceived);
    }

    if (codeReceived.length < 6) {
      setIsTokenCodeValid(null);
    }
  }, [
    codeReceived,
    email,
    tokenCode,
    isCodeComplete,
    setTokenCode,
    typeItem,
    setIsTokenCodeValid,
  ]);

  useEffect(() => {
    if (codeReceived.length === 6 && typeItem !== "SendEmailCode") {
      onSendCode(numberType, codeReceived);
    }
  }, [codeReceived, numberType, onSendCode, typeItem]);

  const handleSendCodeAgain = useCallback(() => {
    setIsSendingCodeAgain(true);
  }, []);

  useEffect(() => {
    if (clipboardText) {
      const newCode = clipboardText
        .replace(/\D/g, "")
        .split("")
        .concat(Array(length).fill(""))
        .slice(0, length);
      setCode(newCode);
      setClipboardText("");
    }
  }, [clipboardText, length, setClipboardText]);

  useEffect(() => {
    setIsCodeComplete(isCodeComplete);
  }, [isCodeComplete, setIsCodeComplete]);

  useEffect(() => {
    if (countdown === 180) {
      setIsSendingCodeAgain(false);
    }
  }, [countdown]);

  return (
    <InputCodeContainer {...buildTestIds("input-code-container")}>
      {hasError && codeSent ? (
        <MessageErrorWrapper>
          <MessageError>
            {typeItem === "ResendCodeComponent" && isUserBlocked ? (
              <WarningIcon height={60} width={60} />
            ) : (
              <WarningIcon />
            )}
            {messageError}
          </MessageError>
        </MessageErrorWrapper>
      ) : codeSent && codeIsValid && !noTopSubtitle ? (
        <MessageSuccess>
          <ValidIcon />
          {validCode}
        </MessageSuccess>
      ) : null}
      <Inputs
        display={isUserBlocked ? "none" : "flex"}
        {...buildTestIds("inputs")}
      >
        {code.map((num, index) => {
          const key = index + 1;
          return (
            <ValidationInput
              key={key}
              height={height}
              width={width}
              role="textbox"
              disabled={codeIsValid}
              border={
                (hasError && codeSent) ||
                (validateCode && isTokenCodeValid === false && isCodeComplete)
                  ? `2px solid ${theme.colors.primary["150"]}`
                  : codeSent || isTokenCodeValid
                  ? `2px solid ${theme.colors.secondary["380"]} `
                  : `1px solid ${theme.colors.neutral["240"]}`
              }
              type="tel"
              maxLength={1}
              value={num}
              onFocus={() => {
                if (isIncorrectCode) {
                  clearInputFields();
                }
              }}
              onChange={() => []}
              autoFocus={!code[0].length && index === 0}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(ref) => inputs.current.push(ref as HTMLInputElement)}
            />
          );
        })}
      </Inputs>
      {typeItem === "ResendCodeComponent" ? (
        <Inputs display={isUserBlocked || disappearCountdown ? "none" : "flex"}>
          <CodeExpiration
            codeCountdown={(time) => setCountdown(time)}
            resetTime={isSendingCodeAgain}
          />
          {countdown === 0 ? (
            <SendCodeAgain
              color={`${theme.colors.primary["150"]}`}
              typeSMS={numberType}
              onSendCodeAgain={handleSendCodeAgain}
            />
          ) : null}
        </Inputs>
      ) : null}
      {isUserBlocked ? (
        <>
          <SupportWrapper>
            <Contact>
              <PhoneCallIcon />
              <SupportText>3338-8333</SupportText>
            </Contact>
            <Contact>
              <AtEmailIcon />
              <SupportText>sac@ferreiracosta.com.br</SupportText>
            </Contact>
          </SupportWrapper>
          <MoreInformation>
            <CartIcon
              height={45}
              width={45}
              strokeWidth="2"
              color={`${theme.colors.secondary["380"]}`}
            />
            <InformationText>
              Você ainda pode finalizar sua compra com cartão de crédito ou PIX.
            </InformationText>
          </MoreInformation>
        </>
      ) : null}
      {bottomSubtitle && isIncorrectCode && (
        <IncorrectCode>Código incorreto</IncorrectCode>
      )}
    </InputCodeContainer>
  );
};

export { InputCode };
