import { IconProps } from "typing";
import { theme } from "../themes";

export const ArrowDownSolid = ({
  width = 8,
  height = 7,
  alt = "Seta direcionada para baixo",
  color = theme.colors.primary["200"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 8 7"
      fill="none"
    >
      <path d="M4.00001 6.83317L0 0.166504H8L4.00001 6.83317Z" fill={color} />
    </svg>
  );
};
