import { CloseIcon, theme } from "ui";
import { ReactNode } from "react";
import {
  ButtonCLoseModal,
  ModalValidationContent,
  ModalValidationSMSContainer,
  ModalValidationSMSHeader,
  ModalValidationSMSWrapper,
  ModalValidationText,
  ModalValidationTitle,
} from "./styles";

interface ModalValidationSMSProps {
  actionCloseModal: () => void;
  title: string;
  description: string;
  children?: ReactNode;
  stateModal: boolean;
}
const ModalValidationSMS = ({
  actionCloseModal,
  title,
  description,
  children,
  stateModal,
}: ModalValidationSMSProps) => {
  return (
    <ModalValidationSMSContainer stateModal={stateModal}>
      <ModalValidationSMSWrapper>
        <ModalValidationSMSHeader>
          <ModalValidationTitle>{title}</ModalValidationTitle>
          <ButtonCLoseModal onClick={actionCloseModal}>
            <CloseIcon
              width={50}
              height={38}
              strokeWidth="0.6"
              color={`${theme.colors.primary["200"]}`}
            />
          </ButtonCLoseModal>
        </ModalValidationSMSHeader>
        <ModalValidationContent>
          <ModalValidationText>{description}</ModalValidationText>
          {children}
        </ModalValidationContent>
      </ModalValidationSMSWrapper>
    </ModalValidationSMSContainer>
  );
};

export default ModalValidationSMS;
