import { IIdGenerator } from "app-domain";
import { v4 as uuidv4 } from "uuid";

const UUIDLib: IIdGenerator = {
  generateUUID: () => {
    return uuidv4();
  },
};

export { UUIDLib };
