import { IconProps } from "typing";
import { theme } from "../themes";

export const PaymentCancelledIcon = ({
  color = theme.colors.primary["155"],
  width = 21,
  height = 20,
  strokeWidth = "1.5",
  alt = "Pagamento cancelado",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      aria-label={alt}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 14.1667H7.16667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1667 16.6666H4.66667C3.74583 16.6666 3 15.9208 3 14.9999V7.14075C3 6.91575 3.04583 6.69242 3.13417 6.48575L4.04917 4.34492C4.31167 3.73159 4.91417 3.33325 5.58167 3.33325H13.7517C14.4192 3.33325 15.0217 3.73159 15.2842 4.34492L16.1992 6.48575C16.2875 6.69325 16.3333 6.91575 16.3333 7.14075V10.8333"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66667 3.33325V7.03325"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.325 7.03337H3"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0234 11.8096C18.3251 13.1113 18.3251 15.2219 17.0234 16.5236C15.7216 17.8254 13.6111 17.8254 12.3093 16.5236C11.0076 15.2219 11.0076 13.1113 12.3093 11.8096C13.6111 10.5078 15.7216 10.5078 17.0234 11.8096Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8 11.9317L12.4316 16.3"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
