import { EnvsApi, useRecaptcha } from "application";
import { useCallback, useEffect } from "react";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AwsWafCaptcha: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AwsWafIntegration: any;
  }
}

interface CaptchaError extends Error {
  kind: "internal_error" | "network_error" | "token_error" | "client_error";
  statusCode?: number;
}

const AwsWafRecaptcha = (envsApi: EnvsApi) => {
  const {
    handleCheckIfTheCustomerIsNotARobotSignUp,
    handleCheckIfTheCustomerIsNotARobotSignIn,
  } = useRecaptcha();

  const onSuccess = useCallback(
    async (wafToken: string) => {
      const { data: wafProtectedUrl } = (await envsApi?.getEnv(
        "AWS_WAF_JS_API_URL"
      )) || {
        data: "",
      };

      window?.AwsWafIntegration?.fetch(wafProtectedUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (wafToken) {
        handleCheckIfTheCustomerIsNotARobotSignUp(false);
        handleCheckIfTheCustomerIsNotARobotSignIn(false);
      }
    },
    [
      envsApi,
      handleCheckIfTheCustomerIsNotARobotSignIn,
      handleCheckIfTheCustomerIsNotARobotSignUp,
    ]
  );

  const onError = useCallback(
    (error: CaptchaError) => {
      if (error) {
        handleCheckIfTheCustomerIsNotARobotSignUp(true);
        handleCheckIfTheCustomerIsNotARobotSignIn(true);
      }
    },
    [
      handleCheckIfTheCustomerIsNotARobotSignIn,
      handleCheckIfTheCustomerIsNotARobotSignUp,
    ]
  );

  const renderAwsWafCaptcha = useCallback(
    async (usedEnvsApi: EnvsApi) => {
      const container = document.querySelector("#aws-captcha-container");

      const { data: apiKey } = (await usedEnvsApi?.getEnv(
        "AWS_WAF_CAPTCHA"
      )) || {
        data: "",
      };

      window?.AwsWafCaptcha?.renderCaptcha(container, {
        apiKey,
        onSuccess,
        onError,
        dynamicWidth: true,
        skipTitle: true,
      });
    },
    [onError, onSuccess]
  );

  useEffect(() => {
    renderAwsWafCaptcha(envsApi);
  }, [envsApi, renderAwsWafCaptcha]);

  return <div id="aws-captcha-container" />;
};

export { AwsWafRecaptcha };
