import styled, { css } from "styled-components";
import { FAQThemeDto } from "typing";
import { theme } from "ui";

type BlackFridayTheme = {
  faqBlackTheme?: FAQThemeDto;
};

interface CustomFAQTextProps extends BlackFridayTheme {
  alignSelf: "center" | "flex-start" | "flex-end";
}

export const CustomFAQContainer = styled.section<BlackFridayTheme>`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;

  @media ${theme.device.desktopFirst.laptop} {
    width: 94vw;
  }

  ${({ faqBlackTheme }) =>
    faqBlackTheme &&
    css`
      background-color: transparent;
      border: none;
    `}
`;

export const CustomFAQHeader = styled.div``;

export const CustomFAQTitle = styled.h3<CustomFAQTextProps>`
  align-self: ${({ alignSelf }) => alignSelf};
  color: ${({ faqBlackTheme }) =>
    faqBlackTheme
      ? theme.colors.blackFriday.white
      : `${theme.colors.neutral["520"]}`};
  font-size: ${theme.typography.fontSize.md.x3};
  font-weight: ${theme.typography.fontWeight["400"]};
  margin: ${({ alignSelf }) =>
    `${theme.space.x0} ${
      alignSelf === "center" ? theme.space.x0 : theme.space.x4
    }`};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x9};
  }
`;

export const CustomFAQSubTitle = styled.h4<CustomFAQTextProps>`
  align-self: ${({ alignSelf }) => alignSelf};
  color: ${({ faqBlackTheme }) =>
    faqBlackTheme
      ? theme.colors.blackFriday.white
      : `${theme.colors.neutral["520"]}`};
  font-size: ${theme.typography.fontSize.sm.x9};
  font-weight: ${theme.typography.fontWeight["400"]};
  margin: ${({ alignSelf }) =>
    `${theme.space.x0} ${
      alignSelf === "center" ? theme.space.x0 : theme.space.x4
    }`};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x7};
  }
`;
