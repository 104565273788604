import { IResponsiveModalActions, IResponsiveModalContent } from "typing";
import { ResponsiveComponent } from "./components";
import { InputProps } from "../Form/Input";

const ResponsiveModal = ({
  ...props
}: IResponsiveModalActions & IResponsiveModalContent & InputProps) => {
  return <ResponsiveComponent {...props} />;
};

export { ResponsiveModal };
