import { NewAddressProps } from "typing";
import { ArrowLeft, CloseIcon } from "ui";
import {
  AddressHeaderContainer,
  AddressHeaderTitle,
  CloseButton,
} from "../../styles";

interface AddressHeaderProps {
  onCloseModal?: () => void;
  newAddressData?: NewAddressProps;
  isClientMobile: boolean;
}

export const AddressHeader = ({
  isClientMobile,
  newAddressData,
  onCloseModal,
}: AddressHeaderProps) => {
  return (
    <AddressHeaderContainer>
      {isClientMobile ? (
        <CloseButton onClick={onCloseModal}>
          <ArrowLeft />
        </CloseButton>
      ) : null}
      <AddressHeaderTitle>
        {newAddressData ? "Alterar endereço" : "Adicionar novo endereço"}
      </AddressHeaderTitle>
      {!isClientMobile ? (
        <CloseButton onClick={onCloseModal}>
          <CloseIcon />
        </CloseButton>
      ) : null}
    </AddressHeaderContainer>
  );
};
