import { buildTestIds, useAppContext, useCurrentPathName } from "application";
import { appMonitoringClient } from "implementations";
import { useRouter } from "next/router";
import { CustomProductDto } from "typing";
import {
  DiscountContainer,
  DiscountPercentageContainer,
  DiscountTagDot,
  DiscountTagText,
  OldPrice,
  PriceInstallments,
  PriceMainContainer,
  PriceValue,
  PriceValueText,
  SpotPriceText,
  Text,
  theme,
} from "ui";
import { Image } from "../../Image";
import { Link } from "../../Link";
import { useGetBranchLinkConfig } from "../utils";
import { getCustomProductStyles } from "./data";
import {
  BoxTags,
  CustomCartIcon,
  CustomProductInnerContainer,
  ProductBuyButton,
  ProductDetailsContainer,
  ProductImageContainer,
  ProductTitle,
  TagCampaignBoxProduct,
  TagProductPriceOnlySiteContainer,
  TagProductPriceOnlySiteText,
  TagProductWrapper,
  TagTextCampaignBoxProduct,
} from "./style";
import { getProductUtils, hasProductCardAllInfosToShow } from "./utils";

const CustomProduct = ({
  config,
  isClientMobile,
  isClientTablet,
  isinsidecarousel,
  position,
  productCards,
  priority = false,
}: CustomProductDto) => {
  const linkConfig = useGetBranchLinkConfig(
    config?.branches,
    "subComponentProduct"
  );

  const router = useRouter();

  const { isClientTabletM } = useAppContext();

  const currentPathName = useCurrentPathName();

  const productCard = productCards?.[0];

  if (!config?.branches?.length) {
    appMonitoringClient.captureMessage(
      `Um subComponente do tipo CustomProduct, cujo id é "${linkConfig?.id}", localizado em "${currentPathName}", na posição ${position}, não possui nenhuma configuração de branches.`,
      {
        level: "warning",
        tags: {
          fcx_labs_error_source: "workspace",
        },
      }
    );
  }

  if (!hasProductCardAllInfosToShow(productCard)) {
    appMonitoringClient.captureMessage(
      `Um subComponente do tipo CustomProduct, cujo id é "${linkConfig?.id}", localizado em "${currentPathName}", na posição ${position}, não possui as informações necessárias sobre o produto (productCards) e não será exibido por completo para o usuário.`,
      {
        level: "error",
        tags: {
          fcx_labs_error_source: "workspace",
        },
      }
    );
  }

  const productUtils = getProductUtils(productCard);

  if (!productUtils) {
    return null;
  }

  const {
    description,
    discountPercentage,
    priceList,
    installmentsText,
    finalPrice,
    imageUrl,
    salePrice,
  } = productUtils;

  const { discountTagTextFontSize, priceValueGap } = getCustomProductStyles(
    !!isClientTablet
  );

  return (
    <CustomProductInnerContainer
      as={Link}
      href={`/${linkConfig?.url}`}
      target={linkConfig?.target}
      {...buildTestIds("custom-product-inner-container")}
    >
      <ProductImageContainer
        {...buildTestIds("product-image-container")}
        isinsidecarousel={isinsidecarousel}
      >
        <Image
          src={String(imageUrl)}
          alt="Imagem do produto"
          width="100%"
          height="100%"
          layout="responsive"
          priority={priority}
          {...buildTestIds("product-image")}
        />
      </ProductImageContainer>

      <ProductDetailsContainer {...buildTestIds("product-details-container")}>
        <TagProductWrapper {...buildTestIds("tag-product-wrapper")}>
          {productCard?.isBlackFriday && (
            <BoxTags {...buildTestIds("box-tags-black-friday")}>
              <TagCampaignBoxProduct
                backgroundColor={theme.colors.blackFriday["125"]}
                borderColor={theme.colors.blackFriday["125"]}
                {...buildTestIds("tag-campaign-box-product")}
              >
                <TagTextCampaignBoxProduct
                  {...buildTestIds("tag-text-campaign-box-product")}
                >
                  {isClientMobile ? "Black Friday" : "Oferta Black Friday"}
                </TagTextCampaignBoxProduct>
              </TagCampaignBoxProduct>
            </BoxTags>
          )}
          {productCard?.tagProduct?.title && !isClientTabletM ? (
            <BoxTags {...buildTestIds("box-tags-price-only-site")}>
              <TagProductPriceOnlySiteContainer
                {...buildTestIds("tag-product-price-only-site-container")}
              >
                <TagProductPriceOnlySiteText
                  {...buildTestIds("tag-product-only-site-text")}
                >
                  {`${productCard?.tagProduct?.title?.toLocaleLowerCase()}`}
                </TagProductPriceOnlySiteText>
              </TagProductPriceOnlySiteContainer>
            </BoxTags>
          ) : null}
        </TagProductWrapper>

        <ProductTitle {...buildTestIds("product-description")}>
          {description}
        </ProductTitle>

        <PriceMainContainer
          {...buildTestIds("price-main-container")}
          childrenQuantity={discountPercentage ? 2 : 1}
        >
          {discountPercentage ? (
            <DiscountContainer {...buildTestIds("discount-container")}>
              <OldPrice
                {...buildTestIds("old-price")}
                marginRight="0px"
                fontSizeDiscount={discountTagTextFontSize}
              >
                {priceList}
              </OldPrice>

              <DiscountPercentageContainer
                {...buildTestIds("discount-percentage-container")}
              >
                <DiscountTagDot />
                <DiscountTagText
                  {...buildTestIds("discount-tag-text")}
                  fontSizeDiscount={discountTagTextFontSize}
                >
                  {discountPercentage}% OFF
                </DiscountTagText>
              </DiscountPercentageContainer>
            </DiscountContainer>
          ) : null}

          <PriceValue
            isFlexDirectionColumn={isClientMobile}
            display="flex"
            gap={priceValueGap}
            fontSize="40px"
            fontSizeLaptop="36px"
            margin="0px"
            fontSizeTablet="32px"
            fontSizeMobileLarge="24px"
            fontSizeMobile="21px"
            {...buildTestIds("price-value")}
          >
            <PriceValueText {...buildTestIds("price-value-text")}>
              {finalPrice?.value || salePrice}
            </PriceValueText>
            {finalPrice?.text ? (
              <SpotPriceText
                {...buildTestIds("spot-price-text")}
              >{`${finalPrice.text}`}</SpotPriceText>
            ) : null}
          </PriceValue>
        </PriceMainContainer>

        <PriceInstallments
          {...buildTestIds("price-installments")}
          color={theme.colors.blackFriday["210"]}
        >
          {installmentsText}
        </PriceInstallments>

        {isClientTabletM && productCard?.tagProduct?.title ? (
          <BoxTags {...buildTestIds("box-tags-price-only-site-mobile")}>
            <TagProductPriceOnlySiteContainer
              {...buildTestIds("tag-product-only-site-container-mobile")}
            >
              <TagProductPriceOnlySiteText
                {...buildTestIds("product-price-only-site-text-mobile")}
              >
                {productCard?.tagProduct?.title || ""}
              </TagProductPriceOnlySiteText>
            </TagProductPriceOnlySiteContainer>
          </BoxTags>
        ) : null}

        <ProductBuyButton
          onClick={() => {
            return linkConfig?.target === "_blank"
              ? window.open(`/${linkConfig?.url}`, "_blank")
              : router?.push(`/${linkConfig?.url}`);
          }}
          rounded
          backgroundColor={theme.colors.secondary["310"]}
          mouseOverColor={theme.colors.secondary["500"]}
          color={theme.colors.neutral.white}
          width="181px"
          height="50px"
          {...buildTestIds("product-buy-button")}
        >
          <CustomCartIcon />
          <Text
            {...buildTestIds("product-buy-button-text")}
            fontSize={theme.typography.fontSize.sm.x7}
            fontWeight={theme.typography.fontWeight["500"]}
          >
            Comprar
          </Text>
        </ProductBuyButton>
      </ProductDetailsContainer>
    </CustomProductInnerContainer>
  );
};

export { CustomProduct };
