import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ReadyForPickupTrackingStatusIcon = ({
  width = 32,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Retirada Disponível",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <path
        d="M8.49683 16V12.7819C8.49683 12.5285 8.54855 12.2778 8.64856 12.045L9.67815 9.63563C9.97343 8.94507 10.652 8.49707 11.403 8.49683H20.5977C21.3487 8.49729 22.0271 8.94522 22.3226 9.63563L23.3522 12.045C23.4516 12.278 23.5031 12.5286 23.5031 12.7819V21.6273C23.5031 22.6633 22.6633 23.5031 21.6273 23.5031H18.501"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5811 19.9057L12.12 17.3872C12.3832 16.9591 12.8983 16.7574 13.3822 16.8929C13.8661 17.0283 14.2016 17.4682 14.2042 17.9707V19.3005H15.4631C15.8414 19.3006 16.1945 19.4903 16.4035 19.8057C16.5895 20.0853 16.6434 20.4324 16.551 20.7552L15.9408 22.8911C15.8375 23.2512 15.5084 23.4994 15.1338 23.4997H12.15C11.9065 23.5 11.6706 23.4151 11.4831 23.2596L10.5811 22.5093"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1642 23.5032H8.91367C8.68345 23.5032 8.49683 23.3166 8.49683 23.0864V19.3348C8.49683 19.1046 8.68345 18.918 8.91367 18.918H10.1642C10.3944 18.918 10.581 19.1046 10.581 19.3348V23.0864C10.581 23.3166 10.3944 23.5032 10.1642 23.5032Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0001 12.6652V8.49683"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3347 20.1683H20.1684"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49683 16V14.3326C8.49683 13.4118 9.24333 12.6653 10.1642 12.6653H21.8357C22.7566 12.6653 23.5031 13.4118 23.5031 14.3326V20.1684"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
