import styled, { css } from "styled-components";
import { DefaultProductImage, FlexContainer } from "ui";
import { theme } from "ui/themes";

interface BoxImageProps {
  isClientMobile: boolean;
}

export const SectionImages = styled.div`
  max-height: 60rem;

  @media ${theme.device.desktopFirst.tablet} {
    order: 2;
    height: fit-content;
    /* overflow: auto; */
  }
`;

interface MainImageProps {
  isVideo?: boolean;
}

export const MainImage = styled.div<MainImageProps>`
  display: flex;
  padding-top: 1rem;
  justify-content: center;
  padding-bottom: 1rem;
  height: fit-content;
  width: 100%;
  cursor: zoom-in;
  overflow: hidden;

  ${({ isVideo }) =>
    isVideo &&
    css`
      aspect-ratio: 1;
    `}

  @media ${theme.device.desktopFirst.tablet} {
    height: fit-content;
    padding-top: 0rem;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    height: auto;
  }
`;

export const ImageProductContainer = styled(FlexContainer)`
  justify-content: center;
  @media ${theme.device.desktopFirst.tablet} {
    flex-direction: column;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageProductDefault = styled(DefaultProductImage).attrs(
  ({ isClientMobile }: BoxImageProps) => ({
    width: isClientMobile ? "200" : "280",

    height: isClientMobile ? "200" : "280",
  })
)<BoxImageProps>``;
