import { IconProps } from "typing";

export const CallIcon = ({ alt, color, height, width }: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.287 22.147L23.723 20.5829C22.9417 19.8016 21.675 19.8016 20.895 20.5829L19.6657 21.8123C19.391 22.0869 18.975 22.1789 18.6204 22.0229C16.839 21.2429 15.079 20.0603 13.5097 18.4909C11.947 16.9283 10.7684 15.1763 9.98702 13.4016C9.82436 13.0349 9.91902 12.6043 10.203 12.3203L11.3044 11.219C12.199 10.3243 12.199 9.05895 11.4177 8.27762L9.85369 6.71362C8.81236 5.67228 7.12436 5.67228 6.08303 6.71362L5.21369 7.58162C4.22569 8.56962 3.81369 9.99495 4.08036 11.4083C4.73903 14.8923 6.76302 18.7069 10.0284 21.9723C13.2937 25.2376 17.1084 27.2616 20.5924 27.9203C22.0057 28.1869 23.431 27.7749 24.419 26.7869L25.287 25.9189C26.3284 24.8776 26.3284 23.1896 25.287 22.147V22.147Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.334 9.32178C18.7087 9.30312 20.09 9.81245 21.1393 10.8618"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.91 7.09082C22.818 4.99882 20.0753 3.95215 17.334 3.95215"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6787 14.6667C22.6973 13.292 22.188 11.9107 21.1387 10.8613"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9102 7.09082C27.0022 9.18282 28.0488 11.9255 28.0488 14.6668"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
