import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const TrackingStatusRescheduledOrder = ({
  width = 36,
  height = 32,
  color = theme.colors.yellow["170"],
  secondColor = theme.colors.neutral.white,
  alt = "Entrega reprogramada",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M15.4987 23.5031H12.3724C11.3364 23.5031 10.4966 22.6633 10.4966 21.6273V12.7819C10.4966 12.5286 10.548 12.278 10.6475 12.045L11.6771 9.63563C11.9725 8.94522 12.651 8.49729 13.402 8.49683H22.5966C23.3477 8.49707 24.0262 8.94507 24.3215 9.63563L25.3511 12.045C25.4511 12.2778 25.5028 12.5285 25.5028 12.7819V16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9998 8.49683V12.6652"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8315 20.1683H15.4989"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5028 14.3326C25.5028 13.4118 24.7563 12.6653 23.8355 12.6653H12.1639C11.2431 12.6653 10.4966 13.4118 10.4966 14.3326"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5804 17.338L26.1198 21.8507C26.4106 22.3666 26.4058 22.9981 26.1073 23.5097C25.8089 24.0212 25.2614 24.336 24.6692 24.3367H19.5879C18.9954 24.3367 18.4474 24.0224 18.1484 23.511C17.8494 22.9995 17.8442 22.3678 18.1348 21.8515L20.6742 17.338C20.9695 16.8131 21.525 16.4883 22.1273 16.4883C22.7295 16.4883 23.285 16.8131 23.5804 17.338Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1273 20.5186V18.9888"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1265 22.2067C22.1064 22.2067 22.0872 22.2147 22.0731 22.229C22.059 22.2432 22.0512 22.2625 22.0514 22.2826C22.0514 22.3133 22.07 22.341 22.0985 22.3527C22.1269 22.3644 22.1596 22.3578 22.1812 22.3359C22.2029 22.314 22.2091 22.2813 22.1971 22.253C22.1851 22.2246 22.1572 22.2064 22.1265 22.2067"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
