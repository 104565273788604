import { memo } from "react";
import { CustomCountdownDto } from "typing";
import {
  buildTestIds,
  formatCountdownTimer,
  useAppContext,
  useCurrentPathName,
} from "application";
import { customCountdownData, handleComponentWarning } from "./data";
import {
  CustomCountdownContainer,
  CustomCountdownHeader,
  CustomCountdownSubTitle,
  CustomCountdownTitle,
  CustomCountdownWrapper,
  TimerContainer,
} from "./styles";
import { useGetBranchLinkConfig } from "../utils";
import { Link } from "../../Link";
import { Countdown } from "./Countdown";
import { RenderCountdownHeader } from "./Header";

const CustomCountdown = memo(
  ({ properties, isClientMobile, config, position }: CustomCountdownDto) => {
    const {
      showTitle,
      title,
      titleColor,
      showSubTitle,
      subTitle,
      subTitleColor,
      desktopImage,
      mobileImage,
      format,
      showSeparator,
      showUnits,
      separatorColor,
      date,
      backgroundColor,
      backgroundType,
      dateColor,
    } = customCountdownData(properties);

    const linkConfig = useGetBranchLinkConfig(config?.branches, "countdown");

    const { isClientLaptop } = useAppContext();

    handleComponentWarning(config?.branches, useCurrentPathName(), position);

    const hasImage =
      (!isClientMobile && desktopImage) || (isClientMobile && mobileImage);
    const hasBackground = backgroundType === "solidColor";
    const formatSmall = format && format === "small";
    const showSubTitleTrue = showSubTitle && showSubTitle === "true";
    const showTitleTrue = showTitle && showTitle === "true";
    const headerComplete = showSubTitleTrue && showTitleTrue;

    if (
      date &&
      formatCountdownTimer(date).days === 0 &&
      formatCountdownTimer(date).minutes === 0 &&
      formatCountdownTimer(date).hours === 0 &&
      formatCountdownTimer(date).seconds === 0
    ) {
      return null;
    }

    const backgroundImage = isClientMobile ? mobileImage : desktopImage;
    const conditionForBackgroundImage =
      backgroundType === "image" ? backgroundImage : null;
    const backgroundColorCondition = hasBackground ? backgroundColor : null;

    return (
      <CustomCountdownContainer {...buildTestIds("countdown-main-container")}>
        <CustomCountdownWrapper
          backgroundColor={backgroundColorCondition}
          backgroundImage={conditionForBackgroundImage}
          isSmall={formatSmall}
          as={linkConfig?.url ? Link : CustomCountdownWrapper}
          href={`/${linkConfig?.url}`}
          target={linkConfig?.target}
          isComplete={headerComplete}
          {...buildTestIds("countdown-wrapper")}
          isClientLaptop={isClientLaptop}
        >
          <RenderCountdownHeader
            format={format}
            showTitle={showTitle}
            showSubTitle={showSubTitle}
            title={title}
            subTitle={subTitle}
            subTitleColor={subTitleColor}
            titleColor={titleColor}
          />

          {showTitleTrue && formatSmall && !isClientMobile && (
            <CustomCountdownHeader
              isSmall={formatSmall}
              {...buildTestIds("countdown-header-title-container-format-small")}
            >
              <CustomCountdownTitle
                color={titleColor}
                {...buildTestIds("countdown-header-title-small")}
              >
                {title}
              </CustomCountdownTitle>
            </CustomCountdownHeader>
          )}

          <TimerContainer
            isSmall={formatSmall}
            {...buildTestIds("countdown-timer-container")}
          >
            <Countdown
              hasImage={hasImage}
              dateColor={dateColor}
              date={date}
              backgroundType={backgroundType}
              hasBackground={hasBackground}
              formatSmall={formatSmall}
              separatorColor={separatorColor}
              showSeparator={showSeparator}
              showUnits={showUnits}
            />
          </TimerContainer>

          {showSubTitleTrue && formatSmall && !isClientMobile && (
            <CustomCountdownHeader
              {...buildTestIds(
                "countdown-header-subtitle-container-format-small"
              )}
              isSmall={formatSmall}
            >
              <CustomCountdownSubTitle
                {...buildTestIds("countdown-header-subtitle-small")}
                color={subTitleColor}
              >
                {subTitle}
              </CustomCountdownSubTitle>
            </CustomCountdownHeader>
          )}
        </CustomCountdownWrapper>
      </CustomCountdownContainer>
    );
  }
);

CustomCountdown.displayName = "customCountdown";

export { CustomCountdown };
