export const PaymentMethods = () => {
  return (
    <svg
      aria-label="Métodos de pagamento"
      aria-labelledby="Métodos de pagamento"
      xmlns="https://www.w3.org/2000/svg"
      width={185.605}
      height={17.523}
      viewBox="0 0 185.605 17.523"
    >
      <defs>
        <style>
          {
            ".a,.j{fill:#fff;}.b{fill:#006fcf;}.c{fill:#fbc707;}.d{fill:#38a7e4;}.e{fill:#ef3120;}.f{fill:#231f20;}.g{fill:#b3131b;}.h{fill:#e85400;}.h,.i,.j{fill-rule:evenodd;}.i{fill:#ffe000;}.k{fill:#f79f1a;}.l{fill:#ea001b;}.m{fill:#ff5f01;}.n{fill:#3c58bf;}.o{fill:#293688;}.p{fill:#ffbc00;}.q{fill:#f7981d;}.r{fill:#ed7c00;}.s{fill:#051244;}"
          }
        </style>
      </defs>
      <g transform="translate(-518.971 8.853)">
        <g transform="translate(420.133 -106.358)">
          <path className="a" d="M115.133,115.021H98.838V98.726h16.295Z" />
          <path
            className="b"
            d="M115.133,107.769V98.95H98.838v16.295h16.295v-4.754c-.036,0,0-2.722,0-2.722"
            transform="translate(0 -0.216)"
          />
          <path
            className="a"
            d="M113.137,257.325h1.234v-2.867h-1.343v.4l-.254-.4h-1.161v.508l-.218-.508h-2.141c-.073,0-.145.036-.218.036s-.109.036-.181.073-.109.036-.181.073v-.181H102.54l-.181.472-.181-.472h-1.452v.508l-.218-.508H99.346l-.508,1.234v1.633h.835l.145-.4h.29l.145.4h6.387v-.363l.254.363h1.778v-.218c.036.036.109.036.145.073s.109.036.145.073a.46.46,0,0,0,.218.036h1.306l.145-.4h.29l.145.4h1.778v-.363Zm2,3.629v-2.686h-9.98l-.254.363-.254-.363h-2.9v2.867h2.9l.254-.363.254.363h1.815v-.617H106.9a1.758,1.758,0,0,0,.653-.109v.762h1.306v-.363l.254.363h5.407A3.09,3.09,0,0,0,115.133,260.954Z"
            transform="translate(0 -150.68)"
          />
          <path
            className="b"
            d="M114.516,274.337h-.98v.4h.944a.6.6,0,0,0,.653-.617.508.508,0,0,0-.581-.544h-.436a.181.181,0,0,1,0-.363h.835l.181-.4h-.98a.594.594,0,0,0-.653.581.508.508,0,0,0,.581.544h.436a.171.171,0,0,1,.181.181C114.734,274.264,114.661,274.337,114.516,274.337Zm-1.778,0h-.98v.4h.944a.6.6,0,0,0,.653-.617.508.508,0,0,0-.581-.544h-.436a.181.181,0,0,1,0-.363h.835l.181-.4h-.98a.594.594,0,0,0-.653.581.508.508,0,0,0,.581.544h.436a.171.171,0,0,1,.181.181A.169.169,0,0,1,112.738,274.337Zm-1.27-1.161v-.4h-1.524V274.7h1.524v-.4h-1.089v-.4h1.052v-.4h-1.052v-.363h1.089Zm-2.468,0c.181,0,.254.109.254.218s-.073.218-.254.218h-.544v-.472Zm-.544.835h.218l.581.69h.544l-.653-.726a.582.582,0,0,0,.508-.581.6.6,0,0,0-.653-.617h-1.016V274.7h.436Zm-1.161-.581a.229.229,0,0,1-.254.254h-.581v-.508H107A.259.259,0,0,1,107.294,273.429Zm-1.27-.653V274.7h.436v-.653h.581a.639.639,0,0,0,.69-.653.629.629,0,0,0-.653-.653Zm-.653,1.923h.544l-.762-.98.762-.944h-.544l-.472.617-.472-.617h-.544l.762.944-.762.944h.544l.472-.617Zm-1.633-1.524v-.4h-1.524V274.7h1.524v-.4h-1.089v-.4H103.7v-.4h-1.052v-.363h1.089Zm8.819-3.411.762,1.161h.544V269h-.436v1.27l-.109-.181-.69-1.089h-.581v1.923h.436v-1.306Zm-1.887-.036.145-.4.145.4.181.435h-.653Zm.762,1.2h.472L111.069,269h-.581l-.835,1.923h.472l.181-.4h.944Zm-2.032,0,.181-.4h-.109a.487.487,0,0,1-.508-.544v-.036a.487.487,0,0,1,.508-.544h.472V269h-.508a.867.867,0,0,0-.907.944v.036A.841.841,0,0,0,109.4,270.925Zm-1.633,0h.435v-1.887h-.435Zm-.944-1.524c.181,0,.254.109.254.218s-.073.218-.254.218h-.544v-.472Zm-.544.835h.218l.581.69h.544l-.653-.726a.582.582,0,0,0,.508-.581.6.6,0,0,0-.653-.617h-1.016v1.923h.435Zm-.8-.835V269h-1.524v1.923h1.524v-.4h-1.089v-.4h1.052v-.4h-1.052v-.363h1.089Zm-3.3,1.524h.4l.544-1.561v1.561h.435V269h-.726l-.435,1.306L101.959,269h-.726v1.923h.435v-1.561Zm-2.359-1.2.145-.4.145.4.181.435h-.653Zm.762,1.2h.472L100.217,269h-.544l-.835,1.923h.472l.181-.4h.944Z"
            transform="translate(0 -164.752)"
          />
        </g>
        <g transform="translate(113.478 476.008)">
          <g transform="translate(435.492 -480.027)">
            <path
              className="c"
              d="M-4.028-13.635a2.663,2.663,0,0,1,.847-.137A2.671,2.671,0,0,1-.564-11.635l1.831-.374A4.54,4.54,0,0,0-3.181-15.64a4.526,4.526,0,0,0-1.439.233Z"
              transform="translate(4.62 15.64)"
            />
          </g>
          <g transform="translate(432.392 -478.888)">
            <path
              className="d"
              d="M-10.416-46.228l1.238-1.4a2.665,2.665,0,0,1-.9-2,2.663,2.663,0,0,1,.9-2l-1.238-1.4a4.528,4.528,0,0,0-1.531,3.4,4.529,4.529,0,0,0,1.532,3.4"
              transform="translate(11.948 53.027)"
            />
          </g>
          <g transform="translate(435.491 -474.953)">
            <path
              className="e"
              d="M-27.583,0A2.67,2.67,0,0,1-30.2,2.134,2.65,2.65,0,0,1-31.047,2l-.593,1.772A4.53,4.53,0,0,0-30.2,4,4.539,4.539,0,0,0-25.752.375Z"
              transform="translate(31.64)"
            />
          </g>
          <g transform="translate(442.558 -477.643)">
            <path
              className="f"
              d="M-27.742-24.89v0l-.052-.036a1.466,1.466,0,0,1-.679.568,1.319,1.319,0,0,1-1.456-.146l-.034.052h0l-.638.954a2.736,2.736,0,0,0,.507.278,2.641,2.641,0,0,0,2.135-.03,2.626,2.626,0,0,0,1.192-.992Zm-1.99-2.353a1.379,1.379,0,0,0-.836,1.464l2.512-1.082a1.333,1.333,0,0,0-1.677-.382M-31.4-24.338a3.217,3.217,0,0,1-.191-.367,2.694,2.694,0,0,1-.059-2.038A2.69,2.69,0,0,1-30.2-28.323a2.788,2.788,0,0,1,2.56.186A2.737,2.737,0,0,1-26.5-26.758c.035.082.065.169.1.243Z"
              transform="translate(31.822 28.55)"
            />
          </g>
          <g transform="translate(448.545 -478.82)">
            <path
              className="f"
              d="M-8.012,0V5.3l.824.334-.468,1.093-.91-.378a1.035,1.035,0,0,1-.449-.377,1.189,1.189,0,0,1-.176-.657V0Z"
              transform="translate(9.191)"
            />
          </g>
          <g transform="translate(450.592 -476.943)">
            <path
              className="f"
              d="M-8.609-14.1a1.5,1.5,0,0,1,.515-1.132l-.846-.943a2.763,2.763,0,0,0-.935,2.073,2.76,2.76,0,0,0,.933,2.076l.844-.944A1.5,1.5,0,0,1-8.609-14.1"
              transform="translate(9.875 16.179)"
            />
          </g>
          <g transform="translate(452.481 -474.562)">
            <path
              className="f"
              d="M-5.986-8.149a1.514,1.514,0,0,1-.476-.078l-.4,1.2a2.771,2.771,0,0,0,.879.143A2.773,2.773,0,0,0-3.271-9.095l-1.242-.253a1.5,1.5,0,0,1-1.474,1.2"
              transform="translate(6.867 9.348)"
            />
          </g>
          <g transform="translate(452.486 -477.638)">
            <path
              className="f"
              d="M-5.973,0a2.77,2.77,0,0,0-.879.141l.4,1.2a1.522,1.522,0,0,1,.477-.077A1.5,1.5,0,0,1-4.5,2.471l1.242-.252A2.771,2.771,0,0,0-5.973,0"
              transform="translate(6.852 0)"
            />
          </g>
        </g>
        <g transform="translate(468.762 1690.276)">
          <g transform="translate(111.527 -1696.114)">
            <path
              className="g"
              d="M-100.78-53.478c1.471,0,2.755-.452,3.051-1.907s2.217-10.81,2.217-10.81h-23.971c-1.472,0-2.761.524-3.051,1.906s-2.211,10.809-2.211,10.809Z"
              transform="translate(124.745 66.195)"
            />
          </g>
          <g transform="translate(111.397 -1696.221)">
            <path
              className="a"
              d="M-101.328-54.37v-.212c1.458-.006,2.664-.437,2.946-1.823.275-1.354,1.96-9.559,2.192-10.683h-23.841c-1.455,0-2.672.509-2.948,1.822-.145.69-.7,3.392-1.215,5.922-.446,2.184-.864,4.237-.971,4.759l23.836,0v.212l-24.1,0,.026-.127s1.92-9.427,2.211-10.809c.305-1.45,1.666-2,3.155-1.991h24.1l-.026.128s-.48,2.338-1,4.86-1.072,5.223-1.219,5.95c-.307,1.514-1.654,1.992-3.126,1.992h-.029"
              transform="translate(125.423 67.299)"
            />
          </g>
          <g transform="translate(114.097 -1692.243)">
            <path
              className="a"
              d="M-.055-17.871l.2-1.016.2-1.073.173-1.08.162-1.015L.7-22.121h.718a.471.471,0,0,1-.014.065c-.026.123-.077.395-.149.814-.039.227-.091.485-.149.777l-.013.091h.058l.937.012L3-20.374h.072l.012-.091c.039-.252.078-.518.129-.8.1-.569.111-.608.143-.853h.73a.4.4,0,0,1-.019.065c-.013.065-.077.4-.187,1.015l-.2,1.08L3.5-18.887c-.1.608-.155.945-.167,1.016a.232.232,0,0,1-.008.064H2.59l.014-.064c.018-.078.051-.265.1-.563.019-.111.059-.3.11-.583l.1-.569c.032-.174.052-.278.058-.3s.007-.031.013-.064H2.907c-.122-.006-.563-.006-.983-.006s-.7,0-.829.006H1.012c-.013.124-.019.149-.058.369-.032.168-.064.362-.1.569-.051.305-.085.5-.1.583-.051.311-.084.5-.1.563a.285.285,0,0,1-.006.063H-.068Z"
              transform="translate(0.068 22.121)"
            />
          </g>
          <g transform="translate(118.119 -1692.3)">
            <path
              className="a"
              d="M-.08-18.123l.136-.693L.2-19.594l.124-.754c.062-.384.1-.624.111-.723a.274.274,0,0,1,.006-.062h.636a.278.278,0,0,1-.017.062c-.025.1-.068.339-.143.723l-.142.754-.129.778c-.057.364-.094.6-.107.693a.323.323,0,0,1,0,.061H-.1Zm.65-3.809c0-.315.154-.5.426-.5.185,0,.278.087.278.266,0,.309-.161.5-.427.5-.154,0-.247-.063-.273-.179a.541.541,0,0,1,0-.087"
              transform="translate(0.099 22.432)"
            />
          </g>
          <g transform="translate(119.078 -1691.069)">
            <path
              className="a"
              d="M-.08-18.686c.03-.135.1-.519.222-1.161l.2-1.107.179-1.063c.087-.519.137-.847.155-.983a.287.287,0,0,1,.006-.062h.551L1.224-23l-.03.172-.012.069a1.414,1.414,0,0,1,1.007-.37.961.961,0,0,1,1.063,1.081,3.011,3.011,0,0,1-.285,1.218,1.484,1.484,0,0,1-1.421.921A.677.677,0,0,1,.8-20.341l-.012.073-.142.915c-.05.309-.088.532-.1.662a.626.626,0,0,1-.005.074H-.1l.019-.069m1.235-3.623-.2,1.15a1.472,1.472,0,0,0-.024.236.533.533,0,0,0,.605.6.829.829,0,0,0,.643-.273A2.283,2.283,0,0,0,2.6-21.973c0-.5-.222-.749-.7-.749a.947.947,0,0,0-.724.322Z"
              transform="translate(0.099 23.129)"
            />
          </g>
          <g transform="translate(122.594 -1691.069)">
            <path
              className="a"
              d="M-5.226-13.488c-.833,0-1.243-.357-1.243-1.1a3.058,3.058,0,0,1,.285-1.248A1.527,1.527,0,0,1-4.656-16.7c.711,0,1.064.271,1.064.809a3.547,3.547,0,0,1-.1.673l-.068-.006c-.111-.006-.458-.006-1.039-.006-.494,0-.8,0-.914.006l-.063.006a2.16,2.16,0,0,0-.06.545c0,.5.283.761.815.761a2.561,2.561,0,0,0,1.052-.229l-.093.494a4.541,4.541,0,0,1-1.163.154m-.4-2.083c.068.007.3.007.681.007h.1c.278,0,.47,0,.581-.007h.056l.012-.08a1.256,1.256,0,0,0,.019-.2c0-.3-.2-.451-.6-.451a.844.844,0,0,0-.915.735Z"
              transform="translate(6.468 16.696)"
            />
          </g>
          <g transform="translate(125.55 -1691.069)">
            <path
              className="a"
              d="M-.05-12.949l.137-.693.148-.778.124-.754c.062-.384.1-.624.111-.723,0-.013.006-.031.006-.062h.556L1.02-15.9l-.073.389-.02.086a2.019,2.019,0,0,1,.222-.3.855.855,0,0,1,.644-.3.763.763,0,0,1,.346.08l-.08.445a1.16,1.16,0,0,0-.365-.061c-.37,0-.7.334-.829.846-.048.192-.093.415-.135.675a11.392,11.392,0,0,0-.162,1.155H-.062Z"
              transform="translate(0.062 16.028)"
            />
          </g>
          <g transform="translate(127.409 -1691.069)">
            <path
              className="a"
              d="M-10.791-2.314a2.42,2.42,0,0,0-.792-.143.954.954,0,0,0-.686.223A2.146,2.146,0,0,0-12.72-.855c0,.526.229.78.7.78a2.177,2.177,0,0,0,.883-.21l-.087.469a3.1,3.1,0,0,1-.988.161,1.033,1.033,0,0,1-.953-.389,1.174,1.174,0,0,1-.191-.729,2.828,2.828,0,0,1,.3-1.262,1.514,1.514,0,0,1,.365-.476,1.621,1.621,0,0,1,1.181-.352c.2,0,.407.013.742.043l.062.006Z"
              transform="translate(13.357 2.864)"
            />
          </g>
          <g transform="translate(129.981 -1691.069)">
            <path
              className="a"
              d="M-3.871-13.488c-.625,0-.921-.26-.921-.8,0-.8.568-1.267,1.551-1.267.154,0,.458.011.661.024l.013-.068a1.317,1.317,0,0,0,.043-.316c0-.247-.2-.372-.625-.372a3.73,3.73,0,0,0-1.032.162l.124-.508A.2.2,0,0,0-4-16.628a4.583,4.583,0,0,0,.47-.037c.185-.018.371-.03.543-.03.736,0,1.064.192,1.064.618a2.926,2.926,0,0,1-.068.506l-.1.563a8.77,8.77,0,0,0-.172,1.354.711.711,0,0,0,.005.1h-.556v-.049c0-.088.025-.279.05-.458a1.2,1.2,0,0,1-1.106.575m1.162-1.693a3.514,3.514,0,0,0-.408-.018,1.171,1.171,0,0,0-.772.185.8.8,0,0,0-.3.636c0,.31.185.476.524.476a.874.874,0,0,0,.793-.487,3.149,3.149,0,0,0,.221-.786Z"
              transform="translate(4.792 16.696)"
            />
          </g>
          <g transform="translate(133.035 -1691.069)">
            <path
              className="a"
              d="M-.053-12.949l.135-.693.15-.778.124-.754c.061-.384.1-.624.11-.723,0-.013.007-.031.007-.062h.557l-.014.062-.074.389-.018.086a2.041,2.041,0,0,1,.223-.3.852.852,0,0,1,.643-.3.77.77,0,0,1,.346.08l-.08.445a1.158,1.158,0,0,0-.366-.061c-.369,0-.7.334-.827.846-.05.192-.093.415-.136.675-.124.772-.136.834-.162,1.155h-.63Z"
              transform="translate(0.066 16.028)"
            />
          </g>
          <g transform="translate(134.893 -1692.325)">
            <path
              className="a"
              d="M-4.471-18.771a.924.924,0,0,1-1.063-1.056,2.781,2.781,0,0,1,.3-1.25,1.566,1.566,0,0,1,1.489-.9,2.382,2.382,0,0,1,.538.055c.05.018.082.025.174.055l.012-.074c.025-.124.056-.32.1-.593.049-.3.081-.513.1-.65l.007-.049h.63l-.012.044c-.05.248-.123.619-.216,1.125-.168.9-.229,1.243-.322,1.805a9.692,9.692,0,0,0-.161,1.378v.044H-3.47a2.074,2.074,0,0,1,.025-.259c.019-.1.025-.135.037-.241a1.052,1.052,0,0,1-1.064.568m.76-2.806a.963.963,0,0,0-.655.216A2.055,2.055,0,0,0-4.885-19.9c0,.488.2.71.63.71a.845.845,0,0,0,.76-.47,2.511,2.511,0,0,0,.155-.493c.074-.378.174-.915.229-1.243l.006-.067a1.683,1.683,0,0,0-.606-.117"
              transform="translate(5.534 23.234)"
            />
          </g>
        </g>
        <g transform="translate(320.257 -211.128)">
          <path
            className="h"
            d="M302.332,205l11.125-2.645a3.044,3.044,0,0,1,3.655,2.251l2.645,11.125a3.027,3.027,0,0,1-.428,2.379h-17l-2.248-9.455A3.044,3.044,0,0,1,302.332,205Z"
          />
          <path
            className="a"
            d="M328.03,290.606v4.754h1.079v-1.989h1.77v1.989h1.072v-4.754h-1.072v1.827h-1.77v-1.827Z"
            transform="translate(-25.721 -81.055)"
          />
          <path
            className="a"
            d="M452.205,307.649a2.547,2.547,0,0,0,.035-.423,1.53,1.53,0,0,0-1.538-1.721,1.727,1.727,0,0,0-1.742,1.841A1.655,1.655,0,0,0,450.8,309.1a3.4,3.4,0,0,0,1.248-.212l-.141-.726a3.038,3.038,0,0,1-.952.141c-.5,0-.938-.212-.973-.656Zm-2.229-.734a.7.7,0,0,1,.663-.7.622.622,0,0,1,.607.7Z"
            transform="translate(-136.692 -94.726)"
          />
          <path
            className="a"
            d="M492.633,309.031h1.072v-1.749a1.268,1.268,0,0,1,.021-.24.689.689,0,0,1,.734-.543,1.541,1.541,0,0,1,.289.028v-1.008a1.105,1.105,0,0,0-.226-.014,1.013,1.013,0,0,0-.931.712h-.028l-.035-.635h-.924c.014.3.028.628.028,1.135v2.313Z"
            transform="translate(-176.741 -94.726)"
          />
          <path
            className="i"
            d="M382.736,288.283a.551.551,0,1,1-.551.551.551.551,0,0,1,.551-.551Z"
            transform="translate(-75.416 -78.923)"
          />
          <path
            className="j"
            d="M385,308.25h.538a.7.7,0,0,0,.785-.707c0-.361-.026-1.177-.694-1.177-.767,0-.643,1.013-.639,1.543,0,.113.007.227.01.34Zm-2.815-2.65h1.1v1.943a.641.641,0,0,0,.642.707c0-.875,0-1.776-.03-2.65h.921q.03.253.055.507a1.37,1.37,0,0,1,2.267.056c.477.759.639,2.931-1.6,2.931h-.522c.005.441.006.883.006,1.324h-1.1c0-.424,0-.868.006-1.324a1.559,1.559,0,0,1-1.74-1.549V305.6Z"
            transform="translate(-75.416 -94.748)"
          />
        </g>
        <g transform="translate(650.701 -4.429)">
          <path
            className="k"
            d="M294.425,52.494a5.936,5.936,0,1,1-5.941-5.949,5.942,5.942,0,0,1,5.941,5.949Z"
            transform="translate(-275.223 -46.545)"
          />
          <path
            className="l"
            d="M78.956,52.494a5.936,5.936,0,1,1-5.936-5.949,5.942,5.942,0,0,1,5.936,5.949Z"
            transform="translate(-67.083 -46.545)"
          />
          <path
            className="m"
            d="M284.7,83.733a5.961,5.961,0,0,0,0,9.368,5.961,5.961,0,0,0,0-9.368Z"
            transform="translate(-275.106 -82.468)"
          />
          <g transform="translate(18.731 8.893)">
            <g transform="translate(0 0)">
              <path
                className="k"
                d="M1.383,15.654v-.21H1.331l-.063.147L1.2,15.439H1.147v.21h.037v-.158l.058.137h.042l.063-.137v.163Zm-.352,0v-.179H1.1v-.037H.926v.037H1v.179Z"
                transform="translate(-0.926 -15.439)"
              />
            </g>
          </g>
        </g>
        <g transform="translate(680.508 -0.934)">
          <path
            className="n"
            d="M184.8,187.277l1.222-6.877h1.91l-1.184,6.877Z"
            transform="translate(-175.975 -180.171)"
          />
          <path
            className="o"
            d="M184.8,187.277l1.566-6.877h1.566l-1.184,6.877Z"
            transform="translate(-175.975 -180.171)"
          />
          <path
            className="n"
            d="M253.845,175.906a4.846,4.846,0,0,0-1.757-.306c-1.91,0-3.285.955-3.285,2.33,0,1.031.955,1.566,1.719,1.91s.993.573.993.879c0,.458-.611.688-1.146.688a4.152,4.152,0,0,1-1.834-.382l-.267-.115L248,182.477a6.194,6.194,0,0,0,2.178.382c2.025,0,3.362-.955,3.362-2.407,0-.8-.5-1.413-1.643-1.91-.688-.344-1.108-.535-1.108-.879,0-.306.344-.611,1.108-.611a3.5,3.5,0,0,1,1.452.267l.191.076.306-1.49Z"
            transform="translate(-236.157 -175.6)"
          />
          <path
            className="o"
            d="M253.845,175.906a4.846,4.846,0,0,0-1.757-.306c-1.91,0-2.942.955-2.942,2.33a2,2,0,0,0,1.375,1.91c.764.344.993.573.993.879,0,.458-.611.688-1.146.688a4.152,4.152,0,0,1-1.834-.382l-.267-.115L248,182.477a6.194,6.194,0,0,0,2.178.382c2.025,0,3.362-.955,3.362-2.407,0-.8-.5-1.413-1.643-1.91-.688-.344-1.108-.535-1.108-.879,0-.306.344-.611,1.108-.611a3.5,3.5,0,0,1,1.452.267l.191.076.306-1.49Z"
            transform="translate(-236.157 -175.6)"
          />
          <path
            className="n"
            d="M362.259,180.4c-.458,0-.8.038-.993.5l-2.865,6.38h2.063l.382-1.146h2.445l.229,1.146h1.834l-1.6-6.877Zm-.879,4.584c.115-.344.764-2.025.764-2.025s.153-.42.267-.688l.115.649s.382,1.719.458,2.1h-1.6Z"
            transform="translate(-341.285 -180.171)"
          />
          <path
            className="o"
            d="M362.717,180.4c-.458,0-.8.038-.993.5l-3.324,6.38h2.063l.382-1.146h2.445l.229,1.146h1.834l-1.6-6.877Zm-1.337,4.584c.153-.382.764-2.025.764-2.025s.153-.42.267-.688l.115.649s.382,1.719.458,2.1h-1.6Z"
            transform="translate(-341.285 -180.171)"
          />
          <path
            className="n"
            d="M54.065,185.975l-.191-.993a5.538,5.538,0,0,0-2.674-3.018l1.719,6.112h2.063l3.094-6.877H56.014Z"
            transform="translate(-48.755 -180.933)"
          />
          <path
            className="o"
            d="M54.065,185.975l-.191-.993a5.538,5.538,0,0,0-2.674-3.018l1.719,6.112h2.063l3.094-6.877H56.4Z"
            transform="translate(-48.755 -180.933)"
          />
          <path
            className="p"
            d="M0,180.4l.344.076a6.443,6.443,0,0,1,4.775,3.744l-.688-3.247c-.115-.458-.458-.573-.879-.573Z"
            transform="translate(0 -180.171)"
          />
          <path
            className="q"
            d="M0,180.4H0c2.445.573,4.47,2.063,5.119,3.782L4.47,181.47a.933.933,0,0,0-.917-.726Z"
            transform="translate(0 -180.171)"
          />
          <path
            className="r"
            d="M0,180.4H0c2.445.573,4.47,2.063,5.119,3.782l-.458-1.49a1.347,1.347,0,0,0-.8-1.108Z"
            transform="translate(0 -180.171)"
          />
          <g transform="translate(2.483 0.993)">
            <path
              className="s"
              d="M56.737,200.22l-1.3-1.3-.611,1.452-.153-.955A5.538,5.538,0,0,0,52,196.4l1.719,6.112h2.063Z"
              transform="translate(-52 -196.4)"
            />
            <path
              className="s"
              d="M186.748,290.881l-1.643-1.681-.306,1.681Z"
              transform="translate(-178.458 -284.768)"
            />
            <path
              className="s"
              d="M251.324,274.8h0c.153.153.229.267.191.42,0,.458-.611.688-1.146.688a4.152,4.152,0,0,1-1.834-.382l-.267-.115L248,276.978a6.2,6.2,0,0,0,2.178.382,3.782,3.782,0,0,0,2.789-.955Z"
              transform="translate(-238.64 -271.056)"
            />
            <path
              className="s"
              d="M364,224.6h1.8l.382-1.146h2.445l.229,1.146h1.834l-.649-2.789-2.292-2.216.115.611s.382,1.719.458,2.1h-1.6c.153-.382.764-2.025.764-2.025s.153-.42.267-.688"
              transform="translate(-349.101 -218.492)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
