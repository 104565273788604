import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const CircleCheckIcon = ({
  width = 25,
  height = 24,
  color = theme.colors.secondary["28"],
  secondColor = theme.colors.secondary["380"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5"
        cy="12"
        r="9.00375"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.9425 12.3392L11.1104 14.5071L11.0964 14.4931L15.9874 9.60205"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
