import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { CarouselProps } from "typing";
import { ReactChild } from "react";

const ReactResponsiveCarousel = ({ children, ...props }: CarouselProps) => {
  return (
    <Carousel
      {...props}
      showStatus={false}
      showThumbs={false}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={15}
    >
      {children as ReactChild[]}
    </Carousel>
  );
};

export { ReactResponsiveCarousel };
