import styled from "styled-components";
import { theme } from "../../themes";
import { TypographyProps } from "../typography";

export const H2 = styled.h2<TypographyProps>`
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x9};
  margin: 0;

  ${({ color }) => color && `color: ${color};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}

    @media ${theme.device.desktopFirst.tablet} {
    font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x7};
  }
`;
