import { CustomImagesGridDto, ImagesGridSubComponentDto } from "typing";
import { memo } from "react";
import { appMonitoringClient } from "implementations";
import { buildTestIds } from "application";
import { customImagesGridData } from "./data";
import {
  CustomImagesGridHeader,
  CustomImagesGridContainer,
  CustomImagesGridGrid,
  CustomImagesGridSubtitle,
  CustomImagesGridTitle,
} from "./styles";
import { ImagesGrid } from "./SubComponents";

const CustomImagesGrid = memo(
  ({
    properties,
    subComponents,
    isClientTablet,
    isClientMobile,
    isClientLaptop,
    position,
    isBlackTheme,
  }: CustomImagesGridDto) => {
    const {
      title,
      showTitle,
      textAlign,
      subTitle,
      showSubTitle,
      orientation,
      format,
    } = customImagesGridData(properties);

    if (!subComponents?.length) {
      appMonitoringClient.captureMessage(
        "Um componente de grade de imagens não possui subcomponentes, ou seja, não existem produtos a serem mostrados",
        "warning"
      );
      return null;
    }

    if (!format) {
      appMonitoringClient.captureMessage(
        "Um componente de grade de imagens não possui formato especificado. Desse modo, irá assumir o formato double.",
        "warning"
      );
      return null;
    }

    if (!orientation) {
      appMonitoringClient.captureMessage(
        "Um componente de grade de imagens não possui orientação especificada. Desse modo, irá assumir a orientação horizontal.",
        "warning"
      );
      return null;
    }

    const details = { isClientMobile, isClientTablet, isClientLaptop };

    return (
      <CustomImagesGridContainer
        customTheme={isBlackTheme}
        {...buildTestIds("custom-imagesgrid-container")}
      >
        <CustomImagesGridHeader
          textAlign={textAlign}
          {...buildTestIds("custom-imagesgrid-header")}
        >
          {showTitle && showTitle === "true" && (
            <CustomImagesGridTitle
              textAlign={textAlign}
              {...buildTestIds("custom-imagesgrid-title")}
            >
              {title}
            </CustomImagesGridTitle>
          )}
          {showSubTitle && showSubTitle === "true" && (
            <CustomImagesGridSubtitle
              textAlign={textAlign}
              {...buildTestIds("custom-imagesgrid-subtitle")}
            >
              {subTitle}
            </CustomImagesGridSubtitle>
          )}
        </CustomImagesGridHeader>

        <CustomImagesGridGrid
          gridType={format}
          orientation={orientation}
          {...buildTestIds("custom-imagesgrid-grid")}
        >
          {subComponents?.map((subComponent) => {
            const subComponentKey = subComponent?.properties?.find(
              (property) =>
                property?.key === "desktopImage" ||
                property?.key === "mobileImage"
            )?.value;

            return (
              <ImagesGrid
                key={subComponentKey}
                {...(subComponent as ImagesGridSubComponentDto)}
                {...details}
                positionComponent={position}
                {...buildTestIds(
                  `custom-imagesgrid-subComponent-${subComponentKey}`
                )}
              />
            );
          })}
        </CustomImagesGridGrid>
      </CustomImagesGridContainer>
    );
  }
);

CustomImagesGrid.displayName = "CustomImagesGrid";

export { CustomImagesGrid };
