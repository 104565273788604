import { buildTestIds, useAppContext } from "application";
import { ReactNode } from "react";
import { ButtonType } from "typing";
import { theme } from "ui";
import {
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalFooterButton,
  BaseModalHeader,
  BaseModalHeaderClose,
  BaseModalHeaderTitle,
  Close,
} from "./style";

export interface AccountBaseModalProps {
  children?: ReactNode;
  width?: string;
  height?: string;
  footerGap?: string;
  title: string;
  titleFontSize?: string;
  buttonText?: string;
  AlternativeButtonText?: string;
  buttonTextUnderAction?: string;
  buttonUnderAction?: boolean;
  hasFooterShadow?: boolean;
  isFormInvalid?: boolean;
  buttonWidth?: string;
  isOpen: boolean;
  contentLabel: string;
  onCloseModal: (event?: { stopPropagation: () => void }) => void;
  onClickOnButton?: () => void;
  buttonHoverColor?: string;
  onClickAlternativeButton?: () => void;
  modalButtonPadding?: string;
  modalButtonColor?: string;
  buttonType?: ButtonType;
  visible?: string;
  buttonFormId?: string;
  hasFooter?: boolean;
  hasBaseFooter?: boolean;
  alternativeButtonText?: string;
  backgroundColor?: string;
  boxShadow?: string;
  padding?: string;
  displayHeader?: string;
  overlay?: string;
  borderRadius?: string;
  isNewModal?: boolean;
  closeButtonColor?: string;
}

const AccountBaseModal = ({
  children,
  width,
  height,
  footerGap = "2rem",
  title,
  titleFontSize = "2rem",
  buttonText,
  buttonTextUnderAction,
  buttonUnderAction,
  buttonWidth,
  onCloseModal,
  onClickOnButton,
  onClickAlternativeButton,
  buttonHoverColor = theme.colors.secondary["500"],
  hasFooterShadow,
  // modalButtonPadding,
  buttonFormId,
  isFormInvalid = false,
  buttonType = "button",
  modalButtonColor = theme.colors.secondary["265"],
  visible = "visible",
  hasFooter = true,
  hasBaseFooter = true,
  alternativeButtonText = "Cancelar",
  backgroundColor,
  boxShadow,
  padding,
  displayHeader,
  overlay,
  borderRadius,
  isNewModal,
  closeButtonColor,
  ...props
}: AccountBaseModalProps) => {
  const { isClientMobile, isClientTablet } = useAppContext();

  return (
    <BaseModal
      {...props}
      styles={{
        content: {
          borderRadius: borderRadius ?? theme.space.x1,
          boxShadow: boxShadow ?? "none",
          padding: "0px",
          width:
            width ||
            (isClientMobile
              ? "95vw"
              : theme.space.x160 && isClientTablet
              ? "60vw"
              : "65vw"),
          minHeight: height ?? "fit-content",
          visibility: visible,
        },
        overlay: {
          backgroundColor: overlay ?? theme.colors.neutral["999-opacity-50"],
          visibility: visible,
        },
      }}
      onCloseModal={onCloseModal}
      isNewModal={isNewModal}
    >
      <BaseModalHeader
        displayHeader={displayHeader}
        {...buildTestIds("base-modal-header")}
      >
        <BaseModalHeaderTitle
          {...buildTestIds("base-modal-header-title")}
          titleFontSize={titleFontSize}
        >
          {title}
        </BaseModalHeaderTitle>
        <BaseModalHeaderClose
          data-testid="base-modal-closer"
          onClick={onCloseModal}
        >
          <Close color={closeButtonColor} />
        </BaseModalHeaderClose>
      </BaseModalHeader>
      <BaseModalBody
        {...buildTestIds("base-modal-body")}
        backgroundColor={backgroundColor}
        padding={padding}
        data-testid="base-modal-body"
      >
        {children}
      </BaseModalBody>
      {hasBaseFooter && (
        <BaseModalFooter
          {...buildTestIds("base-modal-footer")}
          gap={footerGap}
          hasFooterShadow={hasFooterShadow}
        >
          {alternativeButtonText && (
            <BaseModalFooterButton
              text={alternativeButtonText}
              {...buildTestIds("base-modal-alternative-footer-button")}
              textUnderAction={alternativeButtonText}
              underAction={buttonUnderAction}
              color={theme.colors.neutral.white}
              contentColor={theme.colors.primary["200"]}
              hoveredColor={theme.colors.pink["50"]}
              minWidth={buttonWidth}
              onClickAction={onClickAlternativeButton ?? onCloseModal}
              border={`1px solid ${theme.colors.primary["200"]}`}
              alternativeButton
            />
          )}
          {hasFooter && (
            <BaseModalFooterButton
              {...buildTestIds("base-modal-button")}
              text={buttonText}
              textUnderAction={buttonTextUnderAction || ""}
              underAction={buttonUnderAction}
              isDisabled={isFormInvalid}
              color={modalButtonColor}
              hoveredColor={buttonHoverColor}
              minWidth={buttonWidth}
              onClickAction={onClickOnButton}
              form={buttonFormId}
              buttonType={buttonType ?? "button"}
            />
          )}
        </BaseModalFooter>
      )}
    </BaseModal>
  );
};

export { AccountBaseModal };
