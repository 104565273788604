import {
  brazilianStates,
  buildTestIds,
  useAppContext,
  useAuth,
  useToast,
} from "application";
import { profileApi, shippingApi } from "implementations";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import {
  AddressDto,
  AddressEditBodyDto,
  FormNewAddressProps,
  IFormValues,
  NewAddressInputProps,
  NewAddressProps,
} from "typing";
import { theme } from "ui";
import { Question } from "ui/Icons";
import {
  AlignWrapperInput,
  BoxButton,
  BoxButtonDefault,
  ButtonDefaultForm,
  ButtonRegisterAction,
  CustomCheckboxNewAddress,
  CustomInputNewAddress,
  CustomLabelNewAddress,
  DescriptionText,
  ExtraInfoCEP,
  FormNewAddressContainer,
  FormNewAddressContent,
  IconLoading,
  LinkCustom,
  OptionStates,
  SpaceMobileBottom,
  SpaceMobileTop,
  StateSelect,
  WrapperContentHouse,
  WrapperInput,
  WrapperRow,
  ContainerRequiredWarning,
} from "./styles";
import RequiredWarning from "../RequiredWarning";

interface HouseData extends NewAddressProps {
  noNumber?: boolean;
}

const FormNewAddress = ({
  setCreatingNewAddress,
  newAddress: newAddressData,
  optionButton = "buttonForm",
  returnCreatedStatus,
  returnEditStatus,
  purchaseScreen,
  hasDefaultButton = true,
  isdisplayedonbottomsheet,
  onCreateNewAddress,
  onActionAfterCreateNewAddress,
  hasSpaceBottom = false,
  hasSpaceTop = false,
  isCheckoutPage = false,
  isFlexColumn = false,
  addresses,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
FormNewAddressProps) => {
  const { addToast } = useToast();
  const { getTokens } = useAuth();
  const TOKEN_SESSION = getTokens();
  const { isClientMobile, isClientTablet } = useAppContext();
  const [isDisabledInfoSearchCEP, setIsDisabledInfoSearchCEP] =
    useState<HouseData>();
  const [newAddress, setNewAddress] = useState<HouseData>();
  const [requiredInputStatus, setRequiredInputStatus] = useState(false);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<HouseData>();

  const houseNumberWatcher = watch("number") ?? "";
  const noNumberCheckboxWatcher = watch("noNumber");
  const recipientWatcher = watch("recipient") ?? "";
  const addressComplementWatcher = watch("addressComplement") ?? "";
  const referencePointWatcher = watch("referencePoint") ?? "";
  const zipCodeWatcher = watch("zipCode") ?? "";
  const streetWatcher = watch("street");
  const districtWatcher = watch("district");
  const cityWatcher = watch("city");
  const stateWatcher = watch("state");
  const [isLoadingButton, handleLoadingButton] = useState(false);
  const [searchCEP, handleSearchCEP] = useState(false);
  const [errorCepMessage, setErrorCepMessage] = useState("");

  const isCEPValid = String(zipCodeWatcher).length === 9;

  const {
    data: addressByCep,
    isLoading,
    error,
  } = shippingApi.getAddressByZipCode(
    String(zipCodeWatcher) || "",
    isCEPValid && searchCEP
  );

  const hasNewAddressStreet = !!newAddress?.street;

  const shouldDisabledAllInputs =
    errorCepMessage === "O CEP digitado é inválido.";

  const validateCheckDone = (type: NewAddressInputProps) => {
    switch (type) {
      case "zipCode":
        return !!isCEPValid && hasNewAddressStreet;
      case "city":
        return !!newAddress?.city;

      case "state":
        return !!newAddress?.state;

      case "street":
        return !!newAddress?.street;

      case "number":
        return !!newAddress?.number;

      case "district":
        return !!newAddress?.district;

      default:
        return false;
    }
  };

  const onAutoCompleteAddressByCep = useCallback(() => {
    handleSearchCEP(false);

    if (isCEPValid && addressByCep) {
      const { street, city, uf, district } = addressByCep;

      const formattedStreet = street?.trim();
      const formattedCity = city?.trim();
      const formattedState = uf?.trim();
      const formattedDistrict = district?.trim();

      setValue("city", formattedCity);
      setValue("state", formattedState);
      setValue("street", formattedStreet);
      setValue("district", formattedDistrict);

      setNewAddress({
        zipCode: String(zipCodeWatcher) || "",
        street: formattedStreet,
        city: formattedCity,
        district: formattedDistrict,
        state: formattedState,
        recipient: "",
        addressComplement: "",
        referencePoint: "",
        number: Number(houseNumberWatcher?.toString().slice(0, 6)) || 0,
      });

      setIsDisabledInfoSearchCEP({
        zipCode: String(zipCodeWatcher),
        street: formattedStreet,
        city: formattedCity,
        district: formattedDistrict,
        state: formattedState,
        recipient: "",
        addressComplement: "",
        referencePoint: "",
        number: Number(houseNumberWatcher?.toString().slice(0, 6)) || 0,
      });

      return;
    }

    if (!isCEPValid && !newAddressData) {
      setValue("city", "");
      setValue("state", "");
      setValue("street", "");
      setValue("district", "");

      setNewAddress({
        zipCode: String(zipCodeWatcher) || "",
        street: "",
        city: "",
        district: "",
        state: "",
        recipient: "",
        addressComplement: "",
        referencePoint: "",
        number: 0,
      });
    }
  }, [
    isCEPValid,
    addressByCep,
    newAddressData,
    setValue,
    zipCodeWatcher,
    houseNumberWatcher,
  ]);

  const handleCreateNewAddress = useCallback(async () => {
    handleLoadingButton(true);

    if (!newAddress) {
      return;
    }

    const {
      street,
      zipCode,
      city,
      district,
      recipient,
      state,
      addressComplement,
      number,
      referencePoint,
    } = newAddress;

    const responseCreated = await profileApi.createClientAddress(
      {
        street: street ?? streetWatcher,
        number: Number(number?.toString().slice(0, 6) ?? 0),
        addressComplement,
        referencePoint,
        district: district ?? districtWatcher,
        state: state ?? "",
        zipCode: String(zipCode) ?? "",
        city: city ?? "",
        recipient: recipient ?? "",
        isDefault: false,
      },
      getTokens()?.token as unknown as string
    );
    if (responseCreated) {
      const createdAddress = responseCreated as AddressDto;

      handleLoadingButton(false);
      if (onActionAfterCreateNewAddress) {
        onActionAfterCreateNewAddress(createdAddress?.id);
      }

      if (onCreateNewAddress) {
        onCreateNewAddress(createdAddress);
      }

      if (returnCreatedStatus) {
        returnCreatedStatus();
      }
    }
  }, [
    newAddress,
    streetWatcher,
    districtWatcher,
    getTokens,
    onActionAfterCreateNewAddress,
    onCreateNewAddress,
    returnCreatedStatus,
  ]);

  const handleEditNewAddress = useCallback(async () => {
    if (!newAddress) {
      return;
    }

    const {
      street,
      zipCode,
      city,
      district,
      recipient,
      state,
      addressComplement,
      number,
      referencePoint,
    } = newAddress;

    const BODY: AddressEditBodyDto[] = [
      {
        op: "replace",
        path: "/ZipCode",
        value: zipCode,
      },
      {
        op: "replace",
        path: "/Street",
        value: street,
      },
      {
        op: "replace",
        path: "/Number",
        value: Number(number?.toString().slice(0, 6)),
      },

      {
        op: "replace",
        path: "/AddressComplement",
        value: addressComplement ?? "",
      },
      {
        op: "replace",
        path: "/ReferencePoint",
        value: referencePoint ?? "",
      },
      {
        op: "replace",
        path: "/District",
        value: district,
      },
      {
        op: "replace",
        path: "/State",
        value: state,
      },
      {
        op: "replace",
        path: "/City",
        value: city,
      },
      {
        op: "replace",
        path: "/Recipient",
        value: recipient ?? "",
      },
    ];

    const responseEdit = await profileApi.editClientAddresses(
      (TOKEN_SESSION?.token as unknown as string) || "",
      String(newAddressData?.id) || "",
      BODY
    );

    if (returnEditStatus && responseEdit) {
      returnEditStatus();
    }

    handleLoadingButton(false);
  }, [TOKEN_SESSION?.token, newAddress, newAddressData?.id, returnEditStatus]);

  const handleCheckBox = houseNumberWatcher || noNumberCheckboxWatcher;

  const verifyFieldsForm =
    zipCodeWatcher &&
    newAddress?.street &&
    newAddress?.city &&
    newAddress?.district &&
    newAddress?.state &&
    recipientWatcher &&
    handleCheckBox;

  const controlAction = useCallback(() => {
    if (setCreatingNewAddress) setCreatingNewAddress(true, false);

    if (!verifyFieldsForm) {
      addToast({
        type: "error",
        title: "Preencha os campos obrigatórios antes de enviar.",
      });
      setErrorCepMessage("O Campo CEP deve ser preenchido");
      setRequiredInputStatus(true);
      if (setCreatingNewAddress) setCreatingNewAddress(false, true);
      return;
    }
    setRequiredInputStatus(true);
    handleLoadingButton(true);
    if (newAddressData) {
      handleEditNewAddress();
      if (setCreatingNewAddress) setCreatingNewAddress(false, false);
      return;
    }
    handleCreateNewAddress();
    if (setCreatingNewAddress) setCreatingNewAddress(false, false);
  }, [
    setCreatingNewAddress,
    newAddressData,
    handleCreateNewAddress,
    addToast,
    handleEditNewAddress,
    verifyFieldsForm,
  ]);

  useEffect(() => {
    if (addressByCep) {
      onAutoCompleteAddressByCep();
    }
  }, [addressByCep, onAutoCompleteAddressByCep]);

  useEffect(() => {
    if (
      (String(zipCodeWatcher)?.length === 0 && hasNewAddressStreet) ||
      addressByCep === null
    ) {
      setNewAddress((address) => ({
        ...address,
        street: "",
        city: "",
        district: "",
        state: "",
      }));

      setIsDisabledInfoSearchCEP({
        street: "",
        city: "",
        district: "",
        state: "",
      });
    }
  }, [addressByCep, hasNewAddressStreet, zipCodeWatcher]);

  useEffect(() => {
    if (newAddressData) {
      setNewAddress({
        id: undefined,
        noNumber: Number(newAddressData?.number) === 0,
        number: Number(newAddressData?.number),
        zipCode: String(newAddressData?.zipCode) || String(undefined),
        ...newAddressData,
      });
      setValue("zipCode", newAddressData.zipCode);
      setValue(
        "number",
        Number(newAddressData.number) === 0 ? 0 : newAddressData.number
      );
      setValue("noNumber", newAddressData.number === 0);
      setValue("addressComplement", newAddressData.addressComplement);
      setValue("referencePoint", newAddressData.referencePoint);
      setValue("recipient", newAddressData.recipient);

      setIsDisabledInfoSearchCEP({
        zipCode: String(newAddressData.zipCode),
        street: newAddressData.street,
        city: newAddressData.city,
        district: newAddressData.district,
        state: newAddressData.state,
        recipient: "",
        addressComplement: "",
        referencePoint: "",
        number: Number(newAddressData.number) || 0,
      });
    }
  }, [newAddressData, setValue]);

  useEffect(() => {
    setNewAddress((currentNewAddress) => {
      return {
        ...currentNewAddress,
        number: houseNumberWatcher,
        recipient: recipientWatcher,
        addressComplement: addressComplementWatcher,
        referencePoint: referencePointWatcher,
      };
    });
  }, [
    houseNumberWatcher,
    recipientWatcher,
    addressComplementWatcher,
    referencePointWatcher,
  ]);

  useEffect(() => {
    const validCepAndNotLoading = isCEPValid && !isLoading;

    if (error && validCepAndNotLoading) {
      setErrorCepMessage(
        "Desculpe, ocorreu um erro ao buscar o CEP. Por favor, tente novamente mais tarde."
      );
      return;
    }

    const notExistentZipCode =
      !isLoading &&
      zipCodeWatcher &&
      !streetWatcher &&
      !districtWatcher &&
      !cityWatcher &&
      stateWatcher !== "(Selecione)";

    if (notExistentZipCode || addressByCep === null) {
      setErrorCepMessage("O CEP digitado é inválido.");
      return;
    }

    setErrorCepMessage("");

    // Remove a mensagem abaixo, conforme alinhado com Volpato em 19/06/2023
    // if (!hasNewAddressStreet && validCepAndNotLoading) {
    //   setErrorCepMessage("Ainda não realizamos entrega no seu endereço");
    // }
  }, [
    addressByCep,
    cityWatcher,
    districtWatcher,
    error,
    hasNewAddressStreet,
    isCEPValid,
    isLoading,
    stateWatcher,
    streetWatcher,
    zipCodeWatcher,
  ]);
  const hasAddress = addresses && addresses.length > 0;

  // wellington.braga revisar essa lógica, pois está gerando bug ao cadastrar um novo cartão com um novo endereço
  // useEffect(() => {
  //   if (setCreatingNewAddress) setCreatingNewAddress(true, false);
  //   return () => {
  //     if (setCreatingNewAddress) setCreatingNewAddress(false, false);
  //   };
  // }, [setCreatingNewAddress]);

  return (
    <FormNewAddressContainer
      {...buildTestIds("form-new-address")}
      id="form-new-address"
      onSubmit={handleSubmit(handleCreateNewAddress)}
      isCheckoutPage={isCheckoutPage}
    >
      {(isClientMobile || isClientTablet) && hasSpaceTop ? (
        <SpaceMobileTop {...buildTestIds("space-mobile-top")} />
      ) : null}
      <WrapperRow
        {...buildTestIds("wrapper-row-1")}
        isdisplayedonbottomsheet={isdisplayedonbottomsheet}
      >
        <WrapperInput {...buildTestIds("wrapper-input-1")}>
          <CustomLabelNewAddress
            {...buildTestIds("form-label-zipCode")}
            htmlFor="zipCode"
            text="CEP"
            isRequired={!hasAddress}
            hasSuccessfully={validateCheckDone("zipCode")}
          />
          <CustomInputNewAddress
            {...buildTestIds("form-input-zipCode")}
            id="zipCode"
            name="zipCode"
            required="Campo obrigatório"
            size={isClientMobile ? "large" : "medium"}
            register={register as unknown as UseFormRegister<IFormValues>}
            value={zipCodeWatcher}
            onChange={({ target }) => {
              setValue("zipCode", target.value);
              if (target?.value?.length === 9) {
                handleSearchCEP(true);
              }
            }}
            placeholder="_ _ _ _ _-_ _ _"
            type="tel"
            minLength={9}
            max={9}
            maxLength={9}
            mask="99999-999"
            maskChar=""
            hasError={!hasNewAddressStreet && !!errorCepMessage}
            hasSuccessfully={isCEPValid && hasNewAddressStreet}
            textError={errorCepMessage}
            isdisplayedonbottomsheet={isdisplayedonbottomsheet}
            autoComplete="off"
          />
        </WrapperInput>
        <ExtraInfoCEP
          {...buildTestIds("extra-info-cep")}
          isdisplayedonbottomsheet={isdisplayedonbottomsheet}
        >
          <LinkCustom
            {...buildTestIds("verify-my-zipCode")}
            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            target="_black"
          >
            <DescriptionText
              {...buildTestIds("description-text")}
              isdisplayedonbottomsheet={isdisplayedonbottomsheet}
            >
              Não sei meu CEP
            </DescriptionText>
            <Question color={theme.colors.secondary["250"]} height={24} />
          </LinkCustom>
        </ExtraInfoCEP>
      </WrapperRow>

      {/*  {purchaseScreen && isCEPValid && (
        <>
          <NewAddressValidation
            stateModal={modalSendCode}
            actionSendCode={handleSendCode}
            actionCloseModal={() => setModalSendCode(false)}
          />
          <CodeVerification
            stateModal={isModalOpen}
            actionCloseModal={() => setIsModalOpen(false)}
          />
        </>
      )}
      {(purchaseScreen && isCEPValid && isCodeValid) ||
        (!purchaseScreen && isCEPValid && ( */}
      {(!purchaseScreen || isCEPValid) && (
        <>
          <WrapperRow isFlex={isFlexColumn} {...buildTestIds("wrapper-row-3")}>
            <WrapperInput {...buildTestIds("wrapper-input-3")}>
              <CustomLabelNewAddress
                {...buildTestIds("form-label-city")}
                htmlFor="city"
                text="Cidade"
                isRequired
                hasSuccessfully={validateCheckDone("city")}
              />
              <CustomInputNewAddress
                {...buildTestIds("form-input-city")}
                id="city"
                name="city"
                type="text"
                value={newAddress?.city}
                required="Campo obrigatório"
                size="large"
                placeholder="Ex. Recife"
                onChange={({ target }) => {
                  setValue("city", target?.value);
                  setNewAddress((address) => ({
                    ...address,

                    city: target?.value,
                  }));
                }}
                disabled={
                  !!isDisabledInfoSearchCEP?.city || shouldDisabledAllInputs
                }
                register={register as unknown as UseFormRegister<IFormValues>}
                hasError={
                  !newAddress?.city && (requiredInputStatus || !!errors?.city)
                }
                hasSuccessfully={
                  !!newAddress?.city && !isDisabledInfoSearchCEP?.city
                }
              />
            </WrapperInput>
            <WrapperInput {...buildTestIds("wrapper-input-4")}>
              <CustomLabelNewAddress
                {...buildTestIds("form-label-state")}
                htmlFor="estado"
                text="Estado"
                isRequired
                hasSuccessfully={validateCheckDone("state")}
              />
              <StateSelect
                {...buildTestIds("form-state-select")}
                id="state"
                {...register("state")}
                disabled={
                  !!isDisabledInfoSearchCEP?.state || shouldDisabledAllInputs
                }
                value={newAddress?.state}
                hasError={!newAddress?.state && requiredInputStatus}
                hasSuccessfully={
                  Boolean(newAddress?.state) && !isDisabledInfoSearchCEP?.state
                }
              >
                <OptionStates
                  {...buildTestIds("option-states")}
                  value="selecione"
                >
                  (Selecione)
                </OptionStates>
                {brazilianStates.map(({ state }) => (
                  <OptionStates
                    {...buildTestIds("option-states")}
                    key={state}
                    value={`${state}`}
                  >
                    {state}
                  </OptionStates>
                ))}
              </StateSelect>
            </WrapperInput>
          </WrapperRow>
          <WrapperRow isFlex={isFlexColumn} {...buildTestIds("wrapper-row-2")}>
            <WrapperInput>
              <CustomLabelNewAddress
                {...buildTestIds("form-label-district")}
                htmlFor="district"
                text="Bairro"
                isRequired
                hasSuccessfully={validateCheckDone("district")}
              />
              <CustomInputNewAddress
                {...buildTestIds("form-input-district")}
                id="district"
                name="district"
                type="text"
                size="large"
                value={newAddress?.district}
                required="Campo obrigatório"
                register={register as unknown as UseFormRegister<IFormValues>}
                disabled={
                  !!isDisabledInfoSearchCEP?.district || shouldDisabledAllInputs
                }
                hasError={
                  !newAddress?.district &&
                  (requiredInputStatus || !!errors?.district)
                }
                hasSuccessfully={
                  !!newAddress?.district && !isDisabledInfoSearchCEP?.district
                }
                placeholder="Ex. Santo Antonio"
                onChange={({ target }) => {
                  setValue("district", target?.value);
                  setNewAddress((address) => ({
                    ...address,

                    district: target?.value,
                  }));
                }}
              />
            </WrapperInput>
            <WrapperInput {...buildTestIds("wrapper-input-2")}>
              <CustomLabelNewAddress
                {...buildTestIds("form-label-street")}
                htmlFor="street"
                text="Endereço"
                isRequired
                hasSuccessfully={validateCheckDone("street")}
              />
              <CustomInputNewAddress
                {...buildTestIds("form-input-street")}
                id="street"
                name="street"
                type="text"
                value={newAddress?.street}
                placeholder="Rua ou avenida"
                size="large"
                required="Campo obrigatório"
                register={register as unknown as UseFormRegister<IFormValues>}
                disabled={
                  !!isDisabledInfoSearchCEP?.street || shouldDisabledAllInputs
                }
                hasError={
                  !newAddress?.street &&
                  (requiredInputStatus || !!errors?.street)
                }
                hasSuccessfully={
                  Boolean(newAddress?.street) &&
                  !isDisabledInfoSearchCEP?.street
                }
                onChange={({ target }) => {
                  setNewAddress((address) => ({
                    ...address,
                    street: target?.value,
                  }));
                  setValue("street", target?.value);
                }}
              />
            </WrapperInput>
          </WrapperRow>

          <WrapperRow {...buildTestIds("wrapper-row-4")}>
            <WrapperContentHouse
              {...buildTestIds("wrapper-content-house")}
              isdisplayedonbottomsheet={isdisplayedonbottomsheet}
            >
              <WrapperInput {...buildTestIds("wrapper-input-5")}>
                <CustomLabelNewAddress
                  {...buildTestIds("form-label-house-number")}
                  htmlFor="number"
                  text="Número"
                  isRequired
                  hasSuccessfully={validateCheckDone("number")}
                />
                <CustomInputNewAddress
                  {...buildTestIds("form-input-house-number")}
                  id="number"
                  name="number"
                  size="small"
                  type="tel"
                  placeholder="Ex. 1"
                  value={houseNumberWatcher}
                  maxLength={6}
                  register={register as unknown as UseFormRegister<IFormValues>}
                  onChange={({ target }) => {
                    const value = target?.value;
                    if (/^\d*$/.test(value)) {
                      setValue("number", value);
                    }
                  }}
                  disabled={
                    !!noNumberCheckboxWatcher || shouldDisabledAllInputs
                  }
                  hasSuccessfully={Boolean(
                    houseNumberWatcher || noNumberCheckboxWatcher
                  )}
                  hasError={
                    !newAddress?.number &&
                    !noNumberCheckboxWatcher &&
                    (requiredInputStatus || !!errors?.number)
                  }
                  required={
                    noNumberCheckboxWatcher ? undefined : "Campo obrigatório"
                  }
                />
              </WrapperInput>
              <WrapperInput {...buildTestIds("wrapper-input-6")}>
                <AlignWrapperInput {...buildTestIds("align-wrapper-input")}>
                  <CustomCheckboxNewAddress
                    {...buildTestIds("checkbox-no-number")}
                    id="noNumber"
                    color={theme.colors.secondary["300"]}
                    name="noNumber"
                    register={
                      register as unknown as UseFormRegister<IFormValues>
                    }
                    isCheckedEvent={() => {
                      setValue("noNumber", !noNumberCheckboxWatcher);
                      setValue("number", "");
                    }}
                    checked={noNumberCheckboxWatcher}
                  />
                  <CustomLabelNewAddress
                    {...buildTestIds("checkbox-text-no-number")}
                    htmlFor="noNumber"
                    text="Sem número"
                  />
                </AlignWrapperInput>
              </WrapperInput>
            </WrapperContentHouse>
          </WrapperRow>
          <WrapperRow isFlex={isFlexColumn} {...buildTestIds("wrapper-row-6")}>
            <WrapperInput {...buildTestIds("wrapper-input-8")}>
              <CustomLabelNewAddress
                {...buildTestIds("form-label-addressComplement")}
                htmlFor="addressComplement"
                text="Complemento"
              />
              <CustomInputNewAddress
                {...buildTestIds("form-input-addressComplement")}
                id="addressComplement"
                name="addressComplement"
                size="large"
                type="text"
                hasError={false}
                placeholder="Apartamento, bloco, edifício, andar, etc"
                value={addressComplementWatcher}
                register={register as unknown as UseFormRegister<IFormValues>}
                onChange={({ target }) =>
                  setValue("addressComplement", target.value)
                }
                hasSuccessfully={Boolean(addressComplementWatcher)}
              />
            </WrapperInput>
            <WrapperInput {...buildTestIds("wrapper-input-7")}>
              <CustomLabelNewAddress
                {...buildTestIds("form-label-referencePoint")}
                htmlFor="referencePoint"
                text="Ponto de referência"
              />
              <CustomInputNewAddress
                {...buildTestIds("form-input-referencePoint")}
                id="referencePoint"
                name="referencePoint"
                size="large"
                type="text"
                hasError={false}
                value={referencePointWatcher}
                register={register as unknown as UseFormRegister<IFormValues>}
                onChange={({ target }) =>
                  setValue("referencePoint", target.value)
                }
                hasSuccessfully={Boolean(referencePointWatcher)}
              />
            </WrapperInput>
          </WrapperRow>
          <WrapperRow {...buildTestIds("wrapper-row-5")}>
            <WrapperInput {...buildTestIds("wrapper-input-6")}>
              <CustomLabelNewAddress
                {...buildTestIds("form-label-recipient")}
                htmlFor="recipient"
                isRequired
                text="Nome do destinatário"
              />
              <CustomInputNewAddress
                {...buildTestIds("form-input-recipient")}
                id="recipient"
                name="recipient"
                size="large"
                type="text"
                value={recipientWatcher}
                required="Campo obrigatório"
                register={register as unknown as UseFormRegister<IFormValues>}
                onChange={({ target }) => setValue("recipient", target.value)}
                hasError={
                  !newAddress?.recipient &&
                  (requiredInputStatus || !!errors?.recipient)
                }
                hasSuccessfully={Boolean(recipientWatcher)}
              />
            </WrapperInput>
          </WrapperRow>
          <ContainerRequiredWarning>
            <RequiredWarning />
          </ContainerRequiredWarning>

          {optionButton === "buttonModal" && hasSpaceBottom ? (
            <SpaceMobileBottom {...buildTestIds("space-mobile-bottom")} />
          ) : null}
          <FormNewAddressContent
            {...buildTestIds("form-new-address-content")}
            isCheckoutPage={isCheckoutPage}
          >
            {(!purchaseScreen || isCEPValid) && (
              <Fragment key="fragment-buttons">
                {optionButton === "buttonModal" ? (
                  <BoxButton {...buildTestIds("box-button")}>
                    <ButtonRegisterAction
                      {...buildTestIds("button-register-action")}
                      text={
                        newAddressData
                          ? "Salvar Alterações"
                          : "Adicionando novo endereço"
                      }
                      textUnderAction={
                        newAddressData
                          ? "Salvar Alterações"
                          : "Adicionando novo endereço"
                      }
                      buttonType="button"
                      color={theme.colors.secondary["265"]}
                      hoveredColor={theme.colors.secondary["600"]}
                      onClickAction={controlAction}
                      underAction={isLoadingButton}
                    />
                  </BoxButton>
                ) : (
                  hasDefaultButton && (
                    <BoxButtonDefault {...buildTestIds("box-button-default")}>
                      <ButtonDefaultForm
                        {...buildTestIds("button-save-address")}
                        disabled={isLoadingButton || !verifyFieldsForm}
                        onClick={controlAction}
                        purchaseScreen={purchaseScreen ?? false}
                      >
                        {isLoadingButton ? (
                          <>
                            <IconLoading />
                            Salvando endereço
                          </>
                        ) : (
                          `Salvar Endereço`
                        )}
                      </ButtonDefaultForm>
                    </BoxButtonDefault>
                  )
                )}
              </Fragment>
            )}
          </FormNewAddressContent>
        </>
      )}
    </FormNewAddressContainer>
  );
};

export { FormNewAddress };
