import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Elo = ({
  width = 65,
  height = 40,
  alt = "Elo",
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.yellow["500"],
  thirdColor = theme.colors.tertiary["900"],
  fourthColor = theme.colors.primary["850"],
  fifthColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 45 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M2.86889 0H42.0039C43.2947 0 44.3403 1.06411 44.3403 2.3779V25.6221C44.3403 26.9359 43.2947 28 42.0039 28H2.86889C1.57803 28 0.532471 26.9359 0.532471 25.6221V2.3779C0.532471 1.06411 1.57803 0 2.86889 0Z"
        fill={color}
      />
      <path
        d="M9.90796 10.1686C10.2891 10.0396 10.6984 9.97236 11.1244 9.97236C12.9854 9.97236 14.5382 11.2953 14.8913 13.0498L17.526 12.5117C16.9206 9.52952 14.2859 7.28162 11.1244 7.28162C10.4013 7.28162 9.70616 7.39939 9.05591 7.61795L9.90796 10.1686Z"
        fill={secondColor}
      />
      <path
        d="M6.79675 18.7115L8.57934 16.6935C7.78334 15.9872 7.28444 14.9614 7.28444 13.8122C7.28444 12.6687 7.78334 11.6373 8.57934 10.9366L6.79675 8.92419C5.4458 10.1237 4.59375 11.8671 4.59375 13.8179C4.59375 15.763 5.4458 17.5119 6.79675 18.7115Z"
        fill={thirdColor}
      />
      <path
        d="M14.8913 14.5858C14.5326 16.3404 12.9854 17.6577 11.1244 17.6577C10.6983 17.6577 10.2891 17.5904 9.90234 17.4615L9.05029 20.012C9.70054 20.2306 10.4012 20.3484 11.1244 20.3484C14.2859 20.3484 16.9206 18.1061 17.526 15.124L14.8913 14.5858Z"
        fill={fourthColor}
      />
      <path
        d="M26.3042 16.3908C25.867 16.8169 25.2784 17.0747 24.6281 17.0635C24.1797 17.0579 23.7649 16.9233 23.4173 16.6991L22.5428 18.0894C23.1426 18.4649 23.8433 18.6891 24.6057 18.7003C25.7099 18.7171 26.719 18.2799 27.4533 17.568L26.3042 16.3908ZM24.7234 10.7235C22.526 10.6899 20.7098 12.4501 20.6762 14.6475C20.665 15.4715 20.9004 16.2451 21.3208 16.8841L28.5408 13.7954C28.1372 12.0633 26.5901 10.7571 24.7234 10.7235ZM22.3298 14.9613C22.3186 14.8716 22.313 14.7763 22.313 14.681C22.3354 13.3861 23.4005 12.3491 24.6954 12.3716C25.4017 12.3827 26.0295 12.7023 26.4499 13.2068L22.3298 14.9613ZM30.8111 8.92969V16.6262L32.1453 17.1811L31.5118 18.7003L30.1889 18.1509C29.8918 18.022 29.69 17.8258 29.5386 17.6016C29.3928 17.3773 29.2807 17.0634 29.2807 16.6486V8.92969H30.8111ZM35.6264 12.4892C35.8618 12.4108 36.1085 12.3715 36.3719 12.3715C37.5099 12.3715 38.4517 13.1787 38.6702 14.2494L40.279 13.9187C39.9091 12.0969 38.3002 10.7291 36.3719 10.7291C35.9291 10.7291 35.503 10.802 35.1107 10.9309L35.6264 12.4892ZM33.726 17.6969L34.8135 16.4692C34.3258 16.0376 34.0231 15.4098 34.0231 14.7091C34.0231 14.0084 34.3314 13.3806 34.8135 12.9545L33.726 11.7269C32.902 12.4557 32.3807 13.5263 32.3807 14.7147C32.3807 15.9031 32.902 16.9681 33.726 17.6969ZM38.6702 15.1855C38.4517 16.2562 37.5042 17.0634 36.3719 17.0634C36.1141 17.0634 35.8618 17.0185 35.6264 16.9401L35.105 18.4985C35.503 18.6329 35.9291 18.7059 36.3719 18.7059C38.3002 18.7059 39.9091 17.3381 40.279 15.5163L38.6702 15.1855Z"
        fill={fifthColor}
      />
    </svg>
  );
};
