import {
  buildTestIds,
  useAppContext,
  useAuth,
  useRegisterPhoneNumber,
  useShallow,
  useUser,
} from "application";
import { identityApi } from "implementations";
import { useCallback, useEffect, useState } from "react";
import { theme } from "ui";
import { ConfirmPaymentModal } from "../ConfirmPaymentModal";
import { BlockScreen, ButtonContainer } from "./styles";
import { RegisterPhoneNumber } from "../RegisterPhoneNumber";

interface ConfirmPaymentProps {
  doPayment: () => void;
  isDoingPayment: boolean;
  isButtonDisabled?: boolean;
  isClosedModal?: boolean;
  modalText?: string;
  formSubmitButtonId?: string;
  callbackBeforeOpenModal?: () => void;
}

const ConfirmPayment = ({
  doPayment,
  isDoingPayment,
  isButtonDisabled = false,
  modalText,
  formSubmitButtonId,
  isClosedModal = true,
  callbackBeforeOpenModal,
}: ConfirmPaymentProps) => {
  const { isClientMobile } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;
  const { loggedClient } = useUser();

  const {
    setIsRegisterPhoneNumberModalOpen,
    changeCurrentStep,
    openCheckoutModal,
    setOpenCheckoutModal,
    isRegisterPhoneNumberModalOpen,
  } = useRegisterPhoneNumber(
    useShallow((state) => ({
      setIsRegisterPhoneNumberModalOpen:
        state.setIsRegisterPhoneNumberModalOpen,
      changeCurrentStep: state.changeCurrentStep,
      openCheckoutModal: state.openCheckoutModal,
      setOpenCheckoutModal: state.setOpenCheckoutModal,
      isRegisterPhoneNumberModalOpen: state.isRegisterPhoneNumberModalOpen,
    }))
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setOpenCheckoutModal(false);
  }, [setOpenCheckoutModal]);

  useEffect(() => {
    if (!isClosedModal && !isDoingPayment) {
      handleCloseModal();
    }
  }, [isDoingPayment, isClosedModal, handleCloseModal]);

  const verifyIfUserNeedsToRegisterPhone = async () => {
    const confirmationTypesResponse =
      await identityApi.receiveConfirmationTypes({
        cpf: String(loggedClient?.cpf),
        email: String(loggedClient?.email),
        jwt: accessToken,
      });

    if (
      (confirmationTypesResponse?.data?.phoneNumber === "" ||
        !confirmationTypesResponse?.data?.phoneNumber) &&
      (!loggedClient?.phoneNumber || loggedClient?.phoneNumber?.length < 10)
    ) {
      changeCurrentStep("modalCall");
      setIsRegisterPhoneNumberModalOpen(true);
      return;
    }

    setIsModalOpen(true);
  };

  return (
    <>
      {isDoingPayment && <BlockScreen />}
      <ButtonContainer
        {...buildTestIds("confirm-payment-button")}
        isClientMobile={isClientMobile}
        onClick={(e) => {
          if (callbackBeforeOpenModal) {
            callbackBeforeOpenModal();
          }

          e.preventDefault();
          verifyIfUserNeedsToRegisterPhone();
        }}
        disabled={isButtonDisabled}
        isButtonDisabled={isButtonDisabled}
        type="button"
        form={formSubmitButtonId}
      >
        Finalizar pedido
      </ButtonContainer>

      <ConfirmPaymentModal
        {...buildTestIds("confirm-payment-modal")}
        form="whatsAppForm"
        isOpen={isModalOpen || openCheckoutModal}
        onCloseModal={handleCloseModal}
        width="630px"
        height="15rem"
        title="Finalizar pedido"
        buttonText="Finalizar pedido"
        buttonUnderAction={isDoingPayment}
        buttonTextUnderAction="Finalizando pedido..."
        buttonSize={{ maxWidth: "596px", minWidth: "264px" }}
        modalButtonColor={theme.colors.secondary["250"]}
        onClickOnButton={() => {
          doPayment();
        }}
        buttonHoverColor={theme.colors.secondary["700"]}
        footerGap="5.5rem"
        modalButtonPadding="2rem"
        modalText={modalText}
        isDoingPayment={isDoingPayment}
      />

      {isRegisterPhoneNumberModalOpen && <RegisterPhoneNumber />}
    </>
  );
};

export { ConfirmPayment };
