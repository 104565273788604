import { NewAddressFormComponents } from "typing";
import { theme } from "ui";
import { CustomInputNewAddress, WrapperInput } from "../../../styles";

type CityComponentProps = NewAddressFormComponents;

export const CityComponent = ({
  control,
  requiredFieldText,
  hasError,
  disabled,
}: CityComponentProps) => {
  return (
    <WrapperInput>
      <CustomInputNewAddress
        id="city"
        name="city"
        type="text"
        required={requiredFieldText}
        placeholder="Ex. Recife"
        margin={`${theme.space.x2} ${theme.space.x0}`}
        width="100%"
        disabled={disabled}
        control={control}
        hasError={hasError}
        label="Cidade"
      />
    </WrapperInput>
  );
};
