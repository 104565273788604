import { GridPlaylistsSubComponentDto } from "typing";
import { appMonitoringClient } from "implementations";
import { buildTestIds, useCurrentPathName } from "application";
import {
  GridPlaylistsImage,
  GridPlaylistsCardStyled,
  GridPlaylistsDescription,
  GridPlaylistsDescriptionContainer,
  GridPlaylistsCardContent,
} from "./styles";
import { Image } from "../../../Image";
import { Link } from "../../../Link";
import { customGridPlaylistsCardData } from "./data";
import { useGetBranchLinkConfig } from "../../utils";

interface CustomGridPlaylistsCardProps extends GridPlaylistsSubComponentDto {
  positionComponent: number | undefined;
  index: number;
}

const CustomGridPlaylistsCard = ({
  config,
  properties,
  positionComponent,
  index,
}: CustomGridPlaylistsCardProps) => {
  const linkConfig = useGetBranchLinkConfig(
    config?.branches,
    "subComponentGridPlaylists"
  );

  const { image, title } = customGridPlaylistsCardData(properties);

  const currentPathName = useCurrentPathName();

  if (!config?.branches?.length) {
    appMonitoringClient.captureMessage(
      `Um subComponente de Playlists em Grade, localizado em "${currentPathName}" na posição ${positionComponent}, não possui nenhuma configuração de branches.`,
      "warning"
    );
  }

  if (!image) {
    appMonitoringClient.captureMessage(
      `O subcomponente ${index} de Playlists em Grade na posição ${positionComponent} localizado em "${currentPathName}", não possui imagem.`,
      "error"
    );
  }

  return (
    <GridPlaylistsCardStyled
      {...buildTestIds("custom-grid-playlists-card")}
      as={linkConfig?.url ? Link : "div"}
      href={`/${linkConfig?.url}`}
      target={linkConfig?.target}
    >
      <GridPlaylistsCardContent
        {...buildTestIds("custom-grid-playlists-card-content")}
      >
        <GridPlaylistsImage
          {...buildTestIds("custom-grid-playlists-card-image")}
        >
          <Image src={image} alt="Imagem do Card" width={65} height={65} />
        </GridPlaylistsImage>
        <GridPlaylistsDescriptionContainer
          {...buildTestIds("custom-grid-playlists-card-description-container")}
        >
          <GridPlaylistsDescription
            {...buildTestIds("custom-grid-playlists-card-description")}
          >
            {title}
          </GridPlaylistsDescription>
        </GridPlaylistsDescriptionContainer>
      </GridPlaylistsCardContent>
    </GridPlaylistsCardStyled>
  );
};

export default CustomGridPlaylistsCard;
