import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

//
export const DoubleCard = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral.white,
  secondColor = theme.colors.primary["80"],
  thirdColor = theme.colors.neutral["40"],
  fourthColor = theme.colors.tertiary["110"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill={color} />
      <rect
        x="20.2812"
        y="3"
        width="15"
        height="10"
        rx="1.5"
        transform="rotate(83.8457 20.2812 3)"
        fill={secondColor}
      />
      <rect
        width="3"
        height="0.5"
        rx="0.25"
        transform="matrix(0.107206 0.994237 0.994237 -0.107206 13.536 5.73891)"
        fill={thirdColor}
      />
      <rect
        width="5"
        height="0.5"
        rx="0.25"
        transform="matrix(0.107206 0.994237 0.994237 -0.107206 12.5417 5.84612)"
        fill={thirdColor}
      />
      <rect
        x="9.07108"
        y="4.16116"
        width="15"
        height="10"
        rx="1.5"
        transform="rotate(45 9.07108 4.16116)"
        fill={fourthColor}
      />
      <rect
        x="7.65686"
        y="5.57538"
        width="15"
        height="2.5"
        transform="rotate(45 7.65686 5.57538)"
        fill={fourthColor}
      />
      <rect
        width="3"
        height="0.5"
        rx="0.25"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 5.53554 10.5251)"
        fill={thirdColor}
      />
      <rect
        width="5"
        height="0.5"
        rx="0.25"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 4.82843 11.2322)"
        fill={thirdColor}
      />
    </svg>
  );
};
