import { IconProps } from "typing";

export const AuthenticationIcon = ({
  alt = "Ícone de Autenticação",
  width = 132,
  height = 131,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 132 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="66" cy="65.5" r="65.5" fill="#F6F7F7" />
      <path
        d="M98.2721 82.9386L102.836 75.0249"
        stroke="#A0A2A4"
        strokeWidth="2.35285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.4815 81.3294L98.2717 82.9387"
        stroke="#A0A2A4"
        strokeWidth="2.35285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.3311 78.1222L100.121 79.7315"
        stroke="#A0A2A4"
        strokeWidth="2.35285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.691 75.3994C103.684 75.9381 101.62 74.7478 101.081 72.7409C100.543 70.734 101.733 68.6703 103.74 68.1316C105.747 67.5928 107.811 68.7831 108.349 70.79C108.888 72.797 107.698 74.8606 105.691 75.3994"
        stroke="#A0A2A4"
        strokeWidth="2.35285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.283 84.9563L120.829 80.6493"
        stroke="#C3C4C5"
        strokeWidth="1.42191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.764 86.1741L124.282 84.9562"
        stroke="#C3C4C5"
        strokeWidth="1.42191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.364 84.4287L122.883 83.2109"
        stroke="#C3C4C5"
        strokeWidth="1.42191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.666 79.1238C121.529 80.3721 120.405 81.2728 119.157 81.1356C117.909 80.9985 117.008 79.8753 117.145 78.627C117.283 77.3787 118.406 76.478 119.654 76.6152C120.902 76.7523 121.803 77.8755 121.666 79.1238"
        stroke="#C3C4C5"
        strokeWidth="1.42191"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.8865 43.1952L88.763 36.4157"
        stroke="#C3C4C5"
        strokeWidth="2.35285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.496 45.3543L94.8864 43.1952"
        stroke="#C3C4C5"
        strokeWidth="2.35285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.0146 42.6067L92.4049 40.4477"
        stroke="#C3C4C5"
        strokeWidth="2.35285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.9992 33.8145C89.8937 35.8899 88.1258 37.4867 86.0505 37.3812C83.9752 37.2757 82.3783 35.5078 82.4839 33.4325C82.5894 31.3571 84.3573 29.7603 86.4326 29.8658C88.5079 29.9713 90.1047 31.7392 89.9992 33.8145"
        stroke="#C3C4C5"
        strokeWidth="2.35285"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8969 79.4699L31.6768 72.9162"
        stroke="#A0A2A4"
        strokeWidth="1.94851"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5857 78.1372L27.8964 79.4699"
        stroke="#A0A2A4"
        strokeWidth="1.94851"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1179 75.4812L29.4286 76.8139"
        stroke="#A0A2A4"
        strokeWidth="1.94851"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0411 73.2265C32.3791 73.6727 30.67 72.687 30.2239 71.0249C29.7777 69.3629 30.7634 67.6538 32.4255 67.2077C34.0875 66.7616 35.7966 67.7473 36.2427 69.4093C36.6889 71.0714 35.7032 72.7804 34.0411 73.2265"
        stroke="#A0A2A4"
        strokeWidth="1.94851"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.627 38.6971L111.927 34.7102"
        stroke="#C3C4C5"
        strokeWidth="1.18536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.222 37.8863L109.628 38.6971"
        stroke="#C3C4C5"
        strokeWidth="1.18536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.154 36.2706L110.559 37.0814"
        stroke="#C3C4C5"
        strokeWidth="1.18536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.365 34.8988C112.354 35.1702 111.314 34.5705 111.043 33.5594C110.772 32.5484 111.371 31.5087 112.382 31.2373C113.393 30.9659 114.433 31.5655 114.705 32.5766C114.976 33.5877 114.376 34.6274 113.365 34.8988"
        stroke="#C3C4C5"
        strokeWidth="1.18536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.6372 20.418L79.9367 16.4311"
        stroke="#C3C4C5"
        strokeWidth="1.18536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.2317 19.6073L77.6374 20.418"
        stroke="#C3C4C5"
        strokeWidth="1.18536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1633 17.9916L78.5691 18.8023"
        stroke="#C3C4C5"
        strokeWidth="1.18536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.375 16.6197C80.3639 16.8911 79.3242 16.2915 79.0528 15.2804C78.7814 14.2693 79.3811 13.2296 80.3922 12.9582C81.4033 12.6868 82.4429 13.2865 82.7143 14.2975C82.9857 15.3086 82.3861 16.3483 81.375 16.6197"
        stroke="#C3C4C5"
        strokeWidth="1.18536"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.943 28.049L42.7299 19.7492"
        stroke="#A0A2A4"
        strokeWidth="2.46765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0167 26.3613L37.943 28.0491"
        stroke="#A0A2A4"
        strokeWidth="2.46765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.9561 22.9975L39.8825 24.6854"
        stroke="#A0A2A4"
        strokeWidth="2.46765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.724 20.1423C43.6191 20.7073 41.4548 19.459 40.8898 17.3541C40.3247 15.2492 41.5731 13.0849 43.6779 12.5199C45.7828 11.9549 47.9472 13.2032 48.5122 15.308C49.0772 17.4129 47.8289 19.5773 45.724 20.1423"
        stroke="#A0A2A4"
        strokeWidth="2.46765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0235 48.9445L21.2929 50.7995C20.5492 51.0045 19.7807 50.5682 19.5758 49.8244L18.2773 45.113C18.0723 44.3692 18.5086 43.6008 19.2524 43.3958L25.983 41.5408C26.7268 41.3358 27.4952 41.7722 27.7001 42.5159L28.9987 47.2274C29.2036 47.9711 28.7673 48.7395 28.0235 48.9445Z"
        stroke="#C3C4C5"
        strokeWidth="1.77471"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9255 43.2105L19.369 41.1913V41.1913C18.9592 39.7045 19.8324 38.1668 21.3192 37.757V37.757C22.806 37.3472 24.3437 38.2205 24.7535 39.7073V39.7073L25.31 41.7265"
        stroke="#C3C4C5"
        strokeWidth="1.77471"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.745 104.145L100.12 100.01C99.4985 99.5528 99.3651 98.6792 99.8221 98.0577L102.717 94.1201C103.174 93.4986 104.047 93.3652 104.669 93.8222L110.294 97.9576C110.916 98.4145 111.049 99.2881 110.592 99.9097L107.697 103.847C107.24 104.469 106.367 104.602 105.745 104.145Z"
        stroke="#C3C4C5"
        strokeWidth="1.77471"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.231 94.2356L106.472 92.5481V92.5481C107.385 91.3055 109.133 91.0387 110.376 91.9522V91.9522C111.619 92.8658 111.885 94.6138 110.972 95.8564V95.8564L109.731 97.5439"
        stroke="#C3C4C5"
        strokeWidth="1.77471"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.42 59.6071L112.689 61.4621C111.946 61.6671 111.177 61.2308 110.972 60.487L109.674 55.7756C109.469 55.0318 109.905 54.2634 110.649 54.0584L117.379 52.2034C118.123 51.9984 118.892 52.4348 119.097 53.1785L120.395 57.89C120.6 58.6337 120.164 59.4021 119.42 59.6071Z"
        stroke="#A0A2A4"
        strokeWidth="1.77471"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.322 53.8731L110.765 51.8539V51.8539C110.356 50.3671 111.229 48.8294 112.716 48.4196V48.4196C114.202 48.0098 115.74 48.8831 116.15 50.3699V50.3699L116.706 52.3891"
        stroke="#A0A2A4"
        strokeWidth="1.77471"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.965 60.0862L98.6369 63.3051C98.1586 63.6608 97.4832 63.5616 97.1275 63.0833L94.8742 60.0536C94.5186 59.5754 94.6178 58.8999 95.0961 58.5442L99.4242 55.3253C99.9025 54.9696 100.578 55.0689 100.934 55.5471L103.187 58.5768C103.543 59.0551 103.443 59.7305 102.965 60.0862Z"
        stroke="#C3C4C5"
        strokeWidth="1.37112"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.5296 58.2226L94.5639 56.9241V56.9241C93.8529 55.968 94.0515 54.6164 95.0076 53.9053V53.9053C95.9637 53.1943 97.3154 53.3929 98.0264 54.349V54.349L98.9921 55.6474"
        stroke="#C3C4C5"
        strokeWidth="1.37112"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5313 69.6073L7.08099 68.2086C6.58922 68.054 6.3161 67.5305 6.47066 67.0387L7.44975 63.9235C7.60431 63.4318 8.1278 63.1586 8.61956 63.3132L13.0699 64.7119C13.5617 64.8664 13.8348 65.3899 13.6802 65.8817L12.7011 68.9969C12.5466 69.4887 12.0231 69.7618 11.5313 69.6073Z"
        stroke="#A0A2A4"
        strokeWidth="1.18582"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06485 63.4531L9.48446 62.118V62.118C9.79344 61.1349 10.841 60.5884 11.8241 60.8974V60.8974C12.8072 61.2063 13.3537 62.2539 13.0447 63.237V63.237L12.6251 64.5721"
        stroke="#A0A2A4"
        strokeWidth="1.18582"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7132 58.3377L36.3521 59.7831C35.9807 59.9429 35.5505 59.7714 35.3907 59.4L34.3789 57.0472C34.2192 56.6758 34.3907 56.2456 34.7621 56.0859L38.1232 54.6405C38.4946 54.4807 38.9248 54.6522 39.0845 55.0236L40.0963 57.3764C40.256 57.7478 40.0846 58.178 39.7132 58.3377Z"
        stroke="#C3C4C5"
        strokeWidth="0.930047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0987 55.9414L34.6651 54.9331V54.9331C34.3458 54.1906 34.6889 53.3297 35.4314 53.0104V53.0104C36.1738 52.6911 37.0347 53.0342 37.354 53.7767V53.7767L37.7876 54.785"
        stroke="#C3C4C5"
        strokeWidth="0.930047"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.4264 25.1239L89.8013 22.5515C89.1797 22.2673 88.9065 21.5336 89.1907 20.912L90.9914 16.9744C91.2756 16.3528 92.0093 16.0796 92.6309 16.3638L98.2561 18.9362C98.8776 19.2205 99.1509 19.9542 98.8666 20.5757L97.066 24.5134C96.7817 25.1349 96.048 25.4082 95.4264 25.1239Z"
        stroke="#A0A2A4"
        strokeWidth="1.57233"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.1935 16.6212L93.9652 14.9337V14.9337C94.5334 13.6911 96.0016 13.1443 97.2442 13.7125V13.7125C98.4868 14.2808 99.0336 15.749 98.4653 16.9916V16.9916L97.6936 18.6791"
        stroke="#A0A2A4"
        strokeWidth="1.57233"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5489 102.259L22.8183 104.114C22.0746 104.319 21.3061 103.882 21.1012 103.139L19.8026 98.4272C19.5977 97.6834 20.034 96.915 20.7778 96.7101L27.5084 94.855C28.2521 94.6501 29.0206 95.0864 29.2255 95.8302L30.5241 100.542C30.729 101.285 30.2927 102.054 29.5489 102.259Z"
        stroke="#A0A2A4"
        strokeWidth="1.77471"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4509 96.5247L20.8944 94.5055V94.5055C20.4846 93.0187 21.3578 91.481 22.8446 91.0712V91.0712C24.3314 90.6615 25.8691 91.5347 26.2789 93.0215V93.0215L26.8354 95.0407"
        stroke="#A0A2A4"
        strokeWidth="1.77471"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="65.2382"
        cy="114.397"
        rx="25.1337"
        ry="1.67558"
        fill="#C3C4C5"
      />
      <path
        d="M59.9072 24.1183V20.3102V20.3102C59.9072 17.5061 62.1807 15.2327 64.9847 15.2327V15.2327C67.7888 15.2327 70.0623 17.5061 70.0623 20.3102V20.3102V24.1183"
        stroke="#53565A"
        strokeWidth="3.22674"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="43.8834"
        y="28.1507"
        width="42.7104"
        height="77.7453"
        rx="5.12389"
        fill="#53565A"
        stroke="#53565A"
        strokeWidth="1.46397"
      />
      <rect
        x="45.3176"
        y="31.1081"
        width="36.7959"
        height="70.3075"
        rx="4.49933"
        stroke="#53565A"
        strokeWidth="1.28552"
      />
      <path
        d="M58.3838 30.4653H69.4397C68.7621 31.8205 67.377 32.6765 65.8619 32.6765H61.9615C60.4464 32.6765 59.0614 31.8205 58.3838 30.4653Z"
        fill="#53565A"
      />
      <rect
        x="45.3176"
        y="29.5847"
        width="39.8424"
        height="74.8773"
        rx="4.49933"
        fill="#F6F7F7"
        stroke="#53565A"
        strokeWidth="1.28552"
      />
      <path
        d="M58.3848 29.3762H70.7016C69.9547 31.1304 68.2324 32.2691 66.3258 32.2691H62.7606C60.854 32.2691 59.1317 31.1304 58.3848 29.3762Z"
        fill="#53565A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.568 55.0489L79.2346 75.4666C80.5903 77.8399 78.8766 80.7935 76.1439 80.7935H52.8107C50.0765 80.7935 48.3628 77.8399 49.72 75.4666L61.3866 55.0489C62.753 52.6559 66.2016 52.6559 67.568 55.0489Z"
        fill="#D9A900"
      />
      <path
        d="M64.4774 68.7294V63.0325"
        stroke="#F6F7F7"
        strokeWidth="3.87209"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4759 73.3066C64.2657 73.3066 64.0951 73.4772 64.0966 73.6874C64.0966 73.8976 64.2672 74.0682 64.4775 74.0682C64.6877 74.0682 64.8583 73.8976 64.8583 73.6874C64.8583 73.4772 64.6877 73.3066 64.4759 73.3066"
        stroke="#F6F7F7"
        strokeWidth="3.87209"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
