import { IUniqueClientContent } from "typing";
import { buildTestIds } from "application";
import {
  UniqueClientContentButtons,
  UniqueClientContentContainer,
  UniqueClientContentDescription,
  UniqueClientContentImage,
  UniqueClientContentInformation,
  UniqueClientContentTitle,
  UniqueClientPrimaryButton,
  UniqueClientSecondaryButton,
} from "./styles";

const UniqueClientContentUI = ({
  description,
  image,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonLabel,
  secondaryButtonLabel,
  title,
}: IUniqueClientContent) => {
  return (
    <UniqueClientContentContainer
      {...buildTestIds("unique-client-content-container")}
    >
      <UniqueClientContentImage
        {...buildTestIds("unique-client-content-image")}
      >
        {image}
      </UniqueClientContentImage>
      <UniqueClientContentInformation
        {...buildTestIds("unique-client-content-information")}
      >
        <UniqueClientContentTitle
          {...buildTestIds("unique-client-content-title")}
        >
          {title}
        </UniqueClientContentTitle>
        <UniqueClientContentDescription
          {...buildTestIds("unique-client-content-description")}
        >
          {description}
        </UniqueClientContentDescription>
      </UniqueClientContentInformation>
      <UniqueClientContentButtons
        {...buildTestIds("unique-client-content-buttons")}
      >
        <UniqueClientPrimaryButton
          {...buildTestIds("unique-client-primary-button")}
          onClick={onPrimaryButtonClick}
        >
          {primaryButtonLabel}
        </UniqueClientPrimaryButton>
        <UniqueClientSecondaryButton
          {...buildTestIds("unique-client-secondary-button")}
          onClick={onSecondaryButtonClick}
        >
          {secondaryButtonLabel}
        </UniqueClientSecondaryButton>
      </UniqueClientContentButtons>
    </UniqueClientContentContainer>
  );
};

export { UniqueClientContentUI };
