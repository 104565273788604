import styled from "styled-components";
import { Column, theme, H2 } from "ui";

export const FooterIconAndTitleContent = styled(Column)``;

export const WrapperUtilInfoOption = styled(Column)`
  gap: ${theme.space.x8};

  @media ${theme.device.desktopFirst.mobileM} {
    flex-wrap: wrap;
    gap: ${theme.space.x0};
  }
`;

export const PaymentOptionsTitle = styled(H2)`
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x9};
  margin: 0;

  ${({ color }) => color && `color: ${color};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x7};
  }
`;

export const PaymentOptionsWrapper = styled.div``;
