import { IconProps } from "typing";
import { theme } from "../themes";

export const LockIcon = ({
  alt = "Cadeado",
  width = 33,
  height = 43.3,
  color = theme.colors.neutral["999"],
  strokeWidth,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 79 79"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M26.3333 32.9167V23.0417V23.0417C26.3333 15.7704 32.2286 9.875 39.4999 9.875V9.875C46.7712 9.875 52.6666 15.7704 52.6666 23.0417V23.0417V32.9167"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9583 69.1248H23.0416C19.4043 69.1248 16.4583 66.1788 16.4583 62.5415V39.4998C16.4583 35.8625 19.4043 32.9165 23.0416 32.9165H55.9583C59.5955 32.9165 62.5416 35.8625 62.5416 39.4998V62.5415C62.5416 66.1788 59.5955 69.1248 55.9583 69.1248Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5 51C40.5 53.46 40.278 55.6719 39.9261 57.2555C39.7493 58.0513 39.5454 58.6613 39.3359 59.0602C39.2308 59.2601 39.1369 59.3826 39.0644 59.4493C39.0328 59.4784 39.0115 59.4915 39 59.4972C38.9885 59.4915 38.9672 59.4784 38.9356 59.4493C38.8631 59.3826 38.7692 59.2601 38.6641 59.0602C38.4546 58.6613 38.2507 58.0513 38.0739 57.2555C37.722 55.6719 37.5 53.46 37.5 51C37.5 48.54 37.722 46.3281 38.0739 44.7445C38.2507 43.9487 38.4546 43.3387 38.6641 42.9398C38.7692 42.7399 38.8631 42.6174 38.9356 42.5507C38.9672 42.5216 38.9885 42.5085 39 42.5028C39.0115 42.5085 39.0328 42.5216 39.0644 42.5507C39.1369 42.6174 39.2308 42.7399 39.3359 42.9398C39.5454 43.3387 39.7493 43.9487 39.9261 44.7445C40.278 46.3281 40.5 48.54 40.5 51ZM38.9896 59.5012C38.9896 59.5011 38.9905 59.5008 38.9922 59.5005C38.9905 59.5011 38.9896 59.5013 38.9896 59.5012ZM39.0078 59.5005C39.0095 59.5008 39.0104 59.5011 39.0104 59.5012C39.0104 59.5013 39.0095 59.5011 39.0078 59.5005ZM39.0104 42.4988C39.0104 42.4989 39.0095 42.4992 39.0078 42.4995C39.0095 42.4989 39.0104 42.4987 39.0104 42.4988ZM38.9922 42.4995C38.9905 42.4992 38.9896 42.4989 38.9896 42.4988C38.9896 42.4987 38.9905 42.4989 38.9922 42.4995Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
