import styled from "styled-components";
import Select from "react-select";
import { ArrowLeftIcon as ArrowLeft, FlexContainer, theme } from "ui";
import { Input } from "../../../../../../Form";

export const ForgotPasswordWrapper = styled.div`
  margin-top: 2rem;
  border-radius: ${theme.space.x4};
  max-width: 360px;
  margin: 0 auto;

  @media ${theme.device.desktopFirst.mobileXL} {
    max-width: 100%;
  }
`;

export const SelectIdentificationType = styled.select`
  width: 126px;
  height: 3.35rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 4px;
  justify-content: center;
  border: 1px solid ${theme.colors.neutral["580"]};
`;

export const TypeOption = styled.option`
  border-radius: 0;
  height: 3.35rem;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x1};
`;

export const LabelSelect = styled.label`
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight[400]};
  color: ${theme.colors.neutral["750"]} !important;
`;

export const StyledInput = styled(Input)`
  color: ${theme.colors.neutral["550"]} !important;

  &::placeholder {
    color: ${theme.colors.neutral["350"]} !important;
  }
`;

export const ForgotPasswordContainer = styled.div`
  height: auto;
  margin: 0 !important;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: ${theme.space.x0} ${theme.space.x6} ${theme.space.x6};
  }
`;

export const ContentInformation = styled(FlexContainer)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.space["x2.5"]};
  align-items: center;
  justify-content: center;
  height: 32px;

  @media ${theme.device.desktopFirst.mobileXL} {
    align-items: flex-start;
  }
`;

export const ArrowLeftIcon = styled(ArrowLeft).attrs({
  height: "15px",
  width: "15px",
  color: theme.colors.neutral["600"],
})``;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormForgotPassword = styled.form``;

export const ForgotPasswordContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
  gap: ${theme.space.x4};

  ${FormForgotPassword} {
    width: 100%;
  }
`;

export const RecoverAccessText = styled.span`
  color: ${theme.colors.neutral["550"]} !important;
  font-size: ${theme.typography.fontSize.sm.x9};
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight.bold};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x7};
    color: ${theme.colors.neutral["750"]};
  }
`;

export const RecoverInfoText = styled.span`
  color: ${theme.colors.neutral["110"]} !important;
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["400"]};
  white-space: break-spaces;
`;

export const InputForgotPassword = styled.input`
  width: 100%;
  height: 3.35rem;
  font-family: ${theme.typography.fontFamily.chivo};
  padding-left: ${theme.space.x3};
  border: 1px solid ${theme.colors.neutral["580"]};
  border-radius: ${theme.space.x1};
  background: transparent;
  color: ${theme.colors.neutral["999"]};

  &::placeholder {
    color: ${theme.colors.neutral["350"]};
    font-size: ${theme.typography.fontSize.sm.x5};
    font-weight: ${theme.typography.fontWeight["400"]};
  }

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  > * {
    box-shadow: none !important;
    border-radius: ${theme.space.x1};
    cursor: pointer;
  }

  .react-select__control {
    height: 40px;
    border-color: ${theme.colors.neutral["200"]};
    cursor: pointer;
  }

  .react-select__menu {
    margin-top: 0;
  }

  .react-select__option {
    border-radius: 4px;
    color: ${theme.colors.primary["200"]} !important;
    cursor: pointer;
    background-color: ${theme.colors.neutral.white};
  }

  .react-select__control:hover {
    border-color: ${theme.colors.neutral["200"]};
  }

  .react-select__option:hover {
    background-color: ${theme.colors.pink["50"]};
    color: ${theme.colors.primary["100"]} !important;
    border-color: ${theme.colors.neutral["200"]};
  }

  .react-select__single-value {
    color: ${theme.colors.neutral["550"]} !important;
  }

  .react-select__option--is-selected {
    background-color: ${theme.colors.primary["250"]};
    color: ${theme.colors.neutral.white} !important;
  }

  .react-select__option--is-selected:hover {
    background-color: ${theme.colors.primary["250"]};
    color: ${theme.colors.neutral.white} !important;
  }

  .react-select__indicator-separator {
    display: none;
  }
`;

export const WrapperIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-12"]};
`;
