import styled from "styled-components";
import { ArrowDown, ArrowUp, theme } from "ui";

type BlackTheme = {
  isBlackTheme?: boolean;
};

interface CollapsedProps extends BlackTheme {
  isClientMobile?: boolean;
}

export const Header = styled.div<CollapsedProps>`
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 0.2rem;
  background: none;

  @media ${theme.device.desktopFirst.tablet} {
    padding: ${theme.space.x0} ${theme.space.x1} ${theme.space.x0}
      ${theme.space["x3.5"]};
    background: ${({ isBlackTheme }) =>
      isBlackTheme
        ? `${theme.colors.blackFriday["175"]}`
        : `${theme.colors.neutral.white}`};
    border: thin solid
      ${({ isBlackTheme }) =>
        isBlackTheme
          ? `${theme.colors.blackFriday["100"]}`
          : `${theme.colors.blackFriday["30"]}`};
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 6px ${theme.colors.neutral["999-opacity-10"]};
  }
`;

export const BoxContent = styled.div<CollapsedProps>`
  background: ${theme.colors.neutral.white};
  border-radius: 0.2rem;
  border: thin solid
    ${({ isBlackTheme }) =>
      isBlackTheme
        ? `${theme.colors.blackFriday["100"]}`
        : `${theme.colors.blackFriday["30"]}`};
`;

export const ArrowContainer = styled.span``;

export const CollapseArrowUp = styled(ArrowUp)<CollapsedProps>``;

export const CollapseArrowDown = styled(ArrowDown)<CollapsedProps>``;

export const CollapsedContainer = styled.div<CollapsedProps>`
  .open {
    display: block;
  }
  .closed {
    display: none;
  }

  .openIcon {
    transform: rotate(90deg);
  }
`;
