import { CarouselThemeDto } from "typing";
import { theme } from "../../../attributes/themes";

export const blackFridayCarouselTheme: CarouselThemeDto = {
  textColor: theme.colors.blackFriday["15"],
  container: theme.colors.blackFriday["185"],
  sliderButton: {
    backgroundColor: {
      default: theme.colors.blackFriday["50"],
      disabled: theme.colors.blackFriday["110"],
      hover: theme.colors.blackFriday["420"],
    },
    arrowColor: {
      default: theme.colors.blackFriday["20"],
      disabled: theme.colors.blackFriday["40"],
      hover: theme.colors.blackFriday.white,
    },
  },
  dots: {
    activated: theme.colors.blackFriday["420"],
    deactivated: theme.colors.blackFriday["25"],
  },
};
