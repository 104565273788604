import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const IconLeftBottomMobile = ({
  width = "201",
  height = "201",
  color = theme.colors.neutral["white-075"],
  secondColor = theme.colors.neutral["150"],
  thirdColor = theme.colors.primary["710"],
  fourthColor = theme.colors.primary["705"],
  fifthColor = theme.colors.secondary["355"],
  sixthColor = theme.colors.secondary["45"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 201 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M66 2H-1V69H66V2Z" fill={color} />
      <path
        d="M25.8881 46.6897C26.6316 46.6897 27.2352 47.2932 27.2352 48.0368L27.2352 55.4195L29.5095 57.2214L33.0259 57.2214L35.3002 55.4195L35.3002 48.0368C35.3002 47.2932 35.9038 46.6897 36.6473 46.6897C37.3908 46.6897 37.9944 47.2932 37.9944 48.0368L37.9944 56.0755C37.9944 56.4867 37.8019 56.8803 37.487 57.134L34.338 59.6357C34.1018 59.8281 33.8044 59.9331 33.4983 59.9331L29.0371 59.9331C28.731 59.9331 28.4336 59.8281 28.1974 59.6357L25.0484 57.134C24.7247 56.8803 24.541 56.4867 24.541 56.0755L24.541 48.0368C24.541 47.2932 25.1446 46.6897 25.8881 46.6897Z"
        fill={secondColor}
      />
      <path
        d="M25.8881 51.4828L36.6473 51.4828C37.3908 51.4828 37.9944 52.0864 37.9944 52.8299C37.9944 53.5734 37.3908 54.177 36.6473 54.177L25.8881 54.177C25.1446 54.177 24.541 53.5734 24.541 52.8299C24.541 52.0864 25.1446 51.4828 25.8881 51.4828Z"
        fill={secondColor}
      />
      <path
        d="M31.2675 8.69986C41.6681 8.69986 50.1267 16.9311 50.1267 27.043C50.1267 30.7693 48.9808 34.347 46.829 37.4085C44.9046 40.1289 42.2891 42.2808 39.2276 43.6716L39.2276 46.4445C39.2276 48.0715 37.8805 49.3923 36.2272 49.3923L26.3078 49.3923C24.6546 49.3923 23.3075 48.0715 23.3075 46.4445L23.3075 43.6716C20.2459 42.2895 17.6305 40.1377 15.7061 37.4085C13.5455 34.3557 12.3996 30.7693 12.3996 27.043C12.3996 16.9311 20.8582 8.69986 31.2588 8.69986M36.2185 46.6894C36.3759 46.6894 36.5159 46.5757 36.5159 46.4445L36.5159 42.7706C36.5159 42.2195 36.857 41.7209 37.3731 41.511C43.4788 39.0968 47.4238 33.411 47.4238 27.0342C47.4238 18.4094 40.1723 11.394 31.2675 11.394C22.3628 11.394 15.1112 18.4094 15.1112 27.0342C15.1112 33.411 19.0563 39.088 25.1619 41.511C25.678 41.7122 26.0104 42.2108 26.0104 42.7706L26.0104 46.4445C26.0104 46.5757 26.1503 46.6894 26.3078 46.6894L36.2272 46.6894L36.2185 46.6894Z"
        fill={secondColor}
      />
      <path d="M132 69H65V136H132V69Z" fill={thirdColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.7357 116.384H93.5357V120.177H76.7357V116.384ZM90.5428 121.434H79.0571V124.842H90.5428V121.434ZM97.1142 94.4383H99.7714V110.826H97.1142V94.4383ZM120.257 88.6091H103.457V84.8159H120.257V88.6091ZM106.45 83.5586H117.936V80.1511H106.45V83.5586ZM117.179 89.8664H106.436C105.886 92.7167 104.757 95.3384 101.686 96.0242C101.714 99.8032 101.736 103.589 101.764 107.368C111.364 105.79 116.386 99.2746 117.179 89.8664ZM95.2642 108.983V102.646L95.2285 97.6244C85.6285 99.2031 80.6071 105.718 79.8142 115.126H90.5571C91.1071 112.283 92.2214 109.683 95.2642 108.976V108.983ZM76.5357 115.133H78.3428C79.1714 104.904 84.7785 97.753 95.2642 96.1385V93.3882C95.2642 92.9382 95.6357 92.5667 96.0857 92.5667H100.557C101.007 92.5667 101.379 92.9382 101.379 93.3882V94.5883C103.686 94.0454 104.507 92.0095 104.943 89.8664H103.264C102.679 89.8664 102.207 89.3878 102.207 88.8092V84.623C102.207 84.0372 102.686 83.5657 103.264 83.5657H105.321V79.7153C105.321 79.3367 105.629 79.0295 106.007 79.0295H118.379C118.757 79.0295 119.064 79.3367 119.064 79.7153V83.5657H120.457C121.043 83.5657 121.514 84.0444 121.514 84.623V88.8092C121.514 89.3949 121.036 89.8664 120.457 89.8664H118.65C117.814 100.21 112.093 107.397 101.386 108.911V111.89C101.386 112.34 101.014 112.712 100.564 112.712H96.0928C95.6428 112.712 95.2714 112.34 95.2714 111.89V110.504C93.2285 111.162 92.4642 113.097 92.0499 115.133H93.7285C94.3142 115.133 94.7857 115.612 94.7857 116.191V120.377C94.7857 120.963 94.3071 121.434 93.7285 121.434H91.6714V125.284C91.6714 125.663 91.3642 125.97 90.9857 125.97H78.6142C78.2357 125.97 77.9285 125.663 77.9285 125.284V121.434H76.5357C75.9499 121.434 75.4785 120.955 75.4785 120.377V116.191C75.4785 115.605 75.9571 115.133 76.5357 115.133Z"
        fill={fourthColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.1715 81.3516C74.2643 91.0955 76.8572 95.8246 81.2572 95.8246C85.6572 95.8246 88.1215 90.9027 81.1715 81.3516ZM82.1857 80.6159C84.1286 83.2876 86.2 86.7594 86.6643 90.0883C86.9714 92.2885 86.55 94.6102 84.6786 96.0175C83.6857 96.7604 82.4786 97.0819 81.25 97.0819C80.0214 97.0819 78.7 96.7461 77.6714 95.9532C72.9143 92.2742 77.6429 84.1591 80.1429 80.6301L81.1572 79.2014L82.1857 80.6159Z"
        fill={fourthColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.4782 93.0024C82.3496 92.6881 82.8282 92.1309 83.0711 91.2451C83.3425 90.2664 84.7139 90.645 84.4425 91.6237C84.0711 92.9596 83.2639 93.8668 81.9568 94.3383C80.9996 94.6883 80.5139 93.3453 81.4711 93.0024"
        fill={fourthColor}
      />
      <path d="M66 136H-1V203H66V136Z" fill={fifthColor} />
      <path
        d="M37.83 166.143H28.7972V170.878H37.83V166.143ZM43.7424 186.5V166.143H39.608V186.5H43.7424ZM45.5204 172.571V186.5H52.8323V172.571H45.5204ZM45.5204 166.136V170.871H54.6818V166.136H45.5204ZM49.5691 164.436H49.155C50.0547 164.071 50.5831 163.407 50.7616 162.85C50.8258 162.636 50.8473 162.436 50.8116 162.271C50.783 162.15 50.7259 162.036 50.6188 161.957C50.3546 161.75 49.8547 161.65 49.055 161.743C46.8914 162 45.0705 163.414 44.0066 164.436H49.5762H49.5691ZM31.6534 164.436C31.4106 164.093 31.2178 163.721 31.1036 163.35C30.9536 162.871 30.9179 162.378 31.0108 161.921C31.1179 161.421 31.3749 160.971 31.8034 160.621C32.4032 160.143 33.3243 159.893 34.6167 160.05C38.4155 160.507 41.2575 163.8 41.7288 164.378C42.2 163.8 45.042 160.507 48.8408 160.05C50.1404 159.893 51.0615 160.143 51.6542 160.621C52.0897 160.964 52.3468 161.414 52.4468 161.921C52.5396 162.378 52.5039 162.871 52.3539 163.35C52.2397 163.721 52.054 164.086 51.8041 164.436H55.3815C55.6529 164.436 55.8957 164.543 56.067 164.721C56.2455 164.9 56.3527 165.143 56.3527 165.407V171.593C56.3527 171.857 56.2455 172.1 56.067 172.278C55.8885 172.457 55.6457 172.564 55.3815 172.564H54.5104V186.914C54.5104 187.264 54.3676 187.578 54.1391 187.807C53.9106 188.043 53.5892 188.186 53.2465 188.186H30.2039C29.854 188.186 29.5327 188.043 29.3042 187.814C29.0757 187.586 28.9328 187.264 28.9328 186.914V172.564H28.0617C27.7904 172.564 27.5476 172.457 27.3762 172.278C27.1977 172.1 27.0906 171.857 27.0906 171.593V165.407C27.0906 165.143 27.1977 164.9 27.3762 164.721C27.5547 164.543 27.7975 164.436 28.0617 164.436H31.6391H31.6534ZM39.4795 164.436C38.4155 163.414 36.5947 162 34.4311 161.743C33.6313 161.65 33.1315 161.743 32.8673 161.957C32.7602 162.043 32.7031 162.15 32.6745 162.271C32.6388 162.436 32.6602 162.636 32.7245 162.85C32.8959 163.407 33.6385 164.128 34.274 164.436H39.4866H39.4795ZM30.6466 186.493H37.8371V172.564H30.6466V186.493Z"
        fill={sixthColor}
      />
      <path
        d="M11.082 163.392C10.8107 163.135 10.5608 162.864 10.3251 162.578V181.357C10.3251 183.014 11.0035 184.528 12.096 185.621C13.1885 186.714 14.7023 187.392 16.3589 187.392C18.0155 187.392 19.5293 186.714 20.6218 185.621C21.7143 184.528 22.3927 183.014 22.3927 181.357V162.578C22.1642 162.871 21.9071 163.142 21.6358 163.392C20.2505 164.692 18.3868 165.492 16.3518 165.492C14.3167 165.492 12.453 164.692 11.0677 163.392M9.46826 157.35H10.2751L10.3251 158.142C10.4251 159.721 11.1392 161.135 12.2245 162.157C13.3027 163.171 14.7523 163.792 16.3446 163.792C17.937 163.792 19.3865 163.171 20.4647 162.157C21.5573 161.135 22.2642 159.714 22.3641 158.142L22.4141 157.35H24.0707V181.357C24.0707 183.485 23.1996 185.421 21.8 186.821C20.4005 188.221 18.4654 189.092 16.3375 189.092C14.2096 189.092 12.2745 188.221 10.875 186.821C9.4754 185.421 8.60425 183.485 8.60425 181.357V157.35H9.45398H9.46826Z"
        fill={sixthColor}
      />
      <path
        d="M20.6288 153.364C19.5363 152.271 18.0296 151.593 16.3587 151.593C14.6878 151.593 13.1812 152.271 12.0887 153.364C10.9962 154.457 10.3178 155.964 10.3178 157.635C10.3178 159.307 10.9962 160.814 12.0887 161.907C13.1812 163 14.6878 163.678 16.3587 163.678C18.0296 163.678 19.5363 163 20.6288 161.907C21.7213 160.814 22.3997 159.307 22.3997 157.635C22.3997 155.964 21.7213 154.457 20.6288 153.364ZM16.3587 149.9C18.4938 149.9 20.4289 150.764 21.8284 152.164C23.228 153.564 24.092 155.5 24.092 157.635C24.092 159.771 23.228 161.707 21.8284 163.107C20.4289 164.507 18.4938 165.371 16.3587 165.371C14.2237 165.371 12.2886 164.507 10.8891 163.107C9.4895 161.707 8.62549 159.771 8.62549 157.635C8.62549 155.5 9.4895 153.564 10.8891 152.164C12.2886 150.764 14.2237 149.9 16.3587 149.9Z"
        fill={sixthColor}
      />
      <path
        d="M17.9369 156.064C17.537 155.664 16.9801 155.414 16.366 155.414C15.7519 155.414 15.1949 155.664 14.7951 156.064C14.3952 156.463 14.1453 157.02 14.1453 157.635C14.1453 158.249 14.3952 158.806 14.7951 159.205C15.1949 159.605 15.7519 159.855 16.366 159.855C16.9801 159.855 17.537 159.605 17.9369 159.205C18.3368 158.806 18.5867 158.249 18.5867 157.635C18.5867 157.02 18.3368 156.463 17.9369 156.064ZM16.366 153.714C17.4442 153.714 18.4296 154.15 19.1365 154.864C19.8434 155.571 20.2862 156.549 20.2862 157.635C20.2862 158.72 19.8506 159.698 19.1365 160.405C18.4296 161.112 17.4513 161.555 16.366 161.555C15.2806 161.555 14.3024 161.119 13.5954 160.405C12.8885 159.698 12.4458 158.72 12.4458 157.635C12.4458 156.549 12.8814 155.571 13.5954 154.864C14.3024 154.157 15.2806 153.714 16.366 153.714Z"
        fill={sixthColor}
      />
      <path
        d="M23.2497 156.785H45.4283C45.921 156.785 46.3637 156.985 46.6851 157.307C47.0064 157.628 47.2063 158.078 47.2063 158.564V158.893C46.6065 159.064 46.0424 159.285 45.5069 159.543V158.564C45.5069 158.564 45.4997 158.521 45.4783 158.507C45.464 158.493 45.4426 158.478 45.4212 158.478H24.0923V183.935H27.4555V185.635H22.3928V156.785H23.2425H23.2497Z"
        fill={sixthColor}
      />
      <path
        d="M37.83 166.143H28.7972V170.878H37.83V166.143ZM43.7424 186.5V166.143H39.608V186.5H43.7424ZM45.5204 172.571V186.5H52.8323V172.571H45.5204ZM45.5204 166.136V170.871H54.6818V166.136H45.5204ZM49.5691 164.436H49.155C50.0547 164.071 50.5831 163.407 50.7616 162.85C50.8258 162.636 50.8473 162.436 50.8116 162.271C50.783 162.15 50.7259 162.036 50.6188 161.957C50.3546 161.75 49.8547 161.65 49.055 161.743C46.8914 162 45.0705 163.414 44.0066 164.436H49.5762H49.5691ZM31.6534 164.436C31.4106 164.093 31.2178 163.721 31.1036 163.35C30.9536 162.871 30.9179 162.378 31.0108 161.921C31.1179 161.421 31.3749 160.971 31.8034 160.621C32.4032 160.143 33.3243 159.893 34.6167 160.05C38.4155 160.507 41.2575 163.8 41.7288 164.378C42.2 163.8 45.042 160.507 48.8408 160.05C50.1404 159.893 51.0615 160.143 51.6542 160.621C52.0897 160.964 52.3468 161.414 52.4468 161.921C52.5396 162.378 52.5039 162.871 52.3539 163.35C52.2397 163.721 52.054 164.086 51.8041 164.436H55.3815C55.6529 164.436 55.8957 164.543 56.067 164.721C56.2455 164.9 56.3527 165.143 56.3527 165.407V171.593C56.3527 171.857 56.2455 172.1 56.067 172.278C55.8885 172.457 55.6457 172.564 55.3815 172.564H54.5104V186.914C54.5104 187.264 54.3676 187.578 54.1391 187.807C53.9106 188.043 53.5892 188.186 53.2465 188.186H30.2039C29.854 188.186 29.5327 188.043 29.3042 187.814C29.0757 187.586 28.9328 187.264 28.9328 186.914V172.564H28.0617C27.7904 172.564 27.5476 172.457 27.3762 172.278C27.1977 172.1 27.0906 171.857 27.0906 171.593V165.407C27.0906 165.143 27.1977 164.9 27.3762 164.721C27.5547 164.543 27.7975 164.436 28.0617 164.436H31.6391H31.6534ZM39.4795 164.436C38.4155 163.414 36.5947 162 34.4311 161.743C33.6313 161.65 33.1315 161.743 32.8673 161.957C32.7602 162.043 32.7031 162.15 32.6745 162.271C32.6388 162.436 32.6602 162.636 32.7245 162.85C32.8959 163.407 33.6385 164.128 34.274 164.436H39.4866H39.4795ZM30.6466 186.493H37.8371V172.564H30.6466V186.493Z"
        fill={sixthColor}
      />
      <path
        d="M37.83 166.143H28.7972V170.878H37.83V166.143ZM43.7424 186.5V166.143H39.608V186.5H43.7424ZM45.5204 172.571V186.5H52.8323V172.571H45.5204ZM45.5204 166.136V170.871H54.6818V166.136H45.5204ZM49.5691 164.436H49.155C50.0547 164.071 50.5831 163.407 50.7616 162.85C50.8258 162.636 50.8473 162.436 50.8116 162.271C50.783 162.15 50.7259 162.036 50.6188 161.957C50.3546 161.75 49.8547 161.65 49.055 161.743C46.8914 162 45.0705 163.414 44.0066 164.436H49.5762H49.5691ZM31.6534 164.436C31.4106 164.093 31.2178 163.721 31.1036 163.35C30.9536 162.871 30.9179 162.378 31.0108 161.921C31.1179 161.421 31.3749 160.971 31.8034 160.621C32.4032 160.143 33.3243 159.893 34.6167 160.05C38.4155 160.507 41.2575 163.8 41.7288 164.378C42.2 163.8 45.042 160.507 48.8408 160.05C50.1404 159.893 51.0615 160.143 51.6542 160.621C52.0897 160.964 52.3468 161.414 52.4468 161.921C52.5396 162.378 52.5039 162.871 52.3539 163.35C52.2397 163.721 52.054 164.086 51.8041 164.436H55.3815C55.6529 164.436 55.8957 164.543 56.067 164.721C56.2455 164.9 56.3527 165.143 56.3527 165.407V171.593C56.3527 171.857 56.2455 172.1 56.067 172.278C55.8885 172.457 55.6457 172.564 55.3815 172.564H54.5104V186.914C54.5104 187.264 54.3676 187.578 54.1391 187.807C53.9106 188.043 53.5892 188.186 53.2465 188.186H30.2039C29.854 188.186 29.5327 188.043 29.3042 187.814C29.0757 187.586 28.9328 187.264 28.9328 186.914V172.564H28.0617C27.7904 172.564 27.5476 172.457 27.3762 172.278C27.1977 172.1 27.0906 171.857 27.0906 171.593V165.407C27.0906 165.143 27.1977 164.9 27.3762 164.721C27.5547 164.543 27.7975 164.436 28.0617 164.436H31.6391H31.6534ZM39.4795 164.436C38.4155 163.414 36.5947 162 34.4311 161.743C33.6313 161.65 33.1315 161.743 32.8673 161.957C32.7602 162.043 32.7031 162.15 32.6745 162.271C32.6388 162.436 32.6602 162.636 32.7245 162.85C32.8959 163.407 33.6385 164.128 34.274 164.436H39.4866H39.4795ZM30.6466 186.493H37.8371V172.564H30.6466V186.493Z"
        fill={sixthColor}
      />
      <path d="M199 136H132V203H199V136Z" fill={color} />
      <path
        d="M176.807 173.309H156.664C156.057 173.309 155.564 172.815 155.564 172.206C155.564 171.596 156.057 171.102 156.664 171.102H176.807C177.507 171.102 178.164 170.83 178.657 170.335L182.584 166.394C183.077 165.899 183.348 165.24 183.348 164.537V157.987C183.348 157.536 182.984 157.17 182.534 157.17L151.708 157.063C151.101 157.063 150.608 156.561 150.616 155.959C150.616 155.35 151.108 154.855 151.722 154.863L182.541 154.97C184.198 154.97 185.547 156.325 185.547 157.994V164.545C185.547 165.835 185.047 167.053 184.141 167.963L180.213 171.905C179.306 172.815 178.092 173.316 176.807 173.316"
        fill={secondColor}
      />
      <path
        d="M179.463 188.997C177.428 188.997 175.772 187.384 175.772 185.399C175.772 183.414 177.428 181.802 179.463 181.802C181.498 181.802 183.162 183.414 183.162 185.399C183.162 187.384 181.506 188.997 179.463 188.997ZM179.463 184.009C178.635 184.009 177.964 184.632 177.964 185.399C177.964 186.166 178.635 186.789 179.463 186.789C180.292 186.789 180.963 186.166 180.963 185.399C180.963 184.632 180.292 184.009 179.463 184.009Z"
        fill={secondColor}
      />
      <path
        d="M155.921 188.997C153.886 188.997 152.229 187.384 152.229 185.399C152.229 183.414 153.886 181.802 155.921 181.802C157.956 181.802 159.62 183.414 159.62 185.399C159.62 187.384 157.963 188.997 155.921 188.997ZM155.921 184.009C155.093 184.009 154.421 184.632 154.421 185.399C154.421 186.166 155.093 186.789 155.921 186.789C156.749 186.789 157.42 186.166 157.42 185.399C157.42 184.632 156.749 184.009 155.921 184.009Z"
        fill={secondColor}
      />
      <path
        d="M181.27 181.378H155.357C155.036 181.378 154.729 181.235 154.522 180.991C154.314 180.748 154.222 180.418 154.272 180.103L155.571 172.033C155.671 171.431 156.235 171.023 156.828 171.123C157.428 171.223 157.835 171.79 157.735 172.392L156.642 179.185H181.263C181.87 179.185 182.363 179.68 182.363 180.289C182.363 180.898 181.87 181.393 181.263 181.393"
        fill={secondColor}
      />
      <path
        d="M156.657 173.309C156.185 173.309 155.75 173.001 155.607 172.528L149.423 152.204H146.31C145.703 152.204 145.21 151.709 145.21 151.1C145.21 150.491 145.703 149.996 146.31 149.996H150.237C150.723 149.996 151.144 150.312 151.287 150.777L157.706 171.883C157.885 172.463 157.556 173.08 156.978 173.259C156.871 173.295 156.764 173.309 156.657 173.309Z"
        fill={secondColor}
      />
      <path
        d="M172.601 163.018C171.973 164.028 170.866 164.472 170.138 164.014C169.402 163.555 169.324 162.358 169.952 161.348C170.581 160.337 171.687 159.886 172.416 160.352C173.151 160.81 173.23 162.007 172.601 163.011"
        fill={secondColor}
      />
      <path
        d="M169.224 161.062C168.989 162.23 168.117 163.04 167.268 162.868C166.418 162.696 165.925 161.614 166.154 160.446C166.389 159.278 167.261 158.468 168.11 158.64C168.96 158.812 169.453 159.894 169.224 161.069"
        fill={secondColor}
      />
      <path
        d="M165.154 162.366C165.189 163.262 164.697 164.014 164.047 164.043C163.397 164.071 162.84 163.369 162.804 162.473C162.761 161.577 163.261 160.825 163.911 160.796C164.561 160.768 165.118 161.47 165.161 162.366"
        fill={secondColor}
      />
      <path
        d="M172.087 165.068C171.409 165.648 171.202 166.53 171.623 167.024C172.044 167.519 172.937 167.447 173.623 166.867C174.301 166.286 174.508 165.405 174.087 164.91C173.665 164.416 172.773 164.487 172.087 165.068Z"
        fill={secondColor}
      />
      <path
        d="M163.605 165.935C162.305 167.354 165.354 167.576 166.761 168.135C168.053 168.651 170.481 170.643 170.559 168.715C170.645 166.544 170.288 164.853 168.374 164.079C166.454 163.312 165.19 164.2 163.612 165.928"
        fill={secondColor}
      />
    </svg>
  );
};
