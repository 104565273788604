import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const TrackingStatusOrderReceived = ({
  width = 36,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Recebemos seu pedido",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M18.0003 8.49683V12.6652"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.832 20.1683H15.4994"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5033 14.3326C25.5033 13.4118 24.7568 12.6653 23.836 12.6653H12.1644C11.2436 12.6653 10.4971 13.4118 10.4971 14.3326"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.836 23.5031H12.3729C11.3369 23.5031 10.4971 22.6633 10.4971 21.6273V13.0821C10.4968 12.6318 10.5881 12.1862 10.7655 11.7724L11.6776 9.63563C11.9725 8.94478 12.6513 8.49663 13.4024 8.49683H22.5971C23.3483 8.49663 24.0271 8.94478 24.322 9.63563L25.239 11.7724C25.4149 12.1865 25.5048 12.6321 25.5033 13.0821V21.8357C25.5033 22.7566 24.7568 23.5031 23.836 23.5031Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
