import { useState, ReactNode } from "react";
import { theme } from "ui";
import { buildTestIds, useAppContext } from "application";
import {
  CollapsedContainer,
  Header,
  BoxContent,
  CollapseArrowUp,
  CollapseArrowDown,
} from "./styles";

interface params {
  children: ReactNode;
  titleComponent: ReactNode;
  handleOpen?: boolean;
  id?: string;
  isBlackTheme?: boolean;
}

const Collapsed = ({
  id,
  children,
  titleComponent,
  handleOpen = true,
  isBlackTheme,
}: params) => {
  const { isClientTablet } = useAppContext();
  const [isOpen, setOpen] = useState(true);

  const arrowColor = isBlackTheme
    ? theme.colors.blackFriday.white
    : isClientTablet && !isBlackTheme
    ? theme.colors.primary["200"]
    : theme.colors.neutral["999"];

  return (
    <CollapsedContainer isClientMobile {...{ id }} isBlackTheme={isBlackTheme}>
      <Header
        isBlackTheme={isBlackTheme}
        onClick={() => {
          if (handleOpen) setOpen((state) => !state);
        }}
        {...buildTestIds("collapsed-header")}
      >
        {titleComponent}
        {handleOpen && isOpen ? (
          <CollapseArrowUp color={arrowColor} />
        ) : (
          <CollapseArrowDown color={arrowColor} />
        )}
      </Header>
      <BoxContent
        isBlackTheme={isBlackTheme}
        className={isOpen ? "open" : "closed"}
      >
        {children}
      </BoxContent>
    </CollapsedContainer>
  );
};

export { Collapsed };
