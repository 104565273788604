const formatNumber = (value: number) => {
  try {
    if (typeof value !== "number" || Number.isNaN(value))
      throw new Error("formatNumber: Value must be a number");
    return new Intl.NumberFormat("pt-BR").format(value);
  } catch {
    return "";
  }
};

export { formatNumber };
