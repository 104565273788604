/* eslint-disable class-methods-use-this */
import {
  BookingCouponDto,
  BookingCouponErrorDto,
  BookingCouponResponseDto,
  CardsDto,
  CreateCreditCardDto,
  IHttpResponse,
  Payment,
  PixDto,
  PostBookingCoupon,
  PostPaymentOrderByPhonePaymentApiResponse,
  ResultGiftCardPaymentDto,
  RevalidateCardDto,
  RevalidateCardResponseDto,
} from "typing";

import { IAppMonitoringClient, IHttpClient } from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import { getApisUrlFallback } from "../../../utils";
import { EnvsApi } from "../Envs";
import {
  OrderPaymentEventError,
  handleGetCardsError,
  handleGetGiftError,
  handleOrderPaymentError,
} from "./data";

type OrderPaymentEvent = OrderPaymentEventError;

export class PaymentApi implements IApi {
  baseUrl = "";

  private SALE_CHANNEL = Number(
    `${process.env.SITE_ECOMMERCE_SALE_CHANNEL || "2"}`
  );

  private readonly JSON_CONTENT_TYPE = "application/json";

  constructor(
    private httpClient: IHttpClient,
    private appMonitoringClient: IAppMonitoringClient,
    private envsApi: EnvsApi
  ) {
    if (!this.baseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}payment/`;
    }

    this.appMonitoringClient = appMonitoringClient;
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getCardsEndpoint = () => {
    return `${this.baseUrl}v1/cards?PageSize=30`;
  };

  getCards = (
    jwt: string,
    appMonitoringClient: IAppMonitoringClient,
    condition: boolean
  ): IHttpResponse<CardsDto[], unknown> => {
    const { data, error, isLoading, trigger } = this.httpClient.useGet<
      CardsDto[]
    >(
      this.getCardsEndpoint(),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition
    ) as IHttpResponse<CardsDto[], unknown>;

    handleGetCardsError(error, appMonitoringClient);

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  postCardsEndpoint = () => {
    return `/api/clients/cards`;
  };

  createCard = async (body: CreateCreditCardDto, jwt: string) => {
    try {
      return (await this.httpClient.usePost<CreateCreditCardDto>(
        this.postCardsEndpoint(),
        body as unknown as BodyInit,
        {
          headers: {
            "Content-Type": this.JSON_CONTENT_TYPE,
            Authorization: `Bearer ${jwt}`,
          },
        }
      )) as unknown as IHttpResponse<{ cardIdFC: number }, unknown>;
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  deleteCardEndPoint = (cardId: number) => {
    return `${this.baseUrl}v1/cards/${cardId}`;
  };

  deleteCard = async (cardId: number, jwt: string, body?: string) => {
    try {
      return await this.httpClient.useDelete<CardsDto>(
        this.deleteCardEndPoint(cardId),
        {
          Authorization: `Bearer ${jwt}`,
        },
        body as unknown as BodyInit
      );
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return null;
    }
  };

  getPixEndpoint = (orderGroupId: number) => {
    return `${this.baseUrl}v1/pix/${orderGroupId}`;
  };

  getPix = (
    jwt: string,
    orderGroupId: number
  ): IHttpResponse<PixDto, unknown> => {
    const { data, error, isLoading, trigger } = this.httpClient.useGet<PixDto>(
      this.getPixEndpoint(orderGroupId),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    ) as IHttpResponse<PixDto, unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  postBookingCouponEndpoint = () => {
    return `${this.baseUrl}v1/coupons/booking-coupon`;
  };

  postBookingCoupon = async ({
    couponName,
    cpf,
    groupOrderId,
    branchId,
    couponProducts,
    jwt,
    condition,
  }: PostBookingCoupon) => {
    try {
      const response = await this.httpClient.usePost<
        BookingCouponDto | BookingCouponErrorDto
      >(
        this.postBookingCouponEndpoint(),
        {
          couponName,
          groupOrderId,
          branchId,
          cpf,
          couponProducts,
          saleChannel: this.SALE_CHANNEL,
        },
        {
          headers: {
            "Content-Type": this.JSON_CONTENT_TYPE,
            Authorization: `Bearer ${jwt}`,
          },
        },
        condition
      );

      return {
        data: response as unknown as BookingCouponResponseDto,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  private getGiftEndpoint = () => {
    return `${this.baseUrl}v1/gift`;
  };

  getGift = (
    jwt: string,
    appMonitoringClient: IAppMonitoringClient,
    condition = false
  ) => {
    const { data, isLoading, error } = this.httpClient.useGet(
      this.getGiftEndpoint(),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition
    ) as IHttpResponse<ResultGiftCardPaymentDto, unknown>;

    handleGetGiftError(error, appMonitoringClient);

    return {
      data,
      isLoading,
      error,
    };
  };

  postPaymentOrderEndpoint(orderId: number) {
    return `${this.baseUrl}v1/payment/${orderId}`;
  }

  postPaymentOrder = async (
    jwt: string,
    orderId: number,
    payment: Payment[],
    event: OrderPaymentEvent,
    condition = true
  ) => {
    try {
      const response = (await this.httpClient.usePost(
        this.postPaymentOrderEndpoint(orderId),
        payment,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
        condition
      )) as unknown as IHttpResponse<
        PostPaymentOrderByPhonePaymentApiResponse,
        unknown
      >;

      if (
        !(response as unknown as PostPaymentOrderByPhonePaymentApiResponse)
          ?.isValid
      ) {
        handleOrderPaymentError(response, this.appMonitoringClient, event);
      }

      return response;
    } catch (error) {
      handleOrderPaymentError(error, this.appMonitoringClient, event);
      return null;
    }
  };

  revalidateCardEndPoint = () => {
    return `${this.baseUrl}v1/cards`;
  };

  putRevalidateCard = async (
    body: RevalidateCardDto[],
    accessToken: string
  ) => {
    try {
      const response = await this.httpClient.usePut<RevalidateCardDto>(
        this.revalidateCardEndPoint(),
        {
          "Content-Type": this.JSON_CONTENT_TYPE,
          Authorization: `Bearer ${accessToken}`,
        },
        body as unknown as BodyInit
      );

      return {
        data: response as unknown as RevalidateCardResponseDto[],
        isLoading: false,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };
}
