import { NewAddressFormComponents } from "typing";
import { theme } from "ui";
import { CustomInputNewAddress, WrapperInput } from "../../../styles";

type DistrictComponentProps = NewAddressFormComponents;

export const DistrictComponent = ({
  control,
  disabled,
  hasError,
  requiredFieldText,
}: DistrictComponentProps) => {
  return (
    <WrapperInput>
      <CustomInputNewAddress
        id="district"
        name="district"
        type="text"
        required={requiredFieldText}
        control={control}
        disabled={disabled}
        hasError={hasError}
        placeholder="Ex. Santo Antonio"
        margin={`${theme.space.x2} ${theme.space.x0}`}
        width="100%"
        label="Bairro"
      />
    </WrapperInput>
  );
};
