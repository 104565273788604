import { NewAddressFormComponents } from "typing";
import { theme } from "ui";
import { CustomInputNewAddress, WrapperInput } from "../../../styles";

type StreetComponentProps = NewAddressFormComponents;

export const StreetComponent = ({
  control,
  disabled,
  hasError,
  requiredFieldText,
}: StreetComponentProps) => {
  return (
    <WrapperInput>
      <CustomInputNewAddress
        label="Rua ou avenida"
        id="street"
        name="street"
        type="text"
        placeholder="Rua ou avenida"
        required={requiredFieldText}
        control={control}
        margin={`${theme.space.x2} ${theme.space.x0}`}
        disabled={disabled}
        hasError={hasError}
        width="100%"
      />
    </WrapperInput>
  );
};
