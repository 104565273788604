import styled from "styled-components";
import { theme } from "ui";

export const HighlightCategories = styled.section`
  margin-left: 1.5rem;
  display: flex;

  @media (max-width: 980px) {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  @media ${theme.device.desktopFirst.laptopL} {
    margin-left: 1rem;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  @media ${theme.device.desktopFirst.tablet} {
    margin-left: 1.5rem;
  }

  a {
    text-decoration: none;
    font-size: 1.17rem;
    font-weight: 400;
    padding: 0.4rem 0.65rem;
    border-radius: 4px;
    cursor: pointer;

    transition: all 0.2s ease-in;

    &:hover {
      background: ${theme.colors.neutral["100"]};
    }

    @media (max-width: 980px) {
      padding: 0.25rem 0.38rem !important;
    }

    @media ${theme.device.desktopFirst.laptop} {
      padding: 0.4rem 0.65rem;
    }

    @media ${theme.device.mobileFirst.laptopL} {
      font-size: 1.17rem !important;
    }

    @media ${theme.device.desktopFirst.tablet} {
      padding: 0.25rem 0.38rem;
    }
  }
`;
