import styled from "styled-components";
import { theme } from "../themes";

export const MockCard = styled.div`
  width: 250px;
  height: 350px;
  background-color: ${theme.colors.neutral.white};
  border: 1px solid ${theme.colors.neutral["500"]};
  border-radius: 5px;
`;

export const MockStory = styled.div`
  width: 151px;
  height: 151px;
  background-color: ${theme.colors.neutral["25"]};
  border: 1px solid ${theme.colors.neutral["500"]};
  border-radius: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    width: 76px;
    height: 76px;
  }
`;

export const MockFullWidthBanner = styled.section`
  width: 100%;
  min-height: 50vh;
  background-color: ${theme.colors.neutral["75"]};

  @media ${theme.device.desktopFirst.tablet} {
    min-height: 76.5px;
  }
`;
