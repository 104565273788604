import { IFormValues } from "typing";
import { useForm } from "react-hook-form";
import { ResponsiveModalInput } from "./styles";
import { InputProps } from "../../../../../Form/Input";

const ResponsiveModalInputUI = ({
  disabled,
  hasError,
  mask,
  id,
  name,
  register: registerInput,
  padding,
  placeholder,
  onChange,
  type,
  borderRadius,
}: InputProps) => {
  const { register } = useForm<IFormValues>();
  return (
    <ResponsiveModalInput
      disabled={disabled}
      hasError={hasError}
      id={id}
      name={name}
      height="40px"
      width="100%"
      borderRadius={borderRadius}
      margin="16px 0px"
      padding={padding}
      placeholder={placeholder}
      register={registerInput || register}
      type={type}
      onChange={onChange}
      mask={mask}
    />
  );
};

export { ResponsiveModalInputUI };
