import { useEffect, useState } from "react";
import { ProductCardDto } from "typing";

export const insiderEndPoints = [
  {
    endPoint: "/top-sellers",
    needId: false,
  },
  {
    endPoint: "/most-popular",
    needId: false,
  },
  {
    endPoint: "/trending",
    needId: false,
  },
  {
    endPoint: "/viewed-together",
    needId: false,
  },
  {
    endPoint: "/recently-viewed",
    needId: true,
  },
  {
    endPoint: "/user-based",
    needId: true,
  },
  {
    endPoint: "/chef",
    needId: false,
  },
  {
    endPoint: "/complementary",
    needId: false,
  },
  {
    endPoint: "/highest-discounted",
    needId: false,
  },
  {
    endPoint: "/manual-merchandising",
    needId: false,
  },
  {
    endPoint: "/mixed",
    needId: false,
  },
  {
    endPoint: "/mixed",
    needId: false,
  },
  {
    endPoint: "/most-valuable",
    needId: false,
  },
  {
    endPoint: "/new-arrivals",
    needId: false,
  },
  {
    endPoint: "/new-arrivals",
    needId: false,
  },
  {
    endPoint: "/purchased-together",
    needId: true,
  },
  {
    endPoint: "/last-purchased-together",
    needId: true,
  },
  {
    endPoint: "/substitute",
    needId: false,
  },
];

export const useOderCrmProducts = (
  productCards: ProductCardDto[] | null | undefined,
  crmProductIdsList: string[],
  quantity: number
) => {
  const [orderedProductCards, setOrderedProductCards] = useState<
    ProductCardDto[]
  >([]);

  useEffect(() => {
    if (productCards?.length && crmProductIdsList?.length && quantity) {
      const tempOrderedProductCards = crmProductIdsList.reduce<
        ProductCardDto[]
      >((result, productId) => {
        const productCard = productCards.find(({ id }) => id === productId);

        if (productCard) {
          result.push(productCard);
        }

        return result;
      }, []);

      tempOrderedProductCards.splice(quantity);

      setOrderedProductCards(tempOrderedProductCards);
    }
  }, [productCards, quantity, crmProductIdsList]);

  return orderedProductCards;
};
