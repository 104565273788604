import { createStore } from "../adapter";

type OrderBoxState = {
  isTrackingModalOpen: boolean;
  shouldShowMinimalistProductList: boolean;
  shouldShowVerticalProductList: boolean;
  packageId: number;
  orderHash: string | null;
  setOrderHash: (packageId: string) => void;
};

type OrderBoxDispatch = {
  setIsTrackingModalOpen: (isTrackingModalOpen: boolean) => void;
  setShouldShowMinimalistProductList: (
    shouldShowMinimalistProductList: boolean
  ) => void;
  setShouldShowVerticalProductList: (
    shouldShowVerticalProductList: boolean
  ) => void;
  setPackageId: (packageId: number) => void;
  setOrderHash: (packageId: string) => void;
};

type OrderBoxProps = OrderBoxState & OrderBoxDispatch;

const useOrders = createStore<OrderBoxProps>((set, get) => ({
  isTrackingModalOpen: false,
  shouldShowMinimalistProductList: false,
  shouldShowVerticalProductList: false,
  setShouldShowMinimalistProductList: (shouldShowMinimalistProductList) => {
    if (
      get().shouldShowMinimalistProductList !== shouldShowMinimalistProductList
    ) {
      set({ shouldShowMinimalistProductList });
    }
  },
  setShouldShowVerticalProductList: (shouldShowVerticalProductList) => {
    if (get().shouldShowVerticalProductList !== shouldShowVerticalProductList) {
      set({ shouldShowVerticalProductList });
    }
  },
  setIsTrackingModalOpen: (isTrackingModalOpen) => {
    if (get().isTrackingModalOpen !== isTrackingModalOpen) {
      set({ isTrackingModalOpen });
    }
  },
  packageId: 0,
  setPackageId: (packageId) => {
    if (get().packageId !== packageId) {
      set({ packageId });
    }
  },
  orderHash: null,
  setOrderHash: (orderHash) => {
    if (get().orderHash !== orderHash) {
      set({ orderHash });
    }
  },
}));

export { useOrders };
