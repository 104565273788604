import { IconProps } from "typing";
import { theme } from "../themes";

export const ArrowCarouselLeft = ({
  width = 12,
  height = 26,
  color = theme.colors.neutral["450"],
  themeColors,
  alt = "botão de voltar do carrosel",
}: IconProps) => {
  const customColor = themeColors?.default || color;
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 15 26"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M12.854 24.25L1.61029 12.9662L12.8603 1.75"
        stroke={customColor as string}
        strokeWidth="2.64706"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
