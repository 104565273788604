import { getComponentTheme } from "application";
import { appMonitoringClient } from "implementations";
import {
  BlackFridayThemeProps,
  BranchDto,
  ContentType,
  CtaText,
  CustomHeaderProperty,
  DesktopImage,
  LinkHref,
  LinkText,
  Message,
  MobileImage,
  ShowTopbar,
  Title,
} from "typing";

const customHeaderData = (properties: CustomHeaderProperty[]) => {
  const contentType = (
    properties?.find(({ key }) => key === "contentType") as ContentType
  )?.value;

  const linkText = (
    properties?.find(({ key }) => key === "linkText") as LinkText
  )?.value;
  const linkHref = (
    properties?.find(({ key }) => key === "linkHref") as LinkHref
  )?.value;

  const desktopImage = (
    properties?.find(({ key }) => key === "desktopImage") as DesktopImage
  )?.value;
  const mobileImage = (
    properties?.find(({ key }) => key === "mobileImage") as MobileImage
  )?.value;

  const title = (properties?.find(({ key }) => key === "title") as Title)
    ?.value;
  const message = (properties?.find(({ key }) => key === "message") as Message)
    ?.value;
  const ctaText = (properties?.find(({ key }) => key === "ctaText") as CtaText)
    ?.value;

  const backgroundColor = (
    properties?.find(({ key }) => key === "backgroundColor") as LinkText
  )?.value;

  const showTopbar = (
    properties?.find(({ key }) => key === "showTopbar") as ShowTopbar
  )?.value;

  const blackFridayTheme = properties?.find(
    ({ key }) => key === "blackFridayTheme"
  );

  const headerBlackFridayTheme =
    getComponentTheme<BlackFridayThemeProps>(blackFridayTheme);

  return {
    contentType,
    linkText,
    linkHref,
    desktopImage,
    mobileImage,
    title,
    message,
    ctaText,
    backgroundColor,
    showTopbar,
    headerBlackFridayTheme,
  };
};

const handleComponentWarnings = (
  branches: BranchDto[] | undefined,
  currentPathName: string,
  contentType: "text" | "image",
  desktopImage: string | undefined,
  mobileImage: string | undefined,
  message: string | undefined,
  showTopbar: string | undefined
) => {
  if (!branches?.length) {
    appMonitoringClient.captureMessage(
      `O componente de Header Personalizado, localizado em "${currentPathName}" na posição 0, não possui nenhuma configuração de branches.`,
      "warning"
    );
  }

  if (!showTopbar || showTopbar === "false") {
    return;
  }

  if (contentType === "image") {
    if (!desktopImage || !mobileImage) {
      appMonitoringClient.captureMessage(
        `O componente de Header Personalizado, localizado em "${currentPathName}" na posição 0, não possui imagem.`,
        "warning"
      );
    }
  } else if (!message) {
    appMonitoringClient.captureMessage(
      `O componente de Header Personalizado, localizado em "${currentPathName}" na posição 0, não possui texto.`,
      "warning"
    );
  }
};

export { customHeaderData, handleComponentWarnings };
