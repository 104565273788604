import { ICookie } from "app-domain";
import { ApiDefaultErrorDto, ComponentDto } from "typing";
import { BackofficeApi } from "../../services";

const getCustomHeaderHttpRequest = (
  backofficeApi: BackofficeApi,
  cookie: ICookie
) => {
  const branchId =
    cookie.getCookie({
      name: "@FC:Ecom:Dropdown:BranchLocation",
    }) ?? "2";

  return backofficeApi.getCustomHeader(branchId, !!branchId);
};

export const getCustomHeader = (
  backofficeApi: BackofficeApi,
  cookie: ICookie
) => {
  const { data: component, isLoading: isLoadingCustomHeader } =
    getCustomHeaderHttpRequest(backofficeApi, cookie);

  const customHeaderError = component as ApiDefaultErrorDto;
  const customHeaderData = component as ComponentDto[];

  const customHeader: ComponentDto | null =
    customHeaderError?.status &&
    customHeaderError?.status?.toString()?.startsWith("4")
      ? null
      : customHeaderData?.[0];

  return {
    customHeader,
    isLoadingCustomHeader,
  };
};
