import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const IconRightTopMobile = ({
  width = "139",
  height = "207",
  color = theme.colors.secondary["250"],
  secondColor = theme.colors.secondary["45"],
  thirdColor = theme.colors.neutral["150"],
  fourthColor = theme.colors.primary["705"],
  fifthColor = theme.colors.primary["710"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 139 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M69 70H2V137H69V70Z" fill={color} />
      <path
        d="M22.7392 91.763C22.3981 91.763 22.0482 91.6318 21.7858 91.3694C19.0304 88.614 19.0304 84.1179 21.7858 81.3624C24.5499 78.607 29.0373 78.607 31.7927 81.3624L37.6971 87.2669C38.222 87.7917 38.222 88.649 37.6971 89.1738C37.1723 89.6986 36.3151 89.6986 35.7902 89.1738L29.8858 83.2694C28.1801 81.5636 25.4072 81.5636 23.7014 83.2694C21.9957 84.9751 21.9957 87.748 23.7014 89.4537C24.2263 89.9785 24.2263 90.8358 23.7014 91.3606C23.439 91.623 23.0891 91.7542 22.748 91.7542"
        fill={secondColor}
      />
      <path
        d="M34.2502 123.665C33.9003 123.665 33.5591 123.534 33.2967 123.271L13.4053 103.38C12.8805 102.855 12.8805 101.998 13.4053 101.473L36.6819 78.1964C37.2068 77.6715 38.064 77.6715 38.5888 78.1964L58.4802 98.0877C59.0051 98.6126 59.0051 99.4698 58.4802 99.9947L35.2036 123.271C34.9412 123.534 34.5913 123.665 34.2502 123.665ZM16.2657 102.418L34.2502 120.402L55.6199 99.0324L37.6354 81.048L16.2657 102.418Z"
        fill={secondColor}
      />
      <path
        d="M36.7354 91.7627C34.7585 91.7627 33.1577 90.1532 33.1577 88.1851C33.1577 86.2169 34.7672 84.6074 36.7354 84.6074C38.7035 84.6074 40.313 86.2169 40.313 88.1851C40.313 90.1532 38.7035 91.7627 36.7354 91.7627ZM36.7354 87.3016C36.2543 87.3016 35.8519 87.6952 35.8519 88.1851C35.8519 88.6749 36.2455 89.0685 36.7354 89.0685C37.2252 89.0685 37.6188 88.6749 37.6188 88.1851C37.6188 87.6952 37.2252 87.3016 36.7354 87.3016Z"
        fill={secondColor}
      />
      <path
        d="M56.8794 122.668C54.8413 122.668 53.7304 121.644 53.1531 120.787C51.7447 118.679 51.7622 114.97 53.2143 109.451C54.229 105.602 55.5673 102.33 55.6286 102.19C55.8385 101.683 56.3283 101.351 56.8794 101.351C57.4305 101.351 57.9203 101.683 58.1303 102.19C58.1828 102.33 59.5299 105.602 60.5445 109.451C61.9966 114.97 62.0141 118.679 60.6058 120.787C60.0284 121.644 58.9175 122.668 56.8794 122.668ZM56.8794 106.651C56.5295 107.701 56.1534 108.908 55.821 110.177C55.2699 112.285 54.124 117.384 55.4011 119.291C55.6111 119.606 55.9697 119.974 56.8794 119.974C57.7891 119.974 58.1478 119.615 58.3577 119.291C58.7863 118.653 59.5998 116.492 57.9378 110.177C57.6054 108.917 57.2293 107.701 56.8794 106.651Z"
        fill={secondColor}
      />
      <path
        d="M24.5326 93.565C24.1827 93.565 23.8415 93.4338 23.5791 93.1714L21.7772 91.3782C21.2523 90.8533 21.2523 89.9961 21.7772 89.4713C22.302 88.9464 23.1592 88.9464 23.6841 89.4713L25.486 91.2732C26.0109 91.7981 26.0109 92.6553 25.486 93.1801C25.2236 93.4425 24.8737 93.5738 24.5326 93.5738"
        fill={secondColor}
      />
      <path
        d="M34.9855 124.408C34.6356 124.408 34.2945 124.277 34.032 124.014L12.4437 102.426C11.9188 101.901 11.9188 101.044 12.4437 100.519C12.9685 99.9943 13.8258 99.9943 14.3506 100.519L35.9389 122.107C36.4638 122.632 36.4638 123.49 35.9389 124.014C35.6765 124.277 35.3266 124.408 34.9855 124.408Z"
        fill={secondColor}
      />
      <path
        d="M58.1222 100.983C57.7723 100.983 57.4312 100.852 57.1688 100.589L35.5804 79.0007C35.0556 78.4759 35.0556 77.6187 35.5804 77.0938C36.1052 76.569 36.9625 76.569 37.4873 77.0938L59.0757 98.6822C59.6005 99.207 59.6005 100.064 59.0757 100.589C58.8132 100.852 58.4634 100.983 58.1222 100.983Z"
        fill={secondColor}
      />
      <path
        d="M43.6804 113.369C42.762 113.369 41.9047 113.01 41.2574 112.372L40.6888 111.803C39.3593 110.474 39.3593 108.304 40.6888 106.966L47.7217 99.9331C48.2465 99.4083 49.1038 99.4083 49.6286 99.9331C50.1534 100.458 50.1534 101.315 49.6286 101.84L42.5958 108.873C42.3158 109.153 42.3158 109.616 42.5958 109.888L43.1643 110.456C43.348 110.64 43.558 110.666 43.6717 110.666C43.7854 110.666 44.0041 110.64 44.179 110.456L56.5652 98.0787C57.09 97.5538 57.9473 97.5538 58.4809 98.0787C59.0057 98.6035 59.0057 99.4608 58.4809 99.9856L46.0947 112.372C45.4474 113.019 44.5901 113.369 43.6717 113.369"
        fill={secondColor}
      />
      <path
        d="M48.6837 102.234C48.3338 102.234 47.9927 102.103 47.7302 101.84C47.2054 101.315 47.2054 100.458 47.7302 99.9332C47.9139 99.7495 47.9402 99.5395 47.9402 99.4258C47.9402 99.3121 47.9139 99.1022 47.7302 98.9185L47.1617 98.3499C46.978 98.1662 46.768 98.14 46.6543 98.14C46.5406 98.14 46.3219 98.1662 46.147 98.3499C45.6221 98.8747 44.7649 98.8747 44.2401 98.3499C43.7152 97.8251 43.7152 96.9678 44.2401 96.443C45.5697 95.1134 47.7477 95.1134 49.0773 96.443L49.6459 97.0116C50.2932 97.6589 50.6431 98.5161 50.6431 99.4346C50.6431 100.353 50.2845 101.21 49.6459 101.858C49.3835 102.12 49.0336 102.251 48.6924 102.251"
        fill={secondColor}
      />
      <path
        d="M39.8838 103.187C39.0091 103.187 38.1343 102.855 37.4608 102.19L36.8922 101.622C35.5626 100.292 35.5626 98.1138 36.8922 96.7842L45.9982 87.6783C46.523 87.1535 47.3802 87.1535 47.9051 87.6783C48.4299 88.2031 48.4299 89.0604 47.9051 89.5852L38.7991 98.6912C38.5192 98.9711 38.5192 99.4347 38.7991 99.7058L39.3677 100.274C39.6389 100.554 40.1112 100.554 40.3824 100.274L44.2225 96.4343C44.7473 95.9095 45.6045 95.9095 46.1294 96.4343C46.6542 96.9592 46.6542 97.8164 46.1294 98.3413L42.2893 102.181C41.6245 102.846 40.7498 103.179 39.8663 103.179"
        fill={secondColor}
      />
      <path d="M137 2H70V69H137V2Z" fill={secondColor} />
      <path
        d="M97.7635 31.6698H78.047C77.3035 31.6698 76.7 31.0662 76.7 30.3227V20.0971C76.7 19.3536 77.3035 18.75 78.047 18.75H97.7635C98.507 18.75 99.1106 19.3536 99.1106 20.0971V30.3227C99.1106 31.0662 98.507 31.6698 97.7635 31.6698ZM79.3941 28.9668H96.4076V21.4442H79.3941V28.9668Z"
        fill={thirdColor}
      />
      <path
        d="M97.7635 52.1214H78.047C77.3035 52.1214 76.7 51.5179 76.7 50.7743V40.5487C76.7 39.8052 77.3035 39.2017 78.047 39.2017H97.7635C98.507 39.2017 99.1106 39.8052 99.1106 40.5487V50.7743C99.1106 51.5179 98.507 52.1214 97.7635 52.1214ZM79.3941 49.4185H96.4076V41.8871H79.3941V49.4185Z"
        fill={thirdColor}
      />
      <path
        d="M117.436 52.1214H97.7198C96.9763 52.1214 96.364 51.5179 96.364 50.7743V40.5487C96.364 39.8052 96.9676 39.2017 97.7198 39.2017H117.436C118.18 39.2017 118.783 39.8052 118.783 40.5487V50.7743C118.783 51.5179 118.18 52.1214 117.436 52.1214ZM99.0669 49.4185H116.08V41.8871H99.0669V49.4185Z"
        fill={thirdColor}
      />
      <path
        d="M107.595 41.8953H87.8788C87.1353 41.8953 86.5317 41.2918 86.5317 40.5482V30.3226C86.5317 29.5791 87.1353 28.9668 87.8788 28.9668H107.595C108.339 28.9668 108.951 29.5704 108.951 30.3226V40.5482C108.951 41.2918 108.348 41.8953 107.595 41.8953ZM89.2259 39.1924H106.239V31.6697H89.2259V39.1924Z"
        fill={thirdColor}
      />
      <path
        d="M127.268 41.8953H107.552C106.808 41.8953 106.205 41.2918 106.205 40.5482V30.3226C106.205 29.5791 106.808 28.9668 107.552 28.9668H127.268C128.012 28.9668 128.615 29.5704 128.615 30.3226V40.5482C128.615 41.2918 128.012 41.8953 127.268 41.8953ZM108.899 39.1924H125.912V31.6697H108.899V39.1924Z"
        fill={thirdColor}
      />
      <path d="M137 69H70V136H137V69Z" fill={fifthColor} />
      <path
        d="M103.753 110.322C103.403 110.322 103.062 110.191 102.8 109.928C100.018 107.147 98.2775 103.464 97.8926 99.5541C97.5515 96.0727 98.295 92.5475 99.992 89.5209L94.0351 83.564C92.2419 81.7708 92.2419 78.8492 94.0351 77.0473C94.9098 76.1726 96.0645 75.7002 97.2978 75.7002C98.5312 75.7002 99.6858 76.1813 100.561 77.0473L106.517 83.0042C109.544 81.3072 113.069 80.5637 116.551 80.9048C120.461 81.2897 124.143 83.0304 126.925 85.8121C127.45 86.3369 127.45 87.1942 126.925 87.719L104.724 109.92C104.462 110.182 104.112 110.313 103.771 110.313M97.3066 78.3944C96.7992 78.3944 96.3181 78.5956 95.9595 78.9542C95.216 79.6977 95.216 80.9048 95.9595 81.6484L102.66 88.3488C103.115 88.8037 103.185 89.5034 102.835 90.0458C101.068 92.7574 100.272 96.0377 100.587 99.2829C100.867 102.117 101.995 104.82 103.806 107.007L124.003 86.8093C121.817 84.9986 119.114 83.8702 116.279 83.5903C113.034 83.2666 109.754 84.0714 107.042 85.8383C106.509 86.1882 105.8 86.1182 105.345 85.6634L98.6449 78.9629C98.2863 78.6043 97.8052 78.4031 97.2978 78.4031L97.3066 78.3944Z"
        fill={fourthColor}
      />
      <path
        d="M114.853 105.45C112.911 105.45 110.969 104.715 109.491 103.237C108.966 102.712 108.966 101.855 109.491 101.33C110.016 100.805 110.873 100.805 111.407 101.33C113.305 103.228 116.401 103.228 118.308 101.33C120.206 99.4232 120.206 96.3266 118.308 94.4285C117.783 93.9036 117.783 93.0464 118.308 92.5216C118.833 91.9967 119.69 91.9967 120.215 92.5216C123.172 95.4781 123.172 100.289 120.215 103.246C118.737 104.724 116.795 105.459 114.853 105.459"
        fill={fourthColor}
      />
      <path
        d="M96.6851 131.673C96.1953 131.673 95.7229 131.402 95.478 130.938L83.5467 107.496C83.3105 107.032 83.363 106.472 83.6779 106.061L98.4258 86.6946C98.8807 86.0998 99.7292 85.986 100.315 86.4409C100.91 86.8958 101.024 87.7442 100.569 88.3391L86.3283 107.032L97.8748 129.723C98.2159 130.387 97.9447 131.201 97.28 131.542C97.0875 131.647 96.8776 131.691 96.6676 131.691"
        fill={fourthColor}
      />
      <path d="M137 136H70V203H137V136Z" fill={secondColor} />
      <path
        d="M116.456 172.112C116.171 172.112 115.892 172.005 115.678 171.791C112.429 168.542 108.102 166.749 103.503 166.749C98.9048 166.749 94.5776 168.542 91.3287 171.791C90.9002 172.219 90.2004 172.219 89.772 171.791C89.3436 171.362 89.3436 170.662 89.772 170.234C93.4423 166.564 98.3193 164.543 103.503 164.543C108.687 164.543 113.572 166.564 117.242 170.234C117.67 170.662 117.67 171.362 117.242 171.791C117.028 172.005 116.742 172.112 116.463 172.112"
        fill={thirdColor}
      />
      <path
        d="M96.906 176.984C96.6204 176.984 96.3419 176.877 96.1277 176.662C95.6993 176.234 95.6993 175.534 96.1277 175.106C100.198 171.036 106.817 171.036 110.887 175.106C111.316 175.534 111.316 176.234 110.887 176.662C110.459 177.091 109.759 177.091 109.331 176.662C106.125 173.456 100.905 173.456 97.6915 176.662C97.4773 176.877 97.1917 176.984 96.9132 176.984"
        fill={thirdColor}
      />
      <path
        d="M122.961 166.382C122.676 166.382 122.397 166.275 122.183 166.061C117.192 161.07 110.558 158.328 103.51 158.328C96.4626 158.328 89.8219 161.077 84.8306 166.061C84.4022 166.489 83.7024 166.489 83.274 166.061C82.8455 165.632 82.8455 164.933 83.274 164.504C88.6794 159.099 95.8628 156.121 103.51 156.121C111.158 156.121 118.341 159.099 123.747 164.504C124.175 164.933 124.175 165.632 123.747 166.061C123.533 166.275 123.247 166.382 122.968 166.382"
        fill={thirdColor}
      />
      <path
        d="M105.446 180.935C105.446 182.006 104.575 182.878 103.504 182.878C102.433 182.878 101.562 182.006 101.562 180.935C101.562 179.864 102.433 178.993 103.504 178.993C104.575 178.993 105.446 179.864 105.446 180.935Z"
        fill={thirdColor}
      />
    </svg>
  );
};
