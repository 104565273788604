import { IconProps } from "typing";
import { theme } from "../themes";

export const WeighIcon = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
}: // color = "black",
IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5213 6.00005L14.2427 10.9847C14.5993 11.9007 13.924 12.8887 12.9413 12.8887H3.05935C2.07668 12.8887 1.40068 11.9007 1.75802 10.9847L4.47868 6.00005C4.68801 5.46405 5.20468 5.11072 5.78001 5.11072H10.2193C10.7953 5.11072 11.312 5.46405 11.5213 6.00005Z"
        stroke={color}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="8.00002"
        cy="3.7734"
        rx="1.33334"
        ry="1.33333"
        stroke={color}
        strokeWidth="1"
      />
    </svg>
  );
};
