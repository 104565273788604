import styled from "styled-components";
import { SpaceWrapper, theme } from "ui";

export const RegisterContainer = styled.section`
  background-color: ${theme.colors.neutral["white-050"]};
  min-height: 100vh;
  overflow-x: hidden;
`;

export const RegisterSpaceWrapper = styled(SpaceWrapper)`
  padding: ${theme.space.x4} ${theme.space.x6};

  @media ${theme.device.desktopFirst.laptopL} {
    padding: ${theme.space.x0} ${theme.space.x0};
  }
`;
