import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const IconLeftBottom = ({
  width = "324",
  height = "326",
  color = theme.colors.neutral["white-075"],
  secondColor = theme.colors.neutral["150"],
  thirdColor = theme.colors.primary["710"],
  fourthColor = theme.colors.primary["705"],
  fifthColor = theme.colors.secondary["355"],
  sixthColor = theme.colors.secondary["45"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 324 326"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M106 2H0V108H106V2Z" fill={color} />
      <path
        d="M42.5402 72.7001C43.7165 72.7001 44.6714 73.655 44.6714 74.8313L44.6714 86.5114L48.2695 89.3622L53.8328 89.3622L57.4309 86.5114L57.4309 74.8313C57.4309 73.655 58.3858 72.7001 59.5622 72.7001C60.7385 72.7001 61.6934 73.655 61.6934 74.8313L61.6934 87.5493C61.6934 88.1998 61.3889 88.8225 60.8907 89.2239L55.9087 93.1818C55.535 93.4863 55.0645 93.6523 54.5801 93.6523L47.5222 93.6523C47.0378 93.6523 46.5673 93.4863 46.1937 93.1818L41.2116 89.2239C40.6996 88.8225 40.4089 88.1998 40.4089 87.5493L40.4089 74.8313C40.4089 73.655 41.3638 72.7001 42.5402 72.7001Z"
        fill={secondColor}
      />
      <path
        d="M42.5394 80.2864L59.5614 80.2864C60.7377 80.2864 61.6926 81.2413 61.6926 82.4176C61.6926 83.5939 60.7377 84.5488 59.5614 84.5488L42.5394 84.5488C41.3631 84.5488 40.4082 83.5939 40.4082 82.4176C40.4082 81.2413 41.3631 80.2864 42.5394 80.2864Z"
        fill={secondColor}
      />
      <path
        d="M51.0508 12.6023C67.5054 12.6023 80.8877 25.6248 80.8877 41.6227C80.8877 47.5182 79.0748 53.1783 75.6704 58.022C72.6258 62.3259 68.4879 65.7303 63.6443 67.9307L63.6443 72.3177C63.6443 74.8918 61.5131 76.9814 58.8975 76.9814L43.204 76.9814C40.5885 76.9814 38.4572 74.8918 38.4572 72.3177L38.4572 67.9307C33.6136 65.7442 29.4757 62.3398 26.4311 58.022C23.0129 53.1922 21.2 47.5182 21.2 41.6227C21.2 25.6248 34.5823 12.6023 51.0369 12.6023M58.8836 72.7052C59.1327 72.7052 59.3542 72.5253 59.3542 72.3177L59.3542 66.5053C59.3542 65.6334 59.8939 64.8446 60.7104 64.5125C70.37 60.6929 76.6114 51.6975 76.6114 41.6089C76.6114 27.9636 65.1389 16.8647 51.0508 16.8647C36.9626 16.8647 25.4901 27.9636 25.4901 41.6089C25.4901 51.6975 31.7315 60.6791 41.3911 64.5125C42.2076 64.8308 42.7335 65.6196 42.7335 66.5053L42.7335 72.3177C42.7335 72.5253 42.9549 72.7052 43.204 72.7052L58.8975 72.7052L58.8836 72.7052Z"
        fill={secondColor}
      />
      <path d="M214 112H108V218H214V112Z" fill={thirdColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.567 186.966H153.146V192.968H126.567V186.966V186.966ZM148.411 194.957H130.24V200.348H148.411V194.957ZM158.808 152.247H163.012V178.174H158.808V152.247ZM195.422 143.025H168.843V137.024H195.422V143.025ZM173.578 135.035H191.749V129.644H173.578V135.035V135.035ZM190.551 145.014H173.555C172.685 149.523 170.899 153.671 166.04 154.756C166.085 160.735 166.119 166.725 166.164 172.704C181.353 170.206 189.297 159.899 190.551 145.014V145.014ZM155.881 175.258V165.233L155.824 157.288C140.636 159.786 132.692 170.093 131.438 184.977H148.434C149.304 180.479 151.067 176.365 155.881 175.246V175.258ZM126.251 184.989H129.11C130.421 168.804 139.292 157.491 155.881 154.937V150.586C155.881 149.874 156.469 149.286 157.18 149.286H164.255C164.967 149.286 165.554 149.874 165.554 150.586V152.485C169.204 151.626 170.504 148.405 171.193 145.014H168.538C167.611 145.014 166.865 144.257 166.865 143.341V136.718C166.865 135.792 167.622 135.046 168.538 135.046H171.792V128.954C171.792 128.355 172.278 127.869 172.877 127.869H192.45C193.049 127.869 193.535 128.355 193.535 128.954V135.046H195.738C196.665 135.046 197.411 135.803 197.411 136.718V143.341C197.411 144.268 196.654 145.014 195.738 145.014H192.879C191.557 161.379 182.505 172.749 165.566 175.145V179.858C165.566 180.57 164.978 181.157 164.266 181.157H157.192C156.48 181.157 155.892 180.57 155.892 179.858V177.665C152.66 178.705 151.451 181.768 150.796 184.989H153.451C154.378 184.989 155.124 185.746 155.124 186.661V193.284C155.124 194.211 154.367 194.957 153.451 194.957H150.197V201.049C150.197 201.648 149.711 202.134 149.112 202.134H129.539C128.94 202.134 128.454 201.648 128.454 201.049V194.957H126.251C125.324 194.957 124.578 194.2 124.578 193.284V186.661C124.578 185.735 125.335 184.989 126.251 184.989V184.989Z"
        fill={fourthColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.585 131.54C122.657 146.955 126.759 154.437 133.72 154.437C140.682 154.437 144.58 146.65 133.585 131.54M135.189 130.375C138.263 134.602 141.54 140.095 142.275 145.362C142.761 148.843 142.094 152.516 139.133 154.742C137.563 155.918 135.653 156.426 133.709 156.426C131.765 156.426 129.675 155.895 128.047 154.641C120.521 148.82 128.002 135.981 131.957 130.398L133.562 128.138L135.189 130.375V130.375Z"
        fill={fourthColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.07 149.974C135.449 149.476 136.206 148.595 136.59 147.193C137.02 145.645 139.189 146.244 138.76 147.792C138.172 149.906 136.895 151.341 134.827 152.087C133.313 152.641 132.545 150.516 134.059 149.974"
        fill={fourthColor}
      />
      <path d="M106 218H0V324H106V218Z" fill={fifthColor} />
      <path
        d="M61.4331 265.688H47.1424V273.181H61.4331V265.688ZM70.7871 297.895V265.688H64.2461V297.895H70.7871ZM73.6 275.859V297.895H85.1682V275.859H73.6ZM73.6 265.677V273.169H88.0941V265.677H73.6ZM80.0054 262.987H79.3502C80.7736 262.411 81.6096 261.36 81.892 260.479C81.9937 260.14 82.0276 259.823 81.9711 259.563C81.9259 259.371 81.8356 259.19 81.6661 259.066C81.2481 258.738 80.4573 258.58 79.192 258.727C75.769 259.134 72.8883 261.371 71.205 262.987H80.0167H80.0054ZM51.6612 262.987C51.2771 262.445 50.9721 261.857 50.7913 261.27C50.5541 260.513 50.4976 259.733 50.6444 259.01C50.8139 258.218 51.2206 257.507 51.8984 256.953C52.8474 256.196 54.3047 255.8 56.3495 256.049C62.3595 256.772 66.8557 261.982 67.6013 262.897C68.3469 261.982 72.8431 256.772 78.8531 256.049C80.9092 255.8 82.3665 256.196 83.3042 256.953C83.9933 257.495 84.4 258.207 84.5581 259.01C84.705 259.733 84.6485 260.513 84.4113 261.27C84.2305 261.857 83.9368 262.434 83.5414 262.987H89.2012C89.6305 262.987 90.0146 263.157 90.2857 263.439C90.5681 263.722 90.7376 264.106 90.7376 264.524V274.311C90.7376 274.729 90.5681 275.113 90.2857 275.395C90.0033 275.678 89.6192 275.847 89.2012 275.847H87.823V298.55C87.823 299.104 87.597 299.601 87.2355 299.963C86.874 300.336 86.3657 300.562 85.8234 300.562H49.3679C48.8143 300.562 48.306 300.336 47.9445 299.974C47.583 299.613 47.357 299.104 47.357 298.55V275.847H45.9788C45.5495 275.847 45.1654 275.678 44.8943 275.395C44.6118 275.113 44.4424 274.729 44.4424 274.311V264.524C44.4424 264.106 44.6118 263.722 44.8943 263.439C45.1767 263.157 45.5608 262.987 45.9788 262.987H51.6386H51.6612ZM64.0427 262.987C62.3595 261.371 59.4787 259.134 56.0557 258.727C54.7905 258.58 53.9997 258.727 53.5817 259.066C53.4122 259.202 53.3218 259.371 53.2767 259.563C53.2202 259.823 53.2541 260.14 53.3557 260.479C53.6269 261.36 54.8018 262.501 55.8072 262.987H64.054H64.0427ZM50.0683 297.884H61.4444V275.847H50.0683V297.884Z"
        fill={sixthColor}
      />
      <path
        d="M19.1149 261.338C18.6856 260.931 18.2902 260.501 17.9174 260.049V289.759C17.9174 292.381 18.9906 294.776 20.7191 296.505C22.4475 298.234 24.8425 299.308 27.4634 299.308C30.0843 299.308 32.4793 298.234 34.2077 296.505C35.9362 294.776 37.0094 292.381 37.0094 289.759V260.049C36.6479 260.513 36.2412 260.942 35.8119 261.338C33.6203 263.394 30.6718 264.66 27.4521 264.66C24.2324 264.66 21.2839 263.394 19.0923 261.338M16.5618 251.777H17.8383L17.9174 253.032C18.0756 255.529 19.2053 257.767 20.9224 259.383C22.6283 260.987 24.9216 261.971 27.4408 261.971C29.96 261.971 32.2533 260.987 33.9592 259.383C35.6876 257.767 36.806 255.518 36.9642 253.032L37.0433 251.777H39.6642V289.759C39.6642 293.126 38.2859 296.189 36.0717 298.404C33.8575 300.619 30.796 301.997 27.4295 301.997C24.063 301.997 21.0015 300.619 18.7873 298.404C16.5731 296.189 15.1948 293.126 15.1948 289.759V251.777H16.5392H16.5618Z"
        fill={sixthColor}
      />
      <path
        d="M34.2188 245.473C32.4904 243.744 30.1067 242.67 27.4632 242.67C24.8197 242.67 22.436 243.744 20.7076 245.473C18.9791 247.202 17.9059 249.586 17.9059 252.231C17.9059 254.875 18.9791 257.26 20.7076 258.989C22.436 260.718 24.8197 261.791 27.4632 261.791C30.1067 261.791 32.4904 260.718 34.2188 258.989C35.9473 257.26 37.0205 254.875 37.0205 252.231C37.0205 249.586 35.9473 247.202 34.2188 245.473ZM27.4632 239.992C30.841 239.992 33.9025 241.36 36.1167 243.574C38.3309 245.789 39.6979 248.852 39.6979 252.231C39.6979 255.61 38.3309 258.672 36.1167 260.887C33.9025 263.102 30.841 264.469 27.4632 264.469C24.0854 264.469 21.0239 263.102 18.8097 260.887C16.5955 258.672 15.2285 255.61 15.2285 252.231C15.2285 248.852 16.5955 245.789 18.8097 243.574C21.0239 241.36 24.0854 239.992 27.4632 239.992Z"
        fill={sixthColor}
      />
      <path
        d="M29.9599 249.744C29.3272 249.111 28.4461 248.716 27.4745 248.716C26.503 248.716 25.6218 249.111 24.9892 249.744C24.3566 250.377 23.9612 251.258 23.9612 252.229C23.9612 253.201 24.3566 254.082 24.9892 254.715C25.6218 255.347 26.503 255.743 27.4745 255.743C28.4461 255.743 29.3272 255.347 29.9599 254.715C30.5925 254.082 30.9879 253.201 30.9879 252.229C30.9879 251.258 30.5925 250.377 29.9599 249.744ZM27.4745 246.027C29.1804 246.027 30.7394 246.716 31.8578 247.846C32.9762 248.965 33.6766 250.512 33.6766 252.229C33.6766 253.947 32.9875 255.494 31.8578 256.613C30.7394 257.731 29.1917 258.431 27.4745 258.431C25.7574 258.431 24.2097 257.742 23.0913 256.613C21.9729 255.494 21.2725 253.947 21.2725 252.229C21.2725 250.512 21.9616 248.965 23.0913 247.846C24.2097 246.728 25.7574 246.027 27.4745 246.027Z"
        fill={sixthColor}
      />
      <path
        d="M38.3652 250.887H73.4537C74.2332 250.887 74.9336 251.203 75.442 251.712C75.9504 252.22 76.2667 252.932 76.2667 253.701V254.22C75.3177 254.492 74.4253 254.842 73.578 255.249V253.701C73.578 253.701 73.5667 253.633 73.5328 253.61C73.5102 253.588 73.4763 253.565 73.4424 253.565H39.6982V293.84H45.0191V296.53H37.0095V250.887H38.3539H38.3652Z"
        fill={sixthColor}
      />
      <path
        d="M61.4331 265.688H47.1424V273.181H61.4331V265.688ZM70.7871 297.895V265.688H64.2461V297.895H70.7871ZM73.6 275.859V297.895H85.1682V275.859H73.6ZM73.6 265.677V273.169H88.0941V265.677H73.6ZM80.0054 262.987H79.3502C80.7736 262.411 81.6096 261.36 81.892 260.479C81.9937 260.14 82.0276 259.823 81.9711 259.563C81.9259 259.371 81.8356 259.19 81.6661 259.066C81.2481 258.738 80.4573 258.58 79.192 258.727C75.769 259.134 72.8883 261.371 71.205 262.987H80.0167H80.0054ZM51.6612 262.987C51.2771 262.445 50.9721 261.857 50.7913 261.27C50.5541 260.513 50.4976 259.733 50.6444 259.01C50.8139 258.218 51.2206 257.507 51.8984 256.953C52.8474 256.196 54.3047 255.8 56.3495 256.049C62.3595 256.772 66.8557 261.982 67.6013 262.897C68.3469 261.982 72.8431 256.772 78.8531 256.049C80.9092 255.8 82.3665 256.196 83.3042 256.953C83.9933 257.495 84.4 258.207 84.5581 259.01C84.705 259.733 84.6485 260.513 84.4113 261.27C84.2305 261.857 83.9368 262.434 83.5414 262.987H89.2012C89.6305 262.987 90.0146 263.157 90.2857 263.439C90.5681 263.722 90.7376 264.106 90.7376 264.524V274.311C90.7376 274.729 90.5681 275.113 90.2857 275.395C90.0033 275.678 89.6192 275.847 89.2012 275.847H87.823V298.55C87.823 299.104 87.597 299.601 87.2355 299.963C86.874 300.336 86.3657 300.562 85.8234 300.562H49.3679C48.8143 300.562 48.306 300.336 47.9445 299.974C47.583 299.613 47.357 299.104 47.357 298.55V275.847H45.9788C45.5495 275.847 45.1654 275.678 44.8943 275.395C44.6118 275.113 44.4424 274.729 44.4424 274.311V264.524C44.4424 264.106 44.6118 263.722 44.8943 263.439C45.1767 263.157 45.5608 262.987 45.9788 262.987H51.6386H51.6612ZM64.0427 262.987C62.3595 261.371 59.4787 259.134 56.0557 258.727C54.7905 258.58 53.9997 258.727 53.5817 259.066C53.4122 259.202 53.3218 259.371 53.2767 259.563C53.2202 259.823 53.2541 260.14 53.3557 260.479C53.6269 261.36 54.8018 262.501 55.8072 262.987H64.054H64.0427ZM50.0683 297.884H61.4444V275.847H50.0683V297.884Z"
        fill={sixthColor}
      />
      <path
        d="M61.4331 265.688H47.1424V273.181H61.4331V265.688ZM70.7871 297.895V265.688H64.2461V297.895H70.7871ZM73.6 275.859V297.895H85.1682V275.859H73.6ZM73.6 265.677V273.169H88.0941V265.677H73.6ZM80.0054 262.987H79.3502C80.7736 262.411 81.6096 261.36 81.892 260.479C81.9937 260.14 82.0276 259.823 81.9711 259.563C81.9259 259.371 81.8356 259.19 81.6661 259.066C81.2481 258.738 80.4573 258.58 79.192 258.727C75.769 259.134 72.8883 261.371 71.205 262.987H80.0167H80.0054ZM51.6612 262.987C51.2771 262.445 50.9721 261.857 50.7913 261.27C50.5541 260.513 50.4976 259.733 50.6444 259.01C50.8139 258.218 51.2206 257.507 51.8984 256.953C52.8474 256.196 54.3047 255.8 56.3495 256.049C62.3595 256.772 66.8557 261.982 67.6013 262.897C68.3469 261.982 72.8431 256.772 78.8531 256.049C80.9092 255.8 82.3665 256.196 83.3042 256.953C83.9933 257.495 84.4 258.207 84.5581 259.01C84.705 259.733 84.6485 260.513 84.4113 261.27C84.2305 261.857 83.9368 262.434 83.5414 262.987H89.2012C89.6305 262.987 90.0146 263.157 90.2857 263.439C90.5681 263.722 90.7376 264.106 90.7376 264.524V274.311C90.7376 274.729 90.5681 275.113 90.2857 275.395C90.0033 275.678 89.6192 275.847 89.2012 275.847H87.823V298.55C87.823 299.104 87.597 299.601 87.2355 299.963C86.874 300.336 86.3657 300.562 85.8234 300.562H49.3679C48.8143 300.562 48.306 300.336 47.9445 299.974C47.583 299.613 47.357 299.104 47.357 298.55V275.847H45.9788C45.5495 275.847 45.1654 275.678 44.8943 275.395C44.6118 275.113 44.4424 274.729 44.4424 274.311V264.524C44.4424 264.106 44.6118 263.722 44.8943 263.439C45.1767 263.157 45.5608 262.987 45.9788 262.987H51.6386H51.6612ZM64.0427 262.987C62.3595 261.371 59.4787 259.134 56.0557 258.727C54.7905 258.58 53.9997 258.727 53.5817 259.066C53.4122 259.202 53.3218 259.371 53.2767 259.563C53.2202 259.823 53.2541 260.14 53.3557 260.479C53.6269 261.36 54.8018 262.501 55.8072 262.987H64.054H64.0427ZM50.0683 297.884H61.4444V275.847H50.0683V297.884Z"
        fill={sixthColor}
      />
      <path d="M322 218H216V324H322V218Z" fill={color} />
      <path
        d="M286.889 277.028H255.02C254.06 277.028 253.28 276.246 253.28 275.282C253.28 274.318 254.06 273.536 255.02 273.536H286.889C287.996 273.536 289.035 273.105 289.815 272.323L296.028 266.087C296.808 265.305 297.237 264.261 297.237 263.15V252.787C297.237 252.073 296.661 251.495 295.949 251.495L247.18 251.325C246.22 251.325 245.44 250.531 245.452 249.579C245.452 248.615 246.231 247.833 247.203 247.844L295.961 248.014C298.581 248.014 300.717 250.157 300.717 252.799V263.162C300.717 265.203 299.926 267.13 298.491 268.57L292.278 274.806C290.843 276.246 288.922 277.04 286.889 277.04"
        fill={secondColor}
      />
      <path
        d="M291.091 301.844C287.872 301.844 285.251 299.293 285.251 296.153C285.251 293.012 287.872 290.461 291.091 290.461C294.311 290.461 296.943 293.012 296.943 296.153C296.943 299.293 294.322 301.844 291.091 301.844ZM291.091 293.953C289.781 293.953 288.719 294.939 288.719 296.153C288.719 297.366 289.781 298.352 291.091 298.352C292.402 298.352 293.464 297.366 293.464 296.153C293.464 294.939 292.402 293.953 291.091 293.953Z"
        fill={secondColor}
      />
      <path
        d="M253.845 301.844C250.625 301.844 248.004 299.293 248.004 296.153C248.004 293.012 250.625 290.461 253.845 290.461C257.065 290.461 259.697 293.012 259.697 296.153C259.697 299.293 257.076 301.844 253.845 301.844ZM253.845 293.953C252.535 293.953 251.473 294.939 251.473 296.153C251.473 297.366 252.535 298.352 253.845 298.352C255.155 298.352 256.217 297.366 256.217 296.153C256.217 294.939 255.155 293.953 253.845 293.953Z"
        fill={secondColor}
      />
      <path
        d="M293.95 289.796H252.953C252.445 289.796 251.959 289.569 251.631 289.183C251.304 288.798 251.157 288.276 251.236 287.778L253.292 275.011C253.45 274.058 254.343 273.412 255.28 273.571C256.229 273.73 256.873 274.625 256.715 275.578L254.986 286.326H293.939C294.899 286.326 295.678 287.109 295.678 288.072C295.678 289.036 294.899 289.818 293.939 289.818"
        fill={secondColor}
      />
      <path
        d="M255.009 277.024C254.263 277.024 253.574 276.537 253.348 275.789L243.565 243.634H238.64C237.679 243.634 236.9 242.851 236.9 241.888C236.9 240.924 237.679 240.142 238.64 240.142H244.853C245.621 240.142 246.288 240.64 246.514 241.377L256.67 274.768C256.952 275.687 256.432 276.662 255.517 276.945C255.348 277.002 255.178 277.024 255.009 277.024Z"
        fill={secondColor}
      />
      <path
        d="M280.235 260.742C279.241 262.341 277.49 263.044 276.338 262.318C275.174 261.592 275.05 259.699 276.044 258.1C277.038 256.501 278.789 255.787 279.941 256.524C281.105 257.25 281.229 259.143 280.235 260.731"
        fill={secondColor}
      />
      <path
        d="M274.891 257.649C274.519 259.497 273.14 260.778 271.796 260.506C270.452 260.234 269.672 258.522 270.034 256.674C270.406 254.826 271.785 253.545 273.129 253.817C274.473 254.089 275.253 255.801 274.891 257.66"
        fill={secondColor}
      />
      <path
        d="M268.452 259.712C268.508 261.129 267.729 262.32 266.701 262.365C265.673 262.41 264.792 261.299 264.735 259.882C264.667 258.465 265.458 257.274 266.486 257.229C267.514 257.184 268.395 258.295 268.463 259.712"
        fill={secondColor}
      />
      <path
        d="M279.422 263.986C278.349 264.904 278.021 266.299 278.687 267.081C279.354 267.863 280.766 267.75 281.851 266.831C282.924 265.913 283.251 264.518 282.585 263.736C281.918 262.954 280.506 263.067 279.422 263.986Z"
        fill={secondColor}
      />
      <path
        d="M266 265.359C263.944 267.604 268.768 267.955 270.994 268.84C273.038 269.656 276.879 272.808 277.004 269.758C277.139 266.323 276.574 263.647 273.547 262.422C270.508 261.209 268.508 262.615 266.012 265.348"
        fill={secondColor}
      />
    </svg>
  );
};
