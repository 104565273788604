import { DeviceCheckError } from "ui";
import { buildTestIds, useAuth, useModalGroup } from "application";
import { useCallback } from "react";
import { ModalGeneral } from "../ModalGeneral";
import {
  ButtonResendModalConfirmEmailError,
  ButtonCloseModalConfirmEmailError,
} from "./styles";

interface ModalConfirmEmailErrorProps {
  isOpen: boolean;
  onCloseModal(): void;
  ariaHideApp?: boolean;
}

const ModalConfirmEmailError = ({
  isOpen,
  onCloseModal,
  ariaHideApp,
}: ModalConfirmEmailErrorProps) => {
  const { userData } = useAuth();
  const { sendOrResendEmailConfirmation } = useModalGroup();

  const email = userData?.email as string;

  const handleResendEmail = useCallback(() => {
    sendOrResendEmailConfirmation(email);
  }, [sendOrResendEmailConfirmation, email]);

  return (
    <ModalGeneral
      ariaHideApp={ariaHideApp}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      ariaLabelForModal="Erro na confirmação de e-mail"
      title="Erro na confirmação de e-mail"
      bodyDescription="Não foi possível confirmar o seu e-mail. Para continuar de maneira segura, tente novamente."
      illustration={<DeviceCheckError />}
      bodyContent={
        <>
          <ButtonResendModalConfirmEmailError
            {...buildTestIds("button-resend-confirm-email-error")}
            onClick={handleResendEmail}
          >
            Reenviar e-email
          </ButtonResendModalConfirmEmailError>
          <ButtonCloseModalConfirmEmailError
            {...buildTestIds("button-close-confirm-email-error")}
            onClick={onCloseModal}
          >
            Deixar para mais tarde
          </ButtonCloseModalConfirmEmailError>
        </>
      }
    />
  );
};

export type { ModalConfirmEmailErrorProps };

export { ModalConfirmEmailError };
