import { TestId } from "typing";

const buildTestIds = (id: string): TestId => {
  if (typeof id !== "string") {
    return {
      "data-cy": "",
      "data-testid": "",
    };
  }

  return {
    "data-cy": id,
    "data-testid": id,
  };
};

export { buildTestIds };
