import {
  buildTestIds,
  validateConfirmPassword,
  validatePassword,
} from "application";
import { UseFormRegister, useForm } from "react-hook-form";
import { IFormValues } from "typing";
import { theme } from "ui";
import { useCallback, useState } from "react";
import { Input, Label } from "../Form";
import { PasswordValidation } from "../PasswordValidation";
import {
  ButtonConfirmModalPasswordChange,
  ChangePasswordContainer,
  ChangePasswordForm,
  EyeCloseIcon,
  EyeOpenIcon,
  InputWrapper,
  PasswordValidationWrapper,
} from "./styles";

type ChangePasswordFormValues = {
  password: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type TypePassword = "text" | "password";

interface ChangePasswordProps {
  onChangePassword: (password: string) => void;
  isUnderAction?: boolean;
}

const ChangePassword = ({
  onChangePassword,
  isUnderAction = false,
}: ChangePasswordProps) => {
  const { register, handleSubmit, setValue, watch } =
    useForm<ChangePasswordFormValues>();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [newPasswordType, setNewPasswordType] =
    useState<TypePassword>("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState<TypePassword>("password");

  const newPasswordWatcher = watch("newPassword");
  const confirmNewPasswordWatcher = watch("confirmNewPassword");

  const changePasswordVisibility = (type: TypePassword) => {
    return type === "password" ? "text" : "password";
  };

  const changePasswordIcon = (type: TypePassword) => {
    return type === "password" ? <EyeCloseIcon /> : <EyeOpenIcon />;
  };

  const isInvalidNewPassword = validatePassword(newPasswordWatcher);
  const isInvalidConfirmPassword = validateConfirmPassword(
    newPasswordWatcher,
    confirmNewPasswordWatcher
  );

  const isFormInvalid =
    isInvalidNewPassword ||
    isInvalidConfirmPassword ||
    !newPasswordWatcher ||
    !confirmNewPasswordWatcher ||
    passwordStrength !== 5;

  const isNewPasswordValid = newPasswordWatcher?.length > 0;

  const verifyNewPassword = isInvalidNewPassword && isNewPasswordValid;

  const isConfirmPasswordValid = confirmNewPasswordWatcher?.length > 0;
  const verifyConfirmPassword =
    isInvalidConfirmPassword && isConfirmPasswordValid;

  const handleChangePassword = useCallback(() => {
    onChangePassword(newPasswordWatcher);
  }, [newPasswordWatcher, onChangePassword]);

  return (
    <ChangePasswordContainer>
      <ChangePasswordForm
        {...buildTestIds("change-password-form")}
        onSubmit={handleSubmit(handleChangePassword)}
      >
        <InputWrapper {...buildTestIds("new-password-input-wrapper")}>
          <Label
            htmlFor="new-password"
            text="Senha"
            color={`${theme.colors.neutral["585"]} !important`}
            fontSize={theme.typography.fontSize.sm.x5}
            typeFontWeight={theme.typography.fontWeight["400"]}
          />
          <Input
            id="new-password"
            name="newPassword"
            type={newPasswordType}
            placeholder="Digite uma senha válida"
            register={register as unknown as UseFormRegister<IFormValues>}
            disabled={isUnderAction}
            hasError={verifyNewPassword}
            hasSuccessfully={isNewPasswordValid && !verifyNewPassword}
            width="100%"
            {...buildTestIds("new-password-input")}
            onChange={({ target }) => setValue("newPassword", target?.value)}
            height="45px"
            padding="1rem"
            hasIcon
            paddingInfo="0px"
            paddingErrorMessage="0px"
            onClickIcon={() =>
              setNewPasswordType(changePasswordVisibility(newPasswordType))
            }
            icon={changePasswordIcon(newPasswordType)}
            fontSizeInfo={theme.typography.fontSize.sm.x3}
            colorInfo={`${theme.colors.neutral["550"]} !important`}
            textError={isInvalidNewPassword}
            margin={`${theme.space.x2} 0px 0px`}
          />
        </InputWrapper>
        <InputWrapper {...buildTestIds("confirm-new-password-input-wrapper")}>
          <Label
            htmlFor="confirm-new-password"
            text="Confirme sua senha"
            color={`${theme.colors.neutral["585"]} !important`}
            fontSize={theme.typography.fontSize.sm.x5}
            typeFontWeight={theme.typography.fontWeight["400"]}
          />
          <Input
            id="confirm-new-password"
            name="confirmNewPassword"
            type={confirmNewPasswordType}
            placeholder="Digite novamente a senha"
            register={register as unknown as UseFormRegister<IFormValues>}
            disabled={isUnderAction}
            hasError={verifyConfirmPassword}
            hasSuccessfully={isConfirmPasswordValid && !verifyConfirmPassword}
            textError={isInvalidConfirmPassword}
            width="100%"
            {...buildTestIds("confirm-new-password-input")}
            onChange={({ target }) =>
              setValue("confirmNewPassword", target?.value)
            }
            hasIcon
            paddingInfo="0px"
            paddingErrorMessage="0px"
            onClickIcon={() =>
              setConfirmNewPasswordType(
                changePasswordVisibility(confirmNewPasswordType)
              )
            }
            icon={changePasswordIcon(confirmNewPasswordType)}
            fontSizeInfo={theme.typography.fontSize.sm.x3}
            colorInfo={theme.colors.neutral["550"]}
            height="45px"
            padding="1rem"
            margin={`${theme.space.x2} 0px 0px`}
          />
        </InputWrapper>
      </ChangePasswordForm>
      <PasswordValidationWrapper
        {...buildTestIds("password-validation-wrapper")}
      >
        <PasswordValidation
          showComponent={isNewPasswordValid}
          userConfirmPassword={confirmNewPasswordWatcher}
          userPassword={newPasswordWatcher}
          onChangePasswordStrength={(strength) => setPasswordStrength(strength)}
        />
      </PasswordValidationWrapper>
      <ButtonConfirmModalPasswordChange
        {...buildTestIds("change-password-button")}
        isDisabled={!!isFormInvalid}
        text="Alterar senha"
        textUnderAction="Alterando senha..."
        underAction={isUnderAction}
        onClickAction={handleSubmit(handleChangePassword)}
      />
    </ChangePasswordContainer>
  );
};

export { ChangePassword };
