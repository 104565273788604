import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const TelephoneErrorIcon = ({
  width = 143,
  height = 119,
  color = theme.colors.neutral["white-075"],
  secondColor = theme.colors.neutral["750"],
  thirdColor = theme.colors.primary["60"],
  fourthColor = theme.colors.primary["77"],
  fifthColor = theme.colors.neutral["740"],
  sixthColor = theme.colors.primary["360"],
  seventhColor = theme.colors.neutral.white,
  octaveColor = theme.colors.neutral["240"],
  ninthColor = theme.colors.neutral["760"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 143 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4106_87462)">
        <path
          d="M55.9105 13.0589C60.9008 4.32363 73.496 4.32362 78.4863 13.0589L127.854 99.4741C132.805 108.141 126.547 118.923 116.566 118.923H17.8308C7.84975 118.923 1.59192 108.141 6.54296 99.4741L55.9105 13.0589Z"
          fill={color}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.1048 36.8853H79.0254C84.8975 36.8853 89.6536 41.6414 89.6536 47.5134V95.3403C89.6536 101.212 84.8975 105.968 79.0254 105.968H57.1048C51.2327 105.968 46.4766 101.212 46.4766 95.3403V47.5134C46.4766 41.6414 51.2327 36.8853 57.1048 36.8853Z"
        fill={color}
        stroke={secondColor}
        strokeWidth="4.63085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="51.168"
        y="53.7805"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill={thirdColor}
      />
      <rect
        x="51.168"
        y="42.5171"
        width="33.7907"
        height="5.63178"
        rx="2.54473"
        fill="url(#paint0_linear_4106_87462)"
      />
      <rect
        x="51.168"
        y="85.6941"
        width="33.7907"
        height="15.0181"
        rx="7.50904"
        fill="url(#paint1_linear_4106_87462)"
      />
      <rect
        x="51.168"
        y="66.9211"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill={thirdColor}
      />
      <rect
        x="51.168"
        y="73.4917"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill={thirdColor}
      />
      <rect
        x="64.3105"
        y="60.3511"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill={fourthColor}
      />
      <rect
        x="64.3105"
        y="80.0623"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill={fourthColor}
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.8287 33.342C44.9571 33.342 44.25 34.0491 44.25 34.9211C44.25 35.7932 44.9571 36.4999 45.8287 36.4999H50.1708C51.0429 36.4999 51.75 35.7928 51.75 34.9211C51.75 34.0495 51.0429 33.3424 50.1712 33.3424C50.1712 32.1432 49.1992 31.1711 48 31.1711C46.8008 31.1711 45.8287 32.1428 45.8287 33.342Z"
          fill={ninthColor}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.951 84.5113C107.547 78.5811 110.074 71.797 115.773 68.8859C121.471 65.9747 128.45 67.9026 131.846 73.3261C135.242 78.7497 133.928 85.8693 128.821 89.7244C123.713 93.5794 116.506 92.8907 112.222 88.1381L108.237 88.1088L109.951 84.5113Z"
        fill="#EB3E53"
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.351 72.1294L125.694 73.3579C126.678 73.5841 127.292 74.5643 127.066 75.5483L124.609 86.2341C124.383 87.2181 123.402 87.8319 122.418 87.6056L117.075 86.3772C116.091 86.151 115.478 85.1707 115.704 84.1868L118.161 73.5009C118.387 72.517 119.367 71.9032 120.351 72.1294Z"
        fill={color}
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.24 72.3342L120.872 73.9371C120.804 74.2319 120.948 74.5173 121.194 74.5738L123.866 75.188C124.111 75.2445 124.366 75.0508 124.434 74.7561L124.802 73.1532"
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.261 84.5358L121.256 84.9944"
        stroke="#5C5E62"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4449 64.3805C35.1847 58.5977 33.0496 51.68 27.5272 48.4473C22.0047 45.2147 14.9274 46.7397 11.2265 51.9599C7.52549 57.1801 8.42888 64.3632 13.3072 68.5044C18.1855 72.6456 25.42 72.3708 29.97 67.8713L33.9503 68.0703L32.4449 64.3805Z"
        fill="#EB3E53"
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7747 51.4235L17.3702 52.344C16.3749 52.5135 15.706 53.457 15.8755 54.4523L17.7164 65.2613C17.8859 66.2566 18.8294 66.9255 19.8247 66.7559L25.2292 65.8355C26.2245 65.666 26.8934 64.7225 26.7239 63.7272L24.883 52.9182C24.7135 51.9229 23.77 51.254 22.7747 51.4235Z"
        fill={color}
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.873 51.5769L22.1492 53.1983C22.2 53.4964 22.0395 53.7731 21.7909 53.8154L19.0886 54.2756C18.84 54.318 18.597 54.11 18.5462 53.8119L18.2701 52.1905"
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1529 63.8722L21.1352 64.2159"
        stroke="#5C5E62"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.2431 13.079C64.9356 13.079 63.875 14.0689 63.875 15.2898C63.875 16.5107 64.9356 17.5 66.2431 17.5H72.7562C74.0644 17.5 75.125 16.5101 75.125 15.2898C75.125 14.0695 74.0644 13.0795 72.7569 13.0795C72.7569 11.4007 71.2987 10.0398 69.5 10.0398C67.7012 10.0398 66.2431 11.4001 66.2431 13.079Z"
          stroke="#8F9194"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.8287 25.342C70.9571 25.342 70.25 26.0491 70.25 26.9211C70.25 27.7932 70.9571 28.4999 71.8287 28.4999H76.1708C77.0429 28.4999 77.75 27.7928 77.75 26.9211C77.75 26.0495 77.0429 25.3424 76.1712 25.3424C76.1712 24.1432 75.1992 23.1711 74 23.1711C72.8008 23.1711 71.8287 24.1428 71.8287 25.342Z"
          fill={ninthColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.2431 26.6446C56.9356 26.6446 55.875 27.5638 55.875 28.6975C55.875 29.8312 56.9356 30.7499 58.2431 30.7499H64.7562C66.0644 30.7499 67.125 29.8307 67.125 28.6975C67.125 27.5643 66.0644 26.6451 64.7569 26.6451C64.7569 25.0862 63.2987 23.8225 61.5 23.8225C59.7012 23.8225 58.2431 25.0857 58.2431 26.6446Z"
          stroke={ninthColor}
          strokeWidth="0.62"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.9602 86.6446C28.7399 86.6446 27.75 87.5638 27.75 88.6975C27.75 89.8312 28.7399 90.7499 29.9602 90.7499H36.0392C37.2601 90.7499 38.25 89.8307 38.25 88.6975C38.25 87.5643 37.2601 86.6451 36.0397 86.6451C36.0397 85.0862 34.6788 83.8225 33 83.8225C31.3212 83.8225 29.9602 85.0857 29.9602 86.6446Z"
          fill={ninthColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.8287 78.342C96.9571 78.342 96.25 79.0491 96.25 79.9211C96.25 80.7932 96.9571 81.4999 97.8287 81.4999H102.171C103.043 81.4999 103.75 80.7928 103.75 79.9211C103.75 79.0495 103.043 78.3424 102.171 78.3424C102.171 77.1432 101.199 76.1711 100 76.1711C98.8008 76.1711 97.8287 77.1428 97.8287 78.342Z"
          fill={ninthColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.9602 62.079C94.7399 62.079 93.75 63.0689 93.75 64.2898C93.75 65.5107 94.7399 66.5 95.9602 66.5H102.039C103.26 66.5 104.25 65.5101 104.25 64.2898C104.25 63.0695 103.26 62.0795 102.04 62.0795C102.04 60.4007 100.679 59.0398 99 59.0398C97.3212 59.0398 95.9602 60.4001 95.9602 62.079Z"
          stroke={octaveColor}
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8287 109.342C31.9571 109.342 31.25 110.049 31.25 110.921C31.25 111.793 31.9571 112.5 32.8287 112.5H37.1708C38.0429 112.5 38.75 111.793 38.75 110.921C38.75 110.049 38.0429 109.342 37.1712 109.342C37.1712 108.143 36.1992 107.171 35 107.171C33.8008 107.171 32.8287 108.143 32.8287 109.342Z"
          fill={ninthColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.112 106.342C103.153 106.342 102.375 107.049 102.375 107.921C102.375 108.793 103.153 109.5 104.112 109.5H108.888C109.847 109.5 110.625 108.793 110.625 107.921C110.625 107.049 109.847 106.342 108.888 106.342C108.888 105.143 107.819 104.171 106.5 104.171C105.181 104.171 104.112 105.143 104.112 106.342Z"
          fill={ninthColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1116 103.342C12.1528 103.342 11.375 104.049 11.375 104.921C11.375 105.793 12.1528 106.5 13.1116 106.5H17.8879C18.8472 106.5 19.625 105.793 19.625 104.921C19.625 104.049 18.8472 103.342 17.8884 103.342C17.8884 102.143 16.8191 101.171 15.5 101.171C14.1809 101.171 13.1116 102.143 13.1116 103.342Z"
          fill={ninthColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.243 97.079C109.936 97.079 108.875 98.0689 108.875 99.2898C108.875 100.511 109.936 101.5 111.243 101.5H117.756C119.064 101.5 120.125 100.51 120.125 99.2898C120.125 98.0695 119.064 97.0795 117.757 97.0795C117.757 95.4007 116.299 94.0398 114.5 94.0398C112.701 94.0398 111.243 95.4001 111.243 97.079Z"
          stroke={octaveColor}
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <ellipse cx="70.5" cy="113" rx="23.5" ry="1" fill={octaveColor} />
      <defs>
        <linearGradient
          id="paint0_linear_4106_87462"
          x1="83.5"
          y1="41.5"
          x2="55.5"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fifthColor} stopOpacity="0.7" />
          <stop offset="1" stopColor={fifthColor} stopOpacity="0.17" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4106_87462"
          x1="81.5"
          y1="86"
          x2="54"
          y2="100"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fifthColor} stopOpacity="0.7" />
          <stop offset="1" stopColor={sixthColor} stopOpacity="0.06" />
        </linearGradient>
        <clipPath id="clip0_4106_87462">
          <rect width="135" height="118.77" fill={seventhColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
