import Modal from "react-modal";
import { ModalProps } from "typing";
import { buildTestIds } from "application";

const defaultStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 999,
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "development") {
  try {
    Modal.setAppElement("#__next");
  } catch {
    Modal.setAppElement("body");
  }
}

const ReactModal = ({
  children,
  styles,
  isOpen,
  contentLabel,
  preventScroll = false,
  testId = undefined,
  onCloseModal,
  ariaHideApp,
  shouldCloseOnOverlayClick = true,
}: ModalProps) => {
  document.body.style.overflow = isOpen ? "hidden" : "unset";

  return (
    <Modal
      {...buildTestIds(testId ?? "modal")}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onCloseModal}
      ariaHideApp={ariaHideApp}
      style={
        styles
          ? {
              ...{ content: { ...defaultStyles.content, ...styles.content } },
              ...{ overlay: { ...defaultStyles.overlay, ...styles.overlay } },
            }
          : { ...defaultStyles }
      }
      contentLabel={contentLabel}
      preventScroll={preventScroll}
      testId={testId}
      closeTimeoutMS={300}
    >
      {children}
    </Modal>
  );
};

export { ReactModal };
