import { IconProps } from "typing";
import { theme } from "../themes";

export const Bag = ({
  alt = "Sacola de compras",
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99993 5V6H3.79002C2.77092 6 1.91479 6.76627 1.80225 7.77914L0.493459 19.5583C0.477162 19.705 0.468994 19.8524 0.468994 20C0.468994 22.2091 2.25986 24 4.46899 24H17.5309C17.6785 24 17.8259 23.9918 17.9726 23.9755C20.1682 23.7316 21.7504 21.7539 21.5064 19.5583L20.1976 7.77914C20.0851 6.76627 19.2289 6 18.2098 6H15.9999V5C15.9999 2.23858 13.7614 0 10.9999 0C8.23851 0 5.99993 2.23858 5.99993 5ZM15.9999 9.12692V7.5H18.2098C18.4646 7.5 18.6787 7.69157 18.7068 7.94478L20.0156 19.7239C20.1681 21.0962 19.1792 22.3322 17.807 22.4847C17.7153 22.4949 17.6231 22.5 17.5309 22.5H4.46899C3.08828 22.5 1.96899 21.3807 1.96899 20C1.96899 19.9078 1.9741 19.8156 1.98428 19.7239L3.29308 7.94478C3.32121 7.69157 3.53524 7.5 3.79002 7.5H5.99993V9.12692C5.99993 9.54114 6.33572 9.87692 6.74993 9.87692C7.16415 9.87692 7.49993 9.54114 7.49993 9.12692V7.5H14.4999V9.12692C14.4999 9.54114 14.8357 9.87692 15.2499 9.87692C15.6641 9.87692 15.9999 9.54114 15.9999 9.12692ZM14.4999 6V5C14.4999 3.067 12.9329 1.5 10.9999 1.5C9.06694 1.5 7.49993 3.067 7.49993 5V6H14.4999ZM14.0314 13.4143L9.5556 16.995L8.03026 15.4697C7.73737 15.1768 7.2625 15.1768 6.9696 15.4697C6.67671 15.7626 6.67671 16.2374 6.9696 16.5303L8.9696 18.5303C9.23983 18.8006 9.67004 18.8244 9.96846 18.5857L14.9685 14.5857C15.2919 14.3269 15.3443 13.8549 15.0856 13.5315C14.8268 13.208 14.3549 13.1556 14.0314 13.4143Z"
        fill={color}
      />
    </svg>
  );
};
