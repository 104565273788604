/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import { mappedIcons, IconWrapper, DeskInfo, Aggregator, theme } from "ui";
import { IHttpResponse, SliderProps, SupportContactDto } from "typing";
import { supportContactApi } from "implementations";
import { Link } from "../../../Link";
import { Skeleton } from "../../../Skeleton";
import { SupportContactText, SupportContactTitle } from "../styles";
import { Slider } from "../../../Slider";

interface SupportContactMobileProps {
  isBlackTheme?: boolean;
}

const SupportContactMobile = ({ isBlackTheme }: SupportContactMobileProps) => {
  const [options, setOptions] = useState({
    data: null,
    isLoading: true,
    error: false,
  } as IHttpResponse<SupportContactDto[], unknown>);

  useEffect(() => {
    const getOptions = async () => {
      const response = await supportContactApi.getOptions();
      setOptions(response);
    };

    getOptions();
  }, []);

  const { data, isLoading } = options;

  if (!data) {
    return null;
  }

  const settings: SliderProps = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    swipe: true,
    dots: true,
    autoplay: true,
    infinite: true,
  };
  return isLoading ? (
    <Skeleton count={3} />
  ) : (
    <Slider {...settings}>
      {data?.map(({ mainIcon, title, description, link, secondaryIcons }) => {
        return (
          <Aggregator
            key={title}
            justifyContent="center"
            display="flex"
            alignItems="start"
            padding={`${theme.space.x2} ${theme.space.x8} ${theme.space.x8}`}
          >
            <DeskInfo>
              <IconWrapper
                color={
                  isBlackTheme
                    ? theme.colors.secondary["230"]
                    : theme.colors.secondary["300"]
                }
                width="75px"
                height="75px"
              >
                {mappedIcons(
                  mainIcon?.title,
                  {
                    color: theme.colors.neutral.white,
                  },
                  {
                    secondColor: theme.colors.neutral.white,
                  }
                )}
              </IconWrapper>
            </DeskInfo>
            <DeskInfo>
              <SupportContactTitle isBlackTheme={!!isBlackTheme}>
                {title}
              </SupportContactTitle>

              {description && (
                <SupportContactText isBlackTheme={!!isBlackTheme}>
                  {description}
                </SupportContactText>
              )}

              {link && (
                <Link
                  color={
                    isBlackTheme
                      ? theme.colors.secondary["230"]
                      : theme.colors.primary["200"]
                  }
                  href={link?.href}
                >
                  {link?.title}
                </Link>
              )}

              <Aggregator
                display="flex"
                flexDirection="row"
                margin={`${theme.space.x4} 0 0`}
                gap="8px"
              >
                {secondaryIcons?.map(({ title: iconTitle, link: iconLink }) => (
                  <IconWrapper
                    key={iconLink}
                    width={theme.space.x6}
                    height={theme.space.x6}
                    color="transparent"
                    borderRadius={theme.space.x1}
                    clickable
                    marginRight="0px"
                    isBlackTheme={!!isBlackTheme}
                    mouseOverColor={theme.colors.primary["200"]}
                  >
                    <Link href={iconLink || ""} aria-label={iconTitle}>
                      {mappedIcons(
                        iconTitle,
                        {
                          color: theme.colors.neutral["280"],
                        },
                        {
                          secondColor: theme.colors.neutral["280"],
                        }
                      )}
                    </Link>
                  </IconWrapper>
                ))}
              </Aggregator>
            </DeskInfo>
          </Aggregator>
        );
      })}
    </Slider>
  );
};

export { SupportContactMobile };
