import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const DefaultCard = ({
  width = 65,
  height = 40,
  alt = "Default Card",
  color = `${theme.colors.neutral["25"]}`,
  secondColor = theme.colors.primary["200"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 65 40"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="63.5"
        height="38.5"
        rx="3.25"
        fill={color}
        stroke={secondColor}
      />
      <rect x="1" y="8" width="63" height="7" fill={secondColor} />
      <rect x="5" y="25" width="21" height="7" rx="2" fill={secondColor} />
      <rect x="35" y="25" width="22" height="2" rx="1" fill={secondColor} />
      <rect x="35" y="29" width="18" height="2" rx="1" fill={secondColor} />
    </svg>
  );
};
