import { CustomTextBlockDto } from "typing";
import { memo, useCallback, useState } from "react";
import { buildTestIds, useAppContext } from "application";
import {
  calculateTextLimit,
  customTextBlockData,
  removeHtmlTags,
  truncateHtml,
} from "./data";
import {
  CustomTextBlockContainer,
  CustomTextBlockHeader,
  CustomTextBlockCtaText,
  CustomTextBlockContent,
  CustomTextBlockCtaContainer,
  CustomTextBlockTitle,
  CustomTextBlockSubTitle,
  CustomTextBlockCollapsibleField,
  ArrowDownIcon,
} from "./styles";

const CustomTextBlock = memo(
  ({ properties, isBlackTheme }: CustomTextBlockDto) => {
    const {
      title,
      showTitle,
      ctaText,
      subTitle,
      showSubTitle,
      text,
      plainText,
      hideText,
    } = customTextBlockData(properties);

    const showTitleBoolean = showTitle === "true";
    const showSubTitleBoolean = showSubTitle === "true";
    const shouldHideText = hideText === "true";
    const textLength = plainText && plainText?.length > 180;
    const [hidingText, setHidingText] = useState<boolean>(shouldHideText);
    const hasToShowCollapse = shouldHideText && textLength;
    const { isClientMobile } = useAppContext();

    const handleChangeHidingTextValue = useCallback(() => {
      setHidingText((state) => !state);
    }, []);

    return (
      <CustomTextBlockContainer
        customTheme={isBlackTheme}
        {...buildTestIds("custom-textblock-container")}
      >
        <CustomTextBlockHeader {...buildTestIds("custom-textblock-header")}>
          {showTitleBoolean && (
            <CustomTextBlockTitle {...buildTestIds("custom-textblock-title")}>
              {title}
            </CustomTextBlockTitle>
          )}
          {showSubTitleBoolean && (
            <CustomTextBlockSubTitle
              {...buildTestIds("custom-textblock-subtitle")}
            >
              {subTitle}
            </CustomTextBlockSubTitle>
          )}
        </CustomTextBlockHeader>
        {hidingText ? (
          <CustomTextBlockContent
            dangerouslySetInnerHTML={{
              __html: text
                ? truncateHtml(
                    text,
                    calculateTextLimit(
                      removeHtmlTags(text),
                      isClientMobile ? 180 : 430
                    )
                  )
                : "",
            }}
            {...buildTestIds("custom-textblock-content")}
          />
        ) : (
          <CustomTextBlockContent
            dangerouslySetInnerHTML={{ __html: text ?? "" }}
            {...buildTestIds("custom-textblock-content")}
          />
        )}
        {hasToShowCollapse && (
          <CustomTextBlockCtaContainer
            onClick={() => handleChangeHidingTextValue()}
            {...buildTestIds("custom-textblock-ctaContainer")}
          >
            <CustomTextBlockCtaText
              {...buildTestIds("custom-textblock-ctaText")}
            >
              {hidingText ? ctaText : "Ocultar"}
            </CustomTextBlockCtaText>
            <CustomTextBlockCollapsibleField
              hidingText={hidingText}
              {...buildTestIds("custom-textblock-collapsibleField")}
            >
              <ArrowDownIcon />
            </CustomTextBlockCollapsibleField>
          </CustomTextBlockCtaContainer>
        )}
      </CustomTextBlockContainer>
    );
  }
);

CustomTextBlock.displayName = "CustomTextBlock";

export { CustomTextBlock };
