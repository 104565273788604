import styled from "styled-components";
import { theme } from "ui";

const makeLongShadow = (color: string, size: string) => {
  const shadowLength = 700;
  return Array.from(
    { length: shadowLength },
    (_, i) => `${i + 18}px 0 0 ${size} ${color}`
  ).join(", ");
};

const height = "36px";
const trackHeight = "8px";
const thumbHeight = 30;

const barColor = theme.colors.neutral[99];
const progressColor = theme.colors.secondary[265];

const upperBackground = `linear-gradient(to bottom, ${barColor}, ${barColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${progressColor}, ${progressColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
export const Container = styled.div`
  flex-direction: column;
  margin: 0;

  .reactEasyCrop_Container {
    height: 250px;
    position: relative;
  }
`;

export const RangeContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  padding: 0 24px;
`;

export const RangeInput = styled.input`
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: ${height};
  cursor: pointer;

  /* Webkit */
  &::-webkit-slider-runnable-track {
    overflow: hidden;
    width: 100%;
    height: ${height};
    background: linear-gradient(to bottom, ${progressColor}, ${progressColor})
      100% 50% / 100% ${trackHeight} no-repeat transparent;
  }
  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbHeight}px;
    background: ${theme.colors.neutral.white};
    border-radius: 100%;
    border: 1px solid ${theme.colors.neutral["99"]};
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.09);
    top: 50%;
    transform: translateY(-50%);
    box-shadow: ${makeLongShadow(barColor, "-10px")};
    transition: background-color 150ms;
  }

  /* Firefox */
  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }
  &::-moz-range-progress {
    background: ${lowerBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${theme.colors.neutral.white};
    border-radius: 100%;
    border: 1px solid ${theme.colors.neutral["99"]};
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.09);
    transition: background-color 150ms;
  }
`;
