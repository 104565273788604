import styled from "styled-components";
import { theme } from "ui";
import { ProductCardContainer } from "../ProductCard/styles";
// import { ProductCardStyledContent } from "../ProductCard/styles";

interface PlaylistStyledProps {
  width?: string;
  isProductPage?: boolean;
}

export const PlaylistStyled = styled.div<PlaylistStyledProps>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 7px;
  width: ${({ width }) => width || "100%"};
  align-items: center;
  justify-content: flex-start;
  place-items: center;

  //artur.castro essa é uma media query especifica para o comportamento
  @media (max-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 7px;
  }

  @media ${theme.device.desktopFirst.laptop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;
  }

  @media ${theme.device.desktopFirst.tablet} {
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;
  }

  //artur.castro essa é uma media query especifica para o comportamento
  @media (max-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 7px;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    grid-template-columns: repeat(2, 50%);
    gap: 0px;
    column-gap: ${({ isProductPage }) => (isProductPage ? "8px" : "0")};
  }

  @media ${theme.device.desktopFirst.mobileS} {
    grid-template-columns: repeat(1, calc(100% - 2px));
    gap: 0px;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;

  ${ProductCardContainer} {
    height: 100%;
  }
`;
