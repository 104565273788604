import { Text, theme } from "ui";
import {
  useAppContext,
  buildTestIds,
  useToast,
  useAuth,
  useUser,
} from "application";
import { notificationApi } from "implementations";
import { useCallback, useEffect, useState } from "react";
import { IFormValues, WhatsAppReceiveNotificationDto } from "typing";
import { useForm, UseFormRegister } from "react-hook-form";
import {
  WhatsAppOptionContainer,
  WhatsAppImage,
  WhatsAppForm,
  CheckBoxContainer,
} from "./styles";
import Checkbox from "../Form/Checkbox";
import {
  myDataWhatsAppOptionCheckboxTestIds,
  myDataWhatsAppOptionContainerTestIds,
  myDataWhatsAppOptionImageTestIds,
  myDataWhatsAppOptionTextTestIds,
} from "./test-utils";

interface WhatsAppOptionProps {
  isSavingWhatsAppOption: boolean;
  shallUseToast: boolean;
  finishSaveWhatsAppOption?: (errorMessage?: string) => void;
  hasBackground?: boolean;
}

const WhatsAppOption = ({
  isSavingWhatsAppOption,
  shallUseToast,
  finishSaveWhatsAppOption,
  hasBackground = false,
}: WhatsAppOptionProps) => {
  const { addToast } = useToast();
  const { isClientMobile } = useAppContext();
  const { register } = useForm<WhatsAppReceiveNotificationDto>();
  const { getTokens } = useAuth();
  const { acceptedWhatsapp } = useUser();

  const accessToken = getTokens()?.token;
  const defaultErrorMessage = "Ocorreu um erro inesperado, tente novamente";

  const [isOptionChecked, setIsOptionChecked] = useState(acceptedWhatsapp);

  // gabriel.lenon diminuir complexidade
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSaveWhatsAppOption = useCallback(async () => {
    if (accessToken && isSavingWhatsAppOption) {
      const { data, error: onSetUserNotificationsError } =
        await notificationApi.setUserNotificationsSettingsWhatsApp(
          {
            token_type: "Bearer",
            access_token: accessToken,
          },
          {
            notificationChannelId: 5,
            notificationTypeId: 1,
            receiveNotification: isOptionChecked,
          }
        );

      if (onSetUserNotificationsError) {
        if (shallUseToast) {
          addToast({
            title: `${onSetUserNotificationsError ?? defaultErrorMessage}`,
            type: "error",
          });
        }
        if (finishSaveWhatsAppOption)
          finishSaveWhatsAppOption(
            `${onSetUserNotificationsError ?? defaultErrorMessage}`
          );
        return;
      }

      if (!data) {
        if (shallUseToast) {
          addToast({
            title: defaultErrorMessage,
            type: "error",
          });
        }

        if (finishSaveWhatsAppOption)
          finishSaveWhatsAppOption(
            `Ocorreu um erro inesperado, tente novamente`
          );
        return;
      }

      if (shallUseToast) {
        addToast({
          title: "Opção alterada com sucesso!",
          type: "success",
        });
      }

      if (finishSaveWhatsAppOption) finishSaveWhatsAppOption();
    }
  }, [
    accessToken,
    addToast,
    finishSaveWhatsAppOption,
    isOptionChecked,
    isSavingWhatsAppOption,
    shallUseToast,
  ]);

  useEffect(() => {
    onSaveWhatsAppOption();
  }, [
    accessToken,
    addToast,
    isOptionChecked,
    isSavingWhatsAppOption,
    finishSaveWhatsAppOption,
    shallUseToast,
    onSaveWhatsAppOption,
  ]);

  const fontSize = isClientMobile
    ? theme.typography.fontSize.sm.x3
    : theme.typography.fontSize.sm.x5;

  return (
    <WhatsAppOptionContainer
      hasBackground={hasBackground}
      {...buildTestIds("whatsapp-option-container")}
      {...myDataWhatsAppOptionContainerTestIds}
    >
      <WhatsAppForm {...buildTestIds("whatsapp-form")} id="whatsAppForm">
        <CheckBoxContainer {...buildTestIds("checkbox-Container")}>
          <Checkbox
            {...buildTestIds("receive-notification")}
            id="receive-notification"
            height={theme.space.x4}
            width={theme.space.x4}
            borderRadius={theme.space.x1}
            borderColor={theme.colors.neutral["580"]}
            name="receiveNotification"
            color={theme.colors.secondary["250"]}
            required={false}
            hasError={false}
            heightChecked="8px"
            widthChecked="4px"
            left="1px"
            sizeChecked="1em"
            register={register as unknown as UseFormRegister<IFormValues>}
            checked={isOptionChecked}
            disabled={isSavingWhatsAppOption}
            isCheckedEvent={(isChecked) => {
              if (isChecked !== null && isChecked !== undefined) {
                setIsOptionChecked(isChecked);
              }
            }}
            {...myDataWhatsAppOptionCheckboxTestIds}
          />
        </CheckBoxContainer>
        <WhatsAppImage {...myDataWhatsAppOptionImageTestIds} />
        <Text
          {...buildTestIds("whatsapp-option-text")}
          aria-label="Desejo acompanhar meu pedido por WhatsApp"
          fontSize={fontSize}
          color={theme.colors.neutral["550"]}
          {...myDataWhatsAppOptionTextTestIds}
          textAlign="flex-start"
          margin="0px"
        >
          Desejo acompanhar meu pedido por WhatsApp
        </Text>
      </WhatsAppForm>
    </WhatsAppOptionContainer>
  );
};

export { WhatsAppOption };
