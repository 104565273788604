import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const NewEyeCloseIcon = ({
  width = 16,
  height = 16,
  color = theme.colors.neutral["550"],
  secondColor = theme.colors.neutral.white,
  alt = "ícone de olho fechado",
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1475_522305)">
        <path
          d="M9.41317 9.41334C9.23007 9.60984 9.00927 9.76744 8.76394 9.87675C8.51861 9.98607 8.25377 10.0448 7.98523 10.0496C7.71669 10.0543 7.44995 10.0049 7.20091 9.90433C6.95188 9.80374 6.72565 9.65403 6.53573 9.46411C6.34582 9.27419 6.1961 9.04797 6.09551 8.79893C5.99492 8.5499 5.94552 8.28315 5.95026 8.01461C5.955 7.74607 6.01378 7.48124 6.12309 7.2359C6.2324 6.99057 6.39001 6.76977 6.5865 6.58667M11.9598 11.96C10.8202 12.8287 9.43258 13.3099 7.99984 13.3333C3.33317 13.3333 0.666504 8.00001 0.666504 8.00001C1.49576 6.4546 2.64593 5.10441 4.03984 4.04001L11.9598 11.96ZM6.59984 2.82667C7.05872 2.71926 7.52855 2.66557 7.99984 2.66667C12.6665 2.66667 15.3332 8.00001 15.3332 8.00001C14.9285 8.75708 14.4459 9.46983 13.8932 10.1267L6.59984 2.82667Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.666504 0.666656L15.3332 15.3333"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1475_522305">
          <rect width="16" height="16" fill={secondColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
