import { appMonitoringClient, cookie } from "implementations";
import { BranchDto, ComponentType, LinkType } from "typing";
import { NextRouter } from "next/router";

export type LinkConfig = {
  url: string | null;
  id: string | null;
  target: "_self" | "_blank";
  // data?: T | null;
  // isLoading?: boolean;
} | null;

export type BranchLinkConfig = {
  [linkType in LinkType]: LinkConfig;
};

// const getProductLink = (
//   productId: string,
//   branchID = 2
// ): LinkConfig<Product> => {
//   const { data, isLoading } = catalogApi.getProduct(
//     Number(productId),
//     branchID,
//     !!productId && !Number.isNaN(productId)
//   );

//   return {
//     url: `produto/${data?.id}/${formatStringUrl(data?.description || "")}`,
//     id: data?.id || null,
//     target: "_self",
//     data,
//     isLoading,
//   };
// };

const handleOnClickRedirect = (linkConfig: LinkConfig, router: NextRouter) => {
  if (linkConfig?.url) {
    const { url, target } = linkConfig;
    if (target === "_blank") {
      window.open(url, "_ blank");
      return;
    }
    router.push(`/${url}`);
  }
};

const getLinkConfig = (
  typeLink: LinkType | null,
  id: string,
  url: string | null = ""
): LinkConfig => {
  if (!typeLink) {
    return null;
  }

  if (typeLink === "external") {
    return {
      url: url || id,
      id,
      target: "_blank",
    };
  }

  if (typeLink === "landingPage") {
    return {
      // No caso da landingPage, o backend irá retornar a url em linkId
      url: url || id,
      id,
      target: "_self",
    };
  }

  if (typeLink === "none") {
    return null;
  }

  if (typeLink === "playlist") {
    return {
      url,
      id,
      target: "_self",
    };
  }

  if (typeLink === "product") {
    // Tratamento momentâneo enquanto a api retorna a url de produto com "Product/" no início
    const formattedUrl =
      url?.replace("Product", "produto") || id ? `produto/${id}` : null;

    return {
      url: formattedUrl,
      id,
      target: "_self",
    };
  }

  return null;
};

const useGetBranchLinkConfig = (
  branches: BranchDto[] | null = [],
  componentType: ComponentType = "customizado"
) => {
  if (!branches?.length) {
    return null;
  }

  const currentBranchId =
    cookie.getCookie({
      name: "@FC:Ecom:Dropdown:BranchLocation",
    }) || "2";

  const currentBranch = branches?.find(({ id }) => id === currentBranchId);

  if (
    !currentBranch?.typeLink &&
    !currentBranch?.linkId &&
    !currentBranch?.linkUrl
  ) {
    appMonitoringClient.captureMessage(
      `Um componente ${componentType} não tem o tipo de link, o id do link e .`
    );
    return null;
  }

  const { typeLink, linkId: id, linkUrl: url } = currentBranch;

  if (typeLink === "playlist" && !url && !id) {
    appMonitoringClient.captureMessage(
      `Um componente ${componentType} cujo link é do tipo Playlist não possui uma URL configurada.`
    );
  }

  if (typeLink === "landingPage" && !url && !id) {
    appMonitoringClient.captureMessage(
      `Um componente ${componentType} cujo link é do tipo Landing Page não possui um id configurado.`
    );
  }

  return getLinkConfig(typeLink, id, url);
};

const useBackofficeComponentUrl = (
  configBranches?: BranchDto[],
  url?: string | null
) => {
  const typeLinkExternal = configBranches?.find(
    ({ typeLink }) => typeLink === "external"
  );

  const linkConfig = useGetBranchLinkConfig(
    configBranches,
    "subComponentImagesGrid"
  );

  const formattedUrl = linkConfig?.url?.includes("https://")
    ? linkConfig?.url
    : `https://${linkConfig?.url}`;

  if (typeLinkExternal) {
    return formattedUrl;
  }

  if (url) {
    return `/${url}`;
  }

  return "";
};

export {
  useGetBranchLinkConfig,
  handleOnClickRedirect,
  useBackofficeComponentUrl,
};
