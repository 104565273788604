import { IHeatmapClient } from "app-domain";
import { ClarityEventType, MappedTheme } from "typing";

const mockedBlackFridayTheme = (isBlackTheme = false) => {
  return {
    id: "mocked_id",
    isBlackTheme,
    startAt: "",
    endAt: "",
  };
};

const mockedDefaultPageThemeMap = {
  home: "default",
  category: "default",
  highlight: "default",
  searchResult: "default",
  login: "default",
  product: "default",
  cart: "default",
  myAddresses: "default",
  myOrders: "default",
  myWeddingLists: "default",
  myCreditCards: "default",
  changePassword: "default",
  myOrdersByPhone: "default",
  detailedOrder: "default",
  myData: "default",
};

type MockedPageThemeMapType = {
  home: MappedTheme;
  category: MappedTheme;
  highlight: MappedTheme;
  searchResult: MappedTheme;
  login: MappedTheme;
  product: MappedTheme;
  cart: MappedTheme;
  myAddresses: MappedTheme;
  myOrders: MappedTheme;
  myWeddingLists: MappedTheme;
  myCreditCards: MappedTheme;
  changePassword: MappedTheme;
  myOrdersByPhone: MappedTheme;
  detailedOrder: MappedTheme;
  myData: MappedTheme;
};

const mockedPageThemeMap = ({
  home = "default",
  category = "default",
  highlight = "default",
  searchResult = "default",
  login = "default",
  product = "default",
  cart = "default",
  myAddresses = "default",
  myOrders = "default",
  myWeddingLists = "default",
  myCreditCards = "default",
  changePassword = "default",
  myOrdersByPhone = "default",
  detailedOrder = "default",
  myData = "default",
}: MockedPageThemeMapType) => {
  return {
    home,
    category,
    highlight,
    searchResult,
    login,
    product,
    cart,
    myAddresses,
    myOrders,
    myWeddingLists,
    myCreditCards,
    changePassword,
    myOrdersByPhone,
    detailedOrder,
    myData,
  };
};

type mockedHeatmapClientMapProps = {
  key?: string | string[];
  value?: string | string[];
  customId?: string;
  upgradeReason?: string;
  customSessionId?: string;
  customPageId?: string;
  friendlyName?: string;
};

export const mockedHeatmapClientMap = new Map<
  ClarityEventType,
  mockedHeatmapClientMapProps
>();

export const mockedHeatmapClient: IHeatmapClient = {
  Id(): string {
    return "mocked_heatmapClient_id";
  },
  cookieConsent(): void {
    mockedHeatmapClientMap.set("consent", {});
  },
  customIdentifiers(
    customId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string
  ): void {
    mockedHeatmapClientMap.set("identify", {
      customId,
      customSessionId,
      customPageId,
      friendlyName,
    });
  },
  customTags(key: string, value: string | string[]): void {
    mockedHeatmapClientMap.set("set", {
      key,
      value,
    });
  },
  upgradeSession(upgradeReason: string): void {
    mockedHeatmapClientMap.set("upgrade", {
      upgradeReason,
    });
  },
  resetCustomIdentifiers(): void {
    mockedHeatmapClientMap.set("identify", {
      customId: "",
      customSessionId: "",
    });
  },
};

export {
  mockedBlackFridayTheme,
  mockedDefaultPageThemeMap,
  mockedPageThemeMap,
};

export const dropListTerms = [
  /Failed to fetch/,
  "Invalid data: Key Name",
  "<unknown>",
  "aD",
  "Response served by service worker is an error",
  /TypeError/,
];
