import { ICookie } from "app-domain";
import { createContext, ReactNode, useContext, useMemo } from "react";
import {
  BlackFridayThemeProps,
  ComponentDto,
  CustomHighlightCategoryDto,
} from "typing";
import { BackofficeApi } from "../../services";
import { getCustomHeader } from "./get-custom-header";
import { highlightCategoriesMapper } from "./highlight-categories-mapper";

export interface HeaderState {
  customHeader: ComponentDto | null;
  isLoadingCustomHeader: boolean;
  highlightCategories: CustomHighlightCategoryDto[] | null;
  isLoadingHighlightCategories: boolean;
  headerBlackFridayTheme?: BlackFridayThemeProps;
}

interface HeaderWrapperProviderProps {
  children: ReactNode;
  backofficeApi: BackofficeApi;
  // catalogApi: CatalogApi;
  cookie: ICookie;
}

const HeaderContext = createContext({} as HeaderState);

export const HeaderProvider = ({
  children,
  backofficeApi,
  cookie,
}: HeaderWrapperProviderProps) => {
  const { customHeader, isLoadingCustomHeader } = getCustomHeader(
    backofficeApi,
    cookie
  );

  // const { highlightCategories, isLoadingHighlightCategories } =
  //   getHighlightCategories(catalogApi, cookie);

  const isLoadingHighlightCategories = isLoadingCustomHeader;

  const highlightCategories = highlightCategoriesMapper(
    customHeader?.subComponents
  );

  const headerContextProviderValue = useMemo(() => {
    return {
      customHeader,
      isLoadingCustomHeader,
      highlightCategories,
      isLoadingHighlightCategories,
    };
  }, [
    customHeader,
    isLoadingCustomHeader,
    highlightCategories,
    isLoadingHighlightCategories,
  ]);

  return (
    <HeaderContext.Provider value={headerContextProviderValue}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  return useContext(HeaderContext);
};
