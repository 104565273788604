import { ComponentDto } from "typing";

const isVisibleOnDesktop = (component: ComponentDto) => {
  return [
    "stories",
    "imagesGrid",
    "carousel",
    "dynamicBanner",
    "gridPlaylists",
  ]?.includes(component?.type)
    ? component?.subComponents?.some(
        (subComponent) => subComponent?.config?.branches?.[0]?.visibleOnDesktop
      )
    : component?.config?.branches?.[0]?.visibleOnDesktop;
};

const isVisibleOnMobile = (component: ComponentDto) => {
  return [
    "stories",
    "imagesGrid",
    "carousel",
    "dynamicBanner",
    "gridPlaylists",
  ]?.includes(component?.type)
    ? component?.subComponents?.some(
        (subComponent) => subComponent?.config?.branches?.[0]?.visibleOnMobile
      )
    : component?.config?.branches?.[0]?.visibleOnMobile;
};

export * from "./handleBackofficeContent";
export { isVisibleOnDesktop, isVisibleOnMobile };
