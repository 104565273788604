import styled, { css } from "styled-components";
import { Aggregator, theme } from "ui";
import { StyledLink } from "../../StyledLink/style";

type StyledFooterProps = {
  isBlackTheme?: boolean;
};

export const StyledFooter = styled.footer`
  .slick-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
    gap: 10px;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
    ${Aggregator} {
      display: inline-flex !important;
    }

    ${StyledLink} {
      position: absolute;
    }
  }
`;

export const Wrapper = styled.div<StyledFooterProps>`
  ${`
    border-top: ${theme.space.xpx} solid ${theme.colors.neutral["250"]};
    border-bottom: ${theme.space.xpx} solid ${theme.colors.neutral["250"]};
    background-color: ${theme.colors.neutral.white}
  `};

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      border-top: ${theme.space.xpx} solid ${theme.colors.neutral["750"]};
      border-bottom: ${theme.space.xpx} solid ${theme.colors.neutral["750"]};
      background-color: ${theme.colors.blackFriday["200"]} !important;
      color: ${theme.colors.blackFriday.white} !important;

      @media ${theme.device.desktopFirst.tablet} {
        background-color: ${theme.colors.blackFriday["175"]} !important;
      }
    `}
`;

export const Button = styled.button<StyledFooterProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.space.x60};
  height: ${theme.space.x10};
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  font-family: "Chivo", Arial, Helvetica, sans-serif;
  ${`
    background-color: ${theme.colors.neutral["white-050"]};
    border-top: ${theme.space.xpx} solid ${theme.colors.neutral["250"]};
    border-left: ${theme.space.xpx} solid ${theme.colors.neutral["250"]};
    border-right: ${theme.space.xpx} solid ${theme.colors.neutral["250"]};
    border-bottom: none;
  `};

  &:hover {
    background-color: ${theme.colors.neutral.white};
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      border-top: ${theme.space.xpx} solid ${theme.colors.neutral["750"]};
      border-left: ${theme.space.xpx} solid ${theme.colors.neutral["750"]};
      border-right: ${theme.space.xpx} solid ${theme.colors.neutral["750"]};
      background-color: ${theme.colors.blackFriday["175"]} !important;
    `};
`;

export const FooterDescriptionContainer = styled(Aggregator)<StyledFooterProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.space.x0} ${theme.space.x0};
  text-align: center;
  color: ${theme.colors.neutral["520"]} !important;

  @media ${theme.device.desktopFirst.laptopL} {
    padding: ${theme.space.x0} ${theme.space.x2} ${theme.space.x2};
  }

  @media ${theme.device.desktopFirst.tablet} {
    margin: ${theme.space.x0};
    padding: ${theme.space.x0} ${theme.space.x1};
    text-align: start;
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      background-color: ${theme.colors.blackFriday["175"]};
      * {
        background-color: ${theme.colors.blackFriday["175"]} !important;
        color: ${theme.colors.blackFriday.white} !important;
      }
    `}
`;
