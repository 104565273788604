export type ValidateNameResponse =
  | "O campo nome é obrigatório!"
  | "O campo nome precisa ter um sobrenome"
  | "O campo nome não permite números!"
  | "O campo nome não permite caracteres especiais!"
  | "O campo deve ter o máximo de 50 caracteres"
  | "";

export const validateName = (
  name: string,
  isRequired = true
): ValidateNameResponse => {
  const nameRequired = "O campo nome é obrigatório!";
  const nameShouldHaveSurname = "O campo nome precisa ter um sobrenome";
  const nameContainsNumbers = "O campo nome não permite números!";
  const nameShouldHaveMaxLengthTwentySix =
    "O campo deve ter o máximo de 50 caracteres";
  const blank = " ";

  if (!isRequired && !name) return "";

  if (!name || typeof name !== "string") return nameRequired;

  if (name.length < 1 && name !== undefined) return nameShouldHaveSurname;

  if (name.length > 50) return nameShouldHaveMaxLengthTwentySix;

  if (name.match(/\d/)) return nameContainsNumbers;

  const removedSpaceBlankFromName = name.trim();
  const fullName = removedSpaceBlankFromName.split(blank);

  if (fullName?.length < 2) return nameShouldHaveSurname;
  return "";
};

export const validateFirstName = (name: string) => {
  const mandatory = "O campo é obrigatório!";
  const cantNumber = "O campo não permite números!";
  const cantSpecialChars = "O campo não permite caracteres especiais!";
  const valid = "";
  const mustBeAtLeastThreeCharacters =
    "É necessário ter no mínimo três caracteres";

  if (!name || typeof name !== "string") return mandatory;

  if (name.match(/\d/)) return cantNumber;

  if (!name.match(/^[a-zA-ZÀ-ÿç\s]*$/)) return cantSpecialChars;

  if (name.length < 3) return mustBeAtLeastThreeCharacters;

  return valid;
};

export const handleNameValidation = (name: string) => {
  const textError = name ? validateFirstName(name) : "";

  const hasError = !name ? false : !name.match(/^\D{20,}$/);

  const hasSuccessfully = /^[a-zA-ZÀ-ÿ\s]{3,}$/.test(name ?? "");

  return { textError, hasError, hasSuccessfully };
};

export const validateWeddingList = (value: string): boolean => {
  const isNameValid = validateFirstName(value);

  return !!(isNameValid === "");
};

export const validateNameFormWeddingList = (value: string) => {
  const invalidateName =
    /[0-9]|@|#|&|\$|!|\*|\[|\]|\{|\}|\/|\||\\|%|\(|\)|\+|-|_|\.|"/g;

  if (value?.match(invalidateName)) {
    return "";
  }

  return value;
};
