import { IconProps } from "typing";
import { theme } from "../themes";

export const LinkedinIconFooter = ({
  width = 28,
  height = 28,
  alt = "Ícone do Linkedin",
  color = theme.colors.neutral["280"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M19.3636 3H4.63636C3.73227 3 3 3.73227 3 4.63636V19.3636C3 20.2677 3.73227 21 4.63636 21H19.3636C20.2677 21 21 20.2677 21 19.3636V4.63636C21 3.73227 20.2677 3 19.3636 3ZM8.68964 17.7273H6.276V9.96109H8.68964V17.7273ZM7.45827 8.85082C6.68018 8.85082 6.051 8.22 6.051 7.44355C6.051 6.66709 6.681 6.03709 7.45827 6.03709C8.23391 6.03709 8.86473 6.66791 8.86473 7.44355C8.86473 8.22 8.23391 8.85082 7.45827 8.85082ZM17.7305 17.7273H15.3185V13.9505C15.3185 13.0497 15.3022 11.8912 14.0643 11.8912C12.8084 11.8912 12.6153 12.8722 12.6153 13.8851V17.7273H10.2033V9.96109H12.5187V11.0223H12.5515C12.8738 10.4119 13.6609 9.768 14.835 9.768C17.2789 9.768 17.7305 11.3765 17.7305 13.4678V17.7273Z"
        fill={color}
      />
    </svg>
  );
};
