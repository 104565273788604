import styled from "styled-components";
import { Column, Container, theme } from "ui";

export const UtilInfoContainer = styled(Container)`
  @media ${theme.device.desktopFirst.mobileM} {
    max-width: 100%;
    padding: 0;
  }

  @media ${theme.device.desktopFirst.mobileS} {
    max-width: 100%;
    padding: 0;
  }
`;

export const UtilInfoPaymentOptionsWrapper = styled(Column).attrs({
  lg: "6",
  xs: "12",
})``;

export const UtilInfoContent = styled(Column).attrs({
  lg: "6",
})`
  @media ${theme.device.desktopFirst.mobileS} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
