import { AllConfigs } from "typing";
import { createStore } from "../adapter";

type EnvsState = {
  allConfigs: AllConfigs | null;
};

type EnvsDispatch = {
  setAllConfigs: (allConfigs: AllConfigs | null) => void;
};

type EnvsProps = EnvsState & EnvsDispatch;

const useEnvs = createStore<EnvsProps>((set) => ({
  allConfigs: null,
  setAllConfigs: (allConfigs) => set({ allConfigs }),
}));

export { useEnvs };
