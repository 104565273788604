const formatLocationName = (cityAndState: string) => {
  if (typeof cityAndState !== "string") {
    return "";
  }

  const alwaysInLowerCaseWords = ["da", "das", "de", "do", "dos"];

  const [city, state] = cityAndState.trim().split("-");

  if (!city) {
    return "";
  }

  const cityInLowerCase = city?.toLowerCase()?.trim();

  const formattedCity = cityInLowerCase
    ?.split(/\s+/)
    ?.map((cityWord) => {
      const firstLetter = alwaysInLowerCaseWords?.includes(cityWord)
        ? cityWord?.[0]
        : cityWord?.[0] === "("
        ? `${cityWord?.[0]}${cityWord?.[1]?.toUpperCase()}`
        : cityWord?.[0]?.toUpperCase();

      const restOfLetters = cityWord.substring(
        firstLetter?.startsWith("(") ? 2 : 1
      );

      return `${firstLetter}${restOfLetters}`;
    })
    ?.join(" ");

  const formattedState = state ? ` - ${state?.trim()}` : "";

  return `${formattedCity}${formattedState}`;
};

export { formatLocationName };
