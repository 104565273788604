import { IconProps } from "typing";
import { theme } from "../themes";

export const Google = ({
  width = 20.5,
  height = 20.5,
  color = theme.colors.neutral.white,
}: IconProps) => {
  return (
    <svg
      fill={color}
      height={height}
      width={width}
      version="1.1"
      id="Capa_1"
      xmlns="https://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 210 210"
      xmlSpace="preserve"
    >
      <path
        d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40
      c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105
      S0,162.897,0,105z"
      />
    </svg>

    // <svg
    //     width={width}
    //     height={height}
    //     viewBox="0 0 24 24"
    //     fill="none"
    //     xmlns="https://www.w3.org/2000/svg"
    // >
    //     <path
    //         d="M17.12 5.856C15.733 4.698 13.948 4 12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12H13"
    //         stroke={color}
    //         strokeWidth={strokeWidth}
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //     />
    // </svg>
  );
};
