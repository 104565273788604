import {
  IAppMonitoringClient,
  IGtag,
  IHttpClient,
} from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import {
  AlertStatusDto,
  CancelOrderDto,
  CreateOrderDto,
  CreateOrderErrorDto,
  EligibleClientDto,
  IHttpResponse,
  NewOrdersDto,
  OrderDetailsDto,
  OrdersQueryParams,
  OrderStatus,
  OrderStatusDto,
  OrderStatusResponse,
  PostOrdersBffDto,
  PostOrdersResponse,
  PostOrdersResponseBffDto,
  ProductCardDto,
  UniqueClientDTO,
} from "typing";
import { defaultHeaders } from "../../../utils/http";
import { EnvsApi } from "../Envs";
import { handleOrderPaymentError } from "../Payment/data";
import { getApisUrlFallback } from "../../../utils";

export class PurchaseApi implements IApi {
  baseUrl = "";

  bffBaseUrl = "";

  serverSideUrl = "";

  constructor(
    private httpClient: IHttpClient,
    private appMonitoringClient: IAppMonitoringClient,
    private gtag: IGtag,
    private envsApi: EnvsApi
  ) {
    this.serverSideUrl = "/api/purchase/";

    if (!this.baseUrl || !this.bffBaseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      if (apisBaseUrl) {
        this.baseUrl = `${apisBaseUrl || fallback}purchase/`;
        this.bffBaseUrl = `${apisBaseUrl || fallback}bff-site/purchase/`;
      }
    }

    this.appMonitoringClient = appMonitoringClient;
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getOrdersEndpoint = ({
    pageSize,
    status,
    search,
    startDate,
    endDate,
    isGift,
    saleChannel,
    currentPage,
    getOmnichannel = false,
  }: OrdersQueryParams) => {
    const searchFormatted = search ? `&Search=${search}` : "";
    const startDateFormatted = startDate ? `&StartDate=${startDate}` : "";
    const endDateFormatted = endDate ? `&EndDate=${endDate}` : "";
    const isGiftFormatted = isGift ? `&IsGift=${isGift}` : "";
    const saleChannelFormatted = saleChannel
      ? `&SaleChannel=${saleChannel}`
      : "";

    return `${this.baseUrl}v1/orders?getOnlyOmnichannel=${getOmnichannel}&CurrentPage=${currentPage}&PageSize=${pageSize}&Status=${status}${searchFormatted}${startDateFormatted}${endDateFormatted}${isGiftFormatted}${saleChannelFormatted}`;
  };

  getOrders = ({
    jwt,
    search,
    startDate,
    endDate,
    isGift,
    saleChannel,
    getOmnichannel,
    pageSize = 5,
    status = "All",
    currentPage = 0,
    condition = false,
  }: OrdersQueryParams): IHttpResponse<NewOrdersDto, unknown, string[]> => {
    const { data, error, isLoading } = this.httpClient.useGet<NewOrdersDto>(
      this.getOrdersEndpoint({
        jwt,
        pageSize,
        status,
        search,
        startDate,
        endDate,
        isGift,
        saleChannel,
        currentPage,
        getOmnichannel,
      }),
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
      condition
    ) as IHttpResponse<NewOrdersDto, unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  getOrdersByServer = async (
    jwt: string,
    search: string,
    getOmnichannel: boolean,
    status: OrderStatus = "All",
    currentPage = 0,
    pageSize = 5
  ): Promise<IHttpResponse<NewOrdersDto, string, string[]>> => {
    try {
      const response = await fetch(
        this.getOrdersEndpoint({
          currentPage,
          pageSize,
          status,
          search,
          getOmnichannel,
        }),
        {
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      const formattedError = {
        data: null,
        isLoading: false,
        error: "Houve um erro ao carregar os pedidos eletrônicos",
      };

      let data;
      if (response.body) {
        data = await response.json();
      }

      if (!response.ok) {
        return formattedError;
      }

      return {
        data,
        isLoading: false,
        error: "",
      };
    } catch (err) {
      return {
        data: null,
        isLoading: false,
        error: JSON.stringify(err),
      };
    }
  };

  getOrdersDetails = (
    jwt: string,
    groupId: string,
    condition = false
  ): IHttpResponse<OrderDetailsDto, unknown> => {
    const { data, isLoading, error, trigger } =
      this.httpClient.useGet<OrderDetailsDto>(
        this.getOrdersDetailsEndpoint(groupId),
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
        condition
      ) as IHttpResponse<OrderDetailsDto, unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  getOrdersDetailsByPhone = async (
    jwt: string,
    groupId: string,
    condition = true
  ): Promise<IHttpResponse<OrderDetailsDto, unknown>> => {
    if (process?.browser) {
      const { data, isLoading, error, trigger } =
        this.httpClient.useGet<OrderDetailsDto>(
          this.getOrdersDetailsEndpoint(groupId),
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          },
          condition
        ) as IHttpResponse<OrderDetailsDto, unknown>;

      return {
        data,
        isLoading,
        error,
        trigger,
      };
    }

    try {
      const response = await fetch(this.getOrdersDetailsEndpoint(groupId), {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      const data = await response.json();

      return {
        data,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureMessage(JSON.stringify(error));

      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  getOrdersDetailsEndpoint = (groupId: string) => {
    return `${this.baseUrl}v1/orders/details/${groupId}`;
  };

  postCancelOrderEndpoint = (orderHash: string) => {
    return `${this.baseUrl}v1/orders/${orderHash}/cancel-order`;
  };

  cancelOrder = async (
    orderHash: string,
    body: CancelOrderDto,
    jwt: string
  ) => {
    try {
      const response = await this.httpClient.usePost<CancelOrderDto>(
        this.postCancelOrderEndpoint(orderHash),
        body as unknown as BodyInit,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      return {
        data: response,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  private postOrderEndpoint = () => {
    return `${this.bffBaseUrl}order`;
  };

  createOrder = async (
    {
      jwt,
      cartId,
      couponMovementId,
      payment,
      shippingOptions,
      shipping,
      giftMessages,
      gtagItems,
      quantity,
      sessionId,
      fingerprint,
      clientIp,
      giftCardSMSToken,
    }: CreateOrderDto,
    condition?: boolean
  ) => {
    try {
      const response = (await this.httpClient.usePost<
        PostOrdersBffDto,
        CreateOrderErrorDto
      >(
        this.postOrderEndpoint(),
        {
          cartId,
          payment,
          shippingOptions,
          couponMovementId,
          giftMessages,
          ...(giftCardSMSToken && { giftCardSMSToken }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
            ...(sessionId && { clearSale: sessionId }),
            ...(fingerprint && { allowMe: fingerprint }),
            ...(clientIp && { "X-Client-Ip": clientIp }),
          },
          timeout: "62000",
        },
        condition
      )) as unknown as IHttpResponse<PostOrdersResponse, CreateOrderErrorDto>;

      const formattedData = response as unknown as PostOrdersResponseBffDto;

      if (formattedData?.statusCode && formattedData.statusCode === 400) {
        handleOrderPaymentError(
          formattedData,
          this.appMonitoringClient,
          "payment"
        );

        return {
          data: null,
          isLoading: false,
          error: formattedData?.validationProblemDetails,
        };
      }

      const { data, isLoading, error } = response;

      const total = (data as unknown as PostOrdersResponse[])?.reduce(
        (previousOrder, currentOrder) =>
          previousOrder + currentOrder.totalPrice,
        shipping
      );

      const packageItem = (data as unknown as PostOrdersResponse[])?.at(0);

      if (!data?.groupId || !data?.clientId || error)
        this.appMonitoringClient.captureMessage(
          `O endpoint de bff-site/purchase/order não está retornando o valor de ${
            !packageItem?.groupId ? "groupId" : ""
          }${
            !packageItem?.clientId ? "| clientId" : ""
          }:\n Response: {clientId: ${data?.clientId}, groupId: ${
            packageItem?.groupId
          }, hash: ${packageItem?.ordertHash}, totalPrice: ${
            packageItem?.totalPrice
          }}\n error: ${error}\n`,
          { level: "error" }
        );

      this.gtag.purchase(
        total,
        shipping,
        String(packageItem?.groupId ?? ""),
        gtagItems as ProductCardDto[],
        quantity,
        Number(packageItem?.clientId),
        "BRL"
      );

      return {
        data,
        isLoading,
        error,
      };
    } catch (error) {
      handleOrderPaymentError(error, this.appMonitoringClient, "payment");

      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  // eslint-disable-next-line class-methods-use-this
  private getVerifyUniqueClientEndpoint = () => {
    return "/api/purchase";
  };

  getVerifyUniqueClient = async (jwt: string) => {
    try {
      const response = await fetch(this.getVerifyUniqueClientEndpoint(), {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      const data = await response.json();

      if (
        String(response?.status)?.startsWith("4") ||
        String(response?.status)?.startsWith("5")
      ) {
        return {
          data: null,
          isLoading: false,
          error: data,
        };
      }

      return {
        data: data as UniqueClientDTO,
        isLoading: false,
        error: null,
      };
    } catch (error) {
      this.appMonitoringClient.captureException(error);
      return {
        data: null,
        isLoading: false,
        error,
      };
    }
  };

  getOrdersStatusEndpoint = (orderId: number) => {
    return `${this.baseUrl}v1/order-status/${orderId}`;
  };

  getOrdersStatus = (
    accessToken: string,
    orderId: number,
    condition = false
  ): IHttpResponse<OrderStatusDto[], unknown> => {
    const { data, error, isLoading, trigger } = this.httpClient.useGet<
      OrderStatusDto[]
    >(
      this.getOrdersStatusEndpoint(orderId),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      condition
    ) as IHttpResponse<OrderStatusDto[], unknown>;
    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };

  getChatEligibleForClientEndpoint = () => {
    return `${this.baseUrl}v1/customer-orders/chat-eligible-for-client`;
  };

  getChatEligibleForClient = (
    accessToken: string,
    condition = false
  ): IHttpResponse<EligibleClientDto, unknown> => {
    const { data, error, isLoading } =
      this.httpClient.useGet<EligibleClientDto>(
        this.getChatEligibleForClientEndpoint(),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        condition
      ) as IHttpResponse<EligibleClientDto, unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  getAlertStatusEndpoint = () => {
    return `${this.baseUrl}v1/orders/alert-status`;
  };

  getAlertStatus = (
    accessToken: string,
    condition = false
  ): IHttpResponse<AlertStatusDto[], unknown> => {
    const { data, error, isLoading } = this.httpClient.useGet<AlertStatusDto[]>(
      this.getAlertStatusEndpoint(),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      condition
    ) as IHttpResponse<AlertStatusDto[], unknown>;
    return {
      data,
      isLoading,
      error,
    };
  };

  getFiscalDocumentEndpoint = (
    orderId: number,
    branchId: string,
    typeDocument: "Danfe" | "Xml"
  ) => {
    return `${this.baseUrl}v1/orders/fiscal-document?numOrder=${orderId}&branchid=${branchId}&typeDocument=${typeDocument}`;
  };

  getFiscalDocument = async (
    orderId: number,
    accessToken: string,
    branchId: string,
    typeDocument: "Danfe" | "Xml" = "Danfe"
  ) => {
    try {
      const response = await fetch(
        this.getFiscalDocumentEndpoint(orderId, branchId, typeDocument),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/pdf",
          },
        }
      );

      const responseToBlob = await response.blob();

      const invoiceLink =
        response.status === 200
          ? window.URL.createObjectURL(responseToBlob)
          : "";

      if (response.status !== 200) {
        return {
          data: [],
          fileName: "",
          isLoading: false,
          error: `Houve um erro ao obter a nota fiscal, status code: ${response.status}`,
        };
      }

      return {
        data: invoiceLink,
        fileName:
          response.headers
            ?.get("Content-Disposition")
            ?.split("filename=")[1]
            .split(";")[0] ?? "",
        isLoading: false,
        error: null,
      };
    } catch (err) {
      this.appMonitoringClient.captureException(err);
      return {
        data: [],
        fileName: "",
        isLoading: false,
        error: err,
      };
    }
  };

  getOrderTrackingTMSEndpoint = (trackingCode: string) => {
    return `${this.bffBaseUrl}get-order-tracking?trackingCode=${trackingCode}`;
  };

  getOrderTrackingTMS = (
    accessToken: string,
    trackingCode: string,
    condition = false
  ): IHttpResponse<OrderStatusResponse, unknown> => {
    const { data, error, isLoading, trigger } =
      this.httpClient.useGet<OrderStatusResponse>(
        this.getOrderTrackingTMSEndpoint(trackingCode),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        condition
      ) as IHttpResponse<OrderStatusResponse, unknown>;

    return {
      data,
      isLoading,
      error,
      trigger,
    };
  };
}
