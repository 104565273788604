import styled from "styled-components";
import { theme } from "ui";

interface StyledButtonSubmitProps {
  color?: string;
  contentColor?: string;
  hoveredColor?: string;
  underAction?: boolean;
  fontSize?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  fontWeight?: number;
  border?: string;
  borderColor?: string;
  padding?: string;
  isDisabled?: boolean;
  alignSelf?: string;
  textDecoration?: string;
  borderRadius?: string;
  margin?: string;
}

export const StyledButtonSubmit = styled.button<StyledButtonSubmitProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  padding: ${({ underAction }) => (underAction ? "0.75rem" : 0)};
  cursor: ${({ underAction, isDisabled }) =>
    underAction || isDisabled ? "default" : "pointer"};
  opacity: ${({ underAction, isDisabled }) =>
    underAction || isDisabled ? "0.5" : "1"};
  align-self: ${({ alignSelf }) => alignSelf || "flex-start"};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  border: ${({ border }) => border || "none"};
  border-color: ${({ borderColor }) => borderColor || "initial"};
  border-radius: ${({ borderRadius }) => borderRadius || "4px"};
  width: 100%;
  min-width: ${({ minWidth }) => minWidth || "90px"};
  max-width: ${({ maxWidth }) => maxWidth || "max-content"};
  height: ${({ height }) => height || "4rem"};
  font-size: ${({ fontSize }) => fontSize || "1.2rem"};
  font-weight: ${({ fontWeight }) => fontWeight || "initial"};
  background: ${({ color }) => color || theme.colors.primary["200"]};
  color: ${({ contentColor }) => contentColor || theme.colors.neutral.white};
  border: ${({ border }) => border || "transparent"};
  font-family: "Chivo", sans-serif;
  transition: 0.2s ease-in-out;
  margin: ${({ margin }) => margin};

  ${({ isDisabled, underAction, hoveredColor }) =>
    !isDisabled &&
    !underAction &&
    `&:not(:disabled):hover { background: ${
      hoveredColor || theme.colors.primary["300"]
    }; }`};
`;

export const ButtonSubmitText = styled.span``;
