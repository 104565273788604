import { CustomCarouselDto } from "typing";
import { memo } from "react";
import { appMonitoringClient } from "implementations";
import { buildTestIds } from "application";
import { customCarouselData } from "./data";
import { ExtendedCarousel } from "./Extended";
import { GridCarousel } from "./Grid";

const CustomCarousel = memo(
  ({
    properties,
    position,
    subComponents,
    isClientMobile,
    isClientTablet,
    isClientLaptop,
    isBlackTheme,
    preloadComponent,
  }: CustomCarouselDto) => {
    const { format, slide, carouselBlackFridayTheme } = customCarouselData(
      properties,
      !!isBlackTheme
    );

    if (!format) {
      appMonitoringClient.captureMessage(
        `Um componente de Carrossel, localizado na posição ${position}, não está retornando o formato do componente.`,
        "warning"
      );
    }

    if (!subComponents?.length) {
      appMonitoringClient.captureMessage(
        "Um componente de carrossel não possui subcomponentes, ou seja, não existem items a serem mostrados",
        "warning"
      );
      return null;
    }

    const details = {
      isClientMobile,
      isClientTablet,
      isClientLaptop,
      isBlackTheme,
    };

    if (format === "extended") {
      return (
        <ExtendedCarousel
          {...buildTestIds("custom-extended-carousel")}
          automaticSlide={slide}
          subComponents={subComponents}
          carouselTheme={carouselBlackFridayTheme}
          preloadComponent={preloadComponent}
          {...details}
        />
      );
    }

    if (format === "grid") {
      return (
        <GridCarousel
          {...buildTestIds("custom-grid-carousel")}
          automaticSlide={slide}
          carouselTheme={carouselBlackFridayTheme}
          subComponents={subComponents}
          preloadComponent={preloadComponent}
          {...details}
        />
      );
    }

    return null;
  }
);

CustomCarousel.displayName = "CustomCarousel";

export { CustomCarousel };
