import { ChangeEvent } from "react";
import {
  Path,
  UseFormRegister,
  UseFormSetValue,
  ValidationRule,
} from "react-hook-form";
import { IFormValues } from "typing";
import FieldError from "../FieldError";
import { FieldInfo } from "../FieldInfo";
import { StyledInput } from "./style";

export type TextAreaProps = {
  id: string;
  name: string;
  mask?: string;
  maskChar?: string;
  label?: Path<IFormValues>;
  register: UseFormRegister<IFormValues>;
  setValue?: UseFormSetValue<IFormValues>;
  required?: string | boolean;
  pattern?: ValidationRule<RegExp>;
  minLength?: ValidationRule<number>;
  maxLength?: ValidationRule<number>;
  placeholder?: string;
  value?: string | number | string[];
  hasError?: boolean | string | number;
  textError?: string;
  textInfo?: string;
  hasSuccessfully?: boolean;
  disabled?: boolean;
  width?: string;
  padding?: string;
  rows?: number;
  cols?: number;
  paddingErrorMessage?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  onKeyUp?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
};

const TextArea = ({
  id,
  label,
  padding,
  paddingErrorMessage,
  hasError,
  hasSuccessfully,
  width,
  register,
  setValue,
  onChange,
  onBlur,
  required,
  pattern,
  minLength,
  maxLength,
  name,
  textError,
  textInfo,
  onKeyUp,
  onKeyDown,
  ...props
}: TextAreaProps) => {
  return (
    <StyledInput
      hasSuccessfully={hasSuccessfully}
      hasError={hasError as unknown as boolean}
      width={width || "initial"}
      padding={padding}
      data-testid={`form-styled-input-${id}`}
    >
      {label && (
        <label htmlFor={id} data-testid={`form-input-label-${id}`}>
          {label}
        </label>
      )}

      <>
        <textarea
          id={id}
          data-testid={`form-input-${id}`}
          {...props}
          {...setValue}
          {...register(name, {
            required,
            pattern,
            minLength,
            maxLength,
          })}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
        {textError && hasError ? (
          <FieldError
            text={textError}
            data-cy={`error-${id}`}
            data-testid={`form-field-error-input-${id}`}
          />
        ) : null}
        {textInfo ? (
          <FieldInfo
            text={textInfo}
            data-cy={`info-${id}`}
            data-testid={`form-field-info-input-${id}`}
            padding={paddingErrorMessage}
          />
        ) : null}
      </>
    </StyledInput>
  );
};

export default TextArea;
