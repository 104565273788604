import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Amex = ({
  width = 65,
  height = 40,
  alt = "Amex",
  color = theme.colors.tertiary["800"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 53 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M3.26662 0H49C50.8033 0 52.2667 1.09069 52.2667 2.43585V25.5654C52.2667 26.9093 50.8033 28 49 28H3.26662C1.46178 28.0001 0 26.9093 0 25.5655V2.43585C0 1.09069 1.4634 0 3.26662 0Z"
        fill={color}
      />
      <path
        d="M9.14104 8.43201L4.1145 19.5556H10.132L10.878 17.782H12.5831L13.3291 19.5556H19.9527V18.2019L20.5429 19.5556H23.9691L24.5593 18.1733V19.5556H38.3344L40.0094 17.8281L41.5778 19.5556L48.653 19.5699L43.6106 14.0248L48.653 8.43201H41.6876L40.0571 10.1276L38.538 8.43201H23.5525L22.2656 11.3031L20.9486 8.43201H14.9437V9.73958L14.2757 8.43201C14.2757 8.43201 9.14104 8.43201 9.14104 8.43201ZM10.3054 10.0116H13.2386L16.5727 17.5545V10.0116H19.7859L22.3611 15.4198L24.7344 10.0116H27.9316V17.9935H25.9862L25.9703 11.7389L23.1341 17.9935H21.3939L18.5418 11.7389V17.9935H14.5397L13.781 16.204H9.68186L8.92471 17.9919H6.78042L10.3054 10.0116ZM29.7114 10.0116H37.6218L40.0412 12.625L42.5386 10.0116H44.958L41.2819 14.0233L44.958 17.9887H42.4288L40.0094 15.345L37.4993 17.9887H29.7114V10.0116ZM11.7323 11.3621L10.3818 14.5498H13.0812L11.7323 11.3621ZM31.6648 11.6642V13.1213H35.9803V14.7454H31.6648V16.3361H36.5052L38.7544 13.9931L36.6007 11.6628H31.6648V11.6642Z"
        fill={secondColor}
      />
    </svg>
  );
};
