import React from "react";

export interface ImageZoomActions {
  refresh: (
    node?:
      | Node
      | undefined
      | null
      | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  start: (
    node?:
      | Node
      | undefined
      | null
      | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  stop: (
    node?:
      | Node
      | undefined
      | null
      | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  update: (
    node?:
      | Node
      | undefined
      | null
      | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  prev: (
    node?: Node | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  next: (
    node?: Node | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  switchTo: (
    node?:
      | HTMLAnchorElement
      | Node
      | null
      | React.MutableRefObject<HTMLAnchorElement | null>,
    selector?: number
  ) => void;
  zoomIn: (
    node?: Node | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  zoomOut: (
    node?: Node | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  expand: (
    node?: Node | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
  close: (
    node?: Node | React.MutableRefObject<HTMLAnchorElement | null>
  ) => void;
}
