import ScriptFromNext from "next/script";

type ScriptProps = {
  id?: string;
  strategy: "afterInteractive" | "lazyOnload" | "beforeInteractive" | "worker";
  dangerouslySetInnerHTML?: {
    __html: string;
  };
  onLoad?: (e: unknown) => void;
  onReady?: () => void | null;
  onError?: (e: unknown) => void;
  children?: React.ReactNode;
};

const Script = (props: ScriptProps) => {
  return <ScriptFromNext {...props} />;
};

export { Script };
