import { buildTestIds, useCartContext } from "application";
import dynamic from "next/dynamic";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { ModalAlertProps, SkeletonProps } from "typing";
import { InputNumber } from "../Form/InputNumber";
import {
  getAlertMessage,
  getAvailableStock,
  getMaxQuantityLimitReason,
  getMaxQuantityProduct,
} from "./data";
import {
  BoxDescription,
  BoxQuantity,
  SelectQuantity,
  TextUni,
  TitleBoxQuantity,
} from "./styles";

const PopUpAlertConfirm = dynamic<ModalAlertProps>(() =>
  import("../PopUpAlertConfirm").then((module) => module.PopUpAlertConfirm)
);

const Skeleton = dynamic<SkeletonProps>(() =>
  import("../Skeleton").then((module) => module.Skeleton)
);

interface CountQuantityProductProps {
  descriptionPackagingUnitSingular?: string;
  descriptionPackagingUnitPlural?: string;
  factPackaged?: number;
  measuredUnity?: string;
  packingUnity?: string;
  packingQuantity?: number;
  setProductQuantity: (quantity: number) => void;
  resumeDescription?: boolean;
  quantityItemSelected?: number;
  minValue?: number;
  maximumStockPerBranch?: number;
  totalStockFC?: number;
  productListId?: number;
  limitQuantityItem?: number;
  loadingGiftProducts?: boolean;
  debounceTime?: number;
}

const validateValue = (value: number, max: number, min: number) => {
  if (!value) {
    return min;
  }
  if (value > max) {
    return max;
  }
  if (value < min) {
    return min;
  }
  if (!Number.isInteger(value / min)) {
    return +(Math.round(value / min) * min).toFixed(2);
  }
  return value;
};

const messageLimitByCpf = "unidade(s) por cliente.";

const CountQuantityProduct = ({
  setProductQuantity,
  descriptionPackagingUnitSingular = "",
  descriptionPackagingUnitPlural = "",
  factPackaged,
  measuredUnity,
  packingUnity,
  packingQuantity,
  productListId,
  resumeDescription = false,
  quantityItemSelected,
  minValue,
  totalStockFC = 1,
  maximumStockPerBranch = 1,
  limitQuantityItem,
  loadingGiftProducts,
  debounceTime = 500,
}: // rafael.vitor diminuir a complexidade desse componente
// eslint-disable-next-line sonarjs/cognitive-complexity
CountQuantityProductProps) => {
  const formattedQuantityItemSelected = quantityItemSelected ?? 1;

  const { messageError, setLimitItemsPerCpf } = useCartContext();
  const [quantityProductSelected, setQuantityProductSelected] =
    useState<number>(formattedQuantityItemSelected);
  const [isPopUpAlertConfirmOpen, setIsPopUpAlertConfirmOpen] =
    useState<boolean>(false);
  const [initialValue, setInitialValue] = useState(quantityProductSelected);

  const QUANTITY_CURRENT_FORMAT = factPackaged
    ? (quantityProductSelected / factPackaged).toFixed(0)
    : quantityProductSelected;

  const DESCRIPTION_PACKAGED =
    packingUnity === "m²" || packingUnity === "M2"
      ? QUANTITY_CURRENT_FORMAT === "1"
        ? "embalagem"
        : "embalagens"
      : quantityProductSelected > 1
      ? descriptionPackagingUnitPlural
      : descriptionPackagingUnitSingular;

  const QUANTITY_CURRENT_PACKAGE = factPackaged
    ? `${factPackaged} ${measuredUnity}`
    : `${packingQuantity} ${measuredUnity}`;

  const COUNT_TOTAL_ITEMS = factPackaged
    ? `${quantityProductSelected} ${measuredUnity}`
    : packingQuantity
    ? `${packingQuantity * quantityProductSelected} ${measuredUnity}`
    : 0;

  const maxQuantityLimitReason = useCallback(
    (
      availableStock: number,
      productsQuantity: number,
      productPackingQuantity?: number,
      productFactPackaged?: number
    ) => {
      return getMaxQuantityLimitReason(
        availableStock,
        productsQuantity,
        productPackingQuantity,
        productFactPackaged
      );
    },
    []
  );

  const handleActionCloseButton = useCallback(() => {
    setIsPopUpAlertConfirmOpen(false);
  }, []);

  useLayoutEffect(() => {
    const normalizedQuantityProductSelected =
      quantityProductSelected / (factPackaged || 1);

    const maxQuantityProduct = getMaxQuantityProduct(
      getAvailableStock(maximumStockPerBranch, totalStockFC),
      Number(QUANTITY_CURRENT_FORMAT),
      packingQuantity,
      factPackaged
    );

    if (
      !Number.isNaN(quantityProductSelected) &&
      Number(normalizedQuantityProductSelected) <= maxQuantityProduct
    ) {
      const newProductQuantity = validateValue(
        Number(normalizedQuantityProductSelected),
        limitQuantityItem ?? maxQuantityProduct,
        minValue ?? 1
      );

      if (quantityProductSelected !== initialValue) {
        setProductQuantity(newProductQuantity);
      }
    }

    if (maxQuantityProduct) {
      setLimitItemsPerCpf(maxQuantityProduct);
    }
  }, [
    quantityProductSelected,
    factPackaged,
    limitQuantityItem,
    minValue,
    initialValue,
    setProductQuantity,
    setLimitItemsPerCpf,
    maximumStockPerBranch,
    totalStockFC,
    QUANTITY_CURRENT_FORMAT,
    packingQuantity,
  ]);

  const resumeDescriptionText = (value: boolean) => {
    const descriptionSingular =
      descriptionPackagingUnitSingular ||
      (packingUnity === "m²" && "Metro Quadrado");

    const descriptionPlural =
      descriptionPackagingUnitPlural ||
      (packingUnity === "m²" && "Metros Quadrados");

    return value ? descriptionPlural : descriptionSingular;
  };

  const maxQuantityLimitReasonMemoized = useMemo(() => {
    return maxQuantityLimitReason(
      getAvailableStock(maximumStockPerBranch, totalStockFC),
      Number(QUANTITY_CURRENT_FORMAT),
      packingQuantity,
      factPackaged
    );
  }, [
    maxQuantityLimitReason,
    maximumStockPerBranch,
    totalStockFC,
    QUANTITY_CURRENT_FORMAT,
    packingQuantity,
    factPackaged,
  ]);

  const maxQuantityProduct = useMemo(() => {
    return getMaxQuantityProduct(
      getAvailableStock(maximumStockPerBranch, totalStockFC),
      Number(QUANTITY_CURRENT_FORMAT),
      packingQuantity,
      factPackaged
    );
  }, [
    QUANTITY_CURRENT_FORMAT,
    factPackaged,
    maximumStockPerBranch,
    packingQuantity,
    totalStockFC,
  ]);

  return (
    <>
      <BoxQuantity {...buildTestIds("count-quantity-product-box-quantity")}>
        {!resumeDescription && <TitleBoxQuantity>Quantidade</TitleBoxQuantity>}
        <SelectQuantity
          {...buildTestIds("count-quantity-product-select-quantity")}
        >
          <InputNumber
            setCountState={(newValue) => setQuantityProductSelected(newValue)}
            isInputClicked={() => {
              setInitialValue(quantityProductSelected);
            }}
            {...buildTestIds("count-quantity-product-input-number")}
            productListId={productListId}
            min={
              minValue ?? (factPackaged && factPackaged > 1) ? factPackaged : 1
            }
            max={limitQuantityItem ?? maxQuantityProduct}
            callbackMaxQuantity={() => {
              setIsPopUpAlertConfirmOpen(true);
            }}
            productQuantity={Number(QUANTITY_CURRENT_FORMAT)}
            valueCount={quantityProductSelected}
            debounceTime={debounceTime}
          />
          {resumeDescription ? (
            <TextUni
              {...buildTestIds("count-quantity-product-text-quantity-uni")}
              capitalize={resumeDescription}
            >
              {resumeDescriptionText(quantityProductSelected > 1)}
            </TextUni>
          ) : !factPackaged &&
            packingQuantity === 1 &&
            !Number.isNaN(quantityProductSelected) ? (
            <TextUni
              {...buildTestIds("count-quantity-product-text-quantity-uni")}
            >
              {quantityProductSelected}{" "}
              {quantityProductSelected > 1
                ? descriptionPackagingUnitPlural
                : descriptionPackagingUnitSingular}
            </TextUni>
          ) : !Number.isNaN(quantityProductSelected) &&
            QUANTITY_CURRENT_FORMAT &&
            DESCRIPTION_PACKAGED &&
            QUANTITY_CURRENT_PACKAGE ? (
            <BoxDescription
              {...buildTestIds("count-quantity-product-box-description")}
            >
              <>
                <TextUni {...buildTestIds("count-quantity-product-text-uni")}>
                  {`${QUANTITY_CURRENT_FORMAT} ${DESCRIPTION_PACKAGED} de ${QUANTITY_CURRENT_PACKAGE}`}
                </TextUni>
                <TextUni
                  {...buildTestIds("count-quantity-product-text-uni")}
                >{`( total de ${COUNT_TOTAL_ITEMS} )`}</TextUni>
              </>
            </BoxDescription>
          ) : (
            <Skeleton count={1} width={100} height={20} />
          )}
        </SelectQuantity>
      </BoxQuantity>
      {(limitQuantityItem || maxQuantityProduct) &&
      !messageError?.includes(messageLimitByCpf) &&
      isPopUpAlertConfirmOpen &&
      maxQuantityLimitReasonMemoized === "cpf" &&
      (!productListId || (productListId && !loadingGiftProducts)) ? (
        <PopUpAlertConfirm
          {...buildTestIds("count-quantity-product-pop-up-alert-confirm")}
          textHeader="Limite excedido"
          descriptionBody={getAlertMessage(
            getMaxQuantityProduct(
              getAvailableStock(maximumStockPerBranch, totalStockFC),
              Number(QUANTITY_CURRENT_FORMAT),
              packingQuantity,
              factPackaged
            ),
            DESCRIPTION_PACKAGED?.toLowerCase()
          )}
          isOpen
          actionCloseButton={handleActionCloseButton}
        />
      ) : null}

      {isPopUpAlertConfirmOpen &&
      maxQuantityLimitReasonMemoized === "stock" &&
      !messageError?.includes(messageLimitByCpf) &&
      (!productListId || (productListId && !loadingGiftProducts)) ? (
        <PopUpAlertConfirm
          {...buildTestIds("count-quantity-product-pop-up-alert-confirm")}
          textHeader="Limite atingido"
          descriptionBody="Não é possível adicionar mais unidades desse produto devido ao estoque disponível."
          isOpen
          actionCloseButton={handleActionCloseButton}
        />
      ) : null}
    </>
  );
};

export { CountQuantityProduct };
