import { IResponsiveModalContent } from "typing";
import { buildTestIds } from "application";
import { ResponsiveModalContentContainer } from "./styles";
import {
  ResponsiveModalImage,
  ResponsiveModalInformation,
  ResponsiveModalButtons,
  ResponsiveModalInput,
} from "./components";
import { InputProps } from "../../../Form/Input";

const ResponsiveModalContentUI = ({
  description,
  image,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonLabel,
  secondaryButtonLabel,
  title,
  primaryButtonIsDisabled,
  hasInput,
  margin,
  ...props
}: IResponsiveModalContent & InputProps) => {
  return (
    <ResponsiveModalContentContainer
      {...buildTestIds("responsive-modal-content-container")}
    >
      <ResponsiveModalImage margin={margin} image={image} />
      <ResponsiveModalInformation description={description} title={title} />
      {hasInput ? (
        <ResponsiveModalInput
          {...props}
          {...buildTestIds("responsive-modal-input")}
        />
      ) : null}
      <ResponsiveModalButtons
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={onSecondaryButtonClick}
        primaryButtonLabel={primaryButtonLabel}
        secondaryButtonLabel={secondaryButtonLabel}
        primaryButtonIsDisabled={primaryButtonIsDisabled}
      />
    </ResponsiveModalContentContainer>
  );
};

export { ResponsiveModalContentUI };
