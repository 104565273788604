import styled from "styled-components";
import { CheckmarkCircle, theme } from "ui";

interface TextNoticeConfirmationEmailProps {
  type?: "success";
}
const textNoticeConfirmationEmailColors = {
  success: theme.colors.secondary["380"],
};

export const NoticeConfirmationEmailContainer = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.65rem;
`;

export const CheckmarkCircleNoticeConfirmationEmailWrapper = styled.div`
  width: 2rem;
  height: 2rem;
`;

export const CheckmarkCircleNoticeConfirmationEmail = styled(CheckmarkCircle)``;

export const TextNoticeConfirmationEmail = styled.span<TextNoticeConfirmationEmailProps>`
  color: ${({ type }) => textNoticeConfirmationEmailColors[type as "success"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight["400"]};
`;
