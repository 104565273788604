import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const UnsatisfiedFaceIcon = ({
  alt = "Ícone de Avaliação - Insatisfeito",
  width = 40,
  height = 40,
  color = theme.colors.primary["77"],
  secondColor = theme.colors.primary["350"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle
        cx="20"
        cy="20"
        r="19"
        fill={color}
        stroke={secondColor}
        strokeWidth="2"
      />
      <path
        d="M26.3252 28.4187C26.3252 28.4187 23.7941 25.8027 19.763 25.7847C16.7397 25.7713 13.5489 28.5479 13.5489 28.5479"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.49988 15.8642L11.6668 14.0358C12.6234 13.4835 13.8466 13.8112 14.3989 14.7678L16.2273 17.9347"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.5363 17.934L24.3647 14.7671C24.917 13.8105 26.1401 13.4828 27.0967 14.035L30.2637 15.8635"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
