import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ToastMessageProps, ToastProviderProps } from "typing";
import { ToastContainer } from "./ToastContainer";

export interface ToastContextProps {
  addToast({
    title,
    type,
    timerInMilliseconds,
    description,
    isNewToast,
    newToastTheme,
    showProgressBar,
  }: ToastMessageProps): void;
  removeToast(id: string): void;
}

const ToastContext = createContext({} as ToastContextProps);

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [triggerToast, setTriggerToast] = useState<ToastMessageProps[]>([]);

  const addToast = useCallback(
    ({
      title,
      type,
      timerInMilliseconds,
      showProgressBar,
      description,
      isNewToast,
      newToastTheme,
    }: ToastMessageProps) => {
      const id = String(Math.floor(Math.random() * 1000));

      const progressTimeInMilliseconds =
        title?.length > 50 ? 12000 : timerInMilliseconds || 3500;

      const toast = {
        id,
        title,
        type,
        description,
        isNewToast,
        timerInMilliseconds: progressTimeInMilliseconds,
        showProgressBar,
        newToastTheme,
      };

      setTriggerToast((oldToasts) => [...oldToasts, toast]);
    },
    []
  );

  const removeToast = useCallback((id: string) => {
    setTriggerToast((oldToast) => oldToast.filter((toast) => toast.id !== id));
  }, []);

  const providerMemo = useMemo(() => {
    return {
      addToast,
      removeToast,
    };
  }, [addToast, removeToast]);

  return (
    <ToastContext.Provider value={providerMemo}>
      {children}
      <ToastContainer toastProps={triggerToast} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within an ToastProvider");
  }

  return context;
};
