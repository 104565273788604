import { IconProps } from "typing";
import { theme } from "../themes";

export const MessagesIcon = ({
  height = 33,
  width = 32,
  color = theme.colors.secondary["380"],
  alt = "Ícone de mensagem",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4658 22.3623C27.4392 20.629 28.0005 18.6316 28.0005 16.501C28.0005 9.87298 22.6285 4.50098 16.0005 4.50098C9.37249 4.50098 4.00049 9.87298 4.00049 16.501C4.00049 23.129 9.37249 28.501 16.0005 28.501C18.1312 28.501 20.1285 27.9396 21.8618 26.9663L28.0005 28.501L26.4658 22.3623Z"
        stroke={color}
        strokeWidth="1.5882"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7646 16.9316C15.8953 16.801 16.106 16.801 16.2366 16.9316C16.3673 17.0623 16.3673 17.273 16.2366 17.4036C16.106 17.5343 15.8953 17.5343 15.7646 17.4036C15.6353 17.273 15.6353 17.0623 15.7646 16.9316"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4311 16.9316C10.5618 16.801 10.7725 16.801 10.9031 16.9316C11.0338 17.0623 11.0338 17.273 10.9031 17.4036C10.7725 17.5343 10.5618 17.5343 10.4311 17.4036C10.3018 17.273 10.3018 17.0623 10.4311 16.9316"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0976 16.9316C21.2283 16.801 21.439 16.801 21.5696 16.9316C21.7003 17.0623 21.7003 17.273 21.5696 17.4036C21.439 17.5343 21.2283 17.5343 21.0976 17.4036C20.9683 17.273 20.9683 17.0623 21.0976 16.9316"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
