import { MultipleColorsIconProps } from "typing";

export const UniqueClientMobileImage = ({
  width = 47,
  height = 93,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        borderRadius: "8px 0 0 8px",
        height: "100%",
        width: "100%",
        maxHeight: "93px",
        maxWidth: "47px",
      }}
    >
      <path
        d="M0.993642 2.20292C1.54242 2.20292 1.98728 1.70978 1.98728 1.10146C1.98728 0.493141 1.54242 0 0.993642 0C0.444869 0 0 0.493141 0 1.10146C0 1.70978 0.444869 2.20292 0.993642 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 2.20292C5.55853 2.20292 6.0034 1.70978 6.0034 1.10146C6.0034 0.493141 5.55853 0 5.00976 0C4.46098 0 4.01611 0.493141 4.01611 1.10146C4.01611 1.70978 4.46098 2.20292 5.00976 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 2.20292C9.57428 2.20292 10.0191 1.70978 10.0191 1.10146C10.0191 0.493141 9.57428 0 9.0255 0C8.47673 0 8.03186 0.493141 8.03186 1.10146C8.03186 1.70978 8.47673 2.20292 9.0255 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 2.20292C13.5904 2.20292 14.0353 1.70978 14.0353 1.10146C14.0353 0.493141 13.5904 0 13.0416 0C12.4928 0 12.048 0.493141 12.048 1.10146C12.048 1.70978 12.4928 2.20292 13.0416 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 2.20292C17.6011 2.20292 18.046 1.70978 18.046 1.10146C18.046 0.493141 17.6011 0 17.0524 0C16.5036 0 16.0587 0.493141 16.0587 1.10146C16.0587 1.70978 16.5036 2.20292 17.0524 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 2.20292C21.6172 2.20292 22.0621 1.70978 22.0621 1.10146C22.0621 0.493141 21.6172 0 21.0685 0C20.5197 0 20.0748 0.493141 20.0748 1.10146C20.0748 1.70978 20.5197 2.20292 21.0685 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 2.20292C25.633 2.20292 26.0779 1.70978 26.0779 1.10146C26.0779 0.493141 25.633 0 25.0842 0C24.5354 0 24.0906 0.493141 24.0906 1.10146C24.0906 1.70978 24.5354 2.20292 25.0842 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1003 2.20292C29.6491 2.20292 30.094 1.70978 30.094 1.10146C30.094 0.493141 29.6491 0 29.1003 0C28.5516 0 28.1067 0.493141 28.1067 1.10146C28.1067 1.70978 28.5516 2.20292 29.1003 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 2.20292C33.6651 2.20292 34.11 1.70978 34.11 1.10146C34.11 0.493141 33.6651 0 33.1163 0C32.5676 0 32.1227 0.493141 32.1227 1.10146C32.1227 1.70978 32.5676 2.20292 33.1163 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 2.20292C37.681 2.20292 38.1258 1.70978 38.1258 1.10146C38.1258 0.493141 37.681 0 37.1322 0C36.5834 0 36.1385 0.493141 36.1385 1.10146C36.1385 1.70978 36.5834 2.20292 37.1322 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 2.20292C41.697 2.20292 42.1418 1.70978 42.1418 1.10146C42.1418 0.493141 41.697 0 41.1482 0C40.5994 0 40.1545 0.493141 40.1545 1.10146C40.1545 1.70978 40.5994 2.20292 41.1482 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 2.20292C45.7128 2.20292 46.1577 1.70978 46.1577 1.10146C46.1577 0.493141 45.7128 0 45.1641 0C44.6153 0 44.1704 0.493141 44.1704 1.10146C44.1704 1.70978 44.6153 2.20292 45.1641 2.20292Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 6.46544C1.54242 6.46544 1.98728 5.9723 1.98728 5.36398C1.98728 4.75566 1.54242 4.26251 0.993642 4.26251C0.444869 4.26251 0 4.75566 0 5.36398C0 5.9723 0.444869 6.46544 0.993642 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 6.46544C5.55853 6.46544 6.0034 5.9723 6.0034 5.36398C6.0034 4.75566 5.55853 4.26252 5.00976 4.26252C4.46098 4.26252 4.01611 4.75566 4.01611 5.36398C4.01611 5.9723 4.46098 6.46544 5.00976 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 6.46544C9.57428 6.46544 10.0191 5.9723 10.0191 5.36398C10.0191 4.75566 9.57428 4.26252 9.0255 4.26252C8.47673 4.26252 8.03186 4.75566 8.03186 5.36398C8.03186 5.9723 8.47673 6.46544 9.0255 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 6.46544C13.5904 6.46544 14.0353 5.9723 14.0353 5.36398C14.0353 4.75566 13.5904 4.26252 13.0416 4.26252C12.4928 4.26252 12.048 4.75566 12.048 5.36398C12.048 5.9723 12.4928 6.46544 13.0416 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 6.46544C17.6011 6.46544 18.046 5.9723 18.046 5.36398C18.046 4.75566 17.6011 4.26252 17.0524 4.26252C16.5036 4.26252 16.0587 4.75566 16.0587 5.36398C16.0587 5.9723 16.5036 6.46544 17.0524 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 6.46544C21.6172 6.46544 22.0621 5.9723 22.0621 5.36398C22.0621 4.75566 21.6172 4.26252 21.0685 4.26252C20.5197 4.26252 20.0748 4.75566 20.0748 5.36398C20.0748 5.9723 20.5197 6.46544 21.0685 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 6.46544C25.633 6.46544 26.0779 5.9723 26.0779 5.36398C26.0779 4.75566 25.633 4.26252 25.0842 4.26252C24.5354 4.26252 24.0906 4.75566 24.0906 5.36398C24.0906 5.9723 24.5354 6.46544 25.0842 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 6.46544C29.649 6.46544 30.0939 5.9723 30.0939 5.36398C30.0939 4.75566 29.649 4.26252 29.1002 4.26252C28.5514 4.26252 28.1066 4.75566 28.1066 5.36398C28.1066 5.9723 28.5514 6.46544 29.1002 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 6.46544C33.6651 6.46544 34.11 5.9723 34.11 5.36398C34.11 4.75566 33.6651 4.26252 33.1163 4.26252C32.5676 4.26252 32.1227 4.75566 32.1227 5.36398C32.1227 5.9723 32.5676 6.46544 33.1163 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 6.46544C37.681 6.46544 38.1258 5.9723 38.1258 5.36398C38.1258 4.75566 37.681 4.26252 37.1322 4.26252C36.5834 4.26252 36.1385 4.75566 36.1385 5.36398C36.1385 5.9723 36.5834 6.46544 37.1322 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 6.46544C41.697 6.46544 42.1418 5.9723 42.1418 5.36398C42.1418 4.75566 41.697 4.26252 41.1482 4.26252C40.5994 4.26252 40.1545 4.75566 40.1545 5.36398C40.1545 5.9723 40.5994 6.46544 41.1482 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 6.46544C45.7128 6.46544 46.1577 5.9723 46.1577 5.36398C46.1577 4.75566 45.7128 4.26252 45.1641 4.26252C44.6153 4.26252 44.1704 4.75566 44.1704 5.36398C44.1704 5.9723 44.6153 6.46544 45.1641 6.46544Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 10.7282C1.54242 10.7282 1.98728 10.2351 1.98728 9.62674C1.98728 9.01842 1.54242 8.52528 0.993642 8.52528C0.444869 8.52528 0 9.01842 0 9.62674C0 10.2351 0.444869 10.7282 0.993642 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 10.7282C5.55853 10.7282 6.0034 10.2351 6.0034 9.62674C6.0034 9.01842 5.55853 8.52528 5.00976 8.52528C4.46098 8.52528 4.01611 9.01842 4.01611 9.62674C4.01611 10.2351 4.46098 10.7282 5.00976 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 10.7282C9.57428 10.7282 10.0191 10.2351 10.0191 9.62674C10.0191 9.01842 9.57428 8.52528 9.0255 8.52528C8.47673 8.52528 8.03186 9.01842 8.03186 9.62674C8.03186 10.2351 8.47673 10.7282 9.0255 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 10.7282C13.5904 10.7282 14.0353 10.2351 14.0353 9.62674C14.0353 9.01842 13.5904 8.52528 13.0416 8.52528C12.4928 8.52528 12.048 9.01842 12.048 9.62674C12.048 10.2351 12.4928 10.7282 13.0416 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 10.7282C17.6011 10.7282 18.046 10.2351 18.046 9.62674C18.046 9.01842 17.6011 8.52528 17.0524 8.52528C16.5036 8.52528 16.0587 9.01842 16.0587 9.62674C16.0587 10.2351 16.5036 10.7282 17.0524 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 10.7282C21.6172 10.7282 22.0621 10.2351 22.0621 9.62674C22.0621 9.01842 21.6172 8.52528 21.0685 8.52528C20.5197 8.52528 20.0748 9.01842 20.0748 9.62674C20.0748 10.2351 20.5197 10.7282 21.0685 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 10.7282C25.633 10.7282 26.0779 10.2351 26.0779 9.62674C26.0779 9.01842 25.633 8.52528 25.0842 8.52528C24.5354 8.52528 24.0906 9.01842 24.0906 9.62674C24.0906 10.2351 24.5354 10.7282 25.0842 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 10.7282C29.649 10.7282 30.0939 10.2351 30.0939 9.62674C30.0939 9.01842 29.649 8.52528 29.1002 8.52528C28.5514 8.52528 28.1066 9.01842 28.1066 9.62674C28.1066 10.2351 28.5514 10.7282 29.1002 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 10.7282C33.6651 10.7282 34.11 10.2351 34.11 9.62674C34.11 9.01842 33.6651 8.52528 33.1163 8.52528C32.5676 8.52528 32.1227 9.01842 32.1227 9.62674C32.1227 10.2351 32.5676 10.7282 33.1163 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 10.7282C37.681 10.7282 38.1258 10.2351 38.1258 9.62674C38.1258 9.01842 37.681 8.52528 37.1322 8.52528C36.5834 8.52528 36.1385 9.01842 36.1385 9.62674C36.1385 10.2351 36.5834 10.7282 37.1322 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 10.7282C41.697 10.7282 42.1418 10.2351 42.1418 9.62674C42.1418 9.01842 41.697 8.52528 41.1482 8.52528C40.5994 8.52528 40.1545 9.01842 40.1545 9.62674C40.1545 10.2351 40.5994 10.7282 41.1482 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 10.7282C45.7128 10.7282 46.1577 10.2351 46.1577 9.62674C46.1577 9.01842 45.7128 8.52528 45.1641 8.52528C44.6153 8.52528 44.1704 9.01842 44.1704 9.62674C44.1704 10.2351 44.6153 10.7282 45.1641 10.7282Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 14.9901C1.54242 14.9901 1.98728 14.497 1.98728 13.8887C1.98728 13.2803 1.54242 12.7872 0.993642 12.7872C0.444869 12.7872 0 13.2803 0 13.8887C0 14.497 0.444869 14.9901 0.993642 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 14.9901C5.55853 14.9901 6.0034 14.497 6.0034 13.8887C6.0034 13.2803 5.55853 12.7872 5.00976 12.7872C4.46098 12.7872 4.01611 13.2803 4.01611 13.8887C4.01611 14.497 4.46098 14.9901 5.00976 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 14.9901C9.57428 14.9901 10.0191 14.497 10.0191 13.8887C10.0191 13.2803 9.57428 12.7872 9.0255 12.7872C8.47673 12.7872 8.03186 13.2803 8.03186 13.8887C8.03186 14.497 8.47673 14.9901 9.0255 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 14.9901C13.5904 14.9901 14.0353 14.497 14.0353 13.8887C14.0353 13.2803 13.5904 12.7872 13.0416 12.7872C12.4928 12.7872 12.048 13.2803 12.048 13.8887C12.048 14.497 12.4928 14.9901 13.0416 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 14.9901C17.6011 14.9901 18.046 14.497 18.046 13.8887C18.046 13.2803 17.6011 12.7872 17.0524 12.7872C16.5036 12.7872 16.0587 13.2803 16.0587 13.8887C16.0587 14.497 16.5036 14.9901 17.0524 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 14.9901C21.6172 14.9901 22.0621 14.497 22.0621 13.8887C22.0621 13.2803 21.6172 12.7872 21.0685 12.7872C20.5197 12.7872 20.0748 13.2803 20.0748 13.8887C20.0748 14.497 20.5197 14.9901 21.0685 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 14.9901C25.633 14.9901 26.0779 14.497 26.0779 13.8887C26.0779 13.2803 25.633 12.7872 25.0842 12.7872C24.5354 12.7872 24.0906 13.2803 24.0906 13.8887C24.0906 14.497 24.5354 14.9901 25.0842 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 14.9901C29.649 14.9901 30.0939 14.497 30.0939 13.8887C30.0939 13.2803 29.649 12.7872 29.1002 12.7872C28.5514 12.7872 28.1066 13.2803 28.1066 13.8887C28.1066 14.497 28.5514 14.9901 29.1002 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 14.9901C33.6651 14.9901 34.11 14.497 34.11 13.8887C34.11 13.2803 33.6651 12.7872 33.1163 12.7872C32.5676 12.7872 32.1227 13.2803 32.1227 13.8887C32.1227 14.497 32.5676 14.9901 33.1163 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 14.9901C37.681 14.9901 38.1258 14.497 38.1258 13.8887C38.1258 13.2803 37.681 12.7872 37.1322 12.7872C36.5834 12.7872 36.1385 13.2803 36.1385 13.8887C36.1385 14.497 36.5834 14.9901 37.1322 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 14.9901C41.697 14.9901 42.1418 14.497 42.1418 13.8887C42.1418 13.2803 41.697 12.7872 41.1482 12.7872C40.5994 12.7872 40.1545 13.2803 40.1545 13.8887C40.1545 14.497 40.5994 14.9901 41.1482 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 14.9901C45.7128 14.9901 46.1577 14.497 46.1577 13.8887C46.1577 13.2803 45.7128 12.7872 45.1641 12.7872C44.6153 12.7872 44.1704 13.2803 44.1704 13.8887C44.1704 14.497 44.6153 14.9901 45.1641 14.9901Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 19.2528C1.54242 19.2528 1.98728 18.7596 1.98728 18.1513C1.98728 17.543 1.54242 17.0498 0.993642 17.0498C0.444869 17.0498 0 17.543 0 18.1513C0 18.7596 0.444869 19.2528 0.993642 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 19.2528C5.55853 19.2528 6.0034 18.7596 6.0034 18.1513C6.0034 17.543 5.55853 17.0498 5.00976 17.0498C4.46098 17.0498 4.01611 17.543 4.01611 18.1513C4.01611 18.7596 4.46098 19.2528 5.00976 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 19.2528C9.57428 19.2528 10.0191 18.7596 10.0191 18.1513C10.0191 17.543 9.57428 17.0498 9.0255 17.0498C8.47673 17.0498 8.03186 17.543 8.03186 18.1513C8.03186 18.7596 8.47673 19.2528 9.0255 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 19.2528C13.5904 19.2528 14.0353 18.7596 14.0353 18.1513C14.0353 17.543 13.5904 17.0498 13.0416 17.0498C12.4928 17.0498 12.048 17.543 12.048 18.1513C12.048 18.7596 12.4928 19.2528 13.0416 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 19.2528C17.6011 19.2528 18.046 18.7596 18.046 18.1513C18.046 17.543 17.6011 17.0498 17.0524 17.0498C16.5036 17.0498 16.0587 17.543 16.0587 18.1513C16.0587 18.7596 16.5036 19.2528 17.0524 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 19.2528C21.6172 19.2528 22.0621 18.7596 22.0621 18.1513C22.0621 17.543 21.6172 17.0498 21.0685 17.0498C20.5197 17.0498 20.0748 17.543 20.0748 18.1513C20.0748 18.7596 20.5197 19.2528 21.0685 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 19.2528C25.633 19.2528 26.0779 18.7596 26.0779 18.1513C26.0779 17.543 25.633 17.0498 25.0842 17.0498C24.5354 17.0498 24.0906 17.543 24.0906 18.1513C24.0906 18.7596 24.5354 19.2528 25.0842 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 19.2528C29.649 19.2528 30.0939 18.7596 30.0939 18.1513C30.0939 17.543 29.649 17.0498 29.1002 17.0498C28.5514 17.0498 28.1066 17.543 28.1066 18.1513C28.1066 18.7596 28.5514 19.2528 29.1002 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 19.2528C33.6651 19.2528 34.11 18.7596 34.11 18.1513C34.11 17.543 33.6651 17.0498 33.1163 17.0498C32.5676 17.0498 32.1227 17.543 32.1227 18.1513C32.1227 18.7596 32.5676 19.2528 33.1163 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 19.2528C37.681 19.2528 38.1258 18.7596 38.1258 18.1513C38.1258 17.543 37.681 17.0498 37.1322 17.0498C36.5834 17.0498 36.1385 17.543 36.1385 18.1513C36.1385 18.7596 36.5834 19.2528 37.1322 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 19.2528C41.697 19.2528 42.1418 18.7596 42.1418 18.1513C42.1418 17.543 41.697 17.0498 41.1482 17.0498C40.5994 17.0498 40.1545 17.543 40.1545 18.1513C40.1545 18.7596 40.5994 19.2528 41.1482 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 19.2528C45.7128 19.2528 46.1577 18.7596 46.1577 18.1513C46.1577 17.543 45.7128 17.0498 45.1641 17.0498C44.6153 17.0498 44.1704 17.543 44.1704 18.1513C44.1704 18.7596 44.6153 19.2528 45.1641 19.2528Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 23.5147C1.54242 23.5147 1.98728 23.0215 1.98728 22.4132C1.98728 21.8049 1.54242 21.3118 0.993642 21.3118C0.444869 21.3118 0 21.8049 0 22.4132C0 23.0215 0.444869 23.5147 0.993642 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 23.5147C5.55853 23.5147 6.0034 23.0215 6.0034 22.4132C6.0034 21.8049 5.55853 21.3118 5.00976 21.3118C4.46098 21.3118 4.01611 21.8049 4.01611 22.4132C4.01611 23.0215 4.46098 23.5147 5.00976 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 23.5147C9.57428 23.5147 10.0191 23.0215 10.0191 22.4132C10.0191 21.8049 9.57428 21.3118 9.0255 21.3118C8.47673 21.3118 8.03186 21.8049 8.03186 22.4132C8.03186 23.0215 8.47673 23.5147 9.0255 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 23.5147C13.5904 23.5147 14.0353 23.0215 14.0353 22.4132C14.0353 21.8049 13.5904 21.3118 13.0416 21.3118C12.4928 21.3118 12.048 21.8049 12.048 22.4132C12.048 23.0215 12.4928 23.5147 13.0416 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 23.5147C17.6011 23.5147 18.046 23.0215 18.046 22.4132C18.046 21.8049 17.6011 21.3118 17.0524 21.3118C16.5036 21.3118 16.0587 21.8049 16.0587 22.4132C16.0587 23.0215 16.5036 23.5147 17.0524 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 23.5147C21.6172 23.5147 22.0621 23.0215 22.0621 22.4132C22.0621 21.8049 21.6172 21.3118 21.0685 21.3118C20.5197 21.3118 20.0748 21.8049 20.0748 22.4132C20.0748 23.0215 20.5197 23.5147 21.0685 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 23.5147C25.633 23.5147 26.0779 23.0215 26.0779 22.4132C26.0779 21.8049 25.633 21.3118 25.0842 21.3118C24.5354 21.3118 24.0906 21.8049 24.0906 22.4132C24.0906 23.0215 24.5354 23.5147 25.0842 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 23.5147C29.649 23.5147 30.0939 23.0215 30.0939 22.4132C30.0939 21.8049 29.649 21.3118 29.1002 21.3118C28.5514 21.3118 28.1066 21.8049 28.1066 22.4132C28.1066 23.0215 28.5514 23.5147 29.1002 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 23.5147C33.6651 23.5147 34.11 23.0215 34.11 22.4132C34.11 21.8049 33.6651 21.3118 33.1163 21.3118C32.5676 21.3118 32.1227 21.8049 32.1227 22.4132C32.1227 23.0215 32.5676 23.5147 33.1163 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 23.5147C37.681 23.5147 38.1258 23.0215 38.1258 22.4132C38.1258 21.8049 37.681 21.3118 37.1322 21.3118C36.5834 21.3118 36.1385 21.8049 36.1385 22.4132C36.1385 23.0215 36.5834 23.5147 37.1322 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 23.5147C41.697 23.5147 42.1418 23.0215 42.1418 22.4132C42.1418 21.8049 41.697 21.3118 41.1482 21.3118C40.5994 21.3118 40.1545 21.8049 40.1545 22.4132C40.1545 23.0215 40.5994 23.5147 41.1482 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 23.5147C45.7128 23.5147 46.1577 23.0215 46.1577 22.4132C46.1577 21.8049 45.7128 21.3118 45.1641 21.3118C44.6153 21.3118 44.1704 21.8049 44.1704 22.4132C44.1704 23.0215 44.6153 23.5147 45.1641 23.5147Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 27.7772C1.54242 27.7772 1.98728 27.284 1.98728 26.6757C1.98728 26.0674 1.54242 25.5743 0.993642 25.5743C0.444869 25.5743 0 26.0674 0 26.6757C0 27.284 0.444869 27.7772 0.993642 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 27.7772C5.55853 27.7772 6.0034 27.2841 6.0034 26.6757C6.0034 26.0674 5.55853 25.5743 5.00976 25.5743C4.46098 25.5743 4.01611 26.0674 4.01611 26.6757C4.01611 27.2841 4.46098 27.7772 5.00976 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 27.7772C9.57428 27.7772 10.0191 27.2841 10.0191 26.6757C10.0191 26.0674 9.57428 25.5743 9.0255 25.5743C8.47673 25.5743 8.03186 26.0674 8.03186 26.6757C8.03186 27.2841 8.47673 27.7772 9.0255 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 27.7772C13.5904 27.7772 14.0353 27.2841 14.0353 26.6757C14.0353 26.0674 13.5904 25.5743 13.0416 25.5743C12.4928 25.5743 12.048 26.0674 12.048 26.6757C12.048 27.2841 12.4928 27.7772 13.0416 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 27.7772C17.6011 27.7772 18.046 27.2841 18.046 26.6757C18.046 26.0674 17.6011 25.5743 17.0524 25.5743C16.5036 25.5743 16.0587 26.0674 16.0587 26.6757C16.0587 27.2841 16.5036 27.7772 17.0524 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 27.7772C21.6172 27.7772 22.0621 27.2841 22.0621 26.6757C22.0621 26.0674 21.6172 25.5743 21.0685 25.5743C20.5197 25.5743 20.0748 26.0674 20.0748 26.6757C20.0748 27.2841 20.5197 27.7772 21.0685 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 27.7772C25.633 27.7772 26.0779 27.2841 26.0779 26.6757C26.0779 26.0674 25.633 25.5743 25.0842 25.5743C24.5354 25.5743 24.0906 26.0674 24.0906 26.6757C24.0906 27.2841 24.5354 27.7772 25.0842 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 27.7772C29.649 27.7772 30.0939 27.2841 30.0939 26.6757C30.0939 26.0674 29.649 25.5743 29.1002 25.5743C28.5514 25.5743 28.1066 26.0674 28.1066 26.6757C28.1066 27.2841 28.5514 27.7772 29.1002 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 27.7772C33.6651 27.7772 34.11 27.2841 34.11 26.6757C34.11 26.0674 33.6651 25.5743 33.1163 25.5743C32.5676 25.5743 32.1227 26.0674 32.1227 26.6757C32.1227 27.2841 32.5676 27.7772 33.1163 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 27.7772C37.681 27.7772 38.1258 27.2841 38.1258 26.6757C38.1258 26.0674 37.681 25.5743 37.1322 25.5743C36.5834 25.5743 36.1385 26.0674 36.1385 26.6757C36.1385 27.2841 36.5834 27.7772 37.1322 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 27.7772C41.697 27.7772 42.1418 27.2841 42.1418 26.6757C42.1418 26.0674 41.697 25.5743 41.1482 25.5743C40.5994 25.5743 40.1545 26.0674 40.1545 26.6757C40.1545 27.2841 40.5994 27.7772 41.1482 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 27.7772C45.7128 27.7772 46.1577 27.2841 46.1577 26.6757C46.1577 26.0674 45.7128 25.5743 45.1641 25.5743C44.6153 25.5743 44.1704 26.0674 44.1704 26.6757C44.1704 27.2841 44.6153 27.7772 45.1641 27.7772Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 32.0397C1.54242 32.0397 1.98728 31.5466 1.98728 30.9382C1.98728 30.3299 1.54242 29.8368 0.993642 29.8368C0.444869 29.8368 0 30.3299 0 30.9382C0 31.5466 0.444869 32.0397 0.993642 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 32.0397C5.55853 32.0397 6.0034 31.5466 6.0034 30.9383C6.0034 30.3299 5.55853 29.8368 5.00976 29.8368C4.46098 29.8368 4.01611 30.3299 4.01611 30.9383C4.01611 31.5466 4.46098 32.0397 5.00976 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 32.0397C9.57428 32.0397 10.0191 31.5466 10.0191 30.9383C10.0191 30.3299 9.57428 29.8368 9.0255 29.8368C8.47673 29.8368 8.03186 30.3299 8.03186 30.9383C8.03186 31.5466 8.47673 32.0397 9.0255 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 32.0397C13.5904 32.0397 14.0353 31.5466 14.0353 30.9383C14.0353 30.3299 13.5904 29.8368 13.0416 29.8368C12.4928 29.8368 12.048 30.3299 12.048 30.9383C12.048 31.5466 12.4928 32.0397 13.0416 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 32.0397C17.6011 32.0397 18.046 31.5466 18.046 30.9383C18.046 30.3299 17.6011 29.8368 17.0524 29.8368C16.5036 29.8368 16.0587 30.3299 16.0587 30.9383C16.0587 31.5466 16.5036 32.0397 17.0524 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 32.0397C21.6172 32.0397 22.0621 31.5466 22.0621 30.9383C22.0621 30.3299 21.6172 29.8368 21.0685 29.8368C20.5197 29.8368 20.0748 30.3299 20.0748 30.9383C20.0748 31.5466 20.5197 32.0397 21.0685 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 32.0397C25.633 32.0397 26.0779 31.5466 26.0779 30.9383C26.0779 30.3299 25.633 29.8368 25.0842 29.8368C24.5354 29.8368 24.0906 30.3299 24.0906 30.9383C24.0906 31.5466 24.5354 32.0397 25.0842 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 32.0397C29.649 32.0397 30.0939 31.5466 30.0939 30.9383C30.0939 30.3299 29.649 29.8368 29.1002 29.8368C28.5514 29.8368 28.1066 30.3299 28.1066 30.9383C28.1066 31.5466 28.5514 32.0397 29.1002 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 32.0397C33.6651 32.0397 34.11 31.5466 34.11 30.9383C34.11 30.3299 33.6651 29.8368 33.1163 29.8368C32.5676 29.8368 32.1227 30.3299 32.1227 30.9383C32.1227 31.5466 32.5676 32.0397 33.1163 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 32.0397C37.681 32.0397 38.1258 31.5466 38.1258 30.9383C38.1258 30.3299 37.681 29.8368 37.1322 29.8368C36.5834 29.8368 36.1385 30.3299 36.1385 30.9383C36.1385 31.5466 36.5834 32.0397 37.1322 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 32.0397C41.697 32.0397 42.1418 31.5466 42.1418 30.9383C42.1418 30.3299 41.697 29.8368 41.1482 29.8368C40.5994 29.8368 40.1545 30.3299 40.1545 30.9383C40.1545 31.5466 40.5994 32.0397 41.1482 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 32.0397C45.7128 32.0397 46.1577 31.5466 46.1577 30.9383C46.1577 30.3299 45.7128 29.8368 45.1641 29.8368C44.6153 29.8368 44.1704 30.3299 44.1704 30.9383C44.1704 31.5466 44.6153 32.0397 45.1641 32.0397Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 36.3024C1.54242 36.3024 1.98728 35.8093 1.98728 35.201C1.98728 34.5927 1.54242 34.0995 0.993642 34.0995C0.444869 34.0995 0 34.5927 0 35.201C0 35.8093 0.444869 36.3024 0.993642 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 36.3024C5.55853 36.3024 6.0034 35.8093 6.0034 35.201C6.0034 34.5927 5.55853 34.0995 5.00976 34.0995C4.46098 34.0995 4.01611 34.5927 4.01611 35.201C4.01611 35.8093 4.46098 36.3024 5.00976 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 36.3024C9.57428 36.3024 10.0191 35.8093 10.0191 35.201C10.0191 34.5927 9.57428 34.0995 9.0255 34.0995C8.47673 34.0995 8.03186 34.5927 8.03186 35.201C8.03186 35.8093 8.47673 36.3024 9.0255 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 36.3024C13.5904 36.3024 14.0353 35.8093 14.0353 35.201C14.0353 34.5927 13.5904 34.0995 13.0416 34.0995C12.4928 34.0995 12.048 34.5927 12.048 35.201C12.048 35.8093 12.4928 36.3024 13.0416 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 36.3024C17.6011 36.3024 18.046 35.8093 18.046 35.201C18.046 34.5927 17.6011 34.0995 17.0524 34.0995C16.5036 34.0995 16.0587 34.5927 16.0587 35.201C16.0587 35.8093 16.5036 36.3024 17.0524 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 36.3024C21.6172 36.3024 22.0621 35.8093 22.0621 35.201C22.0621 34.5927 21.6172 34.0995 21.0685 34.0995C20.5197 34.0995 20.0748 34.5927 20.0748 35.201C20.0748 35.8093 20.5197 36.3024 21.0685 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 36.3024C25.633 36.3024 26.0779 35.8093 26.0779 35.201C26.0779 34.5927 25.633 34.0995 25.0842 34.0995C24.5354 34.0995 24.0906 34.5927 24.0906 35.201C24.0906 35.8093 24.5354 36.3024 25.0842 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 36.3024C29.649 36.3024 30.0939 35.8093 30.0939 35.201C30.0939 34.5927 29.649 34.0995 29.1002 34.0995C28.5514 34.0995 28.1066 34.5927 28.1066 35.201C28.1066 35.8093 28.5514 36.3024 29.1002 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 36.3024C33.6651 36.3024 34.11 35.8093 34.11 35.201C34.11 34.5927 33.6651 34.0995 33.1163 34.0995C32.5676 34.0995 32.1227 34.5927 32.1227 35.201C32.1227 35.8093 32.5676 36.3024 33.1163 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 36.3024C37.681 36.3024 38.1258 35.8093 38.1258 35.201C38.1258 34.5927 37.681 34.0995 37.1322 34.0995C36.5834 34.0995 36.1385 34.5927 36.1385 35.201C36.1385 35.8093 36.5834 36.3024 37.1322 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 36.3024C41.697 36.3024 42.1418 35.8093 42.1418 35.201C42.1418 34.5927 41.697 34.0995 41.1482 34.0995C40.5994 34.0995 40.1545 34.5927 40.1545 35.201C40.1545 35.8093 40.5994 36.3024 41.1482 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 36.3024C45.7128 36.3024 46.1577 35.8093 46.1577 35.201C46.1577 34.5927 45.7128 34.0995 45.1641 34.0995C44.6153 34.0995 44.1704 34.5927 44.1704 35.201C44.1704 35.8093 44.6153 36.3024 45.1641 36.3024Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 40.5651C1.54242 40.5651 1.98728 40.0719 1.98728 39.4636C1.98728 38.8553 1.54242 38.3621 0.993642 38.3621C0.444869 38.3621 0 38.8553 0 39.4636C0 40.0719 0.444869 40.5651 0.993642 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 40.5651C5.55853 40.5651 6.0034 40.0719 6.0034 39.4636C6.0034 38.8553 5.55853 38.3622 5.00976 38.3622C4.46098 38.3622 4.01611 38.8553 4.01611 39.4636C4.01611 40.0719 4.46098 40.5651 5.00976 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 40.5651C9.57428 40.5651 10.0191 40.0719 10.0191 39.4636C10.0191 38.8553 9.57428 38.3622 9.0255 38.3622C8.47673 38.3622 8.03186 38.8553 8.03186 39.4636C8.03186 40.0719 8.47673 40.5651 9.0255 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 40.5651C13.5904 40.5651 14.0353 40.0719 14.0353 39.4636C14.0353 38.8553 13.5904 38.3622 13.0416 38.3622C12.4928 38.3622 12.048 38.8553 12.048 39.4636C12.048 40.0719 12.4928 40.5651 13.0416 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 40.5651C17.6011 40.5651 18.046 40.0719 18.046 39.4636C18.046 38.8553 17.6011 38.3622 17.0524 38.3622C16.5036 38.3622 16.0587 38.8553 16.0587 39.4636C16.0587 40.0719 16.5036 40.5651 17.0524 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 40.5651C21.6172 40.5651 22.0621 40.0719 22.0621 39.4636C22.0621 38.8553 21.6172 38.3622 21.0685 38.3622C20.5197 38.3622 20.0748 38.8553 20.0748 39.4636C20.0748 40.0719 20.5197 40.5651 21.0685 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 40.5651C25.633 40.5651 26.0779 40.0719 26.0779 39.4636C26.0779 38.8553 25.633 38.3622 25.0842 38.3622C24.5354 38.3622 24.0906 38.8553 24.0906 39.4636C24.0906 40.0719 24.5354 40.5651 25.0842 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 40.5651C29.649 40.5651 30.0939 40.0719 30.0939 39.4636C30.0939 38.8553 29.649 38.3622 29.1002 38.3622C28.5514 38.3622 28.1066 38.8553 28.1066 39.4636C28.1066 40.0719 28.5514 40.5651 29.1002 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 40.5651C33.6651 40.5651 34.11 40.0719 34.11 39.4636C34.11 38.8553 33.6651 38.3622 33.1163 38.3622C32.5676 38.3622 32.1227 38.8553 32.1227 39.4636C32.1227 40.0719 32.5676 40.5651 33.1163 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 40.5651C37.681 40.5651 38.1258 40.0719 38.1258 39.4636C38.1258 38.8553 37.681 38.3622 37.1322 38.3622C36.5834 38.3622 36.1385 38.8553 36.1385 39.4636C36.1385 40.0719 36.5834 40.5651 37.1322 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 40.5651C41.697 40.5651 42.1418 40.0719 42.1418 39.4636C42.1418 38.8553 41.697 38.3622 41.1482 38.3622C40.5994 38.3622 40.1545 38.8553 40.1545 39.4636C40.1545 40.0719 40.5994 40.5651 41.1482 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 40.5651C45.7128 40.5651 46.1577 40.0719 46.1577 39.4636C46.1577 38.8553 45.7128 38.3622 45.1641 38.3622C44.6153 38.3622 44.1704 38.8553 44.1704 39.4636C44.1704 40.0719 44.6153 40.5651 45.1641 40.5651Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 44.827C1.54242 44.827 1.98728 44.3339 1.98728 43.7255C1.98728 43.1172 1.54242 42.6241 0.993642 42.6241C0.444869 42.6241 0 43.1172 0 43.7255C0 44.3339 0.444869 44.827 0.993642 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 44.827C5.55853 44.827 6.0034 44.3339 6.0034 43.7255C6.0034 43.1172 5.55853 42.6241 5.00976 42.6241C4.46098 42.6241 4.01611 43.1172 4.01611 43.7255C4.01611 44.3339 4.46098 44.827 5.00976 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 44.827C9.57428 44.827 10.0191 44.3339 10.0191 43.7255C10.0191 43.1172 9.57428 42.6241 9.0255 42.6241C8.47673 42.6241 8.03186 43.1172 8.03186 43.7255C8.03186 44.3339 8.47673 44.827 9.0255 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 44.827C13.5904 44.827 14.0353 44.3339 14.0353 43.7255C14.0353 43.1172 13.5904 42.6241 13.0416 42.6241C12.4928 42.6241 12.048 43.1172 12.048 43.7255C12.048 44.3339 12.4928 44.827 13.0416 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 44.827C17.6011 44.827 18.046 44.3339 18.046 43.7255C18.046 43.1172 17.6011 42.6241 17.0524 42.6241C16.5036 42.6241 16.0587 43.1172 16.0587 43.7255C16.0587 44.3339 16.5036 44.827 17.0524 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 44.827C21.6172 44.827 22.0621 44.3339 22.0621 43.7255C22.0621 43.1172 21.6172 42.6241 21.0685 42.6241C20.5197 42.6241 20.0748 43.1172 20.0748 43.7255C20.0748 44.3339 20.5197 44.827 21.0685 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 44.827C25.633 44.827 26.0779 44.3339 26.0779 43.7255C26.0779 43.1172 25.633 42.6241 25.0842 42.6241C24.5354 42.6241 24.0906 43.1172 24.0906 43.7255C24.0906 44.3339 24.5354 44.827 25.0842 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 44.827C29.649 44.827 30.0939 44.3339 30.0939 43.7255C30.0939 43.1172 29.649 42.6241 29.1002 42.6241C28.5514 42.6241 28.1066 43.1172 28.1066 43.7255C28.1066 44.3339 28.5514 44.827 29.1002 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 44.827C33.6651 44.827 34.11 44.3339 34.11 43.7255C34.11 43.1172 33.6651 42.6241 33.1163 42.6241C32.5676 42.6241 32.1227 43.1172 32.1227 43.7255C32.1227 44.3339 32.5676 44.827 33.1163 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 44.827C37.681 44.827 38.1258 44.3339 38.1258 43.7255C38.1258 43.1172 37.681 42.6241 37.1322 42.6241C36.5834 42.6241 36.1385 43.1172 36.1385 43.7255C36.1385 44.3339 36.5834 44.827 37.1322 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 44.827C41.697 44.827 42.1418 44.3339 42.1418 43.7255C42.1418 43.1172 41.697 42.6241 41.1482 42.6241C40.5994 42.6241 40.1545 43.1172 40.1545 43.7255C40.1545 44.3339 40.5994 44.827 41.1482 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 44.827C45.7128 44.827 46.1577 44.3339 46.1577 43.7255C46.1577 43.1172 45.7128 42.6241 45.1641 42.6241C44.6153 42.6241 44.1704 43.1172 44.1704 43.7255C44.1704 44.3339 44.6153 44.827 45.1641 44.827Z"
        fill="#E5E6E6"
      />
      <path
        d="M0.993642 49.0898C1.54242 49.0898 1.98728 48.5966 1.98728 47.9883C1.98728 47.38 1.54242 46.8868 0.993642 46.8868C0.444869 46.8868 0 47.38 0 47.9883C0 48.5966 0.444869 49.0898 0.993642 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M5.00976 49.0898C5.55853 49.0898 6.0034 48.5966 6.0034 47.9883C6.0034 47.38 5.55853 46.8868 5.00976 46.8868C4.46098 46.8868 4.01611 47.38 4.01611 47.9883C4.01611 48.5966 4.46098 49.0898 5.00976 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M9.0255 49.0898C9.57428 49.0898 10.0191 48.5966 10.0191 47.9883C10.0191 47.38 9.57428 46.8868 9.0255 46.8868C8.47673 46.8868 8.03186 47.38 8.03186 47.9883C8.03186 48.5966 8.47673 49.0898 9.0255 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M13.0416 49.0898C13.5904 49.0898 14.0353 48.5966 14.0353 47.9883C14.0353 47.38 13.5904 46.8868 13.0416 46.8868C12.4928 46.8868 12.048 47.38 12.048 47.9883C12.048 48.5966 12.4928 49.0898 13.0416 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M17.0524 49.0898C17.6011 49.0898 18.046 48.5966 18.046 47.9883C18.046 47.38 17.6011 46.8868 17.0524 46.8868C16.5036 46.8868 16.0587 47.38 16.0587 47.9883C16.0587 48.5966 16.5036 49.0898 17.0524 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M21.0685 49.0898C21.6172 49.0898 22.0621 48.5966 22.0621 47.9883C22.0621 47.38 21.6172 46.8868 21.0685 46.8868C20.5197 46.8868 20.0748 47.38 20.0748 47.9883C20.0748 48.5966 20.5197 49.0898 21.0685 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M25.0842 49.0898C25.633 49.0898 26.0779 48.5966 26.0779 47.9883C26.0779 47.38 25.633 46.8868 25.0842 46.8868C24.5354 46.8868 24.0906 47.38 24.0906 47.9883C24.0906 48.5966 24.5354 49.0898 25.0842 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M29.1002 49.0898C29.649 49.0898 30.0939 48.5966 30.0939 47.9883C30.0939 47.38 29.649 46.8868 29.1002 46.8868C28.5514 46.8868 28.1066 47.38 28.1066 47.9883C28.1066 48.5966 28.5514 49.0898 29.1002 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M33.1163 49.0898C33.6651 49.0898 34.11 48.5966 34.11 47.9883C34.11 47.38 33.6651 46.8868 33.1163 46.8868C32.5676 46.8868 32.1227 47.38 32.1227 47.9883C32.1227 48.5966 32.5676 49.0898 33.1163 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M37.1322 49.0898C37.681 49.0898 38.1258 48.5966 38.1258 47.9883C38.1258 47.38 37.681 46.8868 37.1322 46.8868C36.5834 46.8868 36.1385 47.38 36.1385 47.9883C36.1385 48.5966 36.5834 49.0898 37.1322 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M41.1482 49.0898C41.697 49.0898 42.1418 48.5966 42.1418 47.9883C42.1418 47.38 41.697 46.8868 41.1482 46.8868C40.5994 46.8868 40.1545 47.38 40.1545 47.9883C40.1545 48.5966 40.5994 49.0898 41.1482 49.0898Z"
        fill="#E5E6E6"
      />
      <path
        d="M45.1641 49.0898C45.7128 49.0898 46.1577 48.5966 46.1577 47.9883C46.1577 47.38 45.7128 46.8868 45.1641 46.8868C44.6153 46.8868 44.1704 47.38 44.1704 47.9883C44.1704 48.5966 44.6153 49.0898 45.1641 49.0898Z"
        fill="#E5E6E6"
      />
      <path d="M38.2797 49.8402H0V92.2736H38.2797V49.8402Z" fill="#7AC143" />
      <path
        d="M26.5424 72.7127C26.3792 72.7127 26.2201 72.6448 26.0977 72.5091C24.2415 70.4508 21.7692 69.3153 19.1419 69.3153C16.5145 69.3153 14.0423 70.4508 12.186 72.5091C11.9412 72.7805 11.5414 72.7805 11.2966 72.5091C11.0518 72.2377 11.0518 71.7943 11.2966 71.5229C13.3936 69.1977 16.18 67.9174 19.1419 67.9174C22.1037 67.9174 24.8942 69.1977 26.9912 71.5229C27.236 71.7943 27.236 72.2377 26.9912 72.5091C26.8688 72.6448 26.7056 72.7127 26.5465 72.7127"
        fill="#DEF0D0"
      />
      <path
        d="M15.372 75.7979C15.2089 75.7979 15.0498 75.7301 14.9274 75.5944C14.6826 75.3229 14.6826 74.8796 14.9274 74.6082C17.2528 72.0296 21.0346 72.0296 23.3601 74.6082C23.6049 74.8796 23.6049 75.3229 23.3601 75.5944C23.1153 75.8658 22.7155 75.8658 22.4707 75.5944C20.6389 73.5632 17.6567 73.5632 15.8208 75.5944C15.6984 75.7301 15.5352 75.7979 15.3761 75.7979"
        fill="#DEF0D0"
      />
      <path
        d="M30.2589 69.0845C30.0957 69.0845 29.9366 69.0167 29.8142 68.881C26.9625 65.7188 23.1725 63.9817 19.1458 63.9817C15.1192 63.9817 11.325 65.7233 8.47335 68.881C8.22857 69.1524 7.82876 69.1524 7.58398 68.881C7.3392 68.6095 7.3392 68.1662 7.58398 67.8948C10.6723 64.4702 14.7765 62.5838 19.1458 62.5838C23.5151 62.5838 27.6193 64.4702 30.7076 67.8948C30.9524 68.1662 30.9524 68.6095 30.7076 68.881C30.5852 69.0167 30.4221 69.0845 30.2629 69.0845"
        fill="#DEF0D0"
      />
      <path
        d="M20.2515 78.2996C20.2515 78.9781 19.7537 79.53 19.1418 79.53C18.5298 79.53 18.0321 78.9781 18.0321 78.2996C18.0321 77.621 18.5298 77.0691 19.1418 77.0691C19.7537 77.0691 20.2515 77.621 20.2515 78.2996Z"
        fill="#DEF0D0"
      />
    </svg>
  );
};
