import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Clock = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g id="Icons / Clock">
        <g id="history">
          <g id="Cores/Preto">
            <path
              id="Mask"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 2C20.6274 2 26 7.37258 26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2ZM14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5ZM18.4673 19.6083C18.7913 19.3502 18.8447 18.8784 18.5866 18.5544L14.8589 13.8745C14.7884 13.786 14.75 13.6762 14.75 13.563V6.02118C14.75 5.60697 14.4142 5.27118 14 5.27118C13.5858 5.27118 13.25 5.60697 13.25 6.02118V14.0874C13.25 14.2006 13.2884 14.3104 13.3589 14.3989L17.4134 19.4889C17.6714 19.8129 18.1433 19.8664 18.4673 19.6083Z"
              fill={color}
            />
            <mask
              id="mask0_0_3132"
              maskUnits="userSpaceOnUse"
              x="2"
              y="2"
              width="24"
              height="24"
            >
              <path
                id="Mask_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 2C20.6274 2 26 7.37258 26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2ZM14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5ZM18.4673 19.6083C18.7913 19.3502 18.8447 18.8784 18.5866 18.5544L14.8589 13.8745C14.7884 13.786 14.75 13.6762 14.75 13.563V6.02118C14.75 5.60697 14.4142 5.27118 14 5.27118C13.5858 5.27118 13.25 5.60697 13.25 6.02118V14.0874C13.25 14.2006 13.2884 14.3104 13.3589 14.3989L17.4134 19.4889C17.6714 19.8129 18.1433 19.8664 18.4673 19.6083Z"
                fill={secondColor}
              />
            </mask>
            <g mask="url(#mask0_0_3132)">
              <g id="Cores/Preto_2">
                <rect id="Rectangle" width="28" height="28" fill={color} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
