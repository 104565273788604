import { memo } from "react";
import { CustomFAQDto, FAQSubComponentDto } from "typing";
import { buildTestIds } from "application";
import { customFAQData } from "./data";
import {
  CustomFAQContainer,
  CustomFAQHeader,
  CustomFAQSubTitle,
  CustomFAQTitle,
} from "./styles";
import { CustomFAQSubComponent } from "./SubComponents";

const getTextAlignment = (
  textAlign: "left" | "center" | "right" | undefined
): "flex-start" | "center" | "flex-end" => {
  if (!textAlign) {
    return "center";
  }

  return textAlign === "left"
    ? "flex-start"
    : textAlign === "center"
    ? "center"
    : textAlign === "right"
    ? "flex-end"
    : "center";
};

const CustomFAQ = memo(
  ({ properties, subComponents, isBlackTheme }: CustomFAQDto) => {
    const {
      title,
      textAlign,
      showTitle,
      showSubTitle,
      subTitle,
      faqBlackFridayTheme,
    } = customFAQData(properties, !!isBlackTheme);

    const titleTextAlign = getTextAlignment(textAlign);

    return (
      <CustomFAQContainer
        {...buildTestIds("custom-faq-container")}
        faqBlackTheme={faqBlackFridayTheme}
      >
        <CustomFAQHeader {...buildTestIds("custom-faq-header")}>
          {showTitle && showTitle === "true" ? (
            <CustomFAQTitle
              {...buildTestIds("custom-faq-title")}
              faqBlackTheme={faqBlackFridayTheme}
              alignSelf={titleTextAlign}
            >
              {title}
            </CustomFAQTitle>
          ) : null}
          {showSubTitle && showSubTitle === "true" ? (
            <CustomFAQSubTitle
              {...buildTestIds("custom-faq-subtitle")}
              faqBlackTheme={faqBlackFridayTheme}
              alignSelf={titleTextAlign}
            >
              {subTitle}
            </CustomFAQSubTitle>
          ) : null}
        </CustomFAQHeader>
        {subComponents?.map((item, index) => (
          <CustomFAQSubComponent
            faqTheme={faqBlackFridayTheme}
            key={`${item.type}-${index + 1}`}
            {...(item as FAQSubComponentDto)}
          />
        ))}
      </CustomFAQContainer>
    );
  }
);

CustomFAQ.displayName = "CustomFAQ";
export { CustomFAQ };
