import styled from "styled-components";
import { theme } from "ui";

interface ControlDotProps {
  isSelected: boolean;
}

export const ControlDot = styled.div<ControlDotProps>`
  border-radius: 3px !important;
  width: ${({ isSelected }) => (isSelected ? "30px" : theme.space.x5)};
  height: 5px;
  background-color: ${({ isSelected }) =>
    isSelected ? theme.colors.primary["200"] : "rgba(189, 189, 189, 0.5)"};
  box-shadow: none;
  transition: background-color 0.2s ease-in-out;
`;

// .carousel .control-dots .selected {
//   width: 30px;
//   background: ${theme.colors.primary["200"]};
// }
