import { Title, CustomProductProperty } from "typing";
import { theme } from "ui";

const customProductData = (properties: CustomProductProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  return {
    title,
  };
};

const getUtilDimensions = (
  isClientMobile: boolean,
  isClientTablet: boolean
) => {
  const percentageDiscountDimension = isClientMobile
    ? "20px"
    : isClientTablet
    ? "26px"
    : "28px";

  const saleTagIconDimension = isClientMobile ? 18 : isClientTablet ? 24 : 28;

  return {
    percentageDiscountDimension,
    saleTagIconDimension,
  };
};

const getCustomProductStyles = (isClientTablet: boolean) => {
  const discountTagTextFontSize = !isClientTablet
    ? theme.typography.fontSize.sm.x5
    : theme.typography.fontSize.sm.x3;

  const priceValueGap = !isClientTablet ? theme.space.x2 : theme.space.x1;
  return {
    discountTagTextFontSize,
    priceValueGap,
  };
};

export { customProductData, getUtilDimensions, getCustomProductStyles };
