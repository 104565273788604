import { IconProps } from "typing";
import { theme } from "../themes";

export const BagIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.primary["155"],
  alt = "ícone de bolsa",
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 21H6C4.895 21 4 20.105 4 19V9C4 7.895 4.895 7 6 7H18C19.105 7 20 7.895 20 9V19C20 20.105 19.105 21 18 21Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6C9 4.343 10.343 3 12 3C13.657 3 15 4.343 15 6"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7V6"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7V6"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
