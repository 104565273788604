import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const PreparingTrackingStatusIcon = ({
  width = 32,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Preparando",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <path
        d="M17.6667 22.6667H10.2708C9.2925 22.6667 8.5 21.8742 8.5 20.8958V12.5458C8.5 12.3067 8.54833 12.0692 8.6425 11.85L9.615 9.575C9.89333 8.92333 10.5342 8.5 11.2433 8.5H19.9242C20.6333 8.5 21.2733 8.92333 21.5525 9.575L22.525 11.85C22.6183 12.07 22.6667 12.3067 22.6667 12.5458V16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5834 8.5V12.2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6309 12.2001H8.53589"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 19.3334H12.6667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1667 23.5001C17.8658 23.5001 16 21.6342 16 19.3334C16 17.0326 17.8658 15.1667 20.1667 15.1667C22.4683 15.1667 24.3333 17.0326 24.3333 19.3334C24.3333 21.6342 22.4683 23.5001 20.1667 23.5001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7866 18.5234L19.7608 20.5493L18.5449 19.3334"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};
