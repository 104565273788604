import { IAppMonitoringClient } from "app-domain";
import { CartAddItems, CartDeleteItem, CartDto, CartUpdateItem } from "typing";
import { ShoppingApi } from "../../services";

export const createCartAuth = (
  branchId: number,
  jwt: string,
  appMonitoringClient: IAppMonitoringClient,
  shoppingApi: ShoppingApi
) => {
  return shoppingApi.createdCartAuthenticated(
    branchId,
    appMonitoringClient,
    jwt
  );
};

export const createNewCartAnonymous = (
  branchId: number,
  shoppingApi: ShoppingApi,
  appMonitoringClient: IAppMonitoringClient
) => {
  return shoppingApi.createCartAnonymous(branchId, appMonitoringClient);
};

export const useGetCartAuth = (
  jwt: string,
  shoppingApi: ShoppingApi,
  appMonitoringClient: IAppMonitoringClient,
  condition: boolean,
  fallbackData?: CartDto
) => {
  return shoppingApi.getCartAuthenticated(
    jwt,
    appMonitoringClient,
    condition,
    fallbackData
  );
};

export const useFetchCartAnonymous = (
  cookie: string,
  shoppingApi: ShoppingApi,
  appMonitoringClient: IAppMonitoringClient,
  condition: boolean
) => {
  return shoppingApi.getCartAnonymous(cookie, appMonitoringClient, condition);
};

export const setItemCartAction = (
  args: CartAddItems,
  shoppingApi: ShoppingApi,
  appMonitoringClient: IAppMonitoringClient,
  jwt?: string
) => {
  return shoppingApi.addItemsCart(args, appMonitoringClient, jwt);
};

export const removeItemCartAction = (
  args: CartDeleteItem,
  shoppingApi: ShoppingApi,
  appMonitoringClient: IAppMonitoringClient,
  jwt?: string
) => {
  return shoppingApi.removeItemCart(args, appMonitoringClient, jwt);
};

export const updateQuantityItemCartAction = (
  args: CartUpdateItem,
  shoppingApi: ShoppingApi,
  appMonitoringClient: IAppMonitoringClient,
  jwt?: string
) => {
  return shoppingApi.updateQuantityItemCart(args, appMonitoringClient, jwt);
};
