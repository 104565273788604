import { buildTestIds, getClientBranchId, useDebounce } from "application";
import { useAppContext } from "application/context/state";
import { catalogApi, cookie, gtag } from "implementations";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Close,
  HeaderSearchbarFlexContainer,
  InputSearch,
  InputSearchFlexContainer,
  OverlayContainer,
} from "ui";

import { SearchHistoricResult, SuggestedProductsResult } from "./components";
import { OmniSearchResult } from "./components/Results/OmniSearchResult";
import { TermSuggestionResult } from "./components/Results/TermSuggestionResult";
import { useSearchHistory } from "./data";
import {
  ContainerSearchProduct,
  SearchContainerSuggestion,
  SearchProductHyperLink,
  SearchProductIcon,
} from "./styles";

interface SearchInputProps {
  isDesktop?: boolean;
}

interface SearchProductProps {
  searchProduct: string;
}

const SearchInput = ({ isDesktop }: SearchInputProps) => {
  const router = useRouter();
  const { saveSearchTerm, termsList, removeSearchTerm } = useSearchHistory({});
  const { isSidebarMenuOpened, setIsSidebarMenuOpened } = useAppContext();
  const { handleSubmit, reset, register, watch, setFocus, setValue } =
    useForm<SearchProductProps>();

  useEffect(() => {
    if (!router?.pathname?.toLowerCase().includes("/pesquisa")) {
      reset();
    }
  }, [reset, router?.pathname]);

  const goToSearchResult = useCallback(
    (url: string) => {
      const term = url?.split("/")[2];
      gtag.search(term);
      router.push(url);
      saveSearchTerm(term);
      setValue("searchProduct", term);
    },
    [router, saveSearchTerm, setValue]
  );

  const { focusOnInputSearch } = useAppContext();
  const handleSearchProduct = ({ searchProduct }: SearchProductProps) => {
    if (!searchProduct) {
      return;
    }

    const formattedSearchProduct = searchProduct.replace(/\//g, " ");
    document.getElementById("searchProduct")?.blur();
    goToSearchResult(`/pesquisa/${formattedSearchProduct}`);
  };

  const searchProduct = watch("searchProduct");

  const debouncedSearchProduct = useDebounce(searchProduct, 500);
  const [hasFocusOnInputSearch, setHasFocusOnInputSearch] = useState(false);
  const { isClientTablet } = useAppContext();

  useEffect(() => {
    if (focusOnInputSearch) {
      setFocus("searchProduct");
    }
  }, [setFocus, focusOnInputSearch]);

  const branchId = Number(getClientBranchId(cookie));

  const { data } = catalogApi.getSuggestions(debouncedSearchProduct, branchId);

  const showHistoricSearch = useMemo(
    () => Boolean(!searchProduct?.length && termsList?.length),
    [searchProduct?.length, termsList?.length]
  );

  const handleTypeInSearchProduct = useCallback(() => {
    if (isSidebarMenuOpened) {
      setTimeout(() => {
        setIsSidebarMenuOpened(false);
      }, 150);
    }
  }, [isSidebarMenuOpened, setIsSidebarMenuOpened]);

  return (
    <ContainerSearchProduct
      onSubmit={handleSubmit(handleSearchProduct)}
      isDesktop={isDesktop}
      {...buildTestIds("container-search-product")}
    >
      <HeaderSearchbarFlexContainer>
        <InputSearchFlexContainer
          {...buildTestIds("container-input-search")}
          onFocus={() => setHasFocusOnInputSearch(true)}
          onBlur={() => setHasFocusOnInputSearch(false)}
          onClick={handleTypeInSearchProduct}
        >
          <SearchProductHyperLink
            isDesktop={isDesktop}
            {...buildTestIds("search-product-hyperlink")}
            hasFocusOnInputSearch={hasFocusOnInputSearch}
            isDisabled={Boolean(debouncedSearchProduct?.length === 0)}
            onClick={handleSubmit(handleSearchProduct)}
          >
            <SearchProductIcon />
          </SearchProductHyperLink>
          <InputSearch
            id="searchProduct"
            fontSize="1.25rem"
            fontWeight="400"
            autoComplete="off"
            list="search-products"
            type="text"
            maxLength={200}
            hasFocusOnInputSearch={hasFocusOnInputSearch}
            placeholder="O que você está procurando?"
            {...buildTestIds("input-search-product")}
            hideBorder={hasFocusOnInputSearch}
            {...register("searchProduct")}
          />
          <OverlayContainer {...buildTestIds("overlay-container")} />
        </InputSearchFlexContainer>

        <SearchContainerSuggestion
          id="search-products"
          {...buildTestIds("search-container-suggestion")}
          showItems={!!searchProduct || showHistoricSearch}
          hasFocusOnInputSearch={hasFocusOnInputSearch}
        >
          <SearchHistoricResult
            actionButton={{
              icon: <Close />,
              onClick: removeSearchTerm,
            }}
            onClick={goToSearchResult}
            showHistoricSearch={showHistoricSearch}
            result={termsList}
          />

          <OmniSearchResult
            result={data?.omniSearch}
            onClick={saveSearchTerm}
          />

          <TermSuggestionResult
            result={data?.suggestion}
            searchProduct={searchProduct}
            onClick={goToSearchResult}
          />

          <SuggestedProductsResult
            onClick={saveSearchTerm}
            isClientTablet={isClientTablet}
            suggestion={data?.suggestion}
            suggestionsProducts={data?.products}
          />
        </SearchContainerSuggestion>
      </HeaderSearchbarFlexContainer>
    </ContainerSearchProduct>
  );
};

export { SearchInput };
