import { memo, useEffect, useState } from "react";
import { ExtendedCarouselProps, SliderProps } from "typing";
import { buildTestIds } from "application";
import { CarouselSubComponent } from "../SubComponent";
import { CustomExtendedCarousel } from "./styles";
import { Slider } from "../../../Slider";

const ExtendedCarousel = memo(
  ({
    subComponents,
    isClientLaptop,
    automaticSlide,
    carouselTheme,
    ...restProps
  }: ExtendedCarouselProps) => {
    const [autoPlay, setAutoPlay] = useState<boolean>(false);

    useEffect(() => {
      if (automaticSlide === "true") {
        setAutoPlay(true);
      } else {
        setAutoPlay(false);
      }
    }, [automaticSlide]);

    const settings: SliderProps = {
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
      swipe: true,
      dots: true,
      autoplay: autoPlay,
      infinite: true,
      arrowsIsActive: !isClientLaptop,
    };

    return (
      <CustomExtendedCarousel
        {...buildTestIds("custom-extended-carousel")}
        customTheme={carouselTheme}
      >
        <Slider customTheme={carouselTheme?.sliderButton} {...settings}>
          {subComponents?.map((subComponent, index) => {
            const subComponentKey = `${subComponent?.type}#${index}`;
            return (
              <CarouselSubComponent
                key={subComponentKey}
                position={index + 1}
                {...subComponent}
                {...restProps}
              />
            );
          })}
        </Slider>
      </CustomExtendedCarousel>
    );
  }
);

ExtendedCarousel.displayName = "ExtendedCarousel";

export { ExtendedCarousel };
