import { appMonitoringClient } from "implementations";
import {
  ConfigDto,
  CustomBannerProperty,
  DesktopFormat,
  DesktopImage,
  DeviceFormatValue,
  MobileFormat,
  MobileImage,
  Title,
} from "typing";

const customBannerData = (properties: CustomBannerProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const desktopImage = (
    properties.find(({ key }) => key === "desktopImage") as DesktopImage
  )?.value;
  const mobileImage = (
    properties.find(({ key }) => key === "mobileImage") as MobileImage
  )?.value;

  const desktopFormat = (
    properties.find(({ key }) => key === "desktopFormat") as DesktopFormat
  )?.value;
  const mobileFormat = (
    properties.find(({ key }) => key === "mobileFormat") as MobileFormat
  )?.value;

  return {
    title,
    desktopImage,
    mobileImage,
    desktopFormat,
    mobileFormat,
  };
};

const handleComponentWarnings = (
  config: ConfigDto | null,
  currentPathName: string,
  position: number
) => {
  if (!config?.branches?.length) {
    appMonitoringClient.captureMessage(
      `Um componente de Banner, localizado em "${currentPathName}" na posição ${position}, não possui nenhuma configuração de branches.`,
      "warning"
    );
  }
};

const getBannerTypeValidation = (
  isinsidecarousel: boolean,
  isinsidecarouselingridformat: boolean
) => {
  return isinsidecarousel
    ? "carousel"
    : isinsidecarouselingridformat
    ? "grid"
    : "banner";
};

const getImageFormat = (
  isinsidecarouselingridformat: boolean,
  isinsidecarousel: boolean,
  isClientMobile: boolean,
  mobileFormat: DeviceFormatValue | undefined,
  desktopFormat: DeviceFormatValue | undefined
) => {
  return isinsidecarouselingridformat
    ? "default"
    : (isinsidecarousel
        ? "extended"
        : isClientMobile
        ? mobileFormat
        : desktopFormat) || "extended";
};

export {
  customBannerData,
  handleComponentWarnings,
  getBannerTypeValidation,
  getImageFormat,
};
