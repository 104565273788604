import { buildTestIds, useEnvs } from "application";
import { AllConfigs } from "typing";
import { Category } from "../Category";
import { userAuthenticatedActions } from "./data";
import {
  DropdownTop,
  DropdownUserAuthenticatedContainer,
  DropdownUserAuthenticatedHeader,
} from "./styles";

interface DropdownUserAuthenticatedProps {
  handleCloseDropdownUserAuthenticated: () => void;
  isOpenedDropdownUser: boolean;
}

const DropdownUserAuthenticated = ({
  handleCloseDropdownUserAuthenticated,
  isOpenedDropdownUser,
}: DropdownUserAuthenticatedProps) => {
  const allConfigs = useEnvs((state) => state.allConfigs);

  return (
    <DropdownUserAuthenticatedContainer
      {...buildTestIds("dropdown-user-authenticated")}
      isOpenedDropdownUser={isOpenedDropdownUser}
    >
      <DropdownUserAuthenticatedHeader
        {...buildTestIds("dropdown-user-authenticated-header")}
      />
      <DropdownTop {...buildTestIds("dropdown-user-authenticated-top")} />
      {userAuthenticatedActions(allConfigs as AllConfigs).map((action) => {
        return Category({
          action,
          handleCloseDropdownUserAuthenticated,
          isCloseIcon: false,
        });
      })}
      {Category({
        action: null,
        handleCloseDropdownUserAuthenticated,
        isCloseIcon: true,
      })}
    </DropdownUserAuthenticatedContainer>
  );
};

export { DropdownUserAuthenticated };
