import { useInitializeTwoFactor } from "application";
import { useShallow, useTwoFactor } from "application/state-manager";
import { memo, useEffect } from "react";
import { TwoFactorContentProps } from "typing";
import { ViewTwoFactorContent } from "./View";
import { ViewTwoFactorContentFailure } from "./View/failure";
import { ViewTwoFactorVerifyIdentityContent } from "./View/verifyIdentity";

const TwoFactorContent = memo(
  ({
    sendCodeType = "sms",
    successTitle = "Verificação de identidade",
    errorTitle = "Falha na verificação de identidade",
    errorButtonText = "Voltar para página inicial",
    viewType,
    clientAction,
    email,
    phoneNumber,
    hasError,
    canChooseAnotherTwoFactorMethod,
    isUnderAction,
    onClickFailureButton,
    onCallAction,
    onClickIsInvalidClientInfo,
    isVerifyIdentity,
    verifyIdentityDescription,
    verifyIdentityTitle,
    handleSelectAnotherTwoFactorType,
    options,
    buttonBorderRadius,
    buttonHeight,
    hasHeader,
    hasModalHeader,
    hasDescriptionOnMobile,
    failureIllustration,
    marginDescription,
    showButton,
    failureText,
    noTitle,
    onClickOnCloseButton,
    titleFailure,
    optionsBackgroundColor,
    shouldMask = true,
    preventDefault = false,
    hasTitleBorder = false,
  }: TwoFactorContentProps) => {
    const {
      isBlockedToSendCode,
      setClientAction,
      setEmail,
      setPhoneNumber,
      setCanChooseAnotherTwoFactorMethod,
      setIsUnderAction,
    } = useTwoFactor(
      useShallow((state) => ({
        isBlockedToSendCode: state.isBlockedToSendCode,
        setClientAction: state.setClientAction,
        setEmail: state.setEmail,
        setPhoneNumber: state.setPhoneNumber,
        setCanChooseAnotherTwoFactorMethod:
          state.setCanChooseAnotherTwoFactorMethod,
        setIsUnderAction: state.setIsUnderAction,
      }))
    );

    useEffect(() => {
      setIsUnderAction(isUnderAction);
    }, [isUnderAction, setIsUnderAction]);

    useInitializeTwoFactor({
      clientAction,
      email,
      phoneNumber,
      canChooseAnotherTwoFactorMethod,
      setClientAction,
      setEmail,
      setPhoneNumber,
      setCanChooseAnotherTwoFactorMethod,
      options,
    });

    if (hasError || isBlockedToSendCode) {
      return (
        <ViewTwoFactorContentFailure
          titleFailure={titleFailure}
          hasHeader={hasHeader}
          title={errorTitle}
          viewType={viewType}
          buttonText={errorButtonText}
          onClickFailureButton={onClickFailureButton}
          hasModalHeader={hasModalHeader}
          illustration={failureIllustration}
          showButton={showButton}
          text={failureText}
        />
      );
    }

    if (isVerifyIdentity) {
      return (
        <ViewTwoFactorVerifyIdentityContent
          noTitle={noTitle}
          options={options}
          description={verifyIdentityDescription}
          title={verifyIdentityTitle}
          viewType={viewType}
          hasTitleBorder={hasTitleBorder}
          hasDescriptionOnMobile={hasDescriptionOnMobile}
          backgroundColor={optionsBackgroundColor}
        />
      );
    }

    return (
      <ViewTwoFactorContent
        noTitle={noTitle}
        title={successTitle}
        sendCodeType={sendCodeType}
        showButton={showButton}
        marginDescription={marginDescription}
        viewType={viewType}
        shouldMask={shouldMask}
        onCallAction={onCallAction}
        buttonBorderRadius={buttonBorderRadius}
        backgroundColor={optionsBackgroundColor}
        buttonHeight={buttonHeight}
        onClickIsInvalidClientInfo={onClickIsInvalidClientInfo}
        handleSelectAnotherTwoFactorType={handleSelectAnotherTwoFactorType}
        preventDefault={preventDefault}
        onClickOnCloseButton={onClickOnCloseButton}
      />
    );
  }
);

TwoFactorContent.displayName = "TwoFactorContent";

export { TwoFactorContent };
