import styled from "styled-components";
import { NewEyeCloseIcon, NewEyeOpenIcon, theme } from "ui";
import { ButtonSubmit } from "../ButtonSubmit";

export const ButtonConfirmModalPasswordChange = styled(ButtonSubmit)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  width: 100% !important;
  height: 3.75rem;
  padding: 0.65rem 4.9125rem;
  text-align: center;
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1.15rem;
  font-weight: ${theme.typography.fontWeight["400"]};
  line-height: 1.3125rem;
  letter-spacing: 0.005rem;

  border-radius: 4px;
  color: ${theme.colors.neutral.white};
  background: ${theme.colors.secondary["380"]};
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  border: 0;

  &:hover {
    background: ${theme.colors.secondary["360"]} !important;
  }
`;

export const ChangePasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
  width: 100%;
`;

export const InputWrapper = styled.div``;

export const PasswordValidationWrapper = styled.div`
  width: 100%;
`;

export const EyeOpenIcon = styled(NewEyeOpenIcon).attrs({
  width: 20,
  height: 18,
})``;

export const EyeCloseIcon = styled(NewEyeCloseIcon)``;
