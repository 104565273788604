import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const Mastercard = ({
  width = 65,
  height = 40,
  alt = "Mastercard",
  color = theme.colors.orange["400"],
  secondColor = theme.colors.primary["700"],
  thirdColor = theme.colors.yellow["150"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 43 26"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1940_4380)">
        <path
          d="M27.1521 3.24288H16.8179V21.7584H27.1521V3.24288Z"
          fill={color}
        />
        <path
          d="M17.4747 12.4999C17.4731 10.7167 17.8784 8.95655 18.6599 7.35262C19.4414 5.74869 20.5787 4.34306 21.9856 3.24214C20.2433 1.87673 18.1509 1.02761 15.9474 0.791818C13.744 0.556025 11.5185 0.943071 9.52528 1.90873C7.53205 2.87439 5.85153 4.37971 4.6758 6.2526C3.50007 8.1255 2.87659 10.2904 2.87659 12.4999C2.87659 14.7094 3.50007 16.8743 4.6758 18.7472C5.85153 20.6201 7.53205 22.1254 9.52528 23.0911C11.5185 24.0567 13.744 24.4438 15.9474 24.208C18.1509 23.9722 20.2433 23.1231 21.9856 21.7577C20.5787 20.6567 19.4414 19.2511 18.6599 17.6472C17.8784 16.0433 17.4731 14.2831 17.4747 12.4999Z"
          fill={secondColor}
        />
        <path
          d="M41.0935 12.4999C41.0936 14.7093 40.4702 16.8742 39.2945 18.7471C38.1188 20.62 36.4384 22.1253 34.4452 23.091C32.452 24.0567 30.2265 24.4437 28.0231 24.208C25.8197 23.9722 23.7273 23.123 21.985 21.7576C23.3907 20.6556 24.5271 19.2497 25.3085 17.6461C26.0899 16.0424 26.4959 14.2828 26.4959 12.4999C26.4959 10.717 26.0899 8.95733 25.3085 7.35366C24.5271 5.74999 23.3907 4.34413 21.985 3.24209C23.7273 1.87669 25.8197 1.02756 28.0231 0.791771C30.2265 0.555981 32.452 0.943044 34.4452 1.90872C36.4384 2.87439 38.1188 4.3797 39.2945 6.2526C40.4702 8.12549 41.0936 10.2904 41.0935 12.4999Z"
          fill={thirdColor}
        />
        <path
          d="M39.9667 19.7962V19.4172H40.12V19.3399H39.7296V19.4172H39.883V19.7962H39.9667ZM40.7247 19.7962V19.3392H40.6051L40.4674 19.6536L40.3297 19.3392H40.21V19.7962H40.2945V19.4515L40.4236 19.7487H40.5112L40.6403 19.4507V19.7962H40.7247Z"
          fill={thirdColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1940_4380">
          <rect width="43" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
