import { IconProps } from "typing";

export const ArrowLeftIcon = ({
  height = "18px",
  width = "18px",
  alt = "Seta para esquerda",
  color = "currentColor",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 256 256"
    >
      <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z" />
    </svg>
  );
};
