import { useEffect, useState } from "react";
import { ToastMessageProps } from "typing";
import { ErrorIcon, SuccessIcon, theme } from "ui";
import {
  TitleToast,
  ToastContainer,
  CloseIcon,
  WrapperIcon,
  LineProgressBar,
  TextContent,
  IconContainer,
  TitleNewToast,
  DescriptionToast,
} from "./styles";
import { buildTestIds } from "../../../../utils";

const Toast = ({
  id,
  title,
  description,
  type,
  timerInMilliseconds = 3500,
  showProgressBar = true,
  newToastTheme,
  isNewToast = false,
}: ToastMessageProps) => {
  const [isHasClose, setIsHasClose] = useState<boolean>(false);
  const timerInSeconds = timerInMilliseconds / 1000;

  const handleCloseToast = () => {
    setIsHasClose(true);
  };

  useEffect(() => {
    if (showProgressBar) {
      setTimeout(() => {
        handleCloseToast();
      }, timerInMilliseconds);
    }
  }, [showProgressBar, timerInMilliseconds]);

  const themeColor =
    newToastTheme === "light"
      ? theme.colors.neutral.white
      : theme.colors.neutral["680"];

  return isNewToast ? (
    <ToastContainer
      isNewToast={isNewToast}
      id={id}
      type={type}
      hasClose={isHasClose}
      newToastTheme={newToastTheme}
      {...buildTestIds("toast-container")}
    >
      <IconContainer {...buildTestIds("icon-container")}>
        {type === "success" ? (
          <SuccessIcon secondColor={themeColor} />
        ) : (
          <ErrorIcon secondColor={themeColor} />
        )}
      </IconContainer>
      <TextContent>
        <TitleNewToast
          {...buildTestIds("title-new-toast")}
          newToastTheme={newToastTheme}
        >
          {title}
        </TitleNewToast>
        <DescriptionToast
          {...buildTestIds("description-toast")}
          newToastTheme={newToastTheme}
        >
          {description}
        </DescriptionToast>
      </TextContent>
      {showProgressBar && (
        <LineProgressBar
          {...buildTestIds("line-progress-bar")}
          isNewToast
          type={type}
          time={timerInSeconds}
        />
      )}
    </ToastContainer>
  ) : (
    <ToastContainer
      id={id}
      hasClose={isHasClose}
      type={type}
      {...buildTestIds("container-toast")}
    >
      <TitleToast {...buildTestIds("title-toast")}>{title}</TitleToast>
      <WrapperIcon
        {...buildTestIds("toast-close-button")}
        onClick={() => handleCloseToast()}
      >
        <CloseIcon />
      </WrapperIcon>
      {showProgressBar && (
        <LineProgressBar isNewToast={false} time={timerInSeconds} />
      )}
    </ToastContainer>
  );
};

export { Toast };
