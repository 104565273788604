import { forwardRef } from "react";
import { LinkProps } from "typing";
import { buildTestIds } from "application";
import { StyledLink } from "./style";

const CustomStyledLink = forwardRef<unknown, Omit<LinkProps, "href">>(
  (
    { onClick, target, mouseOver, children, ...props }: Omit<LinkProps, "href">,
    ref
  ) => {
    return (
      <StyledLink
        onClick={onClick}
        ref={ref}
        target={target || "_blank"}
        mouseOver={mouseOver}
        {...buildTestIds("styled-link")}
        {...props}
      >
        {children}
      </StyledLink>
    );
  }
);

CustomStyledLink.displayName = "StyledLink";

export { CustomStyledLink };
