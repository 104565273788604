import {
  buildTestIds,
  formatTestId,
  useAppContext,
  useBranchLocation,
} from "application";
import { FlexContainer, Text, theme } from "ui";

import { DropdownBranchLocation } from "../DropdownBranchLocation";
import { ModalBranchLocation } from "../ModalBranchLocation";
import {
  CompanyProductLink,
  ContentCompanyProducts,
  ContentLocation,
  DividerCompanyProducts,
  LocationIcon,
  NavBarDesktopContainer,
  RegionClientLocation,
  RegionText,
} from "./styles";

const companyProducts = [
  {
    title: "Lista de Casamento",
    path: "/lista-de-casamento/",
  },
  {
    title: "Nossas Lojas",
    path: "https://ferreiracosta.movidesk.com/kb/article/156436/nossas-lojas?ticketId=&q=",
  },
  {
    title: "Para Empresas",
    path: "http://empresas.ferreiracosta.com/",
  },
  {
    title: "Clube do Profissional",
    path: "https://clubedoprofissional.ferreiracosta.com/",
  },
];

interface NavBarDesktopProps {
  companyProductsColor?: string;
  isBlackFriday?: boolean;
}

const NavBarDesktop = ({
  companyProductsColor,
  isBlackFriday,
}: NavBarDesktopProps) => {
  const { isPageWeddingList } = useAppContext();
  const { regionClientLocation, onOpenModalBranchLocation } =
    useBranchLocation();

  return (
    <>
      <ModalBranchLocation />
      <DropdownBranchLocation />
      <NavBarDesktopContainer
        {...buildTestIds("wrapper-navbar-location-desktop-header")}
        isPageWeddingList={isPageWeddingList}
      >
        <ContentLocation
          {...buildTestIds("content-location-desktop-header")}
          onClick={() => onOpenModalBranchLocation()}
          arrowDownColor={
            isPageWeddingList || isBlackFriday
              ? theme.colors.neutral.white
              : theme.colors.neutral["999"]
          }
        >
          <LocationIcon
            width={16}
            height={16}
            alt="ícone de localização"
            color={
              isPageWeddingList
                ? theme.colors.neutral.white
                : theme.colors.primary["200"]
            }
          />
          <RegionText
            {...buildTestIds("wrapper-navbar-location-desktop")}
            isPageWeddingList={isPageWeddingList}
          >
            <Text fontSize="15px" margin="0px 0px 0px 5px">
              {regionClientLocation === "Demais Regiões" ? "" : "Você está em "}
              <RegionClientLocation
                {...buildTestIds("nav-bar-region-client-desktop")}
                isPageWeddingList={isPageWeddingList}
              >
                {regionClientLocation ?? "Demais Regiões"}
              </RegionClientLocation>
            </Text>
          </RegionText>
        </ContentLocation>
        {companyProducts?.length ? (
          <FlexContainer
            {...buildTestIds("flex-container-company-products-header")}
          >
            <ContentCompanyProducts
              isPageWeddingList={isPageWeddingList}
              {...buildTestIds("content-company-products-header")}
            >
              {companyProducts.map((product) => (
                <CompanyProductLink
                  {...buildTestIds(
                    `company-product-link-${formatTestId(product.title)}`
                  )}
                  isPageWeddingList={isPageWeddingList}
                  key={product.title}
                  href={product.path}
                  color={companyProductsColor ?? theme.colors.neutral["900"]}
                  margin={theme.space.x4}
                >
                  {product.title}
                  {isPageWeddingList ? (
                    <DividerCompanyProducts
                      {...buildTestIds(`divider-company-product`)}
                      isPageWeddingList={isPageWeddingList}
                    >
                      |
                    </DividerCompanyProducts>
                  ) : null}
                </CompanyProductLink>
              ))}
            </ContentCompanyProducts>
          </FlexContainer>
        ) : null}
      </NavBarDesktopContainer>
    </>
  );
};

export { NavBarDesktop };
