import { createStore } from "../adapter";

type WeddingListState = {
  coupleLink: string;
};

type WeddingListDispatch = {
  setCoupleLink: (value: string) => void;
};

type WeddingListProps = WeddingListState & WeddingListDispatch;

const useWeddingList = createStore<WeddingListProps>((set) => ({
  coupleLink: "",
  setCoupleLink: (coupleLink) => set({ coupleLink }),
}));

export { useWeddingList };
