import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const BoxGift = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral["999"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_620_10128" fill={color}>
        <rect x="3.33333" y="10.7333" width="17.3333" height="10.8333" rx="1" />
      </mask>
      <rect
        x="3.33333"
        y="10.7333"
        width="17.3333"
        height="10.8333"
        rx="1"
        stroke={color}
        strokeWidth="3"
        mask="url(#path-1-inside-1_620_10128)"
      />
      <path
        d="M8.46666 6.91666C5.42666 6.91666 6.03333 3.96667 7.21667 3.28333C9.29609 2.05403 12.0233 5.3163 12 7.2C11.9178 5.4069 14.6627 2.16472 16.8 3.28334C17.9833 3.96667 18.59 6.91667 15.55 6.91667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M12.05 20.5833V7.3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <mask id="path-4-inside-2_620_10128" fill={color}>
        <rect x="2" y="6.16667" width="20" height="6.06667" rx="1" />
      </mask>
      <rect
        x="2"
        y="6.16667"
        width="20"
        height="6.06667"
        rx="1"
        stroke={secondColor}
        strokeWidth="3"
        mask="url(#path-4-inside-2_620_10128)"
      />
    </svg>
  );
};
