import { createStore } from "../adapter";

type GiftCardState = {
  sentSMSToken: string | null;
};

type GiftCardDispatch = {
  setSentSMSToken: (sentSMSToken: string | null) => void;
};

type GiftCardProps = GiftCardState & GiftCardDispatch;

const useGiftCard = createStore<GiftCardProps>((set) => ({
  sentSMSToken: null,
  setSentSMSToken: (sentSMSToken: string | null) => {
    set({ sentSMSToken });
  },
}));

export { useGiftCard };
