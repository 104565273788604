import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

const TelephoneAuthenticationIcon = ({
  width = 144,
  height = 120,
  color = theme.colors.neutral["white-075"],
  secondColor = theme.colors.neutral["750"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 144 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4146_9395)">
        <path
          d="M56.4105 13.3111C61.4008 4.57583 73.996 4.57581 78.9863 13.3111L128.354 99.7263C133.305 108.393 127.047 119.175 117.066 119.175H18.3308C8.34975 119.175 2.09192 108.393 7.04296 99.7263L56.4105 13.3111Z"
          fill={color}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6048 37.1367H79.5254C85.3975 37.1367 90.1536 41.8928 90.1536 47.7649V95.5917C90.1536 101.464 85.3975 106.22 79.5254 106.22H57.6048C51.7327 106.22 46.9766 101.464 46.9766 95.5917V47.7649C46.9766 41.8928 51.7327 37.1367 57.6048 37.1367Z"
        fill={color}
        stroke={secondColor}
        strokeWidth="4.63085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="51.668"
        y="54.0312"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#B6DD98"
      />
      <rect
        x="51.668"
        y="42.7686"
        width="33.7907"
        height="5.63178"
        rx="2.54473"
        fill="url(#paint0_linear_4146_9395)"
      />
      <rect
        x="51.668"
        y="85.9453"
        width="33.7907"
        height="15.0181"
        rx="7.50904"
        fill="url(#paint1_linear_4146_9395)"
      />
      <rect
        x="51.668"
        y="67.1729"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#B6DD98"
      />
      <rect
        x="51.668"
        y="73.7432"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#B6DD98"
      />
      <rect
        x="64.8086"
        y="60.6025"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#D0E9BD"
      />
      <rect
        x="64.8086"
        y="80.3145"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#D0E9BD"
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.3287 33.5937C45.4571 33.5937 44.75 34.3008 44.75 35.1729C44.75 36.0449 45.4571 36.7516 46.3287 36.7516H50.6708C51.5429 36.7516 52.25 36.0445 52.25 35.1729C52.25 34.3012 51.5429 33.5941 50.6712 33.5941C50.6712 32.3949 49.6992 31.4229 48.5 31.4229C47.3008 31.4229 46.3287 32.3945 46.3287 33.5937Z"
          fill="#A0A2A4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.451 84.7628C108.047 78.8326 110.574 72.0484 116.273 69.1373C121.971 66.2262 128.95 68.154 132.346 73.5776C135.742 79.0011 134.428 86.1208 129.321 89.9758C124.213 93.8309 117.006 93.1422 112.722 88.3895L108.737 88.3603L110.451 84.7628Z"
        fill="#68A439"
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.851 72.3812L126.194 73.6096C127.178 73.8358 127.792 74.8161 127.566 75.8L125.109 86.4859C124.883 87.4698 123.902 88.0836 122.918 87.8573L117.575 86.6289C116.591 86.4027 115.978 85.4225 116.204 84.4385L118.661 73.7526C118.887 72.7687 119.867 72.1549 120.851 72.3812Z"
        fill={color}
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.742 72.5859L121.374 74.1888C121.306 74.4836 121.45 74.769 121.696 74.8255L124.368 75.4397C124.613 75.4962 124.868 75.3025 124.936 75.0078L125.304 73.4049"
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.761 84.788L121.756 85.2466"
        stroke="#5C5E62"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9449 64.6327C35.6847 58.8499 33.5496 51.9322 28.0272 48.6995C22.5047 45.4669 15.4274 46.9919 11.7265 52.2121C8.02549 57.4323 8.92888 64.6154 13.8072 68.7566C18.6855 72.8978 25.92 72.623 30.47 68.1235L34.4503 68.3225L32.9449 64.6327Z"
        fill="#68A439"
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2766 51.676L17.8721 52.5964C16.8768 52.7659 16.2079 53.7094 16.3775 54.7047L18.2184 65.5137C18.3879 66.509 19.3314 67.1779 20.3267 67.0084L25.7312 66.0879C26.7265 65.9184 27.3954 64.9749 27.2259 63.9796L25.3849 53.1706C25.2154 52.1753 24.272 51.5064 23.2766 51.676Z"
        fill={color}
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.375 51.8291L22.6511 53.4504C22.7019 53.7486 22.5414 54.0253 22.2928 54.0676L19.5906 54.5278C19.342 54.5702 19.0989 54.3622 19.0481 54.0641L18.772 52.4427"
        stroke={secondColor}
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6548 64.1252L21.6372 64.4688"
        stroke="#5C5E62"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.7431 13.3312C65.4356 13.3312 64.375 14.3211 64.375 15.542C64.375 16.7629 65.4356 17.7522 66.7431 17.7522H73.2562C74.5644 17.7522 75.625 16.7623 75.625 15.542C75.625 14.3217 74.5644 13.3317 73.2569 13.3317C73.2569 11.6529 71.7987 10.292 70 10.292C68.2012 10.292 66.7431 11.6523 66.7431 13.3312Z"
          stroke="#8F9194"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.3287 25.5937C71.4571 25.5937 70.75 26.3008 70.75 27.1729C70.75 28.0449 71.4571 28.7516 72.3287 28.7516H76.6708C77.5429 28.7516 78.25 28.0445 78.25 27.1729C78.25 26.3012 77.5429 25.5941 76.6712 25.5941C76.6712 24.3949 75.6992 23.4229 74.5 23.4229C73.3008 23.4229 72.3287 24.3945 72.3287 25.5937Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.7431 26.8963C57.4356 26.8963 56.375 27.8155 56.375 28.9492C56.375 30.0829 57.4356 31.0016 58.7431 31.0016H65.2562C66.5644 31.0016 67.625 30.0824 67.625 28.9492C67.625 27.8161 66.5644 26.8968 65.2569 26.8968C65.2569 25.3379 63.7987 24.0742 62 24.0742C60.2012 24.0742 58.7431 25.3374 58.7431 26.8963Z"
          stroke="#A0A2A4"
          strokeWidth="0.62"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4602 86.8963C29.2399 86.8963 28.25 87.8155 28.25 88.9492C28.25 90.0829 29.2399 91.0016 30.4602 91.0016H36.5392C37.7601 91.0016 38.75 90.0824 38.75 88.9492C38.75 87.8161 37.7601 86.8968 36.5397 86.8968C36.5397 85.3379 35.1788 84.0742 33.5 84.0742C31.8212 84.0742 30.4602 85.3374 30.4602 86.8963Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.3287 78.5937C97.4571 78.5937 96.75 79.3008 96.75 80.1729C96.75 81.0449 97.4571 81.7516 98.3287 81.7516H102.671C103.543 81.7516 104.25 81.0445 104.25 80.1729C104.25 79.3012 103.543 78.5941 102.671 78.5941C102.671 77.3949 101.699 76.4229 100.5 76.4229C99.3008 76.4229 98.3287 77.3945 98.3287 78.5937Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.4602 62.3312C95.2399 62.3312 94.25 63.3211 94.25 64.542C94.25 65.7629 95.2399 66.7522 96.4602 66.7522H102.539C103.76 66.7522 104.75 65.7623 104.75 64.542C104.75 63.3217 103.76 62.3317 102.54 62.3317C102.54 60.6529 101.179 59.292 99.5 59.292C97.8212 59.292 96.4602 60.6523 96.4602 62.3312Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.3287 109.594C32.4571 109.594 31.75 110.301 31.75 111.173C31.75 112.045 32.4571 112.752 33.3287 112.752H37.6708C38.5429 112.752 39.25 112.045 39.25 111.173C39.25 110.301 38.5429 109.594 37.6712 109.594C37.6712 108.395 36.6992 107.423 35.5 107.423C34.3008 107.423 33.3287 108.395 33.3287 109.594Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.612 106.594C103.653 106.594 102.875 107.301 102.875 108.173C102.875 109.045 103.653 109.752 104.612 109.752H109.388C110.347 109.752 111.125 109.045 111.125 108.173C111.125 107.301 110.347 106.594 109.388 106.594C109.388 105.395 108.319 104.423 107 104.423C105.681 104.423 104.612 105.395 104.612 106.594Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6116 103.594C12.6528 103.594 11.875 104.301 11.875 105.173C11.875 106.045 12.6528 106.752 13.6116 106.752H18.3879C19.3472 106.752 20.125 106.045 20.125 105.173C20.125 104.301 19.3472 103.594 18.3884 103.594C18.3884 102.395 17.3191 101.423 16 101.423C14.6809 101.423 13.6116 102.395 13.6116 103.594Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.743 97.3312C110.436 97.3312 109.375 98.3211 109.375 99.542C109.375 100.763 110.436 101.752 111.743 101.752H118.256C119.564 101.752 120.625 100.762 120.625 99.542C120.625 98.3217 119.564 97.3317 118.257 97.3317C118.257 95.6529 116.799 94.292 115 94.292C113.201 94.292 111.743 95.6523 111.743 97.3312Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <ellipse cx="71" cy="113.252" rx="23.5" ry="1" fill="#B2B3B5" />
      <defs>
        <linearGradient
          id="paint0_linear_4146_9395"
          x1="84"
          y1="41.7515"
          x2="56"
          y2="47.2515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68A439" stopOpacity="0.7" />
          <stop offset="1" stopColor="#68A439" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4146_9395"
          x1="82"
          y1="86.2512"
          x2="54.5"
          y2="100.251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68A439" stopOpacity="0.7" />
          <stop offset="1" stopColor="#68A439" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_4146_9395">
          <rect
            width="135"
            height="118.77"
            fill="white"
            transform="translate(0.5 0.251953)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { TelephoneAuthenticationIcon };
