import { IconProps } from "typing";

const DeviceCheck = ({
  alt = "E-mail confirmado ícone",
  width = 101,
  height = 100,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50.5" cy="50" r="50" fill="#F6F7F7" />
      <path
        d="M75.1351 63.3117L78.6193 57.2707"
        stroke="#B2B3B5"
        strokeWidth="1.09164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.0051 62.0832L75.135 63.3117"
        stroke="#B2B3B5"
        strokeWidth="1.09164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.4167 59.635L76.5466 60.8635"
        stroke="#B2B3B5"
        strokeWidth="1.09164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.7987 57.5566C79.2667 57.9679 77.6913 57.0593 77.2801 55.5273C76.8689 53.9952 77.7774 52.4199 79.3095 52.0087C80.8415 51.5974 82.4168 52.506 82.828 54.038C83.2393 55.5701 82.3307 57.1454 80.7987 57.5566"
        stroke="#B2B3B5"
        strokeWidth="1.09164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.9912 64.8522L92.3544 61.5644"
        stroke="#C3C4C5"
        strokeWidth="0.659714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.8323 65.7819L94.9915 64.8522"
        stroke="#C3C4C5"
        strokeWidth="0.659714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.7634 64.4494L93.9226 63.5198"
        stroke="#C3C4C5"
        strokeWidth="0.659714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.9938 60.4C92.8891 61.3529 92.0318 62.0404 91.0789 61.9357C90.126 61.831 89.4384 60.9736 89.5431 60.0208C89.6478 59.0679 90.5052 58.3803 91.4581 58.485C92.411 58.5897 93.0986 59.4471 92.9938 60.4"
        stroke="#C3C4C5"
        strokeWidth="0.659714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.5514 32.9733L67.877 27.7981"
        stroke="#C3C4C5"
        strokeWidth="1.09164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.727 34.6215L72.5517 32.9734"
        stroke="#C3C4C5"
        strokeWidth="1.09164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.8325 32.5241L70.6572 30.876"
        stroke="#C3C4C5"
        strokeWidth="1.09164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.8204 25.8126C68.7399 27.3968 67.3903 28.6157 65.8061 28.5352C64.2219 28.4546 63.003 27.1051 63.0835 25.5209C63.164 23.9367 64.5136 22.7177 66.0978 22.7983C67.682 22.8788 68.901 24.2284 68.8204 25.8126"
        stroke="#C3C4C5"
        strokeWidth="1.09164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4129 60.6641L24.2983 55.6612"
        stroke="#B2B3B5"
        strokeWidth="0.904039"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.649 59.6467L21.4129 60.6641"
        stroke="#B2B3B5"
        strokeWidth="0.904039"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8184 57.6193L22.5823 58.6366"
        stroke="#B2B3B5"
        strokeWidth="0.904039"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1037 55.898C24.8349 56.2386 23.5303 55.4862 23.1898 54.2174C22.8492 52.9487 23.6016 51.6441 24.8704 51.3035C26.1391 50.9629 27.4437 51.7154 27.7843 52.9841C28.1248 54.2529 27.3724 55.5575 26.1037 55.898"
        stroke="#B2B3B5"
        strokeWidth="0.904039"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.8039 29.5397L85.5592 26.4963"
        stroke="#C3C4C5"
        strokeWidth="0.549963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.7306 28.9208L83.8037 29.5397"
        stroke="#C3C4C5"
        strokeWidth="0.549963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.442 27.6874L84.5151 28.3063"
        stroke="#C3C4C5"
        strokeWidth="0.549963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.657 26.6403C85.8852 26.8475 85.0916 26.3898 84.8844 25.6179C84.6772 24.8461 85.135 24.0525 85.9068 23.8453C86.6786 23.6381 87.4723 24.0958 87.6794 24.8677C87.8866 25.6395 87.4289 26.4331 86.657 26.6403"
        stroke="#C3C4C5"
        strokeWidth="0.549963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.3849 15.5862L61.1403 12.5427"
        stroke="#C3C4C5"
        strokeWidth="0.549963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.3121 14.9673L59.3852 15.5862"
        stroke="#C3C4C5"
        strokeWidth="0.549963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.0231 13.7339L60.0961 14.3528"
        stroke="#C3C4C5"
        strokeWidth="0.549963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.2381 12.6868C61.4663 12.894 60.6726 12.4363 60.4655 11.6644C60.2583 10.8926 60.716 10.099 61.4878 9.89179C62.2597 9.68461 63.0533 10.1423 63.2605 10.9142C63.4677 11.686 63.0099 12.4796 62.2381 12.6868"
        stroke="#C3C4C5"
        strokeWidth="0.549963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.081 21.4115L32.7352 15.0757"
        stroke="#B2B3B5"
        strokeWidth="1.1449"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8471 20.1231L29.081 21.4115"
        stroke="#B2B3B5"
        strokeWidth="1.1449"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3281 17.5554L30.562 18.8438"
        stroke="#B2B3B5"
        strokeWidth="1.1449"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.021 15.3757C33.4143 15.807 31.7621 14.8541 31.3308 13.2473C30.8995 11.6405 31.8524 9.98834 33.4592 9.55704C35.0659 9.12573 36.7181 10.0786 37.1494 11.6854C37.5807 13.2922 36.6278 14.9444 35.021 15.3757"
        stroke="#B2B3B5"
        strokeWidth="1.1449"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5102 37.3623L16.3723 38.7783C15.8046 38.9348 15.218 38.6017 15.0615 38.034L14.0703 34.4374C13.9138 33.8697 14.247 33.2831 14.8147 33.1266L19.9526 31.7106C20.5203 31.5541 21.1069 31.8872 21.2634 32.455L22.2546 36.0515C22.4111 36.6192 22.078 37.2058 21.5102 37.3623Z"
        stroke="#C3C4C5"
        strokeWidth="0.823401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3286 32.985L14.9038 31.4436V31.4436C14.591 30.3087 15.2576 29.1349 16.3926 28.8221V28.8221C17.5275 28.5093 18.7013 29.1759 19.0141 30.3108V30.3108L19.4389 31.8522"
        stroke="#C3C4C5"
        strokeWidth="0.823401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.8394 79.5001L76.5455 76.3433C76.071 75.9945 75.9693 75.3277 76.3181 74.8532L78.5278 71.8474C78.8766 71.373 79.5435 71.2712 80.018 71.62L84.3119 74.7768C84.7864 75.1256 84.8882 75.7924 84.5393 76.2669L82.3296 79.2727C81.9808 79.7472 81.3139 79.8489 80.8394 79.5001Z"
        stroke="#C3C4C5"
        strokeWidth="0.823401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.4475 71.9357L81.3945 70.6475V70.6475C82.0918 69.699 83.4263 69.4953 84.3748 70.1926V70.1926C85.3233 70.89 85.527 72.2244 84.8297 73.1729V73.1729L83.8826 74.4611"
        stroke="#C3C4C5"
        strokeWidth="0.823401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.2778 45.5018L86.1399 46.9179C85.5722 47.0743 84.9856 46.7412 84.8291 46.1735L83.8379 42.577C83.6814 42.0092 84.0145 41.4226 84.5823 41.2662L89.7202 39.8501C90.2879 39.6937 90.8745 40.0268 91.0309 40.5945L92.0222 44.191C92.1787 44.7588 91.8455 45.3453 91.2778 45.5018Z"
        stroke="#B2B3B5"
        strokeWidth="0.823401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.0962 41.1245L84.6714 39.5832V39.5832C84.3586 38.4482 85.0252 37.2744 86.1601 36.9616V36.9616C87.2951 36.6488 88.4689 37.3154 88.7817 38.4503V38.4503L89.2065 39.9917"
        stroke="#B2B3B5"
        strokeWidth="0.823401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7189 45.8676L75.4149 48.3247C75.0499 48.5963 74.5342 48.5205 74.2627 48.1554L72.5427 45.8427C72.2712 45.4776 72.347 44.962 72.7121 44.6904L76.016 42.2333C76.381 41.9618 76.8967 42.0375 77.1682 42.4026L78.8882 44.7154C79.1597 45.0804 79.0839 45.5961 78.7189 45.8676Z"
        stroke="#C3C4C5"
        strokeWidth="0.636149"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.0423 44.4447L72.3051 43.4535V43.4535C71.7623 42.7237 71.914 41.6919 72.6438 41.1491V41.1491C73.3737 40.6063 74.4055 40.7579 74.9483 41.4878V41.4878L75.6854 42.479"
        stroke="#C3C4C5"
        strokeWidth="0.636149"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9212 53.1353L5.524 52.0676C5.14861 51.9496 4.94012 51.55 5.0581 51.1746L5.8055 48.7966C5.92348 48.4212 6.32309 48.2127 6.69848 48.3307L10.0957 49.3984C10.4711 49.5164 10.6796 49.916 10.5616 50.2914L9.81418 52.6694C9.6962 53.0448 9.29659 53.2533 8.9212 53.1353Z"
        stroke="#B2B3B5"
        strokeWidth="0.55018"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.03825 48.4374L7.35856 47.4183V47.4183C7.59442 46.6678 8.39408 46.2506 9.14452 46.4865V46.4865C9.89497 46.7223 10.3122 47.522 10.0763 48.2724V48.2724L9.75601 49.2916"
        stroke="#B2B3B5"
        strokeWidth="0.55018"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4347 44.5324L27.869 45.6358C27.5855 45.7577 27.2571 45.6268 27.1351 45.3433L26.3628 43.5473C26.2408 43.2638 26.3717 42.9354 26.6552 42.8134L29.221 41.71C29.5045 41.5881 29.8329 41.719 29.9548 42.0025L30.7272 43.7985C30.8491 44.082 30.7182 44.4104 30.4347 44.5324Z"
        stroke="#C3C4C5"
        strokeWidth="0.431508"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9115 42.7031L26.5805 41.9334V41.9334C26.3368 41.3666 26.5987 40.7095 27.1655 40.4657V40.4657C27.7322 40.222 28.3894 40.4839 28.6331 41.0507V41.0507L28.9641 41.8204"
        stroke="#C3C4C5"
        strokeWidth="0.431508"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.964 19.1785L68.67 17.2148C68.1955 16.9979 67.9869 16.4378 68.2039 15.9633L69.5785 12.9575C69.7954 12.483 70.3555 12.2744 70.83 12.4914L75.124 14.4551C75.5985 14.6721 75.8071 15.2321 75.5901 15.7066L74.2155 18.7124C73.9986 19.1869 73.4385 19.3955 72.964 19.1785Z"
        stroke="#B2B3B5"
        strokeWidth="0.729504"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.2592 12.6878L71.8483 11.3996V11.3996C72.2821 10.451 73.4028 10.0336 74.3514 10.4674V10.4674C75.2999 10.9012 75.7173 12.0219 75.2835 12.9705V12.9705L74.6945 14.2587"
        stroke="#B2B3B5"
        strokeWidth="0.729504"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6738 78.0598L17.5359 79.4758C16.9682 79.6323 16.3816 79.2992 16.2251 78.7315L15.2339 75.1349C15.0774 74.5672 15.4105 73.9806 15.9783 73.8242L21.1162 72.4081C21.6839 72.2516 22.2705 72.5847 22.4269 73.1525L23.4182 76.749C23.5746 77.3167 23.2415 77.9033 22.6738 78.0598Z"
        stroke="#B2B3B5"
        strokeWidth="0.823401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4922 73.6825L16.0674 72.1412V72.1412C15.7546 71.0062 16.4212 69.8324 17.5561 69.5196V69.5196C18.6911 69.2068 19.8649 69.8734 20.1777 71.0083V71.0083L20.6025 72.5497"
        stroke="#B2B3B5"
        strokeWidth="0.823401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="49.9185"
        cy="87.3256"
        rx="19.186"
        ry="1.27907"
        fill="#C3C4C5"
      />
      <path
        d="M45.8486 20.9619V18.0549C45.8486 15.9144 47.5841 14.179 49.7246 14.179C51.8651 14.179 53.6006 15.9144 53.6006 18.0549V20.9619"
        stroke="#53565A"
        strokeWidth="1.49709"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="33.3977"
        y="21.2699"
        width="33.0417"
        height="59.7859"
        rx="2.3773"
        fill="#53565A"
        stroke="#53565A"
        strokeWidth="0.679229"
      />
      <rect
        x="34.5189"
        y="23.5541"
        width="28.4733"
        height="54.0547"
        rx="2.08753"
        stroke="#7AC143"
        strokeWidth="0.596436"
      />
      <path
        d="M44.686 23.2559H53.1256C52.6084 24.2903 51.5511 24.9438 50.3945 24.9438H47.4171C46.2606 24.9438 45.2033 24.2903 44.686 23.2559Z"
        fill="#7AC143"
      />
      <rect
        x="34.5189"
        y="22.3913"
        width="30.7989"
        height="57.5431"
        rx="2.08753"
        fill="#F6F7F7"
        stroke="#53565A"
        strokeWidth="0.596436"
      />
      <path
        d="M48.0264 23.7636C47.4654 23.7636 46.932 23.5939 46.4858 23.2939H52.2885C51.8423 23.5939 51.3088 23.7636 50.7479 23.7636H48.0264Z"
        fill="#53565A"
        stroke="#53565A"
        strokeWidth="1.73856"
      />
      <ellipse
        cx="49.3372"
        cy="51.1629"
        rx="10.4651"
        ry="10.4651"
        fill="#68A439"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3372 61.6279V61.6279C43.557 61.6279 38.8721 56.943 38.8721 51.1628V51.1628C38.8721 45.3826 43.557 40.6977 49.3372 40.6977V40.6977C55.1174 40.6977 59.8023 45.3826 59.8023 51.1628V51.1628C59.8023 56.943 55.1174 61.6279 49.3372 61.6279Z"
        stroke="#F6F7F7"
        strokeWidth="1.79651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.9884 48.8372L48.1744 54.6512L44.686 51.1628"
        stroke="#F6F7F7"
        strokeWidth="1.79651"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { DeviceCheck };
