import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const FurnitureGiftSmallIcon = ({
  width = 197,
  height = 210,
  color = `${theme.colors.neutral["150"]}`,
  secondColor = `${theme.colors.neutral["white-075"]}`,
  thirdColor = `${theme.colors.primary["900"]}`,
  fourthColor = `${theme.colors.primary["180"]}`,
  fifthColor = `${theme.colors.secondary["410"]}`,
  sixthColor = `${theme.colors.yellow["1100"]}`,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 197 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M66 2H-1V69H66V2Z" fill={secondColor} />
      <path
        d="M25.8881 46.6882C26.6316 46.6882 27.2352 47.2918 27.2352 48.0353L27.2352 55.418L29.5095 57.22L33.0259 57.22L35.3002 55.418L35.3002 48.0353C35.3002 47.2918 35.9038 46.6882 36.6473 46.6882C37.3908 46.6882 37.9944 47.2918 37.9944 48.0353L37.9944 56.0741C37.9944 56.4852 37.8019 56.8788 37.487 57.1325L34.338 59.6342C34.1018 59.8267 33.8044 59.9316 33.4983 59.9316L29.0371 59.9316C28.731 59.9316 28.4336 59.8267 28.1974 59.6342L25.0484 57.1325C24.7247 56.8788 24.541 56.4852 24.541 56.0741L24.541 48.0353C24.541 47.2918 25.1446 46.6882 25.8881 46.6882Z"
        fill={color}
      />
      <path
        d="M25.8881 51.4855L36.6473 51.4855C37.3908 51.4855 37.9944 52.0891 37.9944 52.8326C37.9944 53.5761 37.3908 54.1797 36.6473 54.1797L25.8881 54.1797C25.1446 54.1797 24.541 53.5761 24.541 52.8326C24.541 52.0891 25.1446 51.4855 25.8881 51.4855Z"
        fill={color}
      />
      <path
        d="M31.2675 8.69815C41.6681 8.69815 50.1267 16.9294 50.1267 27.0413C50.1267 30.7676 48.9808 34.3453 46.829 37.4068C44.9046 40.1272 42.2891 42.2791 39.2276 43.6699L39.2276 46.4428C39.2276 48.0698 37.8805 49.3906 36.2272 49.3906L26.3078 49.3906C24.6546 49.3906 23.3075 48.0698 23.3075 46.4428L23.3075 43.6699C20.2459 42.2878 17.6305 40.136 15.7061 37.4068C13.5455 34.354 12.3996 30.7676 12.3996 27.0413C12.3996 16.9294 20.8582 8.69815 31.2588 8.69815M36.2185 46.6877C36.3759 46.6877 36.5159 46.574 36.5159 46.4428L36.5159 42.7689C36.5159 42.2178 36.857 41.7192 37.3731 41.5093C43.4788 39.095 47.4238 33.4093 47.4238 27.0325C47.4238 18.4077 40.1723 11.3923 31.2675 11.3923C22.3628 11.3923 15.1112 18.4077 15.1112 27.0325C15.1112 33.4093 19.0563 39.0863 25.1619 41.5093C25.678 41.7105 26.0104 42.2091 26.0104 42.7689L26.0104 46.4428C26.0104 46.574 26.1503 46.6877 26.3078 46.6877L36.2272 46.6877L36.2185 46.6877Z"
        fill={color}
      />
      <path d="M130.5 71H63.5V138H130.5V71Z" fill={thirdColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.2357 118.385H92.0357V122.178H75.2357V118.385ZM89.0428 123.436H77.5571V126.843H89.0428V123.436ZM95.6142 96.44H98.2714V112.827H95.6142V96.44ZM118.757 90.6108H101.957V86.8176H118.757V90.6108ZM104.95 85.5603H116.436V82.1528H104.95V85.5603ZM115.679 91.8681H104.936C104.386 94.7184 103.257 97.3401 100.186 98.0259C100.214 101.805 100.236 105.591 100.264 109.37C109.864 107.791 114.886 101.276 115.679 91.8681ZM93.7642 110.984V104.648L93.7285 99.6261C84.1285 101.205 79.1071 107.72 78.3142 117.128H89.0571C89.6071 114.285 90.7214 111.685 93.7642 110.977V110.984ZM75.0357 117.135H76.8428C77.6714 106.905 83.2785 99.7547 93.7642 98.1402V95.3899C93.7642 94.9399 94.1357 94.5684 94.5857 94.5684H99.0571C99.5071 94.5684 99.8785 94.9399 99.8785 95.3899V96.59C102.186 96.0471 103.007 94.0112 103.443 91.8681H101.764C101.179 91.8681 100.707 91.3895 100.707 90.8109V86.6247C100.707 86.0389 101.186 85.5674 101.764 85.5674H103.821V81.717C103.821 81.3384 104.129 81.0312 104.507 81.0312H116.879C117.257 81.0312 117.564 81.3384 117.564 81.717V85.5674H118.957C119.543 85.5674 120.014 86.0461 120.014 86.6247V90.8109C120.014 91.3966 119.536 91.8681 118.957 91.8681H117.15C116.314 102.212 110.593 109.399 99.8857 110.913V113.892C99.8857 114.342 99.5142 114.713 99.0642 114.713H94.5928C94.1428 114.713 93.7714 114.342 93.7714 113.892V112.506C91.7285 113.163 90.9642 115.099 90.5499 117.135H92.2285C92.8142 117.135 93.2857 117.614 93.2857 118.192V122.379C93.2857 122.964 92.8071 123.436 92.2285 123.436H90.1714V127.286C90.1714 127.665 89.8642 127.972 89.4857 127.972H77.1142C76.7357 127.972 76.4285 127.665 76.4285 127.286V123.436H75.0357C74.4499 123.436 73.9785 122.957 73.9785 122.379V118.192C73.9785 117.607 74.4571 117.135 75.0357 117.135Z"
        fill={fourthColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.6715 83.3514C72.7643 93.0953 75.3572 97.8244 79.7572 97.8244C84.1572 97.8244 86.6215 92.9024 79.6715 83.3514ZM80.6857 82.6156C82.6286 85.2873 84.7 88.7591 85.1643 92.088C85.4714 94.2883 85.05 96.61 83.1786 98.0172C82.1857 98.7602 80.9786 99.0817 79.75 99.0817C78.5214 99.0817 77.2 98.7459 76.1714 97.953C71.4143 94.274 76.1429 86.1588 78.6429 82.6299L79.6572 81.2012L80.6857 82.6156Z"
        fill={fourthColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9782 95.0066C80.8496 94.6923 81.3282 94.1351 81.5711 93.2492C81.8425 92.2706 83.2139 92.6492 82.9425 93.6279C82.5711 94.9637 81.7639 95.871 80.4568 96.3424C79.4996 96.6925 79.0139 95.3495 79.9711 95.0066"
        fill={fourthColor}
      />
      <path d="M66 142H-1V209H66V142Z" fill={fifthColor} />
      <path
        d="M27.3404 195.87H13.4672C12.4962 195.87 11.709 195.083 11.709 194.112V170.232C11.709 169.488 12.3125 168.885 13.0561 168.885H26.938C27.6816 168.885 28.2851 169.488 28.2851 170.232V193.543C28.5388 193.788 28.6963 194.129 28.6963 194.514C28.6963 195.258 28.0927 195.87 27.3492 195.87M14.4119 193.167H25.591V171.588H14.4119V193.167Z"
        fill={sixthColor}
      />
      <path
        d="M49.9703 195.87H36.0971C35.3536 195.87 34.75 195.267 34.75 194.514C34.75 194.138 34.9074 193.797 35.1611 193.543V170.232C35.1611 169.488 35.7647 168.885 36.5082 168.885H50.3814C51.125 168.885 51.7285 169.488 51.7285 170.232V194.112C51.7285 195.083 50.9413 195.87 49.9703 195.87ZM37.8553 193.167H49.0343V171.588H37.8553V193.167Z"
        fill={sixthColor}
      />
      <path
        d="M52.6292 171.588H36.4992C35.7557 171.588 35.1521 170.984 35.1521 170.241V161.642C34.8984 161.397 34.741 161.056 34.741 160.671C34.741 159.928 35.3445 159.324 36.0881 159.324H52.6292C53.6002 159.324 54.3874 160.111 54.3874 161.082V169.83C54.3874 170.801 53.6002 171.588 52.6292 171.588ZM37.855 168.885H51.6932V162.018H37.855V168.885Z"
        fill={sixthColor}
      />
      <path
        d="M26.9383 171.588H10.8083C9.83731 171.588 9.05005 170.801 9.05005 169.83V161.082C9.05005 160.111 9.83731 159.324 10.8083 159.324H27.3494C28.0929 159.324 28.6965 159.928 28.6965 160.671C28.6965 161.047 28.5391 161.397 28.2854 161.642V170.241C28.2854 170.984 27.6818 171.588 26.9383 171.588ZM11.753 168.885H25.5912V162.018H11.753V168.885Z"
        fill={sixthColor}
      />
      <path
        d="M32.1607 162.018H21.6289C18.4361 162.018 15.2959 159.123 15.2871 156.175C15.2871 155.055 15.5845 153.052 17.5614 152.309C19.4858 151.583 22.4512 152.379 26.3874 154.679C29.4752 156.481 32.1869 158.651 33.1754 159.779C33.5252 160.181 33.604 160.741 33.3853 161.231C33.1666 161.712 32.6855 162.027 32.1519 162.027M19.1184 154.758C18.8648 154.758 18.6636 154.793 18.5061 154.846C18.3662 154.898 17.9813 155.047 17.9813 156.184C17.9813 157.653 19.9232 159.324 21.6202 159.324H28.5043C27.5158 158.589 26.3175 157.785 25.0229 157.024C22.145 155.344 20.1943 154.758 19.1184 154.758Z"
        fill={sixthColor}
      />
      <path
        d="M41.8001 162.017H31.2684C30.7435 162.017 30.2625 161.711 30.035 161.221C29.8163 160.74 29.8951 160.172 30.245 159.769C31.2247 158.641 33.9451 156.471 37.0329 154.678C40.9692 152.386 43.9345 151.582 45.8589 152.308C47.8358 153.051 48.1332 155.046 48.1332 156.174C48.1332 159.122 44.9929 162.017 41.7914 162.017M34.9073 159.314H41.7914C43.4884 159.314 45.4303 157.644 45.4303 156.174C45.4303 155.037 45.0541 154.897 44.9054 154.844C44.0832 154.538 41.9401 154.949 38.3887 157.022C37.0853 157.783 35.8957 158.588 34.9073 159.323V159.314Z"
        fill={sixthColor}
      />
      <path
        d="M36.053 195.873H27.3845C26.396 195.873 25.5825 195.069 25.5825 194.071V161.12C25.5825 160.123 26.3873 159.318 27.3845 159.318H36.053C37.0415 159.318 37.855 160.123 37.855 161.12V194.071C37.855 195.06 37.0502 195.873 36.053 195.873ZM28.2854 193.17H35.1521V162.021H28.2854V193.17Z"
        fill={sixthColor}
      />
      <path d="M197 140H126V211H197V140Z" fill={secondColor} />
      <path
        d="M184.26 173.118H138.74V201.342H184.26V173.118ZM138.325 170.869H184.675C185.174 170.869 185.635 171.074 185.968 171.407C186.3 171.74 186.504 172.202 186.504 172.701V201.751C186.504 202.251 186.3 202.713 185.968 203.046C185.635 203.379 185.182 203.591 184.675 203.591H138.325C137.826 203.591 137.365 203.386 137.032 203.053C136.7 202.72 136.496 202.258 136.496 201.759V172.709C136.496 172.209 136.7 171.747 137.032 171.414C137.365 171.081 137.818 170.869 138.325 170.869Z"
        fill={color}
      />
      <path
        d="M185.887 173.957H138.348V176.427H185.887V173.957Z"
        fill={color}
      />
      <path
        d="M184.26 149.681H138.74V162.438H184.26V149.681ZM138.325 147.416H184.675C185.174 147.416 185.635 147.622 185.968 147.957C186.3 148.3 186.504 148.758 186.504 149.261V162.85C186.504 163.353 186.3 163.818 185.968 164.154C185.635 164.489 185.182 164.695 184.675 164.695H138.325C137.826 164.695 137.365 164.489 137.032 164.154C136.7 163.811 136.496 163.353 136.496 162.85V149.261C136.496 148.758 136.7 148.293 137.032 147.957C137.365 147.622 137.818 147.408 138.325 147.408V147.416Z"
        fill={color}
      />
      <path
        d="M155.017 148.027H152.548V163.462H155.017V148.027Z"
        fill={color}
      />
      <path d="M171.07 148.027H168.6V163.462H171.07V148.027Z" fill={color} />
      <path
        d="M149.461 154.611C149.461 154.045 149.877 153.582 150.387 153.582C150.896 153.582 151.313 154.045 151.313 154.611V156.875C151.313 157.441 150.896 157.904 150.387 157.904C149.877 157.904 149.461 157.441 149.461 156.875V154.611Z"
        fill={color}
      />
      <path
        d="M165.513 154.611C165.513 154.045 165.929 153.582 166.439 153.582C166.949 153.582 167.365 154.045 167.365 154.611V156.875C167.365 157.441 166.949 157.904 166.439 157.904C165.929 157.904 165.513 157.441 165.513 156.875V154.611Z"
        fill={color}
      />
      <path
        d="M181.565 154.611C181.565 154.045 181.982 153.582 182.491 153.582C183.001 153.582 183.417 154.045 183.417 154.611V156.875C183.417 157.441 183.001 157.904 182.491 157.904C181.982 157.904 181.565 157.441 181.565 156.875V154.611Z"
        fill={color}
      />
      <path
        d="M155.017 187.951C155.017 187.384 155.573 186.922 156.252 186.922C156.932 186.922 157.487 187.384 157.487 187.951V190.215C157.487 190.781 156.932 191.244 156.252 191.244C155.573 191.244 155.017 190.781 155.017 190.215V187.951Z"
        fill={color}
      />
      <path
        d="M166.13 187.951C166.13 187.384 166.547 186.922 167.056 186.922C167.566 186.922 167.983 187.384 167.983 187.951V190.215C167.983 190.781 167.566 191.244 167.056 191.244C166.547 191.244 166.13 190.781 166.13 190.215V187.951Z"
        fill={color}
      />
      <path
        d="M163.044 175.191H160.574V202.357H163.044V175.191Z"
        fill={color}
      />
    </svg>
  );
};
