import { IconProps } from "typing";
import { theme } from "../../themes";

export const EmailAuthenticationIcon = ({
  width = theme.space.x15,
  height = theme.space.x15,
  strokeWidth = "1.5",
  alt = "Autenticação por E-mail",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="60" height="60" rx="30" fill="#F6F7F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5005 23.0675V23.0675C18.5005 23.8675 18.9005 24.5341 19.5672 25.0675L27.5672 30.5341C29.4338 31.7341 31.7005 31.7341 33.5672 30.5341L41.5672 25.2008C42.1005 24.5341 42.5005 23.8675 42.5005 23.0675V23.0675C42.5005 21.7341 41.4338 20.6675 40.1005 20.6675H20.9005C19.5672 20.6675 18.5005 21.7341 18.5005 23.0675Z"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5005 23.334V36.6673C18.5005 38.134 19.7005 39.334 21.1672 39.334H39.8338C41.3005 39.334 42.5005 38.134 42.5005 36.6673V23.334"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2847 38.5501L27.41 30.4248"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.647 30.481L41.7163 38.5503"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
