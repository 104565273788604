import { IconProps } from "typing";
import { theme } from "../themes";

export const NewGiftIcon = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["760"],
  alt = "caixa de presente",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 9.16732H16.25C16.9404 9.16732 17.5 8.60767 17.5 7.91732V7.08398C17.5 6.39363 16.9404 5.83398 16.25 5.83398H3.75C3.05964 5.83398 2.5 6.39363 2.5 7.08398V7.91732C2.5 8.60767 3.05964 9.16732 3.75 9.16732H8.33333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6668 9.16602V15.8327C16.6668 16.7532 15.9206 17.4993 15.0002 17.4993H5.00016C4.07969 17.4993 3.3335 16.7532 3.3335 15.8327V9.16602"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33317 2.5V2.5C9.25365 2.5 9.99984 3.24619 9.99984 4.16667V5.83333H8.33317C7.4127 5.83333 6.6665 5.08714 6.6665 4.16667V4.16667C6.6665 3.24619 7.4127 2.5 8.33317 2.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 2.5V2.5C12.5871 2.5 13.3333 3.24619 13.3333 4.16667V4.16667C13.3333 5.08714 12.5871 5.83333 11.6667 5.83333H10V4.16667C10 3.24619 10.7462 2.5 11.6667 2.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="8.3335"
        y="5.83398"
        width="3.33333"
        height="11.6667"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
