import styled, { css } from "styled-components";
import { FAQThemeDto } from "typing";
import { Accordion, theme } from "ui";

type BlackFridayTheme = {
  faqBlackTheme?: FAQThemeDto;
};

export const CustomFAQSubComponentContainer = styled.section<BlackFridayTheme>`
  margin: 1.3rem 0;
  border: ${({ faqBlackTheme }) =>
    faqBlackTheme
      ? `1px solid ${theme.colors.blackFriday["75"]}`
      : `1px solid ${theme.colors.neutral["100"]}`};
  border-radius: 4px;

  ${({ faqBlackTheme }) =>
    faqBlackTheme &&
    css`
      background-color: ${theme.colors.blackFriday["125"]};
    `}
`;

export const AccordionButton = styled.button<BlackFridayTheme>`
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: ${({ faqBlackTheme }) =>
    faqBlackTheme
      ? theme.colors.blackFriday["125"]
      : `${theme.colors.neutral.white}`};
  width: 100%;
  height: 64px;
  cursor: pointer;
  border-radius: ${theme.space.x1};
  padding: ${theme.space["x5.5"]} ${theme.space.x6} ${theme.space.x0};
  transition: all 0.2ms ease-in-out;
  -webkit-tap-highlight-color: transparent;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: ${theme.space.x4} ${theme.space.x4} ${theme.space.x0};
  }
`;

export const AccordionContent = styled(Accordion)<BlackFridayTheme>`
  background-color: ${({ faqBlackTheme }) =>
    faqBlackTheme
      ? theme.colors.blackFriday["125"]
      : `${theme.colors.neutral.white}`};
  padding: 0 2rem;
`;

export const Question = styled.div<BlackFridayTheme>`
  font-size: ${theme.typography.fontSize.sm.x9};
  font-weight: ${theme.typography.fontWeight["500"]};
  text-align: start;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x7};
  }

  ${({ faqBlackTheme }) =>
    faqBlackTheme &&
    css`
      color: ${theme.colors.blackFriday.white};
    `}
`;

export const AccordionContentText = styled.h2<BlackFridayTheme>`
  padding: 1.5rem 0;
  border-top: 1px solid ${theme.colors.neutral["100"]};
  color: ${({ faqBlackTheme }) =>
    faqBlackTheme
      ? `${theme.colors.neutral["150"]}`
      : `${theme.colors.neutral["550"]}`};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-size: ${theme.typography.fontSize.sm.x7};

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const ArrowContainer = styled.div`
  margin-top: -4px;
`;
