import * as zod from "zod";
import { ReceivedProps } from "typing/types";
import {
  validateBirthdate,
  validateCpf,
  validateEmail,
  validateName,
  validatePassword,
  validatePhoneNumber,
} from "application/utils/validations";

export type RegisterClientInfo = {
  name: string;
  cpf: string;
  birthdate: string;
  phoneNumber: string;
  email: string;
  password: string;
  acceptedTermsUse: boolean;
  trackerOrderByWhatsAppState: boolean;
};

export const registerFormValidateSchema = zod.object({
  name: zod
    .string()
    .superRefine((value: string, context: zod.RefinementCtx) => {
      const response = validateName(value);
      const received = response as unknown as ReceivedProps;

      if (response)
        context.addIssue({
          code: "invalid_type",
          expected: "string",
          received,
          message: `${response}`,
        });
    }),
  cpf: zod.string().superRefine((value: string, context: zod.RefinementCtx) => {
    const response = validateCpf(value);
    const received = response as unknown as ReceivedProps;

    if (response)
      context.addIssue({
        code: "invalid_type",
        expected: "string",
        received,
        message: `${response}`,
      });
  }),
  birthdate: zod
    .string()
    .superRefine((value: string, context: zod.RefinementCtx) => {
      const response = validateBirthdate(value);
      const received = response as unknown as ReceivedProps;

      if (response)
        context.addIssue({
          code: "invalid_type",
          expected: "string",
          received,
          message: `${response}`,
        });
    }),
  phoneNumber: zod
    .string()
    .superRefine((value: string, context: zod.RefinementCtx) => {
      const response = validatePhoneNumber(value);
      const received = response as unknown as ReceivedProps;

      if (response) {
        context.addIssue({
          code: "invalid_type",
          expected: "string",
          received,
          message: `${response}`,
        });
      }
    }),
  email: zod
    .string()
    .superRefine((value: string, context: zod.RefinementCtx) => {
      const response = validateEmail(value);
      const received = response as unknown as ReceivedProps;

      if (response) {
        context.addIssue({
          code: "invalid_type",
          expected: "string",
          received,
          message: `${response}`,
        });
      }
    }),
  password: zod
    .string()
    .superRefine((value: string, context: zod.RefinementCtx) => {
      const response = validatePassword(value);
      const received = response as unknown as ReceivedProps;

      if (response) {
        context.addIssue({
          code: "invalid_type",
          expected: "string",
          received,
          message: `${response}`,
        });
      }
    }),
  confirmPassword: zod
    .string()
    .superRefine((value: string, context: zod.RefinementCtx) => {
      const response = validatePassword(value);
      const received = response as unknown as ReceivedProps;

      if (response) {
        context.addIssue({
          code: "invalid_type",
          expected: "string",
          received,
          message: `${response}`,
        });
      }
    }),
  trackerOrderByWhatsApp: zod.boolean().optional(),
  acceptedTermsUse: zod.literal(true, {
    required_error: "É necessário aceitar os termos de uso e privacidade",
  }),
});

export type RegisterFormProps = zod.infer<typeof registerFormValidateSchema>;
