import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ArrowUp = ({
  alt = "Seta para cima",
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M14 12.0607L8.53033 17.5303C8.23744 17.8232 7.76256 17.8232 7.46967 17.5303C7.17678 17.2374 7.17678 16.7626 7.46967 16.4697L13.4697 10.4697C13.7626 10.1768 14.2374 10.1768 14.5303 10.4697L20.5303 16.4697C20.8232 16.7626 20.8232 17.2374 20.5303 17.5303C20.2374 17.8232 19.7626 17.8232 19.4697 17.5303L14 12.0607Z"
        fill={color}
      />
      <mask
        id="mask0_0_2440"
        maskUnits="userSpaceOnUse"
        x="7"
        y="10"
        width="14"
        height="8"
      >
        <path
          d="M14 12.0607L8.53033 17.5303C8.23744 17.8232 7.76256 17.8232 7.46967 17.5303C7.17678 17.2374 7.17678 16.7626 7.46967 16.4697L13.4697 10.4697C13.7626 10.1768 14.2374 10.1768 14.5303 10.4697L20.5303 16.4697C20.8232 16.7626 20.8232 17.2374 20.5303 17.5303C20.2374 17.8232 19.7626 17.8232 19.4697 17.5303L14 12.0607Z"
          fill={secondColor}
        />
      </mask>
      <g mask="url(#mask0_0_2440)" />
    </svg>
  );
};
