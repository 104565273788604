import styled from "styled-components";
import { theme } from "ui";
import { Image } from "../../../../../../../Image";

export const SuggestedCardContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  background: ${theme.colors.neutral.white};
  margin-bottom: 0.15rem;
  box-shadow: 0 2px 2px 0 ${theme.colors.neutral["999-opacity-025"]};
  border-radius: 2px;
  cursor: pointer;
  color: ${theme.colors.neutral["520"]};
`;

export const SuggestedCardImageBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border-right: 2px solid ${theme.colors.neutral["50"]};
`;

export const SuggestedCardImage = styled(Image)`
  margin-right: 0 !important;
`;

export const SuggestedCardInfo = styled.div`
  box-flex: 1;
  display: inline-flex;
  box-orient: vertical;
  box-direction: normal;
  flex-flow: column nowrap;
  box-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
`;

export const SuggestedCardDescription = styled.h4`
  white-space: normal;
`;

export const SuggestedCardPrice = styled.div`
  color: ${theme.colors.primary["200"]};
`;
