import { buildTestIds } from "application";
import {
  ResponsiveModalContentDescription,
  ResponsiveModalContentInformation,
  ResponsiveModalContentTitle,
} from "./styles";

export interface IResponsiveModalInformation {
  title?: string;
  description?: string;
}

const ResponsiveModalInformation = ({
  description,
  title,
}: IResponsiveModalInformation) => {
  return (
    <ResponsiveModalContentInformation
      {...buildTestIds("responsive-modal-content-information")}
    >
      {title && (
        <ResponsiveModalContentTitle
          {...buildTestIds("responsive-modal-content-title")}
        >
          {title}
        </ResponsiveModalContentTitle>
      )}
      {description && (
        <ResponsiveModalContentDescription
          {...buildTestIds("responsive-modal-content-description")}
        >
          {description}
        </ResponsiveModalContentDescription>
      )}
    </ResponsiveModalContentInformation>
  );
};

export { ResponsiveModalInformation };
