import styled, { css } from "styled-components";
import { ArrowButtonProperty } from "typing";
import { theme } from "ui";

type ButtonArrowPrevProps = {
  isCentralized: boolean;
  buttonArrowTheme?: ArrowButtonProperty;
  customTheme?: boolean;
  arrowButtonsSize?: "small" | "large";
};

export const ButtonArrowPrev = styled.div<ButtonArrowPrevProps>`
  height: ${({ arrowButtonsSize }) =>
    arrowButtonsSize === "small" ? "32px" : "60px"} !important;
  width: ${({ arrowButtonsSize }) =>
    arrowButtonsSize === "small" ? "32px" : "60px"} !important;

  top: ${({ isCentralized }) =>
    isCentralized ? "50%" : "calc(50% - 25px)"} !important;
  position: absolute !important;

  border-radius: 30px;
  padding: 0px 0px 0px 4px !important;
  background: ${({ customTheme }) =>
    customTheme
      ? theme?.colors?.blackFriday?.["50"]
      : theme.colors.neutral.white} !important;
  margin-left: 12px !important;
  z-index: 1;

  @media (max-width: 1270px) {
    margin-left: 25px;
  }

  &:hover {
    background: ${({ buttonArrowTheme }) =>
      buttonArrowTheme?.backgroundColor?.hover ||
      theme.colors.primary["200"]} !important;
    transition: 0.8s !important;
  }

  &:hover path {
    stroke: ${({ buttonArrowTheme }) =>
      buttonArrowTheme?.arrowColor?.hover || theme.colors.neutral.white};
  }

  svg {
    ${({ arrowButtonsSize }) =>
      arrowButtonsSize === "small" &&
      css`
        width: 10px !important;
      `}
    margin-top: ${({ arrowButtonsSize }) =>
      arrowButtonsSize === "small" ? "4px" : "17px"} !important;
    margin-left: ${({ arrowButtonsSize }) =>
      arrowButtonsSize === "small" ? "5px" : "18px"} !important;
  }

  box-shadow: ${`${theme.space.x0} ${theme.space["x0.5"]} ${theme.space["x0.5"]}
    ${theme.colors.neutral["999-opacity-25"]}`};

  ${({ customTheme }) => {
    return css`
      ${customTheme &&
      css`
        background: ${theme?.colors?.blackFriday?.["50"]};

        svg {
          ${customTheme &&
          css`
            path {
              stroke: ${theme?.colors?.blackFriday?.["20"]} !important;
            }
          `}
        }

        &:hover {
          background: ${theme?.colors?.primary?.["150"]};
          transition: 0.8s;

          svg {
            ${customTheme &&
            css`
              path {
                stroke: ${theme?.colors?.blackFriday?.white} !important;
              }
            `}
          }
        }
      `}
    `;
  }}
`;
