import { useAppContext } from "application";
import { profileApi } from "implementations";
import { SpaceWrapper, theme } from "ui";
import FormBody from "./Body";
import FormFooter from "./Footer";
import { FormBox, FormContainer } from "./styles";

const Form = () => {
  const { isClientTabletM, isClientMobile } = useAppContext();

  const basePadding = `${theme.space.x6}`;
  const tabletMPadding = `${theme.space.x4} ${theme.space["x13.5"]}`;

  const paddingMobile = () => {
    return isClientMobile && basePadding;
  };

  const paddingTabletM = () => {
    return isClientTabletM ? tabletMPadding : basePadding;
  };

  const padding = paddingMobile() || paddingTabletM();

  return (
    <FormContainer data-cy="form-container">
      <FormBox>
        <SpaceWrapper padding={padding}>
          <FormBody profileApi={profileApi} />
          <FormFooter />
        </SpaceWrapper>
      </FormBox>
    </FormContainer>
  );
};

export default Form;
