import { IconProps } from "typing";
import { theme } from "../themes";

export const EraseIcon = ({
  width = 16,
  height = 16,
  color = theme.colors.secondary["380"],
  alt = "Ícone de borracha",
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.018 13.3176H5.956C6.30933 13.3176 6.64867 13.1769 6.89867 12.9269L12.7147 7.11091C13.2353 6.59024 13.2353 5.74624 12.7147 5.22558L10.5153 3.02624C9.99467 2.50558 9.15067 2.50558 8.63 3.02624L2.34533 9.31091C1.82467 9.83158 1.82467 10.6756 2.34533 11.1962L4.076 12.9269C4.32533 13.1769 4.664 13.3176 5.018 13.3176V13.3176Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.39331 6.25977L9.47998 10.3464"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13.3197L5.95337 13.3197"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
