import dynamic from "next/dynamic";
// import { ComponentType } from "react";

// const lazyLoading = <T,>(path: string, exported?: string): ComponentType<T> => {
//   return dynamic<T>(() =>
//     import(path).then((module) => (exported ? module[`${exported}`] : module))
//   );
// };

type Options = {
  loading: () => JSX.Element;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyLoading = <T,>(callbackFunc: any, options?: Options) => {
  if (options) {
    const { loading } = options;

    return dynamic<T>(callbackFunc, {
      loading,
    });
  }

  return dynamic<T>(callbackFunc);
};

export { lazyLoading };
