import styled from "styled-components";
import { theme } from "ui";

export const TwoFactorOptionsContainer = styled.div`
  width: 100%;
`;

interface TwoFactorOptionContainerProps {
  isValid?: boolean;
}

export const TwoFactorOptionContainer = styled.div<TwoFactorOptionContainerProps>`
  /* display: ${({ isValid }) => (isValid ? "flex" : "none")}; */
  display: flex;
  width: 100%;
  align-items: center;
  height: 80px;
  padding: 8px 10px;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.neutral["150"]};
  cursor: pointer;
`;

export const TwoFactorOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.space["x4.5"]};
`;

export const TwoFactorOptionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 43px;
  background-color: ${theme.colors.secondary["28"]} !important;
  width: 60px;
  height: 60px;
`;

export const TwoFactorOptionInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
`;

export const TwoFactorOptionTitle = styled.span`
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["500"]};
  font-family: ${theme.typography.fontFamily.chivo};
  color: ${theme.colors.neutral["550"]} !important;
`;

export const TwoFactorOptionDescription = styled.span`
  font-size: ${theme.typography.fontSize.sm.x3};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-family: ${theme.typography.fontFamily.chivo};
  color: ${theme.colors.neutral["330"]} !important;
`;

export const TwoFactorOptionArrowWrapper = styled.div`
  width: fit-content;
`;
