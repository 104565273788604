import { ComponentDto, CustomHighlightCategoryDto } from "typing";

const highlightCategoriesMapper = (
  subComponents: ComponentDto[] | null | undefined
): CustomHighlightCategoryDto[] | null => {
  if (!subComponents?.length) {
    return null;
  }

  //   console.log("subComponents: ", subComponents);

  return subComponents.map((subComponent) => {
    const name =
      (subComponent?.properties?.find((property) => property?.key === "title")
        ?.value as string) || "";

    const branchId = Number(subComponent?.config?.branches?.[0]?.id || 0);

    const descriptionUrl =
      subComponent?.config?.branches?.[0]?.linkUrl ||
      subComponent?.config?.branches?.[0]?.linkId ||
      "";

    return {
      id: 0,
      name,
      level: 0,
      ordination: 0,
      branchId,
      titleSeo: "",
      descriptionSeo: "",
      descriptionUrl,
    };
  });
};

export { highlightCategoriesMapper };
