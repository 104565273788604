import styled, { css } from "styled-components";
import { CloseIcon as Close, theme } from "ui";
import { ButtonSubmit } from "../ButtonSubmit";
import { Modal } from "../Modal";

const BaseModal = styled(Modal)``;

interface BaseModalHeaderProps {
  justifyContent?: string;
}

const BaseModalHeader = styled.section<BaseModalHeaderProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  position: relative;
  z-index: 1;
  flex: 1;
  padding: ${theme.space.x4};
  max-height: 70px;
  min-height: 70px;
  width: 100%;
`;

const BaseModalHeaderTitle = styled.h1`
  justify-content: flex-start;
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  font-size: ${theme.typography.fontSize.md.x1};
  font-weight: bold;
  padding: ${`${theme.space.x4} ${theme.space.x0}`};
  color: ${theme.colors.neutral["550"]};
`;

const BaseModalHeaderClose = styled.section`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CloseIcon = styled(Close).attrs({
  width: "32px",
  height: "32px",
  strokeWidth: "0.75",
  color: theme.colors.neutral["550"],
})``;

interface BaseModalBodyProps {
  backgroundColor?: string;
}

const BaseModalBody = styled.section<BaseModalBodyProps>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || theme.colors.neutral["80"]};
  padding: ${theme.space.x4};
  padding-bottom: 0;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
`;

type BaseModalFooterProps = {
  gap: string;
  hasFooterShadow?: boolean;
  padding?: string;
};

const BaseModalFooter = styled.section<BaseModalFooterProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  flex: 1;
  gap: ${({ gap }) => gap || theme.space.x2};
  min-height: 70px;
  padding: ${({ padding }) => padding || "initial"};
  ${({ hasFooterShadow }) =>
    hasFooterShadow &&
    css`
      -webkit-box-shadow: 0px -38px 26px -15px rgba(0, 0, 0);
      -moz-box-shadow: 0px -38px 26px -15px rgba(0, 0, 0);
      box-shadow: 0px -38px 26px -15px rgba(0, 0, 0);
    `}
`;

const BaseModalFooterButton = styled(ButtonSubmit).attrs({
  alignSelf: "center",
  fontSize: theme.typography.fontSize.sm.x5,
})`
  padding: 0 16px;
  transition: background-color 0.2s ease-in;
  align-self: center;
`;

export const BaseModalFooterText = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: ${theme.colors.neutral["999"]};
  font-family: ${theme.typography.fontFamily.chivo}, sans-serif;
  text-decoration: underline;
  text-decoration-color: ${theme.colors.neutral["999"]};
  cursor: pointer;
`;

export {
  BaseModal,
  BaseModalHeader,
  BaseModalHeaderTitle,
  BaseModalHeaderClose,
  CloseIcon,
  BaseModalBody,
  BaseModalFooter,
  BaseModalFooterButton,
};

export const ConfirmPaymentBody = styled.section`
  font-size: 1.5rem;
  width: 100%;
  max-width: 50rem;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
  min-width: 17rem;
`;

export const ConfirmPaymentHeader = styled.span`
  display: flex;
  flex-direction: column;
  text-align: start;
  font-weight: 500;
  color: ${theme.colors.neutral["550"]};
  font-size: ${theme.typography.fontSize.sm.x7};
  gap: ${theme.space.x2};

  @media ${theme.device.desktopFirst.mobileS} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const ConfirmPaymentFieldSet = styled.span`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: ${theme.space.x2};
  color: ${theme.colors.neutral["520"]};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ConfirmPaymentFieldSetLegend = styled.legend`
  text-align: start;
  width: 100%;
  color: ${theme.colors.neutral["520"]};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: 400;
  @media ${theme.device.desktopFirst.mobileM} {
    margin-left: 0;
  }
  @media ${theme.device.desktopFirst.mobileS} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;
