import styled, { css } from "styled-components";
import { theme } from "ui";

export const StatusStepperContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  margin-top: ${theme.space.x10};

  @media (max-width: 924px) {
    margin-top: ${theme.space.x0};
  }
`;

export const StepperContainer = styled.section`
  display: flex;
  gap: ${theme.space.x8};
  justify-self: flex-end;

  & > div:last-child {
    width: 53px;
  }

  @media (max-width: 924px) {
    justify-self: auto;
    gap: initial;
    padding-left: 18%;
    justify-content: space-between;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    padding-left: 0;
  }
`;

interface StepperBarProps {
  backgroundColor?: string;
  maxWidth?: string;
}

export const StepperBar = styled.div<StepperBarProps>`
  position: absolute;
  top: 11px;
  max-width: 400px;
  width: 100%;
  height: 6px;
  border-radius: ${theme.space.x1};

  background: ${({ backgroundColor }) =>
    backgroundColor ?? theme.colors.neutral["155"]};
  transition: background-color 1s ease;

  @media (max-width: 924px) {
    max-width: 96%;
  }
`;

interface StatusContainerProps {
  width?: string;
}

export const StatusContainer = styled.div<StatusContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.space.x2};
  width: ${({ width }) => width ?? "100%"};
`;

interface StatusIconProps {
  color?: string;
}

export const StatusIcon = styled.div<StatusIconProps>`
  width: ${theme.space.x7};
  height: ${theme.space.x7};
  border: 1.5px solid
    ${({ color }) => color ?? `${theme.colors.neutral["350"]}`};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.space.x1};
  background: ${theme.colors.neutral.white};
  z-index: 1;

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

interface StatusTextProps {
  color?: string;
}

export const StatusText = styled.span<StatusTextProps>`
  color: ${({ color }) => color ?? `${theme.colors.neutral["350"]}`};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-size: ${theme.typography.fontSize.sm.x3};
  font-family: ${theme.typography.fontFamily.chivo};
  white-space: nowrap;
`;

interface StepperProps {
  color?: string;
  height?: string;
}

export const Stepper = styled.div<StepperProps>`
  height: ${({ height }) => height ?? "4px"};
  width: 100%;
  border-radius: 4px;
  background-color: ${({ color }) => color ?? theme.colors.neutral["555"]};
`;

export const StatusProgressContainer = styled.div`
  display: flex;
  gap: ${theme.space.x2};
`;

interface StepperWrapperProps {
  hasHeader?: boolean;
}

export const StepperWrapper = styled.section<StepperWrapperProps>`
  ${({ hasHeader }) =>
    hasHeader
      ? css`
          display: flex;
          padding: ${theme.space.x4};
          width: 100%;
          align-items: center;
          gap: ${theme.space.x3};
          background-color: ${theme.colors.neutral.white};
        `
      : css``}
`;

export const IconWrapper = styled.div`
  width: 41px;
  height: 36px;
  border-radius: ${theme.space.x3};
  background-color: ${theme.colors.secondary["28"]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
  width: 100%;
`;

export const StepTitle = styled.span`
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.neutral["550"]};
  font-weight: ${theme.typography.fontWeight["500"]};
  font-family: ${theme.typography.fontFamily.chivo};
`;
