import { IconProps } from "typing";
import { theme } from "../themes";

export const Home = ({
  width = 24,
  height = 24,
  alt = "Inicio",
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50613 0.435567L5.00001 3.50342V2C5.00001 1.44772 4.5523 1 4.00001 1C3.44773 1 3.00001 1.44772 3.00001 2V5C3.00001 5.0792 3.00922 5.15625 3.02662 5.23014L0.506132 7.43557C0.194404 7.70833 0.162816 8.18215 0.435578 8.49388C0.708339 8.80561 1.18216 8.83719 1.49389 8.56443L9.00074 1.99594L16.5305 8.56515C16.8426 8.83746 17.3164 8.80518 17.5887 8.49306C17.861 8.18093 17.8287 7.70716 17.5166 7.43485L9.49307 0.434849C9.21025 0.188111 8.78859 0.188418 8.50613 0.435567ZM11 11V16H14C14.5523 16 15 15.5523 15 15V9.46837C15 9.17159 14.8682 8.89015 14.6402 8.70015L9.64019 4.53349C9.26935 4.22445 8.73067 4.22445 8.35983 4.53349L3.35983 8.70015C3.13183 8.89015 3.00001 9.17159 3.00001 9.46837V15C3.00001 15.5523 3.44773 16 4.00001 16H7.00001V11H11Z"
        fill={color}
      />
    </svg>
  );
};
