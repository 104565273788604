import { useForm } from "react-hook-form";
import { IFormValues } from "typing";
import { usePhoneValidationContentData } from "./data";
import { PhoneValidationContentUI } from "./ui";

const PhoneValidationContent = () => {
  const { register } = useForm<IFormValues>();
  const { getPhoneValidationContent } = usePhoneValidationContentData();
  return (
    <PhoneValidationContentUI
      register={register}
      {...getPhoneValidationContent().content}
      {...getPhoneValidationContent().actions}
      hasInput={false}
    />
  );
};

export { PhoneValidationContent };
