import { CustomGridPlaylistsDto, GridPlaylistsSubComponentDto } from "typing";
import { memo } from "react";
import { appMonitoringClient } from "implementations";
import { buildTestIds } from "application";
import {
  CustomGridPlaylistsContainer,
  CustomGridPlaylistsContent,
  CustomGridPlaylistsHeader,
  CustomGridPlaylistsSubTitle,
  CustomGridPlaylistsTitle,
  WrapperRow,
} from "./styles";
import GridPlaylistsSlider from "./GridPlaylistsSlider";
import { customGridPlaylistsData } from "./data";
import CustomGridPlaylistsCard from "./GridPlaylistsCard";

const getTextAlignment = (
  textAlign: "left" | "center" | "right" | undefined
): "flex-start" | "center" | "flex-end" => {
  if (!textAlign) {
    return "center";
  }

  return textAlign === "left"
    ? "flex-start"
    : textAlign === "center"
    ? "center"
    : textAlign === "right"
    ? "flex-end"
    : "center";
};

const CustomGridPlaylists = memo(
  ({
    properties,
    subComponents,
    isClientMobile,
    isClientTablet,
    position,
    isBlackTheme,
  }: CustomGridPlaylistsDto) => {
    const { title, textAlign, showTitle, showSubTitle, subTitle } =
      customGridPlaylistsData(properties);

    const titleTextAlign = getTextAlignment(textAlign);

    if (!subComponents?.length) {
      appMonitoringClient.captureMessage(
        "Um componente de Playlists em Grade não possui subcomponentes, ou seja, não existem produtos a serem mostrados",
        "warning"
      );
      return null;
    }

    const evenSubComponents = subComponents?.filter(
      (_, index) => (index + 1) % 2 === 0
    );
    const oddSubComponents = subComponents?.filter(
      (_, index) => (index + 1) % 2 !== 0
    );

    const conditionForTitle = showTitle && showTitle === "true";
    const conditionForSubTitle = showSubTitle && showSubTitle === "true";
    const cardsWithoutSliderOnTabletAndMobile =
      (subComponents?.length < 19 && isClientMobile) ||
      (subComponents?.length < 17 && isClientTablet);
    const cardsWithoutSliderOnDesktop =
      !isClientTablet && subComponents?.length < 17;

    return (
      <CustomGridPlaylistsContainer
        {...buildTestIds("custom-grid-playlists-container")}
        customTheme={isBlackTheme}
      >
        <CustomGridPlaylistsHeader
          {...buildTestIds("custom-grid-playlists-header")}
        >
          {conditionForTitle && (
            <CustomGridPlaylistsTitle
              {...buildTestIds("custom-grid-playlists-title")}
              alignSelf={titleTextAlign}
            >
              {title}
            </CustomGridPlaylistsTitle>
          )}
          {conditionForSubTitle && (
            <CustomGridPlaylistsSubTitle
              {...buildTestIds("custom-grid-playlists-subtitle")}
              alignSelf={titleTextAlign}
            >
              {subTitle}
            </CustomGridPlaylistsSubTitle>
          )}
        </CustomGridPlaylistsHeader>
        {cardsWithoutSliderOnTabletAndMobile ? (
          <CustomGridPlaylistsContent
            {...buildTestIds("custom-grid-playlists-content")}
          >
            {subComponents?.map((item, index) => (
              <CustomGridPlaylistsCard
                key={`${item.displayName}-${item.type}`}
                {...(item as GridPlaylistsSubComponentDto)}
                positionComponent={position}
                index={index + 1}
              />
            ))}
          </CustomGridPlaylistsContent>
        ) : cardsWithoutSliderOnDesktop ? (
          <WrapperRow {...buildTestIds("custom-grid-playlists-wrapper-row")}>
            {oddSubComponents?.map((subComponent, index) => {
              const componentKey = `${subComponent.type}#odd#${index}`;

              return (
                <CustomGridPlaylistsCard
                  {...buildTestIds("custom-grid-playlists-card")}
                  key={componentKey}
                  {...(subComponent as GridPlaylistsSubComponentDto)}
                  positionComponent={position}
                  index={index + 1}
                />
              );
            })}
            {evenSubComponents?.map((subComponent, index) => {
              const componentKey = `${subComponent.type}#even#${index}`;

              return (
                <CustomGridPlaylistsCard
                  {...buildTestIds("custom-grid-playlists-card")}
                  key={componentKey}
                  {...(subComponent as GridPlaylistsSubComponentDto)}
                  positionComponent={position}
                  index={index + 1}
                />
              );
            })}
          </WrapperRow>
        ) : (
          <GridPlaylistsSlider
            {...buildTestIds("custom-grid-playlists-slider")}
            positionComponent={position}
            isBlackFriday={isBlackTheme}
            subComponents={subComponents}
          />
        )}
      </CustomGridPlaylistsContainer>
    );
  }
);

CustomGridPlaylists.displayName = "CustomGridPlaylists";

export { CustomGridPlaylists };
