import styled from "styled-components";
import { AlertWithStroke, CheckDone, theme } from "ui";

interface WrapperAlertErrorProps {
  positionShouldBeRelative: boolean;
}

export const TextError = styled.span`
  font-size: 1.125rem;
  font-family: ${theme.typography.fontFamily.roboto};
  font-weight: 400;
  color: ${theme.colors.primary["380"]};
  width: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: 1rem;
  }
`;

export const WrapperAlertError = styled.span<WrapperAlertErrorProps>`
  position: ${({ positionShouldBeRelative }) =>
    positionShouldBeRelative ? "relative" : "absolute"};
  margin: 1.5rem 0 0 -2rem;
`;

export const WrapperCheckSuccess = styled.span`
  position: absolute;
  margin: 1.5rem 0 0 -2rem;
`;

export const AlertError = styled(AlertWithStroke).attrs({
  width: "1.125rem",
  height: "1.125rem",
  color: theme.colors.primary["200"],
})``;

export const CheckSuccess = styled(CheckDone).attrs({
  width: "1.125rem",
  height: "1.125rem",
  color: theme.colors.secondary["380"],
})``;
