import { ProductCardDto, SliderProps } from "typing";
import { buildTestIds, useAppContext } from "application";
import { useMemo } from "react";
import { Slider } from "../../../Slider";
import { RecommendedProductCard } from "../../styles";
import { ProductCardStyled } from "./styles";

type RecommendedProductListSliderProps = {
  slidesToShow: number;
  arrowsIsActive: boolean;
  productCards: ProductCardDto[];
  height: number;
  isBlackFriday?: boolean;
};

const RecommendedProductListSlider = ({
  slidesToShow,
  arrowsIsActive,
  productCards,
  isBlackFriday,
  height,
}: RecommendedProductListSliderProps) => {
  const { isClientLaptop } = useAppContext();

  const slidesToScroll = useMemo(() => {
    if (isClientLaptop) return 4;

    return 5;
  }, [isClientLaptop]);

  const sliderProps: SliderProps = {
    speed: 700,
    slidesToShow,
    slidesToScroll,
    arrowsIsActive,
    touchMove: true,
    swipe: false,
  };

  return (
    <Slider
      {...buildTestIds("recommended-product-list-slider")}
      {...sliderProps}
      isBlackTheme={isBlackFriday}
    >
      {productCards?.map((product) => (
        <ProductCardStyled
          {...buildTestIds("product-card-style")}
          className="ins-web-smart-recommender-box-item"
          key={`${product.id}`}
          height={height}
        >
          <RecommendedProductCard
            className="ins-product-box ins-element-link ins-add-to-cart-wrapper ins-sr-api"
            data-ins-product-id={`${product?.id}`}
            data-event-collection="true"
            item={product}
            shouldShowInGridOnMobile={false}
            isPlaylist
          />
        </ProductCardStyled>
      ))}
    </Slider>
  );
};

export { RecommendedProductListSlider };
