import { IconProps } from "typing";
import { theme } from "../themes";

export const CircularTelephoneIcon = ({
  width = 24,
  height = 25,
  color = theme.colors.neutral["550"],
  alt = "Ícone de alianças de casamento",
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.65126 14.8469C11.8343 17.0309 14.6003 18.4229 16.2453 16.7849L16.6443 16.3859C17.1763 15.8549 17.1023 14.9749 16.4843 14.5459C16.0953 14.2749 15.6773 13.9839 15.2153 13.6589C14.7373 13.3229 14.0823 13.3759 13.6673 13.7869L13.2163 14.2349C12.6573 13.8809 12.1043 13.4259 11.5903 12.9129L11.5883 12.9109C11.0753 12.3979 10.6203 11.8439 10.2663 11.2849L10.7143 10.8339C11.1263 10.4199 11.1773 9.76392 10.8423 9.28592C10.5163 8.82392 10.2253 8.40592 9.95526 8.01692C9.52626 7.39992 8.64626 7.32592 8.11526 7.85692L7.71626 8.25592C6.07926 9.90092 7.47026 12.6649 9.65326 14.8499"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
