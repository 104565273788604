import { IconProps } from "typing";
import { theme } from "../themes";

export const Heart = ({
  width = 24,
  height = 24,
  color = theme.colors.secondary["400"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5247 1.3017C12.7802 1.8775 12.272 2.40814 12 2.89362C11.7281 2.40814 11.22 1.8775 10.4755 1.3017C9.3587 0.437988 8.08997 0 6.70459 0C4.85101 0 3.1463 0.742126 1.90393 2.0896C0.676208 3.42133 0 5.24066 0 7.21271C0 9.24243 0.756409 11.1004 2.38037 13.06C3.83313 14.8129 5.92108 16.5923 8.33899 18.6528L8.3432 18.6564C8.83468 19.0752 9.86085 19.8377 11.4217 20.9439C11.7681 21.1895 12.2318 21.1894 12.5782 20.9439L12.5782 20.9439C14.1415 19.8358 15.1693 19.0719 15.6617 18.6524L15.6621 18.6521C18.0795 16.5919 20.1671 14.8128 21.6198 13.0598C23.2438 11.1004 24 9.24243 24 7.21271C24 5.24066 23.324 3.42133 22.0963 2.0896C20.8539 0.742126 19.149 0 17.2956 0C15.9102 0 14.6415 0.437988 13.5247 1.3017ZM20.4649 12.1026C19.3195 13.4848 18.0768 14.6234 14.6888 17.5108C13.4507 18.5656 12.7863 19.1355 12.0001 19.8225C11.2181 19.1392 10.568 18.5815 9.31609 17.5147L9.32326 17.5209C5.92452 14.6244 4.6807 13.4849 3.53528 12.1028C2.12233 10.3978 1.5 8.86915 1.5 7.21271C1.5 5.60902 2.04064 4.15431 3.00678 3.10632C3.96428 2.06782 5.26875 1.5 6.70459 1.5C7.75606 1.5 8.70242 1.82669 9.55779 2.48823C10.0054 2.83449 10.4168 3.26406 10.7845 3.7726L11.1896 4.33275C11.2519 4.41894 11.3276 4.49463 11.4138 4.55697C11.8612 4.88069 12.4864 4.78037 12.8101 4.33291L13.2153 3.77283C13.5836 3.26381 13.9948 2.83434 14.4423 2.48829C15.2978 1.82669 16.2441 1.5 17.2956 1.5C18.7313 1.5 20.036 2.06788 20.9935 3.10638C21.9595 4.15421 22.5 5.60888 22.5 7.21271C22.5 8.8693 21.8777 10.398 20.4649 12.1026Z"
        fill={color}
      />
    </svg>
  );
};
