export const getApisUrlFallback = (env?: string) => {
    switch (env) {
        case "development":
        return "https://fcxlabs-ecommerce-dev-api.ferreiracosta.com/";
        case "qas":
        return "https://fcxlabs-ecommerce-qas-api.ferreiracosta.com/";
        case "production":
        return "https://fcxlabs-ecommerce-api.ferreiracosta.com/";
        default:
        return "https://fcxlabs-ecommerce-api.ferreiracosta.com/";
    }
}