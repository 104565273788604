import styled from "styled-components";
import { H2, theme } from "ui";

export const LinkOptionsTitle = styled(H2)`
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x9};
  margin: 0;
  ${({ color }) => color && `color: ${color};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}

    @media ${theme.device.desktopFirst.tablet} {
    font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x7};
  }
`;
