import styled from "styled-components";
import { Button, theme } from "ui";

export const ResponsiveModalContentButtons = styled.section`
  margin-top: ${theme.space.x4};
`;

export const ResponsiveModalPrimaryButton = styled(Button)`
  width: 100%;
  background-color: ${theme.colors.secondary["380"]};
  border-radius: ${theme.space.x1};
  color: ${theme.colors.neutral.white};
  height: 45px;
  transition: background-color 0.3s ease-in-out;
  font-size: ${theme.typography.fontSize.sm.x5};

  :hover {
    background-color: ${theme.colors.secondary["350"]};
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const ResponsiveModalSecondaryButton = styled(Button)`
  width: 100%;
  background-color: ${theme.colors.neutral.white};
  height: 45px;
  color: ${theme.colors.neutral["550"]};
  font-size: ${theme.typography.fontSize.sm.x3};

  :hover {
    background-color: ${theme.colors.neutral.white};
  }

  :disabled {
    opacity: 0.5;
  }
`;
