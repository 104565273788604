import { memo } from "react";
import { RecommendedProductCardsDto } from "typing";
import { buildTestIds } from "application";
import {
  RecommendedProductBoxCard,
  RecommendedProductCard,
  RecommendedProductCardContainer,
  RecommendedProductListContainer,
  RecommendedProductListHeader,
  RecommendedProductListTitle,
} from "./styles";
import { RecommendedProductListSlider } from "./components/Slider";

interface RecommendedProductListUIProps extends RecommendedProductCardsDto {
  maxHeightForProductCards: number;
  arrowIsActive: boolean;
  slidesToShow: number;
}

const RecommendedProductListUI = memo(
  ({
    productCards,
    isBlackTheme,
    campaignVariationId,
    isClientTablet,
    recommendedProductsId,
    maxHeightForProductCards,
    arrowIsActive,
    slidesToShow,
  }: RecommendedProductListUIProps) => {
    return (
      <RecommendedProductListContainer
        {...buildTestIds("recommended-product-list-container")}
        className={`ins-preview-wrapper-${campaignVariationId}`}
        isBlackTheme={isBlackTheme}
      >
        <RecommendedProductListHeader
          {...buildTestIds("recommended-product-list-header")}
        >
          <RecommendedProductListTitle
            {...buildTestIds("recommended-product-list-header-title")}
          >
            Recomendamos para você
          </RecommendedProductListTitle>
        </RecommendedProductListHeader>

        {isClientTablet ? (
          <RecommendedProductCardContainer
            {...buildTestIds("recommended-product-card-container")}
            className="ins-web-smart-recommender-body"
            data-recommended-items={recommendedProductsId}
          >
            {productCards?.map((product) => (
              <RecommendedProductBoxCard
                {...buildTestIds(`recommended-product-card`)}
                className="ins-web-smart-recommender-box-item"
                key={`box-${product.id}`}
                height={maxHeightForProductCards}
              >
                <RecommendedProductCard
                  className="ins-product-box ins-element-link ins-add-to-cart-wrapper ins-sr-api"
                  data-event-collection="true"
                  data-ins-product-id={`${product?.id}`}
                  key={`${product.id}`}
                  item={product}
                  shouldShowInGridOnMobile={false}
                  isPlaylist
                />
              </RecommendedProductBoxCard>
            ))}
          </RecommendedProductCardContainer>
        ) : (
          <RecommendedProductListSlider
            {...buildTestIds("recommended-product-list-slider")}
            arrowsIsActive={arrowIsActive}
            isBlackFriday={isBlackTheme}
            slidesToShow={slidesToShow}
            productCards={productCards}
            height={maxHeightForProductCards}
          />
        )}
      </RecommendedProductListContainer>
    );
  }
);

RecommendedProductListUI.displayName = "RecommendedProductListUI";

export { RecommendedProductListUI };
