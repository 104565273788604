import styled from "styled-components";
import { theme } from "ui";

interface StyledFieldInfoProps {
  color?: string;
  fontSize?: string;
  padding?: string;
}

const StyledFieldInfo = styled.div<StyledFieldInfoProps>`
  color: ${({ color }) => color || theme.colors.neutral["450"]};
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x3};
  padding: ${({ padding }) => padding || `${theme.space.x0} ${theme.space.x2}`};
`;

export { StyledFieldInfo };
