import styled, { css } from "styled-components";
import { CartIcon, theme } from "ui";

interface CartButtonProps {
  isLoading?: boolean;
}

export const ContainerButton = styled.section`
  position: relative;
  max-width: 5rem;
  @media ${theme.device.desktopFirst.mobileL} {
    max-width: 4.2rem;
  }
`;

const BaseHyperLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: ${theme.colors.primary["200"]};
  cursor: pointer;

  transition: all 0.2s ease-in;

  &:hover {
    background: ${theme.colors.primary["400"]};
  }
`;

export const CartHyperLink = styled(BaseHyperLink)<CartButtonProps>`
  flex: none;
  width: 5rem;
  height: 4.17rem;
  border: 0;
  border-radius: 4px;

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: ${isLoading ? "none" : "initial"};
    `}

  @media ${theme.device.desktopFirst.mobileL} {
    height: 3rem;
    width: 3.75rem;
    padding: 13px;
  }

  //larissa.ferreira não utilizar inline-css
  svg {
    width: 28px;
    height: 28px;
  }
`;

export const CartIconUi = styled(CartIcon).attrs({
  color: theme.colors.neutral.white,
})``;

export const CountItens = styled.span`
  width: fit-content;
  max-width: 2rem;
  max-height: 2rem;
  padding: 0.7rem 0.9rem;
  /* height: 2.5rem; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: -0.5rem;
  bottom: -0.8rem;

  background: ${theme.colors.secondary["265"]};
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 200;
  color: ${theme.colors.neutral.white};
  user-select: none;

  @media ${theme.device.desktopFirst.mobileL} {
    max-width: 1.9rem;
    max-height: 1.9rem;
    font-size: 0.9rem;
  }
`;
