import { IconProps } from "typing";
import { theme } from "../themes";

export const AtEmailIcon = ({
  width = 20,
  height = 21,
  color = theme.colors.neutral["655"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1685 18.0028H10.0001C5.85621 18.0028 2.49695 14.6436 2.49695 10.4997C2.49695 6.35585 5.85621 2.99658 10.0001 2.99658C14.1439 2.99658 17.5032 6.35585 17.5032 10.4997V11.7502C17.5032 12.9013 16.5701 13.8344 15.419 13.8344C14.2679 13.8344 13.3348 12.9013 13.3348 11.7502V10.4997C13.3348 8.65799 11.8418 7.16498 10.0001 7.16498V7.16498C8.15836 7.16498 6.66535 8.65799 6.66535 10.4997C6.66535 12.3414 8.15836 13.8344 10.0001 13.8344C11.8418 13.8344 13.3348 12.3414 13.3348 10.4997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
