import styled from "styled-components";
import { FlexContainer, theme } from "ui";

interface StyledLabelProps {
  color?: string;
  fontSize?: string;
  display?: string;
  justifyContent?: string;
  paddingLeft?: string;
  maxWidth?: string;
  typeFontFamily?: string;
  typeFontWeight?: number;
}

export const StyledLabel = styled.label<StyledLabelProps>`
  color: ${({ color }) => color || theme.colors.neutral["999"]};
  font-size: ${({ fontSize }) => fontSize};
  display: ${({ display }) => display} !important;
  justify-content: ${({ justifyContent }) => justifyContent} !important;
  padding-left: ${({ paddingLeft }) => paddingLeft || "initial"};
  font-family: ${({ typeFontFamily }) =>
    typeFontFamily || theme.typography.fontFamily.chivo};
  font-weight: ${({ typeFontWeight }) =>
    typeFontWeight || theme.typography.fontWeight["500"]};
`;

export const LabelContainer = styled(FlexContainer)<StyledLabelProps>`
  display: ${({ display }) => display} !important;
  justify-content: ${({ justifyContent }) => justifyContent} !important;
  flex-direction: row;
  align-items: end;
  gap: 0.5rem;
  max-width: ${({ maxWidth }) => maxWidth || "initial"};
`;

export const OptionalText = styled.span`
  letter-spacing: -0.2px;
  color: ${theme.colors.neutral["585"]};
  font-size: ${theme.typography.fontSize.sm.x1};
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight["400"]};
`;

export const RequiredFieldSignal = styled.span`
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["600"]};
  color: ${theme.colors.primary["225"]};
`;
