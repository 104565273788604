import { parse as cookieParse, serialize as cookieSerialize } from "cookie";
import { ICookieParser } from "app-domain";

export const Cookie: ICookieParser = {
  parse(value: string) {
    return cookieParse(value);
  },

  serialize(name: string, value: string, options = undefined) {
    return cookieSerialize(name, value, options);
  },
};
