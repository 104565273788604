import { buildTestIds } from "application";
import { ReactNode } from "react";
import { Header } from "../../../../Header";
import { Head } from "../../../Head";
import { AuthContainer } from "./styles";

interface AuthContentProps {
  loginMode: "page" | "modal";
  children?: ReactNode;
}

const AuthContent = ({ loginMode, children }: AuthContentProps) => {
  const isLoginPage = loginMode === "page";

  return (
    <AuthContainer
      isPageLogin={isLoginPage}
      {...buildTestIds("auth-content-container")}
    >
      {isLoginPage && <Head title="Entrar | Ferreira Costa" />}
      {isLoginPage && <Header />}
      {children}
    </AuthContainer>
  );
};

export { AuthContent };
