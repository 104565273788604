import { IGtag } from "app-domain";
import { calculateDiscountPercentage } from "application";
import {
  CartInfo,
  CartItemsDto,
  GtagSelectPromotion,
  Packages,
  Product,
  ProductCardDto,
  SelectItemInfo,
} from "typing";

declare const window: Window &
  // eslint-disable-next-line no-undef
  typeof globalThis & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag(event: "event" | "config", GA_ID: string, object: unknown): any;
  };

const handleIsGtagDefined = async (
  retryTimes: number
): Promise<{ action: "abort" | "retry" | "continue" }> => {
  if (!window?.gtag) {
    if (retryTimes >= 5) {
      return Promise.resolve({ action: "abort" });
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ action: "retry" });
      }, 1000);
    });
  }

  return { action: "continue" };
};

interface EventProps {
  action: string;
  shipping?: number;
  transactionId?: string;
  category?: string;
  label?: string;
  value?: string | number;
  items?: unknown[];
  currency?: "BRL";
  method?: string;
  searchTerm?: string;
  sendTo?: string | string[];
  itemListId?: string;
  itemListName?: string;
  shippingTier?: string;
  paymentType?: string;
  quantity?: number;
  zipCode?: string;
  clientId?: number | string;
  discount?: number;
  productId?: string;
  productName?: string;
  fcShippingOptions?: string;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = (eventProps: EventProps, retryTimes = 0) => {
  handleIsGtagDefined(retryTimes)
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .then(({ action: isGtagDefinedAction }) => {
      if (isGtagDefinedAction === "abort") {
        return;
      }

      if (isGtagDefinedAction === "retry") {
        event(eventProps, retryTimes + 1);
        return;
      }

      const {
        action,
        category,
        label,
        value,
        items,
        method,
        searchTerm,
        sendTo,
        currency,
        transactionId,
        itemListId,
        itemListName,
        shippingTier,
        shipping,
        paymentType,
        quantity,
        zipCode,
        clientId,
        discount,
        productId,
        productName,
        fcShippingOptions,
      } = eventProps;

      window.gtag("event", action, {
        ...(value && { value }),
        ...(category && { event_category: category }),
        ...(label && { event_label: label }),
        ...(items && { items }),
        ...(method && { method }),
        ...(searchTerm && { search_term: searchTerm }),
        ...(sendTo && { send_to: sendTo }),
        ...(currency && { currency }),
        ...(transactionId && { transaction_id: transactionId }),
        ...(itemListId && { item_list_id: itemListId }),
        ...(itemListName && { item_list_name: itemListName }),
        ...(shippingTier && { shipping_tier: shippingTier }),
        ...(shipping && { shipping }),
        ...(paymentType && { payment_type: paymentType }),
        ...(quantity && { quantity }),
        ...(zipCode && { zipCode }),
        ...(clientId && { client: clientId }),
        ...(discount && { discount }),
        ...(productId && { product_id: productId }),
        ...(productName && { productName }),
        ...(fcShippingOptions && { fc_shipping_options: fcShippingOptions }),
      });
    })
    .catch(() => undefined);
};

// const addToCartGoogleAdsLabel =
//   process.env.NEXT_PUBLIC_HOST_ENV !== "production"
//     ? "/nT5ACKfflZ0YEIKt7cEo"
//     : "/0094CPmOx6gYEIOF-OYC";

// const beginCheckoutGoogleAdsLabel =
//   process.env.NEXT_PUBLIC_HOST_ENV !== "production"
//     ? "/nJF9CKrflZ0YEIKt7cEo"
//     : "/YFA2CN2Z0qgYEIOF-OYC";

const gtagRaw: IGtag = {
  GA_TRACKING_ID:
    process.env.NEXT_PUBLIC_HOST_ENV !== "production"
      ? "G-TC1D09CY73"
      : "G-9T953BGJS4",

  GTM_ID:
    process.env.NEXT_PUBLIC_HOST_ENV !== "production"
      ? "GTM-NFZ95SF"
      : "GTM-54XRRFM",

  GA_TRACKING_ID_DEV: "G-SQ70CTCNQ2",

  AW_ID:
    process.env.NEXT_PUBLIC_HOST_ENV !== "production"
      ? "AW-10875524738"
      : "AW-752747139",

  AW_PURCHASE_LABEL:
    process.env.NEXT_PUBLIC_HOST_ENV !== "production"
      ? "/YjHMCLzflZ0YEIKt7cEo"
      : "/1br_CMbOwKgYEIOF-OYC",

  pageview(url: string): void {
    window?.gtag("config", gtagRaw.GA_TRACKING_ID, {
      page_path: url,
    });
  },

  addToCart: (value: number, item: Product, currency: "BRL" = "BRL"): void => {
    // Precisamos entender melhor como enviar a tag de google ads sem que ela seja de conversão
    // Evento do AW
    // event({
    //   action: "conversion",
    //   value: args.price,
    //   currency,
    //   sendTo: `${gtagRaw.AW_ID}${addToCartGoogleAdsLabel}`,
    // });

    // Evento do GA
    event({
      currency,
      action: "add_to_cart",
      category: "ecommerce",
      label: "Adicionar ao carrinho",
      value,
      items: [
        {
          item_id: item?.id,
          quantity: item?.quantity,
          item_name: item?.description,
          item_brand: item?.brand,
          item_category: item?.breadCrumbs?.category?.name,
          item_category2: item?.breadCrumbs?.fatherCategory?.name,
          item_category3: item?.breadCrumbs?.grandFatherCategory?.name,
          price: item?.prices?.[0]?.spotPrice || item?.prices?.[0]?.salePrice,
          item_variant:
            item?.productFamilies[0]?.families[0]?.color?.name || "no_variant",
          coupon: item?.coupons[0]?.couponName || "not_applied",
          discount: item?.prices?.[0]?.discountPercentage,
        },
      ],
      sendTo: gtagRaw.GA_TRACKING_ID,
    });

    // henrique.melo precisamos ver se faz sentido unificar os dois eventos acima em um só, utilizando as mesmas propriedades
  },

  beginCheckout(
    value: number,
    items: ProductCardDto[],
    clientId: number,
    currency: "BRL" = "BRL"
  ): void {
    // Precisamos entender melhor como enviar a tag de google ads sem que ela seja de conversão
    // Evento do AW
    // event({
    //   action: "conversion",
    //   value,
    //   currency,
    //   sendTo: `${gtagRaw.AW_ID}${beginCheckoutGoogleAdsLabel}`,
    // });

    // Evento do GA
    event({
      currency,
      action: "begin_checkout",
      category: "ecommerce",
      label: "Iniciar fechamento de pedido",
      value,
      clientId,
      items: items?.map((item) => ({
        item_id: item?.id,
        item_name: item?.description,
        item_brand: item?.brand,
        item_category: item?.breadCrumbs?.category?.name,
        item_category2: item?.breadCrumbs?.fatherCategory?.name,
        item_category3: item?.breadCrumbs?.grandFatherCategory?.name,
        price: item?.prices?.[0]?.spotPrice || item?.prices?.[0]?.salePrice,
        discount: item?.prices?.[0]?.discountPercentage || "string",
        coupon: item?.coupons?.[0]?.couponName || "not_applied",
      })),
      sendTo: gtagRaw.GA_TRACKING_ID,
    });

    // henrique.melo precisamos ver se faz sentido unificar os dois eventos acima em um só, utilizando as mesmas propriedades
  },

  viewCart(
    value: number,
    items: CartItemsDto[],
    clientId: number,
    currency: "BRL" = "BRL"
  ): void {
    const customViewCartEvent: EventProps = {
      currency,
      action: "view_cart",
      category: "ecommerce",
      label: "Visualizar carrinho",
      value,
      clientId,
      items: items?.map((item) => ({
        item_id: item?.productId,
        item_variant: item?.package,
        quantity: item?.quantity,
      })),
      sendTo: gtagRaw.GA_TRACKING_ID,
    };
    event(customViewCartEvent);
  },

  login(method: string, clientId: string): void {
    event({
      action: "login",
      category: "ecommerce",
      label: "Realizar login",
      method,
      clientId,
      sendTo: gtagRaw.GA_TRACKING_ID,
    });
  },

  viewItem(value: number, item: Product, currency: "BRL" = "BRL"): void {
    const discountValue = calculateDiscountPercentage(
      item?.prices?.[0]?.discountPercentage
    );

    const customViewItemEvent: EventProps = {
      currency,
      action: "view_item",
      category: "ecommerce",
      label: "Acessar tela do produto",
      value,
      items: [
        {
          item_id: item?.id,
          quantity: item?.quantity,
          item_name: item?.description,
          item_brand: item?.brand,
          item_category: item?.breadCrumbs?.category?.name,
          item_category2: item?.breadCrumbs?.fatherCategory?.name,
          item_category3: item?.breadCrumbs?.grandFatherCategory?.name,
          price: item?.prices?.[0]?.spotPrice || item?.prices?.[0]?.salePrice,
          coupon: item?.coupons[0]?.couponName || "not_applied",
          item_variant:
            item?.productFamilies[0]?.families[0]?.color?.name || "no_variant",
          index: 0,
        },
      ],
      discount: discountValue,
      sendTo: gtagRaw.GA_TRACKING_ID,
    };
    event(customViewItemEvent);
  },

  viewItemList(
    itemListName: string,
    itemListId: string,
    items: ProductCardDto[]
  ): void {
    event({
      action: "view_item_list",
      category: "ecommerce",
      label: "Acessar lista de produtos",
      itemListName,
      itemListId,
      items: items?.map((item, index) => ({
        item_id: item?.id,
        item_name: item?.description,
        item_brand: item?.brand,
        item_category: item?.breadCrumbs?.category?.name,
        item_category2: item?.breadCrumbs?.fatherCategory?.name,
        item_category3: item?.breadCrumbs?.grandFatherCategory?.name,
        price: item?.prices?.[0]?.spotPrice || item?.prices?.[0]?.salePrice,
        discount: item?.prices?.[0]?.discountPercentage || "string",
        coupon: item?.coupons?.[0]?.couponName || "not_applied",
        index,
      })),
      sendTo: gtagRaw.GA_TRACKING_ID,
    });
  },

  signUp(method: string): void {
    event({
      action: "sign_up",
      category: "ecommerce",
      label: "Realizar cadastro da conta",
      method,
    });
  },

  removeFromCart(args: CartInfo, value: number, clientId: number): void {
    const customRemoveFromCartEvent: EventProps = {
      action: "remove_from_cart",
      category: "ecommerce",
      label: "Remover do carrinho",
      clientId,
      value,
      items: [
        {
          item_id: args.itemId,
          quantity: args.quantity,
          item_name: args.itemName,
          item_brand: args.itemBrand,
          item_category: args.itemCategory,
          item_variant: args.itemVariant,
          price: args.price,
        },
      ],
      sendTo: [gtagRaw.GA_TRACKING_ID],
    };

    event(customRemoveFromCartEvent);
  },

  search(searchTerm: string): void {
    event({
      action: "search",
      category: "ecommerce",
      label: "Realizar busca",
      searchTerm,
    });
  },

  viewProductShipping(
    productId: string,
    productName: string,
    zipCode: string,
    fcShippingOptions: string
  ): void {
    event({
      action: "view_product_shipping",
      label: "Consultar Frete",
      productId,
      productName,
      zipCode,
      fcShippingOptions,
    });
  },

  selectItem: (
    value: number,
    args: SelectItemInfo,
    discount: number,
    currency: "BRL" = "BRL"
  ): void => {
    const customSelectItemEvent: EventProps = {
      action: "select_item",
      category: "ecommerce",
      label: "Selecionar produto",
      currency,
      value,
      items: [
        {
          item_id: args.itemId,
          item_name: args.itemName,
          price: args.price,
          item_list_id: args.itemListId,
          item_list_name: args.itemListName,
        },
      ],
      discount,
      sendTo: gtagRaw.GA_TRACKING_ID,
    };
    event(customSelectItemEvent);
  },

  purchase(
    value: number,
    shipping: number,
    transactionId: string,
    items: ProductCardDto[],
    quantity: number,
    clientId: number,
    currency: "BRL" = "BRL"
  ): void {
    // Evento do AW
    event({
      action: "conversion",
      value,
      currency,
      transactionId,
      sendTo: `${gtagRaw.AW_ID}${gtagRaw.AW_PURCHASE_LABEL}`,
    });

    // Evento do GA
    const customPurchaseEvent: EventProps = {
      currency,
      action: "purchase",
      category: "ecommerce",
      label: "Fechar pedido",
      value,
      shipping,
      clientId,
      items: items?.map((item, index) => ({
        item_id: item?.id,
        item_name: item?.description,
        item_brand: item?.brand,
        item_category: item?.breadCrumbs?.category?.name,
        item_category2: item?.breadCrumbs?.fatherCategory?.name,
        item_category3: item?.breadCrumbs?.grandFatherCategory?.name,
        item_variant: item?.itemVariant,
        price: item?.prices?.[0]?.spotPrice || item?.prices?.[0]?.salePrice,
        discount:
          calculateDiscountPercentage(item?.prices?.[0]?.discountPercentage) ||
          "no_discount",
        coupon: item?.coupons?.[0]?.couponName || "not_applied",
        index,
        quantity: item?.quantity,
      })),
      transactionId,
      quantity,
    };

    event(customPurchaseEvent);
  },

  addShippingInfo(
    items: Packages[],
    shippingTier: string,
    value: number,
    zipCode: string,
    clientId: number
  ): void {
    // Evento do GA
    const customEvent: EventProps = {
      action: "add_shipping_info",
      category: "ecommerce",
      label: "Adicionar informação de entrega",
      items: items?.map(({ id }) => ({
        item_id: id,
      })),
      shippingTier,
      value,
      zipCode,
      clientId,
    };

    event(customEvent);
  },

  selectPromotion: (args: GtagSelectPromotion): void => {
    // Evento do GA
    event({
      action: "select_promotion",
      category: "ecommerce",
      label: "Selecionar banner, carrousel ou grid de imagens",
      items: [
        {
          location_id: args.locationId,
          promotion_id: args.promotionId,
          promotion_name: args.promotionName,
          creative_name: args.creativeName,
          creative_slot: args.creativeSlot,
        },
      ],
    });
  },

  addPaymentInfo: (
    items: Packages[],
    paymentType: string,
    value: number,
    clientId: number
  ): void => {
    // Evento do GA
    event({
      action: "purchase",
      category: "ecommerce",
      label: "Adicionar informação de pagamento",
      items: items?.map(({ id }) => ({
        item_id: id,
      })),
      paymentType,
      value,
      clientId,
    });
  },
};

export { gtagRaw };
