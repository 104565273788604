import { IconProps } from "typing";
import { theme } from "../themes";

export const QrCode = ({
  color = theme.colors.neutral.white,
  alt,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5556 11.6667H6.11111C5.49778 11.6667 5 11.1689 5 10.5556V6.11111C5 5.49778 5.49778 5 6.11111 5H10.5556C11.1689 5 11.6667 5.49778 11.6667 6.11111V10.5556C11.6667 11.1689 11.1689 11.6667 10.5556 11.6667Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5556 25H6.11111C5.49778 25 5 24.5022 5 23.8889V19.4444C5 18.8311 5.49778 18.3333 6.11111 18.3333H10.5556C11.1689 18.3333 11.6667 18.8311 11.6667 19.4444V23.8889C11.6667 24.5022 11.1689 25 10.5556 25Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8889 11.6667H19.4445C18.8311 11.6667 18.3333 11.1689 18.3333 10.5556V6.11111C18.3333 5.49778 18.8311 5 19.4445 5H23.8889C24.5022 5 25 5.49778 25 6.11111V10.5556C25 11.1689 24.5022 11.6667 23.8889 11.6667Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.22222V5"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4444 15H16.1111C15.4978 15 15 14.5022 15 13.8889V11.6667"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.11111 15H5"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 21.6667H16.1111C15.4978 21.6667 15 22.1644 15 22.7778V25"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 18.3333H21.6667"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 18.3333V23.8889C25 24.5022 24.5022 25 23.8889 25H18.3333"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33331 8.32222C8.32998 8.32222 8.32776 8.32444 8.32776 8.32777C8.32776 8.33111 8.32998 8.33333 8.33331 8.33333C8.33664 8.33333 8.33887 8.33111 8.33887 8.32777C8.33887 8.32444 8.33664 8.32222 8.33331 8.32222"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6611 8.32777C21.6577 8.32777 21.6555 8.33 21.6555 8.33333C21.6555 8.33666 21.6577 8.33888 21.6611 8.33888C21.6644 8.33888 21.6666 8.33666 21.6666 8.33333C21.6666 8.33 21.6644 8.32777 21.6611 8.32777"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7722 14.9944C22.7689 14.9944 22.7667 14.9967 22.7667 15C22.7667 15.0033 22.7689 15.0056 22.7722 15.0056C22.7756 15.0056 22.7778 15.0033 22.7778 15C22.7778 14.9967 22.7756 14.9944 22.7722 14.9944"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6611 21.6611C21.6577 21.6611 21.6555 21.6633 21.6555 21.6667C21.6555 21.67 21.6577 21.6722 21.6611 21.6722C21.6644 21.6722 21.6666 21.67 21.6666 21.6667C21.6666 21.6633 21.6644 21.6611 21.6611 21.6611"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33889 21.6611C8.33556 21.6611 8.33333 21.6633 8.33333 21.6667C8.33333 21.67 8.33556 21.6722 8.33889 21.6722C8.34222 21.6722 8.34444 21.67 8.34444 21.6667C8.34444 21.6633 8.34222 21.6611 8.33889 21.6611"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44444 15H11.6667"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
