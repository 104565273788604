import { useRef } from "react";

const useFormInputRefs = () => {
  const emailRef = useRef<HTMLDivElement>(null);
  const phoneNumberRef = useRef<HTMLDivElement>(null);

  return { emailRef, phoneNumberRef };
};

export { useFormInputRefs };
