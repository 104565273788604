import NextLink from "next/link";
import { LinkProps } from "typing";
import { buildTestIds } from "application";
import { CustomStyledLink } from "../StyledLink";

const Link = ({
  children,
  href,
  scroll = true,
  mouseOver,
  id,
  ...props
}: LinkProps) => {
  return (
    <NextLink href={href} scroll={scroll} passHref>
      <CustomStyledLink
        {...buildTestIds(`custom-styled-link-${id || ""}`)}
        mouseOver={mouseOver}
        {...props}
      >
        {children}
      </CustomStyledLink>
    </NextLink>
  );
};

export { Link };
