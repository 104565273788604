import styled from "styled-components";
import { FlexContainer, theme, MapPin } from "ui";

interface RegionTextProps {
  isPageWeddingList: boolean;
}

export const NavBarMobileContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
`;

export const RegionText = styled.span<RegionTextProps>`
  margin-left: 4px;
  color: ${({ isPageWeddingList }) =>
    isPageWeddingList ? theme.colors.neutral.white : ""};
`;

export const RegionTextStrong = styled.strong``;

export const Wrapper = styled(FlexContainer)`
  align-items: center;
  justify-content: center;
`;

export const LocationIcon = styled(MapPin)`
  color: ${theme.colors.primary["200"]};
  font-size: 1.125rem;
  margin-right: 4px;
`;
