import { IconProps } from "typing";
import { theme } from "../themes";

export const ArrowLength = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M12.2426 6.58578V3.75735H9.41422"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.643 10.357L12.2427 3.75731"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75732 9.41418V12.2426H6.58575"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.357 5.64293L3.75738 12.2426"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
