import styled, { css } from "styled-components";
import { CloseIcon, theme } from "ui";
import { Modal } from "../Modal";

interface ModalGeneralContainerProps {
  maxWidth?: string;
  padding?: string;
  containerGap?: string;
}

interface TitleModalGeneralProps {
  titleFontSize?: string;
  centeredTitle?: boolean;
  fontWeight?: number;
}

interface CloseModalGeneralWrapperProps {
  hideCloseButton?: boolean;
  closeModalSize?: number;
}

interface DescriptionModalGeneralProps {
  descriptionFontSize?: string;
  descriptionColor?: string;
  textAlign?: string;
}

interface ModalGeneralContentProps {
  alignItems?: string;
  contentGap?: string;
}

export const stylesModalGeneral = (value: boolean) => {
  const overlayStyle = {
    overlay: {
      backgroundColor: theme.colors.neutral["900-opacity-25"],
      zIndex: 999,
    },
  };
  const contentStyle = {
    padding: "0",
    borderRadius: "6px",
    boxShadow: `7px 7px 7px 0px ${theme.colors.neutral["999-opacity-60"]}`,
  };

  return value
    ? {
        content: {
          top: "none",
          left: "none",
          right: "none",
          position: "absolute",
          bottom: "0",
          marginRight: "none",
          transform: "none",
          ...contentStyle,
        },
        ...overlayStyle,
      }
    : {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          ...contentStyle,
        },
        ...overlayStyle,
      };
};

export const ModalGeneralRoot = styled(Modal)``;

export const ModalGeneralContainer = styled.section<ModalGeneralContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: "center";
  justify-content: center;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "38rem"};
  padding: ${({ padding }) => padding || "1rem 2rem 2rem 2rem"};
  gap: ${({ containerGap }) => containerGap};
`;

type HeaderModalGeneralContainerProps = {
  hasTitleBorder: boolean;
};

export const HeaderModalGeneralContainer = styled.header<HeaderModalGeneralContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  padding: 0.65rem 0rem;

  ${({ hasTitleBorder }) =>
    hasTitleBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.neutral["150"]};
      margin-bottom: ${theme.space.x2};
    `}
`;

export const TitleModalGeneral = styled.h3<TitleModalGeneralProps>`
  color: ${theme.colors.neutral["550"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${({ titleFontSize }) => titleFontSize || "1.35rem"};
  font-weight: ${({ fontWeight }) =>
    fontWeight || `${theme.typography.fontWeight["700"]}`};
  display: ${({ centeredTitle }) => (centeredTitle ? "flex" : "block")};
  width: ${({ centeredTitle }) => centeredTitle && "100%"};
  justify-content: ${({ centeredTitle }) => centeredTitle && "center"};
`;

export const CloseModalGeneralWrapper = styled.div<CloseModalGeneralWrapperProps>`
  width: ${({ closeModalSize }) =>
    closeModalSize ? `${closeModalSize}px` : "2.65rem"};
  height: ${({ closeModalSize }) =>
    closeModalSize ? `${closeModalSize}px` : "2.65rem"};
  cursor: pointer;

  ${({ hideCloseButton }) =>
    hideCloseButton &&
    css`
      display: none;
    `}
`;

interface CloseModalGeneralIconProps {
  closeButtonSize?: number;
}

export const CloseModalGeneralIcon = styled(
  CloseIcon
).attrs<CloseModalGeneralIconProps>(
  ({ closeButtonSize }: CloseModalGeneralIconProps) => ({
    width: closeButtonSize ?? 32,
    height: closeButtonSize ?? 33,
    color: theme.colors.neutral["550"],
    strokeWidth: "1",
  })
)<CloseModalGeneralIconProps>``;

export const ModalGeneralContent = styled.section<ModalGeneralContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  gap: ${({ contentGap }) => contentGap ?? "1.33rem"};
`;

export const IllustrationModalGeneralWrapper = styled.div``;

export const DescriptionModalGeneral = styled.span<DescriptionModalGeneralProps>`
  max-width: 100%;
  color: ${({ descriptionColor }) =>
    descriptionColor || `${theme.colors.neutral["525"]}`};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${({ descriptionFontSize }) => descriptionFontSize || "1.15rem"};
  font-weight: ${theme.typography.fontWeight["400"]};
  text-align: ${({ textAlign }) => textAlign || "start"};
  letter-spacing: -0.025rem;
`;
