import { memo } from "react";
import { ImagesGridSubComponentDto } from "typing";
import { appMonitoringClient } from "implementations";
import { buildTestIds, useCurrentPathName } from "application";
import { customImagesGridSubComponentData } from "./data";
import { BannerImage, Container, ImageContainer } from "./styles";
import { useBackofficeComponentUrl, useGetBranchLinkConfig } from "../../utils";
import { Link } from "../../../Link";

interface ImagesGridProps extends ImagesGridSubComponentDto {
  positionComponent?: number;
}

const ImagesGrid = memo(
  ({
    properties,
    config,
    isClientMobile,
    positionComponent,
  }: ImagesGridProps) => {
    const { desktopImage, mobileImage, title } =
      customImagesGridSubComponentData(properties);

    const linkConfig = useGetBranchLinkConfig(
      config?.branches,
      "subComponentImagesGrid"
    );

    const currentPathName = useCurrentPathName();

    if (!config?.branches?.length) {
      appMonitoringClient.captureMessage(
        `Um subComponente de Imagens em Grade, localizado em "${currentPathName}" na posição ${positionComponent}, não possui nenhuma configuração de branches.`,
        "warning"
      );
    }

    const image = isClientMobile ? mobileImage ?? "" : desktopImage ?? "";
    const url = useBackofficeComponentUrl(config?.branches, linkConfig?.url);

    return (
      <Container
        as={linkConfig?.url ? Link : "section"}
        href={url}
        target={`${linkConfig?.target}`}
        {...buildTestIds("custom-imagesgrid-subComponent")}
      >
        <ImageContainer {...buildTestIds("image-container")}>
          <BannerImage
            src={image || ""}
            alt={title ?? "Imagem de banner"}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            {...buildTestIds("banner-image")}
          />
        </ImageContainer>
      </Container>
    );
  }
);

ImagesGrid.displayName = "CustomImagesGrid";

export { ImagesGrid };
