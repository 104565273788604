import { IconProps } from "typing";
import { theme } from "../themes";

export const ChangePasswordIcon = ({
  width,
  height,
  color = theme.colors.neutral["550"],
  alt,
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <path
        d="M7.84344 11.7299C7.95083 11.8371 7.98302 11.9985 7.92498 12.1388C7.86694 12.279 7.73011 12.3705 7.57833 12.3705C7.42655 12.3705 7.28973 12.279 7.23169 12.1388C7.17365 11.9985 7.20583 11.8371 7.31322 11.7299C7.45969 11.5836 7.69697 11.5836 7.84344 11.7299"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2595 11.7299C12.3669 11.8371 12.399 11.9985 12.341 12.1388C12.283 12.279 12.1461 12.3705 11.9943 12.3705C11.8426 12.3705 11.7057 12.279 11.6477 12.1388C11.5897 11.9985 11.6218 11.8371 11.7292 11.7299C11.8757 11.5836 12.113 11.5836 12.2595 11.7299"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9963 19.9989V3.99219"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9964 16.9973H4.99302C3.88799 16.9973 2.99219 16.1015 2.99219 14.9965V8.994C2.99219 7.88897 3.88799 6.99316 4.99302 6.99316H12.9964"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9961 16.9973H18.9973C20.1024 16.9973 20.9982 16.1015 20.9982 14.9965V8.994C20.9982 7.88897 20.1024 6.99316 18.9973 6.99316H15.9961"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4961 19.9983H18.4982"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4961 3.99142H18.4982"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
