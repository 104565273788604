import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const CircleErrorIcon = ({
  width = 25,
  height = 24,
  color = theme.colors.primary["60"],
  secondColor = theme.colors.primary["155"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 21C7.529 21 3.5 16.971 3.5 12C3.5 7.029 7.529 3 12.5 3C17.471 3 21.5 7.029 21.5 12C21.5 16.971 17.471 21 12.5 21Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.33 9.17004L9.67004 14.83"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.33 14.83L9.67004 9.17004"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
