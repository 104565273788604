import {
  buildTestIds,
  useAppContext,
  useCartContext,
  useClickOutside,
  useModalGroup,
} from "application";
import { useRouter } from "next/router";
import { useCallback, useMemo, useRef, useState } from "react";
import ModalItems from "./ModalItems";
import {
  CartHyperLink,
  CartIconUi,
  ContainerButton,
  CountItens,
} from "./styles";

const CartButton = () => {
  const { isClientMobile } = useAppContext();
  const { modalToConfirmEmail } = useModalGroup();
  const {
    cartItems,
    removeItemCart,
    id: cartId,
    isGetCartLoading,
  } = useCartContext();
  const [isOpenStatus, setStatusModal] = useState(false);
  const ModalCartRef = useRef<HTMLElement>(null);
  const router = useRouter();

  const COUNT_ITEMS = useMemo(
    () =>
      cartItems?.reduce(
        (previousSum: number, currentItem) =>
          currentItem.quantity + previousSum,
        0
      ) || 0,
    [cartItems]
  );

  const handleModal = () => {
    if (isClientMobile) {
      router.push("/checkout/meu-carrinho");
      return;
    }
    setStatusModal((state) => !state);
  };

  const handleRemoveItem = useCallback(
    (idProductCart: number) => {
      removeItemCart({ idProductCart, cartId });
    },
    [cartId, removeItemCart]
  );

  const navigateCart = useCallback(() => {
    router.push("/checkout/meu-carrinho");
    setStatusModal((state) => !state);
  }, [router]);

  // useEffect(() => {
  //   if (isOpenStatus) {
  //     fetchCart();
  //   }
  // }, [fetchCart, isOpenStatus]);

  useClickOutside({
    ref: ModalCartRef,
    callback: () => {
      if (isOpenStatus && !modalToConfirmEmail) {
        setStatusModal(false);
      }
    },
  });

  return (
    <ContainerButton
      {...buildTestIds("box-details-info-button-success")}
      ref={ModalCartRef}
      className="cart-btn"
    >
      <CartHyperLink
        {...buildTestIds("cart-hyper-link")}
        onClick={handleModal}
        className="cart-btn"
        isLoading={isGetCartLoading}
      >
        <CartIconUi alt="Carrinho" />
      </CartHyperLink>
      {COUNT_ITEMS ? (
        <CountItens {...buildTestIds("counter-itens")} onClick={handleModal}>
          {COUNT_ITEMS > 99 ? "99+" : COUNT_ITEMS}
        </CountItens>
      ) : null}
      <ModalItems
        {...buildTestIds("modal-item-header")}
        navigate={navigateCart}
        isOpen={isOpenStatus}
        deleteAction={handleRemoveItem}
        closeModal={() => setStatusModal(false)}
      />
    </ContainerButton>
  );
};
export { CartButton };
