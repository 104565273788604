// import { rest } from "msw";
import { mockAsyncHttpRequest } from "../utils";
import data from "./data";

// export const supportContactHandlers = [
//     rest.get("/support-contact/options", (req, res, ctx) => {
//         return res(ctx.json(data));
//     }),
// ];

export const supportContactHandlers = {
  "/support-contact/options": async () => {
    return mockAsyncHttpRequest(data);
  },
};
