import styled, { css } from "styled-components";
import { CarouselThemeDto } from "typing";
import { theme } from "ui";

interface CustomExtendedCarouselProps {
  customTheme?: CarouselThemeDto;
}

export const CustomExtendedCarousel = styled.section<CustomExtendedCarouselProps>`
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;

  @media ${theme.device.desktopFirst.laptopXL} {
    margin: 0;
    max-width: none;
  }

  .slick-prev,
  .slick-next {
    margin-top: 2rem;
  }

  .slick-prev {
    margin-left: 3.5rem !important;
  }

  .slick-next {
    margin-right: 3.5rem !important;
  }

  ${({ customTheme }) =>
    customTheme &&
    css`
      .slick-disabled {
        background: ${customTheme?.sliderButton?.backgroundColor?.disabled};
        opacity: 1;
        visibility: visible;

        &:hover {
          background: ${customTheme?.sliderButton?.backgroundColor?.disabled};
        }
        cursor: default;

        path {
          stroke: ${customTheme?.sliderButton?.arrowColor?.disabled};
        }

        &:hover path {
          stroke: ${customTheme?.sliderButton?.arrowColor?.disabled};
        }
      }
    `}

  .slick-dots {
    @media ${theme.device.desktopFirst.mobileXL} {
      bottom: -16px;
    }
  }
`;
