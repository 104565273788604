import { buildTestIds } from "application";
import { memo } from "react";
import { RecommendationTypeProps } from "typing";
import { RecommendationTypeBadgeContainer } from "./styles";
import HighestDiscountedBadge from "./HighestDiscountedBadge";
import MostPurchasedBadge from "./MostPurchasedBadge";
import TrendingProductsBadge from "./TrendingProductsBadge";

const RecommendationTypeBadge = memo(
  ({
    recommendationTypeAbbreviation,
    recommendationTypeText,
  }: RecommendationTypeProps) => {
    return (
      <RecommendationTypeBadgeContainer
        {...buildTestIds("recommendation-type-badge-container")}
      >
        {recommendationTypeAbbreviation === "hd" ? (
          <HighestDiscountedBadge
            recommendationTypeText={recommendationTypeText}
          />
        ) : recommendationTypeAbbreviation === "mp" ? (
          <MostPurchasedBadge recommendationTypeText={recommendationTypeText} />
        ) : recommendationTypeAbbreviation === "tp" ? (
          <TrendingProductsBadge
            recommendationTypeText={recommendationTypeText}
          />
        ) : null}
      </RecommendationTypeBadgeContainer>
    );
  }
);

RecommendationTypeBadge.displayName = "RecommendationTypeBadge";

export default RecommendationTypeBadge;
