import { IconProps } from "typing";

export const NoAddress = ({ width = 220, height = 162 }: IconProps) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 220.061 161.762"
    >
      <defs>
        <style>
          {
            ".a{fill:#ededed;}.b{fill:#f7f7f7;}.c{fill:#dbdbdb;}.d{fill:url(#a);}.e{fill:url(#b);}.f{fill:rgba(246,214,219,0.32);stroke:#eba6b1;}.g{fill:#ba0f2b;}.h,.i,.j{fill:#222235;font-family:Barlow-SemiBold, Barlow;font-weight:600;letter-spacing:0.01em;}.h{font-size:18px;}.i{font-size:12px;opacity:0.657;}.j{font-size:14px;opacity:0.81;}.k{stroke:none;}.l{fill:none;}"
          }
        </style>
        <linearGradient
          id="a"
          x1={0.559}
          y1={0.611}
          x2={0.86}
          y2={0.533}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <radialGradient
          id="b"
          cx={0.488}
          cy={0.5}
          r={0.47}
          gradientTransform="matrix(0.953, -0.304, 0.29, 0.91, -0.123, 0.185)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.724} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
      </defs>
      <g transform="translate(-332.939 -193.954)">
        <g transform="translate(22 77.906)">
          <path
            className="a"
            d="M.649,0H15.022V14.373L0,20.14Z"
            transform="translate(353.519 245.193) rotate(21)"
          />
          <path
            className="b"
            d="M.577.207,16.078,0l-.455,20.4L-1.064,27.914Z"
            transform="translate(335.466 238.526) rotate(21)"
          />
          <path
            className="a"
            d="M0,.027,9.841,0,2.433,6.366.758,2Z"
            transform="translate(321.075 233.125) rotate(21)"
          />
          <path
            className="c"
            d="M17.546,0,15.195,19.4,1.675,17.822,0,13.458Z"
            transform="translate(325.889 209.227) rotate(21)"
          />
          <path
            className="c"
            d="M-1.828-22.482l18,43.447L2.765,27.451-10.579-19.123Z"
            transform="translate(425.889 170.227) rotate(21)"
          />
          <path
            className="c"
            d="M5.306,13.721l2.122,5.529L-2.924,23.225-6.4,14.171Z"
            transform="translate(407.889 250.227) rotate(21)"
          />
          <path
            className="c"
            d="M5.306,13.721l2.122,5.529-15.123,7.9L-4.538,6.446Z"
            transform="translate(464.889 222.227) rotate(21)"
          />
          <path
            className="c"
            d="M12.59-23.976l9.6,48.4L1.675,17.822-.632,3.646Z"
            transform="translate(385.953 166.227) rotate(21)"
          />
          <path
            className="c"
            d="M-9.2-15.723,19.485,17.144,7.646,28.159l-11.7-17.47Z"
            transform="translate(464.953 161.227) rotate(21)"
          />
          <path
            className="b"
            d="M19.393-.77l21.894,6.8L-.071,32.389.993,4.185Z"
            transform="translate(360.62 180.227) rotate(21)"
          />
          <path
            className="b"
            d="M46.71,3.424l-18.951,17.6-30.2,16.168L-7.893,21.2,57.831-19.5Z"
            transform="translate(336.62 148.227) rotate(21)"
          />
          <path
            className="b"
            d="M-2.549-9.175l12.61,47.63-7.6,4.632L-9.629-6.457Z"
            transform="translate(416.62 158.227) rotate(21)"
          />
          <path
            className="b"
            d="M-2.549-9.175,3.038,14.348l-16.9.113L-19.284,3.039Z"
            transform="translate(455.62 153.227) rotate(21)"
          />
          <path
            className="b"
            d="M-2.549-9.175,3.038,14.348-2.508,43.973-19.284,3.039Z"
            transform="translate(457.62 205.227) rotate(21)"
          />
          <path
            className="a"
            d="M23.6-24.111,21.942,6.679,3.05,20.174-7.8-8.083Z"
            transform="translate(325.448 197.696) rotate(21)"
          />
          <path
            className="b"
            d="M0,7.226,8.521,0,6.77,24.862.758,9.2Z"
            transform="translate(323.655 236.736) rotate(21)"
          />
          <path
            className="b"
            d="M.252,9.505,14.551,0l-.512,19.332L0,19Z"
            transform="translate(362.943 223.801) rotate(21)"
          />
          <path
            className="b"
            d="M.366,1.454,12.252-5.99l7.593,28.354L.18,22.312Z"
            transform="translate(380.415 226.801) rotate(21)"
          />
          <path
            className="b"
            d="M-11.549-16.395,2.262-23.443,19.845,22.364,6.37,40Z"
            transform="translate(423.415 221.801) rotate(21)"
          />
          <path
            className="b"
            d="M-1.973,13.048-6.109-31.706,19.5-5.25,6.091,37.654Z"
            transform="translate(476.415 222.801) rotate(21)"
          />
          <path
            className="b"
            d="M0,5.889,8.836,0,8.38,5.7l-4.49.216Z"
            transform="translate(345.688 231.24) rotate(21)"
          />
          <path
            className="a"
            d="M1.665,9.282,16.49,0,15.478,20.509,0,31Z"
            transform="translate(348.972 204.858) rotate(21)"
          />
          <path
            className="a"
            d="M16.057,24.228l4-5.551L21.835,28.9l-14.421,3.4Z"
            transform="translate(366.972 147.858) rotate(21)"
          />
          <path
            className="a"
            d="M12.4,21.924,79.765-7.846,70.718.774,7.017,40.593Z"
            transform="translate(329.972 120.858) rotate(21)"
          />
          <path
            className="a"
            d="M-3.49,15.128l7.8-4.406L21.664,65.808l-12.072.459Z"
            transform="translate(411.972 190.858) rotate(21)"
          />
          <path
            className="a"
            d="M-9.866,26.353l12.008-11.4L5.231,56.994-3.1,51.943Z"
            transform="translate(481.972 174.858) rotate(21)"
          />
          <path
            className="a"
            d="M-29.906,28.752l32.048-13.8,27.3,73.907L-.7,60.637Z"
            transform="translate(503.972 125.858) rotate(21)"
          />
          <path
            className="a"
            d="M20.352,76.434-5.006,89.609l-13.956,5.357L-2.856,73.718,15.76,64.47Z"
            transform="translate(472.972 181.858) rotate(21)"
          />
          <path
            className="a"
            d="M-15.584,9.544l15.729.2L11.708,26.439-4.109,35.678Z"
            transform="translate(455.972 162.858) rotate(21)"
          />
          <path
            className="a"
            d="M.743,5.293l20.075-.367,4.416,11.5L.959,18.677Z"
            transform="translate(372.972 246.858) rotate(21)"
          />
          <path
            className="b"
            d="M0,0,24.608-16.95,28.119.3.338,18.721Z"
            transform="translate(367.857 211.205) rotate(21)"
          />
        </g>
        <rect
          className="d"
          width={195}
          height={131}
          transform="translate(358 219)"
        />
        <rect
          className="e"
          width={211}
          height={136}
          transform="translate(340 214)"
        />
        <g className="f" transform="translate(401 265)">
          <ellipse className="k" cx={29} cy={15.5} rx={29} ry={15.5} />
          <ellipse className="l" cx={29} cy={15.5} rx={28.5} ry={15} />
        </g>
        <path
          className="g"
          d="M50.5-174.069c-.96,0-2.659-3.569-3.929-6.91C45.057-184.958,44-188.9,44-190.557a6.372,6.372,0,0,1,1.9-4.556A6.487,6.487,0,0,1,50.5-197a6.486,6.486,0,0,1,4.6,1.887,6.373,6.373,0,0,1,1.9,4.556c0,1.66-1.057,5.6-2.57,9.578C53.159-177.638,51.46-174.069,50.5-174.069Zm0-19.57a3.1,3.1,0,0,0-3.109,3.081,3.1,3.1,0,0,0,3.109,3.081,3.1,3.1,0,0,0,3.109-3.081A3.1,3.1,0,0,0,50.5-193.639Z"
          transform="translate(380 451)"
        />
        <text className="h" transform="translate(466 307)">
          <tspan x={0} y={0}>
            ?
          </tspan>
        </text>
        <text className="i" transform="translate(450 248)">
          <tspan x={0} y={0}>
            ?
          </tspan>
        </text>
        <text className="j" transform="translate(381 275)">
          <tspan x={0} y={0}>
            ?
          </tspan>
        </text>
      </g>
    </svg>
  );
};
