import styled from "styled-components";
import { theme } from "ui";

export const ErrorPageContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  background-color: ${theme.colors.neutral["25"]};
`;

export const ErrorText = styled.h2`
  font-size: ${theme.typography.fontSize.md.x3};
  color: ${theme.colors.neutral["520"]};
  text-align: center;

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x9};
  }
`;
