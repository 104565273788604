import { TwoFactorContentProps, UniqueClientDTO } from "typing";
import { useUniqueClientValidationData } from "./data";
import { UniqueClientValidationUI } from "./ui";

interface UniqueClientValidationProps {
  clientInfo?: UniqueClientDTO | null;
}
const UniqueClientValidation = ({
  clientInfo,
}: UniqueClientValidationProps) => {
  const {
    getUniqueClientValidationData,
    isLoading,
    shouldShowComponent,
    currentStep,
  } = useUniqueClientValidationData(clientInfo);

  return (
    <UniqueClientValidationUI
      {...(Object.fromEntries(
        getUniqueClientValidationData()
      ) as unknown as TwoFactorContentProps)}
      isLoading={isLoading}
      shouldShowComponent={shouldShowComponent}
      currentStep={currentStep}
    />
  );
};

export { UniqueClientValidation };
