import styled from "styled-components";
import { theme } from "ui";
import { Link } from "../Link";

interface ConfirmButtonProps {
  isClientMobile: boolean;
  isButtonDisabled?: boolean;
}

export const BlockScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.neutral["999-opacity-15"]};
  z-index: 9999;

  transition: all 0.4s ease-in-out;
`;

export const ButtonContainer = styled.button<ConfirmButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: ${({ isClientMobile }) => (isClientMobile ? "100%" : "11.5rem")};
  border-radius: 6px;
  padding: 0.75rem 1.25rem;
  cursor: ${({ isButtonDisabled }) =>
    isButtonDisabled ? "default" : "pointer"};
  opacity: ${({ isButtonDisabled }) => (isButtonDisabled ? "0.5" : "1")};
  font-size: 1.25rem;
  font-family: ${theme.typography.fontFamily.barlow}, sans-serif;
  font-weight: 500;
  text-shadow: 0 1px 0 ${theme.colors.neutral["830"]};
  background: ${theme.colors.secondary["360"]};
  color: ${theme.colors.neutral.white};
  border: 0;
  transition: all 0.2s ease;
  &:hover {
    background: ${theme.colors.secondary["420"]};
  }
`;

export const ActionLink = styled(Link)``;

export const ConfirmPaymentBody = styled.section`
  font-size: 1.5rem;
  width: 100%;
  max-width: 50rem;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
  min-width: 17rem;
`;

export const ConfirmPaymentHeader = styled.span`
  display: flex;
  flex-direction: column;
  text-align: start;
  font-weight: 600;
  color: ${theme.colors.neutral["550"]};
  font-size: ${theme.typography.fontSize.sm.x7};
  gap: ${theme.space.x2};

  @media ${theme.device.desktopFirst.mobileS} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const ConfirmPaymentFieldSet = styled.span`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: ${theme.space.x2};
  color: ${theme.colors.neutral["520"]};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ConfirmPaymentFieldSetLegend = styled.legend`
  text-align: start;
  width: 100%;
  color: ${theme.colors.neutral["520"]};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: 400;
  @media ${theme.device.desktopFirst.mobileM} {
    margin-left: 0;
  }
  @media ${theme.device.desktopFirst.mobileS} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;
