import styled from "styled-components";
import { theme } from "ui";

interface RequiredWarningTextProps {
  highlight?: boolean;
  marginLeft?: string;
}

export const RequiredWarningContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${theme.space.x3};
`;

export const RequiredWarningText = styled.span<RequiredWarningTextProps>`
  color: ${({ highlight }) =>
    highlight ? theme.colors.primary["225"] : theme.colors.neutral["550"]};

  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight["600"]};
  margin-left: ${({ marginLeft }) => marginLeft ?? "0"};
`;
