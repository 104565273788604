import { create as zustandCreate } from "zustand";
import { useShallow as zustandUseShallow } from "zustand/react/shallow";

type CreateStore<T> = T;

type Shallow = <S, U>(selector: (state: S) => U) => (state: S) => U;

const createStore: CreateStore<typeof zustandCreate> = zustandCreate;

const useShallow: Shallow = zustandUseShallow;

export { createStore, useShallow };
