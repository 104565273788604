const formatCurrency = (value: number): string => {
  try {
    if (typeof value !== "number" || Number.isNaN(value)) {
      return "";
    }

    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  } catch {
    return "";
  }
};

export { formatCurrency };
