import { IconProps } from "typing";
import { theme } from "../../themes";

export const FaceAuthenticationIcon = ({
  width = theme.space.x15,
  height = theme.space.x15,
  strokeWidth = "1.5",
  alt = "Autenticação por Biometria Facial",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="60" height="60" rx="30" fill="#F6F7F7" />
      <path
        d="M24.5007 26.1074V28.1074"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5007 26.1074V28.1074"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.167 32.1304H29.8337C30.5697 32.1304 31.167 31.533 31.167 30.797V26.1304"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8232 35.5435C32.4365 37.9301 28.5659 37.9301 26.1792 35.5435"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6178 18.001H21.1672C19.6938 18.001 18.5005 19.1943 18.5005 20.6676V24.1183"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3833 42.0011H39.834C41.3073 42.0011 42.5006 40.8078 42.5006 39.3345V35.8838"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5005 35.8838V39.3345C18.5005 40.8078 19.6938 42.0011 21.1672 42.0011H24.6178"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.5006 24.1183V20.6676C42.5006 19.1943 41.3073 18.001 39.834 18.001H36.3833"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
