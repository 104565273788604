import { IconProps } from "typing";
import { theme } from "../themes";

export const Map = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.8333V10.8333C8.61917 10.8333 7.5 9.71408 7.5 8.33325V8.33325C7.5 6.95242 8.61917 5.83325 10 5.83325V5.83325C11.3808 5.83325 12.5 6.95242 12.5 8.33325V8.33325C12.5 9.71408 11.3808 10.8333 10 10.8333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.5C10 17.5 4.16666 12.7083 4.16666 8.33333C4.16666 5.11167 6.77833 2.5 10 2.5C13.2217 2.5 15.8333 5.11167 15.8333 8.33333C15.8333 12.7083 10 17.5 10 17.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
