import { useUser } from "application";
import { NewAddressFormComponents } from "typing";
import { theme } from "ui";
import { CustomInputNewAddress, WrapperInput } from "../../../styles";

type RecipientComponentProps = NewAddressFormComponents;

export const RecipientComponent = ({
  control,
  hasError,
  requiredFieldText,
}: RecipientComponentProps) => {
  const { loggedClient } = useUser();
  return (
    <WrapperInput>
      <CustomInputNewAddress
        id="recipient"
        name="recipient"
        type="text"
        placeholder={`Ex. ${loggedClient?.name}`}
        required={requiredFieldText}
        control={control}
        hasError={!!hasError}
        margin={`${theme.space.x2} ${theme.space.x0}`}
        width="100%"
        label="Nome do destinatário"
      />
    </WrapperInput>
  );
};
