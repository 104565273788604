import { CoupleNamesDto, ShippingBranchDto, WeddingListDtoFull } from "typing";

const getIsListInactive = (
  weddingList: CoupleNamesDto | WeddingListDtoFull | null | undefined
) => {
  return weddingList?.status !== "Aberta";
};

const sortingFavoritesStoresList = (
  array: ShippingBranchDto[] | null | undefined
) => {
  return array?.sort((a, b) => {
    const citiesList = a.city.localeCompare(b.city);

    if (citiesList === 0) {
      return a.district.localeCompare(b.district);
    }

    return citiesList;
  });
};

export { getIsListInactive, sortingFavoritesStoresList };
