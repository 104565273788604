import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const OfflineIcon = ({
  width = "184",
  height = "161",
  color = theme.colors.neutral["150"],
  secondColor = theme.colors.yellow["110"],
  thirdColor = theme.colors.neutral["240"],
  fourthColor = theme.colors.neutral["550"],
  fifthColor = theme.colors.neutral["560"],
  sixthColor = theme.colors.neutral["245"],
  seventhColor = theme.colors.primary["150"],
  octaveColor = theme.colors.neutral.white,
  ninthColor = theme.colors.primary["355"],
  tenthColor = theme.colors.primary["155"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 184 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_324_1292)">
        <path
          d="M80.3036 10.6768C85.2939 1.94152 97.8891 1.94154 102.879 10.6768L177.764 141.758C182.715 150.424 176.457 161.206 166.476 161.206H16.7071C6.72604 161.206 0.468214 150.424 5.41925 141.758L80.3036 10.6768Z"
          fill={color}
        />
        <path
          d="M118.344 53.5231L127.04 41.7136L125.479 50.2524L130.855 51.2358L121.294 64.1947L123.72 54.5065L118.344 53.5231Z"
          fill={secondColor}
        />
        <path
          d="M56.042 55.729L56.9411 41.0903L60.2614 49.1107L65.3113 47.0201L64.3101 63.0929L61.0919 53.6384L56.042 55.729Z"
          fill={secondColor}
        />
        <g filter="url(#filter0_d_324_1292)">
          <rect
            x="70.9788"
            y="40.6553"
            width="43.0681"
            height="50.2616"
            rx="7"
            fill={octaveColor}
          />
        </g>
        <rect
          x="72.0042"
          y="42.21"
          width="41.0172"
          height="47.1526"
          rx="7"
          fill="url(#paint0_linear_324_1292)"
        />
        <g clipPath="url(#clip1_324_1292)">
          <ellipse
            cx="92.7691"
            cy="65.7858"
            rx="12.5615"
            ry="12.6949"
            fill={thirdColor}
          />
          <ellipse
            cx="92.7691"
            cy="67.858"
            rx="12.5615"
            ry="12.6949"
            fill={color}
          />
        </g>
        <rect
          x="80.7075"
          y="53.5908"
          width="24.1231"
          height="24.3899"
          rx="12.0615"
          stroke="url(#paint1_linear_324_1292)"
        />
        <rect
          x="87.8984"
          y="60.8633"
          width="2.05086"
          height="5.18161"
          fill={fourthColor}
        />
        <rect
          x="95.5891"
          y="60.8633"
          width="2.05085"
          height="5.18161"
          fill={fourthColor}
        />
        <path
          d="M95.5891 72.7808C95.5891 71.9563 95.265 71.1655 94.6881 70.5825C94.1112 69.9994 93.3287 69.6719 92.5128 69.6719C91.6969 69.6719 90.9145 69.9994 90.3375 70.5825C89.7606 71.1655 89.4365 71.9563 89.4365 72.7808L92.5128 72.7808H95.5891Z"
          fill={fourthColor}
        />
        <path
          opacity="0.45"
          d="M92.7775 111.384C92.7775 111.384 93.0339 123.561 88.6758 134.701C84.3178 145.841 79.4469 150.79 70.7308 153.873C64.826 155.962 49.4445 153.355 53.5463 147.914C57.648 142.473 76.3621 148.432 85.591 149.21C94.0508 149.987 95.0762 150.764 106.612 150.764C114.816 150.764 123.532 149.728 132.504 155.168C141.477 160.609 146.348 157.241 149.424 155.168C152.5 153.096 155.064 152.578 158.909 152.578C162.755 152.578 173.009 153.873 173.009 153.873"
          fill={fifthColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.7775 111.384C92.7775 111.384 93.0339 123.561 88.6758 134.701C84.3177 145.841 79.4469 150.79 70.7308 153.873C67.9108 154.87 54.3238 155.946 51.5039 148.432C48.684 140.919 57.6565 135.737 69.9617 139.364C74.3197 140.439 78.9342 143.467 87.394 147.137C94.572 150.251 103.032 152.578 113.543 149.21C128.411 143.51 134.82 127.965 134.82 127.965C134.82 127.965 140.452 113.716 147.886 117.084C155.32 120.452 153.782 131.333 153.782 136.515C153.782 141.696 154.038 148.691 159.422 151.282C164.806 153.873 173.009 153.873 173.009 153.873"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="87.394"
          y="86.7715"
          width="2.5636"
          height="11.3995"
          rx="1.2818"
          fill={secondColor}
        />
        <rect
          x="95.5974"
          y="86.7715"
          width="2.56358"
          height="11.3995"
          rx="1.28179"
          fill={secondColor}
        />
        <path
          d="M87.9067 107.326C87.9067 106.849 88.2934 106.462 88.7703 106.462H96.272C96.749 106.462 97.1356 106.849 97.1356 107.326C97.1356 109.71 95.2024 111.644 92.8176 111.644H92.2247C89.84 111.644 87.9067 109.71 87.9067 107.326Z"
          fill="url(#paint2_linear_324_1292)"
        />
        <path
          d="M83.2837 96.0254C83.2837 94.9208 84.1791 94.0254 85.2837 94.0254H100.254C101.359 94.0254 102.254 94.9208 102.254 96.0254V97.4942C102.254 102.733 98.0075 106.979 92.7689 106.979C87.5304 106.979 83.2837 102.733 83.2837 97.4942V96.0254Z"
          fill="url(#paint3_linear_324_1292)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_324_1292"
          x="42.9788"
          y="12.6553"
          width="99.0681"
          height="106.262"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_324_1292"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="13" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_324_1292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_324_1292"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_324_1292"
          x1="72.0042"
          y1="62.7352"
          x2="113.021"
          y2="62.7352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.528337" stopColor={sixthColor} />
          <stop offset="1" stopColor={octaveColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_324_1292"
          x1="92.5127"
          y1="68.8947"
          x2="92.7745"
          y2="78.4806"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor={octaveColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_324_1292"
          x1="92.5127"
          y1="110.866"
          x2="94.0799"
          y2="106.472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={seventhColor} />
          <stop offset="1" stopColor={ninthColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_324_1292"
          x1="96.291"
          y1="91.6937"
          x2="91.8204"
          y2="107.427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={seventhColor} />
          <stop offset="1" stopColor={tenthColor} />
        </linearGradient>
        <clipPath id="clip0_324_1292">
          <rect
            width="183"
            height="161"
            fill={octaveColor}
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1_324_1292">
          <rect
            x="80.2075"
            y="53.0908"
            width="25.1231"
            height="25.3899"
            rx="12.5615"
            fill={octaveColor}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
