import styled from "styled-components";
import { FlexContainer } from "../Container";
import { theme } from "../themes";

type OnFocusProps = {
  borderColor?: string;
};

export interface InputSearchProps {
  backgroundColor?: string;
  onFocusProps?: OnFocusProps;
  width?: string;
  height?: string;
  fontSize?: string;
  fontColor?: string;
  fontWeight?: string;
  hasFocusOnInputSearch?: boolean;
  hideBorder?: boolean;
}

export const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  display: flex;
  visibility: hidden;
  background: ${theme.colors.neutral["999-opacity-50"]};
  transition: opacity 0.2s ease;
`;

export const HeaderSearchbarFlexContainer = styled(
  FlexContainer
)<InputSearchProps>`
  flex-direction: column;
  width: ${theme.space["x1/1"]};

  @media ${theme.device.desktopFirst.laptop} {
    max-width: 690px !important;
  }

  @media ${theme.device.desktopFirst.tablet} {
    max-width: 440px;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    max-width: 440px;
  }
`;

export const InputSearchFlexContainer = styled(FlexContainer)`
  flex-direction: row;
`;

export const InputSearch = styled.input<InputSearchProps>`
  width: ${({ width }) => width ?? theme.space["x1/1"]};
  margin-top: 0;
  height: ${({ height }) => height ?? "4.17rem"};
  padding: 1.8rem;
  background: ${({ backgroundColor }) =>
    backgroundColor ?? theme.colors.neutral.white};
  border: ${({ hideBorder }) =>
    hideBorder
      ? `1px solid ${theme.colors.neutral.white}`
      : `1px solid ${theme.colors.neutral["100"]}`};
  border-radius: 4px 0 0 4px;
  font-family: "Chivo", Arial, Helvetica, sans-serif;
  font-size: ${({ fontSize }) => fontSize || "1.5rem"};
  font-weight: ${({ fontWeight }) => fontWeight || "500"};
  z-index: ${({ hasFocusOnInputSearch }) => (hasFocusOnInputSearch ? 6 : 0)};
  order: 0;
  transition: border 0.2s ease;

  &:focus ~ ${OverlayContainer} {
    z-index: 5;
    opacity: 1;
    visibility: visible;
    border-color: ${({ onFocusProps }) => onFocusProps?.borderColor || "none"};
    box-shadow: 0 4px 8px 0 ${theme.colors.neutral["999-opacity-20"]},
      0 6px 20px 0 ${theme.colors.neutral["999-opacity-19"]};
  }

  &::placeholder {
    color: ${({ fontColor }) => fontColor || theme.colors.neutral["400"]};
    padding-left: ${theme.space["x0.5"]};
    font-size: 1.5rem;
  }

  &::-webkit-calendar-picker-indicator {
    display: none !important;
  }

  @media ${theme.device.desktopFirst.laptop} {
    font-size: ${({ fontSize }) => fontSize || "1.2rem"};
    /* max-width: 690px !important; */
  }

  @media ${theme.device.desktopFirst.tablet} {
    height: 3.75rem;
    /* max-width: 440px; */
  }

  @media ${theme.device.desktopFirst.tabletS} {
    order: 2;
    border-radius: 0 4px 4px 0;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    height: 3rem;
    /* max-width: 440px; */
    padding: 1.1rem 1rem 1rem 0.7rem;
    border-radius: 0 4px 4px 0;
    font-size: 1.2rem;
  }
`;
