import { Head } from "infra/components";
import { ReactNode } from "react";

interface AddToHeadProps {
  children: ReactNode;
}

export const AddToHead = ({ children }: AddToHeadProps) => {
  return <Head>{children}</Head>;
};
