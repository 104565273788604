import styled, { css } from "styled-components";
import { ArrowLeft, FlexContainer, theme } from "ui";

export const ContentInformation = styled(FlexContainer)`
  position: relative;
  width: 100%;
  display: flex;
  gap: ${theme.space["x2.5"]};
  justify-content: center;
  height: 32px;
  align-items: center;
  margin-bottom: 24px;

  @media ${theme.device.desktopFirst.mobileXL} {
    justify-content: start;
  }
`;

export const ArrowLeftIcon = styled(ArrowLeft).attrs({
  height: "24px",
  width: "24px",
  color: theme.colors.neutral["600"],
})``;

export const WrapperIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-12"]};
`;

export const RecoverAccessText = styled.span`
  color: ${theme.colors.neutral["550"]} !important;
  font-size: ${theme.typography.fontSize.sm.x9};
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight.bold};
`;

export const ChangeNewPasswordContent = styled.div`
  max-width: ${theme.space.x112};
  width: 100%;
`;

export const ChangeNewPasswordContainer = styled.div<{
  isLogin: boolean;
}>`
  display: flex;
  justify-content: center;

  ${({ isLogin }) =>
    isLogin &&
    css`
      padding: 0 16px;
      align-items: start;
    `}
`;
