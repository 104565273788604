import styled, { css } from "styled-components";
import { theme } from "ui";

export type ButtonArrowProps = {
  isCentralized: boolean;
  customTheme?: boolean;
  arrowButtonsSize?: "small" | "large";
};

export const ButtonArrowNext = styled.div<ButtonArrowProps>`
  height: ${({ arrowButtonsSize }) =>
    arrowButtonsSize === "small" ? "32px" : "60px"} !important;
  width: ${({ arrowButtonsSize }) =>
    arrowButtonsSize === "small" ? "32px" : "60px"} !important;
  border-radius: 30px;

  top: ${({ isCentralized }) =>
    isCentralized ? "50%" : "calc(50% - 25px)"} !important;

  padding: 0px 0px 0px 4px !important;
  margin-right: 22px !important;

  background: ${({ customTheme }) =>
    customTheme
      ? theme?.colors?.blackFriday?.["50"]
      : theme.colors.neutral.white} !important;
  box-shadow: ${`${theme.space.x0} ${theme.space["x0.5"]} ${theme.space["x0.5"]}
    ${theme.colors.neutral["999-opacity-25"]}`};

  &:hover {
    background: ${theme.colors.primary["200"]} !important;
    transition: 0.8s !important;
  }

  &:hover path {
    stroke: ${theme.colors.neutral.white};
  }

  svg {
    ${({ arrowButtonsSize }) =>
      arrowButtonsSize === "small" &&
      css`
        width: 10px !important;
      `}
    margin-top: ${({ arrowButtonsSize }) =>
      arrowButtonsSize === "small" ? "3px" : "17px"} !important;
    margin-left: ${({ arrowButtonsSize }) =>
      arrowButtonsSize === "small" ? "7px" : "18px"} !important;
  }

  @media (max-width: 1270px) {
    margin-right: 25px;
  }

  ${({ customTheme }) =>
    customTheme &&
    css`
      background: ${theme?.colors?.blackFriday?.["50"]};

      svg {
        ${customTheme &&
        css`
          path {
            stroke: ${theme?.colors?.blackFriday?.["20"]} !important;
          }
        `}
      }

      &:hover {
        background: ${theme?.colors?.primary?.["150"]};
        transition: 0.8s;

        svg {
          ${customTheme &&
          css`
            path {
              stroke: ${theme?.colors?.blackFriday?.white} !important;
            }
          `}
        }
      }
    `}
`;
