import { buildTestIds } from "application";
import { Dispatch, SetStateAction } from "react";
import {
  CloseButton,
  CloseIcon,
  IconAndTextContainer,
  MessageContainer,
  RevalidationMessage,
  RevalidationMessageContainer,
  Warning,
  WarningIconContainer,
} from "./styles";

interface NoticeProps {
  text: string;
  fontSize?: string;
  handleShowRevalidationMessage?: Dispatch<SetStateAction<boolean>>;
  hasCloseButton?: boolean;
}

export const Notice = ({
  text,
  handleShowRevalidationMessage,
  fontSize,
  hasCloseButton = true,
}: NoticeProps) => {
  return (
    <RevalidationMessageContainer
      {...buildTestIds("revalidation-message-container")}
    >
      <MessageContainer {...buildTestIds("revalidation-message")}>
        <IconAndTextContainer
          {...buildTestIds("revalidation-message-icon-and-text")}
        >
          <WarningIconContainer
            {...buildTestIds("revalidation-message-warning-icon")}
          >
            <Warning alt="atenção" />
          </WarningIconContainer>

          <RevalidationMessage
            fontSize={fontSize}
            {...buildTestIds("revalidation-message-text")}
          >
            {text}
          </RevalidationMessage>
        </IconAndTextContainer>
        {hasCloseButton && (
          <CloseButton
            {...buildTestIds("revalidation-message-close-button")}
            onClick={() => {
              if (handleShowRevalidationMessage)
                handleShowRevalidationMessage(false);
            }}
          >
            <CloseIcon />
          </CloseButton>
        )}
      </MessageContainer>
    </RevalidationMessageContainer>
  );
};
