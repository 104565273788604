import styled, { css } from "styled-components";
import { theme } from "ui";

interface CountdownStylesProps {
  hasSeparator?: "true" | "false";
  color?: string;
  dateColor?: string;
  isSmall?: boolean;
  hasImage?: boolean | string;
  hasBackground?: boolean;
  backgroundImage?: string;
  isComplete?: boolean;
  backgroundColor?: string;
  backgroundType?: "image" | "solidColor";
  showDays?: boolean;
}

export const CustomCountdownWrapper = styled.section<CountdownStylesProps>`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  border-radius: 10px;
  padding: 1.3rem 0;
  object-fit: cover;
  object-position: center;
  align-items: center;
  justify-content: center;

  @media ${theme.device.desktopFirst.tablet} {
    padding: ${theme.space.x3} ${theme.space.x0};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    min-height: 120px;
    border-radius: ${theme.space["x2.5"]};
    padding: ${theme.space.x2} ${theme.space.x0};
    gap: ${theme.space.x0};
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      flex-direction: row;
      justify-content: space-between;
      padding: 0.7rem 0.67rem 0.7rem 0.67rem;

      @media ${theme.device.desktopFirst.tablet} {
        gap: ${theme.space.x6};
        padding: 0.5rem 1.87rem 0.5rem 0.67rem;
      }

      @media ${theme.device.desktopFirst.mobileXL} {
        min-height: 50px;
        padding-bottom: ${theme.space.x0};
        gap: ${theme.space.x0};
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: ${theme.space.x2} ${theme.space.x0};
      }
    `};

  ${({ isComplete }) =>
    isComplete &&
    css`
      @media ${theme.device.desktopFirst.mobileXL} {
        padding: ${theme.space["x4.5"]} ${theme.space.x0};
      }
    `}
`;

export const TimeItem = styled.div``;

export const TimeText = styled.span<CountdownStylesProps>`
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1.33rem;
  text-align: center;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: 1.035rem;
    max-width: 74px;
    min-width: 50px;
  }

  @media ${theme.device.desktopFirst.mobileS} {
    font-size: 0.9rem;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      width: 6.16rem;

      @media ${theme.device.desktopFirst.tabletXM} {
        width: 5rem;
      }

      @media ${theme.device.desktopFirst.tabletM} {
        max-width: 4rem;
        min-width: 3rem;
      }

      @media ${theme.device.desktopFirst.mobileXL} {
        max-width: initial;
        min-width: initial;
      }
    `}
`;

export const Separator = styled.div<CountdownStylesProps>`
  display: ${({ hasSeparator }) => (hasSeparator === "true" ? "flex" : "none")};
  align-items: flex-start;
  font-family: "Inter";

  ${({ hasImage, backgroundType, hasBackground }) =>
    (!hasImage && backgroundType === "image") ||
    (!hasBackground && backgroundType === "solidColor")
      ? css`
          color: ${theme.colors.neutral["999"]};
        `
      : css`
          color: ${theme.colors.neutral.white};
        `}

  ${({ isSmall }) =>
    isSmall
      ? css`
          font-size: 40px;
          line-height: 1.8vw;
          margin-top: 1rem;

          @media ${theme.device.desktopFirst.tablet} {
            font-size: ${theme.typography.fontSize.lg.x2};
            line-height: ${theme.typography.fontSize.lg.x2};
            margin-top: 0;
          }
          @media ${theme.device.desktopFirst.mobileXL} {
            font-size: ${theme.typography.fontSize.lg.x2};
            line-height: ${theme.typography.fontSize.md.x5};
          }
        `
      : css`
          font-size: ${theme.typography.fontSize.lg.x7};
          line-height: ${theme.typography.fontSize.lg.x7};

          @media ${theme.device.desktopFirst.tablet} {
            font-size: ${theme.typography.fontSize.lg.x6};
            line-height: ${theme.typography.fontSize.lg.x6};
          }

          @media ${theme.device.desktopFirst.mobileXL} {
            font-size: ${theme.typography.fontSize.md.x5};
            line-height: ${theme.typography.fontSize.md.x5};
          }
        `}
`;

export const TimerContainer = styled.div<CountdownStylesProps>`
  ${({ isSmall }) =>
    isSmall &&
    css`
      display: flex;
      justify-content: center;
      width: 100%;

      @media ${theme.device.desktopFirst.mobileXL} {
        justify-content: center;
      }
    `}
`;

export const Time = styled.div<CountdownStylesProps>`
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${({ dateColor }) => dateColor} !important;

  ${({ hasImage, backgroundType, hasBackground }) =>
    (!hasImage && backgroundType === "image") ||
    (!hasBackground && backgroundType === "solidColor")
      ? css`
          color: ${theme.colors.neutral["999"]};
        `
      : css`
          color: ${theme.colors.neutral.white};
        `}

  ${({ isSmall }) =>
    isSmall
      ? css`
          font-size: 3vw;
          @media ${theme.device.desktopFirst.tablet} {
            font-size: 25px;
          }
        `
      : css`
          font-size: ${theme.typography.fontSize.lg.x7};
          @media ${theme.device.desktopFirst.tablet} {
            font-size: ${theme.typography.fontSize.lg.x6};
          }

          @media ${theme.device.desktopFirst.mobileXL} {
            font-size: ${theme.typography.fontSize.md.x5};
          }
        `}
`;

export const CustomCountdownContainer = styled.section`
  padding: ${theme.space.x0} ${theme.space.x4};
  display: flex;
  justify-content: center;
`;

export const CustomCountdownTitle = styled.h3<CountdownStylesProps>`
  font-size: ${theme.typography.fontSize.lg.x3};
  font-weight: ${theme.typography.fontWeight.normal};
  color: ${({ color }) => color};
  max-width: 625px;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: ${theme.typography.fontSize.md.x3};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    max-width: 322px;
    font-size: ${theme.typography.fontSize.md.x1};
  }
`;

export const CustomCountdownSubTitle = styled.h4<CountdownStylesProps>`
  font-size: ${theme.typography.fontSize.md.x1};
  font-weight: ${theme.typography.fontWeight["400"]};
  color: ${({ color }) => color};

  @media ${theme.device.desktopFirst.mobileXL} {
    padding-top: ${theme.space.x3};
    font-size: ${theme.typography.fontSize.sm.x2};
  }
`;

export const TitleContainer = styled.div`
  max-width: 600px;
  width: 100%;
`;

export const CustomCountdownHeader = styled.header<CountdownStylesProps>`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.space.x0} ${theme.space.x0} ${theme.space.x6};
  align-items: center;
  max-width: 625px;

  @media ${theme.device.desktopFirst.tablet} {
    padding-bottom: ${theme.space.x3};
  }

  ${({ isSmall }) =>
    isSmall
      ? css`
          width: 40%;
          align-items: flex-start;
          padding: ${theme.space.x0};
          @media${theme.device.desktopFirst.tabletSM} {
            display: none;
          }

          @media ${theme.device.desktopFirst.tablet} {
            padding-bottom: ${theme.space.x0};
          }

          @media ${theme.device.desktopFirst.mobileXL} {
            align-items: center;
            padding-bottom: ${theme.space.x3};
          }

          ${CustomCountdownTitle} {
            font-size: ${theme.typography.fontSize.md.x1};
            letter-spacing: ${theme.space["x0.5"]};
            margin: auto;
            display: flex;

            @media ${theme.device.desktopFirst.tablet} {
              font-size: ${theme.typography.fontSize.sm.x6};
              letter-spacing: 0;
            }

            /* pedro.souza procurar melhor solução depois, referente ao media 917px */
            @media (max-width: 917px) {
              font-size: ${theme.typography.fontSize.sm.x5};
            }

            @media ${theme.device.desktopFirst.mobileXL} {
              letter-spacing: ${theme.space["x0.5"]};
            }
          }

          ${CustomCountdownSubTitle} {
            font-size: ${theme.typography.fontSize.md.x1};
            padding-top: 0;
            margin-left: 7.3rem;

            /* pedro.souza procurar melhor solução depois, referente ao media 917px */
            @media (max-width: 917px) {
              font-size: ${theme.typography.fontSize.sm.x5};
            }

            /* pedro.souza procurar melhor solução depois, referente ao media 1075px */
            @media (max-width: 1075px) {
              margin-left: 0;
              margin: 0 auto;
            }

            @media ${theme.device.desktopFirst.tablet} {
              font-size: ${theme.typography.fontSize.sm.x6};
            }
          }
        `
      : null}
`;

export const TimerContent = styled.div<CountdownStylesProps>`
  display: flex;
  margin: 0 auto;
  gap: 1.3rem;
  z-index: 10;
  align-items: flex-start;

  /* pedro.souza procurar melhor solução depois, referente ao media 683px */
  @media (max-width: 683px) {
    gap: 0.5rem;
  }

  ${TimeItem} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
    color: ${({ color }) => color};

    ${({ hasImage, backgroundType, hasBackground }) =>
      (!hasImage && backgroundType === "image") ||
      (!hasBackground && backgroundType === "solidColor")
        ? css`
            color: ${theme.colors.neutral["999"]};
          `
        : css`
            color: ${theme.colors.neutral.white};
          `}
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    gap: 0.5rem;
  }

  ${({ isSmall }) =>
    isSmall
      ? css`
          gap: 1.3rem;

          @media ${theme.device.desktopFirst.tabletS} {
            gap: 0.5rem;
          }

          @media ${theme.device.desktopFirst.tabletSM} {
            margin: 0 auto;
            gap: 1rem;
          }

          @media ${theme.device.desktopFirst.mobileXL} {
            gap: 1rem;

            ${({ showDays }: CountdownStylesProps) =>
              !showDays
                ? null
                : css`
                    gap: 0.5rem;
                  `}
          }

          @media (max-width: 333px) {
            gap: 0.3rem;
          }
        `
      : null}
`;
