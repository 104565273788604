import styled from "styled-components";
import { Button, theme } from "ui";

export const UniqueClientContentContainer = styled.section``;

export const UniqueClientContentImage = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${theme.space.x6};

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-top: ${theme.space.x4};
  }
`;

export const UniqueClientContentInformation = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
  width: 100%;
`;

export const UniqueClientContentTitle = styled.h2`
  font-size: ${theme.typography.fontSize.sm.x7};
  color: ${theme.colors.neutral["550"]};
  font-weight: ${theme.typography.fontWeight["700"]};
  font-family: ${theme.typography.fontFamily.chivo};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const UniqueClientContentDescription = styled.p`
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${theme.colors.neutral["110"]};
  font-weight: ${theme.typography.fontWeight["400"]};
  font-family: ${theme.typography.fontFamily.chivo};
`;

export const UniqueClientContentButtons = styled.section`
  margin-top: ${theme.space.x4};
`;

export const UniqueClientPrimaryButton = styled(Button)`
  width: 100%;
  background-color: ${theme.colors.secondary["380"]};
  border-radius: ${theme.space.x1};
  color: ${theme.colors.neutral.white};
  height: 45px;
  transition: background-color 0.3s ease-in-out;
  font-size: ${theme.typography.fontSize.sm.x5};

  :hover {
    background-color: ${theme.colors.secondary["350"]};
  }
`;

export const UniqueClientSecondaryButton = styled(Button)`
  width: 100%;
  background-color: ${theme.colors.neutral.white};
  height: 45px;
  color: ${theme.colors.neutral["550"]};
  font-size: ${theme.typography.fontSize.sm.x3};

  :hover {
    background-color: ${theme.colors.neutral.white};
  }
`;
