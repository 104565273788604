import { IconProps } from "typing";
import { theme } from "../themes";

export const Spotify = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["280"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM12.5483 13.5C12.3833 13.5 12.3007 13.4175 12.1357 13.335C10.8982 12.5918 9.33075 12.18 7.68075 12.18C6.77325 12.18 5.78325 12.345 4.95825 12.51C4.79325 12.51 4.62825 12.5925 4.54575 12.5925C4.21575 12.5925 3.96825 12.345 3.96825 12.015C3.96825 11.685 4.13325 11.4375 4.46325 11.4375C5.53575 11.19 6.609 11.025 7.764 11.025C9.6615 11.025 11.394 11.52 12.879 12.345C13.044 12.51 13.209 12.5925 13.209 12.9225C13.0425 13.335 12.795 13.5 12.5483 13.5ZM13.455 11.1907C13.29 11.1907 13.125 11.1082 12.96 11.0257C11.5575 10.1182 9.57675 9.62325 7.4325 9.62325C6.36 9.62325 5.36925 9.78825 4.6275 9.95325C4.4625 10.0357 4.38 10.0357 4.215 10.0357C3.8025 10.0357 3.55425 9.70575 3.55425 9.375C3.55425 8.9625 3.71925 8.7975 4.13175 8.63175C5.12175 8.38425 6.1125 8.13675 7.515 8.13675C9.825 8.13675 11.97 8.71425 13.7857 9.78675C14.0332 9.95175 14.1982 10.1992 14.1982 10.4475C14.115 10.86 13.8682 11.1907 13.455 11.1907ZM14.5275 8.55C14.3625 8.55 14.28 8.4675 14.0325 8.385C12.3825 7.4775 9.99 6.9 7.68 6.9C6.525 6.9 5.2875 7.065 4.21425 7.3125C4.04925 7.3125 3.96675 7.395 3.71925 7.395C3.22425 7.47825 2.89425 7.065 2.89425 6.57075C2.89425 6.0765 3.14175 5.8275 3.47175 5.74575C4.7925 5.415 6.195 5.25 7.68 5.25C10.2375 5.25 12.8775 5.8275 14.9407 6.9C15.1882 7.065 15.4357 7.3125 15.4357 7.725C15.3532 8.22 15.0225 8.55 14.5275 8.55Z"
        fill={color}
      />
    </svg>
  );
};
