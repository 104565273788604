import styled from "styled-components";
import { DefaultProductImage, theme } from "ui";

interface ModalAlertAddProductToWeddingListProps {
  color?: string;
  isVisibled: boolean;
  isHidden?: boolean;
}

interface TitleDescriptionProps {
  color?: string;
}

export const ModalAlertAddProductToWeddingList = styled.section<ModalAlertAddProductToWeddingListProps>`
  min-height: 7rem;
  min-width: 12rem;
  max-width: 25rem;
  background-color: ${theme.colors.neutral.white};

  position: fixed;
  top: ${({ isVisibled }) => (isVisibled ? "2rem" : "-30rem")};
  right: 2rem;
  padding: ${theme.space.x4};

  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  z-index: 14;
  transition: top 1500ms;
  border-top: 3px solid ${({ color }) => color ?? theme.colors.secondary[300]};
  box-shadow: 1px 1px 3px 0 ${({ color }) => color ?? theme.colors.neutral[400]};
  border-radius: ${theme.space.x1};
`;

export const Content = styled.div`
  flex: 0.7;
`;
export const BoxImage = styled.div`
  max-height: 5rem;
  max-width: 5rem;
  flex: 0.3;
`;
export const TitleDescription = styled.h6<TitleDescriptionProps>`
  font-size: 1rem;
  color: ${({ color }) => color ?? theme.colors.secondary[300]};
  margin-bottom: ${theme.space["x1.5"]};
  font-weight: 600;
`;
export const DescriptionProduct = styled.span`
  font-size: 0.9rem;
`;

export const DefaultProductImageModal = styled(DefaultProductImage).attrs({
  width: 60,
  height: 60,
})``;
