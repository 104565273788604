import Cookie from "js-cookie";
import {
  IGetCookiesProps,
  ICookie,
  ISetCookiesProps,
  IDeleteCookie,
} from "app-domain/abstractions";

export const JsCookie: ICookie = {
  setCookie({ name, value }: ISetCookiesProps): void {
    Cookie.set(String(name), String(value));
  },

  getCookie({ name }: IGetCookiesProps): string | undefined {
    return Cookie.get(String(name));
  },

  deleteCookie({ name }: IDeleteCookie): void {
    Cookie.remove(String(name));
  },
};
