import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const CardIcon = ({
  width = 24,
  height = 15.57,
  color = theme.colors.tertiary["55"],
  secondColor = theme.colors.tertiary["950"],
  thirdColor = theme.colors.tertiary["205"],
  fourthColor = theme.colors.yellow["55"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.64384C0 0.73597 0.73597 0 1.64384 0H35.3562C36.264 0 37 0.73597 37 1.64384V22.3562C37 23.264 36.264 24 35.3562 24H1.64384C0.735973 24 0 23.264 0 22.3562V1.64384Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3562 1.15068H1.64384C1.37148 1.15068 1.15068 1.37148 1.15068 1.64384V22.3562C1.15068 22.6285 1.37148 22.8493 1.64384 22.8493H35.3562C35.6285 22.8493 35.8493 22.6285 35.8493 22.3562V1.64384C35.8493 1.37148 35.6285 1.15068 35.3562 1.15068ZM1.64384 0C0.73597 0 0 0.73597 0 1.64384V22.3562C0 23.264 0.735973 24 1.64384 24H35.3562C36.264 24 37 23.264 37 22.3562V1.64384C37 0.73597 36.264 0 35.3562 0H1.64384Z"
        fill={secondColor}
      />
      <path d="M0 5H37V11H0V5Z" fill={thirdColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8493 6.15068H1.15068V9.84932H35.8493V6.15068ZM0 5V11H37V5H0Z"
        fill={secondColor}
      />
      <path
        d="M4 15C4 14.4477 4.44772 14 5 14H12C12.5523 14 13 14.4477 13 15V19C13 19.5523 12.5523 20 12 20H5C4.44772 20 4 19.5523 4 19V15Z"
        fill={fourthColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.15068 15.1507V18.8493H11.8493V15.1507H5.15068ZM5 14C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H12C12.5523 20 13 19.5523 13 19V15C13 14.4477 12.5523 14 12 14H5Z"
        fill={secondColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4995 16C19.4995 15.7239 19.7234 15.5 19.9995 15.5H30.9995C31.2757 15.5 31.4995 15.7239 31.4995 16C31.4995 16.2761 31.2757 16.5 30.9995 16.5H19.9995C19.7234 16.5 19.4995 16.2761 19.4995 16Z"
        fill={secondColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4995 18.0005C19.4995 17.7243 19.7234 17.5005 19.9995 17.5005H24.9995C25.2757 17.5005 25.4995 17.7243 25.4995 18.0005C25.4995 18.2766 25.2757 18.5005 24.9995 18.5005H19.9995C19.7234 18.5005 19.4995 18.2766 19.4995 18.0005Z"
        fill={secondColor}
      />
    </svg>
  );
};
