import { IconProps } from "typing";
import { theme } from "../themes";

export const ArrowFilledDown = ({
  width = 7,
  height = 6,
  color = theme.colors.neutral.white,
  alt = "Seta para baixo",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M3.99757 5.13281L0.997559 0.132812H6.99756L3.99757 5.13281Z"
        fill={color}
      />
    </svg>
  );
};
