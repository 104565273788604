import styled from "styled-components";
import { EmailLetterMessage, theme } from "ui";

export const BoxContentEmailContainer = styled.section`
  width: 100%;
  height: 4rem;
  border-radius: 4px;
  background: ${theme.colors.neutral["white-075"]};
`;

export const BoxContentEmailContainerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1.35rem;
`;

export const EmailMessageBoxContentEmailIconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
`;

export const EmailMessageBoxContentEmailIcon = styled(EmailLetterMessage).attrs(
  {
    width: 48,
    height: 48,
  }
)``;

export const TitleBoxContentEmail = styled.span`
  color: ${theme.colors.neutral["550"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeight["400"]};
`;
