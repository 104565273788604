import styled from "styled-components";
import { theme } from "ui";

export const DropdownEllipsisStyled = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Dots = styled.span`
  width: 6px;
  height: 6px;
  display: inline-flex;
  align-items: center;
  border-radius: 100%;
  margin: 0 0.17rem;
  justify-content: center;
  background-color: ${theme.colors.neutral["320"]};
  transition: 500ms;
`;

export const DropdownEllipsisWrapper = styled.span`
  cursor: pointer;
  margin-right: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;

  :hover {
    ${Dots} {
      background-color: ${theme.colors.neutral["520"]};
    }
  }
`;

type DropdownEllipsisContentProps = {
  isVisible: boolean;
  marginTop?: string;
  marginLeft?: string;
  width?: string;
};

export const DropdownEllipsisContent = styled.section<DropdownEllipsisContentProps>`
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  opacity: ${({ isVisible }) => (isVisible ? "1.0" : "0")};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop ?? "initial"};
  margin-left: ${({ marginLeft }) => marginLeft ?? "initial"};
  width: ${({ width }) => width ?? "initial"};
  background-color: ${theme.colors.neutral.white};
  align-items: flex-start;
  justify-content: right;
  position: absolute;
  box-shadow: -1px 2px 6px rgb(0 0 0 / 16%);
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  z-index: 99;
`;

type DropdownEllipsisContentOptionProps = {
  fontSize: string;
};

export const DropdownEllipsisContentOption = styled.span<DropdownEllipsisContentOptionProps>`
  display: flex;
  padding: 1rem;
  font-size: ${({ fontSize }) => fontSize};
  color: ${theme.colors.primary["200"]};
  width: -webkit-fill-available;
`;
