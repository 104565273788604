import { Fragment } from "react";
import { useToast } from "application/hooks/Toast";

interface PropsToastRegisterProps {
  title: string;
  type: "success" | "error" | "info";
  condition: boolean;
}

const PropsToastRegister = ({
  condition,
  title,
  type,
}: PropsToastRegisterProps) => {
  const { addToast } = useToast();
  return (
    <Fragment key={title}>
      {condition ? (
        <>
          {addToast({
            title,
            type,
          })}
        </>
      ) : null}
    </Fragment>
  );
};

export { PropsToastRegister };
