import {
  useLoginAuthentication,
  useShallow,
  useTwoFactor,
} from "application/state-manager";
import { useAppContext, useAuth, useToast, useUser } from "application";
import { useCallback, useEffect, useState } from "react";
import {
  TwoFactorContentPropsMap,
  TwoFactorPublicCodeDto,
  UniqueClientDTO,
} from "typing";
import { notificationApi } from "implementations";
import { TelephoneErrorIcon } from "ui";

const usePhoneValidationComponentData = (
  clientInfo?: UniqueClientDTO | null
) => {
  const { isClientMobile } = useAppContext();
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;
  const [isLoading, setIsLoading] = useState(false);
  const { loggedClient } = useUser();

  const {
    isRequesting,
    setIsTokenCodeValid,
    setIsValidatingCode,
    setIsValidationActive,
    setValidateCode,
    channelSelected,
    faType,
    tokenCode,
    setIsBlockedToSendCode,
  } = useTwoFactor(
    useShallow((state) => ({
      isRequesting: state.isRequesting,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      setIsValidatingCode: state.setIsValidatingCode,
      setValidateCode: state.setValidateCode,
      setIsValidationActive: state.setIsValidationActive,
      channelSelected: state.channelSelected,
      faType: state.faType,
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      tokenCode: state.tokenCode,
    }))
  );

  const { setIsPhoneValidationModalOpen, changeValidationStep } =
    useLoginAuthentication(
      useShallow((state) => ({
        setIsPhoneValidationModalOpen: state.setIsPhoneValidationModalOpen,
        changeValidationStep: state.changeValidationStep,
      }))
    );

  useEffect(() => {
    if ((!loggedClient || !clientInfo) && isClientMobile) {
      setIsLoading(true);
    }
  }, [clientInfo, loggedClient, isClientMobile]);

  const { addToast } = useToast();

  const clearTime = useCallback(() => {
    localStorage.removeItem(`timeSent_${faType}_${channelSelected}`);
    localStorage.removeItem(`countdownTime_${faType}_${channelSelected}`);
    localStorage.removeItem(
      `countdownTimeTimestamp_${faType}_${channelSelected}`
    );
  }, [faType, channelSelected]);

  const checkErrorAttempt = useCallback(
    (statusCode?: string[], messages?: string[]) => {
      if (statusCode?.includes("222")) {
        setIsValidationActive(true);
        setIsBlockedToSendCode(true);
        addToast({
          isNewToast: true,
          newToastTheme: "dark",
          type: "error",
          description: `${messages?.[0]}`,
          title: "Ocorreu um erro ao validar o código",
          timerInMilliseconds: 3000,
        });
        return;
      }

      setIsTokenCodeValid(false);
      setValidateCode(true);
      addToast({
        isNewToast: true,
        newToastTheme: "dark",
        type: "error",
        description: `${messages?.[0]}`,
        title: "Ocorreu um erro ao validar o código",
        timerInMilliseconds: 3000,
      });
    },
    [
      setIsValidationActive,
      setIsTokenCodeValid,
      addToast,
      setValidateCode,
      setIsBlockedToSendCode,
    ]
  );

  const handleSuccessValidation = useCallback(() => {
    setIsTokenCodeValid(true);
    setIsValidatingCode(true);
    setIsValidationActive(false);
    changeValidationStep("modalCall");
    setIsPhoneValidationModalOpen(false);
    addToast({
      isNewToast: true,
      newToastTheme: "dark",
      type: "success",
      description: "Parabéns! Seu telefone foi validado.",
      title: "Telefone validado com sucesso",
      timerInMilliseconds: 3000,
    });
  }, [
    setIsTokenCodeValid,
    setIsValidatingCode,
    setIsValidationActive,
    changeValidationStep,
    setIsPhoneValidationModalOpen,
    addToast,
  ]);

  const handleCodeValidation = useCallback(
    async ({
      channel,
      faType: userFAType,
      cpf,
      email,
      phoneNumber,
      token,
    }: TwoFactorPublicCodeDto) => {
      const { isValid, errors } = await notificationApi.validateTwoFactorCode({
        channel,
        faType: userFAType,
        cpf,
        email,
        phoneNumber,
        token,
        jwt: accessToken,
      });

      if (!isValid) {
        checkErrorAttempt(errors?.statusCode, errors?.messages);
        return;
      }

      clearTime();

      handleSuccessValidation();
    },
    [accessToken, clearTime, checkErrorAttempt, handleSuccessValidation]
  );

  const getPhoneValidationComponentData = (): TwoFactorContentPropsMap => {
    const contentMap: TwoFactorContentPropsMap = new Map();

    contentMap.set("clientAction", "loginValidation");
    contentMap.set("email", String(loggedClient?.email));
    contentMap.set("hasError", false);
    contentMap.set("isUnderAction", isRequesting);
    contentMap.set("onCallAction", () =>
      handleCodeValidation({
        channel: channelSelected,
        faType: Number(faType),
        cpf: String(loggedClient?.cpf),
        email: String(loggedClient?.email),
        phoneNumber: String(loggedClient?.phoneNumber),
        token: String(tokenCode),
      })
    );
    contentMap.set("phoneNumber", String(loggedClient?.phoneNumber));
    contentMap.set("sendCodeType", "sms");
    contentMap.set("viewType", "modal");
    contentMap.set("hasDescriptionOnMobile", true);
    contentMap.set(
      "verifyIdentityTitle",
      "Escolha como verificar sua identidade"
    );
    contentMap.set("preventDefault", true);
    contentMap.set("canChooseAnotherTwoFactorMethod", false);
    contentMap.set("isMobile", isClientMobile);
    contentMap.set("options", null);
    contentMap.set("handleSelectAnotherTwoFactorType", undefined);
    contentMap.set("failureIllustration", <TelephoneErrorIcon />);
    return contentMap;
  };

  return {
    getPhoneValidationComponentData,
    isLoading,
    setIsLoading,
  };
};

export { usePhoneValidationComponentData };
