import {
  getDiscountPercentage,
  getInstallmentsText,
  getPriceList,
  getSalePrice,
  getSpotPrice,
} from "application";
import { Product, ProductCardDto } from "typing";

const hasProductCardAllInfosToShow = (
  productCard: ProductCardDto | undefined
) =>
  productCard && productCard?.prices?.length && productCard?.mediaLinks?.length;

const getProductUtils = (product: ProductCardDto | Product) => {
  if (!product) {
    return null;
  }

  const { description, mediaLinks } = product;

  const discountPercentage = getDiscountPercentage(product);
  const priceList = getPriceList(product, true);
  const salePrice = getSalePrice(product);
  const spotPrice = getSpotPrice(product);
  const installmentsText = getInstallmentsText(product, 2);

  const finalPrice = {
    value: spotPrice || (discountPercentage ? salePrice : priceList),
    text: spotPrice ? "à vista no pix ou no cartão" : null,
  };

  const imageUrl = mediaLinks?.find((item) => {
    return item?.isDefault && item.linkType === "BANNER";
  })?.imageUrl;

  return {
    description,
    discountPercentage,
    priceList,
    installmentsText,
    finalPrice,
    imageUrl,
    salePrice,
  };
};

export { getProductUtils, hasProductCardAllInfosToShow };
