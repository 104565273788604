import React from "react";
import { IconProps } from "typing";

export const PhoneNumberValidationError = ({
  width = 143,
  height = 119,
  alt = "Erro na validação por número de telefone",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 143 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6332_1844)">
        <path
          d="M55.9105 13.059C60.9008 4.32371 73.496 4.32369 78.4863 13.0589L127.854 99.4742C132.805 108.141 126.547 118.923 116.566 118.923H17.8308C7.84975 118.923 1.59192 108.141 6.54296 99.4742L55.9105 13.059Z"
          fill="#F6F7F7"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.1048 36.8851H79.0254C84.8975 36.8851 89.6536 41.6413 89.6536 47.5133V95.3402C89.6536 101.212 84.8975 105.968 79.0254 105.968H57.1048C51.2327 105.968 46.4766 101.212 46.4766 95.3402V47.5133C46.4766 41.6413 51.2327 36.8851 57.1048 36.8851Z"
        fill="#F6F7F7"
        stroke="#47494D"
        strokeWidth="4.63085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="51.168"
        y="53.7803"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#FBDDE1"
      />
      <rect
        x="51.168"
        y="42.5169"
        width="33.7907"
        height="5.63178"
        rx="2.54473"
        fill="url(#paint0_linear_6332_1844)"
      />
      <rect
        x="51.168"
        y="85.694"
        width="33.7907"
        height="15.0181"
        rx="7.50904"
        fill="url(#paint1_linear_6332_1844)"
      />
      <rect
        x="51.168"
        y="66.9212"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#FBDDE1"
      />
      <rect
        x="51.168"
        y="73.4917"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#FBDDE1"
      />
      <rect
        x="64.3105"
        y="60.3509"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#F499A4"
      />
      <rect
        x="64.3105"
        y="80.0623"
        width="19.7112"
        height="2.81589"
        rx="1.40794"
        fill="#F499A4"
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.8287 33.3421C44.9571 33.3421 44.25 34.0491 44.25 34.9212C44.25 35.7933 44.9571 36.5 45.8287 36.5H50.1708C51.0429 36.5 51.75 35.7929 51.75 34.9212C51.75 34.0496 51.0429 33.3425 50.1712 33.3425C50.1712 32.1433 49.1992 31.1712 48 31.1712C46.8008 31.1712 45.8287 32.1429 45.8287 33.3421Z"
          fill="#A0A2A4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.951 84.5114C107.547 78.5812 110.074 71.7971 115.773 68.8859C121.471 65.9748 128.45 67.9027 131.846 73.3262C135.242 78.7497 133.928 85.8694 128.821 89.7244C123.713 93.5795 116.506 92.8908 112.222 88.1382L108.237 88.1089L109.951 84.5114Z"
        fill="#EB3E53"
        stroke="#47494D"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.351 72.1295L125.694 73.3579C126.678 73.5842 127.292 74.5644 127.066 75.5484L124.609 86.2342C124.383 87.2182 123.402 87.8319 122.418 87.6057L117.075 86.3773C116.091 86.151 115.478 85.1708 115.704 84.1868L118.161 73.501C118.387 72.517 119.367 71.9033 120.351 72.1295Z"
        fill="#F6F7F7"
        stroke="#47494D"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.24 72.3342L120.872 73.9371C120.804 74.2318 120.948 74.5172 121.194 74.5737L123.866 75.1879C124.111 75.2445 124.366 75.0508 124.434 74.756L124.802 73.1532"
        stroke="#47494D"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.261 84.5359L121.256 84.9945"
        stroke="#5C5E62"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4449 64.3807C35.1847 58.5979 33.0496 51.6801 27.5272 48.4475C22.0047 45.2148 14.9274 46.7398 11.2265 51.96C7.52549 57.1802 8.42888 64.3633 13.3072 68.5045C18.1855 72.6457 25.42 72.3709 29.97 67.8714L33.9503 68.0704L32.4449 64.3807Z"
        fill="#EB3E53"
        stroke="#47494D"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7747 51.4235L17.3702 52.344C16.3749 52.5135 15.706 53.457 15.8755 54.4523L17.7164 65.2613C17.8859 66.2566 18.8294 66.9254 19.8247 66.7559L25.2292 65.8355C26.2245 65.666 26.8934 64.7225 26.7239 63.7272L24.883 52.9182C24.7135 51.9229 23.77 51.254 22.7747 51.4235Z"
        fill="#F6F7F7"
        stroke="#47494D"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.873 51.577L22.1492 53.1983C22.2 53.4964 22.0395 53.7731 21.7909 53.8155L19.0886 54.2757C18.84 54.318 18.597 54.1101 18.5462 53.8119L18.2701 52.1906"
        stroke="#47494D"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1529 63.8721L21.1352 64.2158"
        stroke="#5C5E62"
        strokeWidth="1.02793"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.2431 13.0789C64.9356 13.0789 63.875 14.0688 63.875 15.2897C63.875 16.5106 64.9356 17.5 66.2431 17.5H72.7562C74.0644 17.5 75.125 16.51 75.125 15.2897C75.125 14.0694 74.0644 13.0795 72.7569 13.0795C72.7569 11.4006 71.2987 10.0397 69.5 10.0397C67.7012 10.0397 66.2431 11.4 66.2431 13.0789Z"
          stroke="#8F9194"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.8287 25.3421C70.9571 25.3421 70.25 26.0491 70.25 26.9212C70.25 27.7933 70.9571 28.5 71.8287 28.5H76.1708C77.0429 28.5 77.75 27.7929 77.75 26.9212C77.75 26.0496 77.0429 25.3425 76.1712 25.3425C76.1712 24.1433 75.1992 23.1712 74 23.1712C72.8008 23.1712 71.8287 24.1429 71.8287 25.3421Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.2431 26.6447C56.9356 26.6447 55.875 27.5639 55.875 28.6976C55.875 29.8313 56.9356 30.75 58.2431 30.75H64.7562C66.0644 30.75 67.125 29.8307 67.125 28.6976C67.125 27.5644 66.0644 26.6452 64.7569 26.6452C64.7569 25.0863 63.2987 23.8226 61.5 23.8226C59.7012 23.8226 58.2431 25.0857 58.2431 26.6447Z"
          stroke="#A0A2A4"
          strokeWidth="0.62"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.9602 86.6447C28.7399 86.6447 27.75 87.5639 27.75 88.6976C27.75 89.8313 28.7399 90.75 29.9602 90.75H36.0392C37.2601 90.75 38.25 89.8308 38.25 88.6976C38.25 87.5644 37.2601 86.6452 36.0397 86.6452C36.0397 85.0863 34.6788 83.8226 33 83.8226C31.3212 83.8226 29.9602 85.0858 29.9602 86.6447Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.8287 78.3421C96.9571 78.3421 96.25 79.0491 96.25 79.9212C96.25 80.7933 96.9571 81.5 97.8287 81.5H102.171C103.043 81.5 103.75 80.7929 103.75 79.9212C103.75 79.0496 103.043 78.3425 102.171 78.3425C102.171 77.1433 101.199 76.1712 100 76.1712C98.8008 76.1712 97.8287 77.1429 97.8287 78.3421Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.9602 62.0789C94.7399 62.0789 93.75 63.0688 93.75 64.2897C93.75 65.5106 94.7399 66.5 95.9602 66.5H102.039C103.26 66.5 104.25 65.51 104.25 64.2897C104.25 63.0694 103.26 62.0795 102.04 62.0795C102.04 60.4006 100.679 59.0397 99 59.0397C97.3212 59.0397 95.9602 60.4 95.9602 62.0789Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8287 109.342C31.9571 109.342 31.25 110.049 31.25 110.921C31.25 111.793 31.9571 112.5 32.8287 112.5H37.1708C38.0429 112.5 38.75 111.793 38.75 110.921C38.75 110.05 38.0429 109.342 37.1712 109.342C37.1712 108.143 36.1992 107.171 35 107.171C33.8008 107.171 32.8287 108.143 32.8287 109.342Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.112 106.342C103.153 106.342 102.375 107.049 102.375 107.921C102.375 108.793 103.153 109.5 104.112 109.5H108.888C109.847 109.5 110.625 108.793 110.625 107.921C110.625 107.05 109.847 106.342 108.888 106.342C108.888 105.143 107.819 104.171 106.5 104.171C105.181 104.171 104.112 105.143 104.112 106.342Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1116 103.342C12.1528 103.342 11.375 104.049 11.375 104.921C11.375 105.793 12.1528 106.5 13.1116 106.5H17.8879C18.8472 106.5 19.625 105.793 19.625 104.921C19.625 104.05 18.8472 103.342 17.8884 103.342C17.8884 102.143 16.8191 101.171 15.5 101.171C14.1809 101.171 13.1116 102.143 13.1116 103.342Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.243 97.0789C109.936 97.0789 108.875 98.0688 108.875 99.2897C108.875 100.511 109.936 101.5 111.243 101.5H117.756C119.064 101.5 120.125 100.51 120.125 99.2897C120.125 98.0694 119.064 97.0795 117.757 97.0795C117.757 95.4006 116.299 94.0397 114.5 94.0397C112.701 94.0397 111.243 95.4 111.243 97.0789Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <ellipse cx="70.5" cy="113" rx="23.5" ry="1" fill="#B2B3B5" />
      <defs>
        <linearGradient
          id="paint0_linear_6332_1844"
          x1="83.5"
          y1="41.4998"
          x2="55.5"
          y2="46.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A43939" stopOpacity="0.7" />
          <stop offset="1" stopColor="#A43939" stopOpacity="0.17" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6332_1844"
          x1="81.5"
          y1="85.9999"
          x2="54"
          y2="99.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A43939" stopOpacity="0.7" />
          <stop offset="1" stopColor="#B53535" stopOpacity="0.06" />
        </linearGradient>
        <clipPath id="clip0_6332_1844">
          <rect width="135" height="118.77" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
