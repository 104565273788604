import { IconProps } from "typing/types/IconProps";

export const TwoCardsIcon = ({ width, height, alt, color }: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 21.333H6.66634C4.82539 21.333 3.33301 19.8406 3.33301 17.9997V8.66634C3.33301 6.82539 4.82539 5.33301 6.66634 5.33301H21.333C23.174 5.33301 24.6663 6.82539 24.6663 8.66634V10.6663"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="8.66699"
        y="10.667"
        width="20"
        height="16"
        rx="2.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66699 15.9997H28.667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9997 21.3337H13.333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
