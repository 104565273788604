import React, { ChangeEvent, FocusEvent } from "react";
import { IFormValues, InputModeProps } from "typing";
import { UseFormRegister, useForm } from "react-hook-form";
import { InputCep } from "./styles";

interface InputCepComponentProps {
  id: string;
  name: string;
  className?: string;
  inputMode?: InputModeProps;
  maxLength?: number;
  minLength?: number;
  value?: string;
  placeholder?: string;
  mask?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onMouseOut: (event: React.MouseEvent<HTMLInputElement>) => void;
  disableField: boolean;
}

export const InputCepComponent = ({
  id,
  name,
  className,
  inputMode,
  maxLength,
  minLength,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  mask,
  onMouseOver,
  onMouseOut,
  disableField,
}: InputCepComponentProps) => {
  const { register } = useForm<IFormValues>();
  return (
    <InputCep
      id={id}
      name={name}
      className={className}
      inputMode={inputMode}
      maxLength={maxLength}
      minLength={minLength}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      placeholder={placeholder}
      mask={mask}
      register={register as unknown as UseFormRegister<IFormValues>}
      hasError={false}
      disabled={disableField}
    />
  );
};
