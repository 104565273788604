import { Image } from "infra";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { buildTestIds } from "application";
import {
  ModalAlertAddProductToWeddingList,
  BoxImage,
  DescriptionProduct,
  TitleDescription,
  Content,
  DefaultProductImageModal,
} from "./styles";

interface PopupAlertAddProductToWeddingListProps {
  imageUrlProduct?: string;
  textDescriptionProduct?: string;
  titleAlert?: string;
  color?: string;
  isOpen: boolean;
  openModalTime?: number;
  handlerStatusPopup?: Dispatch<SetStateAction<boolean>>;
}

export const PopupAlertAddProductToWeddingList = ({
  imageUrlProduct = "",
  textDescriptionProduct = "",
  titleAlert = "",
  color,
  isOpen,
  openModalTime = 6000,
  handlerStatusPopup,
}: PopupAlertAddProductToWeddingListProps) => {
  const [popupIsOpen, popupOpeningHandler] = useState(false);

  useEffect(() => {
    if (popupIsOpen) {
      setTimeout(() => {
        popupOpeningHandler(false);
      }, openModalTime);
    } else if (handlerStatusPopup) {
      handlerStatusPopup(false);
    }
  }, [handlerStatusPopup, openModalTime, popupIsOpen]);

  useEffect(() => {
    if (isOpen) {
      popupOpeningHandler(true);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const imageUrl = imageUrlProduct;

  return (
    <ModalAlertAddProductToWeddingList
      {...buildTestIds("modal-alert-add-product-to-wedding-list")}
      color={color}
      isVisibled={popupIsOpen}
    >
      <BoxImage {...buildTestIds("box-image")}>
        {imageUrl ? (
          <Image
            src={imageUrl}
            layout="responsive"
            width="100%"
            height="100%"
            alt="Imagem do produto"
          />
        ) : (
          <DefaultProductImageModal />
        )}
      </BoxImage>
      <Content {...buildTestIds("text-content")}>
        <TitleDescription {...buildTestIds("title-description")} color={color}>
          {titleAlert}
        </TitleDescription>
        <DescriptionProduct {...buildTestIds("description-product")}>
          {textDescriptionProduct}
        </DescriptionProduct>
      </Content>
    </ModalAlertAddProductToWeddingList>
  );
};
