import styled from "styled-components";
import { theme } from "ui";
import { ProductCardContainer } from "../../../ProductCard/styles";

interface ProductCardStyledProps {
  isClientMobile?: boolean;
  height?: number;
}

export const ProductCardStyled = styled.div<ProductCardStyledProps>`
  width: 100%;

  ${ProductCardContainer} {
    height: ${({ height }) => `${height}px`};
    min-height: ${({ height }) => `${height}px`};
    max-height: ${({ height }) => `${height}px`};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    .slick-list {
      background-color: ${theme.colors.primary["705"]};
    }
  }
`;
