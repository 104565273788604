import { ShoppingFlowIcon } from "ui";
import {
  useShallow,
  useTwoFactor,
  useUniqueClient,
} from "application/state-manager";
import { FATypeValues, IUniqueClientContentMap } from "typing";
import { useRouter } from "next/router";
import { cookie } from "implementations";
import { useAppContext, useUser } from "application";
import { useUniqueClientUtils } from "../utils/data";

const useUniqueClientData = () => {
  const { loggedClient } = useUser();
  const router = useRouter();
  const { isClientMobile } = useAppContext();
  const { setIsUniqueClientModalOpen, changeUniqueClientStep } =
    useUniqueClient(
      useShallow((state) => ({
        setIsUniqueClientModalOpen: state.setIsUniqueClientModalOpen,
        changeUniqueClientStep: state.changeUniqueClientStep,
      }))
    );
  const { setIsValidationActive } = useTwoFactor(
    useShallow((state) => ({
      setIsValidationActive: state.setIsValidationActive,
    }))
  );

  const {
    sendTwoFactorToken,
    needsToValidateEmail,
    needsToValidatePhoneNumber,
  } = useUniqueClientUtils();

  const closeModal = () => {
    setIsUniqueClientModalOpen(false);
  };

  const handleValidationFlow = () => {
    cookie.setCookie({
      name: "@FC:Ecom:HadValidationVisualization",
      value: "true",
    });

    if (isClientMobile) {
      router.push("conta/seguranca/verificacao-identidade");
      return;
    }

    if (needsToValidatePhoneNumber && needsToValidateEmail) {
      changeUniqueClientStep("chooseAnotherMethod");
      setIsValidationActive(true);
      return;
    }

    sendTwoFactorToken({
      channel: needsToValidatePhoneNumber ? 1 : 2,
      faType: needsToValidatePhoneNumber
        ? FATypeValues.AccountValidatePhone2FA
        : FATypeValues.AccountValidateEMail2FA,
      cpf: loggedClient?.cpf ?? "",
      email: loggedClient?.email ?? "",
      phoneNumber: loggedClient?.phoneNumber ?? "",
    });
  };

  const getUniqueClientContent = (): IUniqueClientContentMap => {
    const contentMap: IUniqueClientContentMap = new Map();

    contentMap.set("image", <ShoppingFlowIcon />);
    contentMap.set("title", "Todas as suas compras em um único lugar!");
    contentMap.set(
      "description",
      "Agora você pode visualizar suas compras físicas e online aqui no site. Muito mais comodidade e facilidade para você!"
    );
    contentMap.set("primaryButtonLabel", "Sincronizar compras");
    contentMap.set("secondaryButtonLabel", "Deixar para depois");
    contentMap.set("onPrimaryButtonClick", handleValidationFlow);
    contentMap.set("onSecondaryButtonClick", closeModal);

    return contentMap;
  };

  return {
    getUniqueClientContent,
  };
};

export { useUniqueClientData };
