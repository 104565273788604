import { IconProps } from "typing";
import { theme } from "../themes";

export const AccountDeletionIcon = ({
  width,
  height,
  color = theme.colors.neutral["550"],
  alt,
  strokeWidth,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      aria-label={alt}
    >
      <path
        d="M10.3915 3.01931C11.5279 4.15574 11.5279 5.99824 10.3915 7.13467C9.25508 8.27109 7.41257 8.27109 6.27615 7.13467C5.13972 5.99824 5.13972 4.15574 6.27615 3.01931C7.41257 1.88289 9.25508 1.88289 10.3915 3.01931"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9695 11.164C10.887 10.7315 9.61699 10.4932 8.33366 10.4932C4.96033 10.4932 1.66699 12.1323 1.66699 14.6532V15.4865C1.66699 15.9465 2.04033 16.3198 2.50033 16.3198H10.3445"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 18.8333C11.8658 18.8333 10 16.9683 10 14.6667C10 12.4133 11.9167 10.4975 14.17 10.5C16.47 10.5017 18.3333 12.3667 18.3333 14.6667C18.3333 16.9675 16.4683 18.8333 14.1667 18.8333"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3422 13.4922L12.9922 15.8422"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3422 15.8422L12.9922 13.4922"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
