import { RefObject } from "react";
import { ClientAction, SendCodeType } from "typing";

type InputDataType =
  | "password"
  | "name"
  | "cpf"
  | "birthdate"
  | "phoneNumber"
  | "email"
  | "confirmPassword"
  | "trackerOrderByWhatsApp"
  | "acceptedTermsUse";

const handleCleanInputData = (
  clientAction: ClientAction | null,
  sendCodeType: SendCodeType,
  emailRef: RefObject<HTMLDivElement>,
  phoneNumberRef: RefObject<HTMLDivElement>,
  setValue: (key: InputDataType, value: string) => void,
  setFocus: (key: InputDataType) => void
) => {
  if (clientAction === "createUser") {
    if (sendCodeType === "email" && emailRef?.current) {
      emailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      setValue("email", "");
      setFocus("email");
    }

    if (sendCodeType === "sms" && phoneNumberRef?.current) {
      phoneNumberRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      setValue("phoneNumber", "");
      setFocus("phoneNumber");
    }
  }
};

export { handleCleanInputData };
