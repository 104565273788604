import { useAppContext } from "application";
import { useShallow, useUniqueClient } from "application/state-manager";
import { UniqueClientModalContentMap } from "typing";

const useUniqueClientModalData = () => {
  const { isClientMobile } = useAppContext();
  const { setIsUniqueClientModalOpen, isUniqueClientModalOpen } =
    useUniqueClient(
      useShallow((state) => ({
        setIsUniqueClientModalOpen: state.setIsUniqueClientModalOpen,
        isUniqueClientModalOpen: state.isUniqueClientModalOpen,
      }))
    );

  const getUniqueClientModalContent = (): UniqueClientModalContentMap => {
    const contentMap: UniqueClientModalContentMap = new Map();

    contentMap.set("closeModal", () => {
      setIsUniqueClientModalOpen(false);
    });
    contentMap.set("modalTitle", "Já comprou em uma das nossas lojas?");
    contentMap.set("isModalOpen", isUniqueClientModalOpen);
    contentMap.set("isMobile", isClientMobile);

    return contentMap;
  };

  return {
    getUniqueClientModalContent,
  };
};

export { useUniqueClientModalData };
