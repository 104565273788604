import { zodResolver } from "@hookform/resolvers/zod";
import {
  brazilianStates,
  buildTestIds,
  FormAddressSchema,
  removeSpecialCharacters,
  useAppContext,
  useAuth,
  useToast,
} from "application";
import { profileApi, shippingApi } from "implementations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Control, useForm, UseFormRegister } from "react-hook-form";
import {
  AddressDto,
  AddressEditBodyDto,
  FormNewAddressProps,
  IFormValues,
} from "typing";
import { theme } from "ui";
import {
  AddressHeader,
  CityComponent,
  ComplementComponent,
  DistrictComponent,
  NoNumberComponent,
  NumberComponent,
  RecipientComponent,
  ReferenceComponent,
  StateComponent,
  StreetComponent,
  ZipCodeComponent,
} from "./components";
import {
  ButtonRegisterAction,
  FormContainer,
  FormNewAddressContainer,
  FormNewAddressContent,
  HouseData,
} from "./styles";

const NewFormAddress = ({
  newAddress: newAddressData,
  returnCreatedStatus,
  returnEditStatus,
  onCreateNewAddress,
  onActionAfterCreateNewAddress,
  onActionAfterEditAddress,
  onCloseModal,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
FormNewAddressProps) => {
  const { addToast } = useToast();
  const { getTokens } = useAuth();
  const TOKEN_SESSION = getTokens();
  const { isClientMobile } = useAppContext();
  const [disabledInputs, setDisabledInputs] =
    useState<Record<keyof HouseData, boolean>>();

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<HouseData>({
    defaultValues: {
      addressComplement: newAddressData?.addressComplement ?? "",
      city: newAddressData?.city ?? "",
      district: newAddressData?.district ?? "",
      noNumber: newAddressData?.number === 0,
      number: newAddressData?.number !== 0 ? newAddressData?.number : undefined,
      recipient: newAddressData?.recipient ?? "",
      referencePoint: newAddressData?.referencePoint ?? "",
      state: newAddressData?.state ?? "",
      street: newAddressData?.street ?? "",
      zipCode: newAddressData?.zipCode ?? "",
      clientId: newAddressData?.clientId ?? 0,
      id: newAddressData?.id ?? 0,
      isDefault: newAddressData?.isDefault ?? false,
    },
    shouldFocusError: true,
    mode: "onChange",
    reValidateMode: "onChange",
    resetOptions: {
      keepDefaultValues: false,
      keepValues: false,
      keepErrors: false,
    },
    resolver: zodResolver(FormAddressSchema),
  });

  const buttonText = newAddressData
    ? "Salvar Alterações"
    : "Adicionar novo endereço";
  const buttonUnderActionText = newAddressData
    ? "Salvando Alterações"
    : "Adicionando novo endereço";
  const requiredField = "Campo obrigatório";
  const [shouldFetchAddressByZipCode, setShouldFetchAddressByZipCode] =
    useState<boolean>(false);
  const [isLoadingButton, handleLoadingButton] = useState<boolean>(false);
  const [shouldDisabledAllInputs, setShouldDisabledAllInputs] =
    useState<boolean>(false);
  const newStreetWatcher = watch("street");
  const zipCodeWatcher = watch("zipCode");
  const searchCEP = useMemo(() => String(zipCodeWatcher), [zipCodeWatcher]);
  const isCEPValid = useMemo(() => String(searchCEP).length === 9, [searchCEP]);

  const hasNewAddressStreet = !!newStreetWatcher;

  const {
    data: addressByCep,
    isLoading: addressByCepIsLoading,
    error: addressByCepError,
  } = shippingApi.getAddressByZipCode(
    searchCEP ?? "",
    shouldFetchAddressByZipCode
  );

  const onAutoCompleteAddressByCep = useCallback(() => {
    if (isCEPValid && addressByCep && addressByCep !== null) {
      const { street, city, uf, district } = addressByCep;

      setValue("city", city?.trim());
      setValue("state", uf?.trim());
      setValue("street", street?.trim());
      setValue("district", district?.trim());
      setDisabledInputs({
        street: Boolean(street),
        city: Boolean(city),
        district: Boolean(district),
        state: Boolean(uf),
        addressComplement: false,
        clientId: false,
        id: false,
        isDefault: false,
        noNumber: false,
        number: false,
        recipient: false,
        referencePoint: false,
        zipCode: false,
      });
    }
  }, [addressByCep, isCEPValid, setValue]);

  const handleCreateNewAddress = useCallback(
    async (addressData: HouseData) => {
      handleLoadingButton(true);

      const {
        street,
        zipCode,
        city,
        district,
        recipient,
        state,
        addressComplement,
        number,
        noNumber,
        referencePoint,
      } = addressData;

      const responseCreated = await profileApi.createClientAddress(
        {
          street: removeSpecialCharacters(street),
          number: noNumber ? 0 : Number(number),
          addressComplement: removeSpecialCharacters(addressComplement),
          referencePoint: removeSpecialCharacters(referencePoint),
          district: removeSpecialCharacters(district),
          state: removeSpecialCharacters(state),
          zipCode: String(zipCode),
          city: removeSpecialCharacters(city),
          recipient: removeSpecialCharacters(recipient),
          isDefault: false,
        },
        getTokens()?.token as unknown as string
      );

      if (responseCreated) {
        handleLoadingButton(false);
        if (onActionAfterCreateNewAddress) onActionAfterCreateNewAddress();
        if (onCreateNewAddress)
          onCreateNewAddress(responseCreated as AddressDto);
        if (returnCreatedStatus) {
          returnCreatedStatus();
        }
      }
    },
    [
      getTokens,
      onCreateNewAddress,
      onActionAfterCreateNewAddress,
      returnCreatedStatus,
    ]
  );

  const handleEditNewAddress = useCallback(
    async (data: HouseData) => {
      if (!data) {
        return;
      }

      const {
        street,
        zipCode,
        city,
        district,
        recipient,
        state,
        addressComplement,
        number,
        referencePoint,
        noNumber,
      } = data;

      const BODY: AddressEditBodyDto[] = [
        {
          op: "replace",
          path: "/ZipCode",
          value: zipCode,
        },
        {
          op: "replace",
          path: "/Street",
          value: removeSpecialCharacters(street),
        },
        {
          op: "replace",
          path: "/Number",
          value: Number(noNumber ? 0 : number ?? 0),
        },

        {
          op: "replace",
          path: "/AddressComplement",
          value: removeSpecialCharacters(addressComplement) || "",
        },
        {
          op: "replace",
          path: "/ReferencePoint",
          value: removeSpecialCharacters(referencePoint) || "",
        },
        {
          op: "replace",
          path: "/District",
          value: removeSpecialCharacters(district),
        },
        {
          op: "replace",
          path: "/State",
          value: removeSpecialCharacters(state),
        },
        {
          op: "replace",
          path: "/City",
          value: removeSpecialCharacters(city),
        },
        {
          op: "replace",
          path: "/Recipient",
          value: removeSpecialCharacters(recipient),
        },
      ];

      const responseEdit = await profileApi.editClientAddresses(
        (TOKEN_SESSION?.token as unknown as string) || "",
        String(newAddressData?.id) || "",
        BODY
      );

      if (returnEditStatus && responseEdit) {
        returnEditStatus();
      }
      if (onActionAfterEditAddress) onActionAfterEditAddress();

      handleLoadingButton(false);
    },
    [
      TOKEN_SESSION?.token,
      newAddressData?.id,
      returnEditStatus,
      onActionAfterEditAddress,
    ]
  );

  const controlAction = useCallback(
    (data: HouseData) => {
      const handleCheckBox = data.number || data.noNumber;

      if (
        !data.zipCode ||
        !data?.street ||
        !data?.city ||
        !data?.district ||
        !data?.state ||
        !data.recipient ||
        !handleCheckBox
      ) {
        addToast({
          type: "error",
          title: "Preencha os campos obrigatórios antes de enviar.",
        });
        setError("root", {
          type: "manual",
          message: "Preencha os campos obrigatórios antes de enviar.",
        });
        return;
      }
      handleLoadingButton(true);
      if (newAddressData) {
        handleEditNewAddress(data);
        return;
      }
      handleCreateNewAddress(data);
    },
    [
      newAddressData,
      handleCreateNewAddress,
      addToast,
      setError,
      handleEditNewAddress,
    ]
  );

  useEffect(() => {
    if (zipCodeWatcher?.toString().length === 0) {
      reset({
        addressComplement: "",
        city: "",
        district: "",
        noNumber: false,
        number: undefined,
        recipient: "",
        referencePoint: "",
        state: "",
        street: "",
        zipCode: "",
      });
      setDisabledInputs({
        street: false,
        city: false,
        district: false,
        state: false,
        addressComplement: false,
        clientId: false,
        id: false,
        isDefault: false,
        noNumber: false,
        number: false,
        recipient: false,
        referencePoint: false,
        zipCode: false,
      });
    }
  }, [reset, zipCodeWatcher]);

  useEffect(() => {
    if (addressByCep) {
      setShouldFetchAddressByZipCode(false);
      clearErrors();
      onAutoCompleteAddressByCep();
    }
  }, [addressByCep, clearErrors, onAutoCompleteAddressByCep]);

  useEffect(() => {
    const validCepAndNotLoading = isCEPValid && !addressByCepIsLoading;

    if (addressByCepError && validCepAndNotLoading) {
      setShouldDisabledAllInputs(true);
      addToast({
        type: "error",
        title: "Erro ao buscar o CEP",
        description:
          "Desculpe, ocorreu um erro ao buscar o CEP. Por favor, tente novamente mais tarde.",
        isNewToast: true,
        newToastTheme: "light",
      });
      return;
    }

    if (!addressByCepIsLoading && addressByCep === null) {
      setShouldDisabledAllInputs(true);
      addToast({
        type: "error",
        title: "CEP inválido",
        description: "O CEP digitado é inválido.",
        isNewToast: true,
        newToastTheme: "light",
      });

      return;
    }

    setShouldDisabledAllInputs(false);
  }, [
    addToast,
    addressByCep,
    addressByCepError,
    addressByCepIsLoading,
    hasNewAddressStreet,
    isCEPValid,
    zipCodeWatcher,
  ]);

  useEffect(() => {
    if (newAddressData) {
      setDisabledInputs({
        street: true,
        city: true,
        district: true,
        state: true,
        addressComplement: false,
        clientId: false,
        id: false,
        isDefault: false,
        noNumber: false,
        number: false,
        recipient: false,
        referencePoint: false,
        zipCode: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormNewAddressContainer
      id="form-new-address"
      onSubmit={handleSubmit(handleCreateNewAddress)}
      data-testid="form-new-address"
    >
      <AddressHeader
        isClientMobile={isClientMobile}
        newAddressData={newAddressData}
        onCloseModal={onCloseModal}
      />

      <FormContainer>
        <ZipCodeComponent
          hasError={
            (!hasNewAddressStreet &&
              Boolean(!!errors.zipCode || !!errors.root)) ||
            addressByCep === null
          }
          hasSuccessfully={
            isCEPValid && hasNewAddressStreet && addressByCep !== null
          }
          textError={errors.zipCode?.message ?? errors.root?.message}
          requiredFieldText={requiredField}
          control={control as unknown as Control<IFormValues>}
          onChange={(e) => {
            if (e.target.value.length === 9) {
              setValue("zipCode", e.target.value);
              setShouldFetchAddressByZipCode(true);
            }
          }}
        />

        <StateComponent
          control={control as unknown as Control<IFormValues>}
          disabled={!!disabledInputs?.state || shouldDisabledAllInputs}
          hasError={!!errors.state || !!errors.root}
          stateList={brazilianStates.map(({ state }) => state)}
        />

        <CityComponent
          requiredFieldText={requiredField}
          disabled={!!disabledInputs?.city || shouldDisabledAllInputs}
          control={control as unknown as Control<IFormValues>}
          hasError={!!errors?.city || !!errors?.root}
        />

        <StreetComponent
          requiredFieldText={requiredField}
          control={control as unknown as Control<IFormValues>}
          disabled={!!disabledInputs?.street || shouldDisabledAllInputs}
          hasError={!!errors?.street || !!errors?.root}
        />

        <NumberComponent
          control={control as unknown as Control<IFormValues>}
          disabled={shouldDisabledAllInputs}
          hasError={!!errors?.number || !!errors?.root}
          requiredFieldText={requiredField}
          setValue={setValue}
        />

        <NoNumberComponent
          register={register as unknown as UseFormRegister<IFormValues>}
          isCheckedEvent={(isChecked) => {
            setValue("noNumber", isChecked);
            setValue("number", undefined);
          }}
        />

        <DistrictComponent
          requiredFieldText={requiredField}
          control={control as unknown as Control<IFormValues>}
          disabled={!!disabledInputs?.district || shouldDisabledAllInputs}
          hasError={!!errors.district || !!errors?.root}
        />

        {!newAddressData && (
          <RecipientComponent
            requiredFieldText={requiredField}
            control={control as unknown as Control<IFormValues>}
            hasError={!!errors.recipient || !!errors?.root}
          />
        )}

        <ComplementComponent
          hasError={!!errors.addressComplement}
          control={control}
        />

        <ReferenceComponent
          hasError={!!errors.referencePoint}
          control={control}
        />
      </FormContainer>

      <FormNewAddressContent>
        <ButtonRegisterAction
          {...buildTestIds("button-register-action")}
          text={buttonText}
          textUnderAction={buttonUnderActionText}
          buttonType="button"
          minWidth={isClientMobile ? "100%" : "33.835rem"}
          color={theme.colors.secondary["380"]}
          hoveredColor={theme.colors.secondary["350"]}
          onClickAction={handleSubmit(controlAction)}
          underAction={isLoadingButton}
        />
      </FormNewAddressContent>
    </FormNewAddressContainer>
  );
};

export { NewFormAddress };
