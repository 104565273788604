import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const PinterestIcon = ({
  width = 25,
  height = 24,
  alt = "Ícone do Pinterest",
  color = theme.colors.neutral.white,
  secondColor = theme.colors.neutral["560"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1527_13318)">
        <path
          d="M12.4999 0.507815C9.78009 0.50609 7.14514 1.45483 5.05068 3.19C2.95622 4.92516 1.53393 7.33767 1.02972 10.0104C0.525522 12.6831 0.971108 15.4479 2.28944 17.8269C3.60777 20.2059 5.71596 22.0494 8.2495 23.0388C8.14743 22.1158 8.05411 20.697 8.28887 19.6894C8.50175 18.7781 9.65659 13.8905 9.65659 13.8905C9.65659 13.8905 9.3081 13.192 9.3081 12.1597C9.3081 10.5368 10.2486 9.32655 11.4195 9.32655C12.4139 9.32655 12.8951 10.0731 12.8951 10.9699C12.8951 11.9701 12.2579 13.4662 11.9283 14.8528C11.6542 16.0135 12.5116 16.9613 13.6562 16.9613C15.7297 16.9613 17.3234 14.7741 17.3234 11.6187C17.3234 8.82642 15.3156 6.87399 12.4504 6.87399C9.13166 6.87399 7.18361 9.36301 7.18361 11.9351C7.18361 12.9383 7.57001 14.013 8.05119 14.5977C8.09263 14.6418 8.12193 14.6958 8.13625 14.7546C8.15056 14.8134 8.14939 14.8749 8.13285 14.933C8.0439 15.3005 7.84706 16.0937 7.80915 16.2556C7.75811 16.4685 7.64 16.5137 7.41837 16.4116C5.96024 15.7336 5.05037 13.6032 5.05037 11.8914C5.05037 8.21255 7.72457 4.83407 12.758 4.83407C16.8043 4.83407 19.9495 7.71678 19.9495 11.5706C19.9495 15.5907 17.4138 18.8262 13.8968 18.8262C12.7143 18.8262 11.6032 18.2124 11.2226 17.4862L10.4965 20.2596C10.2325 21.273 9.52098 22.543 9.04564 23.3173C10.6495 23.8132 12.3416 23.957 14.0062 23.739C15.6708 23.521 17.2687 22.9463 18.6908 22.0541C20.1129 21.1619 21.3257 19.9732 22.2463 18.5693C23.1668 17.1654 23.7735 15.5793 24.0249 13.9195C24.2763 12.2596 24.1664 10.565 23.7028 8.95146C23.2392 7.33793 22.4328 5.84348 21.3386 4.5702C20.2444 3.29693 18.8883 2.27483 17.3629 1.57372C15.8375 0.872623 14.1787 0.509041 12.4999 0.507815Z"
          fill={secondColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1527_13318">
          <rect
            width="23.33"
            height="23.33"
            fill={color}
            transform="translate(0.834961 0.507812)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
