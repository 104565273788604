import { IconProps } from "typing";

export const ExclusionAccountSucceedIcon = ({
  alt = "Exclusão de conta",
  height = "151",
  width = "174",
  strokeWidth = "0.84656",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 174 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.7138 11.7437C80.7049 3.01241 93.2951 3.01242 98.2862 11.7437L162.483 124.048C167.437 132.715 161.18 143.5 151.197 143.5H22.8028C12.8202 143.5 6.56254 132.715 11.5166 124.048L75.7138 11.7437Z"
        fill="#F6F7F7"
      />
      <g opacity="0.2">
        <path
          d="M84.447 23.6651C86.531 23.6651 88.2211 22.0878 88.2211 20.1426C88.2211 18.1975 86.5318 16.6201 84.447 16.6201C82.3621 16.6201 80.6729 18.1975 80.6729 20.1426"
          stroke="#040405"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.4509 23.6687C83.883 23.6687 83.345 23.5516 82.8617 23.3426L80.5391 23.803L81.0247 21.6309C80.7992 21.1784 80.673 20.6741 80.673 20.1426"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.0463 16.6397C83.7708 16.05 83.6177 15.4072 83.6177 14.7327C83.6177 11.8473 86.3867 9.53223 89.7745 9.53223C93.1623 9.53223 95.9313 11.8473 95.9313 14.7327C95.9313 16.4379 94.96 17.9391 93.4693 18.8858C93.4701 19.4289 93.4686 20.1608 93.4686 20.9294L91.0528 19.8189C90.6395 19.8929 90.2124 19.9331 89.7745 19.9331C89.2304 19.9331 88.7024 19.8728 88.1991 19.76"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M57.7708 124.152H55.846C55.4376 124.152 55.0458 124.304 54.757 124.573C54.4682 124.843 54.306 125.208 54.3062 125.59V127.386"
          stroke="#040405"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.3062 133.854V135.65C54.306 136.031 54.4682 136.397 54.757 136.666C55.0458 136.936 55.4376 137.087 55.846 137.087H57.7708"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.7002 137.087H66.625C67.0334 137.087 67.4252 136.936 67.714 136.666C68.0028 136.397 68.165 136.031 68.1648 135.65V133.854"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.1648 127.386V125.59C68.165 125.208 68.0028 124.843 67.714 124.573C67.4252 124.304 67.0334 124.152 66.625 124.152H64.7002"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.3857 127.745H65.085"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.3857 130.62H65.085"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.3857 133.494H61.2353"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M93.7708 32.1523H91.846C91.4376 32.1522 91.0458 32.3036 90.757 32.5732C90.4682 32.8427 90.306 33.2083 90.3062 33.5895V35.386"
          stroke="#040405"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.3062 41.8535V43.65C90.306 44.0312 90.4682 44.3968 90.757 44.6664C91.0458 44.9359 91.4376 45.0873 91.846 45.0872H93.7708"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M100.7 45.0872H102.625C103.033 45.0873 103.425 44.9359 103.714 44.6664C104.003 44.3968 104.165 44.0312 104.165 43.65V41.8535"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M104.165 35.386V33.5895C104.165 33.2083 104.003 32.8427 103.714 32.5732C103.425 32.3036 103.033 32.1522 102.625 32.1523H100.7"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.3857 35.745H101.085"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.3857 38.62H101.085"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.3857 41.4941H97.2353"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M142.447 135.664C144.531 135.664 146.221 134.087 146.221 132.142C146.221 130.197 144.532 128.619 142.447 128.619C140.362 128.619 138.673 130.197 138.673 132.142"
          stroke="#040405"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.451 135.669C141.883 135.669 141.345 135.552 140.862 135.343L138.539 135.803L139.025 133.631C138.799 133.178 138.673 132.674 138.673 132.143"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.046 128.64C141.771 128.05 141.618 127.407 141.618 126.733C141.618 123.847 144.387 121.532 147.774 121.532C151.162 121.532 153.931 123.847 153.931 126.733C153.931 128.438 152.96 129.939 151.469 130.886C151.47 131.429 151.469 132.161 151.469 132.929L149.053 131.819C148.64 131.893 148.212 131.933 147.774 131.933C147.23 131.933 146.702 131.873 146.199 131.76"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M63 70C63 65.0294 67.0294 61 72 61H114C118.971 61 123 65.0294 123 70V88.9991C123 90.6354 122.554 92.2407 121.71 93.6424L112.477 108.972C111.726 110.22 110.683 111.266 109.438 112.022L95.1523 120.694C93.7444 121.548 92.1291 122 90.4823 122H72C67.0294 122 63 117.971 63 113V70Z"
        fill="#F6F7F7"
      />
      <path
        d="M83.9105 121.638H68.9483C64.8167 121.638 61.4673 118.54 61.4673 114.719V66.2819C61.4673 62.4603 64.8167 59.3623 68.9483 59.3623H113.835C117.966 59.3623 121.316 62.4603 121.316 66.2819V76.6612"
        stroke="#47494D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.1318 121.638L104.947 120.518C105.638 120.439 106.28 120.149 106.772 119.694L130.555 97.6969C133.189 95.2708 133.199 91.3292 130.577 88.8917L130.555 88.8744C127.932 86.438 123.67 86.4287 121.035 88.8537V88.8744L97.4697 110.671C96.9903 111.111 96.6802 111.685 96.5869 112.304L95.1318 121.638Z"
        fill="#D4D5D6"
        stroke="#47494D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5591 73.2016H104.483"
        stroke="#B2B3B5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5591 85.3109H89.5212"
        stroke="#B2B3B5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5591 99.7006H89.5212"
        stroke="#B2B3B5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.2">
        <path
          d="M66.447 52.6651C68.531 52.6651 70.2211 51.0878 70.2211 49.1426C70.2211 47.1975 68.5318 45.6201 66.447 45.6201C64.3621 45.6201 62.6729 47.1975 62.6729 49.1426"
          stroke="#040405"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.4509 52.6687C65.883 52.6687 65.345 52.5516 64.8617 52.3426L62.5391 52.803L63.0247 50.6309C62.7992 50.1784 62.673 49.6741 62.673 49.1426"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.0463 45.6397C65.7708 45.05 65.6177 44.4072 65.6177 43.7327C65.6177 40.8473 68.3867 38.5322 71.7745 38.5322C75.1623 38.5322 77.9313 40.8473 77.9313 43.7327C77.9313 45.4379 76.96 46.9391 75.4693 47.8858C75.4701 48.4289 75.4686 49.1608 75.4686 49.9294L73.0528 48.8189C72.6395 48.8929 72.2124 48.9331 71.7745 48.9331C71.2304 48.9331 70.7024 48.8728 70.1991 48.76"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M132.771 103.152H130.846C130.438 103.152 130.046 103.304 129.757 103.573C129.468 103.843 129.306 104.208 129.306 104.59V106.386"
          stroke="#040405"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.306 112.854V114.65C129.306 115.031 129.468 115.397 129.757 115.666C130.046 115.936 130.438 116.087 130.846 116.087H132.771"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M139.7 116.087H141.625C142.033 116.087 142.425 115.936 142.714 115.666C143.003 115.397 143.165 115.031 143.165 114.65V112.854"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.165 106.386V104.59C143.165 104.208 143.003 103.843 142.714 103.573C142.425 103.304 142.033 103.152 141.625 103.152H139.7"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M132.386 106.745H140.085"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M132.386 109.62H140.085"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M132.386 112.494H136.235"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.1229 108.743C71.9492 100.676 68.9674 91.0251 61.2549 86.5153C53.5424 82.0054 43.6585 84.133 38.4898 91.4156C33.3211 98.6982 34.5828 108.719 41.3957 114.497C48.2086 120.274 58.3121 119.891 64.6665 113.613L70.2252 113.891L68.1229 108.743Z"
        fill="#FFC700"
        stroke="#47494D"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.9653 102.016L52.9653 90.5"
        stroke="#47494D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.9615 110.099C52.6175 110.099 52.3371 110.358 52.3408 110.676C52.3408 110.991 52.6212 111.25 52.9652 111.25C53.3092 111.25 53.5896 110.991 53.5896 110.673C53.5896 110.355 53.3092 110.099 52.9615 110.099Z"
        fill="#68A439"
      />
      <path
        d="M52.9615 110.099C52.6175 110.099 52.3371 110.358 52.3408 110.676C52.3408 110.991 52.6212 111.25 52.9652 111.25C53.3092 111.25 53.5896 110.991 53.5896 110.673C53.5896 110.355 53.3092 110.099 52.9615 110.099"
        stroke="#47494D"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.2">
        <path
          d="M23.447 136.664C25.531 136.664 27.2211 135.087 27.2211 133.142C27.2211 131.197 25.5318 129.619 23.447 129.619C21.3621 129.619 19.6729 131.197 19.6729 133.142"
          stroke="#040405"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.4509 136.669C22.883 136.669 22.345 136.552 21.8617 136.343L19.5391 136.803L20.0247 134.631C19.7992 134.178 19.673 133.674 19.673 133.143"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0463 129.64C22.7708 129.05 22.6177 128.407 22.6177 127.733C22.6177 124.847 25.3867 122.532 28.7745 122.532C32.1623 122.532 34.9313 124.847 34.9313 127.733C34.9313 129.438 33.96 130.939 32.4693 131.886C32.4701 132.429 32.4686 133.161 32.4686 133.929L30.0528 132.819C29.6395 132.893 29.2124 132.933 28.7745 132.933C28.2304 132.933 27.7024 132.873 27.1991 132.76"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <ellipse cx="91.5" cy="132" rx="29.5" ry="2" fill="#B2B3B5" />
    </svg>
  );
};
