import {
  buildTestIds,
  useToast as defaultUseToast,
  useAppContext,
  useAuth,
} from "application";
import {
  useAuthentication as defaultUseAuthentication,
  useTwoFactor as defaultUseTwoFactor,
  useShallow,
} from "application/state-manager";
import { identityApi } from "implementations";
import { useRouter as defaultUseRouter } from "next/router";
import { useState } from "react";
import { ChangePassword } from "../../../../../ChangePassword";
import {
  ArrowLeftIcon,
  ChangeNewPasswordContainer,
  ChangeNewPasswordContent,
  ContentInformation,
  RecoverAccessText,
  WrapperIcon,
} from "./styles";

interface ChooseNewPasswordProps {
  useRouter?: typeof defaultUseRouter;
  useAuthentication?: typeof defaultUseAuthentication;
  useTwoFactor?: typeof defaultUseTwoFactor;
  useToast?: typeof defaultUseToast;
}

const ChooseNewPassword = ({
  useAuthentication = defaultUseAuthentication,
  useRouter = defaultUseRouter,
  useTwoFactor = defaultUseTwoFactor,
  useToast = defaultUseToast,
}: ChooseNewPasswordProps) => {
  const router = useRouter();
  const { addToast } = useToast();
  const {
    changeCurrentStep,
    userIdentification,
    identificationType,
    setIsAuthenticationModalActive,
  } = useAuthentication(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
      userIdentification: state.userIdentification,
      identificationType: state.identificationType,
      setIsAuthenticationModalActive: state.setIsAuthenticationModalActive,
    }))
  );

  const { setIsOpenedDropdownUser } = useAppContext();

  const { onAuthenticateUser, isAuthenticated } = useAuth();

  const [isUnderAction, setIsUnderAction] = useState(false);

  const {
    availableMethods,
    channelSelected,
    tokenCode,
    setIsTokenCodeValid,
    clearTimer,
  } = useTwoFactor(
    useShallow((state) => ({
      availableMethods: state.availableMethods,
      channelSelected: state.channelSelected,
      tokenCode: state.tokenCode,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      clearTimer: state.clearTimer,
    }))
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const changePassword = async (newPassword: string) => {
    const handleRecoverAccount = async (
      location: string,
      hasLocation?: boolean
    ) => {
      setIsUnderAction(true);
      const { data: response } = await identityApi.recoverAccount({
        email: identificationType === "email" ? userIdentification : "",
        phoneNumber:
          channelSelected === 1 ? String(availableMethods?.phoneNumber) : "",
        newPassword,
        token: tokenCode,
        channel: Number(channelSelected),
        cpf: identificationType === "cpf" ? userIdentification : "",
        faType: 9,
        location: hasLocation ? location : "",
      });

      if (
        response?.data?.errors?.errorMessages?.[0] ===
        "The new password cannot be the same as the old password."
      ) {
        setIsUnderAction(false);
        addToast({
          description: "A nova senha não pode ser igual a senha antiga",
          type: "error",
          title: "Erro",
          isNewToast: true,
          newToastTheme: "dark",
        });
        return;
      }

      if (
        response?.data?.status === 400 ||
        response?.data?.status === 500 ||
        response?.error ||
        response?.status === 400 ||
        response?.status === 500
      ) {
        setIsUnderAction(false);
        setIsTokenCodeValid(false);
        changeCurrentStep("passwordFeedback");
        return;
      }

      clearTimer(9, channelSelected);
      setIsTokenCodeValid(true);
      setIsUnderAction(false);

      const token = await onAuthenticateUser({
        login: userIdentification,
        password: newPassword,
      });

      if (!isAuthenticated && !token) {
        addToast({
          title: "Crendenciais Inválidas",
          type: "error",
        });
        return;
      }

      setIsOpenedDropdownUser(false);

      addToast({
        title: "Senha alterada com sucesso",
        description: "Sua senha foi alterada",
        type: "success",
        isNewToast: true,
        newToastTheme: "dark",
      });

      if (router?.pathname?.includes("meu-carrinho")) {
        router.push("/checkout/pagamento");
        setIsAuthenticationModalActive(false);
        changeCurrentStep("login");
      }
    };

    try {
      setIsUnderAction(true);
      navigator?.geolocation?.getCurrentPosition(
        async (position) => {
          handleRecoverAccount(
            `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`,
            true
          );
        },
        async () => {
          handleRecoverAccount("", false);
        }
      );
    } finally {
      setIsUnderAction(false);
    }
  };

  return (
    <ChangeNewPasswordContainer
      {...buildTestIds("change-new-password-container")}
      isLogin={router?.pathname === "/login"}
    >
      <ChangeNewPasswordContent>
        <ContentInformation {...buildTestIds("content-information")}>
          {router?.pathname !== "/login" && (
            <WrapperIcon
              onClick={() => changeCurrentStep("forgotPassword")}
              {...buildTestIds("forgot-password-back-button")}
            >
              <ArrowLeftIcon />
            </WrapperIcon>
          )}
          <RecoverAccessText {...buildTestIds("recover-access-text")}>
            Escolha sua nova senha
          </RecoverAccessText>
        </ContentInformation>
        <ChangePassword
          onChangePassword={(newPassword) => changePassword(newPassword)}
          isUnderAction={isUnderAction}
        />
      </ChangeNewPasswordContent>
    </ChangeNewPasswordContainer>
  );
};

export { ChooseNewPassword };
