// import { setupWorker } from "msw";
// import { setupServer } from "msw/node";
// import { CoupleDetailDto, SupportContactDto, UtilInfoDto } from "typing/dto";
import { Endpoint, IHttpResponse } from "typing";
import {
  supportContactHandlers,
  utilInfoHandlers,
} from "application/services/mocks";
import { IHttpClient } from "app-domain/abstractions";

// const runMockServiceWorker = () => {
//     if (typeof window === "undefined") {
//         const server = setupServer(
//             ...supportContactHandlers,
//             ...utilInfoHandlers,
//             ...weddingGiftListHandlers
//         );
//         server.listen({
//             onUnhandledRequest: "bypass",
//         });
//     } else {
//         const worker = setupWorker(
//             ...supportContactHandlers,
//             ...utilInfoHandlers,
//             ...weddingGiftListHandlers
//         );
//         worker.start({
//             onUnhandledRequest: "bypass",
//         });
//     }
// };

// export default runMockServiceWorker;
const notImplementedErrorStr = "Function not implemented.";

const MockedClient: IHttpClient = {
  useGet: async <Data = unknown, Error = { message: string }>(
    endpoint: string
  ) => {
    const allResponses = {
      ...supportContactHandlers,
      ...utilInfoHandlers,
    };

    const response = await allResponses[`${endpoint as Endpoint}`]();

    if (!response) {
      return {
        data: null,
        error: { message: "Endpoint does not exist" },
        isLoading: false,
      } as unknown as IHttpResponse<Data, Error>;
    }

    return {
      data: response,
      error: null,
      isLoading: false,
    } as unknown as IHttpResponse<Data, Error>;
  },
  usePost: async <Data = unknown, Error = { message: string }>(
    endpoint: string
    // body: BodyInit
  ) => {
    const allResponses = {
      ...supportContactHandlers,
      ...utilInfoHandlers,
    };

    const response = await allResponses[`${endpoint as Endpoint}`]();

    if (!response) {
      return {
        data: null,
        error: { message: "Endpoint does not exist" },
        isLoading: false,
      } as unknown as IHttpResponse<Data, Error>;
    }

    return {
      data: response,
      error: null,
      isLoading: false,
    } as unknown as IHttpResponse<Data, Error>;
  },
  usePatch<Data = unknown, Error = unknown>(): // body: BodyInit
  IHttpResponse<Data, Error> | Promise<IHttpResponse<Data, Error>> {
    throw new Error(notImplementedErrorStr);
  },
  useDelete<Data = unknown, Error = unknown>(): // body: BodyInit
  IHttpResponse<Data, Error> | Promise<IHttpResponse<Data, Error>> {
    throw new Error(notImplementedErrorStr);
  },
  usePut<Data = unknown, Error = unknown>(): Promise<Data | Error> {
    throw new Error(notImplementedErrorStr);
  },
};

export { MockedClient };
