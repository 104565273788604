import { ICookie } from "app-domain";
import { LocationProps } from "typing";

const cookieModalBranchLocationStr = "@FC:Ecom:Modal:BranchLocation";
const cookieBranchLocationStr = "@FC:Ecom:Dropdown:BranchLocation";
const cookieRegionStr = "@FC:Ecom:Dropdown:Region";
const cookiePostalCodeStr = "@FC:Ecom:Dropdown:Cep";
const optionsMaxAgeEqualOneYear = {
  options: {
    maxAge: 60 * 60 * 24 * 365,
  },
};

const getClientLocationDetails = (cookie: ICookie): LocationProps => {
  const clientLocation =
    cookie.getCookie({
      name: cookieRegionStr,
    }) || "";

  const clientPostalCode = localStorage?.getItem(cookiePostalCodeStr) || "";

  const clientBranchId = Number(
    cookie.getCookie({
      name: cookieBranchLocationStr,
    }) || 2
  );

  return {
    clientLocation,
    clientBranchId,
    clientPostalCode,
  };
};

export {
  cookieModalBranchLocationStr,
  cookieBranchLocationStr,
  cookieRegionStr,
  cookiePostalCodeStr,
  optionsMaxAgeEqualOneYear,
  getClientLocationDetails,
};
