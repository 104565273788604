import styled from "styled-components";
import { theme } from "ui";

export const HighestDiscountedBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.yellow["850"]};
  color: ${theme.colors.yellow["80"]};
  padding: ${theme.space["x1.5"]} 0;
`;
