import { IconProps } from "typing";
import { theme } from "../themes";

export const GiftOutlineIcon = ({
  width = 24,
  height = 24,
  alt = "Presente",
  color = theme.colors.primary["200"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8H4C3.448 8 3 8.448 3 9V11C3 11.552 3.448 12 4 12H20C20.552 12 21 11.552 21 11V9C21 8.448 20.552 8 20 8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21V8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6961 6.61194C14.6181 7.73394 12.9211 7.99994 12.0991 7.99994"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0988 8C12.0988 8 11.6048 4.884 12.8198 3.62"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6958 6.61199C16.4898 5.78599 16.4898 4.44599 15.6958 3.61999C14.9018 2.79399 13.6148 2.79399 12.8208 3.61999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3042 6.61194C9.3822 7.73394 11.0792 7.99994 11.9012 7.99994"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9012 8C11.9012 8 12.3952 4.884 11.1802 3.62"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.304 6.61199C7.51 5.78599 7.51 4.44599 8.304 3.61999C9.098 2.79399 10.385 2.79399 11.179 3.61999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 12V20C19 20.552 18.552 21 18 21H6C5.448 21 5 20.552 5 20V12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
