import { GiftProducts, OrderItemsBody, Product } from "typing";
import { createStore, useShallow as usePurchaseShallow } from "../adapter";
import { CartItemsContextReduce } from "../../context/ShoppingHookContext/typesContextCart";

type PurchaseState = {
  purchaseProduct: OrderItemsBody[];
  productsFromCart: CartItemsContextReduce[];
  userBranchLocation: number;
};

type PurchaseDispatch = {
  changePurchaseProducts: (
    product: Product | GiftProducts,
    quantity: number,
    packaging?: number
  ) => void;
  setProductsFromCart: (products: CartItemsContextReduce[]) => void;
  setBranchLocation: (branchLocation: number) => void;
  clearPurchaseProducts: () => void;
};

type PurchaseProps = PurchaseState & PurchaseDispatch;

const usePurchaseStore = createStore<PurchaseProps>((set, get) => ({
  purchaseProduct: [],
  productsFromCart: [],
  userBranchLocation: 0,
  setProductsFromCart: (products) => {
    if (get().productsFromCart !== products) {
      set({ productsFromCart: products });
    }
  },
  setBranchLocation: (branchLocation) => {
    if (get().userBranchLocation !== branchLocation) {
      set({ userBranchLocation: branchLocation });
    }
  },
  changePurchaseProducts: (
    product: Product | GiftProducts,
    quantity: number,
    packaging = 0
  ) => {
    const productItem = product as Product;
    const productGift = product as GiftProducts;
    const idProduct = productItem?.id || productGift?.productId;

    const cartItem = get().productsFromCart.find(
      (productCart) =>
        productCart.productId === idProduct && productCart.package === packaging
    );

    const purchaseProductAlreadyExists = get().purchaseProduct.some(
      (productToPurchase) =>
        productToPurchase.productId === cartItem?.productId &&
        productToPurchase.packaging === cartItem?.package
    );

    const productPrice = productItem?.prices?.length
      ? productItem?.prices[(cartItem?.package as number) ?? packaging]
      : productGift?.price;

    if (!purchaseProductAlreadyExists && cartItem) {
      set((state) => ({
        purchaseProduct: [
          ...state.purchaseProduct,
          {
            idFamily: product?.family,
            branchProduct: get().userBranchLocation,
            description: productItem?.description || productGift?.productName,
            packaging: Number(cartItem?.package),
            packagingQuantity: Number(cartItem?.packingQuantity),
            price: productPrice,
            productId: idProduct,
            factPackaged: productItem?.factPackaged,
            quantity,
          } as OrderItemsBody,
        ],
      }));
    }
  },
  clearPurchaseProducts: () => {
    set({ purchaseProduct: [] });
  },
}));

export { usePurchaseStore, usePurchaseShallow };
