import { buildTestIds } from "application";
import { Step } from "typing";
import { ReactNode } from "react";
import {
  StatusContainer,
  StatusStepperContainer,
  StepperBar,
  StepperContainer,
  StatusIcon,
  StatusText,
  Stepper,
  StatusProgressContainer,
  StepperWrapper,
  StatusProgressWrapper,
  IconWrapper,
  StepTitle,
} from "./styles";

interface StatusProgressProps {
  barColor?: string;
  statusStep: Step[];
  hasIcon?: boolean;
  icon?: ReactNode;
  stepTitle?: string;
  height?: string;
  width?: string;
}

const StatusProgress = ({
  barColor,
  statusStep,
  hasIcon = true,
  icon,
  stepTitle,
  height,
  width,
}: StatusProgressProps) => {
  return (
    <StepperWrapper
      {...buildTestIds("stepper-wrapper")}
      hasHeader={!!(icon && stepTitle)}
    >
      {icon && (
        <IconWrapper {...buildTestIds("icon-wrapper")}>{icon}</IconWrapper>
      )}
      <StatusProgressWrapper {...buildTestIds("status-progress-wrapper")}>
        {stepTitle ? (
          <StepTitle {...buildTestIds("step-title")}>{stepTitle}</StepTitle>
        ) : null}
        <StatusProgressContainer {...buildTestIds("status-progress-container")}>
          {hasIcon ? (
            <StatusStepperContainer
              {...buildTestIds("status-stepper-container")}
            >
              <StepperContainer {...buildTestIds("stepper-container")}>
                {statusStep.map((step) => {
                  return (
                    <StatusContainer
                      key={step.name}
                      width={width}
                      {...buildTestIds(`status-${step.name}-container`)}
                    >
                      {step?.icon ? (
                        <StatusIcon
                          color={step.statusColor}
                          {...buildTestIds(`status-${step.name}-icon`)}
                        >
                          {step.icon}
                        </StatusIcon>
                      ) : null}

                      <StatusText
                        color={step?.statusTextColor}
                        {...buildTestIds(`status-${step.name}-text`)}
                      >
                        {step?.statusText}
                      </StatusText>
                    </StatusContainer>
                  );
                })}
              </StepperContainer>
              <StepperBar
                backgroundColor={barColor}
                {...buildTestIds("stepper-bar")}
              />
            </StatusStepperContainer>
          ) : (
            statusStep?.map((step) => (
              <Stepper
                height={height}
                color={step?.statusColor}
                key={step?.name}
              />
            ))
          )}
        </StatusProgressContainer>
      </StatusProgressWrapper>
    </StepperWrapper>
  );
};

export { StatusProgress };
