import styled, { css } from "styled-components";
import { theme, Text } from "ui";

interface SidebarFilterListContainerProps {
  isBlackTheme?: boolean;
}

interface SidebarProductListProps {
  isOpen?: boolean;
  className?: string;
  onClick(): void;
}

export const SidebarFilterListHeader = styled.h3<SidebarProductListProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  font-size: 1.3rem;
  cursor: pointer;
`;

export const SidebarFilterListDrop = styled.div<SidebarProductListProps>`
  &.active {
    height: auto;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: all 0.4s ease-in-out, visibility 0.4s;
  }

  &.inactive {
    height: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: all 0.4s ease-in-out, visibility 0.4s;
  }
`;

export const SidebarFilterListItem = styled.div<SidebarProductListProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.8rem;
  margin-bottom: 1rem;
  border-left: 2px solid ${theme.colors.neutral["320"]};
  cursor: pointer;

  &.active {
    color: ${theme.colors.primary["200"]};
    border-color: ${theme.colors.primary["200"]};
  }
`;

export const FilterText = styled(Text)`
  max-width: 82%;
  line-height: 1.4;
`;

export const FilterCounter = styled.span`
  background: ${theme.colors.neutral.white};
  border: thin solid ${theme.colors.neutral["320"]};
  padding: 0.1rem 0.5rem;
  color: ${theme.colors.neutral["450"]};
  border-radius: 50px;

  &.active {
    background-color: ${theme.colors.primary["200"]};
    border-color: ${theme.colors.primary["200"]};
    color: ${theme.colors.neutral.white};
  }
`;

export const ViewMoreButton = styled.button`
  background-color: ${theme.colors.neutral.white};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  padding: 0.4rem;
  min-height: 2rem;
  background-color: ${theme.colors.neutral.white};
  font-size: 1rem;
  cursor: pointer;
  border: none;
  margin-bottom: 5px;
`;

export const SidebarFilterList = styled.div<SidebarFilterListContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      ${SidebarFilterListHeader} {
        color: ${theme.colors.neutral["25"]};

        > svg > path {
          fill: ${theme.colors.neutral.white};
        }
      }

      ${SidebarFilterListItem} {
        color: ${theme.colors.neutral["25"]};
        border-left-color: ${theme.colors.neutral["25"]};
        position: relative;
        width: 100%;

        &.active {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: visible;
          color: ${theme.colors.secondary["380"]};
          border-left-color: ${theme.colors.secondary["250"]};

          &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.2;
            border-radius: 0 30px 30px 0;
          }

          ${FilterCounter} {
            color: ${theme.colors.neutral.white};
            background: ${theme.colors.neutral["25"]};
            border-color: ${theme.colors.secondary["250"]};
          }
        }
      }

      ${FilterCounter} {
        color: ${theme.colors.neutral["999"]};
        background: ${theme.colors.neutral["25"]};
        border-color: ${theme.colors.neutral.white};
      }

      ${ViewMoreButton} {
        color: ${theme.colors.neutral["999"]};
        background: ${theme.colors.neutral["25"]};
      }
    `}
`;
