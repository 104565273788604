import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const HomeImprovementsIcon = ({
  height = 322,
  width = 211,
  color = `${theme.colors.neutral["150"]}`,
  secondColor = `${theme.colors.secondary["410"]}`,
  thirdColor = `${theme.colors.yellow["1100"]}`,
  fourthColor = `${theme.colors.neutral["white-075"]}`,
  fifthColor = `${theme.colors.primary["900"]}`,
  sixthColor = `${theme.colors.primary["180"]}`,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 211 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M108 108H2V214H108V108Z" fill={secondColor} />
      <path
        d="M34.8111 142.428C34.2714 142.428 33.7178 142.221 33.3027 141.805C28.9434 137.446 28.9434 130.333 33.3027 125.974C37.6758 121.614 44.7752 121.614 49.1345 125.974L58.4759 135.315C59.3062 136.145 59.3062 137.501 58.4759 138.332C57.6455 139.162 56.2893 139.162 55.459 138.332L46.1176 128.99C43.419 126.292 39.032 126.292 36.3334 128.99C33.6348 131.689 33.6348 136.076 36.3334 138.775C37.1638 139.605 37.1638 140.961 36.3334 141.792C35.9182 142.207 35.3647 142.414 34.825 142.414"
        fill={thirdColor}
      />
      <path
        d="M53.0235 192.902C52.47 192.902 51.9302 192.694 51.5151 192.279L20.0451 160.809C19.2148 159.979 19.2148 158.622 20.0451 157.792L56.8708 120.967C57.7011 120.136 59.0573 120.136 59.8877 120.967L91.3576 152.436C92.1879 153.267 92.1879 154.623 91.3576 155.453L54.532 192.279C54.1168 192.694 53.5632 192.902 53.0235 192.902ZM24.5705 159.287L53.0235 187.74L86.8322 153.931L58.3792 125.478L24.5705 159.287V159.287Z"
        fill={thirdColor}
      />
      <path
        d="M56.9536 142.43C53.826 142.43 51.2935 139.883 51.2935 136.77C51.2935 133.656 53.8398 131.109 56.9536 131.109C60.0674 131.109 62.6138 133.656 62.6138 136.77C62.6138 139.883 60.0674 142.43 56.9536 142.43ZM56.9536 135.372C56.1925 135.372 55.5559 135.995 55.5559 136.77C55.5559 137.545 56.1786 138.167 56.9536 138.167C57.7286 138.167 58.3514 137.545 58.3514 136.77C58.3514 135.995 57.7286 135.372 56.9536 135.372Z"
        fill={thirdColor}
      />
      <path
        d="M88.8251 191.325C85.6006 191.325 83.843 189.706 82.9297 188.35C80.7016 185.015 80.7293 179.147 83.0265 170.415C84.6319 164.325 86.7492 159.15 86.8461 158.928C87.1782 158.125 87.9532 157.6 88.8251 157.6C89.6969 157.6 90.4719 158.125 90.8041 158.928C90.8871 159.15 93.0183 164.325 94.6236 170.415C96.9209 179.147 96.9486 185.015 94.7205 188.35C93.8071 189.706 92.0496 191.325 88.8251 191.325M88.8251 165.986C88.2715 167.647 87.6764 169.557 87.1506 171.563C86.2787 174.898 84.4658 182.967 86.4863 185.983C86.8184 186.482 87.3858 187.063 88.8251 187.063C90.2643 187.063 90.8317 186.495 91.1639 185.983C91.842 184.973 93.129 181.555 90.4996 171.563C89.9737 169.57 89.3786 167.647 88.8251 165.986Z"
        fill={thirdColor}
      />
      <path
        d="M37.6486 145.279C37.095 145.279 36.5553 145.072 36.1401 144.656L33.2893 141.819C32.4589 140.989 32.4589 139.633 33.2893 138.802C34.1196 137.972 35.4758 137.972 36.3062 138.802L39.157 141.653C39.9873 142.484 39.9873 143.84 39.157 144.67C38.7418 145.085 38.1883 145.293 37.6486 145.293"
        fill={thirdColor}
      />
      <path
        d="M54.1858 194.075C53.6323 194.075 53.0925 193.868 52.6774 193.453L18.5227 159.298C17.6923 158.468 17.6923 157.111 18.5227 156.281C19.353 155.451 20.7092 155.451 21.5396 156.281L55.6943 190.436C56.5246 191.266 56.5246 192.622 55.6943 193.453C55.2791 193.868 54.7255 194.075 54.1858 194.075"
        fill={thirdColor}
      />
      <path
        d="M90.7903 157.017C90.2368 157.017 89.697 156.809 89.2819 156.394L55.1272 122.239C54.2968 121.409 54.2968 120.053 55.1272 119.222C55.9575 118.392 57.3137 118.392 58.1441 119.222L92.2988 153.377C93.1291 154.207 93.1291 155.564 92.2988 156.394C91.8836 156.809 91.33 157.017 90.7903 157.017"
        fill={thirdColor}
      />
      <path
        d="M67.9419 176.611C66.4888 176.611 65.1326 176.044 64.1085 175.034L63.209 174.134C61.1055 172.03 61.1055 168.598 63.209 166.481L74.3356 155.354C75.1659 154.524 76.5221 154.524 77.3525 155.354C78.1828 156.185 78.1828 157.541 77.3525 158.371L66.2259 169.498C65.7831 169.941 65.7831 170.674 66.2259 171.103L67.1254 172.003C67.4161 172.293 67.7482 172.335 67.9281 172.335C68.108 172.335 68.454 172.293 68.7308 172.003L88.3268 152.421C89.1572 151.59 90.5134 151.59 91.3576 152.421C92.1879 153.251 92.1879 154.607 91.3576 155.438L71.7615 175.034C70.7374 176.058 69.3812 176.611 67.9281 176.611"
        fill={thirdColor}
      />
      <path
        d="M75.8576 158.996C75.304 158.996 74.7643 158.788 74.3491 158.373C73.5188 157.543 73.5188 156.187 74.3491 155.356C74.6397 155.066 74.6813 154.734 74.6813 154.554C74.6813 154.374 74.6397 154.042 74.3491 153.751L73.4496 152.851C73.159 152.561 72.8268 152.519 72.6469 152.519C72.467 152.519 72.121 152.561 71.8443 152.851C71.0139 153.682 69.6577 153.682 68.8273 152.851C67.997 152.021 67.997 150.665 68.8273 149.834C70.9309 147.731 74.3768 147.731 76.4803 149.834L77.3799 150.734C78.404 151.758 78.9575 153.114 78.9575 154.567C78.9575 156.021 78.3901 157.377 77.3799 158.401C76.9647 158.816 76.4111 159.024 75.8714 159.024"
        fill={thirdColor}
      />
      <path
        d="M61.9356 160.503C60.5517 160.503 59.1678 159.977 58.1022 158.925L57.2026 158.026C55.0991 155.922 55.0991 152.476 57.2026 150.373L71.6091 135.967C72.4394 135.136 73.7956 135.136 74.626 135.967C75.4563 136.797 75.4563 138.153 74.626 138.983L60.2196 153.39C59.7767 153.833 59.7767 154.566 60.2196 154.995L61.1191 155.895C61.5481 156.338 62.2954 156.338 62.7244 155.895L68.7998 149.819C69.6301 148.989 70.9863 148.989 71.8167 149.819C72.647 150.65 72.647 152.006 71.8167 152.836L65.7413 158.912C64.6896 159.963 63.3057 160.489 61.9079 160.489"
        fill={thirdColor}
      />
      <path d="M214 2H108V108H214V2Z" fill={fourthColor} />
      <path
        d="M151.924 48.9402H120.731C119.555 48.9402 118.6 47.9853 118.6 46.809V30.6312C118.6 29.4549 119.555 28.5 120.731 28.5H151.924C153.101 28.5 154.056 29.4549 154.056 30.6312V46.809C154.056 47.9853 153.101 48.9402 151.924 48.9402ZM122.863 44.664H149.779V32.7624H122.863V44.664Z"
        fill={color}
      />
      <path
        d="M151.924 81.2937H120.731C119.555 81.2937 118.6 80.3389 118.6 79.1625V62.9847C118.6 61.8084 119.555 60.8535 120.731 60.8535H151.924C153.101 60.8535 154.056 61.8084 154.056 62.9847V79.1625C154.056 80.3389 153.101 81.2937 151.924 81.2937ZM122.863 77.0175H149.779V65.1021H122.863V77.0175Z"
        fill={color}
      />
      <path
        d="M183.049 81.2937H151.855C150.679 81.2937 149.71 80.3389 149.71 79.1625V62.9847C149.71 61.8084 150.665 60.8535 151.855 60.8535H183.049C184.225 60.8535 185.18 61.8084 185.18 62.9847V79.1625C185.18 80.3389 184.225 81.2937 183.049 81.2937ZM153.987 77.0175H180.904V65.1021H153.987V77.0175Z"
        fill={color}
      />
      <path
        d="M167.48 65.1182H136.286C135.11 65.1182 134.155 64.1633 134.155 62.9869V46.8091C134.155 45.6328 135.11 44.6641 136.286 44.6641H167.48C168.656 44.6641 169.625 45.619 169.625 46.8091V62.9869C169.625 64.1633 168.67 65.1182 167.48 65.1182ZM138.418 60.8419H165.335V48.9403H138.418V60.8419Z"
        fill={color}
      />
      <path
        d="M198.603 65.1182H167.41C166.234 65.1182 165.279 64.1633 165.279 62.9869V46.8091C165.279 45.6328 166.234 44.6641 167.41 44.6641H198.603C199.78 44.6641 200.735 45.619 200.735 46.8091V62.9869C200.735 64.1633 199.78 65.1182 198.603 65.1182ZM169.541 60.8419H196.458V48.9403H169.541V60.8419Z"
        fill={color}
      />
      <path d="M214 108H108V214H214V108Z" fill={fifthColor} />
      <path
        d="M161.4 173.374C160.847 173.374 160.307 173.167 159.892 172.752C155.491 168.351 152.737 162.525 152.128 156.339C151.588 150.831 152.765 145.254 155.449 140.465L146.025 131.041C143.188 128.204 143.188 123.582 146.025 120.731C147.409 119.347 149.236 118.6 151.187 118.6C153.138 118.6 154.965 119.361 156.349 120.731L165.773 130.155C170.562 127.47 176.139 126.294 181.647 126.834C187.833 127.443 193.659 130.197 198.06 134.598C198.89 135.428 198.89 136.784 198.06 137.614L162.936 172.738C162.521 173.153 161.968 173.361 161.428 173.361M151.201 122.862C150.398 122.862 149.637 123.18 149.07 123.748C147.893 124.924 147.893 126.834 149.07 128.01L159.67 138.611C160.39 139.33 160.501 140.438 159.947 141.296C157.152 145.586 155.892 150.775 156.39 155.91C156.833 160.393 158.618 164.67 161.483 168.129L193.437 136.175C189.978 133.31 185.701 131.525 181.218 131.082C176.083 130.57 170.894 131.844 166.604 134.639C165.759 135.193 164.638 135.082 163.919 134.362L153.318 123.762C152.751 123.194 151.99 122.876 151.187 122.876L151.201 122.862Z"
        fill={sixthColor}
      />
      <path
        d="M178.962 165.665C175.89 165.665 172.817 164.502 170.478 162.163C169.648 161.333 169.648 159.977 170.478 159.147C171.309 158.316 172.665 158.316 173.509 159.147C176.512 162.15 181.411 162.15 184.428 159.147C187.431 156.13 187.431 151.231 184.428 148.228C183.598 147.397 183.598 146.041 184.428 145.211C185.259 144.38 186.615 144.38 187.445 145.211C192.123 149.888 192.123 157.5 187.445 162.177C185.106 164.516 182.034 165.679 178.962 165.679"
        fill={sixthColor}
      />
      <path
        d="M150.218 207.155C149.443 207.155 148.696 206.726 148.309 205.993L129.432 168.904C129.059 168.17 129.142 167.285 129.64 166.634L152.972 135.995C153.692 135.054 155.034 134.874 155.962 135.593C156.903 136.313 157.083 137.655 156.363 138.596L133.833 168.17L152.1 204.069C152.64 205.121 152.211 206.408 151.159 206.947C150.855 207.113 150.523 207.183 150.191 207.183"
        fill={sixthColor}
      />
      <path d="M216 212H106V322H216V212Z" fill={fourthColor} />
      <path
        d="M145.965 307.392C145.906 307.392 145.836 307.392 145.777 307.392C144.323 307.287 143.221 306.02 143.327 304.566L147.653 243.867C147.758 242.413 149.024 241.322 150.478 241.416C151.932 241.521 153.022 242.788 152.928 244.242L148.602 304.941C148.509 306.337 147.348 307.392 145.976 307.392"
        fill={color}
      />
      <path
        d="M176.034 307.392C174.663 307.392 173.502 306.325 173.408 304.941L169.082 244.242C168.977 242.788 170.079 241.521 171.533 241.416C172.986 241.322 174.252 242.413 174.358 243.867L178.684 304.566C178.789 306.02 177.687 307.287 176.234 307.392C176.175 307.392 176.105 307.392 176.046 307.392"
        fill={color}
      />
      <path
        d="M177.219 245.448H144.793C143.339 245.448 142.155 244.264 142.155 242.81V235.867C142.155 234.413 143.339 233.229 144.793 233.229H177.219C178.673 233.229 179.857 234.413 179.857 235.867V242.81C179.857 244.264 178.673 245.448 177.219 245.448ZM147.43 240.171H174.582V238.506H147.43V240.171Z"
        fill={color}
      />
      <path
        d="M173.081 284.004H148.907C147.454 284.004 146.27 282.819 146.27 281.365C146.27 279.911 147.454 278.727 148.907 278.727H173.081C174.534 278.727 175.719 279.911 175.719 281.365C175.719 282.819 174.534 284.004 173.081 284.004Z"
        fill={color}
      />
      <path
        d="M173.937 292.662H148.063C146.61 292.662 145.426 291.478 145.426 290.023C145.426 288.569 146.61 287.385 148.063 287.385H173.937C175.39 287.385 176.574 288.569 176.574 290.023C176.574 291.478 175.39 292.662 173.937 292.662Z"
        fill={color}
      />
    </svg>
  );
};
