import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const RequestErrorIcon = ({
  width = 114,
  height = 120,
  color = theme.colors.neutral["white-075"],
  secondColor = theme.colors.neutral["760"],
  thirdColor = theme.colors.neutral["240"],
  fourthColor = theme.colors.neutral["350"],
  fifthColor = theme.colors.neutral["940"],
  sixthColor = theme.colors.primary["155"],
  seventhColor = theme.colors.neutral["150"],
  alt = "Erro no envio da mensagem",
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 114 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="64.09" cy="44.966" r="33.3371" fill={color} />
      <path
        opacity="0.17"
        d="M68.9584 30.5796L81.1195 41.0143L43.3046 113.604L8.58522 71.714L68.9584 30.5796Z"
        fill="url(#paint0_linear_4814_275)"
      />
      <path
        opacity="0.17"
        d="M70.2219 32.0287L80.1914 39.6525L42.4171 100.65L22.6754 70.8567L70.2219 32.0287Z"
        fill="url(#paint1_linear_4814_275)"
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.0006 54.8299C80.0335 54.8299 79.249 55.5621 79.249 56.4651C79.249 57.3682 80.0335 58.0999 81.0006 58.0999H85.8179C86.7855 58.0999 87.57 57.3677 87.57 56.4651C87.57 55.5625 86.7855 54.8303 85.8184 54.8303C85.8184 53.5886 84.7399 52.582 83.4095 52.582C82.0791 52.582 81.0006 53.5882 81.0006 54.8299Z"
          stroke={secondColor}
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.1256 62.2264C71.1585 62.2264 70.374 62.9586 70.374 63.8616C70.374 64.7647 71.1585 65.4964 72.1256 65.4964H76.9429C77.9105 65.4964 78.695 64.7642 78.695 63.8616C78.695 62.959 77.9105 62.2268 76.9434 62.2268C76.9434 60.9851 75.8649 59.9785 74.5345 59.9785C73.2041 59.9785 72.1256 60.9847 72.1256 62.2264Z"
          fill={secondColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.7701 71.8416C60.803 71.8416 60.0186 72.5738 60.0186 73.4769C60.0186 74.3799 60.803 75.1116 61.7701 75.1116H66.5875C67.555 75.1116 68.3395 74.3795 68.3395 73.4769C68.3395 72.5742 67.555 71.8421 66.5879 71.8421C66.5879 70.6003 65.5094 69.5938 64.179 69.5938C62.8486 69.5938 61.7701 70.5999 61.7701 71.8416Z"
          stroke={thirdColor}
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.2213 52.4178C36.2542 52.4178 35.4697 53.15 35.4697 54.053C35.4697 54.9561 36.2542 55.6878 37.2213 55.6878H42.0386C43.0062 55.6878 43.7907 54.9556 43.7907 54.053C43.7907 53.1504 43.0062 52.4182 42.0391 52.4182C42.0391 51.1765 40.9606 50.1699 39.6302 50.1699C38.2998 50.1699 37.2213 51.1761 37.2213 52.4178Z"
          stroke={thirdColor}
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.9478 27.4627C65.9808 27.4627 65.1963 28.1949 65.1963 29.0979C65.1963 30.001 65.9808 30.7327 66.9478 30.7327H71.7652C72.7327 30.7327 73.5172 30.0006 73.5172 29.0979C73.5172 28.1953 72.7327 27.4632 71.7657 27.4632C71.7657 26.2214 70.6872 25.2148 69.3568 25.2148C68.0263 25.2148 66.9478 26.221 66.9478 27.4627Z"
          stroke={thirdColor}
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.8775 29.4881C42.9105 29.4881 42.126 30.2203 42.126 31.1233C42.126 32.0264 42.9105 32.7581 43.8775 32.7581H48.6949C49.6624 32.7581 50.4469 32.0259 50.4469 31.1233C50.4469 30.2207 49.6624 29.4886 48.6954 29.4886C48.6954 28.2468 47.6169 27.2402 46.2864 27.2402C44.956 27.2402 43.8775 28.2464 43.8775 29.4881Z"
          stroke={fourthColor}
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.4166 48.9119C50.4495 48.9119 49.665 49.6441 49.665 50.5472C49.665 51.4502 50.4495 52.182 51.4166 52.182H56.234C57.2015 52.182 57.986 51.4498 57.986 50.5472C57.986 49.6446 57.2015 48.9124 56.2344 48.9124C56.2344 47.6706 55.1559 46.6641 53.8255 46.6641C52.4951 46.6641 51.4166 47.6702 51.4166 48.9119Z"
          fill={secondColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.5628 41.0567C86.8536 41.0567 86.2783 41.5936 86.2783 42.2558C86.2783 42.918 86.8536 43.4547 87.5628 43.4547H91.0955C91.8051 43.4547 92.3803 42.9177 92.3803 42.2558C92.3803 41.5939 91.8051 41.057 91.0959 41.057C91.0959 40.1464 90.305 39.4082 89.3293 39.4082C88.3537 39.4082 87.5628 40.1461 87.5628 41.0567Z"
          fill={secondColor}
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.137 21.6328C53.4278 21.6328 52.8525 22.1698 52.8525 22.832C52.8525 23.4942 53.4278 24.0308 54.137 24.0308H57.6697C58.3793 24.0308 58.9546 23.4939 58.9546 22.832C58.9546 22.1701 58.3793 21.6331 57.6701 21.6331C57.6701 20.7225 56.8792 19.9844 55.9035 19.9844C54.9279 19.9844 54.137 20.7222 54.137 21.6328Z"
          fill={secondColor}
        />
      </g>
      <path
        d="M49.3676 56.6787L35.6843 70.7791"
        stroke={fifthColor}
        strokeWidth="0.814966"
        strokeLinecap="round"
      />
      <path
        d="M58.1514 64.2129L45.9126 78.6576"
        stroke={fifthColor}
        strokeWidth="0.814966"
        strokeLinecap="round"
      />
      <path
        d="M50.0332 64.2734L36.668 79.2388"
        stroke={fifthColor}
        strokeWidth="0.814966"
        strokeLinecap="round"
      />
      <path
        d="M57.0531 54.5341L57.1362 38.5165L87.8605 22.6264L86.0504 30.3989L57.0531 54.5341Z"
        fill="url(#paint2_linear_4814_275)"
        stroke={fifthColor}
        strokeWidth="1.53792"
      />
      <path
        d="M89.7103 18.5649C90.6075 18.2403 91.1758 19.5162 90.3346 19.9661L57.0936 37.7437C56.9897 37.7993 56.8743 37.8302 56.7565 37.8341L39.2579 38.4142C38.3826 38.4432 38.1473 37.2205 38.9708 36.9225L89.7103 18.5649Z"
        fill={sixthColor}
        stroke={fifthColor}
        strokeWidth="1.53792"
      />
      <path
        d="M90.6864 20.5114C91.016 19.7687 90.1183 19.0889 89.4928 19.6075L62.8962 41.6582C62.6374 41.8727 62.548 42.2304 62.6753 42.5414L70.7415 62.2479C70.9981 62.8749 71.8812 62.8877 72.156 62.2686L90.6864 20.5114Z"
        fill={color}
        stroke={fifthColor}
        strokeWidth="1.53792"
      />
      <path
        d="M57.4951 55.2601L62.52 42.4039C62.5263 42.3878 62.5321 42.38 62.5348 42.3767C62.5376 42.3733 62.5404 42.371 62.5436 42.369C62.5509 42.3644 62.566 42.3586 62.5876 42.3592C62.6091 42.3598 62.6239 42.3665 62.6309 42.3714C62.634 42.3736 62.6366 42.3761 62.6392 42.3796C62.6418 42.383 62.6471 42.3912 62.6525 42.4077L62.6617 42.4357L62.6731 42.463L65.5838 49.4776L66.4336 52.0716L57.4951 55.2601Z"
        fill={sixthColor}
        stroke={fifthColor}
        strokeWidth="1.53792"
      />
      <circle
        cx="82.3154"
        cy="72.2168"
        r="11.25"
        fill={sixthColor}
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M82.3171 72.7178L82.3171 67.7031"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.3157 76.2792C82.2051 76.2792 82.1157 76.3685 82.1171 76.4792C82.1171 76.5898 82.2064 76.6792 82.3171 76.6792C82.4277 76.6792 82.5171 76.5898 82.5171 76.4792C82.5171 76.3698 82.4277 76.2792 82.3157 76.2792"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4814_275"
          x1="73.9938"
          y1="31.1296"
          x2="31.6397"
          y2="100.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.419792" stopColor={sixthColor} />
          <stop offset="1" stopColor={sixthColor} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4814_275"
          x1="73.9936"
          y1="31.13"
          x2="31.6395"
          y2="100.948"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.51875" stopColor={sixthColor} />
          <stop offset="1" stopColor={sixthColor} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4814_275"
          x1="68.8443"
          y1="29.3878"
          x2="76.4187"
          y2="47.9718"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={seventhColor} />
          <stop offset="1" stopColor={fourthColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};
