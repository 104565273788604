import { IconProps } from "typing";
import { theme } from "../themes";

export const HandPackageDeliveryIcon = ({
  width = 20,
  height = 20,
  strokeWidth = "1.5",
  color = theme.colors.neutral["350"],
  alt = "Entrega de pacote com mão",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99811 17.5032H3.33075C2.87032 17.5032 2.49707 17.1299 2.49707 16.6695V11.6674C2.49707 11.207 2.87032 10.8337 3.33075 10.8337H4.99811C5.45854 10.8337 5.83179 11.207 5.83179 11.6674V16.6695C5.83179 17.1299 5.45854 17.5032 4.99811 17.5032Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2508 3.33057V7.1655"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6699 11.6674V8.36603C16.6676 7.70266 16.1294 7.16598 15.466 7.16553H7.03587C6.3725 7.16598 5.83433 7.70266 5.83203 8.36603V12.5845"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83203 11.6674V6.64113C5.83221 6.31592 5.89799 5.99409 6.02545 5.6949L6.68489 4.15259C6.89795 3.65386 7.38807 3.33039 7.93041 3.33057H14.5707C15.1147 3.32883 15.6071 3.65248 15.8212 4.15259L16.4806 5.69657C16.6069 5.99599 16.6712 6.31785 16.6699 6.64279V12.0717"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8341 14.1683H12.7791C13.1399 14.1683 13.4909 14.0513 13.7795 13.8348L15.7937 12.3242C16.2965 11.9473 17 11.9974 17.4444 12.4418V12.4418C17.6813 12.6785 17.8144 12.9997 17.8144 13.3346C17.8144 13.6696 17.6813 13.9908 17.4444 14.2275L15.7161 15.9557C15.2506 16.4213 14.6577 16.7386 14.0121 16.8678L11.5694 17.3563C11.0851 17.4531 10.5854 17.4412 10.1063 17.3213L7.89706 16.7694C7.63294 16.7028 7.3616 16.6692 7.08922 16.6693H5.83203"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8341 14.1685H12.0721C12.7697 14.1685 13.3352 13.603 13.3352 12.9054V12.6553C13.3352 12.076 12.9409 11.5711 12.3789 11.4307L10.4681 10.953C10.1577 10.8746 9.83877 10.8346 9.51857 10.8337V10.8337C8.74555 10.8335 7.9898 11.0624 7.34683 11.4915L5.83203 12.5011"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
