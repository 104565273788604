import { IHttpClient } from "app-domain/abstractions";
import { IApi } from "app-domain/abstractions/apis/IApi";
import { UtilInfoDto } from "typing/dto/UtilInfo";
import { IHttpResponse } from "typing/types";
import { EnvsApi } from "../Envs";
import { getApisUrlFallback } from "../../../utils";

class UtilInfoApi implements IApi {
  baseUrl = "";

  constructor(private httpClient: IHttpClient, private envsApi: EnvsApi) {
    if (!this.baseUrl) {
      const { data: apisBaseUrl } =
        this.envsApi.getRawEnvImmediately("APIS_BASE_URL");
      const fallback = getApisUrlFallback(process.env.HOST_ENV);

      this.baseUrl = `${apisBaseUrl || fallback}`;
    }
  }

  // private getPaymentOptionsEndpoint = () => {
  //     return `${this.baseUrl}util-info/payment-options`;
  // };
  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getPaymentOptions = async (): Promise<
    IHttpResponse<UtilInfoDto[], unknown>
  > => {
    const response = await this.httpClient.useGet("/util-info/payment-options");

    return {
      data: response.data as unknown as UtilInfoDto[],
      isLoading: false,
      error: false,
    };
  };

  getLinkOptions = async (
    isBranchJPA?: boolean,
    isBlackFridayOffersAvailable?: boolean
  ): Promise<IHttpResponse<UtilInfoDto[], unknown>> => {
    const response = (await this.httpClient.useGet(
      "/util-info/link-options"
    )) as unknown as { data: UtilInfoDto[] };

    const data =
      isBranchJPA && isBlackFridayOffersAvailable
        ? response.data.flatMap((item) => {
            if (item.title === "Dúvidas") {
              const newItem: UtilInfoDto = {
                title: item.title,
                icon: item.icon,
                style: item.style,
                options: [
                  ...item.options,
                  {
                    title: "Ofertas Black Friday",
                    link: {
                      title: "Ofertas Black Friday",
                      href: "https://www.ferreiracosta.com/Destaque/produtos-ofertados-black-friday-jpa",
                    },
                  },
                ],
              };

              return newItem;
            }

            return item;
          })
        : response.data;

    return {
      data,
      isLoading: false,
      error: false,
    };
  };
}

export { UtilInfoApi };
