import {
  useRegisterPhoneNumber,
  useShallow,
  useTwoFactor,
} from "application/state-manager";
import {
  formatPhoneNumber,
  useAppContext,
  useAuth,
  useToast,
  useUser,
} from "application";
import { useCallback, useState } from "react";
import {
  EditDataBody,
  ErrorDto,
  FATypeValues,
  IHttpResponse,
  TwoFactorContentPropsMap,
  UpdateDataDto,
} from "typing";
import { profileApi } from "implementations";
import { TelephoneErrorIcon } from "ui";
import { useRouter } from "next/router";

const usePhoneValidationComponentData = () => {
  const { isClientMobile } = useAppContext();
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;
  const [isLoading, setIsLoading] = useState(false);
  const { loggedClient } = useUser();

  const {
    isRequesting,
    setIsTokenCodeValid,
    setIsValidatingCode,
    setIsValidationActive,
    setValidateCode,
    channelSelected,
    faType,
    isBlockedToSendCode,
    tokenCode,
  } = useTwoFactor(
    useShallow((state) => ({
      isRequesting: state.isRequesting,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      setIsValidatingCode: state.setIsValidatingCode,
      setValidateCode: state.setValidateCode,
      setIsValidationActive: state.setIsValidationActive,
      channelSelected: state.channelSelected,
      faType: state.faType,
      isBlockedToSendCode: state.isBlockedToSendCode,
      tokenCode: state.tokenCode,
    }))
  );

  const router = useRouter();

  const {
    setIsRegisterPhoneNumberModalOpen,
    changeCurrentStep,
    phoneNumber,
    setOpenCheckoutModal,
  } = useRegisterPhoneNumber(
    useShallow((state) => ({
      setIsRegisterPhoneNumberModalOpen:
        state.setIsRegisterPhoneNumberModalOpen,
      changeCurrentStep: state.changeCurrentStep,
      phoneNumber: state.phoneNumber,
      setOpenCheckoutModal: state.setOpenCheckoutModal,
    }))
  );

  const { addToast } = useToast();

  const clearTime = useCallback(() => {
    localStorage.removeItem(`timeSent_${faType}_${channelSelected}`);
    localStorage.removeItem(`countdownTime_${faType}_${channelSelected}`);
    localStorage.removeItem(
      `countdownTimeTimestamp_${faType}_${channelSelected}`
    );
  }, [faType, channelSelected]);

  const handleEditPhone = async ({
    faType: userFAType,
    token,
    email,
    phoneNumber: userPhoneNumber,
    cpf,
    channel,
    newEmail,
    newPhoneNumber,
  }: EditDataBody) => {
    setIsValidatingCode(true);

    const handleEditPhoneCall = async (
      location: string,
      hasLocation?: boolean
    ) => {
      const { data } = (await profileApi.editData(
        {
          faType: userFAType,
          token,
          email,
          phoneNumber: userPhoneNumber,
          cpf,
          channel,
          newEmail: newEmail ?? "",
          newPhoneNumber: newPhoneNumber ?? "",
          location: hasLocation ? location : "",
        },
        accessToken as string
      )) as IHttpResponse<UpdateDataDto, ErrorDto>;

      const editResponse = data;

      if (editResponse?.id) {
        clearTime();

        addToast({
          isNewToast: true,
          newToastTheme: "light",
          type: "success",
          title: "Telefone validado com sucesso",
          description: "Parabéns! Seu telefone foi cadastrado.",
          timerInMilliseconds: 3000,
        });

        setIsTokenCodeValid(true);
        setIsRegisterPhoneNumberModalOpen(false);
        setIsValidationActive(false);
        changeCurrentStep("modalCall");
        if (router?.pathname?.includes("pagamento")) {
          setOpenCheckoutModal(true);
        }
        return;
      }

      addToast({
        isNewToast: true,
        newToastTheme: "light",
        type: "error",
        title: "Erro ao cadastrar seu telefone",
        description:
          data?.detail !== "" && data?.detail
            ? data?.detail
            : data?.title ??
              "Ocorreu um erro ao validar o código de verificação.",
        timerInMilliseconds: 3000,
      });

      setIsTokenCodeValid(false);
      setValidateCode(true);
      setIsValidatingCode(false);
    };

    navigator?.geolocation?.getCurrentPosition(
      async (position) => {
        handleEditPhoneCall(
          `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`,
          true
        );
      },
      async () => {
        handleEditPhoneCall("", false);
      }
    );
  };

  const getPhoneValidationComponentData = (): TwoFactorContentPropsMap => {
    const contentMap: TwoFactorContentPropsMap = new Map();

    contentMap.set("shouldMask", false);
    contentMap.set("clientAction", "registerPhoneNumber");
    contentMap.set("email", String(loggedClient?.email));
    contentMap.set("isUnderAction", isRequesting);
    contentMap.set("onCallAction", () =>
      handleEditPhone({
        channel: 1,
        faType: FATypeValues.AccountUpdatePhone2FA,
        cpf: String(loggedClient?.cpf),
        email: String(loggedClient?.email),
        phoneNumber: String(loggedClient?.phoneNumber),
        token: String(tokenCode),
        newPhoneNumber: phoneNumber,
        newEmail: "",
      })
    );
    contentMap.set("phoneNumber", formatPhoneNumber(phoneNumber));
    contentMap.set("sendCodeType", "sms");
    contentMap.set("hasHeader", false);
    contentMap.set("viewType", "component");
    contentMap.set("hasDescriptionOnMobile", true);
    contentMap.set("preventDefault", true);
    contentMap.set("canChooseAnotherTwoFactorMethod", false);
    contentMap.set("isMobile", isClientMobile);
    contentMap.set("successTitle", "Verifique seu novo número de telefone");
    contentMap.set("options", null);
    contentMap.set("onClickFailureButton", () => {
      setIsRegisterPhoneNumberModalOpen(false);
      setIsValidationActive(false);
    });
    contentMap.set("titleFailure", "Erro na validação do telefone");
    contentMap.set("errorButtonText", "Fechar");
    contentMap.set("handleSelectAnotherTwoFactorType", undefined);
    contentMap.set("failureIllustration", <TelephoneErrorIcon />);
    contentMap.set("hasError", isBlockedToSendCode);
    return contentMap;
  };

  return {
    getPhoneValidationComponentData,
    isLoading,
    setIsLoading,
  };
};

export { usePhoneValidationComponentData };
