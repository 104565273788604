import styled from "styled-components";
import { theme } from "ui";

interface ContentModalAlertProps {
  backgroundColor?: string;
  typeInfo?: "info" | "warn" | "error" | "success";
}

const COLOR_TYPE_INFO = {
  info: theme.colors.tertiary["300"],
  warn: theme.colors.yellow["600"],
  error: theme.colors.primary["200"],
  success: theme.colors.secondary["400"],
};

export const ContentModalAlert = styled.section<ContentModalAlertProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? theme.colors.neutral.white};
  width: fit-content;
  height: fit-content;
  padding: ${theme.space.x4};
  border-radius: ${theme.space.x3};

  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: 99999;

  box-shadow: 4px 4px 14px 0px ${theme.colors.neutral["600"]};
  border: 2px solid;
  border-color: ${({ typeInfo }) =>
    typeInfo && typeInfo in COLOR_TYPE_INFO
      ? COLOR_TYPE_INFO[typeInfo || "info"]
      : COLOR_TYPE_INFO.warn};
`;

export const HeaderModal = styled.header`
  position: relative;
`;

interface ButtonCloseProps {
  backgroundColor?: string;
  typeInfo?: "info" | "warn" | "error" | "success";
}

export const ButtonClose = styled.button<ButtonCloseProps>`
  border-radius: 50%;
  max-height: 3rem;
  max-width: 3rem;
  padding: 0.5rem;

  position: absolute;
  top: -1rem;
  right: -1rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ backgroundColor }) =>
    backgroundColor ?? theme.colors.neutral.white};

  border: 2px solid;
  border-color: ${({ typeInfo }) =>
    typeInfo && typeInfo in COLOR_TYPE_INFO
      ? COLOR_TYPE_INFO[typeInfo || "info"]
      : COLOR_TYPE_INFO.warn};
`;

interface BottonLineProps {
  fontSize?: string;
  color?: string;
}

export const ButtonLine = styled.button<BottonLineProps>`
  border: 1px solid ${({ color }) => color ?? theme.colors.secondary["200"]};
  background: none;

  padding: ${theme.space.x2} ${theme.space.x4};
  border-radius: ${theme.space.x2};
  font-size: ${({ fontSize }) => fontSize ?? "1.2rem"};
  font-weight: 500;
  color: ${({ color }) => color ?? theme.colors.secondary["200"]};
`;

interface BottonSolidProps {
  backgroundColor?: string;
  fontSize?: string;
  color?: string;
}

export const ButtonSolid = styled.button<BottonSolidProps>`
  border: none;
  background: ${({ backgroundColor }) =>
    backgroundColor ?? theme.colors.secondary["200"]};

  padding: ${theme.space.x2} ${theme.space.x4};
  border-radius: ${theme.space.x2};
  font-size: ${({ fontSize }) => fontSize ?? "1.2rem"};
  font-weight: 500;
  color: ${theme.colors.neutral.white};
`;

interface TitleProps {
  fontSize?: string;
  lowerContent?: boolean;
}

export const TitleModal = styled.h4<TitleProps>`
  font-size: ${({ fontSize }) => fontSize ?? "1.4rem"};
  border-bottom: ${({ lowerContent }) =>
    lowerContent ? `0.05rem solid ${theme.colors.neutral["400"]}` : "none"};
  padding-bottom: ${({ lowerContent }) => (lowerContent ? `1rem` : "0")};
  margin-bottom: ${({ lowerContent }) => (lowerContent ? `1rem` : "0")};
`;

interface BodyProps {
  fontSize?: string;
}
export const BodyModal = styled.section<BodyProps>`
  font-size: ${({ fontSize }) => fontSize ?? "1.3rem"};
`;
interface FooterProps {
  fontSize?: string;
}
export const FooterModal = styled.section<FooterProps>`
  padding-top: ${theme.space.x4};
  margin-top: 1.2rem;
  border-top: 0.05rem solid ${theme.colors.neutral["400"]};

  display: flex;
  justify-content: flex-end;
  gap: ${theme.space.x4};
`;
