import styled from "styled-components";
import { CloseIcon as Close, WarningRevalidationIcon, theme } from "ui";

export const RevalidationMessageContainer = styled.div`
  border: 1px solid ${theme.colors.yellow["650"]};
  border-left-width: ${theme.space.x2};
  border-left-color: ${theme.colors.yellow["650"]};
  background-color: ${theme.colors.neutral.white};
  border-radius: ${theme.space.x2};
  padding: ${`${theme.space.x2} ${theme.space.x6} ${theme.space.x2}
    ${theme.space.x2}`};

  @media ${theme.device.mobileFirst.mobileL} {
    padding: ${theme.space.x2} ${theme.space.x4};
  }
`;

export const CloseButton = styled.section`
  height: ${theme.space.x6};

  &:hover {
    cursor: pointer;
  }
`;

export const MessageContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const IconAndTextContainer = styled.section`
  display: flex;
  gap: ${theme.space.x2};
  align-items: center;
`;

export const WarningIconContainer = styled.section`
  width: ${theme.space.x6};
  height: ${theme.space.x6};
`;

export const Warning = styled(WarningRevalidationIcon).attrs({
  width: theme.space.x6,
  height: theme.space.x6,
})``;

export const CloseIcon = styled(Close).attrs({
  width: theme.space.x6,
  height: theme.space.x6,
})``;

export const RevalidationMessage = styled.p<{ fontSize?: string }>`
  color: ${theme.colors.neutral[550]};
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x7};
  font-weight: ${theme.typography.fontWeight[400]};
  background-color: transparent;
`;
