import styled, { css } from "styled-components";
import { FlexContainer, OverlayContainer, Search, theme } from "ui";
import { ItemLinkContainer } from "./components/subcomponents/TermSuggestionOption/styles";

interface SearchInputProps {
  isDesktop?: boolean;
  hasFocusOnInputSearch?: boolean;
  isDisabled?: boolean;
}

interface SearchContainerSuggestionProps {
  showItems: boolean;
  hasFocusOnInputSearch: boolean;
}

const BaseHyperLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: ${theme.colors.primary["200"]};
  cursor: pointer;

  transition: all 0.2s ease-in;

  &:hover {
    background: ${theme.colors.primary["400"]};
  }
`;

export const ContainerSearchProduct = styled.form<SearchInputProps>`
  display: inline-flex;
  width: 100%;
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row-reverse" : "row")};
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 0.5rem;
  transition: all 0.2s ease-in;

  @media ${theme.device.desktopFirst.mobileL} {
    padding: 0 1rem;
  }

  /* @media ${theme.device.mobileFirst.laptop} {
    max-width: 100%;
  } */
`;

export const SearchContainerSuggestion = styled.div<SearchContainerSuggestionProps>`
  position: relative;
  top: -2px;
  width: 100%;
  margin-top: -2px;
  padding: 1rem 1rem 0;
  overflow-y: auto;
  will-change: transform;
  max-height: 40rem;
  z-index: ${({ hasFocusOnInputSearch }) =>
    hasFocusOnInputSearch ? "99" : "-1"};
  opacity: ${({ showItems, hasFocusOnInputSearch }) =>
    showItems && hasFocusOnInputSearch ? 1 : 0};
  border-radius: 0px 0px 8px 8px;
  visibility: ${({ showItems, hasFocusOnInputSearch }) =>
    showItems && hasFocusOnInputSearch ? "visible" : "hidden"};
  background-color: ${theme.colors.neutral["80"]};
  box-shadow: 0 12px 18px ${theme.colors.neutral["999-opacity-20"]};
  margin-bottom: -100vh;
  transition: all 0.2s ease;

  &:focus ~ ${OverlayContainer} {
    z-index: 5;
    opacity: 1;
    visibility: visible;
    box-shadow: 0 4px 8px 0 ${theme.colors.neutral["999-opacity-20"]},
      0 6px 20px 0 ${theme.colors.neutral["999-opacity-19"]};
  }
`;

export const SearchProductHyperLink = styled(BaseHyperLink)<SearchInputProps>`
  width: 6.5rem;
  height: 4.17rem;
  z-index: ${({ hasFocusOnInputSearch }) => (hasFocusOnInputSearch ? 20 : 0)};
  border-radius: ${({ isDesktop }) =>
    isDesktop ? "0px 4px 4px 0" : "4px 0 0 4px"};
  margin-top: 0;
  order: 2;
  transition: all 0.2s ease;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.8;
    `}

  &:focus {
    border: 1px solid ${theme.colors.primary["100"]};
  }

  &::placeholder {
    outline: none;
    color: ${theme.colors.neutral["300"]};
  }

  @media ${theme.device.desktopFirst.tablet} {
    height: 3.85rem;
  }

  @media ${theme.device.desktopFirst.tabletS} {
    order: 1;
    border-radius: 4px 0 0 4px;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    width: 4rem;
    height: 3rem;
  }

  @media ${theme.device.desktopFirst.mobileM} {
    height: 3rem;
  }

  @media ${theme.device.desktopFirst.mobileS} {
    height: 3rem;
  }
`;

export const SearchProductIcon = styled(Search).attrs({
  color: theme.colors.neutral.white,
})``;

export const ListSuggestedProducts = styled(FlexContainer)`
  align-items: center;
  padding: 0.75rem 0rem;

  ${ItemLinkContainer} {
    width: 100%;
  }
`;

export const ItemSuggestedProducts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 0.25rem;
  width: 100%;

  ${ItemLinkContainer} {
    width: 100%;
  }
`;
