import { CustomStoriesSubComponentDto } from "typing";
import { memo } from "react";
import { buildTestIds, useCurrentPathName, useAppContext } from "application";
import { appMonitoringClient } from "implementations";
import { customStoriesSubComponentData } from "./data";
import {
  Badge,
  BadgeText,
  Container,
  ImageSection,
  StoriesImage,
  StoriesImageContainer,
  Title,
  TitleSection,
} from "./styles";

import { Link } from "../../../Link";
import { useGetBranchLinkConfig } from "../../utils";

interface CustomStoriesSubComponentProps extends CustomStoriesSubComponentDto {
  format?: "circle" | "squircle" | "square" | "rectangle";
  size?: "big" | "small";
  hasShadow?: "true" | "false";
  index: number;
  positionComponent?: number;
}

const CustomStoriesSubComponent = memo(
  ({
    config,
    properties,
    index,
    positionComponent,
    format,
    size,
    hasShadow,
    preloadComponent = false,
  }: CustomStoriesSubComponentProps) => {
    const { title, showTitle, hasBorder, image, color, hasBadge, badgeText } =
      customStoriesSubComponentData(properties);
    const appContext = useAppContext();
    const linkConfig = useGetBranchLinkConfig(
      config?.branches,
      "subComponentStories"
    );

    const currentPathName = useCurrentPathName();

    if (!linkConfig?.url) {
      appMonitoringClient.captureMessage(
        `Um subComponente de Stories, localizado em "${currentPathName}", na posição ${positionComponent}, não possui nenhuma url (undefined).`,
        "warning"
      );
    }

    if (!config?.branches?.length) {
      appMonitoringClient.captureMessage(
        `Um subComponente de Stories, localizado em "${currentPathName}", na posição ${positionComponent}, não possui nenhuma configuração de branches.`,
        "warning"
      );
    }

    if (!image) {
      appMonitoringClient.captureMessage(
        `O subcomponente ${index} de Stories, na posição ${positionComponent}, localizado em "${currentPathName}", não possui imagem.`,
        "error"
      );
      return null;
    }

    const hasBorderColorOnStories = hasBorder === "true" ? color : "";

    return (
      <Container
        {...buildTestIds("custom-stories-sub-component-container")}
        size={size}
        format={format}
        hasShadow={hasShadow}
        as={linkConfig?.url ? Link : Container}
        href={`/${linkConfig?.url}`}
        target={linkConfig?.target}
      >
        <ImageSection
          {...buildTestIds("custom-stories-sub-component-image-section")}
          size={size}
          format={format}
          color={hasBorderColorOnStories}
          hasShadow={hasShadow}
        >
          <StoriesImageContainer
            {...buildTestIds("custom-stories-sub-component-image-container")}
            size={size}
            format={format}
            color={hasBorderColorOnStories}
            hasShadow={hasShadow}
            themeBlackFriday={appContext?.isBlackTheme}
          >
            <StoriesImage
              {...buildTestIds("custom-stories-sub-component-image")}
              color={hasBorderColorOnStories}
              layout="fill"
              src={image}
              alt={title || "Imagem do Stories"}
              format={format}
              priority={preloadComponent}
            />
          </StoriesImageContainer>
          {hasBadge === "true" &&
          format !== "square" &&
          format !== "rectangle" &&
          hasBorder ? (
            <Badge
              {...buildTestIds("custom-stories-sub-component-badge")}
              color={color}
              size={size}
            >
              <BadgeText
                {...buildTestIds("custom-stories-sub-component-badge-text")}
                size={size}
              >
                {badgeText}
              </BadgeText>
            </Badge>
          ) : null}
        </ImageSection>
        {showTitle === "true" && format !== "rectangle" ? (
          <TitleSection
            {...buildTestIds("custom-stories-sub-component-title-section")}
            hasBackground={format === "square"}
          >
            <Title
              {...buildTestIds("custom-stories-sub-component-title-section")}
              isBlackTheme={appContext?.isBlackTheme}
              size={size}
            >
              {title}
            </Title>
          </TitleSection>
        ) : null}
      </Container>
    );
  }
);

CustomStoriesSubComponent.displayName = "CustomStories";

export { CustomStoriesSubComponent };
