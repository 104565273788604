import { buildTestIds, useAuthentication } from "application";
import { ArrowLeftIcon, CloseIcon, theme } from "ui";
import { NextRouter, useRouter as defaultUseRouter } from "next/router";
import {
  ChangePasswordFeedbackContainer,
  ContentInformationText,
  TitleInformation,
  ChangePasswordFeedbackInformation,
  WrapperIcon,
  GoToLoginButton,
  TitleInformationContainer,
  ReturnWrapper,
} from "./styles";

interface ChangePasswordFeedbackProps {
  title: string;
  description: string;
  buttonText: string;
  hasReturn?: boolean;
  useRouter?: () => NextRouter;
  onCallAction: () => void;
  onCloseFeedback?: () => void;
}

const ChangePasswordFeedback = ({
  title,
  description,
  buttonText,
  onCloseFeedback,
  onCallAction,
  hasReturn,
  useRouter = defaultUseRouter,
}: ChangePasswordFeedbackProps) => {
  const router = useRouter();
  const changeCurrentStep = useAuthentication(
    (state) => state.changeCurrentStep
  );

  return (
    <ChangePasswordFeedbackContainer
      {...buildTestIds("change-password-feedback-container")}
    >
      <ChangePasswordFeedbackInformation
        {...buildTestIds("change-password-feedback-information")}
      >
        {hasReturn ? (
          <ReturnWrapper onClick={() => changeCurrentStep("login")}>
            <ArrowLeftIcon color={`${theme.colors.neutral["330"]}`} />
          </ReturnWrapper>
        ) : null}
        <TitleInformationContainer
          {...buildTestIds("title-information-container")}
        >
          <TitleInformation {...buildTestIds("title-information")}>
            {title}
          </TitleInformation>
        </TitleInformationContainer>
        {router?.pathname !== "/login" ? (
          <WrapperIcon
            onClick={onCloseFeedback}
            {...buildTestIds("close-button")}
          >
            <CloseIcon
              height={24}
              width={24}
              color={`${theme.colors.neutral["330"]}`}
            />
          </WrapperIcon>
        ) : null}
        <ContentInformationText {...buildTestIds("content-information-text")}>
          {description}
        </ContentInformationText>
        <GoToLoginButton
          {...buildTestIds("go-to-login-button")}
          onClick={onCallAction}
        >
          {buttonText}
        </GoToLoginButton>
      </ChangePasswordFeedbackInformation>
    </ChangePasswordFeedbackContainer>
  );
};

export { ChangePasswordFeedback };
