import { IconProps } from "typing";
import { theme } from "../themes";

export const RightArrowProduct = ({
  width = 12,
  height = 15,
  color = theme.colors.neutral["520"],
  alt = "Seta para direita",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M1.6665 10.3506L6.99984 5.85939L1.6665 1.36816"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
