import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const TrackingStatusOrderBeignSeparated = ({
  width = 36,
  height = 32,
  color = theme.colors.secondary["380"],
  secondColor = theme.colors.neutral.white,
  alt = "Produto sendo separado",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6183 8.6829L25.67 13.8709C25.9454 14.013 26.1193 14.2962 26.1214 14.6061C26.1235 14.916 25.9535 15.2015 25.68 15.3473L21.9285 17.3482C21.4452 17.606 20.8662 17.61 20.3795 17.359L10.3303 12.1694C10.0549 12.0272 9.88104 11.7441 9.87893 11.4342C9.87684 11.1242 10.0468 10.8387 10.3203 10.6929L14.0718 8.69207C14.5547 8.43568 15.1325 8.43225 15.6183 8.6829Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.382 8.6829L10.3303 13.8709C10.0549 14.013 9.88104 14.2962 9.87893 14.6061C9.87684 14.916 10.0468 15.2015 10.3203 15.3473L14.0718 17.3482C14.5551 17.606 15.1341 17.61 15.6208 17.359L25.67 12.1694C25.9454 12.0272 26.1193 11.7441 26.1214 11.4342C26.1235 11.1242 25.9535 10.8387 25.68 10.6929L21.9285 8.69207C21.4456 8.43568 20.8678 8.43225 20.382 8.6829Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9232 16.2844V19.5658C23.9232 20.1817 23.5838 20.7475 23.0404 21.0372L18.7886 23.3073C18.2983 23.5688 17.7099 23.5688 17.2196 23.3073L12.9678 21.0372C12.4244 20.7475 12.085 20.1817 12.085 19.5658V16.2844"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0003 23.5032V16.1284"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
