import { IconProps } from "typing";
import { theme } from "../themes";

export const PackageOutlined = ({
  alt,
  click,
  color = theme.colors.neutral["550"],
  strokeWidth,
  height,
  width,
}: IconProps) => {
  return (
    <svg
      onClick={click}
      aria-label={alt || "pacote"}
      aria-labelledby={alt || "pacote"}
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0051 10.7833V25.3373C28.0051 26.8107 26.8107 28.0051 25.3373 28.0051H6.6629C5.18953 28.0051 3.99512 26.8107 3.99512 25.3373V10.7833C3.99461 10.4215 4.06768 10.0635 4.20988 9.73084L5.97061 5.61046C6.39161 4.62985 7.35647 3.99447 8.42363 3.99512H23.5766C24.6447 3.99512 25.6099 4.63228 26.0296 5.61446L27.7904 9.73084C27.9324 10.0635 28.0054 10.4216 28.0051 10.7833Z"
        stroke={color}
        strokeWidth={strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99512 10.664H27.9918"
        stroke={color}
        strokeWidth={strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6675 3.99512L20.0014 10.6646V14.9664C19.9684 15.5678 19.4558 16.0296 18.8542 16.0001H13.1452C12.5436 16.0296 12.0311 15.5678 11.998 14.9664V10.6646L13.3319 3.99512"
        stroke={color}
        strokeWidth={strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99609 24.0029H10.6639"
        stroke={color}
        strokeWidth={strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
