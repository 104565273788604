import styled, { css } from "styled-components";
import { FlexContainer, theme } from "ui";

interface CustomDynamicBannerTitleProps {
  alignSelf: "flex-start" | "center" | "flex-end";
  isBlackTheme?: boolean;
  fontWeight?: number;
}

export const CustomDynamicBannerContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: ${theme.space.x7};

  @media ${theme.device.desktopFirst.mobileXL} {
    gap: ${theme.space.x3};
  }
`;

export const CustomDynamicBannerSlider = styled.section`
  .slick-slide {
    width: auto;
  }

  .slick-prev,
  .slick-next {
    margin-top: 2rem;
  }

  .slick-prev {
    margin-left: calc(50% - (613px)) !important;
    @media ${theme.device.desktopFirst.laptopXL} {
      margin-left: 3.5rem;
    }

    @media (max-width: 1300px) {
      margin-left: 3% !important;
    }
  }

  .slick-next {
    margin-right: calc(50% - (613px)) !important;
    @media ${theme.device.desktopFirst.laptopXL} {
      margin-right: 3.5rem;
    }

    @media (max-width: 1300px) {
      margin-right: 3% !important;
    }
  }
`;

export const CustomDynamicBannerTitle = styled.h2<CustomDynamicBannerTitleProps>`
  align-self: ${({ alignSelf }) => alignSelf};
  color: ${theme.colors.neutral["520"]};
  font-size: ${theme.typography.fontSize.md.x3};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  margin: ${({ alignSelf }) =>
    `${theme.space.x0} ${
      alignSelf === "center" ? theme.space.x0 : theme.space.x4
    }`};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x9};
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      color: ${theme.colors.blackFriday.white};
    `}
`;

export const CustomDynamicBannerSubTitle = styled.h4<CustomDynamicBannerTitleProps>`
  align-self: ${({ alignSelf }) => alignSelf};
  color: ${theme.colors.neutral["520"]};
  font-size: ${theme.typography.fontSize.sm.x9};
  font-weight: ${theme.typography.fontWeight[400]};
  margin: ${({ alignSelf }) =>
    `${theme.space.x0} ${
      alignSelf === "center" ? theme.space.x0 : theme.space.x4
    }`};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x7};
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      color: ${theme.colors.blackFriday.white};
    `}
`;

export const CustomDynamicBannerHeader = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: ${`0 ${theme.space.x3}`};

  @media ${theme.device.desktopFirst.tablet} {
    padding: 0 12px;
  }
`;
