import { IResponsiveModalContent } from "typing";
import { InputProps } from "../../Form/Input";
import { ResponsiveModalContent } from "../../ResponsiveModal/components";

const RegisterNewPhoneNumberUI = ({
  ...props
}: IResponsiveModalContent & InputProps) => {
  return <ResponsiveModalContent {...props} />;
};

export { RegisterNewPhoneNumberUI };
