import { buildTestIds } from "application";
import { useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { InputMultiEmailProps } from "typing";

const MultiEmailInput = ({
  id,
  emails,
  placeholder,
  handlerChange,
  ...props
}: InputMultiEmailProps) => {
  const [, setFocused] = useState(false);

  const removeEmailButton = (
    email: string,
    index: number,
    removeEmail: (emailIndex: number) => void
  ) => {
    return (
      <div
        {...buildTestIds(`form-input-multi-email-tag-${id}`)}
        data-tag
        key={index}
      >
        <div
          {...buildTestIds(`form-input-multi-email-tag-item-${id}`)}
          data-tag-item
        >
          {email}
        </div>
        <button
          {...buildTestIds(`form-input-multi-email-tag-handle-${id}`)}
          type="button"
          data-tag-handle
          onClick={() => removeEmail(index)}
          onKeyDown={() => removeEmail(index)}
        >
          ×
        </button>
      </div>
    );
  };

  return (
    <ReactMultiEmail
      id={id}
      {...props}
      placeholder={placeholder}
      emails={emails}
      autoFocus={false}
      onChange={handlerChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      getLabel={(emailIndex, index, removeEmail) =>
        removeEmailButton(emailIndex, index, removeEmail)
      }
    />
  );
};

export { MultiEmailInput };
