import { createStore } from "../adapter";

type BlackFridayState = {
  isBlackFridayTheme: boolean | undefined;
};

type BlackFridayDispatch = {
  setIsBlackFridayTheme: (value: boolean | undefined) => void;
};

type BlackFridayProps = BlackFridayState & BlackFridayDispatch;

const useBlackFriday = createStore<BlackFridayProps>((set) => ({
  isBlackFridayTheme: false,
  setIsBlackFridayTheme: (value: boolean | undefined) =>
    set({ isBlackFridayTheme: value }),
}));

export { useBlackFriday };
