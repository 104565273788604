import { Dispatch, SetStateAction } from "react";
import { buildTestIds } from "application";
import { MediaLink } from "typing";
import ThumbnailSelect from "../ThumbnailSelect";
import { CarouselThumb } from "../ThumbList";

type ListThumbProps = {
  thumbs: MediaLink[];
  sequenceImageNumberCurrentActive: number;
  setSequenceImageNumberCurrentActive: Dispatch<SetStateAction<number>>;
};

const ListThumb = ({
  thumbs,
  sequenceImageNumberCurrentActive,
  setSequenceImageNumberCurrentActive,
}: ListThumbProps) => {
  if (!thumbs?.length || !sequenceImageNumberCurrentActive) {
    return null;
  }

  return (
    <CarouselThumb {...buildTestIds("thumb-list")}>
      {thumbs?.map((thumbItem) => {
        return (
          <ThumbnailSelect
            key={thumbItem.sequenceNumber}
            {...thumbItem}
            actionReturnInfo={setSequenceImageNumberCurrentActive}
            active={
              sequenceImageNumberCurrentActive === thumbItem.sequenceNumber
            }
          />
        );
      })}
    </CarouselThumb>
  );
};

export { ListThumb };
