import { useEffect, useState } from "react";
import { useBranchLocation, useEnvs } from "application";
import { UtilInfoDto, IHttpResponse } from "typing";
import { theme, Column, SpaceWrapper } from "ui";
import { utilInfoApi } from "implementations";
import { UtilInfoLinkOptionMobileList } from "./List";
import { Skeleton } from "../../../../Skeleton";

interface UtilInfoLinkOptionsMobileProps {
  isBlackTheme: boolean;
}
const UtilInfoLinkOptionsMobile = ({
  isBlackTheme,
}: UtilInfoLinkOptionsMobileProps) => {
  const { getBranchLocationClient } = useBranchLocation();
  const allConfigs = useEnvs((state) => state.allConfigs);
  const [linkOptions, setLinkOptions] = useState({
    data: null,
    isLoading: true,
    error: false,
  } as IHttpResponse<UtilInfoDto[], unknown>);

  useEffect(() => {
    const getLinkOptions = async () => {
      const isBranchJPA = getBranchLocationClient() === "6";
      const response = await utilInfoApi.getLinkOptions(
        isBranchJPA,
        allConfigs?.isBlackFridayOffersAvailable === "true"
      );
      setLinkOptions(response);
    };

    getLinkOptions();
  }, [getBranchLocationClient, allConfigs?.isBlackFridayOffersAvailable]);

  return linkOptions?.isLoading ? (
    <Skeleton count={5} height={theme.space.x4} />
  ) : (
    <SpaceWrapper>
      {linkOptions?.data?.map((linkOption: UtilInfoDto) => (
        <Column
          key={linkOption?.title}
          xs="12"
          data-cy="util-info-link-options-mobile"
        >
          <SpaceWrapper
            borderColor={
              isBlackTheme
                ? theme.colors.blackFriday["210"]
                : theme.colors.neutral["100"]
            }
            padding={`${theme.space.x2} ${theme.space.x4}`}
            {...(linkOption?.style && linkOption.style)}
          >
            <UtilInfoLinkOptionMobileList
              isBlackTheme={isBlackTheme}
              {...linkOption}
            />
          </SpaceWrapper>
        </Column>
      ))}
    </SpaceWrapper>
  );
};

export default UtilInfoLinkOptionsMobile;
