import styled, { css } from "styled-components";
import { HeartIcon, DefaultProductImage, theme, Title } from "ui";
import { Image } from "../Image";

interface ProductCardVerticalProps {
  productCardVertical: boolean;
}

interface ProductCardContainerProps extends ProductCardVerticalProps {
  width?: string;
  shouldShowInGridOnMobile: boolean;
  hideSecondBorderRightOnMobile: boolean;
  isPlaylist: boolean;
}

interface ProductCardTagBorderLineProps {
  tagColor?: string;
}

interface HeartProps {
  isHovered: boolean;
  isClicked: boolean;
}

interface TagCampaignProductCardProps {
  background?: string;
  color?: string;
}

interface DiscountPriceProps {
  hasDiscountPercentage?: boolean;
}

const heartColors = ({ isClicked, isHovered }: HeartProps) => {
  if (isClicked) {
    return {
      color: theme.colors.primary["350"],
      secondColor: theme.colors.primary["350"],
    };
  }

  if (isHovered) {
    return {
      color: theme.colors.primary["60"],
    };
  }

  return {
    color: theme.colors.neutral.white,
    secondColor: theme.colors.neutral["580"],
  };
};

export const TagBorderLine = styled.span<ProductCardTagBorderLineProps>`
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 3px);
  border: 2px solid ${({ tagColor }) => tagColor || theme.colors.primary["200"]};
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  opacity: 0;
  visibility: hidden;
  top: -1px;
`;

export const DefaultProductImageContainer = styled(DefaultProductImage).attrs({
  height: 170,
  width: 170,
})`
  position: relative;
  bottom: 0.6875rem;
  right: 0.1875rem;
  transform: scale(0.9);

  @media ${theme.device.desktopFirst.mobileL} {
    top: 0.5rem;
  }
`;

export const ProductCardImageContainer = styled.figure`
  width: 100%;
  transition: all 0.2s linear;
  max-height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductCardInfoArea = styled.section<ProductCardVerticalProps>`
  min-width: 100%;

  ${({ productCardVertical }) =>
    productCardVertical
      ? css`
          height: 100%;
        `
      : null}
`;

export const ProductCardImageContent = styled.div<ProductCardVerticalProps>`
  max-height: 170px;

  ${({ productCardVertical }) =>
    productCardVertical
      ? css`
          width: 11.75rem;
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : null}
`;

export const ImageProduct = styled(Image)`
  margin: 0px auto 0px auto !important;
  transition: all 0.2s linear;
  transform: scale(0.9);
`;

export const ProductCardContainer = styled.section<ProductCardContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1rem 1.35rem 1rem;
  min-width: 100%;
  margin-left: 2px;
  width: ${({ width }) => width || "100%"};
  /* min-height: 25.45rem; */

  cursor: pointer !important;

  background: ${theme.colors.neutral.white};

  user-select: none;
  -webkit-user-drag: none;
  position: relative;
  -webkit-tap-highlight-color: transparent !important;
  transition: all 0.2s ease-in-out;
  // artur.castro transferir o border-right para a condição da linha onde 'e utilizado o css
  border-right: ${({
    shouldShowInGridOnMobile,
    hideSecondBorderRightOnMobile,
  }) =>
    shouldShowInGridOnMobile
      ? hideSecondBorderRightOnMobile
        ? `1px solid ${theme.colors.neutral["105"]}`
        : "0px"
      : null};

  ${({ isPlaylist }) =>
    isPlaylist
      ? css`
          min-height: 16rem;
          height: auto;
          max-height: 31rem;
        `
      : css`
          height: 31rem;
        `}

  ${({ shouldShowInGridOnMobile }) =>
    shouldShowInGridOnMobile
      ? css`
          @media ${theme.device.desktopFirst.mobileXL} {
            border-top: 0px;
            border-bottom: 1px solid ${theme.colors.neutral["105"]};
            border-left: 0px;
            border-radius: 0;
          }

          @media ${theme.device.desktopFirst.mobileS} {
            border-right: none;
          }
        `
      : css`
          border: 1px solid ${theme.colors.neutral["105"]};
          border-radius: 8px;
        `}

  ${({ productCardVertical }) =>
    productCardVertical
      ? css`
          min-width: 100% !important;
          max-height: 16.45rem !important;
          padding: 0.85rem 0.65rem 1rem 1rem !important;
        `
      : null}

  &:hover {
    ${ProductCardImageContainer} {
      transform: scale(1.1);
    }

    ${TagBorderLine} {
      opacity: ${({ shouldShowInGridOnMobile }) =>
        shouldShowInGridOnMobile ? 0 : 1};
      visibility: ${({ shouldShowInGridOnMobile }) =>
        shouldShowInGridOnMobile ? "hidden" : "visible"};
      transform: scale(1);
    }
  }

  @media ${theme.device.desktopFirst.tablet} {
    margin-left: 0px;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    min-width: ${({ shouldShowInGridOnMobile }) =>
      shouldShowInGridOnMobile ? "calc(50vw - 12px)" : "226px"};
    width: 100%;
    max-width: ${({ shouldShowInGridOnMobile }) =>
      shouldShowInGridOnMobile ? "100%" : "328px"};
  }
`;

export const CouponTagWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: 16px;
  z-index: 2;
`;

export const ProductCardContent = styled.section<ProductCardVerticalProps>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: ${({ productCardVertical }) =>
    productCardVertical ? "row" : "column"};
`;

export const TagCampaignProductCardContainer = styled.div`
  min-width: 100%;
  min-height: 24px;
  z-index: 2;
  position: relative;
`;

export const TagCampaignProductCard = styled.div<TagCampaignProductCardProps>`
  padding: 0.25rem 0.65rem;
  border-radius: 6px;
  background: ${({ background }) => background || theme.colors.neutral["999"]};
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TagTextCampaignProductCard = styled.span<TagCampaignProductCardProps>`
  color: ${({ color }) => color || theme.colors.neutral.white};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeight["600"]};
`;

export const ProductCardInfoContainer = styled.div<ProductCardVerticalProps>`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ productCardVertical }) =>
    productCardVertical
      ? css`
          max-width: max-content;
          margin-top: 0px;
          padding-left: 16px;
        `
      : null}
`;

export const ProductCardTitle = styled(Title)<DiscountPriceProps>`
  color: ${theme.colors.neutral["520"]};

  font-size: 1.15rem;
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight["400"]};

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ hasDiscountPercentage }) =>
    hasDiscountPercentage ? 3 : 2};
  white-space: pre-wrap;

  max-width: 170px;
  min-width: 100%;
`;

export const ProductCardDiscountPriceContainerSpace = styled.section<DiscountPriceProps>`
  min-height: ${({ hasDiscountPercentage }) =>
    hasDiscountPercentage ? "0" : "18px"};
`;

export const ProductCardDiscountPriceContainer = styled.section`
  display: flex;
  gap: 8px;
`;

export const ProductCardDiscountPriceText = styled.span`
  color: ${({ color }) => color || theme.colors.neutral["350"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeight["400"]};
  line-height: 150%;
  text-decoration: line-through;
`;

export const ProductCardDiscountPercentageContainer = styled.section`
  width: 5.5rem;
  height: 1.5rem;
  padding: 0 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.35rem;

  border-radius: 1rem 0.35rem 0.35rem 1rem;
  background: ${theme.colors.tertiary["930"]};
`;

export const ProductCardDiscountPercentageDot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: ${theme.colors.neutral.white};
`;

export const ProductCardDiscountPercentageText = styled.span`
  color: ${theme.colors.tertiary["150"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeight["600"]};
  line-height: 150%;
  letter-spacing: -0.02rem;
`;

export const ProductCardCurrentPrice = styled.span`
  color: ${theme.colors.primary["200"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1.75rem;
  font-weight: ${theme.typography.fontWeight["700"]};
  letter-spacing: -0.05783rem;
`;

export const ProductCardSpotPriceDescription = styled.span`
  color: ${theme.colors.primary["200"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 0.875rem;
  font-weight: ${theme.typography.fontWeight["400"]};
`;

export const ProductCardInstallmentsTextContainer = styled.div`
  min-width: 168px;
`;

export const ProductCardInstallmentsText = styled.span`
  color: ${theme.colors.neutral["805"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeight["400"]};

  min-width: 164px;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: 0.975rem;
  }
`;

export const ProductCardTagPriceOnlySiteContainer = styled.div`
  display: flex;
  padding: 0.3075rem 0.35rem 0.35rem 0.35rem;
  align-items: flex-start;
  gap: 0.875rem;
  margin-top: 0.25rem;
  border-radius: 0.35rem;
  background: ${theme.colors.secondary["28"]};
  max-width: fit-content;
`;

export const ProductCardTagPriceOnlySiteText = styled.span`
  color: ${theme.colors.secondary["380"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeight["600"]};
  line-height: 0.92rem;
`;

export const ProductCardDiscount = styled.div``;

export const FavoriteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 100px;
  background-color: ${theme.colors.neutral["white-opacity-50"]};
  z-index: 2;
  right: 8px;
  top: 8px;
  height: ${theme.space.x8};
  width: ${theme.space.x8};
`;

export const Heart = styled(HeartIcon).attrs<HeartProps>(
  ({ isHovered, isClicked }) => ({
    ...heartColors({ isClicked, isHovered }),
    height: theme.space.x6,
    width: theme.space.x6,
  })
)<HeartProps>`
  animation: ${({ isClicked }) => (isClicked ? "0.5s ease-in-out" : "none")};
`;
