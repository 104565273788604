import { IIdGenerator } from "app-domain";
import { useEffect, useState } from "react";

const clientSessionIdStr = "@FC:Ecom:Client:sessionId";

const useCreateSessionId = (idGenerator: IIdGenerator) => {
  const [clientSessionId, setClientSessionId] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      const alreadyExistentSessionId =
        sessionStorage?.getItem(clientSessionIdStr);

      if (alreadyExistentSessionId) {
        setClientSessionId(alreadyExistentSessionId);
        return;
      }

      const generatedId = idGenerator.generateUUID();
      setClientSessionId(generatedId);
      sessionStorage.setItem(clientSessionIdStr, String(generatedId));
      setIsFirstRender(false);
    }
  }, [idGenerator, isFirstRender]);

  return clientSessionId;
};

const getSessionId = () => {
  return sessionStorage?.getItem(clientSessionIdStr) || "";
};

const createSessionId = (idGenerator: IIdGenerator) => {
  const alreadyExistentSessionId = sessionStorage?.getItem(clientSessionIdStr);

  if (alreadyExistentSessionId) {
    return alreadyExistentSessionId;
  }

  const generatedId = idGenerator.generateUUID();
  sessionStorage.setItem(clientSessionIdStr, String(generatedId));
  return generatedId;
};

export { useCreateSessionId, getSessionId, createSessionId };
