import { EnvsApi, IWebSocketClient } from "application";
import { WebSocketEventAction, WebSocketEventMetadata } from "typing";

// declare const WebSocket: {
//   prototype: WebSocket;
//   new (
//     uri: string,
//     protocols?: string | string[] | null,
//     options?: {
//       headers: { [headerName: string]: string };
//       [optionName: string]: any;
//     } | null
//   ): WebSocket;
//   readonly CLOSED: number;
//   readonly CLOSING: number;
//   readonly CONNECTING: number;
//   readonly OPEN: number;
// };

class WebSocketRaw implements IWebSocketClient {
  private socket: WebSocket | null = null;

  private isCreatingSocketInstance = false;

  private userEmail: string | null = null;

  constructor(private envsApi: EnvsApi) {
    this.envsApi = envsApi;
  }

  public getUserEmail() {
    return this.userEmail;
  }

  async createSocketInstance(userEmail: string, accessToken: string) {
    if (!this.socket?.OPEN && !this.isCreatingSocketInstance) {
      this.isCreatingSocketInstance = true;

      try {
        const allConfigs = await this.envsApi.getAwaitAllConfigs();
        const { websocketUrl } = allConfigs || { websocketUrl: "" };

        const url = new URL(
          `${websocketUrl}?email=${userEmail}&connectionSource=site&Authorizer=${accessToken}`
        );
        this.socket = new WebSocket(url);
        this.userEmail = userEmail;
        this.isCreatingSocketInstance = false;
      } catch {
        this.isCreatingSocketInstance = false;
      }
    }
  }

  destroySocketInstance() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  emitEvent(
    eventAction: WebSocketEventAction,
    metadata: WebSocketEventMetadata
  ) {
    if (this.socket) {
      const eventData = {
        action: "sendMessage",
        email: this.userEmail,
        event: eventAction,
        ...(metadata && { metadata }),
      };
      this.socket.send(JSON.stringify(eventData));
    }
  }

  onReceiveMessage(
    eventAction: string,
    callback: (message?: string | Event | undefined) => void
  ) {
    if (this.socket) {
      this.socket.addEventListener(eventAction, callback);
    }
  }
}

export { WebSocketRaw };
