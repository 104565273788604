import { ValidationStep } from "typing";
import { PhoneValidationComponent, PhoneValidationContent } from "./components";

interface IPhoneValidationUI {
  currentStep: ValidationStep;
}
const PhoneValidationUI = ({ currentStep }: IPhoneValidationUI) => {
  if (currentStep === "validation") {
    return <PhoneValidationComponent />;
  }

  return <PhoneValidationContent />;
};

export { PhoneValidationUI };
