import { ICreditCardUtils } from "app-domain";
import creditCardType from "credit-card-type";
import { CreditCardTypeCardBrandId } from "credit-card-type/dist/types";
import { CardName } from "typing";

const getMappedCreditCardBrand = (
  creditCardTypeCardBrandId: CreditCardTypeCardBrandId
): CardName | null => {
  switch (creditCardTypeCardBrandId) {
    case "american-express":
      return "Amex";
    case "hipercard":
      return "Hipercard";
    case "diners-club":
      return "Diners";
    case "discover":
      return null;
    case "elo":
      return "Elo";
    case "hiper":
      return "Hiper";
    case "mastercard":
      return "Mastercard";
    case "jcb":
      return "Jcb";
    case "visa":
      return "Visa";
    default:
      return null;
  }
};

const CreditCardTypeLib: ICreditCardUtils = {
  getBrand: (creditCardNumber: string) => {
    const formattedCreditCardNumber = creditCardNumber
      .trim()
      .replace(/\s/g, "");
    // console.log("formattedCreditCardNumber: ", formattedCreditCardNumber);
    const creditCardInfo = creditCardType(formattedCreditCardNumber);
    return getMappedCreditCardBrand(
      creditCardInfo[0]?.type as CreditCardTypeCardBrandId
    );
  },
};

export { CreditCardTypeLib };
