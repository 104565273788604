import Breadcrumbs from "nextjs-breadcrumbs";
import { BreadcrumbsProps } from "typing";
import { Home, theme } from "ui";
import { buildTestIds, capitalizeAll } from "application";
import {
  ArrowStyled,
  BreadcrumbsStyled,
  CustomBreadcrumbItem,
  CustomBreadcrumbList,
  CustomBreadcrumbNavigation,
  InnerLink,
  LinkStyled,
} from "./styles";

const NextjsBreadcrumbs = ({
  omitRootLabel = false,
  useDefaultStyle = false,
  rootLabel = "Home",
  replaceCharacterList = [{ from: "sofas", to: "Sofás" }],
  customLabels,
}: BreadcrumbsProps) => {
  return (
    <BreadcrumbsStyled {...buildTestIds("breadcrumbs-styled-component")}>
      {omitRootLabel ? null : (
        <>
          <LinkStyled
            {...buildTestIds("breadcrumbs-home-link")}
            href="/"
            target="_self"
            passHref
          >
            <InnerLink {...buildTestIds("breadcrumbs-home-inner-link")}>
              <Home
                width={16}
                height={16}
                color={theme.colors.neutral["450"]}
              />
            </InnerLink>
          </LinkStyled>
          <ArrowStyled {...buildTestIds("arrow-styled-icon")} />
        </>
      )}
      {customLabels?.length ? (
        <CustomBreadcrumbNavigation
          {...buildTestIds("custom-breadcrumb-navigation")}
        >
          <CustomBreadcrumbList {...buildTestIds("custom-breadcrumb-list")}>
            {customLabels?.map((customLabel, index) => (
              <CustomBreadcrumbItem
                {...buildTestIds(`custom-breadcrumb-item-${index}`)}
                key={customLabel.label}
              >
                <LinkStyled
                  {...buildTestIds(`custom-breadcrumb-link-styled-${index}`)}
                  key={customLabel.label}
                  href={customLabel?.redirectTo}
                  target="_self"
                >
                  {capitalizeAll(customLabel?.label)}
                </LinkStyled>
              </CustomBreadcrumbItem>
            ))}
          </CustomBreadcrumbList>
        </CustomBreadcrumbNavigation>
      ) : (
        <Breadcrumbs
          {...buildTestIds("breadcrumbs-component")}
          omitRootLabel
          useDefaultStyle={useDefaultStyle}
          rootLabel={rootLabel}
          replaceCharacterList={replaceCharacterList}
          omitIndexList={[0]}
        />
      )}
    </BreadcrumbsStyled>
  );
};

export { NextjsBreadcrumbs };
