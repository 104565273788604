import { AddressDto } from "typing";

export const useAddressSorter = (addressList: AddressDto[] | null = []) => {
  if (!addressList?.length) {
    return { sortedAddresses: [] };
  }

  const sortedAddresses = addressList
    .filter((address: AddressDto) => !address.isDefault)
    .sort((a: AddressDto, b: AddressDto) => (a.id - b.id) * -1);

  const defaultAddress = addressList.find(
    (address: AddressDto) => address?.isDefault
  );
  if (defaultAddress) {
    sortedAddresses.unshift(defaultAddress);
  }

  return { sortedAddresses };
};
