import { useState } from "react";
import { buildTestIds, formatCountdownTimer } from "application";
import { Separator, Time, TimeItem, TimeText, TimerContent } from "../styles";
import { leadingZero } from "./loading";

interface CountdownProps {
  hasImage?: string | false;
  separatorColor?: string;
  showSeparator?: "true" | "false";
  formatSmall?: boolean;
  date?: string;
  showUnits?: "true" | "false";
  hasBackground?: boolean;
  backgroundType?: "solidColor" | "image";
  dateColor?: string;
}

export const Countdown = ({
  hasImage,
  separatorColor,
  showSeparator,
  formatSmall,
  date,
  dateColor,
  showUnits,
  hasBackground,
  backgroundType,
}: CountdownProps) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const { days, hours, minutes, seconds } = timeLeft;

  setTimeout(() => {
    if (date) {
      setTimeLeft(formatCountdownTimer(date));
    }
  });

  const showDays = days >= 1;

  return (
    <TimerContent
      hasImage={hasImage}
      color={separatorColor}
      hasBackground={hasBackground}
      backgroundType={backgroundType}
      hasSeparator={showSeparator}
      isSmall={formatSmall}
      showDays={showDays}
      {...buildTestIds("countdown-timer-content")}
    >
      {showDays && (
        <>
          <TimeItem {...buildTestIds("countdown-time-item-days")}>
            <Time
              isSmall={formatSmall}
              hasImage={hasImage}
              dateColor={dateColor}
              {...buildTestIds("countdown-time-days")}
            >
              {leadingZero(days, 2)}
            </Time>
            {showUnits && (
              <TimeText
                isSmall={formatSmall}
                {...buildTestIds("countdown-time-text-days")}
              >
                dias
              </TimeText>
            )}
          </TimeItem>
          <Separator
            isSmall={formatSmall}
            hasBackground={hasBackground}
            backgroundType={backgroundType}
            hasImage={hasImage}
            color={separatorColor}
            hasSeparator={showSeparator}
            {...buildTestIds("countdown-separator-days")}
          >
            :
          </Separator>
        </>
      )}

      <TimeItem {...buildTestIds("countdown-time-item-hours")}>
        <Time
          isSmall={formatSmall}
          hasImage={hasImage}
          dateColor={dateColor}
          {...buildTestIds("countdown-time-hours")}
        >
          {leadingZero(hours)}
        </Time>
        {showUnits && (
          <TimeText
            isSmall={formatSmall}
            {...buildTestIds("countdown-time-text-hours")}
          >
            horas
          </TimeText>
        )}
      </TimeItem>
      <Separator
        {...buildTestIds("countdown-separator-hours")}
        isSmall={formatSmall}
        hasImage={hasImage}
        color={separatorColor}
        hasSeparator={showSeparator}
      >
        :
      </Separator>

      <TimeItem {...buildTestIds("countdown-time-item-minutes")}>
        <Time
          dateColor={dateColor}
          isSmall={formatSmall}
          hasImage={hasImage}
          {...buildTestIds("countdown-time-minutes")}
        >
          {leadingZero(minutes)}
        </Time>
        {showUnits && (
          <TimeText
            isSmall={formatSmall}
            {...buildTestIds("countdown-time-text-minutes")}
          >
            minutos
          </TimeText>
        )}
      </TimeItem>
      <Separator
        {...buildTestIds("countdown-separator-minutes")}
        isSmall={formatSmall}
        hasSeparator={showSeparator}
        hasBackground={hasBackground}
        hasImage={hasImage}
        color={separatorColor}
      >
        :
      </Separator>

      <TimeItem {...buildTestIds("countdown-time-item-seconds")}>
        <Time
          dateColor={dateColor}
          isSmall={formatSmall}
          hasImage={hasImage}
          {...buildTestIds("countdown-time-seconds")}
        >
          {leadingZero(seconds)}
        </Time>
        {showUnits && (
          <TimeText
            isSmall={formatSmall}
            {...buildTestIds("countdown-time-text-seconds")}
          >
            segundos
          </TimeText>
        )}
      </TimeItem>
    </TimerContent>
  );
};
