import { CloseIcon } from "ui";
import { buildTestIds } from "application";
import { ModalAlertProps } from "typing";
import {
  ContentModalAlert,
  ContentModalConfirm,
  HeaderModal,
  BodyModal,
  BodyModalAlert,
  TitleModal,
  ButtonClose,
  ButtonCloseAlert,
  FooterModal,
  ButtonLine,
  ButtonSolid,
} from "./styles";

const PopUpAlertConfirm = ({
  isOpen,
  typeInfo,
  backgroundColor,
  textHeader,
  descriptionBody,
  actionCloseButton,
  footerProps,
  typeModal = "confirm",
  locationHorizontal,
  locationVertical,
}: ModalAlertProps) => {
  if (!isOpen) {
    return null;
  }

  if (typeModal === "alert") {
    return (
      <ContentModalAlert
        {...buildTestIds("pop-up-alert-modal")}
        backgroundColor={backgroundColor}
        locationHorizontal={locationHorizontal}
        locationVertical={locationVertical}
      >
        <BodyModalAlert {...buildTestIds("pop-up-alert-body-modal")}>
          {descriptionBody}
        </BodyModalAlert>
        <ButtonCloseAlert
          {...buildTestIds("pop-up-alert-button-close-modal")}
          onClick={actionCloseButton}
          typeInfo={typeInfo}
        >
          <CloseIcon alt="Fechar" height={25} width={25} />
        </ButtonCloseAlert>
      </ContentModalAlert>
    );
  }

  return (
    <ContentModalConfirm
      typeInfo={typeInfo}
      backgroundColor={backgroundColor}
      locationHorizontal={locationHorizontal}
      locationVertical={locationVertical}
      {...buildTestIds("pop-up-alert-modal")}
    >
      <ButtonClose
        onClick={actionCloseButton}
        typeInfo={typeInfo}
        {...buildTestIds("pop-up-alert-button-close-modal")}
      >
        <CloseIcon height={25} width={25} />
      </ButtonClose>
      {textHeader && (
        <HeaderModal {...buildTestIds("pop-up-alert-header-modal")}>
          <TitleModal
            {...buildTestIds("pop-up-alert-title-modal")}
            lowerContent={Boolean(descriptionBody)}
          >
            {textHeader}
          </TitleModal>
        </HeaderModal>
      )}
      {descriptionBody && (
        <BodyModal {...buildTestIds("pop-up-alert-body-modal")}>
          {descriptionBody}
        </BodyModal>
      )}
      {footerProps?.isActive ? (
        <FooterModal {...buildTestIds("pop-up-alert-footer-modal")}>
          {footerProps?.children ? (
            footerProps.children
          ) : (
            <>
              {footerProps?.buttonLine && (
                <ButtonLine
                  {...buildTestIds("pop-up-alert-button-line-modal")}
                  onClick={footerProps?.actionButtonLine}
                >
                  {footerProps?.textButtonLine ?? "Cancelar"}
                </ButtonLine>
              )}
              {footerProps?.buttonSolid && (
                <ButtonSolid
                  {...buildTestIds("pop-up-alert-button-solid-modal")}
                  onClick={footerProps?.actionButtonSolid}
                >
                  {footerProps?.textButtonSolid ?? "Confirmar"}
                </ButtonSolid>
              )}
            </>
          )}
        </FooterModal>
      ) : null}
    </ContentModalConfirm>
  );
};

export { PopUpAlertConfirm };
