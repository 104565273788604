import { createStore } from "../adapter";

type CounterState = {
  isCodeTimerActive: boolean;
  isSendCodeAvailable: boolean;
  initTimer: boolean;
};

type CounterDispatch = {
  setCodeTimerActive: (value: boolean) => void;
  setIsSendCodeAvailable: (value: boolean) => void;
  setInitTimer: (value: boolean) => void;
};

type CounterProps = CounterState & CounterDispatch;

const useCounter = createStore<CounterProps>((set) => ({
  isCodeTimerActive: false,
  setCodeTimerActive: (value: boolean) => set({ isCodeTimerActive: value }),
  isSendCodeAvailable: true,
  setIsSendCodeAvailable: (value: boolean) =>
    set({ isSendCodeAvailable: value }),
  initTimer: false,
  setInitTimer: (initTimer: boolean) => set({ initTimer }),
}));

export { useCounter };
