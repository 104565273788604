const isObjectEmpty = (passedObject: { [key: string]: string } | null) => {
  try {
    if (typeof passedObject !== "object")
      throw new Error("Passed object is not an object");
    return !!(!passedObject || !Object.keys(passedObject)?.length);
  } catch {
    return true;
  }
};

export { isObjectEmpty };
