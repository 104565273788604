import { IconProps } from "typing";
import { theme } from "../themes";

export const Question = ({
  width = 32,
  height = 32,
  color = theme.colors.neutral.white,
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_579_10148)">
        <circle
          cx="12"
          cy="12"
          r="9.25"
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <path
          d="M9.99336 8.73919C10.3437 8.11641 10.9293 7.75757 11.6154 7.71912C12.4032 7.67497 13.1464 7.93504 13.5218 8.50507C14.0944 9.37464 13.6054 10.3445 12.97 10.8462C12.97 10.8462 12.2161 11.514 12.1004 11.6823C11.7325 12.2174 11.6823 12.4516 11.699 13.0536"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="11.6655"
          cy="15.7123"
          r="0.75"
          fill={color}
          stroke={color}
          strokeWidth="0.506689"
        />
      </g>
      <defs>
        <clipPath id="clip0_579_10148">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
