import styled, { css } from "styled-components";
import { DeviceFormatValue } from "typing";
import { theme } from "ui";
import { Image } from "../../Image";

interface CustomCommonBannerContainerProps {
  isToRedirect: boolean;
  format: DeviceFormatValue | undefined;
  isBannerComponent?: boolean;
}

export const CustomCommonBannerContainer = styled.section<CustomCommonBannerContainerProps>`
  ${({ isBannerComponent }) =>
    isBannerComponent &&
    css`
      margin: 0 auto;
    `}

  ${({ isToRedirect }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.space.x0};
    cursor: ${isToRedirect ? "pointer" : "default"};
  `}

  ${({ format }) =>
    format === "extended" &&
    css`
      max-width: 1600px;
      width: 100%;
    `}

    ${({ format }) =>
      format === "default" &&
      css`
        max-width: 1280px;
        width: 100%;
      `}}

  @media ${theme.device.desktopFirst.tablet} {
    ${({ format }) =>
      format === "default" &&
      css`
        width: 94vw;
      `}
  }
`;

interface CustomBannerImageWrapperProps {
  format: DeviceFormatValue;
}

export const CustomBannerImageWrapper = styled.section<CustomBannerImageWrapperProps>`
  position: relative;
  max-width: ${({ format }) => (format === "extended" ? "1600px" : "1280px")};
  width: 100%;

  ${({ format }) =>
    format === "extended" &&
    css`
      margin: auto 0;
    `}

  @media ${theme.device.desktopFirst.mobileXL} {
    max-width: 100%;
  }

  & > span {
    position: unset !important;
    height: 100% !important;
    width: 100% !important;
  }
`;
interface CustomBannerImageProps {
  format: DeviceFormatValue;
  isinsidecarousel: string;
  isinsidecarouselingridformat: string;
}

export const CustomBannerImage = styled(Image)<CustomBannerImageProps>`
  position: relative !important;
  width: 100% !important;
  border-radius: ${({ format }) =>
    format === "extended" ? theme.space.x0 : theme.space.x2} !important;

  ${({ isinsidecarousel, isinsidecarouselingridformat }) =>
    isinsidecarousel === "true" && isinsidecarouselingridformat === "false"
      ? css`
          border-radius: ${theme.space.x0} !important;
          min-height: 340px !important;
          max-height: 340px !important;
          width: 100%;

          object-fit: cover;
          object-position: center;

          @media ${theme.device.desktopFirst.tablet} {
            min-height: 220px !important;
            max-height: 220px !important;
          }

          @media ${theme.device.desktopFirst.mobileXL} {
            min-height: 100%;
            height: fit-content;
            width: fit-content;
            object-fit: contain;
            object-position: 50% 50%;
          }
        `
      : css`
          height: auto !important;
        `};
`;
