import styled, { keyframes, css } from "styled-components";
import { CloseIcon, FlexContainer, Interrogation, Map, theme } from "ui";
import Label from "../../../../Form/Label";
import { Link } from "../../../../Link";

interface ModalBranchLocationOverlayProps {
  isOpen: boolean;
}

interface DropdownContainerProps {
  isShowDropdown: boolean;
}

// interface ModalBranchLocationContainerProps {
//   isHadShowButtonConfirm: boolean;
// }

// type ConfirmButtonProps = ModalBranchLocationContainerProps;

const animationOpenModal = keyframes`
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: flex;
      opacity: 0;
  }

  100% {
      display: flex;
      opacity: 1;
  }
`;

const animationCloseModal = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const ModalBranchLocationOverlay = styled.div<ModalBranchLocationOverlayProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  z-index: 9999;
  background: rgba(39, 44, 54, 0.3);

  animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${animationOpenModal} 0.4s ease
        `
      : css`
          ${animationCloseModal} 0.4s ease
        `};
`;

export const ModalBranchLocationContainer = styled.section`
  background: ${theme.colors.neutral.white};
  width: 100%;
  height: 100%;
  max-height: 504px;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 60px ${theme.colors.neutral["999-opacity-05"]};
  position: relative;
  transform: translateY(7vh);
  padding: ${theme.space.x3} ${theme.space.x0};

  @media ${theme.device.desktopFirst.tablet} {
    max-height: 476px;
    max-width: 324px;
  }
`;

export const HeaderModal = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.neutral["150"]};
`;

export const TitleModal = styled.span`
  color: ${theme.colors.neutral["550"]};
  font-size: 2rem;
  font-family: ${theme.typography.fontFamily.roboto}, sans-serif;
  font-weight: 700;
  text-align: center;
  padding-bottom: 1rem;
  width: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    font-size: 1.35rem;
  }
`;

export const MainContent = styled.section`
  padding: 2rem;

  width: 100% !important;
  height: auto !important;

  @media ${theme.device.desktopFirst.tablet} {
    padding: 0.75rem 2rem 0;
  }
`;

export const InfoText = styled.span`
  display: flex;
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: ${theme.typography.fontFamily.roboto}, sans-serif;
  line-height: 1.75rem;
  color: ${theme.colors.neutral["550"]};

  @media ${theme.device.desktopFirst.tablet} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const FormContainer = styled.div`
  padding: 1.5rem 2rem;

  @media ${theme.device.desktopFirst.tablet} {
    padding: 2rem 2rem 0;
  }
`;

type LabelCustomProps = {
  highlightText?: boolean;
};

export const LabelCustom = styled(Label)<LabelCustomProps>`
  font-family: ${theme.typography.fontFamily.roboto}, sans-serif;
  font-weight: ${({ highlightText }) => (highlightText ? "bold" : 500)};
  font-size: ${({ highlightText }) => (highlightText ? "1.25rem" : "1.05rem")};
  color: ${theme.colors.neutral["550"]};
`;

interface CustomInputProps {
  hasError: boolean;
  hasSuccessfully: boolean;
  highlightText?: boolean;
  hasLargeMarginBottom?: boolean;
}

export const CustomInput = styled.input<CustomInputProps>`
  width: 100%;
  height: 3rem;
  padding: 0.75rem;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? theme.colors.primary["200"] : theme.colors.neutral["200"]};
  border-radius: 4px;
  background: ${theme.colors.neutral.white};
  color: ${theme.colors.neutral["999"]};
  transition: all 0.2s ease;
  font-family: ${theme.typography.fontFamily.roboto}, sans-serif;
  font-weight: 500;
  font-size: ${({ highlightText }) => (highlightText ? "1.35rem" : "1.125rem")};
  margin: ${({ hasLargeMarginBottom }) =>
    hasLargeMarginBottom ? "0.65rem 0 0.75rem" : "0.65rem 0 0.5rem"};

  ${({ hasLargeMarginBottom, hasError }) =>
    hasError &&
    hasLargeMarginBottom &&
    css`
      margin: 0.65rem 0 0.5rem !important;
    `}

  ${({ hasSuccessfully }) =>
    hasSuccessfully &&
    css`
      border-color: ${theme.colors.secondary["380"]} !important;
    `}

  &:hover {
    border: 1px solid
      ${({ hasError }) =>
        hasError ? theme.colors.primary["200"] : theme.colors.neutral["200"]};
  }

  &:focus {
    border: 1px solid
      ${({ hasError }) =>
        hasError ? theme.colors.primary["200"] : theme.colors.secondary["380"]};
  }

  &::placeholder {
    color: ${theme.colors.neutral["200"]};
    font-size: 1.125rem;
    font-family: ${theme.typography.fontFamily.roboto}, sans-serif;
    font-weight: 400;
  }
`;

export const InputContainer = styled.section`
  margin-bottom: 1.25rem;
`;

export const DropdownContainer = styled.section<DropdownContainerProps>`
  width: calc(100% - 4rem) !important;
  height: auto !important;
  max-height: 8.45rem;
  background: ${theme.colors.neutral["white-050"]};
  position: absolute;
  overflow: auto;
  border-radius: 0 0 4px 4px;
  border: 1px solid ${theme.colors.neutral["99"]};
  margin-top: -0.975rem;
  display: ${({ isShowDropdown }) => (isShowDropdown ? "flex" : "none")};
  opacity: ${({ isShowDropdown }) => (isShowDropdown ? "1" : "0")};
  visibility: ${({ isShowDropdown }) =>
    isShowDropdown ? "visible" : "hidden"};
  flex-direction: column;
  z-index: 8;
`;

export const CityTitle = styled.span`
  color: ${theme.colors.neutral["350"]};
  font-family: ${theme.typography.fontFamily.roboto}, sans-serif;
  font-weight: 400;
  font-size: 1rem;
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.75rem;

  border-bottom: 1px solid ${theme.colors.neutral["99"]};
  transition: all 0.2s ease;

  &:hover {
    background: ${theme.colors.secondary["28"]};

    ${CityTitle} {
      color: ${theme.colors.neutral["999"]};
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const ContentCloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 2.25rem;
  width: 100%;
  padding: 0 1rem;
`;

export const WrapperCloseIcon = styled.div`
  height: 2.25rem;
  width: 2.5rem;
  cursor: pointer;
`;

export const ButtonCloseIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;

  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const Close = styled(CloseIcon).attrs({
  width: "2.125rem",
  height: "1.75rem",
})``;

export const ActualLocationIcon = styled(Map).attrs({
  width: "1.5rem",
  height: "1.5rem",
  color: theme.colors.primary["350"],
})``;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 1.75rem;
  margin-bottom: 0.75rem;

  @media ${theme.device.desktopFirst.tablet} {
    margin-bottom: 2rem;
  }
`;

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.925rem;
  color: ${theme.colors.neutral["550"]};
  gap: 0.25rem;
  font-family: ${theme.typography.fontFamily.roboto}, sans-serif;
`;

export const InterrogationIcon = styled(Interrogation).attrs({
  width: "1rem",
  height: "1rem",
})``;

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding: 0.65rem 0;
  cursor: pointer;
  border: 0;
  transition: all 0.2s ease;
  font-weight: 700;
`;

export const ClientLocationButton = styled(BaseButton)`
  background: transparent;
  margin-top: 2rem;
  color: ${theme.colors.primary["350"]};
  text-decoration: underline;
  gap: 0.25rem;
  font-size: 1.35rem;

  &:hover {
    background: rgb(190, 15, 44, 0.03);
  }

  &:focus {
    border: thin solid ${theme.colors.primary["350"]};
  }

  @media ${theme.device.desktopFirst.tablet} {
    margin-top: 1rem;
    font-size: 1rem;
  }
`;

export const ConfirmButton = styled(BaseButton)`
  background: ${theme.colors.secondary["250"]};
  color: ${theme.colors.neutral.white};
  height: 3rem;
  transition: all 0.2s ease;
  display: flex;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: ${theme.colors.secondary["350"]};
  }

  &:focus {
    border: thin solid ${theme.colors.secondary["350"]};
  }
`;
