/* eslint-disable react/no-array-index-key */
import { buildTestIds, getBranchAddress, getClientBranchId } from "application";
import { useAppContext } from "application/context/state";
import { cookie } from "implementations";
import { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Aggregator,
  ArrowDown,
  ArrowUp,
  Container,
  OverlayDynamic,
  SpaceWrapper,
  Text,
  theme,
} from "ui";
import CategoryBlock from "../../CategoryBlock";
import { Skeleton } from "../../Skeleton";
import { withBlackThemeValidation } from "../../hocs";
import { SupportContact } from "../SupportContact";
import { SupportContactMobile } from "../SupportContact/mobile";
import { UtilInfo } from "../UtilInfo";
import {
  Button,
  FooterDescriptionContainer,
  StyledFooter,
  Wrapper,
} from "./style";

type FooterProps = {
  isBlackTheme?: boolean;
};

const currentYear = new Date().getFullYear();

const Footer = ({ isBlackTheme }: FooterProps) => {
  const { categories, isClientTablet, isClientMobile, isSidebarMenuOpened } =
    useAppContext();

  const styledFooterRef = useRef<HTMLElement>(null);
  const [styledFooterOverlayHeight, setStyledFooterOverlayHeight] =
    useState("100%");
  const [otherPagesButtonClicked, setOtherPagesButtonClicked] = useState(false);

  // Esse eslint está desabilitado para que, toda vez que o componente for recriado, o overlay possa obter a largura exata do StyledFooter, mesmo que a tela seja redimensionada.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (styledFooterRef?.current?.clientHeight) {
      setStyledFooterOverlayHeight(`${styledFooterRef.current.clientHeight}px`);
    }
  });

  const toggleOtherPagesContainer = () => {
    setOtherPagesButtonClicked((state) => !state);
  };

  const branchId = getClientBranchId(cookie);

  const blackThemeColor = isBlackTheme
    ? theme.colors.neutral.white
    : theme.colors.neutral["999"];

  return (
    <>
      <OverlayDynamic
        {...buildTestIds("footer-overlay-dynamic")}
        isVisible={!isClientMobile && isSidebarMenuOpened}
        height={styledFooterOverlayHeight}
      />

      <StyledFooter
        id="footer"
        data-testid="footer"
        data-cy="footer-container"
        ref={styledFooterRef}
      >
        <Wrapper isBlackTheme={!!isBlackTheme}>
          <Container>
            <SpaceWrapper
              data-testid="space-wrapper"
              margin={
                isClientTablet
                  ? `${theme.space.x2} ${theme.space.x0}`
                  : `${theme.space.x10} ${theme.space.x4}`
              }
            >
              {isClientTablet ? (
                <SupportContactMobile
                  isBlackTheme={!!isBlackTheme}
                  data-testid="support-contact-mobile-container"
                />
              ) : (
                <SupportContact isBlackTheme={!!isBlackTheme} />
              )}
            </SpaceWrapper>
            <Aggregator
              display="flex"
              justifyContent="center"
              alignItems="end"
              padding="0"
              laptop={{
                display: "none",
              }}
            >
              <Button
                isBlackTheme={!!isBlackTheme}
                id="other-pages-button"
                data-testid="other-pages-button"
                onClick={toggleOtherPagesContainer}
              >
                <Text
                  data-testid="other-pages-text"
                  fontSize={theme.typography.fontSize.sm.x5}
                  color={blackThemeColor}
                  margin={
                    isClientTablet
                      ? `${theme.space.x2} ${theme.space.x0}`
                      : `${theme.space.x2} ${theme.space.x1}`
                  }
                >
                  Outras páginas
                </Text>
                {otherPagesButtonClicked ? (
                  <ArrowUp color={blackThemeColor} />
                ) : (
                  <ArrowDown color={blackThemeColor} />
                )}
              </Button>
            </Aggregator>
          </Container>
        </Wrapper>
        <Accordion
          {...buildTestIds("other-pages-accordion")}
          show={otherPagesButtonClicked}
          maxHeight={theme.space.x160}
          backgroundColor={
            isBlackTheme
              ? theme.colors.blackFriday["175"]
              : theme.colors.neutral["white-050"]
          }
          laptop={{
            display: "none",
          }}
        >
          <Container>
            <SpaceWrapper
              padding={
                isClientTablet
                  ? `${theme.space.x2} ${theme.space.x0}`
                  : `${theme.space.x10} ${theme.space.x10}`
              }
            >
              <Aggregator
                display="flex"
                flexDirection="column"
                justifyContent="center"
                flexWrap="wrap"
                maxHeight="550px"
                gap={`${theme.space.x3} 40px`}
                alignItems="center"
                color={theme.colors.neutral["999"]}
              >
                {categories?.isLoading ? (
                  <Skeleton count={10} />
                ) : (
                  categories?.data?.map((category) => (
                    <CategoryBlock
                      isBlackTheme={isBlackTheme}
                      key={`${category.id}#${category.descriptionUrl}`}
                      category={category}
                      level={2}
                      isLoading={categories?.isLoading}
                    />
                  ))
                )}
              </Aggregator>
            </SpaceWrapper>
          </Container>
        </Accordion>

        <UtilInfo isBlackTheme={isBlackTheme} />

        <FooterDescriptionContainer
          id="fc-texts-container"
          {...buildTestIds("fc-texts-container")}
          isBlackTheme={isBlackTheme}
        >
          <SpaceWrapper
            margin={
              isClientTablet
                ? `${theme.space.x0} ${theme.space.x2}`
                : `${theme.space.x1} ${theme.space.x2} ${theme.space.x0}`
            }
            padding={
              isClientTablet
                ? `${theme.space.x4} ${theme.space.x0}`
                : `${theme.space.x2} ${theme.space.x2}`
            }
            borderColor={
              isBlackTheme
                ? theme.colors.blackFriday["100"]
                : theme.colors.neutral["250"]
            }
            borderTop
          >
            <Text
              data-testid="warning-text"
              textAlign="center"
              fontSize={
                isClientMobile ? theme.typography.fontSize.sm.x2 : "13.5px"
              }
              margin={
                isClientTablet
                  ? `${theme.space.x0} ${theme.space.x0}`
                  : `${theme.space.x3} ${theme.space.x0}`
              }
            >
              Preços, promoções, condições de pagamento e frete são válidos para
              compras realizadas exclusivamente pelo site. Caso haja divergência
              de preço de um produto, será válido o preço que for exibido no
              carrinho de compras do site no momento do pagamento. As vendas
              estão sujeitas a análise e disponibilidade do estoque. Imagens de
              produtos meramente ilustrativas.
            </Text>

            <SpaceWrapper
              padding={`${theme.space.x0} ${theme.space.x0} ${theme.space.x1}`}
            >
              <Text
                data-testid="info-text"
                fontSize={
                  isClientMobile ? theme.typography.fontSize.sm.x2 : "13.5px"
                }
                textAlign="center"
              >
                Copyright © 1884-{currentYear} · Ferreira Costa & Cia LTDA CNPJ
                10.230.480/0019-60 · {getBranchAddress(branchId)}
              </Text>
            </SpaceWrapper>
          </SpaceWrapper>
        </FooterDescriptionContainer>
      </StyledFooter>
    </>
  );
};

Footer.displayName = "Footer";

const FooterWithBlackThemeValidation =
  withBlackThemeValidation<FooterProps>(Footer);

export { FooterWithBlackThemeValidation as Footer };
