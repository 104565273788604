import { useTwoFactor } from "application/state-manager";
import { useShallow } from "application/state-manager/adapter";
import { memo } from "react";
import { TwoFactorOptionType, ViewType } from "typing";
import { ModalGeneral } from "../../../ModalGeneral";
import { TwoFactorOptions } from "../../Options";
import {
  TwoFactorDescription,
  TwoFactorTitle,
  TwoFactorViewContainer,
} from "./styles";

type ViewTwoFactorContentProps = {
  title?: string;
  description?: string;
  viewType?: ViewType;
  options?: TwoFactorOptionType[] | null;
  noTitle?: boolean;
  hasTitleBorder: boolean;
  backgroundColor?: string;
  hasDescriptionOnMobile?: boolean;
};

const ViewTwoFactorVerifyIdentityContent = memo(
  ({
    title,
    options,
    viewType,
    hasTitleBorder = false,
    hasDescriptionOnMobile = false,
    noTitle,
    backgroundColor,
    description = "Para garantir a sua segurança, escolha um modo para autenticar a sua identidade.",
  }: ViewTwoFactorContentProps) => {
    const { isValidationActive, setIsValidationActive } = useTwoFactor(
      useShallow((state) => ({
        isValidationActive: state.isValidationActive,
        setIsValidationActive: state.setIsValidationActive,
      }))
    );

    if (viewType === "component") {
      return isValidationActive ? (
        <TwoFactorViewContainer backgroundColor={backgroundColor}>
          {!noTitle && <TwoFactorTitle>{title}</TwoFactorTitle>}
          {description && hasDescriptionOnMobile && (
            <TwoFactorDescription>{description}</TwoFactorDescription>
          )}
          <TwoFactorOptions options={options} />
        </TwoFactorViewContainer>
      ) : null;
    }

    return (
      <ModalGeneral
        ariaHideApp={false}
        isOpen={isValidationActive}
        onCloseModal={() => setIsValidationActive(false)}
        ariaLabelForModal={title ?? ""}
        title={title ?? ""}
        bodyDescription={description}
        maxWidth="489px"
        bodyContent={
          <TwoFactorViewContainer>
            <TwoFactorOptions options={options} />
          </TwoFactorViewContainer>
        }
        hasTitleBorder={hasTitleBorder}
        containerGap="0"
      />
    );
  }
);

ViewTwoFactorVerifyIdentityContent.displayName =
  "ViewTwoFactorVerifyIdentityContent";

export { ViewTwoFactorVerifyIdentityContent };
