import {
  Title,
  TextAlign,
  CustomDynamicBannerProperty,
  ShowTitle,
  ShowSubTitle,
  SubTitle,
} from "typing";

const customDynamicBannerData = (properties: CustomDynamicBannerProperty[]) => {
  const title = (properties.find(({ key }) => key === "title") as Title)?.value;

  const textAlign = (
    properties.find(({ key }) => key === "textAlign") as TextAlign
  )?.value;

  const showTitle = (
    properties.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const subTitle = (
    properties.find(({ key }) => key === "subTitle") as SubTitle
  )?.value;

  const showSubTitle = (
    properties.find(({ key }) => key === "showSubTitle") as ShowSubTitle
  )?.value;

  return {
    title,
    textAlign,
    showTitle,
    subTitle,
    showSubTitle,
  };
};

export { customDynamicBannerData };
