import styled from "styled-components";
import { theme } from "ui";

interface SendCodeProps {
  width?: string;
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
}

export const SendCode = styled.button<SendCodeProps>`
  height: 40px;
  width: ${({ width }) => width || "100%"};
  border: 1px solid ${theme.colors.secondary["380"]};
  background: ${({ backgroundColor }) =>
    backgroundColor || `${theme.colors.secondary["360"]}`};
  color: ${({ color }) => color || `${theme.colors.neutral.white}`};
  justify-content: center;
  font-size: ${({ fontSize }) =>
    fontSize || `${theme.typography.fontSize.sm.x5}`};
  border-radius: ${theme.space.x2};
  font-family: ${theme.typography.fontFamily.barlow};
  cursor: pointer;
  font-weight: ${theme.typography.fontWeight[400]};
  transition: background-color 0.2s ease-in-out;

  &:not(:disabled):hover {
    background: ${theme.colors.secondary["420"]};
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;
