const getFeedbackContent = (isTokenCodeValid: boolean | null) => {
  if (isTokenCodeValid) {
    return {
      title: "Sua senha foi alterada!",
      description: "Sua senha foi alterada com sucesso.",
    };
  }

  return {
    title: "Erro na alteração de senha",
    description: "Houve um erro na tentativa de alteração da sua senha.",
  };
};

export { getFeedbackContent };
