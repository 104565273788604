import { IHttpClient, IAppMonitoringClient } from "app-domain";

export const MockedIdentityApi = {
  baseUrl: `mocked_identity_base_url`,
  clientId: "mocked_client_id",
  // deepcode ignore HardcodedNonCryptoSecret: <apenas um mock de secret com um valor qualquer>
  clientSecret: "mocked_client_secret",
  scopeEcommerce: "ecommerce offline_access IdentityServerApi",
  appMonitoringClient: {} as IAppMonitoringClient,

  initialize(
    httpClient: IHttpClient,
    appMonitoringClient: IAppMonitoringClient
  ) {
    this.appMonitoringClient = appMonitoringClient;
  },
};
