import { ReceiveConfirmationResponse } from "typing";
import { IdentityApi } from "../../services";
import { createStore } from "../adapter";

export interface ConfirmationTypesState {
  hasPendency: boolean;
  getAvailableMethods: (
    accessToken: string,
    identityApi: IdentityApi,
    email?: string,
    cpf?: string
  ) => Promise<ReceiveConfirmationResponse | null | undefined>;
  availableMethods: ReceiveConfirmationResponse | null;
  setAvailableMethods: (value: ReceiveConfirmationResponse | null) => void;
  isLoading: boolean;
  canChooseAnotherMethod: boolean;
}

const useConfirmationTypes = createStore<ConfirmationTypesState>(
  (set, get) => ({
    hasPendency: false,
    isLoading: false,
    canChooseAnotherMethod: false,
    availableMethods: null,
    setAvailableMethods: (value) => {
      set({
        hasPendency: !value?.emailConfirmed || !value?.phoneNumberConfirmed,
        availableMethods: value,
        canChooseAnotherMethod:
          (value?.emailConfirmed && value?.phoneNumberConfirmed) ||
          (!value?.emailConfirmed && !value?.phoneNumberConfirmed),
      });
    },
    getAvailableMethods: async (accessToken, identityApi, email, cpf) => {
      try {
        if (accessToken) {
          set({ isLoading: true });
          identityApi
            .receiveConfirmationTypes({
              email: String(email),
              cpf: String(cpf),
              jwt: String(accessToken),
            })
            .then(({ data }) => {
              get().setAvailableMethods(data || null);
              return data || null;
            });
        }
        return null;
      } catch {
        set({
          availableMethods: null,
          canChooseAnotherMethod: false,
        });
        return undefined;
      } finally {
        set({ isLoading: false });
      }
    },
  })
);

export { useConfirmationTypes };
