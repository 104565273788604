import { IconProps } from "typing";
import { theme } from "../themes";

export const TwoStepVerificationIcon = ({
  width,
  height,
  color = theme.colors.neutral["550"],
  alt,
  strokeWidth = "1.5",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.752 17.002H17.2505C16.5599 17.002 16 17.5618 16 18.2525V20.7535C16 21.4442 16.5599 22.004 17.2505 22.004H20.752C21.4426 22.004 22.0025 21.4442 22.0025 20.7535V18.2525C22.0025 17.5618 21.4426 17.002 20.752 17.002Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7534 17.0022V15.7517C20.7534 14.7848 19.9696 14.001 19.0027 14.001V14.001C18.0358 14.001 17.252 14.7848 17.252 15.7517V17.0022"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5001 10.7148L11.3761 13.8389L9.49805 11.9669"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.945 11C20.4062 6.18818 16.158 2.66443 11.3295 3.0243C6.501 3.38417 2.82203 7.49873 3.00246 12.3372C3.18288 17.1757 7.15808 21.0049 12 21.0041"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
