import { CardName } from "typing";
import {
  Amex,
  DefaultCard,
  Diners,
  Elo,
  Hiper,
  Hipercard,
  Jcb,
  Mastercard,
  Visa,
} from "ui";

const getSmallMappedCard = (cardName: CardName | string | null, size = 24) => {
  if (typeof cardName !== "string" && typeof cardName !== "undefined") {
    return null;
  }

  switch (cardName) {
    case "Mastercard":
      return <Mastercard width={size} height={size} />;
    case "Visa":
      return <Visa width={size} height={size} />;
    case "Hiper":
      return <Hiper width={size} height={size} />;
    case "Elo":
      return <Elo width={size} height={size} />;
    case "Amex":
      return <Amex width={size} height={size} />;
    case "Jcb":
      return <Jcb width={size} height={size} />;
    case "Diners":
      return <Diners width={size} height={size} />;
    case "Hipercard":
      return <Hipercard width={size} height={size} />;
    default:
      return <DefaultCard width={size} height={size} />;
  }
};

export { getSmallMappedCard };
