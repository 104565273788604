import { RefObject, useCallback, useEffect, useState } from "react";
import { SelectedBreadcrumbFilter } from "typing";

interface SliderProps {
  appliedFilterItemsRef: RefObject<HTMLDivElement | null>;
  activeFilters: SelectedBreadcrumbFilter[] | string[];
}

const useSlider = ({ appliedFilterItemsRef, activeFilters }: SliderProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const appliedFilterItems = appliedFilterItemsRef?.current;

  const handleNextFiltersClick = useCallback(() => {
    if (appliedFilterItems) {
      const itemWidth = appliedFilterItems?.lastElementChild?.clientWidth || 0;
      const visibleItems = Math.floor(
        appliedFilterItems.clientWidth / itemWidth
      );

      const targetScrollPosition = Math.min(
        appliedFilterItems.scrollWidth - appliedFilterItems.clientWidth,
        scrollPosition + itemWidth * visibleItems
      );

      setScrollPosition(targetScrollPosition);
      appliedFilterItems?.scrollTo({
        left: targetScrollPosition,
        behavior: "smooth",
      });

      setShowPrevButton(true);
    }
  }, [appliedFilterItems, scrollPosition]);

  const handlePrevFiltersClick = useCallback(() => {
    setShowNextButton(true);
    if (appliedFilterItems) {
      const itemWidth = appliedFilterItems?.lastElementChild?.clientWidth || 0;
      const visibleItems = Math.floor(
        appliedFilterItems.clientWidth / itemWidth
      );

      const targetScrollPosition = Math.max(
        0,
        scrollPosition - itemWidth * visibleItems
      );

      setScrollPosition(targetScrollPosition);
      appliedFilterItems?.scrollTo({
        left: targetScrollPosition,
        behavior: "smooth",
      });

      if (targetScrollPosition === 0) {
        setShowPrevButton(false);
      }
    }
  }, [appliedFilterItems, scrollPosition]);

  useEffect(() => {
    if (appliedFilterItems && activeFilters?.length) {
      setShowNextButton(
        appliedFilterItems?.scrollWidth > appliedFilterItems?.clientWidth
      );
    }
  }, [activeFilters, appliedFilterItems, scrollPosition]);

  return {
    showPrevButton,
    showNextButton,
    handleNextFiltersClick,
    handlePrevFiltersClick,
  };
};

export { useSlider };
