import styled from "styled-components";
import { theme } from "ui";
import { ButtonSubmit } from "../../../../../../../../ButtonSubmit";

export const ModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.33rem;
`;

export const ModalDescription = styled.section`
  padding-top: 2.67rem;
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x2};
`;

export const ModalDescriptionTitle = styled.div`
  color: ${theme.colors.neutral["550"]};
  font-size: 1.16667rem;
`;

export const ModalDescriptionSubtitle = styled.div`
  color: ${theme.colors.neutral["730"]};
  font-size: ${theme.typography.fontSize.sm.x3};
  font-weight: ${theme.typography.fontWeight[400]};
`;

export const ModalBottom = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.37rem;
`;

export const ModalButton = styled(ButtonSubmit).attrs({
  minWidth: "100%",
  fontSize: theme.typography.fontSize.sm.x7,
  height: "3.75rem",
  fontWeight: theme.typography.fontWeight["400"],
  fontFamily: theme.typography.fontFamily.chivo,
})`
  /* @media ${theme.device.desktopFirst.tabletM} {
    min-width: 324px;
  } */
`;

export const DoItLaterButton = styled.div`
  cursor: pointer;
  color: ${theme.colors.neutral["550"]};
  font-size: ${theme.typography.fontSize.sm.x3};
`;
