import styled from "styled-components";
import { theme } from "ui";

export const UniqueClientValidationContainer = styled.section`
  background-color: ${theme.colors.neutral.white};
  padding: 2rem;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: ${theme.space.x0} ${theme.space.x4};
  }
`;

export const SkeletonWrapper = styled.section`
  max-width: 100%;
  width: 100%;
`;
