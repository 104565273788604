import { buildTestIds } from "application";
import { memo } from "react";
import { RecommendationTypeProps } from "typing";
import { HighestDiscountedBadgeContainer } from "./styles";

const MostPurchasedBadge = memo(
  ({ recommendationTypeText }: RecommendationTypeProps) => {
    return (
      <HighestDiscountedBadgeContainer
        {...buildTestIds("recommendation-type-badge-content")}
      >
        {recommendationTypeText}
      </HighestDiscountedBadgeContainer>
    );
  }
);

MostPurchasedBadge.displayName = "MostPurchasedBadge";

export default MostPurchasedBadge;
