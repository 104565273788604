import {
  setCookie as nextSetCookie,
  getCookie as nextGetCookie,
  deleteCookie as nextDeleteCookie,
} from "cookies-next";
import { OptionsType } from "cookies-next/lib/types";

import {
  IGetCookiesProps,
  ICookie,
  ISetCookiesProps,
  IDeleteCookie,
} from "app-domain/abstractions";

export const CookiesNext: ICookie = {
  setCookie({ name, value, options }: ISetCookiesProps): void {
    const formattedOptions = options as OptionsType | undefined;
    nextSetCookie(String(name), String(value), formattedOptions);
  },

  getCookie({ name, options }: IGetCookiesProps): string | undefined {
    const formattedOptions = options as OptionsType | undefined;
    return nextGetCookie(String(name), formattedOptions) as string;
  },

  deleteCookie({ name, options }: IDeleteCookie): void {
    const formattedOptions = options as OptionsType | undefined;
    nextDeleteCookie(String(name), formattedOptions);
  },
};
