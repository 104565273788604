import {
  useShallow,
  useTwoFactor,
  useUniqueClient,
} from "application/state-manager";
import { useAppContext, useAuth, useToast, useUser } from "application";
import { useCallback, useEffect, useState } from "react";
import {
  FATypeValues,
  TwoFactorContentPropsMap,
  TwoFactorPublicCodeDto,
  UniqueClientDTO,
} from "typing";
import { notificationApi } from "implementations";
import { EmailIcon, MessagesIcon, theme } from "ui";
import { useRouter } from "next/router";
import {
  maskEmail,
  maskPhoneNumber,
} from "../../../2fa/Content/useTwoFactorContent";
import { useUniqueClientUtils } from "../utils/data";

const useUniqueClientValidationData = (clientInfo?: UniqueClientDTO | null) => {
  const { isClientMobile } = useAppContext();
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;
  const [isLoading, setIsLoading] = useState(false);
  const { loggedClient } = useUser();
  const router = useRouter();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const {
    sendTwoFactorToken,
    needsToValidateEmail,
    needsToValidatePhoneNumber,
  } = useUniqueClientUtils();

  const shouldShowComponent =
    !isClientMobile ||
    (isClientMobile && router?.pathname?.includes("verificacao-identidade"));

  const {
    isRequesting,
    setIsTokenCodeValid,
    setIsValidatingCode,
    setIsValidationActive,
    setValidateCode,
    channelSelected,
    faType,
    tokenCode,
    setInitTimer,
    setChannelSelected,
  } = useTwoFactor(
    useShallow((state) => ({
      isRequesting: state.isRequesting,
      setIsTokenCodeValid: state.setIsTokenCodeValid,
      setIsValidatingCode: state.setIsValidatingCode,
      setValidateCode: state.setValidateCode,
      setIsValidationActive: state.setIsValidationActive,
      channelSelected: state.channelSelected,
      faType: state.faType,
      tokenCode: state.tokenCode,
      setInitTimer: state.setInitTimer,
      setChannelSelected: state.setChannelSelected,
    }))
  );

  const {
    changeUniqueClientStep,
    currentStep,
    setUniqueClientInfo,
    uniqueClientInfo,
  } = useUniqueClient(
    useShallow((state) => ({
      changeUniqueClientStep: state.changeUniqueClientStep,
      currentStep: state.currentStep,
      setUniqueClientInfo: state.setUniqueClientInfo,
      uniqueClientInfo: state.uniqueClientInfo,
    }))
  );

  useEffect(() => {
    if ((!loggedClient || !clientInfo) && isClientMobile) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [clientInfo, loggedClient, isClientMobile]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (isClientMobile && (clientInfo || uniqueClientInfo)) {
      setIsValidationActive(true);

      if (needsToValidateEmail && needsToValidatePhoneNumber && isFirstRender) {
        changeUniqueClientStep("chooseAnotherMethod");
        setIsLoading(false);
        return;
      }

      if (loggedClient && isFirstRender) {
        setIsLoading(true);
        setIsFirstRender(false);
        sendTwoFactorToken({
          channel: needsToValidatePhoneNumber ? 1 : 2,
          faType: needsToValidatePhoneNumber
            ? FATypeValues.AccountValidatePhone2FA
            : FATypeValues.AccountValidateEMail2FA,
          cpf: loggedClient?.cpf ?? "",
          email: loggedClient?.email ?? "",
          phoneNumber: loggedClient?.phoneNumber ?? "",
        });
        setIsLoading(false);
        changeUniqueClientStep("verifyIdentity");
      }
    }
  }, [
    isClientMobile,
    setUniqueClientInfo,
    uniqueClientInfo,
    clientInfo,
    setIsValidationActive,
    changeUniqueClientStep,
    needsToValidateEmail,
    sendTwoFactorToken,
    needsToValidatePhoneNumber,
    loggedClient,
    isFirstRender,
    setIsFirstRender,
  ]);

  const { addToast } = useToast();

  const clearTime = useCallback(() => {
    localStorage.removeItem(`timeSent_${faType}_${channelSelected}`);
    localStorage.removeItem(`countdownTime_${faType}_${channelSelected}`);
    localStorage.removeItem(
      `countdownTimeTimestamp_${faType}_${channelSelected}`
    );
  }, [faType, channelSelected]);

  const checkErrorAttempt = useCallback(
    (statusCode?: string[], messages?: string[]) => {
      if (statusCode?.includes("222")) {
        setIsValidationActive(false);
        changeUniqueClientStep("feedbackError");
        clearTime();
        return;
      }

      setIsTokenCodeValid(false);
      setValidateCode(true);
      addToast({
        isNewToast: true,
        newToastTheme: "dark",
        type: "error",
        description: `${messages?.[0]}`,
        title: "Ocorreu um erro ao validar o código",
        timerInMilliseconds: 3000,
      });
    },
    [
      setIsValidationActive,
      setIsTokenCodeValid,
      addToast,
      setValidateCode,
      changeUniqueClientStep,
      clearTime,
    ]
  );

  const handleSuccessValidation = useCallback(() => {
    setIsTokenCodeValid(true);
    setIsValidatingCode(true);
    changeUniqueClientStep("feedbackSuccess");
  }, [changeUniqueClientStep, setIsTokenCodeValid, setIsValidatingCode]);

  const handleCodeValidation = useCallback(
    async ({
      channel,
      faType: userFAType,
      cpf,
      email,
      phoneNumber,
      token,
    }: TwoFactorPublicCodeDto) => {
      const { isValid, errors } = await notificationApi.validateTwoFactorCode({
        channel,
        faType: userFAType,
        cpf,
        email,
        phoneNumber,
        token,
        jwt: accessToken,
      });

      if (!isValid) {
        checkErrorAttempt(errors?.statusCode, errors?.messages);
        return;
      }

      clearTime();

      handleSuccessValidation();
    },
    [accessToken, clearTime, checkErrorAttempt, handleSuccessValidation]
  );

  const canChooseAnotherMethod =
    needsToValidateEmail && needsToValidatePhoneNumber;

  const handleSelectAnotherTwoFactorType = () => {
    changeUniqueClientStep("chooseAnotherMethod");
  };

  const chooseMethods = (phoneNumber: string, email: string) => {
    const methods = [];

    if (phoneNumber) {
      methods.push({
        title: "SMS",
        description: maskPhoneNumber(String(phoneNumber)),
        handleOptionAction: () => {
          setInitTimer(true);
          setChannelSelected(1);

          if (isRequesting) {
            changeUniqueClientStep("verifyIdentity");
            return;
          }

          sendTwoFactorToken({
            channel: 1,
            cpf: String(loggedClient?.cpf),
            email: String(loggedClient?.email),
            faType: FATypeValues.AccountValidatePhone2FA,
            phoneNumber: String(loggedClient?.phoneNumber),
          });
        },
        icon: <MessagesIcon />,
        isValid: true,
      });
    }

    if (email) {
      methods.push({
        title: "E-mail",
        description: maskEmail(String(email)),
        handleOptionAction: () => {
          setInitTimer(true);
          setChannelSelected(2);

          if (isRequesting) {
            changeUniqueClientStep("verifyIdentity");
            return;
          }

          sendTwoFactorToken({
            channel: 2,
            cpf: String(loggedClient?.cpf),
            email: String(loggedClient?.email),
            faType: FATypeValues.AccountValidateEMail2FA,
            phoneNumber: String(loggedClient?.phoneNumber),
          });
        },
        icon: <EmailIcon />,
        isValid: true,
      });
    }

    return methods;
  };

  const getUniqueClientValidationData = (): TwoFactorContentPropsMap => {
    const contentMap: TwoFactorContentPropsMap = new Map();

    contentMap.set("clientAction", "loginValidation");
    contentMap.set("email", String(loggedClient?.email));
    contentMap.set("hasError", false);
    contentMap.set("isUnderAction", isRequesting);
    contentMap.set("onCallAction", () =>
      handleCodeValidation({
        channel: channelSelected,
        faType: Number(faType),
        cpf: String(loggedClient?.cpf),
        email: String(loggedClient?.email),
        phoneNumber: String(loggedClient?.phoneNumber),
        token: String(tokenCode),
      })
    );
    contentMap.set("phoneNumber", String(loggedClient?.phoneNumber));
    contentMap.set("sendCodeType", channelSelected === 1 ? "sms" : "email");
    contentMap.set("viewType", isClientMobile ? "component" : "modal");
    contentMap.set(
      "handleSelectAnotherTwoFactorType",
      handleSelectAnotherTwoFactorType
    );
    contentMap.set("isVerifyIdentity", currentStep === "chooseAnotherMethod");
    contentMap.set(
      "options",
      chooseMethods(
        String(loggedClient?.phoneNumber),
        String(loggedClient?.email)
      )
    );
    contentMap.set(
      "verifyIdentityDescription",
      "Para continuar, escolha um modo para autenticar a sua identidade."
    );
    contentMap.set("optionsBackgroundColor", `${theme.colors.neutral.white}`);
    contentMap.set("hasDescriptionOnMobile", true);
    contentMap.set(
      "verifyIdentityTitle",
      "Escolha como verificar sua identidade"
    );
    contentMap.set("preventDefault", true);
    contentMap.set("canChooseAnotherTwoFactorMethod", canChooseAnotherMethod);
    contentMap.set("isMobile", isClientMobile);
    return contentMap;
  };

  return {
    getUniqueClientValidationData,
    isLoading,
    shouldShowComponent,
    sendTwoFactorToken,
    handleCodeValidation,
    handleSelectAnotherTwoFactorType,
    setIsLoading,
    currentStep,
  };
};

export { useUniqueClientValidationData };
