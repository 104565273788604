import { ArrowPrev } from "./style";

type ArrowPrevButtonProps = {
  hasPrev: boolean;
  clickHandler: () => void;
  showArrows: boolean;
};

const ArrowPrevButton = ({
  hasPrev,
  clickHandler,
  showArrows,
}: ArrowPrevButtonProps) => {
  if (!showArrows) {
    return null;
  }

  return (
    <ArrowPrev
      aria-label="Voltar"
      hasPrev={hasPrev}
      onClick={(event) => {
        clickHandler();
        event?.stopPropagation();
        event?.preventDefault();
      }}
    />
  );
};

export { ArrowPrevButton };
