import { buildTestIds } from "application";
import { useEffect, useState } from "react";
import { UseFormSetValue, useWatch } from "react-hook-form";
import { NewAddressFormComponents } from "typing";
import { theme } from "ui";
import {
  CustomInputNewAddress,
  HouseData,
  WrapperInput,
} from "../../../styles";

interface NumberComponentProps extends NewAddressFormComponents {
  setValue: UseFormSetValue<HouseData>;
}

export const NumberComponent = ({
  control,
  disabled,
  hasError,
  requiredFieldText,
  setValue,
}: NumberComponentProps) => {
  const [number, selectNumber] = useState<string>("");

  const isNoNumberOptionChecked = useWatch({
    control,
    name: "noNumber",
  }) as boolean;

  useEffect(() => {
    if (isNoNumberOptionChecked) {
      selectNumber("");
      setValue("number", "");
    }
  }, [isNoNumberOptionChecked, setValue]);

  return (
    <WrapperInput>
      <CustomInputNewAddress
        id="number"
        name="number"
        type="tel"
        inputMode="numeric"
        placeholder="Ex. 1"
        control={control}
        disabled={disabled || Boolean(isNoNumberOptionChecked)}
        hasSuccessfully={
          (!!number && !hasError) || Boolean(isNoNumberOptionChecked)
        }
        hasError={hasError}
        onChange={(e) => {
          const formattedValue = e?.target?.value.replace(/\D/g, "");
          selectNumber(formattedValue);
          setValue("number", formattedValue);
        }}
        value={number}
        required={isNoNumberOptionChecked ? undefined : requiredFieldText}
        width="100%"
        margin={`${theme.space.x2} ${theme.space.x0}`}
        maxLength={6}
        label="Número"
        {...buildTestIds("form-input-number")}
      />
    </WrapperInput>
  );
};
