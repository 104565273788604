const invalidZipCodeList = [
  "00000-000",
  "11111-111",
  "22222-222",
  "33333-333",
  "44444-444",
  "55555-555",
  "66666-666",
  "77777-777",
  "88888-888",
  "99999-999",
  "12345-678",
];

export const validateZipCode = (zipCode: string): string => {
  const invalidZipCodeMessage = "CEP inválido";

  const isZipCodeInvalid = invalidZipCodeList.find(
    (invalidZipCode) => zipCode === invalidZipCode
  );

  if (zipCode?.length < 9 || isZipCodeInvalid) return invalidZipCodeMessage;

  return "";
};

export type BranchLocationProps = {
  city: string;
  zipCode: string;
};

export interface LocationProps {
  city: string;
  zipCode: string;
}
