import { NewAddressFormComponents } from "typing";
import { theme } from "ui";
import { CustomInputNewAddress, WrapperInput } from "../../../styles";

type ReferenceComponentProps = NewAddressFormComponents;

export const ReferenceComponent = ({
  control,
  hasError,
}: ReferenceComponentProps) => {
  return (
    <WrapperInput fully>
      <CustomInputNewAddress
        id="referencePoint"
        name="referencePoint"
        type="text"
        hasError={hasError}
        control={control}
        margin={`${theme.space.x2} ${theme.space.x0}`}
        width="100%"
        label="Referência"
        placeholder="Ex. Próximo a Ferreira Costa"
        showOptionalText
      />
    </WrapperInput>
  );
};
