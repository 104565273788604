import { DevicePasswordChanged } from "ui";
import { buildTestIds } from "application";
import { ModalGeneral } from "../ModalGeneral";
import { ButtonConfirmModalPasswordChanged } from "./styles";

interface ModalPasswordChangedProps {
  isOpen: boolean;
  ariaHideApp?: boolean;
  onCloseModal(): void;
}

const ModalPasswordChanged = ({
  isOpen,
  ariaHideApp,
  onCloseModal,
}: ModalPasswordChangedProps) => {
  return (
    <ModalGeneral
      ariaHideApp={ariaHideApp}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      ariaLabelForModal="Senha alterada com sucesso"
      title="Senha alterada com sucesso"
      bodyDescription="Sua senha e seu e-mail foram confirmados com sucesso! Agora você pode aproveitar as ofertas em nosso site com segurança."
      illustration={<DevicePasswordChanged />}
      bodyContent={
        <ButtonConfirmModalPasswordChanged
          {...buildTestIds("button-confirm-modal-password-changed")}
          onClick={onCloseModal}
        >
          Fechar
        </ButtonConfirmModalPasswordChanged>
      }
    />
  );
};

export type { ModalPasswordChangedProps };

export { ModalPasswordChanged };
