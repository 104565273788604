import styled from "styled-components";
import { ArrowLeft, Button, FlexContainer, theme } from "ui";

export const TitleInformation = styled.span`
  text-align: center;
  font-size: ${theme.typography.fontSize.sm.x9};
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.colors.neutral["550"]} !important;
`;

export const ContentInformationText = styled.p`
  text-align: start;
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.neutral["550"]} !important;
`;

export const ChangePasswordFeedbackContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x4};
  max-width: 360px;
  margin: 0 auto;

  @media ${theme.device.desktopFirst.mobileXL} {
    max-width: 100%;
    padding: ${theme.space.x0} ${theme.space.x6};
  }
`;

export const WrapperIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-25"]};
`;

export const ContentInformation = styled(FlexContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.space["x2.5"]};
`;

export const ChangePasswordFeedbackInformation = styled(ContentInformation)`
  gap: ${theme.space.x6};
`;

export const ArrowLeftIcon = styled(ArrowLeft).attrs({
  height: "15px",
  width: "15px",
  color: theme.colors.neutral["600"],
})``;

export const GoToLoginButton = styled(Button)`
  display: flex;
  height: 45px;
  padding: ${theme.space.x2} ${theme.space.x4};
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: ${theme.space.x2};
  background-color: ${theme.colors.secondary["380"]};
  font-size: ${theme.typography.fontSize.sm.x7};
  color: ${theme.colors.neutral.white} !important;
  transition: background-color 0.5s ease-in-out;

  :hover {
    background-color: ${theme.colors.secondary["390"]};
  }
`;

export const TitleInformationContainer = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

export const ReturnWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px ${theme.colors.neutral["999-opacity-25"]};
`;
