import { IconProps } from "typing";
import { theme } from "../themes";

export const Truck = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["550"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M10 4L8 2L6 4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00002 11.3333V2"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12L8 14L10 12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00002 4.66663V14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
