import { memo } from "react";
import { buildTestIds } from "application";
import { RecommendationTypeProps } from "typing";
import {
  ImageProduct,
  RecommendationDefaultProductImageContainer,
} from "./styles";

const mockedRecommendationType: RecommendationTypeProps[] = [
  {
    recommendationTypeAbbreviation: "mp",
    recommendationTypeText: "Mais comprados",
  },
  {
    recommendationTypeAbbreviation: "hd",
    recommendationTypeText: "Desconto imperdível",
  },
  {
    recommendationTypeAbbreviation: "tp",
    recommendationTypeText: "Em alta",
  },
];
interface DynamicProductCardImageProps {
  imageUrl: string;
  description: string;
  preloadComponent: boolean;
}

const DynamicRecommendationProductCardVerticalImage = memo(
  ({
    imageUrl,
    description,
    preloadComponent,
  }: DynamicProductCardImageProps) => {
    return imageUrl ? (
      <ImageProduct
        {...buildTestIds("product-card-dynamic-image")}
        src={imageUrl}
        alt={description}
        width={170}
        height={170}
        objectFit="contain"
        priority={preloadComponent}
      />
    ) : (
      <RecommendationDefaultProductImageContainer />
    );
  }
);

DynamicRecommendationProductCardVerticalImage.displayName =
  "DynamicRecommendationProductCardVerticalImage";

export {
  DynamicRecommendationProductCardVerticalImage,
  mockedRecommendationType,
};
