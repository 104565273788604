import { useState, useCallback } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { DevicePasswordChange, theme } from "ui";
import {
  // useAppContext,
  useToast,
  validatePassword,
  validateConfirmPassword,
  buildTestIds,
} from "application";
import {
  profileApi,
  appMonitoringClient,
  identityApi,
  cookie,
} from "implementations";
import { ChangePasswordDto, IFormValues } from "typing";
import { ModalGeneral } from "../ModalGeneral";
import { Label, Input } from "../Form";
import { PasswordValidation } from "../PasswordValidation";
import {
  ButtonConfirmModalPasswordChange,
  ChangePasswordForm,
  EyeCloseIcon,
  EyeOpenIcon,
  InputWrapper,
  PasswordValidationWrapper,
} from "./styles";

interface ModalPasswordChangeProps {
  isOpen: boolean;
  ariaHideApp?: boolean;
  onCloseModal(): void;
  onBeforeCloseModalAction(): void;
}

type ChangePasswordFormValues = {
  password: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type TypePassword = "text" | "password";

const ModalPasswordChange = ({
  isOpen,
  ariaHideApp,
  onBeforeCloseModalAction,
  onCloseModal,
}: ModalPasswordChangeProps) => {
  const { addToast } = useToast();
  const accessToken = cookie.getCookie({
    name: "@FC:Ecom:Token:Access",
  });

  const { data, error: profileError } = profileApi.getClient(
    accessToken,
    !!accessToken
  );
  const [changingPassword, setChangingPassword] = useState<boolean>(false);
  const { register, handleSubmit, setValue, watch } =
    useForm<ChangePasswordFormValues>();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [currentPasswordType, setCurrentPasswordType] =
    useState<TypePassword>("password");
  const [newPasswordType, setNewPasswordType] =
    useState<TypePassword>("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState<TypePassword>("password");

  const currentPasswordWatcher = watch("currentPassword");
  const newPasswordWatcher = watch("newPassword");
  const confirmNewPasswordWatcher = watch("confirmNewPassword");

  if (profileError) {
    appMonitoringClient.captureException(profileError);
  }

  const changePasswordVisibility = (type: TypePassword) => {
    return type === "password" ? "text" : "password";
  };

  const changePasswordIcon = (type: TypePassword) => {
    return type === "password" ? <EyeCloseIcon /> : <EyeOpenIcon />;
  };

  const isInvalidNewPassword = validatePassword(newPasswordWatcher);
  const isInvalidConfirmPassword = validateConfirmPassword(
    newPasswordWatcher,
    confirmNewPasswordWatcher
  );

  const isFormInvalid =
    !currentPasswordWatcher ||
    isInvalidNewPassword ||
    isInvalidConfirmPassword ||
    !newPasswordWatcher ||
    !confirmNewPasswordWatcher ||
    passwordStrength !== 5;

  const resetForm = useCallback(() => {
    setValue("password", "");
    setValue("currentPassword", "");
    setCurrentPasswordType("password");
    setValue("newPassword", "");
    setNewPasswordType("password");
    setValue("confirmNewPassword", "");
    setConfirmNewPasswordType("password");
  }, [setValue]);

  const onSaveNewPassword: SubmitHandler<ChangePasswordFormValues> = async ({
    currentPassword,
    newPassword,
  }) => {
    if (accessToken) {
      setChangingPassword(true);
      const changePasswordBody: ChangePasswordDto = {
        email: data?.email || "",
        currentPassword,
        newPassword,
      };

      const { error } = await identityApi.changePassword(
        accessToken,
        changePasswordBody
      );

      setChangingPassword(false);

      if (error) {
        addToast({
          title: "Erro ao tentar alterar senha",
          description: error,
          type: "error",
          newToastTheme: "light",
          isNewToast: true,
        });
        return;
      }

      resetForm();
      onCloseModal();
      onBeforeCloseModalAction();
    }
  };

  const isNewPasswordValid = newPasswordWatcher?.length > 0;

  const verifyNewPassword = isInvalidNewPassword && isNewPasswordValid;

  const isConfirmPasswordValid = confirmNewPasswordWatcher?.length > 0;
  const verifyConfirmPassword =
    isInvalidConfirmPassword && isConfirmPasswordValid;

  return (
    <ModalGeneral
      ariaHideApp={ariaHideApp}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      ariaLabelForModal="Alterar senha"
      title="Alterar senha"
      bodyDescription="Percebemos que sua senha pode ser mais segura. Vamos atualizá-la para garantir a proteção de sua conta?"
      illustration={<DevicePasswordChange />}
      bodyContent={
        <>
          <ChangePasswordForm
            {...buildTestIds("change-password-form")}
            onSubmit={handleSubmit(onSaveNewPassword)}
          >
            <InputWrapper {...buildTestIds("current-password-input-wrapper")}>
              <Label
                htmlFor="currentPassword"
                text="Senha atual"
                color={theme.colors.neutral["750"]}
                fontSize={theme.typography.fontSize.sm.x7}
                typeFontWeight={theme.typography.fontWeight[400]}
              />
              <Input
                id="current-password"
                name="currentPassword"
                type={currentPasswordType}
                placeholder="Digite sua senha"
                register={register as unknown as UseFormRegister<IFormValues>}
                disabled={changingPassword}
                hasError={
                  validatePassword(currentPasswordWatcher) &&
                  currentPasswordWatcher?.length > 0
                }
                textError={validatePassword(currentPasswordWatcher)}
                width="100%"
                {...buildTestIds("current-password-input")}
                onChange={({ target }) =>
                  setValue("currentPassword", target?.value)
                }
                height="45px"
                padding="1rem"
                margin={`${theme.space["x2.5"]} 0px 0px 0px`}
                hasIcon
                onClickIcon={() =>
                  setCurrentPasswordType(
                    changePasswordVisibility(currentPasswordType)
                  )
                }
                icon={changePasswordIcon(currentPasswordType)}
              />
            </InputWrapper>
            <InputWrapper {...buildTestIds("new-password-input-wrapper")}>
              <Label
                htmlFor="new-password"
                text="Senha"
                color={theme.colors.neutral["750"]}
                fontSize={theme.typography.fontSize.sm.x7}
                typeFontWeight={theme.typography.fontWeight[400]}
              />
              <Input
                id="new-password"
                name="newPassword"
                type={newPasswordType}
                placeholder="Digite sua senha"
                register={register as unknown as UseFormRegister<IFormValues>}
                disabled={changingPassword}
                hasError={verifyNewPassword}
                hasSuccessfully={isNewPasswordValid && !verifyNewPassword}
                width="100%"
                {...buildTestIds("new-password-input")}
                onChange={({ target }) =>
                  setValue("newPassword", target?.value)
                }
                height="45px"
                padding="1rem"
                hasIcon
                paddingInfo="0px"
                paddingErrorMessage="0px"
                onClickIcon={() =>
                  setNewPasswordType(changePasswordVisibility(newPasswordType))
                }
                icon={changePasswordIcon(newPasswordType)}
                fontSizeInfo={theme.typography.fontSize.sm.x3}
                colorInfo={theme.colors.neutral["550"]}
                textError={isInvalidNewPassword}
                margin={`${theme.space["x2.5"]} 0px 0px 0px`}
              />
            </InputWrapper>
            <InputWrapper
              {...buildTestIds("confirm-new-password-input-wrapper")}
            >
              <Label
                htmlFor="confirm-new-password"
                text="Confirme a senha"
                color={theme.colors.neutral["750"]}
                fontSize={theme.typography.fontSize.sm.x7}
                typeFontWeight={theme.typography.fontWeight[400]}
              />
              <Input
                id="confirm-new-password"
                name="confirmNewPassword"
                type={confirmNewPasswordType}
                placeholder="Digite sua senha"
                register={register as unknown as UseFormRegister<IFormValues>}
                disabled={changingPassword}
                hasError={verifyConfirmPassword}
                hasSuccessfully={
                  isConfirmPasswordValid && !verifyConfirmPassword
                }
                textError={isInvalidConfirmPassword}
                width="100%"
                {...buildTestIds("confirm-new-password-input")}
                onChange={({ target }) =>
                  setValue("confirmNewPassword", target?.value)
                }
                hasIcon
                paddingInfo="0px"
                paddingErrorMessage="0px"
                onClickIcon={() =>
                  setConfirmNewPasswordType(
                    changePasswordVisibility(confirmNewPasswordType)
                  )
                }
                icon={changePasswordIcon(confirmNewPasswordType)}
                fontSizeInfo={theme.typography.fontSize.sm.x3}
                colorInfo={theme.colors.neutral["550"]}
                height="45px"
                padding="1rem"
                margin={`${theme.space["x2.5"]} 0px 0px 0px`}
              />
            </InputWrapper>
          </ChangePasswordForm>
          <PasswordValidationWrapper
            {...buildTestIds("password-validation-wrapper")}
          >
            <PasswordValidation
              showComponent={isNewPasswordValid}
              userConfirmPassword={confirmNewPasswordWatcher}
              userPassword={newPasswordWatcher}
              onChangePasswordStrength={(strength) =>
                setPasswordStrength(strength)
              }
            />
          </PasswordValidationWrapper>
          <ButtonConfirmModalPasswordChange
            {...buildTestIds("change-password-button")}
            isDisabled={!!isFormInvalid}
            text="Confirmar"
            textUnderAction="Confirmando..."
            underAction={changingPassword}
            onClickAction={handleSubmit(onSaveNewPassword)}
          />
        </>
      }
    />
  );
};

export type { ModalPasswordChangeProps };

export { ModalPasswordChange };
