import styled from "styled-components";
import { theme } from "../themes";

export interface DeskProps {
  hasRightBorder?: boolean;
  alignItems?: string;
  gap?: string;
  padding?: string;
  margin?: string;
  justifyContent?: string;
}

export const Desk = styled.div<DeskProps>`
  display: flex;

  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => `align-items: ${alignItems || "center"};`}
  ${({ gap }) => gap && `gap: ${gap};`}

  ${({ hasRightBorder }) => `
      ${
        hasRightBorder
          ? `border-right: 1px solid ${theme.colors.neutral["750"]}`
          : ""
      };
      width: ${theme.space["x1/1"]};
  `};

  padding: ${({ padding }) => padding || `0 ${theme.space.x6}`};
  margin: ${({ margin }) => margin || `0`};

  @media ${theme.device.desktopFirst.laptop} {
    max-height: ${theme.space["x1/1"]};
    border: none;
    width: ${theme.space["x1/1"]};
    padding: 0 ${theme.space.x4};
  }

  @media ${theme.device.desktopFirst.tablet} {
    padding: 0 ${theme.space.x1};
    ${({ alignItems }) => `align-items: ${alignItems || "flex-start"};`}
  }
`;

export const DeskInfo = styled.div``;
