import { ReactResponsiveCarousel } from "infra/components";
import { CarouselProps } from "typing";
import { ArrowNextButton } from "./ArrowNextButton";
import { ArrowPrevButton } from "./ArrowPrevButton";

const Carousel = ({
  children,
  showArrows,
  // showIndicatorsBelowCarousel,
  ...props
}: CarouselProps) => {
  return (
    <ReactResponsiveCarousel
      {...props}
      showArrows={showArrows}
      transitionTime={400}
      interval={3500}
      renderArrowPrev={(clickHandler, hasPrev) => (
        <ArrowPrevButton
          hasPrev={hasPrev}
          clickHandler={clickHandler}
          showArrows={!!showArrows}
        />
      )}
      renderArrowNext={(clickHandler, hasNext) => (
        <ArrowNextButton
          hasNext={hasNext}
          clickHandler={clickHandler}
          showArrows={!!showArrows}
        />
      )}
    >
      {children}
    </ReactResponsiveCarousel>
  );
};

export { Carousel };
