import styled from "styled-components";
import { Button, theme } from "ui";

interface SidebarGiftListFilterProps {
  openMobile?: boolean;
  width?: string;
}

export const SidebarGiftFilter = styled.div<SidebarGiftListFilterProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || "100%"};
  align-items: center;
  justify-content: center;
  gap: 10px 0;

  @media ${theme.device.desktopFirst.tablet} {
    position: absolute;
    width: 100%;
    height: 350%;
    left: 0;
    padding: 15px;
    margin-top: -20px;
    z-index: 5;
    background-color: ${theme.colors.neutral["50"]};
    align-items: start;
    justify-content: start;
    transform: ${({ openMobile }) =>
      openMobile ? "translateX(0)" : "translateX(-100%)"};
    transition: 0.2s ease-in-out;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    position: absolute;
    width: 100%;
    height: 350%;
    left: 0;
    padding: 15px;
    margin-top: -20px;
    z-index: 5;
    background-color: ${theme.colors.neutral["50"]};
    align-items: start;
    justify-content: start;
    transform: ${({ openMobile }) =>
      openMobile ? "translateX(0)" : "translateX(-100%)"};
    transition: 0.2s ease-in-out;
  }
`;

export const BackButton = styled(Button)`
  padding: 0;
  border-radius: 50%;
  background-color: ${theme.colors.neutral.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 1rem;
`;
