import { ICookie } from "app-domain";
import { useRouter } from "next/router";
import { useState } from "react";
import { useBranchLocation } from "../../context/BranchLocationContext";
import { useWebSocket } from "../../context/WebSocket";
import { ShippingApi } from "../../services";
import { useCurrentPathName } from "../currentPathName";
import { setIsPageReloadByZipCodeSearch } from "./pageReload";

const cookieBranchLocationStr = "@FC:Ecom:Dropdown:BranchLocation";
const cookieRegionStr = "@FC:Ecom:Dropdown:Region";
const cookieOptionsMaxAgeEqualOneYear = {
  options: {
    maxAge: 60 * 60 * 24 * 365,
  },
};
const otherRegionStr = "Demais Regiões";

// const handleChangeClientLocation = (
//   cookie: ICookie,
//   clientLocation: string,
//   setRegionClientLocation: Dispatch<SetStateAction<string | null>>
// ) => {
//   if (clientLocation !== otherRegionStr) {
//     cookie.setCookie({
//       name: cookieRegionStr,
//       value: clientLocation,
//       options: cookieOptionsMaxAgeEqualOneYear.options,
//     });

//     setRegionClientLocation(clientLocation);
//   }
// };

// const handleIsToReloadPage = (
//   isToReloadPage: boolean,
//   currentPathname: string,
//   clientLocation: Omit<LocationProps, "clientBranchId"> & {
//     clientBranchId: string;
//   },
//   setIsToReloadPage: Dispatch<SetStateAction<boolean>>,
//   setIsReloadingPage: Dispatch<SetStateAction<boolean>>,
//   emitEvent: (
//     event: WebSocketEventAction,
//     metadata: WebSocketEventMetadata
//   ) => void,
//   router: NextRouter
// ) => {
//   if (isToReloadPage) {
//     setIsToReloadPage(false);

//     localStorage?.setItem("isPageReloadByZipCodeSearch", "true");
//     router.replace(currentPathname, undefined, { shallow: true });

//     emitEvent("branchChanged", clientLocation);

//     router.reload();
//     setIsReloadingPage(true);
//   }
// };

const useValidateClientBranchChange = (
  searchedZipCode: string | null,
  shippingApi: ShippingApi,
  cookie: ICookie
) => {
  const { setRegionClientLocation, isChangingClientLocation } =
    useBranchLocation();

  const [isToReloadPage, setIsToReloadPage] = useState(false);
  const [isReloadingPage, setIsReloadingPage] = useState(false);

  const currentPathname = useCurrentPathName();

  const router = useRouter();
  const { emitEvent } = useWebSocket();

  const shouldDoRequests =
    !!searchedZipCode && !isReloadingPage && !isChangingClientLocation;

  const { data: branchIdByZipCode } = shippingApi.getBranchIdByZipCode(
    searchedZipCode as string,
    shouldDoRequests
  );

  if (branchIdByZipCode?.branchId) {
    const lastClientBranchId = cookie.getCookie({
      name: cookieBranchLocationStr,
    });

    if (String(branchIdByZipCode?.branchId) !== lastClientBranchId) {
      setIsToReloadPage(true);
      cookie.setCookie({
        name: cookieBranchLocationStr,
        value: String(branchIdByZipCode.branchId),
        options: cookieOptionsMaxAgeEqualOneYear.options,
      });
    }
  }

  const { data: addressByZipCode, isLoading: isLoadingAddressByZipCode } =
    shippingApi.getAddressByZipCode(
      searchedZipCode as string,
      shouldDoRequests
    );

  if (shouldDoRequests && !isLoadingAddressByZipCode) {
    let currentClientLocation = otherRegionStr;

    if (addressByZipCode?.city && addressByZipCode?.uf) {
      currentClientLocation = `${addressByZipCode.city} - ${addressByZipCode.uf}`;
      cookie.setCookie({
        name: cookieRegionStr,
        value: currentClientLocation,
        options: cookieOptionsMaxAgeEqualOneYear.options,
      });

      setRegionClientLocation(currentClientLocation);
    } else {
      cookie.deleteCookie({
        name: cookieRegionStr,
      });
    }

    if (isToReloadPage) {
      setIsPageReloadByZipCodeSearch(true);
      router.replace(currentPathname, undefined, { shallow: true });
      emitEvent("branchChanged", {
        ...(branchIdByZipCode?.branchId && {
          clientBranchId: branchIdByZipCode?.branchId,
        }),
        ...(currentClientLocation && { clientLocation: currentClientLocation }),
        clientPostalCode: searchedZipCode,
      });
      setTimeout(() => {
        router.reload();
      }, 250);
      setIsReloadingPage(true);
    }
  }

  return {
    branchIdByZipCode,
    addressByZipCode,
    isToReloadPage,
    isChangingClientLocation,
  };
};

export { useValidateClientBranchChange };
