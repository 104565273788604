import { theme, Text, FlexContainer } from "ui";
import { memo } from "react";
import { Link } from "../../../Link";
import { useFormFooter } from "./useFormFooter";

const FormFooter = memo(() => {
  const { isClientMobile, hideFooter } = useFormFooter();

  if (hideFooter) {
    return null;
  }

  return (
    <FlexContainer
      display="inline-flex"
      alignItems="center"
      gap={theme.space.x1}
      justifyContent="center"
    >
      <Text
        margin={`${theme.space.x0}`}
        fontSize={theme.typography.fontSize.sm.x5}
        color={theme.colors.neutral["550"]}
        fontWeight={theme.typography.fontWeight[400]}
      >
        Já tem uma conta?
      </Text>

      <Text
        margin={`${theme.space.x0}`}
        fontSize={theme.typography.fontSize.sm.x5}
        fontWeight={theme.typography.fontWeight[500]}
      >
        <Link
          href={isClientMobile ? "/login" : "/"}
          target="_self"
          fontSize={theme.typography.fontSize.sm.x5}
          color={theme.colors.neutral["550"]}
          data-cy="link-sign-in"
          textDecoration="underline !important"
        >
          Entrar
        </Link>
      </Text>
    </FlexContainer>
  );
});

FormFooter.displayName = "FormFooter";

export default FormFooter;
