import { IApi } from "app-domain/abstractions/apis/IApi";
import { NextApiRequestCookies } from "next/dist/server/api-utils";
import { NextIncomingMessage } from "next/dist/server/request-meta";
import { EnvsApi } from "../../services";

const setEnvByServer = async (
  envKey: string,
  envClient: EnvsApi,
  apiClient: IApi,
  request: NextIncomingMessage & {
    cookies: NextApiRequestCookies;
  }
) => {
  try {
    const actualEnv = envClient.getRawEnvImmediately(envKey);
    const storedEnv = request.cookies[`${envKey}`];
    if (!actualEnv && storedEnv) {
      return Promise.resolve(() => {
        apiClient.setBaseUrl(storedEnv);
      });
    }
    return Promise.resolve();
  } catch (error) {
    return Promise.resolve();
  }
};

export { setEnvByServer };
