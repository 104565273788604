import { theme } from "ui";
import { buildTestIds, useUser } from "application";
import {
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalFooterButton,
  BaseModalFooterText,
  BaseModalHeader,
  BaseModalHeaderClose,
  BaseModalHeaderTitle,
  CloseIcon,
  ConfirmPaymentBody,
  ConfirmPaymentFieldSet,
  ConfirmPaymentFieldSetLegend,
  ConfirmPaymentHeader,
} from "./style";
import { WhatsAppOption } from "../WhatsAppOption";

export interface ConfirmPaymentModalProps {
  width: string;
  height: string;
  footerGap?: string;
  footerText?: string;
  title: string;
  buttonText: string;
  buttonTextUnderAction: string;
  buttonUnderAction?: boolean;
  hasFooterShadow?: boolean;
  isFormInvalid?: boolean;
  buttonSize?: string | { minWidth: string; maxWidth: string };
  isOpen: boolean;
  onCloseModal: () => void;
  onClickOnButton?: () => void;
  buttonHoverColor?: string;
  onClickAlternativeButton?: () => void;
  modalButtonPadding?: string;
  modalButtonColor?: string;
  fontWeight?: number;
  textDecoration?: string;
  form?: string;
  alternativeButtonText?: string;
  border?: string;
  modalText?: string;
  isDoingPayment?: boolean;
}

const ConfirmPaymentModal = ({
  width,
  height,
  footerGap = "1rem",
  title,
  buttonText,
  alternativeButtonText,
  buttonTextUnderAction,
  buttonUnderAction,
  form,
  buttonSize,
  border,
  onCloseModal,
  onClickOnButton,
  onClickAlternativeButton,
  buttonHoverColor = theme.colors.secondary["500"],
  hasFooterShadow,
  modalButtonPadding,
  textDecoration,
  isFormInvalid = false,
  modalButtonColor = theme.colors.secondary["265"],
  footerText,
  modalText,
  isOpen,
  isDoingPayment,
  ...props
}: ConfirmPaymentModalProps) => {
  const { acceptedWhatsapp } = useUser();

  return (
    <BaseModal
      {...buildTestIds("confirm-payment-modal")}
      {...props}
      styles={{
        content: {
          gap: "16px",
          padding: "0px",
          maxWidth: width,
          minHeight: height ?? "fit-content",
        },
      }}
      onCloseModal={onCloseModal}
      contentLabel={title}
      isOpen={isOpen}
    >
      <BaseModalHeader
        {...buildTestIds("confirm-payment-modal-header")}
        justifyContent="center"
      >
        <BaseModalHeaderTitle
          {...buildTestIds("confirm-payment-modal-header-title")}
        >
          {title}
        </BaseModalHeaderTitle>
        <BaseModalHeaderClose
          {...buildTestIds("confirm-payment-modal-header-close")}
          onClick={onCloseModal}
        >
          <CloseIcon />
        </BaseModalHeaderClose>
      </BaseModalHeader>
      <BaseModalBody
        {...buildTestIds("confirm-payment-modal-body")}
        backgroundColor="white"
      >
        <ConfirmPaymentBody {...buildTestIds("confirm-payment-body")}>
          <ConfirmPaymentHeader {...buildTestIds("confirm-payment-header")}>
            {modalText}
          </ConfirmPaymentHeader>
          {!acceptedWhatsapp && (
            <ConfirmPaymentFieldSet
              {...buildTestIds("confirm-payment-field-set")}
            >
              <ConfirmPaymentFieldSetLegend
                {...buildTestIds("confirm-payment-field-set-legend")}
              >
                Para acompanhar seu pedido em tempo real via Whatsapp selecione
                a opção abaixo:
              </ConfirmPaymentFieldSetLegend>
              <WhatsAppOption
                {...buildTestIds("whats-app-option")}
                isSavingWhatsAppOption={!!isDoingPayment}
                shallUseToast={false}
              />
            </ConfirmPaymentFieldSet>
          )}
        </ConfirmPaymentBody>
      </BaseModalBody>
      <BaseModalFooter
        {...buildTestIds("confirm-payment-modal-footer")}
        gap={footerGap}
        hasFooterShadow={hasFooterShadow}
        padding="16px"
      >
        <BaseModalFooterButton
          {...buildTestIds("confirm-payment-modal-footer-button")}
          buttonType="button"
          text={buttonText}
          textUnderAction={buttonTextUnderAction}
          underAction={buttonUnderAction}
          color={modalButtonColor}
          hoveredColor={buttonHoverColor}
          minWidth={
            typeof buttonSize === "string" ? buttonSize : buttonSize?.minWidth
          }
          maxWidth={
            typeof buttonSize === "string" ? buttonSize : buttonSize?.maxWidth
          }
          onClickAction={onClickOnButton}
          form={form}
          border={border}
          height={theme.space.x12}
          fontWeight={400}
        />
        {onClickAlternativeButton && (
          <BaseModalFooterButton
            {...buildTestIds("confirm-payment-modal-footer-alternative-button")}
            text={alternativeButtonText}
            isDisabled={isFormInvalid}
            textUnderAction=""
            color="white"
            contentColor="black"
            hoveredColor="white"
            minWidth={
              typeof buttonSize === "string" ? buttonSize : buttonSize?.minWidth
            }
            maxWidth={
              typeof buttonSize === "string" ? buttonSize : buttonSize?.maxWidth
            }
            onClickAction={onCloseModal}
            padding={modalButtonPadding}
            buttonType="button"
            fontWeight={700}
            textDecoration={textDecoration}
          />
        )}
        {!!footerText && (
          <BaseModalFooterText
            {...buildTestIds("confirm-payment-modal-footer-text")}
            onClick={onCloseModal}
          >
            {footerText}
          </BaseModalFooterText>
        )}
      </BaseModalFooter>
    </BaseModal>
  );
};

export { ConfirmPaymentModal };
