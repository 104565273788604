import styled, { keyframes } from "styled-components";

type Size = "small" | "medium" | "large" | "extraLarge";

export interface LoadingContainerProps {
  color?: string;
  size?: Size;
  type?: "dotted" | "dashed";
}

const rotateKeyframes = keyframes`
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
`;

const getWidthOrHeight = (size: Size = "medium") => {
  return {
    small: "1rem",
    medium: "1.5rem",
    large: "2rem",
    extraLarge: "10rem",
  }[`${size}`];
};

export const LoadingContainer = styled.div<LoadingContainerProps>`
  color: ${({ color }) => color || "currentColor"};
  display: inline-block;
  width: ${({ size }) => getWidthOrHeight(size)};
  height: ${({ size }) => getWidthOrHeight(size)};
  vertical-align: -0.125em;
  border: 0.25em ${({ type }) => type ?? "solid"} currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: ${rotateKeyframes}
    ${({ type }) => (type === "dotted" ? "1s" : "0.75s")} linear infinite;
  animation: ${rotateKeyframes}
    ${({ type }) => (type === "dotted" ? "1s" : "0.75s")} linear infinite;
`;
