import {
  ComponentDto,
  GridPlaylistsSubComponentDto,
  SliderProps,
} from "typing";
import { buildTestIds, useAppContext } from "application";
import { memo, useEffect, useState } from "react";
import CustomGridPlaylistsCard from "../GridPlaylistsCard";
import { CustomGridPlaylistsContent } from "../styles";
import { Slider } from "../../../Slider";

interface GridPlaylistsSliderProps {
  subComponents: ComponentDto[];
  positionComponent: number | undefined;
  isBlackFriday?: boolean;
}

const GridPlaylistsSlider = memo(
  ({
    subComponents,
    positionComponent,
    isBlackFriday,
  }: GridPlaylistsSliderProps) => {
    const { isClientMobile, isClientTablet, isClientLaptop } = useAppContext();

    const [slidesToShow, setSlidesToShow] = useState(6);
    const [slidesToScroll, setSlidesToScroll] = useState(1);

    useEffect(() => {
      setSlidesToShow(
        isClientMobile ? 3 : isClientTablet ? 4 : isClientLaptop ? 5 : 9
      );
      setSlidesToScroll(
        isClientMobile ? 18 : isClientTablet ? 16 : isClientLaptop ? 3 : 8
      );
    }, [isClientLaptop, isClientMobile, isClientTablet]);

    const settings: SliderProps = {
      speed: 800,
      slidesToShow,
      slidesToScroll,
      arrowsIsActive: !isClientMobile && !isClientTablet,
      touchMove: true,
      swipe: true,
      dots: isClientMobile || isClientTablet,
      isBlackTheme: isBlackFriday,
    };

    return (
      <Slider {...settings}>
        {subComponents?.map((subComponent, index) => {
          const componentKey = `${subComponent.type}#${index}`;

          return (
            <CustomGridPlaylistsContent
              {...buildTestIds("custom-grid-playlists-content")}
              key={componentKey}
              isSliderActive
            >
              <CustomGridPlaylistsCard
                {...buildTestIds("custom-grid-playlists-card")}
                {...(subComponent as GridPlaylistsSubComponentDto)}
                positionComponent={positionComponent}
                index={index + 1}
              />
            </CustomGridPlaylistsContent>
          );
        })}
      </Slider>
    );
  }
);

GridPlaylistsSlider.displayName = "GridPlaylistsSlider";

export default GridPlaylistsSlider;
