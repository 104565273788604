const obfuscate = (value = "", sizeHexaDecimal = 16) => {
  if (typeof value !== "string") return "";

  if (!value) return "value obfuscate is empty";

  let obfuscated = "";

  for (let index = 0; index < value?.length; index += 1) {
    obfuscated += `\\u00${value?.charCodeAt(index)?.toString(sizeHexaDecimal)}`;
  }

  return obfuscated;
};

function obfuscateText(data: string | number): string {
  if (typeof data === "number") {
    return "*".repeat(data.toString().length);
  }

  return data.replace(/./g, "*");
}

const obfuscateObject = (
  data: Record<string | number, string | number | boolean>
) => {
  const obfuscatedObject = {} as Record<
    string | number,
    string | number | boolean
  >;
  Object.keys(data).forEach((key) => {
    obfuscatedObject[`${key}`] = String(data[`${key}`]).replace(/./g, "*");
  });
  return obfuscatedObject;
};

export { obfuscate, obfuscateObject, obfuscateText };
