import { IconProps } from "typing";

const FailurePaperAirplaneIcon = ({
  alt = "Avião de papel indicativo de falha no envio",
  width = 114,
  height = 120,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 114 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="64.089" cy="44.7497" r="33.3371" fill="#F6F7F7" />
      <path
        opacity="0.17"
        d="M68.9593 30.3633L81.1205 40.798L43.3056 113.388L8.5862 71.4977L68.9593 30.3633Z"
        fill="url(#paint0_linear_2964_180087)"
      />
      <path
        opacity="0.17"
        d="M70.2229 31.8124L80.1924 39.4362L42.418 100.434L22.6763 70.6404L70.2229 31.8124Z"
        fill="url(#paint1_linear_2964_180087)"
      />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.0006 54.6136C80.0335 54.6136 79.249 55.3458 79.249 56.2488C79.249 57.1519 80.0335 57.8836 81.0006 57.8836H85.8179C86.7855 57.8836 87.57 57.1514 87.57 56.2488C87.57 55.3462 86.7855 54.614 85.8184 54.614C85.8184 53.3723 84.7399 52.3657 83.4095 52.3657C82.0791 52.3657 81.0006 53.3719 81.0006 54.6136Z"
          stroke="#A0A2A4"
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.1256 62.0101C71.1585 62.0101 70.374 62.7423 70.374 63.6453C70.374 64.5483 71.1585 65.2801 72.1256 65.2801H76.9429C77.9105 65.2801 78.695 64.5479 78.695 63.6453C78.695 62.7427 77.9105 62.0105 76.9434 62.0105C76.9434 60.7688 75.8649 59.7622 74.5345 59.7622C73.2041 59.7622 72.1256 60.7684 72.1256 62.0101Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.7701 71.6253C60.803 71.6253 60.0186 72.3575 60.0186 73.2605C60.0186 74.1636 60.803 74.8953 61.7701 74.8953H66.5875C67.555 74.8953 68.3395 74.1632 68.3395 73.2605C68.3395 72.3579 67.555 71.6258 66.5879 71.6258C66.5879 70.384 65.5094 69.3774 64.179 69.3774C62.8486 69.3774 61.7701 70.3836 61.7701 71.6253Z"
          stroke="#B2B3B5"
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.2213 52.201C36.2542 52.201 35.4697 52.9332 35.4697 53.8362C35.4697 54.7393 36.2542 55.471 37.2213 55.471H42.0386C43.0062 55.471 43.7907 54.7388 43.7907 53.8362C43.7907 52.9336 43.0062 52.2014 42.0391 52.2014C42.0391 50.9597 40.9606 49.9531 39.6302 49.9531C38.2998 49.9531 37.2213 50.9593 37.2213 52.201Z"
          stroke="#B2B3B5"
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.9478 27.2469C65.9808 27.2469 65.1963 27.9791 65.1963 28.8821C65.1963 29.7852 65.9808 30.5169 66.9478 30.5169H71.7652C72.7327 30.5169 73.5172 29.7847 73.5172 28.8821C73.5172 27.9795 72.7327 27.2473 71.7657 27.2473C71.7657 26.0056 70.6872 24.999 69.3568 24.999C68.0263 24.999 66.9478 26.0052 66.9478 27.2469Z"
          stroke="#B2B3B5"
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.8775 29.2723C42.9105 29.2723 42.126 30.0045 42.126 30.9075C42.126 31.8106 42.9105 32.5423 43.8775 32.5423H48.6949C49.6624 32.5423 50.4469 31.8101 50.4469 30.9075C50.4469 30.0049 49.6624 29.2727 48.6954 29.2727C48.6954 28.031 47.6169 27.0244 46.2864 27.0244C44.956 27.0244 43.8775 28.0306 43.8775 29.2723Z"
          stroke="#8F9194"
          strokeWidth="0.369819"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.4166 48.6966C50.4495 48.6966 49.665 49.4288 49.665 50.3318C49.665 51.2349 50.4495 51.9666 51.4166 51.9666H56.234C57.2015 51.9666 57.986 51.2344 57.986 50.3318C57.986 49.4292 57.2015 48.697 56.2344 48.697C56.2344 47.4553 55.1559 46.4487 53.8255 46.4487C52.4951 46.4487 51.4166 47.4549 51.4166 48.6966Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.5618 40.8408C86.8526 40.8408 86.2773 41.3778 86.2773 42.04C86.2773 42.7022 86.8526 43.2388 87.5618 43.2388H91.0945C91.8041 43.2388 92.3794 42.7019 92.3794 42.04C92.3794 41.3781 91.8041 40.8411 91.0949 40.8411C91.0949 39.9305 90.304 39.1924 89.3284 39.1924C88.3527 39.1924 87.5618 39.9302 87.5618 40.8408Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.136 21.416C53.4268 21.416 52.8516 21.953 52.8516 22.6152C52.8516 23.2774 53.4268 23.814 54.136 23.814H57.6688C58.3783 23.814 58.9536 23.2771 58.9536 22.6152C58.9536 21.9533 58.3783 21.4163 57.6691 21.4163C57.6691 20.5057 56.8782 19.7676 55.9026 19.7676C54.9269 19.7676 54.136 20.5054 54.136 21.416Z"
          fill="#A0A2A4"
        />
      </g>
      <path
        d="M49.3686 56.4628L35.6853 70.5633"
        stroke="#040405"
        strokeWidth="0.814966"
        strokeLinecap="round"
      />
      <path
        d="M58.1514 63.9951L45.9126 78.4398"
        stroke="#040405"
        strokeWidth="0.814966"
        strokeLinecap="round"
      />
      <path
        d="M50.0322 64.0561L36.667 79.0215"
        stroke="#040405"
        strokeWidth="0.814966"
        strokeLinecap="round"
      />
      <path
        d="M57.0531 54.3178L57.1362 38.3002L87.8605 22.4101L86.0504 30.1826L57.0531 54.3178Z"
        fill="url(#paint2_linear_2964_180087)"
        stroke="#040405"
        strokeWidth="1.53792"
      />
      <path
        d="M89.7103 18.3486C90.6075 18.024 91.1758 19.2999 90.3346 19.7498L57.0936 37.5274C56.9897 37.583 56.8743 37.6139 56.7565 37.6178L39.2579 38.1979C38.3826 38.2269 38.1473 37.0042 38.9708 36.7062L89.7103 18.3486Z"
        fill="#AA0E27"
        stroke="#040405"
        strokeWidth="1.53792"
      />
      <path
        d="M90.6864 20.2966C91.016 19.5538 90.1183 18.8741 89.4928 19.3927L62.8962 41.4433C62.6374 41.6578 62.548 42.0155 62.6753 42.3266L70.7415 62.0331C70.9981 62.66 71.8812 62.6729 72.156 62.0537L90.6864 20.2966Z"
        fill="#F6F7F7"
        stroke="#040405"
        strokeWidth="1.53792"
      />
      <path
        d="M57.4941 55.0433L62.519 42.1871C62.5253 42.171 62.5311 42.1632 62.5338 42.1599C62.5367 42.1565 62.5394 42.1542 62.5426 42.1522C62.5499 42.1476 62.5651 42.1418 62.5866 42.1424C62.6081 42.143 62.6229 42.1497 62.6299 42.1546C62.633 42.1568 62.6356 42.1593 62.6383 42.1629C62.6408 42.1662 62.6462 42.1744 62.6516 42.1909L62.6608 42.2189L62.6721 42.2462L65.5828 49.2608L66.4326 51.8548L57.4941 55.0433Z"
        fill="#AA0E27"
        stroke="#040405"
        strokeWidth="1.53792"
      />
      <circle
        cx="82.3154"
        cy="72"
        r="11.25"
        fill="#AA0E27"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M82.3161 72.501L82.3161 67.4863"
        stroke="#F6F7F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.3148 76.0624C82.2041 76.0624 82.1148 76.1517 82.1161 76.2624C82.1161 76.373 82.2054 76.4624 82.3161 76.4624C82.4268 76.4624 82.5161 76.373 82.5161 76.2624C82.5161 76.153 82.4268 76.0624 82.3148 76.0624"
        stroke="#F6F7F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2964_180087"
          x1="73.9948"
          y1="30.9133"
          x2="31.6407"
          y2="100.731"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.419792" stopColor="#AA0E27" />
          <stop offset="1" stopColor="#AA0E27" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2964_180087"
          x1="73.9946"
          y1="30.9137"
          x2="31.6404"
          y2="100.731"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.51875" stopColor="#AA0E27" />
          <stop offset="1" stopColor="#AA0E27" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2964_180087"
          x1="68.8443"
          y1="29.1715"
          x2="76.4187"
          y2="47.7555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E6E6" />
          <stop offset="1" stopColor="#8F9194" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { FailurePaperAirplaneIcon };
