import { buildTestIds } from "application";
import { useMemo } from "react";
import { useController } from "react-hook-form";
import FieldError from "../FieldError";
import { FieldInfo } from "../FieldInfo";
import Label from "../Label";
import { MaskedInput, PrincipalInput } from "./components";
import { IconContainer, InputContainer, StyledInput } from "./style";
import { NewInputProps } from "./type";

export const NewCustomInput = (props: Omit<NewInputProps, "register">) => {
  const {
    name,
    autoComplete,
    backgroundColor,
    borderColor,
    borderRadius,
    borderWidth,
    color,
    colorInfo,
    cursor,
    fontSize,
    fontSizeInfo,
    hasError,
    hasIcon,
    hasIconBorder,
    hasSuccessfully,
    icon,
    inputMode,
    label,
    margin,
    mask,
    maskChar,
    onClickIcon,
    padding,
    paddingErrorMessage,
    paddingInfo,
    textError,
    textInfo,
    type,
    width,
    control,
    defaultValue,
    required,
    rules,
    shouldUnregister,
    disabled,
    maxLength,
    minLength,
    onBlur,
    onChange,
    onFocus,
    onKeyUp,
    onKeyDown,
    onMouseOut,
    onMouseOver,
    pattern,
    placeholder,
    role,
    value,
    height,
    min,
    id,
    max,
    ref,
    showOptionalText,
  } = props;

  const requiredText = useMemo(() => {
    return typeof required === "string" ? required : "Campo obrigatório";
  }, [required]);

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules: {
      ...rules,
      maxLength: maxLength
        ? { value: maxLength, message: "Máximo de caracteres excedido" }
        : undefined,
      minLength: minLength
        ? { value: minLength, message: "Mínimo de caracteres não atingido" }
        : undefined,
      required: { value: required as boolean, message: requiredText },
      onChange,
      onBlur,
      max: max ? { value: max, message: "Valor máximo excedido" } : undefined,
      min: min
        ? { value: min, message: "Valor mínimo não atingido" }
        : undefined,
      shouldUnregister,
    },
    shouldUnregister,
  });

  const hasSuccessState = useMemo(
    () => hasSuccessfully || Boolean(field.value),
    [field.value, hasSuccessfully]
  );

  const hasErrorState = useMemo(
    () =>
      Boolean(
        (hasError as unknown as boolean) ||
          Boolean(fieldState.error || fieldState.invalid)
      ) && !hasSuccessState,
    [fieldState.error, fieldState.invalid, hasError, hasSuccessState]
  );

  const errorMessageText = useMemo(
    () =>
      textError ??
      fieldState.error?.root?.message ??
      fieldState.error?.message ??
      requiredText,
    [
      fieldState.error?.message,
      fieldState.error?.root?.message,
      requiredText,
      textError,
    ]
  );

  return (
    <StyledInput
      hasSuccessfully={hasSuccessState}
      hasError={hasErrorState}
      width={width}
      height={height}
      hasIcon={hasIcon}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderColor={borderColor}
      padding={padding}
      margin={margin}
      color={color}
      fontSize={fontSize}
      backgroundColor={backgroundColor}
      hasIconBorder={hasIconBorder}
      {...buildTestIds(`form-styled-input-${id}`)}
      isDisabled={disabled}
    >
      {label && (
        <Label
          text={label}
          isRequired={Boolean(required)}
          hasSuccessfully={hasSuccessState}
          htmlFor={id}
          showOptionalText={showOptionalText}
          typeFontWeight={400}
          {...buildTestIds(`form-input-label-${id}`)}
        />
      )}

      <InputContainer width={width} hasIcon={hasIcon}>
        {mask ? (
          <MaskedInput
            {...buildTestIds(`form-input-mask-${id}`)}
            mask={mask}
            id={id}
            type={type}
            maskChar={maskChar}
            inputMode={inputMode}
            autoComplete={autoComplete}
            {...props}
            ref={ref}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onMouseOut={onMouseOut}
            onBlur={onBlur}
            onMouseOver={onMouseOver}
            min={min}
            max={max}
            required={Boolean(required)}
            disabled={disabled}
            placeholder={placeholder}
            role={role}
            value={value}
            pattern={pattern}
            defaultValue={defaultValue}
            field={field}
            key={id}
          />
        ) : (
          <PrincipalInput
            field={field}
            id={id}
            type={type}
            inputMode={inputMode}
            autoComplete={autoComplete}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onMouseOut={onMouseOut}
            onBlur={onBlur}
            onMouseOver={onMouseOver}
            min={min}
            onFocus={onFocus}
            minLength={minLength}
            maxLength={maxLength}
            max={max}
            required={Boolean(required)}
            disabled={disabled}
            placeholder={placeholder}
            role={role}
            value={value}
            pattern={pattern}
            key={id}
            ref={ref}
            {...buildTestIds(`form-input-${id}`)}
          />
        )}

        {hasIcon && (
          <IconContainer
            cursor={cursor}
            hasSuccessfully={hasSuccessfully}
            margin={margin}
            hasError={hasError as unknown as boolean}
            onClick={onClickIcon}
            {...buildTestIds(`form-icon-container-${id}`)}
          >
            {icon}
          </IconContainer>
        )}
      </InputContainer>

      {errorMessageText && hasErrorState && (
        <FieldError
          text={errorMessageText}
          padding={paddingErrorMessage}
          fontSize={fontSizeInfo}
          {...buildTestIds(`form-field-error-input-${id}`)}
        />
      )}

      {textInfo && (
        <FieldInfo
          padding={paddingInfo}
          text={textInfo as string}
          fontSize={fontSizeInfo}
          color={colorInfo}
          {...buildTestIds(`form-field-info-input-${id}`)}
        />
      )}
    </StyledInput>
  );
};
