import { NewAddressFormComponents } from "typing";
import { theme } from "ui";
import { CustomInputNewAddress, WrapperInput } from "../../../styles";

type ComplementComponentProps = NewAddressFormComponents;

export const ComplementComponent = ({
  control,
  hasError,
}: ComplementComponentProps) => {
  return (
    <WrapperInput fully>
      <CustomInputNewAddress
        id="addressComplement"
        name="addressComplement"
        type="text"
        hasError={hasError}
        control={control}
        margin={`${theme.space.x2} ${theme.space.x0}`}
        width="100%"
        placeholder="Apartamento, bloco, edifício, andar, etc"
        label="Complemento"
        showOptionalText
      />
    </WrapperInput>
  );
};
