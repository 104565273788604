import styled from "styled-components";
import { theme } from "ui";
import { Countdown } from "../CodeExpiration/styles";

interface ModalValidationSMSContainerProps {
  stateModal: boolean;
}

export const ModalValidationSMSContainer = styled.div<ModalValidationSMSContainerProps>`
  display: ${({ stateModal }) => (stateModal ? "flex" : "none")};
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 2;
  background-color: ${theme.colors.neutral["999-opacity-50"]};

  justify-content: center;
  align-items: center;

  ${Countdown} {
    text-align: center;
  }
`;

export const ModalValidationSMSWrapper = styled.section`
  position: fixed;
  top: 20%;
  width: 550px;
  background-color: ${theme.colors.neutral.white};
  border-radius: 2px;
  padding: 1.3rem 0 3rem;

  @media ${theme.device.desktopFirst.mobileXL} {
    width: 400px;
  }
`;

export const ModalValidationSMSHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalValidationText = styled.span`
  text-align: center;
  font-size: ${theme.typography.fontSize.sm.x5};
  font-family: ${theme.typography.fontFamily.barlow};
`;

export const ModalValidationTitle = styled.span`
  font-size: 2rem;
  text-align: center;
  color: ${theme.colors.neutral["570"]};
  font-weight: ${theme.typography.fontWeight["500"]};
  padding-left: 4.5rem;
  font-family: ${theme.typography.fontFamily.barlow};

  @media ${theme.device.desktopFirst.mobileXL} {
    padding-left: 0;
  }
`;

export const ModalValidationContent = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  gap: 2rem;
  padding: 0 4.5rem;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: 0 1.5rem;
  }
`;

export const ModalValidationButton = styled.button`
  height: 40px;
  background-color: ${theme.colors.secondary["380"]};
  border: 1px solid ${theme.colors.secondary["380"]};
  color: ${theme.colors.neutral.white};
  justify-content: center;
  font-size: ${theme.typography.fontSize.sm.x5};
  border-radius: 8px;
  font-family: ${theme.typography.fontFamily.barlow};
  margin-bottom: 2rem;
  font-weight: ${theme.typography.fontWeight["500"]};
`;

export const ButtonCLoseModal = styled.div`
  cursor: pointer;
`;

interface ContainerModalProps {
  stateModal: boolean;
}
export const ContainerModal = styled.div<ContainerModalProps>`
  display: ${({ stateModal }) => (stateModal ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;

  z-index: 999999;
  position: fixed;

  background-color: ${theme.colors.neutral["820"]};
`;
