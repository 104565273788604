import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface CountdownProps {
  timeInSeconds: number;
  timeFormatted: string;
  hasFinishedCountdown: boolean;
  changeTimeInSeconds: (value: number) => void;
  changeHasFinishedCountdown: (value: boolean) => void;
  changeConditionToStartCountdown: (value: boolean) => void;
}

export interface CountdownProviderProps {
  children: ReactNode;
}

const CountdownContext = createContext({} as CountdownProps);

export const CountdownProvider = ({ children }: CountdownProviderProps) => {
  const [timeInSeconds, setTimeInSeconds] = useState<number>(60);
  const [hasFinishedCountdown, setHasFinishedCountdown] =
    useState<boolean>(false);
  const [conditionToStartCountdown, setConditionToStartCountdown] =
    useState<boolean>(false);

  const timeFormatted = useMemo(() => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    const secondsFormmated = seconds < 10 ? `0${seconds}` : seconds;

    return `${minutes}:${secondsFormmated}`;
  }, [timeInSeconds]);

  const changeTimeInSeconds = useCallback((value: number) => {
    setTimeInSeconds(value);
  }, []);

  const changeHasFinishedCountdown = useCallback((value: boolean) => {
    setHasFinishedCountdown(value);
  }, []);

  const changeConditionToStartCountdown = useCallback((value: boolean) => {
    setConditionToStartCountdown(value);
  }, []);

  useMemo(() => {
    if (timeInSeconds > 0 && conditionToStartCountdown) {
      setTimeout(() => {
        setTimeInSeconds(timeInSeconds - 1);
      }, 1000);
    } else if (timeInSeconds === 0) {
      setHasFinishedCountdown(true);
    }
  }, [timeInSeconds, conditionToStartCountdown]);

  const providerMemo = useMemo(() => {
    return {
      timeInSeconds,
      hasFinishedCountdown,
      timeFormatted,
      changeTimeInSeconds,
      changeHasFinishedCountdown,
      changeConditionToStartCountdown,
    };
  }, [
    hasFinishedCountdown,
    timeInSeconds,
    timeFormatted,
    changeTimeInSeconds,
    changeHasFinishedCountdown,
    changeConditionToStartCountdown,
  ]);

  return (
    <CountdownContext.Provider value={providerMemo}>
      {children}
    </CountdownContext.Provider>
  );
};

export const useCountdown = () => {
  const context = useContext(CountdownContext);

  if (!context) {
    throw new Error("useToast must be used within an CountdownProvider");
  }

  return context;
};
