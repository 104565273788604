import { Layout, CustomCardCTAProperty } from "typing";

const customCardCTAData = (properties: CustomCardCTAProperty[]) => {
  const layout = (properties.find(({ key }) => key === "layout") as Layout)
    ?.value;

  return {
    layout,
  };
};

export { customCardCTAData };
