import { ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import {
  UseFormRegister,
  UseFormSetValue,
  ValidationRule,
} from "react-hook-form";
import { IFormValues } from "typing";
import { buildTestIds } from "application";
import { InputContainer, SearchIcon, Input } from "./styles";

interface InputSearchProps {
  placeholder?: string;
  register: UseFormRegister<IFormValues>;
  setValue?: UseFormSetValue<IFormValues>;
  name: string;
  required?: string | boolean;
  minLength?: ValidationRule<number>;
  maxLength?: ValidationRule<number>;
  onBlur?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement, Element>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputSearch = ({
  placeholder,
  register,
  setValue,
  name,
  required,
  minLength,
  maxLength,
  onBlur,
  onFocus,
  onKeyDown,
  onChange,
  ...props
}: InputSearchProps) => {
  return (
    <InputContainer data-testid="input-container" data-cy="input-container">
      <SearchIcon />
      <Input
        onFocus={onFocus}
        type="search"
        placeholder={placeholder}
        {...setValue}
        {...register(name, {
          required,
          minLength,
          maxLength,
        })}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onChange={onChange}
        {...buildTestIds(`input-search-${name}`)}
        {...props}
      />
    </InputContainer>
  );
};

export { InputSearch };
