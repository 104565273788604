import { getClientBranchId } from "application";
import { appMonitoringClient, cookie } from "implementations";
import { BranchDto, ProductCardDto } from "typing";

const calculateSlidesToShow = (
  isClientDesktop?: boolean,
  isClientMobile?: boolean,
  arrowsIsActive?: boolean
) => {
  if (arrowsIsActive && isClientDesktop) {
    return 5;
  }

  if (!arrowsIsActive && isClientDesktop) {
    return 6;
  }

  if (isClientMobile) {
    return 3.3;
  }

  return 5;
};

const handleComponentWarnings = (
  branches: BranchDto[] | undefined,
  currentPathName: string,
  position: number | undefined,
  productCards: ProductCardDto[]
) => {
  if (!branches?.length) {
    appMonitoringClient.captureMessage(
      `Um item de Lista de Produtos, localizado em ${currentPathName} na posição ${position}, não possui nenhuma configuração de branches.`,
      "warning"
    );
  }
  const branchId = getClientBranchId(cookie);

  if (!productCards?.length) {
    appMonitoringClient.captureMessage(
      `Uma Lista de Produtos, cuja filial é ${branchId}, não tem produtos.`,
      "warning"
    );
  }
};

const showArrows = (quantity: number) => {
  return !!(Number(quantity) >= 6);
};

export { handleComponentWarnings, calculateSlidesToShow, showArrows };
