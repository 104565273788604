import { SendTokenResponseDto } from "typing";
import { notificationApi, profileApi } from "implementations";
import { useAuth, useNotification } from "application";
import { useEffect, useState } from "react";
import { SendCode } from "./styles";
import ModalValidationSMS from "../../ModalValidationSMS";

interface ValidationButtonProps {
  actionButtonValidation: () => void;
  typeSMS: 1 | 2 | 3;
  width?: string;
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
}

const ValidationButton = ({
  actionButtonValidation,
  typeSMS,
  backgroundColor,
  color,
  fontSize,
  width,
}: ValidationButtonProps) => {
  const { changeIsUserBlockedFromReceiveMessages } = useNotification();
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token as string;
  const { data } = profileApi.getClient(accessToken, !!accessToken);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabledButton] = useState(false);

  const onSendSMS = async (type: 1 | 2 | 3) => {
    setIsButtonDisabledButton(true);
    const response = (await notificationApi.sendTwoFactorSMS(
      type,
      accessToken
    )) as unknown as SendTokenResponseDto;

    if (response?.validationResult?.errors?.[0]?.errorCode === "222") {
      setIsModalOpen(true);
      setIsUserBlocked(true);
      setIsButtonDisabledButton(false);
      return;
    }

    actionButtonValidation();
  };

  useEffect(() => {
    changeIsUserBlockedFromReceiveMessages(isUserBlocked);
  }, [isUserBlocked, changeIsUserBlockedFromReceiveMessages]);

  if (!data?.phoneNumber) {
    return null;
  }

  const numberValidation = `(${data?.phoneNumber.substring(
    0,
    2
  )}) *****-${data?.phoneNumber.substring(7, 11)}`;

  return (
    <>
      <ModalValidationSMS
        stateModal={isModalOpen}
        actionCloseModal={() => setIsModalOpen(false)}
        title="Infelizmente, você está bloqueado :("
        description="Seus créditos foram bloqueados temporariamente devido a tentativas excessivas de validação do código de segurança. Entre em contato com o suporte para regularizar a situação."
      />
      <SendCode
        color={color}
        backgroundColor={backgroundColor}
        fontSize={fontSize}
        width={width}
        disabled={isButtonDisabled}
        type="button"
        onClick={() => {
          onSendSMS(typeSMS);
        }}
      >
        Enviar código SMS para {numberValidation}
      </SendCode>
    </>
  );
};

export { ValidationButton };
