/* eslint-disable class-methods-use-this */
import { CallbackType, IEventEmitter } from "application";

class DocumentEventListener implements IEventEmitter {
  emit(event: string, data?: unknown) {
    const customEvent = new CustomEvent(event, {
      detail: data,
    });
    document.dispatchEvent(customEvent);
  }

  subscribe(event: string, callback: CallbackType) {
    document.addEventListener(event, callback);
  }

  unsubscribe(event: string, callback: CallbackType) {
    document.removeEventListener(event, callback);
  }
}

export { DocumentEventListener };
