import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const VerySatisfiedFaceIcon = ({
  alt = "Ícone de Avaliação - Muito Satisfeito",
  width = 40,
  height = 40,
  color = theme.colors.secondary[250],
  secondColor = theme.colors.secondary[425],
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle
        cx="20"
        cy="20"
        r="19"
        fill={color}
        stroke={secondColor}
        strokeWidth="2"
      />
      <path
        d="M14.595 24L26.5856 24C28.1141 24 29.0282 25.6186 28.1055 26.8371C26.4201 29.063 23.8097 31.8149 21.1064 31.9909C18.1336 32.1844 15.0846 29.2605 13.1242 26.8955C12.1235 25.6883 13.027 24 14.595 24Z"
        fill={color}
        stroke={secondColor}
        strokeWidth="2"
      />
      <path
        d="M9.11804 16.8969L12.285 15.0685C13.2416 14.5162 14.4647 14.8439 15.017 15.8005L16.8455 18.9675"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.1544 18.9667L24.9828 15.7998C25.5351 14.8432 26.7583 14.5155 27.7149 15.0678L30.8818 16.8962"
        stroke={secondColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
