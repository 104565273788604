import { obfuscateText } from "../obfuscate";

export interface Breadcrumb {
  type?: string;
  level?: "fatal" | "error" | "warning" | "log" | "info" | "debug";
  event_id?: string;
  category?: string;
  message?: string;
  data?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  timestamp?: number;
}

export interface User {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  id?: string;
  ip_address?: string;
  email?: string;
  username?: string;
  geo?: {
    country_code?: string;
    region?: string;
    city?: string;
  };
}

export const anonymizeUserData = (
  data: Record<string | number, string | number>,
  user: User,
  cookie: Record<string, string>,
  headers: Record<string, string>
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const anonymizedData = data;
  const anonymizedUserData = user;
  const anonymizedCookies = cookie;
  const anonymizedHeaders = headers;

  const dataParams = [
    "login",
    "password",
    "email",
    "token",
    "cardNumber",
    "idToken",
    "refreshToken",
    "accessToken",
    "cvv",
    "holderCPF",
    "holderEmail",
    "number",
    "fcx_labs_user_access_token",
  ];

  const userParams = ["email", "username"];

  const cookiesParams = ["@FC:Ecom:Token:Access", "@FC:Ecom:Token:Refresh"];

  const headersParams = ["Authorization"];

  if (anonymizedData) {
    dataParams.forEach((param) => {
      if (typeof anonymizedData[`${param}`] === "string") {
        anonymizedData[`${param}`] = obfuscateText(data[`${param}`]);
      }
    });
  }

  if (anonymizedUserData) {
    userParams.forEach((param) => {
      if (typeof anonymizedUserData[`${param}`] === "string") {
        anonymizedUserData[`${param}`] = obfuscateText(user[`${param}`]);
      }
    });
  }

  if (anonymizedCookies) {
    cookiesParams.forEach((param) => {
      if (typeof anonymizedCookies[`${param}`] === "string") {
        anonymizedCookies[`${param}`] = obfuscateText(cookie[`${param}`]);
      }
    });
  }

  if (anonymizedHeaders) {
    headersParams.forEach((param) => {
      if (typeof anonymizedHeaders[`${param}`] === "string") {
        anonymizedHeaders[`${param}`] = obfuscateText(headers[`${param}`]);
      }
    });
  }

  return {
    anonymizedData,
    anonymizedUserData,
    anonymizedCookies,
    anonymizedHeaders,
  };
};
