import { UseFormRegister } from "react-hook-form";
import { IFormValues } from "typing";
import { theme } from "ui";
import {
  AlignWrapperInput,
  CustomCheckboxNewAddress,
  CustomLabelNewAddress,
  WrapperCheckBox,
} from "../../../styles";

interface NoNumberComponentProps {
  isCheckedEvent?: (isChecked?: boolean) => void;
  register: UseFormRegister<IFormValues>;
}

export const NoNumberComponent = ({
  isCheckedEvent,
  register,
}: NoNumberComponentProps) => {
  return (
    <WrapperCheckBox>
      <AlignWrapperInput>
        <CustomCheckboxNewAddress
          id="noNumber"
          color={theme.colors.secondary["300"]}
          name="noNumber"
          register={register}
          isCheckedEvent={(isChecked) => {
            if (isCheckedEvent) isCheckedEvent(isChecked);
          }}
          heightChecked="8px"
          widthChecked="4px"
          left="1px"
          sizeChecked="1em"
          height={theme.space.x4}
          width={theme.space.x4}
          borderRadius={theme.space.x1}
        />
        <CustomLabelNewAddress htmlFor="noNumber" text="Sem número" />
      </AlignWrapperInput>
    </WrapperCheckBox>
  );
};
