import { getClientBranchId, getComponentTheme } from "application";
import { appMonitoringClient, cookie } from "implementations";
import {
  BlackFridayProductListDto,
  BranchDto,
  CtaText,
  CustomProductListProperty,
  Orientation,
  ProductCardDto,
  Quantity,
  ShowArrows,
  ShowCta,
  ShowTitle,
  Title,
  VisibleItemsQuantity,
} from "typing";
import { blackFridayProductListTheme } from "ui";

export const getBlackFridayProductListTheme = (isBlackTheme?: boolean) => {
  return isBlackTheme
    ? {
        key: "blackFridayTheme",
        value: JSON.stringify(blackFridayProductListTheme),
      }
    : undefined;
};

const getPlaylistId = (branches?: BranchDto[]) => {
  const currentBranchId =
    cookie.getCookie({
      name: "@FC:Ecom:Dropdown:BranchLocation",
    }) || "2";

  return branches?.find(({ id }) => id === currentBranchId)?.linkId;
};

const customProductListData = (
  properties: CustomProductListProperty[],
  isBlackTheme: boolean
) => {
  const title = (properties?.find(({ key }) => key === "title") as Title)
    ?.value;

  const showTitle = (
    properties?.find(({ key }) => key === "showTitle") as ShowTitle
  )?.value;

  const showCta = (properties?.find(({ key }) => key === "showCta") as ShowCta)
    ?.value;

  const ctaText = (properties?.find(({ key }) => key === "ctaText") as CtaText)
    ?.value;

  const quantity =
    (properties?.find(({ key }) => key === "quantity") as Quantity)?.value ??
    "10";

  const orientation = (
    properties?.find(({ key }) => key === "orientation") as Orientation
  )?.value;

  const showArrows = (
    properties?.find(({ key }) => key === "showArrows") as ShowArrows
  )?.value;

  const visibleItemsQuantity = (
    properties?.find(
      ({ key }) => key === "visibleItemsQuantity"
    ) as VisibleItemsQuantity
  )?.value;

  const blackFridayTheme = getBlackFridayProductListTheme(isBlackTheme);

  const productListBlackFridayTheme =
    getComponentTheme<BlackFridayProductListDto>(blackFridayTheme);

  return {
    title,
    showTitle,
    showCta,
    ctaText,
    quantity,
    orientation,
    showArrows,
    visibleItemsQuantity,
    productListBlackFridayTheme,
  };
};

const handleComponentWarnings = (
  branches: BranchDto[] | undefined,
  currentPathName: string,
  position: number | undefined,
  productCards: ProductCardDto[]
) => {
  if (!branches?.length) {
    appMonitoringClient.captureMessage(
      `Um item de Lista de Produtos, localizado em ${currentPathName} na posição ${position}, não possui nenhuma configuração de branches.`,
      "warning"
    );
  }

  const playlistId = getPlaylistId(branches);
  const branchId = getClientBranchId(cookie);

  if (!productCards?.length) {
    appMonitoringClient.captureMessage(
      `Uma Lista de Produtos, cuja playlist é ${playlistId} e cuja filial é ${branchId}, não tem produtos.`,
      "warning"
    );
  }
};

const calculateSlidesToShow = (
  isVertical: boolean,
  isCrmProductListVertical?: boolean,
  isClientDesktop?: boolean,
  isClientMobile?: boolean,
  arrowsIsActive?: boolean
) => {
  if (isCrmProductListVertical) {
    return 10;
  }

  if (arrowsIsActive && isClientDesktop) {
    return 5;
  }

  if (!arrowsIsActive && isClientDesktop) {
    return 6;
  }

  if (isClientMobile && isVertical) {
    return 3.3;
  }

  return 5;
};

const setIsVertical = (
  isClientMobile: boolean,
  isCrmProductListVertical?: boolean,
  orientation?: "horizontal" | "vertical"
) => {
  return !!(
    (isClientMobile && orientation && orientation === "vertical") ||
    isCrmProductListVertical
  );
};

const slidesToShowValueIncrement = (value: number) => {
  return value + 4;
};

const setShouldRenderButtonViewMore = (
  isVertical: boolean,
  productsCards: ProductCardDto[],
  slidesToShow: number,
  isCrmProductListVertical?: boolean
) => {
  return (
    isVertical &&
    !isCrmProductListVertical &&
    productsCards?.length > slidesToShow &&
    productsCards?.length !== 3
  );
};

export {
  setIsVertical,
  getPlaylistId,
  customProductListData,
  handleComponentWarnings,
  calculateSlidesToShow,
  slidesToShowValueIncrement,
  setShouldRenderButtonViewMore,
};
