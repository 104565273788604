import { IconProps } from "typing";
import { theme } from "../themes";

export const ShoppingCartIcon = ({
  width = 20,
  height = 20,
  color = theme.colors.neutral["760"],
  alt = "ícone de carrinho de compras",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17419 11.8723L4.89502 5.83398H15.4167C15.9592 5.83398 16.3567 6.34315 16.225 6.86982L15.1017 11.3632C14.9309 12.0457 14.3509 12.5473 13.6509 12.6173L7.97085 13.1857C7.12419 13.2698 6.35002 12.704 6.17419 11.8723Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.89484 5.83398L4.35317 3.33398H2.9165"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2573 16.057C14.089 16.057 13.9523 16.1936 13.954 16.362C13.954 16.5303 14.0906 16.667 14.259 16.667C14.4273 16.667 14.564 16.5303 14.564 16.362C14.5631 16.1936 14.4265 16.057 14.2573 16.057"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.24746 16.0566C7.07912 16.0566 6.94246 16.1932 6.94412 16.3616C6.94246 16.5307 7.07996 16.6674 7.24829 16.6674C7.41662 16.6674 7.55329 16.5307 7.55329 16.3624C7.55329 16.1932 7.41662 16.0566 7.24746 16.0566"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
