import styled, { css } from "styled-components";
import { theme } from "ui";
import {
  GridPlaylistsCardStyled,
  GridPlaylistsDescription,
  GridPlaylistsImage,
} from "./GridPlaylistsCard/styles";

interface CustomGridPlaylistsContentProps {
  isSliderActive?: boolean;
  customTheme?: boolean;
}

export const CustomGridPlaylistsContent = styled.div<CustomGridPlaylistsContentProps>`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  flex-basis: 33%;
  justify-content: center;
  gap: ${theme.space.x2};

  ${({ isSliderActive }) =>
    isSliderActive
      ? css`
          display: grid;
          grid-auto-flow: column;
          justify-content: start;
          grid-template-rows: repeat(2, auto);

          @media ${theme.device.desktopFirst.tablet} {
            grid-template-rows: repeat(4, auto);
          }

          @media ${theme.device.desktopFirst.mobileXL} {
            grid-template-rows: repeat(6, auto);
          }
        `
      : null}

  @media ${theme.device.desktopFirst.mobileXL} {
    ${GridPlaylistsCardStyled} {
      width: 103px;
    }
    ${GridPlaylistsImage} {
      padding: 0 19px 8px;
    }
    ${GridPlaylistsDescription} {
      padding: 0 8px;
    }
  }
`;

export const WrapperRow = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${theme.space.x1};
  max-width: 1285px;
  margin: 0 auto;
`;

export const CustomGridPlaylistsHeader = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1285px;
  margin: 0 auto;
  width: 100%;
  padding: ${theme.space.x0} ${theme.space.x3} ${theme.space.x7};

  @media (max-width: 1414px) {
    padding: ${theme.space.x0} ${theme.space.x0} ${theme.space.x7};
  }

  @media ${theme.device.desktopFirst.mobileL} {
    padding: ${theme.space.x0} ${theme.space.x0} ${theme.space.x7};
  }
`;

interface CustomGridPlaylistsTextProps {
  alignSelf: "center" | "flex-start" | "flex-end";
}

export const CustomGridPlaylistsTitle = styled.h3<CustomGridPlaylistsTextProps>`
  align-self: ${({ alignSelf }) => alignSelf};
  color: ${theme.colors.neutral["520"]};
  font-size: ${theme.typography.fontSize.md.x3};
  font-weight: ${theme.typography.fontWeight["400"]};
  margin: ${({ alignSelf }) =>
    `${theme.space.x0} ${
      alignSelf === "center" ? theme.space.x0 : theme.space.x4
    }`};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x9};
  }

  @media ${theme.device.desktopFirst.mobileS} {
    margin: ${({ alignSelf }) =>
      `${theme.space.x0} ${
        alignSelf === "center" ? theme.space.x0 : theme.space.x5
      }`};
  }

  @media ${theme.device.desktopFirst.mobileM} {
    margin: ${theme.space.x0};
  }
`;

export const CustomGridPlaylistsSubTitle = styled.h2<CustomGridPlaylistsTextProps>`
  align-self: ${({ alignSelf }) => alignSelf};
  color: ${theme.colors.neutral["520"]};
  font-size: ${theme.typography.fontSize.sm.x9};
  font-weight: ${theme.typography.fontWeight["400"]};
  margin: ${({ alignSelf }) =>
    `${theme.space.x0} ${
      alignSelf === "center" ? theme.space.x0 : theme.space.x4
    }`};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x7};
  }
`;

export const CustomGridPlaylistsContainer = styled.div<CustomGridPlaylistsContentProps>`
  ${({ customTheme }) =>
    customTheme &&
    css`
      ${CustomGridPlaylistsTitle} {
        ${customTheme &&
        css`
          color: ${theme?.colors?.blackFriday?.white};
        `}
      }

      ${CustomGridPlaylistsSubTitle} {
        ${customTheme &&
        css`
          color: ${theme?.colors?.blackFriday["15"]};
        `}
      }

      .slick-disabled {
        background: ${theme?.colors?.blackFriday?.["110"]};
        opacity: 1;
        visibility: visible;

        &:hover {
          background: ${theme?.colors?.blackFriday?.["110"]};
        }
        cursor: default;

        path {
          stroke: ${theme?.colors?.blackFriday?.["40"]};
        }

        &:hover path {
          stroke: ${theme?.colors?.blackFriday?.["40"]};
        }
      }
    `};
  padding: 0 6.6rem;

  .slick-track {
    height: 248px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat (8, auto);
    grid-template-rows: repeat(2, auto);
    width: 100%;

    @media ${theme?.device?.desktopFirst?.tablet} {
      height: 504px;
      width: 680px;
      grid-template-rows: repeat(4, auto);
    }

    @media ${theme.device?.desktopFirst?.mobileXL} {
      width: 360px;
      height: 760px;
      grid-template-rows: repeat(6, auto);
    }
  }

  .slick-track:before {
    display: none;
  }

  .slick-slide {
    display: flex;
    margin: ${theme.space.x0} ${theme.space.x2} ${theme.space.x2};
    max-width: 100%;

    @media ${theme.device.desktopFirst.tablet} {
      gap: 8px;
      margin: 0;
    }

    ${GridPlaylistsCardStyled} {
      flex-grow: 1;
    }
  }

  .slick-slide > div {
    display: flex;
    max-width: 100%;
  }

  .slick-list {
    display: flex;
    flex-wrap: wrap;
    height: 248px;

    @media ${theme.device.desktopFirst.tablet} {
      height: 504px;
    }

    @media ${theme.device.desktopFirst.mobileXL} {
      height: 760px;
    }
  }

  @media (max-width: 479px) {
    ${CustomGridPlaylistsTitle} {
      margin: 0px;
    }

    ${CustomGridPlaylistsSubTitle} {
      margin: 0px;
    }
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: 1.3rem 2.2rem;
  }

  @media (max-width: 1414px) {
    padding: 0 1rem;
  }

  @media ${theme.device.desktopFirst.mobileM} {
    padding: 1.3rem 1.3rem 1.3rem 2.3rem;
  }
`;
