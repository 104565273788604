import { useHeaderContext } from "application";
import { Dispatch, SetStateAction, memo } from "react";
import { theme, Title } from "ui";
import { Link } from "../../../../../../Link";
import { handleHighlightCategories } from "../data";
import {
  CategoryExploreIcon,
  CategorySidebarMenu,
  ListContentSidebarMenuWrapper,
  TitleWrapper,
} from "../styles";

interface OffersListProps {
  isAuthenticated: boolean;
  onCloseSidebarMenu: () => void;
  setIsShowAllCategories: Dispatch<SetStateAction<string>>;
}

const formatTestId = (testId: string) => {
  return testId.replace(/\s/g, "-").toLowerCase();
};

const OffersList = memo(
  ({
    isAuthenticated,
    onCloseSidebarMenu,
    setIsShowAllCategories,
  }: OffersListProps) => {
    const { highlightCategories } = useHeaderContext();

    const highlightCategoriesAndSeeAllCategoriesOption =
      handleHighlightCategories(highlightCategories || undefined);

    if (!highlightCategoriesAndSeeAllCategoriesOption?.length) {
      return null;
    }

    return (
      <>
        <TitleWrapper isAuthenticated={isAuthenticated}>
          <Title
            data-testid="title-offers-sidebar-menu-mobile"
            data-cy="title-offers-sidebar-menu-mobile"
            color={theme.colors.neutral["520"]}
            fontSize={theme.typography.fontSize.sm.x5}
          >
            Ofertas
          </Title>
        </TitleWrapper>

        {highlightCategoriesAndSeeAllCategoriesOption.map((category) => (
          <Link
            key={category.name}
            href={`/${category.descriptionUrl}`}
            target="_self"
            data-cy={`highlight-categories-link-${formatTestId(category.name)}`}
            data-testid={`highlight-categories-link-${formatTestId(
              category.name
            )}`}
          >
            <ListContentSidebarMenuWrapper
              data-cy={`list-content-wrapper-${formatTestId(category.name)}`}
              data-testid={`list-content-wrapper-${formatTestId(
                category.name
              )}`}
              isBorderActive={!category?.excludeDivider ?? undefined}
              onClick={
                category?.excludeDivider ? undefined : onCloseSidebarMenu
              }
            >
              {!category?.excludeDivider ? (
                <CategorySidebarMenu
                  data-cy={`${formatTestId(category.name)}-sidebar-menu-mobile`}
                  data-testid={`${formatTestId(
                    category.name
                  )}-sidebar-menu-mobile`}
                  id="SidebarContainerOverlayId"
                  onClick={onCloseSidebarMenu}
                >
                  {category.name}
                </CategorySidebarMenu>
              ) : (
                <CategorySidebarMenu
                  data-cy={`${formatTestId(category.name)}-sidebar-menu-mobile`}
                  data-testid={`${formatTestId(
                    category.name
                  )}-sidebar-menu-mobile`}
                  onClick={(e) => {
                    e?.preventDefault();
                    setIsShowAllCategories("Ver categorias");
                  }}
                  menuColor={category?.excludeDivider ?? undefined}
                >
                  {category.name}
                </CategorySidebarMenu>
              )}
              <CategoryExploreIcon
                data-cy="category-explore-icon"
                data-testid="category-explore-icon"
              />
            </ListContentSidebarMenuWrapper>
          </Link>
        ))}
      </>
    );
  }
);

OffersList.displayName = "OffersList";

export { OffersList };
