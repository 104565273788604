import { buildTestIds } from "application";

// MyData
const myDataAccountLayoutTestIds = buildTestIds("my-data-account-layout");
const myDataContainerTestIds = buildTestIds("my-data-container");
const myDataContainerTitleTestIds = buildTestIds("my-data-container-title");
const myDataFormTestIds = buildTestIds("my-data-form");
const myDataFormWrapperTestIds = buildTestIds("my-data-form-wrapper");
const myDataBodyTestIds = buildTestIds("my-data-body");
const myDataFooterTestIds = buildTestIds("my-data-footer");

// Body
const myDataBodyContainerTestIds = buildTestIds("my-data-body-container");
const myDataFieldContainerNameTestIds = buildTestIds(
  "my-data-field-container-name"
);
const myDataFieldLabelNameTestIds = buildTestIds("my-data-field-label-name");
const myDataFieldInputNameTestIds = buildTestIds("my-data-field-input-name");
const myDataFieldContainerCpfTestIds = buildTestIds(
  "my-data-field-container-cpf"
);
const myDataFieldLabelCpfTestIds = buildTestIds("my-data-field-label-cpf");
const myDataFieldInputCpfTestIds = buildTestIds("my-data-field-input-cpf");
const myDataFieldContainerEmailTestIds = buildTestIds(
  "my-data-field-container-email"
);
const myDataFieldLabelEmailTestIds = buildTestIds("my-data-field-label-email");
const myDataFieldInputEmailTestIds = buildTestIds("my-data-field-input-email");
const myDataFieldContainerPhoneNumberTestIds = buildTestIds(
  "my-data-field-container-phone-number"
);
const myDataFieldLabelPhoneNumberTestIds = buildTestIds(
  "my-data-field-label-phone-number"
);
const myDataFieldInputPhoneNumberTestIds = buildTestIds(
  "my-data-field-input-phone-number"
);
const myDataFieldContainerPasswordTestIds = buildTestIds(
  "my-data-field-container-password"
);
const myDataFieldLabelPasswordTestIds = buildTestIds(
  "my-data-field-label-password"
);
const myDataFieldInputPasswordTestIds = buildTestIds(
  "my-data-field-input-password"
);

// Footer
const myDataFooterContainerTestIds = buildTestIds("my-data-footer-container");
const myDataWhatsAppOptionContainerTestIds = buildTestIds(
  "my-data-whatsapp-option-container"
);
const myDataWhatsAppOptionCheckboxTestIds = buildTestIds(
  "my-data-whatsapp-option-checkbox"
);
const myDataWhatsAppOptionTextTestIds = buildTestIds(
  "my-data-whatsapp-option-text"
);
const myDataWhatsAppOptionImageTestIds = buildTestIds(
  "my-data-whatsapp-option-image"
);
const myDataSaveDataButtonTestIds = buildTestIds("my-data-save-data-button");

export {
  myDataAccountLayoutTestIds,
  myDataContainerTestIds,
  myDataContainerTitleTestIds,
  myDataFormTestIds,
  myDataFormWrapperTestIds,
  myDataBodyTestIds,
  myDataBodyContainerTestIds,
  myDataFieldContainerNameTestIds,
  myDataFieldLabelNameTestIds,
  myDataFieldInputNameTestIds,
  myDataFieldContainerCpfTestIds,
  myDataFieldLabelCpfTestIds,
  myDataFieldInputCpfTestIds,
  myDataFieldContainerEmailTestIds,
  myDataFieldLabelEmailTestIds,
  myDataFieldInputEmailTestIds,
  myDataFieldContainerPhoneNumberTestIds,
  myDataFieldLabelPhoneNumberTestIds,
  myDataFieldInputPhoneNumberTestIds,
  myDataFieldContainerPasswordTestIds,
  myDataFieldLabelPasswordTestIds,
  myDataFieldInputPasswordTestIds,
  myDataFooterTestIds,
  myDataFooterContainerTestIds,
  myDataWhatsAppOptionContainerTestIds,
  myDataWhatsAppOptionCheckboxTestIds,
  myDataWhatsAppOptionTextTestIds,
  myDataWhatsAppOptionImageTestIds,
  myDataSaveDataButtonTestIds,
};
