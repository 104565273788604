import { useProductsRecommendation } from "application";
import { appMonitoringClient, catalogApi, cookie, crm } from "implementations";
import { ComponentType } from "react";
import { ComponentDto } from "typing";
import { Skeleton } from "../../Skeleton";
import { SkeletonContainer } from "./styles";

const withProductsRecommendations = <T extends ComponentDto>(
  WrappedComponent: ComponentType<T>
) => {
  const ComponentWithLogic = (
    props: T & JSX.IntrinsicAttributes
  ): JSX.Element => {
    const { isCrmComponent, isLoading, productCards, componentCrmProps } =
      useProductsRecommendation(
        crm,
        props,
        cookie,
        catalogApi,
        appMonitoringClient
      );

    if (isCrmComponent && isLoading) {
      return (
        <SkeletonContainer>
          <Skeleton count={1} width={380} height={250} />
          <Skeleton count={1} width={380} height={250} />
          <Skeleton count={1} width={380} height={250} />
        </SkeletonContainer>
      );
    }

    if (!productCards?.length) {
      return <WrappedComponent {...props} />;
    }

    // if (isCrmComponent && !productCards?.length) {
    //   return null as unknown as ReactElement;
    // }

    // if (!isCrmComponent) {
    //   return <WrappedComponent {...props} />;
    // }

    const allProps = {
      ...props,
      productCards,
      crm: componentCrmProps,
    };

    return <WrappedComponent {...allProps} />;
  };

  ComponentWithLogic.displayName = "CustomComponentWithProductsRecommendation";

  return ComponentWithLogic;
};

export { withProductsRecommendations };
