import { IllustrationSendEmail } from "ui";
import { useCallback, useState } from "react";
import { useModalGroup } from "application";
import { ModalGeneral } from "../ModalGeneral";
import { BoxContentEmail } from "../BoxContentEmail";
import { TextWithCountdown } from "../TextWithCountdown";
import { NoticeConfirmationEmail } from "../NoticeConfirmationEmail";

interface ModalConfirmEmailProps {
  email: string;
  isOpen: boolean;
  ariaHideApp?: boolean;
  onCloseModal(): void;
}

const ModalConfirmEmail = ({
  isOpen,
  email,
  ariaHideApp,
  onCloseModal,
}: ModalConfirmEmailProps) => {
  const [isEmailResent, setEmailResent] = useState<boolean>(false);
  const { sendOrResendEmailConfirmation } = useModalGroup();

  const handleFinishedTime = useCallback(() => {
    setEmailResent(false);
  }, []);

  const handleResendEmail = useCallback(() => {
    const responseIfSendEmail = sendOrResendEmailConfirmation(email);

    if (responseIfSendEmail !== null) setEmailResent(true);
  }, [sendOrResendEmailConfirmation, email]);

  return (
    <ModalGeneral
      ariaHideApp={ariaHideApp}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      ariaLabelForModal="Confirme seu e-mail"
      title="Confirme seu e-mail"
      bodyDescription="Para garantir a sua segurança, é necessário confirmar seu 
    e-mail através do link enviado para o endereço abaixo. Por favor, lembre de checar sua caixa de spam."
      illustration={<IllustrationSendEmail />}
      bodyContent={
        <>
          <BoxContentEmail email={email} />
          {isEmailResent && (
            <NoticeConfirmationEmail
              message="O link de confirmação foi reenviado com sucesso. Verifique seu 
            e-mail ou caixa de spam"
            />
          )}
          <TextWithCountdown
            textLink="Enviar novamente"
            textCountdownEnded="Não recebeu o e-mail? "
            onClickTheLink={handleResendEmail}
            onFinishedTime={handleFinishedTime}
            isModalOpen={isOpen}
            textWaitingForEndOfCountdown="Não recebeu o e-mail? Enviar novamente em"
          />
        </>
      }
    />
  );
};

export type { ModalConfirmEmailProps };

export { ModalConfirmEmail };
