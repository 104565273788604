import { IFingerprint } from "app-domain";
import { obfuscate, useEnvs } from "application";
import Script from "next/script";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const clearSaleSessionIdStr = "clear-sale-session-id";

const clientSessionIdStr = "@FC:Ecom:Client:sessionId";

const ClearSaleFingerprint: IFingerprint = {
  collect<U = string>(): U {
    const hostEnv = process.env.NEXT_PUBLIC_HOST_ENV || "";

    if (!["production", "qas"].includes(hostEnv)) {
      return null as unknown as U;
    }

    const alreadyExistentSessionId =
      sessionStorage?.getItem(clientSessionIdStr);

    if (alreadyExistentSessionId) {
      return alreadyExistentSessionId as unknown as U;
    }

    const generatedId = uuidv4();
    sessionStorage.setItem(clientSessionIdStr, String(generatedId));

    return generatedId as unknown as U;
  },

  Script: () => {
    const [hostEnv, setHostEnv] = useState("");

    const [appKey, setAppKey] = useState("");

    const allConfigs = useEnvs((state) => state.allConfigs);

    useEffect(() => {
      if (allConfigs?.clearSaleAppKey && allConfigs?.hostEnv) {
        setAppKey(obfuscate(allConfigs.clearSaleAppKey));
        setHostEnv(allConfigs.hostEnv);
      }
    }, [allConfigs?.clearSaleAppKey, allConfigs?.hostEnv]);

    if (!["production", "qas"].includes(hostEnv) || !appKey) {
      return null;
    }

    const sessionId = ClearSaleFingerprint.collect() as string;

    return (
      <>
        <input type="hidden" id={clearSaleSessionIdStr} value={sessionId} />

        <Script id="clear-sale-fingerprint-script" strategy="afterInteractive">
          {`!function(e,s,c,n,t,a,o){e.CsdpObject=t,e[t]=e[t]||function(){(e[t].q=e[t].q||[]).push(arguments)},e[t].l=1*Date.now(),a=s.createElement(c),o=s.getElementsByTagName(c)[0],a.async=1,a.src=n,o.parentNode.insertBefore(a,o)}(window,document,"script","//device.clearsale.com.br/p/fp.js","csdp"),csdp("app","${appKey}"),csdp("inputsessionid","${clearSaleSessionIdStr}");`}
        </Script>
      </>
    );
  },

  remove: () => {
    sessionStorage.removeItem("@FC:Ecom:Client:sessionId");
  },
};

export { ClearSaleFingerprint };
