import { buildTestIds, useAppContext } from "application";
import {
  Aggregator,
  Column,
  Container,
  LogoFCBlack,
  Row,
  SpaceWrapper,
  theme,
} from "ui";
import UtilInfoLinkOptions from "./LinkOptions";
import UtilInfoLinkOptionsMobile from "./LinkOptions/mobile";
import { UtilInfoPaymentOptions } from "./PaymentOptions";
import {
  UtilInfoContainer,
  UtilInfoContent,
  UtilInfoPaymentOptionsWrapper,
} from "./style";

interface UtilInfoProps {
  isBlackTheme?: boolean;
}

const UtilInfo = ({ isBlackTheme }: UtilInfoProps) => {
  const { isClientLaptop } = useAppContext();

  return (
    <Aggregator
      background={
        isBlackTheme ? theme.colors.blackFriday["175"] : "trasnparent"
      }
      id="util-info-container"
      {...buildTestIds("util-info-container")}
      padding={
        isClientLaptop
          ? `${theme.space.x0} ${theme.space.x0}`
          : `${theme.space.x10} ${theme.space.x6}`
      }
      mobile={{
        margin: `${theme.space.x0}`,
        padding: theme.space.x0,
      }}
      tablet={{
        margin: `${theme.space.x0}`,
        padding: theme.space.x0,
      }}
    >
      <Container>
        <Row
          display="flex"
          flexDirection={isClientLaptop ? "column-reverse" : "row"}
          justifyContent="start"
          alignItems="start"
        >
          <UtilInfoContent>
            <SpaceWrapper
              margin={
                isClientLaptop
                  ? `${theme.space.x0} ${theme.space.x4}`
                  : theme.space.x0
              }
            >
              <UtilInfoContainer>
                <Row display="flex" alignItems="center">
                  {!isClientLaptop && (
                    <Column lg="6" justifyContent="center">
                      <LogoFCBlack />
                    </Column>
                  )}

                  <UtilInfoPaymentOptionsWrapper>
                    <UtilInfoPaymentOptions
                      isBlackTheme={!!isBlackTheme}
                      data-cy="util-info-payment-options"
                    />
                  </UtilInfoPaymentOptionsWrapper>
                </Row>
              </UtilInfoContainer>
            </SpaceWrapper>
          </UtilInfoContent>

          <Column lg="6">
            <Container>
              <Row>
                {isClientLaptop ? (
                  <UtilInfoLinkOptionsMobile isBlackTheme={!!isBlackTheme} />
                ) : (
                  <UtilInfoLinkOptions isBlackTheme={!!isBlackTheme} />
                )}
              </Row>
            </Container>
          </Column>
        </Row>
      </Container>
    </Aggregator>
  );
};

export { UtilInfo };
