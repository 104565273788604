import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const YoutubeIcon = ({
  width = 29,
  height = 29,
  alt = "Ícone do Youtube",
  color = theme.colors.neutral.white,
  secondColor = theme.colors.neutral["560"],
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1527_13316)">
        <path
          d="M14.2542 3.6709H14.4099C15.8484 3.67615 23.1372 3.72865 25.1024 4.25715C25.6965 4.41844 26.2379 4.73284 26.6724 5.16891C27.107 5.60499 27.4195 6.14747 27.5787 6.74215C27.7554 7.40715 27.8797 8.2874 27.9637 9.19565L27.9812 9.37765L28.0197 9.83265L28.0337 10.0146C28.1474 11.6141 28.1614 13.1121 28.1632 13.4394V13.5706C28.1614 13.9101 28.1457 15.5096 28.0197 17.1756L28.0057 17.3594L27.9899 17.5414C27.9024 18.5424 27.7729 19.5364 27.5787 20.2679C27.42 20.8628 27.1076 21.4056 26.673 21.8417C26.2384 22.2779 25.6968 22.5921 25.1024 22.7529C23.0724 23.2989 15.3567 23.3374 14.2874 23.3391H14.0389C13.4982 23.3391 11.2617 23.3286 8.91667 23.2481L8.61917 23.2376L8.46692 23.2306L8.16767 23.2184L7.86842 23.2061C5.92592 23.1204 4.07617 22.9821 3.22392 22.7512C2.62977 22.5905 2.08828 22.2765 1.65368 21.8407C1.21909 21.4049 0.906649 20.8625 0.747667 20.2679C0.553417 19.5381 0.423917 18.5424 0.336417 17.5414L0.322417 17.3576L0.308417 17.1756C0.22205 15.9898 0.174192 14.8016 0.164917 13.6126L0.164917 13.3974C0.168417 13.0211 0.182417 11.7209 0.276917 10.2859L0.289167 10.1056L0.294417 10.0146L0.308417 9.83265L0.346917 9.37765L0.364417 9.19565C0.448417 8.2874 0.572667 7.4054 0.749417 6.74215C0.908131 6.14723 1.22045 5.60449 1.65507 5.16834C2.08968 4.73219 2.63131 4.41796 3.22567 4.25715C4.07792 4.02965 5.92767 3.88965 7.87017 3.80215L8.16767 3.7899L8.46867 3.7794L8.61917 3.77415L8.91842 3.7619C10.5839 3.7083 12.2501 3.67855 13.9164 3.67265H14.2542V3.6709ZM11.3649 9.2884V17.7199L18.6397 13.5059L11.3649 9.2884Z"
          fill={secondColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1527_13316">
          <rect
            width="28"
            height="28"
            fill={color}
            transform="translate(0.164917 0.172852)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
