import { CarouselSubComponentProperty, BannerType } from "typing";

const customCarouselSubComponentData = (
  properties: CarouselSubComponentProperty[]
) => {
  const bannerType = (
    properties.find(({ key }) => key === "bannerType") as BannerType
  )?.value;

  return {
    bannerType,
  };
};

export { customCarouselSubComponentData };
