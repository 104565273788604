import { useShallow, useUniqueClient } from "application/state-manager";

const useUniqueClientComponentData = () => {
  const { currentStep } = useUniqueClient(
    useShallow((state) => ({
      currentStep: state.currentStep,
    }))
  );

  return { currentStep };
};

export { useUniqueClientComponentData };
