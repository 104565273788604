import { Fragment, useCallback, useRef } from "react";
import { buildTestIds, useClickOutside } from "application";
import {
  useAuthentication as defaultUseAuthentication,
  useShallow,
} from "application/state-manager";
import { StepName } from "typing";
import { ModalAuthenticationContainer, PointerUp } from "./styles";
import { AuthContent } from "../AuthContent";
import { getAuthContent } from "../data";

interface ModalAuthenticationProps {
  useAuthentication?: typeof defaultUseAuthentication;
}
const ModalAuthentication = ({
  useAuthentication = defaultUseAuthentication,
}: ModalAuthenticationProps) => {
  const {
    setIsAuthenticationDropdownActive,
    isAuthenticationDropdownActive,
    currentStep,
  } = useAuthentication(
    useShallow((state) => ({
      setIsAuthenticationDropdownActive:
        state.setIsAuthenticationDropdownActive,
      isAuthenticationDropdownActive: state.isAuthenticationDropdownActive,
      currentStep: state.currentStep,
    }))
  );

  const ModalAuthenticationContainerRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    ref: ModalAuthenticationContainerRef,
    callback: () => {
      if (isAuthenticationDropdownActive) {
        setIsAuthenticationDropdownActive(false);
      }
    },
  });

  const renderAuthContent = useCallback((step: StepName) => {
    return getAuthContent("modal", step);
  }, []);

  if (!isAuthenticationDropdownActive) {
    return null;
  }

  return (
    <Fragment key="modal-auth">
      <ModalAuthenticationContainer
        {...buildTestIds("modal-authentication-container")}
        isOpenedLogin={isAuthenticationDropdownActive}
        ref={ModalAuthenticationContainerRef}
      >
        <PointerUp
          {...buildTestIds("modal-authentication-container-pointer-up")}
        />
        <AuthContent loginMode="modal">
          {renderAuthContent(currentStep)}
        </AuthContent>
      </ModalAuthenticationContainer>
    </Fragment>
  );
};

export { ModalAuthentication };
