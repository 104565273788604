import styled, { css } from "styled-components";
import { Button, theme } from "ui";

interface SidebarProductFilterProps {
  openMobile?: boolean;
  width?: string;
  isBlackTheme?: boolean;
}

export const SidebarFilter = styled.div<SidebarProductFilterProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || "100%"};
  align-items: center;
  justify-content: center;
  gap: 10px 0;

  @media ${theme.device.desktopFirst.tabletXL} {
    position: absolute;
    width: 100%;
    height: 350%;
    left: 0;
    padding: 15px;
    margin-top: -20px;
    z-index: 5;
    background: ${theme.colors.neutral["50"]};
    align-items: start;
    justify-content: start;
    transform: ${({ openMobile }) =>
      openMobile ? "translateX(0)" : "translateX(-100%)"};
    transition: 0.2s ease-in-out;
  }

  @media ${theme.device.desktopFirst.tablet} {
    position: absolute;
    width: 100%;
    height: 400%;
    left: 0;
    padding: 15px;
    margin-top: 0px;
    z-index: 5;
    background: ${theme.colors.neutral["50"]};
    align-items: start;
    justify-content: start;
    transform: ${({ openMobile }) =>
      openMobile ? "translateX(0)" : "translateX(-100%)"};
    transition: 0.2s ease-in-out;
  }

  ${({ isBlackTheme }) =>
    isBlackTheme &&
    css`
      @media ${theme.device.desktopFirst.tabletXL} {
        background: ${theme.colors.neutral["670"]};
      }

      @media ${theme.device.desktopFirst.tablet} {
        background: ${theme.colors.neutral["670"]};
      }
    `}
`;

export const BackButton = styled(Button)`
  padding: 0;
  border-radius: 50%;
`;
