import { SuggestedProductCardDto, Suggestion } from "typing";
import { ItemSuggestedProducts, ListSuggestedProducts } from "../../../styles";
import { SuggestedProductCard } from "../../subcomponents";

interface SuggestedProductsResultProps {
  suggestion?: Suggestion;
  suggestionsProducts?: SuggestedProductCardDto[];
  isClientTablet?: boolean;
  onClick: (searchTerm: string) => void;
}
export const SuggestedProductsResult = ({
  suggestion,
  isClientTablet,
  suggestionsProducts,
  onClick,
}: SuggestedProductsResultProps) => {
  if (
    suggestion &&
    (suggestion?.suggestions?.length ?? 1) > 0 &&
    !isClientTablet
  ) {
    return (
      <ListSuggestedProducts>
        <ItemSuggestedProducts>
          {suggestionsProducts?.slice(0, 4).map((suggestedProduct) => (
            <SuggestedProductCard
              key={suggestedProduct.id}
              suggestedProduct={suggestedProduct}
              afterClick={onClick}
            />
          ))}
        </ItemSuggestedProducts>
      </ListSuggestedProducts>
    );
  }

  return null;
};
