import { IconProps } from "typing";
import { theme } from "../themes";

export const CartIcon = ({
  width = 24,
  height = 24,
  alt = "Carrinho",
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      aria-labelledby={alt}
      xmlns="https://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {" "}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.12329 2.26021C2.71472 2.19211 2.3283 2.46813 2.2602 2.8767C2.1921 3.28528 2.46812 3.6717 2.8767 3.7398L5.2061 4.12803C5.30728 4.1449 5.38789 4.22186 5.40943 4.32215L6.14636 7.74701L7.51827 15.1159C7.60981 15.5856 8.02127 15.9246 8.4998 15.9246H18.5569C19.0211 15.9246 19.4243 15.6052 19.5304 15.1534L21.1844 8.11437C21.3319 7.48673 20.8556 6.88562 20.2109 6.88562H7.49403L6.876 4.00725C6.72526 3.30521 6.16097 2.76649 5.4527 2.64844L3.12329 2.26021ZM9.11278 14.4246L7.81611 8.38562H19.5798L18.1608 14.4246H9.11278Z"
        fill={color}
      />{" "}
      <circle
        cx="9.38818"
        cy="19.514"
        r="1.73614"
        stroke={color}
        strokeWidth="1.5"
      />{" "}
      <circle
        cx="16.7643"
        cy="19.514"
        r="1.73614"
        stroke={color}
        strokeWidth="1.5"
      />{" "}
    </svg>
  );
};
