import { useEffect, useState } from "react";
import { UtilInfoDto, IHttpResponse, IconDto } from "typing";
import { theme, Column, Desk, DeskInfo, mappedIcons } from "ui";
import { useBranchLocation, useEnvs } from "application";
import { utilInfoApi } from "implementations";
import UtilInfoOption from "../Option";
import { Skeleton } from "../../../Skeleton";
import { LinkOptionsTitle } from "./style";

interface UtilInfoLinkOptionsProps {
  isBlackTheme: boolean;
}

const iconsColor = (icon: IconDto, isBlackFridayTheme: boolean) => {
  return isBlackFridayTheme
    ? theme.colors.neutral.white
    : icon?.color || undefined;
};

const UtilInfoLinkOptions = ({ isBlackTheme }: UtilInfoLinkOptionsProps) => {
  const { getBranchLocationClient } = useBranchLocation();
  const allConfigs = useEnvs((state) => state.allConfigs);
  const [linkOptions, setLinkOptions] = useState({
    data: null,
    isLoading: true,
    error: false,
  } as IHttpResponse<UtilInfoDto[], unknown>);

  useEffect(() => {
    const getLinkOptions = async () => {
      const isBranchJPA = getBranchLocationClient() === "6";
      const response = await utilInfoApi.getLinkOptions(
        isBranchJPA,
        allConfigs?.isBlackFridayOffersAvailable === "true"
      );
      setLinkOptions(response);
    };

    getLinkOptions();
  }, [allConfigs?.isBlackFridayOffersAvailable, getBranchLocationClient]);

  return linkOptions?.data ? (
    <>
      {linkOptions.data.map(({ title, icon, options }) => (
        <Column key={title} md="4">
          <Desk
            hasRightBorder={false}
            alignItems="start"
            gap={theme.space.x4}
            padding="0 10px"
          >
            {icon && (
              <DeskInfo>
                {mappedIcons(
                  icon?.title,
                  {
                    width: icon?.width ? Number(icon.width) : undefined,
                    height: icon?.height ? Number(icon.height) : undefined,
                    color: iconsColor(icon, isBlackTheme),
                  },
                  {
                    secondColor: iconsColor(icon, isBlackTheme),
                  }
                )}
              </DeskInfo>
            )}
            <DeskInfo>
              <LinkOptionsTitle
                color={isBlackTheme ? theme.colors.neutral.white : "initial"}
                fontSize={theme.typography.fontSize.sm.x5}
              >
                {title}
              </LinkOptionsTitle>
              {options?.length &&
                options?.map((option) => (
                  <UtilInfoOption
                    isBlackTheme={!!isBlackTheme}
                    key={option?.title}
                    {...option}
                  />
                ))}
            </DeskInfo>
          </Desk>
        </Column>
      ))}
    </>
  ) : linkOptions?.isLoading ? (
    <Skeleton count={5} height={theme.space.x4} />
  ) : null;
};

export default UtilInfoLinkOptions;
