import { IconProps } from "typing";

export const WeddingRingsDefaultIcon = ({
  width = "auto",
  height = "auto",
  alt = "Ícone padrão de alianças de casamento",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 215 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="107.5"
        cy="107.5"
        r="106.5"
        fill="#CDCDCD"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M147.409 105.925C158.864 117.38 158.864 135.953 147.409 147.408C135.953 158.864 117.38 158.864 105.925 147.408C94.4696 135.953 94.4696 117.38 105.925 105.925C117.38 94.4693 135.953 94.4693 147.409 105.925"
        stroke="#53565A"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.075 105.925C121.531 117.38 121.531 135.953 110.075 147.408C98.62 158.864 80.0471 158.864 68.5918 147.408C57.1364 135.953 57.1364 117.38 68.5918 105.925C80.0471 94.4693 98.62 94.4693 110.075 105.925"
        stroke="#53565A"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 63.664C124 66 122.992 68.2453 121.195 69.92L109.403 80.928C108.613 81.664 107.387 81.664 106.592 80.928L94.8055 69.9253C93.0081 68.2453 92.0001 66 92.0001 63.664V63.664C92.0001 58.6933 96.0268 54.6666 100.997 54.6666H115.003C119.973 54.6666 124 58.6933 124 63.664V63.664V63.664Z"
        stroke="#53565A"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
