import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const NoOrderIcon = ({
  width = 175,
  height = 175,
  color = theme.colors.neutral.white,
  secondColor = theme.colors.neutral["white-075"],
  thirdColor = theme.colors.neutral["150"],
  fourthColor = theme.colors.neutral["88"],
  fifthColor = theme.colors.neutral["550"],
  alt = "ícone de pacote com um X",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 175 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1021_54010)">
        <rect
          x="20.9774"
          y="2.55566"
          width="151.449"
          height="161.539"
          rx="75.7245"
          fill={secondColor}
        />
        <path
          d="M61.4625 71.8378L86.0074 71.5791V142.348L79.5544 143.005V92.3918L70.4233 91.2822C70.8153 88.7901 70.3955 86.2301 69.2339 84.0278C67.2947 80.4261 61.4625 79.7651 61.4625 79.7651V71.8378Z"
          fill={thirdColor}
        />
        <path
          d="M31.4216 108.962H47.7512L49.5647 142.21H31.4216V108.962Z"
          fill={thirdColor}
        />
        <path
          d="M123.768 112.128H140.097L141.911 145.376H123.768V112.128Z"
          fill={fourthColor}
        />
        <path
          d="M58.124 108.962H73.529V142.21H55.9088L58.124 108.962Z"
          fill={thirdColor}
        />
        <path
          d="M150.47 112.128H165.875V145.376H148.255L150.47 112.128Z"
          fill={fourthColor}
        />
        <path
          d="M28.7446 95.3594H76.0355V105.422H28.7446V95.3594Z"
          fill={thirdColor}
        />
        <path
          d="M123.154 93.7354L169.862 101.626L168.387 111.564L121.678 103.674L123.154 93.7354Z"
          fill={fourthColor}
        />
        <path
          d="M62.4783 22.4375H86.9487L85.8673 66.2606H61.4625L62.4783 22.4375Z"
          fill={thirdColor}
        />
        <path
          d="M45.9273 41.1653L51.5155 43.0893L52.2564 47.9923L48.8642 60.4644L38.2419 56.6773L41.8114 44.6656L45.9273 41.1653Z"
          fill={thirdColor}
        />
        <path
          d="M93.3922 22.4375H114.02V66.3318H92.1294L93.3922 22.4375Z"
          fill={thirdColor}
        />
        <path
          d="M92.1294 71.5781H114.02V141.864H92.1294V71.5781Z"
          fill={thirdColor}
        />
        <path
          d="M121.037 32.9873H142.927C142.927 32.9873 136.251 38.2623 136.251 49.5634C136.251 60.8645 143.357 66.1774 145.026 79.6099C146.695 93.0424 145.026 93.7318 145.026 93.7318H121.037C121.037 93.7318 119.034 93.034 121.037 79.6099C123.039 66.1857 129.063 60.9013 129.063 49.5598C129.09 46.3094 128.373 43.101 126.974 40.213C125.576 37.3251 123.538 34.8447 121.037 32.9873Z"
          fill={thirdColor}
        />
        <path
          d="M57.1461 87.3425C59.7251 84.5917 64.6751 83.6637 66.5198 87.3425C68.3644 91.0213 63.9508 95.3623 63.9508 95.3623H53.6337C53.6337 95.3623 54.5671 90.0933 57.1461 87.3425Z"
          fill={thirdColor}
        />
        <path
          d="M49.0895 87.3425C46.5105 84.5917 41.5605 83.6637 39.7158 87.3425C37.8712 91.0213 42.2859 95.3623 42.2859 95.3623H52.6019C52.6019 95.3623 51.6685 90.0933 49.0895 87.3425Z"
          fill={thirdColor}
        />
        <path
          d="M47.5551 47.273C48.4768 47.273 49.224 46.4761 49.224 45.493C49.224 44.5099 48.4768 43.7129 47.5551 43.7129C46.6334 43.7129 45.8862 44.5099 45.8862 45.493C45.8862 46.4761 46.6334 47.273 47.5551 47.273Z"
          fill={color}
        />
        <path
          d="M62.7977 22.541C58.9197 23.5545 55.4073 25.7635 52.6986 28.8923C48.7789 33.8326 48.1336 41.6863 48.1336 41.6863C48.1336 41.6863 47.3648 32.691 51.2856 27.7507C55.2064 22.8104 62.7977 22.541 62.7977 22.541Z"
          fill={thirdColor}
        />
        <path
          d="M147.34 72.9661L142.41 76.3672L142.884 81.3078L149.187 92.4184L158.528 85.828L152.165 75.211L147.34 72.9661Z"
          fill={thirdColor}
        />
        <path
          d="M147.253 79.3146C148.143 79.06 148.671 78.0839 148.433 77.1343C148.194 76.1847 147.279 75.6213 146.389 75.8759C145.498 76.1305 144.97 77.1067 145.209 78.0563C145.448 79.0058 146.363 79.5692 147.253 79.3146Z"
          fill={color}
        />
        <path
          d="M126.528 59.6338C130.52 59.5418 134.449 60.7055 137.825 62.9796C142.812 66.6688 145.339 74.0795 145.339 74.0795C145.339 74.0795 143.897 65.1765 138.91 61.4873C133.923 57.7981 126.528 59.6338 126.528 59.6338Z"
          fill={thirdColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.923 65.9344V112.562C128.923 117.283 125.33 121.109 120.898 121.109H64.7227C60.2906 121.109 56.6976 117.283 56.6976 112.562V65.9344C56.6961 64.7754 56.9159 63.6282 57.3437 62.5626L62.6402 49.3617C63.9066 46.2201 66.8091 44.1845 70.0193 44.1865H115.602C118.815 44.1865 121.718 46.2279 122.981 49.3746L128.277 62.5626C128.705 63.6284 128.924 64.7755 128.923 65.9344Z"
          fill={color}
          stroke={fifthColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.6976 65.5547H128.883"
          stroke={fifthColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M100.123 44.9404L104.136 62.535V73.8834C104.036 75.4701 102.495 76.6885 100.685 76.6106H83.5115C81.7018 76.6885 80.1601 75.4701 80.0608 73.8834V62.535L84.0733 44.9404"
          stroke={fifthColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.7317 108.289H76.7568"
          stroke={fifthColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140.482 115.91C140.482 125.218 133.397 132.763 124.658 132.763C115.919 132.763 108.835 125.218 108.835 115.91C108.835 106.603 115.919 99.0576 124.658 99.0576C128.857 99.0507 132.885 100.824 135.854 103.986C138.823 107.148 140.488 111.439 140.482 115.91Z"
          fill="#F9C6CD"
          stroke={fifthColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M113.474 126.407L135.845 105.758"
          stroke={fifthColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M55.4105 27.0146L65.2869 25.4199L57.9472 38.4427L67.5153 36.8978"
        stroke={color}
        strokeWidth="15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4105 27.0146L65.2869 25.4199L57.9472 38.4427L67.5153 36.8978"
        stroke={fifthColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.826 50.5039L49.0293 47.189L45.9379 58.4519L52.7959 55.2959L45.9379 58.4519"
        stroke={color}
        strokeWidth="16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.826 50.5039L49.0293 47.189L45.9379 58.4519L52.7959 55.2959L45.9379 58.4519"
        stroke={fifthColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8093 20.2021L38.9139 16.4959L31.5942 34.3485L43.423 30.7267"
        stroke={color}
        strokeWidth="16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9128 20.0176L39.0877 16.5832L31.4191 34.2677L43.3166 30.9115"
        stroke={fifthColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_1021_54010">
          <rect
            x="20.9774"
            y="2.55566"
            width="151.449"
            height="161.539"
            rx="75.7245"
            fill={color}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
