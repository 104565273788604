import { MultipleColorsIconProps } from "typing";

const DevicePasswordChanged = ({
  alt = "Senha alterada com sucesso ícone",
  width = 203,
  height = 155,
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 203 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="64.6416" cy="91" r="64" fill="#F6F7F7" />
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8662 78.4854C32.2562 78.4854 30.9502 79.7043 30.9502 81.2077C30.9502 82.7111 32.2562 83.9293 33.8662 83.9293H41.8862C43.497 83.9293 44.803 82.7104 44.803 81.2077C44.803 79.705 43.497 78.4861 41.887 78.4861C41.887 76.4188 40.0915 74.743 37.8766 74.743C35.6617 74.743 33.8662 76.4181 33.8662 78.4854Z"
          stroke="#A0A2A4"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8662 94.4854C10.2562 94.4854 8.9502 95.7043 8.9502 97.2077C8.9502 98.7111 10.2562 99.9293 11.8662 99.9293H19.8862C21.497 99.9293 22.803 98.7104 22.803 97.2077C22.803 95.705 21.497 94.4861 19.887 94.4861C19.887 92.4188 18.0915 90.743 15.8766 90.743C13.6617 90.743 11.8662 92.4181 11.8662 94.4854Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.8662 135.485C84.2562 135.485 82.9502 136.704 82.9502 138.208C82.9502 139.711 84.2562 140.929 85.8662 140.929H93.8862C95.497 140.929 96.803 139.71 96.803 138.208C96.803 136.705 95.497 135.486 93.887 135.486C93.887 133.419 92.0915 131.743 89.8766 131.743C87.6617 131.743 85.8662 133.418 85.8662 135.485Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.8662 112.485C23.2562 112.485 21.9502 113.704 21.9502 115.208C21.9502 116.711 23.2562 117.929 24.8662 117.929H32.8862C34.497 117.929 35.803 116.71 35.803 115.208C35.803 113.705 34.497 112.486 32.887 112.486C32.887 110.419 31.0915 108.743 28.8766 108.743C26.6617 108.743 24.8662 110.418 24.8662 112.485Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.866 106.485C109.256 106.485 107.95 107.704 107.95 109.208C107.95 110.711 109.256 111.929 110.866 111.929H118.886C120.497 111.929 121.803 110.71 121.803 109.208C121.803 107.705 120.497 106.486 118.887 106.486C118.887 104.419 117.092 102.743 114.877 102.743C112.662 102.743 110.866 104.418 110.866 106.485Z"
          stroke="#B2B3B5"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.8662 41.4854C78.2562 41.4854 76.9502 42.7043 76.9502 44.2077C76.9502 45.7111 78.2562 46.9293 79.8662 46.9293H87.8862C89.497 46.9293 90.803 45.7104 90.803 44.2077C90.803 42.705 89.497 41.4861 87.887 41.4861C87.887 39.4188 86.0915 37.743 83.8766 37.743C81.6617 37.743 79.8662 39.4181 79.8662 41.4854Z"
          stroke="#8F9194"
          strokeWidth="0.61568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.866 63.4854C104.256 63.4854 102.95 64.7043 102.95 66.2077C102.95 67.7111 104.256 68.9293 105.866 68.9293H113.886C115.497 68.9293 116.803 67.7104 116.803 66.2077C116.803 64.705 115.497 63.4861 113.887 63.4861C113.887 61.4188 112.092 59.743 109.877 59.743C107.662 59.743 105.866 61.4181 105.866 63.4854Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.4734 94.4892C92.2927 94.4892 91.335 95.3831 91.335 96.4856C91.335 97.5881 92.2927 98.4815 93.4734 98.4815H99.3547C100.536 98.4815 101.494 97.5876 101.494 96.4856C101.494 95.3837 100.536 94.4898 99.3553 94.4898C99.3553 92.9738 98.0386 91.7449 96.4143 91.7449C94.7901 91.7449 93.4734 92.9733 93.4734 94.4892Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.4734 37.4892C45.2927 37.4892 44.335 38.3831 44.335 39.4856C44.335 40.5881 45.2927 41.4815 46.4734 41.4815H52.3547C53.5359 41.4815 54.4937 40.5876 54.4937 39.4856C54.4937 38.3837 53.5359 37.4898 52.3553 37.4898C52.3553 35.9738 51.0386 34.7449 49.4143 34.7449C47.7901 34.7449 46.4734 35.9733 46.4734 37.4892Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4734 136.489C36.2927 136.489 35.335 137.383 35.335 138.486C35.335 139.588 36.2927 140.481 37.4734 140.481H43.3547C44.5359 140.481 45.4937 139.588 45.4937 138.486C45.4937 137.384 44.5359 136.49 43.3553 136.49C43.3553 134.974 42.0386 133.745 40.4143 133.745C38.7901 133.745 37.4734 134.973 37.4734 136.489Z"
          fill="#A0A2A4"
        />
      </g>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4734 57.4892C24.2927 57.4892 23.335 58.3831 23.335 59.4856C23.335 60.5881 24.2927 61.4815 25.4734 61.4815H31.3547C32.5359 61.4815 33.4937 60.5876 33.4937 59.4856C33.4937 58.3837 32.5359 57.4898 31.3553 57.4898C31.3553 55.9738 30.0386 54.7449 28.4143 54.7449C26.7901 54.7449 25.4734 55.9733 25.4734 57.4892Z"
          fill="#A0A2A4"
        />
      </g>
      <ellipse
        cx="64.4358"
        cy="128.926"
        rx="16.0042"
        ry="1.06694"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.6244 49.0025H75.2765C81.3445 49.0025 86.2593 53.9173 86.2593 59.9853V109.408C86.2593 115.476 81.3445 120.391 75.2765 120.391H52.6244C46.5564 120.391 41.6416 115.476 41.6416 109.408V59.9853C41.6416 53.9173 46.5564 49.0025 52.6244 49.0025Z"
        fill="#F6F7F7"
        stroke="#53565A"
        strokeWidth="3.53019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1318 108.035H68.7418H59.1318Z"
        fill="white"
      />
      <path
        d="M59.1318 108.035H68.7418"
        stroke="#53565A"
        strokeWidth="3.53019"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="63.951" cy="71.3114" r="7.75959" fill="#F8FCF6" />
      <ellipse cx="64.1416" cy="77.5" rx="4.5" ry="1.5" fill="#68A439" />
      <path
        d="M69.6096 65.653C72.7346 68.778 72.7346 73.8447 69.6096 76.9697C66.4846 80.0947 61.418 80.0947 58.293 76.9697C55.168 73.8446 55.168 68.778 58.293 65.653C61.418 62.528 66.4846 62.528 69.6096 65.653"
        stroke="#252728"
        strokeWidth="1.33368"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.7198 68.0429C66.6966 69.0196 66.6966 70.6032 65.7198 71.5799C64.7431 72.5567 63.1595 72.5567 62.1827 71.5799C61.206 70.6032 61.206 69.0196 62.1827 68.0429C63.1595 67.0661 64.7431 67.0661 65.7198 68.0429Z"
        fill="#68A439"
      />
      <path
        d="M65.7198 68.0429C66.6966 69.0196 66.6966 70.6032 65.7198 71.5799C64.7431 72.5567 63.1595 72.5567 62.1827 71.5799C61.206 70.6032 61.206 69.0196 62.1827 68.0429C63.1595 67.0661 64.7431 67.0661 65.7198 68.0429"
        stroke="#252728"
        strokeWidth="1.33368"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0254 77.4978C67.7495 76.1544 65.9499 75.3124 63.9512 75.3124C61.9524 75.3124 60.1528 76.1544 58.877 77.4987"
        fill="#68A439"
      />
      <path
        d="M69.0254 77.4978C67.7495 76.1544 65.9499 75.3124 63.9512 75.3124C61.9524 75.3124 60.1528 76.1544 58.877 77.4987"
        stroke="#252728"
        strokeWidth="1.33368"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.17"
        d="M200.849 56.1655L74.303 94.2279L54.6642 87.1523L132.372 25.8401L200.849 56.1655Z"
        fill="url(#paint0_linear_123_168665)"
        stroke="#68A439"
      />
      <rect
        x="128.795"
        y="22.7583"
        width="73.7162"
        height="32.9783"
        rx="3.8798"
        fill="#68A439"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.389 38.7797C150.389 38.3815 150.068 38.0599 149.669 38.0628C149.271 38.0628 148.95 38.3844 148.95 38.7827C148.95 39.181 149.271 39.5026 149.669 39.5026C150.065 39.5026 150.389 39.181 150.389 38.7797Z"
        fill="#68A439"
      />
      <path
        d="M150.389 38.7797C150.389 38.3815 150.068 38.0599 149.669 38.0628C149.271 38.0628 148.95 38.3844 148.95 38.7827C148.95 39.181 149.271 39.5026 149.669 39.5026C150.065 39.5026 150.389 39.181 150.389 38.7797"
        stroke="#FEF4F5"
        strokeWidth="4.79418"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.19 38.7797C162.19 38.3815 161.869 38.0599 161.47 38.0628C161.072 38.0628 160.75 38.3844 160.75 38.7827C160.75 39.181 161.072 39.5026 161.47 39.5026C161.866 39.5026 162.19 39.181 162.19 38.7797Z"
        fill="#68A439"
      />
      <path
        d="M162.19 38.7797C162.19 38.3815 161.869 38.0599 161.47 38.0628C161.072 38.0628 160.75 38.3844 160.75 38.7827C160.75 39.181 161.072 39.5026 161.47 39.5026C161.866 39.5026 162.19 39.181 162.19 38.7797"
        stroke="#FEF4F5"
        strokeWidth="4.79418"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.991 38.7797C173.991 38.3815 173.669 38.0599 173.271 38.0628C172.873 38.0628 172.551 38.3844 172.551 38.7827C172.551 39.181 172.873 39.5026 173.271 39.5026C173.666 39.5026 173.991 39.181 173.991 38.7797Z"
        fill="#68A439"
      />
      <path
        d="M173.991 38.7797C173.991 38.3815 173.669 38.0599 173.271 38.0628C172.873 38.0628 172.551 38.3844 172.551 38.7827C172.551 39.181 172.873 39.5026 173.271 39.5026C173.666 39.5026 173.991 39.181 173.991 38.7797"
        stroke="#FEF4F5"
        strokeWidth="4.79418"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.792 38.7797C185.792 38.3815 185.47 38.0599 185.072 38.0628C184.674 38.0628 184.352 38.3844 184.352 38.7827C184.352 39.181 184.674 39.5026 185.072 39.5026C185.47 39.5026 185.792 39.181 185.792 38.7797Z"
        fill="#68A439"
      />
      <path
        d="M185.792 38.7797C185.792 38.3815 185.47 38.0599 185.072 38.0628C184.674 38.0628 184.352 38.3844 184.352 38.7827C184.352 39.181 184.674 39.5026 185.072 39.5026C185.47 39.5026 185.792 39.181 185.792 38.7797"
        stroke="#FEF4F5"
        strokeWidth="4.79418"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="53.2812"
        y="85.8606"
        width="21.6812"
        height="9.69949"
        rx="1.14112"
        fill="#68A439"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.6319 90.5728C59.6319 90.4556 59.5373 90.361 59.4202 90.3619C59.303 90.3619 59.2085 90.4565 59.2085 90.5736C59.2085 90.6908 59.303 90.7854 59.4202 90.7854C59.5365 90.7854 59.6319 90.6908 59.6319 90.5728Z"
        fill="#5C5E62"
      />
      <path
        d="M59.6319 90.5728C59.6319 90.4556 59.5373 90.361 59.4202 90.3619C59.303 90.3619 59.2085 90.4565 59.2085 90.5736C59.2085 90.6908 59.303 90.7854 59.4202 90.7854C59.5365 90.7854 59.6319 90.6908 59.6319 90.5728"
        stroke="#36383B"
        strokeWidth="1.41005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.1036 90.5728C63.1036 90.4556 63.009 90.361 62.8919 90.3619C62.7747 90.3619 62.6801 90.4565 62.6801 90.5736C62.6801 90.6908 62.7747 90.7854 62.8919 90.7854C63.0081 90.7854 63.1036 90.6908 63.1036 90.5728Z"
        fill="#5C5E62"
      />
      <path
        d="M63.1036 90.5728C63.1036 90.4556 63.009 90.361 62.8919 90.3619C62.7747 90.3619 62.6801 90.4565 62.6801 90.5736C62.6801 90.6908 62.7747 90.7854 62.8919 90.7854C63.0081 90.7854 63.1036 90.6908 63.1036 90.5728"
        stroke="#36383B"
        strokeWidth="1.41005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.5743 90.5728C66.5743 90.4556 66.4797 90.361 66.3626 90.3619C66.2454 90.3619 66.1508 90.4565 66.1508 90.5736C66.1508 90.6908 66.2454 90.7854 66.3626 90.7854C66.4788 90.7854 66.5743 90.6908 66.5743 90.5728Z"
        fill="#5C5E62"
      />
      <path
        d="M66.5743 90.5728C66.5743 90.4556 66.4797 90.361 66.3626 90.3619C66.2454 90.3619 66.1508 90.4565 66.1508 90.5736C66.1508 90.6908 66.2454 90.7854 66.3626 90.7854C66.4788 90.7854 66.5743 90.6908 66.5743 90.5728"
        stroke="#36383B"
        strokeWidth="1.41005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.045 90.5728C70.045 90.4556 69.9504 90.361 69.8333 90.3619C69.7161 90.3619 69.6216 90.4565 69.6216 90.5736C69.6216 90.6908 69.7161 90.7854 69.8333 90.7854C69.9504 90.7854 70.045 90.6908 70.045 90.5728Z"
        fill="#5C5E62"
      />
      <path
        d="M70.045 90.5728C70.045 90.4556 69.9504 90.361 69.8333 90.3619C69.7161 90.3619 69.6216 90.4565 69.6216 90.5736C69.6216 90.6908 69.7161 90.7854 69.8333 90.7854C69.9504 90.7854 70.045 90.6908 70.045 90.5728"
        stroke="#36383B"
        strokeWidth="1.41005"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_123_168665"
          x1="59.9355"
          y1="103.693"
          x2="158.128"
          y2="9.6697"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#74B740" />
          <stop offset="1" stopColor="#74B740" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { DevicePasswordChanged };
