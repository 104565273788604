import styled from "styled-components";
import { NewAddressProps } from "typing";
import { theme } from "ui";
import { ButtonSubmit } from "../ButtonSubmit";
import { Checkbox, Label, NewCustomInput } from "../Form";
import { Link } from "../Link";
import { Loading } from "../Loading";

export interface HouseData extends NewAddressProps {
  noNumber?: boolean;
}

interface BottomSheetProps {
  isdisplayedonbottomsheet?: boolean;
}

interface WrapperRowProps extends BottomSheetProps {
  hasTwoColumns?: boolean;
}

interface CheckoutPageProps {
  isCheckoutPage?: boolean;
}

interface StateSelectProps {
  hasError?: boolean;
  hasSuccessfully?: boolean;
  disabled?: boolean;
  purchaseScreen?: boolean;
}

export const IconLoading = styled(Loading)`
  margin-right: 1rem;
`;

export const ExtraInfoCEP = styled.section<BottomSheetProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: ${({ isdisplayedonbottomsheet }) =>
    isdisplayedonbottomsheet ? "0" : "5rem"};
  gap: 0.5rem;
  flex-direction: ${({ isdisplayedonbottomsheet }) =>
    isdisplayedonbottomsheet ? "row-reverse" : "row"};
  @media ${theme.device.desktopFirst.mobileXL} {
    width: 100%;
    grid-template-rows: 1fr;
    place-items: center;
  }
`;

export const DescriptionText = styled.span<BottomSheetProps>`
  font-size: 15px;
  color: ${theme.colors.neutral["400"]};
  display: flex;
  align-items: center;
  font-family: ${theme.typography.fontFamily.barlow};

  @media ${theme.device.desktopFirst.laptopL} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }
`;

export const BoxButtonDefault = styled.section`
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const FormNewAddressContainer = styled.form`
  width: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    height: 80vh;
    grid-template-rows: 1fr;
  }

  @media ${theme.device.mobileFirst.mobileM} {
    height: 100%;
  }
`;

export const SpaceMobileTop = styled.section`
  padding: 0.5rem;
`;

export const SpaceMobileBottom = styled.section`
  padding: 2rem;
`;
export const FormNewAddressContent = styled.section<CheckoutPageProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const WrapperContentHouse = styled.div<BottomSheetProps>`
  display: flex;
  gap: 4rem;
  align-items: flex-end;

  @media ${theme.device.desktopFirst.tabletXL} {
    gap: ${theme.space["x4.5"]};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    align-items: center;
  }
`;

export const AlignWrapperInput = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const WrapperInput = styled.div<{ fully?: boolean }>`
  width: 100%;
  height: fit-content;

  @media (max-width: 736px) {
    width: 100%;
  }
`;

export const WrapperCheckBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const WrapperSelect = styled.div`
  width: 100%;
  max-width: 240px;
  height: fit-content;
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.space.x6};
  width: 100%;
  height: fit-content;
`;

export const FormContainer = styled.section<WrapperRowProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${theme.space.x6};
  row-gap: ${theme.space.x6};
  max-width: 800px;
  max-height: 1200px;
  height: 100%;
  margin-bottom: ${theme.space.x6};

  @media ${theme.device.desktopFirst.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, 100px);
    height: fit-content;
    row-gap: ${theme.space["x3.5"]};
  }

  @media ${theme.device.desktopFirst.mobileM} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(9, 80px);
    height: fit-content;
    row-gap: ${theme.space.x2};
  }
`;

export const CheckDoneSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: absolute;
  right: 20%;
  margin-top: -1rem;
  @media ${theme.device.desktopFirst.mobileL} {
    width: 4rem;
    left: 7rem;
  }
`;

export const CustomLabelNewAddress = styled(Label)`
  font-weight: 400;
  font-family: ${theme.typography.fontFamily.chivo};
  font-size: ${theme.typography.fontSize.sm.x3};
`;

export const CustomInputNewAddress = styled(NewCustomInput)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  background-color: ${({ disabled }) =>
    disabled ? theme.colors.neutral["100"] : theme.colors.neutral.white};
`;

export const CustomCheckboxNewAddress = styled(Checkbox)`
  &:checked {
    background-color: ${theme.colors.secondary["300"]} !important;
    border-color: ${theme.colors.secondary["300"]} !important;
  }
`;

export const StateSelect = styled.select<StateSelectProps>`
  font-size: 1.2rem;
  background: ${theme.colors.neutral.white};
  border: thin solid ${theme.colors.neutral["200"]};
  color: thin solid ${theme.colors.neutral["450"]};
  border-radius: ${theme.space.x2};
  padding: 0 0.8rem;
  margin: ${`${theme.space.x2} ${theme.space.x0}`};
  height: 50px;

  border-color: ${({ hasSuccessfully }) =>
    hasSuccessfully ? theme.colors.secondary["300"] : ""};
  border-color: ${({ hasError, hasSuccessfully }) =>
    hasError && !hasSuccessfully ? theme.colors.primary["300"] : ""};

  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  background-color: ${({ disabled }) =>
    disabled ? theme.colors.neutral["100"] : theme.colors.neutral.white};

  width: 100%;
`;

export const ButtonRegisterAction = styled(ButtonSubmit).attrs({
  fontSize: theme.typography.fontSize.sm.x7,
  height: "3.75rem",
  fontWeight: 400,
  borderRadius: "8px",
})`
  padding: 0 16px;
  transition: background-color 0.2s ease-in;
  align-self: center;
`;

export const OptionStates = styled.option``;

export const LinkCustom = styled(Link)`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;

  @media ${theme.device.desktopFirst.tablet} {
    padding-top: 0;
  }
`;

export const AddressHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${theme.space["x4.5"]};
  width: 100%;

  @media ${theme.device.desktopFirst.mobileXL} {
    justify-content: flex-start;
    gap: ${theme.space.x2};
  }
`;

export const CloseButton = styled.div`
  height: 2rem;
  cursor: pointer;

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-left: -${theme.space.x2};
    display: flex;
    align-items: center;
  }
`;

export const AddressHeaderTitle = styled.span`
  font-size: ${theme.typography.fontSize.md.x1};
  color: ${theme.colors.neutral["525"]};
  font-family: ${theme.typography.fontFamily.chivo};
  font-weight: ${theme.typography.fontWeight.bold};
`;

export const BoxButton = styled.section`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  padding: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.neutral.white};
`;

export const FormMyAddressWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${theme.space["x4.5"]};
  margin-bottom: ${theme.space["x4.5"]};
`;
