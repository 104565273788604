import { useEffect } from "react";
import { ClientAction, TwoFactorOptionType } from "typing";

type InitializeTwoFactorProps = {
  clientAction: ClientAction;
  email: string | null;
  phoneNumber: string | null;
  canChooseAnotherTwoFactorMethod: boolean;
  setClientAction: (value: ClientAction) => void;
  setEmail: (value: string) => void;
  setPhoneNumber: (value: string) => void;
  setCanChooseAnotherTwoFactorMethod: (value: boolean) => void;
  options?: TwoFactorOptionType[] | null;
};

const useInitializeTwoFactor = ({
  clientAction,
  email,
  phoneNumber,
  canChooseAnotherTwoFactorMethod,
  setClientAction,
  setEmail,
  setPhoneNumber,
  setCanChooseAnotherTwoFactorMethod,
  options,
}: InitializeTwoFactorProps) => {
  useEffect(() => {
    setClientAction(clientAction);
    setCanChooseAnotherTwoFactorMethod(canChooseAnotherTwoFactorMethod);

    if (email) {
      setEmail(email);
    }

    if (phoneNumber) {
      setPhoneNumber(phoneNumber);
    }
  }, [
    canChooseAnotherTwoFactorMethod,
    clientAction,
    email,
    options,
    phoneNumber,
    setCanChooseAnotherTwoFactorMethod,
    setClientAction,
    setEmail,
    setPhoneNumber,
  ]);
};

export { useInitializeTwoFactor };
