import { ShoppingApi } from "../../services";

interface SendItemToCartByServerProps {
  productId: string;
  quantity?: number;
  shoppingApi: ShoppingApi;
}

export const sendItemToCartByServer = async ({
  productId,
  quantity = 1,
  shoppingApi,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
SendItemToCartByServerProps) => {
  function getCookie(cname: string) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");

    // eslint-disable-next-line no-restricted-syntax
    for (const element of ca) {
      let c = element;
      while (c.startsWith(" ")) {
        c = c.substring(1);
      }
      if (c.startsWith(name)) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const accessToken = getCookie("@FC:Ecom:Token:Access");
  const branchId =
    getCookie("@FC:Ecom:Dropdown:BranchLocation") ||
    getCookie("@FC:Ecom:Modal:BranchLocation") ||
    "2";
  const cartId = getCookie("@FC:Ecom:Cart:Id");

  if (cartId && branchId) {
    shoppingApi
      .addItemsCartByServer(
        {
          cartId: Number(cartId),
          productId,
          quantity,
          package: 0,
          packingQuantity: 1,
          sellerId: 0,
          price: 0,
          branchId: Number(branchId),
          listId: 0,
        },
        accessToken || ""
      )
      .then((res) => {
        if (res?.status?.toString().startsWith("2")) {
          // eslint-disable-next-line no-console
          console.log("Insider - Produto adicionando com sucesso!");
          window.location.reload();
        } else {
          // eslint-disable-next-line no-console
          console.log("Insider - Erro ao adicionar o produto.");
        }
      });
  } else {
    // eslint-disable-next-line no-console
    console.log(
      `Insider - Não foi possível adicionar o produto ao carrinho: ${
        !cartId ? "CartId não encontrado" : ""
      } ${!branchId ? "BranchId não encontrado" : ""}
      `
    );
  }
};
