import { IconProps } from "typing";
import { theme } from "../themes";

export const PigIcon = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M15.6333 6.71667C15.6333 8.68997 14.0124 10.3 12 10.3C9.98761 10.3 8.36667 8.68997 8.36667 6.71667C8.36667 4.74337 9.98761 3.13334 12 3.13334C14.0124 3.13334 15.6333 4.74337 15.6333 6.71667Z"
        stroke={color}
      />
      <path
        d="M9.03327 8.55377C6.63317 9.54729 5 11.5078 5 13.7638C5 15.8595 6.40922 17.7002 8.53333 18.7476L8.46667 20.8H10.8V19.4833C11.3568 19.5838 12.0204 19.6667 12.6167 19.6667C13.2129 19.6667 13.7932 19.6136 14.35 19.5131V20.8H16.7V18.7476C17.9895 18.1117 19.0156 17.1835 19.6228 16.0833H21.4V12.5833H20.063C19.8646 11.8685 19.3978 11.1152 18.8986 10.5111L20.9319 8.47581C20.25 7.8 18.15 6.4 15.7 8.71667C15.4681 8.62616 15.1279 8.38758 14.8833 8.31667"
        stroke={color}
      />
      <path
        d="M4.83333 13.75C4.49457 13.7733 2.89327 13.4143 2.68333 11.9833"
        stroke={color}
        strokeLinecap="square"
      />
      <rect
        x="16.3917"
        y="12.2917"
        width="0.583333"
        height="0.583333"
        stroke={color}
        strokeWidth="0.583333"
      />
      <path
        d="M11.2833 7.63333C11.426 7.70841 11.7344 7.78012 12.0333 7.77529M12.6167 5.54999C12.4025 5.45597 12.2063 5.4127 12.0333 5.41145M12.0333 4.93333V5.41145M12.0333 5.41145C11.6543 5.40873 11.3863 5.6077 11.2833 5.91667C11.2333 6.3 11.5333 6.49445 12.0833 6.61667C12.8333 6.78333 12.7667 7.28333 12.7667 7.28333C12.7484 7.64965 12.3979 7.76939 12.0333 7.77529M12.0333 7.77529V8.3"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="square"
      />
    </svg>
  );
};
