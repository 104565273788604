import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { CropImageProps } from "typing";

import { Container, RangeInput, RangeContainer } from "./styles";

interface CropArea {
  x: number;
  y: number;
}

interface CroppedAreaPixels {
  width: number;
  height: number;
  x: number;
  y: number;
}

const ReactEasyCrop = ({ imageInfo, croppedImage }: CropImageProps) => {
  const [crop, setCrop] = useState<CropArea>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  const onCropComplete = useCallback(
    async (_: unknown, croppedAreaPixels: CroppedAreaPixels) => {
      await new Promise(() => {
        const image = new Image();

        image.src = imageInfo?.imageSrc || "";
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = croppedAreaPixels.width;
          canvas.height = croppedAreaPixels.height;

          ctx?.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
          );
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const file = new File([blob], imageInfo?.name || "", {
                  type: imageInfo?.type,
                });

                croppedImage(file);
              }
            },
            imageInfo?.type,
            0.7
          );
        };
      });
    },
    [imageInfo, croppedImage]
  );

  return (
    <Container>
      <Cropper
        image={imageInfo?.imageSrc}
        crop={crop}
        zoom={zoom}
        showGrid={false}
        cropShape={imageInfo?.formatPhoto === "Profile" ? "round" : "rect"}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
        cropSize={
          imageInfo.formatPhoto === "Profile"
            ? { width: 215, height: 215 }
            : { width: 300, height: 100 }
        }
      />
      <RangeContainer>
        <RangeInput
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={(e) => setZoom(Number(e.target.value))}
        />
      </RangeContainer>
    </Container>
  );
};

export { ReactEasyCrop };
