import { buildTestIds } from "application";
import { PasteIcon } from "ui";
import { ClipboardText, PasteCodeContainer, PasteCodeIcon } from "./styles";

interface PasteCodeUIProps {
  handlePaste: () => void;
}

const PasteCodeUI = ({ handlePaste }: PasteCodeUIProps) => {
  return (
    <PasteCodeContainer
      onClick={handlePaste}
      {...buildTestIds("paste-code-container")}
    >
      <PasteCodeIcon {...buildTestIds("paste-code-icon")}>
        <PasteIcon width={16} height={16} />
      </PasteCodeIcon>
      <ClipboardText {...buildTestIds("paste-code-text")}>
        Colar código
      </ClipboardText>
    </PasteCodeContainer>
  );
};

export { PasteCodeUI };
