import { useState, useEffect } from "react";

const useTimer = (initialTime: number) => {
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    setRemainingTime(initialTime);
  }, [initialTime]);

  useEffect(() => {
    const formatTime = (totalSeconds: number) => {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = Math.floor(totalSeconds % 60);

      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");
      return `${formattedMinutes}:${formattedSeconds}`;
    };

    const idTime = setTimeout(() => {
      if (remainingTime) {
        setRemainingTime(remainingTime - 1);
        setFormattedTime(formatTime(remainingTime));
      }
    }, 1000) as unknown as number;

    return () => clearTimeout(idTime);
  }, [initialTime, remainingTime]);

  return { formattedTime, remainingTime };
};

export { useTimer };
