import { TwoFactorContentProps } from "typing";
import { usePhoneValidationComponentData } from "./data";
import { PhoneValidationComponentUI } from "./ui";

const PhoneValidationComponent = () => {
  const { getPhoneValidationComponentData } = usePhoneValidationComponentData();

  const phoneValidationComponentData = Object.fromEntries(
    getPhoneValidationComponentData()
  ) as unknown as TwoFactorContentProps;

  return <PhoneValidationComponentUI {...phoneValidationComponentData} />;
};

export { PhoneValidationComponent };
