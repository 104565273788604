import { AllConfigs } from "typing";
import { theme } from "ui";
import {
  BagIcon,
  CartIcon,
  DollarIcon,
  GiftIcon,
  HouseIcon,
  ProfileIcon,
  SecurityIcon,
  UserIcon,
} from "../styles";

export type UserAuthenticatedAction = {
  id: number;
  title: string;
  path: string;
  icon: (color: string) => JSX.Element;
};

export const userAuthenticatedActions = (
  allConfigs?: AllConfigs
): UserAuthenticatedAction[] => {
  return [
    {
      id: 8,
      title: "Minha conta",
      path: "/conta/",
      icon: (color: string = theme.colors.neutral["450"]) => (
        <ProfileIcon color={color} />
      ),
    },
    {
      id: 1,
      title: "Meus pedidos",
      path: "/conta/meus-pedidos",
      icon: (color: string = theme.colors.neutral["760"]) => (
        <CartIcon color={color} />
      ),
    },
    {
      id: 2,
      title: `Pedidos telefone/WhatsApp`,
      path: "/conta/pedidos-eletronicos",
      icon: (color: string = theme.colors.neutral["760"]) => (
        <BagIcon color={color} />
      ),
    },
    {
      id: 3,
      title: "Meus dados",
      path: "/conta/meus-dados",
      icon: (color: string = theme.colors.neutral["760"]) => (
        <UserIcon color={color} />
      ),
    },
    {
      id: 4,
      title: "Segurança",
      path:
        allConfigs?.isSecurityPageAvailable === "true"
          ? "/conta/seguranca"
          : "/conta/seguranca/alterar-senha",
      icon: (color: string = theme.colors.neutral["760"]) => (
        <SecurityIcon color={color} />
      ),
    },
    {
      id: 5,
      title: "Meus cartões",
      path: "/conta/meus-cartoes",
      icon: (color: string = theme.colors.neutral["760"]) => (
        <DollarIcon color={color} />
      ),
    },
    {
      id: 6,
      title: "Meus endereços",
      path: "/conta/meus-enderecos",
      icon: (color: string = theme.colors.neutral["760"]) => (
        <HouseIcon color={color} />
      ),
    },
    {
      id: 7,
      title: "Minhas listas",
      path: "/conta/minhas-listas",
      icon: (color: string = theme.colors.neutral["760"]) => (
        <GiftIcon color={color} />
      ),
    },
  ];
};
