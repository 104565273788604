import { useForm } from "react-hook-form";
import { IFormValues } from "typing";
import { useUniqueClientFeedbackData } from "./data";
import { UniqueClientFeedbackUI } from "./ui";

const UniqueClientFeedback = () => {
  const { register } = useForm<IFormValues>();
  const { getUniqueClientFeedback } = useUniqueClientFeedbackData();
  return (
    <UniqueClientFeedbackUI
      register={register}
      {...getUniqueClientFeedback()}
    />
  );
};

export { UniqueClientFeedback };
