import { MultipleColorsIconProps } from "typing";

export const UniqueClientSecondaryMobileImage = ({
  width = 47,
  height = 93,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        borderRadius: "0 8px 8px 0",
        height: "100%",
        width: "100%",
        maxHeight: "93px",
        maxWidth: "47px",
      }}
    >
      <path d="M46.8422 0H0.842224V46H46.8422V0Z" fill="#AA0E27" />
      <path
        d="M20.3 36.9866H10.7751C10.1085 36.9866 9.56799 36.4461 9.56799 35.7794V19.3841C9.56799 18.8736 9.98238 18.4593 10.4929 18.4593H20.0238C20.5342 18.4593 20.9486 18.8736 20.9486 19.3841V35.3891C21.1228 35.5572 21.2309 35.7915 21.2309 36.0557C21.2309 36.5662 20.8165 36.9866 20.306 36.9866M11.4237 35.1308H19.0989V20.315H11.4237V35.1308Z"
        fill="#ED5568"
      />
      <path
        d="M35.8364 36.9866H26.3115C25.801 36.9866 25.3867 36.5722 25.3867 36.0557C25.3867 35.7975 25.4948 35.5632 25.6689 35.3891V19.3841C25.6689 18.8736 26.0833 18.4593 26.5938 18.4593H36.1187C36.6292 18.4593 37.0436 18.8736 37.0436 19.3841V35.7794C37.0436 36.4461 36.503 36.9866 35.8364 36.9866ZM27.5186 35.1308H35.1938V20.315H27.5186V35.1308Z"
        fill="#ED5568"
      />
      <path
        d="M37.6623 20.3149H26.5879C26.0775 20.3149 25.6631 19.9005 25.6631 19.39V13.4865C25.4889 13.3184 25.3808 13.0841 25.3808 12.8199C25.3808 12.3094 25.7952 11.895 26.3057 11.895H37.6623C38.3289 11.895 38.8694 12.4355 38.8694 13.1021V19.1078C38.8694 19.7744 38.3289 20.3149 37.6623 20.3149ZM27.5188 18.4592H37.0197V13.7447H27.5188V18.4592Z"
        fill="#ED5568"
      />
      <path
        d="M20.0237 20.3149H8.94938C8.28276 20.3149 7.74225 19.7744 7.74225 19.1078V13.1022C7.74225 12.4355 8.28276 11.895 8.94938 11.895H20.306C20.8165 11.895 21.2309 12.3094 21.2309 12.8199C21.2309 13.0781 21.1228 13.3184 20.9486 13.4865V19.39C20.9486 19.9005 20.5342 20.3149 20.0237 20.3149ZM9.59798 18.4592H19.0989V13.7448H9.59798V18.4592Z"
        fill="#ED5568"
      />
      <path
        d="M23.6091 13.7447H16.3784C14.1863 13.7447 12.0303 11.7569 12.0243 9.73297C12.0243 8.96425 12.2285 7.58897 13.5858 7.07849C14.907 6.58002 16.9429 7.12653 19.6454 8.70601C21.7654 9.94317 23.6271 11.4326 24.3058 12.2073C24.546 12.4835 24.6 12.8679 24.4499 13.2042C24.2998 13.5345 23.9695 13.7507 23.6031 13.7507M14.6548 8.76006C14.4806 8.76006 14.3425 8.78408 14.2344 8.82012C14.1383 8.85615 13.874 8.95825 13.874 9.73898C13.874 10.7479 15.2073 11.895 16.3724 11.895H21.0988C20.4201 11.3905 19.5974 10.838 18.7085 10.3155C16.7327 9.16244 15.3934 8.76006 14.6548 8.76006Z"
        fill="#ED5568"
      />
      <path
        d="M30.2272 13.7448H22.9965C22.6361 13.7448 22.3058 13.5346 22.1497 13.1983C21.9996 12.8679 22.0536 12.4776 22.2938 12.2013C22.9665 11.4266 24.8342 9.9372 26.9542 8.70605C29.6567 7.13258 31.6926 6.58007 33.0138 7.07853C34.3711 7.58901 34.5753 8.95829 34.5753 9.73301C34.5753 11.7569 32.4193 13.7448 30.2212 13.7448M25.4948 11.889H30.2212C31.3863 11.889 32.7196 10.742 32.7196 9.73301C32.7196 8.95228 32.4613 8.85619 32.3592 8.82016C31.7947 8.60996 30.3233 8.89223 27.8851 10.3156C26.9902 10.838 26.1735 11.3906 25.4948 11.895V11.889Z"
        fill="#ED5568"
      />
      <path
        d="M26.2816 36.9866H20.33C19.6514 36.9866 19.0928 36.4341 19.0928 35.7495V13.1263C19.0928 12.4417 19.6453 11.8892 20.33 11.8892H26.2816C26.9602 11.8892 27.5187 12.4417 27.5187 13.1263V35.7495C27.5187 36.4281 26.9662 36.9866 26.2816 36.9866ZM20.9486 35.1309H25.663V13.7449H20.9486V35.1309Z"
        fill="#ED5568"
      />
      <path d="M47 46H0.842224V92.1578H47V46Z" fill="#C1D82F" />
      <path
        d="M26.3439 80.9678H12.7076C11.2023 80.9678 9.97736 79.7425 9.97736 78.2367V59.9458C9.97736 58.44 11.2023 57.2147 12.7076 57.2147H26.3439C27.8492 57.2147 29.0741 58.44 29.0741 59.9458V63.9219C29.0741 64.3401 28.7346 64.6797 28.3165 64.6797C27.8984 64.6797 27.5589 64.3401 27.5589 63.9219V59.9458C27.5589 59.2766 27.0129 58.7353 26.3488 58.7353H12.7125C12.0435 58.7353 11.5023 59.2766 11.5023 59.9458V78.2367C11.5023 78.9059 12.0435 79.4472 12.7125 79.4472H26.3488C27.0178 79.4472 27.5589 78.9059 27.5589 78.2367V74.4722C27.5589 74.054 27.8984 73.7144 28.3165 73.7144C28.7346 73.7144 29.0741 74.054 29.0741 74.4722V78.2367C29.0741 79.7425 27.8492 80.9678 26.3439 80.9678Z"
        fill="#E3EDA1"
      />
      <path
        d="M24.9811 62.6474H14.0603C13.6422 62.6474 13.3027 62.3079 13.3027 61.8896C13.3027 61.4713 13.6422 61.1268 14.0603 61.1268H24.9811C25.3993 61.1268 25.7387 61.4664 25.7387 61.8896C25.7387 62.3128 25.3993 62.6474 24.9811 62.6474Z"
        fill="#E3EDA1"
      />
      <path
        d="M21.9312 67.4403H14.0603C13.6422 67.4403 13.3027 67.1007 13.3027 66.6825C13.3027 66.2642 13.6422 65.9246 14.0603 65.9246H21.9312C22.3493 65.9246 22.6888 66.2642 22.6888 66.6825C22.6888 67.1007 22.3493 67.4403 21.9312 67.4403Z"
        fill="#E3EDA1"
      />
      <path
        d="M21.8132 72.2381H14.0653C13.6472 72.2381 13.3077 71.8985 13.3077 71.4803C13.3077 71.062 13.6472 70.7225 14.0653 70.7225H21.8132C22.2313 70.7225 22.5708 71.062 22.5708 71.4803C22.5708 71.8985 22.2313 72.2381 21.8132 72.2381Z"
        fill="#E3EDA1"
      />
      <path
        d="M24.9811 77.0311H14.0603C13.6422 77.0311 13.3027 76.6915 13.3027 76.2732C13.3027 75.855 13.6422 75.5154 14.0603 75.5154H24.9811C25.3993 75.5154 25.7387 75.855 25.7387 76.2732C25.7387 76.6915 25.3993 77.0311 24.9811 77.0311Z"
        fill="#E3EDA1"
      />
      <path
        d="M35.8971 80.3034C35.3707 80.3034 34.8738 80.0968 34.5049 79.7277L29.4331 74.6543C28.4099 75.1513 27.2588 75.3531 26.1224 75.23C24.7598 75.0824 23.4758 74.4673 22.5067 73.4979C21.3654 72.3562 20.7358 70.8357 20.7358 69.2167C20.7358 67.5978 21.3654 66.0772 22.5067 64.9356C23.648 63.7939 25.1681 63.1641 26.7865 63.1641C28.405 63.1641 29.9201 63.7939 31.0663 64.9356C32.0354 65.905 32.6503 67.1893 32.7979 68.5524C32.9209 69.6891 32.7192 70.8455 32.2223 71.8642L37.2941 76.9376C37.668 77.3116 37.8746 77.8036 37.8746 78.3351C37.8746 78.8666 37.668 79.3586 37.2941 79.7326C36.9203 80.1066 36.4283 80.3084 35.8971 80.3084M29.561 72.9566C29.7578 72.9566 29.9496 73.0304 30.0972 73.178L35.5724 78.655C35.656 78.7386 35.7692 78.7878 35.8921 78.7878C36.0151 78.7878 36.1283 78.7386 36.2119 78.655C36.2955 78.5713 36.3447 78.4581 36.3447 78.3351C36.3447 78.2121 36.2955 78.0989 36.2119 78.0152L30.7367 72.5383C30.4858 72.2874 30.4415 71.8986 30.6285 71.5984C31.1598 70.7422 31.391 69.7187 31.2778 68.7148C31.1647 67.6814 30.717 66.7464 29.9791 66.0083C29.1232 65.1521 27.9868 64.6797 26.7767 64.6797C25.5665 64.6797 24.4252 65.1521 23.5693 66.0083C22.7133 66.8645 22.2411 68.0013 22.2411 69.2118C22.2411 70.4223 22.7133 71.564 23.5693 72.4202C24.3072 73.1583 25.2419 73.6061 26.2749 73.7193C27.2784 73.8276 28.3017 73.5963 29.1576 73.0648C29.2806 72.991 29.4183 72.9517 29.5561 72.9517L29.561 72.9566Z"
        fill="#E3EDA1"
      />
    </svg>
  );
};
