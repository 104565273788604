import styled from "styled-components";
import { theme } from "ui";

interface StyledFieldErrorProps {
  color?: string;
  fontSize?: string;
  padding?: string;
}

export const StyledFieldError = styled.div<StyledFieldErrorProps>`
  color: ${({ color }) => color || theme.colors.primary["200"]} !important;
  font-size: ${({ fontSize }) => fontSize || theme.typography.fontSize.sm.x3};
  padding: ${({ padding }) => padding || `${theme.space.x0} ${theme.space.x2}`};
`;
