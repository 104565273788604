import { IconProps } from "typing";
import { theme } from "../../themes";

export const PhoneAuthenticationIcon = ({
  width = theme.space.x15,
  height = theme.space.x15,
  strokeWidth = "1.5",
  alt = "Autenticação por Telefone",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="60" height="60" rx="30" fill="#F6F7F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9658 35.8623C41.9392 34.129 42.5005 32.1316 42.5005 30.001C42.5005 23.373 37.1285 18.001 30.5005 18.001C23.8725 18.001 18.5005 23.373 18.5005 30.001C18.5005 36.629 23.8725 42.001 30.5005 42.001C32.6312 42.001 34.6285 41.4396 36.3618 40.4663L42.5005 42.001L40.9658 35.8623Z"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2646 30.4316C30.3953 30.301 30.606 30.301 30.7366 30.4316C30.8673 30.5623 30.8673 30.773 30.7366 30.9036C30.606 31.0343 30.3953 31.0343 30.2646 30.9036C30.1353 30.773 30.1353 30.5623 30.2646 30.4316"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9311 30.4316C25.0618 30.301 25.2725 30.301 25.4031 30.4316C25.5338 30.5623 25.5338 30.773 25.4031 30.9036C25.2725 31.0343 25.0618 31.0343 24.9311 30.9036C24.8018 30.773 24.8018 30.5623 24.9311 30.4316"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5976 30.4316C35.7283 30.301 35.939 30.301 36.0696 30.4316C36.2003 30.5623 36.2003 30.773 36.0696 30.9036C35.939 31.0343 35.7283 31.0343 35.5976 30.9036C35.4683 30.773 35.4683 30.5623 35.5976 30.4316"
        stroke="#6D6F73"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
