const capitalize = (text: string): string => {
  try {
    if (typeof text !== "string")
      throw new Error("capitalize: text must be a string");
    return text?.[0]
      ? text[0].toUpperCase() + text.substring(1).toLowerCase()
      : text;
  } catch {
    return "";
  }
};

const capitalizeAll = (text: string): string => {
  try {
    if (typeof text !== "string")
      throw new Error("capitalizeAll: text must be a string");

    return text
      .split(" ")
      .map((word) =>
        word?.[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : ""
      )
      .join(" ");
  } catch {
    return "";
  }
};

const capitalizeFirstLetterAndLowercaseRest = (text: string): string => {
  try {
    if (typeof text !== "string")
      throw new Error(
        "capitalizeFirstLetterAndLowercaseRest: text must be a string"
      );

    if (!text?.length) return "";

    return text
      ?.split(" ")
      ?.map((word, index) =>
        index !== 0
          ? word?.toLocaleLowerCase()
          : `${word?.[0]?.toLocaleUpperCase()}${word
              ?.slice(1)
              ?.toLocaleLowerCase()}`
      )
      ?.join(" ");
  } catch {
    return "";
  }
};
export { capitalize, capitalizeFirstLetterAndLowercaseRest, capitalizeAll };
