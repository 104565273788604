type ValidatePasswordResponse =
  | ""
  | "A sua senha deve conter pelo menos 6 caracteres"
  | "O campo senha é obrigatório!"
  | "Senha inválida!"
  | "Senha incorreta, tente novamente!"
  | string;

export const validatePassword = (
  password: string,
  passwordStrengthTextError = "A sua senha deve conter pelo menos 8 caracteres"
): ValidatePasswordResponse => {
  const passwordIsRequired = "O campo senha é obrigatório!";
  const invalidPassword = "Senha inválida!";
  const passwordShouldContainEightCharacters = passwordStrengthTextError;
  const mustContainNumber = "A senha deve conter pelo menos um número!";
  const mustContainUppercase =
    "A senha deve conter pelo menos uma letra maiúscula!";
  const mustContainLowercase =
    "A senha deve conter pelo menos uma letra minúscula!";
  const blank = "";

  if (typeof password !== "string") return invalidPassword;
  if (!password) return passwordIsRequired;
  if (password?.length < 8) return passwordShouldContainEightCharacters;
  if (!/\d/.test(password)) return mustContainNumber;
  if (!/[A-Z]/.test(password)) return mustContainUppercase;
  if (!/[a-z]/.test(password)) return mustContainLowercase;

  return blank;
};

export const validateConfirmPassword = (
  password: string,
  confirmPassword: string,
  passwordStrengthTextError = "Senha incorreta, tente novamente!"
): ValidatePasswordResponse => {
  const passwordShouldMatch = passwordStrengthTextError;
  const blank = "";

  if (
    (password?.length > 0 && confirmPassword && password !== confirmPassword) ||
    (password?.length === 0 && confirmPassword?.length > 0)
  )
    return passwordShouldMatch;
  return blank;
};
