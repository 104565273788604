import { IUniqueClientActions } from "typing";
import { CloseIcon, theme } from "ui";
import { BottomSheet } from "../../../BottomSheet";
import { UniqueClientContent } from "../UniqueClientContent";
import { ModalGeneral } from "../../../ModalGeneral";

const UniqueClientModalUI = ({
  closeModal,
  modalTitle,
  isModalOpen,
  isMobile,
}: IUniqueClientActions) => {
  if (isMobile) {
    return (
      <BottomSheet
        omitHeader={false}
        title={modalTitle}
        onCloseBottomSheet={closeModal}
        stateBottomSheet={isModalOpen}
        noFooter
        hasActionInHeader
        headerAction={closeModal}
        textButton={
          <CloseIcon
            width={24}
            height={24}
            color={theme.colors.neutral["350"]}
          />
        }
        justifyContent="space-between"
        display="flex"
        onDismiss={closeModal}
        padding={`${theme.space.x0} ${theme.space.x0} ${theme.space.x2}`}
        paddingContent={`${theme.space.x0} ${theme.space.x4}`}
      >
        <UniqueClientContent />
      </BottomSheet>
    );
  }

  return (
    <ModalGeneral
      contentGap={theme.space.x0}
      isOpen={isModalOpen}
      onCloseModal={closeModal}
      title={modalTitle}
      closeButtonSize={24}
      fontWeight={theme.typography.fontWeight["400"]}
      bodyContent={<UniqueClientContent />}
      ariaLabelForModal={modalTitle}
    />
  );
};

export { UniqueClientModalUI };
