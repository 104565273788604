import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const ArrowDown = ({
  alt = "Seta para baixo",
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M14 15.9393L19.4697 10.4697C19.7626 10.1768 20.2374 10.1768 20.5303 10.4697C20.8232 10.7626 20.8232 11.2374 20.5303 11.5303L14.5303 17.5303C14.2374 17.8232 13.7626 17.8232 13.4697 17.5303L7.46967 11.5303C7.17678 11.2374 7.17678 10.7626 7.46967 10.4697C7.76256 10.1768 8.23744 10.1768 8.53033 10.4697L14 15.9393Z"
        fill={color}
      />
      <mask
        id="mask0_0_2440"
        maskUnits="userSpaceOnUse"
        x="7"
        y="10"
        width="14"
        height="8"
      >
        <path
          d="M14 15.9393L19.4697 10.4697C19.7626 10.1768 20.2374 10.1768 20.5303 10.4697C20.8232 10.7626 20.8232 11.2374 20.5303 11.5303L14.5303 17.5303C14.2374 17.8232 13.7626 17.8232 13.4697 17.5303L7.46967 11.5303C7.17678 11.2374 7.17678 10.7626 7.46967 10.4697C7.76256 10.1768 8.23744 10.1768 8.53033 10.4697L14 15.9393Z"
          fill={secondColor}
        />
      </mask>
      <g mask="url(#mask0_0_2440)" />
    </svg>
  );
};
