import { IconProps } from "typing";
import { theme } from "../themes";

export const NewEyeOpenIcon = ({
  width = 16,
  height = 16,
  color = theme.colors.neutral["550"],
  alt = "ícone de olho aberto",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.07865 8.31137C1.97399 8.11737 1.97399 7.88204 2.07865 7.68804C3.33999 5.35537 5.66999 3.33337 7.99999 3.33337C10.33 3.33337 12.66 5.35537 13.9213 7.68871C14.026 7.88271 14.026 8.11804 13.9213 8.31204C12.66 10.6447 10.33 12.6667 7.99999 12.6667C5.66999 12.6667 3.33999 10.6447 2.07865 8.31137Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.41421 6.58579C10.1953 7.36683 10.1953 8.63316 9.41421 9.41421C8.63316 10.1953 7.36683 10.1953 6.58578 9.41421C5.80474 8.63316 5.80474 7.36683 6.58578 6.58579C7.36683 5.80474 8.63316 5.80474 9.41421 6.58579"
        stroke={color}
        strokeWidth="1.4286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
