import {
  buildTestIds,
  useAppContext,
  useAuth,
  useAuthentication,
  useShallow,
  useToast,
  validCpfRegex,
  validEmailRegex,
} from "application";
import { NextRouter, useRouter } from "next/router";
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { PasswordChangeDuePurchaseTimeDTO, UserProps } from "typing";
import { FlexContainer, theme } from "ui";
import { ButtonSubmit } from "../../../../../ButtonSubmit";
import { ModalAuthUserProps, authPropsFormValidateSchema } from "../schema";
import {
  AccessAction,
  AppleOAuthIcon,
  AuthContentContainer,
  AuthenticationWrapper,
  ButtonAuthenticationOAuth,
  CartContent,
  CompleteOrderText,
  ContainerIconEye,
  EyeCloseIcon,
  EyeOpenIcon,
  FormContainer,
  GoogleOAuthIcon,
  InputAuth,
  LabelCustom,
  LinkCustom,
  LinkText,
  LoginDivider,
  LoginText,
  OptionText,
  PasswordContainer,
  TitleForgotPassword,
  WrapperDivider,
  WrapperOAuthButtons,
  WrapperRow,
} from "./styles";
import { appMonitoringClient, profileApi } from "implementations";
import { zodResolver } from "@hookform/resolvers/zod";

interface LoginProps {
  isMyCartPage?: boolean;
  loginMode: "page" | "modal";
}

const Login = ({ isMyCartPage, loginMode }: LoginProps) => {
  const [isUserAuthenticating, setIsUserAuthenticating] = useState(false);
  const { addToast } = useToast();
  const [typeInputPassword, setTypeInputPassword] = useState("password");
  const {
    register,
    handleSubmit,
    reset: resetForm,
    watch,
  } = useForm<ModalAuthUserProps>({
    resolver: zodResolver(authPropsFormValidateSchema),
  });
  const {
    getTokens,
    onAuthenticateUser,
    onAuthenticateUserOAuth,
    isAuthenticated,
    // userData,
  } = useAuth();

  const {
    changeCurrentStep,
    setUserIdentification,
    setIdentificationType,
    setChangePasswordDueToPurchase,
  } = useAuthentication(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
      setUserIdentification: state.setUserIdentification,
      setIdentificationType: state.setIdentificationType,
      setChangePasswordDueToPurchase: state.setChangePasswordDueToPurchase,
    }))
  );

  const { setIsOpenedDropdownUser, isClientTablet } = useAppContext();

  const accessToken = getTokens()?.token as string;

  const [isHovered, setIsHovered] = useState(false);

  const authContentContainerRef = useRef<HTMLElement>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const { error } = profileApi.getClient(accessToken, !!accessToken);

  if (error) {
    appMonitoringClient.captureException(error);
  }

  const textLogin = watch("login");
  const textPassword = watch("password");
  const isFormInvalid = !!(textLogin?.length < 11 || textPassword?.length < 6);

  const router = useRouter();

  const isLoginPage = loginMode === "page";

  const paymentRoute = "/checkout/pagamento";
  const cartRoute = "/checkout/meu-carrinho";

  const resetLoginForm = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const handleFinishOrder = useCallback(() => {
    // if (!userData?.email_verified) {
    //   handleOpenModalToConfirmEmail(String(router?.route || cartRoute));
    //   sendOrResendEmailConfirmation(userData?.email);
    //   return;
    // }

    router.push(paymentRoute);
  }, [
    // handleOpenModalToConfirmEmail,
    router,
    // sendOrResendEmailConfirmation,
    // userData?.email,
    // userData?.email_verified,
  ]);

  const handleAuthenticateUser = useCallback(
    async ({ login, password }: UserProps) => {
      const formattedLogin = RegExp(validEmailRegex).exec(login)
        ? login
        : RegExp(validCpfRegex).exec(login)
        ? login?.replace(/[.-]/g, "")
        : login?.replace(/\D/g, "");

      const verifyClient = (await profileApi.passwordChangeDuePurchaseTime(
        formattedLogin
      )) as PasswordChangeDuePurchaseTimeDTO;
      setIsUserAuthenticating(true);

      if (verifyClient?.clientNeedUpdatePassword) {
        setUserIdentification(login);
        setIdentificationType("email");
        changeCurrentStep("changePasswordDuePurchaseTime");
        setChangePasswordDueToPurchase(true);
        return;
      }

      const token = await onAuthenticateUser({
        login: formattedLogin,
        password,
      });

      setChangePasswordDueToPurchase(false);

      if (!isAuthenticated && !token) {
        addToast({
          title: "Crendenciais Inválidas",
          type: "error",
        });
        setIsUserAuthenticating(false);
        return;
      }

      if (isMyCartPage) {
        handleFinishOrder();
        return;
      }

      setIsUserAuthenticating(false);
      resetLoginForm();

      setIsOpenedDropdownUser(false);
    },
    [
      onAuthenticateUser,
      setChangePasswordDueToPurchase,
      isAuthenticated,
      isMyCartPage,
      resetLoginForm,
      setIsOpenedDropdownUser,
      setUserIdentification,
      setIdentificationType,
      changeCurrentStep,
      addToast,
      handleFinishOrder,
    ]
  );

  const handleManagementInputPasswordType = useCallback((type: string) => {
    setTypeInputPassword(type);
  }, []);

  const handleAuthenticateUserOAuth = useCallback(
    async (type: "google" | "apple") => {
      await onAuthenticateUserOAuth(type);
    },
    [onAuthenticateUserOAuth]
  );

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleForgotPasswordComponent = useCallback(() => {
    changeCurrentStep("forgotPassword");
    setChangePasswordDueToPurchase(false);
  }, [changeCurrentStep, setChangePasswordDueToPurchase]);

  const handleNavigation = useCallback(
    (currentRouter: NextRouter) => {
      if (currentRouter?.pathname === cartRoute && isMyCartPage) {
        currentRouter.push({
          pathname: "/cadastro",
          query: {
            redirectTo: paymentRoute,
          },
        });
        return;
      }

      currentRouter.push("/cadastro");
    },
    [isMyCartPage]
  );

  useEffect(() => {
    if (formRef?.current) {
      formRef.current.focus();
    }
  }, []);

  const handleOnPressEnter = useCallback(
    (event: KeyboardEvent<HTMLFormElement>) => {
      if (event?.key === "Enter") {
        event.preventDefault();
        handleSubmit(handleAuthenticateUser)();
      }
    },
    [handleSubmit, handleAuthenticateUser]
  );

  return (
    <AuthContentContainer
      ref={authContentContainerRef}
      isPageLogin={isLoginPage}
      {...buildTestIds(
        isLoginPage ? "page-auth-container" : "modal-auth-container"
      )}
    >
      {isMyCartPage && (
        <CartContent {...buildTestIds("cart-content-auth-container")}>
          <CompleteOrderText
            {...buildTestIds("complete-order-text-auth-container")}
          >
            Para concluir o pedido
          </CompleteOrderText>
          <LoginText {...buildTestIds("login-text-auth-container")}>
            Entre na sua conta
          </LoginText>
        </CartContent>
      )}
      <AuthenticationWrapper>
        <WrapperOAuthButtons>
          <ButtonAuthenticationOAuth
            type="button"
            typeAuthentication="Google"
            onClick={() => handleAuthenticateUserOAuth("google")}
            {...buildTestIds("button-auth-google")}
            isUserAuthenticating={isUserAuthenticating}
            // disabled={
            //   checkIfTheCustomerIsNotARobotSignIn || isUserAuthenticating
            // }
            disabled={isUserAuthenticating}
          >
            <GoogleOAuthIcon />
          </ButtonAuthenticationOAuth>
          <ButtonAuthenticationOAuth
            type="button"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            typeAuthentication="Apple"
            onClick={() => handleAuthenticateUserOAuth("apple")}
            {...buildTestIds("button-auth-apple")}
            isUserAuthenticating={isUserAuthenticating}
            // disabled={
            //   checkIfTheCustomerIsNotARobotSignIn || isUserAuthenticating
            // }
            disabled={isUserAuthenticating}
          >
            <AppleOAuthIcon isHovered={isHovered} />
          </ButtonAuthenticationOAuth>
        </WrapperOAuthButtons>
      </AuthenticationWrapper>
      <WrapperDivider>
        <LoginDivider />
        <OptionText {...buildTestIds("other-auth-options-text")}>OU</OptionText>
        <LoginDivider />
      </WrapperDivider>

      <FormContainer
        {...buildTestIds("form-container")}
        ref={formRef}
        onKeyDown={handleOnPressEnter}
      >
        <WrapperRow>
          <LabelCustom htmlFor="login" text="E-mail ou CPF" />
          <InputAuth
            id="login"
            type="text"
            {...buildTestIds("input-email-login")}
            role="presentation"
            autoComplete="off"
            inputMode="email"
            /**
             * carlos.lins no mobile inicia com o teclado de número impossibilitando
             * entrar com email caso o usuário desejar por enquanto vamos comentar e
             * deixar o text por enquanto
             */
            // type={textLogin.includes("@") ? "email" : "tel"}
            placeholder="Digite seu e-mail ou CPF"
            minLength={11}
            {...register("login")}
            // onChange={(e) => setTextLogin(e.target.value)}
            disabled={isUserAuthenticating}
          />
        </WrapperRow>
        <WrapperRow>
          <LabelCustom htmlFor="password" text="Senha" />
          <PasswordContainer>
            <InputAuth
              id="password"
              height="40px"
              type={typeInputPassword ?? "password"}
              {...buildTestIds("input-password-login")}
              role="presentation"
              autoComplete="off"
              placeholder="Digite sua senha"
              hasIcon
              minLength={6}
              {...register("password")}
              // onChange={(e) => setTextPassword(e.target.value)}
              disabled={isUserAuthenticating}
            />
            <ContainerIconEye
              {...buildTestIds("container-icon-eye")}
              onClick={() =>
                handleManagementInputPasswordType(
                  typeInputPassword === "password" ? "text" : "password"
                )
              }
            >
              {typeInputPassword === "text" ? (
                <EyeOpenIcon />
              ) : (
                <EyeCloseIcon />
              )}
            </ContainerIconEye>
          </PasswordContainer>
        </WrapperRow>
      </FormContainer>
      <AccessAction>
        <ButtonSubmit
          text="Entrar"
          textUnderAction="Entrando..."
          underAction={isUserAuthenticating}
          {...buildTestIds("button-submit-login")}
          buttonType="submit"
          onClickAction={handleSubmit(handleAuthenticateUser)}
          isDisabled={isFormInvalid}
          // isDisabled={checkIfTheCustomerIsNotARobotSignIn || isFormInvalid}
          fontSize={`${theme.typography.fontSize.sm.x5}`}
          minWidth="100%"
          height={isClientTablet ? "37px" : "45px"}
          fontWeight={500}
          borderRadius="4px"
          color={`${theme.colors.secondary["380"]}`}
          hoveredColor={`${theme.colors.secondary["350"]}`}
        />
        {/* <WrapperRecaptcha>
            <GoogleRecaptcha />
          </WrapperRecaptcha> */}
        <FlexContainer
          flexDirection="row"
          alignSelf="stretch"
          justifyContent="space-between"
        >
          <LinkCustom
            onClick={() => handleNavigation(router)}
            {...buildTestIds("sign-up-link")}
          >
            <LinkText {...buildTestIds("sign-up-link-text")}>
              Criar conta
            </LinkText>
          </LinkCustom>
          <TitleForgotPassword
            isMyCartPage={isMyCartPage}
            onClick={handleForgotPasswordComponent}
            onKeyDown={handleForgotPasswordComponent}
            role="presentation"
            {...buildTestIds("forgot-password-text")}
          >
            Esqueci minha senha
          </TitleForgotPassword>
        </FlexContainer>
      </AccessAction>
    </AuthContentContainer>
  );
};

export { Login };
