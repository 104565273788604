// import { rest } from "msw";
import { mockAsyncHttpRequest } from "../utils";
import data from "./data";

const { paymentOptionsData, linkOptionsData } = data;

// export const utilInfoHandlers = [
//     rest.get("/util-info/payment-options", (req, res, ctx) => {
//         return res(ctx.json(paymentOptionsData));
//     }),
//     rest.get("/util-info/link-options", (req, res, ctx) => {
//         return res(ctx.json(linkOptionsData));
//     }),
// ];

export const utilInfoHandlers = {
  "/util-info/payment-options": async () => {
    return mockAsyncHttpRequest(paymentOptionsData);
  },
  "/util-info/link-options": async () => {
    return mockAsyncHttpRequest(linkOptionsData);
  },
};
