import { CustomHighlightCategoryDto } from "typing";
import { buildTestIds } from "application";
import { HighlightCategory } from "./HighlightCategory";
import { HighlightCategories } from "./style";

interface CustomHighlightCategoriesProps {
  subComponents: CustomHighlightCategoryDto[] | null | undefined;
}

// const propsAreEqual = (
//   { subComponents: prevSubComponents }: CustomHighlightCategoriesProps,
//   { subComponents: nextSubComponents }: CustomHighlightCategoriesProps
// ) => {
//   return !prevSubComponents?.find(
//     (subComponent, index) =>
//       subComponent?.name !== nextSubComponents?.[`${index}`]?.name ||
//       subComponent?.descriptionUrl !==
//         nextSubComponents?.[`${index}`]?.descriptionUrl
//   );
// };

const CustomHighlightCategories = ({
  subComponents,
}: CustomHighlightCategoriesProps) => {
  if (!subComponents?.length) {
    return null;
  }

  return (
    <HighlightCategories {...buildTestIds("highlight-categories-container")}>
      {subComponents.map((component) => {
        return (
          <HighlightCategory
            key={`${component?.id}#${component?.name}`}
            {...component}
          />
        );
      })}
    </HighlightCategories>
  );
};

CustomHighlightCategories.displayName = "CustomHighlightCategories";

export { CustomHighlightCategories };
