import { ICookie } from "app-domain";

const getClientBranchId = (cookie: ICookie) => {
  if (typeof cookie !== "object") return "2";

  return (
    cookie.getCookie({
      name: "@FC:Ecom:Dropdown:BranchLocation",
    }) || "2"
  );
};

export { getClientBranchId };
