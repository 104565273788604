import * as zod from "zod";

export const authPropsFormValidateSchema = zod.object({
  login: zod
    .string()
    .min(11, { message: "É requerido no mínimo 11 caracteres" }),
  password: zod
    .string()
    .min(6, { message: "É requerido no mínimo 6 caracteres" }),
});

export type ModalAuthUserProps = zod.infer<typeof authPropsFormValidateSchema>;
