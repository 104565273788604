import { theme } from "ui";
import { buildTestIds } from "application";
import { ConfirmPaymentModal } from "../ConfirmPaymentModal";
import { BlockScreen } from "../ConfirmPayment/styles";

interface FinalizeOrderModalProps {
  buttonText: string;
  titleHeader: string;
  footerText?: string;
  subtitleHeader: string;
  fieldSetLegendText?: string;
  buttonTextUnderAction: string;
  isOpen: boolean;
  buttonUnderAction?: boolean;
  onCloseModal: () => void;
  onClickOnButton?: () => void;
  onClickAlternativeButton?: () => void;
  onFinishSaveWhatsAppOption: (errorMessage?: string | undefined) => void;
}

const FinalizeOrderModal = ({
  isOpen,
  onCloseModal,
  buttonUnderAction,
  onClickOnButton,
  onClickAlternativeButton,
  titleHeader,
  subtitleHeader,
  buttonText,
  buttonTextUnderAction,
  footerText = "",
}: FinalizeOrderModalProps) => {
  return (
    <>
      {buttonUnderAction && <BlockScreen />}
      <ConfirmPaymentModal
        {...buildTestIds("finalize-order-modal")}
        form="whatsAppForm"
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        width=""
        height="20rem"
        title={titleHeader}
        buttonText={buttonText}
        buttonUnderAction={buttonUnderAction}
        buttonTextUnderAction={buttonTextUnderAction}
        buttonSize="100px"
        modalButtonColor={theme.colors.secondary["300"]}
        onClickOnButton={onClickOnButton}
        onClickAlternativeButton={onClickAlternativeButton}
        buttonHoverColor={theme.colors.secondary["700"]}
        footerGap="5.5rem"
        modalButtonPadding="2rem"
        alternativeButtonText=""
        footerText={footerText}
        modalText={subtitleHeader}
      />
    </>
  );
};

export { FinalizeOrderModal };
