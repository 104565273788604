import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const OrderDelivering = ({
  width = 44,
  height = 44,
  alt = "pedido em transporte",
  color = theme.colors.secondary["275"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox={`0 0 ${width} ${width}`}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill={color} />
      <path
        d="M16.5575 29.0446H25.7932"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="27.4189"
        cy="29.0446"
        r="1.62568"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="14.9318"
        cy="29.0446"
        r="1.62568"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.25 28.5V24.0168L25.2514 23.4749V16.5811C25.2514 15.384 24.2809 14.4135 23.0838 14.4135H13.3297C12.1326 14.4135 11.1622 15.384 11.1622 16.5811V27.9608C11.1622 28.5594 11.6474 29.0446 12.2459 29.0446H13.3062"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2514 16.5811H28.7699C29.9105 16.5811 30.929 17.2954 31.3174 18.3678L32.7083 22.2077C32.794 22.4444 32.8378 22.6942 32.8378 22.9459V27.4189C32.8378 28.3168 32.11 29.0446 31.2122 29.0446H29.0446"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2917 22H29.5833H29.0417H28.0538H25.2514"
        stroke={secondColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4167 18.6474H14.7163C14.8798 18.6474 14.9343 18.7019 14.9343 18.8654V22.8159H16.1058V20.7998H17.795V19.8462H16.1058V18.6474H18.1488V17.6667H14.4167V18.6474Z"
        fill={secondColor}
      />
      <path
        d="M18.8106 22.7773C18.8106 21.7965 19.4644 21.2517 20.3092 21.2517C20.8813 21.2517 21.3445 21.4151 21.8348 21.742V20.7067C21.3313 20.3826 20.7443 20.2122 20.1455 20.2163C18.6198 20.2163 17.6118 21.2789 17.6118 22.8045C17.6118 24.3302 18.6743 25.3655 20.2272 25.3655C20.8305 25.3736 21.4212 25.1925 21.9163 24.8478V23.7853C21.3715 24.1394 20.8538 24.3029 20.3089 24.3029C19.4644 24.2757 18.8106 23.7308 18.8106 22.7773Z"
        fill={secondColor}
      />
    </svg>
  );
};
