import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const UserCardIcon = ({
  height = 106,
  width = 106,
  color = theme.colors.neutral["white-075"],
  secondColor = theme.colors.neutral["550"],
  thirdColor = theme.colors.secondary["380"],
  fourthColor = theme.colors.neutral["240"],
  alt = "cartão com ícone de perfil do usuário",
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52.6071" cy="53.4364" r="52.0973" fill={color} />
      <g opacity="0.1">
        <path
          d="M23.5971 37.5713L23.5768 36.9687C23.5132 35.0827 21.9328 33.6053 20.0468 33.6689L14.0204 33.872C12.1344 33.9355 10.657 35.516 10.7206 37.402L10.7409 38.0046"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="16.8165"
          cy="27.3423"
          r="3.21588"
          transform="rotate(-1.93037 16.8165 27.3423)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M17.6225 58.3543L17.6022 57.7516C17.5386 55.8656 15.9582 54.3882 14.0722 54.4518L8.0458 54.6549C6.15978 54.7185 4.68239 56.2989 4.74596 58.185L4.76627 58.7876"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="10.8419"
          cy="48.1253"
          r="3.21588"
          transform="rotate(-1.93037 10.8419 48.1253)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M28.6811 77.8138L28.6608 77.2111C28.5972 75.3251 27.0168 73.8477 25.1307 73.9113L19.1044 74.1144C17.2184 74.178 15.741 75.7584 15.8046 77.6444L15.8249 78.2471"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="21.9005"
          cy="67.5847"
          r="3.21588"
          transform="rotate(-1.93037 21.9005 67.5847)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M46.2475 36.0564L46.2272 35.4538C46.1636 33.5678 44.5832 32.0904 42.6972 32.154L36.6708 32.3571C34.7848 32.4206 33.3074 34.0011 33.371 35.8871L33.3913 36.4897"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="39.4669"
          cy="25.8274"
          r="3.21588"
          transform="rotate(-1.93037 39.4669 25.8274)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M66.0737 17.135L66.0534 16.5324C65.9898 14.6464 64.4093 13.169 62.5233 13.2326L56.497 13.4357C54.611 13.4993 53.1336 15.0797 53.1971 16.9657L53.2174 17.5684"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="59.2931"
          cy="6.90602"
          r="3.21588"
          transform="rotate(-1.93037 59.2931 6.90602)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M61.4545 96.3206L61.4342 95.718C61.3706 93.8319 59.7902 92.3546 57.9042 92.4181L51.8778 92.6212C49.9918 92.6848 48.5144 94.2652 48.578 96.1513L48.5983 96.7539"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="54.6739"
          cy="86.0916"
          r="3.21588"
          transform="rotate(-1.93037 54.6739 86.0916)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M67.5834 33.4192L67.5631 32.8166C67.4996 30.9306 65.9191 29.4532 64.0331 29.5168L58.0067 29.7199C56.1207 29.7834 54.6433 31.3639 54.7069 33.2499L54.7272 33.8525"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="60.8029"
          cy="23.1902"
          r="3.21588"
          transform="rotate(-1.93037 60.8029 23.1902)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M43.0522 20.1473L43.0319 19.5446C42.9683 17.6586 41.3879 16.1812 39.5018 16.2448L33.4755 16.4479C31.5895 16.5115 30.1121 18.0919 30.1756 19.9779L30.196 20.5806"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="36.2716"
          cy="9.91823"
          r="3.21588"
          transform="rotate(-1.93037 36.2716 9.91823)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M41.0502 95.2132L41.0299 94.6105C40.9664 92.7245 39.3859 91.2471 37.4999 91.3107L31.4735 91.5138C29.5875 91.5774 28.1101 93.1578 28.1737 95.0438L28.194 95.6465"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="34.2696"
          cy="84.9841"
          r="3.21588"
          transform="rotate(-1.93037 34.2696 84.9841)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M87.8002 32.0935L87.7799 31.4909C87.7164 29.6049 86.1359 28.1275 84.2499 28.1911L78.2235 28.3942C76.3375 28.4577 74.8601 30.0382 74.9237 31.9242L74.944 32.5268"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="81.0197"
          cy="21.8645"
          r="3.21588"
          transform="rotate(-1.93037 81.0197 21.8645)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M99.6069 51.7393L99.5866 51.1367C99.523 49.2506 97.9425 47.7733 96.0565 47.8368L90.0302 48.0399C88.1442 48.1035 86.6668 49.6839 86.7303 51.57L86.7506 52.1726"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="92.8282"
          cy="41.5105"
          r="3.21588"
          transform="rotate(-1.93037 92.8282 41.5105)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M93.0737 71.5872L93.0534 70.9846C92.9898 69.0985 91.4093 67.6212 89.5233 67.6847L83.497 67.8878C81.611 67.9514 80.1336 69.5318 80.1971 71.4179L80.2174 72.0205"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="86.2931"
          cy="61.3582"
          r="3.21588"
          transform="rotate(-1.93037 86.2931 61.3582)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M80.1127 91.9305L80.0924 91.3278C80.0289 89.4418 78.4484 87.9644 76.5624 88.028L70.536 88.2311C68.65 88.2947 67.1726 89.8751 67.2362 91.7611L67.2565 92.3638"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="73.3322"
          cy="81.7014"
          r="3.21588"
          transform="rotate(-1.93037 73.3322 81.7014)"
          stroke={secondColor}
          strokeWidth="1.06018"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M46.515 44.5402C48.8007 46.8259 48.8007 50.5318 46.515 52.8175C44.2293 55.1032 40.5234 55.1032 38.2377 52.8175C35.952 50.5318 35.952 46.8259 38.2377 44.5402C40.5234 42.2545 44.2293 42.2545 46.515 44.5402"
        stroke={secondColor}
        strokeWidth="2.31544"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.3965 46.9432H73.1023"
        stroke={secondColor}
        strokeWidth="3.08725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.1758 57.1859H61.3965"
        stroke={secondColor}
        strokeWidth="3.08725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.618 67.5484C52.1205 66.2988 51.3508 65.175 50.3617 64.2591V64.2591C48.6907 62.711 46.4958 61.8477 44.2161 61.8477H40.5347C38.255 61.8477 36.0601 62.708 34.3891 64.2591V64.2591C33.4 65.1721 32.6303 66.2959 32.1328 67.5484"
        stroke={secondColor}
        strokeWidth="3.08725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.545 35.2375H49.6921C48.0767 35.2375 46.7656 33.9264 46.7656 32.311V23.5317C46.7656 21.9163 48.0767 20.6052 49.6921 20.6052H55.545C57.1604 20.6052 58.4714 21.9163 58.4714 23.5317V32.311C58.4714 33.9264 57.1604 35.2375 55.545 35.2375Z"
        fill={thirdColor}
        stroke={secondColor}
        strokeWidth="3.08725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.7651 29.3845H29.3264C26.0283 29.3845 23.3535 32.0593 23.3535 35.3574V70.3549C23.3535 73.5886 25.9727 76.2078 29.2064 76.2078H76.0297C79.2634 76.2078 81.8826 73.5886 81.8826 70.3549V35.2374C81.8826 32.0037 79.2634 29.3845 76.0297 29.3845H58.471"
        fill={color}
      />
      <path
        d="M46.7651 29.3845H29.3264C26.0283 29.3845 23.3535 32.0593 23.3535 35.3574V70.3549C23.3535 73.5886 25.9727 76.2078 29.2064 76.2078H76.0297C79.2634 76.2078 81.8826 73.5886 81.8826 70.3549V35.2374C81.8826 32.0037 79.2634 29.3845 76.0297 29.3845H58.471"
        stroke={secondColor}
        strokeWidth="3.08725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6075 35.559H49.7546C48.1392 35.559 46.8281 34.248 46.8281 32.6326V23.8532C46.8281 22.2378 48.1392 20.9268 49.7546 20.9268H55.6075C57.2229 20.9268 58.5339 22.2378 58.5339 23.8532V32.6326C58.5339 34.248 57.2229 35.559 55.6075 35.559Z"
        fill={thirdColor}
        stroke={secondColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.515 44.5397C48.8007 46.8254 48.8007 50.5313 46.515 52.817C44.2293 55.1027 40.5234 55.1027 38.2377 52.817C35.952 50.5313 35.952 46.8254 38.2377 44.5397C40.5234 42.254 44.2293 42.254 46.515 44.5397"
        stroke={secondColor}
        strokeWidth="2.31544"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.3965 46.943H73.1023"
        stroke={secondColor}
        strokeWidth="3.08725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.1758 57.1854H61.3965"
        stroke={secondColor}
        strokeWidth="3.08725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.618 67.5481C52.1205 66.2985 51.3508 65.1748 50.3617 64.2588V64.2588C48.6907 62.7107 46.4958 61.8474 44.2161 61.8474H40.5347C38.255 61.8474 36.0601 62.7078 34.3891 64.2588V64.2588C33.4 65.1719 32.6303 66.2956 32.1328 67.5481"
        stroke={secondColor}
        strokeWidth="3.08725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="42.1976" cy="48.7118" r="5.40268" fill={thirdColor} />
      <ellipse cx="54" cy="83.252" rx="22.5" ry="1.5" fill={fourthColor} />
    </svg>
  );
};
