import { IconProps } from "typing";
import { theme } from "../themes";

export const PasteIcon = ({
  alt = "Ícone de colar",
  width = 16,
  height = 17,
  color = `${theme.colors.neutral["110"]} !important`,
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            d="M4.66667 10.752H3.33333C2.59667 10.752 2 10.1553 2 9.41862V4.08529C2 3.34862 2.59667 2.75195 3.33333 2.75195H8.66667C9.40333 2.75195 10 3.34862 10 4.08529V5.41862"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M8.66669 13.4186H6.00002C5.26335 13.4186 4.66669 12.8219 4.66669 12.0852V6.75191C4.66669 6.01525 5.26335 5.41858 6.00002 5.41858H11.3334C12.07 5.41858 12.6667 6.01525 12.6667 6.75191V9.41858"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_3"
            d="M12.6666 12.0853V14.752"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_4"
            d="M11.3333 13.4187H14"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
