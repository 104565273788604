import { CardName } from "typing";

type ValidateCardNumberResponse =
  | ""
  | "O número do cartão é obrigatório!"
  | "O número do cartão precisa ter a quantidade mínima de 13 dígitos"
  | "O número do cartão precisa ter a quantidade máxima de 16 dígitos"
  | "O número do cartão é inválido!"
  | "Os números dos cartões são iguais";

export const validateCardNumber = (
  cardNumber: string,
  secondCardNumber?: string | null
): ValidateCardNumberResponse => {
  if (typeof cardNumber !== "string") return "O número do cartão é inválido!";

  const cardMinLengthMessage =
    "O número do cartão precisa ter a quantidade mínima de 13 dígitos";

  const cardMaxLengthMessage =
    "O número do cartão precisa ter a quantidade máxima de 16 dígitos";

  const sameCardNumberMessage = "Os números dos cartões são iguais";

  if (cardNumber === secondCardNumber) return sameCardNumberMessage;

  const formattedFirstCardNumber = cardNumber.replace(/[\s_]/g, "");

  if (formattedFirstCardNumber.length && formattedFirstCardNumber.length < 13)
    return cardMinLengthMessage;

  if (formattedFirstCardNumber.length > 16) return cardMaxLengthMessage;

  return "";
};

type ValidateSecurityCodeResponse = "" | "CVV inválido";

export const validateSecurityCode = (
  securityCode: string
): ValidateSecurityCodeResponse => {
  const invalid = "CVV inválido";

  if (!securityCode || typeof securityCode !== "string") return invalid;

  return securityCode.length < 3 || securityCode.length > 4 ? invalid : "";
};

type ValidateCreditCardDueDateResponse =
  | ""
  | "Vencimento inválido"
  | "Data inválida"
  | "Ano inválido"
  | "Mês inválido";

export const validateCreditCardDueDate = (
  dueDate: string
): ValidateCreditCardDueDateResponse => {
  const invalidDate = "Data inválida";
  const invalidYear = "Ano inválido";
  const invalidMonth = "Mês inválido";
  const invalidExpiration = "Vencimento inválido";

  if (typeof dueDate !== "string") {
    return invalidDate;
  }

  if (!dueDate?.replace(/[^0-9]/g, "")?.length) {
    return "";
  }

  const [month, originalYear] = dueDate?.match(/\d+/g) ?? [null, null];
  const year = `20${originalYear}`;

  if (!month && !originalYear) {
    return "";
  }

  const currentDate = new Date();

  if (!month || !originalYear) {
    return invalidDate;
  }

  if (Number(month) < 1 || Number(month) > 12) {
    return invalidMonth;
  }

  if (Number(year) < currentDate.getFullYear()) {
    return invalidYear;
  }

  if (
    Number(year) === currentDate.getFullYear() &&
    Number(month) < currentDate.getMonth() + 1
  ) {
    return invalidExpiration;
  }

  return "";
};

type HandleCreditCardNumberMaskResponse =
  | "9999 999999 99999"
  | "9999 999999 9999"
  | "9999 9999 9999 9999";

export const handleCreditCardNumberMask = (
  creditCardBrand: CardName | null
): HandleCreditCardNumberMaskResponse => {
  const defaultMask = "9999 9999 9999 9999";

  if (typeof creditCardBrand !== "string" && creditCardBrand !== null)
    return defaultMask;

  return creditCardBrand
    ? creditCardBrand === "Amex"
      ? "9999 999999 99999"
      : creditCardBrand === "Diners"
      ? "9999 999999 9999"
      : defaultMask
    : defaultMask;
};

export const handleCreditCardCvvMask = (creditCardBrand: CardName | null) => {
  const defaultMask = "999";

  if (typeof creditCardBrand !== "string" && creditCardBrand !== null)
    return defaultMask;

  if (
    !(
      creditCardBrand === "Visa" ||
      creditCardBrand === "Mastercard" ||
      creditCardBrand === "Diners" ||
      creditCardBrand === "Amex" ||
      creditCardBrand === "Hiper" ||
      creditCardBrand === "Hipercard" ||
      creditCardBrand === "Elo" ||
      creditCardBrand === "Jcb" ||
      !creditCardBrand
    )
  )
    return defaultMask;

  return creditCardBrand
    ? creditCardBrand === "Amex"
      ? "9999"
      : defaultMask
    : defaultMask;
};

export const handleCreditCardCvvPlaceholder = (
  creditCardBrand: CardName | null
) => {
  const defaultMask = "000";

  if (typeof creditCardBrand !== "string" && creditCardBrand !== null)
    return defaultMask;

  if (
    !(
      creditCardBrand === "Visa" ||
      creditCardBrand === "Mastercard" ||
      creditCardBrand === "Diners" ||
      creditCardBrand === "Amex" ||
      creditCardBrand === "Hiper" ||
      creditCardBrand === "Hipercard" ||
      creditCardBrand === "Elo" ||
      creditCardBrand === "Jcb" ||
      !creditCardBrand
    )
  )
    return defaultMask;

  return creditCardBrand
    ? creditCardBrand === "Amex"
      ? "0000"
      : defaultMask
    : defaultMask;
};

export const validateCreditCardCVV = (
  cvv: string,
  creditCardBrand: CardName | null
) => {
  const invalid = "CVV inválido";
  const invalidAmexCVV = "CVV inválido para cartões Amex";
  const invalidCvvLength = "CVV precisa ter 3 ou 4 dígitos";

  if (!cvv || typeof cvv !== "string") return invalid;

  if (creditCardBrand === "Amex" && cvv.length !== 4) {
    return invalidAmexCVV;
  }

  if (cvv.length < 3 || cvv.length > 4) return invalidCvvLength;

  return "";
};
