import styled, { css } from "styled-components";
import { theme } from "ui";

type StyledCheckboxProps = {
  hasError?: boolean;
  color?: string;
  width?: string;
  height?: string;
  borderColor?: string;
  borderRadius?: string;
  left?: string;
  sizeChecked?: string;
  heightChecked?: string;
  widthChecked?: string;
  cursor?: string;
  checkboxWrapperWidth?: string;
};

export const CheckboxWrapper = styled.div<StyledCheckboxProps>`
  cursor: ${({ cursor }) => cursor ?? "default"};
  width: ${({ checkboxWrapperWidth }) => checkboxWrapperWidth ?? "auto"};
`;

interface CheckboxLabelProps {
  display?: string;
  alignItems?: string;
  gap?: string;
  flexDirection?: string;
  justifyContent?: string;
  cursor?: string;
}

export const CheckboxLabel = styled.label<CheckboxLabelProps>`
  display: ${({ display }) => display ?? "block"};
  cursor: ${({ cursor }) => cursor ?? "auto"};
  gap: ${({ gap }) => gap ?? `${theme.space.x0}`};
  width: 100%;

  ${({ display, flexDirection, justifyContent, alignItems }) =>
    display === "flex"
      ? css`
          flex-direction: ${flexDirection};
          justify-content: ${justifyContent};
          align-items: ${alignItems};
        `
      : ""};
`;

interface CheckboxSpanProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  cursor?: string;
}

export const CheckboxSpan = styled.span<CheckboxSpanProps>`
  color: ${({ color }) => color ?? `${theme.colors.neutral["550"]}`};
  font-size: ${({ fontSize }) =>
    fontSize ?? `${theme.typography.fontSize.sm.x5}`};
  font-weight: ${({ fontWeight }) =>
    fontWeight ?? `${theme.typography.fontWeight["400"]}`};
  font-family: ${({ fontFamily }) =>
    fontFamily ?? `${theme.typography.fontFamily.chivo}`};
  cursor: ${({ cursor }) => cursor ?? "pointer"};
`;

export const StyledCheckbox = styled.input.attrs({
  type: "checkbox",
})<StyledCheckboxProps>`
  -webkit-appearance: none;
  appearance: none;
  width: ${({ width }) => width ?? "1.6em"};
  height: ${({ height }) => height ?? "1.6em"};
  border-radius: ${({ borderRadius }) =>
    borderRadius ?? `${theme.space["x0.5"]}`};
  margin-right: 0.5em;
  border: ${theme.space.xpx} solid
    ${({ borderColor }) => borderColor ?? `${theme.colors.neutral["300"]}`};
  outline: none;
  cursor: pointer;
  pointer-events: initial;
  transition: all 0.2s ease-in;

  &:hover {
    border: ${theme.space.xpx} solid
      ${({ color }) => color ?? theme.colors.primary["200"]};
  }

  &:checked {
    border: ${theme.space.xpx} solid
      ${({ color }) => color ?? theme.colors.primary["200"]};
    position: relative;
    background-color: ${({ color }) => color ?? theme.colors.primary["200"]};
  }

  &:checked::before {
    content: "";
    font-size: ${({ sizeChecked }) => sizeChecked ?? "1.5em"};
    color: ${theme.colors.neutral.white};
    position: absolute;
    left: ${({ left }) => left ?? "2px"};
    top: -4px;
    width: ${({ widthChecked }) => widthChecked ?? "6px"};
    height: ${({ heightChecked }) => heightChecked ?? "11px"};
    border-width: 0 3px 3px 0;
    border-style: solid;
    transform-origin: bottom left;
    transform: rotate(45deg);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
