import { IconProps } from "typing";
import { theme } from "../themes";

export const DoubleArrowDown = ({
  width = 24,
  height = 24,
  color = theme.colors.neutral["999"],
  click,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={click}
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M4 11.2L12 19.2L20 11.2"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 4L12 12L20 4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
