import {
  useAuthentication,
  useShallow,
  useToast,
  useTwoFactor,
} from "application";
import {
  identityApi,
  notificationApi,
  securedSessionStorage,
} from "implementations";
import { useCallback } from "react";
import { SendTokenResponseDto } from "typing";

const useNewForgotPassword = () => {
  const { addToast } = useToast();
  const {
    changeCurrentStep,
    userIdentification,
    identificationType,
    currentStep,
  } = useAuthentication(
    useShallow((state) => ({
      changeCurrentStep: state.changeCurrentStep,
      userIdentification: state.userIdentification,
      identificationType: state.identificationType,
      currentStep: state.currentStep,
    }))
  );

  const {
    setIsValidationActive,
    channelSelected,
    setAvailableMethods,
    setIsBlockedToSendCode,
    setFaType,
    getTimer,
    setClientAction,
    setChannelSelected,
    setCanChooseAnotherTwoFactorMethod,
    clearTimer,
  } = useTwoFactor(
    useShallow((state) => ({
      setIsValidationActive: state.setIsValidationActive,
      channelSelected: state.channelSelected,
      setAvailableMethods: state.setAvailableMethods,
      setIsBlockedToSendCode: state.setIsBlockedToSendCode,
      setFaType: state.setFaType,
      getTimer: state.getTimer,
      setClientAction: state.setClientAction,
      setChannelSelected: state.setChannelSelected,
      setCanChooseAnotherTwoFactorMethod:
        state.setCanChooseAnotherTwoFactorMethod,
      clearTimer: state.clearTimer,
    }))
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onVerifyIdentity = useCallback(async () => {
    try {
      setFaType(9);
      setClientAction("forgotPassword");
      setIsBlockedToSendCode(false);

      const passedUserIdentification =
        securedSessionStorage.getItem("userIdentification");
      const isTheSameIdentification =
        passedUserIdentification === userIdentification;

      const { isTimerRunning } = getTimer(9, channelSelected);

      if (
        isTimerRunning &&
        isTheSameIdentification &&
        ["forgotPassword", "verifyIdentity"]?.includes(currentStep)
      ) {
        changeCurrentStep("verifyCode");
        return;
      }

      const { data: availableMethods, error: availableMethodsError } =
        await identityApi.receiveConfirmationTypesPublic({
          email: identificationType === "email" ? userIdentification : "",
          cpf: identificationType === "cpf" ? userIdentification : "",
        });

      if (
        (!availableMethods && availableMethodsError) ||
        (!availableMethods && !availableMethodsError)
      ) {
        throw new Error(availableMethodsError.errors?.Messages[0]);
      }

      const redirectToChooseAnotherMethod =
        Boolean(
          availableMethods?.emailConfirmed &&
            availableMethods?.phoneNumberConfirmed
        ) ||
        Boolean(
          !availableMethods?.emailConfirmed &&
            !availableMethods?.phoneNumberConfirmed
        );

      setAvailableMethods(availableMethods);

      if (redirectToChooseAnotherMethod) {
        setIsValidationActive(true);
        setCanChooseAnotherTwoFactorMethod(true);
        changeCurrentStep("verifyIdentity");
        return;
      }

      const currentChannel = availableMethods?.emailConfirmed
        ? 2
        : availableMethods?.phoneNumberConfirmed
        ? 1
        : 2;

      setChannelSelected(currentChannel);
      const response = (await notificationApi.sendTwoFactorCodePublic({
        faType: 9,
        email: identificationType === "email" ? userIdentification : "",
        cpf: identificationType === "cpf" ? userIdentification : "",
        channel: currentChannel,
        phoneNumber: "",
        token: "",
        canSendToken: Boolean(availableMethods),
      })) as unknown as SendTokenResponseDto;

      if (response?.validationResult?.isValid) {
        setIsBlockedToSendCode(false);
        setIsValidationActive(true);
        securedSessionStorage.setItem("userIdentification", userIdentification);
        changeCurrentStep("verifyCode");
        return;
      }

      if (
        response?.validationResult?.errors?.[0]?.errorMessage ===
        "Cliente não encontrado"
      ) {
        changeCurrentStep("emailFeedback");
        return;
      }

      if (
        response?.validationResult?.errors?.[0]?.errorCode === "222" ||
        [
          "Tente novamente em 24 horas.",
          "Operação bloqueada devido ao número de tentativas excessivas na solicitação do código.",
        ]?.includes(
          String(response?.validationResult?.errors?.[0]?.errorMessage)
        )
      ) {
        clearTimer(9, currentChannel);
        setIsValidationActive(true);
        setIsBlockedToSendCode(true);
        changeCurrentStep("verifyCode");
        return;
      }

      if (response?.validationResult?.errors?.[0]?.errorCode === "666") {
        setIsValidationActive(true);
        changeCurrentStep("verifyCode");
      }
    } catch (error) {
      setIsValidationActive(false);
      addToast({
        type: "error",
        title: "Não foi possível processar a solicitação.",
        description: "Verifique as informações inseridas e tente novamente.",
        isNewToast: true,
        newToastTheme: "dark",
        timerInMilliseconds: 7000,
      });
    }
  }, [
    setFaType,
    setClientAction,
    getTimer,
    channelSelected,
    currentStep,
    identificationType,
    userIdentification,
    setCanChooseAnotherTwoFactorMethod,
    setChannelSelected,
    setAvailableMethods,
    changeCurrentStep,
    setIsBlockedToSendCode,
    setIsValidationActive,
    addToast,
    clearTimer,
  ]);

  return {
    onVerifyIdentity,
  };
};

export { useNewForgotPassword };
