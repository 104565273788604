const isSpecialStatus = (idStatus: number) => {
  const specialStatus = [3, 25, 251, 5, 94, 394, 13, 108];

  return specialStatus?.includes(idStatus);
};

const isAttentionStatus = (idStatus: number) => {
  const attentionStatus = [20, 103, 188, 341, 335, 19, 186, 196, 21, 303, 342];

  return attentionStatus?.includes(idStatus);
};

export { isSpecialStatus, isAttentionStatus };
