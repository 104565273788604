import { IconProps } from "typing";
import { theme } from "../themes";

export const Star = ({
  width = 12,
  height = 12.5,
  color = `${theme.colors.secondary["265"]}`,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.49L4.11933 13.3333L4.92533 9.25333L2 6.26133L6.11933 5.74733L8 2L9.88067 5.74733L14 6.26133L11.0747 9.25333L11.8807 13.3333L8 11.49Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
