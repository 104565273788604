import { IconProps } from "typing";
import { theme } from "../themes";

export const Phone = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral["999"],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.856 13.144C9.686 11.974 8.804 10.664 8.218 9.33499C8.094 9.05399 8.167 8.72499 8.384 8.50799L9.203 7.68999C9.874 7.01899 9.874 6.06999 9.288 5.48399L8.114 4.30999C7.333 3.52899 6.067 3.52899 5.286 4.30999L4.634 4.96199C3.893 5.70299 3.584 6.77199 3.784 7.83199C4.278 10.445 5.796 13.306 8.245 15.755C10.694 18.204 13.555 19.722 16.168 20.216C17.228 20.416 18.297 20.107 19.038 19.366L19.689 18.715C20.47 17.934 20.47 16.668 19.689 15.887L18.516 14.714C17.93 14.128 16.98 14.128 16.395 14.714L15.492 15.618C15.275 15.835 14.946 15.908 14.665 15.784C13.336 15.197 12.026 14.314 10.856 13.144Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
