import styled, { css } from "styled-components";
import { theme } from "ui";

type StyledInputProps = {
  hasError: boolean;
  hasSuccessfully?: boolean;
  width?: string;
  padding?: string;
};

export const StyledInput = styled.div<StyledInputProps>`
  width: ${({ width }) => width || "initial"};
  textarea {
    width: ${({ width }) => width || "initial"};
    padding: ${({ padding }) =>
      padding ?? `${theme.space.x4} ${theme.space.x5}`};
    margin: ${theme.space.x2} ${theme.space.x0};
    border: ${`${theme.space.xpx} solid ${theme.colors.neutral["200"]}`};
    border-radius: ${theme.space.x2};

    ${({ hasSuccessfully }) =>
      hasSuccessfully &&
      css`
        border-color: ${theme.colors.secondary["200"]} !important;
      `}

    border-color: ${theme.colors.neutral["200"]};
    border-color: ${({ hasError }) =>
      hasError ? theme.colors.primary["200"] : theme.colors.neutral["200"]};
    box-sizing: border-box;
    resize: none;

    &:focus {
      outline: none;
    }

    @media ${theme.device.desktopFirst.mobileL} {
      &:hover {
        border: ${({ hasError }) =>
          `${theme.space["x0.5"]} solid ${
            hasError ? theme.colors.primary["200"] : theme.colors.neutral["999"]
          }`};
      }
    }
  }
`;
