import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { ImagesProductThumbProps, MediaLink, MediaType } from "typing";

interface MagicZoomThumbModification extends ImagesProductThumbProps {
  sequenceNumber?: number;
}

export interface ListImage {
  medias: MediaLink[];
  thumb: MediaLink[];
  zoomItems: MagicZoomThumbModification[];
  images: MediaLink[];
}

const useMedia = (
  setSequenceImageNumberCurrentActive?: Dispatch<SetStateAction<number>>
) => {
  const [listImages, setListImages] = useState<ListImage>({
    medias: [],
    thumb: [],
    zoomItems: [],
    images: [],
  });
  const processMedia = useCallback((list: MediaLink[]) => {
    if (!Array.isArray(list) || !list.length) return [];
    let count = 0;
    const newMediaList = [...list];
    newMediaList.forEach((item, pos) => {
      for (let i = 0; i < newMediaList.length; i += 1) {
        if (newMediaList[`${i}`]?.sequenceNumber === item?.sequenceNumber) {
          count += 1;
        }
      }

      if (count > 1) {
        newMediaList.splice(pos, 1);
      }

      count = 0;
    });

    return newMediaList;
  }, []);

  const handleZoomItems = (
    mediaList: MagicZoomThumbModification[],
    lastItem: MediaLink,
    type: MediaType
  ) => {
    const zoomItemIndex = mediaList.findIndex(
      (elem) => elem.sequenceNumber === lastItem.sequenceNumber
    );
    if (zoomItemIndex === -1) {
      return [
        ...mediaList,
        {
          sequenceNumber: lastItem.sequenceNumber,
          smallImage: type === "IMAGEM" ? lastItem.imageUrl : "",
          tinyImage: type === "THUMB" ? lastItem.imageUrl : "",
          idThumb: "",
        },
      ];
    }
    return mediaList.map((elem) => {
      if (elem.sequenceNumber === lastItem.sequenceNumber) {
        return {
          sequenceNumber: lastItem.sequenceNumber,
          smallImage:
            type === "IMAGEM" ? lastItem.imageUrl : elem.smallImage ?? "",
          tinyImage:
            type === "THUMB" ? lastItem.imageUrl : elem.smallImage ?? "",
          idThumb: "",
        };
      }
      return elem;
    });
  };

  const sortImages = useCallback(
    (mediaData: MediaLink[]) => {
      const reducerImages = mediaData?.reduce<ListImage>(
        (previousValue, currentValue) => {
          if (currentValue.linkType === "THUMB") {
            return {
              ...previousValue,
              thumb: [...previousValue.thumb, currentValue],
              zoomItems: handleZoomItems(
                previousValue.zoomItems,
                currentValue,
                currentValue.linkType
              ),
            };
          }

          if (currentValue.linkType === "IMAGEM") {
            return {
              ...previousValue,
              images: [...previousValue.images, currentValue],
              zoomItems: handleZoomItems(
                previousValue.zoomItems,
                currentValue,
                currentValue.linkType
              ),
            };
          }

          if (currentValue?.linkType === "VIDEO") {
            return {
              ...previousValue,
              thumb: [...previousValue.thumb, currentValue],
              medias: [...previousValue.medias, currentValue],
              zoomItems: handleZoomItems(
                previousValue.zoomItems,
                currentValue,
                currentValue.linkType
              ),
            };
          }

          return {
            ...previousValue,
            medias: [...previousValue.medias, currentValue],
            zoomItems: handleZoomItems(
              previousValue.zoomItems,
              currentValue,
              currentValue.linkType
            ),
          };
        },
        { medias: [], thumb: [], zoomItems: [], images: [] }
      );

      reducerImages?.thumb?.sort((a, b) => {
        if (a.sequenceNumber < b.sequenceNumber) {
          return -1;
        }
        if (a.sequenceNumber > b.sequenceNumber) {
          return 1;
        }
        return 0;
      });

      const newProcessMediaThumbList = processMedia(reducerImages?.thumb);
      const newProcessMediaImageList = processMedia(reducerImages?.medias);
      const newProcessMediaImagesList = processMedia(reducerImages?.images);

      if (
        newProcessMediaThumbList.length &&
        setSequenceImageNumberCurrentActive
      ) {
        setSequenceImageNumberCurrentActive(
          newProcessMediaThumbList[0]?.sequenceNumber
        );
      }

      setListImages({
        thumb: newProcessMediaThumbList || [],
        medias: newProcessMediaImageList || [],
        zoomItems: reducerImages.zoomItems || [],
        images: newProcessMediaImagesList,
      });
    },
    [processMedia, setSequenceImageNumberCurrentActive]
  );

  return { processMedia, sortImages, listImages, handleZoomItems };
};

export { useMedia };
