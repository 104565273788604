import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const TrackingStatusOrderCanceled = ({
  width = 36,
  height = 32,
  color = theme.colors.primary["150"],
  secondColor = theme.colors.neutral.white,
  alt = "Pedido cancelado",
}: MultipleColorsIconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        fill={secondColor}
      />
      <rect
        x="3.25"
        y="1.25"
        width="29.5"
        height="29.5"
        rx="14.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M20.5 8.5V12.6667C20.5 13.1267 20.1267 13.5 19.6667 13.5H16.3333C15.8733 13.5 15.5 13.1267 15.5 12.6667V8.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5 16V10.1667C25.5 9.24583 24.7542 8.5 23.8333 8.5H12.1667C11.2458 8.5 10.5 9.24583 10.5 10.1667V21.8333C10.5 22.7542 11.2458 23.5 12.1667 23.5H18"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3333 21.4167C26.3333 23.0308 25.0308 24.3333 23.4167 24.3333C21.8025 24.3333 20.5 23.0308 20.5 21.4167C20.5 19.8025 21.8025 18.5 23.4167 18.5C25.0308 18.5 26.3333 19.8025 26.3333 21.4167Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.355 23.4783L25.4783 19.355"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
