import { ReactNode } from "react";
import { buildTestIds } from "application";
import { TooltipContainer } from "./style";

export interface TooltipProps {
  icon?: JSX.Element;
  content?: string | JSX.Element;
  children?: ReactNode;
}

const Tooltip = ({ icon, content, children }: TooltipProps) => {
  return (
    <TooltipContainer {...buildTestIds("tooltip-container")}>
      {children}
      {icon ?? null}
      {content && <span {...buildTestIds("tooltip-content")}>{content}</span>}
    </TooltipContainer>
  );
};

export default Tooltip;
