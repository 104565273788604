import {
  ErrorDto,
  IHttpResponse,
  TwoFactorPublicResponseErrorDto,
} from "typing";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useRouter } from "next/router";
import { useAuth } from "../Identity/AuthContext";
import { NotificationApi } from "../../services";
import { useGiftCard } from "../../state-manager";

interface NotificationContextProps {
  isCodeValid: boolean;
  isValidationActive: boolean;
  codeHasError: boolean;
  isUserBlocked: boolean;
  isUserBlockedFromReceiveMessages: boolean;
  changeIsUserBlockedFromReceiveMessages(value: boolean): void;
  changeIsCodeValid(value: boolean): void;
  changeIsValidationActive(value: boolean): void;
  changeIsUserBlocked(value: boolean): void;
  changeCodeHasError(value: boolean): void;
  validateTwoFactorSMS: (
    type: 1 | 2 | 3,
    token: string
  ) => Promise<IHttpResponse<TwoFactorPublicResponseErrorDto, ErrorDto>>;
}

export interface NotificationProviderProps {
  children: ReactNode;
  notificationApi: NotificationApi;
}

export const NotificationContext = createContext(
  {} as NotificationContextProps
);

export const NotificationProvider = ({
  children,
  notificationApi,
}: NotificationProviderProps) => {
  const { getTokens } = useAuth();
  const router = useRouter();
  const [codeHasError, setCodeHasError] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isValidationActive, setIsValidationActive] = useState(false);
  const [
    isUserBlockedFromReceiveMessages,
    setIsUserBlockedFromReceiveMessages,
  ] = useState(false);
  const setSentSMSToken = useGiftCard((state) => state.setSentSMSToken);

  const changeIsUserBlockedFromReceiveMessages = useCallback(
    (value: boolean) => {
      setIsUserBlockedFromReceiveMessages(value);
    },
    []
  );
  const changeCodeHasError = useCallback((value: boolean) => {
    setCodeHasError(value);
  }, []);

  const changeIsUserBlocked = useCallback((value: boolean) => {
    setIsUserBlocked(value);
  }, []);

  const changeIsValidationActive = useCallback((value: boolean) => {
    setIsValidationActive(value);
  }, []);

  const changeIsCodeValid = useCallback((value: boolean) => {
    setIsCodeValid(value);
  }, []);

  useEffect(() => {
    if (router?.pathname !== "/checkout/pagamento" && isCodeValid) {
      changeIsCodeValid(false);
    }
  }, [changeIsCodeValid, isCodeValid, router?.pathname]);

  useEffect(() => {
    return () => {
      setSentSMSToken(null);
    };
  }, [router?.pathname, setSentSMSToken]);

  const validateTwoFactorSMS = useCallback(
    async (type: 1 | 2 | 3, token: string) => {
      const { data, isLoading, error, status, errors } =
        await notificationApi.validateTwoFactorSMS(
          type,
          token,
          getTokens()?.token as string
        );

      setSentSMSToken(token);

      return {
        data,
        error,
        isLoading,
        status,
        errors,
      } as unknown as IHttpResponse<
        TwoFactorPublicResponseErrorDto,
        ErrorDto,
        string[]
      >;
    },
    [getTokens, notificationApi, setSentSMSToken]
  );

  const notificationContextProviderValue = useMemo(() => {
    return {
      codeHasError,
      isUserBlocked,
      isValidationActive,
      isCodeValid,
      isUserBlockedFromReceiveMessages,
      changeIsCodeValid,
      changeIsValidationActive,
      changeCodeHasError,
      changeIsUserBlocked,
      validateTwoFactorSMS,
      changeIsUserBlockedFromReceiveMessages,
    };
  }, [
    codeHasError,
    isUserBlocked,
    isValidationActive,
    isCodeValid,
    isUserBlockedFromReceiveMessages,
    changeIsCodeValid,
    changeIsValidationActive,
    changeCodeHasError,
    changeIsUserBlocked,
    validateTwoFactorSMS,
    changeIsUserBlockedFromReceiveMessages,
  ]);

  return (
    <NotificationContext.Provider value={notificationContextProviderValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextProps => {
  const notificationContext = useContext(NotificationContext);

  if (!notificationContext) {
    throw new Error(
      "useNotification must be used within an NotificationProvider"
    );
  }

  return notificationContext;
};
