import { CloseIcon, theme } from "ui";
import { BottomSheet } from "../../BottomSheet";
import { StepperModal, StepperModalProps } from "../../StepperModal";
import { StatusProgress } from "../../StatusProgress";
import { StepperContainer } from "./styles";

const RegisterPhoneNumberModalUI = ({
  ...props
}: StepperModalProps & { isMobile: boolean; currentStep?: string }) => {
  if (props.isMobile) {
    return (
      <BottomSheet
        omitHeader={false}
        title={props.title}
        onCloseBottomSheet={props.onClose}
        stateBottomSheet={props.isOpen}
        justifyContent="space-between"
        onClickFooterButton={props.goNext}
        containerHeight="540px"
        heightContent="max-content"
        display="flex"
        buttonFontSize={theme.typography.fontSize.sm.x5}
        buttonFontWeight={theme.typography.fontWeight["500"]}
        textButton="Salvar"
        onActionTextButton={<CloseIcon />}
        headerAction={props.onClose}
        hasActionInHeader
        size="small"
        noFooter={props.currentStep !== "modalCall"}
        buttonBorderRadius={theme.space.x1}
        isButtonDisabled={props.isNextButtonDisabled}
        onDismiss={props.onClose}
        padding={`${theme.space.x4} ${theme.space.x0}`}
        paddingContent={`${theme.space.x0} ${theme.space.x4}`}
      >
        <StepperContainer>
          <StatusProgress
            height={props.statusHeight}
            statusStep={props.steps}
            hasIcon={false}
          />
        </StepperContainer>
        {props.children}
      </BottomSheet>
    );
  }

  return <StepperModal {...props} />;
};

export { RegisterPhoneNumberModalUI };
