import { UniqueClientDTO, UniqueClientSteps } from "typing";
import { PurchaseApi } from "../../services";
import { createStore } from "../adapter";

export interface UniqueClientState {
  isUniqueClientModalOpen: boolean;
  currentStep: UniqueClientSteps;
  uniqueClientInfo: UniqueClientDTO | null;
  needsToValidateEmail: boolean;
  needsToValidatePhoneNumber: boolean;
  hasError: boolean;
  isLoading: boolean;
}

export interface UniqueClientDispatch {
  getClientInfo: (purchaseApi: PurchaseApi, token: string) => void;
  setIsUniqueClientModalOpen: (value: boolean) => void;
  changeUniqueClientStep: (value: UniqueClientSteps) => void;
  setUniqueClientInfo: (value: UniqueClientDTO | null) => void;
  setNeedsToValidatePhoneNumber: (value: boolean) => void;
  setNeedsToValidateEmail: (value: boolean) => void;
}

type UniqueClient = UniqueClientState & UniqueClientDispatch;

const useUniqueClient = createStore<UniqueClient>((set) => ({
  currentStep: "modalCall",
  isUniqueClientModalOpen: false,
  needsToValidateEmail: false,
  needsToValidatePhoneNumber: false,
  hasError: false,
  isLoading: true,
  changeUniqueClientStep: (value) => {
    set({
      currentStep: value,
    });
  },
  setIsUniqueClientModalOpen: (value) => {
    set({
      isUniqueClientModalOpen: value,
    });
  },
  uniqueClientInfo: {
    isCustomerEligible: false,
    emailValidation: {
      isEmailVerified: false,
      isSameInPhysicalStoreAndEcommerce: false,
    },
    phoneNumberValidation: {
      isPhoneNumberVerified: false,
      isSameInPhysicalStoreAndEcommerce: false,
    },
  },
  setUniqueClientInfo: (value) => {
    set({
      uniqueClientInfo: value,
    });
  },

  setNeedsToValidatePhoneNumber: (value) => {
    set({
      needsToValidatePhoneNumber: value,
    });
  },

  setNeedsToValidateEmail: (value) => {
    set({
      needsToValidateEmail: value,
    });
  },

  getClientInfo: async (purchaseApi, token) => {
    const { data: clientInfo, error } = await purchaseApi.getVerifyUniqueClient(
      token
    );

    if (clientInfo || error) {
      set({
        isLoading: false,
      });
    }

    set({
      uniqueClientInfo: clientInfo,
      needsToValidateEmail:
        clientInfo?.emailValidation?.isSameInPhysicalStoreAndEcommerce &&
        !clientInfo?.emailValidation?.isEmailVerified,
      needsToValidatePhoneNumber:
        clientInfo?.phoneNumberValidation?.isSameInPhysicalStoreAndEcommerce &&
        !clientInfo?.phoneNumberValidation?.isPhoneNumberVerified,
      hasError: clientInfo === null,
    });
  },
}));

export { useUniqueClient };
