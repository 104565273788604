import { IconProps } from "typing";
import { theme } from "../themes";

export const SaleTagIcon = ({
  width = 28,
  height = 28,
  color = theme.colors.neutral.white,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8814 24.5817C10.0555 25.4155 8.7147 25.4155 7.88874 24.5817L1.07736 17.7116C0.251395 16.8778 0.251395 15.5243 1.07736 14.6905L11.3284 4.34822C11.8998 3.76571 12.6805 3.44591 13.4951 3.45733L19.6333 3.54299C20.8892 3.56012 21.9019 4.58236 21.9188 5.85017V5.8673C22.2017 5.97009 22.4902 6.0786 22.7787 6.19853C24.3458 6.85527 25.7262 7.74045 26.6766 8.69987L26.7275 8.75127C28.2606 10.3275 28.0909 11.5267 27.8533 12.0978C27.6157 12.6746 26.8916 13.6397 24.7079 13.6512C23.8253 13.6569 22.824 13.5027 21.7944 13.2114C21.6473 13.5883 21.421 13.9424 21.1268 14.2394L10.8814 24.5817ZM18.2642 7.15795C17.5797 6.46694 16.4709 6.46694 15.7863 7.15795C15.1018 7.84896 15.1018 8.96828 15.7807 9.65358C16.4652 10.3446 17.574 10.3446 18.2586 9.65358C18.2699 9.6393 18.2826 9.62503 18.2953 9.61075C18.3081 9.59647 18.3208 9.58219 18.3321 9.56792C19.0279 10.0876 19.8879 10.5673 20.8043 10.9556C23.9385 12.2749 26.0034 11.8637 26.1957 11.4011C26.258 11.2526 26.1788 10.7672 25.4433 10.0077L25.4094 9.97339C24.6343 9.191 23.4293 8.42575 22.0885 7.86038C22.0631 7.84896 22.039 7.83896 22.015 7.82897C21.991 7.81897 21.9669 7.80898 21.9415 7.79756L21.9188 10.1614C21.6386 10.1321 21.1268 9.90958 20.8043 9.74679C20.0189 9.35029 19.459 8.99042 18.7858 8.51071C18.865 7.97961 18.6715 7.56913 18.2642 7.15795Z"
        fill={color}
      />
      <mask
        id="mask0_11_427895"
        maskUnits="userSpaceOnUse"
        x="0"
        y="3"
        width="29"
        height="23"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8814 24.5817C10.0555 25.4155 8.7147 25.4155 7.88874 24.5817L1.07736 17.7116C0.251395 16.8778 0.251395 15.5243 1.07736 14.6905L11.3284 4.34822C11.8998 3.76571 12.6805 3.44591 13.4951 3.45733L19.6333 3.54299C20.8892 3.56012 21.9019 4.58236 21.9188 5.85017V5.8673C22.2017 5.97009 22.4902 6.0786 22.7787 6.19853C24.3458 6.85527 25.7262 7.74045 26.6766 8.69987L26.7275 8.75127C28.2606 10.3275 28.0909 11.5267 27.8533 12.0978C27.6157 12.6746 26.8916 13.6397 24.7079 13.6512C23.8253 13.6569 22.824 13.5027 21.7944 13.2114C21.6473 13.5883 21.421 13.9424 21.1268 14.2394L10.8814 24.5817ZM18.2642 7.15795C17.5797 6.46694 16.4709 6.46694 15.7863 7.15795C15.1018 7.84896 15.1018 8.96828 15.7807 9.65358C16.4652 10.3446 17.574 10.3446 18.2586 9.65358C18.2699 9.6393 18.2826 9.62503 18.2953 9.61075C18.3081 9.59647 18.3208 9.58219 18.3321 9.56792C19.0279 10.0876 19.8879 10.5673 20.8043 10.9556C23.9385 12.2749 26.0034 11.8637 26.1957 11.4011C26.258 11.2526 26.1788 10.7672 25.4433 10.0077L25.4094 9.97339C24.6343 9.191 23.4293 8.42575 22.0885 7.86038C22.0631 7.84896 22.039 7.83896 22.015 7.82897C21.991 7.81897 21.9669 7.80898 21.9415 7.79756L21.9188 10.1614C21.6386 10.1321 21.1268 9.90958 20.8043 9.74679C20.0189 9.35029 19.459 8.99042 18.7858 8.51071C18.865 7.97961 18.6715 7.56913 18.2642 7.15795Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_11_427895)">
        <rect width={width} height={height} fill={color} />
      </g>
    </svg>
  );
};
