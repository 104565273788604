import { MultipleColorsIconProps } from "typing";
import { theme } from "../themes";

export const WhatsappIcon = ({
  width = 24,
  height = 25,
  alt = "ícone de whatsapp",
  color = theme.colors.secondary["750"],
  secondColor = theme.colors.neutral.white,
}: MultipleColorsIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      aria-label={alt}
      aria-labelledby={alt}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="&#240;&#159;&#166;&#134; icon &#34;Whatsapp&#34;">
        <path
          id="Vector"
          d="M24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5C18.6274 24.5 24 19.1274 24 12.5Z"
          fill={color}
        />
        <g id="Group">
          <path
            id="Vector_2"
            d="M6 18.0556L6.86098 14.8543C6.16663 13.6072 5.94603 12.152 6.23965 10.7557C6.53328 9.3593 7.32144 8.11549 8.45951 7.25247C9.59757 6.38947 11.0092 5.96513 12.4354 6.05732C13.8615 6.1495 15.2066 6.75206 16.2237 7.7544C17.2407 8.75674 17.8617 10.0916 17.9725 11.5141C18.0832 12.9366 17.6765 14.3513 16.8268 15.4985C15.9772 16.6458 14.7416 17.4487 13.3469 17.7599C11.9521 18.0711 10.4918 17.8697 9.23375 17.1927L6 18.0556ZM9.38966 15.9947L9.58965 16.1131C10.5009 16.6523 11.5651 16.8755 12.6166 16.7478C13.6681 16.62 14.6478 16.1487 15.4029 15.4071C16.158 14.6655 16.6462 13.6954 16.7914 12.6479C16.9366 11.6003 16.7306 10.5343 16.2056 9.61586C15.6805 8.69742 14.8659 7.97817 13.8887 7.57018C12.9114 7.16222 11.8265 7.08844 10.8028 7.36036C9.77919 7.63225 8.87441 8.2346 8.22947 9.07348C7.58453 9.91237 7.23565 10.9407 7.23722 11.9981C7.23638 12.875 7.47926 13.7349 7.93889 14.482L8.06431 14.6885L7.58298 16.4753L9.38966 15.9947Z"
            fill={secondColor}
          />
          <path
            id="Vector_3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2432 12.8197C14.126 12.7253 13.9887 12.6589 13.8419 12.6254C13.695 12.592 13.5425 12.5925 13.3958 12.6268C13.1755 12.7182 13.0331 13.0634 12.8908 13.236C12.8607 13.2773 12.8166 13.3063 12.7667 13.3176C12.7168 13.3288 12.6645 13.3214 12.6196 13.2969C11.813 12.9815 11.1368 12.4031 10.7011 11.6556C10.6639 11.609 10.6463 11.5497 10.652 11.4904C10.6577 11.4311 10.6862 11.3763 10.7315 11.3375C10.8903 11.1806 11.0069 10.9862 11.0705 10.7724C11.0846 10.5365 11.0305 10.3016 10.9146 10.0956C10.825 9.80676 10.6544 9.54959 10.4231 9.35447C10.3038 9.3009 10.1715 9.28294 10.0422 9.30276C9.91286 9.32257 9.79205 9.3793 9.69431 9.46614C9.52464 9.61227 9.38999 9.79454 9.30024 9.99952C9.21047 10.2045 9.16792 10.427 9.17571 10.6505C9.17621 10.7761 9.19217 10.9011 9.22315 11.0228C9.30187 11.3151 9.42291 11.5945 9.58246 11.8519C9.69756 12.0491 9.82317 12.24 9.95871 12.4238C10.3992 13.0275 10.9529 13.5401 11.5891 13.9331C11.9084 14.1328 12.2496 14.2952 12.606 14.417C12.9763 14.5845 13.3851 14.6489 13.789 14.6031C14.0192 14.5683 14.2372 14.4776 14.424 14.3389C14.6108 14.2003 14.7606 14.0179 14.8602 13.8079C14.9187 13.681 14.9364 13.5391 14.911 13.4018C14.85 13.1209 14.4737 12.9551 14.2432 12.8197Z"
            fill={secondColor}
          />
        </g>
      </g>
    </svg>
  );
};
