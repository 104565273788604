import { IconProps } from "typing";

export const NoPackages = ({ width = 213, height = 137 }: IconProps) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 213 137"
    >
      <defs>
        <style>
          {
            ".a{fill:#f7f7f7;}.b{fill:#eaeaea;}.c{fill:#f8f8f8;}.d{fill:#f2f2f2;}.e{fill:#fafafa;}.f{fill:url(#a);}.g{fill:url(#b);}.h{fill:url(#c);}.i{fill:#ba0f2b;}.j{fill:#222235;}.k{fill:#fff;}.l{fill:rgba(0,0,0,0.08);}.m{opacity:0.854;}.n{fill:none;stroke:rgba(189,39,47,0.6);stroke-linecap:round;}.o,.p{stroke:none;}.p{fill:#222235;}"
          }
        </style>
        <linearGradient
          id="a"
          x1={0.559}
          y1={0.611}
          x2={0.86}
          y2={0.533}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <radialGradient
          id="b"
          cx={0.488}
          cy={0.5}
          r={0.47}
          gradientTransform="matrix(0.953, -0.304, 0.29, 0.91, -0.123, 0.185)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.724} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
        <linearGradient
          id="c"
          x1={0.116}
          y1={0.79}
          x2={0.18}
          y2={-0.167}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#bd272f" stopOpacity={0} />
          <stop offset={1} stopColor="#bd272f" stopOpacity={0.302} />
        </linearGradient>
      </defs>
      <g transform="translate(-336 -235)">
        <path
          className="a"
          d="M0,0,9-7.366H21V46H0Z"
          transform="translate(336 309)"
        />
        <path
          className="a"
          d="M0-33.893H24.506L17.734,46H0Z"
          transform="translate(383 309)"
        />
        <path
          className="b"
          d="M-.256-25.4H12.609L14.656,1.27l.919,44.2H-5.809Z"
          transform="translate(411 309)"
        />
        <path
          className="b"
          d="M-12.213,19.1l-19.662-1.145V6.822H7.182v3.872H3.826L2.787,45.469H-9.247Z"
          transform="translate(483.213 242.747)"
        />
        <path
          className="c"
          d="M-.505-6.738H27.341L30,82.143H13.012V44.267H-7.626L-2.5.471-12.981-6.738Z"
          transform="translate(441 271)"
        />
        <path
          className="d"
          d="M-5.809,10.2H15.575V45.469H-5.809Z"
          transform="translate(435.384 309)"
        />
        <path
          className="d"
          d="M-6.8,14.216H14.587l2.646,22.762L18.4,75.012H-5.809Z"
          transform="translate(480.384 278)"
        />
        <path
          className="e"
          d="M-5.809,10.2h26.4V45.469H.4L-.747,60.9h-6.72Z"
          transform="translate(356.384 238.134)"
        />
        <path
          className="d"
          d="M0,0H18l1.428,68H-1Z"
          transform="translate(361 287)"
        />
        <path
          className="d"
          d="M-.193,50.652H24.743V77.744H19.722L18.89,68H-1Z"
          transform="translate(394 204)"
        />
        <g transform="translate(-4 16)">
          <rect
            className="f"
            width={195}
            height={131}
            transform="translate(358 219)"
          />
          <rect
            className="g"
            width={211}
            height={136}
            transform="translate(340 220)"
          />
        </g>
        <path
          className="h"
          d="M3.354-9.774H17.373L36.907,65.515l-54.568-4.356Z"
          transform="translate(413 286)"
        />
        <g transform="translate(409.83 320.554) rotate(-13)">
          <path
            className="i"
            d="M13,11H2A2,2,0,0,1,0,9V0H15V9A2,2,0,0,1,13,11ZM5.616,2.2a1,1,0,0,0-1,1v.2a1,1,0,0,0,1,1H9.385a1,1,0,0,0,1-1V3.2a1,1,0,0,0-1-1Z"
            transform="translate(2.12 16.1)"
          />
          <path
            className="i"
            d="M2,0H15a2,2,0,0,1,2,2V4a0,0,0,0,1,0,0H0A0,0,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
            transform="translate(1.12 11.1)"
          />
        </g>
        <g transform="translate(4.955 5.98)">
          <ellipse
            className="j"
            cx={20.5}
            cy={6.5}
            rx={20.5}
            ry={6.5}
            transform="translate(398.045 259.02)"
          />
          <g className="k" transform="translate(408.968 252)">
            <path
              className="o"
              d="M 9.577101707458496 12.54406452178955 C 4.847671508789062 12.54406452178955 1.000001668930054 11.21309471130371 1.000001668930054 9.577104568481445 C 1.000001668930054 4.84767484664917 4.847671508789062 1.000004649162292 9.577101707458496 1.000004649162292 C 14.30653190612793 1.000004649162292 18.15420150756836 4.84767484664917 18.15420150756836 9.577104568481445 C 18.15420150756836 11.21309471130371 14.30653190612793 12.54406452178955 9.577101707458496 12.54406452178955 Z"
            />
            <path
              className="p"
              d="M 9.577101707458496 2.000004768371582 C 5.39907169342041 2.000004768371582 2.000001907348633 5.399074554443359 2.000001907348633 9.577104568481445 C 2.000001907348633 9.70484447479248 2.399072647094727 10.20244407653809 3.683871269226074 10.67549514770508 C 5.183061599731445 11.22748470306396 7.331061363220215 11.54406452178955 9.577101707458496 11.54406452178955 C 11.82314109802246 11.54406452178955 13.97114181518555 11.22748470306396 15.4703311920166 10.67549514770508 C 16.75513076782227 10.20244407653809 17.15420150756836 9.70484447479248 17.15420150756836 9.577104568481445 C 17.15420150756836 5.399074554443359 13.75513172149658 2.000004768371582 9.577101707458496 2.000004768371582 M 9.577101707458496 4.76837158203125e-06 C 14.86639213562012 4.76837158203125e-06 19.15420150756836 4.287815093994141 19.15420150756836 9.577104568481445 C 19.15420150756836 12.22174453735352 14.36565208435059 13.54406452178955 9.577101707458496 13.54406452178955 C 4.788551330566406 13.54406452178955 1.9073486328125e-06 12.22174453735352 1.9073486328125e-06 9.577104568481445 C 1.9073486328125e-06 4.287815093994141 4.287811279296875 4.76837158203125e-06 9.577101707458496 4.76837158203125e-06 Z"
            />
          </g>
        </g>
        <ellipse
          className="l"
          cx={13.5}
          cy={3.5}
          rx={13.5}
          ry={3.5}
          transform="translate(411 355)"
        />
        <g className="m">
          <line className="n" y2={4} transform="translate(430.5 345.5)" />
          <line className="n" y2={5.793} transform="translate(427.5 346.707)" />
          <line className="n" y2={4} transform="translate(419.5 347.5)" />
          <line className="n" y2={3} transform="translate(415.5 343.707)" />
        </g>
      </g>
    </svg>
  );
};
