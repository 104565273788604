import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  CartAddItems,
  CartDeleteItem,
  CartDto,
  CartItemsDto,
  CartUpdateItem,
  ProductCardDto,
} from "typing";

import { useRouter } from "next/router";
import { useToast } from "../../hooks";
import { handleCartError } from "../../services/apis/Shopping/data";
import { getClientLocationDetails, getProductQuantity } from "../../utils";
import { useBranchLocation } from "../BranchLocationContext";
import { useAuth } from "../Identity/AuthContext";
import { useWebSocket } from "../WebSocket";
import {
  createCartAuth,
  createNewCartAnonymous,
  removeItemCartAction,
  setItemCartAction,
  updateQuantityItemCartAction,
  useFetchCartAnonymous,
  useGetCartAuth,
} from "./shoppingApi";
import {
  Action,
  CartContextType,
  CartItemsContextReduce,
  CartProviderProps,
  CartReducer,
  ErrorDto,
  ReducerType,
} from "./typesContextCart";
import {
  addItemList,
  createTokenCartANONYMOUS,
  deleteTokenCartANONYMOUS,
  getTokenCartANONYMOUS,
  handleCartHasChanges,
  isUpdateQuantityAction,
  persistFromLimitOnCartItem,
  setItemLimit,
} from "./utilsCart";

export const cartIdCookie = "@FC:Ecom:Cart:Id";

const reducer = (state: CartReducer, action: Action): CartReducer => {
  switch (action.type) {
    case "NEW_CART_DATA":
      return {
        ...action.payload,
        isLoading: false,
        oldItemCartUpdate: null,
        cartItems: persistFromLimitOnCartItem(
          state.cartItems,
          action.payload.cartItems
        ),
      };
    case "ADD_NEW_ITEM":
      return {
        ...state,
        cartItems: addItemList(state.cartItems, action.payload),
      };
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "IS_LOADING_INITIAL_PAGE":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "IS_UPDATE_QUANTITY_ITEM":
      return {
        ...state,
        cartItems: isUpdateQuantityAction(state.cartItems, action.payload),
        isLoading: false,
      };
    case "QUANTITY_LIMIT":
      return {
        ...state,
        cartItems: setItemLimit(state.cartItems, action.payload),
        isLoading: false,
      };
    default:
      throw new Error();
  }
};

const initState = (): CartReducer => {
  return {
    id: 0,
    clientId: 0,
    cookieId: "",
    branchId: 0,
    quantityOfItemsInCart: 0,
    totalSpotPrice: 0,
    totalSalePrice: 0,
    maxNumberOfInstallments: 0,
    minInstallmentValue: 0,
    cartItems: [],
    isLoading: false,
    oldItemCartUpdate: null,
  };
};

const CartContext = createContext({} as CartContextType);

export const CartProvider = ({
  children,
  shoppingApi,
  catalogApi,
  cookie,
  appMonitoringClient,
  PopUpAlertConfirm,
}: CartProviderProps) => {
  const router = useRouter();
  const { addToast } = useToast();
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;
  const { getBranchLocationClient, setRegionClientLocation } =
    useBranchLocation();
  const BRANCH_LOCATION = getBranchLocationClient();
  const TOKEN_COOKIE_ANONYMOUS = getTokenCartANONYMOUS(cookie);
  const [messageError, setMessageError] = useState("");
  const [isSearchCEPButtonLoading, setIsSearchCEPButtonLoading] =
    useState(false);
  const [limitItemsPerCpf, setLimitItemsPerCpf] = useState(0);

  const [isRemoveProductPopUpOpen, setIsRemoveProductPopUpOpen] =
    useState(false);
  const [isAddProductPopUpOpen, setIsAddProductPopUpOpen] = useState(false);

  const errorDescriptionMessage =
    "Ocorreu um erro inesperado. Por favor, tente novamente em instantes.";

  const [stateCart, dispatch] = useReducer<
    ReducerType<CartReducer, Action, CartReducer>,
    CartReducer
  >(
    reducer,
    {
      id: 0,
      clientId: 0,
      cookieId: "",
      branchId: 0,
      quantityOfItemsInCart: 0,
      totalSpotPrice: 0,
      totalSalePrice: 0,
      maxNumberOfInstallments: 0,
      minInstallmentValue: 0,
      cartItems: [],
      isLoading: false,
      oldItemCartUpdate: null,
    },
    initState as unknown as () => CartReducer
  ) as [CartReducer, (args: Action) => void];

  const errorCallback = useCallback(
    (productId?: string | number, cartId?: string | number) => {
      addToast({
        title: "Houve um erro ao adicionar o produto ao carrinho",
        description:
          "Não foi possível adicionar o produto em questão ao seu carrinho. Por favor tente novamente",
        isNewToast: true,
        newToastTheme: "light",
        type: "error",
      });

      if (productId || cartId) {
        appMonitoringClient.captureMessage(
          `Falha ao adicionar o produto ${productId} ao carrinho. Cartid inválido: ${cartId}`
        );
      }
    },
    [addToast, appMonitoringClient]
  );

  const cartIdValue = useMemo(
    () =>
      stateCart.id ||
      Number(
        cookie.getCookie({
          name: cartIdCookie,
        })
      ),
    [cookie, stateCart.id]
  );

  const {
    data: dataGetCart,
    isLoading: isLoadingAuth,
    trigger: triggerGetCartAuth,
  } = useGetCartAuth(
    accessToken ?? "",
    shoppingApi,
    appMonitoringClient,
    Boolean(accessToken)
  );

  const {
    data: dataFetchCartAnonymous,
    isLoading: isLoadingAnonymous,
    trigger: triggerCartAnonymous,
  } = useFetchCartAnonymous(
    TOKEN_COOKIE_ANONYMOUS || "",
    shoppingApi,
    appMonitoringClient,
    Boolean(TOKEN_COOKIE_ANONYMOUS)
  );

  useEffect(() => {
    if (dataGetCart) {
      cookie.setCookie({
        name: cartIdCookie,
        value: String(dataGetCart.id),
      });
      return;
    }

    if (dataFetchCartAnonymous) {
      cookie.setCookie({
        name: cartIdCookie,
        value: String(dataFetchCartAnonymous.id),
      });
    }
  }, [cookie, dataFetchCartAnonymous, dataGetCart]);

  const { emitEvent } = useWebSocket();

  const handleEmitEventCartModified = useCallback(() => {
    const clientLocation = getClientLocationDetails(cookie);
    emitEvent("cartModified", clientLocation);
  }, [cookie, emitEvent]);

  const createdCartAuthAction = useCallback(async () => {
    const response = (await createCartAuth(
      Number(BRANCH_LOCATION),
      accessToken ?? "",
      appMonitoringClient,
      shoppingApi
    )) as unknown as CartDto;

    if (response) {
      dispatch({
        type: "NEW_CART_DATA",
        payload: response,
      });
    }
  }, [BRANCH_LOCATION, accessToken, appMonitoringClient, shoppingApi]);

  // A lógica abaixo tem a pretensão de criar um novo carrinho sempre que o branchId for alterado. Se o usuário estiver logado, uma requisição PUT é feita para criar um novo carrinho com o branchId atualizado. Se o usuário não estiver logado, o token do carrinho anônimo é deletado e um novo carrinho é criado.
  useEffect(() => {
    if (
      stateCart.branchId !== 0 &&
      stateCart.branchId !== Number(BRANCH_LOCATION)
    ) {
      if (accessToken) {
        createdCartAuthAction();
      } else {
        deleteTokenCartANONYMOUS(cookie);
      }
    }
  }, [
    BRANCH_LOCATION,
    accessToken,
    cookie,
    createdCartAuthAction,
    stateCart.branchId,
  ]);

  const initialCartAnonymous = useCallback(async () => {
    const response = (await createNewCartAnonymous(
      Number(BRANCH_LOCATION),
      shoppingApi,
      appMonitoringClient
    )) as unknown as CartDto;

    if (response) {
      const { cookieId } = response as unknown as CartDto;
      dispatch({
        type: "NEW_CART_DATA",
        payload: response,
      });
      createTokenCartANONYMOUS(cookie, `${cookieId}`);
    }
  }, [BRANCH_LOCATION, appMonitoringClient, cookie, shoppingApi]);

  const [isFetchingCartFromEventListener, setIsFetchingCartFromEventListener] =
    useState(false);

  const fetchCart = useCallback(
    (fromEventListener = false) => {
      if (accessToken && triggerGetCartAuth) {
        triggerGetCartAuth();

        if (fromEventListener) {
          setIsFetchingCartFromEventListener(true);
        }
      } else if (!TOKEN_COOKIE_ANONYMOUS) {
        initialCartAnonymous();
      } else if (triggerCartAnonymous) {
        triggerCartAnonymous();
      }
    },
    [
      accessToken,
      TOKEN_COOKIE_ANONYMOUS,
      initialCartAnonymous,
      triggerGetCartAuth,
      triggerCartAnonymous,
    ]
  );

  const setInitialStateCart = useCallback((stateCartDto: CartDto) => {
    if (!stateCartDto) {
      return;
    }
    dispatch({
      type: "NEW_CART_DATA",
      payload: stateCartDto,
    });
  }, []);

  const handleAddCartItemError = useCallback(
    (errorData: ErrorDto, limitItemsPerCpfStr: number) => {
      if (errorData?.errors?.["0"]?.errorMessage?.includes("limite")) {
        setMessageError(
          `Este item possui um limite de ${limitItemsPerCpfStr} unidade(s) por cliente.`
        );
        return errorData?.errors?.[0]?.errorMessage?.replace(/\D/g, "");
      }

      if (errorData?.errors?.["0"]?.errorMessage?.includes("PackingQuantity")) {
        setMessageError(
          "A quantidade de pacotes do produto deve ser maior que 0."
        );

        return null;
      }

      if (errorData?.errors?.["0"]?.errorMessage?.includes("indisponível")) {
        setMessageError("Essa oferta está esgotada no momento.");
        return null;
      }

      if (errorData?.errors?.["0"]?.errorMessage?.includes("informada")) {
        setMessageError("A filial precisa ser informada.");
        return null;
      }

      if (errorData?.errors?.["0"]?.errorMessage?.includes("carrinho")) {
        setMessageError("A filial do produto deve ser a mesma do carrinho.");
        return null;
      }

      setMessageError(errorDescriptionMessage);
      return null;
    },
    [setMessageError]
  );

  const addNewItemCart = useCallback(
    async ({
      productId,
      quantity,
      package: packageItem,
      packingQuantity,
      sellerId,
      price,
      cartId = cartIdValue,
      listId,
      branchId,
      shouldValidateIfBranchIdIsChanged = true,
      showPopUp = true,
    }: CartAddItems & {
      shouldValidateIfBranchIdIsChanged?: boolean;
      showPopUp?: boolean;
    }): Promise<{ limitTotalItens: string } | object> => {
      const reliableCartId = cartId || cartIdValue;

      if (!reliableCartId) {
        errorCallback(productId, reliableCartId);

        return {};
      }

      const returnData = (await setItemCartAction(
        {
          cartId: reliableCartId,
          productId,
          quantity,
          package: packageItem,
          packingQuantity,
          sellerId,
          price,
          branchId,
          listId,
        },
        shoppingApi,
        appMonitoringClient,
        accessToken
      )) as unknown as CartItemsDto;

      const errorData = returnData as unknown as ErrorDto;

      if (shouldValidateIfBranchIdIsChanged) {
        const { isBranchIdChanged, branchId: updatedBranchId } =
          await handleCartHasChanges(
            "add",
            errorData,
            accessToken as string,
            shoppingApi,
            cookie,
            appMonitoringClient,
            branchId as number
          );

        if (isBranchIdChanged) {
          return addNewItemCart({
            productId,
            quantity,
            package: packageItem,
            packingQuantity,
            sellerId,
            price,
            cartId: reliableCartId,
            listId,
            branchId: updatedBranchId,
            shouldValidateIfBranchIdIsChanged: false,
          });
        }
      }

      if (
        ("isValid" in errorData && !errorData?.isValid) ||
        !("id" in errorData)
      ) {
        if (errorData?.errors?.length) {
          handleCartError(errorData, appMonitoringClient, "add_item_to_cart");
        }

        const TOTAL_ITEMS_LIMIT = handleAddCartItemError(
          errorData,
          limitItemsPerCpf
        );

        if (showPopUp) {
          setIsAddProductPopUpOpen(true);
        }
        return { limitTotalItens: TOTAL_ITEMS_LIMIT };
      }

      dispatch({ type: "ADD_NEW_ITEM", payload: returnData });
      fetchCart();
      handleEmitEventCartModified();

      return {};
    },
    [
      accessToken,
      appMonitoringClient,
      cartIdValue,
      cookie,
      errorCallback,
      fetchCart,
      handleAddCartItemError,
      handleEmitEventCartModified,
      limitItemsPerCpf,
      shoppingApi,
    ]
  );

  const updateQuantityItemCart = useCallback(
    async ({
      showPopUp = true,
      ...args
    }: CartUpdateItem & { showPopUp?: boolean }): Promise<{
      hasError: boolean;
    } | void> => {
      const returnRequestUpdate = (await updateQuantityItemCartAction(
        args,
        shoppingApi,
        appMonitoringClient,
        accessToken
      )) as unknown as ErrorDto;

      const { isBranchIdChanged, hasSomeError } = await handleCartHasChanges(
        "update",
        returnRequestUpdate,
        accessToken as string,
        shoppingApi,
        cookie,
        appMonitoringClient,
        Number(BRANCH_LOCATION)
      );

      if (isBranchIdChanged) {
        setRegionClientLocation(null);
      }

      if (hasSomeError) {
        fetchCart();
        addToast({
          title: isBranchIdChanged
            ? "O seu carrinho foi atualizado"
            : "Esse item já foi excluído do seu carrinho",
          type: "info",
          timerInMilliseconds: 3000,
        });
        return undefined;
      }

      if (
        (returnRequestUpdate &&
          "isValid" in returnRequestUpdate &&
          !returnRequestUpdate?.isValid) ||
        (returnRequestUpdate && !("id" in returnRequestUpdate))
      ) {
        handleCartError(
          returnRequestUpdate,
          appMonitoringClient,
          "update_item_to_cart"
        );

        if (
          returnRequestUpdate?.errors?.[0]?.errorMessage?.includes("limite")
        ) {
          const TOTAL_ITEMS_LIMIT =
            returnRequestUpdate?.errors?.[0]?.errorMessage?.replace(/\D/g, "");
          dispatch({
            type: "QUANTITY_LIMIT",
            payload: {
              limitItemCart: Number(TOTAL_ITEMS_LIMIT || 1),
              productIdCart: args.idProductCart,
            },
          });
          setMessageError(
            returnRequestUpdate?.errors?.[0]?.errorMessage ||
              "Ops! Você atingiu o limite máximo de quantidade permitida por compra."
          );
        }

        if (showPopUp) {
          setIsAddProductPopUpOpen(true);
        }
        return { hasError: true };
      }

      dispatch({
        type: "IS_UPDATE_QUANTITY_ITEM",
        payload: {
          productIdCart: args.idProductCart,
          quantityItem: args.quantity,
        },
      });
      fetchCart();

      handleEmitEventCartModified();
      return undefined;
    },
    [
      BRANCH_LOCATION,
      accessToken,
      addToast,
      appMonitoringClient,
      cookie,
      fetchCart,
      handleEmitEventCartModified,
      setRegionClientLocation,
      shoppingApi,
    ]
  );

  const addProductToControlledCart = useCallback(
    async ({
      productId,
      quantity,
      package: packageItem,
      packingQuantity,
      sellerId,
      price,
      cartId = cartIdValue,
      listId = 0,
      showPopUp,
    }: CartAddItems & { showPopUp?: boolean }) => {
      const reliableCartId = cartId || cartIdValue;

      if (!reliableCartId) {
        errorCallback(productId, reliableCartId);
      }

      return addNewItemCart({
        productId,
        branchId: Number(BRANCH_LOCATION),
        quantity,
        package: packageItem,
        packingQuantity,
        sellerId,
        price,
        cartId: reliableCartId,
        listId,
        showPopUp,
      });
    },
    [BRANCH_LOCATION, addNewItemCart, cartIdValue, errorCallback]
  );

  const removeItemCart = useCallback(
    async (args: CartDeleteItem) => {
      const hasErrorOnItemRemoval = await removeItemCartAction(
        args,
        shoppingApi,
        appMonitoringClient,
        accessToken
      );

      if (hasErrorOnItemRemoval) {
        setMessageError(errorDescriptionMessage);
        setIsRemoveProductPopUpOpen(true);
      }

      handleEmitEventCartModified();
      return fetchCart();
    },
    [
      accessToken,
      appMonitoringClient,
      fetchCart,
      handleEmitEventCartModified,
      shoppingApi,
    ]
  );

  useEffect(() => {
    if (dataGetCart) {
      dispatch({
        type: "NEW_CART_DATA",
        payload: { ...dataGetCart },
      });
    }

    if (dataFetchCartAnonymous) {
      dispatch({
        type: "NEW_CART_DATA",
        payload: { ...dataFetchCartAnonymous },
      });
    }
  }, [dataFetchCartAnonymous, dataGetCart]);

  useEffect(() => {
    if (isFetchingCartFromEventListener && !isLoadingAuth && dataGetCart) {
      setIsFetchingCartFromEventListener(false);
    }
  }, [dataGetCart, isFetchingCartFromEventListener, isLoadingAuth]);

  useEffect(() => {
    dispatch({
      type: "IS_LOADING",
      payload: isLoadingAnonymous || isLoadingAuth,
    });
  }, [isLoadingAnonymous, isLoadingAuth]);

  useEffect(() => {
    fetchCart();
  }, [TOKEN_COOKIE_ANONYMOUS, accessToken, fetchCart]);

  useEffect(() => {
    const handleClientHasCartItems = () => {
      localStorage?.setItem(
        "clientHasCartItems",
        `${Boolean(
          dataFetchCartAnonymous?.cartItems?.length ||
            dataGetCart?.cartItems?.length
        )}`
      );
    };

    window.addEventListener("beforeunload", handleClientHasCartItems);

    return () => {
      window.removeEventListener("beforeunload", handleClientHasCartItems);
    };
  }, [dataFetchCartAnonymous?.cartItems, dataGetCart?.cartItems]);

  /**
   * artur.castro Encontrar melhor solução para depois
   */

  useEffect(() => {
    const routeChangeHandler = () => {
      if (router.pathname !== "/produto/[idProduct]/[nameProduct]") {
        setMessageError("");
      }
    };

    router.events.on("routeChangeStart", routeChangeHandler);

    // Solução temporária para forçar a exclusão do token do carrinho anônimo, quando o usuário já estiver logado
    if (accessToken && getTokenCartANONYMOUS(cookie)) {
      deleteTokenCartANONYMOUS(cookie);
    }

    return () => {
      router.events.off("routeChangeStart", routeChangeHandler);
    };
  }, [accessToken, cookie, router]);

  const [cartProductsList, setCartProductsList] = useState<ProductCardDto[]>(
    []
  );

  const getCartProductsList = useCallback(
    (cartItems: CartItemsContextReduce[]) => {
      const productIdsList = cartItems?.map((item) => item.productId);
      const productListString = productIdsList?.join(",");

      const { data: productsList, isLoading } = catalogApi.getProductList(
        productListString,
        String(cartItems?.[0]?.branchId),
        !!(productListString && cartItems?.[0]?.branchId)
      );

      const formattedProductsList = (productsList as ProductCardDto[])?.map(
        (item) => {
          const quantity = getProductQuantity(item?.id, cartItems);

          return {
            ...item,
            quantity,
          };
        }
      );

      const hasCartProductsListChanges = formattedProductsList?.find(
        ({ id, quantity }, index) =>
          id !== cartProductsList?.[`${index}`]?.id ||
          quantity !== cartProductsList?.[`${index}`]?.quantity
      );

      if (hasCartProductsListChanges) {
        setCartProductsList(formattedProductsList);
      }

      return {
        productsList: formattedProductsList,
        isLoadingProductInfo: isLoading,
      };
    },
    [cartProductsList, catalogApi]
  );

  const removeProductsFromCartProductsList = useCallback(
    (productIdsList: string[], currentCartItemsList: ProductCardDto[]) => {
      const updatedCartItemsList = currentCartItemsList?.filter(
        ({ id }) => !productIdsList?.includes(id)
      );
      setCartProductsList(updatedCartItemsList);
      handleEmitEventCartModified();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const cartProviderValues = useMemo(() => {
    return {
      ...stateCart,
      createdCartAuthAction,
      initialCartAnonymous,
      addNewItemCart,
      updateQuantityItemCart,
      addProductToControlledCart,
      setInitialStateCart,
      removeItemCart,
      fetchCart,
      isSearchCEPButtonLoading,
      setIsSearchCEPButtonLoading,
      messageError,
      handleEmitEventCartModified,
      getCartProductsList,
      cartProductsList,
      removeProductsFromCartProductsList,
      setLimitItemsPerCpf,
      isFetchingCartFromEventListener,
      isGetCartLoading: isLoadingAuth || isLoadingAnonymous,
    };
  }, [
    stateCart,
    createdCartAuthAction,
    initialCartAnonymous,
    addNewItemCart,
    updateQuantityItemCart,
    addProductToControlledCart,
    setInitialStateCart,
    removeItemCart,
    fetchCart,
    isSearchCEPButtonLoading,
    messageError,
    handleEmitEventCartModified,
    getCartProductsList,
    cartProductsList,
    removeProductsFromCartProductsList,
    isFetchingCartFromEventListener,
    isLoadingAuth,
    isLoadingAnonymous,
  ]);

  return (
    <CartContext.Provider value={cartProviderValues}>
      {children}

      <PopUpAlertConfirm
        textHeader="Falha ao adicionar produto ao carrinho."
        descriptionBody={`Ops! ${messageError}`}
        isOpen={isAddProductPopUpOpen}
        actionCloseButton={() => {
          setIsAddProductPopUpOpen(false);
        }}
      />

      <PopUpAlertConfirm
        textHeader="Falha ao remover produto do carrinho."
        descriptionBody={messageError}
        isOpen={isRemoveProductPopUpOpen}
        actionCloseButton={() => {
          setIsRemoveProductPopUpOpen(false);
        }}
      />
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  return useContext(CartContext);
};
