import { NewAddressFormComponents } from "typing";
import { CustomSelect } from "../../../../Form";
import { WrapperSelect } from "../../../styles";

type StateComponentProps = NewAddressFormComponents & {
  stateList: string[];
};

export const StateComponent = ({
  control,
  disabled,
  hasError,
  stateList,
}: StateComponentProps) => {
  const stateListFormatted = stateList.map((state, index) => ({
    value: state,
    label: state,
    index: index + 1,
  }));
  return (
    <WrapperSelect>
      <CustomSelect
        id="state"
        name="state"
        label="Estado"
        control={control}
        disabled={disabled}
        placeholder="Selecione o estado"
        hasError={hasError}
        width="100%"
        optionsList={stateListFormatted}
        required
      />
    </WrapperSelect>
  );
};
