import styled, { css } from "styled-components";
import { theme } from "../../themes";

type OverlayDynamicProps = {
  isVisible: boolean;
  height: string;
  top?: string;
};

export const OverlayDynamic = styled.div<OverlayDynamicProps>`
  position: ${({ isVisible }) => (isVisible ? "absolute" : "fixed")};
  width: 100%;
  height: ${({ height }) => height};
  background-color: ${theme.colors.neutral["999-opacity-50"]};
  z-index: 50;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  opacity: ${({ isVisible }) => (isVisible ? "1.0" : "0")};
  overflow: hidden;
  transition: all 1s ease;
  ${({ top }) =>
    top &&
    css`
      top: ${top};
    `}
`;
