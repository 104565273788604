import { IconProps } from "typing";
import { theme } from "../themes";

export const Alliances = ({
  width = 40,
  height = 40,
  color = theme.colors.neutral["999"],
  strokeWidth = "1.25",
  alt = "Alianças",
}: IconProps) => {
  return (
    <svg
      aria-label={alt}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <circle
        cx="8.16665"
        cy="15.0106"
        r="5.41665"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <circle
        cx="15.8333"
        cy="15.0106"
        r="5.41665"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12 7.17733L11.5638 7.78741L11.5637 7.78739L11.5636 7.78731L11.5632 7.78703L11.5618 7.78597L11.556 7.78188L11.5344 7.76638L11.4564 7.71048C11.3914 7.66382 11.3032 7.6004 11.2107 7.53352C11.0356 7.40698 10.8163 7.24749 10.7315 7.17965C10.6431 7.10896 10.1158 6.63397 9.87182 6.37606C9.72508 6.22094 9.55479 5.98833 9.42244 5.73296C9.30068 5.49801 9.14672 5.12276 9.18706 4.71937C9.2462 4.1279 9.60617 3.78716 9.71518 3.68398C9.72395 3.67567 9.7311 3.6689 9.73634 3.66367C10.0848 3.31517 10.4623 3.26732 10.624 3.26143C10.6408 3.25972 10.6651 3.25738 10.6939 3.2553C10.7607 3.25051 10.8537 3.24696 10.9621 3.25384C11.1701 3.26704 11.4859 3.32189 11.7825 3.53127C11.8569 3.58379 11.9328 3.64677 12.0073 3.71489C12.0835 3.64642 12.1602 3.58344 12.2342 3.53127C12.5231 3.32728 12.8245 3.2683 13.0347 3.25405C13.1418 3.24679 13.2331 3.25053 13.2988 3.25561C13.3234 3.25752 13.3446 3.25964 13.3601 3.2613C13.5097 3.26615 13.9243 3.30769 14.2803 3.66367L13.75 4.194L14.2803 3.66367C14.2851 3.66847 14.2907 3.67391 14.2969 3.68002C14.4115 3.79265 14.7539 4.12895 14.8129 4.71937C14.8533 5.12275 14.6993 5.49801 14.5776 5.73296C14.4452 5.98833 14.2749 6.22094 14.1282 6.37606C13.8842 6.63397 13.3569 7.10896 13.2685 7.17964C13.1837 7.24749 12.9644 7.40698 12.7893 7.53352C12.6968 7.6004 12.6086 7.66382 12.5436 7.71048L12.4656 7.76638L12.444 7.78188L12.4382 7.78597L12.4368 7.78703L12.4364 7.78731L12.4363 7.78739L12.4362 7.78741L12 7.17733ZM12 7.17733L11.5638 7.78742L12 8.09932L12.4362 7.78742L12 7.17733Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};
