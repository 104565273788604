import { useEffect, useState } from "react";
import { UtilInfoDto, IHttpResponse } from "typing";
import { theme, Container, SpaceWrapper, Row, mappedIcons } from "ui";
import { useAppContext } from "application/context/state";
import { utilInfoApi } from "implementations";
import UtilInfoOption from "../Option";
import { Skeleton } from "../../../Skeleton";
import {
  FooterIconAndTitleContent,
  PaymentOptionsTitle,
  PaymentOptionsWrapper,
  WrapperUtilInfoOption,
} from "./styles";

interface UtilInfoPaymentOptionsProps {
  isBlackTheme: boolean;
}

const UtilInfoPaymentOptions = ({
  isBlackTheme,
}: UtilInfoPaymentOptionsProps) => {
  const { isClientLaptop } = useAppContext();
  const [paymentOptions, setPaymentOptions] = useState({
    data: null,
    isLoading: true,
    error: false,
  } as IHttpResponse<UtilInfoDto[], unknown>);

  const marginSpaceWrapper = () => {
    return isClientLaptop
      ? `${theme.space.x0} ${theme.space.x2}`
      : `${theme.space.x0} ${theme.space.x2} ${theme.space.x0}`;
  };

  const paddingSpaceWrapper = () => {
    return isClientLaptop
      ? `${theme.space.x6} ${theme.space.x0} ${theme.space.x0}`
      : `${theme.space.x0} ${theme.space.x2}`;
  };

  useEffect(() => {
    const getPaymentOptions = async () => {
      const response = await utilInfoApi.getPaymentOptions();
      setPaymentOptions(response);
    };

    getPaymentOptions();
  }, []);

  const paymentOptionsColor = (title?: string) => {
    return isBlackTheme
      ? theme.colors.neutral.white
      : title === "Formas de pagamento"
      ? theme.colors.neutral["520"]
      : theme.colors.neutral["999"];
  };

  return paymentOptions?.isLoading ? (
    <Skeleton count={4} height={theme.space.x4} />
  ) : (
    <PaymentOptionsWrapper>
      {paymentOptions?.data?.map(({ title, icon, options }) => {
        return (
          <SpaceWrapper
            key={title}
            margin={marginSpaceWrapper()}
            padding={paddingSpaceWrapper()}
          >
            <Container>
              <Row alignItems="start" gap={theme.space.x4}>
                <FooterIconAndTitleContent>
                  {icon && mappedIcons(icon?.title, { ...icon }, { ...icon })}

                  <PaymentOptionsTitle
                    color={paymentOptionsColor(title)}
                    fontSize={theme.typography.fontSize.sm.x5}
                  >
                    {title}
                  </PaymentOptionsTitle>
                </FooterIconAndTitleContent>

                <WrapperUtilInfoOption
                  display="flex"
                  flexDirection={isClientLaptop ? "row" : "column"}
                  isPayment
                >
                  {options?.length &&
                    options.map((option) => (
                      <UtilInfoOption
                        isBlackTheme={!!isBlackTheme}
                        key={option?.title}
                        {...option}
                      />
                    ))}
                </WrapperUtilInfoOption>
              </Row>
            </Container>
          </SpaceWrapper>
        );
      })}
    </PaymentOptionsWrapper>
  );
};

export { UtilInfoPaymentOptions };
